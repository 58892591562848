import React from 'react'
import { Color, Icon, Token } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import {
  ResignationApprovalInterface,
  ResignationStatus,
} from '@src/interfaces/employeeResignation'
import { pathToUrl } from '@src/utils/router'
import { ApprovalFlowStep, ApprovalStatuses } from '@src/interfaces/approvalFlow'

export const RESIGNATION_PAGE_TITLE = 'Employee resignation'
export const RESIGNATION_LETTER_PAGE_TITLE = 'Resignation letter'

export const getProfileBackUrl = (id: number) =>
  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })

export const getResignationStatusColor = (status?: ResignationStatus): Color => {
  switch (status) {
    case 'completed':
      return 'green'
    case 'cancelled':
      return 'red'
    case 'pending_review':
      return 'orange'
    case 'pending_notice_file':
      return 'orange'
    case 'notice_file_pending_review':
      return 'orange'
    default:
      return Token.color.foreground
  }
}

export const getResignationLetterStatusColor = (status?: ApprovalStatuses): Color => {
  switch (status) {
    case 'approved':
      return 'green'
    case 'rejected':
      return 'red'
    case 'pending':
      return 'orange'
    default:
      return 'orange'
  }
}

export const getIconFromApprovalStep = ({
  status,
  color,
}: {
  status?: ApprovalStatuses
  color?: string
}): ReturnType<typeof Icon> => {
  switch (status) {
    case 'pending':
      return <Icon name="16/SandWatch" color={color || Token.color.yellow} size={16} />
    case 'approved':
      return <Icon name="Check" color={color || Token.color.green} size={16} />
    case 'rejected':
      return <Icon name="Cross" color={color || Token.color.red} size={16} />
    default:
      return <Icon name="16/SandWatch" color={color || Token.color.yellow} size={16} />
  }
}

// TODO: Remove formatting when data is adjusted on BE
export const formatApproval = (
  resignationApprovalStep: ResignationApprovalInterface | undefined,
): ApprovalFlowStep | undefined => {
  if (!resignationApprovalStep) {
    return undefined
  }
  const { approval_step, approval_status, ...restApprovalData } = resignationApprovalStep

  return {
    approval_step: approval_step.id,
    approval_status: approval_status.id,
    ...restApprovalData,
  }
}
