import React, { useRef, useState } from 'react'
import {
  Box,
  Cell,
  Checkbox,
  ExpandableCell,
  Flex,
  HStack,
  Input,
  Item,
  VStack,
} from '@revolut/ui-kit'

import { useQuery } from '@src/utils/queryParamsHooks'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { WorkScheduleExtraHoursStepInterface } from '@src/interfaces/workSchedule'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { navigateReplace } from '@src/actions/RouterActions'
import { PageActions } from '@components/Page/PageActions'
import { selectorKeys } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'

import { CommonTabsProps, useCopyEmptyFields } from '../common'
import { useGetWorkScheduleExtraHours } from '@src/api/workSchedule'
import Loader from '@components/CommonSC/Loader'

type CheckboxInputProps = {
  checkboxLabel: string
  inputLabel: string
  fieldName:
    | 'max_daily_overtime'
    | 'max_weekly_overtime'
    | 'max_monthly_overtime'
    | 'max_yearly_overtime'
}
const CheckboxInput = ({ checkboxLabel, inputLabel, fieldName }: CheckboxInputProps) => {
  const { values } = useLapeContext<WorkScheduleExtraHoursStepInterface>()

  const [enabled, setEnabled] = useState(values[fieldName] != null)
  const [inputValue, setInputValue] = useState(
    values[fieldName] != null ? String(values[fieldName]) : '',
  )

  const inputRef = useRef<HTMLInputElement>(null)

  const updateField = (newValue: string | null) => {
    if (newValue === null) {
      values[fieldName] = null
      return
    }
    const numValue = Number(newValue)

    if (isNaN(numValue)) {
      throw new Error('Something went wrong, please reload the page')
    } else {
      values[fieldName] = numValue
    }
  }

  return (
    <HStack align="center" space="s-16" width="100%">
      <Checkbox
        label={checkboxLabel}
        checked={enabled}
        onChange={e => {
          const checked = e.currentTarget.checked
          setEnabled(checked)

          if (checked) {
            updateField(inputValue)
            setTimeout(() => {
              inputRef.current?.focus()
            }, 0)
          } else {
            updateField(null)
          }
        }}
      />
      <Input
        required
        ref={inputRef}
        type="number"
        disabled={!enabled}
        label={inputLabel}
        value={inputValue}
        onChange={e => {
          const newValue = e.currentTarget.value
          setInputValue(newValue)
          values[fieldName] = isNaN(Number(newValue)) ? null : Number(newValue)
        }}
      />
    </HStack>
  )
}

type Props = CommonTabsProps
export const WorkScheduleExtraHours = ({ nextTabPath }: Props) => {
  const { query } = useQuery()
  const { values } = useLapeContext<WorkScheduleExtraHoursStepInterface>()

  const { data: copySource, isLoading: isCopySourceLoading } =
    useGetWorkScheduleExtraHours(query.copyFrom)

  useCopyEmptyFields<WorkScheduleExtraHoursStepInterface>(copySource, [
    'allow_past_shift_changes',
    'consecutive_working_days_limit',
    'max_daily_overtime',
    'max_monthly_overtime',
    'max_weekly_overtime',
    'max_yearly_overtime',
    'minimum_rest_period',
    'night_hours_time_end',
    'night_hours_time_start',
    'overtime_allowed',
    'overtime_compensation',
    'schedule_tab_visible_for_employee',
    'toil_policy',
    'weekly_nominal_hours',
  ])

  if (isCopySourceLoading) {
    return <Loader />
  }
  return (
    <>
      <VStack mt="s-16" space="s-16">
        <VStack space="s-8">
          <SectionTitle title="What hours should be considered night shifts?" />
          <HStack space="s-16">
            <LapeNewTimeInput
              name="night_hours_time_start"
              label="Beginning of night hours"
              formatValueToDateTime={false}
              required
            />
            <LapeNewTimeInput
              name="night_hours_time_end"
              label="End of night hours"
              formatValueToDateTime={false}
              required
            />
          </HStack>
        </VStack>
        <VStack>
          <SectionTitle
            title="Should employees be able to take overtime?"
            subtitle="Overtime is when employees work more than the hours set in the previous step"
          />
          <LapeRadioSwitch<boolean>
            name="overtime_allowed"
            variant="horizontal"
            options={[
              {
                id: 'yes',
                label: 'Yes',
                description:
                  'Employees should be able to take overtime with or without approval',
                value: true,
              },
              {
                id: 'no',
                label: 'No',
                description: 'Employees are not allowed to register ay overtime hours',
                value: false,
              },
            ]}
          />
        </VStack>
        {values.overtime_allowed && (
          <>
            <VStack space="s-8">
              <SectionTitle title="Should there be limits on how much overtime can be taken?" />
              <Cell>
                <VStack space="s-16" width="100%">
                  <HStack space="s-16">
                    <CheckboxInput
                      checkboxLabel="Per day"
                      inputLabel="Hours/day"
                      fieldName="max_daily_overtime"
                    />
                    <CheckboxInput
                      checkboxLabel="Per week"
                      inputLabel="Hours/week"
                      fieldName="max_weekly_overtime"
                    />
                  </HStack>
                  <HStack space="s-16">
                    <CheckboxInput
                      checkboxLabel="Per month"
                      inputLabel="Hours/month"
                      fieldName="max_monthly_overtime"
                    />
                    <CheckboxInput
                      checkboxLabel="Per year"
                      inputLabel="Hours/year"
                      fieldName="max_yearly_overtime"
                    />
                  </HStack>
                </VStack>
              </Cell>
            </VStack>
            <VStack>
              <SectionTitle
                title="How should overtime be compensated?"
                subtitle="When someone works overtime, they are normally compensated by either getting more time off, known as Time off in Lieu (TOIL) or by being paid more in cash."
              />
              <LapeRadioSelectInput
                name="overtime_compensation"
                label="Overtime compensation options"
                selector={selectorKeys.work_schedule_overtime_compensations}
              />
            </VStack>
            {values.overtime_compensation &&
              values.overtime_compensation.id !== 'compensation' && (
                <VStack space="s-8">
                  <SectionTitle title="When TOIL is selected, which time off policy should be incremented?" />
                  <LapeRadioSelectInput
                    name="toil_policy"
                    label="TOIL time off policy"
                    selector={selectorKeys.time_off_toil_policies}
                  />
                </VStack>
              )}
          </>
        )}
        <Box mt="s-16">
          <ExpandableCell>
            <ExpandableCell.Title>Advanced settings</ExpandableCell.Title>
            <ExpandableCell.Note>
              <VStack>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Allow employees to change shifts in the past',
                    description:
                      'If turned on, employees can make any edits to past shifts',
                    transparent: true,
                  }}
                  name="allow_past_shift_changes"
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Allow employees to see shift and attendance data',
                    description:
                      'If enabled, employees will be able to see their working hours in their profile',
                    transparent: true,
                  }}
                  name="schedule_tab_visible_for_employee"
                />
                <Flex gap="s-16" alignItems="center" justifyContent="space-between">
                  <Item
                    style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                  >
                    <Item.Content>
                      <Item.Title>
                        What should be the limit of consecutive working days?
                      </Item.Title>
                      <Item.Description>
                        Employees will not be allowed to work more than this number of
                        day, without a day of break
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <Box maxWidth={96}>
                    <LapeNewInput
                      required
                      type="number"
                      label="Days"
                      name="consecutive_working_days_limit"
                    />
                  </Box>
                </Flex>
                <Flex gap="s-16" alignItems="center" justifyContent="space-between">
                  <Item
                    style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                  >
                    <Item.Content>
                      <Item.Title>
                        What should be the minimum resting period between shifts?
                      </Item.Title>
                      <Item.Description>
                        Shifts will need to have a break of at least this many hours
                        between them
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <Box maxWidth={96}>
                    <LapeNewInput
                      required
                      type="number"
                      label="Hours"
                      name="minimum_rest_period"
                    />
                  </Box>
                </Flex>
                <Flex gap="s-16" alignItems="center" justifyContent="space-between">
                  <Item
                    style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                  >
                    <Item.Content>
                      <Item.Title>
                        How many hours should a full time employee work?
                      </Item.Title>
                      <Item.Description>
                        This will be used to calculate proportional holidays, for example
                        when an employee works part time
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <Box maxWidth={96}>
                    <LapeNewInput
                      required
                      type="number"
                      label="Hours"
                      name="weekly_nominal_hours"
                    />
                  </Box>
                </Flex>
              </VStack>
            </ExpandableCell.Note>
          </ExpandableCell>
        </Box>
      </VStack>
      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          hideWhenNoChanges={false}
          onAfterSubmit={res => {
            navigateReplace(pathToUrl(nextTabPath, { id: res.id }, query))
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
