import React from 'react'
import { Group, Box } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import {
  AllowRoleAndSpecialisationOwnersToViewSalariesField,
  EnableDataExportForTalentAndRequisitionSectionsField,
  EnableMultipleLevelsPerSeniorityField,
  EnableSpecialisationSettingsField,
  FunctionSettingsFields,
  FunctionSettingsSaveButton,
  RoleApprovalStepSettingsField,
  SpecialisationApprovalStepSettingsField,
} from './common'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const Function = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply to functions, roles and specialisations"
          />
          <Box mt="s-24">
            <Group>
              <EnableSpecialisationSettingsField />
              <AllowRoleAndSpecialisationOwnersToViewSalariesField />
              <EnableMultipleLevelsPerSeniorityField />
              <FunctionSettingsFields />
              <EnableDataExportForTalentAndRequisitionSectionsField />
            </Group>
          </Box>
          {specialisationsEnabled ? (
            <>
              <NewStepperTitle
                title="Roles"
                subtitle="Settings that apply to all roles"
              />
              <Box mt="s-24">
                <RoleApprovalStepSettingsField />
              </Box>
            </>
          ) : null}

          <NewStepperTitle
            title={specialisationsEnabled ? 'Specialisations' : 'Roles'}
            subtitle={
              specialisationsEnabled
                ? 'Settings that apply to all specialisations'
                : 'Settings that apply to all roles'
            }
          />
          <Box mt="s-24">
            <SpecialisationApprovalStepSettingsField />
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <FunctionSettingsSaveButton />
      </PageActions>
    </>
  )
}
