import React, { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { Avatar, Banner, Cell, Flex, Item, Switch, Token, VStack } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  WorkScheduleBasicStepInterface,
  WorkScheduleWorkingDay,
  WorkScheduleWorkingDaySettings,
} from '@src/interfaces/workSchedule'
import {
  DayOption,
  isEmptyWorkDaysError,
  parseWorkDaySettingsErrors,
  TimeSteppedInput,
  WorkDaySettingsErrors,
} from './common'
import { daysOptions } from './constants'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

const createDaySettingsGetterById =
  (values: WorkScheduleBasicStepInterface) =>
  (dayId: WorkScheduleWorkingDay): WorkScheduleWorkingDaySettings => {
    const daySettings = values.working_days?.find(
      workingDaySettings => workingDaySettings.day === dayId,
    )
    return daySettings
      ? omit(daySettings, 'day')
      : {
          work_start_time: null,
          work_end_time: null,
          break_start_time: null,
          break_end_time: null,
        }
  }

const useSettingsByDays = () => {
  const { values } = useLapeContext<WorkScheduleBasicStepInterface>()
  const getDaySettings = createDaySettingsGetterById(values)

  const [mondaySettings, setMondaySettings] = useState<WorkScheduleWorkingDaySettings>(
    getDaySettings('monday'),
  )
  const [tuesdaySettings, setTuesdaySettings] = useState<WorkScheduleWorkingDaySettings>(
    getDaySettings('tuesday'),
  )
  const [wednesdaySettings, setWednesdaySettings] =
    useState<WorkScheduleWorkingDaySettings>(getDaySettings('wednesday'))
  const [thursdaySettings, setThursdaySettings] =
    useState<WorkScheduleWorkingDaySettings>(getDaySettings('thursday'))
  const [fridaySettings, setFridaySettings] = useState<WorkScheduleWorkingDaySettings>(
    getDaySettings('friday'),
  )
  const [saturdaySettings, setSaturdaySettings] =
    useState<WorkScheduleWorkingDaySettings>(getDaySettings('saturday'))
  const [sundaySettings, setSundaySettings] = useState<WorkScheduleWorkingDaySettings>(
    getDaySettings('sunday'),
  )

  return {
    monday: { value: mondaySettings, setValue: setMondaySettings },
    tuesday: { value: tuesdaySettings, setValue: setTuesdaySettings },
    wednesday: { value: wednesdaySettings, setValue: setWednesdaySettings },
    thursday: { value: thursdaySettings, setValue: setThursdaySettings },
    friday: { value: fridaySettings, setValue: setFridaySettings },
    saturday: { value: saturdaySettings, setValue: setSaturdaySettings },
    sunday: { value: sundaySettings, setValue: setSundaySettings },
  }
}

const createIsDaySelected =
  (selectedDays: DayOption[]) => (dayId: WorkScheduleWorkingDay) =>
    selectedDays.some(day => day.id === dayId)

type Props = {
  selectedDays: DayOption[]
  addSelectedDay: (newDay: DayOption) => void
  updateSelectedDays: (newDays: DayOption[]) => void
  errors: Array<string | WorkDaySettingsErrors>
  clearErrors: () => void
}
export const DaysIndividualSettingsWidget = ({
  selectedDays,
  addSelectedDay,
  updateSelectedDays,
  errors,
  clearErrors,
}: Props) => {
  const { values } = useLapeContext<WorkScheduleBasicStepInterface>()
  const daysSettings = useSettingsByDays()
  const isDaySelected = createIsDaySelected(selectedDays)

  useEffect(() => {
    const daysIds = daysOptions.map(day => day.id)
    const daysWithSettings = daysIds
      .map(dayId =>
        selectedDays.some(day => day.id === dayId)
          ? {
              day: dayId,
              ...daysSettings[dayId].value,
            }
          : null,
      )
      .filter(Boolean)

    values.working_days = daysWithSettings.length ? daysWithSettings : null
  }, [
    daysSettings.monday.value,
    daysSettings.tuesday.value,
    daysSettings.wednesday.value,
    daysSettings.thursday.value,
    daysSettings.friday.value,
    daysSettings.saturday.value,
    daysSettings.sunday.value,
    selectedDays.length,
  ])

  const errorsByDays =
    values.working_days?.reduce<
      Partial<Record<WorkScheduleWorkingDay, WorkDaySettingsErrors>>
    >((errorsMap, workingDay, idx) => {
      if (!isEmpty(errors[idx])) {
        errorsMap[workingDay.day] = errors[idx] as WorkDaySettingsErrors
      }
      return errorsMap
    }, {}) || {}
  const hasErrors = !isEmpty(errorsByDays) || isEmptyWorkDaysError(errors)
  const brokenDays = Object.keys(errorsByDays)

  return (
    <VStack space="s-16">
      {hasErrors && (
        <Banner bg={Token.color.green}>
          <Banner.Avatar>
            <Avatar useIcon="ExclamationMarkOutline" color={Token.color.red} />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>Please review work days settings below</Banner.Title>
            <Banner.Description>
              {isEmptyWorkDaysError(errors)
                ? 'You should select at least one work day'
                : `${
                    brokenDays.length > 1 ? 'These work days seem' : 'This work day seems'
                  } to be configured improperly: ${brokenDays
                    .map(capitalize)
                    .join(', ')}`}
            </Banner.Description>
          </Banner.Content>
        </Banner>
      )}
      <Cell p={0} pb="s-12">
        <VStack space="s-16" width="100%">
          {daysOptions.map(dayOption => {
            const checked = isDaySelected(dayOption.id)
            const { value, setValue } = daysSettings[dayOption.id]
            const parsedErrors = parseWorkDaySettingsErrors(errorsByDays[dayOption.id])

            return (
              <VStack key={dayOption.id} data-testid={`${dayOption.id}_settings`}>
                <Item style={transparentThemeBackgroundOverrides}>
                  <Item.Content>
                    <Item.Title>{dayOption.name}</Item.Title>
                    {checked ? null : <Item.Description>Not working</Item.Description>}
                  </Item.Content>
                  <Item.Side>
                    <Switch
                      checked={checked}
                      onChange={e => {
                        clearErrors()

                        if (e.currentTarget.checked) {
                          addSelectedDay(dayOption)
                        } else {
                          updateSelectedDays(
                            selectedDays.filter(
                              selectedDay => selectedDay.id !== dayOption.id,
                            ),
                          )
                        }
                      }}
                    />
                  </Item.Side>
                </Item>
                {checked && (
                  <Flex gap="s-8" mx="s-16">
                    <TimeSteppedInput
                      label="Start"
                      value={value.work_start_time || ''}
                      onChange={e => {
                        clearErrors()
                        setValue({ ...value, work_start_time: e.currentTarget.value })
                      }}
                      invalid={!!parsedErrors.workStartTime}
                      errorMessage={parsedErrors.workStartTime}
                    />
                    <TimeSteppedInput
                      label="End"
                      value={value.work_end_time || ''}
                      onChange={e => {
                        clearErrors()
                        setValue({ ...value, work_end_time: e.currentTarget.value })
                      }}
                      invalid={!!parsedErrors.workEndTime}
                      errorMessage={parsedErrors.workEndTime}
                    />
                    <TimeSteppedInput
                      label="Break start"
                      value={value.break_start_time || ''}
                      onChange={e => {
                        setValue({ ...value, break_start_time: e.currentTarget.value })
                      }}
                      invalid={!!parsedErrors.breakStartTime}
                      errorMessage={parsedErrors.breakStartTime}
                    />
                    <TimeSteppedInput
                      label="Break end"
                      value={value.break_end_time || ''}
                      onChange={e => {
                        clearErrors()
                        setValue({ ...value, break_end_time: e.currentTarget.value })
                      }}
                      invalid={!!parsedErrors.breakEndTime}
                      errorMessage={parsedErrors.breakEndTime}
                    />
                  </Flex>
                )}
              </VStack>
            )
          })}
        </VStack>
      </Cell>
    </VStack>
  )
}
