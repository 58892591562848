import { EmployeeOptionInterface, NameIdInterface } from '@src/interfaces/employees'
import { SeniorityInterface, SeniorityTimelineInterface } from '@src/interfaces/seniority'
import { OptionInterface } from '@src/interfaces/selectors'
import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { KeeperGrade, RankGrade } from '@src/interfaces/performance'
import { BaseGrowthPlanInterface } from '@src/interfaces/growthPlans'

export enum PromotionStage {
  Initial = 'promotion_nomination',
  Benchmark = 'benchmark_review',
  Department = 'department_review',
  Function = 'function_review',
  PerformanceTeam = 'performance_team_review',
  RewardsTeam = 'rewards_team_review',
  PerformanceCommittee = 'performance_committee',
  HRTeam = 'hr_team_processing',
  Passed = 'passed',
}

export enum PromotionReviewerType {
  Group = 'group',
  Employee = 'employee',
}

export enum ProgressionTrack {
  Exeptional = 'exeptional',
  Fast = 'fast',
  Normal = 'normal',
}

export interface RecommendationDataInterface {
  are_skills_exceeding_current_position: IdAndName<KeeperGrade>
  are_skills_exceeding_future_position: IdAndName<KeeperGrade>
  employee_department_rank: IdAndName<RankGrade>
  is_culture_champion: IdAndName<KeeperGrade>
}

export interface PromotionNominationInterface {
  id: number
  employee: EmployeeOptionInterface
  nominated_by: EmployeeOptionInterface
  target_seniority: SeniorityInterface | null
  target_specialisation: OptionInterface | null
  meets_eligibility_criteria: boolean
  justification: string[]
  is_role_change_promotion: boolean
  initial_seniority: SeniorityInterface
  initial_specialisation: NameIdInterface
  status: Statuses
  current_stages: Array<{
    id: PromotionStage
    name: string
    outcome: Statuses
    reviewer: EmployeeOptionInterface | { id: null }
  }>
  update_date_time?: string
  business_requirements_justification?: string
  recommendation_data: Omit<RecommendationDataInterface, 'employee_department_rank'>
  progression_track: ProgressionTrack | null
  field_options: FieldOptions
  last_updated_by?: EmployeeOptionInterface | null
  growth_plan?: BaseGrowthPlanInterface | null
}

export interface PromotionNominationTimelineInterface {
  current_seniority: SeniorityTimelineInterface | null
  target_seniority: SeniorityTimelineInterface | null
  eligibility_criteria: EligibilityCriteriaInterface | null
  eligibility_criteria_result: EligibilityCriteriaResultInterface
}

export interface PromotionNominationProgression {
  progression_track: ProgressionTrack
}

export interface EligibilityCriteriaInterface {
  is_promotion_nomination_required: boolean
  cycles_in_role_and_seniority: number
  strong_plus_grades_in_recent_cycles: number
}

export interface EligibilityCriteriaResultInterface {
  cycles_in_role_and_seniority: number
  strong_plus_grades_in_recent_cycles: number
  is_eligible: boolean
  progression_cycle: string | null
}

export interface PromotionCurrentStageInterface {
  id: PromotionStage
  name: string
}

export interface PromotionStageInterface {
  id: number
  new_salary: number
  stage_type: { id: PromotionStage; name: string }
  outcome: { id: Statuses; name: string }
  reviewer_type: PromotionReviewerType
  reviewer: EmployeeOptionInterface | null
  reviewer_group: { id: number; name: string } | null
  decision_justification: string
  rejection_reasons?: { name: string; value: boolean }[]
  recommendation_data: RecommendationDataInterface
  completed_date_time: string | null
  field_options: FieldOptions
  progression_track: PromotionNominationInterface['progression_track']
  status: Statuses
}

export interface PromotionStageReviewData {
  outcome: { id: Statuses }
  new_salary?: number
  decision_justification: string
  rejection_reasons?: PromotionStageInterface['rejection_reasons']
  recommendation_data?: RecommendationDataInterface
}

export interface PromotionLocationState {
  backUrl?: string
}

export interface EligibilityCriteria {
  id: number
  name: string
  description: string
  is_shown_in_banner?: boolean
}

export interface EligibilityCheckItem {
  passed: boolean
  criterion_value?: number
  eligibility_criteria: EligibilityCriteria
}

export interface EligibilityCheckOptionsInterface {
  specialisation: { id: number | string }
  seniority: { id: number | string }
  progression_track: ProgressionTrack
  growth_plan_id?: number
}

export interface EligibilityCheckResult extends EligibilityCheckOptionsInterface {
  meets_eligibility_criteria: boolean
  eligibility_criteria_results: EligibilityCheckItem[]
}
