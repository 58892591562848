import React from 'react'
import { Checkbox, HStack, TextArea, Box } from '@revolut/ui-kit'
import { ActionItem } from '@src/interfaces/meetings'

import styled from 'styled-components'

const StyledTextArea = styled(TextArea)`
  & > div {
    background-color: transparent !important;
    padding: 0 16px 0 16px;

    :hover,
    :focus,
    :active,
    :focus-within {
      background-color: transparent !important;
    }
  }
`

interface Props {
  onChecked?: VoidFunction
  actionItem: ActionItem & { id?: number }
  onTextChange?: (newValue: string) => void
  onEnterPressed?: VoidFunction
  inputRef?: (el: HTMLTextAreaElement | null) => void
  disabled?: boolean
  onPaste?: (e: React.ClipboardEvent<HTMLTextAreaElement>) => void
}

export const Actionable = ({
  actionItem,
  onChecked,
  onTextChange,
  onEnterPressed,
  inputRef,
  disabled,
  onPaste,
}: Props) => {
  return (
    <HStack width="100%" align="start">
      <Checkbox
        checked={actionItem.completed}
        onClick={onChecked}
        aria-label={`${actionItem.completed ? 'Complete' : 'Uncomplete'} ${
          actionItem.value
        }`}
      />
      <Box width="100%">
        <StyledTextArea
          onPaste={onPaste}
          disabled={disabled}
          ref={inputRef}
          placeholder="Insert next action item here"
          value={actionItem.value}
          onChange={e => onTextChange?.(e.currentTarget.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              onEnterPressed?.()
            }
            if (e.key === 'Backspace' && !actionItem.value) {
              e.preventDefault()
              onTextChange?.('')
            }
          }}
        />
      </Box>
    </HStack>
  )
}
