import React from 'react'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import styled from 'styled-components'
import { Icon, Token } from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import Tooltip from '@components/Tooltip/Tooltip'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'
import { selectorKeys } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'

const StatusFailed = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
`

const CustomTooltip = styled(Tooltip)`
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: start;
`

export const changelogChangedFields = (
  specialisationEnabled: boolean,
): ColumnInterface<ChangelogInterface<unknown>> => ({
  type: CellTypes.insert,
  idPoint: 'fields_to_change',
  dataPoint: 'fields_to_change',
  sortKey: 'fields_to_change__keys',
  filterKey: 'fields_to_change__keys',
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    const fields =
      data.fields_to_change &&
      Object.keys(data.fields_to_change).map(field => {
        if (specialisationEnabled !== undefined && field === 'specialisation_id') {
          return `${getSpecialisationTitle(specialisationEnabled)} id`
        }
        return capitalize(lowerCase(field))
      })

    if (!fields || !fields.length) {
      return '-'
    }

    return <div>{fields.join(', ')}</div>
  },
  title: 'Changed fields',
})

export const changelogFieldsChangedFields = (
  specialisationEnabled?: boolean,
): ColumnInterface<ChangelogInterface<unknown>> => ({
  type: CellTypes.insert,
  idPoint: 'field_name',
  dataPoint: 'field_name',
  sortKey: 'field_name',
  filterKey: 'field_name',
  insert: ({ data }) => {
    if (specialisationEnabled !== undefined && data.field_name === 'specialisation') {
      return getSpecialisationTitle(specialisationEnabled)
    }
    if (specialisationEnabled !== undefined && data.field_name === 'specialisation_id') {
      return `${getSpecialisationTitle(specialisationEnabled)} id`
    }
    return capitalize(lowerCase(data.field_name))
  },
  selectorsKey: selectorKeys.employee_changelog_fields,
  title: 'Changed field',
})

export const changelogFieldsOldValue: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.text,
  idPoint: 'initial_field_display_value',
  dataPoint: 'initial_field_display_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Old Value',
}

export const changelogFieldsNewValue: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.text,
  idPoint: 'field_display_value',
  dataPoint: 'field_display_value',
  sortKey: 'field_display_value',
  filterKey: 'field_display_value',
  selectorsKey: selectorKeys.none,
  title: 'New Value',
}

export const changelogEffectiveDate: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.date,
  idPoint: 'effective_date_time',
  dataPoint: 'effective_date_time',
  sortKey: 'effective_date_time',
  filterKey: 'effective_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Effective Date',
}

export const changelogChangedOn: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.date,
  idPoint: 'updated_date_time',
  dataPoint: 'updated_date_time',
  sortKey: 'updated_date_time',
  filterKey: 'updated_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Changed on',
}

export const changelogChangedBy: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.text,
  idPoint: 'created_by.id',
  dataPoint: 'created_by.full_name',
  sortKey: 'created_by__full_name',
  filterKey: 'created_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Changed by',
}

export const changelogChangedByWithAvatar: ColumnInterface<ChangelogInterface<unknown>> =
  {
    type: CellTypes.insert,
    idPoint: 'created_by.id',
    dataPoint: 'created_by.full_name',
    sortKey: 'created_by__full_name',
    filterKey: 'created_by__id',
    selectorsKey: selectorKeys.employee,
    title: 'Changed by',
    insert: ({ data }) => <Table.EmployeeCell employee={data.created_by} />,
  }

export const changelogStatus: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  insert: ({ data }) => {
    let variant: ColorTagVariant = 'default'

    switch (data.status?.id) {
      case Statuses.requires_changes:
      case Statuses.pending:
        variant = 'warning'
        break
      case Statuses.completed:
      case Statuses.approved:
        variant = 'success'
        break
      case Statuses.failed:
      case Statuses.rejected:
        variant = 'danger'
        break
      default:
        break
    }

    if (data.status.id === Statuses.failed && data.errors) {
      return (
        <StatusFailed>
          <CustomTooltip
            placement="bottom"
            title="Changes failed with an error:"
            text={JSON.stringify(data.errors)}
          >
            <Table.StatusCell status={data.status.name} variant={variant} />
            <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
          </CustomTooltip>
        </StatusFailed>
      )
    }
    return <Table.StatusCell status={data.status.name} variant={variant} />
  },
  selectorsKey: selectorKeys.changelog_item_statuses,
  title: 'Status',
}

export const changelogAction: ColumnInterface<ChangelogInterface<unknown>> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
}
