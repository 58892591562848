import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import MultiInput, { MultiInputProps } from '@components/Inputs/MultiInput/MultiInput'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props<T, V> extends MultiInputProps<T> {
  name: string
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
  mapValue?: (value: V) => T
}

const LapeMultiInput = <T extends {} = { text: string }, V = unknown>({
  name,
  hideOptional,
  mapValue,
  ...props
}: Props<T, V>) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, hidden } = lapeProps
  const formValidator = useFormValidator()

  if (hidden) {
    return null
  }

  const { description, ...commonProps } = getCommonInputProps(
    { ...props, hideOptional, name },
    lapeProps,
    !!formValidator?.validated,
  )

  const isErrorsObject = typeof lapeProps.error === 'object'

  // description and message are the same thing in common props. If using "withCell" variant we don't want
  // message duplicated for input and cell description
  const shouldDisplayMessage = !(isErrorsObject || (props.withCell && !lapeProps.error))

  return (
    <MultiInput<T>
      onChange={onChange}
      valueFieldName="text"
      value={mapValue?.(value) || value}
      {...props}
      {...commonProps}
      message={shouldDisplayMessage ? commonProps.message : undefined}
      inputErrors={isErrorsObject ? lapeProps.error : undefined}
    />
  )
}

export default connect(LapeMultiInput)
