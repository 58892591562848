import React, { useState } from 'react'
import { ActionButton, Box, Flex, Radio, RadioGroup, Text } from '@revolut/ui-kit'
import { useUpdateCandidateConsent } from '@src/features/CandidateConsentToInterviewAISummarisation/api'
import { ConsentLayout } from '@src/features/CandidateConsentToInterviewAISummarisation/ConsentLayout'

interface Props {
  recruiterEmail: string
}

export const AskForCandidateConsent = ({ recruiterEmail }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [consent, setConsent] = useState<boolean | null>(null)

  const {
    mutateAsync,
    isLoading: loadingPatchCandidateConsent,
    isError,
  } = useUpdateCandidateConsent()

  const handleSubmit = async () => {
    await mutateAsync({ consent_to_interview_ai_summarisation: consent })
    setSubmitted(true)
  }

  if (isError) {
    return (
      <ConsentLayout title="Interview transcripts">
        <Text textAlign="center">There was a error with your consent request.</Text>
        <Text textAlign="center">Please contact {recruiterEmail}</Text>
        <ActionButton mx="auto" use="a" href={`mailto:${recruiterEmail}`}>
          Contact recruiter
        </ActionButton>
      </ConsentLayout>
    )
  }

  if (submitted) {
    return (
      <ConsentLayout title="Interview transcripts">
        <Text textAlign="center">
          Thank you for sharing your preference! Your choice matters to us.
        </Text>
      </ConsentLayout>
    )
  }

  return (
    <ConsentLayout title="Interview transcripts">
      <RadioGroup<boolean>
        disabled={loadingPatchCandidateConsent}
        value={consent}
        onChange={setConsent}
      >
        {group => (
          <Flex flexDirection="row" justifyContent="center">
            <Box p="s-16">
              <Radio {...group.getInputProps({ value: true })}>
                <Radio.Label>Yes, I consent</Radio.Label>
              </Radio>
            </Box>
            <Box p="s-16">
              <Radio {...group.getInputProps({ value: false })}>
                <Radio.Label>No, I don't consent</Radio.Label>
              </Radio>
            </Box>
          </Flex>
        )}
      </RadioGroup>
      <Text textAlign="center" variant="emphasis1">
        Tick "Yes, I consent" above if you agree to us using AI to record a written
        transcript and summarise your interviews (video or audio will not be recorded).
        The transcripts and summaries will be used to help our interviewers fill in their
        interview scorecards, which means we will be able to assess you more fairly and
        provide you with more timely and accurate feedback.
      </Text>
      <Text textAlign="center" variant="emphasis1">
        Consent is entirely optional and will not impact your employment prospects. You
        can withdraw your consent at any time by emailing us at dpo@revolut.com or by
        notifying your interviewer during any interview.
      </Text>
      <ActionButton
        variant="black"
        mx="auto"
        disabled={consent === null}
        pending={loadingPatchCandidateConsent}
        onClick={handleSubmit}
      >
        Submit
      </ActionButton>
    </ConsentLayout>
  )
}
