import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { performanceSettings } from '@src/api/performanceSettings'
import Form from '@src/features/Form/Form'
import { API } from '@src/constants/api'
import { Switch } from 'react-router-dom'
import { GeneralSettings } from '@src/apps/Performance/Settings/GeneralSettings'

import { SettingsLanding } from '@src/apps/Performance/Settings/Landing'
import { InnerSettingsRoute } from '@src/pages/Performance/Scorecard/Settings/common/InnerSettingsRoute'
import { CycleSettings } from '@src/pages/Performance/Scorecard/Settings/Cycle'
import { PipSettings } from '@src/pages/Performance/Scorecard/Settings/PIP'
import { ProbationSettings } from '@src/pages/Performance/Scorecard/Settings/Probation'
import { ReviewSettings } from '@src/pages/Settings/Performance/ReviewSettings/ReviewSettings'

export const PerformanceSettings = () => {
  return (
    <PageWrapper>
      <Form
        api={performanceSettings}
        invalidateQueries={[API.PERFORMANCE_SETTINGS]}
        forceParams={{ id: '1' }}
      >
        <Switch>
          <InnerSettingsRoute
            path={ROUTES.PERFORMANCE.SETTINGS.LANDING}
            title="Review settings"
            description="Settings for performance functionalities"
            backPath={ROUTES.PERFORMANCE.REVIEWS.GENERAL}
            showActions={false}
          >
            <SettingsLanding />
          </InnerSettingsRoute>
          <InnerSettingsRoute
            path={ROUTES.PERFORMANCE.SETTINGS.GENERAL}
            title="General settings"
            backPath={ROUTES.PERFORMANCE.SETTINGS.LANDING}
          >
            <GeneralSettings />
          </InnerSettingsRoute>

          <InnerSettingsRoute
            path={ROUTES.PERFORMANCE.SETTINGS.CYCLE}
            title="Cycle duration"
            backPath={ROUTES.PERFORMANCE.SETTINGS.LANDING}
          >
            <CycleSettings />
          </InnerSettingsRoute>

          <InnerSettingsRoute
            path={ROUTES.PERFORMANCE.SETTINGS.REVIEWS}
            title="Review types"
            backPath={ROUTES.PERFORMANCE.SETTINGS.LANDING}
          >
            <ReviewSettings />
          </InnerSettingsRoute>

          <InnerSettingsRoute
            path={ROUTES.PERFORMANCE.SETTINGS.PIP}
            title="PIP settings"
            backPath={ROUTES.PERFORMANCE.SETTINGS.LANDING}
          >
            <PipSettings />
          </InnerSettingsRoute>

          <InnerSettingsRoute
            path={ROUTES.PERFORMANCE.SETTINGS.PROBATION}
            title="Probation settings"
            backPath={ROUTES.PERFORMANCE.SETTINGS.LANDING}
          >
            <ProbationSettings />
          </InnerSettingsRoute>
        </Switch>
      </Form>
    </PageWrapper>
  )
}
