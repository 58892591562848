import React from 'react'
import { Box } from '@revolut/ui-kit'
import { RatingHistoryItemData } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/ChartData'
import { RatingAssessors } from '@src/features/ReviewCycle/Analytics/Skills/components/RatingAssessors'
import { LIGHT_BACKGROUND_TOOLTIP_COLOR } from '@src/pages/Forms/QueryForm/components/Charts/components/LightBackgroundTooltip'

export const Tooltip = (props: RatingHistoryItemData) => {
  const {
    rating,
    rating_category,
    rating_score,
    expected_rating,
    expected_rating_score,
    rating_expectation,
    lm_rating,
    fm_rating,
    self_rating,
    ...talent
  } = props.rawData

  return (
    <Box color={LIGHT_BACKGROUND_TOOLTIP_COLOR}>
      <RatingAssessors
        rating={{
          rating,
          rating_category,
          rating_score,
          expected_rating,
          expected_rating_score,
          rating_expectation,
          lm_rating,
          fm_rating,
          self_rating,
        }}
        talent={talent}
      />
    </Box>
  )
}
