import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { RoleInterface, RoleStep as RoleStepLegacy } from '@src/interfaces/roles'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { ROUTES } from '@src/constants/routes'
import { RoleStep } from '../constants'
import { navigateReplace } from '@src/actions/RouterActions'
import { SubmitButton } from './SubmitButton'
import { notReachable } from '@src/utils/notReachable'
import { useGetMatrixValidatorAndSenioritiesForRole } from '@src/features/CompetencyMatrixTable/utils'

interface Props {
  stepKey: RoleStep
  withHiringProcess: boolean
}

const getNextStep = (
  currentStep: RoleStep,
  withHiringProcess: boolean,
): RoleStep | 'finish' => {
  switch (currentStep) {
    case 'general-info':
      return 'competency-matrix'
    case 'competency-matrix':
      return withHiringProcess ? 'hiring-process' : 'finish'
    case 'hiring-process':
      return 'finish'

    default:
      return notReachable(currentStep)
  }
}

const stepToLegacyRoleStep: Record<RoleStep, RoleStepLegacy> = {
  'competency-matrix': 'competency_matrix',
  'general-info': 'general',
  'hiring-process': 'hiring_process',
}

export const FormActions = ({ stepKey, withHiringProcess }: Props) => {
  const { values, dirty, valid } = useLapeContext<RoleInterface>()
  const { matrixValidated } = useGetMatrixValidatorAndSenioritiesForRole(values)

  const getSubmitButtonText = () => {
    if (getNextStep(stepKey, withHiringProcess) === 'finish') {
      return 'Finish'
    }
    if (values.id && dirty && values.status !== Statuses.draft) {
      return 'Save changes'
    }
    return 'Next'
  }
  return (
    <>
      {(values.status === Statuses.draft || !values.id) && (
        <RoleSaveDraftButton
          title="Role"
          pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.EDIT.GENERAL_INFO)}
          pathAfterSave={pathToUrl(ROUTES.FORMS.ROLE.EDIT.ANY, {
            step: stepKey,
            id: ':id', // a hack to keep id in the path so it can be replaced after update
          })}
          isNew
          notification={{
            updateMsg: 'Role draft successfully updated',
            createMsg: 'Role draft successfully created',
          }}
        />
      )}
      {/**
       * Need to use that component instead of the default form submit as
       * 1. step query param matters for BE (does different parsing / normalisation)
       * 2. It handles some normalisation already
       */}
      <SubmitButton
        step={stepToLegacyRoleStep[stepKey]}
        onAfterSubmit={response => {
          navigateReplace(
            getNextStep(stepKey, withHiringProcess) === 'finish'
              ? pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id: response.id })
              : pathToUrl(ROUTES.FORMS.ROLE.EDIT.ANY, {
                  id: response.id,
                  step: getNextStep(stepKey, withHiringProcess),
                }),
          )
        }}
        disabled={!valid || (stepKey === 'competency-matrix' && !matrixValidated)}
      >
        {getSubmitButtonText()}
      </SubmitButton>
    </>
  )
}
