import React from 'react'
import { Token, Grid, VStack, HStack, Flex, Image, Text, Box } from '@revolut/ui-kit'

interface ImgProps {
  tags: string[]
  imageSrc: string
  imageFit?: React.CSSProperties['objectFit']
  imagePosition?: React.CSSProperties['objectPosition']
  imageBackground?: string
  category: {
    name: string
    badgeImageSrc: string
  }
  title: string
  description: string
}

interface VideoProps {
  tags: string[]
  videoSrc: string
  category: {
    name: string
    badgeImageSrc: string
  }
  title: string
  description: string
}

type Props = VideoProps | ImgProps

export const Block = (props: Props) => {
  const { tags, category, title, description } = props
  return (
    <Grid columns="1fr 1fr" gap="s-24" py={80}>
      <VStack gap="s-24" minHeight={600} style={{ justifyContent: 'center' }}>
        <Flex gap="s-8">
          {tags.map(tag => (
            <Box
              key={tag}
              bg="#4F55F1"
              color={Token.color.white}
              radius={Token.radius.r24}
              py="s-8"
              px="s-12"
            >
              <Text variant="emphasis2">{tag}</Text>
            </Box>
          ))}
        </Flex>
        <HStack gap="s-24" maxWidth={310}>
          <Image src={category.badgeImageSrc} alt="" width={56} height={56} />
          <Text use="h6" fontWeight={500} fontSize="1.5rem" color={Token.color.grey20}>
            {category.name}
          </Text>
        </HStack>
        <Text
          variant="h2"
          use="h2"
          fontWeight={900}
          lineHeight="3.75rem"
          fontSize="3.75rem"
          style={{ textTransform: 'uppercase' }}
        >
          {title}
        </Text>
        <Text fontWeight={400} fontSize="1.125rem">
          {description}
        </Text>
      </VStack>
      <VStack minHeight={600}>
        {'imageSrc' in props ? (
          <Image
            src={props.imageSrc}
            alt=""
            width="100%"
            height={600}
            radius={Token.radius.r24}
            style={{
              objectFit: props.imageFit || 'cover',
              objectPosition: props.imagePosition,
              background: props.imageBackground,
            }}
          />
        ) : (
          <video
            autoPlay
            loop
            width="100%"
            height={600}
            style={{
              borderRadius: Token.radius.r24,
              objectFit: 'cover',
              objectPosition: 'bottom',
            }}
          >
            <source src={props.videoSrc} type="video/mp4" />
          </video>
        )}
      </VStack>
    </Grid>
  )
}
