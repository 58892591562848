import { AxiosPromise } from 'axios'
import { API } from '@src/constants/api'
import { apiV2 } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  ComplianceStatsDTO,
  PipelineStage,
  ProbationInterface,
  ProbationStatisticsDTO,
} from '@src/interfaces/probation'
import { ProbationCycleSettingsInterface } from '@src/interfaces/reviewCycles'
import { useFetchV2 } from '@src/utils/reactQuery'

export const getProbationItems = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ProbationInterface>> =>
  apiV2.get(
    API.PROBATION,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const useGetProbationStats = () =>
  useFetchV2<ProbationStatisticsDTO>({ url: API.PROBATION_STATS, version: 'v2' })

export const useGetPipelineStages = ({
  employeeId,
  cycleId,
  enabled,
}: {
  employeeId: number
  cycleId: string | number
  enabled?: boolean
}) =>
  useFetchV2<PipelineStage[]>({
    version: 'v2',
    url: `/employees/${employeeId}/performance/cycles/${cycleId}/stages`,
    queryOptions: { enabled },
  })

export const useGetProbationComplianceStats = ({ enabled }: { enabled?: boolean } = {}) =>
  useFetchV2<ComplianceStatsDTO>({
    url: '/probation/all/complianceStats',
    version: 'v2',
    queryOptions: { enabled },
  })

export const regenerateProbationScorecards = (cycleId: string) =>
  apiV2.post(`${API.PROBATION_SCORECARDS}/${cycleId}/regenerate`, {}, undefined, 'v2')

export const createProbationCycle = (employeeId: number) =>
  apiV2.post(`/probation/probationCycle/${employeeId}/create`, {}, undefined, 'v2')

export const closeProbationCycle = (cycleId: string) =>
  apiV2.post(`${API.PROBATION_CYCLE}/${cycleId}/close`, {}, undefined, 'v2')

export const deleteEmployeeCycle = (cycleId: string) =>
  apiV2.delete(`${API.EMPLOYEE_PERFORMANCE_CYCLES}/${cycleId}`, {}, 'v2')

export const probationCycleSettingsRequests: RequestInterfaceNew<ProbationCycleSettingsInterface> =
  {
    get: async ({ id }) => {
      return apiV2.get(`${API.PROBATION_CYCLE}/${id}`, {}, 'v2')
    },
    update: async (data, { id }) => {
      return apiV2.patch(`${API.PROBATION_CYCLE}/${id}`, data, {}, 'v2')
    },
    submit: async (data, { id }) => {
      return apiV2.post(`${API.PROBATION_CYCLE}/${id}`, data, {}, 'v2')
    },
  }
