import { Button, ButtonProps, StatusPopup, VStack, useStatusPopup } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { apiWithoutHandling } from '@src/api'
import { connect } from 'lape'
import { navigateReplace } from '@src/actions/RouterActions'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface, RoleStep, SpecialisationInterface } from '@src/interfaces/roles'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import { arrayErrorsToFormError, difference } from '@src/utils/form'
import { cloneDeep } from 'lodash'
import { pushNotification, successNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import {
  getNormalizedCompetencyMatrix,
  getNormalizedFuncCompetencyMatrix,
} from '@src/features/CompetencyMatrixTable/utils'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'
import { omitSpecialisationCompetencyMatrixV1Fields } from '@src/api/specialisations'

interface ContinueRoleButtonProps extends ButtonProps {
  step?: RoleStep
  api: string
  to: string
  type: 'specialisation' | 'role'
  onAfterSubmit?: () => void
}

const ContinueRoleButton = ({
  step,
  api,
  to,
  type,
  onAfterSubmit = () => {},
  ...rest
}: ContinueRoleButtonProps) => {
  const params = useParams()
  const formValidator = useFormValidator()
  const form = useLapeContext<RoleInterface | SpecialisationInterface>()
  const [pending, setPending] = useState(false)
  const isExisting = form.values.id && form.values.status !== Statuses.draft
  const statusPopup = useStatusPopup()
  const competencyMatrixV2 =
    useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2) && type === 'specialisation'

  const showHiringProcessRoundErrors = (
    error: (FormError<HiringProcessInterface> | undefined)[],
  ) => {
    const idsWithError = Object.keys(error).map(Number)
    const hiringProcessRoundsErrors = form.values.hiring_process_rounds
      .filter(({ id }) => idsWithError.includes(id))
      .map(({ id, title }) => {
        const hiringStageRoute =
          type === 'role'
            ? ROUTES.FORMS.HIRING_STAGES.ROLE
            : ROUTES.FORMS.HIRING_STAGES.SPECIALISATION
        return (
          <InternalLink
            key={id}
            target="_blank"
            to={pathToUrl(hiringStageRoute, {
              id,
            })}
          >
            {title}
          </InternalLink>
        )
      })
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          {hiringProcessRoundsErrors.length
            ? 'There are errors in the following hiring stages: '
            : 'Something went wrong'}
        </StatusPopup.Title>
        {!!hiringProcessRoundsErrors.length && (
          <StatusPopup.Description>
            <VStack mt="-s-8">
              <VStack>{hiringProcessRoundsErrors}</VStack>
              Please edit these stages and try again
            </VStack>
          </StatusPopup.Description>
        )}
      </StatusPopup>,
    )
  }
  const submit = async () => {
    try {
      setPending(true)
      if (step === 'general' && !form.values.id) {
        const data = {
          ...form.values,
          status: Statuses.draft,
          functional_competency_matrix: getNormalizedFuncCompetencyMatrix(form.values),
        }
        const resp = await apiWithoutHandling.post(
          api,
          competencyMatrixV2
            ? omitSpecialisationCompetencyMatrixV1Fields({
                ...data,
                competency_matrix: getNormalizedCompetencyMatrix(form.values),
              })
            : data,
        )
        if (resp.data) {
          if (type === 'role') {
            workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {}))
          } else if (type === 'specialisation') {
            workspaceLocalStorage.removeItem(
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {}),
            )
          }
          !isExisting && navigateReplace(pathToUrl(to, { id: resp.data.id }))
        }
      } else {
        const diff = difference(form.values, form.initialValues) as
          | Partial<RoleInterface>
          | Partial<SpecialisationInterface>

        if (competencyMatrixV2) {
          if ('competency_matrix' in diff) {
            diff.competency_matrix = getNormalizedCompetencyMatrix(form.values)
          }
        } else {
          diff.functional_competency_matrix = getNormalizedFuncCompetencyMatrix(
            form.values,
          )
        }

        await apiWithoutHandling.patch(
          `${api}/${form.values.id}`,
          competencyMatrixV2 ? omitSpecialisationCompetencyMatrixV1Fields(diff) : diff,
          {
            params: {
              step,
            },
          },
        )
        !isExisting && navigateReplace(pathToUrl(to, params))
      }
      if (isExisting) {
        onAfterSubmit()
        successNotification('Updated successfully')
      }
      form.dirty = false
      form.initialValues = cloneDeep(form.values)
    } catch (e) {
      form.errors = arrayErrorsToFormError(e?.response?.data)
      if (e?.response?.data?.non_field_errors || e?.response?.data?.detail) {
        pushNotification({
          type: NotificationTypes.error,
          value: e?.response?.data?.non_field_errors?.[0] || e?.response?.data?.detail,
          duration: SUCCESS_DEFAULT_DURATION,
        })
      }
      if (form.errors?.hiring_process_rounds) {
        showHiringProcessRoundErrors(form.errors?.hiring_process_rounds)
      }
      if (!form.errors?.hiring_process_rounds) {
        throw e
      }
    } finally {
      setPending(false)
    }
  }

  if (isExisting && !form.dirty) {
    return null
  }

  const onSubmit = formValidator?.validate ? formValidator.validate(submit) : submit

  return (
    <Button
      data-testid="continue-role-button"
      onClick={onSubmit}
      pending={pending}
      elevated
      {...rest}
    >
      {isExisting ? 'Save Changes' : 'Continue'}
    </Button>
  )
}

export default connect(ContinueRoleButton)
