import React from 'react'
import { Absolute, Box, BoxProps, Flex, Token } from '@revolut/ui-kit'

import { ROUTES, WORKSPACES } from '@src/constants/routes'

import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import { pathToUrl } from '@src/utils/router'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { BookDemoButton } from '@src/pages/Landing/components/BookDemoButton'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { navigateTo } from '@src/actions/RouterActions'

export const LandingHeader = (props: BoxProps) => {
  const workspaceContext = useWorkspaceContext()

  return (
    <Absolute top={0} width="100%" {...props}>
      <Flex
        maxWidth={{ all: '1000px', xxl: '1432px' }}
        width="100%"
        margin="auto"
        justifyContent="space-between"
        px="s-16"
        mt="s-16"
        alignItems="center"
      >
        <InternalLink to={pathToUrl(ROUTES.MAIN)}>
          <LogoAlt width="200" />
        </InternalLink>

        <>
          <Flex gap="s-24" alignItems="center" display={{ all: 'none', md: 'flex' }}>
            <Button
              // @ts-ignore
              variant="text"
              onClick={() => {
                const lastWorkspace = workspaceContext?.workspaces[0]

                if (lastWorkspace) {
                  window.location.href = `/${lastWorkspace.name}`
                } else {
                  navigateTo(WORKSPACES.MAIN)
                }
              }}
              style={{ color: Token.color.foreground }}
            >
              Log in
            </Button>

            <BookDemoButton />
          </Flex>

          <Box display={{ all: 'flex', md: 'none' }}>
            <BookDemoButton />
          </Box>
        </>
      </Flex>
    </Absolute>
  )
}
