import React, { useMemo } from 'react'
import {
  Flex,
  Icon,
  HStack,
  Text,
  Token,
  Widget,
  Placeholder,
  ChartWidgetSkeleton,
} from '@revolut/ui-kit'
import { EmployeeTotalCompensationTimeSeriesInterface } from '@src/interfaces/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { BarChart } from '../Charts/BarChart'

export const ON_TARGET_BONUS_LABEL = 'On target bonus*'
export const VESTED_EQUITY_LABEL = 'Vested equity'
export const BENEFITS_LABEL = 'Benefits'

type Props = {
  data?: EmployeeTotalCompensationTimeSeriesInterface[]
  isLoading: boolean
  height: string
  minHeight: number
}

const DATA_KEYS = [
  {
    id: 0,
    label: 'Base salary',
    value: 'base_salary',
    color: Token.colorChannel.blue,
  },
  {
    id: 1,
    label: VESTED_EQUITY_LABEL,
    value: 'vested_equity_value',
    color: Token.colorChannel.cyan,
  },
  {
    id: 2,
    label: 'Cash bonus',
    value: 'cash_bonus',
    color: Token.colorChannel.pink,
  },
  {
    id: 3,
    label: 'Sold equity',
    value: 'sold_equity_value',
    color: Token.colorChannel.lime,
  },
  {
    id: 4,
    label: BENEFITS_LABEL,
    value: 'benefits_contribution_value',
    color: Token.colorChannel.yellow,
  },
  {
    id: 5,
    label: ON_TARGET_BONUS_LABEL,
    value: 'on_target_bonus_value',
    color: Token.colorChannel.orange,
    isProjected: true,
  },
] as const

export const CompensationOverTime = ({ data, isLoading, height, minHeight }: Props) => {
  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(value => {
      return {
        label: '',
        tooltip: '',
        on_target_bonus_value: value.on_target_bonus.value,
        sold_equity_value: value.sold_equity.value,
        vested_equity_value: value.vested_equity.value,
        benefits_contribution_value: value.benefits_contribution.value,
        ...value,
      }
    })
  }, [data])

  const dataKeys = useMemo(() => {
    if (!chartData) {
      return []
    }

    return DATA_KEYS.filter(
      key => chartData.filter(timeSeries => Number(timeSeries[key.value])).length > 0,
    )
  }, [chartData])

  if (isLoading) {
    return <ChartWidgetSkeleton />
  }

  if (!isLoading && !data?.length) {
    return (
      <Widget height={height} minHeight={minHeight} p="s-16">
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Placeholder>
            <Placeholder.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D086',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
              }}
            />
            <Placeholder.Title>
              We couldn't find any historical compensation data
            </Placeholder.Title>
          </Placeholder>
        </Flex>
      </Widget>
    )
  }

  return (
    <Widget
      height={height}
      minHeight={minHeight}
      p="s-16"
      data-testid="compensation_over_time_section"
    >
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Compensation over time</Text>
          <Tooltip
            placement="bottom"
            text="Review your past earnings and expected future compensation, including: vesting equity, base salary (per your current contractual details), estimated benefits, and on target bonus based on your current role and seniority (without performance adjustments)."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <BarChart data={chartData} dataKeys={dataKeys} isLoading={isLoading} />
      </Flex>
    </Widget>
  )
}
