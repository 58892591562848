import { GetRequestInterface } from '@src/interfaces'
import { useFetchV2, UseQueryOptions } from '@src/utils/reactQuery'
import {
  IndividualBitbucketMetricsInterface,
  IndividualJiraMetricsInterface,
  ExternalMetricSources,
  OrgUnitBitbucketMetricsInterface,
  OrgUnitJiraMetricsInterface,
  IndividualInterviewsMetricsInterface,
  InternalMetricSources,
  OrgUnitInterviewsMetricsInterface,
} from '@src/features/AiInsights/Performance/interfaces/metrics'

export enum MetricsParamsKeys {
  Source = 'source',
  CycleId = 'cycle_id',
  OrgUnitId = 'unit',
}

export interface EmployeeJiraMetricsParams {
  [MetricsParamsKeys.CycleId]?: number | number[]
}

export const useGetEmployeeJiraMetrics = (
  employeeId: number,
  params?: EmployeeJiraMetricsParams,
  queryOptions?: UseQueryOptions<GetRequestInterface<IndividualJiraMetricsInterface>>,
) => {
  const adaptedParams = params?.cycle_id
    ? {
        [MetricsParamsKeys.CycleId]:
          typeof params.cycle_id === 'number'
            ? params.cycle_id
            : params.cycle_id.join(','),
      }
    : {}

  return useFetchV2<GetRequestInterface<IndividualJiraMetricsInterface>>({
    url: `/${employeeId}/employeeSourceStats`,
    version: 'v2',
    params: {
      params: {
        [MetricsParamsKeys.Source]: ExternalMetricSources.Jira,
        ...adaptedParams,
      },
    },
    queryOptions,
  })
}

export interface OrgUnitJiraMetricsParams {
  [MetricsParamsKeys.OrgUnitId]?: string
  [MetricsParamsKeys.CycleId]?: number
}

export const useGetOrgUnitJiraMetrics = (
  employeeId: number,
  params: OrgUnitJiraMetricsParams,
  queryOptions?: UseQueryOptions<OrgUnitJiraMetricsInterface>,
) => {
  const adaptedParams = {
    [MetricsParamsKeys.OrgUnitId]: params.unit,
    ...(params.cycle_id ? { [MetricsParamsKeys.CycleId]: params.cycle_id } : {}),
  }

  return useFetchV2<OrgUnitJiraMetricsInterface>({
    url: `/${employeeId}/employeeSourceStats/statistics`,
    version: 'v2',
    params: {
      params: {
        [MetricsParamsKeys.Source]: ExternalMetricSources.Jira,
        ...adaptedParams,
      },
    },
    queryOptions: {
      ...(queryOptions || {}),
      enabled: queryOptions?.enabled !== false && params.unit !== undefined,
    },
  })
}

export interface EmployeeBitbucketMetricsParams {
  [MetricsParamsKeys.CycleId]?: number
}

export const useGetEmployeeBitbucketMetrics = (
  employeeId: number,
  params?: EmployeeBitbucketMetricsParams,
  queryOptions?: UseQueryOptions<
    GetRequestInterface<IndividualBitbucketMetricsInterface>
  >,
) => {
  return useFetchV2<GetRequestInterface<IndividualBitbucketMetricsInterface>>({
    url: `/${employeeId}/employeeSourceStats`,
    version: 'v2',
    params: {
      params: {
        [MetricsParamsKeys.Source]: ExternalMetricSources.Bitbucket,
        ...(params ?? {}),
      },
    },
    queryOptions,
  })
}

export interface OrgUnitBitbucketMetricsParams {
  [MetricsParamsKeys.OrgUnitId]?: string
  [MetricsParamsKeys.CycleId]?: number
}

export const useGetOrgUnitBitbucketMetrics = (
  employeeId: number,
  params: OrgUnitBitbucketMetricsParams,
  queryOptions?: UseQueryOptions<OrgUnitBitbucketMetricsInterface>,
) => {
  const adaptedParams = {
    [MetricsParamsKeys.OrgUnitId]: params.unit,
    ...(params.cycle_id ? { [MetricsParamsKeys.CycleId]: params.cycle_id } : {}),
  }

  return useFetchV2<OrgUnitBitbucketMetricsInterface>({
    url: `/${employeeId}/employeeSourceStats/statistics`,
    version: 'v2',
    params: {
      params: {
        [MetricsParamsKeys.Source]: ExternalMetricSources.Bitbucket,
        ...adaptedParams,
      },
    },
    queryOptions: {
      ...(queryOptions || {}),
      enabled: queryOptions?.enabled !== false && params.unit !== undefined,
    },
  })
}

export interface EmployeeInterviewsMetricsParams {
  [MetricsParamsKeys.CycleId]?: number
}

export const useGetEmployeeInterviewsMetrics = (
  employeeId: number,
  params?: EmployeeInterviewsMetricsParams,
  queryOptions?: UseQueryOptions<
    GetRequestInterface<IndividualInterviewsMetricsInterface>
  >,
) => {
  return useFetchV2<GetRequestInterface<IndividualInterviewsMetricsInterface>>({
    url: `/${employeeId}/employeeSourceStats`,
    version: 'v2',
    params: {
      params: {
        [MetricsParamsKeys.Source]: InternalMetricSources.Interviews,
        ...(params ?? {}),
      },
    },
    queryOptions,
  })
}

export interface OrgUnitInterviewsMetricsParams {
  [MetricsParamsKeys.OrgUnitId]?: string
  [MetricsParamsKeys.CycleId]?: number
}

export const useGetOrgUnitInterviewsMetrics = (
  employeeId: number,
  params: OrgUnitInterviewsMetricsParams,
  queryOptions?: UseQueryOptions<OrgUnitInterviewsMetricsInterface>,
) => {
  const adaptedParams = {
    [MetricsParamsKeys.OrgUnitId]: params.unit,
    ...(params.cycle_id ? { [MetricsParamsKeys.CycleId]: params.cycle_id } : {}),
  }

  return useFetchV2<OrgUnitInterviewsMetricsInterface>({
    url: `/${employeeId}/employeeSourceStats/statistics`,
    version: 'v2',
    params: {
      params: {
        [MetricsParamsKeys.Source]: InternalMetricSources.Interviews,
        ...adaptedParams,
      },
    },
    queryOptions: {
      ...(queryOptions || {}),
      enabled: queryOptions?.enabled !== false && params.unit !== undefined,
    },
  })
}
