import React from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { StructureLevelGeneral } from './General'
import { PageWrapper } from '@src/components/Page/Page'
import { structureLevelRequests } from '../api'
import { ORGANISATION_STRUCTURE_LEVEL_DEFAULT_ICON } from '@src/constants/common'

export const StructureLevel = connect(() => {
  const params = useParams<{ levelId?: string }>()

  return (
    <Form
      api={structureLevelRequests}
      initialValues={{
        icon: ORGANISATION_STRUCTURE_LEVEL_DEFAULT_ICON,
      }}
      /** forcing `{}` for new "level" form, because it exists in a "structure" route, where the structure id exists, so it's needed to force no `id` in this case */
      forceParams={params.levelId ? params : {}}
      disableLocalStorageCaching
    >
      <PageWrapper>
        <StructureLevelGeneral />
      </PageWrapper>
    </Form>
  )
})
