import React, { useState } from 'react'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { DepartmentSettingsFields, DepartmentSettingsSaveButton } from './common'
import { StructureTabsSidebar } from '@src/pages/Settings/Structures/Structure/components/TabsSidebar'
import { OrganisationStructureTab } from '@src/pages/Settings/Structures/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { InputGroup } from '@revolut/ui-kit'
import { TabsItem } from '@src/pages/Settings/Structures/Structure/components/TabsItem'

const DepartmentsSettings = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const isResourcesTabEnabled = useHasFeatureFlag(FeatureFlags.ResourcesTabEnabled)

  const [openedSidebar, setOpenedSidebar] = useState<'tabs' | 'fields' | null>(null)

  const onChangeSidebarTabs = (tabs: OrganisationStructureTab[]) => {
    if (values.department_level) {
      values.department_level.tabs = tabs
    }
  }

  return (
    <>
      <PageBody>
        <DepartmentSettingsFields />

        {isResourcesTabEnabled && (
          <>
            <NewStepperTitle
              title="Default fields and layout"
              subtitle="Choose default fields and tabs for departments. These settings could be overridden for each department."
              noAutoStep
            />
            <InputGroup>
              <TabsItem
                title="Default layout"
                description="Configure which tabs will be available for each department"
                onOpen={() => setOpenedSidebar('tabs')}
                selectedTabs={values.department_level?.tabs.filter(
                  tab => tab.state?.id !== 'disabled',
                )}
              />
            </InputGroup>
          </>
        )}
      </PageBody>

      <PageActions>
        <DepartmentSettingsSaveButton />
      </PageActions>

      {isResourcesTabEnabled && (
        <StructureTabsSidebar
          isOpen={openedSidebar === 'tabs'}
          onChange={onChangeSidebarTabs}
          onClose={() => setOpenedSidebar(null)}
          onTabClick={id => {
            if (values.department_level?.id) {
              navigateTo(
                pathToUrl(ROUTES.SETTINGS.STRUCTURE_TAB, {
                  id,
                  levelId: values.department_level.id,
                }),
              )
            }
          }}
          tabs={values.department_level?.tabs}
          title="Default layout"
          subtitle="Configure which tabs will be available for each department"
          allowReorder={false}
        />
      )}
    </>
  )
}

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
    url: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: DepartmentsSettings,
  },
]

export const Departments = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Departments settings"
      subtitle="Settings that apply to all departments"
    />
  )
}
