import React from 'react'
import { Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { employeeBenefitRequests } from '@src/api/benefits'
import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeBenefitInterface } from '@src/interfaces/benefits'

import { Summary } from './Summary'
import { MainScreensWrapper, useEmployeeBenefitsParams } from './common'
import { PackageDetails } from './PackageDetails'
import { PackageSelect } from './PackageSelect'
import { Changelog } from './Changelog'
import { Dependant, NewDependant } from './Dependants'
import { useGetEmployee } from '@src/api/employees'
import { PermissionTypes } from '@src/store/auth/types'

const EmployeeBenefit = () => {
  const { initialValues } = useLapeContext<EmployeeBenefitInterface>()
  const isOptedOut = initialValues.status?.id === 'opted_out'

  const isSummaryRoute = !!useRouteMatch(ROUTES.FORMS.EMPLOYEE_BENEFIT.SUMMARY)
  const isPackageSelectRoute = !!useRouteMatch(
    ROUTES.FORMS.EMPLOYEE_BENEFIT.PACKAGE_SELECT,
  )
  const isEditRoute = !!useRouteMatch(ROUTES.FORMS.EMPLOYEE_BENEFIT.EDIT) && !isOptedOut
  const isDependantsRoute = !!useRouteMatch([
    ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW,
    ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.EDIT,
  ])
  const isNewDependantsRoute = !!useRouteMatch(
    ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.NEW,
  )
  const isPackageDetailsRoute = !!useRouteMatch(
    ROUTES.FORMS.EMPLOYEE_BENEFIT.PACKAGE_DETAILS,
  )
  const params = useEmployeeBenefitsParams()
  const { data: employee } = useGetEmployee(params.employeeId)
  const canAccessEmployeeBenefits = employee?.field_options.permissions?.some(
    permission => permission === PermissionTypes.CanAccessEmployeeBenefits,
  )

  if (isSummaryRoute) {
    return (
      <MainScreensWrapper>
        <Summary />
      </MainScreensWrapper>
    )
  }

  if (isPackageSelectRoute || isEditRoute) {
    return (
      <MainScreensWrapper>
        <PackageSelect />
      </MainScreensWrapper>
    )
  }

  if (isPackageDetailsRoute) {
    return <PackageDetails />
  }

  if (isNewDependantsRoute) {
    return <NewDependant />
  }

  if (isDependantsRoute) {
    return <Dependant />
  }

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW, params),
      component: Summary,
      canView: true,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.EMPLOYEE_BENEFIT.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.CHANGELOG, params),
      component: Changelog,
      canView: canAccessEmployeeBenefits,
    },
  ].filter(tab => tab.canView)

  return (
    <MainScreensWrapper
      tabbar={
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={tabs} />
        </Box>
      }
    >
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </MainScreensWrapper>
  )
}

export default connect(() => (
  <Form api={employeeBenefitRequests} disableLocalStorageCaching>
    <EmployeeBenefit />
  </Form>
))
