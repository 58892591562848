import { RequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { KPIRuleInterface } from '@src/interfaces/KpiTemplates'
import { usePostV2 } from '@src/utils/reactQuery'

export const kpisRulesRequests: RequestInterface<KPIRuleInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.KPI_RULE, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.KPI_RULE}/${id}`),
  putItem: async (data, kpiId) => api.patch(`${API.KPI_RULE}/${kpiId}`, data),
  patchItem: async (data, kpiId) => api.patch(`${API.KPI_RULE}/${kpiId}`, data),
  deleteItem: async id => api.delete(`${API.KPI_RULE}/${id}`),
  postItem: async data => api.post(API.KPI_RULE, data),
}

export const kpiRuleRequestsNew: RequestInterfaceNew<KPIRuleInterface> = {
  get: async ({ id }) => api.get(`${API.KPI_RULE}/${id}`),
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.KPI_RULE}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.KPI_RULE, data),
  delete: async ({ id }) => api.delete(`${API.KPI_RULE}/${id}`),
}

export const useCheckKpiRuleAffectedEntitiesByFilters = (id: number) =>
  usePostV2<undefined, { count: number }, Record<string, Array<string | number>>>({
    url: `${API.KPI_RULE}/${id}/entitiesCount`,
  })

interface UseRefreshRuleTemplateProps
  extends Pick<Parameters<typeof usePostV2>[0], 'handleError' | 'handleSuccess'> {
  ruleId: number
}

export const useRefreshRuleTemplate = ({
  ruleId,
  handleSuccess,
  handleError,
}: UseRefreshRuleTemplateProps) => {
  return usePostV2({
    url: `${API.KPI_RULE}/${ruleId}/apply`,
    version: 'v1',
    handleSuccess,
    handleError,
  })
}
