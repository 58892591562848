import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import {
  ActionButton,
  Avatar,
  Item,
  Subheader,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { handleError } from '@src/api'
import axios from 'axios'
import { SecurityErrorPopup } from '@src/pages/Settings/Security/SecurityErrorPopup'
import { useDispatch, useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { SecuritySettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { logOutAction } from '@src/store/auth/actions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'

export const Security = () => {
  const { initialValues } = useLapeContext<SecuritySettingsInterface>()

  const [error, setError] = useState<string>()
  const [twoFactorTurnedOn, setTwoFactorTurnedOn] = useState(false)

  const permissions = useSelector(selectPermissions)
  const dispatch = useDispatch()

  const queryClient = useQueryClient()
  const canEdit = permissions.includes(PermissionTypes.ChangeSecuritySettings)

  const oktaIntegrationEnabled = initialValues.okta_integration_enabled

  const onError = (e: Error) => {
    if (!axios.isAxiosError(e)) {
      throw e
    }
    if (e?.response?.data?.non_field_error?.[0]) {
      setError(e?.response?.data?.non_field_error?.[0])
      return
    }
    handleError(e)
  }

  return (
    <>
      <PageBody>
        <Subheader>Login methods</Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          Enable login methods for active users. Onboarding employees will require email
          and password.
        </Text>

        <VStack mt="s-12" mb="s-16" space="s-16">
          <Item use="label">
            <Item.Avatar>
              <Avatar useIcon="ChangePasscode" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Enable login with email and password</Item.Title>
              <Item.Description>
                Allow your employees to log in with their work email and password.
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <LapeNewSwitch name="enable_password_authentication" disabled={!canEdit} />
            </Item.Side>
          </Item>

          <Item use="label">
            <Item.Avatar>
              <Avatar useIcon="LogoGoogle|image" bg="white" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Enable login with Google</Item.Title>
              <Item.Description>
                Allow all your employees to log in with their Google Workspace account
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <LapeNewSwitch name="enable_google_sso" disabled={!canEdit} />
            </Item.Side>
          </Item>

          <VStack>
            <Item use={oktaIntegrationEnabled ? 'label' : undefined}>
              <Item.Avatar>
                <Avatar
                  image="https://assets.revolut.com/revoluters/icons/okta-76.png"
                  variant="brand"
                  bg="white"
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Enable login with Okta</Item.Title>
                <Item.Description>
                  Allow all your employees to log in with their Okta account
                </Item.Description>
              </Item.Content>
              <Item.Side>
                {oktaIntegrationEnabled ? (
                  <LapeNewSwitch name="enable_okta_sso" disabled={!canEdit} />
                ) : (
                  <ActionButton use={InternalLink} to={ROUTES.FEATURES.INTEGRATION.OKTA}>
                    Set up
                  </ActionButton>
                )}
              </Item.Side>
            </Item>
          </VStack>

          <Item use="label">
            <Item.Avatar>
              <Avatar useIcon="LogoMicrosoft|image" bg="#007DC1" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Enable login with Microsoft</Item.Title>
              <Item.Description>
                Allow all your employees to log in with their Microsoft account
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <LapeNewSwitch name="enable_microsoft_sso" disabled={!canEdit} />
            </Item.Side>
          </Item>
        </VStack>

        <Subheader>2-factor authentification</Subheader>

        <Item use="label">
          <Item.Avatar>
            <Avatar useIcon="Logo2Fa" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Enable 2-factor authentication</Item.Title>
            <Item.Description>
              Your employees will be asked to create a 2-Step Login via SMS messages.
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <LapeNewSwitch
              name="enable_two_factor_authentication"
              disabled={!canEdit}
              onAfterChange={enableTwoFA => {
                setTwoFactorTurnedOn(
                  !initialValues.enable_two_factor_authentication && enableTwoFA,
                )
              }}
            />
          </Item.Side>
        </Item>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<SecuritySettingsInterface>
          successText="Settings saved successfully"
          onSubmitError={onError}
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.LOGIN_SETTINGS)
            if (twoFactorTurnedOn) {
              dispatch(logOutAction())
            }
          }}
          confirmationDialogue={
            twoFactorTurnedOn
              ? {
                  label: 'Enabling 2-Factor authentication',
                  body: 'Enabling 2 factor authentication for the first time will require you to re-login. Do you want to proceed?',
                  yesMessage: 'Confirm',
                  noMessage: 'Cancel',
                  variant: 'compact',
                }
              : undefined
          }
        />
      </PageActions>

      <SecurityErrorPopup error={error} onClose={() => setError(undefined)} />
    </>
  )
}
