import { useQuery } from '@src/utils/queryParamsHooks'
import {
  createCandidateAppointment,
  getDynamicAvailableCandidateSlots,
  getPredefinedAvailableCandidateSlots,
  useCheckSchedulingReEngagementConsentNeeded,
  useGetCandidateInterview,
  useGetPublicTimezones,
} from '@src/api/recruitment/candidateScheduling'
import React, { useEffect, useState } from 'react'
import ReEngageConsent from '@components/ReEngageConsent/ReEngageConsent'
import { CandidateSelectSlots } from '@src/pages/CandidateScheduling/CandidateSelectSlots'
import {
  CreateCandidateSchedulingBasePayload,
  DynamicAvailableCandidateSlot,
  PredefinedAvailableCandidateSlot,
} from '@src/interfaces/interviewTool'
import { DataPrivacyNoticeForCandidates } from '@src/components/DataPrivacyNoticeForCandidates/DataPrivacyNoticeForCandidates'
import { Box } from '@revolut/ui-kit'

export default () => {
  const { query } = useQuery()
  const { data: timeZones, isLoading: isLoadingTimeZones } = useGetPublicTimezones(
    query.token,
  )
  const {
    data: interview,
    isLoading: isLoadingInterview,
    refetch: refetchInterview,
  } = useGetCandidateInterview(query.token)
  const { data: consentNeededData, isLoading: isConsentLoading } =
    useCheckSchedulingReEngagementConsentNeeded(query.token)
  const [consentCheck, setConsentCheck] = useState<boolean | undefined>(false)

  useEffect(() => {
    if (!consentNeededData?.is_consent_required) {
      setConsentCheck(undefined)
    }
  }, [consentNeededData?.is_consent_required])

  return (
    <CandidateSelectSlots<
      PredefinedAvailableCandidateSlot | DynamicAvailableCandidateSlot
    >
      confirmAppointment
      interview={
        interview
          ? {
              duration: interview.duration || interview.interview_stage.duration,
              durationUnit:
                interview.duration_unit || interview.interview_stage.duration_unit,
              id: interview.id,
              recruiterEmail: interview.coordinator?.email,
              timeZoneId: interview.candidate_timezone?.id,
              title: interview.is_adhoc
                ? 'Catch-up call'
                : interview.interview_stage.title,
              eventDateTime: interview.event_date_time,
            }
          : undefined
      }
      isLoadingInterview={isLoadingInterview}
      isLoadingTimeZones={isLoadingTimeZones}
      timeZones={timeZones}
      token={query.token}
      onCreateCandidateAppointment={async (
        timeZoneId,
        slot,
        interviewAISummarisationConsent,
      ) => {
        const params: CreateCandidateSchedulingBasePayload = {
          interviewId: interview!.id!,
          timeZoneId,
          reEngageConsent: consentCheck,
          token: query.token,
          interviewAISummarisationConsent,
        }

        await createCandidateAppointment(
          'id' in slot
            ? {
                ...params,
                slotId: slot.id,
              }
            : {
                ...params,
                eventDate: slot.event_start_datetime,
              },
        )
      }}
      onGetAvailableCandidateSlots={async (token, page) => {
        switch (interview?.scheduling_type) {
          case 'dynamic_scheduling': {
            const res = await getDynamicAvailableCandidateSlots(token, page)
            return {
              slots: res.data,
              count: res.data.length,
            }
          }

          default: {
            const res = await getPredefinedAvailableCandidateSlots(token, page)
            return {
              slots: res.data.results,
              count: res.data.count,
            }
          }
        }
      }}
      onRefetchInterview={refetchInterview}
      footer={
        <Box mb="s-16">
          <ReEngageConsent
            loading={isConsentLoading}
            consentNeeded={consentNeededData?.is_consent_required}
            checked={consentCheck}
            onCheck={setConsentCheck}
          />
          <DataPrivacyNoticeForCandidates />
        </Box>
      }
    />
  )
}
