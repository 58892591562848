import {
  ExternalMetricSources,
  InternalMetricSources,
  Metrics,
  MetricSources,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import {
  PerformanceInsightsGroupSourceInterface,
  PerformanceInsightsGroupSourceType,
} from '@src/features/AiInsights/Performance/interfaces/insights'

export const externalMetricSources: ExternalMetricSources[] =
  Object.values(ExternalMetricSources)

export const internalMetricSources: InternalMetricSources[] =
  Object.values(InternalMetricSources)

interface MetricSourceModel {
  isExternal: <T extends ExternalMetricSources, K extends Metrics>(
    source: PerformanceInsightsGroupSourceInterface<MetricSources, Metrics>,
  ) => source is PerformanceInsightsGroupSourceInterface<T, K>
  isInternal: <T extends InternalMetricSources, K extends Metrics>(
    source: PerformanceInsightsGroupSourceInterface<MetricSources, Metrics>,
  ) => source is PerformanceInsightsGroupSourceInterface<T, K>
}

const isExternal = <T extends ExternalMetricSources, K extends Metrics>(
  source: PerformanceInsightsGroupSourceInterface<MetricSources, Metrics>,
): source is PerformanceInsightsGroupSourceInterface<T, K> =>
  source.type === PerformanceInsightsGroupSourceType.External

const isInternal = <T extends InternalMetricSources, K extends Metrics>(
  source: PerformanceInsightsGroupSourceInterface<MetricSources, Metrics>,
): source is PerformanceInsightsGroupSourceInterface<T, K> =>
  source.type === PerformanceInsightsGroupSourceType.Internal

const createMetricSourceModel = (): MetricSourceModel => ({
  isExternal,
  isInternal,
})

export const metricSourceModel = createMetricSourceModel()
