import React, { useMemo } from 'react'
import {
  ActionMenu,
  Box,
  Flex,
  HStack,
  Icon,
  IconProps,
  Input,
  InputProps,
  Placeholder,
  Radio,
  RadioSelect,
  SelectOptionItemType,
  Text,
  TextSkeleton,
  Token,
  Tooltip,
  useDropdown,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { SurveyRoundStatus } from '@src/interfaces/engagement'
import { toKeyLabelValue } from '@src/utils/toKeyLabelValue'
import { UseSurveyRoundSelectorReturnType } from '../../Rounds/Selector/hooks/useSurveyRoundSelector'
import { RoundOption } from './types'

const StatusIcon = ({ status }: { status: IdAndName<SurveyRoundStatus> | undefined }) => {
  const tooltip = useTooltip()
  const statusToIconProps: Record<SurveyRoundStatus, IconProps> = {
    upcoming: { name: 'CalendarDate', color: Token.color.blue },
    ongoing: { name: '16/Time', color: Token.color.warning },
    completed: { name: 'CheckSuccess', color: Token.color.green },
    cancelled: { name: 'CrossCircle', color: Token.color.greyTone50 },
    terminated: { name: 'CrossCircle', color: Token.color.greyTone50 },
  }
  if (status && statusToIconProps[status.id]) {
    return (
      <>
        <Icon
          ml="s-8"
          size={20}
          {...tooltip.getAnchorProps()}
          {...statusToIconProps[status.id]}
        />
        <Tooltip {...tooltip.getTargetProps()}>{status.name}</Tooltip>
      </>
    )
  }
  return null
}

const SurveyOption = ({
  isOpen,
  title,
  children,
  onClick,
}: {
  isOpen: boolean
  title: string
  children: React.ReactNode
  onClick: VoidFunction
}) => {
  return (
    <VStack space="s-8" py="s-8" pr="s-12">
      <HStack align="center" use="button" onClick={onClick} space="s-8" pl="s-4">
        <Icon
          name={isOpen ? 'ChevronDown' : 'ChevronRight'}
          color={Token.color.greyTone50}
        />
        <Text textAlign="left" variant="body1">
          {title}
        </Text>
      </HStack>
      {isOpen ? children : null}
    </VStack>
  )
}

const NoResultsPlaceholder = ({ title }: { title: string }) => {
  return (
    <Placeholder>
      <Placeholder.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
        }}
      />
      <Placeholder.Title color={Token.color.greyTone50}>{title}</Placeholder.Title>
    </Placeholder>
  )
}

interface Props {
  data: UseSurveyRoundSelectorReturnType
  nested: boolean | undefined
  renderActions?: () => React.ReactNode
}
export const RoundSelector = ({ data, nested, renderActions }: Props) => {
  const { survey, round } = data

  const radioSelectProps = useMemo(() => {
    const options = round.options.map(roundOption => toKeyLabelValue(roundOption))
    const value = options.find(option => option.value.id === round.value?.id)?.value

    return { options, value }
  }, [round.options, round.value])

  const surveysDropdown = useDropdown()
  const roundsDropdown = useDropdown()

  const inputWidth = 260
  const dropdownWidth = inputWidth + 40
  const inputLabelFallback = 'Survey round'

  const commonInputProps: InputProps = {
    type: 'button',
    label: nested ? survey.value?.name || inputLabelFallback : inputLabelFallback,
    value: round.value?.name || 'Round is not selected',
    useIcon: 'ChevronDown',
    'aria-haspopup': 'listbox',
    width: 260,
    pending: round.isLoadingOptions,
    renderAction: () => <StatusIcon status={round.value?.status} />,
  }
  const dropdownSizeProps = { width: dropdownWidth, ml: '-s-16', mt: 's-4' }

  if (!nested) {
    return (
      <>
        <VStack space="s-6">
          <HStack space="s-8" align="center">
            <Input
              renderAction={() => <StatusIcon status={round.value?.status} />}
              {...roundsDropdown.getAnchorProps()}
              {...commonInputProps}
            />
            {renderActions?.()}
          </HStack>
        </VStack>
        <RadioSelect<RoundOption>
          {...dropdownSizeProps}
          {...roundsDropdown.getTargetProps()}
          loadingState={round.isLoadingOptions ? 'pending' : 'ready'}
          options={radioSelectProps.options}
          value={radioSelectProps.value}
          onChange={value => {
            if (value) {
              round.setValue(value)
            }
          }}
          labelList="Survey rounds"
          autoClose
        >
          {(option: SelectOptionItemType<RoundOption>) => {
            return (
              <Flex justifyContent="space-between" alignItems="center" gap="s-4">
                <Text whiteSpace="nowrap">{option.label}</Text>
                <StatusIcon status={option.value?.status} />
              </Flex>
            )
          }}
        </RadioSelect>
      </>
    )
  }
  return (
    <>
      <Input {...surveysDropdown.getAnchorProps()} {...commonInputProps} />
      <ActionMenu
        py="s-8"
        {...dropdownSizeProps}
        {...surveysDropdown.getTargetProps()}
        onClose={() => survey.setRoundsPreviewSurveyId(undefined)}
      >
        <ActionMenu.Group>
          {survey.options.length ? (
            survey.options.map(surveyOption => {
              const isOpen = surveyOption.id === survey.roundsPreviewSurveyId

              return (
                <SurveyOption
                  key={surveyOption.id}
                  title={surveyOption.name}
                  isOpen={isOpen}
                  onClick={() => {
                    if (isOpen) {
                      survey.setRoundsPreviewSurveyId(undefined)
                    } else {
                      survey.setRoundsPreviewSurveyId(surveyOption.id)
                    }
                  }}
                >
                  {round.isLoadingPreviewOptions ? (
                    <VStack space="s-12" px="s-32">
                      <TextSkeleton />
                      <TextSkeleton />
                      <TextSkeleton />
                    </VStack>
                  ) : round.options.length ? (
                    round.options.map(option => (
                      <Box key={option.id} p="s-4" pl="s-12">
                        <Radio
                          value={option.name}
                          checked={round.value?.id === option.id}
                          onChange={() => {
                            round.setValue(option)
                            survey.setValue(surveyOption)
                          }}
                        >
                          <Flex
                            width={200}
                            justifyContent="space-between"
                            alignItems="center"
                            gap="s-4"
                          >
                            <Text variant="body1" whiteSpace="nowrap">
                              {option.name}
                            </Text>
                            <StatusIcon status={option.status} />
                          </Flex>
                        </Radio>
                      </Box>
                    ))
                  ) : (
                    <NoResultsPlaceholder title="No completed rounds found" />
                  )}
                </SurveyOption>
              )
            })
          ) : (
            <NoResultsPlaceholder title="No active surveys found" />
          )}
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}
