import React from 'react'
import styled from 'styled-components'
import { Icon, HStack, Token } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import { pathToUrl } from '@src/utils/router'
import { CompanyUnit } from '@components/ColumnInserts/CompanyUnit/CompanyUnit'
import Table from '@components/TableV2/Table'
import { ColorTag, ColorTagVariant } from '@components/ColorTag/ColorTag'
import { RelatedKPIs } from '@components/ColumnInserts/RelatedKPIs/RelatedKPIs'
import { dashboardTypeRouteMap } from '@src/pages/Forms/DataAnalyticsDashboardForm/constants'
import { RelatedGoals } from '@src/components/ColumnInserts/RelatedGoals/RelatedGoals'
import { Statuses } from '@src/interfaces'
import { DASHBOARD_DEFAULT_ICON } from '@src/constants/common'

export const mapRatingToColor = (data: GenericAnalyticsDashboardInterface) => {
  if (!data.rating) {
    return Token.color.foreground
  }

  if (data.rating < 3) {
    return Token.color.danger
  }
  if (data.rating > 4) {
    return Token.color.success
  }

  return Token.color.foreground
}

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

export const analyticsDashboardNameColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.dashboard_names,
    dynamicHyperlinks: data =>
      pathToUrl(dashboardTypeRouteMap[data.dashboard_type], { id: data.id }),
    title: 'Dashboard Name',
    insert: ({ data, url }) => (
      <StyledTooltip placement="top" text={data.description} useFormatting>
        <Table.EntityCell
          useIcon={data.icon || DASHBOARD_DEFAULT_ICON}
          avatar={data.avatar}
          url={url}
        >
          {data.name}
        </Table.EntityCell>
      </StyledTooltip>
    ),
  }

export const analyticsDashboardOwnerColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.dashboard_owner_employees,
    title: 'Owner',
    insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
  }

export const analyticsDashboardRelatedKpisColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'related_kpis',
    dataPoint: 'name',
    sortKey: null,
    filterKey: 'related_kpis',
    selectorsKey: selectorKeys.dashboard_related_kpis,
    title: 'Related metrics',
    insert: ({ data }) => <RelatedKPIs kpis={data.related_kpis} />,
  }

export const analyticsDashboardRelatedGoalsColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'related_goals',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Related goals',
    insert: ({ data }) => <RelatedGoals goals={data.related_goals || []} />,
  }
export const analyticsDashboardOrganisationUnitsColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Organisation unit',
    insert: ({ data }) => {
      if (data.company_related) {
        return <CompanyUnit />
      }

      if (data.related_employees.length) {
        return (
          <HStack gap="s-4">
            {data.related_employees.map(item => (
              <ColorTag key={item.id}>{item.name}</ColorTag>
            ))}
          </HStack>
        )
      }

      if (data.related_teams.length) {
        return (
          <HStack gap="s-4">
            {data.related_teams.map(item => (
              <ColorTag key={item.id}>{item.name}</ColorTag>
            ))}
          </HStack>
        )
      }

      if (data.related_departments.length) {
        return (
          <HStack gap="s-4">
            {data.related_departments.map(item => (
              <ColorTag key={item.id}>{item.name}</ColorTag>
            ))}
          </HStack>
        )
      }

      return null
    },
  }

export const analyticsDashboardDetailsColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'details',
    dataPoint: 'details',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Details',
  }

export const analyticsDashboardSourceColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'dashboard_type',
    dataPoint: 'dashboard_type',
    sortKey: null,
    filterKey: 'dashboard_type',
    selectorsKey: selectorKeys.dashboard_types,
    title: 'Source',
    insert: ({ data }) => {
      if (data.dashboard_type === 'looker') {
        return (
          <Icon src="https://assets.revolut.com/assets/apps/Looker.png" variant="image" />
        )
      }

      if (data.dashboard_type === 'tableau') {
        return (
          <Icon
            src="https://assets.revolut.com/assets/apps/Tableau.webp"
            variant="image"
          />
        )
      }

      return <Icon name="LogoRevolut" />
    },
  }

export const analyticsDashboardRatingColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.text,
    idPoint: 'rating',
    dataPoint: 'rating',
    sortKey: 'rating',
    filterKey: 'rating',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    colors: mapRatingToColor,
    title: 'Rating',
  }

export const analyticsDashboardStatusColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.dashboard_statuses,
    title: 'Status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.status.name}
        variant={getStatusVariant(data.status.id)}
      />
    ),
  }

const getStatusVariant = (
  status: Statuses.active | Statuses.archived,
): ColorTagVariant => {
  switch (status) {
    case Statuses.active:
      return 'success'

    case Statuses.archived:
      return 'warning'

    default:
      return 'neutral'
  }
}
