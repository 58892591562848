import React from 'react'
import {
  ActionButton,
  Avatar,
  HStack,
  Item,
  ItemSkeleton,
  Tag,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useOrganisationStructures } from './api'
import { ErrorItem } from '@src/features/Errors/components/ErrorItem'
import { UploadAvatarPreview } from '@src/components/AvatarUploadPopup/components/UploadAvatarPreview'
import { ORGANISATION_STRUCTURE_DEFAULT_ICON } from '@src/constants/common'

export const StructuresSettingsAll = () => {
  const structures = useOrganisationStructures()

  return (
    <PageWrapper>
      <PageHeader
        title="Company structure"
        subtitle="Define the arrangement of employees within your organization to manage the hierarchy of goals and relationships."
        backUrl={ROUTES.SETTINGS.ALL}
      />
      <PageBody>
        <VStack space="s-16">
          <Item
            use={InternalLink}
            to={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL}
            variant="disclosure"
          >
            <Item.Avatar>
              <Avatar useIcon="Bank" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <HStack space="s-8">
                  <Text>Teams</Text>
                  <MandatoryTag />
                </HStack>
              </Item.Title>
              <Item.Description>
                Define areas of responsibility for deliverables in your business and use
                them to manage goals, talent, and performance reviews.
              </Item.Description>
            </Item.Content>
          </Item>

          <Item
            use={InternalLink}
            to={ROUTES.SETTINGS.STRUCTURES.ROLES.ALL}
            variant="disclosure"
          >
            <Item.Avatar>
              <Avatar useIcon="RepairTool" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <HStack space="s-8">
                  <Text>Roles</Text>
                  <MandatoryTag />
                </HStack>
              </Item.Title>
              <Item.Description>
                Define areas of technical competencies to provide a clear view of
                expectations to use in hiring and performance management.
              </Item.Description>
            </Item.Content>
          </Item>

          {structures.isLoading && <ItemSkeleton />}
          {structures.error && (
            <ErrorItem error={structures.error} forcedTitle="Failed to load structures" />
          )}
          {structures.data?.results.map(structure => (
            <Item
              use={InternalLink}
              to={pathToUrl(ROUTES.SETTINGS.STRUCTURE.PREVIEW, { id: structure.id })}
              variant="disclosure"
              key={structure.id}
            >
              <Item.Avatar>
                <UploadAvatarPreview
                  useIcon={structure.icon}
                  avatar={structure.avatar}
                  defaultIcon={ORGANISATION_STRUCTURE_DEFAULT_ICON}
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{structure.name}</Item.Title>
                <Item.Description>{structure.description}</Item.Description>
              </Item.Content>
            </Item>
          ))}

          <ActionButton
            to={pathToUrl(ROUTES.SETTINGS.STRUCTURE.GENERAL)}
            use={InternalLink}
            useIcon="Plus"
          >
            Add structure
          </ActionButton>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

const MandatoryTag = () => (
  <Tag variant="outlined" color={Token.color.deepGrey}>
    Mandatory
  </Tag>
)
