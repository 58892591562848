import React from 'react'
import { DataPoint, Text } from '@revolut/ui-kit'

interface Props {
  label: React.ReactNode
  value: React.ReactNode
}

export const MetricHighlight = ({ label, value }: Props) => (
  <DataPoint p="s-16">
    <DataPoint.Label textAlign="center">
      <Text fontSize={16}>{label}</Text>
    </DataPoint.Label>
    <DataPoint.Value textAlign="center">{value}</DataPoint.Value>
  </DataPoint>
)
