import React, { useMemo } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { teamNameColumn } from '@src/constants/columns/team'
import {
  recruitmentRoleColumn,
  recruitmentTeamOwner,
} from '@src/constants/columns/recruitmentGroups'
import { RecruitmentGroupsInterface } from '@src/interfaces/recruitmentGroups'
import { recruitmentGroupsRequests } from '@src/api/recruitmentGroups'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const RowNotifications = (
  specialisationsEnabled: boolean,
): RowInterface<RecruitmentGroupsInterface> => ({
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL, { id })),
  cells: [
    {
      ...teamNameColumn,
      width: 382,
    },
    {
      ...recruitmentTeamOwner,
      width: 250,
    },
    {
      ...recruitmentRoleColumn(specialisationsEnabled),
      width: 1000,
    },
  ],
})

const RecruitmentGroupsTable = () => {
  const table = useTable<RecruitmentGroupsInterface>(recruitmentGroupsRequests)
  const permissions = useSelector(selectPermissions)
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const canAdd = permissions.includes(PermissionTypes.AddRecruitmentgroups)
  const row = useMemo(
    () => RowNotifications(specialisationsEnabled),
    [specialisationsEnabled],
  )
  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL)}
              useIcon={Plus}
            >
              Add Recruitment Group
            </PrimaryAction>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS}
            canView={[PermissionTypes.ViewHiringProcessPreferences]}
          />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RecruitmentGroupsInterface>
          name={TableNames.RecruitmentGroups}
          useWindowScroll
          dataType="Recruitment Group"
          row={row}
          {...table}
          emptyState={<EmptyTableRaw title="Recruitment groups will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const RecruitmentGroupsPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Recruitment groups"
        backUrl={pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)}
      />
      <PageBody maxWidth="100%">
        <RecruitmentGroupsTable />
      </PageBody>
    </PageWrapper>
  )
}
