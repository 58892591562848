import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
} from '@src/interfaces/performance'
import React from 'react'
import { Avatar, Item, VStack } from '@revolut/ui-kit'
import { ProgressionSection } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary'
import { PerformanceSection } from '@src/pages/EmployeeProfile/Layout/Performance/PerformanceSection'

export const PerformanceHistorySection = ({
  data,
  canViewProgression,
  onRowClick,
  selectedPeriod,
  isSection = true,
}: {
  data: EmployeeInterface
  canViewProgression?: boolean
  onRowClick?: (rowData: PerformanceTimelineInterface) => void
  selectedPeriod?: PerformanceSelector
  isSection?: boolean
}) => {
  return (
    <VStack>
      {isSection && (
        <Item>
          <Item.Avatar>
            <Avatar useIcon="16/Timeline" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>History</Item.Title>
            <Item.Description>Your data for each review cycle</Item.Description>
          </Item.Content>
        </Item>
      )}
      <VStack pt="s-16">
        {canViewProgression && isSection && (
          <ProgressionSection
            data={data}
            renderHeader={false}
            sticky
            withBorder
            noError
          />
        )}
        <PerformanceSection
          isSection={isSection}
          data={data}
          selectedCycle={selectedPeriod}
          onRowClick={onRowClick}
        />
      </VStack>
    </VStack>
  )
}
