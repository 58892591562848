import React, { useState } from 'react'
import { groupBy } from 'lodash'
import { Flex, useToggle } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { ApiQueryParams } from '@src/api/types'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import {
  EngagementInsightCategory,
  EngagementInsightsTarget,
} from '@src/interfaces/engagement'
import { CreateActionSideBar } from '../ActionPlans/CreateActionSidebar'
import { InsightDetailsSideBar } from './InsightDetailsSideBar'
import { InsightsCategory } from './components/InsightsCategory'
import { InsightsPlaceholder } from './components/InsightsPlaceholder'
import { getLink, InsightScoreVariant, InsightsWidgetStatus } from './helpers'
import { InsightWithScore } from './hooks/useGetInsightsWithScore'

interface Props {
  insightsStatus: InsightsWidgetStatus
  insights: InsightWithScore[]
  insightType: EngagementInsightsTarget
  surveyId: number
  surveyRoundId: number
  categories: EngagementInsightCategory[]
  scoreVariant: InsightScoreVariant
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}

export const TabInsights = ({
  insightsStatus,
  insights,
  insightType,
  surveyId,
  surveyRoundId,
  categories,
  scoreVariant,
  scopeParams,
}: Props) => {
  const [selectedInsight, setSelectedInsight] = useState<InsightWithScore>()
  const [isCreatingAction, isCreatingActionToggle] = useToggle()

  const groupedInsights = groupBy(insights || [], insight => insight.category.id)
  const shouldRedirect = insightType === 'survey'
  const onInsightClick = (insight: InsightWithScore) => {
    if (shouldRedirect) {
      navigateTo(getLink({ insight, surveyId, surveyRoundId, scopeParams }))
      return
    }
    setSelectedInsight(insight)
  }

  if (insightsStatus !== 'completed') {
    return <InsightsPlaceholder status={insightsStatus} category={categories[0]} />
  }

  return (
    <>
      <Flex
        gap="s-16"
        alignItems={{ all: 'center', lg: 'stretch' }}
        flexDirection={{ all: 'column', lg: 'row' }}
      >
        {categories.map((category: EngagementInsightCategory) => (
          <InsightsCategory
            key={category}
            category={category}
            insights={groupedInsights[category]}
            onInsightClick={onInsightClick}
            scoreVariant={scoreVariant}
            insightType={insightType}
          />
        ))}
      </Flex>
      {isCreatingAction ? (
        <CreateActionSideBar
          roundId={surveyRoundId}
          onClose={isCreatingActionToggle.off}
        />
      ) : selectedInsight ? (
        <InsightDetailsSideBar
          insight={selectedInsight}
          onClickCreateAction={isCreatingActionToggle.on}
          onClose={() => setSelectedInsight(undefined)}
        />
      ) : null}
    </>
  )
}
