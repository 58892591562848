import { useGetCompanyPreferences } from '@src/api/settings'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import React from 'react'

import { useQuery } from '@src/utils/queryParamsHooks'
import { isGenericNewGoalPath } from '../helpers'

export const OrgUnitSelector = () => {
  const { values, apiErrors, initialValues } = useLapeContext<GoalsInterface>()
  const { data: settings } = useGetCompanyPreferences()
  const name = settings?.brand_name || 'Company'
  const { query } = useQuery()

  const isChildCreationForm = !initialValues.content_type && initialValues.parent

  const isGenericNewGoal = isGenericNewGoalPath() || isChildCreationForm

  if (values.is_company) {
    return (
      <RadioSelectInput
        inputProps={{
          'aria-invalid': false,
          label: 'Company',
        }}
        value={{ name }}
        disabled
      />
    )
  }

  if (isGenericNewGoal) {
    switch (query.type) {
      case EntityTypes.companyV2:
        return (
          <RadioSelectInput
            inputProps={{
              'aria-invalid': false,
              label: 'Company',
            }}
            value={{ name }}
            disabled
          />
        )
      case EntityTypes.department:
        return (
          <LapeRadioSelectInput
            selector={selectorKeys.department}
            hasError={!!apiErrors.object_id}
            message={apiErrors.object_id}
            value={values.content_object?.id ? values.content_object : undefined}
            onChange={option => {
              if (option) {
                apiErrors.object_id = undefined
                values.content_object = option
                values.object_id = option.id
              }
            }}
            name="content_object"
            label="Department"
            required
          />
        )
      case EntityTypes.team:
      case EntityTypes.teams:
        return (
          <LapeRadioSelectInput
            selector={selectorKeys.team}
            value={values.content_object?.id ? values.content_object : undefined}
            hasError={!!apiErrors.object_id}
            message={apiErrors.object_id}
            onChange={option => {
              if (option) {
                apiErrors.object_id = undefined
                values.content_object = option
              }
            }}
            name="content_object"
            label="Team"
            required
          />
        )
      default:
        return null
    }
  }

  switch (values.content_type?.model) {
    case 'department':
      return (
        <LapeRadioSelectInput
          selector={selectorKeys.department}
          value={values.object_id ? { id: values.object_id } : undefined}
          hasError={!!apiErrors.object_id}
          message={apiErrors.object_id}
          onChange={option => {
            apiErrors.object_id = undefined
            values.object_id = option?.id!
          }}
          name="content_object"
          label="Department"
          required
        />
      )
    case 'teams':
      return (
        <LapeRadioSelectInput
          selector={selectorKeys.team}
          value={values.object_id ? { id: values.object_id } : undefined}
          hasError={!!apiErrors.object_id}
          message={apiErrors.object_id}
          onChange={option => {
            apiErrors.object_id = undefined
            values.object_id = option?.id!
          }}
          name="content_object"
          label="Team"
          required
        />
      )
    default:
      return null
  }
}
