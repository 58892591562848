import { SpecialisationInterface } from '@src/interfaces/roles'
import { SpecialisationStep } from '../constants'
import { notReachable } from '@src/utils/notReachable'

export const checkStepCompleted = (
  step: SpecialisationStep,
  values: SpecialisationInterface,
) => {
  if (!values.id) {
    return false
  }
  switch (step) {
    case 'competency-matrix':
      return Boolean(
        values.performance_weights &&
          values.performance_weights.length > 0 &&
          values.performance_weights.every(({ weight }) => weight !== 0),
      )
    case 'general-info':
      return Boolean(values.id && values.name && values.owner && values.role)
    case 'hiring-process':
      return Boolean(
        values.hiring_process_rounds && values.hiring_process_rounds.length > 0,
      )
    default:
      throw notReachable(step)
  }
}
