import { CellInsertParams } from '@src/interfaces/data'
import { MatrixRowInterface, MatrixTableData } from '../types'

const getParentIndexes = ({
  data,
  parentIndexes,
}: Pick<CellInsertParams<MatrixTableData>, 'data' | 'parentIndexes'>) => {
  return 'parentIndex' in data ? [data.parentIndex, data.childIndex] : parentIndexes
}

export const getCompetencyMatrix = ({
  data,
  parentIndexes,
  competencyMatrices,
}: Pick<CellInsertParams<MatrixTableData>, 'data' | 'parentIndexes'> & {
  competencyMatrices: MatrixRowInterface[]
}) => {
  const indexes = getParentIndexes({ data, parentIndexes })
  const isNested = indexes.length !== 1
  const index = isNested ? indexes[1] : indexes[0]
  const matrixId = isNested ? indexes[0] : 0
  const competencyMatrix = competencyMatrices[matrixId]

  return { index, competencyMatrix, matrixId }
}
