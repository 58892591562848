import { useMemo } from 'react'
import { selectorKeys } from '@src/constants/api'
import { BaseOption } from '@src/interfaces/selectors'
import { useGetSelectors } from '@src/api/selectors'
import { SkillRatingsDistributionGroupTypes } from '@src/features/ReviewCycle/Analytics/api/skills'

interface Props {
  data: BaseOption<string>[]
  isLoading: boolean
}

export const useDistributionOptions = (
  groupType: SkillRatingsDistributionGroupTypes,
): Props => {
  const { data: distributionOptions = [], isLoading: isDistributionOptionsLoading } =
    useGetSelectors<BaseOption<string>>(selectorKeys.skills_distribution_choices)
  const { data: benchmarkOptions = [], isLoading: isBenchmarkOptionsLoading } =
    useGetSelectors<BaseOption<string>>(selectorKeys.skills_benchmark_choices)

  return useMemo<Props>(
    () => ({
      data:
        groupType === SkillRatingsDistributionGroupTypes.Distribution
          ? distributionOptions
          : benchmarkOptions,
      isLoading: isDistributionOptionsLoading || isBenchmarkOptionsLoading,
    }),
    [
      groupType,
      distributionOptions.length,
      benchmarkOptions.length,
      isDistributionOptionsLoading,
      isBenchmarkOptionsLoading,
    ],
  )
}
