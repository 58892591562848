import React, { useMemo } from 'react'
import { Dropdown, HStack, Icon, Text, TextButton, useDropdown } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'

interface Props {
  value: BaseOption<string>
  options: BaseOption<string>[]
  onSelect: (option: BaseOption<string>) => void
}

export const OrgUnitSelector = ({ value, options, onSelect }: Props) => {
  const dropdown = useDropdown()

  const visibleOptions = useMemo(
    () => options.filter(({ id }) => id !== value.id),
    [value.id],
  )

  return (
    <>
      <TextButton {...dropdown.getAnchorProps()}>
        <HStack space="s-4" align="center">
          <Text variant="emphasis1">{value.name}</Text>
          <Icon size={16} name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
        </HStack>
      </TextButton>
      <Dropdown {...dropdown.getTargetProps()} autoClose>
        {visibleOptions.map(option => (
          <Dropdown.Item key={option.id} use="button" onClick={() => onSelect(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}
