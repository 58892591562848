import { Color, IconName, Token } from '@revolut/ui-kit'
import { addDays, differenceInYears } from 'date-fns'

import { EmployeeEventType } from '@src/interfaces/employeeEvents'
import { formatToOrdinal } from '@src/utils/format'

export const employeeEventToColor: Record<EmployeeEventType, Color> = {
  birthday: Token.color.purple,
  anniversary: Token.color.pink,
  time_off: Token.color.orange,
}

export const employeeEventToIcon: Record<EmployeeEventType, IconName> = {
  birthday: 'Cake' as const,
  anniversary: 'Trophy' as const,
  time_off: 'Resort' as const,
}

export const formatAnniversary = (startDate: Date, joiningDate: string) => {
  const diffInYears = differenceInYears(
    /** Adding 1 day to fix the cases when `joining_date_time` has time part (hh:mm:ss) and it would falsely count out one year */
    addDays(startDate, 1),
    new Date(joiningDate),
  )

  if (diffInYears === 0) {
    return `Joined today`
  }

  return `${formatToOrdinal(diffInYears)} anniversary`
}
