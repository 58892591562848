import { BundleType } from '@src/store/auth/types'
import pluralize from 'pluralize'

export const SUBSCRIPTION_CURRENCY = 'GBP'

export const formatSubscriptionFreePeriod = (freeDays: number) =>
  pluralize('day', freeDays, true)

export const getBundleFeatures = (features: BundleType[]) => {
  if (!features.length) {
    return 'Performance'
  }
  return `Performance, ${features.map(feature => featuresMap[feature]).join(', ')}`
}

const featuresMap = {
  hr_bundle_features: 'HR',
  recruitment_bundle_features: 'Recruitment',
}
