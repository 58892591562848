import { Tag } from '@revolut/ui-kit'
import {
  getTrendScoreColor,
  normalizedScoreToColors,
} from '@src/features/Engagement/helpers/statValuesToColor'
import { TrendIndicator } from '@src/features/Engagement/components/TrendIndicator'
import React from 'react'
import { InsightScoreVariant } from '../helpers'

interface Props {
  scoreVariant: InsightScoreVariant
  avgScore: number
  trendAvgScore?: number
}

export const InsightScore = ({ scoreVariant, avgScore, trendAvgScore }: Props) => {
  if (scoreVariant === 'trend' && typeof trendAvgScore === 'number') {
    return (
      <Tag variant="outlined" color={getTrendScoreColor(avgScore - trendAvgScore)}>
        <TrendIndicator colorized avgScore={avgScore} trendAvgScore={trendAvgScore} />
      </Tag>
    )
  }

  return (
    <Tag variant="outlined" color={normalizedScoreToColors(avgScore)?.color}>
      {avgScore.toFixed(1)}
    </Tag>
  )
}
