import React, { useMemo } from 'react'
import { Button, createChain, Header, Popup, Text, Token, VStack } from '@revolut/ui-kit'
import {
  CandidateInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { TranscriptSpeakerName } from '@src/pages/Forms/InterviewFeedback/General/AIInterviewSummary/TranscriptSpeakerName'
import { parseTranscript } from '@src/pages/Forms/InterviewFeedback/General/AIInterviewSummary/parseTranscript'

interface TranscriptPopupProps {
  candidate: CandidateInterface
  interviewer?: InterviewToolInterviewer
  open: boolean
  transcript: string
  onClose: VoidFunction
}

interface TranscriptLine {
  speaker: string
  content: string
}

export const TranscriptPopup = ({
  candidate,
  interviewer,
  open,
  transcript,
  onClose,
}: TranscriptPopupProps) => {
  const { transcriptLines, speakers } = useMemo(() => {
    const parsed = parseTranscript(transcript) ?? []

    const { lines } = parsed.reduce<{
      lines: TranscriptLine[]
      currentSpeaker: string | null
    }>(
      (acc, { type, content }) => {
        if (type === 'speaker') {
          return {
            ...acc,
            currentSpeaker: content,
          }
        }
        if (type === 'caption' && acc.currentSpeaker) {
          return {
            ...acc,
            lines: [...acc.lines, { speaker: acc.currentSpeaker, content }],
          }
        }
        return acc
      },
      { lines: [], currentSpeaker: null },
    )

    const speakerSet = new Set(lines.map(({ speaker }) => speaker))

    return {
      transcriptLines: lines,
      speakers: [...speakerSet.values()],
    }
  }, [transcript])

  return (
    <Popup open={open} size="md" onClose={onClose}>
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Interview transcript</Header.Title>
      </Header>
      <VStack gap="s-24">
        {speakers && (
          <Text color={Token.color.greyTone50}>
            Participants:{' '}
            {createChain(', ')(
              ...speakers.map(speaker => (
                <TranscriptSpeakerName
                  key={speaker}
                  candidate={candidate}
                  interviewer={interviewer}
                  speaker={speaker}
                />
              )),
            )}
          </Text>
        )}
        <VStack gap="s-8">
          {transcriptLines?.map(({ speaker, content }) => (
            <Text key={`${speaker}:${content}`}>
              <TranscriptSpeakerName
                candidate={candidate}
                interviewer={interviewer}
                speaker={speaker}
                speakerLabel={`${speaker}: `}
              />
              <Text color={Token.color.greyTone50}>{content}</Text>
            </Text>
          ))}
        </VStack>
      </VStack>
      <Popup.Actions>
        <Button elevated onClick={onClose}>
          Close
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
