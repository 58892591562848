import { SkillRatingsDistributionFilterKeys } from '@src/features/ReviewCycle/Analytics/api/skills'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import {
  ChartTypeOption,
  chartTypeOptions,
  getChartTypeOption,
} from '@src/features/ReviewCycle/Analytics/Skills/Charts/ChartTypeSelector'
import { useTableReturnType } from '@components/TableV2/hooks'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { BaseOption } from '@src/interfaces/selectors'
import { defaultGroupType } from '@src/features/ReviewCycle/Analytics/Skills/constants'

interface Props {
  teams: FilterProps<FilterOption>
  specialisations: FilterProps<FilterOption>
  group: FilterProps<ChartTypeOption>
}

interface FilterProps<T extends BaseOption> {
  filter: FilterByInterface | undefined
  options: T[]
}

export const useTableFilters = (
  table: useTableReturnType<TalentSkillRatingInterface, undefined>,
): Props => {
  const teamsFilter = table.filterBy.find(
    ({ columnName }) => columnName === SkillRatingsDistributionFilterKeys.TeamId,
  )
  const teamsOptions: FilterOption[] = teamsFilter
    ? teamsFilter.filters.map(({ id, name }) => ({ id, name }))
    : []

  const specialisationsFilter = table.filterBy.find(
    ({ columnName }) =>
      columnName === SkillRatingsDistributionFilterKeys.SpecialisationId,
  )
  const specialisationsOptions: FilterOption[] = specialisationsFilter
    ? specialisationsFilter.filters.map(({ id, name }) => ({ id, name }))
    : []

  const groupFilter = table.filterBy.find(
    ({ columnName }) => columnName === SkillRatingsDistributionFilterKeys.GroupType,
  )
  const groupFilterOption =
    groupFilter?.filters.length === 1
      ? chartTypeOptions.find(({ id }) => id === groupFilter.filters[0].id)
      : undefined
  const groupsOptions: ChartTypeOption =
    groupFilterOption ?? getChartTypeOption(defaultGroupType)

  return {
    teams: {
      filter: teamsFilter,
      options: teamsOptions,
    },
    specialisations: {
      filter: specialisationsFilter,
      options: specialisationsOptions,
    },
    group: {
      filter: groupFilter,
      options: [groupsOptions],
    },
  }
}
