import { useMemo } from 'react'
import { Token } from '@revolut/ui-kit'
import {
  RatingHistoryDataKey,
  RatingHistoryItemData,
  RatingHistoryYAxisTick,
} from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/ChartData'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { useGetSelectors } from '@src/api/selectors'
import { PerformanceRatingOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'

export const RATING_COLOR = Token.colorChannel.blue
export const EXPECTED_RATING_COLOR = Token.color.foreground
const HISTORY_RECORDS_COUNT = 6

interface Props {
  dataKeys: RatingHistoryDataKey[]
  data: RatingHistoryItemData[]
  yAxisTicks: RatingHistoryYAxisTick[]
  isLoading: boolean
}

export const useChartData = (historyData: TalentSkillRatingInterface[]): Props => {
  const { data: ratingCategoryOptions = [], isLoading: isRatingCategoryOptionsLoading } =
    useGetSelectors<PerformanceRatingOption>(selectorKeys.skills_distribution_choices)
  const { data: ratingOptions = [], isLoading: isRatingOptionsLoading } =
    useGetSelectors<PerformanceRatingOption>(selectorKeys.performance_ratings)

  const yAxisTicks = useMemo<RatingHistoryYAxisTick[]>(
    () =>
      ratingOptions
        .filter(({ id }) => id !== '_empty')
        .map(ratingOption => ({
          id: ratingOption.id,
          label: ratingOption.name,
          isVisible: !!ratingCategoryOptions.find(({ id }) => id === ratingOption.id),
        })),
    [ratingOptions, ratingCategoryOptions],
  )

  const dataKeys = useMemo<RatingHistoryDataKey[]>(
    () => [
      {
        id: 0,
        label: 'Skill level',
        value: 'rating',
        color: RATING_COLOR,
      },
      {
        id: 1,
        label: 'Expected level',
        value: 'expected_rating',
        isProjected: true,
      },
    ],
    [],
  )

  const data = useMemo<RatingHistoryItemData[]>(
    () =>
      historyData
        .reduce<RatingHistoryItemData[]>((ratedData, datum, index) => {
          const { rating, expected_rating, cycle } = datum

          const ratingSortedIndex = yAxisTicks.findIndex(({ id }) => id === rating)
          const expectedRatingCategorySortedIndex = yAxisTicks.findIndex(
            ({ id }) => id === expected_rating,
          )

          if (
            rating === null ||
            ratingSortedIndex === -1 ||
            expectedRatingCategorySortedIndex === -1
          ) {
            return ratedData
          }

          const itemData: RatingHistoryItemData = {
            id: rating,
            rating: ratingSortedIndex,
            expected_rating: expectedRatingCategorySortedIndex,
            label: cycle.name,
            index,
            rawData: datum,
          }

          return [...ratedData, itemData]
        }, [])
        .slice(-HISTORY_RECORDS_COUNT, historyData.length),
    [historyData, yAxisTicks],
  )

  return useMemo<Props>(
    () => ({
      dataKeys,
      data,
      yAxisTicks,
      isLoading: isRatingOptionsLoading || isRatingCategoryOptionsLoading,
    }),
    [dataKeys, data, yAxisTicks, isRatingOptionsLoading, isRatingCategoryOptionsLoading],
  )
}
