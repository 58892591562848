import React from 'react'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import { ROUTES } from '@src/constants/routes'
import { useGetHiringPipeline } from '@src/api/recruitment/hiringPipelines'

export const ShareCandidatesAccessAction = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { hiringPipelineId } = useCandidatePipelineData(values.id)
  const { data } = useGetHiringPipeline(hiringPipelineId)

  if (!hiringPipelineId) {
    return null
  }

  return (
    <PermissionTransferButton
      values={data}
      path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.POSTING}
    />
  )
}
