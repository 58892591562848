import { apiV2 } from '@src/api/index'

import { API } from '@src/constants/api'
import { ExportRequest, GetRequestInterface } from '@src/interfaces'
import { FilterSortPageIntoQueryInterface } from '@src/interfaces/data'
import { PipAndProbationChangelogItem } from '@src/interfaces/pipAndProbation'
import { useFetchV2 } from '@src/utils/reactQuery'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getProbationItemsExport: ExportRequest = (exportType, filterQuery) =>
  apiV2.get(
    `${API.PROBATION}/${exportType}`,
    { params: filterQuery, responseType: 'blob' },
    'v2',
  )

export const useProbationChangelog = (
  probationId: number | string,
  options?: FilterSortPageIntoQueryInterface,
) =>
  useFetchV2<GetRequestInterface<PipAndProbationChangelogItem>>({
    url: `/probation/${getReviewCycleIdWithoutPrefix(probationId)}/changelog`,
    version: 'v2',
    params: {
      params: filterSortPageIntoQuery(options?.sortBy, options?.filters, options?.page),
    },
  })
