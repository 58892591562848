import { InputGroup, Side, Subheader } from '@revolut/ui-kit'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PipCycleSettingsInterface,
  ProbationCycleSettingsInterface,
} from '@src/interfaces/reviewCycles'
import React from 'react'

interface Props {
  variant: 'PIP' | 'Probation'
  placement: 'page' | 'sidebar'
  onAfterSubmit?: VoidFunction
}

export const TimelineFormBody = ({ variant, placement, onAfterSubmit }: Props) => {
  const { values } = useLapeContext<
    ProbationCycleSettingsInterface | PipCycleSettingsInterface
  >()
  const performanceCheckpoints = values.performance_checkpoints?.filter(checkpoint =>
    ['review', 'decision'].includes(checkpoint.checkpoint_type),
  )

  const goalSettingCheckpoints = values.performance_checkpoints.filter(
    checkpoint => checkpoint.checkpoint_type === 'goals',
  )

  const ActionWrapper = placement === 'sidebar' ? Side.Actions : PageActions

  return (
    <>
      <Subheader variant="default">General</Subheader>
      <InputGroup>
        <LapeDatePickerInput
          name="start_date_time"
          label={`${variant} Start Date`}
          required
        />
        <LapeDatePickerInput
          name="end_date_time"
          label={`${variant} End Date`}
          required
        />
        <LapeRadioSelectInput
          label={`${variant} Manager`}
          name="hr_manager"
          selector={
            variant === 'PIP'
              ? selectorKeys.pip_hr_managers
              : selectorKeys.probation_hr_managers
          }
        />
      </InputGroup>
      {!!goalSettingCheckpoints.length && (
        <>
          <Subheader variant="default">Goals setting</Subheader>
          <InputGroup>
            {goalSettingCheckpoints?.map(checkpoint => {
              const index = values.performance_checkpoints.findIndex(
                item => item.id === checkpoint.id,
              )
              return (
                <LapeDatePickerInput
                  key={checkpoint.id}
                  name={`performance_checkpoints[${index}].checkpoint_date_time`}
                  label="Goal setting end date"
                  required
                />
              )
            })}
          </InputGroup>
        </>
      )}
      <Subheader variant="default">Checkpoints</Subheader>
      <InputGroup>
        {performanceCheckpoints?.map(checkpoint => {
          const index = values.performance_checkpoints.findIndex(
            item => item.id === checkpoint.id,
          )
          return (
            <LapeDatePickerInput
              key={checkpoint.id}
              name={`performance_checkpoints[${index}].checkpoint_date_time`}
              label={
                checkpoint.checkpoint_type === 'decision'
                  ? 'Committee decision'
                  : `Checkpoint ${checkpoint.number} End date`
              }
              required
            />
          )
        })}
      </InputGroup>

      <ActionWrapper horizontal>
        <NewSaveButtonWithPopup
          successText="Settings successfully updated"
          onAfterSubmit={onAfterSubmit}
          useValidator
          isExistingData
        >
          Save
        </NewSaveButtonWithPopup>
      </ActionWrapper>
    </>
  )
}
