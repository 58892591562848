import React from 'react'
import { Avatar, IconName, Item, chain, Text, Tag, VStack } from '@revolut/ui-kit'
import {
  changelogCreated,
  changelogReviewCreated,
  changelogStarted,
  PipAndProbationChangelogItem,
} from '@src/interfaces/pipAndProbation'
import { formatDate } from '@src/utils/format'
import { getProbationResultSettings } from '../Probation/utils'
import { get } from 'lodash'
import { getStatusColor } from '@src/components/CommonSC/General'
import { formatSnakeCase } from '@src/utils/string'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'

type Config = Record<
  PipAndProbationChangelogItem['change_location'],
  { title: string; icon: IconName }
>

export const baseChangelogItemConfig: Omit<Config, ''> = {
  start_date_time: { title: 'Start Date Time', icon: 'CalendarDate' },
  end_date_time: { title: 'End Date Time', icon: 'CalendarDate' },
  goals_updated: { title: 'Updated goals', icon: 'Flag' },
  goals_approved: { title: 'Confirmed goals', icon: 'Check' },
  decision_created: { title: 'Decision Created', icon: 'Plus' },
  decision_updated: { title: 'Decision Updated', icon: 'Plus' },
  review_created: { title: 'Review Created', icon: 'Plus' },
  hr_manager: { title: 'HR Manager', icon: 'Profile' },
}

export const probationChangelogItemConfig: Config = {
  ...baseChangelogItemConfig,
  '': { title: 'Started Probation', icon: 'Wealth' },
}
export const pipChangelogItemConfig: Config = {
  ...baseChangelogItemConfig,
  '': { title: 'Started PIP', icon: 'Wealth' },
}

const getTitle = (changelog: PipAndProbationChangelogItem, config: Config) =>
  config[changelog.change_location].title

const getIcon = (changelog: PipAndProbationChangelogItem, config: Config) =>
  config[changelog.change_location].icon

const getValue = (changelog: PipAndProbationChangelogItem) => {
  if (changelogStarted(changelog)) {
    return (
      <VStack align="end">
        <EmployeeUserWithAvatar id={changelog.change.after.hr_manager.id} />
        <Text>{`${formatDate(changelog.change.after.start_date_time)} - ${formatDate(
          changelog.change.after.end_date_time,
        )}`}</Text>
      </VStack>
    )
  }

  if (changelogReviewCreated(changelog)) {
    return null
  }

  if (changelog.change_location === 'goals_updated') {
    return `${get(changelog, 'change.before.name', 'N/A')} → ${get(
      changelog,
      'change.after.name',
      'N/A',
    )}`
  }

  if (changelog.change_location === 'goals_approved') {
    return (
      <Tag color={getStatusColor(changelog.change.after)}>
        {formatSnakeCase(changelog.change.after)}
      </Tag>
    )
  }

  if (
    changelog.change_location === 'start_date_time' ||
    changelog.change_location === 'end_date_time'
  ) {
    return changelogCreated(changelog)
      ? formatDate(changelog.change.after)
      : `${formatDate(changelog.change.before)} → ${formatDate(changelog.change.after)}`
  }

  if (
    changelog.change_location === 'decision_created' ||
    changelog.change_location === 'decision_updated'
  ) {
    const settings = getProbationResultSettings(changelog.change.after.result)
    return <Text color={settings.color}>{settings.title}</Text>
  }

  return null
}

const getDescription = (changelog: PipAndProbationChangelogItem) =>
  chain(
    changelog.author ? `By ${changelog.author.full_name}` : undefined,
    formatDate(changelog.change_time),
  )

export const renderChangeLogItem =
  (config: Config) => (changelog: PipAndProbationChangelogItem) =>
    (
      <Item key={changelog.id} use="button">
        <Item.Avatar>
          <Avatar useIcon={getIcon(changelog, config)} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{getTitle(changelog, config)}</Item.Title>
          <Item.Description>{getDescription(changelog)}</Item.Description>
        </Item.Content>
        <Item.Side>{getValue(changelog)}</Item.Side>
      </Item>
    )
