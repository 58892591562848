import { matchPath } from 'react-router-dom'
import { history } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { captureException } from '@sentry/react'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { omit, pick } from 'lodash'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { DeepPartial } from 'redux'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'

export const isGenericNewGoalPath = () =>
  !!matchPath(history.location.pathname, [
    ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.FORM,
    ROUTES.FORMS.GOAL.CREATE.FORM,
  ])

export const getCache = <T>(key: string): Partial<T> | null => {
  try {
    const rawCache = workspaceLocalStorage.getItem(key)
    return rawCache ? JSON.parse(rawCache) : null
  } catch (err) {
    captureException(err)
    return null
  }
}

export const setCache = <T>(key: string, value: Partial<T>): void => {
  try {
    workspaceLocalStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    captureException(err)
  }
}

export const removeMetricIrrelvantDataForType = <
  T extends GoalKpiDetails | (KpiInterface & { tempId?: number }),
>(
  values: T,
) => {
  let newValues: T
  switch (values.update_type) {
    case UpdateTypes.sql:
      newValues = omit(
        values,
        'target_epics',
        'look_url',
        'counted_sql_column',
        'counted_sql_date_column',
        'salesforce_report',
      ) as T // to satisfy forms
      break
    case UpdateTypes.linear:
    case UpdateTypes.notion:
    case UpdateTypes.monday:
    case UpdateTypes.roadmap:
    case UpdateTypes.clickup:
      newValues = omit(
        values,
        'sql_query',
        'sql_query_db',
        'connection',
        'look_url',
        'counted_sql_column',
        'counted_sql_date_column',
        'salesforce_report',
      ) as T // to satisfy forms
      break
    case UpdateTypes.manual:
      newValues = omit(
        values,
        'look_url',
        'sql_query',
        'sql_query_db',
        'connection',
        'target_epics',
        'counted_sql_column',
        'counted_sql_date_column',
        'salesforce_report',
      ) as T // to satisfy forms
      break
    case UpdateTypes.looker:
      newValues = omit(
        values,
        'sql_query',
        'sql_query_db',
        'target_epics',
        'connection',
        'salesforce_report',
      ) as T // to satisfy forms
      break
    case UpdateTypes.tableau:
      newValues = omit(
        values,
        'counted_sql_date_column',
        'sql_query',
        'sql_query_db',
        'connection',
        'target_epics',
        'salesforce_report',
      ) as T // to satisfy forms
      break
    case UpdateTypes.salesforce:
      newValues = omit(
        values,
        'sql_query',
        'sql_query_db',
        'target_epics',
        'connection',
        'look_url',
      ) as T // to satisfy forms
      break
    default:
      newValues = values
  }

  return newValues
}

export const getMetricRelevantFormData = (metric: DeepPartial<GoalKpiDetails>) =>
  pick(
    metric,
    'id',
    'tempId',
    'name',
    'targets',
    'target_epics',
    'goal',
    'owner',
    'is_company',
    'department',
    'team',
    'look_url',
    'sql_query',
    'sql_query_db',
    'connection',
    'unit',
    'is_employee',
    'employee',
    'update_type',
    'current_progress',
    'means_of_measure',
    'todo_items',
    'counted_sql_column',
    'counted_sql_date_column',
    'salesforce_report',
  )

export const getGoalContentTypeLabel = (goal: GoalsInterface): string => {
  if (!goal.content_type) {
    return 'company'
  }

  switch (goal.content_type.model) {
    case 'department':
      return 'department'
    case 'teams':
      return 'team'
    case 'employees':
      return 'employee'
    default:
      return 'company'
  }
}
