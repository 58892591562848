import { Box, Text, Token, Flex } from '@revolut/ui-kit'
import React from 'react'
import { InterviewRoundEvaluationItem } from '@src/interfaces/interviewTool'
import { CVSkillsListItem } from '@src/features/CVInsightsWidget/components/CVSkillsListItem'

interface CVSkillsListProps {
  evaluations: InterviewRoundEvaluationItem[]
}

export const CVSkillsList = ({ evaluations }: CVSkillsListProps) => {
  const list = evaluations.filter(evaluation => evaluation.trait_type === 'SKILL')

  if (list.length === 0) {
    return null
  }

  return (
    <Box px="s-16">
      <Text use="div" variant="emphasis1">
        Skills
      </Text>
      <Text use="div" variant="body2" pb="s-8" color={Token.color.greyTone50}>
        CV has evidence of required skills?
      </Text>
      <Flex rowGap="s-8" columnGap="s-4" flexWrap="wrap">
        {list.map(evaluation => (
          <CVSkillsListItem evaluation={evaluation} key={evaluation.id} />
        ))}
      </Flex>
    </Box>
  )
}
