import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon, MoreBar } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectUser } from '@src/store/auth/selectors'
import { DocumentRequestType } from '@src/pages/Forms/DocumentRequest/common'
import { hasDocumentManagerPermissions } from '@src/pages/EmployeeProfile/Layout/Documents/common'
import useIsCommercial from '@src/hooks/useIsCommercial'

type Props = {
  data: EmployeeInterface
}
export const RequestDocumentButton = ({ data }: Props) => {
  const user = useSelector(selectUser)
  const isCurrentUser = user.id === data.id

  const isCommercial = useIsCommercial()

  const [isRequestOptionsExpanded, setIsRequestOptionsExpanded] = useState(false)

  const onClickRequestTypeOption = (requestType: DocumentRequestType) =>
    navigateTo(
      pathToUrl(
        ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL,
        { employeeId: data.id },
        requestType ? { requestType } : undefined,
      ),
      {
        initialValues: {
          employee: { id: data.id, name: data.full_name },
          issuer: { id: user.id, name: user.full_name },
        },
      },
    )

  if (!isCurrentUser && !hasDocumentManagerPermissions(data)) {
    return null
  }
  return hasDocumentManagerPermissions(data) ? (
    <>
      <MoreBar.Action
        useIcon={isRequestOptionsExpanded ? 'ChevronUp' : 'ChevronDown'}
        onClick={e => {
          e.preventDefault()
          setIsRequestOptionsExpanded(!isRequestOptionsExpanded)
        }}
      >
        Request
      </MoreBar.Action>
      {isRequestOptionsExpanded && (
        <>
          {[
            {
              icon: 'Document' as const,
              title: 'Manually',
              type: 'manual' as const,
            },
            !isCommercial
              ? {
                  icon: 'Lightning' as const,
                  title: 'By template rule',
                  type: 'byTemplateRule' as const,
                }
              : null,
            {
              icon: 'DocumentsPair' as const,
              title: 'Collection',
              type: 'byCollection' as const,
            },
            isCurrentUser
              ? {
                  icon: 'Envelope' as const,
                  title: 'Self serve',
                  type: 'selfServe' as const,
                }
              : null,
          ]
            .filter(Boolean)
            .map(({ icon, title, type }) => (
              <MoreBar.Action
                key={title}
                useIcon={<Icon size={15} ml="s-24" name={icon} />}
                onClick={() => onClickRequestTypeOption(type)}
              >
                {title}
              </MoreBar.Action>
            ))}
        </>
      )}
    </>
  ) : (
    <MoreBar.Action
      useIcon="SendMessage"
      onClick={() => onClickRequestTypeOption('selfServe')}
    >
      Request
    </MoreBar.Action>
  )
}
