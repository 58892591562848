import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Flex,
  HStack,
  Icon,
  Subheader,
  Text,
  Token,
  useTooltip,
  Widget,
} from '@revolut/ui-kit'
import { useGetCalibrationStats } from '@src/api/reviewCycles'
import { PerformanceScorePieChart } from '@src/features/ReviewCycle/Analytics/Charts/PerformanceScorePieChart/PieChart'
import { PerformanceScoreItemDataKeys } from '@src/features/ReviewCycle/Analytics/Charts/PerformanceScorePieChart/Items'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import { NipsCalculationTooltip } from '@src/features/ReviewCycle/components/Nips/NipsCalculationTooltip'

interface Props {
  cycleId: number | string
  gradesOptions: PerformanceGradeOption[]
  height: number
}

export const PerformanceScore = ({ cycleId, gradesOptions, height }: Props) => {
  const { data: stats, isLoading } = useGetCalibrationStats(cycleId)

  const nipsTooltip = useTooltip()

  const nips = Number(stats?.nips ?? 0)
  const nipsPieChartData = useMemo(
    () => [
      {
        name: PerformanceScoreItemDataKeys.NIPS,
        value: Math.abs(nips),
        color: Token.colorChannel.blue,
      },
      {
        name: PerformanceScoreItemDataKeys.ALL,
        value: 1 - Math.abs(nips),
        color: Token.colorChannel.grey20,
      },
    ],
    [nips],
  )

  return (
    <Flex alignItems="stretch" minHeight={height} width="100%">
      <Widget flex={1} width="100%">
        <Flex
          alignItems="stretch"
          flexDirection="column"
          px="s-16"
          pb="s-16"
          height="100%"
        >
          <Subheader>
            <Subheader.Title>
              <HStack space="s-8" align="center">
                <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
                  <FormattedMessage defaultMessage="Performance" id="performance.label" />
                </Text>
                <Icon
                  name="InfoOutline"
                  size={16}
                  {...nipsTooltip.getAnchorProps<HTMLSpanElement>()}
                />
              </HStack>
              <NipsCalculationTooltip
                grades={gradesOptions}
                placement="right"
                maxWidth={300}
                {...nipsTooltip.getTargetProps<HTMLSpanElement>()}
              />
            </Subheader.Title>
          </Subheader>
          <Box width="100%" height="100%" mt="-s-16">
            <PerformanceScorePieChart
              total={nips}
              data={nipsPieChartData}
              label="NIPS"
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Widget>
    </Flex>
  )
}
