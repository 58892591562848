import React from 'react'
import { useGlobalSettings } from '@src/api/settings'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { jobsSettingsLinkConfig } from '../Jobs/config'
import { getSettingsLinkConfig } from '../common/config'

export const JobPostingList = () => {
  const { settings } = useGlobalSettings()
  const featureFlags = useSelector(selectFeatureFlags)

  const permissions = useSelector(selectPermissions)
  const config = getSettingsLinkConfig({
    config: jobsSettingsLinkConfig,
    permissions,
    featureFlags,
    globalSettings: settings,
    type: 'settings',
  })

  return (
    <Group mb="s-24">
      {config.jobsJobPostingSettings?.component}
      {config.jobsApplicationForms?.component}
      {config.jobsCareersWebsiteSettings?.component}
      {config.jobsApprovalSettings?.component}
    </Group>
  )
}
