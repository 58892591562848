import React from 'react'
import { ROUTES } from '@src/constants/routes'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { PageWrapper } from '@components/Page/Page'
import {
  HStack,
  Icon,
  MoreBar,
  Text,
  Token,
  useTooltip,
  VStack,
  Link,
  Flex,
  Tooltip,
  useToggle,
} from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import HeadCard from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/HeadCard'
import InterviewFeedbackForm from '@src/features/InterviewFeedbackForm'
import { pathToUrl } from '@src/utils/router'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import RejectFeedbackButton from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/RejectFeedbackButton'
import { navigateTo, useGetPathWithWorkspace } from '@src/actions/RouterActions'
import { PageActions } from '@components/Page/PageActions'
import SaveToDraftButton from '@src/pages/Forms/InterviewFeedback/General/SaveToDraftButton'
import SubmitButton from '@src/pages/Forms/InterviewFeedback/General/SubmitButton'
import useAutoSavingDraft from '@src/hooks/useAutoSavingDraft'
import { interviewFeedbackFormRequests } from '@src/api/recruitment/interviewFeedback'
import { Warning } from '@revolut/icons'
import usePreventUserClose from '@src/hooks/usePreventUserClose'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { InterviewFeedbackSide } from '@src/pages/Forms/InterviewFeedback/General/InterviewFeedbackSide'
import { PageBody } from '@components/Page/PageBody'

export const GeneralV2 = () => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const round = values.interview_stage?.interview_round
  const candidate = round.candidate
  const { lastSavedMessage, autosaveFailed, saving, hasChanges } =
    useAutoSavingDraft<InterviewFeedbackInterface>(
      interviewFeedbackFormRequests.update,
      values.interviewer?.id !== user.id,
    )
  const [showCandidateDetails, toggleCandidateDetails] = useToggle({ defaultState: true })
  const getPathWithWorkspace = useGetPathWithWorkspace()

  usePreventUserClose(saving || hasChanges())
  useSetDocumentTitle(
    `${candidate?.full_name}, ${values.interview_stage.title}`,
    'Interview feedback',
  )

  const tooltipProps = useTooltip()

  const canViewCandidate = values?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidate,
  )
  const backUrl = canViewCandidate
    ? pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
        id: candidate.id,
      })
    : pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)

  const canEdit = values?.field_options?.permissions?.includes(
    PermissionTypes.ChangeInterviewFeedback,
  )

  const canCancel = !!values.field_options?.actions?.includes(EntityPermissions.Cancel)

  const candidateName = (
    <HStack gap="s-4" align="center">
      <Icon name="Profile" size={16} /> <Text>{candidate?.full_name}</Text>
    </HStack>
  )

  const specialisation = (
    <Link
      use="a"
      href={getPathWithWorkspace(
        pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id: round.specialisation.id }),
      )}
      target="_blank"
      color="inherit"
    >
      {round.specialisation.name}
    </Link>
  )

  return (
    <PageWrapper>
      <PageHeader
        title={
          <PageHeader.Title
            maxWidth={{ all: Token.breakpoint.md }}
            title={values.interview_stage.title}
            labels={
              <VStack gap="s-4">
                <Text variant="emphasis1">
                  {canViewCandidate ? (
                    <Link
                      use="a"
                      href={getPathWithWorkspace(
                        pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidate.id }),
                      )}
                      target="_blank"
                      color={Token.color.accent}
                    >
                      {candidateName}
                    </Link>
                  ) : (
                    candidateName
                  )}
                </Text>
                <Text color={Token.color.greyTone50} variant="body2">
                  {round.seniority ? (
                    <>
                      {round.seniority.name} {specialisation}
                    </>
                  ) : (
                    specialisation
                  )}
                </Text>
                <Text color={Token.color.greyTone50} variant="body2">
                  <Flex gap="s-4" alignItems="center">
                    <div>{lastSavedMessage}</div>
                    {autosaveFailed && (
                      <>
                        <Warning
                          color={Token.color.warning}
                          {...tooltipProps.getAnchorProps()}
                        />
                        <Tooltip {...tooltipProps.getTargetProps()}>
                          Auto saving failed, please save the feedback manually
                        </Tooltip>
                      </>
                    )}
                  </Flex>
                </Text>
              </VStack>
            }
            actions={
              <MoreBar>
                <MoreBar.Action useIcon="Profile" onClick={toggleCandidateDetails.switch}>
                  Candidate details
                </MoreBar.Action>
                {values.playbook_link && (
                  <MoreBar.Action
                    use="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={values.playbook_link}
                    useIcon="InfoOutline"
                  >
                    Interview playbook
                  </MoreBar.Action>
                )}
                {canCancel &&
                  (values.status === InterviewFeedbackStatus.pending ||
                    values.status === InterviewFeedbackStatus.draft) && (
                    <RejectFeedbackButton
                      id={values.id}
                      onRefresh={() => navigateTo(backUrl)}
                      menuType="negative"
                    />
                  )}
              </MoreBar>
            }
          />
        }
        backUrl={backUrl}
      />
      <PageBody>
        <VStack gap="s-24">
          <HeadCard data={values?.scorecard.interview_objective} />
          <InterviewFeedbackForm
            isViewMode={!canEdit}
            round={round}
            stageType={values.interview_type}
          />
        </VStack>
      </PageBody>
      <InterviewFeedbackSide
        candidate={candidate}
        interviewer={values.interviewer}
        interviewFeedbackId={values.id}
        canViewCandidate={
          !!values?.field_options?.permissions?.includes(PermissionTypes.ViewCandidate)
        }
        round={round}
        open={showCandidateDetails}
        onClose={toggleCandidateDetails.off}
      />
      <PageActions>
        <SaveToDraftButton />
        <SubmitButton afterSubmitUrl={backUrl} />
      </PageActions>
    </PageWrapper>
  )
}
