import React from 'react'
import {
  Absolute,
  ActionButton,
  Flex,
  Icon,
  Item,
  Relative,
  Token,
} from '@revolut/ui-kit'
import {
  AvailabilityStatus,
  useExtensionAvailability,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/hooks/useExtensionAvailability'
import { useExtensionData } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useExtensionData'

export const OneToOneBanner = () => {
  const { isChecking, status } = useExtensionAvailability()
  const { extensionLink } = useExtensionData()
  if (isChecking || status === AvailabilityStatus.Installed) {
    return null
  }
  return (
    <Item style={{ backgroundColor: Token.color.actionBackground }}>
      <Item.Avatar>
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <Relative width={48} height={48}>
            <Absolute top={0} left={0} width="100%" height="100%">
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Icon name="Message" color={Token.color.white} size={40} />
              </Flex>
            </Absolute>
            <Absolute top={-2} left={0} width="100%" height="100%">
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Icon name="LogoRevolut" color={Token.color.black} size={24} />
              </Flex>
            </Absolute>
          </Relative>
        </Flex>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>Streamline your 1:1 meetings and performance reviews</Item.Title>
        <Item.Description>
          Install our Chrome extension to streamline note-taking and prepopulate strengths
          and weaknesses effortlessly.
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <ActionButton
          use="a"
          target="_blank"
          rel="noreferrer noopener"
          useIcon="Download"
          href={extensionLink}
        >
          Install Chrome extension
        </ActionButton>
      </Item.Side>
    </Item>
  )
}
