import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const useCanViewGrowthPlans = (user: EmployeeInterface) => {
  return user.field_options.permissions?.includes(PermissionTypes.CanViewGrowthPlans)
}

export const useCanCreateGrowthPlans = (user: EmployeeInterface) => {
  return user.field_options.permissions?.includes(PermissionTypes.CanCreateGrowthPlans)
}

export const useCanChangeGrowthPlan = (growthPlan: GrowthPlanInterface) =>
  growthPlan.field_options?.permissions?.includes(PermissionTypes.CanChangeGrowthPlan)

export const useCanApproveGrowthPlan = (growthPlan: GrowthPlanInterface) =>
  growthPlan.field_options?.permissions?.includes(PermissionTypes.CanApproveGrowthPlan)

export const useCanRejectGrowthPlan = (growthPlan: GrowthPlanInterface) =>
  growthPlan.field_options?.permissions?.includes(PermissionTypes.CanRejectGrowthPlan)

export const useCanDeleteGrowthPlan = (growthPlan: GrowthPlanInterface) =>
  growthPlan.field_options?.permissions?.includes(PermissionTypes.CanDeleteGrowthPlan)

export const useCanMarkGrowthPlanActions = (growthPlan: GrowthPlanInterface) =>
  growthPlan.field_options?.permissions?.includes(
    PermissionTypes.CanMarkActionsOnGrowthPlan,
  )

export const useCanAccessGrowthPlanComments = (growthPlan: GrowthPlanInterface) =>
  growthPlan.field_options?.permissions?.includes(
    PermissionTypes.CanAccessCommentsForGrowthPlan,
  )

export const useCanManageGrowthPlans = () => {
  return useHasGlobalPermission(PermissionTypes.PerformanceTeam)
}
