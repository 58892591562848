import { api, apiV2, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { FileInterface } from '@src/interfaces/files'
import { saveFile } from '@src/utils'

export const uploadFile = (
  file: File,
  category?: string,
  isPrivate?: boolean,
  apiHandler = apiWithoutHandling,
  fileNameOverride?: string,
  apiEndpoint = API.FILES,
) => {
  const fileFormData = new FormData()
  fileFormData.append('file', file, fileNameOverride || file.name)
  if (category) {
    fileFormData.append('category', category)
  }
  if (isPrivate) {
    fileFormData.append('access_level', 'private')
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  if (apiHandler) {
    return apiHandler.post<FileInterface>(apiEndpoint, fileFormData, {
      headers,
    })
  }

  return apiWithoutHandling.post<FileInterface>(apiEndpoint, fileFormData, {
    headers: {
      ...headers,
    },
  })
}

export const downloadFile = async (id: number) => {
  const res = await api.get<Blob>(
    `${API.FILES}/${id}/download`,
    {
      responseType: 'blob',
    },
    undefined,
    true,
  )
  return URL.createObjectURL(res.data)
}

export const getFilePreviewUrl = async (id: number | string) => {
  const response = await apiV2.get<{ url: string }>(
    `${API.FILES}/${id}/previewUrl`,
    undefined,
    undefined,
    true,
  )

  return response.data.url
}

const getFileDownloadUrl = async (id: number | string) => {
  const response = await apiV2.get<{ url: string }>(
    `${API.FILES}/${id}/downloadUrl`,
    undefined,
    undefined,
    true,
  )

  return response.data.url
}

export const downloadUploadedFile = async (id: number | string, fileName: string) => {
  const downloadUrl = await getFileDownloadUrl(id)

  return saveFile(downloadUrl, fileName)
}

export const getFileMetadata = (id: number) => {
  return api.get<FileInterface>(`${API.FILES}/${id}`, undefined, undefined, true)
}

export const deleteFile = (id: number) =>
  api.delete(`${API.FILES}/${id}`, undefined, undefined, true)
