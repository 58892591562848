import React from 'react'

import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'
import { PayCyclesTableV1 } from './PayCyclesTableV1'
import { PayCyclesTableV2 } from './PayCyclesTableV2'

export const PayCyclesTable = () => {
  const isPayrollV2 = useHasFeatureFlag(FeatureFlags.PayrollV2)

  if (isPayrollV2) {
    return <PayCyclesTableV2 />
  }
  return <PayCyclesTableV1 />
}
