import React from 'react'
import { Avatar, Item, Group, Flex, Text, Copyable } from '@revolut/ui-kit'

import { DataHandlerInterface, FormPreview } from '@components/FormPreview/FormPreview'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { useGetEmployeeSettings, useGetOrganisationSettings } from '@src/api/settings'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { onCopyFieldNotification } from '@src/pages/EmployeeProfile/Layout/common/helpers'
import { WorkDetailsSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/WorkDetails/Sidebar'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import {
  isLoadingSectionData,
  useActiveProfileSidebar,
} from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getRoleSpecialisationTitle } from '@src/features/Roles/helpers'

type Props = {
  data: InternalOrExternalEmployee
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  dynamicGroups: DynamicGroupIDs
  sectionsData: ProfileSectionsData
}
export const WorkDetails = ({
  data,
  primaryDataHandler,
  dynamicGroups,
  sectionsData,
}: Props) => {
  const { setSidebarQueryParam } = useActiveProfileSidebar()

  const employeeId = data.id
  const isExternal = data.employee_type === 'external'
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const { data: orgSettings } = useGetOrganisationSettings()

  const { data: settings } = useGetEmployeeSettings()
  const loading = isLoadingSectionData(primaryDataHandler)

  return (
    <>
      <Group>
        <Item
          use="button"
          onClick={() => setSidebarQueryParam('workDetails', true)}
          aria-label="Open work details sidebar"
          variant="disclosure"
        >
          <Item.Avatar>
            <Avatar useIcon="Services" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Work details</Item.Title>
            <Item.Description>Name, Position, Organisation, Notes</Item.Description>
          </Item.Content>
        </Item>
        <FormPreview data={data}>
          <FormPreview.Item<Partial<InternalOrExternalEmployee>>
            title="Work email"
            field="email"
            insert={d =>
              d.email ? (
                <Copyable
                  value={d.email}
                  labelButtonCopy="Copy employee email"
                  onClick={() => onCopyFieldNotification('Email')}
                >
                  {d.email}
                </Copyable>
              ) : (
                '-'
              )
            }
          />
          {!!settings?.enable_show_employee_id && (
            <FormPreview.Item
              title="Employee ID"
              insert={() =>
                employeeId ? (
                  <Copyable
                    value={String(employeeId)}
                    labelButtonCopy="Copy employee ID"
                    onClick={() => onCopyFieldNotification('Employee ID')}
                  >
                    {employeeId}
                  </Copyable>
                ) : (
                  '-'
                )
              }
              loading={loading}
            />
          )}
          <FormPreview.Item<Partial<InternalOrExternalEmployee>>
            title={getRoleSpecialisationTitle(specialisationsEnabled)}
            field="specialisation.name"
            to={position =>
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                id: position.specialisation?.id,
              })
            }
            loading={loading}
          />
          <FormPreview.Item<EmployeeInterface>
            hidden={isExternal}
            title="Seniority"
            insert={employee => (
              <Flex>
                <Text>{employee.seniority?.name || '-'}</Text>
                {!!orgSettings?.enable_multiple_levels_per_seniority &&
                  employee.specialisation_seniority_sublevel && (
                    <Text pl="s-6">
                      ({employee.specialisation_seniority_sublevel.name})
                    </Text>
                  )}
              </Flex>
            )}
            loading={loading}
          />
          <FormPreview.Item<Partial<InternalOrExternalEmployee>>
            title="Location"
            field="location.name"
            loading={loading}
          />
          <FormPreview.Item<Partial<InternalOrExternalEmployee>>
            title="Team"
            field="team.name"
            to={organisation =>
              pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: organisation?.team?.id })
            }
            loading={loading}
          />
          <FormPreview.Item<Partial<InternalOrExternalEmployee>>
            title={isExternal ? 'Point of contact 1' : 'Line manager'}
            field="line_manager.name"
            insert={d =>
              d.line_manager?.id ? (
                <EmployeeUserWithAvatar
                  id={d.line_manager.id}
                  full_name={d.line_manager.display_name}
                />
              ) : (
                '-'
              )
            }
            loading={loading}
          />
          <FormPreview.Item<Partial<EmployeeInterface>>
            hidden={isExternal}
            title="Functional manager"
            field="quality_control.name"
            insert={d =>
              d.quality_control?.id ? (
                <EmployeeUserWithAvatar
                  id={d.quality_control.id}
                  full_name={d.quality_control.display_name}
                />
              ) : (
                '-'
              )
            }
            loading={loading}
          />
          <FormPreview.Item<Partial<EmployeeInterface>>
            field="joining_date_time"
            type="date"
            title="Start date"
          />
        </FormPreview>
      </Group>
      <WorkDetailsSidebar
        onClose={() => setSidebarQueryParam('workDetails', false)}
        data={data}
        primaryDataHandler={primaryDataHandler}
        dynamicGroups={dynamicGroups}
        sectionsData={sectionsData}
      />
    </>
  )
}
