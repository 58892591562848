import React, { useMemo, useState } from 'react'
import Table from '@src/components/TableV2/Table'
import {
  DataPoint,
  DataPointSkeleton,
  FilterButton,
  Flex,
  Highlights,
} from '@revolut/ui-kit'
import { useTable } from '@src/components/TableV2/hooks'
import {
  getProbationItems,
  useGetProbationComplianceStats,
  useGetProbationStats,
} from '@src/api/probationEmployees'
import { formatPercentage } from '@src/utils/format'
import { ProbationInterface } from '@src/interfaces/probation'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { addWeeks, endOfToday, formatISO, startOfToday } from 'date-fns'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { navigateTo } from '@src/actions/RouterActions'
import {
  pipelineCheckpointDeadlineColumn,
  pipelineCurrentStageColumn,
  pipelineEmployeeFunctionManagerColumn,
  pipelineEmployeeLevelColumn,
  pipelineEmployeeLineManagerColumn,
  pipelineEmployeeNameColumn,
  pipelineHrManagerColumn,
  pipelinePendingActionsColumn,
  pipelineProbationEndDateColumn,
  pipelineProbationStartDateColumn,
} from './columns'
import { roleNameColumn } from '@src/constants/columns/role'
import { locationNameColumn } from '@src/constants/columns/location'
import { toIdAndName } from '@src/utils/toIdAndName'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'
import { getSelectCellConfig } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { ExportTypes } from '@src/constants/export'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { getProbationItemsExport } from '@src/api/probation'
import { ProbationBulkActionsToolbar } from './BulkActions/Probation/ProbationBulkActionsToolbar'
import { useProbationPermissions } from '@src/features/Probation/permissions/useProbationPermissions'
import { PageActions } from '@src/components/Page/PageActions'
import { SELECTED_CYCLE_ID_PARAM } from '@src/pages/EmployeeProfile/Layout/Performance/constants'

const getRow = (specialisationsEnabled: boolean, bulkActionsEnabled: boolean) => ({
  cells: [
    ...(bulkActionsEnabled ? [{ ...getSelectCellConfig() }] : []),
    { ...pipelineEmployeeNameColumn, width: 200 },
    { ...pipelineEmployeeLevelColumn, width: 70 },
    {
      ...roleNameColumn(specialisationsEnabled),
      width: 100,
    },
    {
      ...locationNameColumn,
      width: 100,
    },
    {
      ...pipelineEmployeeLineManagerColumn,
      width: 60,
    },
    {
      ...pipelineEmployeeFunctionManagerColumn,
      width: 60,
    },
    { ...pipelineProbationStartDateColumn, width: 90 },
    { ...pipelineProbationEndDateColumn, width: 90 },
    { ...pipelineHrManagerColumn, width: 60 },
    { ...pipelineCurrentStageColumn, width: 90 },
    { ...pipelinePendingActionsColumn, width: 100 },
    { ...pipelineCheckpointDeadlineColumn, width: 90 },
  ],
})
export const ProbationPipelineTable = () => {
  const [selectedEmployees, setSelectedEmployee] = useState<ProbationInterface[]>()
  const user = useSelector(selectUser)
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const canViewEmployeeCycleCompliance = useHasGlobalPermission(
    PermissionTypes.ViewEmployeeCycleComplianceStats,
  )
  const table = useTable<ProbationInterface>({ getItems: getProbationItems })
  const { data: stats, isLoading: statsLoading } = useGetProbationStats()
  const { data: complianceStats, isLoading: complianceStatsLoading } =
    useGetProbationComplianceStats({ enabled: canViewEmployeeCycleCompliance })
  const { hasProbationCommitteeHRPermissions } = useProbationPermissions()

  const hasAssignedToMeFilterActive = table.filterBy.some(filter => {
    return (
      filter.columnName === 'pending_with__id' && filter.filters.at(0)?.id === user.id
    )
  })
  const hasPendingCommitteeFilterActive = table.filterBy.some(filter => {
    return (
      filter.columnName === 'checkpoint_type' && filter.filters.at(0)?.id === 'decision'
    )
  })

  const bulkActionsEnabled = hasProbationCommitteeHRPermissions

  const row = useMemo(
    () => getRow(specialisationsEnabled, bulkActionsEnabled),
    [specialisationsEnabled, bulkActionsEnabled],
  )

  const onToggleAssignedToMe = () => {
    table.onFilterChange({
      columnName: 'pending_with__id',
      nonResettable: true,
      filters: hasAssignedToMeFilterActive
        ? []
        : [
            {
              name: user.display_name,
              id: user.id,
            },
          ],
    })
  }

  const onTogglePendingCommittee = () => {
    const todayDate = startOfToday()
    const weekLaterDate = addWeeks(endOfToday(), 1)
    table.onFilterChange([
      {
        filters: hasPendingCommitteeFilterActive ? [] : [toIdAndName('decision')],
        columnName: 'checkpoint_type',
        nonResettable: true,
      },
      {
        filters: hasPendingCommitteeFilterActive
          ? []
          : [toIdAndName(formatISO(todayDate)), toIdAndName(formatISO(weekLaterDate))],
        columnName: 'checkpoint_date_time',
        nonResettable: true,
      },
    ])
  }
  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          {statsLoading ? (
            <Highlights>
              <DataPointSkeleton />
              <DataPointSkeleton />
              <DataPointSkeleton />
            </Highlights>
          ) : (
            <Highlights>
              <DataPoint>
                <DataPoint.Value>{stats?.employees_in_probation}</DataPoint.Value>
                <DataPoint.Label>Employees in probation</DataPoint.Label>
              </DataPoint>
              <DataPoint>
                <DataPoint.Value>{stats?.overdue}</DataPoint.Value>
                <DataPoint.Label>Overdue</DataPoint.Label>
              </DataPoint>
              <DataPoint>
                <DataPoint.Value>{stats?.soon_to_be_overdue}</DataPoint.Value>
                <DataPoint.Label>Soon to be overdue</DataPoint.Label>
              </DataPoint>
            </Highlights>
          )}
        </Table.Widget.Info>
        {canViewEmployeeCycleCompliance && (
          <Table.Widget.Search>
            <Flex justifyContent="flex-end">
              {complianceStatsLoading ? (
                <Highlights>
                  <DataPointSkeleton />
                  <DataPointSkeleton />
                </Highlights>
              ) : (
                <Highlights>
                  <DataPoint>
                    <DataPoint.Value>
                      {formatPercentage(complianceStats?.hr_response_on_time)}
                    </DataPoint.Value>
                    <DataPoint.Label>HR response on time</DataPoint.Label>
                  </DataPoint>
                  <DataPoint>
                    <DataPoint.Value>
                      {formatPercentage(complianceStats?.lm_response_on_time)}
                    </DataPoint.Value>
                    <DataPoint.Label>LM response on time</DataPoint.Label>
                  </DataPoint>
                </Highlights>
              )}
            </Flex>
          </Table.Widget.Search>
        )}
        <Table.Widget.Filters>
          <FilterButton
            active={hasAssignedToMeFilterActive}
            onClick={onToggleAssignedToMe}
          >
            Assigned to me
          </FilterButton>
          <FilterButton
            active={hasPendingCommitteeFilterActive}
            onClick={onTogglePendingCommittee}
          >
            Pending committee review
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <Table.ColumnsSettingsButton />
            <ExportMenu
              fileName="Probation"
              supportedTypes={[ExportTypes.csv]}
              request={getProbationItemsExport}
              filterQuery={filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)}
            />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled
            filters={table.filterBy}
            tableData={table.data}
            onChange={data => {
              setSelectedEmployee(
                data.isAllSelected ? data.tableData : data.selectedRowsData,
              )
            }}
            tableDataLength={table.data.length}
          >
            <AdjustableTable
              name={TableNames.ProbationEmployees}
              row={row}
              {...table}
              emptyState={<EmptyTableRaw title="Probation employees will appear here." />}
              onRowClick={data =>
                navigateTo(
                  pathToUrl(
                    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
                    { id: data.employee.id },
                    { [SELECTED_CYCLE_ID_PARAM]: String(data.cycle.id) },
                  ),
                )
              }
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>
      {bulkActionsEnabled ? (
        <PageActions bottom={12} alignSelf="center">
          <ProbationBulkActionsToolbar
            selection={selectedEmployees}
            refresh={table.refresh}
          />
        </PageActions>
      ) : null}
    </>
  )
}
