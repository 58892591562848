import React from 'react'

import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'
import { useMatrixData } from './useMatrixData'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { ParentSkillsTitle } from '../components/ParentSkillTitle'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const useSpecialisationCompetencyMatrices = ({
  role,
  disabled,
}: {
  role?: RoleInterface
  disabled?: boolean
}) => {
  const { values } = useLapeContext<SpecialisationInterface>()

  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const competencyMatrixV2 = useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2)
  const {
    roleFunctionalMatrix,
    deliverablesMatrix,
    specialisationFunctionalMatrix,
    onChangeDeliverablesMatrix,
    onChangeRoleMatrix,
    onChangeSpecialisationMatrix,
  } = useMatrixData({ values, role, type: 'specialisation' })
  const allowDeleteSkill =
    roleFunctionalMatrix &&
    specialisationFunctionalMatrix &&
    roleFunctionalMatrix.length + specialisationFunctionalMatrix.length > 1

  return [
    specialisationsEnabled
      ? {
          isSeparator: true,
          sectionTitle: 'Deliverables',
        }
      : null,
    {
      sectionTitle: 'Deliverables',
      children: deliverablesMatrix,
      onChange: onChangeDeliverablesMatrix,
      disabled,
      hideActionsColumn: disabled,
      disableWeights: disabled,
    },
    specialisationsEnabled
      ? {
          isSeparator: true,
          sectionTitle: (
            <ParentSkillsTitle
              to={pathToUrl(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX, { id: values.role?.id })}
            />
          ),
        }
      : null,
    {
      children: roleFunctionalMatrix,
      onChange: onChangeRoleMatrix,
      disableWeights: disabled,
      disabled: disabled || specialisationsEnabled || !competencyMatrixV2,
      hideActionsColumn: disabled,
      isSkillOptional: specialisationsEnabled ? false : allowDeleteSkill,
    },
    ...(specialisationFunctionalMatrix?.length
      ? [
          specialisationsEnabled
            ? {
                isSeparator: true,
                sectionTitle: 'Specialised skills',
              }
            : null,
          {
            children: specialisationFunctionalMatrix,
            matrixToFilterOut: roleFunctionalMatrix,
            onChange: onChangeSpecialisationMatrix,
            staticSkill: false,
            isSkillOptional: specialisationsEnabled ? true : allowDeleteSkill,
            disabled,
            hideActionsColumn: disabled,
            disableWeights: disabled,
          },
        ].filter(Boolean)
      : []),
  ].filter(Boolean)
}
