import { Id } from '@src/interfaces'
import { BaseOption } from '@src/interfaces/selectors'
import { EligibleGroupFiltersInterface } from '@src/interfaces/reviewCycles'

import {
  Metrics,
  MetricSources,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { MeetingNotesFeedback } from '@src/interfaces/meetings'

export interface PerformanceInsightsGroupInterface<
  T extends MetricSources = MetricSources,
  K extends Metrics = Metrics,
> {
  id: number
  name: string
  creation_date_time: string
  update_date_time: string | null
  sources: Array<PerformanceInsightsGroupSourceInterface<T, K>>
  filters: Partial<EligibleGroupFiltersInterface>
  is_default: boolean
}

export enum PerformanceInsightsGroupSourceType {
  Internal = 'internal',
  External = 'external',
}

export interface PerformanceInsightsGroupSourceInterface<
  T extends MetricSources,
  K extends Metrics,
> {
  name: T
  type: PerformanceInsightsGroupSourceType
  metrics: Array<PerformanceInsightsGroupSourceMetricInterface<K>>
}

export interface PerformanceInsightsGroupSourceMetricInterface<T extends Metrics> {
  name: T
  enabled: boolean
}

export interface PerformanceInsightsSourceOption<T extends string>
  extends BaseOption<undefined, T> {
  type: PerformanceInsightsGroupSourceType
  metrics: Array<PerformanceInsightsGroupSourceMetricInterface<Metrics>>
}

export type PerformanceInsightsDomain = 'keyInsights' | 'skillInsights'

export interface PerformanceInsightsInterface {
  id: number
  data: string | null
  comment: string | null
  creation_date_time: string
  update_date_time: string
}

export interface SkillPerformanceInsightsInterface extends PerformanceInsightsInterface {
  growth_plan: Id | null
}

export interface SkillOneToOneInsightsInterface {
  id: number
  one_to_one_quote: string | null
  creation_date_time: string
  update_date_time: string
  skill_insights: PerformanceInsightsInterface
  feedback_item: MeetingNotesFeedback
}
