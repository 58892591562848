import { IconName, Token } from '@revolut/ui-kit'
import {
  EngagementAnswerInterface,
  EngagementInsightCategory,
  EngagementInsightsInterface,
  EngagementInsightStatus,
} from '@src/interfaces/engagement'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import { ApiQueryParams } from '@src/api/types'
import { mapValues, omitBy } from 'lodash'

export type InsightsWidgetStatus = 'no_insights' | 'generating' | 'completed' | 'error'
export type InsightScoreVariant = 'average' | 'trend'

export const getInsightsWidgetStatus = ({
  status,
  hasInsights,
  error,
  isInsightsLoading,
}: {
  status?: EngagementInsightStatus
  hasInsights?: boolean
  error?: boolean
  isInsightsLoading: boolean
}): InsightsWidgetStatus => {
  if (hasInsights && !isInsightsLoading) {
    return 'completed'
  }

  if (status === 'success' && !hasInsights && !isInsightsLoading) {
    return 'no_insights'
  }

  if (error || status === 'failed') {
    return 'error'
  }

  if (
    !status ||
    status === 'not_started' ||
    status === 'in_progress' ||
    isInsightsLoading
  ) {
    return 'generating'
  }

  return 'no_insights'
}

const categoryToIconNameMap: Record<EngagementInsightCategory, IconName> = {
  area_of_development: 'SocialDislike',
  strength: 'SocialLike',
  upward_trend: 'ArrowRates',
  downward_trend: 'ArrowRatesDown',
}

export const getCategoryIconName = (category: EngagementInsightCategory): IconName =>
  categoryToIconNameMap[category]

const categoryToNameMap: Record<EngagementInsightCategory, string> = {
  area_of_development: 'Weaknesses',
  strength: 'Strengths',
  upward_trend: 'Upward trends',
  downward_trend: 'Downward trends',
}

export const getCategoryName = (category: EngagementInsightCategory): string =>
  categoryToNameMap[category]

export const getLink = ({
  insight,
  surveyId,
  surveyRoundId,
  scopeParams = {},
}: {
  insight: EngagementInsightsInterface
  surveyId: number
  surveyRoundId: number
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}) => {
  const scope = mapValues(
    omitBy(scopeParams, value => value === undefined),
    String,
  )
  return pathToUrl(
    ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.CATEGORY,
    {
      id: surveyId,
      itemId: insight.driver.id,
    },
    { survey_round_id: String(surveyRoundId), ...scope },
  )
}

export const getAnswerScoreColor = (answer: EngagementAnswerInterface) => {
  const score = answer.answer_score
  if (!score) {
    return undefined
  }
  switch (answer.question.type?.id) {
    case 'nps':
      if (score > 8) {
        return Token.color.success
      }
      if (score > 5) {
        return Token.color.warning
      }
      return Token.color.danger
    case 'rating':
      if (score > 4) {
        return Token.color.success
      }
      if (score > 2) {
        return Token.color.yellow
      }
      return Token.color.danger
    default:
      return Token.color.greyTone50
  }
}

export const formatAnswerScoreValue = (answer: EngagementAnswerInterface) => {
  const score = answer.answer_score
  if (!score) {
    return ''
  }
  switch (answer.question.type?.id) {
    case 'nps':
      return `${answer.answer_score}/10`
    case 'rating':
      return `${answer.answer_score}/5`
    default:
      return answer.answer_score
  }
}
