import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import {
  ExternalMetricSources,
  InternalMetricSources,
} from '@src/features/AiInsights/Performance/interfaces/metrics'

export const buildPathToMetricEnabledField = (
  formData: PerformanceInsightsGroupInterface,
  sourceName: InternalMetricSources | ExternalMetricSources,
  metricName: string,
): string | undefined => {
  const sourceIndex = formData.sources.findIndex(({ name }) => name === sourceName)

  if (sourceIndex === -1) {
    return undefined
  }

  const metricIndex = formData.sources[sourceIndex].metrics.findIndex(
    ({ name }) => name === metricName,
  )

  if (metricIndex === -1) {
    return undefined
  }

  return `sources.${sourceIndex}.metrics.${metricIndex}.enabled`
}
