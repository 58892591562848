import {
  Action,
  Flex,
  HStack,
  Icon,
  IconName,
  ItemSkeleton,
  Spacer,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import React, { useState } from 'react'
import { OutlierItem } from './components/Outliers/OutlierItem'
import { useGetEngagementOutliers } from '@src/api/engagement'
import { OutliersSideBar } from './components/Outliers/OutliersSidebar'
import { OutliersPlaceholder } from './components/Outliers/OutliersPlaceholder'
import { SORT_DIRECTION } from '@src/interfaces/data'

interface Props {
  surveyRoundId: number
}

const OUTLIERS_PAGE_SIZE = 3

export const TabOutliers = ({ surveyRoundId }: Props) => {
  const [allOutliers, setAllOutliers] =
    useState<{ title: string; sortDirection: SORT_DIRECTION }>()

  const { data: positiveOutliers, isLoading: isPositiveOutliersLoading } =
    useGetEngagementOutliers({
      roundId: surveyRoundId,
      params: { ordering: '-average_score', page_size: OUTLIERS_PAGE_SIZE },
    })

  const { data: negativeOutliers, isLoading: isNegativeOutliersLoading } =
    useGetEngagementOutliers({
      roundId: surveyRoundId,
      params: { ordering: 'average_score', page_size: OUTLIERS_PAGE_SIZE },
    })

  const noOutliers =
    !positiveOutliers?.results?.length &&
    !negativeOutliers?.results?.length &&
    !isPositiveOutliersLoading &&
    !isNegativeOutliersLoading

  const renderCategory = ({
    type,
    title,
    iconName,
  }: {
    type: 'positive' | 'negative'
    title: string
    iconName: IconName
  }) => {
    const data = type === 'positive' ? positiveOutliers : negativeOutliers
    const isLoading =
      type === 'positive' ? isPositiveOutliersLoading : isNegativeOutliersLoading

    return (
      <Widget p="s-16" width="100%">
        <HStack align="center">
          <HStack gap="s-12" align="center">
            <Icon name={iconName} />
            <Text variant="heading3">{title}</Text>
          </HStack>
          <Spacer />
          <Action
            aria-label={`See all ${type} outliers`}
            onClick={() =>
              setAllOutliers({
                title,
                sortDirection:
                  type === 'positive' ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
              })
            }
          >
            See all
          </Action>
        </HStack>
        <VStack gap="s-8" mt="s-12">
          {isLoading ? (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          ) : data?.results?.length ? (
            data?.results.map((outlier, i) => <OutlierItem key={i} outlier={outlier} />)
          ) : (
            <OutliersPlaceholder type={type} />
          )}
        </VStack>
      </Widget>
    )
  }

  if (noOutliers) {
    return <OutliersPlaceholder />
  }

  return (
    <Flex
      gap="s-16"
      alignItems={{ all: 'center', lg: 'stretch' }}
      flexDirection={{ all: 'column', lg: 'row' }}
    >
      {renderCategory({
        type: 'positive',
        title: 'Positive Outliers',
        iconName: 'OrderUpwards',
      })}
      {renderCategory({
        type: 'negative',
        title: 'Negative Outliers',
        iconName: 'OrderDownwards',
      })}
      {allOutliers && (
        <OutliersSideBar
          sortDirection={allOutliers.sortDirection}
          surveyRoundId={surveyRoundId}
          title={allOutliers.title}
          onClose={() => setAllOutliers(undefined)}
        />
      )}
    </Flex>
  )
}
