import React, { useMemo } from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsCandidateColumn,
  notificationsDeadline,
  notificationsDeadlineDate,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
  notificationsRoleColumn,
  notificationsSeniorityColumn,
  notificationsTextColumn,
} from '@src/constants/columns/notifications'
import { notificationsInterviews } from '@src/api/notifications'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const rowNotifications = (
  specialisationsEnabled: boolean,
): RowInterface<NotificationsInterface> => ({
  cells: [
    {
      ...notificationsTextColumn,
      width: 200,
    },
    {
      ...notificationsDeadlineDate,
      title: 'Interview date',
      width: 130,
    },
    {
      ...notificationsCandidateColumn,
      width: 150,
    },
    {
      ...notificationsRoleColumn(specialisationsEnabled),
      width: 180,
    },
    {
      ...notificationsSeniorityColumn,
      width: 100,
    },
    {
      ...notificationsReceivedColumn,
      width: 100,
    },
    {
      ...notificationsDeadline,
      width: 100,
    },
    {
      ...notificationsPriorityColumn,
      width: 80,
    },
    {
      ...notificationsActionColumn,
      width: 120,
    },
  ],
})

const Interviews = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const row = useMemo(
    () => rowNotifications(specialisationsEnabled),
    [specialisationsEnabled],
  )

  return (
    <GenericNotificationTable
      rows={row}
      request={notificationsInterviews(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default Interviews
