import {
  Circle,
  Flex,
  Icon,
  Text,
  Token,
  TransitionCollapse,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { FinalGrade, PerformanceRating, Ratings } from '@src/interfaces/performance'
import { formatPercentage } from '@src/utils/format'
import React, { ReactNode, useMemo, useState } from 'react'
import { RatingWithTooltip } from './RatingWithTooltip'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { getPerformanceRatingColor } from '@src/constants/columns/performanceSummary'
import { gradeSettings } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { GradesMapInterface } from '@src/utils/grades'
import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import { RowHeight } from '@src/interfaces/table'
import { LocalStorageKeys } from '@src/store/auth/types'
import { isNumber } from 'lodash'

const HEADERS_OFFSET = 42
const TABLE_MIN_HEIGHT = 310

export const CollapsableTableWrapper = ({
  children,
  headerTitle,
  headerStat,
  headerRating,
  headerGrade,
  completedReviews,
  icon,
  testId,
  count,
  noHeadersOffset,
  readOnly,
  ratings,
  additionalContent,
  mapperFunc,
  gradesMap,
  statPosition = 'chain',
  headerStatColor,
}: {
  children: React.ReactNode
  headerTitle: string
  headerStat?: number
  headerRating?: PerformanceRating | null
  headerGrade?: FinalGrade
  completedReviews?: boolean
  icon?: ReactNode
  testId?: string
  count?: number
  noHeadersOffset?: boolean
  readOnly?: boolean
  ratings?: Ratings[]
  gradesMap?: GradesMapInterface
  mapperFunc?: (
    gradesMap: GradesMapInterface,
    rating?: PerformanceRating | null,
  ) => string | undefined
  additionalContent?: React.ReactNode
  statPosition?: 'chain' | 'end'
  headerStatColor?: string
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!readOnly || headerTitle === 'Goals')
  const rowHeight: RowHeight = useMemo(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)

    if (localStorageRowHeight === 'large') {
      return 'large'
    }
    return 'medium'
  }, [])

  const onCollapse = () => {
    if (readOnly) {
      return
    }
    setIsCollapsed(!isCollapsed)
  }

  return (
    <VStack
      bg={Token.color.grey50_10}
      space="s-16"
      data-testid={testId || `card-table-${headerTitle}`}
      style={{ borderRadius: Token.radius.r16 }}
    >
      <Flex
        style={{ cursor: readOnly ? '' : 'pointer' }}
        onClick={onCollapse}
        padding="s-16"
        justifyContent="space-between"
        alignItems="center"
        data-testid="collapsable-table-wrapper"
      >
        <Flex gap="s-16" alignItems="center">
          {!readOnly && (
            <Icon
              color={Token.color.greyTone50}
              name={isCollapsed ? 'ChevronDown' : 'ChevronRight'}
              size={24}
            />
          )}
          {icon && (
            <Circle size={40} bg={Token.color.accent}>
              {icon}
            </Circle>
          )}
          {statPosition === 'chain' && (
            <Text variant="emphasis1" color={Token.color.foreground}>
              {chain(
                headerTitle,
                isNumber(headerStat) ? (
                  <Text color={getPercentColor(headerStat * 100)}>
                    {formatPercentage(headerStat)}
                  </Text>
                ) : undefined,
              )}
            </Text>
          )}
          {statPosition === 'end' && (
            <Text variant="emphasis1" color={Token.color.foreground}>
              {headerTitle}
            </Text>
          )}
        </Flex>
        {statPosition === 'end' && (
          <Text color={headerStatColor ?? getPercentColor((headerStat || 0) * 100)}>
            {formatPercentage(headerStat || 0)}
          </Text>
        )}

        {completedReviews &&
          headerRating &&
          (ratings ? (
            <RatingWithTooltip
              showAsText
              getTitle={perfRating => {
                return PerformanceRatingTitle[perfRating]
              }}
              rating={headerRating}
              ratings={ratings}
              mapperFunc={mapperFunc}
              gradesMap={gradesMap}
            />
          ) : mapperFunc && gradesMap ? (
            <Text variant="heading3" data-testid="card-result">
              {mapperFunc(gradesMap, headerRating)}
            </Text>
          ) : (
            <Text
              color={getPerformanceRatingColor(headerRating, true)}
              variant="heading3"
              data-testid="card-result"
            >
              {PerformanceRatingTitle[headerRating]}
            </Text>
          ))}
        {completedReviews && headerGrade && (
          <Text color={gradeSettings[headerGrade]?.color} variant="heading3">
            {gradeSettings[headerGrade]?.title}
          </Text>
        )}
      </Flex>
      <TransitionCollapse in={isCollapsed}>
        <Flex
          style={{ position: 'relative', fontWeight: 400 }}
          minHeight={
            count
              ? count * rowWrapperMinHeight[rowHeight] +
                (noHeadersOffset ? 0 : HEADERS_OFFSET)
              : TABLE_MIN_HEIGHT
          }
          flex="1 0"
        >
          {children}
        </Flex>
        {additionalContent}
      </TransitionCollapse>
    </VStack>
  )
}
