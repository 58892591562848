import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  BoxProps,
  DataPoint,
  DataPointSkeleton,
  Ellipsis,
  HStack,
  Icon,
  IconButton,
  Text,
  Token,
  Tooltip,
  TooltipProps,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import {
  getComparableMetricProps,
  MetricDetailsVariants,
} from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/constants'
import { MetricDetails } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/MetricDetails'
import { formatNumericValue } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/formatters'
import { metricWidgetStylesProps } from '@src/features/AiInsights/Performance/components/Metrics/common/styles'

interface Props {
  value: number
  average: number
  metricName: string
  orgUnitName: string
  employeeName?: string
  link?: string
  variant?: MetricDetailsVariants
  comparable?: boolean
  inverted?: boolean
  dataLabel: React.ReactNode
  summary?: React.ReactNode
  tooltipProps?: Partial<TooltipProps>
}

export const MetricHighlight = ({
  value,
  average,
  metricName,
  employeeName,
  orgUnitName,
  link,
  variant = MetricDetailsVariants.Default,
  comparable = true,
  inverted = false,
  dataLabel,
  summary,
  tooltipProps = { width: 280, placement: 'left' },
}: Props) => {
  const tooltip = useTooltip()

  const [dividend, divisor] = inverted ? [average, value] : [value, average]

  const valueFormatted = formatNumericValue(value, variant)
  const averageFormatted = formatNumericValue(average, variant)
  const comparableProps = comparable ? getComparableMetricProps(dividend, divisor) : null

  return (
    <VStack
      space="s-4"
      align="center"
      p="s-16"
      {...metricWidgetStylesProps}
      bg={tooltip.state ? Token.color.greyTone10 : Token.color.widgetBackground}
      {...tooltip.getAnchorProps()}
      data-testid="ai_insights_performance_numeric_metric"
    >
      <DataPoint.Label>
        <HStack space="s-4" align="center">
          <Ellipsis>{dataLabel}</Ellipsis>
          {link && (
            <IconButton
              useIcon="LinkExternal"
              use="a"
              target="_blank"
              href={link}
              size={16}
            />
          )}
        </HStack>
      </DataPoint.Label>
      <DataPoint.Value>
        <HStack space="s-4" align="center">
          {comparableProps && (
            <Icon name={comparableProps.name} color={comparableProps.color} />
          )}
          <Text>{valueFormatted}</Text>
        </HStack>
      </DataPoint.Value>
      <Text variant="body2" color={Token.color.greyTone50} textAlign="center">
        <FormattedMessage
          id="performance.metrics.highlights.average.label"
          values={{
            avg: averageFormatted,
          }}
          defaultMessage="Avg. {avg}"
        />
      </Text>
      <Tooltip {...tooltip.getTargetProps()} {...tooltipProps}>
        <MetricDetails
          variant={variant}
          comparable={comparable}
          inverted={inverted}
          value={value}
          average={average}
          employeeName={employeeName}
          orgUnitName={orgUnitName}
          metricName={metricName}
          title={dataLabel}
          summary={summary}
        />
      </Tooltip>
    </VStack>
  )
}

export const MetricHighlightSkeleton = (props: BoxProps) => (
  <Box p="s-16" radius={Token.radius.r16} bg={Token.color.widgetBackground} {...props}>
    <DataPointSkeleton />
  </Box>
)
