import React, { useState } from 'react'
import {
  ActionBar,
  Avatar,
  Box,
  DetailsCell,
  Item,
  Text,
  TextWidget,
  TextWidgetSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  useGetCandidatePerformanceProfile,
  useGetInterviewSummary,
} from '@src/api/recruitment/interviews'
import { AiIcon } from '@src/components/AiIcon/AiIcon'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import {
  convertOperators,
  getGradeColor,
} from '@src/pages/Forms/Candidate/Performance/utils'
import upperFirst from 'lodash/upperFirst'
import { RatingTag } from '@src/pages/Forms/Candidate/Performance/components/ResultTag'
import { CandidateMatchTag } from '@src/features/CommonCandidatesTable/CandidateMatchTag'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PredictedPerformanceContent } from '@src/features/InterviewTool/components/PredictedPerformanceDetailsContent'
import { NotAssessed } from '@src/features/InterviewTool/components/NotAssessed'
import { HowGradesArePredictedSidebar } from '@src/features/InterviewTool/components/HowGradesArePredictedSidebar'
import { CVScreeningSidebar } from '@src/features/CommonCandidatesTable/CVScreeningSidebar'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

interface PredictedPerformanceProps {
  candidate: CandidateInterface
  round: InterviewRoundInterface
}

export const PredictedPerformance = ({ candidate, round }: PredictedPerformanceProps) => {
  const [openSidebar, setOpenSidebar] = useState<'grades' | 'insights' | null>(null)
  const { data: candidatePerformance, isLoading: loadingCandidatePerformance } =
    useGetCandidatePerformanceProfile(round.id)
  const isCVScoringEnabled = useHasFeatureFlag(FeatureFlags.AICVScoring)

  const skillsRatings = candidatePerformance?.skill_ratings

  const { data: interviewSummaryData, isLoading: loadingInterviewSummary } =
    useGetInterviewSummary(round.id)

  const interviewSummary = interviewSummaryData?.summary?.split('\n') || []
  const show2SentencesInSummaryTextWidget = 2

  return (
    <>
      <Widget aria-label="Predicted performance widget" variant="widget">
        <Item>
          <Item.Avatar>
            <Avatar size={40}>
              <AiIcon />
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Predicted performance</Item.Title>
          </Item.Content>
        </Item>
        <DetailsCell>
          <DetailsCell.Title>Overall grade</DetailsCell.Title>
          <PredictedPerformanceContent
            content={
              skillsRatings?.average_rating ? (
                <Text
                  color={getGradeColor(
                    convertOperators(skillsRatings.average_rating, '', ''),
                  )}
                  fontWeight={700}
                >
                  {convertOperators(upperFirst(skillsRatings.average_rating))}
                </Text>
              ) : (
                <NotAssessed />
              )
            }
            loading={loadingCandidatePerformance}
          />
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Experience match</DetailsCell.Title>
          <CandidateMatchTag
            score={candidate.active_interview_round?.candidate_score}
            style={isCVScoringEnabled ? { cursor: 'pointer' } : undefined}
            onClick={isCVScoringEnabled ? () => setOpenSidebar('insights') : undefined}
          />
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Deliverables</DetailsCell.Title>
          <PredictedPerformanceContent
            content={
              skillsRatings?.delivery?.average_rating ? (
                <RatingTag rating={skillsRatings?.delivery?.average_rating} />
              ) : (
                <NotAssessed />
              )
            }
            loading={loadingCandidatePerformance}
          />
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Skills</DetailsCell.Title>
          <PredictedPerformanceContent
            content={
              skillsRatings?.skill?.average_rating ? (
                <RatingTag rating={skillsRatings?.skill?.average_rating} />
              ) : (
                <NotAssessed />
              )
            }
            loading={loadingCandidatePerformance}
          />
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Values</DetailsCell.Title>
          <PredictedPerformanceContent
            content={
              skillsRatings?.value?.average_rating ? (
                <RatingTag rating={skillsRatings?.value?.average_rating} />
              ) : (
                <NotAssessed />
              )
            }
            loading={loadingCandidatePerformance}
          />
        </DetailsCell>
        {loadingInterviewSummary ? (
          <Box p="s-16">
            <TextWidgetSkeleton />
          </Box>
        ) : interviewSummary.length ? (
          <Box p="s-16">
            <TextWidget>
              <TextWidget.Title>AI Summary of all interviews</TextWidget.Title>
              <TextWidget.Summary>
                <VStack>
                  {interviewSummary
                    .slice(0, show2SentencesInSummaryTextWidget)
                    .map(text => (
                      <Text key={text} color={Token.color.greyTone50} variant="body1">
                        {text}
                      </Text>
                    ))}
                </VStack>
              </TextWidget.Summary>
              <TextWidget.Content>
                <VStack>
                  {interviewSummary.map(text => (
                    <Text key={text} color={Token.color.greyTone50} variant="body1">
                      {text}
                    </Text>
                  ))}
                </VStack>
              </TextWidget.Content>
            </TextWidget>
          </Box>
        ) : null}
        <Box p="s-16">
          <ActionBar>
            <ActionBar.Item
              color={Token.color.foreground}
              to={pathToUrl(ROUTES.FORMS.CANDIDATE.PERFORMANCE, { id: candidate.id })}
              use={InternalLink}
              useIcon="Wealth"
            >
              Performance Profile
            </ActionBar.Item>
            <ActionBar.Item
              color={Token.color.foreground}
              useIcon="InfoOutline"
              onClick={() => setOpenSidebar('grades')}
            >
              How are grades predicted
            </ActionBar.Item>
          </ActionBar>
        </Box>
      </Widget>
      <HowGradesArePredictedSidebar
        candidate={candidate}
        open={openSidebar === 'grades'}
        onClose={() => setOpenSidebar(null)}
      />
      <CVScreeningSidebar
        candidate={candidate}
        open={openSidebar === 'insights'}
        onClose={() => setOpenSidebar(null)}
      />
    </>
  )
}
