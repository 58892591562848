import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'

interface Props {
  label?: React.ReactNode
  successText?: string
  onAfterSubmit?: (insightsGroup: PerformanceInsightsGroupInterface) => void
}

export const SubmitFormAction = ({ label, successText, onAfterSubmit }: Props) => {
  const { isSubmitting } = useLapeContext<PerformanceInsightsGroupInterface>()

  return (
    <NewSaveButtonWithPopup
      successText={successText || 'Insights group successfully created'}
      useValidator
      pending={isSubmitting}
      onAfterSubmit={onAfterSubmit}
    >
      {label || (
        <FormattedMessage id="forms.actions.submit.cta.label" defaultMessage="Submit" />
      )}
    </NewSaveButtonWithPopup>
  )
}

export const SaveFormAction = ({ label, successText, onAfterSubmit }: Props) => {
  const { isSubmitting } = useLapeContext<PerformanceInsightsGroupInterface>()

  return (
    <NewSaveButtonWithPopup
      successText={successText || 'Insights group successfully updated'}
      useValidator
      pending={isSubmitting}
      onAfterSubmit={onAfterSubmit}
    >
      {label || (
        <FormattedMessage
          id="forms.actions.save.changes.cta.label"
          defaultMessage="Save changes"
        />
      )}
    </NewSaveButtonWithPopup>
  )
}
