import React, { useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Flex,
  HStack,
  Icon,
  Subheader,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import {
  HISTORY_RECORDS_COUNT,
  useChartData,
  YAxisUnits,
} from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/hooks/useChartData'
import { GradesDistributionHistoryBarChart } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/GradeDistributionBarChart/BarChart'
import {
  ChartTypeOption,
  ChartTypes,
  ChartTypeSelector,
  performanceChartTypeOption,
  performanceChartTypeToMetricLabelMap,
} from '@src/features/ReviewCycle/Analytics/History/ChartTypeSelector'
import { NipsHistoryBarChart } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/NipsBarChart/BarChart'
import {
  percentageOption,
  YAxisUnitOption,
  YAxisUnitsSelector,
} from '@src/features/ReviewCycle/Analytics/History/YAxisUntisSelector'
import pluralize from 'pluralize'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { selectorKeys } from '@src/constants/api'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'

export const AnalyticsHistory = () => {
  const [chartTypeOption, setChartTypeOption] = useState<ChartTypeOption>(
    performanceChartTypeOption,
  )
  const [yAxisUnitsOption, setYAxisUnitsOption] =
    useState<YAxisUnitOption>(percentageOption)
  const [departmentsOptions, setDepartmentsOptions] = useState<BaseOption[]>([])
  const [functionsOptions, setFunctionsOptions] = useState<BaseOption[]>([])

  const {
    height: chartContainerHeight,
    width: chartContainerWidth,
    ref: chartContainerRef,
  } = useResizeObserver<HTMLDivElement>()
  const titleTooltip = useTooltip()

  const {
    gradeDistributionDataKeys,
    gradeDistributionData,
    nipsDataKey,
    nipsData,
    isLoading,
  } = useChartData(yAxisUnitsOption.id, {
    departmentsIds: departmentsOptions.map(({ id }) => Number(id)),
    functionsIds: functionsOptions.map(({ id }) => Number(id)),
  })

  useEffect(() => {
    if (
      chartTypeOption.id === ChartTypes.Nips &&
      yAxisUnitsOption.id !== YAxisUnits.Percentage
    ) {
      setYAxisUnitsOption(percentageOption)
    }
  }, [chartTypeOption.id, yAxisUnitsOption.id])

  return (
    <Widget p="s-16" height="70vh">
      <VStack space="s-16" height="100%">
        <Flex
          gap="s-16"
          flexWrap="wrap"
          data-testid="performance_analytics_history_filters"
        >
          <ChartTypeSelector value={chartTypeOption} onSelect={setChartTypeOption} />
          <ButtonFilter<BaseOption>
            value={departmentsOptions}
            type={FilterSelectType.MultiSelect}
            selector={selectorKeys.department}
            title={departmentsOptions.length ? 'Selected departments' : 'All departments'}
            useIcon="Bank"
            variant="inactive"
            onChange={setDepartmentsOptions}
          />
          <ButtonFilter<BaseOption>
            value={functionsOptions}
            type={FilterSelectType.MultiSelect}
            selector={selectorKeys.functions}
            title={functionsOptions.length ? 'Selected functions' : 'All functions'}
            useIcon="RepairTool"
            variant="inactive"
            onChange={setFunctionsOptions}
          />
        </Flex>
        <Widget
          width="100%"
          height="100%"
          px="s-16"
          pb="s-16"
          data-testid="performance_analytics_history"
        >
          <VStack width="100%" height="100%">
            <Box>
              <Subheader>
                <Subheader.Title>
                  <HStack space="s-8" align="center">
                    <Text
                      variant="emphasis1"
                      fontWeight={500}
                      color={Token.color.foreground}
                    >
                      <FormattedMessage
                        id="performance.history.title.label"
                        values={{
                          metric:
                            performanceChartTypeToMetricLabelMap[chartTypeOption.id],
                        }}
                        defaultMessage="{metric} over time"
                      />
                    </Text>
                    <Icon
                      name="InfoOutline"
                      size={16}
                      {...titleTooltip.getAnchorProps<HTMLSpanElement>()}
                    />
                  </HStack>
                  <Tooltip
                    {...titleTooltip.getTargetProps<HTMLSpanElement>()}
                    placement="right"
                    width={240}
                  >
                    <FormattedMessage
                      id="performance.history.title.info"
                      values={{ count: HISTORY_RECORDS_COUNT }}
                      defaultMessage={`Review analytics data for latest {count} ${pluralize(
                        'cycle',
                        HISTORY_RECORDS_COUNT,
                      )}`}
                    />
                  </Tooltip>
                </Subheader.Title>
                <Subheader.Side>
                  <YAxisUnitsSelector
                    value={yAxisUnitsOption}
                    disabled={chartTypeOption.id === ChartTypes.Nips}
                    onSelect={setYAxisUnitsOption}
                  />
                </Subheader.Side>
              </Subheader>
            </Box>
            <Box width="100%" height="100%" ref={chartContainerRef}>
              {chartTypeOption.id === ChartTypes.GradesDistribution && (
                <GradesDistributionHistoryBarChart
                  data={gradeDistributionData}
                  dataKeys={gradeDistributionDataKeys}
                  yAxisUnit={yAxisUnitsOption.id}
                  isLoading={isLoading}
                  height={chartContainerHeight}
                />
              )}
              {chartTypeOption.id === ChartTypes.Nips && (
                <NipsHistoryBarChart
                  dataKey={nipsDataKey}
                  data={nipsData}
                  isLoading={isLoading}
                  height={chartContainerHeight}
                  width={chartContainerWidth}
                />
              )}
            </Box>
          </VStack>
        </Widget>
      </VStack>
    </Widget>
  )
}
