import { REV_10X_ASSETS } from './assets'

export const aiChatbotWinners = [
  {
    firstName: 'Theodore',
    lastName: 'Willems',
    imageSrc: REV_10X_ASSETS.winners.willems,
  },
  {
    firstName: 'Rony Leonardo',
    lastName: 'Batista',
    imageSrc: REV_10X_ASSETS.winners.ronyBatista,
  },
  {
    firstName: 'Anastasia',
    lastName: 'Motrenko',
    imageSrc: REV_10X_ASSETS.winners.motrenko,
  },
  {
    firstName: 'Ignasi Pons',
    lastName: 'Montasell',
    imageSrc: REV_10X_ASSETS.winners.montasell,
  },
  {
    firstName: 'Maria Ines',
    lastName: 'Azevedo',
    imageSrc: REV_10X_ASSETS.winners.azevedo,
  },
  { firstName: 'Ivan', lastName: 'Vovk', imageSrc: REV_10X_ASSETS.winners.vovk },
  { firstName: 'Tomás', lastName: 'Hervás', imageSrc: REV_10X_ASSETS.winners.hervas },
  {
    firstName: 'Ovidiu',
    lastName: 'Munteanu',
    imageSrc: REV_10X_ASSETS.winners.munteanu,
  },
  {
    firstName: 'Mariia',
    lastName: 'Korovina',
    imageSrc: REV_10X_ASSETS.winners.korovina,
  },
]

export const projectMeruWinners = [
  {
    firstName: 'Justin',
    lastName: 'Sett',
    team: 'Finance',
    imageSrc: REV_10X_ASSETS.winners.sett,
  },
  {
    firstName: 'Nicholas',
    lastName: 'Panetta',
    team: 'Finance',
    imageSrc: REV_10X_ASSETS.winners.panetta,
  },
  {
    firstName: 'Joshua',
    lastName: 'Bretherton',
    team: 'Finance',
    imageSrc: REV_10X_ASSETS.winners.bretherton,
  },
  {
    firstName: 'Enrique Sevilla',
    lastName: 'García-Conde',
    team: 'Finance',
    imageSrc: REV_10X_ASSETS.winners.conde,
  },
  {
    firstName: 'Pedro',
    lastName: 'Oliveira',
    team: 'Finance',
    imageSrc: REV_10X_ASSETS.winners.oliveira,
  },
  {
    firstName: 'Cara',
    lastName: 'Todd',
    team: 'Legal',
    imageSrc: REV_10X_ASSETS.winners.todd,
  },
  {
    firstName: 'Nikoletta',
    lastName: 'Zinonos',
    team: 'Legal',
    imageSrc: REV_10X_ASSETS.winners.zinonos,
  },
  {
    firstName: 'Soniya',
    lastName: 'Kenth',
    team: 'Legal',
    imageSrc: REV_10X_ASSETS.winners.kenth,
  },
  {
    firstName: 'Luke',
    lastName: 'Dovey',
    team: 'Legal',
    imageSrc: REV_10X_ASSETS.winners.dovey,
  },
  {
    firstName: 'Chloe',
    lastName: 'Stevenson',
    team: 'Legal',
    imageSrc: REV_10X_ASSETS.winners.stevenson,
  },
  {
    firstName: 'Elena',
    lastName: 'Nistor',
    team: 'Talent',
    imageSrc: REV_10X_ASSETS.winners.nistor,
  },
  {
    firstName: 'Ana',
    lastName: 'Carrasqueira',
    team: 'Talent',
    imageSrc: REV_10X_ASSETS.winners.carasqueira,
  },
  {
    firstName: 'Jose Maria Amor',
    lastName: 'Pilon',
    team: 'Talent',
    imageSrc: REV_10X_ASSETS.winners.pilon,
  },
  {
    firstName: 'Paul',
    lastName: 'Twist',
    team: 'Talent',
    imageSrc: REV_10X_ASSETS.winners.twist,
  },
  {
    firstName: 'Aaron',
    lastName: 'Hayes',
    team: 'Talent',
    imageSrc: REV_10X_ASSETS.winners.hayes,
  },
]

export const revBusinessWinners = [
  { firstName: 'Pierre', lastName: 'Cahuzac', imageSrc: REV_10X_ASSETS.winners.cahuzac },
  {
    firstName: 'Lorenzo',
    lastName: 'Mengolini',
    imageSrc: REV_10X_ASSETS.winners.mengolini,
  },
  { firstName: 'Madeleine', lastName: 'White', imageSrc: REV_10X_ASSETS.winners.white },
  { firstName: 'Sameera', lastName: 'Gunturi', imageSrc: REV_10X_ASSETS.winners.gunturi },
  {
    firstName: 'Vitalii',
    lastName: 'Vitoshnev',
    imageSrc: REV_10X_ASSETS.winners.vitoshnev,
  },
  { firstName: 'Margaux', lastName: 'Poirier', imageSrc: REV_10X_ASSETS.winners.poirier },
  {
    firstName: 'Mattia',
    lastName: 'Maestrini',
    imageSrc: REV_10X_ASSETS.winners.maestrini,
  },
  { firstName: 'Paolo', lastName: 'Furlan', imageSrc: REV_10X_ASSETS.winners.furlan },
  { firstName: 'Gongwu', lastName: 'Nie', imageSrc: REV_10X_ASSETS.winners.nie },
]

export const eSimWinners = [
  { firstName: 'Elyas', lastName: 'Sadou', imageSrc: REV_10X_ASSETS.winners.sadou },
  {
    firstName: 'Pawel',
    lastName: 'Ochrymowicz',
    imageSrc: REV_10X_ASSETS.winners.ochrymowicz,
  },
  {
    firstName: 'Amalia',
    lastName: 'Christofidou',
    imageSrc: REV_10X_ASSETS.winners.christofidou,
  },
  { firstName: 'Hanna', lastName: 'Shylenko', imageSrc: REV_10X_ASSETS.winners.shylenko },
  {
    firstName: 'David Rodríguez',
    lastName: 'Gonzalez',
    imageSrc: REV_10X_ASSETS.winners.gonzalez,
  },
]

export const finCrimeWinners = [
  {
    firstName: 'Vito',
    lastName: 'Armonavicius',
    imageSrc: REV_10X_ASSETS.winners.armonavicius,
  },
  {
    firstName: 'Guillermo',
    lastName: 'Fremd Kanovich',
    imageSrc: REV_10X_ASSETS.winners.kanovich,
  },
  {
    firstName: 'Alexander Alan',
    lastName: 'Coubrough',
    imageSrc: REV_10X_ASSETS.winners.coubrough,
  },
  {
    firstName: 'Ben',
    lastName: 'Pocklington',
    imageSrc: REV_10X_ASSETS.winners.pocklington,
  },
  {
    firstName: 'Simonas',
    lastName: 'Bankauskas',
    imageSrc: REV_10X_ASSETS.winners.bankauskas,
  },
  {
    firstName: 'Thomas',
    lastName: 'Fitzpatrick',
    imageSrc: REV_10X_ASSETS.winners.fitzpatrick,
  },
  {
    firstName: 'Rustam',
    lastName: 'Shahbazov',
    imageSrc: REV_10X_ASSETS.winners.shahbazov,
  },
]

export const strategicPlanningWinners = [
  {
    firstName: 'Rahul',
    lastName: 'Ramchandani',
    imageSrc: REV_10X_ASSETS.winners.ramchandani,
  },
  { firstName: 'Ravi', lastName: 'Teja Muni', imageSrc: REV_10X_ASSETS.winners.muni },
  {
    firstName: 'Sumanth',
    lastName: 'Nagaraja',
    imageSrc: REV_10X_ASSETS.winners.nagaraja,
  },
  {
    firstName: 'Vasilis',
    lastName: 'Lyssiotis',
    imageSrc: REV_10X_ASSETS.winners.lyssiotis,
  },
  {
    firstName: 'Ankit ',
    lastName: 'Boratkar',
    imageSrc: REV_10X_ASSETS.winners.borathar,
  },
  {
    firstName: 'Nithin ',
    lastName: 'Nellepalli',
    imageSrc: REV_10X_ASSETS.winners.nellepalli,
  },
]

export const revPayWinners = [
  { firstName: 'Andrei', lastName: 'Bents', imageSrc: REV_10X_ASSETS.winners.bents },
  {
    firstName: 'Anton',
    lastName: 'Nazarenko',
    imageSrc: REV_10X_ASSETS.winners.nazarenko,
  },
  {
    firstName: 'Egor',
    lastName: 'Samarkhanov',
    imageSrc: REV_10X_ASSETS.winners.samarkhanov,
  },
  {
    firstName: 'Kuanysh',
    lastName: 'Raimbekov',
    imageSrc: REV_10X_ASSETS.winners.raimbekov,
  },
  { firstName: 'Maciej', lastName: 'Reszke', imageSrc: REV_10X_ASSETS.winners.reszke },
  { firstName: 'Mateusz', lastName: 'Malek', imageSrc: REV_10X_ASSETS.winners.malek },
  { firstName: 'Toma', lastName: 'Radu-Petrescu', imageSrc: REV_10X_ASSETS.winners.radu },
  { firstName: 'Abishek', lastName: 'bansal', imageSrc: REV_10X_ASSETS.winners.bansal },
  { firstName: 'Dinu', lastName: 'Piscuc', imageSrc: REV_10X_ASSETS.winners.piscuc },
  { firstName: 'Victor', lastName: 'Semin', imageSrc: REV_10X_ASSETS.winners.semin },
  { firstName: 'Damian', lastName: 'Ziaber', imageSrc: REV_10X_ASSETS.winners.ziaber },
]

export const salesSpecialProjectsWinners = [
  {
    firstName: 'Ausra',
    lastName: 'Maseckyte',
    imageSrc: REV_10X_ASSETS.winners.maseckyte,
  },
  {
    firstName: 'Ankit',
    lastName: 'Agarwal',
    imageSrc: REV_10X_ASSETS.winners.agarwal,
  },
  {
    firstName: 'George',
    lastName: 'Nettleton',
    imageSrc: REV_10X_ASSETS.winners.nettleton,
  },
  {
    firstName: 'Alexis',
    lastName: 'Picot',
    imageSrc: REV_10X_ASSETS.winners.picot,
  },
  {
    firstName: 'Juan Antonio Delgado',
    lastName: 'Ozonas',
    imageSrc: REV_10X_ASSETS.winners.ozonas,
  },
  {
    firstName: 'Konrad',
    lastName: 'Barczykowski',
    imageSrc: REV_10X_ASSETS.winners.barczykowski,
  },
  {
    firstName: 'Mauricio Daniel Olivares',
    lastName: 'Watson',
    imageSrc: REV_10X_ASSETS.winners.watson,
  },
  {
    firstName: 'Maxime',
    lastName: 'Grudé',
    imageSrc: REV_10X_ASSETS.winners.grude,
  },
  {
    firstName: 'Rodrigo',
    lastName: 'Sala',
    imageSrc: REV_10X_ASSETS.winners.sala,
  },
  {
    firstName: 'Michael',
    lastName: 'Mcgill',
    imageSrc: REV_10X_ASSETS.winners.mcgill,
  },
]
