import { ZodError, ZodIssue } from 'zod'

export const errorMessageByField = (error: ZodError): Record<string, string> | null => {
  const errorMap: Record<string, string> = {}

  error.errors.forEach((issue: ZodIssue) => {
    const [key] = issue.path
    errorMap[key] = issue.message
  })

  return errorMap
}
