import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterButton, MoreBar } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { payGroupTableRequests } from '@src/api/payrollV2'
import { useGetSelectors } from '@src/api/selectors'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import {
  payGroupChangesNumColumn,
  payGroupHeadcountColumn,
  payGroupIssuesNumColumn,
  payGroupNameColumn,
  payGroupPayrollProviderColumn,
  payGroupPayRunColumn,
  payGroupStatusColumn,
} from '@src/constants/columns/payGroupV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { PayGroupListItemInterface } from '@src/interfaces/payrollV2'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'

const row: RowInterface<PayGroupListItemInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.OVERVIEW, {
        id: data.current_pay_cycle.id,
      }),
    ),
  cells: [
    {
      ...payGroupNameColumn,
      width: 250,
    },
    {
      ...payGroupHeadcountColumn,
      width: 80,
    },
    {
      ...payGroupPayRunColumn,
      width: 80,
    },
    {
      ...payGroupPayrollProviderColumn,
      width: 120,
    },
    {
      ...payGroupChangesNumColumn,
      width: 100,
    },
    {
      ...payGroupIssuesNumColumn,
      width: 100,
    },
    {
      ...payGroupStatusColumn,
      width: 100,
    },
  ],
}

export const PayCyclesTableV2 = () => {
  const [selectedCountryFilter, setSelectedCountryFilter] = useState<OptionInterface[]>(
    [],
  )
  const [selectedEntityFilter, setSelectedEntityFilter] = useState<OptionInterface[]>([])

  const { data: countries } = useGetSelectors(selectorKeys.countries)
  const { data: entities } = useGetSelectors(selectorKeys.entity)

  const [ownedByMe, setOwnedByMe] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_OWNED_BY_ME,
    false,
  )

  const user = useSelector(selectUser)

  const hasAddPayCyclePermission = useHasGlobalPermission(PermissionTypes.AddPayCycle)
  const canViewPayrollPreferences = useHasGlobalPermission(
    PermissionTypes.ViewPayrollPreferences,
  )
  const canAddPayCycle = hasAddPayCyclePermission

  const getOwnedByMeFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'owner_id',
    filters: enabled ? [{ name: user.display_name, id: user.id }] : [],
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const initialFilters: FilterByInterface[] = [
      {
        columnName: 'current',
        filters: [toIdAndName('true')],
        nonResettable: true,
      },
    ]
    if (ownedByMe) {
      initialFilters.push(getOwnedByMeFilter(true))
    }
    return initialFilters
  }

  const table = useTable(payGroupTableRequests, getInitialFilters())

  const toggleOwnedByMe = () => {
    setOwnedByMe(prev => {
      const newOwnedByMe = !prev
      table.onFilterChange(getOwnedByMeFilter(newOwnedByMe))
      return newOwnedByMe
    })
  }

  const selectCountries = (selection: OptionInterface[] | undefined) => {
    setSelectedCountryFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'country_id',
    })
  }

  const selectEntities = (selection: OptionInterface[] | undefined) => {
    setSelectedEntityFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'company_entity_id',
    })
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="Total" val={table.loading ? undefined : table.count} />
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <FilterButton onClick={toggleOwnedByMe} active={ownedByMe}>
            Owned by me
          </FilterButton>
          <FilterButtonCheckboxSelect
            label="Country"
            searchable
            width={300}
            options={countries || []}
            value={selectedCountryFilter}
            onChange={selectCountries}
          />
          <FilterButtonCheckboxSelect
            label="Entity"
            searchable
            width={300}
            options={entities || []}
            value={selectedEntityFilter}
            onChange={selectEntities}
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {canAddPayCycle && (
              <MoreBar.Action
                useIcon="Plus"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT)}
              >
                Add paygroup
              </MoreBar.Action>
            )}
            <HideIfCommercial>
              <MoreBar.Action
                to={pathToUrl(ROUTES.APPS.PAYROLL.PAYMENTS_TABLE)}
                use={InternalLink}
                useIcon="Pencil"
              >
                Manage payments
              </MoreBar.Action>
            </HideIfCommercial>
            {canViewPayrollPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.PAYROLL.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </MoreBar.Action>
            )}
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            hideCount
            name={TableNames.PayCycles}
            dataType="paycycle"
            row={row}
            useWindowScroll
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
