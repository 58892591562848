import React from 'react'
import { VStack, Grid, Text } from '@revolut/ui-kit'
import { EmployeeTile, EmployeeTileProps } from './EmployeeTile'

interface Props {
  team: EmployeeTileProps[]
}

export const Team = ({ team }: Props) => {
  return (
    <VStack gap="s-48" pb="s-48">
      <Text use="h2" fontWeight={900} fontSize="3.75rem" textAlign="center">
        THE TEAM
      </Text>
      <Grid columns="1fr 1fr 1fr" gap="s-24">
        {team.map((member, i) => (
          <EmployeeTile
            firstName={member.firstName}
            lastName={member.lastName}
            team={member.team}
            key={i}
            imageSrc={member.imageSrc}
          />
        ))}
      </Grid>
    </VStack>
  )
}
