import React from 'react'
import {
  Fixed,
  Header,
  PositionProps,
  HeaderSkeleton,
  Side,
  Avatar,
  AvatarProps,
  Token,
  Layout,
  useMatchMedia,
  Portal,
} from '@revolut/ui-kit'
import { defaultTheme } from '@src/styles/theme'
import { SideProps, SideVariant } from '@revolut/ui-kit/types/dist/components/Side/Side'
import { css } from 'styled-components'
import { SideBarHeaderTitle } from './SideBarHeaderTitle'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'

const fixedCSS = css`
  justify-content: center;
`

export interface SideBarProps extends Omit<PositionProps, 'title' | 'open'> {
  children: React.ReactNode
  isOpen?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  onClose?: () => void
  // Is added to header right after title if you specify one
  headerContent?: React.ReactNode
  customHeader?: React.ReactNode
  zIndex?: number
  sideProps?: SideProps
  variant?: SideVariant
  loading?: boolean
  avatar?: string | null
  useIcon?: AvatarProps['useIcon']
  avatarProps?: AvatarProps
  useLayout?: boolean
  /** Pass a string if custom portal target is needed */
  usePortal?: boolean | string
  useBackButton?: boolean
}

const SideBar = ({
  children,
  isOpen = true,
  title,
  sideProps,
  onClose = () => {},
  headerContent,
  customHeader,
  subtitle,
  loading,
  variant,
  avatar,
  useIcon,
  avatarProps,
  useLayout,
  usePortal,
  useBackButton,
  ...rest
}: SideBarProps) => {
  const { visible } = useBanner()
  const mdMedia = useMatchMedia(Token.media.md)

  const renderHeader = () => {
    if (loading) {
      return <HeaderSkeleton variant="item" labelBackButton="Back" />
    }

    if (customHeader !== undefined) {
      return customHeader
    }

    return (
      <Header variant="item" pb={0}>
        {useBackButton ? (
          <Header.BackButton
            data-testid="sidebar-back"
            aria-label="Sidebar go back"
            color={Token.color.foreground}
          />
        ) : (
          <Header.CloseButton
            data-testid="sidebar-close"
            aria-label="Close sidebar"
            color={Token.color.foreground}
          />
        )}
        {(avatar || useIcon) && (
          <Header.Avatar>
            <Avatar
              variant="brand"
              {...(avatar ? { image: avatar } : { useIcon })}
              {...avatarProps}
            />
          </Header.Avatar>
        )}
        {title && (
          <SideBarHeaderTitle data-testid="sidebar-title">{title}</SideBarHeaderTitle>
        )}
        {subtitle && (
          <Header.Description data-testid="sidebar-description">
            {subtitle}
          </Header.Description>
        )}
        {headerContent}
      </Header>
    )
  }

  if (!isOpen) {
    return null
  }

  const renderContent = () => (
    <Side open onClose={onClose} variant={variant} height="100%" {...sideProps}>
      <ErrorBoundary>
        {renderHeader()}
        {children}
      </ErrorBoundary>
    </Side>
  )

  // be careful, Layout.SideFill takes the sidebar out of the flow and it can't access the contexts which are above. You should wrap the whole MainLayout with your context
  if (useLayout) {
    return <Layout.SideFill>{renderContent()}</Layout.SideFill>
  }

  const Wrapper = usePortal ? Portal : React.Fragment

  return (
    <Wrapper {...(typeof usePortal === 'string' ? { target: usePortal } : {})}>
      <Fixed
        right={0}
        top={visible ? 46 : 60}
        left={{ all: 0, lg: 'auto' }}
        display={{ all: 'flex', lg: 'block' }}
        zIndex={defaultTheme.zIndex.sideBar}
        pt={{ all: visible ? 's-40' : 0, lg: visible ? 's-56' : 's-16' }}
        pr={{ all: 0, lg: 's-16' }}
        pb={{ all: 0, lg: 's-16' }}
        pl={{ all: 0, lg: 's-16' }}
        /** Need to account for the core navigation header, and bottom nav on md screens */
        height={`calc(100vh - 60px${mdMedia ? '' : ' - 50px'}${
          visible ? ' + 20px' : ''
        })`}
        css={fixedCSS}
        data-testid={`sidebar-${title}`}
        {...rest}
      >
        {renderContent()}
      </Fixed>
    </Wrapper>
  )
}

export default SideBar
