import React from 'react'
import { Box, Checkbox, HStack, Tag, VStack } from '@revolut/ui-kit'
import { EditorView } from '@components/Editor/EditorView'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'

interface Props {
  action: GrowthPlanActionInterface
}

export const CompletedAction = ({ action }: Props) => (
  <HStack space="s-16" key={action.id}>
    <Box pt="s-4">
      <Checkbox checked disabled />
    </Box>
    <VStack space="s-8">
      <EditorView html={action.description} />
      {action.content_object && <Tag useIcon="Palette">{action.content_object.name}</Tag>}
    </VStack>
  </HStack>
)
