import { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'

import { useTableReturnType } from '@src/components/TableV2/hooks'
import { ItemsToAnalyse } from '@src/features/Engagement/api/analytics/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { toIdAndName } from '@src/utils/toIdAndName'
import { TimelineFilterInterface } from '../../../Rounds/Selector/types'
import { UseHeatmapFiltersReturnType } from './useHeatmapFilters'

export const getSurveyRoundFilterParams = (
  timelineFilter: TimelineFilterInterface,
): FilterByInterface[] => {
  const { mode, round } = timelineFilter

  if (mode.value === 'rounds' && round.value) {
    return [
      {
        columnName: 'survey_round_id',
        filters: [toIdAndName(String(round.value.id))],
        nonResettable: true,
      },
    ]
  }
  return []
}

export const getDateRangeFilterParams = (
  dateFrom: string,
  dateTo: string,
  itemsToAnalyse?: ItemsToAnalyse,
): FilterByInterface[] => {
  if (itemsToAnalyse === 'answers') {
    return [
      {
        columnName: 'creation_date_time',
        filters: [toIdAndName(dateFrom), toIdAndName(dateTo)],
        nonInheritable: true,
        nonResettable: true,
      },
    ]
  }
  return [
    {
      columnName: 'from_date',
      filters: [toIdAndName(dateFrom)],
      nonInheritable: true,
      nonResettable: true,
    },
    {
      columnName: 'to_date',
      filters: [toIdAndName(dateTo)],
      nonInheritable: true,
      nonResettable: true,
    },
  ]
}

export const getHeatmapFilterParams = (
  groupByKey: string | undefined,
  groupByValue: string | undefined,
  scopeFilter: FilterByInterface[] = [],
): FilterByInterface[] => {
  if (!groupByKey || !groupByValue) {
    return []
  }
  const groupByValueOverriddenByScope = scopeFilter.find(
    filterItem => filterItem.columnName === groupByKey,
  )
  return [
    {
      columnName: 'group_by',
      filters: [toIdAndName(groupByKey)],
      nonInheritable: true,
      nonResettable: true,
    },
    {
      columnName: groupByKey,
      filters: groupByValueOverriddenByScope?.filters || [toIdAndName(groupByValue)],
      nonInheritable: true,
      nonResettable: true,
    },
    ...(groupByValueOverriddenByScope ? [] : scopeFilter),
  ]
}

const getFiltersToApply = (
  currentFilters: FilterByInterface[],
  extFilters: FilterByInterface[],
): FilterByInterface[] => {
  const filtersToClear = [
    // if the value is not passed in extFilters, it should be reset
    'role',
    'department',
    'function',
    'location',
    'country',
    'team',
    'seniority',
    'performance',
    'tenure',
  ]
  return currentFilters
    .filter(filterItem =>
      filtersToClear.includes(filterItem.columnName)
        ? extFilters.some(extFilter => extFilter.columnName === filterItem.columnName)
        : true,
    )
    .map(filterItem => {
      const extValue = extFilters.find(
        extFilterItem => extFilterItem.columnName === filterItem.columnName,
      )
      if (extValue) {
        return extValue
      }
      return filterItem
    })
}

const validateTimelineFilter = (timelineFilter: TimelineFilterInterface) => {
  return timelineFilter.dateFrom != null && timelineFilter.dateTo != null
}

const validateHeatmapFilters = (
  heatmapFilters: UseHeatmapFiltersReturnType | undefined,
) => {
  return Boolean(heatmapFilters?.groupBy.paramKey && heatmapFilters?.groupBy.paramValue)
}

export const useApplyNonTableFilters = <T>({
  disable,
  table,
  timelineFilter,
  heatmapFilters,
  scopeFilters = [],
  itemsToAnalyse,
  onRefresh,
  isHeatmap,
}: {
  disable: boolean
  table: useTableReturnType<T>
  timelineFilter: TimelineFilterInterface
  heatmapFilters?: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  itemsToAnalyse?: ItemsToAnalyse
  onRefresh?: VoidFunction
  isHeatmap?: boolean
}) => {
  const { dateFrom, dateTo } = timelineFilter
  const groupByKey = heatmapFilters?.groupBy.paramKey
  const groupByValue = heatmapFilters?.groupBy.paramValue

  const invalidFilters =
    !validateTimelineFilter(timelineFilter) ||
    (isHeatmap && !validateHeatmapFilters(heatmapFilters))

  const extFilters = useMemo(() => {
    const dateRangeFilterParams = getDateRangeFilterParams(
      dateFrom,
      dateTo,
      itemsToAnalyse,
    )
    const restFilterParams = isHeatmap
      ? getHeatmapFilterParams(groupByKey, groupByValue, scopeFilters)
      : [...scopeFilters, ...getSurveyRoundFilterParams(timelineFilter)]
    return [...dateRangeFilterParams, ...restFilterParams]
  }, [isHeatmap, dateFrom, dateTo, groupByKey, groupByValue, timelineFilter]) // scopeFilters are not on the deps list since they shouldn't change

  const filtersToApply = getFiltersToApply(table.filterBy, extFilters)

  useEffect(() => {
    table.resetFiltersAndSorting(filtersToApply)
    onRefresh?.()
  }, [itemsToAnalyse])

  useEffect(() => {
    const isDisabled = disable || invalidFilters
    const nothingChanged = isEqual(table.filterBy, filtersToApply)
    if (isDisabled || nothingChanged) {
      return
    }

    table.resetFiltersAndSorting(filtersToApply)
    onRefresh?.()
  }, [disable, isHeatmap, invalidFilters, table.filterBy, filtersToApply])
}
