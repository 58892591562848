import React, { useMemo, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import {
  Box,
  IconButton,
  Flex,
  HStack,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { Chat, Envelope, Profile } from '@revolut/icons'
import {
  useDocumentsSignature,
  useDocumentsUpload,
  useOnboardingData,
  useOnboardingStatsData,
} from '@src/api/onboardingEmployeesV2'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { OnboardingTemplateInterface } from '@src/interfaces/onboardingV2'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Title from '@src/pages/Forms/Candidate/InterviewProgress/components/Title'
import { useEmployeeProfileData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { SendEmailSidebar } from '@src/pages/Forms/SendEmployeeEmail/SendEmployeeEmailSidebar'
import { EmployeeEmails } from '@src/features/EmployeeEmails/EmployeeEmails'
import { pathToUrl } from '@src/utils/router'
import { OnboardingActions } from './components/OnboardingActions'
import { OnboardingSubtitle } from './components/OnboardingSubtitle'
import { TaskViewForm } from './components/TaskViewForm'
import { Comments } from './Comments'
import { OverviewTab } from './Overview'

const hasDocumentTemplate = (template: OnboardingTemplateInterface) =>
  !!template.document_template

export const OnboardingTimelineV2 = () => {
  const params = useParams<{ id: string; onboardingId: string }>()
  const {
    data: employeeData,
    handler,
    sections,
  } = useEmployeeProfileData({
    employeeId: params.id,
  })
  const {
    data: onboardingData,
    isLoading,
    refetch: refetchOnboardingData,
  } = useOnboardingData(params.onboardingId)
  const { data: statsData, refetch: refetchStatsData } = useOnboardingStatsData(
    params.onboardingId,
  )
  const { data: documentsSignature, refetch: refetchDocumentsSignature } =
    useDocumentsSignature(params.onboardingId)
  const { data: documentsUpload, refetch: refetchDocumentsUpload } = useDocumentsUpload(
    params.onboardingId,
  )
  const employeeProfileTooltip = useTooltip()
  const [emailSidebarOpen, setEmailSidebarOpen] = useState(false)

  const documentsSignatureData = useMemo(
    () => documentsSignature?.results.filter(hasDocumentTemplate),
    [documentsSignature?.results],
  )
  const documentsUploadData = useMemo(
    () => documentsUpload?.results.filter(hasDocumentTemplate),
    [documentsUpload?.results],
  )

  if (!employeeData || !onboardingData) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, params),
      icon: <Profile size={15} />,
      component: (
        <OverviewTab
          data={employeeData}
          documentsSignature={documentsSignatureData}
          documentsUpload={documentsUploadData}
          status={onboardingData.status.id}
          onRefetchDocumentsSignature={refetchDocumentsSignature}
          onRefetchDocumentsUpload={refetchDocumentsUpload}
          primaryDataHandler={handler}
          sectionsData={sections}
        />
      ),
    },
    {
      title: 'Emails',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE_V2.EMAILS,
      icon: <Envelope size={15} />,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.EMAILS, params),
      component: (
        <EmployeeEmails
          employeeId={Number(params.id)}
          onRefetch={refetchStatsData}
          templateFilter={item => item.category.id === 'onboarding'}
          threadPath={ROUTES.FORMS.ONBOARDING_SEND_EMAIL_V2.VIEW}
          useOnboardingSettings
          useSidebar
        />
      ),
      quickSummary: <QuickSummaryCount count={statsData?.emails} />,
    },
    {
      title: 'Comments',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE_V2.COMMENTS,
      icon: <Chat size={15} />,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.COMMENTS, params),
      quickSummary: <QuickSummaryCount count={statsData?.comments} />,
      component: (
        <Comments onboardingId={params.onboardingId} onRefetch={refetchStatsData} />
      ),
    },
  ]

  return (
    <>
      <Switch>
        <Route exact path={ROUTES.FORMS.ONBOARDING_TIMELINE_V2.TASK_VIEW}>
          <TaskViewForm />
        </Route>

        <Route exact path={ROUTES.FORMS.ONBOARDING_TIMELINE_V2.ANY}>
          <PageWrapper>
            <PageHeader
              backUrl={ROUTES.APPS.LIFECYCLE.OFFBOARDING}
              title={
                <PageHeader.Title
                  actions={
                    <OnboardingActions
                      employee={employeeData}
                      onboardingData={onboardingData}
                      onRefetchOnboarding={refetchOnboardingData}
                      onRefetchDocumentsSignature={refetchDocumentsSignature}
                      onRefetchDocumentsUpload={refetchDocumentsUpload}
                      setEmailSidebarOpen={setEmailSidebarOpen}
                    />
                  }
                  labels={
                    <OnboardingSubtitle
                      onboardingData={onboardingData}
                      loading={isLoading}
                    />
                  }
                  maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}
                  title={
                    <HStack align="center" space="s-8">
                      <Title name={employeeData.full_name} />
                      <IconButton
                        aria-label="Navigate to employee profile"
                        color={Token.color.greyTone50}
                        size={16}
                        to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: params.id })}
                        use={InternalLink}
                        useIcon="Switches"
                        {...employeeProfileTooltip.getAnchorProps()}
                      />
                      <Tooltip {...employeeProfileTooltip.getTargetProps()}>
                        {employeeData.full_name} profile
                      </Tooltip>
                    </HStack>
                  }
                />
              }
            >
              <Box pb="s-32">
                <TabBarNavigation tabs={tabs} />
              </Box>
            </PageHeader>
            <Flex flex={1} flexDirection="column" minHeight={0} width="100%">
              <Box maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}>
                <Switch>
                  {tabs.map(tab => (
                    <Route exact path={tab.path} key={tab.path}>
                      {tab.component}
                    </Route>
                  ))}
                </Switch>
              </Box>
            </Flex>
          </PageWrapper>
          <SendEmailSidebar
            employeeId={params.id}
            isOpen={emailSidebarOpen}
            onClose={() => setEmailSidebarOpen(false)}
            useOnboardingSettings
          />
        </Route>
      </Switch>
    </>
  )
}
