import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import {
  dontExtendTeamAccessRequest,
  extendTeamAccessRequest,
  getTeamAccessRequests,
  getTeamAccessStatsRequest,
} from '@src/api/accessRequests'
import { TeamAccessRequestInterface } from '@src/interfaces/accessRequest'
import { CellTypes, FetchDataQueryInterface, RowInterface } from '@src/interfaces/data'
import {
  accessRequestApprover,
  accessRequestDatabaseColumn,
  accessRequestDateFromColumn,
  accessRequestDaysSinceViewed,
  accessRequestPermissionColumn,
  accessRequestReviewStatus,
  accessRequestStatusColumn,
  accessRequestUsedCount,
  accessRequestUsingPercent,
} from '@src/constants/columns/accessRequest'
import { useTable } from '@components/TableV2/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { selectorKeys } from '@src/constants/api'
import LoadingButton from '@components/Button/LoadingButton'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { Token } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TeamInterface } from '@src/interfaces/teams'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

interface Props {
  data: TeamInterface
}

interface ConfirmationInfo {
  submit: () => void
  body: ReactNode
}

const ActionsContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
`

const ActionButtons = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: ${Token.color.background};
`

const DenyExtensionButton = styled(LoadingButton)`
  background-color: ${Token.color.red};
  ${ActionButtons};
`

const ApproveExtensionButton = styled(LoadingButton)`
  background-color: ${Token.color.blue};
  ${ActionButtons};
`

const ROW: RowInterface<TeamAccessRequestInterface> = {
  highlight: data => {
    if (data.status === Statuses.pending) {
      return Token.color.red_20
    }

    if (data.status === Statuses.expired) {
      return Token.color.greyTone20
    }

    return ''
  },
  linkToForm: ({ id, team }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL, { id, teamId: team.id }),
    ),
  cells: [
    {
      ...specialisationRoleNameColumn,
      width: 155,
    },
    {
      ...accessRequestDatabaseColumn,
      width: 130,
    },
    {
      ...accessRequestPermissionColumn,
      width: 110,
    },
    {
      ...accessRequestDateFromColumn,
      width: 130,
    },
    {
      ...accessRequestApprover,
      width: 200,
    },
    {
      ...accessRequestStatusColumn,
      width: 115,
    },
    {
      ...accessRequestUsingPercent,
      width: 100,
    },
    {
      ...accessRequestUsedCount,
      width: 130,
    },
    {
      ...accessRequestDaysSinceViewed,
      width: 120,
    },
    {
      ...accessRequestReviewStatus,
      width: 250,
    },
  ],
}

const AccessRequests = ({ data }: Props) => {
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [confirmationInfo, setConfirmationInfo] = useState<null | ConfirmationInfo>()
  const initialFilter = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const table = useTable<TeamAccessRequestInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getTeamAccessRequests(requestData, data.id),
      getStats: getTeamAccessStatsRequest(data.id),
    },
    initialFilter,
  )

  const extend = async (id: number) => {
    try {
      setConfirmationLoading(true)
      await extendTeamAccessRequest(id)
      setConfirmationInfo(undefined)
      table.refresh()
      pushNotification({
        value: 'Access extended',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } finally {
      setConfirmationLoading(false)
    }
  }

  const dontExtend = async (id: number) => {
    try {
      setConfirmationLoading(true)
      await dontExtendTeamAccessRequest(id)
      setConfirmationInfo(undefined)
      table.refresh()
      pushNotification({
        value: 'Access not extended',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } finally {
      setConfirmationLoading(false)
    }
  }

  const handleExtend = async (row: TeamAccessRequestInterface) => {
    if (row.is_assigned_permission_used) {
      return extend(row.id)
    }
    return setConfirmationInfo({
      body: (
        <>
          <b>Are you sure?</b>
          <br />
          This access was not used often or at all.
        </>
      ),
      submit: () => extend(row.id),
    })
  }

  const handleDontExtend = async (row: TeamAccessRequestInterface) => {
    if (row.view_ct > 0 && row.permission === 'write') {
      return setConfirmationInfo({
        body: (
          <>
            <b>Are you sure?</b>
            <br />
            This access was used{' '}
            <b>
              {row.view_ct} time{row.view_ct > 1 ? 's' : ''}
            </b>{' '}
            to view saved questions using this database in the last 30 days. You might
            want to consider creating a "view" permission instead.
          </>
        ),
        submit: () => dontExtend(row.id),
      })
    }
    return setConfirmationInfo({
      body: (
        <>
          <b>Are you sure you want this access to expire at the end of the cycle?</b>
          <br />
          It's okay, you can submit a new request for this later on if it's necessary.
        </>
      ),
      submit: () => dontExtend(row.id),
    })
  }

  if (table?.stats?.display_extend_actions) {
    ROW.cells[9].width = 150
    ROW.cells[10] = {
      type: CellTypes.insert,
      idPoint: '-',
      dataPoint: '-',
      sortKey: null,
      filterKey: null,
      insert: ({ data: d }) => {
        if (!d?.can_extend) {
          return null
        }
        return (
          <ActionsContainer>
            <DenyExtensionButton
              icon="Close"
              iconSize="tiny"
              onSubmit={() => handleDontExtend(d)}
            />
            <ApproveExtensionButton
              icon="Check"
              iconSize="tiny"
              onSubmit={() => handleExtend(d)}
            />
          </ActionsContainer>
        )
      },
      selectorsKey: selectorKeys.none,
      title: ' ',
      width: 100,
    }
  }

  const canCreateRequests = data?.field_options?.permissions?.includes(
    PermissionTypes.AddTeamAccessRequests,
  )

  const handleNewRow = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL, { teamId: data.id }),
      {
        initialValues: { team: { id: data.id, employee: { full_name: data.name } } },
      },
    )
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Filters>
          <CycleFilterButton
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {canCreateRequests && (
              <PrimaryAction onClick={handleNewRow} useIcon="Plus">
                Create Request
              </PrimaryAction>
            )}
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<TeamAccessRequestInterface>
            name={TableNames.TeamDataAccess}
            useWindowScroll
            dataType="Access Request"
            row={ROW}
            {...table}
            emptyState={<EmptyTableRaw title="All access requests will appear here" />}
          />
        </Table.Widget.Table>
      </Table.Widget>

      <ConfirmationDialog
        open={!!confirmationInfo}
        onClose={() => setConfirmationInfo(null)}
        loading={confirmationLoading}
        onConfirm={confirmationInfo?.submit || (() => {})}
        onReject={() => setConfirmationInfo(null)}
        label="Warning"
        body={confirmationInfo?.body}
      />
    </>
  )
}

export default AccessRequests
