import { useMemo } from 'react'
import { useGetBitbucketPreferences, useGetJiraPreferences } from '@src/api/integrations'
import { PerformanceInsightsSourceOption } from '@src/features/AiInsights/Performance/interfaces/insights'
import { ExternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'
import useIsCommercial from '@src/hooks/useIsCommercial'

export interface ExternalSourceOptionAvailability
  extends PerformanceInsightsSourceOption<ExternalMetricSources> {
  enabled: boolean
}

interface Props {
  options: ExternalSourceOptionAvailability[]
  isLoading: boolean
}

export const useSourcesOptionsAvailability = (
  options: PerformanceInsightsSourceOption<ExternalMetricSources>[],
): Props => {
  const isBitbucketIntegrationEnabled = useHasFeatureFlag(
    FeatureFlags.BitbucketIntegrationEnabled,
  )
  const isCommercial = useIsCommercial()

  const { data: jiraPreferences, isLoading: isJiraPreferencesLoading } =
    useGetJiraPreferences({
      cached: false,
      enabled: !!isCommercial,
    })
  const { data: bitbucketPreferences, isLoading: isBitbucketPreferencesLoading } =
    useGetBitbucketPreferences({
      enabled: isBitbucketIntegrationEnabled && !!isCommercial,
    })

  const optionsAvailability = useMemo<ExternalSourceOptionAvailability[]>(
    () =>
      options.reduce<ExternalSourceOptionAvailability[]>((availableOptions, option) => {
        if (option.name === ExternalMetricSources.Jira) {
          return [
            ...availableOptions,
            {
              ...option,
              enabled:
                !isCommercial ||
                (!isJiraPreferencesLoading && !!jiraPreferences?.enabled),
            },
          ]
        }

        if (option.name === ExternalMetricSources.Bitbucket) {
          return [
            ...availableOptions,
            {
              ...option,
              enabled:
                !isCommercial ||
                (!isBitbucketPreferencesLoading && !!bitbucketPreferences?.enabled),
            },
          ]
        }

        return availableOptions
      }, []),
    [
      options,
      jiraPreferences?.enabled,
      isJiraPreferencesLoading,
      bitbucketPreferences?.enabled,
      isBitbucketPreferencesLoading,
    ],
  )

  return {
    options: optionsAvailability,
    isLoading: isJiraPreferencesLoading || isBitbucketPreferencesLoading,
  }
}
