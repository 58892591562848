import React, { useMemo } from 'react'
import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS } from '@src/constants/hub'
import { useEmailNotificationsEnabled } from '@src/pages/Forms/NotificationTemplate/useEmailNotificationsEnabled'
import { ROUTES } from '@src/constants/routes'

const Communication = () => {
  const emailNotificationsEnabled = useEmailNotificationsEnabled()

  const app = useMemo(() => {
    return {
      ...APPLICATIONS.communication,
      tabs: APPLICATIONS.communication.tabs.filter(tab => {
        if (tab.path === ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES) {
          return emailNotificationsEnabled
        }
        return true
      }),
    }
  }, [emailNotificationsEnabled])

  return <HubApp app={app} />
}

export default Communication
