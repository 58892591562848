import React, { useEffect } from 'react'
import {
  Token,
  Flex,
  Button,
  VStack,
  InputGroup,
  Box,
  Widget,
  Avatar,
  HStack,
  Text,
} from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CascadeGoalInterface,
  GoalContentType,
  GoalsOrganisationalUnit,
} from '@src/interfaces/goals'
import { cleanGoalCache } from '@src/pages/Forms/GoalForm/useGoalFormCache'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { CycleInput } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleInput'
import { CascadeGoalsTable } from './CascadeGoalsTable'
import { useBulkCascadeCreate } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { CycleOption, SelectorType } from '@src/interfaces/selectors'
import { useGetEmployeeCyclesSelector } from '@src/features/Goals/common/useGetEmployeeCyclesSelector'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { useLocation } from 'react-router-dom'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { adaptPerformanceSelectorToCycleOption } from '@src/utils/performance'

const CURRENT_CYCLE_OFFSET = '0'

interface Props {
  onSubmitted: () => void
  isLoadingUnits: boolean
  selectorKey: SelectorType
  defaultValue?: GoalsOrganisationalUnit
  orgUnitsSelectLabel: string
  ownerId: string
}

export const CascadeGoalFormPageBody = ({
  onSubmitted,
  isLoadingUnits,
  selectorKey,
  defaultValue,
  orgUnitsSelectLabel,
  ownerId,
}: Props) => {
  const location = useLocation<{
    entity: OrgEntityInterface
  }>()
  const { mutateAsync: createCascadeGoals, isLoading: isSubmitting } =
    useBulkCascadeCreate()
  const { data: contentTypes } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )

  const goalForm = useLapeContext<CascadeGoalInterface>()

  const { values } = goalForm
  const { cycleSelector, initialCycle } = useGetEmployeeCyclesSelector(
    values.content_type?.model === 'employees' ? values.content_object.id : undefined,
    true,
  )

  const { data: reviewCycles } = useGetSelectors<CycleOption>(selectorKeys.review_cycles)

  const { filter: contentTypeFilter, isLoading: isLoadingContentType } =
    useGetGoalContentTypeFilter(
      values.organisational_units?.content_type_model || undefined,
    )

  const errorPopup = useErrorPopup()

  const targetCycle = reviewCycles?.find(
    cycle => String(cycle.offset) === CURRENT_CYCLE_OFFSET,
  )

  const selectedCycle =
    values.content_type?.model === 'employees'
      ? initialCycle
        ? adaptPerformanceSelectorToCycleOption(initialCycle)
        : undefined
      : targetCycle

  useEffect(() => {
    values.cycle = selectedCycle
      ? { id: selectedCycle.id, name: selectedCycle.name }
      : undefined
  }, [selectedCycle])

  const onSubmit = async () => {
    try {
      await createCascadeGoals(
        {
          owner: values.owner,
          content_type:
            contentTypes?.find(({ model }) => model === location.state.entity.type) ||
            null,
          object_id: location.state.entity.data.id,
          parent_goals: values.parent_goals,
        },
        {},
      )
      onSubmitted()
      cleanGoalCache(values.id)
    } catch (error) {
      errorPopup.show({
        fallbackTitle: 'Failed to submit',
        error,
      })
    }
  }

  useEffect(() => {
    if (defaultValue) {
      values.organisational_units = defaultValue
    }
  }, [defaultValue])

  useEffect(() => {
    values.owner = { id: Number(ownerId) }
  }, [ownerId])

  const isGoalsTableVisible = values.organisational_units?.id && values.cycle
  const childContentTypeId =
    contentTypes?.find(({ model }) => model === location.state.entity.type)?.id || null

  return (
    <PageBody maxWidth={{ all: Token.breakpoint.lg, xxl: Token.breakpoint.xl }}>
      <VStack space="s-24" px="s-16">
        <Widget p="s-16">
          <HStack align="center" gap="s-16" mb="s-16">
            <Avatar useIcon="Target" />
            <Text variant="heading3">General info</Text>
          </HStack>
          <InputGroup>
            <LapeRadioSelectInput
              selector={selectorKeys.employee}
              name="owner"
              label="Owner"
              required
            />
            <LapeRadioSelectInput
              loading={isLoadingUnits || isLoadingContentType}
              name="organisational_units"
              selector={selectorKey}
              label={orgUnitsSelectLabel}
            />
            <CycleInput
              value={selectedCycle}
              selector={
                values.content_type?.model === 'employees'
                  ? cycleSelector
                  : selectorKeys.review_cycles
              }
              label="Review cycle"
              onChange={(cycle: CycleOption) => {
                values.cycle = cycle
              }}
            />
          </InputGroup>
          <Box mt="s-16">
            {isGoalsTableVisible && (
              <CascadeGoalsTable
                childObjectId={location.state.entity.data.id}
                childContentTypeId={childContentTypeId}
                cycle={values.cycle}
                contentTypeFilter={contentTypeFilter}
                is_company={values.organisational_units.is_company}
                object_id={values.organisational_units.id}
              />
            )}
          </Box>
        </Widget>
      </VStack>

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          <Button
            disabled={!values.parent_goals?.length}
            onClick={onSubmit}
            pending={isSubmitting}
            elevated
          >
            Submit
          </Button>
        </Flex>
      </PageActions>
    </PageBody>
  )
}
