import { API } from '@src/constants/api'
import { RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '.'
import {
  FeedbackCategory,
  AnytimeFeedbackInterface,
  AnytimeFeedbackLabel,
  AnytimeFeedbackListInterface,
} from '@src/interfaces/anytimeFeedback'
import { useFetchV2, UseQueryOptions } from '@src/utils/reactQuery'
import { AxiosPromise } from 'axios'

export const getAnytimeFeedbackRequests: (
  category: FeedbackCategory,
) => RequestInterfaceNew<AnytimeFeedbackInterface> = category => ({
  get: async ({ userId, id }) => api.get(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`),
  update: async (data, { userId, id }) => {
    return apiWithoutHandling.put(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`, data)
  },
  submit: async (data, { userId }) => {
    return apiWithoutHandling.post(`${API.EMPLOYEE_FEEDBACK}/${userId}`, {
      ...data,
      category,
    })
  },
})

export const deleteAnytimeFeedback = (
  userId: number,
  id: number,
): AxiosPromise<AnytimeFeedbackInterface> =>
  api.delete(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`)

interface AnytimeFeedbackParams {
  nominationId: number
  dateRange: string
  category: FeedbackCategory
}

export const useGetAllFeedbackList = (
  userId: number,
  params: Partial<AnytimeFeedbackParams> = {},
  queryOptions: UseQueryOptions<AnytimeFeedbackListInterface> = {},
) =>
  useFetchV2<AnytimeFeedbackListInterface>({
    url: `${API.EMPLOYEE_FEEDBACK}/${userId}`,
    params: {
      params: {
        ...(params.nominationId ? { nomination_id: params.nominationId } : {}),
        ...(params.dateRange ? { creation_date_time: params.dateRange } : {}),
        ...(params.category ? { category: params.category } : {}),
      },
    },
    queryOptions: {
      enabled: userId !== undefined && queryOptions.enabled !== false,
    },
  })

export const getAnytimeFeedbackLabels = (
  userId: string,
): AxiosPromise<AnytimeFeedbackLabel[]> =>
  api.get(`${API.EMPLOYEE_FEEDBACK}/${userId}/labels`)
