import React, { type RefObject, useCallback, useRef } from 'react'
import {
  Cell,
  Group,
  type SelectOptionItemType,
  useVirtualViewport,
} from '@revolut/ui-kit'

export interface RadioSelectMobileGroupProps<T extends number | object | string> {
  scrollRef: RefObject<HTMLElement | null>
  options: SelectOptionItemType<T>[]
  onChange: (value: T | null) => Promise<void> | void
  children: (props: { item: SelectOptionItemType<T> }) => React.ReactNode | string
}

const DEFAULT_ESTIMATE_SIZE = 76
const DEFAULT_OVERSCAN_SIZE = 16

export const RadioSelectMobileGroup = <T extends number | object | string = string>({
  scrollRef,
  options,
  onChange,
  children,
}: RadioSelectMobileGroupProps<T>) => {
  const itemsGroupRef = useRef<HTMLDivElement | null>(null)
  const estimateSize = useCallback(() => DEFAULT_ESTIMATE_SIZE, [])
  const itemExtractor = useCallback((index: number) => options[index], [options])

  const virtualList = useVirtualViewport({
    parentRef: itemsGroupRef,
    size: options.length,
    overscan: DEFAULT_OVERSCAN_SIZE,
    estimateSize,
    scrollRef,
  })

  return (
    <Group
      ref={itemsGroupRef}
      style={{
        position: 'relative',
        height: `${virtualList.totalSize}px`,
      }}
    >
      {virtualList.virtualItems.map(item => (
        <Cell
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            minHeight: `${item.size}px`,
            transform: `translateY(${item.start}px)`,
          }}
          ref={item.measureRef}
          key={itemExtractor(item.index).key}
          use="button"
          onClick={() => onChange(itemExtractor(item.index).value)}
        >
          {children({ item: itemExtractor(item.index) })}
        </Cell>
      ))}
    </Group>
  )
}
