import React from 'react'
import { DragAndDrop } from '@revolut/ui-kit'

export const getDNDItemStylesOverrides = (
  sortable: DragAndDrop.DefaultSortableItemState<any>,
): React.CSSProperties => ({
  transform: sortable.transform
    ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
    : undefined,
  transition: sortable.transition || 'none',
  opacity: sortable.isDragging ? 0 : undefined,
})
