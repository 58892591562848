import React from 'react'
import {
  Badge,
  DataPoint,
  Dropdown,
  Flex,
  H5,
  Highlights,
  IconButton,
  Skeleton,
  Spinner,
  Tag,
  Text,
  TextSkeleton,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { AiGenerationStatus, MeetingNotesFeedback } from '@src/interfaces/meetings'
import { differenceInMinutes, format, isPast } from 'date-fns'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { deleteMeetingNotesFeedback } from '@src/api/meetings'
import { PermissionTypes } from '@src/store/auth/types'
import { meetingDuration } from '../utils/meetingDuration'
import { getPrivateContentItemsByRole } from '../utils/getContentItemsByRole'
import { FinalGrade } from '@src/interfaces/performance'
import { getInverseColorsAndGrade } from '@src/components/PerformanceGrade/PerformanceGrade'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { toGradesMap } from '@src/utils/grades'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { PerformanceGradeOption } from '@src/interfaces/selectors'

interface Props {
  meetingNotes: MeetingNotesFeedback | undefined
  isLoading: boolean
  isLoadingPerformanceRating: boolean
  meetingId?: number
  refetchMeetingNotesFeedback: VoidFunction
  onPerformanceRatingChange: (value: { name: string; value: FinalGrade } | null) => void
  disabled: boolean
  isCompletedEvent: boolean
}

const NotesSkeleton = () => (
  <Flex p="s-16" alignItems="center" justifyContent="space-between">
    <Flex gap="s-48" justifyContent="space-between">
      <Flex gap="s-4" flexDirection="column" alignItems="flex-start">
        <Flex gap="s-8" alignItems="center">
          <TextSkeleton size={20} variant="heading3" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>

        <Flex gap="s-8" justifyContent="flex-start">
          <TextSkeleton size={14} variant="body2" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>
      </Flex>

      <Flex gap="s-8" flexDirection="column">
        <Flex gap="s-8" alignItems="center">
          <Skeleton height={24} width={150} />
          <TextSkeleton size={14} variant="body2" />
        </Flex>

        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex alignItems="center" gap="s-6">
      <TextSkeleton size={14} variant="body2" />
      <TextSkeleton size={14} variant="body2" />
    </Flex>
  </Flex>
)

export const NotesHeader = ({
  meetingNotes,
  isLoading,
  isLoadingPerformanceRating,
  meetingId,
  onPerformanceRatingChange,
  refetchMeetingNotesFeedback,
  isCompletedEvent,
  disabled,
}: Props) => {
  const { employeeId, managerId } =
    useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const performanceRatingData = getPrivateContentItemsByRole(
    meetingNotes,
    'performance_rating',
  )?.content

  const { data: grades, isLoading: isLoadingGrades } =
    useGetSelectors<PerformanceGradeOption>(selectorKeys.grades)

  const gradesMap = grades ? toGradesMap(grades) : undefined

  const performanceRatingOptions = [
    { name: null, value: null },
    ...(performanceRatingData?.content || []),
  ]
  const performanceRatingValue = performanceRatingData?.value
  const actionsDropdown = useDropdown()
  const performanceRatingDropdown = useDropdown()

  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const canChangeNotes = meetingNotes?.field_options.permissions?.includes(
    PermissionTypes.ChangeOneToOneFeedbackItem,
  )

  const getPerformanceRatingLabel = () => {
    const grade = getInverseColorsAndGrade(
      performanceRatingValue?.value,
      gradesMap,
    )?.grade

    if (disabled) {
      return grade || 'N/A'
    }
    return grade || 'Select Rating'
  }

  const performanceRatingLabel = getPerformanceRatingLabel()

  const handleDelete = () => {
    confirmationPopup.show({
      showHeader: false,
      body: (
        <H5 color={Token.color.foreground}>
          Are you sure you want to delete this notes?
        </H5>
      ),
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        if (meetingNotes?.id && meetingId) {
          await deleteMeetingNotesFeedback(meetingId, meetingNotes.id)
          showStatusPopup({
            title: 'Notes deleted',
            status: 'success',
          })
          refetchMeetingNotesFeedback()
        }
      },
    })
  }

  if (isLoading || isLoadingGrades) {
    return <NotesSkeleton />
  }

  if (!meetingNotes) {
    return null
  }

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex gap="s-48" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start">
          <Highlights>
            <DataPoint>
              <DataPoint.Value>
                <Flex alignItems="center" gap="s-4">
                  <Text variant="heading3">
                    {format(new Date(meetingNotes.meeting.start), 'MMM d')}
                  </Text>
                  <Badge bg={Token.color.searchBackground} color={Token.color.grey50}>
                    {isPast(new Date(meetingNotes.meeting.end)) ? 'Previous' : 'Future'}
                  </Badge>
                </Flex>
              </DataPoint.Value>
              <DataPoint.Label>
                <Flex alignItems="center" gap="s-4">
                  <Text variant="body2">
                    {meetingDuration(
                      meetingNotes.meeting.start,
                      meetingNotes.meeting.end,
                    )}
                  </Text>
                  <Text variant="body2" color={Token.color.grey50}>
                    {differenceInMinutes(
                      new Date(meetingNotes.meeting.end),
                      new Date(meetingNotes.meeting.start),
                    )}
                    min
                  </Text>
                </Flex>
              </DataPoint.Label>
            </DataPoint>
          </Highlights>
        </Flex>

        {meetingNotes.recorded_by && (
          <Highlights>
            <DataPoint>
              <DataPoint.Value>
                <Flex gap="s-4" alignItems="center">
                  <UserWithAvatar compact {...meetingNotes.recorded_by} />
                  <Text variant="heading3">{meetingNotes.recorded_by.full_name}</Text>
                </Flex>
              </DataPoint.Value>
              <DataPoint.Label>
                <Text variant="body2">Notes taken by</Text>
              </DataPoint.Label>
            </DataPoint>
          </Highlights>
        )}

        {(isCompletedEvent || performanceRatingData?.value?.value) &&
          performanceRatingData && (
            <Highlights>
              <DataPoint>
                <DataPoint.Value>
                  <Flex
                    {...(disabled ? {} : performanceRatingDropdown.getAnchorProps())}
                    style={{ cursor: disabled ? 'default' : 'pointer' }}
                    gap="s-4"
                    alignItems="center"
                  >
                    <Text
                      variant="heading3"
                      color={
                        getInverseColorsAndGrade(performanceRatingValue?.value, gradesMap)
                          ?.color
                      }
                    >
                      {performanceRatingLabel}
                    </Text>
                    {!disabled && (
                      <IconButton size={16} useIcon="ChevronDown" aria-label="Close" />
                    )}
                    {isLoadingPerformanceRating && <Spinner size={16} />}
                  </Flex>

                  <Dropdown
                    autoClose
                    {...(disabled ? {} : performanceRatingDropdown.getTargetProps())}
                  >
                    {performanceRatingOptions?.map(rating => {
                      return (
                        <Dropdown.Item
                          key={rating.value}
                          use="button"
                          onClick={() =>
                            onPerformanceRatingChange(rating.value ? rating : null)
                          }
                        >
                          {rating.value ? (
                            <Text
                              color={
                                getInverseColorsAndGrade(rating.value, gradesMap)?.color
                              }
                            >
                              {getInverseColorsAndGrade(rating.value, gradesMap)?.grade}
                            </Text>
                          ) : (
                            <Text>-</Text>
                          )}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown>
                </DataPoint.Value>
                <DataPoint.Label>Grade</DataPoint.Label>
              </DataPoint>
            </Highlights>
          )}
      </Flex>

      <Flex alignItems="center" gap="s-8">
        {meetingNotes.enable_ai &&
        (meetingNotes.summary_status === AiGenerationStatus.COMPLETED ||
          meetingNotes.has_transcript) ? (
          <Tag color={Token.color.lightBlue} variant="outlined" useIcon="Cleaning">
            AI Transcript
          </Tag>
        ) : (
          <Tag color={Token.color.greyTone50} variant="outlined" useIcon="Pencil">
            Manual
          </Tag>
        )}
        {canChangeNotes && (
          <IconButton
            useIcon="Ellipsis"
            aria-label="More Actions"
            {...actionsDropdown.getAnchorProps()}
          />
        )}
        <Dropdown {...actionsDropdown.getTargetProps()}>
          <Dropdown.Item
            use={InternalLink}
            useIcon="History"
            to={pathToUrl(
              ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.CHANGELOG,
              {
                employeeId,
                meetingId,
                managerId,
              },
            )}
          >
            Change log
          </Dropdown.Item>
          <Dropdown.Item
            use="button"
            useIcon="Delete"
            color={Token.color.danger}
            onClick={handleDelete}
          >
            Delete notes
          </Dropdown.Item>
        </Dropdown>
      </Flex>
    </Flex>
  )
}
