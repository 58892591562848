import {
  ColumnCellInterface,
  FilterByInterface,
  FilterOption,
  SORT_DIRECTION,
  SortByInterface,
} from '../interfaces/data'
import { stripDiacritics } from './filters'
import { OptionInterface } from '../interfaces/selectors'
import xxhashjs from 'xxhashjs'
import { MatrixColumnCellInterface } from '@components/Table/MatrixTable'
import get from 'lodash/get'
import { TableTypes } from '@src/interfaces/table'
import { ADJUSTABLE_TABLE_PADDING, TABLE_PADDING } from '@components/TableV2/constants'
import { ApiQueryParams } from '@src/api/types'

export const filterOptions = (
  inputValue: string,
  options: FilterOption[],
  value?: OptionInterface[],
) => {
  return options.filter(option => {
    if (value?.length && value?.some(selected => selected.id === option.id)) {
      return false
    }
    return inputValue
      .toLowerCase()
      .split(' ')
      .every(
        (searchWord: string) =>
          option.name && stripDiacritics(option.name).toLowerCase().includes(searchWord),
      )
  })
}

export const connectFilterBy = (filters: FilterOption[]) => {
  return filters.map(filter => filter.id).join(',')
}

export const groupFiltersIntoURLQuery = (filters?: FilterByInterface[]) => {
  if (!filters || !filters.length) {
    return ''
  }

  return filters.reduce((filterArr, filter) => {
    if (filter && filter.filters.length) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      filterArr[filter.columnName.toLowerCase()] = connectFilterBy(filter.filters)
    }
    return filterArr
  }, {})
}

export const groupSortByIntoURLQuery = (sortBy?: SortByInterface[]) => {
  if (!sortBy || !sortBy.length) {
    return ''
  }

  return sortBy
    .map(
      singleSortBy =>
        `${singleSortBy.direction === SORT_DIRECTION.ASC ? '-' : ''}${
          singleSortBy.sortBy
        }`,
    )
    .join(',')
}

export const filterSortPageIntoQuery = (
  sortBy?: SortByInterface[],
  filters?: FilterByInterface[],
  page?: number,
): ApiQueryParams => {
  const filter_by = groupFiltersIntoURLQuery(filters)
  let ordering = ''
  if (sortBy) {
    ordering = groupSortByIntoURLQuery(sortBy)
  }

  return {
    page,
    ordering,
    ...filter_by,
  }
}

export const bulkNormalizeWidth = (
  cells: (ColumnCellInterface<any> | MatrixColumnCellInterface<any>)[],
  type: TableTypes,
  containerWidth?: number,
  lockFirstColumn?: boolean,
) => {
  const map = {
    [TableTypes.Form]: window.innerWidth - 154,
    [TableTypes.Stepper]: window.innerWidth - 234,
    [TableTypes.NewStepper]: window.innerWidth - 324,
    [TableTypes.Matrix]: window.innerWidth - 234,
  }
  /** Reduce by 2/4 pixels to account for the table border/padding */
  const availableSpace =
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    (containerWidth || map[type]) -
    (type === TableTypes.Adjustable ? ADJUSTABLE_TABLE_PADDING : TABLE_PADDING)
  const halfOfAvailableSpace = availableSpace / 2
  const allCellsWidths = cells.reduce((total, cell) => cell.width + total, 0)
  const fillAllAvailableSpaceRatio =
    availableSpace > allCellsWidths ? availableSpace / allCellsWidths : 1

  return cells.map((cell, index) => {
    /** First column cannot take up more than half of the available space if it's fixed, because other columns wont be visible  */
    if (index === 0 && lockFirstColumn && cell.width > halfOfAvailableSpace) {
      cell.width = halfOfAvailableSpace
    }

    return {
      ...cell,
      width: cell.width * fillAllAvailableSpaceRatio,
    }
  })
}

type FilterByInterfaceMap = {
  [key: string]: FilterByInterface
}

export const queryToFilter = (
  queries: {
    [key: string]: string
  },
  filterByInitial: FilterByInterface[],
): FilterByInterface[] => {
  const filterMap: FilterByInterfaceMap = Object.fromEntries(
    filterByInitial.map(f => [f.columnName, f]),
  )
  return Object.entries(queries)
    .filter(([name]) => !filterMap[name]?.disableQueryParam)
    .map(([name, query]) => {
      const values = query ? query.split(',') : []
      return {
        columnName: name,
        filters: values.map(val => ({
          name: val,
          id: val,
        })),
      }
    })
}

export const queryToSort = (query: string): SortByInterface[] => {
  if (!query) {
    return []
  }
  const values = query.split(',')

  return values.map(val => {
    const asc = val[0] === '-'
    if (asc) {
      return {
        sortBy: val.slice(1),
        direction: SORT_DIRECTION.ASC,
      }
    }
    return {
      sortBy: val,
      direction: SORT_DIRECTION.DESC,
    }
  })
}

export const sortToString = (sortBy: SortByInterface[]) => {
  return sortBy
    .map(sort => `${sort.direction === SORT_DIRECTION.ASC ? '-' : ''}${sort.sortBy}`)
    .join(',')
}
export const filterToString = (filter: FilterByInterface) => {
  return filter.filters.map(fil => fil.id).join(',')
}

export const generateKey = (rowData: any, key?: string) => {
  const { children, ...rest } = rowData
  // if rowData contains nested objects keys could be duplicated, adding this key will fix the issue
  const values = key ? { ...rest, internalKey: get(rest, key) } : rest

  return xxhashjs.h32(Object.values(values).join(''), 0xabcd).toString(16)
}

export const withoutFilterAndSort = <T>(cells: ColumnCellInterface<T>[]) => {
  return cells.map(cell => ({ ...cell, filterKey: null, sortKey: null }))
}
