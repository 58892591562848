import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ActionButton, ActionButtonSkeleton, ItemSkeleton, VStack } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { EmptyList } from '@src/features/AiInsights/Performance/components/InsightsGroups/common/EmptyList'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import {
  InsightsGroupItem,
  toInsightsGroupItemProps,
} from '@src/features/AiInsights/Performance/components/InsightsGroups/ItemsList/Item'
import { useIsPerformanceAiInsightsEnabled } from '@src/features/AiInsights/Performance/hooks/useAiInsightsAvailability'
import { navigateTo } from '@src/actions/RouterActions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useDeleteInsightsGroup } from '@src/features/AiInsights/Performance/api/insights'

interface Props {
  isLoading: boolean
  groups: PerformanceInsightsGroupInterface[]
  refetch: VoidFunction
}

export const InsightsGroupItemsList = ({ groups, isLoading, refetch }: Props) => {
  const isPerformanceAiInsightsEnabled = useIsPerformanceAiInsightsEnabled()
  const { mutateAsync: deleteInsightsGroup } = useDeleteInsightsGroup()

  const confirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  const handleOnEditClick = (id: number) => {
    navigateTo(pathToUrl(ROUTES.FORMS.PERFORMANCE_INSIGHTS.EDIT, { id }))
  }

  const handleOnDeleteClick = (id: number) => {
    confirmationDialog.show({
      body: (
        <FormattedMessage
          id="performance.ai.insights.groups.delete.confirmation.title"
          defaultMessage="Are you sure you want to delete this inisghts group?"
        />
      ),
      onConfirm: async () => {
        try {
          await deleteInsightsGroup(id)
          refetch()
          showStatusPopup({
            title: (
              <FormattedMessage
                id="performance.ai.insights.groups.delete.success.title"
                defaultMessage="Insights group was successfully deleted"
              />
            ),
          })
        } catch (error) {
          errorPopup.show({
            error,
            fallbackTitle: 'Failed to delete insights group',
          })
        }
      },
    })
  }

  if (isLoading) {
    return <Skeleton />
  }

  if (!isLoading && !groups.length) {
    return (
      <EmptyList
        title={
          <FormattedMessage
            id="performance.ai.insights.groups.empty.list.title"
            defaultMessage="No insight groups added"
          />
        }
        actions={
          <ActionButton
            useIcon="Plus"
            disabled={!isPerformanceAiInsightsEnabled}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.PERFORMANCE_INSIGHTS.CREATE)}
          >
            <FormattedMessage
              id="performance.ai.insights.groups.add.group.cta.label"
              defaultMessage="Add group"
            />
          </ActionButton>
        }
        imageId="3d-images-v2/3D028"
      />
    )
  }

  return (
    <VStack space="s-16">
      {groups.map(props => (
        <InsightsGroupItem
          key={props.id}
          {...toInsightsGroupItemProps(props)}
          onEditClick={() => handleOnEditClick(props.id)}
          onDeleteClick={() => handleOnDeleteClick(props.id)}
        />
      ))}
      <ActionButton
        useIcon="Plus"
        disabled={!isPerformanceAiInsightsEnabled}
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.PERFORMANCE_INSIGHTS.CREATE)}
      >
        <FormattedMessage
          id="performance.ai.insights.groups.add.group.cta.label"
          defaultMessage="Add group"
        />
      </ActionButton>
    </VStack>
  )
}

const Skeleton = () => (
  <VStack space="s-16">
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
    <ActionButtonSkeleton width={150} />
  </VStack>
)
