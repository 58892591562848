import { CDN_BUCKET } from '@src/constants/externalLinks'

const BASE = `${CDN_BUCKET}/rev10x`

export const REV_10X_ASSETS = {
  heroBg: `${BASE}/hero_bg_v2.png`,
  footerBg: `${BASE}/footer.png`,
  carlos: `${BASE}/carlos.png`,
  franceExpansion: `${BASE}/france_expansion.png`,
  aiCustomerChatbot: `${BASE}/ai_customer_service_chatbot.png`,
  esim: `${BASE}/esim.png`,
  gabrielLopez: `${BASE}/gabriel_lopez.png`,
  mehvish: `${BASE}/mehvish.png`,
  projectMeru: `${BASE}/project_meru.png`,
  csSupport: `${BASE}/cs_support.png`,
  revPayJoint: `${BASE}/rev_pay_joint.png`,
  fincrime: `${BASE}/fincrime.png`,
  revBusiness: `${BASE}/revolut_business_5.png`,
  services: `${BASE}/services.png`,
  finOnbording: `${BASE}/finance_onboarding.png`,
  silvija: `${BASE}/silvija.png`,

  europeExpansion: `${BASE}/europe_expansion.png`,
  strategicPlanning: `${BASE}/strategic_planning.mp4`,
  badges: {
    deliverWow: `${BASE}/badges/deliver_wow.png`,
    thinkDeeper: `${BASE}/badges/think_deeper.png`,
    getItDone: `${BASE}/badges/get_it_done.png`,
    neverSettle: `${BASE}/badges/never_settle.png`,
    dreamTeam: `${BASE}/badges/dream_team.png`,
  },
  winners: {
    hernandez: `${BASE}/winners/hernandez.png`,
    zawadzka: `${BASE}/winners/zawadzka.png`,
    dickinson: `${BASE}/winners/dickinson.png`,
    mitalaite: `${BASE}/winners/mitalaite.png`,
    soriano: `${BASE}/winners/soriano.png`,
    moalic: `${BASE}/winners/moalic.png`,
    aijaz: `${BASE}/winners/aijaz.png`,
    bhat: `${BASE}/winners/bhat.png`,
    willems: `${BASE}/winners/theodore_willems.png`,
    azevedo: `${BASE}/winners/azevedo.png`,
    hervas: `${BASE}/winners/hervas.png`,
    korovina: `${BASE}/winners/korovina.png`,
    montasell: `${BASE}/winners/montasell.png`,
    munteanu: `${BASE}/winners/munteanu.png`,
    ronyBatista: `${BASE}/winners/rony_batista.png`,
    vovk: `${BASE}/winners/vovk.png`,
    motrenko: `${BASE}/winners/motrenko.png`,

    pilon: `${BASE}/winners/pilon.png`,
    twist: `${BASE}/winners/twist.png`,
    hayes: `${BASE}/winners/hayes.png`,
    nistor: `${BASE}/winners/nistor.png`,
    oliveira: `${BASE}/winners/oliveira.png`,
    dovey: `${BASE}/winners/dovey.png`,
    carasqueira: `${BASE}/winners/carasqueira.png`,
    zinonos: `${BASE}/winners/zinonos.png`,
    kenth: `${BASE}/winners/kenth.png`,
    stevenson: `${BASE}/winners/stevenson.png`,
    todd: `${BASE}/winners/todd.png`,
    sett: `${BASE}/winners/sett.png`,
    panetta: `${BASE}/winners/panetta.png`,
    bretherton: `${BASE}/winners/bretherton.png`,
    conde: `${BASE}/winners/conde.png`,

    cahuzac: `${BASE}/winners/cahuzac.png`,
    mengolini: `${BASE}/winners/mengolini.png`,
    white: `${BASE}/winners/white.png`,
    gunturi: `${BASE}/winners/gunturi.png`,
    vitoshnev: `${BASE}/winners/vitoshnev.png`,
    poirier: `${BASE}/winners/poirier.png`,
    maestrini: `${BASE}/winners/maestrini.png`,
    furlan: `${BASE}/winners/furlan.png`,
    nie: `${BASE}/winners/nie.png`,

    sadou: `${BASE}/winners/sadou.png`,
    ochrymowicz: `${BASE}/winners/ochrymowicz.png`,
    christofidou: `${BASE}/winners/christofidou.png`,
    shylenko: `${BASE}/winners/shylenko.png`,
    gonzalez: `${BASE}/winners/gonzalez.png`,

    armonavicius: `${BASE}/winners/armonavicius.png`,
    kanovich: `${BASE}/winners/kanovich.png`,
    coubrough: `${BASE}/winners/coubrough.png`,
    pocklington: `${BASE}/winners/pocklington.png`,
    bankauskas: `${BASE}/winners/bankauskas.png`,
    fitzpatrick: `${BASE}/winners/fitzpatrick.png`,
    shahbazov: `${BASE}/winners/shahbazov.png`,

    borathar: `${BASE}/winners/borathar.png`,
    lyssiotis: `${BASE}/winners/lyssiotis.png`,
    nellepalli: `${BASE}/winners/nellepalli.png`,
    muni: `${BASE}/winners/muni.png`,
    nagaraja: `${BASE}/winners/nagaraja.png`,
    ramchandani: `${BASE}/winners/ramchandani.png`,

    bents: `${BASE}/winners/bents.png`,
    nazarenko: `${BASE}/winners/nazarenko.png`,
    samarkhanov: `${BASE}/winners/samarkhanov.png`,
    raimbekov: `${BASE}/winners/raimbekov.png`,
    reszke: `${BASE}/winners/reszke.png`,
    malek: `${BASE}/winners/malek.png`,
    radu: `${BASE}/winners/radu.png`,
    bansal: `${BASE}/winners/bansal.png`,
    piscuc: `${BASE}/winners/piscuc.png`,
    semin: `${BASE}/winners/semin.png`,
    ziaber: `${BASE}/winners/ziaber.png`,

    maseckyte: `${BASE}/winners/maseckyte.png`,
    agarwal: `${BASE}/winners/agarwal.png`,
    nettleton: `${BASE}/winners/nettleton.png`,
    picot: `${BASE}/winners/picot.png`,
    ozonas: `${BASE}/winners/ozonas.png`,
    barczykowski: `${BASE}/winners/barczykowski.png`,
    watson: `${BASE}/winners/watson.png`,
    grude: `${BASE}/winners/grude.png`,
    sala: `${BASE}/winners/sala.png`,
    mcgill: `${BASE}/winners/mcgill.png`,
  },
}
