import React from 'react'
import { Input, InputProps } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { WorkScheduleWorkingDay } from '@src/interfaces/workSchedule'

export type WorkDaySettingsErrors = {
  work_start_time?: string[]
  work_end_time?: string[]
  break_start_time?: string[]
  break_end_time?: string[]
}

export type DayOption = IdAndName<WorkScheduleWorkingDay>

export const isEmptyWorkDaysError = (errors: Array<string | WorkDaySettingsErrors>) => {
  // TODO: REVPI-3 Use BE error codes when implemented
  return errors[0] === 'This field may not be null.'
}

export const parseWorkDaySettingsErrors = (
  workDaySettingsErrors: WorkDaySettingsErrors | string | undefined,
) => {
  const settingsErrors =
    typeof workDaySettingsErrors === 'object'
      ? workDaySettingsErrors
      : {
          work_start_time: [],
          work_end_time: [],
          break_start_time: [],
          break_end_time: [],
        }
  const workStartTime =
    'work_start_time' in settingsErrors && Array.isArray(settingsErrors.work_start_time)
      ? settingsErrors.work_start_time[0]
      : undefined
  const workEndTime =
    'work_end_time' in settingsErrors && Array.isArray(settingsErrors.work_end_time)
      ? settingsErrors.work_end_time[0]
      : undefined
  const breakStartTime =
    'break_start_time' in settingsErrors && Array.isArray(settingsErrors.break_start_time)
      ? settingsErrors.break_start_time[0]
      : undefined
  const breakEndTime =
    'break_end_time' in settingsErrors && Array.isArray(settingsErrors.break_end_time)
      ? settingsErrors.break_end_time[0]
      : undefined

  return {
    workStartTime,
    workEndTime,
    breakStartTime,
    breakEndTime,
  }
}

export const TimeSteppedInput = (props: InputProps) => {
  const { value } = props
  const allowedMinsStep = 5

  const minutes = typeof value === 'string' ? value?.split(':')[1] : undefined

  const minutesValueNotRounded =
    typeof minutes === 'string' ? Number(minutes) % allowedMinsStep !== 0 : undefined
  const stepError = minutesValueNotRounded
    ? `Time must be in ${allowedMinsStep}-minute increments`
    : undefined

  return (
    <Input
      type="time"
      step={300} // 5 mins
      {...props}
      invalid={props.invalid || minutesValueNotRounded}
      errorMessage={props.errorMessage || stepError}
    />
  )
}
