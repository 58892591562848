import { PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const useProbationPermissions = () => {
  const hasProbationCommitteeHRPermissions = useHasGlobalPermission(
    PermissionTypes.ProbationCommitteeHRPermissions,
  )
  return {
    hasProbationCommitteeHRPermissions,
  }
}
