import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Absolute, IconButton, Token } from '@revolut/ui-kit'
import { ReviewInterface } from '@src/interfaces/performance'
import Icon from '@components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  BtnWrapper,
  Circle,
  ButtonTooltip,
  Container,
  Count,
  StyledTooltip,
} from '@src/components/AssessButtons/CommonSC'
import { useGetSkillsSettings } from '@src/api/settings'

export type JustificationIndicator = 'count' | 'dot'

export interface AssessButtonsProps {
  checked: AssessBehaviourButtonTypes[]
  disabled?: boolean
  error?: boolean
  justificationsCount: number
  justificationIndicator?: JustificationIndicator
  name?: string
  onChange?: (value: AssessBehaviourButtonTypes) => void
  onMessageClick?: () => void
  showCounts?: boolean
  superpowersCount: number
  reviewsList?: TooltipInterface
}

export enum AssessBehaviourButtonTypes {
  positive = 'superpower',
  negative = 'improvement_needed',
  neutral = 'performing',
  unknown = 'dont_know',
}

const visibleButtons = [
  AssessBehaviourButtonTypes.negative,
  AssessBehaviourButtonTypes.neutral,
  AssessBehaviourButtonTypes.positive,
  AssessBehaviourButtonTypes.unknown,
]

interface TooltipInterface {
  [AssessBehaviourButtonTypes.positive]?: ReviewInterface[]
  [AssessBehaviourButtonTypes.negative]?: ReviewInterface[]
  [AssessBehaviourButtonTypes.neutral]?: ReviewInterface[]
  [AssessBehaviourButtonTypes.unknown]?: ReviewInterface[]
}

const negativeBtnCSS = css`
  border: 1px solid ${Token.color.orange};
  background-color: ${Token.color.orange_5};
  color: ${Token.color.orange};
  &:active {
    color: ${Token.color.orange};
  }
`
const neutralBtnCSS = css`
  border: 1px solid ${Token.color.teal_50};
  background-color: ${Token.color.teal_5};
  color: ${Token.color.teal_50};
  &:active {
    color: ${Token.color.teal_50};
  
`
const positiveBtnCSS = css`
  border: 1px solid ${Token.color.teal};
  background-color: ${Token.color.teal_5};
  color: ${Token.color.teal};
  &:active {
    color: ${Token.color.teal};
  }
`
const unknownBtnCSS = css`
  border: 1px solid ${Token.color.greyTone20};
`
const ButtonStyled = styled.button<{
  checked: boolean
  styles: AssessBehaviourButtonTypes
}>`
  border: 1px solid
    ${props => (props.disabled ? Token.color.greyTone20 : Token.color.greyTone50)};
  box-sizing: border-box;
  border-radius: 4px;
  width: 28px;
  height: 24px;
  background-color: ${props => (props.disabled ? Token.color.background : 'inherit')};
  color: ${props => (props.disabled ? Token.color.greyTone20 : Token.color.greyTone50)};

  cursor: ${props => {
    if (props.disabled) {
      return 'not-allowed'
    }

    if (props.checked) {
      return 'auto'
    }

    return 'pointer'
  }};
  &:active {
    color: ${props => (props.disabled ? Token.color.greyTone20 : Token.color.greyTone50)};
  }
  ${props => {
    if (props.checked) {
      if (props.styles === AssessBehaviourButtonTypes.positive) {
        return positiveBtnCSS
      }

      if (props.styles === AssessBehaviourButtonTypes.negative) {
        return negativeBtnCSS
      }

      if (props.styles === AssessBehaviourButtonTypes.neutral) {
        return neutralBtnCSS
      }

      if (props.styles === AssessBehaviourButtonTypes.unknown) {
        return unknownBtnCSS
      }
    }
    return null
  }}
`

const maxSuperpowersCount = 3

const AssessBehaviourButtons = ({
  checked,
  disabled,
  error,
  justificationsCount,
  name,
  onChange,
  onMessageClick,
  reviewsList = {},
  superpowersCount,
  showCounts,
  justificationIndicator = 'count',
}: AssessButtonsProps) => {
  const [currentlyChecked, setCurrentlyChecked] = useState<AssessBehaviourButtonTypes>()
  const { data: skillsSettings } = useGetSkillsSettings()
  const hasSuperpowerLimit = !!skillsSettings?.company_values_validation_enabled

  useEffect(() => {
    setCurrentlyChecked(checked[0])
  }, [checked])

  const onButtonClick = (type: AssessBehaviourButtonTypes) => () => {
    setCurrentlyChecked(type)
    onChange && onChange(type)
  }

  const negativeBtnChecked =
    !!checked?.includes(AssessBehaviourButtonTypes.negative) ||
    currentlyChecked === AssessBehaviourButtonTypes.negative

  const neutralBtnChecked =
    !!checked?.includes(AssessBehaviourButtonTypes.neutral) ||
    currentlyChecked === AssessBehaviourButtonTypes.neutral

  const positiveBtnChecked =
    !!checked?.includes(AssessBehaviourButtonTypes.positive) ||
    currentlyChecked === AssessBehaviourButtonTypes.positive

  const unknownBtnChecked =
    !!checked?.includes(AssessBehaviourButtonTypes.unknown) ||
    currentlyChecked === AssessBehaviourButtonTypes.unknown

  const messageBtnDisabled = showCounts && !justificationsCount

  return (
    <Container>
      {visibleButtons.includes(AssessBehaviourButtonTypes.negative) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessBehaviourButtonTypes.negative]?.length ? (
            <Count>{reviewsList[AssessBehaviourButtonTypes.negative].length}</Count>
          ) : null}

          <ButtonStyled
            checked={negativeBtnChecked}
            data-checked={negativeBtnChecked}
            data-testid={`${name}_${AssessBehaviourButtonTypes.negative}`}
            disabled={disabled}
            onClick={onButtonClick(AssessBehaviourButtonTypes.negative)}
            styles={AssessBehaviourButtonTypes.negative}
            type="button"
          >
            <StyledTooltip
              body={
                <ButtonTooltip
                  reviewsList={reviewsList[AssessBehaviourButtonTypes.negative] || []}
                >
                  Developing
                </ButtonTooltip>
              }
              placement="top"
            >
              <IconButton aria-label="Improvement" size={12} useIcon="StarEmpty" />
            </StyledTooltip>
          </ButtonStyled>
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessBehaviourButtonTypes.neutral) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessBehaviourButtonTypes.neutral]?.length ? (
            <Count>{reviewsList[AssessBehaviourButtonTypes.neutral].length}</Count>
          ) : null}

          <ButtonStyled
            checked={neutralBtnChecked}
            data-checked={neutralBtnChecked}
            data-testid={`${name}_${AssessBehaviourButtonTypes.neutral}`}
            disabled={disabled}
            onClick={onButtonClick(AssessBehaviourButtonTypes.neutral)}
            styles={AssessBehaviourButtonTypes.neutral}
            type="button"
          >
            <StyledTooltip
              body={
                <ButtonTooltip
                  reviewsList={reviewsList[AssessBehaviourButtonTypes.neutral] || []}
                >
                  Performing
                </ButtonTooltip>
              }
              placement="top"
            >
              <IconButton aria-label="Performing" size={12} useIcon="Check" />
            </StyledTooltip>
          </ButtonStyled>
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessBehaviourButtonTypes.positive) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessBehaviourButtonTypes.positive]?.length ? (
            <Count>{reviewsList[AssessBehaviourButtonTypes.positive].length}</Count>
          ) : null}

          <ButtonStyled
            checked={positiveBtnChecked}
            data-checked={positiveBtnChecked}
            data-testid={`${name}_${AssessBehaviourButtonTypes.positive}`}
            disabled={
              (hasSuperpowerLimit && superpowersCount >= maxSuperpowersCount) || disabled
            }
            onClick={onButtonClick(AssessBehaviourButtonTypes.positive)}
            styles={AssessBehaviourButtonTypes.positive}
            type="button"
          >
            <StyledTooltip
              body={
                positiveBtnChecked ||
                !hasSuperpowerLimit ||
                superpowersCount < maxSuperpowersCount ? (
                  <ButtonTooltip
                    reviewsList={reviewsList[AssessBehaviourButtonTypes.positive] || []}
                  >
                    Exceeding
                  </ButtonTooltip>
                ) : null
              }
              placement="top"
              text='You can select a maximum of 3 "Superpowers" across all behaviours. Please prioritise behaviours that the employee is known for, exemplifies and sets standards for others to follow.'
            >
              <IconButton aria-label="Superpower" size={12} useIcon="StarFilled" />
            </StyledTooltip>
          </ButtonStyled>
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessBehaviourButtonTypes.unknown) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessBehaviourButtonTypes.unknown]?.length ? (
            <Count>{reviewsList[AssessBehaviourButtonTypes.unknown].length}</Count>
          ) : null}

          <ButtonStyled
            checked={unknownBtnChecked}
            data-checked={unknownBtnChecked}
            data-testid={`${name}_${AssessBehaviourButtonTypes.unknown}`}
            disabled={disabled}
            onClick={onButtonClick(AssessBehaviourButtonTypes.unknown)}
            styles={AssessBehaviourButtonTypes.unknown}
            type="button"
          >
            <StyledTooltip
              body={
                <ButtonTooltip
                  reviewsList={reviewsList[AssessBehaviourButtonTypes.unknown] || []}
                >
                  Don't&nbsp;know
                </ButtonTooltip>
              }
              placement="top"
            >
              <Circle checked={unknownBtnChecked} disabled={disabled} />
            </StyledTooltip>
          </ButtonStyled>
        </BtnWrapper>
      )}

      {onMessageClick && (
        <BtnWrapper showCounts={showCounts}>
          <IconButton
            aria-label="Message"
            color={messageBtnDisabled ? Token.color.greyTone10 : Token.color.greyTone20}
            disabled={messageBtnDisabled}
            onClick={onMessageClick}
            size={20}
            useIcon="Message"
          >
            {justificationsCount > 0 && justificationIndicator === 'count' && (
              <IconButton.Badge bg={Token.color.blue}>
                {justificationsCount}
              </IconButton.Badge>
            )}
          </IconButton>
          {justificationsCount > 0 && justificationIndicator === 'dot' && (
            <Absolute top={-5} right={-5}>
              <Icon type="Dot" size="tiny" color={Token.color.blue} />
            </Absolute>
          )}
        </BtnWrapper>
      )}
      {error && (
        <Tooltip placement="right" text="You need to select a rating">
          <Icon color={Token.color.red} size={'tiny'} type={'WarningOutlined'} />
        </Tooltip>
      )}
    </Container>
  )
}

export default AssessBehaviourButtons
