import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import {
  ExternalMetricSources,
  IndividualJiraMetricsInterface,
  JiraCommonMetrics,
  JiraMetrics as JiraMetricsType,
  JiraNumericMetrics,
  Metrics,
  MetricSources,
  OrgUnitJiraMetricsInterface,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import {
  PerformanceInsightsGroupSourceInterface,
  PerformanceInsightsGroupSourceMetricInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { JiraEpicInterface } from '@src/interfaces/roadmaps'
import { useTableReturnType } from '@components/TableV2/hooks'
import { MetricHighlight } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/MetricHighlight'
import { metricModel } from '@src/features/AiInsights/Performance/models/metricModel'
import { EpicsContributionsTable } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/EpicsContributionsTable/EpicsContributionsTable'
import {
  metricsGridCellItemStyles,
  metricsGridGap,
} from '@src/features/AiInsights/Performance/components/Metrics/common/styles'
import { useChartData } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/NumericMetricHistoryChart/hooks/useChartData'
import { NumericMetricHistoryBarChart } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/NumericMetricHistoryChart/BarChart'
import {
  getMetricLabel,
  getMetricUnit,
  getNumericMetricDetailsVariant,
  NumericMetricAvgCalculationInfo,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { getJiraNumericMetricLink } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/constants'
import { MetricsSourceItem } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsSourceItem/MetricsSourceItem'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'

interface Props<T extends MetricSources, K extends Metrics> {
  employeeId: number
  orgUnit: BaseOption<string>
  insightsSource: PerformanceInsightsGroupSourceInterface<T, K>
  employeeMetrics: IndividualJiraMetricsInterface | null
  orgUnitMetrics: OrgUnitJiraMetricsInterface | null
  jiraEpicsTable: useTableReturnType<JiraEpicInterface, undefined>
  isLoading: boolean
}

export const JiraMetrics = ({
  employeeId,
  orgUnit,
  insightsSource,
  employeeMetrics,
  orgUnitMetrics,
  jiraEpicsTable,
  isLoading,
}: Props<ExternalMetricSources.Jira, JiraMetricsType>) => {
  const {
    data: metricsHistoryDataRecord,
    dataKeys: metricsHistoryKeysRecord,
    isLoading: isMetricsHistoryLoading,
  } = useChartData(employeeId)

  if (isLoading || isMetricsHistoryLoading) {
    return <SourceSkeleton />
  }

  const enabledMetrics = insightsSource.metrics.filter(metricModel.isEnabled)

  if (!employeeMetrics || !orgUnitMetrics || enabledMetrics.length === 0) {
    return null
  }

  const numericMetrics = enabledMetrics.filter<
    PerformanceInsightsGroupSourceMetricInterface<JiraNumericMetrics>
  >(metricModel.isNumeric)
  const epicsMetric = enabledMetrics.find(metric =>
    metricModel.isByName(metric, JiraCommonMetrics.EpicContribution),
  )

  return (
    <VStack space="s-16" data-testid="ai_insights_performance_jira_metrics">
      <MetricsSourceItem
        source={ExternalMetricSources.Jira}
        info={<NumericMetricAvgCalculationInfo />}
      />
      {numericMetrics.length > 0 && (
        <Flex gap={metricsGridGap} flexWrap="wrap" width="100%">
          {numericMetrics.map(({ name: metricKey }) => {
            const highlightProps = {
              value: employeeMetrics[metricKey] ?? 0,
              average: orgUnitMetrics[metricKey] ?? 0,
              employeeName: employeeMetrics.employee.name,
              orgUnitName: orgUnit.id,
              variant: getNumericMetricDetailsVariant(metricKey),
              metricName: getMetricUnit(metricKey),
              dataLabel: getMetricLabel(metricKey),
              link: getJiraNumericMetricLink(metricKey, employeeMetrics),
            }

            const barChartProps = {
              dataKey: metricsHistoryKeysRecord[metricKey],
              data: metricsHistoryDataRecord[metricKey],
              variant: getNumericMetricDetailsVariant(metricKey),
              isLoading: isMetricsHistoryLoading,
            }
            const isHistoryDataAvailable = metricsHistoryDataRecord[metricKey]?.length > 1

            return (
              <Box
                key={metricKey}
                flex={1}
                style={metricsGridCellItemStyles}
                data-testid={`ai_insights_performance_jira_numeric_metric_${metricKey}`}
              >
                <MetricHighlight
                  {...highlightProps}
                  summary={
                    isHistoryDataAvailable && (
                      <VStack
                        space="s-2"
                        width="100%"
                        height={200}
                        pt="s-8"
                        data-testid={`ai_insights_performance_jira_numeric_metric_${metricKey}_history`}
                      >
                        <Text color={Token.color.greyTone50}>
                          <FormattedMessage
                            id="charts.trend.label"
                            defaultMessage="Trend"
                          />
                        </Text>
                        <NumericMetricHistoryBarChart {...barChartProps} />
                      </VStack>
                    )
                  }
                />
              </Box>
            )
          })}
        </Flex>
      )}
      {!!epicsMetric && jiraEpicsTable.count > 0 && (
        <Box data-testid="ai_insights_performance_jira_epics">
          <EpicsContributionsTable {...jiraEpicsTable} />
        </Box>
      )}
    </VStack>
  )
}
