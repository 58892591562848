import { useMemo } from 'react'
import { FilterByInterface } from '@src/interfaces/data'
import { toIdAndName } from '@src/utils/toIdAndName'
import { useTable, useTableReturnType } from '@components/TableV2/hooks'
import {
  getTalentSkillsCompareRatingTableRequests,
  SkillRatingsDistributionFilterKeys,
} from '@src/features/ReviewCycle/Analytics/api/skills'
import { TalentSkillRatingComparableInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'

interface Options {
  departmentId: number
  cycleId: number
}

export const useTableData = ({
  departmentId,
  cycleId,
}: Options): useTableReturnType<TalentSkillRatingComparableInterface, undefined> => {
  const initialFilters = useMemo<FilterByInterface[]>(
    () => [
      {
        columnName: SkillRatingsDistributionFilterKeys.DepartmentId,
        filters: [toIdAndName(String(departmentId))],
        nonResettable: true,
      },
      {
        columnName: SkillRatingsDistributionFilterKeys.CycleId,
        filters: [toIdAndName(String(cycleId))],
        nonResettable: true,
      },
    ],
    [departmentId, cycleId],
  )

  return useTable(getTalentSkillsCompareRatingTableRequests(), initialFilters)
}
