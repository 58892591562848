import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'

export const SkillMasteryInputFields = () => {
  return (
    <InputGroup>
      <LapeMultiInput
        title="Poor"
        data-name="poor"
        valueFieldName={undefined}
        withCell
        name="mastery_level_definition_poor"
        description={`
               Used in interviews to filter out under-qualified candidates. 
               Ideally contains 2 points.
              `}
      />
      <LapeMultiInput
        title="Basic"
        valueFieldName={undefined}
        withCell
        name="mastery_level_definition_basic"
        description={`
                This is the minimum working expectations for this skill, often 
                aligned with Junior-Mid seniorities. Ideally contains 2-3 points.
              `}
      />
      <LapeMultiInput
        title="Intermediate"
        data-name="intermediate"
        valueFieldName={undefined}
        withCell
        name="mastery_level_definition_intermediate"
        description={`
                This the typical competency of an employee within the company, 
                often aligned with Mid-Senior seniorities. Ideally contains 3-5 points.
              `}
      />
      <LapeMultiInput
        title="Advanced"
        data-name="advanced"
        valueFieldName={undefined}
        withCell
        name="mastery_level_definition_advanced"
        description={`
                This represents higher than average knowledge and an expertise in a 
                particular skill, often aligned with Senior-Lead seniorities. 
                Ideally contains 2-3 points.
              `}
      />
      <LapeMultiInput
        title="Expert"
        data-name="expert"
        valueFieldName={undefined}
        withCell
        name="mastery_level_definition_expert"
        description={`
                Define what an expert of this skill will look like. 
                At least have 2 points.
              `}
      />
    </InputGroup>
  )
}
