import React from 'react'
import { API } from '@src/constants/api'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { useNextSpecialisationStepButtonLink } from '../../hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { ROUTES } from '@src/constants/routes'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const CURRENT_STEP = 'hiring_process'

interface Props {
  onAfterSubmit: VoidFunction
}

export const HiringProcessFormActions = ({ onAfterSubmit }: Props) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <>
      {values.status === Statuses.draft && (
        <RoleSaveDraftButton
          title={specialisationsEnabled ? 'specialisation' : 'role'}
          pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
          pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS}
          isNew
          notification={{
            updateMsg: specialisationsEnabled
              ? 'Specialisation draft successfully updated.'
              : 'Role draft successfully updated',
            createMsg: specialisationsEnabled
              ? 'Specialisation draft successfully created.'
              : 'Role draft successfully created',
          }}
        />
      )}
      <ContinueRoleButton
        api={API.SPECIALISATIONS}
        type="specialisation"
        step={CURRENT_STEP}
        to={nextLink}
        disabled={!values?.hiring_process_rounds?.length}
        onAfterSubmit={onAfterSubmit}
      />
    </>
  )
}
