import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar } from '@revolut/ui-kit'
import {
  ExternalMetricSources,
  InternalMetricSources,
  MetricSources,
  BitbucketNumericMetrics,
  InterviewNumericMetrics,
  JiraCommonMetrics,
  JiraNumericMetrics,
  Metrics,
  NumericMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { MetricDetailsVariants } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/constants'
import Bitbucket from '@src/assets/temporary/Bitbucket.png'

export const getMetricDefaultMessage = (metricKey: Metrics): string =>
  metricToDefaultMessageMap[metricKey]

export const metricToDefaultMessageMap: Record<Metrics, string> = {
  [JiraNumericMetrics.TicketsClosed]: 'Tickets closed',
  [JiraNumericMetrics.BugsClosed]: 'Bugs closed',
  [JiraNumericMetrics.StoryPoints]: 'Story points',
  [JiraNumericMetrics.CycleParticipation]: 'Cycle participation',
  [JiraCommonMetrics.EpicContribution]: 'Epic contribution',
  [BitbucketNumericMetrics.PRsMerged]: 'Pull requests merged',
  [BitbucketNumericMetrics.PRsReviewed]: 'Pull requests reviewed',
  [BitbucketNumericMetrics.LinesAdded]: 'Lines added',
  [BitbucketNumericMetrics.LinesRemoved]: 'Lines removed',
  [BitbucketNumericMetrics.CommentsAdded]: 'Comments added',
  [BitbucketNumericMetrics.AverageMergeTime]: 'Average time to merge',
  [InterviewNumericMetrics.InterviewsCompleted]: 'Interviews completed',
  [InterviewNumericMetrics.PassRate]: 'Pass rate',
  [InterviewNumericMetrics.ConversionRate]: 'Conversion rate',
  [InterviewNumericMetrics.AverageFeedbackTime]: 'Feedback delay',
}

export const getMetricUnit = (metricKey: Metrics): string => metricToUnitMap[metricKey]

export const metricToUnitMap: Record<Metrics, string> = {
  [JiraNumericMetrics.TicketsClosed]: 'ticket',
  [JiraNumericMetrics.BugsClosed]: 'bug',
  [JiraNumericMetrics.StoryPoints]: 'story point',
  [JiraNumericMetrics.CycleParticipation]: 'cycle participation',
  [JiraCommonMetrics.EpicContribution]: 'epic contribution',
  [BitbucketNumericMetrics.PRsMerged]: 'pull request',
  [BitbucketNumericMetrics.PRsReviewed]: 'pull request',
  [BitbucketNumericMetrics.LinesAdded]: 'line',
  [BitbucketNumericMetrics.LinesRemoved]: 'line',
  [BitbucketNumericMetrics.CommentsAdded]: 'comment',
  [BitbucketNumericMetrics.AverageMergeTime]: 'time',
  [InterviewNumericMetrics.InterviewsCompleted]: 'interview',
  [InterviewNumericMetrics.PassRate]: 'rate',
  [InterviewNumericMetrics.ConversionRate]: 'rate',
  [InterviewNumericMetrics.AverageFeedbackTime]: 'time',
}

export const getNumericMetricDetailsVariant = (
  metricKey: NumericMetrics,
): MetricDetailsVariants => numericMetricToDetailsVariant[metricKey]

export const numericMetricToDetailsVariant: Record<
  NumericMetrics,
  MetricDetailsVariants
> = {
  [JiraNumericMetrics.TicketsClosed]: MetricDetailsVariants.Default,
  [JiraNumericMetrics.BugsClosed]: MetricDetailsVariants.Default,
  [JiraNumericMetrics.StoryPoints]: MetricDetailsVariants.Default,
  [JiraNumericMetrics.CycleParticipation]: MetricDetailsVariants.Percentage,
  [BitbucketNumericMetrics.PRsMerged]: MetricDetailsVariants.Default,
  [BitbucketNumericMetrics.PRsReviewed]: MetricDetailsVariants.Default,
  [BitbucketNumericMetrics.LinesAdded]: MetricDetailsVariants.Default,
  [BitbucketNumericMetrics.LinesRemoved]: MetricDetailsVariants.Default,
  [BitbucketNumericMetrics.CommentsAdded]: MetricDetailsVariants.Default,
  [BitbucketNumericMetrics.AverageMergeTime]: MetricDetailsVariants.Seconds,
  [InterviewNumericMetrics.InterviewsCompleted]: MetricDetailsVariants.Default,
  [InterviewNumericMetrics.PassRate]: MetricDetailsVariants.Percentage,
  [InterviewNumericMetrics.ConversionRate]: MetricDetailsVariants.Percentage,
  [InterviewNumericMetrics.AverageFeedbackTime]: MetricDetailsVariants.Seconds,
}

export const getMetricLabel = (metricKey: Metrics): React.ReactNode =>
  metricToLabelMap[metricKey] ?? metricKey

export const metricToLabelMap: Record<Metrics, React.ReactNode> = {
  [JiraNumericMetrics.TicketsClosed]: (
    <FormattedMessage
      id="performance.metrics.external.jira.tickets.closed.label"
      defaultMessage={getMetricDefaultMessage(JiraNumericMetrics.TicketsClosed)}
    />
  ),
  [JiraNumericMetrics.BugsClosed]: (
    <FormattedMessage
      id="performance.metrics.external.jira.bugs.closed.label"
      defaultMessage={getMetricDefaultMessage(JiraNumericMetrics.BugsClosed)}
    />
  ),
  [JiraNumericMetrics.StoryPoints]: (
    <FormattedMessage
      id="performance.metrics.external.jira.story.points.label"
      defaultMessage={getMetricDefaultMessage(JiraNumericMetrics.StoryPoints)}
    />
  ),
  [JiraNumericMetrics.CycleParticipation]: (
    <FormattedMessage
      id="performance.metrics.external.jira.cycle.participation.label"
      defaultMessage={getMetricDefaultMessage(JiraNumericMetrics.CycleParticipation)}
    />
  ),
  [JiraCommonMetrics.EpicContribution]: (
    <FormattedMessage
      id="performance.metrics.external.jira.epic.contribution.label"
      defaultMessage={getMetricDefaultMessage(JiraCommonMetrics.EpicContribution)}
    />
  ),
  [BitbucketNumericMetrics.PRsMerged]: (
    <FormattedMessage
      id="performance.metrics.external.bitbucket.prs.merged.label"
      defaultMessage={getMetricDefaultMessage(BitbucketNumericMetrics.PRsMerged)}
    />
  ),
  [BitbucketNumericMetrics.PRsReviewed]: (
    <FormattedMessage
      id="performance.metrics.external.bitbucket.prs.reviewed.label"
      defaultMessage={getMetricDefaultMessage(BitbucketNumericMetrics.PRsReviewed)}
    />
  ),
  [BitbucketNumericMetrics.LinesAdded]: (
    <FormattedMessage
      id="performance.metrics.external.bitbucket.lines.added.label"
      defaultMessage={getMetricDefaultMessage(BitbucketNumericMetrics.LinesAdded)}
    />
  ),
  [BitbucketNumericMetrics.LinesRemoved]: (
    <FormattedMessage
      id="performance.metrics.external.bitbucket.lines.removed.label"
      defaultMessage={getMetricDefaultMessage(BitbucketNumericMetrics.LinesRemoved)}
    />
  ),
  [BitbucketNumericMetrics.CommentsAdded]: (
    <FormattedMessage
      id="performance.metrics.external.bitbucket.comments.added.label"
      defaultMessage={getMetricDefaultMessage(BitbucketNumericMetrics.CommentsAdded)}
    />
  ),
  [BitbucketNumericMetrics.AverageMergeTime]: (
    <FormattedMessage
      id="performance.metrics.external.bitbucket.avg.merge.time.label"
      defaultMessage={getMetricDefaultMessage(BitbucketNumericMetrics.AverageMergeTime)}
    />
  ),
  [InterviewNumericMetrics.InterviewsCompleted]: (
    <FormattedMessage
      id="performance.metrics.internal.interviews.interviews.completed.label"
      defaultMessage={getMetricDefaultMessage(
        InterviewNumericMetrics.InterviewsCompleted,
      )}
    />
  ),
  [InterviewNumericMetrics.PassRate]: (
    <FormattedMessage
      id="performance.metrics.internal.interviews.pass.rate.label"
      defaultMessage={getMetricDefaultMessage(InterviewNumericMetrics.PassRate)}
    />
  ),
  [InterviewNumericMetrics.ConversionRate]: (
    <FormattedMessage
      id="performance.metrics.internal.interviews.conversion.rate.label"
      defaultMessage={getMetricDefaultMessage(InterviewNumericMetrics.ConversionRate)}
    />
  ),
  [InterviewNumericMetrics.AverageFeedbackTime]: (
    <FormattedMessage
      id="performance.metrics.internal.interviews.avg.feedback.time.label"
      defaultMessage={getMetricDefaultMessage(
        InterviewNumericMetrics.AverageFeedbackTime,
      )}
    />
  ),
}

export const getMetricSourceLabel = (source: MetricSources): React.ReactNode =>
  metricSourceToLabelMap[source]

const metricSourceToLabelMap: Record<
  InternalMetricSources | ExternalMetricSources,
  React.ReactNode
> = {
  [InternalMetricSources.Goals]: (
    <FormattedMessage
      id="performance.metrics.sources.internal.goals.label"
      defaultMessage="Goals"
    />
  ),
  [InternalMetricSources.Interviews]: (
    <FormattedMessage
      id="performance.metrics.sources.internal.interviews.label"
      defaultMessage="Interviews"
    />
  ),
  [InternalMetricSources.OneToOnes]: (
    <FormattedMessage
      id="performance.metrics.sources.internal.one.to.one.label"
      defaultMessage="1:1s"
    />
  ),
  [ExternalMetricSources.Jira]: (
    <FormattedMessage
      id="performance.metrics.sources.external.jira.label"
      defaultMessage="Jira"
    />
  ),
  [ExternalMetricSources.Bitbucket]: (
    <FormattedMessage
      id="performance.metrics.sources.external.bitbucket.label"
      defaultMessage="Bitbucket"
    />
  ),
}

export const getMetricSourceAvatar = (source: MetricSources): React.ReactNode =>
  metricSourceToAvatarMap[source]

const metricSourceToAvatarMap: Record<
  InternalMetricSources | ExternalMetricSources,
  React.ReactNode
> = {
  [InternalMetricSources.Goals]: <Avatar useIcon="Flag" />,
  [InternalMetricSources.Interviews]: <Avatar useIcon="Chat" />,
  [InternalMetricSources.OneToOnes]: <Avatar useIcon="Calendar" />,
  [ExternalMetricSources.Jira]: (
    <Avatar image="https://assets.revolut.com/assets/apps/Jira.png" />
  ),
  // TODO: use correct Bitbucket avatar image once it's requested and added to ui-kit
  //  https://revolut.atlassian.net/jira/servicedesk/projects/MPR/queues/issue/MPR-7862
  [ExternalMetricSources.Bitbucket]: <Avatar image={Bitbucket} />,
}

export const NumericMetricAvgCalculationInfo = () => (
  <FormattedMessage
    id="performance.insights.numeric.metrics.avg.calculation.explanation.message"
    defaultMessage="Averages are determined based on employees within the same specialization and seniority level."
  />
)
