import { useMemo } from 'react'
import { TalentType } from '@src/interfaces/talent/talent'
import { CycleOption } from '@src/interfaces/selectors'
import { FieldOptions } from '@src/interfaces'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { TalentPerformanceStatsInterface } from '@src/interfaces/talent/performance'
import { FinalGrade, getFinalGradeScore } from '@src/interfaces/performance'

export const isCompanyType = (type: TalentType) => type === TalentType.Company

export const isDepartmentType = (talentType: TalentType) =>
  talentType === TalentType.Team || talentType === TalentType.Department

export const isFunctionType = (talentType: TalentType) =>
  talentType === TalentType.Function ||
  talentType === TalentType.Role ||
  talentType === TalentType.Specialisation

export const performanceClarification =
  'These stats are calculated using most recent performance grades and don’t depend on the chosen cycle'

export const useGetCycleSelector = (selector: CycleOption[]) => {
  const buildCycleSelector = async (): Promise<{
    options: CycleOption[]
  }> => {
    return {
      options: selector ?? [],
    }
  }

  return useMemo(() => buildCycleSelector, [selector])
}

export const isActivePerformanceCycle = (
  id: number | string,
  cycles: CycleOption[],
): boolean => {
  return !!cycles.find(cycle => Number(id) === Number(cycle.cycle_id || cycle.id))
    ?.performance_reviews_selected_cycle
}

export const canViewTalentPerformance = (fieldOptions?: FieldOptions) => {
  return fieldOptions?.permissions?.includes(PermissionTypes.CanViewCalibrationTable)
}

export const useShowRanking = (talentType: TalentType) => {
  const isFunctionalTalent = isFunctionType(talentType)
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = useIsCommercial()
  const hasFeature =
    !isCommercial || featureFlags.includes(FeatureFlags.CalibrationRankingScore)
  return hasFeature && isFunctionalTalent
}

export const useShowStats = (talentType: TalentType) => {
  const eligibleViewType = [
    TalentType.Department,
    TalentType.Company,
    TalentType.Function,
    TalentType.Role,
    TalentType.Specialisation,
  ].includes(talentType)

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = useIsCommercial()
  const hasFeature =
    !isCommercial || featureFlags.includes(FeatureFlags.CalibrationRankingScore)
  return hasFeature && eligibleViewType
}

export const getCalibrationFlagOptions = (
  tableStats?: TalentPerformanceStatsInterface,
) => [
  {
    id: 'lm_fm_mismatch',
    name: 'LM-FM mismatch',
    count: tableStats?.lm_fm_mismatch_count,
  },
  {
    id: 'department_owner_calibrated',
    name: 'HoD calibrated',
    count: tableStats?.department_owner_calibrated_count,
  },
  {
    id: 'function_owner_calibrated',
    name: 'HoF calibrated',
    count: tableStats?.function_owner_calibrated_count,
  },
  {
    id: 'underperformers_only',
    name: 'Underperformers',
    count: tableStats?.underperformers_count,
  },
  {
    id: 'nominated_for_promotion',
    name: 'Nominated for promotion',
    count: tableStats?.nominated_for_promotion_count,
  },
  {
    id: 'department_owner_non_calibrated',
    name: 'HoD not calibrated',
    count: tableStats?.department_owner_non_calibrated_count,
  },
  {
    id: 'function_owner_non_calibrated',
    name: 'HoF not calibrated',
    count: tableStats?.function_owner_non_calibrated_count,
  },
  {
    id: 'hod_hof_mismatch',
    name: 'HoD-HoF mismatch',
    count: tableStats?.hod_hof_mismatch_count,
  },
]

export const getAboveBelowSuggestedGrades = (
  grade?: FinalGrade,
  suggestedGrade?: FinalGrade,
): 'above' | 'below' | 'neutral' => {
  if (!grade || !suggestedGrade) {
    return 'neutral'
  }

  const gradeScore = getFinalGradeScore(grade)
  const prefilledScore = getFinalGradeScore(suggestedGrade)

  if (gradeScore === prefilledScore) {
    return 'neutral'
  }

  if (gradeScore > prefilledScore) {
    return 'above'
  }
  return 'below'
}

export const getGradeLabel = (
  gradeOptions: { id: FinalGrade; name: string }[],
  gradeId: FinalGrade | undefined,
) => {
  return gradeOptions.find(option => option.id === gradeId)?.name
}
