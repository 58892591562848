import { ReviewCategory, ReviewStatuses } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import {
  ProbationCheckpoint,
  ProbationRecommendationInterface,
} from '@src/interfaces/probationReview'
import { Avatar, Box, Group, Item, ItemSkeleton, Text } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { getProbationRecommendations } from '@src/api/probationReview'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

const ReviewDeliverablesPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_FINAL_RESULT.GENERAL,
}

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Recommendation',
  [ButtonStates.Edit]: 'Edit Recommendation',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
}

export const RecommendationStepReviewers = ({
  checkpoint,
  reviewCategory,
  cycleId,
  employeeId,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
}) => {
  const [recommendations, setRecommendations] =
    useState<ProbationRecommendationInterface[]>()
  const [loading, setLoading] = useState(false)
  const errorPopup = useErrorPopup()

  const fetchRecommendations = async () => {
    try {
      setLoading(true)
      const resp = await getProbationRecommendations(employeeId, cycleId)
      setRecommendations(resp.data.results)
    } catch (error) {
      errorPopup.show({ error, fallbackTitle: 'Failed fetching recommendations' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRecommendations()
  }, [])

  const buttonDisabled = !checkpoint?.can_be_submitted

  const onClickAddReview = () => {
    const id = checkpoint.recommendation?.id
    const cpEmployeeId = checkpoint.recommendation?.reviewed_employee?.id
    const cpCycleId = checkpoint.recommendation?.cycle?.id
    if (id && cpEmployeeId && cpCycleId) {
      navigateTo(
        pathToUrl(ReviewDeliverablesPath[reviewCategory], {
          id,
          employeeId: cpEmployeeId,
          cycleId: cpCycleId,
        }),
      )
    }
  }

  const state =
    !checkpoint?.recommendation ||
    checkpoint.recommendation.status === ReviewStatuses.pending
      ? ButtonStates.Add
      : ButtonStates.Edit
  return (
    <Box width="100%">
      {loading ? (
        <ItemSkeleton />
      ) : (
        <Group style={transparentThemeBackgroundOverrides}>
          {recommendations && recommendations.length > 0 && (
            <StepReviewersList
              reviews={recommendations}
              onClickAddReview={onClickAddReview}
              buttonDisabled={buttonDisabled}
              icon={ButtonIcon[state]}
              title={ButtonTitles[state]}
            />
          )}
        </Group>
      )}
      {!recommendations?.length && !loading && (
        <Group width="100%" style={transparentThemeBackgroundOverrides}>
          <Item py="s-8">
            <Item.Avatar>
              <Avatar useIcon="16/SandWatch" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <Text whiteSpace="nowrap" variant="emphasis1">
                  Recommendations not available
                </Text>
              </Item.Title>
              <Item.Description>Recommendation period is not opened yet</Item.Description>
            </Item.Content>
          </Item>
        </Group>
      )}
    </Box>
  )
}
