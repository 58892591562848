import React, { useState } from 'react'
import { SkillInterface } from '@src/interfaces/skills'
import { Text } from '@revolut/ui-kit'
import {
  CardTypes,
  PerformanceReviewTypes,
  ReviewCategory,
  ScorecardSections,
  SectionType,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLapeEffect } from 'lape'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const getCard = (values: SkillInterface): SkillCardInterface => ({
  type: CardTypes.skillCard,
  sections: [
    {
      level_key: 'Basic',
      type: SectionType.CriteriasAssessment,
      items: values?.mastery_level_definition_basic?.map(item => ({ text: item })),
    },
    {
      level_key: 'Intermediate',
      type: SectionType.CriteriasAssessment,
      items: values?.mastery_level_definition_intermediate?.map(item => ({
        text: item,
      })),
    },
    {
      level_key: 'Advanced',
      type: SectionType.CriteriasAssessment,
      items: values?.mastery_level_definition_advanced?.map(item => ({
        text: item,
      })),
    },
    {
      level_key: 'Expert',
      type: SectionType.CriteriasAssessment,
      items: values?.mastery_level_definition_expert?.map(item => ({ text: item })),
    },
  ].filter(section => section.items?.length) as ScorecardSections[],
  name: 'hello',
  rating_expectation: null,
})

const ScorecardPreview = () => {
  const { values } = useLapeContext<SkillInterface>()
  const [card, setCard] = useState<SkillCardInterface>(getCard(values))

  useLapeEffect(() => {
    setCard(getCard(values))
  })

  return (
    <PageWrapper>
      <PageHeader
        backUrl={pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id: values.id })}
        title="Scorecard preview"
      />
      <PageBody>
        <Text use="p" color="grey-tone-50">
          This is the preview of how your skill definition transforms onto an interview
          scorecard; modify in previous steps if changes need to be made.
        </Text>
        <Text use="p" mt="s-32" fontSize="h5" fontWeight={500}>
          {values.name}
        </Text>
        <ScorecardSection
          mt="s-6"
          skillId="0"
          cardIndex={0}
          onChange={() => {}}
          onSetResultLoading={() => {}}
          card={card}
          type={PerformanceReviewTypes.interviewScorecard}
          category={ReviewCategory.Performance}
          hideCardTitle
          hideJustification
          hideResult
          disabled
        />
      </PageBody>
    </PageWrapper>
  )
}

export default ScorecardPreview
