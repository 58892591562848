import React from 'react'
import { useInitializeSkillFormData } from '@src/features/Skills/hooks/useInitializeSkillFormData'
import { FunctionOptionInterface } from '@src/interfaces/functions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillInterface, SkillType } from '@src/interfaces/skills'
import { selectorKeys } from '@src/constants/api'

export const FunctionSelector = () => {
  const { values } = useLapeContext<SkillInterface>()
  const { executiveFunctionFilter } = useInitializeSkillFormData()
  return (
    <LapeRadioSelectInput<FunctionOptionInterface>
      name="function"
      clearable={values.skill_type !== SkillType.Functional}
      optional={values.skill_type !== SkillType.Functional}
      label="Function"
      selector={selectorKeys.functions}
      filter={
        [SkillType.Executive, SkillType.Culture].includes(values.skill_type)
          ? executiveFunctionFilter
          : undefined
      }
    />
  )
}
