import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { MoreBar, useToggle } from '@revolut/ui-kit'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { useSyncMeetings } from '@src/api/meetings'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'
import { oneToOneModel } from '../../models/oneToOneModel'
import { selectUser } from '@src/store/auth/selectors'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { FeedbackCategory } from '@src/interfaces/anytimeFeedback'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { ScheduleMeetingSeriesAction } from './ScheduleMeetingSeriesAction'
import { AnytimeFeedbackNotesSidebar } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/AnytimeFeedbackNotes/AnytimeFeedbackNotesSidebar'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'

const SYNC_ENABLE_TIMEOUT = 1000 * 60 * 10 // 10 minutes

interface Props {
  employee: EmployeeInterface
  meeting: OneToOneMeeting | null
  cycle: ReviewCyclesInterface | null
}

export const HeaderActions = ({ employee, meeting, cycle }: Props) => {
  const openNewTab = useOpenNewTab()
  const user = useSelector(selectUser)
  const { mutateAsync: syncMeetings, isLoading } = useSyncMeetings()
  const canChangeMeeting = useHasGlobalPermission(PermissionTypes.ChangeManagerMeeting)
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>()
  const [isAnytimeFeedbackNotesSidebarOpen, anyTimeFeedbackNotesSidebarToggler] =
    useToggle()

  // aware that user can refresh the page and this will reset. But that's fine
  const [isSyncDisabled, setSyncDisabled] = useState(false)

  const {
    data: legacyOneToOneFeedback,
    refetch: refetchLegacyOneToOneFeedback,
    isLoading: isLegacyOneToOneFeedbackLoading,
  } = useGetAllFeedbackList(
    employee.id,
    {
      category: FeedbackCategory.OneToOne,
      dateRange: [cycle?.start_date_time, cycle?.end_date_time].join(','),
    },
    { enabled: !!cycle },
  )

  const showStatusPopup = useShowStatusPopup()

  const disableSync = () => {
    setSyncDisabled(true)

    setTimer(
      setTimeout(() => {
        setSyncDisabled(false)
      }, SYNC_ENABLE_TIMEOUT),
    )
  }

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  if (!meeting) {
    return null
  }

  const isManager = oneToOneModel.isMeetingManagedBy(user, meeting)

  const changeScheduleLink = meeting.next_meeting_calendar_link

  return (
    <>
      <MoreBar maxCount={2}>
        {changeScheduleLink && (canChangeMeeting || isManager) && (
          <MoreBar.Action
            onClick={() => {
              openNewTab(changeScheduleLink)
            }}
            useIcon="Calendar"
          >
            <FormattedMessage
              id="one-to-one.meetings.schedule.change.cta.label"
              defaultMessage="Change Schedule"
            />
          </MoreBar.Action>
        )}
        <ScheduleMeetingSeriesAction meeting={meeting} />
        <MoreBar.Action
          pending={isLoading}
          onClick={async () => {
            await syncMeetings()
            disableSync()
            showStatusPopup({
              title: 'Meetings are syncing',
              description: 'It can take up to 10 minutes for the meetings to sync',
            })
          }}
          disabled={isSyncDisabled}
          useIcon="ArrowExchange"
        >
          <FormattedMessage
            id="one-to-one.meetings.sync.cta.label"
            defaultMessage="Sync latest meetings"
          />
        </MoreBar.Action>
        {!!legacyOneToOneFeedback?.results.length && (
          <MoreBar.Action onClick={anyTimeFeedbackNotesSidebarToggler.on}>
            <FormattedMessage
              id="one-to-one.anytime.feedback.cta.label"
              defaultMessage="See old 1:1 notes"
            />
          </MoreBar.Action>
        )}
      </MoreBar>
      {cycle && (
        <AnytimeFeedbackNotesSidebar
          employee={employee}
          feedbacks={legacyOneToOneFeedback?.results ?? []}
          isOpen={isAnytimeFeedbackNotesSidebarOpen}
          isLoading={isLegacyOneToOneFeedbackLoading}
          onClose={anyTimeFeedbackNotesSidebarToggler.off}
          onRefresh={refetchLegacyOneToOneFeedback}
        />
      )}
    </>
  )
}
