import React, { useState } from 'react'
import {
  InputGroup,
  MoreBar,
  Side,
  Subheader,
  Text,
  TextArea,
  VStack,
} from '@revolut/ui-kit'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  cancelTask,
  completeTask,
  employeeOnboardingTasksRequests,
  reassignTask,
  startTask,
} from '@src/api/onboardingEmployeesV2'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import SideBar from '@src/components/SideBar/SideBar'
import { selectorKeys } from '@src/constants/api'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { OnboardingTaskInterface } from '@src/interfaces/onboardingV2'

interface TaskFormProps {
  onboardingId: string
  onClose: VoidFunction
  refetchTasksData: VoidFunction
}

const TaskForm = ({ onboardingId, onClose, refetchTasksData }: TaskFormProps) => {
  const [isPending, setIsPending] = useState(false)
  const [assigneePending, setAssigneePending] = useState(false)
  const [assigneePopupOpen, setAssigneePopupOpen] = useState(false)
  const [newAssignee, setNewAssignee] = useState<EmployeeOptionInterface>()
  const [cancelPending, setCancelPending] = useState(false)
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false)
  const [cancelReason, setCancelReason] = useState<string>()

  const { values } = useLapeContext<OnboardingTaskInterface>()
  const errorPopup = useErrorPopup()

  const onConfirmTransferAssignee = async () => {
    if (!newAssignee) {
      return
    }
    try {
      setAssigneePending(true)
      await reassignTask(onboardingId, values.id, newAssignee)
      refetchTasksData()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to transfer assignee',
        forceFallbackTitle: true,
      })
    } finally {
      setAssigneePending(false)
      setAssigneePopupOpen(false)
      setNewAssignee(undefined)
    }
  }

  const onConfirmCancel = async () => {
    if (!cancelReason) {
      return
    }
    try {
      setCancelPending(true)
      await cancelTask(onboardingId, values.id, cancelReason)
      onClose()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to cancel the task',
        forceFallbackTitle: true,
      })
    } finally {
      setCancelPending(false)
    }
  }

  return (
    <>
      <VStack gap="s-16">
        {values.id && (
          <MoreBar>
            {values.status.id === 'not_started' && (
              <MoreBar.Action
                onClick={async () => {
                  try {
                    setIsPending(true)
                    await startTask(onboardingId, values.id)
                    onClose()
                  } catch (error) {
                    errorPopup.show({
                      error,
                      fallbackTitle: 'Failed to start the task',
                      forceFallbackTitle: true,
                    })
                  } finally {
                    setIsPending(false)
                  }
                }}
                pending={isPending}
                useIcon="SendMessage"
                variant="accent"
              >
                Start
              </MoreBar.Action>
            )}
            {values.status.id === 'in_progress' && (
              <MoreBar.Action
                onClick={async () => {
                  try {
                    setIsPending(true)
                    await completeTask(onboardingId, values.id)
                    onClose()
                  } catch (error) {
                    errorPopup.show({
                      error,
                      fallbackTitle: 'Failed to complete the task',
                      forceFallbackTitle: true,
                    })
                  } finally {
                    setIsPending(false)
                  }
                }}
                pending={isPending}
                useIcon="Check"
                variant="accent"
              >
                Mark as done
              </MoreBar.Action>
            )}
            <MoreBar.Action
              onClick={() => setAssigneePopupOpen(true)}
              pending={assigneePending}
              useIcon="ArrowExchange"
            >
              Change assignee
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => setCancelPopupOpen(true)}
              pending={cancelPending}
              useIcon="Delete"
              variant="negative"
            >
              Cancel
            </MoreBar.Action>
          </MoreBar>
        )}
        <InputGroup>
          <LapeNewInput label="Task name" name="name" required />
          <LapeNewTextArea label="Task description" name="description" rows={3} />
          {!values.id && (
            <>
              <Subheader>
                <Subheader.Title>
                  Who should be responsible for this task?
                </Subheader.Title>
              </Subheader>
              <LapeRadioSelectInput
                label="Assignee type"
                name="default_assignee_type"
                selector={selectorKeys.approval_step_approver_types}
              />
              {values.default_assignee_type?.id === 'employee' && (
                <LapeRadioSelectInput
                  label="Assignee"
                  name="default_assignee"
                  selector={selectorKeys.employee}
                />
              )}
              {values.default_assignee_type?.id === 'group' && (
                <LapeRadioSelectInput
                  label="Group"
                  name="default_assignee_group"
                  selector={selectorKeys.dynamic_groups}
                />
              )}
              {values.default_assignee_type?.id === 'relationship' && (
                <LapeRadioSelectInput
                  label="Relationship"
                  name="default_assignee_relationship"
                  selector={
                    selectorKeys.employee_onboarding_default_assignee_relationships
                  }
                />
              )}
            </>
          )}
        </InputGroup>
      </VStack>
      <Side.Actions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => onClose()}
          successText={
            values?.id ? 'Task was successfully updated' : 'Task was successfully created'
          }
          useValidator
        >
          {values?.id ? 'Update task' : 'Save task'}
        </NewSaveButtonWithPopup>
      </Side.Actions>

      <ConfirmationDialog
        onClose={() => setAssigneePopupOpen(false)}
        onConfirm={onConfirmTransferAssignee}
        onReject={() => setAssigneePopupOpen(false)}
        open={assigneePopupOpen}
        label="Change assignee"
        loading={assigneePending}
        body={
          <RadioSelectInput
            label="Employee"
            selector={selectorKeys.employee}
            onChange={option => {
              if (option) {
                setNewAssignee(option)
              }
            }}
            value={newAssignee}
          />
        }
        yesMessage="Confirm"
        noMessage="Cancel"
        submitDisabled={!newAssignee || newAssignee?.id === values?.assignee?.id}
      />

      <ConfirmationDialog
        onClose={() => setCancelPopupOpen(false)}
        onConfirm={onConfirmCancel}
        onReject={() => setCancelPopupOpen(false)}
        open={cancelPopupOpen}
        loading={cancelPending}
        body={
          <>
            <Text use="div" mb="s-16">
              Are you sure you want to cancel this task?
            </Text>
            <TextArea
              label="Cancel reason"
              onChange={e => setCancelReason(e.currentTarget.value)}
              rows={3}
              value={cancelReason}
            />
          </>
        }
        yesMessage="Confirm"
        noMessage="Cancel"
        submitDisabled={!cancelReason}
      />
    </>
  )
}

export const TaskSidebar = ({
  isOpen,
  onClose,
  onboardingId,
  refetchTasksData,
  task,
}: {
  isOpen: boolean
  onClose: VoidFunction
  onboardingId: string
  refetchTasksData: VoidFunction
  task?: OnboardingTaskInterface
}) => {
  return (
    <SideBar isOpen={isOpen} onClose={onClose} title={task?.name || 'Add task'}>
      <Form
        api={employeeOnboardingTasksRequests}
        forceParams={{
          onboardingId,
          taskId: task?.id ? String(task.id) : undefined,
        }}
        isExistingForm={!!task?.id}
        disableLocalStorageCaching
      >
        <TaskForm
          onboardingId={onboardingId}
          onClose={onClose}
          refetchTasksData={refetchTasksData}
        />
      </Form>
    </SideBar>
  )
}
