import { FinalGrade } from '@src/interfaces/performance'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getPrivateContentItemsByRole } from '../utils/getContentItemsByRole'
import {
  useCreateOneToOneNote,
  useGetMeetingNotesFeedback,
  useUpdateOneToOneNote,
} from '@src/api/meetings'
import {
  AiGenerationStatus,
  GoogleCalendarEventAttendee,
  MeetingEvent,
  MeetingEventStatus,
  MeetingNotesFeedback,
  MeetingNotesOrigin,
  PrivateContentItem,
  privateRoles,
  PublicContentItem,
} from '@src/interfaces/meetings'
import { generateRandomId } from '@src/utils/numbers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useReviewCycleData } from '../hooks/useReviewCycleData'
import { isAfter } from 'date-fns'
import {
  CYCLE_ID_QUERY_PARAM_KEY,
  MEETING_EVENT_ID_QUERY_PARAM_KEY,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/constants'

interface Props {
  children: React.ReactNode
  employeeId: number
  managerId: number
}

type UpdateTextSectionType = (value: string) => Promise<void>

interface MeetingNotesContextInterface {
  loadingStates: {
    isLoadingCreateNotes: boolean
    isLoadingMeetingNotesDetails: boolean
  }
  settings: {
    hasEditDisabled: boolean
    showCompletedMeetingSection: boolean
    isMeetingListAvailable: boolean
    isNotesEmptyState: boolean
    isEnabledAI: boolean
    isNotesDetails: boolean
  }
  updatingSection:
    | PublicContentItem['role']
    | PrivateContentItem['role']
    | 'summary'
    | 'personal_note'
    | undefined
  meetingNotesDetails: MeetingNotesFeedback | undefined
  selectedEvent: MeetingEvent<GoogleCalendarEventAttendee> | undefined
  setSelectedEvent: React.Dispatch<MeetingEvent<GoogleCalendarEventAttendee> | undefined>
  setMeetingList: React.Dispatch<MeetingEvent<GoogleCalendarEventAttendee>[]>
  onTalkingPointsChange: UpdateTextSectionType
  onPerformanceRatingChange: (
    performanceRating: {
      name: string
      value: FinalGrade
    } | null,
  ) => void
  onMeetingChange: (
    meetingEvent: MeetingEvent<GoogleCalendarEventAttendee> | undefined,
  ) => void
  onCreateNotes: () => Promise<void>
  refetchMeetingNotesFeedback: VoidFunction
  onPersonalNotesChange: UpdateTextSectionType
  onSummaryChange: UpdateTextSectionType
  onContentChange: ({
    newValue,
    role,
  }:
    | {
        newValue: PublicContentItem['content']
        role: PublicContentItem['role']
      }
    | {
        newValue: PrivateContentItem['content']
        role: PrivateContentItem['role']
      }) => void
}

export const MeetingNotesContext = React.createContext<MeetingNotesContextInterface>({
  onMeetingChange: () => {},
  onPerformanceRatingChange: () => {},
  onTalkingPointsChange: () => Promise.resolve(),
  onContentChange: () => Promise.resolve(),
  onPersonalNotesChange: () => Promise.resolve(),
  onSummaryChange: () => Promise.resolve(),
  setSelectedEvent: () => {},
  setMeetingList: () => {},
  onCreateNotes: () => Promise.resolve(),
  refetchMeetingNotesFeedback: () => {},
  selectedEvent: undefined,
  updatingSection: undefined,
  meetingNotesDetails: undefined,
  loadingStates: {
    isLoadingCreateNotes: false,
    isLoadingMeetingNotesDetails: false,
  },
  settings: {
    hasEditDisabled: false,
    showCompletedMeetingSection: false,
    isMeetingListAvailable: false,
    isNotesEmptyState: false,
    isEnabledAI: false,
    isNotesDetails: false,
  },
})

export const MeetingNotesContextProvider = ({
  children,
  employeeId,
  managerId,
}: Props) => {
  // INIT
  const { query, deleteQueryParam, changeQueryParam } = useQuery<{
    [CYCLE_ID_QUERY_PARAM_KEY]?: string
    [MEETING_EVENT_ID_QUERY_PARAM_KEY]?: string
  }>()
  const [saveHash] = useState(generateRandomId().toString())
  const [updatingSection, setUpdatingSection] = useState<
    | PublicContentItem['role']
    | PrivateContentItem['role']
    | 'summary'
    | 'personal_note'
    | undefined
  >()

  // STATE

  const [selectedEvent, setSelectedEvent] = useState<
    MeetingEvent<GoogleCalendarEventAttendee> | undefined
  >()

  const [meetingList, setMeetingList] = useState<
    MeetingEvent<GoogleCalendarEventAttendee>[]
  >([])

  // API CALLS
  const { mutateAsync: updateNotes, isLoading: isLoadingUpdatingNotes } =
    useUpdateOneToOneNote()

  const { mutateAsync: createNotes, isLoading: isLoadingCreateNotes } =
    useCreateOneToOneNote(selectedEvent?.id)

  const {
    data: meetingNotesDetails,
    isLoading: isLoadingMeetingNotesDetails,
    refetch: refetchMeetingNotesFeedback,
  } = useGetMeetingNotesFeedback(selectedEvent?.id)

  const { selectedCycle } = useReviewCycleData({
    activeCycleId: query.cycle_id,
    employeeId: Number(employeeId),
    managerId: Number(managerId),
  })

  // FUNCTION HANDLERS
  const onCreateNotes = async () => {
    const payload = {
      manager: { id: managerId },
      report: { id: employeeId },
      origin: MeetingNotesOrigin.Platform,
    }
    await createNotes(payload)
    refetchMeetingNotesFeedback()
  }

  const onMeetingChange = useCallback(
    (event: MeetingEvent<GoogleCalendarEventAttendee> | undefined) => {
      if (event === undefined) {
        deleteQueryParam(MEETING_EVENT_ID_QUERY_PARAM_KEY)
      } else if (!query.noteId || String(event.id) !== query.noteId) {
        changeQueryParam(MEETING_EVENT_ID_QUERY_PARAM_KEY, String(event.id))
      }

      setSelectedEvent(event)
    },
    [query.noteId],
  )

  const onContentChange = async ({
    newValue,
    role,
  }:
    | {
        newValue: PublicContentItem['content']
        role: PublicContentItem['role']
      }
    | {
        newValue: PrivateContentItem['content']
        role: PrivateContentItem['role']
      }) => {
    if (meetingNotesDetails && selectedEvent) {
      setUpdatingSection(role)
      const isPrivate = privateRoles.includes(role as PrivateContentItem['role']) // Type assertion to satisfy TypeScript)
      const newContent = (
        isPrivate ? meetingNotesDetails?.private_content : meetingNotesDetails?.content
      )?.map(item => {
        if (item.role === role) {
          item.content = newValue
        }
        return item
      })
      await updateNotes([
        { noteId: meetingNotesDetails.id, meetingId: selectedEvent.id },
        {
          [isPrivate ? 'private_content' : 'content']: newContent,
          origin: MeetingNotesOrigin.Platform,
          save_hash: saveHash,
        },
      ])
    }
  }

  const onSummaryChange = async (summary: string) => {
    if (meetingNotesDetails && selectedEvent) {
      setUpdatingSection('summary')
      await updateNotes([
        { noteId: meetingNotesDetails.id, meetingId: selectedEvent.id },
        { summary, origin: MeetingNotesOrigin.Platform, save_hash: saveHash },
      ])
    }
  }

  const onPersonalNotesChange = async (notes: string) => {
    if (meetingNotesDetails && selectedEvent) {
      setUpdatingSection('personal_note')
      const updateFieldName = Object.hasOwn(meetingNotesDetails, 'manager_personal_notes')
        ? 'manager_personal_notes'
        : 'report_personal_notes'

      await updateNotes([
        { noteId: meetingNotesDetails.id, meetingId: selectedEvent.id },
        {
          [updateFieldName]: notes,
          origin: MeetingNotesOrigin.Platform,
          save_hash: saveHash,
        },
      ])
    }
  }

  const onPerformanceRatingChange = (
    value: {
      name: string
      value: FinalGrade
    } | null,
  ) => {
    const performanceRating = getPrivateContentItemsByRole(
      meetingNotesDetails,
      'performance_rating',
    )?.content

    if (performanceRating) {
      onContentChange({
        newValue: { ...performanceRating, value },
        role: 'performance_rating',
      })
    }
  }

  const onTalkingPointsChange = async (talkingPoints: string) => {
    onContentChange({ newValue: { content: talkingPoints }, role: 'talking_points' })
  }

  // EFFECTS

  useEffect(() => {
    if (!isLoadingUpdatingNotes) {
      setUpdatingSection(undefined)
    }
  }, [isLoadingUpdatingNotes])

  // CONSTANTS

  // edit is enabled for the upcoming meetings and 3 latest completed meetings
  const isSelectedCompletedMeetingEditable = meetingList
    .filter(meeting => meeting.status === MeetingEventStatus.Completed)
    .slice(0, 3)
    .some(({ id }) => id === selectedEvent?.id)

  const isPreviousCycle = selectedCycle ? selectedCycle?.offset > 0 : false

  const hasEditDisabled =
    selectedEvent?.status === MeetingEventStatus.Completed
      ? !isSelectedCompletedMeetingEditable || isPreviousCycle
      : false

  const showCompletedMeetingSection =
    (selectedEvent?.start && isAfter(new Date(), new Date(selectedEvent.start))) ||
    selectedEvent?.status === MeetingEventStatus.Completed ||
    meetingNotesDetails?.has_transcript ||
    meetingNotesDetails?.summary_status !== null ||
    meetingNotesDetails.action_items_status !== null

  const isMeetingListAvailable = Boolean(meetingList.length)

  const isNotesEmptyState = Boolean(
    selectedEvent && !meetingNotesDetails?.id && !isLoadingMeetingNotesDetails,
  )

  const isEnabledAI = Boolean(
    meetingNotesDetails?.enable_ai &&
      (meetingNotesDetails?.summary_status === AiGenerationStatus.COMPLETED ||
        meetingNotesDetails?.has_transcript),
  )

  const isNotesDetails = Boolean(
    selectedEvent && meetingNotesDetails?.id && !isLoadingMeetingNotesDetails,
  )

  const value = useMemo<MeetingNotesContextInterface>(
    () => ({
      onTalkingPointsChange,
      onPerformanceRatingChange,
      onMeetingChange,
      onContentChange,
      onPersonalNotesChange,
      onSummaryChange,
      onCreateNotes,
      refetchMeetingNotesFeedback,
      meetingNotesDetails,
      updatingSection,
      selectedEvent,
      setSelectedEvent,
      setMeetingList,
      settings: {
        hasEditDisabled,
        showCompletedMeetingSection,
        isMeetingListAvailable,
        isNotesEmptyState,
        isEnabledAI,
        isNotesDetails,
      },
      loadingStates: {
        isLoadingCreateNotes,
        isLoadingMeetingNotesDetails,
      },
    }),
    [
      meetingNotesDetails,
      query.noteId,
      selectedEvent,
      updatingSection,
      hasEditDisabled,
      isMeetingListAvailable,
      showCompletedMeetingSection,
      meetingList,
      isNotesEmptyState,
      isLoadingCreateNotes,
      isLoadingMeetingNotesDetails,
    ],
  )

  return (
    <MeetingNotesContext.Provider value={value}>{children}</MeetingNotesContext.Provider>
  )
}

export const useMeetingNoteContext = () => {
  return useContext(MeetingNotesContext)
}
