import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Side, VStack } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { EligibilityGroupFilters } from '@src/pages/Forms/ReviewCycle/components/EligibilityGroupFilters'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  actions: React.ReactNode
}

export const PerformanceInsightsGroupFiltersSidebar = ({
  isOpen,
  onClose,
  actions,
}: Props) => {
  return (
    <SideBar
      variant="wide"
      title={
        <FormattedMessage
          id="forms.performance.insights.group.filters.sidebar.title"
          defaultMessage="Insights employee group"
        />
      }
      subtitle={
        <FormattedMessage
          id="forms.performance.insights.group.filters.sidebar.subtitle"
          defaultMessage="Filter for eligible employees"
        />
      }
      onClose={onClose}
      isOpen={isOpen}
    >
      <VStack space="s-16">
        <EligibilityGroupFilters />
        <Side.Actions variant="horizontal">{actions}</Side.Actions>
      </VStack>
    </SideBar>
  )
}
