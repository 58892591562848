import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  Button,
  Input,
  InputGroup,
  SelectInput,
  Side,
  Subheader,
  Text,
  TextArea,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import {
  AssigneeType,
  TaskDefinitionInterface,
} from '@src/interfaces/employeeOnboardingTemplate'
import SideBar from '@src/components/SideBar/SideBar'
import { useValidation } from '../hooks'

interface TaskFormProps {
  onClose: () => void
  onAdd: (data: TaskDefinitionInterface) => void
  onEdit: (data: TaskDefinitionInterface) => void
  task?: TaskDefinitionInterface
}

interface OptionInterface<T> {
  key: T
  label: string
  value: IdAndName<T>
}

const mapSelectorDataToOptions = <T,>(options?: IdAndName<T>[]) => {
  return options?.map(option => ({
    key: option.id,
    label: option.name,
    value: option,
  }))
}

const getOptionDisplayValue = <T,>(
  options: OptionInterface<T>[],
  value?: IdAndName<T> | null,
) => options.find(opt => opt.value.id === value?.id)?.value

const TaskForm = ({ onClose, onAdd, onEdit, task }: TaskFormProps) => {
  const [assignee, setAssignee] = useState<IdAndName | null | undefined>(
    task?.default_assignee,
  )
  const [assigneeRelationship, setAssigneeRelationship] = useState<
    IdAndName | null | undefined
  >(task?.default_assignee_relationship)
  const [assigneeType, setAssigneeType] = useState<IdAndName<AssigneeType> | undefined>(
    task?.default_assignee_type,
  )
  const [dynamicGroup, setDynamicGroup] = useState<IdAndName | null | undefined>(
    task?.default_assignee_group,
  )
  const [taskTitle, setTaskTitle] = useState<string | undefined>(task?.name)
  const [taskDescription, setTaskDescription] = useState<string | undefined>(
    task?.description,
  )

  const validation = useValidation({
    name: taskTitle,
    default_assignee: assignee,
    default_assignee_group: dynamicGroup,
    default_assignee_relationship: assigneeRelationship,
    default_assignee_type: assigneeType,
  })

  const { data: assigneeRelationships } = useGetSelectors<IdAndName>(
    selectorKeys.employee_onboarding_default_assignee_relationships,
  )
  const { data: assigneeTypes } = useGetSelectors<IdAndName<AssigneeType>>(
    selectorKeys.approval_step_approver_types,
  )
  const { data: employees } = useGetSelectors<IdAndName>(selectorKeys.employee)
  const { data: dynamicGroups } = useGetSelectors<IdAndName>(selectorKeys.dynamic_groups)

  const assigneeRelationshipsOptions =
    mapSelectorDataToOptions(assigneeRelationships) || []
  const assigneeTypesOptions = mapSelectorDataToOptions(assigneeTypes) || []
  const assigneeOptions = mapSelectorDataToOptions(employees) || []
  const dynamicGroupsOptions = mapSelectorDataToOptions(dynamicGroups) || []

  return (
    <>
      <InputGroup>
        <Input
          aria-invalid={!!validation.data.name}
          errorMessage={validation.data.name}
          label="Task title"
          onChange={e => {
            setTaskTitle(e.currentTarget.value)
            if (validation.data.name) {
              validation.resetFields('name')
            }
          }}
          value={taskTitle}
        />
        <TextArea
          label="Task description"
          onChange={e => setTaskDescription(e.currentTarget.value)}
          rows={3}
          value={taskDescription}
        />
        <Subheader>
          <Subheader.Title>Who should be responsible for this task?</Subheader.Title>
        </Subheader>
        <SelectInput
          aria-invalid={!!validation.data.default_assignee_type}
          errorMessage={validation.data.default_assignee_type}
          label="Assignee type"
          options={assigneeTypesOptions}
          onChange={option => {
            if (option) {
              setAssigneeType(option)
              if (validation.data.default_assignee_type) {
                validation.resetFields([
                  'default_assignee',
                  'default_assignee_group',
                  'default_assignee_relationship',
                  'default_assignee_type',
                ])
              }
            }
          }}
          renderOption={option => {
            if (!option.value.id) {
              return null
            }

            const description = {
              relationship: `A person who has some form of relationship to the onboarding employee.`,
              employee: `A specific person in the organisation.`,
              group: `A group of people from which the assignee can be selected. The groups are managed in the Groups application.`,
            }[option.value.id]

            return (
              <VStack space="s-4">
                <Text>{option.value.name}</Text>
                <Text color={Token.color.greyTone50} variant="caption">
                  {description}
                </Text>
              </VStack>
            )
          }}
          value={getOptionDisplayValue(assigneeTypesOptions, assigneeType)}
        />
        {assigneeType?.id === 'employee' && (
          <SelectInput
            aria-invalid={!!validation.data.default_assignee}
            errorMessage={validation.data.default_assignee}
            label="Assignee"
            onChange={option => {
              if (option) {
                setAssignee(option)
                if (validation.data.default_assignee) {
                  validation.resetFields('default_assignee')
                }
              }
            }}
            options={assigneeOptions}
            value={getOptionDisplayValue(assigneeOptions, assignee)}
          />
        )}
        {assigneeType?.id === 'group' && (
          <SelectInput
            aria-invalid={!!validation.data.default_assignee_group}
            errorMessage={validation.data.default_assignee_group}
            label="Group"
            onChange={option => {
              if (option) {
                setDynamicGroup(option)
                if (validation.data.default_assignee_group) {
                  validation.resetFields('default_assignee_group')
                }
              }
            }}
            options={dynamicGroupsOptions}
            value={getOptionDisplayValue(dynamicGroupsOptions, dynamicGroup)}
          />
        )}
        {assigneeType?.id === 'relationship' && (
          <SelectInput
            aria-invalid={!!validation.data.default_assignee_relationship}
            errorMessage={validation.data.default_assignee_relationship}
            label="Relationship"
            onChange={option => {
              if (option) {
                setAssigneeRelationship(option)
                if (validation.data.default_assignee_relationship) {
                  validation.resetFields('default_assignee_relationship')
                }
              }
            }}
            options={assigneeRelationshipsOptions}
            value={getOptionDisplayValue(
              assigneeRelationshipsOptions,
              assigneeRelationship,
            )}
          />
        )}
      </InputGroup>

      <Side.Actions>
        <Button
          onClick={() => {
            const errs = validation.validate()

            if (isEmpty(errs)) {
              const newData = {
                name: taskTitle!,
                description: taskDescription,
                default_assignee_type: assigneeType!,
                default_assignee: assignee!,
                default_assignee_group: dynamicGroup!,
                default_assignee_relationship: assigneeRelationship!,
              }
              if (task) {
                onEdit(newData)
              } else {
                onAdd(newData)
              }
              onClose()
            } else {
              validation.setErrors(errs)
            }
          }}
        >
          Add task
        </Button>
      </Side.Actions>
    </>
  )
}

export const TaskSidebar = ({
  isOpen,
  onClose,
  onAdd,
  onEdit,
  task,
}: {
  isOpen: boolean
  onClose: () => void
  onAdd: (data: TaskDefinitionInterface) => void
  onEdit: (data: TaskDefinitionInterface) => void
  task?: TaskDefinitionInterface
}) => {
  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title={task ? `Edit task ${task.name}` : 'Add task'}
    >
      <TaskForm onClose={onClose} onAdd={onAdd} onEdit={onEdit} task={task} />
    </SideBar>
  )
}
