import { FiltersSidebarItemInterface } from '@src/components/FiltersSidebar/FiltersSidebarItem'
import { selectorKeys } from '@src/constants/api'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import {
  employeeCountryColumn,
  employeeEntityColumn,
  employeeNameColumn,
  employeeNameIdStatusColumn,
  employeeStartedAtColumn,
  employeeTypeColumn,
} from '@src/constants/columns/employee'
import { locationNameColumn } from '@src/constants/columns/location'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { teamNameColumn } from '@src/constants/columns/team'
import { FilterType, RowInterface } from '@src/interfaces/data'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { OptionInterface } from '@src/interfaces/selectors'
import { TableSettingsInterface } from '@src/interfaces/tableSettings'

export const Row = (
  specialisationsEnabled: boolean,
): RowInterface<RevolutersInterface> => ({
  cells: [
    {
      ...employeeNameColumn,
      width: 150,
    },
    {
      ...employeeTypeColumn,
      width: 100,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 100,
    },
    {
      ...roleNameColumn(specialisationsEnabled),
      width: 130,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...employeeCountryColumn,
      width: 100,
    },
    {
      ...locationNameColumn,
      width: 100,
    },
    {
      ...employeeEntityColumn,
      width: 120,
    },
    {
      ...employeeStartedAtColumn,
      width: 120,
    },
    {
      ...employeeNameIdStatusColumn,
      width: 160,
    },
  ],
})

const canShowFilter = (
  col: {
    filterKey: string | null
    selectorsKey: selectorKeys | (() => Promise<{ options: OptionInterface[] }>)
  },
  filterType: FilterType,
) => {
  switch (filterType) {
    case FilterType.selector:
      return Boolean(col.filterKey && col.selectorsKey)
    case FilterType.date:
      return true
  }
  return false
}

export const filtersSidebarItems = (specialisationsEnabled: boolean) => {
  const roleNameColumnData = roleNameColumn(specialisationsEnabled)

  return [
    canShowFilter(employeeNameColumn, FilterType.selector) && {
      label: employeeNameColumn.title,
      field: employeeNameColumn.filterKey,
      filterType: FilterType.selector,
      selector: employeeNameColumn.selectorsKey,
      icon: 'Profile',
    },
    canShowFilter(employeeTypeColumn, FilterType.selector) && {
      label: 'Employee type',
      field: employeeTypeColumn.filterKey,
      filterType: FilterType.selector,
      selector: employeeTypeColumn.selectorsKey,
      icon: 'InfoOutline',
    },
    canShowFilter(departmentNameRevolutersColumn, FilterType.selector) && {
      label: departmentNameRevolutersColumn.title,
      field: departmentNameRevolutersColumn.filterKey,
      filterType: FilterType.selector,
      selector: departmentNameRevolutersColumn.selectorsKey,
      icon: 'Services',
    },
    canShowFilter(teamNameColumn, FilterType.selector) && {
      label: teamNameColumn.title,
      field: teamNameColumn.filterKey,
      filterType: FilterType.selector,
      selector: teamNameColumn.selectorsKey,
      icon: 'People',
    },
    canShowFilter(roleNameColumnData, FilterType.selector) && {
      label: roleNameColumnData.title,
      field: roleNameColumnData.filterKey,
      filterType: FilterType.selector,
      selector: roleNameColumnData.selectorsKey,
      icon: 'RepairTool',
    },
    canShowFilter(seniorityNameColumn, FilterType.selector) && {
      label: seniorityNameColumn.title,
      field: seniorityNameColumn.filterKey,
      filterType: FilterType.selector,
      selector: seniorityNameColumn.selectorsKey,
      icon: 'StarSemi',
    },
    canShowFilter(employeeCountryColumn, FilterType.selector) && {
      label: employeeCountryColumn.title,
      field: employeeCountryColumn.filterKey,
      filterType: FilterType.selector,
      selector: employeeCountryColumn.selectorsKey,
      icon: 'LocationPin',
    },
    canShowFilter(locationNameColumn, FilterType.selector) && {
      label: locationNameColumn.title,
      field: locationNameColumn.filterKey,
      filterType: FilterType.selector,
      selector: locationNameColumn.selectorsKey,
      icon: 'MapPoint',
    },
    canShowFilter(employeeEntityColumn, FilterType.selector) && {
      label: employeeEntityColumn.title,
      field: employeeEntityColumn.filterKey,
      filterType: FilterType.selector,
      selector: employeeEntityColumn.selectorsKey,
      icon: 'Bank',
    },
    canShowFilter(employeeStartedAtColumn, FilterType.date) && {
      label: employeeStartedAtColumn.title,
      field: employeeStartedAtColumn.filterKey,
      filterType: FilterType.date,
      icon: 'CalendarDate',
      showInPopup: true,
    },
    {
      label: 'Days since joined',
      field: 'days_since_joined',
      filterType: FilterType.rangeWithSelector,
      numberRangeFilterUnitLabel: 'Days',
      icon: 'CalendarCheck',
    },
    {
      label: 'Legal sex',
      field: 'legal_sex',
      filterType: FilterType.selector,
      selector: selectorKeys.legal_sexes,
      icon: 'InfoOutline',
    },
    canShowFilter(employeeNameIdStatusColumn, FilterType.selector) && {
      label: employeeNameIdStatusColumn.title,
      field: employeeNameIdStatusColumn.filterKey,
      filterType: FilterType.selector,
      selector: employeeNameIdStatusColumn.selectorsKey,
      icon: 'CheckVerification',
    },
  ].filter(Boolean) as FiltersSidebarItemInterface[]
}

const simplifiedViewVisible = (specialisationsEnabled: boolean) => [
  employeeNameColumn.title,
  teamNameColumn.title,
  roleNameColumn(specialisationsEnabled).title,
  seniorityNameColumn.title,
  locationNameColumn.title,
]

export const simplifiedColsSettings = (
  specialisationsEnabled: boolean,
): TableSettingsInterface => ({
  visible: simplifiedViewVisible(specialisationsEnabled),
  hidden: Row(specialisationsEnabled)
    .cells.map(col =>
      simplifiedViewVisible(specialisationsEnabled).includes(col.title)
        ? null
        : col.title,
    )
    .filter(Boolean),
})
