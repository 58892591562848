import React from 'react'
import { Flex, Text } from '@revolut/ui-kit'

import { CompetencyMatrixInterface } from '@src/interfaces/roles'
import { MatrixRowInterface } from '../types'
import { isSeparator } from '../helpers/isSeparator'

interface Props {
  rowData: MatrixRowInterface | CompetencyMatrixInterface
}

export const GroupSeparator = ({ rowData }: Props) => {
  if (!isSeparator(rowData)) {
    return null
  }
  return (
    <Flex alignItems="center" width="100%" px="s-16">
      <Text variant="emphasis1">{rowData.sectionTitle}</Text>
    </Flex>
  )
}
