import {
  EngagementInsightsInterface,
  EngagementInsightsTarget,
} from '@src/interfaces/engagement'
import { DatesRange } from '@src/pages/Performance/Engagement/components/Rounds/Selector/types'
import { ApiQueryParams } from '@src/api/types'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import { useGetEngagementInsights } from '@src/api/engagement'
import { uniqBy } from 'lodash'
import { useGetEngagementDrivers } from '@src/features/Engagement/api/analytics'

export interface InsightWithScore extends EngagementInsightsInterface {
  avgScore?: number
  trendAvgScore?: number
}

export const useGetInsightsWithScore = ({
  roundId,
  insightType,
  itemId,
  surveyId,
  datesRange,
  insightsGenerated,
  scopeParams,
}: {
  roundId: number
  insightType: EngagementInsightsTarget
  itemId: number
  surveyId: number
  insightsGenerated?: boolean
  datesRange?: DatesRange
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}) => {
  const {
    data: rawInsights = [],
    isLoading: isInsightsLoading,
    error: insightsError,
  } = useGetEngagementInsights({
    roundId,
    insightType,
    itemId,
    params: scopeParams,
    queryOptions: { enabled: insightsGenerated, refetchOnWindowFocus: false },
  })

  const uniqueDrivers = uniqBy(
    rawInsights.map(insight => insight.driver),
    'id',
  )

  const {
    data: drivers,
    isLoading: isDriversLoading,
    error: driversError,
  } = useGetEngagementDrivers({
    surveyId,
    queryOptions: {
      enabled: !!uniqueDrivers.length && insightType !== 'driver',
      refetchOnWindowFocus: false,
    },
    params: {
      ...(datesRange
        ? { from_date: datesRange.dateFrom, to_date: datesRange.dateTo }
        : undefined),
      driver: uniqueDrivers.map(driver => driver.id).join(','),
      ...scopeParams,
      survey_round_id: roundId,
    },
  })

  const scoresMap = (drivers?.results || []).reduce<
    Record<number, { avgScore: number; trendAvgScore: number | null }>
  >(
    (acc, driver) => ({
      ...acc,
      [driver.id]: {
        avgScore: driver.average_score,
        trendAvgScore: driver.trend_average_score,
      },
    }),
    {},
  )

  const insights = rawInsights?.map<InsightWithScore>(insight => ({
    ...insight,
    avgScore: scoresMap[insight.driver.id]?.avgScore,
    trendAvgScore: scoresMap[insight.driver.id]?.trendAvgScore || undefined,
  }))

  return {
    insights,
    isLoading: isInsightsLoading || isDriversLoading,
    insightsError,
    driversError,
  }
}
