import React from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { useGetEmployeeMeetingNotesFeedbackItemsByDates } from '@src/api/meetings'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import { OneToOnesSummary } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Summary'
import { FeedbackItem } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/Feedback/OneToOnes/FeedbackItem'

interface Props {
  cycle: ReviewCyclesInterface
  summary: MeetingsSummaryInterface
  employeeId: number
  managerId: number
}

export const OneToOnes = ({ cycle, summary, employeeId, managerId }: Props) => {
  const { data: feedbackItems } = useGetEmployeeMeetingNotesFeedbackItemsByDates(
    employeeId,
    {
      from: cycle.start_date_time,
      to: cycle.end_date_time,
    },
  )
  const { data: grades = [] } = useGetSelectors<PerformanceGradeOption>(
    selectorKeys.grades,
  )

  return (
    <VStack space="s-16">
      <Widget p="s-16">
        <OneToOnesSummary summary={summary} managerId={managerId} />
      </Widget>
      <VStack data-testid="meetings_feedback" space="s-16">
        {(feedbackItems?.results ?? []).map(feedback => (
          <FeedbackItem key={feedback.id} feedback={feedback} grades={grades} />
        ))}
      </VStack>
    </VStack>
  )
}
