import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import {
  CompetencyMatrixInterface,
  CompetencyMatrixInterfaceV2,
  CompetencyPerformanceWeightType,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { FeatureFlags } from '@src/store/auth/types'

interface Props {
  values: SpecialisationInterface
  role: undefined | RoleInterface
  type: 'specialisation' | 'role'
}

export const useMatrixData = ({ values, role, type }: Props) => {
  const competencyMatrixV2 =
    useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2) && type === 'specialisation'

  const changeSkillsWeights = (data: CompetencyMatrixInterface[]) => {
    data.forEach(skillItem => {
      const weightItem = values.performance_weights!.find(
        item => item.skill_id === skillItem.skill?.id,
      )

      const skillWeight =
        skillItem.weight && (skillItem.weight >= 5 ? skillItem.weight : 5)

      const weight = skillWeight || 0

      if (weightItem) {
        weightItem.weight = weight
      } else {
        values.performance_weights!.push({
          skill_id: skillItem.skill?.id || null,
          weight_type: CompetencyPerformanceWeightType.Skill,
          weight,
        })
      }
    })
  }

  const onChangeSpecialisationMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data) {
      return
    }

    values.functional_competency_matrix = data.map(item => ({
      ...item,
      id: undefined,
      weight: undefined,
    }))

    if (values.performance_weights) {
      const matrix = [...(roleFunctionalMatrix || []), ...data]
      values.performance_weights = values.performance_weights.filter(item =>
        matrix.find(skillItem => {
          if (item.weight_type === CompetencyPerformanceWeightType.Skill) {
            return skillItem.skill?.id === item.skill_id
          }
          return true
        }),
      )

      changeSkillsWeights(data)
    }
  }

  const onChangeDeliverablesMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data?.[0]) {
      return
    }

    const row = data[0]
    values.deliverables_competencies = row.competencies

    if (values.performance_weights) {
      const weightItem = values.performance_weights.find(
        item => item.weight_type === CompetencyPerformanceWeightType.Deliverables,
      )

      const weight = row.weight || 0

      if (weightItem) {
        weightItem.weight = weight
      } else {
        values.performance_weights.push({
          skill_id: null,
          weight_type: CompetencyPerformanceWeightType.Deliverables,
          weight,
        })
      }
    }
  }

  const onChangeRoleMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data || !values.performance_weights) {
      return
    }

    changeSkillsWeights(data)
  }

  const onChangeCompetencyMatrix = (data?: CompetencyMatrixInterfaceV2[]) => {
    if (data) {
      values.competency_matrix = data
    }
  }

  const deliverablesMatrix = (() => {
    const competencyMatrixV2Deliverable = competencyMatrixV2
      ? values.competency_matrix?.find(skill => skill.skill_id == null)
      : null

    if (competencyMatrixV2) {
      return competencyMatrixV2Deliverable
        ? [mapCompetencyV2ToV1(competencyMatrixV2Deliverable)]
        : []
    }

    return [
      {
        skill: {
          id: null,
          name: 'Deliverables',
        },
        competencies: values.deliverables_competencies || [],
        weight: values.performance_weights?.find(
          w => w.weight_type === CompetencyPerformanceWeightType.Deliverables,
        )?.weight,
      },
    ]
  })()

  const roleFunctionalMatrix = competencyMatrixV2
    ? values.competency_matrix
        ?.filter(skill => !!skill.skill_id && skill.parent_skill === true)
        .map(mapCompetencyV2ToV1)
    : role?.functional_competency_matrix &&
      role.functional_competency_matrix.map(item => ({
        ...item,
        weight: values.performance_weights?.find(
          w =>
            w.weight_type === CompetencyPerformanceWeightType.Skill &&
            w.skill_id === item.skill?.id,
        )?.weight,
      }))

  const specialisationFunctionalMatrix = competencyMatrixV2
    ? values.competency_matrix
        ?.filter(skill => !!skill.skill_id && skill.parent_skill === false)
        .map(mapCompetencyV2ToV1)
    : values.functional_competency_matrix?.map(item => ({
        ...item,
        weight: values.performance_weights?.find(
          w =>
            w.weight_type === CompetencyPerformanceWeightType.Skill &&
            w.skill_id === item.skill?.id,
        )?.weight,
      }))

  return {
    deliverablesMatrix,
    roleFunctionalMatrix,
    specialisationFunctionalMatrix,
    onChangeDeliverablesMatrix,
    onChangeRoleMatrix,
    onChangeSpecialisationMatrix,
    onChangeCompetencyMatrix,
  }
}

const mapCompetencyV2ToV1 = (item: CompetencyMatrixInterfaceV2) => {
  return {
    weight: item.weight,
    skill: { ...item, id: item.skill_id },
    competencies: item.competencies,
  }
}
