import { matchPath } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { ItemsToAnalyse } from '@src/features/Engagement/api/analytics/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { history } from '@src/utils/router'
import { TimelineFilterInterface } from '../../Rounds/Selector/types'
import { UseHeatmapFiltersReturnType } from './hooks/useHeatmapFilters'
import { toLabeledIdAndName } from '@src/utils/toIdAndName'
import { IdAndName } from '@src/interfaces'
import { HeatMapComparisonMode } from '@src/features/Engagement/components/HeatmapTableCell'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export const MAX_ITEMS_ALLOWED_FOR_ANALYSIS = 10

export const mainEntrypointBaseRoutes = [
  ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS,
  ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS,
]

export const entityLevelEntrypointBaseRoutes = [
  ROUTES.ORGANISATION.COMPANY.ENGAGEMENT,
  ROUTES.FORMS.DEPARTMENT.ENGAGEMENT,
  ROUTES.FORMS.TEAM.ENGAGEMENT,
  ROUTES.FORMS.ROLE.ENGAGEMENT,
  ROUTES.FORMS.FUNCTION.ENGAGEMENT,
  ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT,
  ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT,
]

export const allSurveyResultsBaseRoutes = [
  ...mainEntrypointBaseRoutes,
  ...entityLevelEntrypointBaseRoutes,
]

export const getAnalysedItemsBaseRoute = (subroute?: 'CATEGORIES' | 'COMMENTS') => {
  const baseRoute = allSurveyResultsBaseRoutes.find(
    surveyResultsBaseRoute =>
      !!matchPath(history.location.pathname, surveyResultsBaseRoute.ANY),
  )
  if (subroute) {
    return baseRoute?.[subroute]
  }
  return baseRoute?.ANY
}

export const getHeatmapComparingOptions = (
  isScopedView: boolean,
  viewMode: ViewMode,
): RadioSelectOption<IdAndName<HeatMapComparisonMode>>[] => {
  const comparedTo =
    isScopedView || viewMode === 'table' ? 'Compared to Company' : 'Compared to Benchmark'
  return [
    toLabeledIdAndName('score'),
    toLabeledIdAndName('trend'),
    {
      label: comparedTo,
      value: {
        id: 'compared',
        name: comparedTo,
      },
    },
  ]
}

export type ViewMode = 'table' | 'heatmap'
export type DetailsPageType = 'question' | 'category'

export type ResultsInterface = {
  survey: EngagementSurveyInterface
  refetchSurvey: VoidFunction
  initialFilters?: FilterByInterface[]
  timelineFilter: TimelineFilterInterface
  heatmapFilters: UseHeatmapFiltersReturnType
  heatMapComparisonMode?: HeatMapComparisonMode
  viewMode?: ViewMode
  itemsToAnalyse?: ItemsToAnalyse
  scopeFilters?: FilterByInterface[]
  showItemTypeSelector?: boolean
}
