import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Flex, Text, Token, Widget } from '@revolut/ui-kit'

import { useGetSurveyResultsItemTimeSeries } from '@src/api/engagement'
import Loader from '@src/components/CommonSC/Loader'
import { LineChart } from '@src/pages/Forms/QueryForm/components/Charts/LineChart'
import { formatDate } from '@src/utils/format'
import { NoDataPlaceholder, RouteParams, useDetailsFilterParamsFromQuery } from './common'

interface Props {
  yPadding: number
  titleHeight: number
  containerHeight: number
}
export const TimeSeriesChartWidget = ({
  yPadding,
  titleHeight,
  containerHeight,
}: Props) => {
  const filtersParams = useDetailsFilterParamsFromQuery()
  const params = useParams<RouteParams>()

  const { data: timeSeriesData = [], isLoading: isLoadingTimeSeriesData } =
    useGetSurveyResultsItemTimeSeries({
      surveyId: params.id,
      itemsToAnalyse: params.itemType,
      itemId: params.itemId,
      filtersParams,
    })

  const lineChartProps = useMemo(
    () => ({
      dataKeys: [
        {
          id: 0,
          label: 'Score value',
          value: 'value0' as const,
          color: Token.colorChannel.blue,
        },
      ],
      data: timeSeriesData
        .filter(datum =>
          Boolean(datum.average_score != null && datum.from_date && datum.to_date),
        )
        .map(datum => ({
          id: formatDate(datum.to_date),
          label: formatDate(datum.to_date),
          value0: datum.average_score,
        })),
    }),
    [timeSeriesData],
  )

  return (
    <Widget px="s-12" py={yPadding} height="100%">
      <Box height={titleHeight}>
        <Text use="div" variant="emphasis1" mb="s-16">
          Average score over time
        </Text>
      </Box>
      {isLoadingTimeSeriesData ? (
        <Flex height={containerHeight} alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      ) : timeSeriesData.length ? (
        <Box height={containerHeight}>
          <LineChart {...lineChartProps} />
        </Box>
      ) : (
        <NoDataPlaceholder />
      )}
    </Widget>
  )
}
