import React from 'react'
import {
  Box,
  Color,
  Flex,
  HStack,
  Icon,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'

import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import {
  getHeatMapCellColor,
  getPrimaryValue,
  getSecondaryValue,
  HeatmapTableCellData,
} from './helpers'

const HEATMAP_ITEM_HEIGHT = rowWrapperMinHeight.heatmap - 8 // reduced to visually separate the rows
export const HEATMAP_ITEM_MIN_WIDTH = 170
export type HeatMapComparisonMode = 'score' | 'trend' | 'compared'

const CellWrapper = ({
  children,
  color,
  isParent,
}: {
  children: React.ReactNode
  color: Color
  isParent?: boolean
}) => {
  return (
    <Box
      width={isParent ? '100%' : 'calc(100% - 12px)'}
      height={HEATMAP_ITEM_HEIGHT}
      radius={Token.radius.r12}
      backgroundColor={color}
      ml={isParent ? undefined : '12px'}
    >
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Flex>
    </Box>
  )
}

export const HeatmapTableCell = ({
  data,
  isParent,
  mode = 'score',
}: {
  data?: HeatmapTableCellData
  isParent?: boolean
  mode?: HeatMapComparisonMode
}) => {
  const tooltip = useTooltip()
  if (data?.anonymized) {
    return (
      <CellWrapper color={Token.color.greyTone8} isParent={isParent}>
        <Icon
          name="EyeHide"
          color={Token.color.greyTone20}
          size={16}
          {...tooltip.getAnchorProps()}
        />
        <Tooltip placement="top" {...tooltip.getTargetProps()}>
          This result is hidden due to the anonymity threshold
        </Tooltip>
      </CellWrapper>
    )
  }
  if (data?.average_score == null) {
    return (
      <CellWrapper color={Token.color.greyTone8} isParent={isParent}>
        <Icon
          name="MinusCircle"
          color={Token.color.greyTone20}
          size={16}
          {...tooltip.getAnchorProps()}
        />
        <Tooltip placement="top" {...tooltip.getTargetProps()}>
          This result is not available
        </Tooltip>
      </CellWrapper>
    )
  }

  return (
    <CellWrapper color={getHeatMapCellColor({ data, mode })} isParent={isParent}>
      <HStack space="s-2" align="end">
        <Text fontWeight={500} lineHeight={1} color={Token.color.greyTone50}>
          {getPrimaryValue({ data, mode })}
        </Text>
      </HStack>
      <HStack mt="s-8" gap="s-8" align="center">
        {getSecondaryValue({ data, mode })}
        {typeof data.comparisonScore === 'number' && (
          <HStack space="s-2" align="center">
            <Text variant="body3" color={Token.color.greyTone50}>
              {data.comparisonScore}
            </Text>
            <Icon color={Token.color.greyTone50} size={12} name="CompanyOutline" />
          </HStack>
        )}
      </HStack>
    </CellWrapper>
  )
}
