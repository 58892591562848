import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, MoreBar, Text, TextArea } from '@revolut/ui-kit'
import { updateEmployeeStatus as updateEmployeeStatusApi } from '@src/api/employees'
import {
  cancelOnboarding,
  completeOnboarding,
  startOnboarding,
  transferOnboarding,
} from '@src/api/onboardingEmployeesV2'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  EmployeeStatusTransitionsInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { OnboardingInterface } from '@src/interfaces/onboardingV2'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import SettingsButtons from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

type OnboardingActionsProps = {
  employee: EmployeeInterface
  onboardingData: OnboardingInterface
  onRefetchOnboarding: VoidFunction
  onRefetchDocumentsSignature: VoidFunction
  onRefetchDocumentsUpload: VoidFunction
  setEmailSidebarOpen: (isOpen: boolean) => void
}

export const OnboardingActions = ({
  employee,
  onboardingData,
  onRefetchOnboarding,
  onRefetchDocumentsSignature,
  onRefetchDocumentsUpload,
  setEmailSidebarOpen,
}: OnboardingActionsProps) => {
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false)
  const [cancelPending, setCancelPending] = useState(false)
  const [cancelReason, setCancelReason] = useState<string>()
  const [completePopupOpen, setCompletePopupOpen] = useState(false)
  const [completePending, setCompletePending] = useState(false)
  const [markAsNotHiredPending, setMarkAsNotHiredPending] = useState(false)
  const [newHrManager, setNewHrManager] = useState<EmployeeOptionInterface>()
  const [ownershipPending, setOwnershipPending] = useState(false)
  const [ownershipPopupOpen, setOwnershipPopupOpen] = useState(false)
  const [startPopupOpen, setStartPopupOpen] = useState(false)
  const [startPending, setStartPending] = useState(false)

  const errorPopup = useErrorPopup()

  const showMarkAsNotHired =
    employee.status.id === IdStatuses.onboarding ||
    employee.status.id === IdStatuses.pending ||
    employee.status.id === IdStatuses.hired

  const onCancelProcess = async () => {
    setCancelPending(true)
    try {
      await cancelOnboarding(onboardingData.id, cancelReason)
      onRefetchOnboarding()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to cancel onboarding',
        forceFallbackTitle: true,
      })
    } finally {
      setCancelPending(false)
      setCancelPopupOpen(false)
    }
  }

  const onCompleteProcess = async () => {
    setCompletePending(true)
    try {
      await completeOnboarding(onboardingData.id)
      onRefetchOnboarding()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to complete onboarding',
        forceFallbackTitle: true,
      })
    } finally {
      setCompletePending(false)
      setCompletePopupOpen(false)
    }
  }

  const onConfirmTransferOwnership = async () => {
    if (!newHrManager) {
      return
    }
    setOwnershipPending(true)
    try {
      await transferOnboarding(onboardingData.id, newHrManager)
      onRefetchOnboarding()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to transfer ownership',
        forceFallbackTitle: true,
      })
    } finally {
      setOwnershipPending(false)
      setOwnershipPopupOpen(false)
      setNewHrManager(undefined)
    }
  }

  const onMarkAsNotHired = async () => {
    setMarkAsNotHiredPending(true)
    try {
      await updateEmployeeStatusApi(employee.id, {
        status: { id: IdStatuses.not_hired },
      } as EmployeeStatusTransitionsInterface)
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to mark as not hired',
        forceFallbackTitle: true,
      })
    } finally {
      setMarkAsNotHiredPending(false)
    }
  }

  const onStartProcess = async () => {
    setStartPending(true)
    try {
      await startOnboarding(onboardingData.id)
      onRefetchOnboarding()
      onRefetchDocumentsSignature()
      onRefetchDocumentsUpload()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to start onboarding',
        forceFallbackTitle: true,
      })
    } finally {
      setStartPending(false)
      setStartPopupOpen(false)
    }
  }

  return (
    <>
      <Box>
        <SettingsButtons pb="s-16" maxCount={2}>
          {onboardingData.status.id === 'not_started' && (
            <MoreBar.Action onClick={() => setStartPopupOpen(true)} variant="accent">
              Begin onboarding
            </MoreBar.Action>
          )}
          {onboardingData.status.id === 'in_progress' && (
            <MoreBar.Action onClick={() => setCompletePopupOpen(true)} variant="accent">
              Complete onboarding
            </MoreBar.Action>
          )}
          <MoreBar.Action onClick={() => setEmailSidebarOpen(true)} useIcon="Envelope">
            Send email
          </MoreBar.Action>
          <MoreBar.Action
            target="_blank"
            to={pathToUrl(
              ROUTES.ONBOARDING_V2.START,
              { id: employee.id, onboardingId: onboardingData.id },
              { preview: 'true' },
            )}
            use={Link}
            useIcon="LinkExternal"
          >
            Preview onboarding portal
          </MoreBar.Action>
          <MoreBar.Action
            onClick={() => setOwnershipPopupOpen(true)}
            useIcon="ArrowRightLeft"
          >
            Transfer ownership
          </MoreBar.Action>
          {showMarkAsNotHired && onboardingData.status.id === 'not_started' && (
            <MoreBar.Action
              onClick={onMarkAsNotHired}
              pending={markAsNotHiredPending}
              useIcon="CrossVoid"
            >
              Mark as not hired
            </MoreBar.Action>
          )}
          {onboardingData.status.id !== 'cancelled' && (
            <MoreBar.Action
              onClick={() => setCancelPopupOpen(true)}
              variant="negative"
              useIcon="CrossCircle"
            >
              Cancel process
            </MoreBar.Action>
          )}
        </SettingsButtons>
      </Box>

      <ConfirmationDialog
        onClose={() => setStartPopupOpen(false)}
        onConfirm={onStartProcess}
        onReject={() => setStartPopupOpen(false)}
        open={startPopupOpen}
        loading={startPending}
        label="Send onboarding invitation"
        body={`${employee.full_name} will receive a invitation email to start his onboarding via the platform.`}
        yesMessage="Send invitation"
        noMessage="Cancel"
      />

      <ConfirmationDialog
        onClose={() => setCancelPopupOpen(false)}
        onConfirm={onCancelProcess}
        onReject={() => setCancelPopupOpen(false)}
        open={cancelPopupOpen}
        loading={cancelPending}
        body={
          <>
            <Text mb="s-16">
              Are you sure you want to cancel this onboarding process?
            </Text>
            <TextArea
              label="Cancel reason"
              onChange={e => setCancelReason(e.currentTarget.value)}
              rows={3}
              value={cancelReason}
            />
          </>
        }
        yesMessage="Confirm"
        noMessage="Cancel"
        submitDisabled={!cancelReason}
      />

      <ConfirmationDialog
        onClose={() => setCompletePopupOpen(false)}
        onConfirm={onCompleteProcess}
        onReject={() => setCompletePopupOpen(false)}
        open={completePopupOpen}
        loading={completePending}
        body="Are you sure you want to complete this onboarding process?"
        yesMessage="Confirm"
        noMessage="Cancel"
      />

      <ConfirmationDialog
        onClose={() => setOwnershipPopupOpen(false)}
        onConfirm={onConfirmTransferOwnership}
        onReject={() => setOwnershipPopupOpen(false)}
        open={ownershipPopupOpen}
        label="Transfer ownership"
        loading={ownershipPending}
        body={
          <RadioSelectInput
            label="HR manager"
            selector={selectorKeys.hr_managers_employees}
            onChange={option => {
              if (option) {
                setNewHrManager(option)
              }
            }}
            value={newHrManager}
          />
        }
        yesMessage="Confirm"
        noMessage="Cancel"
        submitDisabled={
          !newHrManager || newHrManager?.id === onboardingData.hr_manager.id
        }
      />
    </>
  )
}
