import { KeyboardEvent } from 'react'
import { BULLET, SEPARATOR, BULLET_POINT_REG_EXP, AFTER_BULLET_SPACE } from './constants'

export const getPrefix = () => `${BULLET}${AFTER_BULLET_SPACE}`

export const splitTextAtPosition = (text: string, position: number) => {
  const textBefore = text.slice(0, position)
  const textAfter = text.slice(position)
  return { textBefore, textAfter }
}

export const linesToBulletText = (lines: string[]): string => {
  return lines.map(line => `${getPrefix()}${line}`).join(SEPARATOR)
}

export const bulletTextToLines = (text: string): string[] | undefined => {
  const lines = text
    .split(SEPARATOR)
    .map(line => line.replace(BULLET_POINT_REG_EXP, '').trim())
    .filter(Boolean)

  return lines.length > 0 ? lines : undefined
}

export const isPrintableKey = (event: KeyboardEvent<HTMLTextAreaElement>) => {
  return event.key.length === 1 && !event.metaKey && !event.ctrlKey && !event.altKey
}
