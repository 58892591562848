import React, { useEffect } from 'react'
import { AxiosError } from 'axios'
import PageLoading from '@components/PageLoading/PageLoading'
import { GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import {
  connectGoogleEmail,
  connectGoogleCalendar,
  OfficeSuiteProviderSourceType,
  OfficeSuiteProviderType,
  connectMicrosoftEmail,
  connectMicrosoftCalendar,
  connectGoogleWorkspace,
  connectSlackWorkspace,
  connectJiraWorkspace,
  connectLookerWorkspace,
  connectClickupWorkspace,
  connectSalesforceWorkspace,
  connectSlackWorkspaceV2,
  connectLinearWorkspace,
  connectDocusign,
  connectMondayWorkspace,
} from '@src/api/officeSuiteProvider'
import * as Sentry from '@sentry/react'
import { LocalStorageKeys } from '@src/store/auth/types'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { captureError } from '@src/features/Errors/captureError'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { CreateEmployeeUploadSource } from '@src/api/bulkDataImport'
import {
  clickupIntegrationPreferences,
  docuSignPreferencesRequests,
  jiraPreferencesRequests,
  linearIntegationPreferences,
  lookerIntegrationPreferences,
  mondayIntegrationPreferences,
  salesforceIntegrationPreferences,
} from '@src/api/integrations'
import { roadmapSettings } from '@src/api/settings'
import { useSyncMeetings } from '@src/api/meetings'
import { useOneToOnesCalendarAvailability } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/hooks/useOneToOnesAvailability'
import { CalendarProviders } from '@src/interfaces/meetings'

declare global {
  interface Window {
    handleSuccessfulConnectionToWorkspace?: (type?: CreateEmployeeUploadSource) => void
    showFailureToConnectWorkspace?: (error?: AxiosError) => void
  }
}

const GrantPermissionsRedirect = () => {
  const oneToOnesCalendarAvailability = useOneToOnesCalendarAvailability()
  const { mutateAsync: syncMeetings } = useSyncMeetings()

  useEffect(() => {
    const validateToken = async () => {
      const currentURL = new URL(window.location.href)
      const token = currentURL.searchParams.get('code')
      const state = currentURL.searchParams.get('state')
      let source: OfficeSuiteProviderSourceType | undefined
      let type: OfficeSuiteProviderType | undefined

      try {
        const parsedState: unknown = state && JSON.parse(state)

        if (typeof parsedState === 'object' && parsedState !== null) {
          if ('source' in parsedState) {
            source = parsedState.source as OfficeSuiteProviderSourceType | undefined
          }

          if ('type' in parsedState) {
            type = parsedState.type as OfficeSuiteProviderType | undefined
          }
        }
      } catch (e) {
        captureError(e, { tags: { component: 'GrantPermissionsRedirect' } })
        source = undefined
        type = undefined
      }

      try {
        if (token) {
          switch (type) {
            case 'googleEmail': {
              await connectGoogleEmail(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
                source,
              )
              break
            }

            case 'googleCalendar': {
              await connectGoogleCalendar(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)

              if (oneToOnesCalendarAvailability[CalendarProviders.Google]) {
                await syncMeetings()
              }

              break
            }

            case 'googleWorkspace': {
              await connectGoogleWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'slackWorkspace': {
              await connectSlackWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'slackIntegration': {
              await connectSlackWorkspaceV2(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'microsoftEmail': {
              await connectMicrosoftEmail(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
                source,
              )
              break
            }

            case 'jiraWorkspace': {
              await connectJiraWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await jiraPreferencesRequests.update({ enabled: true }, { id: '1' })
              await roadmapSettings.update({ jira_epics_enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Jira',
              )
              break
            }
            case 'lookerWorkspace': {
              await connectLookerWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await lookerIntegrationPreferences.update({ enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Looker',
              )
              break
            }

            case 'microsoftCalendar': {
              await connectMicrosoftCalendar(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
              )
              break
            }

            case 'clickupWorkspace': {
              await connectClickupWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await clickupIntegrationPreferences.update({ enabled: true }, { id: '1' })
              await roadmapSettings.update({ clickup_enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'ClickUp',
              )
              break
            }

            case 'docusign': {
              await connectDocusign(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await docuSignPreferencesRequests.update({ enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'DocuSign',
              )
              break
            }

            case 'linearWorkspace': {
              await connectLinearWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await linearIntegationPreferences.update({ enabled: true }, { id: '1' })
              await roadmapSettings.update({ linear_enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Linear',
              )
              break
            }

            case 'salesforceWorkspace': {
              await connectSalesforceWorkspace(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
              )
              await salesforceIntegrationPreferences.update(
                { enabled: true },
                { id: '1' },
              )
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Salesforce',
              )
              break
            }

            case 'monday': {
              await connectMondayWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await mondayIntegrationPreferences.update({ enabled: true }, { id: '1' })
              await roadmapSettings.update({ monday_enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Monday',
              )
              break
            }

            default: {
              Sentry.captureMessage(
                '[GrantPermissionsRedirect] type is required but not defined',
              )
            }
          }
        }

        if (window && window.opener && window.opener.location) {
          if (type === 'googleWorkspace') {
            window.opener?.focus?.()
            window.opener.handleSuccessfulConnectionToWorkspace?.('google')
          } else if (type === 'slackWorkspace') {
            window.opener?.focus?.()
            window.opener.handleSuccessfulConnectionToWorkspace?.('slack')
          } else if (type === 'jiraWorkspace') {
            window.opener?.focus?.()
            window.opener.handleSuccessfulConnectionToWorkspace?.('jira')
          } else if (type === 'slackIntegration') {
            window.opener?.focus?.()
            window.opener.handleSuccessfulConnectionToWorkspace?.()
          } else {
            window.opener.location.reload()
          }
          window.close()
        }
      } catch (e) {
        captureError(e, { tags: { type } })

        // save the error in the localstorage as we close the window
        workspaceLocalStorage.setItem(
          LocalStorageKeys.PROVIDER_PERMISSIONS_ERROR,
          JSON.stringify({
            type,
            message: e?.response?.data?.[0] || UNKNOWN_ERROR,
          }),
        )

        if (window && window.opener && window.opener.location) {
          if (
            type === 'googleWorkspace' ||
            type === 'slackWorkspace' ||
            type === 'slackIntegration' ||
            type === 'jiraWorkspace'
          ) {
            window.opener?.focus?.()
            window.opener.showFailureToConnectWorkspace?.(e)
          }
          window.close()
        }
      }
    }

    validateToken()
  }, [])

  return <PageLoading />
}

export default GrantPermissionsRedirect
