import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { formatDateTime } from '@src/utils/format'
import {
  SummaryFormatted,
  SummaryProps,
} from '@src/features/AiInsights/Performance/components/Summary/Summary'

interface Props extends SummaryProps {
  updatedAt: string
  details?: React.ReactNode
  footerVariant?: FooterVariant
}

export const KeyInsights = ({
  summary,
  updatedAt,
  details,
  variant,
  footerVariant,
}: Props) => (
  <VStack space="s-12">
    <SummaryFormatted summary={summary} variant={variant} />
    {details}
    <Footer updatedAt={updatedAt} variant={footerVariant} />
  </VStack>
)

type FooterVariant = 'default' | 'compact'

interface FooterProps {
  updatedAt: string
  variant?: FooterVariant
}

const Footer = ({ updatedAt, variant = 'default' }: FooterProps) => {
  const flexDirection = variant === 'default' ? 'row' : 'column'
  const justifyContent = variant === 'default' ? 'space-between' : 'start'

  return (
    <Flex gap="s-4" flexDirection={flexDirection} justifyContent={justifyContent}>
      <Text fontSize={12} color={Token.color.greyTone50}>
        <FormattedMessage
          id="performance.ai.insights.warning.description"
          defaultMessage="AI-generated content may include errors. Verify critical details."
        />
      </Text>
      <Text fontSize={12} color={Token.color.greyTone50}>
        <FormattedMessage
          id="performance.ai.insights.generation.time.label"
          values={{
            updatedAt: formatDateTime(updatedAt),
          }}
          defaultMessage="Last updated on: {updatedAt}"
        />
      </Text>
    </Flex>
  )
}
