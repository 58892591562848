import React, { useState } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Group,
  InputGroup,
  Item,
  Subheader,
  Switch,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API, selectorKeys } from '@src/constants/api'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { candidateSettings, useGlobalSettings } from '@src/api/settings'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { PRODUCT_NAME } from '@src/constants/product'
import {
  askGoogleEmailPermissions,
  askMicrosoftEmailPermissions,
} from '@src/api/officeSuiteProvider'
import {
  mapAccountStatusToProps,
  useShowOfficeSuitePermissionsError,
} from '@src/utils/linkedAccounts'
import { Statuses } from '@src/interfaces'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CandidateSettingsInterface,
  OfficeSuiteProviderType,
} from '@src/interfaces/settings'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import EmailTemplatePreview from '@src/pages/Settings/Candidates/EmailTemplatePreview'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'

export const SchedulingEmailTemplate = () => {
  const { values } = useLapeContext<CandidateSettingsInterface>()
  const [emailPreviewOpen, setEmailPreviewOpen] = useState<number | string>()
  return (
    <>
      <Widget p="s-16">
        <InputGroup>
          <Box>
            <Text variant="emphasis1">Automatic slots template</Text>
            <Subheader variant="nested">
              <Subheader.Title>
                Define the template to be used when scheduling interviews through the
                automatic slots method. The candidate is prompted to choose a slot from a
                shared range of slots. The time and date of the interview are not known at
                the moment of scheduling.
              </Subheader.Title>
            </Subheader>
          </Box>
          <LapeRadioSelectInput
            name="default_automatic_scheduling_email_template"
            selector={selectorKeys.email_templates_active}
            label="Automatic slots email template"
            clearable
            onAfterChange={val => {
              setEmailPreviewOpen(val?.id)
            }}
          />
          {values.default_automatic_scheduling_email_template && (
            <ActionButton
              onClick={() =>
                setEmailPreviewOpen(
                  values.default_automatic_scheduling_email_template?.id,
                )
              }
            >
              Show preview
            </ActionButton>
          )}
          <Box>
            <Text variant="emphasis1">Custom scheduling template</Text>
            <Subheader variant="nested">
              <Subheader.Title>
                Define the template to be used when scheduling interviews through
                alternative scheduling methods. The time and date of an interview are
                selected by the coordinator responsible for scheduling the interview.
              </Subheader.Title>
            </Subheader>
          </Box>
          <LapeRadioSelectInput
            clearable
            name="default_custom_scheduling_email_template"
            selector={selectorKeys.email_templates_active}
            label="Custom slots email template"
            onAfterChange={val => {
              setEmailPreviewOpen(val?.id)
            }}
          />
          {values.default_custom_scheduling_email_template && (
            <ActionButton
              onClick={() =>
                setEmailPreviewOpen(values.default_custom_scheduling_email_template?.id)
              }
            >
              Show preview
            </ActionButton>
          )}
          <Box>
            <Text variant="emphasis1">Catch-up call email template</Text>
            <Subheader variant="nested">
              <Subheader.Title>
                Define the email template to be used by default when scheduling catch-up
                calls
              </Subheader.Title>
            </Subheader>
          </Box>
          <LapeRadioSelectInput
            clearable
            name="default_adhoc_call_email_template"
            selector={selectorKeys.email_templates_active}
            label="Catch-up calls email template"
            onAfterChange={val => {
              setEmailPreviewOpen(val?.id)
            }}
          />
          {values.default_adhoc_call_email_template && (
            <ActionButton
              onClick={() =>
                setEmailPreviewOpen(values.default_adhoc_call_email_template?.id)
              }
            >
              Show preview
            </ActionButton>
          )}
        </InputGroup>
      </Widget>
      {emailPreviewOpen && (
        <EmailTemplatePreview
          id={emailPreviewOpen}
          onClose={() => setEmailPreviewOpen(undefined)}
        />
      )}
    </>
  )
}

type EmailIntegrationProps = {
  title: React.ReactNode
  officeSuite: OfficeSuiteProviderType
}

export const EmailIntegration = ({ title, officeSuite }: EmailIntegrationProps) => {
  const { values } = useLapeContext<CandidateSettingsInterface>()
  useShowOfficeSuitePermissionsError()
  const isEmailIntegrationEnabled = !!values.enable_candidate_no_reply
  const { statusText, statusIcon, color } = mapAccountStatusToProps(
    isEmailIntegrationEnabled ? Statuses.linked : Statuses.pending,
  )
  const onClickSyncEmails = async () => {
    if (isEmailIntegrationEnabled) {
      await candidateSettings.update(
        {
          enable_candidate_no_reply: false,
        },
        // we use id=1 for settings forms
        { id: '1' },
      )
      values.enable_candidate_no_reply = false
    } else if (officeSuite === 'google') {
      await askGoogleEmailPermissions('candidate_settings')
    } else if (officeSuite === 'microsoft') {
      await askMicrosoftEmailPermissions('candidate_settings')
    }
  }
  return (
    <VStack gap="s-16">
      {!isEmailIntegrationEnabled && (
        <ActionWidget
          title="Why do I need to sync mail?"
          text={`To allow sending emails from ${PRODUCT_NAME} and sync replies from candidates to ${PRODUCT_NAME}, please enable this permission`}
          avatarColor={Token.color.grey20}
        />
      )}
      <Item use="label" onClick={onClickSyncEmails}>
        <Item.Avatar>
          <Avatar
            useIcon={
              officeSuite === 'google'
                ? 'LogoGoogle|image'
                : officeSuite === 'microsoft'
                ? 'LogoMicrosoft|image'
                : undefined
            }
          >
            <Avatar.Badge useIcon={statusIcon} color="background" bg={color} />
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description color={color}>{statusText}</Item.Description>
        </Item.Content>
        <Item.Side>
          <Switch checked={isEmailIntegrationEnabled} />
        </Item.Side>
      </Item>
    </VStack>
  )
}

export const CandidatesGeneral = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const {
    settings: { applicants_tracking_enabled, office_suite_provider: officeSuiteProvider },
  } = useGlobalSettings()
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCandidatePreferences)
  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" subtitle="Basic settings for Candidates app" />
          <Widget>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable ATS functionality',
                description: (
                  <>
                    Enable or disable the complete Applicant Tracking System (ATS)
                    functionality. When turned on, the system enables candidates
                    processing, along with the utilisation of 'Candidates', 'Pipelines',
                    and 'Hiring Process' apps. When turned off, the system stops all
                    applicant tracking activities.
                  </>
                ),
              }}
              name="enable_ats"
              disabled={disableEdit}
            />
          </Widget>
          <NewStepperTitle
            title="Default scheduling email template"
            subtitle="Select a default scheduling email template to be sent alongside the invitation to an interview. You can always edit an individual email while sending an interview invite."
          />
          <SchedulingEmailTemplate />
          <NewStepperTitle
            title="Emails"
            subtitle="All settings related to candidates email communication"
          />
          {officeSuiteProvider ? (
            <VStack gap="s-16" mb="s-16">
              <EmailIntegration
                title={
                  officeSuiteProvider === 'google'
                    ? 'Gmail'
                    : officeSuiteProvider === 'microsoft'
                    ? 'Outlook'
                    : undefined
                }
                officeSuite={officeSuiteProvider}
              />
            </VStack>
          ) : null}
          <Widget>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable candidates emails',
                description: (
                  <>
                    Enable or disable the email communication feature. When activated, it
                    allows recruiters to send messages to candidates directly through the
                    platform, schedule them for later release, and to track related email
                    threads. <br /> Email integration is a prerequisite for enabling this
                    setting.
                  </>
                ),
              }}
              name="enable_emails"
              disabled={!applicants_tracking_enabled || disableEdit}
            />
            <VStack p="s-16" space="s-16">
              <LapeNewInput
                label="Recruitment team email"
                name="no_reply_email"
                required
                disabled
              />
              <LapeNewInput
                label="Recruitment team email displayed name"
                name="no_reply_name"
                required
                disabled={!applicants_tracking_enabled || disableEdit}
              />
            </VStack>
          </Widget>
          <NewStepperTitle
            title="Scheduling"
            subtitle="All settings related to scheduling interviews and calls with candidates"
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable calls scheduling',
                description:
                  'Enable or disable the ability to schedule meetings with candidates through the platform. If activated, recruiters can book interviews using various scheduling methods, track their status, and adjust details such as time, duration, and the interviewers invited.',
              }}
              name="enable_scheduling"
              disabled={!applicants_tracking_enabled || disableEdit}
            />
          </Group>
          <NewStepperTitle
            title="Freezing period for unsuccessful candidates"
            subtitle="The duration after which a candidate, previously unsuccessful in an attempt, can apply again for the same specialisation. A new interview round will be initiated for any fresh attempt once the cooling-off period has elapsed."
          />
          <Widget p="s-16">
            <LapeNewInput
              type="number"
              label="Enter number of months"
              name="freezing_period_months"
              required
              disabled={disableEdit}
            />
          </Widget>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.CANDIDATE_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Candidates settings',
    path: ROUTES.SETTINGS.CANDIDATES.GENERAL,
    url: ROUTES.SETTINGS.CANDIDATES.GENERAL,
    canView: [
      PermissionTypes.ViewCandidatePreferences,
      PermissionTypes.ChangeCandidatePreferences,
    ],
    component: CandidatesGeneral,
  },
]

export const General = () => {
  return <SettingsForm routes={routes} api={candidateSettings} />
}
