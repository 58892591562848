import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Group, Tag, Token, VStack } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsSourceOption,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { InternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { InternalSourcesSidebar } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Internal/Sidebar'
import {
  getMetricLabel,
  getMetricSourceAvatar,
  getMetricSourceLabel,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { SourcesWidget } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/common/SourcesWidget'
import { SourceItem } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/common/SourceItem'
import { buildPathToMetricEnabledField } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/utils'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import { metricSourceModel } from '@src/features/AiInsights/Performance/models/metricSourceModel'

interface Props {
  isSidebarOpen: boolean
  onSidebarOpen: VoidFunction
  onSidebarClose: VoidFunction
  actions: React.ReactNode
}

export const InternalSources = ({
  isSidebarOpen,
  onSidebarOpen,
  onSidebarClose,
  actions,
}: Props) => {
  const { values } = useLapeContext<PerformanceInsightsGroupInterface>()
  const internalSources = values.sources.filter(metricSourceModel.isInternal)

  const { data: sourceOptions = [], isLoading } = useGetSelectors<
    PerformanceInsightsSourceOption<InternalMetricSources>
  >(selectorKeys.performance_insights_internal_data_sources)

  return (
    <Box data-testid="performance_ai_insights_group_internal_sources_widget">
      <SourcesWidget
        isLoading={isLoading}
        sources={internalSources}
        title={
          <FormattedMessage
            id="forms.performance.insights.group.sources.widget.internal.title"
            defaultMessage="Internal sources"
          />
        }
        description={
          <FormattedMessage
            id="forms.performance.insights.group.sources.widget.internal.description"
            defaultMessage="What are the internal metrics you want to include in the insights summary?"
          />
        }
        onManageClick={onSidebarOpen}
      >
        <VStack space="s-16">
          {internalSources.map(source => (
            <SourceItem
              id={source.name}
              key={source.name}
              title={getMetricSourceLabel(source.name)}
              avatar={getMetricSourceAvatar(source.name)}
              side={
                <Tag variant="outlined" color={Token.color.deepGrey}>
                  <FormattedMessage
                    id="performance.insights.group.internal.source.item.tag"
                    defaultMessage="Internal"
                  />
                </Tag>
              }
              expandedContent={
                source.metrics.length > 0 && (
                  <Group>
                    {source.metrics.map(({ name }) => {
                      const fieldPath = buildPathToMetricEnabledField(
                        values,
                        source.name,
                        name,
                      )

                      return fieldPath ? (
                        <LapeSingleCheckbox
                          key={name}
                          name={fieldPath}
                          label={getMetricLabel(name)}
                          cellProps={{ variant: 'compact' }}
                        />
                      ) : null
                    })}
                  </Group>
                )
              }
            />
          ))}
        </VStack>
      </SourcesWidget>
      <InternalSourcesSidebar
        sourceOptions={sourceOptions}
        isOpen={isSidebarOpen}
        actions={actions}
        onClose={onSidebarClose}
      />
    </Box>
  )
}
