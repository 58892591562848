import React, { ChangeEvent } from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsSettingsInterface } from '@src/interfaces/settings'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { useGetDocuSignPreferences } from '@src/api/integrations'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getSettingsLinkConfig } from '../common/config'
import { documentsSettingsLinkConfig } from './config'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const Documents = () => {
  const { initialValues, values } = useLapeContext<DocumentsSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { data: docuSignSettings } = useGetDocuSignPreferences()
  const featureFlags = useSelector(selectFeatureFlags)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeDocumentsPreferences)

  const config = getSettingsLinkConfig({
    config: documentsSettingsLinkConfig,
    permissions,
    featureFlags,
    globalSettings: {
      [GlobalSettings.DocumentsTemplatesEnabled]:
        !!initialValues.enable_document_generation_from_templates,
    },
    type: 'settings',
  })

  const handleDocusignSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    values.enable_docusign_integration = isChecked

    if (!isChecked) {
      values.enable_document_generation_from_templates = false
    }
  }

  return (
    <>
      <PageBody mb="s-64">
        <Group mb="s-24">
          {config.documentCollections?.component}
          {config.documentCategories?.component}
          {config.documentTemplates?.component}
        </Group>

        <AutoStepper>
          <NewStepperTitle
            noAutoStep
            title="Integrations"
            subtitle="Settings that apply for all documents functionalities"
          />

          <Group>
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable document rules',
                  description:
                    'Allows generating multiple documents based on pre-configured rules.',
                }}
                name="enable_document_generation_from_templates"
                disabled={disableEdit}
              />
            </HideIfCommercial>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Docusign integration',
                description: (
                  <>
                    Allows generated documents to be signed with Docusign. This works only
                    when the DocuSign integration is configured{' '}
                    <InternalUIKitLink
                      // @ts-expect-error object works fine here, but UI kit expects string
                      to={pathToUrl(ROUTES.FEATURES.INTEGRATION.DOCUSIGN)}
                    >
                      here
                    </InternalUIKitLink>
                    .
                  </>
                ),
              }}
              name="enable_docusign_integration"
              onChange={handleDocusignSwitch}
              disabled={disableEdit || !docuSignSettings?.enabled}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable document request feature',
                description:
                  'Allows HR managers to request certain documents from the employees',
              }}
              name="enable_document_requests"
              disabled={disableEdit}
            />
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.DOCUMENT_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
