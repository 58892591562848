import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { EmployeePageHeader } from '@src/pages/EmployeeProfile/Layout/common/EmployeePageHeader'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { Bonuses } from '@src/pages/EmployeeProfile/Preview/Rewards/Bonuses'
import { Salary } from '@src/pages/EmployeeProfile/Preview/Rewards/Salary'
import { Equity } from './Equity'
import { Box } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { TabBarSimple } from '@src/features/TabBarNavigation/TabBarSimple'

type Props = {
  data: EmployeeInterface
  compensationParams: CompensationParams
}
export const CompensationV2Pages = ({ compensationParams, ...props }: Props) => {
  const pages = [
    {
      title: 'Salary',
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.SALARY, { id: props.data.id }),
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.SALARY,
      component: Salary,
    },
    {
      title: 'Cash Bonus',
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.BONUSES, { id: props.data.id }),
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.BONUSES,
      component: Bonuses,
    },
    {
      title: 'Equity',
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.EQUITY, { id: props.data.id }),
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.EQUITY,
      component: Equity,
    },
  ]

  return (
    <>
      <EmployeePageHeader data={props.data} title="Compensation summary" />
      <TabBarSimple tabs={pages} />
      <Box mt="s-12">
        <Switch>
          {pages.map(page => {
            return (
              <Route exact path={page.path} key={page.url}>
                <page.component
                  {...props}
                  bonusType="cash"
                  compensationParams={compensationParams}
                  currency={compensationParams?.currency}
                  hideBonusesStats
                  setCurrency={compensationParams?.setCurrency}
                />
              </Route>
            )
          })}
        </Switch>
      </Box>
    </>
  )
}
