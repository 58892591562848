import React from 'react'
import {
  ActionButton,
  Avatar,
  DetailsCell,
  Group,
  Item,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useParams } from 'react-router-dom'
import { InterviewRoundState } from '@src/interfaces/interviewTool'
import { toIdAndName } from '@src/utils/toIdAndName'

const CandidatesPipelineOverview = () => {
  const params = useParams()
  const { values } = useLapeContext<JobPostingInterface>()
  const { hiringPipelineId, stats, loading } = useCandidatePipelineData(values.id, [
    {
      filters: [toIdAndName(InterviewRoundState.active)],
      columnName: 'active_interview_round__state',
    },
    {
      filters: [toIdAndName('False')],
      columnName: 'is_snoozed',
    },
  ])
  if (loading) {
    return (
      <Group>
        <ItemSkeleton />
      </Group>
    )
  }
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="DataLimit" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Candidates</Item.Title>
        </Item.Content>
        {hiringPipelineId && (
          <Item.Side>
            <ActionButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.JOB_POSTING.CANDIDATES, params)}
            >
              View
            </ActionButton>
          </Item.Side>
        )}
      </Item>
      {stats?.map(({ label, count }) => (
        <DetailsCell key={label} variant={label === 'Total' ? 'header' : undefined}>
          <DetailsCell.Title>{label}</DetailsCell.Title>
          <DetailsCell.Content aria-label={`Count for ${label}`}>
            {count}
          </DetailsCell.Content>
        </DetailsCell>
      ))}
    </Group>
  )
}

export default CandidatesPipelineOverview
