import React, { useState } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import {
  DataPoint,
  DataPointSkeleton,
  Highlights,
  MoreBar,
  Token,
  useToggle,
} from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import {
  getProbationTemplates,
  useGetProbationTemplatesStats,
} from '@src/api/probationTemplate'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import { PageBody } from '@src/components/Page/PageBody'
import { CreateProbationTemplateSidebar } from './CreateProbationTemplateSidebar'
import { toIdAndName } from '@src/utils/toIdAndName'
import {
  probationTemplateCountryWithNameColumn,
  probationTemplateDurationColumn,
  probationTemplateEmployeesColumn,
  probationTemplateEntityNameColumn,
  probationTemplateNoticePeriodColumn,
  probationTemplatePolicyColumn,
  probationTemplateStatusColumn,
  probationTemplatesNumberOfCheckpointsColumn,
  probationTemplateGoalSettingColumn,
  probationTemplateFinalDecisionColumn,
} from '@src/features/Probation/Templates/columns'
import { EditProbationTemplateSidebar } from './EditProbationTemplateSidebar'

const row: RowInterface<ProbationTemplateInterface> = {
  cells: [
    {
      ...probationTemplateCountryWithNameColumn,
      width: 200,
    },
    { ...probationTemplateEmployeesColumn, width: 150 },
    { ...probationTemplateEntityNameColumn, width: 120 },
    { ...probationTemplateDurationColumn, width: 120 },
    { ...probationTemplateGoalSettingColumn, width: 90 },
    { ...probationTemplateFinalDecisionColumn, width: 90 },
    { ...probationTemplatesNumberOfCheckpointsColumn, width: 70 },
    { ...probationTemplateNoticePeriodColumn, width: 120 },
    { ...probationTemplateStatusColumn, width: 80 },
    { ...probationTemplatePolicyColumn, width: 70 },
  ],
}

export const ProbationTemplatesTable = () => {
  const [isCreateTemplateSidebarOpen, toggleCreateTemplateSidebar] = useToggle()
  const { data: stats, isLoading: isStatsLoading } = useGetProbationTemplatesStats()
  const table = useTable<ProbationTemplateInterface>({ getItems: getProbationTemplates })
  const [editingTemplateId, setEditingTemplateId] = useState<number>()

  const applyFilter = (val: React.Key) => {
    if (val === 'total') {
      table.resetFiltersAndSorting()
    }
    if (val === 'enabled') {
      table.onFilterChange({
        columnName: 'status',
        filters: [toIdAndName('enabled')],
      })
    }
    if (val === 'disabled') {
      table.onFilterChange({
        columnName: 'status',
        filters: [toIdAndName('disabled')],
      })
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Probation timeline templates"
        backUrl={ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_TEMPLATES}
      />
      <PageBody maxWidthMd={Token.breakpoint.max}>
        <Table.Widget>
          <Table.Widget.Info>
            <Highlights variant="tabs" defaultValue="total" onChange={applyFilter}>
              {isStatsLoading ? (
                <>
                  <DataPointSkeleton aria-selected />
                  <DataPointSkeleton />
                  <DataPointSkeleton />
                </>
              ) : (
                <>
                  <DataPoint to="total">
                    <DataPoint.Value>{stats?.total}</DataPoint.Value>
                    <DataPoint.Label>Total</DataPoint.Label>
                  </DataPoint>
                  <DataPoint to="enabled">
                    <DataPoint.Value color={Token.color.success}>
                      {stats?.enabled}
                    </DataPoint.Value>
                    <DataPoint.Label>Enabled</DataPoint.Label>
                  </DataPoint>
                  <DataPoint to="disabled">
                    <DataPoint.Value color={Token.color.greyTone50}>
                      {stats?.disabled}
                    </DataPoint.Value>
                    <DataPoint.Label>Disabled</DataPoint.Label>
                  </DataPoint>
                </>
              )}
            </Highlights>
          </Table.Widget.Info>
          <Table.Widget.Filters>
            <Table.Search
              placeholder="Search by name, country or entity name"
              onFilter={table.onFilterChange}
              variant="compact"
            />
          </Table.Widget.Filters>
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <MoreBar.Action
                onClick={() => {
                  setEditingTemplateId(undefined)
                  toggleCreateTemplateSidebar.on()
                }}
                useIcon="Plus"
              >
                Add new template
              </MoreBar.Action>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable<ProbationTemplateInterface>
              name={TableNames.ProbationTemplates}
              useWindowScroll
              onRowClick={({ id }) => setEditingTemplateId(id)}
              row={row}
              {...table}
              emptyState={<EmptyTableRaw title="Probation templates will appear here." />}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
      {isCreateTemplateSidebarOpen && (
        <CreateProbationTemplateSidebar
          onClose={toggleCreateTemplateSidebar.off}
          onAfterSubmit={() => {
            table.refresh()
            toggleCreateTemplateSidebar.off()
          }}
        />
      )}
      {editingTemplateId && (
        <EditProbationTemplateSidebar
          templateId={editingTemplateId}
          onClose={() => {
            setEditingTemplateId(undefined)
          }}
          onAfterSubmit={() => {
            table.refresh()
            setEditingTemplateId(undefined)
          }}
        />
      )}
    </PageWrapper>
  )
}
