import React, { useState } from 'react'
import { BottomSheet, Button, Header } from '@revolut/ui-kit'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

interface Props {
  open: boolean
  isLoading: boolean
  onClose: VoidFunction
  onConfirm: (hr: EmployeeOptionInterface) => void
  hrSelector: selectorKeys.pip_hr_managers | selectorKeys.probation_hr_managers
}

export const HRManagerBulkAssign = ({
  open,
  hrSelector,
  isLoading,
  onClose,
  onConfirm,
}: Props) => {
  const [hr, setHR] = useState<EmployeeOptionInterface | undefined>()
  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Assign HR manager</Header.Title>
        <Header.Description>
          Bulk assign a HR manager to all selected employees
        </Header.Description>
      </Header>

      <RadioSelectInput<EmployeeOptionInterface>
        label="HR manager"
        selector={hrSelector}
        onChange={hr_manager => setHR(hr_manager ?? undefined)}
        value={hr}
      />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          elevated
          disabled={!hr}
          onClick={() => hr && onConfirm(hr)}
          pending={isLoading}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
