import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import { JiraEpicInterface } from '@src/interfaces/roadmaps'
import { TableNames } from '@src/constants/table'
import { TableTypes } from '@src/interfaces/table'
import { useTableReturnType } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import {
  epicContributionColumn,
  epicNameColumn,
  epicPriorityColumn,
  epicTicketsClosedColumn,
} from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/EpicsContributionsTable/columns'

const row: RowInterface<JiraEpicInterface> = {
  cells: [
    {
      ...epicNameColumn,
      width: 150,
    },
    {
      ...epicPriorityColumn,
      width: 80,
    },
    {
      ...epicContributionColumn,
      width: 120,
    },
    { ...epicTicketsClosedColumn, width: 100 },
  ],
}

export const EpicsContributionsTable = (
  table: useTableReturnType<JiraEpicInterface, undefined>,
) => {
  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.JiraEpicsContributions}
          type={TableTypes.Contained}
          dataType="Epic"
          {...table}
          count={table.data.length}
          row={row}
          emptyState={<EmptyTableRaw title="No Contributions found" imageSize={72} />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
