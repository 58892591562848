import { useParams } from 'react-router-dom'
import { decodeToken } from '@src/utils/decodeToken'
import { useFetchV2 } from '@src/utils/reactQuery'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { apiV2 } from '@src/api'
import {
  InterviewConsents,
  InterviewConsentsToken,
} from '@src/features/CandidateConsentToInterviewAISummarisation/interfaces'

const URL = '/interviewConsents'

export const useGetCandidateConsent = () => {
  const { token } = useParams<InterviewConsentsToken>()
  return useFetchV2<InterviewConsents>({
    url: `${URL}/${token}`,
    params: {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    isExternal: true,
  })
}

export const useUpdateCandidateConsent = () => {
  const { token } = useParams<InterviewConsentsToken>()
  return useMutation<AxiosResponse, AxiosError, Partial<InterviewConsents>>(
    ({ consent_to_interview_ai_summarisation }) =>
      apiV2.patch<InterviewConsents>(
        `${URL}/${token}`,
        {
          consent_to_interview_ai_summarisation,
        },
        {
          headers: {
            apitoken: decodeToken(token),
          },
        },
        undefined,
        true,
      ),
  )
}
