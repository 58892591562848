import React, { MouseEvent } from 'react'
import { Color, Flex, Icon, Link } from '@revolut/ui-kit'

export const extractHostname = (url: string) => {
  try {
    return new URL(url).hostname
  } catch (e) {
    return url
  }
}

type Props = {
  label?: string
  link: string
  noIcon?: boolean
  onClick?: (event: MouseEvent) => void
  color?: Color
  iconPosition?: 'start' | 'end'
}

const OpenInNewTab = ({
  label = 'Open in new tab',
  link,
  noIcon,
  onClick,
  color,
  iconPosition = 'start',
}: Props) => {
  const icon = <Icon name="LinkExternal" size={14} />
  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      color={color}
    >
      <Flex alignItems="center">
        {!noIcon && iconPosition === 'start' && <>{icon}&nbsp;</>}
        {label}
        {!noIcon && iconPosition === 'end' && <>&nbsp;{icon}</>}
      </Flex>
    </Link>
  )
}

export default OpenInNewTab
