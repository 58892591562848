import React from 'react'
import { useGetInterviewApplication } from '@src/api/applicationCandidate'
import {
  VStack,
  ItemSkeleton,
  Text,
  Widget,
  Box,
  Subheader,
  HStack,
  Avatar,
} from '@revolut/ui-kit'
import CandidateApplicationFormSection from '@src/features/CandidateFormPreview/CandidateApplicationFormSection'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { EducationUniversitiesInfo } from '@src/features/InterviewTool/EducationInfo'

interface CVScreeningApplicationFormProps {
  applicationId?: number
}

export const ApplicationForm = ({ applicationId }: CVScreeningApplicationFormProps) => {
  const { data, isLoading } = useGetInterviewApplication(applicationId)

  if (isLoading) {
    return (
      <VStack space="s-16">
        <ItemSkeleton />
      </VStack>
    )
  }

  return (
    <Widget px="s-16" pb="s-16">
      <Subheader variant="default">
        <Subheader.Title>
          <HStack space="s-16" align="center">
            <Avatar useIcon="Questionnaire" />
            <Text>Application Form</Text>
          </HStack>
        </Subheader.Title>
      </Subheader>
      <FormPreview data={data}>
        <FormPreview.Item
          title="Application date"
          field="created_date_time"
          type="date"
        />
      </FormPreview>

      {data?.educations && data?.educations.length > 0 ? (
        <Box pb="s-16">
          <Subheader variant="default">
            <Subheader.Title>Education</Subheader.Title>
          </Subheader>
          <VStack gap="s-4" pl="s-16">
            <EducationUniversitiesInfo data={data.educations} />
          </VStack>
        </Box>
      ) : null}

      {data?.application_form_data?.sections?.map(section => (
        <CandidateApplicationFormSection
          key={section.internal_data_id}
          section={section}
        />
      ))}
    </Widget>
  )
}
