import { PermissionTypes } from '@src/store/auth/types'
import Bitbucket from '@src/assets/temporary/Bitbucket.png'

export const BitbucketConfig = {
  name: 'Bitbucket',
  description: 'Your Bitbucket account',
  placeholderDescription: 'Provide access to you Bitbucket account in 3 easy steps',
  requiredIntegrationPermission: PermissionTypes.ViewBitbucketIntegration,
  image: {
    default: Bitbucket, // TODO change to bitbucket https://revolut.atlassian.net/browse/MPR-7862
  },
}
