import React, { useMemo } from 'react'
import { Icon } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { useIsEngagementLayoutV2 } from '@src/features/Engagement/hooks/permissions'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { SurveyOverview } from '../components/SurveyOverview'
import { EngagementResultsWidget } from '../components/SurveyResults/ResultsWidget'
import { ResultAnswersTable } from '../components/SurveyResults/ResultsWidget/AnswersTable'
import { getAnalysedItemsBaseRoute } from '../components/SurveyResults/ResultsWidget/common'

const tabsConfigV1 = [
  {
    title: 'Overview',
    icon: <Icon name="Profile" size={16} />,
    path: [
      ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
      ROUTES.APPS.ENGAGEMENT.SURVEY_OVERVIEW,
    ],
    url: ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
    component: SurveyOverview,
  },
  {
    title: 'Results',
    icon: <Icon name="Questionnaire" size={16} />,
    path: [
      ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ANY,
      ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.ANY,
    ],
    url: ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES,
    component: EngagementResultsWidget,
  },
]

const getTabsConfigV2 = ({
  isScopedView,
  canViewComments,
}: { isScopedView?: boolean; canViewComments?: boolean } | undefined = {}) => {
  const overviewUrl = isScopedView
    ? getAnalysedItemsBaseRoute('CATEGORIES') || ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD
    : ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW
  const answersUrl = isScopedView
    ? getAnalysedItemsBaseRoute('COMMENTS') || ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD
    : ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_COMMENTS

  return [
    {
      title: 'Overview',
      path: [overviewUrl],
      url: overviewUrl,
      component: SurveyOverview,
    },
    canViewComments
      ? {
          title: 'Comments',
          path: [answersUrl],
          url: answersUrl,
          component: ResultAnswersTable,
        }
      : null,
  ].filter(Boolean)
}

export const useSubtabs = ({
  id,
  isScopedView,
  entityPermissions,
  checkEntityPermissions,
}: {
  id: number | undefined
  isScopedView?: boolean
  entityPermissions?: string[]
  checkEntityPermissions?: boolean
}) => {
  const isLayoutV2 = useIsEngagementLayoutV2({ isOrganisationCompanySubtab: false })
  const canViewComments =
    isScopedView && entityPermissions && checkEntityPermissions
      ? Boolean(entityPermissions?.includes(PermissionTypes.ViewEngagementAnswer))
      : true

  const subtabs = useMemo(() => {
    return (
      isLayoutV2 ? getTabsConfigV2({ isScopedView, canViewComments }) : tabsConfigV1
    ).map(tab => ({ ...tab, url: pathToUrl(tab.url, { id }) }))
  }, [id])

  return id !== undefined ? subtabs : []
}
