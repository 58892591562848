import React, { useMemo } from 'react'
import {
  HStack,
  Subheader,
  TransitionCollapse,
  VStack,
  Widget,
  Text,
  useToggle,
  Icon,
  Box,
  Token,
} from '@revolut/ui-kit'
import {
  GradeLabelMappingInterface,
  ManagerRecommendationInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import { RowHeight } from '@src/interfaces/table'
import { LocalStorageKeys } from '@src/store/auth/types'
import { fmColumn, lmColumn, titleColumn } from './columns'
import { ProbationAndPipBarRaiserSection } from './types'
import { parseSectionDataByCheckpointId } from './adaptors/parseSectionDataByCheckpointId'
import {
  BarRaiserSummaryJustification,
  JustificationInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/BarRaiserSummaryJustification'

interface Props {
  summary: ReviewSummaryDataInterface
  gradeLabelMapping: GradeLabelMappingInterface
}

const HEADERS_OFFSET = 42

const getJustificationsForCheckpoint = ({
  checkpointNumber,
  data,
}: {
  checkpointNumber?: number
  data: ManagerRecommendationInterface[]
}): JustificationInterface[] => {
  const filtered = data.filter(
    recommendationForCheckpoint =>
      recommendationForCheckpoint.checkpoint_number === checkpointNumber,
  )
  const justifications: JustificationInterface[] = []
  filtered.forEach(recommendationForCheckpoint => {
    if (
      recommendationForCheckpoint.employee_project_performance.justification &&
      recommendationForCheckpoint.relation &&
      recommendationForCheckpoint.reviewer
    ) {
      justifications.push({
        value: recommendationForCheckpoint.employee_project_performance.justification,
        review: {
          reviewer: {
            ...recommendationForCheckpoint.reviewer,
            display_name: recommendationForCheckpoint.reviewer.display_name || '',
            full_name: recommendationForCheckpoint.reviewer.full_name || '',
          },
          reviewer_relation: recommendationForCheckpoint.relation,
        },
      })
    }
  })

  return justifications
}

export const PipAndProbationBarRaiserTables = ({ summary, gradeLabelMapping }: Props) => {
  const groupedCheckpoints = parseSectionDataByCheckpointId(
    summary.recommendation_extra_sections || [],
  )

  return (
    <VStack gap="s-16">
      {groupedCheckpoints.map((checkpoint, i) => (
        <CheckpointTable
          key={i}
          gradeLabelMapping={gradeLabelMapping}
          section={checkpoint}
          justifications={getJustificationsForCheckpoint({
            checkpointNumber: checkpoint.at(0)?.checkpointNumber,
            data: summary.recommendation_extra_sections || [],
          })}
        />
      ))}
    </VStack>
  )
}

const CheckpointTable = ({
  section,
  justifications,
  gradeLabelMapping,
}: {
  section: ProbationAndPipBarRaiserSection[]
  justifications: JustificationInterface[]
  gradeLabelMapping: GradeLabelMappingInterface
}) => {
  const [expanded, toggleExpanded] = useToggle()

  const row = useMemo(
    () => ({
      cells: [
        { ...titleColumn, width: 300 },
        { ...lmColumn(gradeLabelMapping), width: 100 },
        { ...fmColumn(gradeLabelMapping), width: 100 },
      ],
    }),
    [gradeLabelMapping],
  )

  const numOfItems = section.length || 0

  const rowHeight: RowHeight = useMemo(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)

    if (localStorageRowHeight === 'large') {
      return 'large'
    }
    return 'medium'
  }, [])

  return (
    <Widget bg={Token.color.grey50_10}>
      <Subheader variant="default" onClick={toggleExpanded.switch}>
        <Subheader.Title>
          <HStack align="center" space="s-8" pl="s-16" style={{ cursor: 'pointer' }}>
            <Icon
              name={expanded ? 'ChevronDown' : 'ChevronRight'}
              color={Token.color.greyTone50}
            />
            <Text variant="emphasis1">Checkpoint {section.at(0)?.checkpointNumber}</Text>
          </HStack>
        </Subheader.Title>
      </Subheader>
      <TransitionCollapse in={expanded}>
        <Box
          minHeight={
            numOfItems * rowWrapperMinHeight[rowHeight] +
            (!numOfItems ? 0 : HEADERS_OFFSET)
          }
        >
          <AdjustableTable
            row={row}
            hideCount
            mainColumnIndex={-1} // hide the count on first column
            data={section}
            name={TableNames.BarRaiserQuestionsSummary}
            count={section.length || 0}
          />
        </Box>
        <BarRaiserSummaryJustification justifications={justifications} />
      </TransitionCollapse>
    </Widget>
  )
}
