import React, { useEffect, useState } from 'react'
import { endOfDay, startOfDay } from 'date-fns'
import format from 'date-fns/format'
import get from 'lodash/get'
import {
  ActionButton,
  Calendar,
  Dropdown,
  DropdownProps,
  Header,
  Popup,
} from '@revolut/ui-kit'
import { RangeValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'

import NewGrid from '@components/CommonSC/NewGrid'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { DatePickerLocaleProvider } from '../../DatePickerInput/DatePickerLocaleProvider'

export interface FilterDateRangeProps<T extends FilterOption = FilterOption>
  extends Pick<DropdownProps, 'anchorRef' | 'fitInAnchor' | 'positionStrategy'> {
  open: boolean
  onClose: () => void
  onChange: (options: T[]) => void
  value?: T[]
  type?: FilterType
  showInPopup?: boolean
}

const convertFilterToRange = (filters: FilterOption[]): RangeValue => {
  const from = get(filters, '[0].id')
  const to = get(filters, '[1].id')

  if (!from && !to) {
    return null
  }

  return {
    from: from && new Date(from),
    to: to && new Date(to),
  }
}

export const FilterDateRange = <T extends FilterOption = FilterOption>({
  open,
  onChange,
  onClose,
  anchorRef,
  value = [],
  type = FilterType.date,
  fitInAnchor,
  positionStrategy,
  showInPopup,
}: FilterDateRangeProps<T>) => {
  const [dateRange, setDateRange] = useState<RangeValue>()

  useEffect(() => {
    setDateRange(convertFilterToRange(value))
  }, [value[0], value[1]])

  const onCalendarChange = (selectedRange: RangeValue) => {
    setDateRange(selectedRange)
  }

  const onClear = () => {
    onChange([])
    onClose()
  }

  const onApply = () => {
    const filters: T[] = []
    const { from, to } = dateRange || { to: null, from: null }

    if (!from && !to) {
      onChange([])
      return
    }

    if (from) {
      const formattedFrom = startOfDay(from)
      const filterFrom =
        type === FilterType.date
          ? formattedFrom.toISOString()
          : format(formattedFrom, 'yyyy-MM-dd')

      filters.push({
        id: filterFrom,
        name: `${filterFrom}`,
      } as T)
    } else {
      filters.push({ id: '', name: '' } as T)
    }

    if (to) {
      const formattedTo = endOfDay(to)
      const filterTo =
        type === FilterType.date
          ? formattedTo.toISOString()
          : format(formattedTo, 'yyyy-MM-dd')

      filters.push({
        id: filterTo,
        name: `${filterTo}`,
      } as T)
    } else {
      filters.push({ id: '', name: '' } as T)
    }

    onChange(filters)
  }

  const calendarProps = {
    variant: 'range' as const,
    labelButtonClear: 'Clear',
    labelEmptyValue: 'Select range',
    labelPrev: 'Previous',
    labelNext: 'Next',
    value: dateRange,
    onChange: onCalendarChange,
  }

  const actions = (
    <>
      <ActionButton width="100%" onClick={onClear}>
        Clear
      </ActionButton>
      <ActionButton variant="accent" width="100%" onClick={onApply}>
        Apply
      </ActionButton>
    </>
  )

  if (showInPopup) {
    return (
      <Popup open={open} onClose={onClose} variant="bottom-sheet">
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Select range</Header.Title>
        </Header>
        <DatePickerLocaleProvider>
          <Calendar {...calendarProps} />
        </DatePickerLocaleProvider>
        <Popup.Actions horizontal>{actions}</Popup.Actions>
      </Popup>
    )
  }
  return (
    <Dropdown
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      width={fitInAnchor ? undefined : 360}
      maxHeight={null}
      fitInAnchor={fitInAnchor}
      positionStrategy={positionStrategy}
    >
      <Dropdown.Group sticky="top">
        <DatePickerLocaleProvider>
          <Calendar transparentBg {...calendarProps} />
        </DatePickerLocaleProvider>
      </Dropdown.Group>
      <Dropdown.Group sticky="bottom" p="s-16">
        <NewGrid gridAutoFlow="column" gridColumnGap="1rem">
          {actions}
        </NewGrid>
      </Dropdown.Group>
    </Dropdown>
  )
}
