import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { specificationChangelogTableRequests } from '@src/api/changelog'
import {
  changelogChangedBy,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { selectorKeys } from '@src/constants/api'
import { TableTypes } from '@src/interfaces/table'

const fieldsChangelogRequisitionRow = (specialisationEnabled: boolean) => ({
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields(specialisationEnabled),
      selectorsKey: selectorKeys.specialisation_changelog_fields,
      width: 210,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
})

const initialSort = [{ sortBy: 'updated_date_time', direction: SORT_DIRECTION.ASC }]

export const ChangelogTable = () => {
  const specialisationEnabled = useIsSpecialisationsEnabled()
  const { values } = useLapeContext<SpecialisationInterface>()

  const rows = useMemo(
    () => fieldsChangelogRequisitionRow(specialisationEnabled),
    [specialisationEnabled],
  )

  const initialFilter = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: values.id, name: values.name }],
    },
  ]

  const table = useTable(
    specificationChangelogTableRequests(values.id),
    initialFilter,
    initialSort,
  )

  const handleRowClick = (change: ChangelogInterface<SpecialisationInterface>) => {
    navigateReplace(
      `${pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id: values.id })}?change=${
        change.id
      }`,
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<ChangelogInterface<SpecialisationInterface>>
          name={TableNames.SpecialisationsChangelog}
          emptyState={<EmptyTableRaw title="There are no changes to display" />}
          dataType="Data Change"
          row={rows}
          {...table}
          onRowClick={handleRowClick}
          type={TableTypes.Contained}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
