import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  ExternalMetricSources,
  InternalMetricSources,
} from '@src/features/AiInsights/Performance/interfaces/metrics'

export const getMetricSourceDescription = (
  source: InternalMetricSources | ExternalMetricSources,
): React.ReactNode => metricSourceToDescriptionMap[source]

const metricSourceToDescriptionMap: Record<
  InternalMetricSources | ExternalMetricSources,
  React.ReactNode
> = {
  [InternalMetricSources.Goals]: (
    <FormattedMessage
      id="performance.metrics.internal.source.goals.description"
      defaultMessage="Measure progress and achievement across personal and team objectives."
    />
  ),
  [InternalMetricSources.Interviews]: (
    <FormattedMessage
      id="performance.metrics.internal.source.interviews.description"
      defaultMessage="Track interview contributions and feedback quality."
    />
  ),
  [InternalMetricSources.OneToOnes]: (
    <FormattedMessage
      id="performance.metrics.internal.source.one.to.one.description"
      defaultMessage="Track interview contributions and feedback quality."
    />
  ),
  [ExternalMetricSources.Jira]: (
    <FormattedMessage
      id="performance.metrics.external.source.jira.description"
      defaultMessage="Aggregates and visualises performance data for actionable insights across teams."
    />
  ),
  [ExternalMetricSources.Bitbucket]: (
    <FormattedMessage
      id="performance.metrics.external.source.bitbucket.description"
      defaultMessage="Tracks engineering contributions, including pull requests, code reviews, and bug fixes."
    />
  ),
}
