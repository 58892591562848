import { HStack, Icon, opacity, Text, Token } from '@revolut/ui-kit'
import { HeatMapComparisonMode } from '@src/features/Engagement/components/HeatmapTableCell/index'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { TrendIndicator } from '@src/features/Engagement/components/TrendIndicator'

export interface HeatmapTableCellData {
  anonymized: boolean
  name: string
  average_score: number
  max_value?: number
  min_value?: number
  trend_average_score: number | null
  comparisonScore: number | null
}

export const mapScoreToCellColor = ({
  score,
  min = 0,
  mid = 5,
  max = 10,
}: {
  score?: number
  min?: number
  mid?: number
  max?: number
}) => {
  const neutralColor = Token.color.greyTone2

  if (!score) {
    return neutralColor
  }

  const MAX_RANGE = Math.max(Math.abs(mid - min), Math.abs(max - mid))

  const opacityValue = Math.min(1, Math.abs(score - mid) / MAX_RANGE)

  if (score < mid) {
    return opacity(Token.colorChannel.red, opacityValue)
  }
  if (score > mid) {
    return opacity(Token.colorChannel.green, opacityValue)
  }
  return neutralColor
}

export const getPrimaryValue = ({
  data,
  mode,
}: {
  data: HeatmapTableCellData
  mode: HeatMapComparisonMode
}) => {
  if (mode === 'compared') {
    const diffValue =
      typeof data.comparisonScore === 'number'
        ? data.average_score - data.comparisonScore
        : null
    const value =
      typeof diffValue === 'number'
        ? diffValue > 0
          ? `+${diffValue.toFixed(1)}`
          : diffValue.toFixed(1)
        : 'N/A'

    return (
      <Text variant="heading3" lineHeight={1} color={Token.color.foreground}>
        {value}
      </Text>
    )
  }

  if (mode === 'trend') {
    return (
      <TrendIndicator
        variant="md"
        avgScore={data.average_score}
        trendAvgScore={data.trend_average_score}
        defaultColor={Token.color.foreground}
      />
    )
  }

  return (
    <FormattedMessage
      id="engagement.heatmapTable.averageScore"
      defaultMessage={`<T>${data.average_score}</T> / 10`}
      values={{
        T: chunks => (
          <Text variant="heading3" lineHeight={1} color={Token.color.foreground}>
            {chunks}
          </Text>
        ),
      }}
    />
  )
}

export const getSecondaryValue = ({
  data,
  mode,
}: {
  data: HeatmapTableCellData
  mode: HeatMapComparisonMode
}) => {
  if (mode === 'compared' || mode === 'trend') {
    return (
      <HStack space="s-2" align="center">
        <Icon color={Token.color.greyTone50} size={12} name="LikeDislike" />
        <Text variant="body3" color={Token.color.greyTone50}>
          {data.average_score}
        </Text>
      </HStack>
    )
  }

  return (
    <TrendIndicator
      avgScore={data.average_score}
      trendAvgScore={data.trend_average_score}
    />
  )
}

export const getHeatMapCellColor = ({
  data,
  mode,
}: {
  data: HeatmapTableCellData
  mode: HeatMapComparisonMode
}) => {
  if (mode === 'compared') {
    const diffValue =
      typeof data.comparisonScore === 'number'
        ? data.average_score - data.comparisonScore
        : null
    return diffValue
      ? mapScoreToCellColor({ score: diffValue, min: -3, mid: 0, max: 3 })
      : Token.color.greyTone2
  }

  if (mode === 'trend') {
    return data.trend_average_score
      ? mapScoreToCellColor({
          score: data.average_score - data.trend_average_score,
          min: -2,
          mid: 0,
          max: 2,
        })
      : Token.color.greyTone2
  }

  return mapScoreToCellColor({ score: data.average_score })
}
