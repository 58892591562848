import React from 'react'
import Table from '@src/components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { ProbationInterface } from '@src/interfaces/probation'
import { Token } from '@revolut/ui-kit'
import DateDistance from '@src/components/ColumnInserts/DateDistance/DateDistance'
import { CurrentStageCell } from './CurrentStateCell'

export const pipelineEmployeeNameColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee} size={40} />,
  title: 'Employee name',
}

export const pipelineEmployeeLevelColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Level',
  insert: ({ data }) => <Table.SeniorityCell seniority={data.seniority?.name} />,
}

export const pipelineEmployeeLineManagerColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager.id',
  dataPoint: 'line_manager.full_name',
  sortKey: 'line_manager__full_name',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.active_manager,
  title: 'LM',
  insert: ({ data }) => <Table.EmployeeCell employee={data.line_manager} compact />,
}

export const pipelineEmployeeFunctionManagerColumn: ColumnInterface<ProbationInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'functional_manager.id',
    dataPoint: 'functional_manager.full_name',
    sortKey: 'functional_manager__full_name',
    filterKey: 'functional_manager__id',
    selectorsKey: selectorKeys.manager,
    title: 'FM',
    insert: ({ data }) => (
      <Table.EmployeeCell employee={data.functional_manager} compact />
    ),
  }

export const pipelineProbationStartDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.start_date_time',
  dataPoint: 'cycle.start_date_time',
  sortKey: 'cycle__start_date_time',
  filterKey: 'cycle__start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Probation start date',
}

export const pipelineProbationEndDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.end_date_time',
  dataPoint: 'cycle.end_date_time',
  sortKey: 'cycle__end_date_time',
  filterKey: 'cycle__end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Probation end date',
}

export const pipelinePipStartDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.start_date_time',
  dataPoint: 'cycle.start_date_time',
  sortKey: 'cycle__start_date_time',
  filterKey: 'cycle__start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'PIP start date',
}

export const pipelinePipEndDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.end_date_time',
  dataPoint: 'cycle.end_date_time',
  sortKey: 'cycle__end_date_time',
  filterKey: 'cycle__end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'PIP end date',
}

export const pipelineHrManagerColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'hr_manager.id',
  dataPoint: 'hr_manager.full_name',
  sortKey: 'hr_manager__full_name',
  filterKey: 'hr_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'HR Manager',
  insert: ({ data }) => <Table.EmployeeCell employee={data.hr_manager} compact />,
}

export const pipelineCurrentStageColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'checkpoint_type',
  dataPoint: 'checkpoint_type',
  sortKey: 'checkpoint_type',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => <CurrentStageCell probationData={data} />,
  title: 'Current stage',
}

export const pipelinePendingActionsColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'pending_actions',
  dataPoint: 'pending_actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => data.pending_actions.join(', '),
  title: 'Pending actions',
}

export const pipelineCheckpointDeadlineColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'checkpoint_deadline',
  dataPoint: 'checkpoint_deadline',
  sortKey: 'checkpoint_date_time',
  filterKey: 'checkpoint_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Checkpoint deadline',
  insert: ({ data }) => (
    <DateDistance
      date={data?.checkpoint_deadline}
      color={data.status !== 'pending' ? Token.color.foreground : undefined}
    />
  ),
}
