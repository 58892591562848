export enum TableTypes {
  /** @deprecated */
  Form = 'form',
  /** @deprecated */
  Popup = 'popup',
  /** @deprecated */
  Stepper = 'stepper',
  /** @deprecated */
  NewStepper = 'stepper-new',
  /** @deprecated */
  Matrix = 'matrix',
  Adjustable = 'adjustable',
  Contained = 'contained',
}

export type RowHeight =
  | 'competency-matrix' // custom size for competency matrix
  | 'heatmap' // custom size for engagement heatmap layout case
  | 'large'
  | 'medium'
  | 'small'
