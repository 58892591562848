import React from 'react'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'

import { API } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { pathToUrl } from '@src/utils/router'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useNextRoleStepButtonLink } from '@src/pages/Forms/RoleForm/hooks'
import { HiringProcessPageContent } from './HiringProcessPageContent'

const CURRENT_STEP = 'hiring_process'

const HiringProcess = () => {
  const context = useLapeContext<RoleInterface>()
  const { values } = context
  const nextLink = useNextRoleStepButtonLink(CURRENT_STEP)

  return (
    <>
      <PageBody maxWidth="100%">
        <HiringProcessPageContent />
      </PageBody>
      <PageActions>
        {values.status === Statuses.draft && (
          <RoleSaveDraftButton
            title="role"
            pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
            pathAfterSave={ROUTES.FORMS.ROLE.HIRING_PROCESS}
            isNew
            notification={{
              updateMsg: 'Role draft successfully updated.',
              createMsg: 'Role draft successfully created.',
            }}
          />
        )}
        <ContinueRoleButton
          api={API.ROLES}
          type="role"
          step={CURRENT_STEP}
          to={nextLink}
          disabled={!values?.hiring_process_rounds?.length}
        />
      </PageActions>
    </>
  )
}

export default HiringProcess
