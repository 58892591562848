import React from 'react'
import { abbreviate, Avatar, Box, DualAvatar, Status } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { HeaderActions } from './HeaderActions'
import { formatRRule } from '@src/utils/format'
import { getAvatarUrl } from '@src/utils/employees'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface Props {
  employee: EmployeeInterface | null
  meeting: OneToOneMeeting | null
  cycle: ReviewCyclesInterface | null
  isLoading: boolean
}

export const SummaryHeader = ({ employee, meeting, cycle, isLoading }: Props) => {
  const user = useSelector(selectUser)

  if (!meeting || !employee) {
    return null
  }

  const title = `1:1 ${meeting.manager.name} <> ${meeting.employee.name}`
  const status = formatRRule(meeting.meeting_series?.recurrence)

  return (
    <PageHeader
      isLoading={isLoading}
      title={
        <PageHeader.Title
          avatar={
            <Box p="s-2">
              <DualAvatar
                topImage={
                  meeting.manager.avatar ? (
                    getAvatarUrl(meeting.manager.avatar)
                  ) : (
                    <Avatar
                      uuid={meeting.manager.full_name}
                      label={
                        meeting.manager.full_name
                          ? abbreviate(meeting.manager.full_name)
                          : ''
                      }
                    />
                  )
                }
                bottomImage={
                  meeting.employee.avatar ? (
                    getAvatarUrl(meeting.employee.avatar)
                  ) : (
                    <Avatar
                      uuid={meeting.employee.full_name}
                      label={
                        meeting.employee.full_name
                          ? abbreviate(meeting.employee.full_name)
                          : ''
                      }
                    />
                  )
                }
                size={56}
              />
            </Box>
          }
          labels={<Status useIcon="Calendar">{status}</Status>}
          title={title}
          actions={<HeaderActions employee={employee} meeting={meeting} cycle={cycle} />}
        />
      }
      backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW, {
        id: user.id,
      })}
    />
  )
}
