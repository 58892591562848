import { useEffect, useMemo, useState } from 'react'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { BaseOptionId, CycleOption } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { timelineModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/TimelineModel'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { adaptReviewCycleToCycleOption } from '@src/utils/performance'

const getFilteredAndMappedCycleResults = (cycles: ReviewCyclesInterface[] = []) => {
  return cycles.reduce<{
    options: CycleOption[]
    filteredCycles: ReviewCyclesInterface[]
  }>(
    (acc, cycle) => {
      if (!cycleModel.isTest(cycle)) {
        acc.options.push(adaptReviewCycleToCycleOption(cycle))
        acc.filteredCycles.push(cycle)
      }
      return acc
    },
    { options: [], filteredCycles: [] },
  )
}

export const useReviewCycleData = ({
  activeCycleId,
  employeeId,
  managerId,
}: {
  activeCycleId?: BaseOptionId
  employeeId: number
  managerId: number
}) => {
  const { query, changeQueryParam } = useQuery<{ cycle_id?: string }>()
  const [selectedCycle, setSelectedCycle] = useState<ReviewCyclesInterface>()
  const { data: reviewCycles, isLoading: isLoadingReviewCycles } = useGetReviewCycles({
    employeeIds: [employeeId, managerId],
    sortBy: [{ sortBy: 'start_date_time', direction: SORT_DIRECTION.ASC }],
  })

  const { filteredCycles, options } = useMemo(
    () => getFilteredAndMappedCycleResults(reviewCycles?.results),
    [reviewCycles?.results],
  )

  useEffect(() => {
    if (!isLoadingReviewCycles && reviewCycles?.results) {
      const cycle =
        // select cycle based on passed param id if present
        filteredCycles.find(item => item.id === Number(activeCycleId)) ||
        // else select the first cycle that has start date in past and end date in future
        // technically we cannot identify the "ongoing" cycle precisely
        // because it could be > 1 manual/adhoc/test review cycles "ongoing" at the same time
        filteredCycles.find(timelineModel.isOngoingCycleByDates) ||
        filteredCycles.at(0)
      if (!query.cycle_id && cycle?.id) {
        changeQueryParam('cycle_id', String(cycle.id))
      }
      setSelectedCycle(cycle)
    }
  }, [reviewCycles?.results, isLoadingReviewCycles, activeCycleId])

  const dateRanges = {
    startDate: selectedCycle?.start_date_time,
    endDate: selectedCycle?.end_date_time,
  }
  return {
    selectedCycle,
    reviewCycleOptions: { options },
    isLoading: isLoadingReviewCycles,
    dateRanges,
  }
}
