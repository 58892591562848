import {
  VStack,
  Text,
  Token,
  Separator,
  HStack,
  Grid,
  Color,
  Box,
  Tag,
  Flex,
  ExpandableCell,
} from '@revolut/ui-kit'
import { isStandardPlan, PlanConfiguration } from '@src/pages/Forms/Plans/config'
import React, { PropsWithChildren } from 'react'
import {
  AvailableSubscriptionPlanInterfaceV2,
  SubscriptionPlanInfterface,
} from '@src/interfaces/plans'
import { PlanType } from '@src/pages/Forms/Plans/types'
import { formatMoney } from '@src/utils/format'
import { PlanWidgetFeature } from './PlanWidgetFeature'

interface PlanWidgetProps {
  backgroundColor: Color
  planDescription: string
  features: PlanConfiguration[]
  flatFeatures: boolean
  plan: AvailableSubscriptionPlanInterfaceV2 | SubscriptionPlanInfterface
  planType: PlanType
  companySizeOption: number
  discountedPrices: number[]
  billingCycle: string
}

export const PlanWidget = ({
  backgroundColor,
  planDescription,
  features,
  flatFeatures,
  plan,
  planType,
  companySizeOption,
  children,
  discountedPrices,
  billingCycle,
}: PropsWithChildren<PlanWidgetProps>) => {
  const getDiscountedPrice = () => discountedPrices[companySizeOption]

  const finalPrice = formatMoney(
    Number(
      planType === 'custom' && isStandardPlan(plan)
        ? getDiscountedPrice()
        : plan.cycle_fee,
    ),
    plan.currency,
  )

  return (
    <Grid
      style={{ gridTemplateRows: 'subgrid', gridRow: 'span 6' }}
      backgroundColor={backgroundColor}
      py="s-16"
      radius="widget"
      gap="s-16"
      data-testid="plan-widget-wrapper"
    >
      <Flex px="s-24" justifyContent="space-between">
        <Text variant="heading2">{plan.name}</Text>

        {isStandardPlan(plan) && plan.tag ? (
          <Tag useIcon="Lightning" color={Token.color.deepPurple}>
            {plan.tag}
          </Tag>
        ) : null}
      </Flex>

      {plan.description.length > 0 && (
        <Text variant="caption" px="s-24" color={Token.color.greyTone50}>
          {plan.description}
        </Text>
      )}

      <VStack align={{ all: 'start', md: 'center' }} px="s-24">
        {isStandardPlan(plan) ? (
          <HStack align="end" gap="s-4">
            {planType !== 'monthly' && <Text variant="h1">from</Text>}
            {planType === 'monthly' && (
              <Text
                variant="heading3"
                color={Token.color.greyTone50}
                textDecoration="line-through"
              >
                {formatMoney(Number(plan.marketing_full_price), plan.currency)}
              </Text>
            )}
            <Text variant="h1" data-testid="plan-price">
              {finalPrice}
            </Text>
          </HStack>
        ) : (
          <HStack align="end" gap="s-4">
            <Text variant="h1" data-testid="plan-price">
              {finalPrice}
            </Text>
          </HStack>
        )}

        <Text variant="caption">per active employee/{billingCycle}</Text>
      </VStack>

      <Box px="s-32" display={{ all: 'none', md: 'block' }}>
        {children}
      </Box>

      <Separator mx="s-16" />
      {flatFeatures ? (
        <VStack mx="s-8">
          <Text variant="h6" px="s-16" mb="s-16">
            {planDescription}
          </Text>
          <Box pl="s-16">
            {features
              .flatMap(f => f.features)
              .map(feature => (
                <PlanWidgetFeature feature={feature} key={feature.title} />
              ))}
          </Box>
        </VStack>
      ) : (
        <Box>
          {features.map(featureGroup => (
            <Box mx="s-16" mb="s-16" key={featureGroup.id}>
              <ExpandableCell prefersExpanded={featureGroup.id === 'performance'}>
                <ExpandableCell.Title>
                  <Text variant="h4">{featureGroup.label}</Text>
                </ExpandableCell.Title>
                <ExpandableCell.Note>
                  {featureGroup.features.map(feature => (
                    <PlanWidgetFeature feature={feature} key={feature.title} />
                  ))}
                </ExpandableCell.Note>
              </ExpandableCell>
            </Box>
          ))}
        </Box>
      )}
    </Grid>
  )
}
