import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ProbationPipelineTable } from './ProbationPipelineTable'
import { PageBody } from '@src/components/Page/PageBody'
import { Token } from '@revolut/ui-kit'

export const ProbationReviewsTable = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Probation pipeline"
        subtitle="Check out employees who are on probation"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.PROBATION)}
      />
      <PageBody maxWidthMd={Token.breakpoint.xxl}>
        <ProbationPipelineTable />
      </PageBody>
    </PageWrapper>
  )
}
