import { LOCAL_STORAGE } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Location } from 'history'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { IdAndName } from '@src/interfaces'
import { isDate, format, intervalToDuration, formatDistanceStrict } from 'date-fns'

export const KeyboardShortcuts = {
  quickArchive: {
    key: 'KeyA',
    label: 'Ctrl ⌥  A',
  },
  skip: {
    key: 'KeyS',
    label: 'Ctrl ⌥ S',
  },
  moveNextStage: {
    key: 'KeyM',
    label: 'Ctrl ⌥ M',
  },
  linkedin: {
    key: 'KeyL',
    label: 'Ctrl ⌥ L',
  },
  schedule: {
    key: 'KeyI',
    label: 'Ctrl ⌥ I',
  },
  applicationQuestions: {
    key: 'KeyQ',
    label: 'Ctrl ⌥ Q',
  },
  timeline: {
    key: 'KeyT',
    label: 'Ctrl ⌥ T',
  },
  sendOnlineTest: {
    key: 'KeyO',
    label: 'Ctrl ⌥ O',
  },
  changeStage: {
    key: 'KeyN',
    label: 'Ctrl ⌥ C',
  },
  assignDifferentRecruiter: {
    key: 'KeyR',
    label: 'Ctrl ⌥ R',
  },
  assignDifferentJobPosting: {
    key: 'KeyJ',
    label: 'Ctrl ⌥ J',
  },
  archiveWithDifferentReason: {
    key: 'KeyD',
    label: 'Ctrl ⌥ D',
  },
}

export interface CVScreeningSessionInterface {
  candidates?: IdAndName[]
  screenedCandidateIds?: number[]
  archivedCandidateIds?: number[]
  skippedCandidateIds?: number[]
  movedToNextStageCandidateIds?: number[]
  scheduledCandidateIds?: number[]
  onlineTestSentCandidateIds?: number[]
  // in seconds
  duration?: number
  referrerPath?: string
}

export interface CVScreeningPerformanceInterface {
  screened?: number
  // in seconds
  avgTime?: number
}

export const clearCVArchivingChoice = () => {
  workspaceLocalStorage.removeItem(LOCAL_STORAGE.ARCHIVING_DETAILS_REMEMBER_CHOICE)
}

export const clearCVScreeningSession = () => {
  clearCVArchivingChoice()
  workspaceLocalStorage.removeItem(LOCAL_STORAGE.CV_SCREENING_SESSION)
}

export const getCVScreeningSession = (): CVScreeningSessionInterface => {
  try {
    const data = workspaceLocalStorage.getItem(LOCAL_STORAGE.CV_SCREENING_SESSION)
    if (!data) {
      throw Error
    }
    return JSON.parse(data) as CVScreeningSessionInterface
  } catch (e) {
    return {
      candidates: [],
      screenedCandidateIds: [],
      archivedCandidateIds: [],
      skippedCandidateIds: [],
      movedToNextStageCandidateIds: [],
      scheduledCandidateIds: [],
      onlineTestSentCandidateIds: [],
      duration: 0,
    }
  }
}

export const setCVScreeningSession = (data: Partial<CVScreeningSessionInterface>) => {
  workspaceLocalStorage.setItem(
    LOCAL_STORAGE.CV_SCREENING_SESSION,
    JSON.stringify({
      ...getCVScreeningSession(),
      ...data,
    } as CVScreeningSessionInterface),
  )
}

export const setCVScreeningPerformance = (
  data: Partial<CVScreeningPerformanceInterface>,
) => {
  workspaceLocalStorage.setItem(
    LOCAL_STORAGE.CV_SCREENING_PERFORMANCE,
    JSON.stringify(data),
  )
}

export const getCVScreeningPerformance = (): CVScreeningPerformanceInterface => {
  try {
    const data = workspaceLocalStorage.getItem(LOCAL_STORAGE.CV_SCREENING_PERFORMANCE)
    if (!data) {
      throw Error
    }
    return JSON.parse(data) as CVScreeningPerformanceInterface
  } catch (e) {
    return {
      screened: undefined,
      avgTime: undefined,
    }
  }
}

export const navigateToCVScreening = (
  candidateId: number | string,
  location?: Location,
) => {
  navigateTo(
    `${pathToUrl(ROUTES.FORMS.CV_SCREENING, {
      id: candidateId,
    })}${location?.search || ''}`,
  )
}

export const getDate = (date?: string | Date | null) => {
  return date ? ((isDate(date) ? date : new Date(date)) as Date) : null
}

export const calcDurationInYears = (
  start?: string | Date | null,
  end?: string | Date | null,
) => {
  const startDate = getDate(start)
  const endDate = getDate(end)

  if (!startDate || !endDate) {
    return null
  }

  return formatDistanceStrict(startDate, endDate, {
    unit: 'year',
    roundingMethod: 'round',
  })
}

export const calcDuration = (
  start?: string | Date | null,
  end?: string | Date | null,
) => {
  const startDate = getDate(start)
  const endDate = getDate(end)
  let duration = ''

  if (!startDate || !endDate) {
    return null
  }

  let interval = intervalToDuration({ start: startDate, end: endDate })
  if (interval.years && interval.years > 0) {
    duration = `${interval.years}y `
  }

  return `${duration}${
    interval.months && interval.months > 0 ? ` ${interval.months}m` : ''
  }`
}

export const formatDateRange = (
  start?: string | Date | null,
  end?: string | Date | null,
  dateFormat: string = 'yyyy',
) => {
  const startDate = getDate(start)
  const endDate = getDate(end)

  if (!isDate(startDate) && !isDate(endDate)) {
    return null
  }

  if (startDate && endDate) {
    return `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`
  }
  if (startDate) {
    return format(startDate, dateFormat)
  }
  if (endDate) {
    return format(endDate, dateFormat)
  }

  return null
}
