import React from 'react'
import {
  Action,
  DetailsCell,
  Group,
  Text,
  Toggle,
  TransitionCollapse,
  VStack,
} from '@revolut/ui-kit'

import { useGetEngagementSurveyRounds } from '@src/api/engagement'
import Loader from '@src/components/CommonSC/Loader'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useIsEngagementLayoutV2 } from '@src/features/Engagement/hooks/permissions'
import { RoundItem } from './Rounds/RoundItem'
import { ResultsInterface } from './SurveyResults/ResultsWidget/common'
import { EngagementResultsWidget } from './SurveyResults/ResultsWidget'

const DEFALT_SURVEYS_DISPLAY_NUM = 4

export const SurveyOverview = (props: ResultsInterface) => {
  const { survey, refetchSurvey } = props
  const { data: surveyRoundsResponse, isLoading: isLoadingRounds } =
    useGetEngagementSurveyRounds(props.survey.id, {
      ordering: 'status_order,-sent_on',
      is_test: 'false',
    })
  const isLayoutV2 = useIsEngagementLayoutV2({ isOrganisationCompanySubtab: false })

  if (isLayoutV2) {
    return <EngagementResultsWidget {...props} />
  }
  return isLoadingRounds ? (
    <Loader />
  ) : (
    <Group px="s-8">
      <Toggle>
        {({ state, toggle }) => (
          <>
            <DetailsCell>
              <DetailsCell.Title>
                <Text variant="h4" color="foreground">
                  {state ? 'All survey runs' : 'Latest survey run'}
                </Text>
              </DetailsCell.Title>
              <DetailsCell.Content>
                {surveyRoundsResponse &&
                  surveyRoundsResponse?.count > DEFALT_SURVEYS_DISPLAY_NUM && (
                    <Action onClick={() => toggle()}>
                      {state ? 'Collapse' : 'See all'}
                    </Action>
                  )}
              </DetailsCell.Content>
            </DetailsCell>
            {surveyRoundsResponse?.results.length ? (
              <VStack gap="s-8" px="s-16" pb="s-16">
                {surveyRoundsResponse.results
                  .slice(0, DEFALT_SURVEYS_DISPLAY_NUM)
                  .map(singleRound => (
                    <RoundItem
                      permissions={survey.field_options.permissions}
                      key={singleRound.id}
                      refetch={refetchSurvey}
                      round={singleRound}
                      surveyId={survey.id}
                    />
                  ))}

                <TransitionCollapse in={state}>
                  <VStack gap="s-8">
                    {surveyRoundsResponse?.results
                      .slice(DEFALT_SURVEYS_DISPLAY_NUM)
                      .map(singleResult => (
                        <RoundItem
                          permissions={survey.field_options.permissions}
                          refetch={refetchSurvey}
                          round={singleResult}
                          key={singleResult.id}
                          surveyId={survey.id}
                        />
                      ))}
                  </VStack>
                </TransitionCollapse>
              </VStack>
            ) : (
              <EmptyTableRaw title="This survey has no runs yet" />
            )}
          </>
        )}
      </Toggle>
    </Group>
  )
}
