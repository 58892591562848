import React, { useState } from 'react'
import { Dropdown, MoreBar, Tooltip, useTooltip } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { useCanViewCreateProbation } from '@src/pages/EmployeeProfile/Preview/components/Buttons/common'
import { isBefore } from 'date-fns'
import { createProbationCycle } from '@src/api/probationEmployees'
import { successNotification } from '@src/actions/NotificationActions'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

type Props = {
  employeeData: EmployeeInterface
  disabled?: boolean
  refreshData?: () => void
  isDropdownItem?: boolean
}

export const CreateProbation = ({
  employeeData,
  refreshData,
  isDropdownItem,
  disabled,
}: Props) => {
  const canView = useCanViewCreateProbation({ data: employeeData })
  const errorPopup = useErrorPopup()
  const [pending, setPending] = useState(false)
  const probationTooltip = useTooltip()
  const { performance_cycles } = employeeData

  if (!canView) {
    return null
  }

  // there's sometimes some delay on BE so this may be initally be an empty array
  const isEmployeeOnProbation = Boolean(
    performance_cycles
      ?.filter(cycle => cycle.category === 'probation')
      .find?.(cycle => {
        const now = new Date()
        const end = new Date(cycle.end_date_time)
        return isBefore(now, end)
      }),
  )

  const handleCreateCycle = async () => {
    setPending(true)
    try {
      await createProbationCycle(employeeData.id)
      successNotification('Probation cycle created')
      refreshData && refreshData()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: "Couldn't create probation cycle",
        forceFallbackTitle: true,
      })
    } finally {
      setPending(false)
    }
  }

  if (disabled || isEmployeeOnProbation) {
    return isDropdownItem ? (
      <Dropdown.Item
        use="button"
        useIcon="Retry"
        aria-disabled
        {...probationTooltip.getAnchorProps()}
      >
        Create probation cycle
        <Tooltip
          placement="right"
          {...probationTooltip.getTargetProps()}
          maxWidth="250px"
        >
          This employee is already on an active probation cycle.
        </Tooltip>
      </Dropdown.Item>
    ) : (
      <MoreBar.Action
        useIcon="Retry"
        aria-disabled
        {...probationTooltip.getAnchorProps()}
      >
        Create probation cycle
        <Tooltip
          placement="right"
          {...probationTooltip.getTargetProps()}
          maxWidth="250px"
        >
          This employee is already on an active probation cycle.
        </Tooltip>
      </MoreBar.Action>
    )
  }

  return isDropdownItem ? (
    <Dropdown.Item use="button" useIcon="Retry" onClick={handleCreateCycle}>
      Create probation cycle
    </Dropdown.Item>
  ) : (
    <MoreBar.Action useIcon="Retry" onClick={handleCreateCycle} pending={pending}>
      Create probation cycle
    </MoreBar.Action>
  )
}
