import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@revolut/ui-kit'

import { useTable } from '@src/components/TableV2/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  changelogChangedByWithAvatar,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogFieldsOldValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, SORT_DIRECTION } from '@src/interfaces/data'
import { getScheduleChangelog } from '@src/api/attendance'
import { TableNames } from '@src/constants/table'

const row = {
  cells: [
    {
      ...changelogFieldsChangedFields(),
      filterKey: null,
      width: 100,
    },
    {
      ...changelogFieldsOldValue,
      width: 200,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      sortKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 150,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      type: CellTypes.dateTime,
      width: 120,
    },
    {
      ...changelogStatus,
      selectorsKey: selectorKeys.changelog_item_statuses,
      width: 100,
    },
  ],
}

const Changelog = () => {
  const { id, employeeId } = useParams<{ employeeId: string; id: string }>()

  const table = useTable({ getItems: getScheduleChangelog(employeeId, id) }, undefined, [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
      nonResettable: true,
    },
  ])

  return (
    <Box mt="s-16">
      <AdjustableTable
        name={TableNames.ScheduleShiftChangelog}
        row={row}
        {...table}
        useWindowScroll
      />
    </Box>
  )
}

export default Changelog
