import { BULLET_POINT_REG_EXP } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/BulletPointTextArea'

const BULLET_POINT_SOL_REG_EXP = new RegExp(`^${BULLET_POINT_REG_EXP.source}`)
const BULLET_POINT_SOL_MULTILINE_REG_EXP = new RegExp(
  `^${BULLET_POINT_SOL_REG_EXP.source}`,
  'gm',
)
const BOLD_TOKENS_GROUPS_REG_EXP = /(\*\*)([\s\S]+?)(\*\*)/g

export const toMarkdown = (value: string): string =>
  value
    .replace(BULLET_POINT_SOL_REG_EXP, '')
    .replace(BOLD_TOKENS_GROUPS_REG_EXP, '<b>$2</b>')

export const toString = (value: string): string =>
  value
    .replace(BULLET_POINT_SOL_MULTILINE_REG_EXP, '')
    .replace(BOLD_TOKENS_GROUPS_REG_EXP, '$2')
