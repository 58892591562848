import React, { useMemo } from 'react'
import { ROUTES } from '@src/constants/routes'
import { payrollSettingsRequests } from '@src/api/settings'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { PayGroups } from './PayGroups'
import { General } from './General'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'

const getRoutes = (showPayGroupsTab: boolean) =>
  [
    {
      url: ROUTES.SETTINGS.PAYROLL.GENERAL,
      path: ROUTES.SETTINGS.PAYROLL.GENERAL,
      component: General,
      title: 'General',
      canView: [PermissionTypes.ViewPayrollPreferences],
    },
    showPayGroupsTab
      ? {
          url: ROUTES.SETTINGS.PAYROLL.CONFIGURATION,
          path: ROUTES.SETTINGS.PAYROLL.CONFIGURATION,
          component: PayGroups,
          title: 'Pay groups',
          canView: [PermissionTypes.ViewPaygroup],
        }
      : null,
  ].filter(Boolean)

export const PayrollSettings = () => {
  const isPayrollV2 = useHasFeatureFlag(FeatureFlags.PayrollV2)
  const showPayGroupsTab = !isPayrollV2
  const routes = useMemo(() => getRoutes(showPayGroupsTab), [showPayGroupsTab])

  return (
    <SettingsForm
      api={payrollSettingsRequests}
      routes={routes}
      title="Payroll app settings"
      subtitle="Settings for the payroll functionalities"
    />
  )
}
