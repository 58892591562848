import React from 'react'
import { Tag, TagProps } from '@revolut/ui-kit'
import { candidateMatchConfigs } from '@src/features/CommonCandidatesTable/constants'
import { getMatchType } from '@src/features/CommonCandidatesTable/utils'

interface Props extends TagProps {
  score: number | null | undefined
}

export const CandidateMatchTag = React.forwardRef<HTMLElement, Props>(
  ({ score, ...props }, ref) => {
    const type = getMatchType(score)
    const config = candidateMatchConfigs[type]

    if (!config) {
      return null
    }

    return (
      <Tag
        variant="faded"
        useIcon={config?.iconName}
        bg={config.bg}
        color={config.color}
        ref={ref}
        {...props}
      >
        {config.text}
      </Tag>
    )
  },
)
