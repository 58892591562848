import React, { useMemo } from 'react'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  HStack,
  Text,
  Tooltip,
  useTooltip,
  Flex,
  Box,
  Copyable,
  Ellipsis,
} from '@revolut/ui-kit'
import { formatDistanceToNow } from 'date-fns'
import { formatDateTime } from '@src/utils/format'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CountryFlag } from '@components/Inputs/LapeFields/LapePhoneInput'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

interface CVScreeningProfileProps {
  candidate: CandidateInterface
  round: InterviewRoundInterface
}

export const Info = ({ candidate, round }: CVScreeningProfileProps) => {
  const isCVScoringEnabled = useHasFeatureFlag(FeatureFlags.AICVScoring)
  const seenTooltip = useTooltip()
  const { data: allRounds, isLoading: isAllRoundsLoading } = useGetFullInterviewRounds(
    candidate.id,
  )

  const appliedLabel = useMemo(() => {
    switch (round.origin) {
      case 'application':
        return 'Applied'

      case 'referral':
        return 'Referred'

      default:
        return 'Sourced'
    }
  }, [round.origin])

  const activeJobPosting = allRounds?.find(item => item.id === round.id)
    ?.application_forms?.[0]?.job_posting

  return (
    <FormPreview data={candidate}>
      {!isAllRoundsLoading && (
        <>
          {activeJobPosting ? (
            <FormPreview.Item
              title="Job posting"
              insert={() => activeJobPosting.name}
              to={() =>
                pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                  id: activeJobPosting.id,
                  specId: round.specialisation.id,
                })
              }
            />
          ) : (
            <FormPreview.Item
              title="Specialisation"
              insert={() => round.specialisation.name}
              to={() =>
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                  id: round.specialisation.id,
                })
              }
            />
          )}
        </>
      )}

      <FormPreview.Item
        title="Last seen"
        insert={() =>
          round.cv_seen_at ? (
            <HStack gap="s-4">
              <Text {...seenTooltip.getAnchorProps()} style={{ flexShrink: 0 }}>
                {formatDistanceToNow(new Date(round.cv_seen_at))} ago
              </Text>
              <Tooltip {...seenTooltip.getTargetProps()}>
                {formatDateTime(round.cv_seen_at)}
              </Tooltip>

              {round.cv_seen_by && (
                <HStack gap="s-8" style={{ flexShrink: 0 }}>
                  <Text>by</Text>
                  <UserWithAvatar compact {...round.cv_seen_by} />
                </HStack>
              )}
            </HStack>
          ) : (
            '-'
          )
        }
      />

      {isCVScoringEnabled ? (
        <FormPreview.Item<CandidateInterface>
          title="Email"
          insert={() =>
            candidate.email ? (
              <Flex alignItems="center">
                <Box mr="10px">
                  <Copyable
                    value={candidate.email}
                    labelButtonCopy="Copy"
                    onCopy={() => {
                      pushNotification({
                        value: 'Email copied to clipboard',
                        duration: SUCCESS_DEFAULT_DURATION,
                        type: NotificationTypes.success,
                      })
                    }}
                  />
                </Box>
                <Ellipsis>{candidate.email}</Ellipsis>
              </Flex>
            ) : (
              '-'
            )
          }
        />
      ) : null}

      <FormPreview.Item
        title={appliedLabel}
        insert={() =>
          round?.specialisation_latest_application_date_time
            ? formatDateTime(round.specialisation_latest_application_date_time)
            : '-'
        }
      />
      <FormPreview.Item
        title="Current country"
        insert={() =>
          candidate.country ? (
            <HStack gap="s-8">
              <CountryFlag countryCode={candidate.country.country_code} />{' '}
              <Text>{candidate.country.name}</Text>
            </HStack>
          ) : (
            '-'
          )
        }
      />
      <FormPreview.Item
        title="Preferred work locations"
        insert={() =>
          candidate.preferred_locations?.length ? (
            <TextWithMoreCount
              items={candidate.preferred_locations}
              visibleCount={2}
              expandable
            />
          ) : (
            '-'
          )
        }
      />
      <FormPreview.Item<CandidateInterface>
        title="Recruiter assigned"
        insert={resp => (resp.recruiter ? <UserWithAvatar {...resp.recruiter} /> : '-')}
      />
    </FormPreview>
  )
}
