import React, { useMemo, useState } from 'react'
import { chain, FilterButton, Flex, Text } from '@revolut/ui-kit'
import { BarChart } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import {
  getSkillsTalentDistributionGraph,
  getSkillsTalentTableExport,
  skillsTalentTableRequests,
  useSkillsTalentStats,
} from '@src/api/skills'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { SkillsTalentInterface } from '@src/interfaces/skills'
import {
  skillTalentDepartmentColumn,
  skillTalentEmployeeColumn,
  skillTalentExpectedRatingColumn,
  skillTalentFunctionColumn,
  skillTalentLatestRatingColumn,
  skillTalentRoleColumn,
  skillTalentSeniorityColumn,
  skillTalentTeamColumn,
} from '@src/constants/columns/skill'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { filterSortPageIntoQuery } from '@src/utils/table'
import Stat from '@src/components/Stat/Stat'
import { PerformanceRating } from '@src/interfaces/performance'
import {
  PerformanceRatingShortTitle,
  PerformanceRatingTitle,
} from '@src/constants/performance'
import { ExportTypes } from '@src/constants/export'
import { formatPercentage } from '@src/utils/format'
import PopupChart from '@src/components/Charts/BaseChart/PopupChart'
import {
  BaseChartInner,
  TooltipContainer,
} from '@src/components/Charts/BaseChart/BaseChartInner'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const skillTaletRow = (
  specialisationsEnabled: boolean,
): RowInterface<SkillsTalentInterface> => ({
  linkToForm: ({ employee }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: employee.id })),
  cells: [
    {
      ...skillTalentEmployeeColumn,
      width: 200,
    },
    {
      ...skillTalentTeamColumn,
      width: 200,
    },
    {
      ...skillTalentRoleColumn(specialisationsEnabled),
      width: 200,
    },
    {
      ...skillTalentSeniorityColumn,
      width: 120,
    },
    {
      ...skillTalentDepartmentColumn,
      width: 120,
    },
    {
      ...skillTalentFunctionColumn,
      width: 120,
    },
    {
      ...skillTalentLatestRatingColumn,
      width: 200,
    },
    {
      ...skillTalentExpectedRatingColumn,
      width: 200,
    },
  ],
})

const getAverageLevel = (id: string, rating?: PerformanceRating | null) => {
  if (rating === null) {
    return <>-</>
  }

  if (rating) {
    return (
      <Flex gap="s-8" alignItems="center">
        <Text>{PerformanceRatingTitle[rating]}</Text>
        <PopupChart
          trigger={
            <Flex pr="s-12">
              <BarChart cursor="pointer" size={16} color="grey-tone-20" />
            </Flex>
          }
        >
          {fullScreen => (
            <BaseChartInner
              id={Number(id)}
              formatValue={formatPercentage}
              margin={{ top: 10, right: 60, bottom: 30, left: 20 }}
              showButtons={false}
              legend={() => null}
              fetchData={getSkillsTalentDistributionGraph}
              axisBottom={
                fullScreen
                  ? {
                      format: val =>
                        typeof val === 'string'
                          ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                            PerformanceRatingShortTitle[val] || val
                          : '',
                    }
                  : undefined
              }
              tooltip={({ value, indexValue }: any) => {
                const key = Object.keys(PerformanceRatingShortTitle).find(
                  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                  k => PerformanceRatingShortTitle[k] === indexValue,
                )
                return (
                  <TooltipContainer>
                    {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
                    {key ? PerformanceRatingTitle[key] : indexValue}:{' '}
                    {formatPercentage(value)}
                  </TooltipContainer>
                )
              }}
              theme={{
                grid: {
                  line: {
                    strokeWidth: 1,
                    strokeDasharray: '2 4',
                  },
                },
              }}
              isNew
            />
          )}
        </PopupChart>
      </Flex>
    )
  }

  return undefined
}

type FilterGroup = 'basic' | 'intermediate' | 'advanced' | 'expert'

const filterGroupToRatingMap: Record<FilterGroup, PerformanceRating[]> = {
  basic: [
    PerformanceRating.basic_minus,
    PerformanceRating.basic,
    PerformanceRating.basic_plus,
  ],
  intermediate: [
    PerformanceRating.intermediate_minus,
    PerformanceRating.intermediate,
    PerformanceRating.intermediate_plus,
  ],
  advanced: [
    PerformanceRating.advanced_minus,
    PerformanceRating.advanced,
    PerformanceRating.advanced_plus,
  ],
  expert: [
    PerformanceRating.expert_minus,
    PerformanceRating.expert,
    PerformanceRating.expert_plus,
  ],
}

export const SkillFormTalentTab = () => {
  const { id } = useParams<{ id: string }>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const [filterGroup, setFilterGroup] = useState<FilterGroup>()

  const row = useMemo(
    () => skillTaletRow(specialisationsEnabled),
    [specialisationsEnabled],
  )

  const table = useTable(
    skillsTalentTableRequests(id),
    [],
    [
      {
        sortBy: 'rating',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'expected_rating',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  )
  const { data: stats } = useSkillsTalentStats(id)

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const onQuickFilterClick = (group: FilterGroup) => {
    if (filterGroup === group) {
      setFilterGroup(undefined)
      table.onFilterChange({
        columnName: 'rating',
        filters: [],
      })
    } else {
      setFilterGroup(group)
      table.onFilterChange({
        columnName: 'rating',
        filters: filterGroupToRatingMap[group].map(rating => ({
          name: rating,
          id: rating,
        })),
      })
    }
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Total" val={stats?.total} />
        <Stat label="Average Level" val={getAverageLevel(id, stats?.rating_avg)} />
        <Stat label="Review Cycle" val={stats?.cycle_name} />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <FilterButton
          onClick={() => onQuickFilterClick('basic')}
          active={filterGroup === 'basic'}
        >
          {chain('Basic', stats?.rating_basic_count)}
        </FilterButton>
        <FilterButton
          onClick={() => onQuickFilterClick('intermediate')}
          active={filterGroup === 'intermediate'}
        >
          {chain('Intermediate', stats?.rating_intermediate_count)}
        </FilterButton>
        <FilterButton
          onClick={() => onQuickFilterClick('advanced')}
          active={filterGroup === 'advanced'}
        >
          {chain('Advanced', stats?.rating_advanced_count)}
        </FilterButton>
        <FilterButton
          onClick={() => onQuickFilterClick('expert')}
          active={filterGroup === 'expert'}
        >
          {chain('Expert', stats?.rating_expert_count)}
        </FilterButton>
      </Table.Widget.Filters>

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <ExportMenu
            request={getSkillsTalentTableExport(id)}
            filterQuery={filterQuery}
            fileName="Skill Talent"
            supportedTypes={[ExportTypes.csv]}
          />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>

      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.SkillTalent}
          useWindowScroll
          {...table}
          row={row}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
