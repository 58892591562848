import React, { useEffect } from 'react'
import {
  ThemeProvider,
  Grid,
  Box,
  Flex,
  VStack,
  Token,
  Text,
  Image,
  Button,
  Spacer,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

import { GlobalStyle } from './styles'
import styled from 'styled-components'
import { Block } from './components/Block'
import { Team } from './components/Team'
import { Divider } from './components/Divider'
import { EmployeeTile } from './components/EmployeeTile'

import {
  aiChatbotWinners,
  eSimWinners,
  finCrimeWinners,
  projectMeruWinners,
  revBusinessWinners,
  revPayWinners,
  salesSpecialProjectsWinners,
  strategicPlanningWinners,
} from './winners'

import { REV_10X_ASSETS } from './assets'
import { REVOLUT_10X_AWARDS_IN_ACTION } from '@src/constants/externalLinks'

const HeroHeader = styled(Flex)`
  flex-direction: column;
  align-self: center;
  align-items: flex-end;

  @media ${Token.media.md} {
    transform: translateY(25%);
  }
  @media ${Token.media.xxl} {
    transform: translateY(50%);
  }
`

export const Rev10x = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Box>
      <GlobalStyle />
      <ThemeProvider mode="dark">
        <Grid
          minHeight={{ lg: '800px' }}
          placeContent="center center"
          rows="auto 1fr"
          style={{
            position: 'relative',
            background: `url(${REV_10X_ASSETS.heroBg}) no-repeat`,
            backgroundSize: 'contain',
            backgroundPositionX: 'center',
          }}
        >
          <Flex justifyContent="center" py="s-16">
            <InternalLink to={pathToUrl(ROUTES.MAIN)}>
              <LogoAlt width="200" />
            </InternalLink>
          </Flex>
          <HeroHeader>
            <Text fontWeight={300} fontSize="6.75rem">
              10x Awards
            </Text>
            <Text fontWeight={300} fontSize="2.25rem">
              Values in Action
            </Text>
          </HeroHeader>
        </Grid>
        <VStack align="center" py={80} px="s-16">
          <Text use="h4" variant="h4" fontSize="3.75rem" textAlign="center">
            WALL OF FAME
          </Text>
          <Text fontWeight={500} textAlign="center">
            The awards are broken down into five values-based categories in each cycle,
            and winners can be either individuals or teams.
          </Text>
        </VStack>
        <VStack maxWidth={1000} mx="auto">
          <Block
            tags={['Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.aiCustomerChatbot}
            imagePosition="top center"
            category={{
              name: 'Deliver Wow Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.deliverWow,
            }}
            title="AI Customer Service Chatbot"
            description="This team delivered a wow experience by building an AI-powered LLM assistant that transformed customer support. By simplifying complex interactions, it provided fast, accurate, and human-like responses. The assistant prioritised and resolved customer issues with efficiency, driving a 40% drop in detractor rates and boosting chat retention to over 60%. This AI-driven innovation set a new benchmark for customer service, offering intelligent support and generating over $10 million in annual savings."
          />
          <Team team={aiChatbotWinners} />
          <Divider />
          <Block
            tags={['Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.projectMeru}
            category={{
              name: 'Dream Team Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.dreamTeam,
            }}
            title="Project Meru"
            description="Defying market trends, Revolut achieved a record $45 billion valuation through its global secondary share sale. This success demanded teamwork and collaboration from the Finance, Legal, and Talent teams to prepare investor materials, run the fundraising process, and execute payments across 33 countries, all while navigating intricate tax and legal landscapes. This project exemplifies our 'Dream Team' value, showcasing how unified effort delivers extraordinary results."
          />
          <Team team={projectMeruWinners} />
          <Divider />
          <Block
            tags={['Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.revBusiness}
            category={{
              name: 'Get it Done Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.getItDone,
            }}
            title="Revolut business 5"
            description="In just three months, the RB5 team rebuilt core app functionalities and redesigned the web platform. Throughout the process, each member took ownership, coordinating cross-functionally, with engineering delivering the app rebuild on time by managing workloads and design ensuring assets were actionable for engineers. The team also aligned with various internal departments, from legal to support, while executing coordinated external communications. The completed rebrand resulted in a significant uplift in user engagement; doubling benchmark click-through and conversion rates. The team's performance reflects our commitment to delivering results while prioritising high speed and collaboration."
          />
          <Team team={revBusinessWinners} />
          <Divider />
          <Block
            tags={['Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.europeExpansion}
            imageFit="contain"
            category={{
              name: 'Never Settle Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.neverSettle,
            }}
            title="Europe Expansion"
            description={`Despite our strong initial presence in Europe, Alexander truly embodied the 'Never Settle' mindset by setting even higher goals and driving faster, more impactful expansion across Germany, Romania, and Italy. By coordinating over 20 departments, conducting market research, handling localization, and managing regulatory engagement, he successfully onboarded more than 100,000 new customers. In doing so, he exceeded key targets, including a significant increase in the customer activation rate, while meeting critical regulatory deadlines ahead of schedule. This expansion has strengthened our position in Europe and laid the foundation for continued 10x growth.`}
          />
          <EmployeeTile
            firstName="Alexander George"
            lastName="Dickinson"
            imageSrc={REV_10X_ASSETS.winners.dickinson}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.esim}
            category={{
              name: 'Think Deeper Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.thinkDeeper,
            }}
            title="ESIM"
            description={`The eSIM launch reflects our 'Think Deeper' mindset by anticipating travel tech trends and delivering top-tier solutions for Ultra users. Recognising the demand for seamless global connectivity, the Premium and Global Business teams proactively positioned Revolut as the first financial services company to offer eSIMs in the UK/EEA, ahead of other players in the market. The product's simple 'download and top-up' model was made possible through extensive commercial negotiations, while the Legal team created a framework to navigate regulatory challenges, enabling global expansion. This strategic move unlocked a new revenue stream, generating over £13M annually while enhancing the user experience.`}
          />
          <Team team={eSimWinners} />
          <Divider />
          <Block
            tags={['Customer Facing']}
            imageSrc={REV_10X_ASSETS.silvija}
            imagePosition="left center"
            category={{
              name: 'Dream Team Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.dreamTeam,
            }}
            title="Capacity planning team transformation"
            description={`Silvija has embodied the 'Dream Team' value through her leadership in implementing dynamic process flows for the Capacity Planning teams at Revolut to aid collaboration - the key to our collective success. By providing greater clarity around ownership and a more efficient logic to task ordering, and after being successfully implemented via detailed training, these new processes have fostered a new, positive team culture. These transformative changes have allowed Capacity Planning to function as a true 'Dream Team' regarded for its operating model which allows for collaborative working, knowledge sharing and team growth. Silvija’s work in implementing these new processes showcases how by instilling a culture of ownership and trust, individual efforts can be transformed into a cohesive, solution-driven team.`}
          />
          <EmployeeTile
            firstName="Silvija"
            lastName="Mitalaite"
            imageSrc={REV_10X_ASSETS.winners.mitalaite}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['Customer Facing']}
            imageSrc={REV_10X_ASSETS.carlos}
            imageBackground="white"
            imageFit="none"
            imagePosition="center center"
            category={{
              name: 'Get it Done Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.getItDone,
            }}
            title="Average customer resolution time reduction"
            description={`Carlos Soriano demonstrated the 'Get It Done' value by tackling the challenge of improving one of the company's key 'Deliver Wow' KPIs by introducing an initiative to reduce Average Customer Resolution Time (CRT). Despite his primary focus on Retail Support, Carlos went above and beyond in his scope to collaborate across departments to build a dedicated plan towards this KPI that involved stakeholders from several departments, designed to reduce second line dependencies in Retail support, improve outdated resolution flows, and drive product improvements. Carlos took the lead on execution, achieving a 66% improvement in two quarters. This accomplishment, reaching 100% KPI attainment, showcases Carlos' commitment, ownership mindset and dedication to pushing through obstacles to get things done.`}
          />
          <EmployeeTile
            firstName="Carlos"
            lastName="Soriano"
            imageSrc={REV_10X_ASSETS.winners.soriano}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['Customer Facing']}
            imageSrc={REV_10X_ASSETS.fincrime}
            category={{
              name: 'Never Settle Winner(s)',
              badgeImageSrc: REV_10X_ASSETS.badges.neverSettle,
            }}
            title="FinCrime Complex Investigations Unit"
            description={`Revolut's Complex Investigations Unit (CIU) has exemplified our 'Never Settle' value, by strategically refining its internal structure and optimising resources to drive a transformation in the way Revolut handles high-profile, high-risk incidents. Through their anti-financial crime efforts, including proactive investigations and strategic partnerships, the CIU achieved ambitious goals, including 210 external disclosures and 168 law enforcement investigations in 2024. The CIU's work has garnered recognition from law enforcement and EU bodies, including one from the Metropolitan Police, strengthening Revolut's reputation in financial crime prevention and increasing trust and collaboration with financial intelligence units, policymakers, and financial market participants.`}
          />
          <Team team={finCrimeWinners} />
          <Divider />

          <Block
            tags={['2nd Place', 'Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.franceExpansion}
            category={{
              name: 'Deliver Wow Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.deliverWow,
            }}
            title="France Expansion"
            description={`Nicolas' leadership drove France to become Revolut's fastest-growing market globally in 2024, surpassing the UK. By building a world-class influencer engine and collaborating with top influencers, he positioned Revolut as the "benchmark for success," delivering a 100% ROI on attributable sign-ups. Nicolas also built key partnerships with major brands including Stade Toulousain, boosting performance marketing. His strategic narrative positioned Revolut as a serious competitor to Boursorama, surpassing them in new accounts and creating a significant "wow" experience for the brand in France.`}
          />
          <EmployeeTile
            firstName="Nicolas"
            lastName="Moalic"
            imageSrc={REV_10X_ASSETS.winners.moalic}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['2nd Place', 'Non-Customer Facing']}
            videoSrc={REV_10X_ASSETS.strategicPlanning}
            category={{
              name: 'Dream Team Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.dreamTeam,
            }}
            title="Strategic Planning"
            description={`After inheriting a set of dysfunctional planning processes, the Strategic Planning Team embodied the 'Dream Team' value by transforming into a high-performing, self-directed unit in under two quarters. Established from scratch in Q4'24, they took on key responsibilities including annual/quarterly planning, capacity planning, performance assessment/audit, and long-term incentives. This effort drove operational excellence, consistent high NPS scores and substantial KPI growth in 2024. Their success demonstrates the power of cohesive teamwork.`}
          />
          <Team team={strategicPlanningWinners} />
          <Divider />
          <Block
            tags={['2nd Place', 'Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.revPayJoint}
            category={{
              name: 'Get it Done Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.getItDone,
            }}
            title="Revolut Pay"
            description={`After a challenging first year for Revolut Pay with limited growth, the team embraced the 'Get It Done' ethos. Through speedy execution, the team onboarded major merchants like Binance and Wizzair, cutting through lengthy negotiations that had lasted 1-2 years. They also deployed key features like RevPoints, integrated RevPay into the new Android Terminal, and expanded RevPay infrastructure for Stays, Shops, and eSIMs, driving 5x year-on-year growth. In the spirit of getting things done, this team went a step further, expanding their scope to look after the Donations product and emergency campaigns, such as for the Valencia floods, delivering high-impact results.`}
          />
          <Team team={revPayWinners} />
          <Divider />
          <Block
            tags={['2nd Place', 'Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.finOnbording}
            category={{
              name: 'Never Settle Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.neverSettle,
            }}
            title="Finance Onboarding"
            description={`After she inherited the Finance Onboarding team, which faced consistent bottlenecks and inconsistent performance on new initiatives & product launches, Eshita embodied the can-do attitude that forms the foundation of our 'Never Settle' value. She immediately implemented changes, simplifying processes, increasing collaboration within the team, proactively improving Service Delivery Management (SDM), and supporting global product launches. These efforts resulted in significantly improved SLA targets (consistently 100%+), an overall KPI attainment of 103%, and improved SDM performance and service catalog quality. Eshita demonstrated an ability to go 10X and achieve ambitious goals, creating a culture of continuous improvement and scalable frameworks in the process.`}
          />
          <EmployeeTile
            firstName="Eshita"
            lastName="Bhat"
            imageSrc={REV_10X_ASSETS.winners.bhat}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['2nd Place', 'Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.csSupport}
            imageFit="contain"
            category={{
              name: 'Think Deeper Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.thinkDeeper,
            }}
            title="Customer Support Performance Optimisation"
            description={`When tasked with working on data analytics for Customer Support, Patrycja structured the way analytics were produced and maintained. Through her work, she improved performance tracking for 2,000+ agents, streamlined CRM ticket and agent lifecycle data, and enhanced decision-making. Her approach challenged existing processes and set a higher standard for data use in Customer Support. This embodied 'Think Deeper' by extracting the essence from highly complex matters and continuously improving systems by developing structured, scalable solutions instead of short-term fixes.`}
          />
          <EmployeeTile
            firstName="Patrycja"
            lastName="Zawadzka"
            imageSrc={REV_10X_ASSETS.winners.zawadzka}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['2nd Place', 'Customer Facing']}
            imageSrc={REV_10X_ASSETS.mehvish}
            category={{
              name: 'Never Settle Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.neverSettle,
            }}
            title="Dante Attainment Improvements"
            description={`Mehvish Ajaz demonstrated the 'Never Settle' value by implementing targeted interventions to drive significant improvements and solve specific challenges her team was facing with the Dante platform - a case management tool similar to Jira. New team joiners were facing difficulties with average handing time (AHT) and latency issues around the management of tickets. Through the introduction of KPI improvements for latency and AHT, regular process reviews, creating best practice guidance for colleagues on how to reduce latency and ensure accurate data capture and introducing a culture of healthy competition within the team to boost attainment, Mehvish led her team to exceed 100+ daily Dante attainments, achieved over 95% adherence, and met latency targets. Mehvish's work on this project demonstrates the effectiveness of ambitious goals and a can-do attitude in unlocking success.`}
          />
          <EmployeeTile
            firstName="Mehvish"
            lastName="Aijaz"
            imageSrc={REV_10X_ASSETS.winners.aijaz}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['2nd Place', 'Customer Facing']}
            imageSrc={REV_10X_ASSETS.gabrielLopez}
            imagePosition="right"
            category={{
              name: 'Get it Done Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.getItDone,
            }}
            title="Retail Support Lead"
            description={`At Revolut, ideas are great but execution is everything. Gabriel has embodied the 'Get it Done' value through effectively leading an operation of 1,000 people in Retail Support while balancing daily executions and roadmap delivery. Gabriel showed true sweat and stretch to deliver significant improvements across metrics in 10 verticals, delivering 20% improvement on metrics QoQ, demonstrating impressive leadership skills and the ability to execute at scale across product areas to get things done. His leadership, ownership mindset and ability to extract best practice from one retail area and implement into another has earned him recognition and respect across the function as he has driven success.`}
          />
          <EmployeeTile
            firstName="Gabriel López-Garzón"
            lastName="Hernández"
            imageSrc={REV_10X_ASSETS.winners.hernandez}
          />
          <Spacer height={48} />
          <Divider />
          <Block
            tags={['2nd Place', 'Non-Customer Facing']}
            imageSrc={REV_10X_ASSETS.services}
            category={{
              name: 'Deliver Wow Runner(s) up',
              badgeImageSrc: REV_10X_ASSETS.badges.deliverWow,
            }}
            title="Sales Special Projects"
            description={`Revolut Business Sales previously lacked a clear understanding of customer satisfaction and customer experience issues. By mapping key touchpoints across the customer lifecycle and implementing an effective feedback solution via Typeform, the Special Projects team was able to 'Deliver Wow', overcoming internal blockers, and expanding surveys globally to capture essential customer insight data. The team achieved a 4.6/5 average sales rating and high engagement rates, providing actionable insights to improve sales performance, product roadmaps, onboarding, and support quality. The scalable framework created by the team has now also been applied across product launches, including Business Credit, MMF, Private Banking, and People products, ensuring tailored sales approaches for maximum impact.`}
          />
          <Team team={salesSpecialProjectsWinners} />
          <Divider />
          <Flex
            pt={120}
            gap="s-24"
            pb="s-24"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box>
              <Button
                elevated
                use="a"
                href={REVOLUT_10X_AWARDS_IN_ACTION}
                rel="noreferrer noopener"
                target="_blank"
              >
                More info
              </Button>
            </Box>
            <Text textAlign="center" fontWeight={500} fontSize="1.5rem" maxWidth={825}>
              Have an exceptional team or colleague in mind? Why not nominate them? The
              next round of 10X Award nominations will open in Q2'25.
            </Text>
          </Flex>
          <Image src={REV_10X_ASSETS.footerBg} alt="" />
        </VStack>
      </ThemeProvider>
    </Box>
  )
}
