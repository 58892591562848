import React from 'react'
import { DetailsCell, Group, Text, textChain, Token, Item, Avatar } from '@revolut/ui-kit'
import { CareersApplicationWorkInterface } from '@src/interfaces/careers'
import { formatDistanceStrict } from 'date-fns'
import { formatExperienceDateRange } from '@src/utils/hiring'

interface Props {
  data?: CareersApplicationWorkInterface[]
}

const WorkExperienceInfo = ({ data }: Props) => {
  if (!data?.length) {
    return null
  }

  return (
    <Group>
      <Item aria-label="Work Experience">
        <Item.Avatar>
          <Avatar useIcon="Suitcase" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Work Experience</Item.Title>
        </Item.Content>
      </Item>

      <DetailsCell>
        <DetailsCell.Note>
          {data.map((item, i) => (
            <Text
              display="block"
              color={item.end_date ? Token.color.greyTone50 : undefined}
              key={i}
            >
              {textChain(
                item.company?.name || item.other_company_name,
                item.position?.name || item.other_position_title,
                formatExperienceDateRange(item.start_date, item.end_date),
                formatDistanceStrict(
                  new Date(item.start_date),
                  item.end_date ? new Date(item.end_date) : new Date(),
                ),
              )}
            </Text>
          ))}
        </DetailsCell.Note>
      </DetailsCell>
    </Group>
  )
}

export default WorkExperienceInfo
