import React, { useEffect, useMemo, useState } from 'react'
import { chain, FilterButton, Flex, VStack } from '@revolut/ui-kit'

import { getRevolutersItems } from '@src/api/revoluters'
import FiltersSidebar from '@src/components/FiltersSidebar/FiltersSidebar'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { TableNames } from '@src/constants/table'
import { FilterByInterface } from '@src/interfaces/data'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { filtersSidebarItems, Row, simplifiedColsSettings } from './constants'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export type TableFilter = Record<string, (string | number)[]>

interface AudienceTableProps {
  filter?: TableFilter | null
  onFilterChange?: (filters: TableFilter) => void
  isPreview?: boolean
  withSidebarFilter?: boolean
  isSimplifiedView?: boolean
}

export const AudienceTable = ({
  isPreview,
  filter,
  onFilterChange,
  withSidebarFilter,
  isSimplifiedView,
}: AudienceTableProps) => {
  const [showFiltersSidebar, setShowFiltersSidebar] = useState(false)
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const initialFilters: FilterByInterface[] = filter
    ? Object.keys(filter).map(key => ({
        columnName: key,
        filters: filter![key].map((prop: string | number) => ({
          id: prop,
          name: `${prop}`,
        })),
      }))
    : []

  const tableOptions = {
    disableQuery: true,
  }

  const employeesTable = useTable<RevolutersInterface>(
    { getItems: getRevolutersItems },
    initialFilters,
    [],
    tableOptions,
  )

  useEffect(() => {
    onFilterChange?.(
      employeesTable.filterBy.reduce(
        (obj, tableFilter) => ({
          ...obj,
          [tableFilter.columnName]: tableFilter.filters.map(f => f.id),
        }),
        {},
      ),
    )
  }, [employeesTable.filterBy])

  const row = useMemo(() => Row(specialisationsEnabled), [specialisationsEnabled])

  const getActiveFilters = (filtersArray: FilterByInterface[]) =>
    filtersArray.filter(f => !!f.filters?.length)
  const activeFiltersNum = getActiveFilters(employeesTable.filterBy).length
  const hasActiveFilters = activeFiltersNum > 0

  return (
    <>
      <VStack space="s-8">
        {withSidebarFilter && (
          <Flex flex={1} width="100%">
            <FilterButton
              ml="auto"
              useIcon={hasActiveFilters ? 'Pencil' : 'Plus'}
              active={hasActiveFilters}
              onClick={() => setShowFiltersSidebar(true)}
            >
              {chain(hasActiveFilters ? 'Edit filters' : 'Add filters', activeFiltersNum)}
            </FilterButton>
          </Flex>
        )}
        <AdjustableTable
          name={TableNames.DynamicGroupsAudience}
          row={row}
          {...employeesTable}
          noReset={isPreview}
          disabledFiltering={isPreview}
          useWindowScroll
          onFilterChange={f => employeesTable?.onFilterChange(f, false)}
          tableSettings={
            isSimplifiedView ? simplifiedColsSettings(specialisationsEnabled) : undefined
          }
        />
      </VStack>
      {withSidebarFilter && (
        <FiltersSidebar
          open={showFiltersSidebar}
          onClose={() => setShowFiltersSidebar(false)}
          items={filtersSidebarItems(specialisationsEnabled)}
          filters={employeesTable.filterBy}
          onFilter={employeesTable.onFilterChange}
        />
      )}
    </>
  )
}
