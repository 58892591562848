import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Subheader,
  SubheaderSkeleton,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { SkillInterface } from '@src/interfaces/skills'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { SkillOneToOneInsightsInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'
import { AnalyticsMetrics } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/AnalyticsMetrics'
import { GrowthPlanMetrics } from '@src/features/AiInsights/Performance/components/Metrics/Internal/GrowthPlans/Skills/GrowthPlanMetrics'
import { OneToOneMetrics } from '@src/features/AiInsights/Performance/components/Metrics/Internal/OneToOnes/Skills/OneToOneMetrics'

export interface SkillPerformanceOutputProps {
  cycleId: number
  skill: SkillInterface
  skillRatingHistoryData: TalentSkillRatingInterface[]
  growthPlan: GrowthPlanInterface | null
  oneToOneInsights: SkillOneToOneInsightsInterface[]
  oneToOneMeeting: OneToOneMeeting | null
  isLoading: boolean
}

export const PerformanceOutput = ({
  cycleId,
  skill,
  skillRatingHistoryData,
  growthPlan,
  oneToOneInsights,
  oneToOneMeeting,
  isLoading,
}: SkillPerformanceOutputProps) => {
  if (isLoading) {
    return <Skeleton />
  }

  return (
    <VStack>
      <Subheader>
        <Subheader.Title>
          <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
            <FormattedMessage
              id="performance.metrics.sources.title"
              defaultMessage="Sources"
            />
          </Text>
        </Subheader.Title>
      </Subheader>
      <VStack space="s-16">
        {skillRatingHistoryData.length > 0 && (
          <Widget p="s-16">
            <AnalyticsMetrics
              skillRatingHistoryData={skillRatingHistoryData}
              isLoading={isLoading}
            />
          </Widget>
        )}
        {growthPlan && (
          <Widget p="s-16">
            <GrowthPlanMetrics
              growthPlan={growthPlan}
              skill={skill}
              isLoading={isLoading}
            />
          </Widget>
        )}
        {oneToOneInsights.length > 0 && (
          <Widget p="s-16">
            <OneToOneMetrics
              cycleId={cycleId}
              oneToOneInsights={oneToOneInsights}
              oneToOneMeeting={oneToOneMeeting}
              skill={skill}
              isLoading={isLoading}
            />
          </Widget>
        )}
      </VStack>
    </VStack>
  )
}

const Skeleton = () => (
  <VStack space="s-16">
    <SubheaderSkeleton />
    <SubheaderSkeleton />
    <Widget>
      <SourceSkeleton />
    </Widget>
    <SubheaderSkeleton />
    <Widget>
      <SourceSkeleton />
    </Widget>
  </VStack>
)
