import React from 'react'
import { z, ZodError } from 'zod'
import {
  Avatar,
  BottomSheet,
  Header,
  InputGroup,
  Text,
  TextButton,
  useToggle,
} from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BitbucketIntegrationHelperPopup } from './BitbucketIntegrationHelperPopup'

import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { errorMessageByField } from '@src/utils/zodUtils'
import { BitbucketConfig } from '../constants'

import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { arrayErrorsToFormError } from '@src/utils/form'
import { BitbucketIntegrationInterface } from '@src/interfaces/integrations'

const trimString = (value: string) => value.trim()

const validationSchema = z.object({
  client_id: z
    .string()
    .trim()
    .min(1, 'Client Key is required')
    .regex(/^\S*$/, 'Client ID cannot contain spaces')
    .transform(trimString),

  workspace: z
    .string()
    .trim()
    .min(1, 'Workspace is required')
    .regex(/^\S*$/, 'Workspace cannot contain spaces')
    .transform(trimString),

  client_secret: z
    .string()
    .trim()
    .min(1, 'Client Secret is required')
    .regex(/^\S*$/, 'Client Secret cannot contain spaces')
    .transform(trimString),
})

interface Props {
  open: boolean
  onClose: VoidFunction
}

export const BitbucketIntegrationEnablePopup = ({ open, onClose }: Props) => {
  const { name, image } = BitbucketConfig
  const { submit, values } = useLapeContext<BitbucketIntegrationInterface>()
  const { forceErrors } = useSafeFormValidator()

  const [helpPopupOpened, helpPopupToggle] = useToggle()
  const errorPopup = useErrorPopup()
  const showStatusPopup = useShowStatusPopup()

  const handleSubmitClick = async () => {
    try {
      validationSchema.parse({ ...values, enabled: true })
      values.enabled = true
      await submit()
      showStatusPopup({ title: 'Integration enabled' })
      onClose()
    } catch (error) {
      if (error instanceof ZodError) {
        forceErrors(arrayErrorsToFormError(errorMessageByField(error)))
        return
      }
      errorPopup.show({
        fallbackTitle: 'Failed to connect',
        error,
      })
    }
  }

  return (
    <>
      <BottomSheet open={open} onClose={onClose}>
        <Header>
          <Header.Title>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Your {name} account"
              id="integrations.bitbucket.connection.popup.title"
            />
          </Header.Title>
          <Header.Actions>
            <Avatar image={image} />
          </Header.Actions>
        </Header>
        <>
          <InputGroup>
            <LapeNewInput
              name="workspace"
              label="Workspace"
              description={`You can see your workspace name in the Bitbucket URL, appearing after "bitbucket.org/”`}
              required
            />

            <LapeNewInput name="client_id" label="Client Key" required />

            <LapeNewInput
              name="client_secret"
              label="Client Secret"
              required
              type="password"
            />

            <TextButton onClick={helpPopupToggle.on}>
              <Text variant="caption">Where to find this?</Text>
            </TextButton>
          </InputGroup>
          <BottomSheet.Actions>
            <NewSaveButtonWithPopup
              noPopup
              noValidate
              onClick={handleSubmitClick}
              isExistingData
              hideWhenNoChanges={false}
            >
              <FormattedMessage
                values={{
                  name,
                }}
                defaultMessage="Connect to {name}"
                id="integration.goalForm.connection.popup.cta"
              />
            </NewSaveButtonWithPopup>
          </BottomSheet.Actions>
        </>
      </BottomSheet>
      {helpPopupOpened ? (
        <BitbucketIntegrationHelperPopup onClose={helpPopupToggle.off} />
      ) : null}
    </>
  )
}
