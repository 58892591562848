import { Id } from '.'
import { ApprovalStatuses } from './approvalFlow'
import { EmployeeOptionInterface } from './employees'
import { GoalsInterface } from './goals'
import { FinalGrade } from './performance'
import { ProbationResults } from './probationReview'

export interface BulkAssignHRManagerPayloadInterface {
  category: 'probation' | 'PIP'
  hr_manager: Id<number>
  cycles: string[]
}

export interface BulkApproveGoalsPayloadInterface {
  cycles: (string | number)[]
}

interface ChangeMapByLocationIds {
  start_date_time: string
  end_date_time: string
  hr_manager: Id<number>
  goals_approved: ApprovalStatuses
  goals_updated: Pick<GoalsInterface, 'id' | 'name'>
  decision_created: { result: ProbationResults; final_grade: FinalGrade }
  decision_updated: { result: ProbationResults; final_grade: FinalGrade }
}

interface BasePipAndProbationChangelogItem {
  id: number
  author: EmployeeOptionInterface
  change_time: string
  change_type: 'created' | 'updated'
}

type CreatedChangeLogItem = {
  [K in keyof ChangeMapByLocationIds]: BasePipAndProbationChangelogItem & {
    change_type: 'created'
    change_location: K
    change: {
      before: null
      after: ChangeMapByLocationIds[K]
    }
  }
}[keyof ChangeMapByLocationIds]

type UpdatedChangeLogItem = {
  [K in keyof ChangeMapByLocationIds]: BasePipAndProbationChangelogItem & {
    change_type: 'updated'
    change_location: K
    change: {
      before: ChangeMapByLocationIds[K]
      after: ChangeMapByLocationIds[K]
    }
  }
}[keyof ChangeMapByLocationIds]

export interface StartedChangeLogItem extends BasePipAndProbationChangelogItem {
  change_type: 'created'
  change_location: ''
  change: {
    before: null
    after: {
      hr_manager: Id<number>
      end_date_time: string
      start_date_time: string
    }
  }
}

export interface ReviewCreatedChangeLogItem extends BasePipAndProbationChangelogItem {
  change_type: 'created'
  change_location: 'review_created'
  change: {}
}

export type PipAndProbationChangelogItem =
  | CreatedChangeLogItem
  | UpdatedChangeLogItem
  | ReviewCreatedChangeLogItem
  | StartedChangeLogItem

export const changelogUpdated = (
  changelog: PipAndProbationChangelogItem,
): changelog is UpdatedChangeLogItem => changelog.change_type === 'updated'

export const changelogCreated = (
  changelog: PipAndProbationChangelogItem,
): changelog is CreatedChangeLogItem => changelog.change_type === 'created'

export const changelogStarted = (
  changelog: PipAndProbationChangelogItem,
): changelog is StartedChangeLogItem =>
  changelog.change_type === 'created' && changelog.change_location === ''

export const changelogReviewCreated = (
  changelog: PipAndProbationChangelogItem,
): changelog is ReviewCreatedChangeLogItem =>
  changelog.change_type === 'created' && changelog.change_location === 'review_created'
