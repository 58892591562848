import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React, { ComponentType, useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { RoleStep } from './constants'
import { Badge, Box, Token, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface } from '@src/interfaces/roles'
import { PageActions } from '@src/components/Page/PageActions'

import { FormattedMessage } from 'react-intl'
import { connect } from 'lape'
import { rolesRequestsNew } from '@src/api/roles'
import { checkStepCompleted } from './helpers/checkStepCompleted'
import { FormActions } from './components/FormActions'
import { GeneralInfo } from './components/GeneralInfo'
import { CompetencyMatrix } from './components/CompetencyMatrix'
import { useGetHiringProcessSettings, useGlobalSettings } from '@src/api/settings'
import { HiringProcessPageContent } from '@src/pages/Forms/RoleForm/HiringProcess/HiringProcessPageContent'

const formTabs: (TabBarTableNavigationInterface & {
  component: ComponentType
  key: RoleStep
})[] = [
  {
    title: 'General info',
    key: 'general-info',
    component: GeneralInfo,
  },
  {
    title: 'Competency Matrix',
    key: 'competency-matrix',
    component: CompetencyMatrix,
  },
  {
    title: 'Hiring process',
    key: 'hiring-process',
    component: HiringProcessPageContent,
  },
]

const FormBody = () => {
  const { id, step } = useParams<{ id?: string; step: RoleStep }>()
  const { values } = useLapeContext<RoleInterface>()
  const {
    settings: { candidates_enabled },
  } = useGlobalSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()
  const title = useMemo(() => {
    if (!id) {
      return (
        <FormattedMessage
          id="addEditRole.form.title.create"
          defaultMessage="Add new role"
        />
      )
    }
    return (
      <FormattedMessage
        id="addEditRole.form.title.edit"
        defaultMessage="Edit {name} role"
        values={{
          name: values.name || '',
        }}
      />
    )
  }, [])

  const withHiringProcess = Boolean(
    candidates_enabled && hiringProcessSettings?.enable_role_level_process_definition,
  )

  const tabs = useMemo(() => {
    return formTabs
      .filter(tab => {
        if (tab.key !== 'hiring-process') {
          return true
        }
        return withHiringProcess
      })
      .map((tab, idx) => {
        const path = pathToUrl(ROUTES.FORMS.ROLE.EDIT.ANY, { id, step: tab.key })
        const isCompleted = checkStepCompleted(tab.key, values)
        const isCurrent = step === tab.key

        return {
          ...tab,
          path,
          disabled: !values.id,
          url: path,
          preTitle: (
            <Box mr="s-8">
              <Badge
                size={16}
                bg={
                  isCurrent
                    ? Token.color.foreground
                    : isCompleted
                    ? Token.color.green
                    : Token.color.greyTone50
                }
              >
                {idx + 1}
              </Badge>
            </Box>
          ),
        }
      })
  }, [id, step, values, withHiringProcess])

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.ORGANISATION.ROLES.ROLES} />
      <Box mb="s-16">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.key} path={tab.path}>
            <PageBody
              maxWidthMd={
                tab.key === 'general-info' ? Token.breakpoint.md : Token.breakpoint.xxl
              }
            >
              <VStack gap="s-16">
                <tab.component />
              </VStack>
            </PageBody>
            <PageActions
              maxWidthMd={
                tab.key === 'general-info' ? Token.breakpoint.md : Token.breakpoint.xxl
              }
            >
              <FormActions stepKey={tab.key} withHiringProcess={withHiringProcess} />
            </PageActions>
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export const AddEditRole = connect(() => {
  return (
    <Form api={rolesRequestsNew}>
      <FormBody />
    </Form>
  )
})
