import * as Sentry from '@sentry/react'
import { decl } from '@src/utils/string'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  Duration,
  format,
  formatDistance,
  formatDuration,
  formatRelative,
  getYear,
  intervalToDuration,
  isDate,
  isSameDay,
  isSameYear,
  startOfToday,
} from 'date-fns'
import { format as formatWithTimeZone, utcToZonedTime } from 'date-fns-tz'
import round from 'lodash/round'
import { enGB } from 'date-fns/locale'
import {
  meetingRecurrence,
  rruleInterface,
  rruleRecurrence,
} from '@src/interfaces/meetingsTracker'

export const formatPeriod = (
  rowFrom: string | Date,
  rowTo: string | Date,
  options?: {
    hideYear?: boolean
    showOneDateIfStartAndEndDatesAreSame?: boolean
  },
) => {
  const fromDate = (isDate(rowFrom) ? rowFrom : new Date(rowFrom)) as Date
  const toDate = (isDate(rowTo) ? rowTo : new Date(rowTo)) as Date

  const formatDefault = 'd MMM'
  const formatWithYear = options?.hideYear ? formatDefault : 'd MMM yyyy'

  if (isDate(fromDate) && isDate(toDate)) {
    const sameYear = getYear(fromDate) === getYear(toDate)
    if (sameYear) {
      return `${
        options?.showOneDateIfStartAndEndDatesAreSame && isSameDay(fromDate, toDate)
          ? ''
          : `${format(fromDate, formatDefault)} - `
      }${format(toDate, formatWithYear)}`
    }
    return `${format(fromDate, formatWithYear)} - ${format(toDate, formatWithYear)}`
  }
  return 'Invalid date period'
}

export const getTotalTimeFromMinutes = (minutesAmount: number) => {
  const hours = Math.floor(minutesAmount / 60)
  const minutes = hours === 0 ? minutesAmount : minutesAmount % (hours * 60)

  if (hours === 0) {
    return `${minutes} min`
  }

  if (minutes === 0) {
    return `${hours} h`
  }

  return `${hours} h ${minutes} min`
}

export const formatMoney = (
  money?: number | string | null,
  currency: string = 'GBP',
  locale: string = 'en-US',
) => {
  if (!money || typeof money !== 'number') {
    money = 0
  }
  return money.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: money > 1000 ? 0 : 2,
  })
}

export const formatMoneyWithCode = (
  money?: number | string | null,
  currency?: string,
) => {
  if (!currency) {
    return '-'
  }
  if (!money) {
    money = 0
  }
  return `${money.toLocaleString()} ${currency}`
}

export const formatMoneyMillions = (
  money?: number | string,
  currency: string = 'GBP',
  locale: string = 'en-US',
) => {
  if (!money) {
    money = 0
  }
  money = parseFloat(money as string)
  if (money >= 1000000) {
    return `${(money / 1000000).toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}M`
  }
  if (money >= 10000) {
    return `${(money / 1000).toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}k`
  }

  return money.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export const formatNumber = (
  num?: number,
  precision?: number,
  locale: string = 'en-US',
) => {
  if (!num) {
    num = 0
  }

  const maximumFractionDigits = precision ?? (num > 1000 ? 0 : 2)

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(num)
}

export const formatPercentage = (num: number | null | undefined, precision?: number) => {
  if (num == null) {
    return 'N/A'
  }
  return `${round(num * 100, precision)}%`
}

export const formatNumberMillions = (
  num?: number,
  precision: number = 2,
  locale: string = 'en-US',
) => {
  if (!num) {
    num = 0
  }
  if (num >= 1_000_000_000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 1_000_000_000)}B`
  }
  if (num >= 1_000_000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 1_000_000)}M`
  }
  if (num >= 10_000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num / 1_000)}k`
  }
  if (num >= 1_000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(num / 1_000)}k`
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
  }).format(num)
}

export const timeLeft = (targetDate: string | null): string => {
  if (!targetDate) {
    return '0m left'
  }
  const target = new Date(targetDate)
  const now = new Date()

  const days = differenceInDays(target, now)
  const hours = differenceInHours(target, now) % 24
  const minutes = differenceInMinutes(target, now) % 60

  const parts: string[] = []
  if (days > 0) {
    parts.push(`${days}d`)
  }
  if (hours > 0) {
    parts.push(`${hours}h`)
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`)
  }

  return parts.length > 0 ? `${parts.join(' ')} left` : '0m left'
}

export const formatSecondsDuration = (seconds?: number) => {
  if (!seconds) {
    return 'N/A'
  }
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.round((seconds % 3600) / 60)
  return formatDuration(
    {
      hours,
      minutes,
    },
    {
      format: ['hours', 'minutes'],
    },
  )
}

export const calculatePercentageBetweenDates = (
  startDateStr: string | null,
  endDateStr: string | null,
) => {
  if (!startDateStr || !endDateStr) {
    return 0
  }
  // Parse the input date strings into Date objects
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)
  const now = new Date()

  if (startDate.getTime() >= now.getTime()) {
    return 0
  }
  if (now.getTime() >= endDate.getTime()) {
    return 1
  }

  const totalDuration = endDate.getTime() - startDate.getTime()

  const elapsedDuration = now.getTime() - startDate.getTime()

  return elapsedDuration / totalDuration
}

export const formatListLetters = (i: number) =>
  (i + 10 + 10 * Math.floor(i / 26)).toString(36)

export const formatDate = (
  date: string | Date,
  fmt?: string,
  options?: { hideSameYear?: boolean },
) =>
  format(
    new Date(date),
    fmt ||
      (options?.hideSameYear && isSameYear(new Date(), new Date(date))
        ? 'd MMM'
        : 'd MMM yyyy'),
  )

export const formatTimeAgo = (date: string | Date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

/**
 * @param date string representation of the date, in `YYYY-MM-DD` format, e.g. "2024-04-10"
 */
export const getDateFromDayString = (date: string) => {
  const dt = new Date(date)
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
}

// WARN: don't call this function on its returned value, this can cause
// accumulating DST shift, resulting in different outputs on every
// next call depending on date
export const formatWithoutTimezone = (date: string, withTime?: boolean) => {
  const schema = withTime ? 'd MMM yyyy HH:mm' : 'd MMM yyyy'
  const outFmtRegexp =
    /^(0[1-9]|[12][0-9]|3[01]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}( (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]))?$/

  if (outFmtRegexp.test(date)) {
    Sentry.captureException(
      'formatWithoutTimezone was probably called on its own output, this can cause date formatting inconsistencies',
      { extra: { date } },
    )
  }
  return format(getDateFromDayString(date), schema)
}

export const formatDateDistance = (date: Date) => {
  const diff = differenceInDays(date, startOfToday())
  const diffMonths = Math.ceil(diff / 30)
  if (diff <= 30) {
    const diffWeeks = Math.ceil(diff / 7) || 1
    if (diffWeeks > 3) {
      return '1 month'
    }
    return `${diffWeeks} ${decl(diffWeeks, 'week', 'weeks')}`
  }
  if (diffMonths >= 6) {
    return '6 months +'
  }
  return `${diffMonths} ${decl(diffMonths, 'month', 'months')}`
}

export const formatToOrdinal = (number: number) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (s[(v - 20) % 10] || s[v] || s[0])
}

export const formatDateTime = (date: Date | string | null) => {
  if (!date) {
    return ''
  }

  try {
    return format(new Date(date), 'd MMM yyyy, HH:mm')
  } catch {
    return ''
  }
}

/**
 * @param date server date (UTC)
 * @param format output format. Defaults to d MMM yyyy, HH:mm 'UTC'
 * @returns formatted date in UTC with suffix
 */
export const formatUTCDate = (
  date: string | null | Date | undefined,
  fmt?: string,
): string => {
  if (!date) {
    return ''
  }
  // It does need to be doubled timezoned as for some reason in doesn't really like formatting strings
  // as 2024-12-09T12:33:38.335821+00:00 to be in UTC timezone and keeps parsing it to local
  return formatWithTimeZone(
    utcToZonedTime(date, 'UTC'),
    fmt || "d MMM yyyy, HH:mm 'UTC'",
    {
      timeZone: 'UTC',
    },
  )
}

export const getDatesDuration = (
  startDate: string | Date,
  endDate: string | Date,
): Duration => {
  return intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  })
}

export const formatFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`
  }
  if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(1)}KB`
  }
  if (size >= 1048576) {
    return `${(size / 1048576).toFixed(1)}MB`
  }

  return ''
}

// new Date(undefined) is different from new Date() https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#passing_a_non-date_non-string_non-number_value
export const formatTime = (value?: string) =>
  format(value ? new Date(value) : new Date(), 'HH:mm')

export const formatRRule = (rrule?: string) => {
  if (!rrule || !rrule.length) {
    return meetingRecurrence.Custom
  }

  // formatting string of type "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;INTERVAL=2"
  // to { FREQ: "WEEKLY", BYDAY: ['MO', 'TU', 'WE', 'TH', 'FR'], INTERVAL: "2" }
  let rruleParams: rruleInterface = {}
  rrule
    .replace('RRULE:', '')
    .split(';')
    .map(paramString => {
      const [param, value] = paramString.split('=')
      return { [param]: value }
    })
    .forEach(el => {
      const [key, value] = Object.entries(el)[0]
      const splittedValue = value?.split(',')
      rruleParams = {
        ...rruleParams,
        [key]: splittedValue?.length > 1 ? splittedValue : value,
      }
    })

  let isRepeatedOnWeekdays = false
  const workingDays = 'FR,MO,TH,TU,WE'
  const weekDays = 'FR,MO,SA,SU,TH,TU,WE'
  if (
    Array.isArray(rruleParams.BYDAY) &&
    (rruleParams.BYDAY.length === 5 || rruleParams.BYDAY.length === 7)
  ) {
    const sortedByday = [...rruleParams.BYDAY].sort().join()
    if (sortedByday === workingDays || sortedByday === weekDays) {
      isRepeatedOnWeekdays = true
    }
  }

  let recurrence = meetingRecurrence.Custom

  if (rruleParams.FREQ === rruleRecurrence.DAILY && rruleParams.INTERVAL === '1') {
    return meetingRecurrence.Daily
  }

  if (rruleParams.FREQ === rruleRecurrence.WEEKLY && isRepeatedOnWeekdays) {
    return meetingRecurrence.Daily
  }

  if (rruleParams.FREQ === rruleRecurrence.WEEKLY && rruleParams.INTERVAL === '2') {
    return meetingRecurrence.BiWeekly
  }

  if (rruleParams.FREQ === rruleRecurrence.WEEKLY) {
    return meetingRecurrence.Weekly
  }

  if (rruleParams.FREQ === rruleRecurrence.MONTHLY) {
    return meetingRecurrence.Monthly
  }

  return recurrence
}

export const formatRelativeDate = (date: Date, baseDate = Date.now()) => {
  // formatRelative uses enUS by default but most users expect different formats
  // we use enGB override because it has formats most users expect
  const locale = navigator.language === 'en-US' ? undefined : enGB
  const options = locale ? { locale } : undefined
  return formatRelative(date, baseDate, options)
}
