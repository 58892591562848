import { format, utcToZonedTime } from 'date-fns-tz'
import { compareAsc } from 'date-fns'

export const getDateFromString = (value: string) => value?.split('T')[0]

export const getTimeFromString = (value: string) => value?.split('T')[1]?.slice(0, 5)

/**
 * Helper to get short datetime format from full date.
 * Short datetime format looks like: 2025-03-13T16:45
 */
export const getShortDateTimeFormat = (date: string): string => {
  const datePart = getDateFromString(date)
  const timePart = getTimeFromString(date)
  if (datePart && timePart) {
    return `${datePart}T${timePart}`
  }
  return date
}

/**
 * Helper to get full datetime format from potentially short datetime format
 */
export const getFullDateTimeFormat = (date: string): string => {
  if (date?.split('T')[1]?.length <= 5) {
    // it's not full format yet
    return `${date}:00.000Z`
  }
  return date
}

export const getTime = (date: Date) =>
  `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`

export const getDate = (date: Date) =>
  `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`

export const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getTimeZoneLabel = (
  date: string,
  timeZone: string,
  formatRule: string = 'hh:mm aa',
) => {
  return format(utcToZonedTime(new Date(date), timeZone), formatRule, {
    timeZone,
  })
}

/** Take the incoming date UTC string and convert it to the same day in user's local time zone */
export const utcToLocalDate = (date: string | Date) => {
  const dateObject = new Date(date)
  const year = dateObject.getUTCFullYear()
  const month = dateObject.getUTCMonth()
  const day = dateObject.getUTCDate()
  return new Date(year, month, day)
}

/** Take the incoming date and convert it to the same day in UTC */
export const localDateToUtc = (date: Date) => {
  const inUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return inUTC.toISOString()
}

/** Take date string with incorrect or non-specified time (e.g. '2024-11-01') and create new date object set to midnight */
export const dateStringToMidnightDate = (dateStr: string) => {
  const dateSubstring = getDateFromString(dateStr)

  if (!dateSubstring) {
    throw new Error('Could not get date value from string')
  }
  return new Date(`${getDateFromString(dateStr)}T00:00:00`)
}

export const getCurrentCountryCode = () => navigator.language?.split('-')?.[1]

export const isInDateRange = (from: Date, to: Date, value: Date): boolean =>
  compareAsc(value, from) > 0 && compareAsc(value, to) < 0
