import { probationCycleSettingsRequests } from '@src/api/probationEmployees'
import Form from '@src/features/Form/Form'
import React, { ComponentProps } from 'react'
import { TimelineFormBody } from './TimelineFormBody'

interface Props extends Omit<ComponentProps<typeof TimelineFormBody>, 'variant'> {
  cycleId: number
}

export const ProbationTimelineSettingsForm = ({ cycleId, ...formBodyProps }: Props) => {
  return (
    <Form
      api={probationCycleSettingsRequests}
      forceParams={{ id: String(cycleId) }}
      disableLocalStorageCaching
      disableDataCleanup
    >
      <TimelineFormBody variant="Probation" {...formBodyProps} />
    </Form>
  )
}
