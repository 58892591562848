import React from 'react'

import { BottomSheet, Button, Header } from '@revolut/ui-kit'
import { UpdateOrganisationUnitType } from '@src/interfaces/updateOrganisationStructure'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import { typeToName, typeToNamePlularised } from '../common'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { bulkEditSpecialisation } from '@src/api/specialisations'

import { IdAndName } from '@src/interfaces'
import { AxiosPromise } from 'axios'
import { BulkEditResponse } from '@src/interfaces/specialisations'

interface Props {
  type: UpdateOrganisationUnitType
  unitsToChange: { id: number }[]
  onSuccess: (data: BulkEditResponse) => void
  onClose: VoidFunction
}

const bulkEditResponse: Record<
  UpdateOrganisationUnitType,
  | undefined
  | ((ids: number[], fields: Record<string, number>) => AxiosPromise<BulkEditResponse>)
> = {
  // for now this is only enabled for specialisation but can be extended
  department: undefined,
  team: undefined,
  role: undefined,
  entity: undefined,
  specialisation: bulkEditSpecialisation,
}

export const ChangeOwnerPopup = ({ onClose, type, unitsToChange, onSuccess }: Props) => {
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()
  const submitFunction = bulkEditResponse[type]

  const parsedType =
    type === 'specialisation' && !isSpecialisationsEnabled ? 'role' : type

  return (
    <BottomSheet open onClose={onClose}>
      <Header>
        <Header.Title>
          <FormattedMessage
            id="updateOrganisationStructure.changeOwnerPopup.title"
            defaultMessage="Change {type} owner"
            values={{
              type: (
                <FormattedPlural
                  value={unitsToChange.length}
                  one={typeToName[parsedType]}
                  other={typeToNamePlularised[parsedType]}
                />
              ),
            }}
          />
        </Header.Title>
      </Header>
      <LapeForm<{ owner: IdAndName }>
        onSubmit={form => {
          if (submitFunction) {
            return submitFunction(
              unitsToChange.map(({ id }) => id),
              { owner: form.values.owner.id },
            ).then(response => {
              onSuccess(response.data)
              return form.values
            })
          }
          throw new Error(`change owner for ${type} not supported`)
        }}
      >
        <FormValidatorProvider>
          <AssignOwnerForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const AssignOwnerForm = () => {
  const { isSubmitting, submit } = useLapeContext<{ owner: IdAndName }>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <LapeRadioSelectInput
        name="owner"
        label="Select owner"
        selector={selectorKeys.employee}
      />
      <BottomSheet.Actions>
        <Button onClick={validate(() => submit())} pending={isSubmitting} elevated>
          Save
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
