import { useMemo } from 'react'
import { Color } from '@revolut/ui-kit'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { FinalGrade } from '@src/interfaces/performance'
import { MeetingsSummaryInterface, TemplateItemRole } from '@src/interfaces/meetings'
import { useGetSelectors } from '@src/api/selectors'
import { useGetOneToOneMeetingManagerTemplate } from '@src/api/meetings'
import { gradeSettings } from '@src/pages/EmployeeProfile/Layout/Performance/utils'

export interface GradeProps {
  label: string
  color: Color
}

interface Props {
  isActionItemsEnabled: boolean
  isPerformanceRatingEnabled: boolean
  isLoading: boolean
  gradeProps: GradeProps
}

export const useMeetingsSummary = (
  summary: MeetingsSummaryInterface,
  managerId: number,
): Props => {
  const { data: template, isLoading: isTemplateLoading } =
    useGetOneToOneMeetingManagerTemplate(managerId)

  const isActionItemsEnabled = !!template?.items.find(
    ({ role }) => role === TemplateItemRole.ActionItems,
  )
  const isPerformanceRatingEnabled = !!template?.items.find(
    ({ role }) => role === TemplateItemRole.PerformanceRating,
  )

  const { data: gradesOptions = [], isLoading: isGradesLoading } =
    useGetSelectors<PerformanceGradeOption>(selectorKeys.grades)

  const gradeLabel =
    gradesOptions.find(({ id }) => id === summary.performance_grade)?.name ??
    FinalGrade.None
  const { color: gradeColor } =
    gradeSettings[summary.performance_grade ?? FinalGrade.None]

  return useMemo<Props>(
    () => ({
      isLoading: isTemplateLoading || isGradesLoading,
      isActionItemsEnabled,
      isPerformanceRatingEnabled,
      gradeProps: {
        label: gradeLabel,
        color: gradeColor,
      },
    }),
    [
      isTemplateLoading,
      isGradesLoading,
      isActionItemsEnabled,
      isPerformanceRatingEnabled,
      gradeLabel,
      gradeColor,
    ],
  )
}
