import { BottomSheet, Box, Button, Header as UIKitHeader } from '@revolut/ui-kit'
import React from 'react'

const Header = ({
  title,
  subtitle,
  children,
}: React.PropsWithChildren<{ title: string; subtitle: string }>) => {
  return (
    <UIKitHeader variant="bottom-sheet">
      <UIKitHeader.Title>{title}</UIKitHeader.Title>
      <UIKitHeader.Subtitle>
        <Box mt="s-8">{subtitle}</Box>
      </UIKitHeader.Subtitle>
      {children}
    </UIKitHeader>
  )
}

export const ConfirmDeletePopup = ({
  name,
  open,
  onConfirm,
  onCancel,
}: {
  open: boolean
  name?: string
  onConfirm: () => void
  onCancel: () => void
}) => {
  if (!open) {
    return null
  }
  const title = name ? `Delete ${name}?` : 'Delete entity?'
  return (
    <BottomSheet open onClose={onCancel}>
      <Header
        title={title}
        subtitle="Deleting this item might affect other objects that reference it. You will not be
          able to delete this item while there are existing dependencies."
      />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Yes
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
