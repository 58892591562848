import React from 'react'

import { contractorsRequests, employeesRequestsNew } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import {
  NameAndWorkDetailsFormContent,
  NameAndWorkDetailsFormContentProps,
} from './FormContent'

export const NameAndWorkDetails = (
  props: Omit<NameAndWorkDetailsFormContentProps, 'type'>,
) => {
  const type = props.data.employee_type

  return type === 'external' ? (
    <Form api={contractorsRequests}>
      <NameAndWorkDetailsFormContent {...props} type={type} />
    </Form>
  ) : (
    <Form api={employeesRequestsNew}>
      <NameAndWorkDetailsFormContent {...props} type={type} />
    </Form>
  )
}
