import {
  ProbationTemplateRulesInterface,
  ResultOptionType,
} from '@src/interfaces/probationTemplate'

export const templateResultToOutcomeLabel: Record<ResultOptionType, string> = {
  passed: 'Pass',
  change_seniority: 'Pass with lower seniority',
  failed: 'Fail',
  extend: 'Extend',
}

export const templateRuleKeyToLabel: Record<
  keyof ProbationTemplateRulesInterface,
  string
> = {
  seniority_id: 'Seniority',
  team_id: 'Team',
  team__department_id: 'Department',
  specialisation_id: 'Specialisation',
  position_id: 'Position',
  position__function_id: 'Function',
  employee_type: 'Employee type',
  contract_type: 'Contract type',
  location_id: 'Location',
  entity_id: 'Entity',
}
