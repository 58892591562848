import React from 'react'
import {
  Box,
  HStack,
  Icon,
  Lightbox,
  Spacer,
  Text,
  TravelWidget,
  useToggle,
} from '@revolut/ui-kit'
import { Video } from '@src/components/Video/Video'
import { TravelWidgetProps } from '@revolut/ui-kit/types'

type VideoProps = Parameters<typeof Video>[0]

interface HelperVideoWidgetProps {
  preview: TravelWidgetProps['image']
  poster: VideoProps['poster']
  video: VideoProps['url']
}
export const HelperVideoWidget = ({ preview, poster, video }: HelperVideoWidgetProps) => {
  const [open, toggle] = useToggle()

  return (
    <>
      <TravelWidget use="div" variant="large" image={preview}>
        <TravelWidget.Details use="button" onClick={toggle.on}>
          <HStack align="center">
            <Text>Watch video</Text>
            <Spacer />
            <Icon name="ChevronRight" />
          </HStack>
        </TravelWidget.Details>
      </TravelWidget>
      <Lightbox open={open} onClose={toggle.off}>
        <Lightbox.Item>
          <Box maxWidth="75%">
            <Video poster={poster} url={video} autoPlay />
          </Box>
        </Lightbox.Item>
      </Lightbox>
    </>
  )
}
