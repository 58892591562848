import React from 'react'
import { Avatar, Box, Group, Item } from '@revolut/ui-kit'
import { useGetSkillsSettings } from '@src/api/settings'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CompetencyMatrixInterface,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { PermissionTypes } from '@src/store/auth/types'

interface Props {
  role: RoleInterface | undefined
  defaultCultureSkills: CompetencyMatrixInterface[]
}

export const CultureFitSkillsWidget = ({ role, defaultCultureSkills }: Props) => {
  const { data: skillsSettings } = useGetSkillsSettings()
  const { values } = useLapeContext<SpecialisationInterface>()

  const canEdit =
    !values.id ||
    [PermissionTypes.AddSpecialisation, PermissionTypes.ChangeSpecialisation].some(p =>
      values.field_options?.permissions?.includes(p),
    )

  return !skillsSettings?.behaviours_assessment_enabled ? (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="TurboTransfer" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Culture fit skills</Item.Title>
          <Item.Description>
            Culture fit competency matrix is defined at company level. You cannot make any
            change here
          </Item.Description>
        </Item.Content>
        <Item.Side />
      </Item>
      {!!role && (
        <Box>
          <CompetencyMatrixTable
            competencyMatrices={[
              {
                sectionTitle: 'Deliverables',
                children: defaultCultureSkills,
                disabled: true,
                hideActionsColumn: true,
              },
            ]}
            firstRowTitle="Deliverables"
            minSeniority={values.seniority_min}
            maxSeniority={values.seniority_max}
            readOnly={!canEdit}
          />
        </Box>
      )}
    </Group>
  ) : null
}
