import { PayrollTimelineChangeInterface } from '@src/interfaces/payrollV2'
import { sortByDate } from '@src/utils/sortByDate'

const getChangeDomainKey = (change: PayrollTimelineChangeInterface) =>
  `${change.domain_category} ${change.domain_name}`

export const isLatestDomainChange = (
  change: PayrollTimelineChangeInterface,
  allChanges: PayrollTimelineChangeInterface[] = [],
) => {
  const domainChanges = allChanges.filter(
    allChangesItem => getChangeDomainKey(allChangesItem) === getChangeDomainKey(change),
  )
  const sortedChanges = sortByDate<PayrollTimelineChangeInterface>(
    domainChanges,
    'effective_date_time',
  )
  return change.effective_date_time === sortedChanges[0]?.effective_date_time
}
