import React, { useMemo } from 'react'

import { OnboardingBulkDataUpload } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import {
  ImportCandidatesCellOptions,
  importCandidatesFullNameColumn,
  importCandidatesRequisitionColumn,
  importCandidatesSpecialisationColumn,
  importCandidatesSeniorityColumn,
  importCandidatesOriginColumn,
  importCandidatesEmailColumn,
  importCandidatesCountryColumn,
  importCandidatesCurrentCompanyColumn,
  importCandidatesRecruiterColumn,
  importCandidatesHiringManagerColumn,
  importCandidatesJobPostingColumn,
  importCandidatesPhoneColumn,
  importCandidatesLinkedInUrlColumn,
  importCandidatesPreferredWorkLocationColumn,
  importCandidatesSourcerColumn,
  importCandidatesNotesColumn,
} from '@src/constants/columns/importCandidates'
import { fields, originOptions } from './sessionConfig'
import { useOptions } from '@src/pages/Forms/ImportData/Candidates/config'
import { ROUTES } from '@src/constants/routes'
import { candidatesConfig } from '../common/constants'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { ImportCandidatesDataInterface } from '@src/interfaces/importCandidates'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const candidateRow = (
  options: ImportCandidatesCellOptions,
  specialisationsEnabled: boolean,
): Partial<RowInterface<ImportInterface<ImportCandidatesDataInterface>>> => ({
  cells: [
    {
      ...importCandidatesFullNameColumn,
      width: 150,
    },
    {
      ...importCandidatesRequisitionColumn(options),
      width: 150,
    },
    {
      ...importCandidatesSpecialisationColumn(options, specialisationsEnabled),
      width: 150,
    },
    {
      ...importCandidatesSeniorityColumn(options),
      width: 150,
    },
    {
      ...importCandidatesOriginColumn(options),
      width: 150,
    },
    {
      ...importCandidatesEmailColumn,
      width: 150,
    },
    {
      ...importCandidatesCountryColumn,
      width: 150,
    },
    {
      ...importCandidatesCurrentCompanyColumn,
      width: 150,
    },
    {
      ...importCandidatesRecruiterColumn(options),
      width: 150,
    },
    {
      ...importCandidatesHiringManagerColumn(options),
      width: 150,
    },
    {
      ...importCandidatesJobPostingColumn(options),
      width: 150,
    },
    {
      ...importCandidatesPhoneColumn,
      width: 150,
    },
    {
      ...importCandidatesLinkedInUrlColumn,
      width: 150,
    },
    {
      ...importCandidatesPreferredWorkLocationColumn,
      width: 150,
    },
    {
      ...importCandidatesSourcerColumn(options),
      width: 150,
    },
    {
      ...importCandidatesNotesColumn,
      width: 150,
    },
  ],
})

export const CandidatesBulkUpload = () => {
  const options = useOptions(originOptions)
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const row = useMemo(
    () => candidateRow(options, specialisationsEnabled),
    [options, specialisationsEnabled],
  )
  return (
    <OnboardingBulkDataUpload
      importRoute={ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.UPLOAD.IMPORT}
      previousRoute={ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.COMMUNICATION}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.ANY}
      apiEndpoint={API.CANDIDATES_UPLOAD}
      config={candidatesConfig}
      currentStep="Upload candidates"
      category="candidate_bulk_upload"
      name="candidate"
      row={row}
      tableName={TableNames.ImportCandidates}
      fields={fields}
      recommendedLabel="yes"
      yesByDefault
    />
  )
}
