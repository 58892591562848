import React from 'react'
import { VStack, Widget, Text, Icon, TravelWidget, HStack, Spacer } from '@revolut/ui-kit'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import { FormattedMessage } from 'react-intl'
import { CopyUrlToClipboard } from '../CopyUrlToClipboard'

const REDIRECT_URL = 'https://revolutpeople.com/public/subdomain-permissions-redirect'

interface Props {
  setLightBoxSrc: (src: { video: string; poster: string } | undefined) => void
}

export const SalesforceHelperContent = ({ setLightBoxSrc }: Props) => {
  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Log in to your <Text fontWeight={700}>Salesforce account</Text>, go to
                Settings and click <Text fontWeight={700}>App manager</Text> in{' '}
                <Text fontWeight={700}>Platform tools</Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Click <Text fontWeight={700}>New Connected App</Text>, then{' '}
                <Text fontWeight={700}>Create a Connected App</Text>
              </Text>
            }
            subtitle="You need to be an admin or owner in order to do this"
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Enter app's basic detail, check{' '}
                <Text fontWeight={700}>Enable OAuth Settings</Text>, then ented following
                URL in
                <Text fontWeight={700}>Callback URL</Text>
              </Text>
            }
            subtitle={
              <CopyUrlToClipboard
                url={REDIRECT_URL}
                successText="redirect URL copied to clipboard"
              />
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Add following <Text fontWeight={700}>Available OAuth Scopes</Text>
              </Text>
            }
            subtitle={
              <VStack gap="s-2">
                <Text variant="small">
                  Access Analytics REST API resources (wave_api)
                </Text>
                <Text variant="small">Manage user data via APIS (api)</Text>
                <Text variant="small">Manage user data via Web browsers (web)</Text>
                <Text variant="small">
                  Perform requests at any time (refresh_token, offline_access)
                </Text>
                <Text variant="small">
                  Access the identity URL service (Id, profile, email. address, phone)
                </Text>
                <Text variant="small">Full access (full)</Text>
                <Text variant="small">
                  Access Analytics REST API resources (wave_api)
                </Text>
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <FormattedMessage
                id="integrations.salesforce.helper.stepper.5.title"
                defaultMessage={`Enable <T>Token Exchange Flow</T> and <T>Refresh Token Rotation</T>`}
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Disable{' '}
                <Text fontWeight={700}>
                  Require Proof Key for Code Exchange (PKCE) Extension for Supported
                  Authorization Flows
                </Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Press <Text fontWeight={700}>save</Text>, then{' '}
                <Text fontWeight={700}>continue</Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <FormattedMessage
                id="integrations.salesforce.helper.stepper.8.title"
                defaultMessage={`Click <T>Manage Consumer details</T> to obtain Consumer Key and Consumer Secret`}
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
          />
        </AutoStepper>
      </Widget>

      <TravelWidget
        use="div"
        variant="large"
        image={[
          'https://assets.revolut.com/revoluters/assets/integrations/salesforce_integration_help_preview.png',
        ]}
      >
        <TravelWidget.Details
          use="button"
          onClick={() =>
            setLightBoxSrc({
              video:
                'https://assets.revolut.com/revoluters/assets/integrations/salesforce_integration_help.mp4',
              poster:
                'https://assets.revolut.com/revoluters/assets/integrations/salesforce_integration_help_preview.png',
            })
          }
        >
          <HStack align="center">
            <Text>Watch video</Text>
            <Spacer />
            <Icon name="ChevronRight" />
          </HStack>
        </TravelWidget.Details>
      </TravelWidget>
    </VStack>
  )
}
