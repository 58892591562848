import React, { useEffect } from 'react'

import SideBar from '@src/components/SideBar/SideBar'
import { selectorKeys } from '@src/constants/api'
import Form from '@src/features/Form/Form'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationTemplateInterface,
  ProbationTimeUnits,
} from '@src/interfaces/probationTemplate'
import { connect } from 'lape'
import { probationTemplateRequests } from '@src/api/probationTemplate'
import { IdAndName } from '@src/interfaces'
import { useGetSelectors } from '@src/api/selectors'
import { ProbationTemplateFormBody } from './ProbationTemplateFormBody'

interface Props {
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
}

const FormBody = connect((props: Props) => {
  const { values } = useLapeContext<ProbationTemplateInterface>()
  const { data: timeUnits } = useGetSelectors<IdAndName<ProbationTimeUnits>>(
    selectorKeys.probation_template_time_units,
  )
  const { data: entitySelector } = useGetSelectors<IdAndName>(selectorKeys.entity)

  useEffect(() => {
    if (timeUnits?.length) {
      values.duration = 3
      values.duration_time_unit = timeUnits.at(-1) || timeUnits[0] // default should be months that should be last from BE
      values.notice_period = 1
      values.notice_period_time_unit = timeUnits.at(1) || timeUnits[0] // default should be weeks that should be 2nd from BE
    }
  }, [timeUnits])

  useEffect(() => {
    if (entitySelector?.length) {
      values.entity = entitySelector[0]
    }
  }, [entitySelector])

  return <ProbationTemplateFormBody {...props} />
})

export const CreateProbationTemplateSidebar = ({ onClose, onAfterSubmit }: Props) => {
  return (
    <SideBar title="Add country template" onClose={onClose} isOpen>
      <Form
        isExistingForm={false}
        disableLocalStorageCaching
        initialValues={{ status: 'enabled' }}
        api={probationTemplateRequests}
      >
        <FormBody onClose={onClose} onAfterSubmit={onAfterSubmit} />
      </Form>
    </SideBar>
  )
}
