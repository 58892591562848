import React, { useEffect, useState } from 'react'
import { ArrowThinLeft, CrossSmall } from '@revolut/icons'
import {
  Box,
  IconButton,
  Layout,
  Overlay,
  Search,
  Sticky,
  VStack,
  H4,
  Flex,
  Token,
} from '@revolut/ui-kit'

import { FaqInterface } from '@src/interfaces/faq'
import {
  useFiltersState,
  useNavigationBreadcrumbs,
  useOpenHelpCenter,
  useRenderSidebarContent,
  useTitle,
} from './hooks'
import { useQuery } from '@src/utils/queryParamsHooks'
import { SidebarView } from '@components/HelpCenter/types'
import { defaultTheme } from '@src/styles/theme'

export const HelpCenter = () => {
  const [question, setQuestion] = useState<FaqInterface>()
  const [searchValue, setSearchValue] = useState('')
  const [sidebarView, setSidebarView] = useState<SidebarView>()

  const { query } = useQuery<{ help: 'categories' | 'chat' }>()
  const isOpen = !!query.help

  const filtersState = useFiltersState(searchValue)
  const { category, topic } = filtersState

  useEffect(() => {
    if (query.help === 'categories') {
      setSidebarView('categories')
      setSearchValue('')
      category.setValue(undefined)
      topic.setValue(undefined)
    } else if (query.help === 'chat') {
      setSidebarView('chatbot')
      setSearchValue('')
      category.setValue(undefined)
      topic.setValue(undefined)
    } else if (!!query.help && !sidebarView) {
      setSidebarView('categories')
    }
  }, [query.help])

  const { closeHelpCenter } = useOpenHelpCenter()

  const viewsWithoutSearch = ['answer', 'chatbot', 'rate-experience']
  const showSearchInput = sidebarView && !viewsWithoutSearch.includes(sidebarView)

  const title = useTitle(sidebarView, question)
  const breadcrumbs = useNavigationBreadcrumbs(
    filtersState,
    sidebarView,
    setSidebarView,
    setSearchValue,
  )
  const sidebarContent = useRenderSidebarContent(
    filtersState,
    sidebarView,
    setSidebarView,
    question,
    setQuestion,
    closeHelpCenter,
    searchValue,
    setSearchValue,
  )

  const showCloseButton = sidebarView === 'chatbot'

  return (
    <Overlay closeOnEsc open={isOpen} onExited={closeHelpCenter}>
      <Layout data-testid="help-center-layout">
        <Layout.Side />
        <Layout.Main>
          <Sticky
            style={{ zIndex: defaultTheme.zIndex.main }}
            top={0}
            pt="s-16"
            bg={Token.color.layoutBackground}
            variant="bottom-sheet"
          >
            <VStack space="s-16">
              <Flex
                alignItems="center"
                justifyContent={showCloseButton ? 'space-between' : 'flex-start'}
              >
                <IconButton
                  useIcon={ArrowThinLeft}
                  aria-label="Help center go back"
                  onClick={() => {
                    switch (sidebarView) {
                      case 'chatbot':
                        if (question) {
                          setSidebarView('answer')
                        } else {
                          setSidebarView('categories')
                        }
                        break
                      case 'answer':
                        setSidebarView('questions')
                        break
                      case 'questions':
                        setSidebarView('topics')
                        break
                      case 'topics':
                        setSidebarView('categories')
                        break
                      case 'categories':
                      case 'rate-experience':
                        closeHelpCenter()
                        break
                    }
                  }}
                />
                {showCloseButton && (
                  <Box pt="s-4">
                    <IconButton useIcon={CrossSmall} onClick={closeHelpCenter} />
                  </Box>
                )}
              </Flex>
              <H4>{title}</H4>
              <Box pb="s-24" data-testid="breadcrumbs">
                {breadcrumbs}
              </Box>
            </VStack>
          </Sticky>
          <VStack height="100%" gap="s-12">
            {showSearchInput && (
              <Box mb="s-12">
                <Search
                  aria-label="search-help"
                  value={searchValue}
                  placeholder={
                    sidebarView === 'questions'
                      ? 'Write your question'
                      : 'Write your question or topic'
                  }
                  onChange={newValue => {
                    setSearchValue(newValue)
                  }}
                />
              </Box>
            )}
            {sidebarContent}
          </VStack>
        </Layout.Main>
      </Layout>
    </Overlay>
  )
}
