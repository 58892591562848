import React from 'react'
import { Box, Flex, HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'

import { EngagementResultsAverageScoreInterface } from '@src/features/Engagement/api/analytics/interfaces'
import { formatPercentage } from '@src/utils/format'
import { getAmbivalentsRate } from '@src/features/Engagement/helpers/getAmbivalentsRate'

interface Props {
  averageScore: EngagementResultsAverageScoreInterface
}
export const Summary = ({ averageScore }: Props) => (
  <Box p="s-4" minWidth={200}>
    <VStack>
      <Flex justifyContent="space-between">
        <HStack space="s-8" align="center">
          <Icon name="SocialLike" size={16} color={Token.color.green} />
          <Text>Promoters</Text>
        </HStack>
        <Text>
          {averageScore.promoters} ({formatPercentage(averageScore.promoters_rate)})
        </Text>
      </Flex>
      <Flex justifyContent="space-between">
        <HStack space="s-8" align="center">
          <Icon name="LikeDislike" size={16} color={Token.color.yellow} />
          <Text>Passives</Text>
        </HStack>
        <Text>
          {averageScore.passives} ({formatPercentage(averageScore.passives_rate)})
        </Text>
      </Flex>
      <Flex justifyContent="space-between">
        <HStack space="s-8" align="center">
          <Icon name="SocialDislike" size={16} color={Token.color.red} />
          <Text>Detractors</Text>
        </HStack>
        <Text>
          {averageScore.detractors} ({formatPercentage(averageScore.detractors_rate)})
        </Text>
      </Flex>
      <Flex justifyContent="space-between">
        <HStack space="s-8" align="center">
          <Icon name="QuestionOutline" size={16} color={Token.color.greyTone50} />
          <Text>Don't know</Text>
        </HStack>
        <Text>
          {averageScore.ambivalents} ({formatPercentage(getAmbivalentsRate(averageScore))}
          )
        </Text>
      </Flex>
    </VStack>
  </Box>
)
