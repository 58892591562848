import { apiV2 } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  BulkEditResponse,
  EstimatedRequisitionAnalytics,
  RecruitmentAnalytics,
  SpecialisationLocations,
} from '@src/interfaces/specialisations'
import { AxiosResponse } from 'axios'
import {
  GetRequestData,
  RequestInterface,
  RequestInterfaceNew,
  tableRequests,
} from '@src/interfaces'
import { SeniorityInterface, SenioritySublevelInterface } from '@src/interfaces/seniority'
import { useFetch, useFetchV2, useUpdate } from '@src/utils/reactQuery'
import { LocationInterface } from '@src/interfaces/requisitions'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  CompetencyMatrixInterface,
  SpecialisationCandidateArchetypeInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import {
  getNormalizedCompetencyMatrix,
  getNormalizedDeliverablesCompetencyMatrix,
  getNormalizedFuncCompetencyMatrix,
} from '@src/features/CompetencyMatrixTable/utils'
import { getTalentStats } from '@src/api/talent'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { isNumber, omit } from 'lodash'

export const getRecruitmentAnalytics = (id: number) => {
  return apiV2.get<RecruitmentAnalytics>(
    `${API.SPECIALISATIONS}/${id}/recruitmentAnalytics`,
  )
}

export const calcEstimatedRequisitionAnalytics = (id: number, headcount: number) => {
  return apiV2.post<EstimatedRequisitionAnalytics>(
    `${API.SPECIALISATIONS}/${id}/estimatedLastRequisitionAnalyticsFields`,
    { headcount },
  )
}

export const getSpecialisationSeniorities = async (
  id: number | string,
): Promise<AxiosResponse<SeniorityInterface[]>> =>
  apiV2.get(`${API.SPECIALISATIONS}/${id}/seniorities`)

export const useGetSpecialisationSeniorities = (id: number | string | null) => {
  const url = `${API.SPECIALISATIONS}/${id}/seniorities`
  return useFetch<SeniorityInterface[]>(id ? url : null)
}

export const useGetSpecialisationPreferredHiringLocations = (
  specialisationId?: number | string,
) =>
  useFetch<LocationInterface[]>(
    specialisationId
      ? `${API.SPECIALISATIONS}/${specialisationId}/preferredHiringLocations`
      : null,
  )

export const getSpecialisationPreferredHiringLocations = (
  specialisationId: number | string,
) =>
  apiV2.get<LocationInterface[]>(
    `${API.SPECIALISATIONS}/${specialisationId}/preferredHiringLocations`,
  )

export const useGetSpecialisationLocations = (id?: number | string) => {
  const url = `${API.SPECIALISATIONS}/${id}/locations`
  return useFetch<SpecialisationLocations>(id ? url : null)
}

export const getSpecialisationSublevels = (specialisationId: number) =>
  apiV2.get<GetRequestData<SenioritySublevelInterface>>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels`,
  )

export const renameSenioritySublevel = (
  specialisationId: number,
  sublevelId: number,
  newTitle: string,
) =>
  apiV2.patch<SenioritySublevelInterface>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels/${sublevelId}`,
    { job_title: newTitle },
  )

export const updateHiringProcessRounds = (
  specialisationId: number,
  hiring_process_rounds: HiringProcessInterface[],
) =>
  apiV2.patch(
    `${API.SPECIALISATIONS}/${specialisationId}`,
    {
      hiring_process_rounds,
    },
    {
      params: {
        step: 'hiring_process',
      },
    },
  )

export const useGetSpecialisation = (specialisationId?: number | string) =>
  useFetchV2<SpecialisationInterface>({
    url: `${API.SPECIALISATIONS}/${specialisationId}`,
    queryOptions: {
      enabled: specialisationId != null && specialisationId !== '',
    },
  })

export const bulkEditSpecialisation = (ids: number[], fields: Record<string, number>) =>
  apiV2.patch<BulkEditResponse>(`${API.SPECIALISATIONS}/bulkEdit`, {
    ids,
    fields,
  })

export const bulkDeleteSpecialisations = (ids: number[]) =>
  apiV2.post(`${API.SPECIALISATIONS}/bulkDelete`, { ids })

export const specialisationsRequests: RequestInterface<SpecialisationInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(API.SPECIALISATIONS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => apiV2.get(`${API.SPECIALISATIONS}/${id}`),
  patchItem: async (data, id) => apiV2.patch(`${API.SPECIALISATIONS}/${id}`, data),
  deleteItem: async id => apiV2.delete(`${API.SPECIALISATIONS}/${id}`),
  postItem: async data => apiV2.post(API.SPECIALISATIONS, data),
}

export const specialisationsRequestsNew: RequestInterfaceNew<SpecialisationInterface> = {
  get: async ({ id }) =>
    apiV2.get<SpecialisationInterface>(`${API.SPECIALISATIONS}/${id}`),
  update: async (diff, { id }, data) => {
    const values = {
      ...diff,
    }

    if (diff.seniority_min || diff.seniority_max) {
      values.functional_competency_matrix = getNormalizedFuncCompetencyMatrix(data)
      values.deliverables_competencies =
        getNormalizedDeliverablesCompetencyMatrix(data)?.competencies
    }

    return apiV2.patch(`${API.SPECIALISATIONS}/${id}`, values)
  },
  submit: async data => {
    const values = {
      ...data,
      functional_competency_matrix: getNormalizedFuncCompetencyMatrix(data),
      deliverables_competencies:
        getNormalizedDeliverablesCompetencyMatrix(data)?.competencies,
    }

    return data?.id
      ? apiV2.patch(`${API.SPECIALISATIONS}/${data.id}`, values)
      : apiV2.post(API.SPECIALISATIONS, values)
  },
  delete: async ({ id }) => apiV2.delete(`${API.SPECIALISATIONS}/${id}`),
}

export const omitSpecialisationCompetencyMatrixV1Fields = (
  data: Partial<SpecialisationInterface>,
) =>
  omit(data, [
    'functional_competency_matrix',
    'performance_weights',
    'deliverables_competencies',
  ])

export const specialisationsRequestsV2: RequestInterfaceNew<SpecialisationInterface> = {
  get: async ({ id }) =>
    apiV2.get<SpecialisationInterface>(`${API.SPECIALISATIONS}/${id}`),
  update: async (diff, { id }, data) => {
    const values = omitSpecialisationCompetencyMatrixV1Fields(diff)

    if (diff.seniority_min || diff.seniority_max) {
      values.competency_matrix = getNormalizedCompetencyMatrix(data)
    }

    return apiV2.patch(`${API.SPECIALISATIONS}/${id}`, values)
  },
  submit: async data => {
    const values = omitSpecialisationCompetencyMatrixV1Fields(data)

    values.competency_matrix = getNormalizedCompetencyMatrix(data)

    return data?.id
      ? apiV2.patch(`${API.SPECIALISATIONS}/${data.id}`, values)
      : apiV2.post(API.SPECIALISATIONS, values)
  },
  delete: async ({ id }) => apiV2.delete(`${API.SPECIALISATIONS}/${id}`),
}

export const specialisationTalentTabStats = (id: string) =>
  getTalentStats('specialisation__id', id)
export const updateSpecialisation = (
  id: number,
  data: Partial<SpecialisationInterface>,
) => apiV2.patch(`${API.SPECIALISATIONS}/${id}`, data)

export const useUpdateSpecialisation = () =>
  useUpdate<SpecialisationInterface, SpecialisationInterface>(
    API.SPECIALISATIONS,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )

export const specialisationHiringStagesRequests = (
  id?: number,
): tableRequests<HiringProcessInterface, undefined> => ({
  getItems: ({ sortBy, filters, page }) =>
    apiV2.get(`${API.SPECIALISATIONS}/${id}/specialisationHiringStages`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const updateSpecialisationCompetencyMatrix = (
  id: number,
  data: CompetencyMatrixInterface[],
) =>
  specialisationsRequestsNew.update(
    { functional_competency_matrix: data },
    { id: String(id) },
  )

export const useGetSpecialisationArchetype = (specialisationId: number | undefined) =>
  useFetchV2<GetRequestData<SpecialisationCandidateArchetypeInterface>>({
    url: `${API.CANDIDATE_ARCHETYPE}`,
    params: { params: { specialisation: specialisationId } },
    queryOptions: {
      enabled: isNumber(specialisationId),
    },
  })

export const useGetLastSpecialisationArchetype = (
  specialisationId: number | undefined,
) => {
  const result = useGetSpecialisationArchetype(specialisationId)
  return { ...result, data: result?.data?.results?.at(-1) }
}

export const candidateArchetypeRequests: RequestInterfaceNew<SpecialisationCandidateArchetypeInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.CANDIDATE_ARCHETYPE}/${id}`),
    update: async (data, { id }) => apiV2.patch(`${API.CANDIDATE_ARCHETYPE}/${id}`, data),
    submit: async data => apiV2.post(API.CANDIDATE_ARCHETYPE, data),
    delete: async ({ id }) => apiV2.delete(`${API.CANDIDATE_ARCHETYPE}/${id}`),
  }
