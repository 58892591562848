import React from 'react'
import { Flex, VStack } from '@revolut/ui-kit'
import { MetricHighlightSkeleton } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/MetricHighlight'
import { SourceItemSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/SourceItem'

export const SourceSkeleton = () => (
  <VStack>
    <SourceItemSkeleton />
    <VStack space="s-8" p="s-16">
      <Flex gap="s-8" flexWrap="wrap">
        <MetricHighlightSkeleton flex={1} />
        <MetricHighlightSkeleton flex={1} />
      </Flex>
      <Flex gap="s-8" flexWrap="wrap">
        <MetricHighlightSkeleton flex={1} />
        <MetricHighlightSkeleton flex={1} />
      </Flex>
    </VStack>
  </VStack>
)
