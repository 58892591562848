import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { BottomSheet, Button, Header, InputGroup, Skeleton } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PermissionTypes } from '@src/store/auth/types'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useSeniorityRange } from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/useSeniorityRange'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface Props {
  onClose: VoidFunction
}

export const AllowedSenioritiesPopup = ({ onClose }: Props) => {
  const { values, initialValues } = useLapeContext<SpecialisationInterface>()
  const canEdit =
    !values.id ||
    [PermissionTypes.AddSpecialisation, PermissionTypes.ChangeSpecialisation].some(p =>
      values.field_options?.permissions?.includes(p),
    )

  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()

  const { seniorities, loading } = useSeniorityRange({
    ignoreParentRoleSeniorityRange: !isSpecialisationsEnabled,
  })

  return (
    <BottomSheet open onClose={onClose}>
      <Header>
        <Header.Title>Allowed seniorities</Header.Title>
        <Header.Subtitle>{values.name}</Header.Subtitle>
      </Header>
      {loading ? (
        <InputGroup>
          <Skeleton height="s-48" />
          <Skeleton height="s-48" />
        </InputGroup>
      ) : (
        <InputGroup variant="vertical">
          <LapeRadioSelectInput<SeniorityInterface>
            label="Lowest Seniority"
            options={seniorities.map(option => ({
              label: option.name,
              value: option,
            }))}
            disabled={!canEdit}
            clearable={false}
            name="seniority_min"
          />
          <LapeRadioSelectInput<SeniorityInterface>
            label="Highest Seniority"
            name="seniority_max"
            options={seniorities.map(option => ({
              label: option.name,
              value: option,
            }))}
            disabled={!canEdit}
            clearable={false}
          />
        </InputGroup>
      )}
      <BottomSheet.Actions>
        <Button
          onClick={e => {
            e.preventDefault()
            values.seniority_max = initialValues.seniority_max
            values.seniority_min = initialValues.seniority_min
            onClose()
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          elevated
          onClick={e => {
            e.preventDefault()
            onClose()
          }}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
