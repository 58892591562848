import React, { useRef } from 'react'
import { IconButton, Token } from '@revolut/ui-kit'
import { uploadFile } from '@src/api/files'
import { FileInterface } from '@src/interfaces/files'
import { getStringMessageFromError } from '@src/store/notifications/actions'

import { FilesFailedType } from './ChatTextEditor'

export interface FileUploaderProps {
  category?: string
  setFilesFailed: React.Dispatch<React.SetStateAction<FilesFailedType[]>>
  setFilesUploaded: React.Dispatch<React.SetStateAction<FileInterface[]>>
}

export const FileUploader = ({
  category = 'generic_comment_attachment',
  setFilesFailed,
  setFilesUploaded,
}: FileUploaderProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleFilesChange = (fileList: FileList) => {
    const files = Array.from(fileList)

    files.forEach(file => {
      uploadFile(file, category, true)
        .then(({ data }) => {
          setFilesUploaded(prev => [data, ...prev])
        })
        .catch(e => {
          setFilesFailed(prev => [
            {
              error: getStringMessageFromError(e),
              file,
              tempId: Math.random(),
            },
            ...prev,
          ])
        })
    })
  }

  return (
    <>
      <IconButton
        aria-label="Upload file"
        color={Token.color.blue}
        onClick={() => hiddenFileInput.current?.click()}
        useIcon="Clip"
      />
      <input
        hidden
        multiple
        onChange={e => {
          if (e.target.files) {
            handleFilesChange(e.target.files)
          }
        }}
        ref={hiddenFileInput}
        type="file"
      />
    </>
  )
}
