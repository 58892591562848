import { useMemo } from 'react'
import { PerformanceSelector } from '@src/interfaces/performance'
import { useGetPerformanceSelector } from '@src/api/performance'
import { ReviewCycleCategory, ReviewCycleStatus } from '@src/interfaces/reviewCycles'

interface Props {
  data: PerformanceSelector[]
  isLoading: boolean
}

export const useGetLatestPerformanceCycles = (employeeId: number): Props => {
  const { data: employeeEligibleCycles, isLoading } =
    useGetPerformanceSelector(employeeId)

  return useMemo<Props>(
    () => ({
      data: (employeeEligibleCycles ?? []).filter(
        cycle =>
          cycle.category === ReviewCycleCategory.Performance &&
          cycle.status !== ReviewCycleStatus.planned,
      ),
      isLoading,
    }),
    [isLoading, employeeEligibleCycles],
  )
}
