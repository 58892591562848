import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, HStack, Spacer, Text, Token, VStack } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BitbucketConfig } from '@src/pages/Hub/Integrations/Bitbucket/constants'
import { PageBody } from '@components/Page/PageBody'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'

export const InternalLayout = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Bitbucket"
        backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                <Text variant="emphasis1" color={Token.color.success}>
                  Connected
                </Text>
                <Text color={Token.color.greyTone50} variant="body2">
                  Productivity
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image={{
                  default: BitbucketConfig.image.default,
                }}
                size={56}
              >
                <Avatar.Badge useIcon="Check" bg={Token.color.success} />
              </Avatar>
            </HStack>
          </VStack>
        }
      />
      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.bitbucket.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <Text use="p">
              <FormattedMessage
                id="integrations.bitbucket.intro.text"
                defaultMessage="Easily connect all your Bitbucket workspace to automatically track the progress and get performance insights within Revolut People Performance app"
              />
            </Text>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
      </PageBody>
    </PageWrapper>
  )
}
