import React from 'react'
import type { ReactNode } from 'react'
import type { TooltipProps } from 'recharts'
import { LightBackgroundTooltip } from './LightBackgroundTooltip'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { Token } from '@revolut/ui-kit'

export enum ChartTooltipVariants {
  Default = 'Default',
  Percentage = 'Percentage',
}

type ChartTooltipProps<T> = TooltipProps<number, string> & {
  tooltip?: (
    props: T,
    dataKeys?: DataKey<any>[],
    colors?: string[],
    variant?: ChartTooltipVariants,
  ) => ReactNode
  dataKeys?: DataKey<any>[]
  variant?: ChartTooltipVariants
}

export const ChartTooltip = <T,>({
  payload,
  active,
  tooltip,
  dataKeys,
  variant = ChartTooltipVariants.Default,
}: ChartTooltipProps<T>) => {
  const data = payload?.[0]?.payload
  const colors = payload?.map(item => item.color).filter(Boolean)

  if (!active || !tooltip || !data) {
    return null
  }

  return (
    <LightBackgroundTooltip color={Token.color.background}>
      {tooltip(data, dataKeys, colors, variant)}
    </LightBackgroundTooltip>
  )
}
