import React, { useEffect, useRef } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  useScoreCardGrades,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { getGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { ProbationGoalsCard } from './ProbationGoalsCard'

// FYI It's called "Probation" but in reality it's also used in PIP  https://revolut.atlassian.net/browse/REVC-8586
export const ProbationDeliverablesCard = connect(
  ({ onHelpClick, gradesMap }: CommonCardProps) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (
        !values.review_data.kpis_section?.recommended_rating &&
        values.review_data.kpis_section?.rating
      ) {
        set(
          values,
          'review_data.kpis_section.recommended_rating',
          getRoundedRating(values.review_data.kpis_section?.rating),
        )
      }
    }, [values.review_data.kpis_section])

    useEffect(() => {
      if (
        errors.review_data?.calculated_deliverables_ratings ||
        errors.review_data?.kpis_section
      ) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [
      errors.review_data?.calculated_deliverables_ratings,
      errors.review_data?.kpis_section,
    ])

    const { gradeProps: kpiGrades } = useScoreCardGrades()
    const kpiGradesWithExpectations = getGradesWithExpectations(kpiGrades, null)

    return (
      <>
        {hasKPI ? (
          <Card
            data={values}
            renderExpandedContent={() => (
              <KPI
                reviewData={values.review_data}
                kpiGrades={kpiGradesWithExpectations}
                gradesMap={gradesMap}
              />
            )}
            type={CardContentTypes.KPI}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'review_data.kpis_section',
                title: 'Contribution and impact',
                grades: kpiGradesWithExpectations,
                cardJustification: null,
              },
            ]}
            onSelectDeliverableGrade={grade => {
              set(values, `review_data.kpis_section.recommended_rating`, grade.key)
            }}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        ) : (
          <ProbationGoalsCard
            onHelpClick={onHelpClick}
            gradesMap={gradesMap}
            headerRef={ref}
          />
        )}
      </>
    )
  },
)
