import React from 'react'

import { PerformanceSelector } from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { PerformanceCycleActions } from './components/PerformanceCycleActions'
import { PipCycleActions } from './components/PipCycleActions'
import { ProbationCycleActions } from './components/ProbationCycleActions'
import { EmployeeInterface } from '@src/interfaces/employees'
import Table from '@src/components/TableV2/Table'

interface Props {
  selectedPeriod: PerformanceSelector
  employee: EmployeeInterface
}

export const EmployeeCycleActions = ({ selectedPeriod, employee }: Props) => {
  return (
    <Table.Widget.MoreBar maxCount={2}>
      {selectedPeriod.category === ReviewCycleCategory.Performance && (
        <PerformanceCycleActions employee={employee} selectedPeriod={selectedPeriod} />
      )}
      {(selectedPeriod.category === ReviewCycleCategory.PIP_V2 ||
        selectedPeriod.category === ReviewCycleCategory.PIP) && (
        <PipCycleActions selectedPeriod={selectedPeriod} employee={employee} />
      )}
      {selectedPeriod.category === ReviewCycleCategory.Probation && (
        <ProbationCycleActions employee={employee} selectedPeriod={selectedPeriod} />
      )}
    </Table.Widget.MoreBar>
  )
}
