import React, { useMemo } from 'react'
import { JobPostingListInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  ColumnCellInterface,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import { FilterButton, Token } from '@revolut/ui-kit'
import {
  jobDescriptionLocationsColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRecruiterColumn,
  jobDescriptionSpecialisationColumn,
  jobDescriptionStatusColumn,
  jobDescriptionUsedInRequisitionsCountNameColumn,
  jobDescriptionCandidatesColumn,
  jobDescriptionHiringManagersColumn,
  jobDescriptionIconColumn,
} from '@src/constants/columns/jobDescription'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@components/TableV2/hooks'
import { jobPostingsRequests, useGetJobsStats } from '@src/api/jobPosting'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { TableNames } from '@src/constants/table'
import { JobPostingStats } from './JobPostingStats'
import Table from '@components/TableV2/Table'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { jobsConfig } from '@src/pages/Recruitment/RecruitmentSubTabs/common'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useFilterToggle } from '@src/hooks/useFilterToggle'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const Row = (
  specialisationsEnabled: boolean,
  setOpenJobPostingId?: (id: null | number) => void,
  specialisationId?: number,
): RowInterface<JobPostingListInterface> => ({
  linkToForm: data => {
    setOpenJobPostingId
      ? setOpenJobPostingId(data.id)
      : navigateTo(
          pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
            specId: data?.specialisation?.id,
            id: data?.id,
          }),
        )
  },
  highlight: data =>
    data.status === PublishingStatuses.closed ? Token.color.greyTone5 : '',
  cells: [
    {
      ...jobDescriptionIconColumn,
      width: 250,
    },
    {
      ...jobDescriptionCandidatesColumn,
      width: 110,
    },
    !specialisationId
      ? {
          ...jobDescriptionSpecialisationColumn(specialisationsEnabled),
          width: 250,
        }
      : undefined,
    {
      ...jobDescriptionUsedInRequisitionsCountNameColumn,
      width: 100,
    },
    {
      ...jobDescriptionLocationsColumn,
      width: 170,
    },
    {
      ...jobDescriptionHiringManagersColumn,
      width: 150,
    },
    {
      ...jobDescriptionRecruiterColumn,
      width: 200,
    },
    {
      ...jobDescriptionPublishedStatusColumn,
      width: 150,
    },
    {
      ...jobDescriptionStatusColumn,
      width: 150,
    },
  ].filter(i => i) as ColumnCellInterface<JobPostingListInterface>[],
})

type Type = 'main' | 'role'

type Props = {
  specialisationId?: number
  setOpenJobPostingId?: (id: number | null) => void
  actions?: React.ReactNode
  type?: Type
}

export const JobPostingsTable = ({
  setOpenJobPostingId,
  specialisationId,
  actions,
  type = 'main',
}: Props) => {
  const user = useSelector(selectUser)
  const { data: jobPostingSettings } = useGetJobPostingSettings()

  const initialFilterBy = [
    specialisationId
      ? {
          columnName: 'specialisation__id',
          filters: [{ id: specialisationId, name: `${specialisationId}` }],
          nonResettable: true,
        }
      : undefined,
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
    },
    {
      columnName: 'status',
      filters: [
        {
          id: PublishingStatuses.published_internally,
          name: PublishingStatuses.published_internally,
        },
        {
          id: PublishingStatuses.fully_published,
          name: PublishingStatuses.fully_published,
        },
        { id: PublishingStatuses.unpublished, name: PublishingStatuses.unpublished },
      ],
    },
  ].filter(i => i) as FilterByInterface[]

  const initialSortBy = [
    {
      sortBy: 'id',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const table = useTable<JobPostingListInterface>(
    jobPostingsRequests,
    initialFilterBy,
    initialSortBy,
  )

  const { toggle: toggleFilterMyPostings, isActive: isFilteredByMyPostings } =
    useFilterToggle({
      table,
      columnName: 'recruiter__id',
      valueName: String(user.id),
    })

  const { toggle: toggleFilterWithMyCandidates, isActive: isFilteredByMyCandidates } =
    useFilterToggle({
      table,
      columnName: 'hiring_pipeline__recruiters',
      valueName: String(user.id),
    })

  const {
    toggle: toggleFilterWithMyTeamCandidates,
    isActive: isFilteredByMyTeamCandidates,
  } = useFilterToggle({
    table,
    columnName: 'hiring_pipeline__recruiters__team__team_owner',
    valueName: String(user.id),
  })

  const hiddenCells = useMemo(
    () => ({
      [jobDescriptionStatusColumn.idPoint]: !jobPostingSettings?.enable_approvals,
    }),
    [jobPostingSettings?.enable_approvals],
  )

  const row = useMemo(
    () => Row(specialisationsEnabled, setOpenJobPostingId, specialisationId),
    [setOpenJobPostingId, specialisationId],
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {type === 'main' ? (
          <StatNavigation api={useGetJobsStats} config={jobsConfig} />
        ) : (
          <JobPostingStats
            filters={table.filterBy}
            onFilterChange={table.onFilterChange}
          />
        )}
      </Table.Widget.Info>

      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by title or specialisation"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {actions} <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>

      {type === 'main' && (
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by title or specialisation"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          <FilterButton active={isFilteredByMyPostings} onClick={toggleFilterMyPostings}>
            My job postings
          </FilterButton>
          <FilterButton
            active={isFilteredByMyCandidates}
            onClick={toggleFilterWithMyCandidates}
          >
            With my candidates
          </FilterButton>
          <FilterButton
            active={isFilteredByMyTeamCandidates}
            onClick={toggleFilterWithMyTeamCandidates}
          >
            With my team candidates
          </FilterButton>
        </Table.Widget.Filters>
      )}

      <Table.Widget.Table>
        <AdjustableTable<JobPostingListInterface>
          name={TableNames.JobPostings}
          useWindowScroll
          dataType="Job postings"
          row={row}
          {...table}
          noDataMessage="Job postings will appear here."
          hideCount
          tableSettings={{
            hidden: [jobDescriptionSpecialisationColumn(specialisationsEnabled).title],
            visible: [],
          }}
          hiddenCells={hiddenCells}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
