import React from 'react'
import { Flex, HStack, Text, Token } from '@revolut/ui-kit'
import { PerformanceLayoutCycle } from '../../Preview/Performance/Common/PerformanceLayoutCycle'
import Stat from '@components/Stat/Stat'
import { gradeSettings } from './utils'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { FinalGradeInterface, PerformanceSelector } from '@src/interfaces/performance'

interface Props {
  selectedPeriod?: PerformanceSelector
  selectedCycleGrade?: FinalGradeInterface
  chartContent: React.ReactNode
}

export const PerformanceStats = ({
  selectedPeriod,
  selectedCycleGrade,
  chartContent,
}: Props) => {
  return (
    <HStack gap="s-16">
      <PerformanceLayoutCycle selectedPeriod={selectedPeriod} />
      {(selectedPeriod?.status === ReviewCycleStatus.ongoing || selectedCycleGrade) && (
        <Stat
          label="Grade"
          val={
            <Flex alignItems="center" gap="s-8">
              {selectedCycleGrade ? (
                <Text color={gradeSettings[selectedCycleGrade?.id]?.color}>
                  {selectedCycleGrade.label || 'N/A'}
                </Text>
              ) : (
                <Text color={Token.color.greyTone50}>Pending</Text>
              )}
              {chartContent}
            </Flex>
          }
        />
      )}
    </HStack>
  )
}
