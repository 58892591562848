import { useMemo } from 'react'
import { AxiosPromise } from 'axios'

import { apiV2 } from '@src/api/index'
import { API } from '@src/constants/api'
import { ExportRequest, GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  FetchDataQueryInterface,
  FilterByInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import {
  AdpReportInterface,
  ExportReportInterface,
  ManuallyAddedPayCycleInterface,
  PayCycleDetailedReportInterface,
  PayCycleInterface,
  PayCyclePaymentInterface,
  PayCyclePaymentStatsInterface,
  PayCyclePreviewInterface,
  PayCycleReportInterface,
  PayCycleReportsStatsInterface,
  PayCycleSettlementInterface,
  PayCyclesStatsInterface,
  PaygroupInterface,
  PaygroupStatus,
} from '@src/interfaces/payroll'
import { saveFileByBlob } from '@src/utils'
import { formatPeriod } from '@src/utils/format'
import { useFetchV2 } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import { toIdAndName } from '@src/utils/toIdAndName'
import { getCommentsAPI } from './comments'

export const getPayCyclesTableRequests = () => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<GetRequestInterface<PayCycleInterface>>(`${API.PAYROLL}/cycles`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<PayCyclesStatsInterface>(`${API.PAYROLL}/cycles/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const usePaygroups = () =>
  useFetchV2<GetRequestInterface<PaygroupInterface>>({ url: API.PAY_GROUPS })

export const usePaygroup = (id?: number | string) => {
  const fetchResults = useFetchV2<PaygroupInterface>({
    url: `${API.PAY_GROUPS}/${id}`,
    queryOptions: { enabled: id != null },
  })

  const allSettlementProfiles = fetchResults.data?.payroll_elements.map(
    pe => pe.settlement_profile,
  )

  const nonNullSettlementProfiles = allSettlementProfiles?.filter(
    profile => profile !== null,
  ) as string[] | undefined

  const uniqueSettlementProfiles = [...new Set(nonNullSettlementProfiles)]

  const selectableSettlementProfiles = uniqueSettlementProfiles.map(profile => ({
    id: profile,
    name: profile,
  }))

  return { ...fetchResults, selectableSettlementProfiles }
}

export const closePayCycle = (id: string) =>
  apiV2.post<PayCycleInterface>(`${API.PAYROLL}/cycles/${id}/close`)

export const usePayCycle = (id: string | null) =>
  useFetchV2<PayCycleInterface>({
    url: `${API.PAYROLL}/cycles/${id}`,
    queryOptions: { enabled: id != null },
  })

export const usePayCycleFiles = (id: string) =>
  useFetchV2<PayCycleInterface>({ url: `${API.PAYROLL}/cycles/${id}/file` })

export const getPayCycleExport =
  (id: number | string): ExportRequest =>
  (exportType, filterQuery) =>
    apiV2.get(`${API.PAYROLL}/cycles/${id}/reports/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    })

export const getPayGroupExport =
  (id: string): ExportRequest =>
  (exportType, filterQuery) =>
    apiV2.get(`${API.PAYROLL}/payGroups/${id}/reports/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    })

export const usePayCycles = (paygroupId?: string, sortAsc?: boolean) => {
  const fetchResults = useFetchV2<GetRequestInterface<PayCycleInterface>>({
    url: `${API.PAYROLL}/cycles`,
    params: {
      params: filterSortPageIntoQuery(
        [
          {
            sortBy: 'start_date',
            direction: sortAsc ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
          },
        ],
        paygroupId
          ? [
              {
                filters: [toIdAndName(paygroupId)],
                columnName: 'pay_group_id',
                nonResettable: true,
              },
            ]
          : [],
        1,
      ),
    },
  })

  const selectablePaycycles = useMemo(() => {
    return fetchResults.data?.results.map(option => ({
      id: option.id,
      name: formatPeriod(new Date(option.start_date), new Date(option.end_date)),
    }))
  }, [fetchResults.data])

  return { ...fetchResults, selectablePaycycles }
}

export const getPaygroups = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<PaygroupInterface>> =>
  apiV2.get(API.PAY_GROUPS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const paygroupRequests: RequestInterfaceNew<PaygroupInterface> = {
  get: async ({ id }) => apiV2.get(`${API.PAY_GROUPS}/${id}`),
  submit: async data => apiV2.post(API.PAY_GROUPS, data),
  update: async (data, { id }) => apiV2.patch(`${API.PAY_GROUPS}/${id}`, data),
  delete: async ({ id }) => apiV2.delete(`${API.PAY_GROUPS}/${id}`),
}

export const getPaycyclePreview = (
  data: PaygroupInterface & { number_of_schedules: number },
) => apiV2.post<PayCyclePreviewInterface[]>(`${API.PAY_GROUPS}/payScheduleDates`, data)

export const paycycleRequests: RequestInterfaceNew<PayCycleInterface> = {
  get: async ({ id }) => apiV2.get(`${API.PAYROLL}/cycles/${id}`),
  submit: async data => apiV2.post(`${API.PAYROLL}/cycles`, data),
  update: async (data, { id }) => apiV2.patch(`${API.PAYROLL}/cycles/${id}`, data),
  delete: async ({ id }) => apiV2.delete(`${API.PAYROLL}/cycles/${id}`),
}

export const getPayCycleReportsTableRequests = (id: string) => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<GetRequestInterface<PayCycleReportInterface>>(
      `${API.PAYROLL}/cycles/${id}/reports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<PayCycleReportsStatsInterface>(
      `${API.PAYROLL}/cycles/${id}/reports/stats`,
      { params: filterSortPageIntoQuery(sortBy, filters, page) },
    ),
})

export const getPayCycleDetailedReport = (id: string, employeeId: string) =>
  apiV2.get<PayCycleDetailedReportInterface>(
    `${API.PAYROLL}/cycles/${id}/reports/${employeeId}`,
  )

export const useEmployeePayrollData = (id: string, employeeId: string) =>
  useFetchV2<PayCycleDetailedReportInterface>({
    url: `${API.PAYROLL}/cycles/${id}/reports/${employeeId}`,
  })

export const updatePaygroupStatus = (
  newStatus: PaygroupStatus,
  id: number,
): AxiosPromise<PaygroupInterface> => {
  return apiV2.patch(`${API.PAY_GROUPS}/${id}`, { status: newStatus })
}

export const addPayCycleFormRequests: RequestInterfaceNew<ManuallyAddedPayCycleInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.PAYROLL}/cycles/${id}`),
    submit: async data => apiV2.post(`${API.PAYROLL}/cycles`, data),
    update: async (data, { id }) => apiV2.patch(`${API.PAYROLL}/cycles/${id}`, data),
  }

export const createSettlement = (data: Partial<PayCycleSettlementInterface>) =>
  apiV2.post<PayCycleSettlementInterface>(`${API.PAYROLL}/cycles/settlements`, data)

export const paymentsTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<GetRequestInterface<PayCyclePaymentInterface>>(
      `${API.PAYROLL}/cycles/payments`,
      { params: filterSortPageIntoQuery(sortBy, filters, page) },
    ),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<PayCyclePaymentStatsInterface>(`${API.PAYROLL}/cycles/payments/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const createPayment = (data: Partial<PayCyclePaymentInterface>) =>
  apiV2.post<PayCyclePaymentInterface>(`${API.PAYROLL}/cycles/payments`, data)

export const paymentFormRequests: RequestInterfaceNew<PayCyclePaymentInterface> = {
  submit: data => {
    return apiV2.post<PayCyclePaymentInterface>(`${API.PAYROLL}/cycles/payments`, data)
  },
  get: ({ id }) => {
    return apiV2.get(`${API.PAYROLL}/cycles/payments/${id}`)
  },
  update: (_diff, _params, data) => {
    // there is no way to update a payment at the moment, create a new one instead
    return apiV2.post<PayCyclePaymentInterface>(`${API.PAYROLL}/cycles/payments`, data)
  },
}

export const deletePayment = (id: string) =>
  apiV2.delete(`${API.PAYROLL}/cycles/payments/${id}`)

export const bulkDeletePayments = (
  params: ReturnType<typeof groupFiltersIntoURLQuery> & {
    id?: string
    excluded_ids?: string
  },
) =>
  apiV2.delete(`${API.PAYROLL}/cycles/payments/delete`, {
    params,
  })

export const usePayment = (id?: number) =>
  useFetchV2<PayCyclePaymentInterface>({
    url: `${API.PAYROLL}/cycles/payments/${id}`,
    queryOptions: {
      enabled: id != null,
    },
  })

export const useFilteredPaymentsSelector = (filters: FilterByInterface[]) =>
  useFetchV2<{ options: PayCyclePaymentInterface[] }>({
    url: `${API.PAYROLL}/cycles/payments/selector`,
    params: {
      params: groupFiltersIntoURLQuery(filters),
    },
  })

export const getPayCycleCommentsAPI = (payCycleId: string | number) =>
  getCommentsAPI({
    baseUrl: `${API.PAYROLL}/cycles/${payCycleId}/comments`,
  })

export const getPayCycleReportsCommentsAPI = (
  payCycleId: string | number,
  reportId: string | number,
) =>
  getCommentsAPI({
    baseUrl: `${API.PAYROLL}/cycles/${payCycleId}/reports/${reportId}/comments`,
  })

export const paygroupsBulkEdit = (data: PaygroupInterface[]) =>
  apiV2.patch(API.PAY_GROUPS, data)

export const getReportExportHandler = (
  id: number | string,
  data: ExportReportInterface,
) => apiV2.post(`${API.PAYROLL}/cycles/${id}/reportExports`, data)

export const getReportFtpUploadHandler = (
  id: number | string,
  data: ExportReportInterface,
) => apiV2.post(`${API.PAYROLL}/cycles/${id}/reportFtpUploads`, data)

export const useGetAdpReportExport = (id: string, requestId: string) =>
  useFetchV2<AdpReportInterface>({
    url: `${API.PAYROLL}/cycles/${id}/reportExports/${requestId}`,
    queryOptions: {
      refetchInterval: session => (!session?.is_completed ? 1000 : false),
    },
  })

export const useGetAdpReportFtpUpload = (id: string, requestId: string) =>
  useFetchV2<AdpReportInterface>({
    url: `${API.PAYROLL}/cycles/${id}/reportFtpUploads/${requestId}`,
    queryOptions: {
      refetchInterval: session => (!session?.is_completed ? 1000 : false),
    },
  })

export const downloadAdpReport = async (
  id: string,
  requestId: string,
  fileName: string,
) => {
  const { data, headers } = await apiV2.get<string>(
    `${API.PAYROLL}/cycles/${id}/reportExports/${requestId}/download`,
    { responseType: 'blob' },
  )
  saveFileByBlob(data, fileName, headers['content-type'])
}

export const adpReportsExportTableRequests = (id: string) => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<GetRequestInterface<AdpReportInterface>>(
      `${API.PAYROLL}/cycles/${id}/reportExports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
})

export const adpReportsFtpUploadTableRequests = (id: string) => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<GetRequestInterface<AdpReportInterface>>(
      `${API.PAYROLL}/cycles/${id}/reportFtpUploads`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
})
