import React, { ReactNode, useContext } from 'react'
import { Avatar, AvatarSize, chain, HStack, Text } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import { TableDensityContextValue, TableDensityContext } from '@components/TableV2/Table'
import { getAdjustedAvatarSize } from '@components/TableV2/utils'

interface CountryCellProps {
  country: IdAndName & {
    country_code?: string
  }
  size?: AvatarSize
  suffix?: ReactNode
}

export const CountryCell = ({ country, size, suffix }: CountryCellProps) => {
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  const adjustedSize = getAdjustedAvatarSize(size || 40, density)

  return (
    <HStack align="center" space="s-12">
      {country.country_code ? (
        <Avatar
          size={adjustedSize}
          variant="brand"
          image={getCountryFlagImageByCode(country.country_code)}
        />
      ) : (
        <Avatar size={adjustedSize} variant="brand" useIcon="Globe" />
      )}
      <Text>{chain(country.name, suffix)}</Text>
    </HStack>
  )
}
