import React, { PropsWithChildren } from 'react'
import {
  Action,
  ActionButton,
  DragAndDrop,
  Flex,
  HStack,
  Tag,
  Token,
  Widget,
} from '@revolut/ui-kit'

interface EvaluationSectionProps {
  disableActions: boolean
  isEditing?: boolean
  isLocked?: boolean
  isSubmitting?: boolean
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDelete?: VoidFunction
  onEdit?: VoidFunction
}

export const EvaluationSection = ({
  children,
  disableActions,
  isEditing,
  isLocked,
  isSubmitting,
  sortable,
  onDelete,
  onEdit,
}: PropsWithChildren<EvaluationSectionProps>) => {
  return (
    <Widget
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Flex alignItems="center" justifyContent="space-between" px="s-16" pt="s-16">
        <HStack gap="s-8">
          <Action
            {...sortable?.attributes}
            {...sortable?.listeners}
            color={Token.color.greyTone50}
            iconOnly
            ref={sortable?.setNodeRef}
            useIcon="Drag"
            disabled={disableActions || isEditing}
          />
        </HStack>
        {!isLocked ? (
          <HStack gap="s-16">
            <ActionButton
              aria-label="Edit"
              disabled={disableActions}
              iconOnly
              size="xs"
              useIcon={isEditing ? 'Check' : 'Pencil'}
              pending={isEditing && isSubmitting}
              onClick={onEdit}
            />
            <ActionButton
              disabled={disableActions}
              aria-label="Delete"
              iconOnly
              size="xs"
              useIcon="Delete"
              onClick={onDelete}
            />
          </HStack>
        ) : (
          <Tag bg={Token.color.greyTone8} useIcon="LockClosed" variant="status">
            Locked
          </Tag>
        )}
      </Flex>
      {children}
    </Widget>
  )
}
