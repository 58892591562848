import { EmployeeOptionInterface } from './employees'
import { FieldOptions, IdAndName, Statuses } from './index'
import { PerformanceScores } from './scorecard'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { OptionInterface, SpecialisationOption } from '@src/interfaces/selectors'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import { FunctionType } from '@src/interfaces/functions'
import { CulturePercentile } from './culture'
import { LegacyApprovalStep } from '@src/interfaces/approvalFlow'
import { ReactNode } from 'react'
import { IconName } from '@revolut/ui-kit'

export enum RoleType {
  general = 'general',
  leadership = 'leadership',
}

export interface RoleInterviewer {
  id: number | string
  name: string
  status?: Statuses
}

export interface RoleSkillset {
  id: number
  name: string
  info?: string
  description: string | null
  sub_skills: string[]
  status: string
  owner: {
    id: number
    name: string
    status: string
  } | null
  interviewers: RoleInterviewer[]
  home_task: string | null
  test: string | null
  assessment_criteria: string[]
  skill_type?: string
  mastery_level_definition_poor?: string
  mastery_level_definition_basic?: string
  mastery_level_definition_intermediate?: string
  mastery_level_definition_advanced?: string
  mastery_level_definition_expert?: string
  interview_questions?: string[]
}

export interface RoleHomeTaskInterviewInterface {
  skill_id: number
  interviewers: EmployeeOptionInterface[]
}

export enum SkillLevels {
  None = 'none',
  Poor = 'poor',
  Basic = 'basic',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
  Expert = 'expert',
}

export enum RoleApprovalSteps {
  function_owner = 'function_owner',
  recruitment = 'recruitment',
  ceo = 'ceo',
  no_step = 'no_step',
}

export interface CompetencyInterface {
  seniority_id: number
  seniority_name: string
  seniority_level?: number
  competency_level: SkillLevels | null
}

export interface CompetencyMatrixSkill {
  id: number | null
  name: string | null
  interviewers?: RoleInterviewer[]
  status?: Statuses
}

export interface CompetencyMatrixInterface {
  skill?: CompetencyMatrixSkill
  sectionTitle?: ReactNode
  competencies?: CompetencyInterface[]
  weight?: number
}

type DeliverableCompetency = {
  skill_id: null
  name: string
  weight: number
  weight_type: CompetencyPerformanceWeightType.Deliverables
  competencies: CompetencyInterface[]
  parent_skill: false
}

type Competency = {
  skill_id: number
  name: string
  weight: number
  weight_type: CompetencyPerformanceWeightType.Skill
  competencies: CompetencyInterface[]
  parent_skill: boolean
}

export type CompetencyMatrixInterfaceV2 = DeliverableCompetency | Competency

export interface DefaultCompetencyMatrixInterface {
  executive_matrix: CompetencyMatrixInterface[]
  culture_matrix: CompetencyMatrixInterface[]
}

export enum CompetencyPerformanceWeightType {
  Skill = 'skill',
  Deliverables = 'deliverables',
}

export interface CompetencyPerformanceWeight {
  skill_id: number | null
  weight: number
  weight_type: CompetencyPerformanceWeightType
}

interface FunctionInterface {
  id: number
  name: string
  function_type: FunctionType
}

export interface SpecialisationInterface {
  role_culture_competency_matrix?: CompetencyMatrixInterface[]
  mission?: string
  talent_above_average_percent: number
  talent_below_average_percent: number
  id: number
  name: string
  owner: EmployeeOptionInterface
  candidate_expectations: {
    must_have: { id: number; text: string }[]
    good_to_have: { id: number; text: string }[]
    red_flags: { id: number; text: string }[]
    example_profiles: { id: number; text: string }[]
    relevant_sectors: { id: number; text: string }[]
  }
  goals?: { text: string }[]
  locations?: OptionInterface[]
  calibration?: string[]
  cv_criteria_pass_score?: number
  nips?: number
  status: Statuses
  are_skills_assessed?: boolean
  are_deliverables_assessed?: boolean
  are_values_assessed?: boolean
  status_display: string
  cv_criteria_pass_score_percent?: number
  role: SpecialisationRole
  skillsets: RoleSkillset[]
  /** @deprecated use specialisationHiringStagesRequests instead */
  hiring_process_rounds: HiringProcessInterface[]
  seniority_max?: SeniorityInterface
  seniority_min?: SeniorityInterface
  average_performance_review_label: PerformanceScores | ''
  headcount: number
  requisition_headcount: number
  dashboard_count: number
  field_options: FieldOptions
  functional_competency_matrix?: CompetencyMatrixInterface[]
  competency_matrix?: CompetencyMatrixInterfaceV2[]
  role_functional_competency_matrix?: CompetencyMatrixInterface[]
  approval_flow_status: {
    approval_steps: LegacyApprovalStep[]
  }
  culture_competency_matrix?: CompetencyMatrixInterface[]
  deliverables_competencies?: CompetencyInterface[]
  performance_weights?: CompetencyPerformanceWeight[]
  culture_enps: number
  culture_values: number
  culture_compliance: number
  culture_compliance_percentile: CulturePercentile
  culture_enps_percentile: CulturePercentile
  culture_values_percentile: CulturePercentile
  culture_manager_enps: number
  culture_job_satisfaction: number
  non_field_errors?: string
  posting_count?: number | null
  candidate_count?: number | null
  average_score?: number | null
  hiring_playbook_url?: string
  role_id?: number
  default_hiring_process: SpecialisationHiringProcess
  icon: IconName | null
  avatar: string | null
  related_specialisations: SpecialisationOption[] | null
  function: FunctionInterface
  is_hiring_process_locked: boolean
}

export interface RoleInterface {
  id: number
  name: string
  role_manager: EmployeeOptionInterface
  nips?: number
  locations?: OptionInterface[]
  status: Statuses
  approval_step?: RoleApprovalSteps
  function: FunctionInterface
  role_type?: RoleType
  role_remit?: string
  goals: { text: string }[]
  role_goals?: { id: number; text: string }[]
  skillsets: RoleSkillset[]
  assessed_skills?: RoleSkillset[]
  seniority_max?: SeniorityInterface
  entities?: OptionInterface[]
  seniority_min?: SeniorityInterface
  description: string
  candidate_expectations: {
    must_have: { id: number; text: string }[]
    good_to_have: { id: number; text: string }[]
    red_flags: { id: number; text: string }[]
    example_profiles: { id: number; text: string }[]
    relevant_sectors: { id: number; text: string }[]
  }
  calibration?: string[]
  cv_criteria_pass_score?: number
  cv_criteria_pass_score_percent?: number
  hiring_process_rounds: HiringProcessInterface[]
  cv_screening_interviewers?: EmployeeOptionInterface[]
  final_round_interviewers?: EmployeeOptionInterface[]
  first_round_interviewers?: EmployeeOptionInterface[]
  home_task_interviewers?: RoleHomeTaskInterviewInterface[]
  job_skills_interviewers?: EmployeeOptionInterface[]
  role_description?: string
  screencall_interviewers?: EmployeeOptionInterface[]
  second_round_interviewers?: EmployeeOptionInterface[]
  average_performance_review_label?: PerformanceScores
  headcount: number
  requisition_headcount: number
  dashboard_count: number
  field_options: FieldOptions
  requisition_remaining_headcount?: number
  functional_competency_matrix: CompetencyMatrixInterface[]
  culture_competency_matrix: CompetencyMatrixInterface[]
  approval_flow_status: {
    approval_steps: LegacyApprovalStep[]
  }
  kpi_weight_mode?: {
    id: string
    name: string
  }
  culture_enps: number
  culture_values: number
  culture_compliance: number
  culture_compliance_percentile: CulturePercentile
  culture_enps_percentile: CulturePercentile
  culture_values_percentile: CulturePercentile
  culture_manager_enps: number
  culture_job_satisfaction: number
  non_field_errors?: string
  average_score?: number | null
  icon: IconName | null
  avatar: string | null
}

interface SpecialisationRole
  extends Omit<
    RoleInterface,
    | 'function'
    | 'candidate_expectations'
    | 'hiring_process_rounds'
    | 'role_description'
    | 'requisition_remaining_headcount'
    | 'average_performance_review_label'
    | 'headcount'
    | 'requisition_headcount'
    | 'field_options'
    | 'functional_competency_matrix'
    | 'culture_competency_matrix'
    | 'approval_flow_status'
  > {
  function_id: number
}

export type RoleStep =
  | 'general'
  | 'competency_matrix'
  | 'candidate_expectations'
  | 'hiring_process'
  | 'postings'
  | 'archetype'

export interface RolesStatsInterface {
  roles_count: number
  specialisations_count: number
  functions_count: number
}

export interface SpecialisationCandidateArchetypeInterface extends IdAndName {
  specialisation: IdAndName
  owner: EmployeeOptionInterface
  status: Statuses.active | Statuses.archived
  must_have_traits: string[]
  nice_to_have_traits: string[]
  red_flag_traits: string[]
  skills: CompetencyMatrixSkill[]
}
