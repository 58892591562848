import React from 'react'

import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { OrderingDataType } from '@src/interfaces/ordering'
import { useGetResourcesTabConfig } from '@src/pages/Forms/DepartmentForm/Resources/api'

export const getDepartmentResourcesAnalyticsInitialFilter = (id: number) => [
  {
    filters: [
      {
        name: OrderingDataType.Department,
        id: OrderingDataType.Department,
      },
    ],
    columnName: 'parent_type',
    nonResettable: true,
  },
  {
    filters: [{ name: '', id }],
    columnName: 'parent_id',
    nonResettable: true,
  },
]

interface DepartmentResourcesStatsNavigationProps {
  tabId: number
  departmentId: number
}

export const DepartmentResourcesStatsNavigation = ({
  tabId,
  departmentId,
}: DepartmentResourcesStatsNavigationProps) => {
  const { config, api } = useGetResourcesTabConfig({ tabId, departmentId })

  return <StatNavigation api={api} config={config} />
}
