import React from 'react'
import { FormattedMessage } from 'react-intl'
import { chain, Text, Token, VStack } from '@revolut/ui-kit'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'

interface Props {
  summary: MeetingsSummaryInterface
}

export const SummaryHeader = ({ summary }: Props) => {
  return (
    <VStack>
      <Text variant="heading3" fontWeight={500}>
        {chain(
          <Text>{summary.cycle.name}</Text>,
          <FormattedMessage
            id="one-to-one.meetings.summary.header.title"
            defaultMessage="1:1 summary"
          />,
        )}
      </Text>
      <Text variant="body2" color={Token.color.greyTone50}>
        <FormattedMessage
          id="one-to-one.meetings.summary.header.description"
          defaultMessage="Updated daily until cycle ends"
        />
      </Text>
    </VStack>
  )
}
