import React from 'react'
import {
  Grid,
  Checkbox,
  VStack,
  TextButton,
  Icon,
  Token,
  HStack,
  Text,
  useTooltip,
  Tooltip,
} from '@revolut/ui-kit'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { GoalsInterfaceWithChildren } from '@src/api/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { entityTypeToIconNameMap } from '@src/pages/Performance/Engagement/components/SurveyResults/InsightsWidget/components/Outliers/constants'
import { GoalContentType } from '@src/interfaces/goals'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'

interface Props {
  option: GoalsInterfaceWithChildren
  isSelected: boolean
  onChange: VoidFunction
}

const modelToEntityType: Record<GoalContentType['model'], ScopeFilterKey> = {
  teams: 'team',
  department: 'department',
  employees: 'line_manager',
}

export const OptionItem = ({ option, isSelected, onChange }: Props) => {
  const openInNewTab = useOpenNewTab()
  const tooltip = useTooltip()
  return (
    <>
      <Grid
        columns="auto 1fr auto"
        use="label"
        gap="s-16"
        style={{ alignItems: 'center', cursor: 'pointer' }}
        disabled={!!option.parent?.id}
        {...(option.parent?.id ? { ...tooltip.getAnchorProps() } : {})}
      >
        <Checkbox
          value={option.id}
          width="100%"
          checked={isSelected}
          onChange={onChange}
          disabled={!!option.parent?.id}
        />
        <VStack color={option.parent?.id ? Token.color.greyTone10 : undefined}>
          <Checkbox.Label>{option.name}</Checkbox.Label>
          <Checkbox.Description>
            <HStack align="center" gap="s-8">
              {option.content_type?.model && (
                <Icon
                  size={16}
                  color={Token.color.greyTone20}
                  name={
                    entityTypeToIconNameMap[modelToEntityType[option.content_type.model]]
                  }
                />
              )}
              <Text>{option.content_object.name}</Text>
            </HStack>
          </Checkbox.Description>
        </VStack>
        <TextButton
          onClick={e => {
            e.preventDefault()
            openInNewTab(
              pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, {
                id: option.id,
              }),
            )
          }}
        >
          <Icon name="LinkExternalSimple" color={Token.color.greyTone20} />
        </TextButton>
      </Grid>
      {option.parent?.id ? (
        <Tooltip {...tooltip.getTargetProps()}>
          <VStack>
            <Text variant="emphasis1">Goals can only have one parent goal</Text>
            <Text>
              This goal already has parent selected, if you want to change it, go to the
              goal page directly
            </Text>
          </VStack>
        </Tooltip>
      ) : null}
    </>
  )
}
