import React, { useEffect, useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { GroupedVirtuoso } from 'react-virtuoso'
import upperFirst from 'lodash/upperFirst'
import {
  Avatar,
  Box,
  Item,
  ItemSkeleton,
  Flex,
  Subheader,
  Token,
  chain,
  HStack,
  Text,
  IconButton,
} from '@revolut/ui-kit'
import { deleteTimelineItem, getEmployeeTimeline } from '@src/api/employeeTimeline'
import { selectorKeys } from '@src/constants/api'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeTimelineEventInterface } from '@src/interfaces/employeeTimeline'
import { EmployeeInterface } from '@src/interfaces/employees'
import ButtonFilters, {
  ButtonFilterConfig,
} from '@components/ButtonFilters/ButtonFilters'
import { PageBody } from '@components/Page/PageBody'
import { useTable } from '@components/Table/hooks'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { formatDate } from '@src/utils/format'
import { getDateFromString } from '@src/utils/timezones'
import { ValuesDiff } from './ValuesDiff'
import { changeFieldNameToIconName, getGroup, useCheckHRPermissions } from './utils'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { FormattedMessage } from 'react-intl'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface TimelineProps {
  data: EmployeeInterface
}

const sortBy = [
  {
    sortBy: 'effective_date',
    direction: SORT_DIRECTION.ASC,
  },
]

const filtersConfig: ButtonFilterConfig = {
  field_name: {
    title: 'Filter',
    type: 'MultiSelect',
    selector: selectorKeys.timeline_field_names,
    useIcon: 'Filter',
  },
  effective_date: {
    title: 'Date',
    type: 'DateRange',
    selector: selectorKeys.none,
    useIcon: 'Calendar',
  },
}

export const Timeline = ({ data }: TimelineProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { width: containerWidth } = useResizeObserver({ ref: containerRef })
  const [currentGroups, setCurrentGroups] = useState<string[]>([])
  const [currentGroupCounts, setCurrentGroupCounts] = useState<number[]>([])
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const confirmationPopup = useConfirmationDialog()
  const errorPopup = useErrorPopup()
  const hasHRPermissions = useCheckHRPermissions()

  const isMobile = containerWidth
    ? containerWidth <= parseInt(Token.breakpoint.md, 10)
    : true

  const table = useTable<EmployeeTimelineEventInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getEmployeeTimeline(data.id, requestData),
    },
    undefined,
    sortBy,
  )

  const getItemTitle = (item: EmployeeTimelineEventInterface) => {
    if (item.field_name === 'Specialisation') {
      return getSpecialisationTitle(specialisationsEnabled)
    }
    return upperFirst(item.field_name)
  }
  const updateGroups = (items: EmployeeTimelineEventInterface[]) => {
    const groups: string[] = []
    const groupCounts: number[] = []
    for (const item of items) {
      const groupName = getGroup(item)
      if (groups[groups.length - 1] !== groupName) {
        groups.push(groupName)
      }
      groupCounts[groups.length - 1] = (groupCounts[groups.length - 1] || 0) + 1
    }

    setCurrentGroupCounts(groupCounts)
    setCurrentGroups(groups)
  }

  const getCount = (end: number) => {
    return currentGroupCounts.slice(0, end).reduce((a, b) => a + b, 0)
  }

  const onDeleteClick = (item: EmployeeTimelineEventInterface) => {
    confirmationPopup.show({
      label: 'Removing employee record',
      body: (
        <FormattedMessage
          id="employee.profile.preview.timeline.delete.confirmation.body"
          defaultMessage="Are you sure you want to remove this record from <T>{name}</T>’s timeline? This action can not be reverted"
          values={{
            T: text => <Text fontWeight={700}>{text}</Text>,
            name: data?.display_name,
          }}
        />
      ),
      yesMessage: 'Confirm',
      noMessage: 'Cancel',
      onConfirm: async () => {
        try {
          const res = await deleteTimelineItem({ employeeId: data.id, itemId: item.id })
          if (res.status === 204) {
            table.refresh()
          }
        } catch (error) {
          errorPopup.show({
            error,
            fallbackTitle: 'Unable to delete timeline record',
          })
        }
      },
    })
  }

  useEffect(() => {
    updateGroups(table.data)
  }, [table.data])

  return (
    <PageBody maxWidth={Token.breakpoint.xl}>
      <Flex justifyContent="flex-end" mb="s-16">
        <ButtonFilters
          filters={table.filterBy}
          filtersConfig={filtersConfig}
          onChange={filter => {
            if (filter.columnName === 'effective_date') {
              return table.onFilterChange({
                ...filter,
                filters: filter.filters.map(filterOption => ({
                  id: getDateFromString(String(filterOption.id)),
                  name: getDateFromString(String(filterOption.id)),
                })),
              })
            }

            return table.onFilterChange(filter)
          }}
        />
      </Flex>
      <Box ref={containerRef}>
        <GroupedVirtuoso
          endReached={table.fetchNextPage}
          groupCounts={currentGroupCounts}
          groupContent={index => (
            <Box pt={index === 0 ? 0 : 's-16'}>
              <Subheader>
                <Subheader.Title>{currentGroups[index]}</Subheader.Title>
              </Subheader>
            </Box>
          )}
          itemContent={(index, groupIndex) => {
            const item = table.data[index]
            if (!item) {
              return null
            }
            const date = formatDate(item.effective_date)

            return (
              <Box
                backgroundColor={Token.color.widgetBackground}
                borderRadius={`${
                  index === getCount(groupIndex)
                    ? `${Token.radius.r12} ${Token.radius.r12}`
                    : '0 0'
                } ${
                  index + 1 === getCount(groupIndex + 1)
                    ? `${Token.radius.r12} ${Token.radius.r12}`
                    : '0 0'
                }`}
                data-testid={`timeline-item-${item.id}`}
                key={item.id}
              >
                <Item style={transparentThemeBackgroundOverrides}>
                  <Item.Avatar>
                    <Avatar useIcon={changeFieldNameToIconName(item.field_name)} />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{getItemTitle(item)}</Item.Title>
                    <Item.Description>
                      {item.performed_by?.display_name
                        ? chain(`By ${item.performed_by.display_name}`, date)
                        : date}
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <HStack gap="s-16" align="center">
                      <ValuesDiff
                        from={item.changed_from}
                        isMobile={isMobile}
                        to={item.changed_to}
                      />
                      {hasHRPermissions && (
                        <IconButton
                          useIcon="Delete"
                          aria-label="Delete record"
                          size={18}
                          color={Token.color.blue}
                          onClick={() => onDeleteClick(item)}
                        />
                      )}
                    </HStack>
                  </Item.Side>
                </Item>
              </Box>
            )
          }}
          overscan={1000}
          useWindowScroll
        />
      </Box>
      {table.loading && <ItemSkeleton />}
      {!table.loading && table.count === 0 && (
        <Item useIcon="InfoOutline">
          <Item.Content>
            <Item.Title color={Token.color.grey50}>No events in timeline</Item.Title>
          </Item.Content>
        </Item>
      )}
    </PageBody>
  )
}
