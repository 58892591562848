import React, { useState } from 'react'
import {
  VStack,
  DataPoint,
  Flex,
  Cell,
  Tag,
  useTooltip,
  Tooltip,
  Token,
  Details,
  Link as UIKitLink,
  TextWidget,
  Ellipsis,
  chain,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { fetchGoalGraph, useGetGoal } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { CycleOption, OptionInterface } from '@src/interfaces/selectors'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'
import { GoalStatusDropdown } from '@src/features/Goals/components/GoalStatusDropdown'
import { TargetsPreviewWidget } from './TargetsPreviewWidget'

import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { isCascadedGoal } from '@src/pages/Forms/GoalForm/common/utils'
import { Link } from 'react-router-dom'
import { GoalCycleSelectType } from './types'

import { RoadmapsWidget } from '../Widgets/Roadmaps/RoadmapsWidget'
import { RoadmapSidebarForm } from '../Widgets/Roadmaps/RoadmapSidebarForm'
import { ChildGoalsPreviewFormWidget } from '../Widgets/ChildGoalsPreview/ChildGoalsPreviewWidget'
import { IntegrationDataPreviewWidget } from '../Widgets/IntegrationDataPreview/IntergrationDataPreviewWidget'
import { AnalyticsPreviewWidget } from '../Widgets/AnalyticsPreview/AnalyticsPreviewWidget'
import { GoalCommentsWidget } from '../Widgets/GoalComments/GoalCommentsWidget'

const styleOverrides = { paddingBottom: 0 }

export const GoalsPreviewContent = ({
  cycle,
  availableCycles,
  onCycleChanged,
}: {
  cycle: GoalCycleSelectType
  availableCycles: GoalCycleSelectType[]
  onCycleChanged: (cycleId: number | string) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { data: parent } = useGetGoal(values.parent?.id)
  const roadmapsTable = useGoalRoadmapsTable(cycle)
  const [filteredAvailableCycles, setFilteredAvailableCycles] = useState(availableCycles)
  const [selectedRoadmap, setSelectedRoadmap] = useState<number>()
  const tooltip = useTooltip()

  const isCascaded = isCascadedGoal(values.update_type)
  const isTemplate = !!values.template_rule

  const onCycleChange = (filter: FilterByInterface) => {
    const cycleId = filter.filters[0].id

    onCycleChanged(cycleId)
    if (
      filter.filters[0].category &&
      !['probation', 'PIP'].includes(filter.filters[0].category) &&
      roadmapsTable
    ) {
      roadmapsTable.onFilterChange({ ...filter, columnName: 'review_cycle__id' })
    }
  }

  const handleTableSelected = (tab: 'chart' | 'table') => {
    if (tab === 'table') {
      // if there is only one cycle select that cycle otherwise select "All cycles"
      const baseSelectedCycle =
        availableCycles.length === 2 ? availableCycles[1] : availableCycles[0]
      onCycleChange({
        filters: [baseSelectedCycle as OptionInterface],
        columnName: 'cycle__id',
      })
      setFilteredAvailableCycles(availableCycles)
    }
    if (tab === 'chart') {
      // do not show all cycles in the chart view
      setFilteredAvailableCycles(availableCycles.filter(({ id }) => !!id))
      if (!cycle.id) {
        // if no cycle selected find the cycle closes to current cycle (0)
        const newCycle = availableCycles.reduce((closestCycle, currentCycle) => {
          if (currentCycle.offset === undefined) {
            return closestCycle
          }

          if (
            closestCycle.offset === undefined ||
            Math.abs(currentCycle.offset) < Math.abs(closestCycle.offset)
          ) {
            return currentCycle
          }

          return closestCycle
        }, availableCycles[0])

        onCycleChange({
          columnName: 'cycle__id',
          filters: [newCycle as OptionInterface],
        })
      }
    }
  }
  const cycleFilter = [
    {
      columnName: 'cycle__id',
      filters: [{ id: cycle?.id, name: cycle?.name }],
    },
  ] as FilterByInterface[]

  const goalCycles =
    (values.update_type?.id === 'cascaded' ? parent?.goal_cycles : values.goal_cycles) ||
    []

  const goalProgressDetails = goalCycles.find(cycleDetails => {
    if (cycleDetails.review_cycle) {
      return cycleDetails.review_cycle.id === cycle.id
    }
    if (cycleDetails.employee_cycle) {
      return cycleDetails.employee_cycle.id === cycle.id
    }
    return false
  })

  const progress =
    goalProgressDetails?.calibrated_progress ??
    goalProgressDetails?.progress ??
    values.calibrated_progress ??
    values.progress

  const showTargets =
    values.update_type?.id === 'target_based' || values.update_type?.id === 'cascaded'

  return (
    <VStack space="s-24">
      <Cell width="100%">
        <VStack width="100%">
          <Flex justifyContent="space-between">
            <Flex width="100%" gap="s-24" alignItems="baseline">
              <GoalCycleFilter
                isFilterButtonUI={false}
                allowEmployeeCycles={false}
                onFilterChange={onCycleChange}
                columnName="cycle__id"
                filter={cycleFilter}
                selector={() =>
                  Promise.resolve({
                    // TODO: this is wrong, after BE refines types needs to be updated
                    //  https://revolut.atlassian.net/browse/REVC-8274
                    options: filteredAvailableCycles as unknown as CycleOption[],
                  })
                }
              />
              {cycle.id && (
                <OverallProgress
                  value={progress}
                  id={values.id}
                  fetchData={fetchGoalGraph}
                />
              )}
              <DataPoint>
                <DataPoint.Value aria-labelledby="goalStatus">
                  <GoalStatusDropdown
                    labelVariant="h5"
                    goal={values}
                    onStatusChanged={status => {
                      values.status = status
                    }}
                  />
                </DataPoint.Value>
                <DataPoint.Label id="goalStatus">Status</DataPoint.Label>
              </DataPoint>
            </Flex>
            <Flex gap="s-6">
              {isTemplate && (
                <Tag variant="outlined" color={Token.color.warning}>
                  Template
                </Tag>
              )}
              {isCascaded && (
                <Tag
                  variant="outlined"
                  color={Token.color.deepGrey}
                  style={{ cursor: 'pointer' }}
                  {...tooltip.getAnchorProps()}
                >
                  Cascaded
                  <Tooltip {...tooltip.getTargetProps()} placement="top" maxWidth={280}>
                    This is a cascaded goal. To refresh the progress, please access the
                    parent goal
                  </Tooltip>
                </Tag>
              )}
              {values.has_latency && (
                <Tag
                  variant="outlined"
                  color={Token.color.deepGrey}
                  style={{ cursor: 'pointer' }}
                  {...tooltip.getAnchorProps()}
                >
                  {chain('Late update', `${values.latency_days} days`)}
                  <Tooltip {...tooltip.getTargetProps()} placement="top" maxWidth={280}>
                    Goal can be updated for {values.latency_days} days after the
                    performance cycle has ended.
                  </Tooltip>
                </Tag>
              )}
            </Flex>
          </Flex>
          <VStack mt="s-8" space="s-8">
            {!!values.parent && (
              <Details style={styleOverrides}>
                <Details.Title>Parent goal</Details.Title>
                <Details.Content>
                  <UIKitLink
                    use={Link}
                    to={pathToUrl(
                      isOnboardingPath()
                        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
                        : ROUTES.FORMS.GOAL.PREVIEW,
                      {
                        id: String(values.parent?.id),
                      },
                    )}
                    target="_blank"
                  >
                    {parent?.name}
                  </UIKitLink>
                </Details.Content>
              </Details>
            )}
            {!!values.description && (
              <Details style={styleOverrides}>
                <Details.Title>Description</Details.Title>
                <Details.Note whiteSpace="pre-line">
                  <TextWidget>
                    {values.description.length > 100 && (
                      <TextWidget.Summary>
                        <Ellipsis tooltip="never">{values.description}</Ellipsis>
                      </TextWidget.Summary>
                    )}
                    <TextWidget.Content>{values.description}</TextWidget.Content>
                  </TextWidget>
                </Details.Note>
              </Details>
            )}
          </VStack>
        </VStack>
      </Cell>

      {values.update_type?.id === 'aggregated' && (
        <ChildGoalsPreviewFormWidget
          parentId={values.id}
          cycle={cycle}
          isTopLevel={values.is_top_level}
        />
      )}
      {showTargets && (
        <TargetsPreviewWidget
          onChange={handleTableSelected}
          cycle={cycle}
          updateType={values.update_type}
        />
      )}
      <IntegrationDataPreviewWidget />
      {roadmapsTable && !!roadmapsTable.data?.length && (
        <RoadmapsWidget
          viewMode
          table={roadmapsTable}
          variant="preview"
          onSelected={r => setSelectedRoadmap(r.id)}
          updateType={values.update_type}
        />
      )}
      {!!values.dashboards?.length && <AnalyticsPreviewWidget />}
      <GoalCommentsWidget goalId={values.id} />

      <RoadmapSidebarForm
        roadmapId={selectedRoadmap}
        onClose={() => setSelectedRoadmap(undefined)}
        onSuccess={() => {
          roadmapsTable && roadmapsTable.refresh()
          setSelectedRoadmap(undefined)
        }}
      />
    </VStack>
  )
}
