import React, { useMemo } from 'react'
import {
  Box,
  Color,
  DetailsCell,
  DetailsCellSkeleton,
  Flex,
  Grid,
  Group,
  Spacer,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { HirePerformanceDistribution } from '@src/interfaces/interviewTool'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'

interface InterviewerProfileHirePerformanceDistributionDetailsProps {
  hirePerformanceDistribution: HirePerformanceDistribution
}

const colors: Record<string, Color> = {
  unsatisfactory: Token.color.warning,
  average_minus: Token.color.deepGrey,
  average_plus: Token.color.blue,
  strong: Token.color.teal,
  exceptional: Token.color.lightGreen,
}

export const InterviewerProfileHirePerformanceDistributionDetails = ({
  hirePerformanceDistribution,
}: InterviewerProfileHirePerformanceDistributionDetailsProps) => {
  const { data: grades, isLoading } = useGetSelectors(selectorKeys.grades)

  const { gridColumns, total, allColumns, visibleColumns } = useMemo(() => {
    const asArr = Object.entries(hirePerformanceDistribution).map(([id, value]) => ({
      id,
      label: grades?.find(grade => grade.id === id)?.name || '',
      value: value * 100,
      color: colors[id],
    }))

    const sum = asArr.reduce<number>((a, b) => a + b.value, 0)

    return {
      allColumns: asArr,
      gridColumns: asArr.reduce<string>((columns, { value }) => {
        if (value > 0) {
          const column = `${value.toFixed(0)}fr`
          return `${columns} ${column}`
        }
        return columns
      }, ''),
      total: sum,
      visibleColumns: asArr.filter(({ value }) => value > 0),
    }
  }, [grades])

  return (
    <Group>
      <DetailsCell>
        <DetailsCell.Title>Hire performance distribution</DetailsCell.Title>
        {isLoading ? (
          <DetailsCellSkeleton.Note />
        ) : (
          <DetailsCell.Note>
            {total === 0 ? (
              'No hiring performance details for interviewer'
            ) : (
              <VStack gap="s-16">
                <Grid columns={gridColumns} columnGap="s-4" width="100%">
                  {visibleColumns.map(({ id, label, value }) => {
                    return (
                      <VStack
                        key={`${id}_count`}
                        height={Token.size.s32}
                        p="s-4"
                        backgroundColor={colors[id]}
                        radius={Token.radius.r6}
                      >
                        <Spacer />
                        <Text
                          aria-label={`Hires with ${label} performance count`}
                          textAlign="center"
                          fontSize={12}
                          use="p"
                          whiteSpace="nowrap"
                        >
                          {value.toFixed(0)}%
                        </Text>
                        <Spacer />
                      </VStack>
                    )
                  })}
                </Grid>
                <Flex columnGap="s-12" rowGap="s-2" flexWrap="wrap">
                  {allColumns.map(({ id, label, color }) => (
                    <Flex key={`Legend ${id}`} alignItems="center" gap="s-4">
                      <Box
                        bg={color}
                        width="s-12"
                        height="s-12"
                        border={`1px solid ${Token.color.greyTone10}`}
                        borderRadius={Token.radius.r2}
                      />
                      <Text fontSize="small" color={Token.color.greyTone50} use="div">
                        {label}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </VStack>
            )}
          </DetailsCell.Note>
        )}
      </DetailsCell>
    </Group>
  )
}
