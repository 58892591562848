import React from 'react'
import {
  Avatar,
  Box,
  chain,
  Flex,
  AvatarProps,
  Text,
  Token,
  useTooltip,
} from '@revolut/ui-kit'
import {
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'

import { InfoOutline } from '@revolut/icons'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { isNumber } from 'lodash'
import { HowGradeIsCalculatedTooltip } from './components/HowGradeIsCalculatedTooltip'
import { useRecommendedGradesContext } from './ScorecardContent'

export interface CardHeaderProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  title: string
  stat?: number
  icon: AvatarProps['useIcon']
  finalRating?: string
  onHelpClick?: VoidFunction
}

export const CardHeader = ({
  data,
  title,
  stat,
  icon,
  finalRating,
  onHelpClick,
}: CardHeaderProps) => {
  const ratingTooltip = useTooltip()
  const recommendedGrades = useRecommendedGradesContext()

  const skillRating =
    ('review_data' in data && data.review_data.functional_skills?.rating) || null

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap="s-16" width="50%">
        <Avatar useIcon={icon} />
        <Flex alignItems="center" gap="s-8">
          <Box>
            {chain(
              <Text variant="primary" fontWeight={400}>
                {title}
              </Text>,
              isNumber(stat) ? (
                <Text
                  variant="primary"
                  fontWeight={400}
                  color={getPercentColor(stat * 100)}
                >
                  {formatPercentage(stat)}
                </Text>
              ) : undefined,
            )}
          </Box>
          {onHelpClick && (
            <HideIfCommercial>
              <InfoOutline
                cursor="pointer"
                size={16}
                color={Token.color.greyTone50}
                onClick={onHelpClick}
              />
            </HideIfCommercial>
          )}
        </Flex>
      </Flex>
      {finalRating && (
        <>
          <Text variant="caption" {...ratingTooltip.getAnchorProps()}>
            {finalRating}
          </Text>
          <HowGradeIsCalculatedTooltip
            skillsGrade={skillRating}
            tooltip={ratingTooltip}
            recommendedGrades={recommendedGrades}
          />
        </>
      )}
    </Flex>
  )
}
