import React, { MouseEvent, useState } from 'react'
import { Avatar, Box, Color, HStack, Item, Text, VStack } from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import InterviewScorecardTemplateSection from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateSection'
import { Plus } from '@revolut/icons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessSettingsInterface } from '@src/interfaces/settings'
import {
  InterviewScorecardEnumSectionIds,
  EnumSectionOptions,
} from '@src/interfaces/interviewScorecardTemplates'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LocationsQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/LocationsQuestionSection'
import SalaryQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/SalaryQuestionSection'
import RightToWorkQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/RightToWorkQuestionSection'
import { ROUTES } from '@src/constants/routes'
import { hiringProcessSettings } from '@src/api/settings'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'

type PredefinedSectionsProps = {
  disableEdit: boolean
}

export const PredefinedSections = ({ disableEdit }: PredefinedSectionsProps) => {
  const { values } = useLapeContext<HiringProcessSettingsInterface>()
  return (
    <VStack gap="s-16">
      <LocationsQuestionSection
        locked={disableEdit}
        actions={
          <HStack gap="s-24">
            <LapeNewSwitch
              label="Optional"
              name="is_location_optional"
              disabled={!values.enable_location_collecting || disableEdit}
            />
            <LapeNewSwitch
              label="Enabled"
              name="enable_location_collecting"
              disabled={disableEdit}
            />
          </HStack>
        }
      />
      <SalaryQuestionSection
        locked={disableEdit}
        actions={
          <HStack gap="s-24">
            <LapeNewSwitch
              label="Optional"
              name="is_compensation_optional"
              disabled={!values.enable_compensation_collecting || disableEdit}
            />
            <LapeNewSwitch
              label="Enabled"
              name="enable_compensation_collecting"
              disabled={disableEdit}
            />
          </HStack>
        }
      />
      <RightToWorkQuestionSection
        locked={disableEdit}
        actions={
          <HStack gap="s-24">
            <LapeNewSwitch
              label="Optional"
              name="is_right_to_work_optional"
              disabled={!values.enable_right_to_work_collecting || disableEdit}
            />
            <LapeNewSwitch
              label="Enabled"
              name="enable_right_to_work_collecting"
              disabled={disableEdit}
            />
          </HStack>
        }
      />
    </VStack>
  )
}

type ScorecardSectionsProps = {
  disableEdit: boolean
}

export const ScorecardSections = ({ disableEdit }: ScorecardSectionsProps) => {
  const { values, errors } = useLapeContext<HiringProcessSettingsInterface>()
  const [editId, setEditId] = useState(0)
  const handleAddSection = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    values.global_sections = [
      ...(values.global_sections || []),
      { section_type: sectionOptions[0], questions: [] },
    ]

    setEditId(values.global_sections.length - 1)
  }
  const predefinedSectionsLength = 3
  return (
    <VStack gap="s-16">
      {values?.global_sections?.map((section, idx) => {
        return (
          <InterviewScorecardTemplateSection
            key={idx}
            isPreview={editId !== idx || disableEdit}
            id={idx}
            editId={editId}
            setEditId={setEditId}
            section={section}
            onChange={val => {
              values.global_sections![idx] = val
            }}
            onDelete={() => {
              values.global_sections = values.global_sections?.filter((_, i) => i !== idx)
            }}
            onCopy={() => {
              values.global_sections?.push({
                ...values.global_sections[idx],
                questions: values.global_sections[idx]?.questions?.map(question => ({
                  ...question,
                  id: undefined,
                })),
                id: undefined,
              })
            }}
            onMove={direction => {
              const offset = direction === 'down' ? 1 : -1
              if (editId === idx) {
                setEditId(idx + offset)
              }
              if (editId === idx + offset) {
                setEditId(idx)
              }
            }}
            sections={values.global_sections!}
            errors={errors?.global_sections?.[idx]}
            sectionOptions={sectionOptions}
            sectionsFieldName="global_sections"
            locked={disableEdit}
            titleIndexNumber={idx + predefinedSectionsLength + 1}
          />
        )
      })}
      {!disableEdit && (
        <Item use="button" type="button" onClick={handleAddSection}>
          <Item.Avatar>
            <Avatar useIcon={Plus} />
          </Item.Avatar>
          <Item.Content color="primary">Add new section</Item.Content>
        </Item>
      )}
    </VStack>
  )
}

const sectionOptions = EnumSectionOptions.filter(
  item => item.id !== InterviewScorecardEnumSectionIds.Level,
)

const ScreenCallScorecardsPage = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeHiringProcessPreferences,
  )

  return (
    <>
      <PageBody mb="s-64">
        <Box mt="s-16">
          <Text variant="h6" color={Color.GREY_TONE_50}>
            Questions you define here will be added to all the scorecards where the type
            is “Screen Call”
          </Text>
        </Box>
        <AutoStepper>
          <NewStepperTitle title="Predefined sections" />
          <PredefinedSections disableEdit={disableEdit} />
          <NewStepperTitle title="Scorecard sections" />
          <ScorecardSections disableEdit={disableEdit} />
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.HIRING_PROCESS_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Screen call scorecards',
    path: ROUTES.SETTINGS.CANDIDATES.SCREEN_CALL_SCORECARDS,
    url: ROUTES.SETTINGS.CANDIDATES.SCREEN_CALL_SCORECARDS,
    canView: [
      PermissionTypes.ViewHiringProcessPreferences,
      PermissionTypes.ChangeHiringProcessPreferences,
    ],
    component: ScreenCallScorecardsPage,
  },
]

export const ScreenCallScorecards = () => {
  return <SettingsForm routes={routes} api={hiringProcessSettings} />
}
