import React from 'react'
import { DataPoint, FlexProps, Token } from '@revolut/ui-kit'
import { metricWidgetStylesProps } from '@src/features/AiInsights/Performance/components/Metrics/common/styles'

interface Props extends Omit<FlexProps, 'label' | 'value'> {
  label: React.ReactNode
  value: React.ReactNode
  metric: React.ReactNode
}

export const SummaryDataPoint = ({ label, value, metric, ...flexProps }: Props) => (
  <DataPoint p="s-12" {...flexProps} {...metricWidgetStylesProps}>
    <DataPoint.Label textAlign="center">{label}</DataPoint.Label>
    <DataPoint.Value textAlign="center">{value}</DataPoint.Value>
    <DataPoint.Label textAlign="center" color={Token.color.greyTone50}>
      {metric}
    </DataPoint.Label>
  </DataPoint>
)
