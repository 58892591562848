import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ActionButton, Placeholder, Widget } from '@revolut/ui-kit'

interface Props {
  onAddClick: VoidFunction
}

export const EmptyView = ({ onAddClick }: Props) => (
  <Widget>
    <Placeholder>
      <Placeholder.Image
        image={{
          default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
          '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
          '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
        }}
      />
      <Placeholder.Title>
        <FormattedMessage
          id="performance.analytics.skills.ratings.comparator.empty.selection.title"
          defaultMessage="No skills added"
        />
      </Placeholder.Title>
      <Placeholder.Description>
        <FormattedMessage
          id="performance.analytics.skills.ratings.comparator.empty.selection.description"
          defaultMessage="Select skills from the list to compare them"
        />
      </Placeholder.Description>
      <Placeholder.Actions>
        <ActionButton useIcon="Plus" onClick={onAddClick}>
          <FormattedMessage
            id="performance.analytics.skills.ratings.comparator.add.skills.cta.label"
            defaultMessage="Select skills"
          />
        </ActionButton>
      </Placeholder.Actions>
    </Placeholder>
  </Widget>
)
