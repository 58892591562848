import React from 'react'
import { List, Token, Text, Box } from '@revolut/ui-kit'
import { InterviewRoundEvaluationItem, TraitType } from '@src/interfaces/interviewTool'
import { CVInsightsListItem } from '@src/features/CVInsightsWidget/components/CVInsightsListItem'

interface CVInsightsListProps {
  evaluations: InterviewRoundEvaluationItem[]
  type: TraitType
  title: string
}

export const CVInsightsList = ({ evaluations, type, title }: CVInsightsListProps) => {
  const isRedFlag = type === 'RED_FLAG'

  const list = evaluations.filter(
    evaluation =>
      evaluation.trait_type === type &&
      (!isRedFlag || (isRedFlag && evaluation.has_evaluation)), // show only those red flags which have evaluation
  )

  if (list.length === 0 && !isRedFlag) {
    return null
  }

  return (
    <Box px="s-16">
      <Text use="div" variant="emphasis1" pb="s-8">
        {title}
      </Text>
      <List variant="compact" color={Token.color.greyTone50}>
        {list?.map(evaluation => (
          <CVInsightsListItem
            isRedFlag={isRedFlag}
            evaluation={evaluation}
            key={evaluation.id}
          />
        ))}
        {list.length === 0 && isRedFlag ? (
          <List.Item useIcon="16/CheckCircle" color={Token.color.success}>
            <Text color={Token.color.greyTone50}>No red flags</Text>
          </List.Item>
        ) : null}
      </List>
    </Box>
  )
}
