import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Copyable,
  HStack,
  Spacer,
  Subheader,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'
import { AiIcon } from '@components/AiIcon/AiIcon'
import {
  SummaryFormatted,
  SummaryVariant,
} from '@src/features/AiInsights/Performance/components/Summary/Summary'

import { metricWidgetStylesProps } from '@src/features/AiInsights/Performance/components/Metrics/common/styles'
import { toString } from '@src/features/AiInsights/Performance/components/Summary/formatters'

interface Props {
  notes: string
  label: string
  title: React.ReactNode
  variant?: 'default' | 'widget'
  summaryVariant?: SummaryVariant
  isCopyable?: boolean
}

export const SummaryNotes = ({ variant = 'default', label, ...contentProps }: Props) => {
  if (variant === 'default') {
    return (
      <Box aria-label={label}>
        <SummaryNotesContent {...contentProps} />
      </Box>
    )
  }

  return (
    <Box p="s-16" {...metricWidgetStylesProps} aria-label={label}>
      <SummaryNotesContent {...contentProps} />
    </Box>
  )
}

const SummaryNotesContent = ({
  notes,
  summaryVariant,
  title,
  isCopyable,
}: Omit<Props, 'variant' | 'label'>) => {
  return (
    <VStack space="s-8">
      <HStack align="center">
        <HStack space="s-4" align="center" width="100%">
          <Text variant="emphasis1" color={Token.color.greyTone50}>
            {title}
          </Text>
          <AiIcon />
        </HStack>
        <Spacer />
        {isCopyable && (
          <Box>
            <Copyable
              value={toString(notes)}
              labelButtonCopy="Copy"
              onCopy={() => {
                pushNotification({
                  type: NotificationTypes.success,
                  duration: SUCCESS_DEFAULT_DURATION,
                  value: (
                    <FormattedMessage
                      id="one-to-one.notes.copy.success.notification.message"
                      defaultMessage="Notes are copied to clipboard"
                    />
                  ),
                })
              }}
              style={{ color: Token.color.greyTone20 }}
            />
          </Box>
        )}
      </HStack>
      <SummaryFormatted summary={notes} variant={summaryVariant} />
    </VStack>
  )
}

export const SummaryNotesHeader = () => (
  <Subheader>
    <Subheader.Title>
      <Text variant="emphasis1" color={Token.color.foreground}>
        <FormattedMessage
          id="one-to-one.meetings.summary.notes.title"
          defaultMessage="Notes"
        />
      </Text>
    </Subheader.Title>
  </Subheader>
)
