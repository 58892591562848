import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Caption,
  Input,
  Paragraph,
  Skeleton,
  Sticky,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { ContactInformationInterface } from '@src/interfaces/bookingLinks'
import { useGetBookingLinkCompany } from '@src/api/bookingLinks'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { ContactInformationErrors } from 'src/features/BookingLinks/Candidate/validation'
import ReEngageConsent from '@components/ReEngageConsent/ReEngageConsent'
import { useCheckPublicReEngagementConsentNeeded } from '@src/api/hiringProcess'
import { useFetchCountries } from '@src/api/recruitment/careers'
import { Country } from '@src/interfaces/selectors'
import { getCurrentCountryCode } from '@src/utils/timezones'
import { DataPrivacyNoticeForCandidates } from '@src/components/DataPrivacyNoticeForCandidates/DataPrivacyNoticeForCandidates'

type ContactInformationProps = {
  contactInformation: ContactInformationInterface
  contactInformationErrors: ContactInformationErrors
  onChange: (
    contactInformation: Partial<ContactInformationInterface>,
    contactInformationErrors?: Partial<ContactInformationErrors>,
  ) => void
  onSubmit: () => void
}

const getUserCountry = (locations?: Country[]) => {
  const region = getCurrentCountryCode()
  const country_code = region?.toUpperCase() ?? 'GB'
  const location = locations?.find(loc => country_code === loc.country_code)
  return location
}

const useCountries = () => {
  const { data, isLoading: loadingCountries } = useFetchCountries()
  const countries = data?.map(({ id, name }) => ({
    id,
    name,
    label: name,
    value: { id, name },
  }))
  return { countries, loadingCountries, userCountry: getUserCountry(data) }
}

export const ContactInformation = ({
  contactInformation,
  contactInformationErrors,
  onChange,
  onSubmit,
}: ContactInformationProps) => {
  const { data: company, isLoading: isCompanyLoading } = useGetBookingLinkCompany()
  const { countries, loadingCountries, userCountry } = useCountries()
  useEffect(() => {
    if (!contactInformation?.country) {
      onChange({
        ...contactInformation,
        country: userCountry,
      })
    }
  }, [userCountry])
  const { data: consentNeededData, isLoading: isConsentLoading } =
    useCheckPublicReEngagementConsentNeeded(contactInformation.country?.id)

  useEffect(() => {
    if (!consentNeededData?.is_consent_required) {
      onChange({
        consent_to_re_engage: undefined,
      })
    }
  }, [consentNeededData?.is_consent_required])

  return (
    <VStack gap="s-16" color={Token.color.foreground}>
      <Text variant="heading2">Contact information</Text>
      {isCompanyLoading ? (
        <Skeleton />
      ) : (
        <Paragraph>Thank you for your interest at {company?.company_name}</Paragraph>
      )}
      <Caption>Please provide your contact information before booking a call</Caption>
      <VStack gap="s-8">
        <Widget p="s-16">
          <VStack gap="s-8">
            <Input
              required
              id="name"
              name="name"
              autoComplete="name"
              label="Full name"
              value={contactInformation.full_name}
              onChange={e => {
                const value = e.currentTarget.value
                onChange(
                  {
                    full_name: value,
                  },
                  {
                    full_name: '',
                  },
                )
              }}
              errorMessage={contactInformationErrors.full_name}
              aria-invalid={!!contactInformationErrors.full_name}
            />
            <Input
              required
              id="email"
              name="email"
              autoComplete="email"
              label="Email"
              value={contactInformation.email}
              onChange={e => {
                const value = e.currentTarget.value
                onChange(
                  {
                    email: value,
                  },
                  {
                    email: '',
                  },
                )
              }}
              errorMessage={contactInformationErrors.email}
              aria-invalid={!!contactInformationErrors.email}
            />
            <RadioSelectInput
              required
              label="Country"
              value={
                contactInformation?.country
                  ? {
                      id: contactInformation?.country?.id,
                      name: contactInformation?.country?.name,
                    }
                  : undefined
              }
              onChange={option => {
                if (option) {
                  onChange({
                    country: option as IdAndName,
                  })
                }
              }}
              options={countries}
              loading={loadingCountries}
            />
            <Input
              label="LinkedIn profile link (optional)"
              value={contactInformation?.links?.[0]}
              onChange={e => {
                const value = e.currentTarget.value
                onChange(
                  {
                    links: value ? [value] : undefined,
                  },
                  {
                    link: '',
                  },
                )
              }}
              errorMessage={contactInformationErrors.link}
              aria-invalid={!!contactInformationErrors.link}
            />
          </VStack>
        </Widget>
        <ReEngageConsent
          loading={isConsentLoading}
          consentNeeded={consentNeededData?.is_consent_required}
          checked={contactInformation.consent_to_re_engage}
          onCheck={checked => {
            onChange({
              consent_to_re_engage: checked,
            })
          }}
        />
      </VStack>
      <Sticky bottom={0}>
        <Box bg={Token.color.layoutBackground} pt="s-8">
          <Box mb="s-16">
            <DataPrivacyNoticeForCandidates />
          </Box>
          <Button
            onClick={() => {
              onSubmit()
            }}
          >
            Proceed to booking
          </Button>
        </Box>
      </Sticky>
    </VStack>
  )
}

export default ContactInformation
