import React, { useEffect } from 'react'
import addYears from 'date-fns/addYears'
import capitalize from 'lodash/capitalize'
import { Input, InputGroup, Link } from '@revolut/ui-kit'

import { Line } from '@components/CommonSC/Line'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@components/Page/PageActions'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { navigateReplace } from '@src/actions/RouterActions'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useGetProbationTemplates } from '@src/api/probationTemplate'
import {
  useGetContractsSettings,
  useGetEmployeeSettings,
  useGetOrganisationSettings,
} from '@src/api/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { selectorKeys } from '@src/constants/api'
import { CONFLUENCE_CONTRACT_TYPES_DOCUMENTATION } from '@src/constants/externalLinks'
import { ROUTES } from '@src/constants/routes'
import { ContractTypeOption } from '@src/features/Contracts/ContractTypeOption'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeSenioritySelector from '@src/features/SenioritySelector/LapeSenioritySelector'
import { ClosingSubscriptionPageWall } from '@src/features/Subscriptions/ClosingSubscriptionPageWall'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useSubsenioritySelector } from '@src/hooks/useSubsenioritySelector'
import {
  ContractorInterface,
  EmployeeContractType,
  EmployeeInterface,
  EmployeeType,
  IdStatuses,
  ProbationTemplateOptionInterface,
} from '@src/interfaces/employees'
import { renderTemplatesSelectorOption } from '@src/pages/Forms/ProbationTemplate/common'
import { updateProbationEndDate } from '@src/utils/employees'
import { pathToUrl } from '@src/utils/router'
import { getDate, getTimeFromString } from '@src/utils/timezones'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getRoleSpecialisationTitle } from '@src/features/Roles/helpers'
import { CountryAvatar } from '@src/components/CountryAvatar/CountryAvatar'

const isExternal = (
  _values: EmployeeInterface | ContractorInterface,
  type: EmployeeType,
): _values is ContractorInterface => type === 'external'

const formatDateTime = (date: Date, prevDateTime?: string | null) => {
  const time = prevDateTime ? getTimeFromString(prevDateTime) : '18:00'
  return `${getDate(date)}T${time}`
}

interface Props {
  type: EmployeeType
  onEmployeeTypeChange: (type: EmployeeType) => void
}

export type ProbationTemplatesOption = {
  id?: string | number
  name?: string
  amount_of_months: number
}

export const CreateEmployeeForm = ({ onEmployeeTypeChange, type }: Props) => {
  const form = useLapeContext<EmployeeInterface | ContractorInterface>()
  const { values, initialValues } = form

  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const { data: settings } = useGetEmployeeSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: contractSettings } = useGetContractsSettings()
  const { data: templates } = useGetProbationTemplates({
    seniority_id: values.seniority?.id,
    contract_type: values.contract_type?.id,
    employee_type: type,
    specialisation_id: values.specialisation?.id,
    team_id: values.team?.id,
    location_id: values.location?.id,
    entity_id: values.entity?.id,
  })
  const isCommercial = useIsCommercial()

  useEffect(() => {
    if (values.first_name && values.last_name) {
      values.display_name = `${values.first_name} ${values.last_name}`
    }
  }, [values.first_name, values.last_name])

  useEffect(() => {
    if (values.contract_type?.employee_type) {
      onEmployeeTypeChange(values.contract_type.employee_type)
    }
  }, [values.contract_type])

  useEffect(() => {
    if (!values.probation_template && templates) {
      values.probation_template = templates?.find(option => option.is_eligible)
    }
  }, [templates])

  const subsenioritySelector = useSubsenioritySelector<
    EmployeeInterface | ContractorInterface
  >(values, initialValues)
  const onAfterCreateUser = ({ id }: { id?: number }) => {
    if (id) {
      navigateReplace(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }))
    }
  }

  return (
    <ClosingSubscriptionPageWall>
      <PageBody mt="-s-32">
        <AutoStepper>
          <NewStepperTitle title="Legal names" />
          <InputGroup>
            <LapeNewInput
              name="first_name"
              label="Legal first name"
              description="Your first name as it appears on your identification documents"
              required
            />
            <LapeNewInput
              name="middle_name"
              label="Middle name"
              description="Your middle name as it appears on your identification documents"
            />
            <LapeNewInput
              name="last_name"
              label="Legal last name"
              description="Your last name as it appears on your identification documents"
              required
            />
          </InputGroup>

          <NewStepperTitle title="Preferred name" />
          <LapeNewInput
            name="display_name"
            label="Preferred name"
            description="The name you prefer to be called e. g. your legal first name is Jonathan, but your friends call you Jon"
          />

          <NewStepperTitle title="Work email" />
          <LapeNewInput name="email" label="Work email" required />

          <NewStepperTitle
            title="Work"
            subtitle={
              type === 'internal'
                ? 'When a contract in the employee’s Contracts tab becomes active, this section is automatically updated with the details of the new contract'
                : undefined
            }
          />
          <InputGroup>
            <LapeRadioSelectInput<EmployeeContractType>
              name="contract_type"
              label="Contract type"
              selector={selectorKeys.contract_types}
              message={
                <>
                  The nature of the relationship between the person and our organisation.
                  <HideIfCommercial>
                    For more information about the meaning of each option please consult{' '}
                    <Link href={CONFLUENCE_CONTRACT_TYPES_DOCUMENTATION} target="_blank">
                      this Confluence page.
                    </Link>
                  </HideIfCommercial>
                </>
              }
              onAfterChange={() => {
                values.probation_template = undefined
              }}
            >
              {option => <ContractTypeOption option={option} />}
            </LapeRadioSelectInput>
            {type && (
              <Input
                label="Employee type"
                value={capitalize(type)}
                description="This is determined by the contract type. Internal employees have access to our systems whilst external employees cannot access internal systems, unless specified otherwise."
                disabled
              />
            )}
            {values?.id && (
              <LapeRadioSelectInput
                name="status"
                label="Status"
                selector={selectorKeys.employee_status}
              />
            )}

            <Line />

            <LapeRadioSelectInput
              name="specialisation"
              label={getRoleSpecialisationTitle(specialisationsEnabled)}
              selector={selectorKeys.specialisations}
              message="Function served by an employee in the company"
              clearable
              onAfterChange={() => {
                values.probation_template = undefined
              }}
            />
            {type === 'internal' && (
              <>
                <LapeSenioritySelector
                  name="seniority"
                  specialisationId={values.specialisation?.id || null}
                  label="Seniority"
                  message="Required level of expertise or prospective level of authority"
                  clearable
                  required
                  onAfterChange={() => {
                    values.probation_template = undefined
                  }}
                />
                {!!organisationSettings?.enable_multiple_levels_per_seniority && (
                  <LapeRadioSelectInput
                    name="specialisation_seniority_sublevel"
                    label="Seniority Level"
                    selector={subsenioritySelector}
                  />
                )}
                {!!settings?.enable_job_title &&
                  (isCommercial ? (
                    <LapeNewInput
                      name="job_title"
                      label="Job title"
                      description="Enter a job title"
                    />
                  ) : (
                    <LapeRadioSelectInput<{ id: string; name?: string }>
                      name="job_title"
                      label="Job title"
                      message="Enter a new title or select one from the list"
                      selector={selectorKeys.all_job_titles}
                      value={
                        (values as EmployeeInterface).job_title
                          ? {
                              id: (values as EmployeeInterface).job_title,
                            }
                          : undefined
                      }
                      onChange={selector => {
                        if (selector?.name) {
                          ;(values as EmployeeInterface).job_title = selector.name
                        }
                      }}
                      clearable={false}
                    />
                  ))}
              </>
            )}

            <Line />

            <LapeRadioSelectInput
              name="team"
              label="Team"
              selector={selectorKeys.team}
              onAfterChange={() => {
                values.probation_template = undefined
              }}
            />
            <LapeRadioSelectInput
              name="line_manager"
              label={type === 'internal' ? 'Line manager' : 'Point of contact 1'}
              selector={selectorKeys.employees_for_teams}
            />
            {type === 'internal' ? (
              <>
                <LapeRadioSelectInput
                  name="quality_control"
                  label="Functional manager"
                  selector={selectorKeys.employees_for_teams}
                  optional={values.status?.id !== IdStatuses.pending}
                  message="Sets the quality standards and expectations for the employee. Must be different from the Line Manager."
                />
                {!!settings?.enable_buddy && (
                  <LapeRadioSelectInput
                    name="mentor"
                    label="Buddy"
                    selector={selectorKeys.all_employees}
                    optional={values.status?.id !== IdStatuses.pending}
                    clearable
                  />
                )}
              </>
            ) : (
              <LapeRadioSelectInput
                name="quality_control"
                label="Point of contact 2"
                selector={selectorKeys.employees_for_teams}
              />
            )}

            <Line />

            <LapeRadioSelectInput
              name="location"
              label="Location"
              selector={selectorKeys.location}
              onAfterChange={() => {
                values.probation_template = undefined
              }}
            />
            <LapeRadioSelectInput
              name="entity"
              label="Entity"
              selector={selectorKeys.active_entities}
              optional
              onAfterChange={() => {
                values.probation_template = undefined
              }}
            />

            <LapeDatePickerInput
              name="joining_date_time"
              label="Start date"
              message="According to the contract. This is provisional until the employee starts."
              onAfterChange={value => {
                if (
                  value != null &&
                  values.probation_template?.duration &&
                  values.probation_template.duration_time_unit
                ) {
                  updateProbationEndDate({
                    startDateTimestamp: value,
                    timeframe: {
                      unit: values.probation_template.duration_time_unit.id,
                      length: values.probation_template.duration,
                    },
                    values,
                  })
                }
              }}
              required
            />
            <LapeRadioSelectInput
              name="probation_template"
              label="Select probation template"
              inputProps={{
                renderPrefix: () => (
                  <CountryAvatar country={values.probation_template?.country} />
                ),
              }}
              options={templates?.map(item => ({
                label: item.name,
                value: item,
              }))}
              onAfterChange={(option: ProbationTemplateOptionInterface | null) => {
                if (
                  option &&
                  values.joining_date_time &&
                  option.duration &&
                  option.duration_time_unit
                ) {
                  updateProbationEndDate({
                    startDateTimestamp: values.joining_date_time,
                    timeframe: {
                      unit: option.duration_time_unit.id,
                      length: option.duration,
                    },
                    values,
                  })
                }
              }}
              disableOptionRule={option => !option.value.is_eligible}
              clearable
            >
              {renderTemplatesSelectorOption}
            </LapeRadioSelectInput>

            {performanceSettings?.enable_probation && type === 'internal' && (
              <LapeDatePickerInput
                name="end_of_probation_date_time"
                label="Probation end date"
                message={
                  isCommercial
                    ? undefined
                    : 'Mandatory for internal employees only, according to the contract and provisional until the employee starts. Not applicable for external employees.'
                }
                disabledDays={{
                  before: new Date(values.joining_date_time),
                }}
                disabled={Boolean(values.probation_template)}
                required
              />
            )}

            {type === 'external' && (
              <>
                <NewStepperTitle title="Termination details" />
                <InputGroup>
                  <LapeDatePickerInput
                    clearable
                    required={!!contractSettings?.force_termination_enabled}
                    onChange={value => {
                      if (!isExternal(values, type)) {
                        return
                      }
                      if (value) {
                        const terminationDateTime = formatDateTime(
                          value,
                          values.termination?.termination_date_time,
                        )
                        if (values.termination) {
                          values.termination.termination_date_time = terminationDateTime
                        } else {
                          values.termination = {
                            termination_date_time: terminationDateTime,
                          }
                        }
                      } else {
                        values.termination = undefined
                      }
                    }}
                    disabledDays={{
                      after: addYears(new Date(), 1),
                    }}
                    name="termination.termination_date_time"
                    label="Termination date"
                    message={
                      isCommercial
                        ? undefined
                        : 'For external employees the termination date has to be set in advance. ' +
                          'Please select the period of time up to one year ahead starting form the start date.'
                    }
                  />
                  <InputGroup variant="horizontal">
                    <LapeNewTimeInput
                      name="termination.termination_date_time"
                      label="Time"
                      required
                    />
                    <LapeRadioSelectInput
                      selector={selectorKeys.timezones}
                      label="Timezone"
                      name="termination.termination_date_timezone"
                    />
                  </InputGroup>
                  <LapeRadioSelectInput
                    name="termination.termination_type"
                    label="Termination type"
                    selector={selectorKeys.termination_type}
                  />
                </InputGroup>
              </>
            )}
          </InputGroup>

          {!!settings?.enable_notes && (
            <>
              <NewStepperTitle
                title="Notes"
                subtitle={`This section is visible for HR managers and super admins only. It is not visible for ${
                  values.first_name || 'this employee'
                }.`}
              />
              <InputGroup>
                <LapeNewTextArea
                  label="Notes"
                  name="info"
                  description="Leave any notes regarding the employees work profile"
                />
                <LapeRadioSelectInput
                  name="work_authorisation_status"
                  label="Work authorisation status"
                  selector={selectorKeys.employee_work_authorisation_statuses}
                />
                <LapeRadioSelectInput
                  name="relocation_type"
                  label="Relocation type"
                  selector={selectorKeys.employee_relocation_types}
                />
              </InputGroup>
            </>
          )}
          <NewStepperTitle title="Contact info" />
          <InputGroup>
            <LapeNewInput
              name="personal_info.personal_email"
              label="Personal email"
              required
            />
            <LapePhoneInput
              prefixName="personal_info.phone_country_code"
              phoneName="personal_info.phone_number_short"
              required
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          errorHandler={e => {
            // BE maps returned error data inconsistently
            if (e.response?.data?.personal_email) {
              e.response.data.personal_info = {
                personal_email: e.response.data.personal_email,
              }
              delete e.response.data.personal_email
            }
            throw e
          }}
          successText="Employee successfully created."
          onAfterSubmit={onAfterCreateUser}
          useValidator
        />
      </PageActions>
    </ClosingSubscriptionPageWall>
  )
}
