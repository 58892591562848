import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FilterButton, Token } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { getResignationsList, useResignationsStats } from '@src/api/employeeResignation'
import { LOCAL_STORAGE } from '@src/constants/api'
import {
  createdOnColumn,
  hrManagerColumn,
  isKeyPersonColumn,
  isRegrettableColumn,
  lineManagerColumn,
  noticePeriodColumn,
  resignatingEmployeeNameColumn,
  resignationStatusColumn,
  roleNameColumn,
  seniorityNameColumn,
} from '@src/constants/columns/resignation'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const rowResignation = (
  specialisationsEnabled: boolean,
): RowInterface<EmployeeResignationInterface> => ({
  hideAddButton: true,
  cells: [
    {
      ...resignatingEmployeeNameColumn,
      width: 200,
    },
    {
      ...roleNameColumn(specialisationsEnabled),
      width: 140,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...isKeyPersonColumn,
      width: 70,
    },
    {
      ...isRegrettableColumn,
      width: 70,
    },
    {
      ...lineManagerColumn,
      width: 70,
    },
    {
      ...createdOnColumn,
      width: 70,
    },
    {
      ...noticePeriodColumn,
      width: 100,
    },
    {
      ...resignationStatusColumn,
      width: 100,
    },
    {
      ...hrManagerColumn,
      width: 100,
    },
  ],
})

const ResignationsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_RESIGNATION_ASSIGNED_TO_ME,
    false,
  )

  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'hr_manager',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const row = useMemo(
    () => rowResignation(specialisationsEnabled),
    [specialisationsEnabled],
  )

  const table = useTable<EmployeeResignationInterface>(
    { getItems: getResignationsList },
    getInitialFilters(),
  )
  const { data: stats } = useResignationsStats()

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Pending review" val={stats?.total_headcount} mr="s-32" />
        <Stat
          label="Pending notice"
          val={stats?.pending_notice_headcount}
          mr="s-32"
          color={Token.color.warning}
        />
        <Stat
          label="Notice pending review"
          val={stats?.notice_pending_review_headcount}
          mr="s-32"
          color={Token.color.warning}
        />
        <Stat
          label="Pending completion"
          val={stats?.pending_review_headcount}
          mr="s-32"
          color={Token.color.warning}
        />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <Table.Widget.MoreBar>
          <FilterButton active={assignedToMe} onClick={onToggleAssignedToMe}>
            Assigned to me
          </FilterButton>
        </Table.Widget.MoreBar>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<EmployeeResignationInterface>
          hideCount
          name={TableNames.Resignations}
          useWindowScroll
          row={row}
          {...table}
          noDataMessage="Resignating employees will appear here."
          onRowClick={({ id, employee }) => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD, {
                employeeId: employee.id,
                id,
              }),
            )
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default ResignationsTable
