import Form from '@src/features/Form/Form'
import { lookerIntegrationPreferences } from '@src/api/integrations'
import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { Group, Item } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LookerIntegrationInterface } from '@src/interfaces/integrations'
import { navigateTo } from '@src/actions/RouterActions'
import { LookerOauthIntegration } from './LookerOauthIntegration'

export const LookerIntegration = () => {
  return (
    <Form api={lookerIntegrationPreferences} forceParams={{ id: '1' }}>
      <LookerForm />
    </Form>
  )
}

const LookerForm = () => {
  const queryClient = useQueryClient()
  const { values } = useLapeContext<LookerIntegrationInterface>()

  if (values.method?.id === 'basic') {
    return (
      <>
        <PageWrapper>
          <PageHeader
            title="Looker"
            subtitle="Collaboration"
            backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
          />
          <PageBody mb="s-64" mt="s-16">
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Looker integration',
                  description: 'Enable integration with Looker for all features.',
                }}
                name="enabled"
              />
              {values.enabled && (
                <Item
                  use="button"
                  variant="disclosure"
                  onClick={() => navigateTo(ROUTES.SETTINGS.ANALYTICS)}
                >
                  <Item.Content>
                    <Item.Title>Manage Looker connections</Item.Title>
                  </Item.Content>
                </Item>
              )}
            </Group>
          </PageBody>
        </PageWrapper>
        <PageActions>
          <NewSaveButtonWithPopup
            successText="Settings saved successfully"
            onAfterSubmit={() => {
              queryClient.invalidateQueries(API.LOOKER_INTEGRATION_PREFERENCES)
            }}
            useValidator
          />
        </PageActions>
      </>
    )
  }
  return <LookerOauthIntegration />
}
