import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useTable } from '@src/components/TableV2/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { RouteParams } from '.'
import { getTimeOffRequestChangelog } from '@src/api/timeOff'
import {
  changelogChangedByWithAvatar,
  changelogChangedOn,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogFieldsOldValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { selectorKeys } from '@src/constants/api'
import { CellTypes } from '@src/interfaces/data'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'

const row = {
  cells: [
    {
      ...changelogFieldsChangedFields(),
      selectorsKey: selectorKeys.time_off_request_changelog_fields,
      width: 200,
    },
    {
      ...changelogFieldsOldValue,
      width: 200,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 150,
    },
    {
      ...changelogChangedOn,
      title: 'Changed on',
      type: CellTypes.dateTime,
      width: 120,
    },
    {
      ...changelogStatus,
      selectorsKey: selectorKeys.time_off_request_changelog_statuses,
      width: 100,
    },
  ],
}

type Props = {
  setCategoryName: (name: string) => void
}
const Changelog = ({ setCategoryName }: Props) => {
  const { id } = useParams<RouteParams>()

  const { values } = useLapeContext<EmployeeTimeOffRequestInterface>()
  const table = useTable({ getItems: getTimeOffRequestChangelog(id) })

  useEffect(() => {
    setCategoryName(values.balance?.policy?.category?.name || 'Unknown request category')
  }, [])

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.EmployeeTimeOffChangelog}
          row={row}
          {...table}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Changelog
