import { IconName } from '@revolut/ui-kit'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { ItemType } from '@src/interfaces/deliverables'

export const epicTypeToIcon: Record<ItemType, IconName | undefined> = {
  [ItemType.Clickup]: 'ClickUp|image',
  [ItemType.Manual]: undefined,
  [ItemType.Jira]: 'Jira|image',
  [ItemType.Linear]: undefined,
  [ItemType.Notion]: undefined,
  [ItemType.Monday]: undefined,
}

export const commonRoadmapSorting = [
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]
