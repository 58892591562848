import { BottomSheet, Box, Button, Header, InputGroup, MoreBar } from '@revolut/ui-kit'
import React, { useRef, useState } from 'react'

import { AccessGroupSelectorOption } from '@components/AccessGroup/AccessGroupSelectorOption'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateTo } from '@src/actions/RouterActions'
import { createEmployeeSimple, employeesArchive } from '@src/api/employees'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { API, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { CreateDepartmentPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDepartment'
import { CreateEntityPopup } from '@src/features/GenericEditableTable/CellPopups/CreateEntity'
import { CreateLocationPopup } from '@src/features/GenericEditableTable/CellPopups/CreateLocation'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { CreateTeamPopup } from '@src/features/GenericEditableTable/CellPopups/CreateTeam'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { IdAndName } from '@src/interfaces'
import {
  EmployeeSimpleStatsFormattedInterface,
  EmployeesSimpleCreateInterface,
  EmployeesSimpleInterface,
} from '@src/interfaces/employees'
import { useOnboardingContext } from '@src/pages/OnboardingChecklistV2/components/OnboardingContext'
import { pathToUrl } from '@src/utils/router'
import { useQueryClient } from 'react-query'
import { CreateNewItemType, EditableEmployeesTableProps } from '.'
import { employeesSimpleTableFilterByInitial } from './constants'

export const TableView = ({
  getRow,
  hiddenColumns = {},
  sessionRoute,
  isDetailed,
  renderEditActionsLeft,
  renderEditActionsRight,
  useMainAddForm,
  bulkSessionFieldsMap,
  table,
  tableInfo,
  onAfterRefresh,
}: EditableEmployeesTableProps) => {
  const { setNextButtonState } = useOnboardingContext()
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    onChangeAction: (entity: IdAndName) => void
  }>()
  const [addEmployeePopupOpen, setAddEmployeePopupOpen] = useState(false)

  const tableRefreshRef = useRef<() => void>()

  const onCreateNew = (
    type: CreateNewItemType,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, onChangeAction })
  }

  return (
    <>
      <GenericEditableTable<
        EmployeesSimpleInterface,
        EmployeeSimpleStatsFormattedInterface
      >
        apiEndpoint={API.EMPLOYEES_SIMPLE}
        tableName={TableNames.ImportEmployeesV2}
        row={getRow(onCreateNew)}
        entity="employee"
        variant="existingEntities"
        hiddenColumns={hiddenColumns}
        sortByInitial={[{ sortBy: 'email', nonResettable: true }]}
        filterByInitial={employeesSimpleTableFilterByInitial}
        table={table}
        tableInfo={tableInfo}
        onAfterRefresh={onAfterRefresh}
        onChangeValidationState={state => {
          if (table?.loading) {
            setNextButtonState('disabled')
            return
          }
          if (state === 'valid') {
            setNextButtonState('active')
          } else if (state === 'invalid') {
            setNextButtonState('disabled')
          }
        }}
        existingEntitiesDeleteHandler={employeesArchive}
        tableActions={tableActionsProps => (
          <>
            <MoreBar.Action
              useIcon="Plus"
              onClick={
                useMainAddForm
                  ? () => navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.NEW))
                  : () => {
                      setAddEmployeePopupOpen(true)
                      tableRefreshRef.current = tableActionsProps.refreshTableState
                    }
              }
            >
              Add employee
            </MoreBar.Action>

            <>
              {renderEditActionsLeft?.(tableActionsProps)}
              <BulkEditExistingEntitiesAction
                sessionRoute={sessionRoute}
                buttonIcon="Suitcase"
                field="team"
                selector={selectorKeys.team}
                {...tableActionsProps}
                apiEndpoint={API.EMPLOYEE_UPLOADS}
                fieldsForBulkEdit={bulkSessionFieldsMap.team}
                onCreateNew={onChangeAction => {
                  setCreateNewTypeState({ type: 'team', onChangeAction })
                }}
              />
              <BulkEditExistingEntitiesAction
                sessionRoute={sessionRoute}
                buttonIcon="RepairTool"
                field="specialisation"
                selector={selectorKeys.specialisations}
                {...tableActionsProps}
                apiEndpoint={API.EMPLOYEE_UPLOADS}
                label="role"
                fieldsForBulkEdit={bulkSessionFieldsMap.specialisation}
                onCreateNew={onChangeAction => {
                  setCreateNewTypeState({ type: 'role', onChangeAction })
                }}
              />
              <BulkEditExistingEntitiesAction
                sessionRoute={sessionRoute}
                buttonIcon="ArrowRightLeft"
                field="seniority"
                selector={selectorKeys.seniority}
                {...tableActionsProps}
                apiEndpoint={API.EMPLOYEE_UPLOADS}
                fieldsForBulkEdit={bulkSessionFieldsMap.seniority}
              />
              {renderEditActionsRight?.(tableActionsProps)}
            </>
          </>
        )}
      />

      <CreateEmployeePopup
        isDetailed={!!isDetailed}
        open={addEmployeePopupOpen}
        onSuccess={() => {
          tableRefreshRef.current?.()
          setAddEmployeePopupOpen(false)
        }}
        onClose={() => {
          setAddEmployeePopupOpen(false)
        }}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateEntityPopup
        open={createNewTypeState?.type === 'companyEntity'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateLocationPopup
        open={createNewTypeState?.type === 'location'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
        showSuccessPopup={false}
      />

      <CreateDepartmentPopup
        open={createNewTypeState?.type === 'department'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}

interface CreateEmployeePopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
  isDetailed: boolean
}

const CreateEmployeePopup = ({
  open,
  onSuccess,
  onClose,
  isDetailed,
}: CreateEmployeePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add employee</Header.Title>
      </Header>

      <LapeForm<EmployeesSimpleCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createEmployeeSimple(form.values)
            .then(() => {
              onSuccess()
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateEmployeeForm isDetailed={isDetailed} />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateEmployeeForm = ({ isDetailed }: { isDetailed: boolean }) => {
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    onChangeAction: (entity: IdAndName) => void
  }>()

  const { isSubmitting, submit, values } =
    useLapeContext<EmployeesSimpleCreateInterface>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="email" label="Email" required />
        <LapeNewInput name="first_name" label="First name" required />
        <LapeNewInput name="last_name" label="Last name" required />
        <LapeRadioSelectInput<IdAndName<string | number>>
          name="team"
          label="Team"
          selector={selectorKeys.team}
          showCreateNewButton
          onChange={option => {
            if (option?.id === createNewKey) {
              setCreateNewTypeState({
                type: 'team',
                onChangeAction: entity => {
                  values.team = entity
                },
              })
            } else if (option) {
              values.team = option
            }
          }}
          useQuery
        />
        {isDetailed && (
          <>
            <LapeRadioSelectInput
              name="location"
              label="Location"
              selector={selectorKeys.location}
            />
            <LapeRadioSelectInput
              name="line_manager"
              label="Line manager"
              selector={selectorKeys.all_employees_avatar_email}
            />
          </>
        )}
        <InputGroup variant="horizontal">
          <Box width="60%">
            <LapeRadioSelectInput<{
              id: number | string
              name: string
              status?: 'archived'
            }>
              name="specialisation"
              label="Role"
              selector={selectorKeys.specialisations}
              filter={i => i.status !== 'archived'}
              showCreateNewButton
              onChange={option => {
                if (option?.id === createNewKey) {
                  setCreateNewTypeState({
                    type: 'role',
                    onChangeAction: entity => {
                      values.specialisation = entity
                    },
                  })
                } else if (option) {
                  values.specialisation = option
                }
              }}
              useQuery
            />
          </Box>
          <Box width="40%">
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
            />
          </Box>
        </InputGroup>
        <LapeRadioSelectInput
          name="access_groups"
          label="Access group"
          selector={selectorKeys.groups}
        >
          {option => <AccessGroupSelectorOption {...option.value} />}
        </LapeRadioSelectInput>
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error =>
              errorPopup.show({
                error,
                fallbackTitle: 'Failed to create employee',
              }),
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={specialisation => {
          queryClient.invalidateQueries([
            API.SELECTORS,
            'v1',
            { params: { name: selectorKeys.specialisations } },
          ])
          values.specialisation = specialisation
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          queryClient.invalidateQueries([
            API.SELECTORS,
            'v1',
            { params: { name: selectorKeys.team } },
          ])
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}
