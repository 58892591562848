import React, { useState } from 'react'
import {
  BottomSheet,
  Header,
  Button,
  Icon,
  Token,
  VStack,
  Text,
  Checkbox,
  DetailsCell,
  HStack,
  Grid,
  Item,
  Switch,
  Cell,
  Avatar,
} from '@revolut/ui-kit'
import { GoalsInterface, GoalUpdateType, PerformanceType } from '@src/interfaces/goals'
import Tooltip from '@components/Tooltip/Tooltip'
import { LatencyDaysSelect } from './LatencyDaysSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'

export interface AdvancedSettingsPayload {
  mandatorySetting: boolean
  childProgressGoal: boolean
  latencyDays: number | null
  allowLateUpdates: boolean
}

interface Props {
  open: boolean
  performanceType: PerformanceType
  updateType?: GoalUpdateType
  onConfirm: (payload: AdvancedSettingsPayload) => void
  onCancel: () => void
  latencyDays: number | null
}

export const AdvancedSettingsPopup = ({
  open,
  onCancel,
  performanceType,
  onConfirm,
  latencyDays,
  updateType,
}: Props) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSettingsPayload>({
    mandatorySetting: performanceType?.id === 'mandatory',
    childProgressGoal: updateType === 'aggregated',
    latencyDays,
    allowLateUpdates: Boolean(latencyDays),
  })

  const onChangeSetting = (
    key: keyof typeof advancedSettings,
    value: number | boolean | null,
  ) => {
    setAdvancedSettings(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  return (
    <BottomSheet size="lg" open={open} onClose={onCancel}>
      <Header>
        <Header.Title>Advanced metric settings</Header.Title>
        <Header.Actions>
          <Avatar useIcon="Gear" />
        </Header.Actions>
      </Header>
      <BottomSheet.Description>
        <VStack gap="s-16">
          {values.content_type?.model !== 'employees' && (
            <Item>
              <Item.Content>
                <Item.Title>Use child goals for progress</Item.Title>
                <Item.Description>
                  When enabled, goal progress is calculated based on the progress of its
                  child goals.
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <Item.Value>
                  <Switch
                    aria-label="Child Progress"
                    checked={advancedSettings.childProgressGoal}
                    onChange={event =>
                      onChangeSetting('childProgressGoal', event.target.checked)
                    }
                  />
                </Item.Value>
              </Item.Side>
            </Item>
          )}
          <VStack gap="s-12">
            <Text variant="heading3">Metric attributes</Text>
            <DetailsCell>
              <DetailsCell.Title>
                <HStack gap="s-16" align="center">
                  <Checkbox
                    data-testid="mandatory-goal"
                    checked={advancedSettings.mandatorySetting}
                    onChange={event =>
                      onChangeSetting('mandatorySetting', event.target.checked)
                    }
                  />
                  <Text variant="emphasis1" color={Token.color.foreground}>
                    Mandatory goal
                  </Text>
                </HStack>
              </DetailsCell.Title>
              <DetailsCell.Content>
                <Tooltip
                  placement="bottom"
                  text="Mandatory goals must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
                >
                  <Icon name="InfoOutline" size={24} />
                </Tooltip>
              </DetailsCell.Content>
            </DetailsCell>
          </VStack>
          <VStack gap="s-12">
            <HStack gap="s-4">
              <Text variant="heading3">Late update</Text>
              <Tooltip
                placement="top"
                text="When enabled, goal owners can update results for a specified number of days after the cycle ends. 
                Useful for goals that require additional time to finalise results"
              >
                <Icon name="InfoOutline" size={24} />
              </Tooltip>
            </HStack>

            <Grid columns="2fr 1fr" columnGap="s-16">
              <Cell>
                <HStack gap="s-16" align="center">
                  <Checkbox
                    data-testid="latency-goal"
                    checked={advancedSettings.allowLateUpdates}
                    onChange={event => {
                      onChangeSetting('allowLateUpdates', event.target.checked)
                      if (!event.target.checked) {
                        onChangeSetting('latencyDays', null)
                      }
                    }}
                  />
                  <Text variant="emphasis1" color={Token.color.foreground}>
                    Allow late updates
                  </Text>
                </HStack>
              </Cell>
              <LatencyDaysSelect
                disabled={!advancedSettings.allowLateUpdates}
                value={advancedSettings.latencyDays}
                onChange={(value: number | null) => onChangeSetting('latencyDays', value)}
              />
            </Grid>
          </VStack>
        </VStack>
      </BottomSheet.Description>
      <BottomSheet.Actions horizontal>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm(advancedSettings)
          }}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
