import React from 'react'
import { BottomSheet, Button, InputGroup } from '@revolut/ui-kit'

import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import {
  MergeSpecialisationPayloadInterface,
  MergeSpecialisationResponseInterface,
} from '@src/interfaces/updateOrganisationStructure'
import { mergeSpecialisations } from '@src/api/updateOrganisationStructure'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { Id } from '@src/interfaces'

interface Props {
  unitsToMerge: Id[]
  onSuccess: (data: MergeSpecialisationResponseInterface) => void
}

export const MergeSpecialsations = ({ unitsToMerge, onSuccess }: Props) => {
  return (
    <LapeForm<MergeSpecialisationPayloadInterface>
      onSubmit={form =>
        mergeSpecialisations({ ...form.values, specialisations: unitsToMerge }).then(
          response => {
            onSuccess(response.data)
            return form.values
          },
        )
      }
    >
      <FormValidatorProvider>
        <MergeRolesForm />
      </FormValidatorProvider>
    </LapeForm>
  )
}

const MergeRolesForm = () => {
  const { isSubmitting, submit } = useLapeContext<MergeSpecialisationPayloadInterface>()
  const { validate } = useSafeFormValidator()
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="name"
          label={`New ${isSpecialisationsEnabled ? 'specialisation' : 'role'} name`}
          required
        />
      </InputGroup>
      <BottomSheet.Actions>
        <Button onClick={validate(() => submit())} pending={isSubmitting} elevated>
          Save
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
