import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, VStack } from '@revolut/ui-kit'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'
import { useMeetingsSummary } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/MeetingsSummary/hooks/useMeetingsSummary'
import { SummaryHighlights } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Highlights'
import { SummaryHeader } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Header'
import { SummarySkeleton } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Skeleton'
import { SummarySection } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/types'
import { defaultSections } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/constants'
import {
  SummaryNotes,
  SummaryNotesHeader,
} from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Notes'

interface Props {
  summary: MeetingsSummaryInterface
  managerId: number
  sections?: SummarySection[]
}

export const OneToOnesSummary = ({
  summary,
  managerId,
  sections = defaultSections,
}: Props) => {
  const { isActionItemsEnabled, isPerformanceRatingEnabled, gradeProps, isLoading } =
    useMeetingsSummary(summary, managerId)

  const hasHeaderSection = sections.includes('header')
  const hasHighlightsSection = sections.includes('highlights')
  const hasSummarySection = sections.includes('summary')
  const hasStrengthsSection = sections.includes('strengths')
  const hasWeaknessesSection = sections.includes('weaknesses')

  const hasNotes =
    (hasSummarySection && !!summary.summary) ||
    (hasStrengthsSection && !!summary.strengths) ||
    (hasWeaknessesSection && !!summary.area_for_development)

  if (isLoading) {
    return <SummarySkeleton sections={sections} />
  }

  return (
    <Box data-testid="meetings_summary_details">
      <VStack space="s-8">
        {hasHeaderSection && <SummaryHeader summary={summary} />}
        {hasHighlightsSection && (
          <SummaryHighlights
            meetingsCount={summary.meetings_count}
            actionsCount={summary.actions_count}
            gradesCount={summary.performance_grade_count}
            isRatingVisible={
              isPerformanceRatingEnabled && summary.performance_grade !== null
            }
            isActionItemsVisible={isActionItemsEnabled}
            gradeProps={gradeProps}
          />
        )}
      </VStack>
      {hasNotes && <SummaryNotesHeader />}
      <VStack space="s-16">
        {hasSummarySection && summary.summary && (
          <SummaryNotes
            label="Summary"
            variant="widget"
            isCopyable
            notes={summary.summary}
            title={
              <FormattedMessage id="common.summary.title" defaultMessage="Summary" />
            }
          />
        )}
        {hasStrengthsSection && summary.strengths && (
          <SummaryNotes
            notes={summary.strengths}
            label="Strengths"
            variant="widget"
            summaryVariant="list"
            isCopyable
            title={
              <FormattedMessage
                id="one-to-one.meetings.summary.notes.strengths.title"
                defaultMessage="Strengths"
              />
            }
          />
        )}
        {hasWeaknessesSection && summary.area_for_development && (
          <SummaryNotes
            notes={summary.area_for_development}
            label="Weaknesses"
            variant="widget"
            summaryVariant="list"
            isCopyable
            title={
              <FormattedMessage
                id="one-to-one.meetings.summary.notes.weaknesses.title"
                defaultMessage="Areas for development"
              />
            }
          />
        )}
      </VStack>
    </Box>
  )
}
