import React from 'react'
import {
  Flex,
  HStack,
  Text,
  Token,
  useToggle,
  TextButton,
  Icon,
  TextSkeleton,
  Ellipsis,
  Grid,
} from '@revolut/ui-kit'

import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import { getInverseColorsAndGrade } from '@src/utils/grades'

import {
  getAboveBelowSuggestedGrades,
  getGradeLabel,
  isDepartmentType,
} from '@src/pages/Forms/CommonTalentTab/utils'
import { CellInsertParams } from '@src/interfaces/data'
import { TalentUIInterface } from '../types'
import { TalentType } from '@src/interfaces/talent/talent'
import { FinalGrade } from '@src/interfaces/performance'
import { SetGradePopup } from './SetGradePopup'
import { IdAndName } from '@src/interfaces'
import { StyledActionButton } from './StyledActionButton'
import { AdjustedTooltipWithIcon } from './AdjustedTooltipWithIcon'

interface Props {
  data: CellInsertParams<TalentUIInterface>['data']
  talentType: TalentType
  changeHoDGrade: (id: number, rating: FinalGrade, justification?: string) => void
  gradeOptions: IdAndName<FinalGrade>[]
  requireJustification: boolean
}

export const HoDGradeCell = ({
  data,
  talentType,
  changeHoDGrade,
  gradeOptions = [],
  requireJustification,
}: Props) => {
  const [isPopupOpen, setPopupOpen] = useToggle()

  if (data.self_employee_locked) {
    return <GradeLockIcon />
  }
  const readOnly = data.field_options.read_only.includes(
    'department_owner_grade_suggestion',
  )
  const canChange = !isDepartmentType(talentType) ? false : !readOnly
  const gradeSuggestion = data.performance_extra_grade_suggestion
    ? {
        id: data.performance_extra_grade_suggestion.id,
        name:
          getGradeLabel(gradeOptions, data.performance_extra_grade_suggestion.id) ||
          data.performance_extra_grade_suggestion.label,
        label: getGradeLabel(gradeOptions, data.performance_extra_grade_suggestion.id),
      }
    : null
  const { color, grade } =
    getInverseColorsAndGrade(
      data.department_owner_grade_suggestion?.id
        ? {
            id: data.department_owner_grade_suggestion.id,
            label:
              getGradeLabel(gradeOptions, data.department_owner_grade_suggestion.id) ||
              data.department_owner_grade_suggestion.label,
          }
        : null,
    ) || {}

  const adjustedAttitude = getAboveBelowSuggestedGrades(
    data.department_owner_grade_suggestion?.id,
    gradeSuggestion?.id,
  )

  const showActionButtons = [
    TalentType.Company,
    TalentType.Team,
    TalentType.Department,
  ].includes(talentType)

  if (data.isPendingGrade) {
    return <TextSkeleton height={14} />
  }

  return (
    <>
      {grade ? (
        <Flex justifyContent="space-between" alignItems="center">
          <HStack align="center" gap="s-4">
            <Ellipsis>
              <Text color={color}>{grade}</Text>
            </Ellipsis>
            <AdjustedTooltipWithIcon
              attitude={adjustedAttitude}
              calibratorName={data.department_grade_calibrator?.full_name}
              justification={data.department_grade_justification}
            />
          </HStack>
          {canChange && (
            <TextButton
              aria-label="Edit HoD grade"
              color={Token.color.foreground}
              onClick={e => {
                e.stopPropagation()
                setPopupOpen.on()
              }}
            >
              <Icon name="Pencil" size={16} />
            </TextButton>
          )}
        </Flex>
      ) : (
        <Grid columns="1fr auto" gap="s-8" placeItems="center start">
          <Ellipsis>
            <Text style={{ fontStyle: 'italic' }} color={Token.color.greyTone20}>
              {gradeSuggestion?.label || '-'}
            </Text>
          </Ellipsis>
          {showActionButtons && (
            <HStack gap="s-8">
              <StyledActionButton
                styleVariant="warning"
                size="xs"
                aria-label="Edit HoD grade"
                iconOnly
                useIcon="Pencil"
                onClick={e => {
                  e.stopPropagation()
                  setPopupOpen.on()
                }}
              />
              {gradeSuggestion && (
                <StyledActionButton
                  styleVariant="success"
                  size="xs"
                  aria-label="Accept suggested HoD grade"
                  iconOnly
                  useIcon="Check"
                  onClick={e => {
                    e.stopPropagation()
                    changeHoDGrade(data.id, gradeSuggestion.id)
                  }}
                />
              )}
            </HStack>
          )}
        </Grid>
      )}
      <SetGradePopup
        isOpen={isPopupOpen}
        onClose={setPopupOpen.off}
        data={data}
        gradeOptions={gradeOptions}
        requireJustification={requireJustification}
        initialSelectedGrade={
          data.department_owner_grade_suggestion?.id
            ? {
                id: data.department_owner_grade_suggestion.id,
                name: data.department_owner_grade_suggestion.label,
              }
            : gradeSuggestion
        }
        gradeSuggestion={gradeSuggestion?.id}
        onConfirm={async (selectedGrade, justification) => {
          await changeHoDGrade(data.id, selectedGrade, justification)
          setPopupOpen.off()
        }}
      />
    </>
  )
}
