import React from 'react'
import { HStack, Icon, IconButton, Item, Token } from '@revolut/ui-kit'
import { noop } from 'lodash'

interface Props {
  title: React.ReactNode
  description?: React.ReactNode
  avatar?: React.ReactNode
  side?: React.ReactNode
}

export const DNDOverlayItem = ({ title, avatar, description, side }: Props) => {
  return (
    <Item>
      <Item.Prefix>
        <Icon name="MinusCircle" color={Token.color.red} size={16} />
      </Item.Prefix>
      {avatar && <Item.Avatar>{avatar}</Item.Avatar>}
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {description && <Item.Description>{description}</Item.Description>}
      </Item.Content>
      <Item.Side>
        <HStack space="s-8" align="center">
          {side}
          <IconButton
            aria-label="Drag"
            useIcon="Drag"
            color={Token.color.greyTone20}
            onClick={noop}
            size={16}
          />
        </HStack>
      </Item.Side>
    </Item>
  )
}
