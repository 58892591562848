import React from 'react'
import { Text, Box, Token } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import styled from 'styled-components'

import Tooltip from '@src/components/Tooltip/Tooltip'
import { CellInsertParams, CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  ExternalEmployeeInterface,
  ImportAction,
} from '@src/interfaces/importExternalEmployees'
import { selectorKeys } from '../api'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'

const CellWrapper = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const getCell =
  (key: keyof Omit<ExternalEmployeeInterface, 'errors'>) =>
  ({ data }: CellInsertParams<ExternalEmployeeInterface>) => {
    const errors = data.errors?.[key]
    const value = data[key]
    const hasError = !!errors?.length
    const dangerAction =
      (key === 'action' && value === ImportAction.Terminate) ||
      (key === 'status' && value === 'terminated')

    const getColor = () => {
      if (hasError) {
        return Token.color.red
      }
      if (dangerAction) {
        return Token.color.warning
      }
      return undefined
    }

    return (
      <Tooltip text={errors?.join('\n')} placement="top" hide={!hasError} noArrow>
        <CellWrapper>
          <Text color={getColor()}>
            {value || (hasError ? <ExclamationTriangle color="red" size={18} /> : '-')}
          </Text>
        </CellWrapper>
      </Tooltip>
    )
  }

export const externalEmployeeFirstNameColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'first_name',
    dataPoint: 'first_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'First name',
    insert: getCell('first_name'),
  }

export const externalEmployeeLastNameColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'last_name',
    dataPoint: 'last_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Last name',
    insert: getCell('last_name'),
  }

export const externalEmployeeEmailColumn: ColumnInterface<ExternalEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: getCell('email'),
}

export const externalEmployeeTeamColumn: ColumnInterface<ExternalEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: getCell('team'),
}

export const externalEmployeeSpecialisationColumn = (
  specialisationsEnabled: boolean,
): ColumnInterface<ExternalEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: getSpecialisationTitle(specialisationsEnabled),
  insert: getCell('specialisation'),
})

export const externalEmployeeSeniorityColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'seniority',
    dataPoint: 'seniority',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Seniority',
    insert: getCell('seniority'),
  }

export const externalEmployeeLineManagerColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'line_manager',
    dataPoint: 'line_manager',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Line manager',
    insert: getCell('line_manager'),
  }

export const externalEmployeeLocationColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'location',
    dataPoint: 'location',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Location',
    insert: getCell('location'),
  }

export const externalEmployeeJoinDateColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'joining_date_time',
    dataPoint: 'joining_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Joining date',
    insert: getCell('joining_date_time'),
  }

export const externalEmployeeTerminationDateColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'termination_date_time',
    dataPoint: 'termination_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Termination date',
    insert: getCell('termination_date_time'),
  }

export const externalEmployeeStatusColumn: ColumnInterface<ExternalEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: getCell('status'),
}

export const externalEmployeeActionColumn: ColumnInterface<ExternalEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: getCell('action'),
}

export const externalEmployeeVendorNameColumn: ColumnInterface<ExternalEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'vendor_name',
    dataPoint: 'vendor_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Vendor',
    insert: getCell('vendor_name'),
  }
