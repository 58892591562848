import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { FilterByInterface, SortByInterface } from '@src/interfaces/data'
import { useFetchV2 } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'

export const useGetOnboardingStats = (
  filters?: FilterByInterface[],
  sortBy?: SortByInterface[],
) => {
  const result = useFetchV2<GetRequestInterface<EmployeesSimpleInterface>>({
    url: API.EMPLOYEES_SIMPLE,
    params: {
      params: {
        ...filterSortPageIntoQuery(sortBy, filters),
        page_size: 10000,
      },
    },
  })

  const errorRows = result.data?.results.filter(
    employee => !employee.location || !employee.line_manager,
  )

  return {
    isLoading: result.isLoading,
    errors: errorRows?.length,
    errorRows,
    total: result.data?.count,
    refetch: result.refetch,
  }
}
