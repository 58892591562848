import React from 'react'
import { CandidatesListInterface, InterviewType } from '@src/interfaces/interviewTool'
import { ColumnCellInterface, RowInterface } from '@src/interfaces/data'
import {
  candidateInterviewerColumn,
  candidateInterviewStageColumn,
  candidateLastActivityDateColumn,
  CandidateMatchCell,
  candidateMatchColumn,
  candidateNameColumn,
  candidateNextInterviewColumn,
  candidateOfferApprovalStatusColumn,
  candidateOfferLocationColumn,
  candidateOfferStartDateColumn,
  candidateOfferTeamColumn,
  candidateOriginColumn,
  candidateRecruiterColumn,
  candidateSeniorityColumn,
  candidateSnoozedUntilDateColumn,
  candidateSpecialisationColumn,
  candidateStateColumn,
  candidateStatusColumn,
  candidateTagsColumn,
  candidateWorkExperienceColumn,
  candidateYearsOfExperienceColumn,
  createCandidateRequisitionColumn,
} from '@src/constants/columns/candidates'
import { selectorKeys } from '@src/constants/api'
import { getSelectCellConfig } from '@components/TableV2/AdvancedCells/SelectCell/SelectCell'
import { CandidatesTableType } from '@src/features/CommonCandidatesTable/types'

export const candidatesTableRow = ({
  showAssignedToMe,
  showSnoozedUntil,
  showStatus,
  stageType,
  type,
  isConfidential,
  onClickScore,
}: {
  showAssignedToMe: boolean
  showStatus: boolean
  showSnoozedUntil: boolean
  type: CandidatesTableType
  isConfidential?: boolean
  stageType?: InterviewType
  onClickScore: (row: CandidatesListInterface) => void
}): RowInterface<CandidatesListInterface> => {
  const columns: Record<string, ColumnCellInterface<CandidatesListInterface>> = {
    name: {
      ...candidateNameColumn,
      width: 250,
      filterKey: type === 'cvScreeningSummary' ? null : candidateNameColumn.filterKey,
    },
    specialisation: { ...candidateSpecialisationColumn, width: 250 },
    seniority: { ...candidateSeniorityColumn, width: 140 },
    tags: { ...candidateTagsColumn, width: 140 },
    interviewStage: { ...candidateInterviewStageColumn, width: 170 },
    status: { ...candidateStatusColumn, width: 200 },
    lastActivityDate: { ...candidateLastActivityDateColumn, width: 180 },
    origin: { ...candidateOriginColumn, width: 140 },
    recruiter: {
      ...candidateRecruiterColumn,
      filterKey: showAssignedToMe ? null : 'recruiter',
      selectorsKey: showAssignedToMe ? selectorKeys.none : selectorKeys.employee,
      width: showStatus ? 160 : 210,
    },
    startDate: { ...candidateOfferStartDateColumn, width: 140 },
    company: { ...candidateWorkExperienceColumn, width: 210 },
    yearsOfExperience: { ...candidateYearsOfExperienceColumn, width: 140 },
    location: { ...candidateOfferLocationColumn, width: 140 },
    team: { ...candidateOfferTeamColumn, width: 140 },
    approvalStatus: { ...candidateOfferApprovalStatusColumn, width: 140 },
    interviewer: { ...candidateInterviewerColumn, width: 140 },
    nextInterviewDate: { ...candidateNextInterviewColumn, width: 140 },
    state: { ...candidateStateColumn, width: 150 },
    requisition: { ...createCandidateRequisitionColumn(isConfidential), width: 200 },
    snoozedUntilDate: { ...candidateSnoozedUntilDateColumn, width: 140 },
    match: {
      ...candidateMatchColumn,
      insert: ({ data }) => (
        <CandidateMatchCell
          onClick={() => onClickScore(data)}
          score={data.active_interview_round?.candidate_score}
        />
      ),
      width: 105,
    },
  }
  let cells: ColumnCellInterface<CandidatesListInterface>[] = [
    columns.name,
    columns.match,
    ...(type !== 'specialisation' && type !== 'requisition'
      ? [columns.specialisation]
      : []),
    columns.company,
    columns.yearsOfExperience,
    columns.seniority,
    columns.interviewStage,
    ...(stageType === 'cv_screening'
      ? []
      : [columns.interviewer, columns.nextInterviewDate]),
    columns.tags,
    columns.status,
    columns.lastActivityDate,
    columns.origin,
    columns.recruiter,
    ...(showStatus ? [columns.state] : []),
    columns.requisition,
    ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
  ]

  switch (stageType) {
    case 'offer': {
      cells = [
        columns.name,
        columns.match,
        columns.lastActivityDate,
        columns.recruiter,
        columns.specialisation,
        columns.company,
        columns.yearsOfExperience,
        columns.seniority,
        columns.tags,
        columns.startDate,
        columns.location,
        columns.team,
        columns.status,
        columns.approvalStatus,
        ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
      ]
      break
    }

    case 'cv_screening': {
      cells = [
        columns.name,
        columns.match,
        ...(type !== 'specialisation' && type !== 'requisition'
          ? [columns.specialisation]
          : []),
        columns.company,
        columns.yearsOfExperience,
        columns.seniority,
        columns.interviewStage,
        ...(stageType === 'cv_screening'
          ? []
          : [columns.interviewer, columns.nextInterviewDate]),
        columns.tags,
        columns.status,
        columns.lastActivityDate,
        columns.origin,
        columns.recruiter,
        ...(showStatus ? [columns.state] : []),
        columns.requisition,
        ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
      ]
      break
    }
  }
  return {
    cells: [{ ...getSelectCellConfig() }, ...cells],
  }
}
