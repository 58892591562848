import React from 'react'
import {
  ActionButton,
  Avatar,
  Item,
  useToggle,
  VStack,
  Widget,
  Popup,
  Header,
} from '@revolut/ui-kit'
import { SkillsWidget } from './SkillsWidget'
import { BulletTextWidget, BulletTextWidgetSkeleton } from './BulletTextWidget'
import { useGetLastSpecialisationArchetype } from '@src/api/specialisations'
import {
  SpecialisationCandidateArchetypeInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import {
  CandidateArchetypeFormBody,
  CandidateArchetypeLapeFormContext,
} from './Form/CandidateArchetypeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const CandidateArchetypeOverview = ({
  data: specialisation,
}: {
  data: SpecialisationInterface
}) => {
  const [editPopupState, popup] = useToggle()
  const {
    data: archetype,
    isLoading,
    refetch: refetchArchetype,
  } = useGetLastSpecialisationArchetype(specialisation.id)

  return (
    <>
      <Widget>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="UsersSearch" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Ideal candidate profile</Item.Title>
            <Item.Description>
              Define the must-haves, nice-to-haves, and red flags for candidates for this
              specialisation
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton onClick={popup.on}>{archetype ? 'Edit' : 'Add'}</ActionButton>
          </Item.Side>
        </Item>
        {isLoading ? (
          <VStack space="s-16" px="s-16" pb="s-16">
            <BulletTextWidgetSkeleton label="Skills" />
            <BulletTextWidgetSkeleton label="Must have" />
            <BulletTextWidgetSkeleton label="Nice to have" />
            <BulletTextWidgetSkeleton label="Red flags" />
          </VStack>
        ) : null}

        {!isLoading && archetype ? (
          <CandidateArchetypeInfo archetype={archetype} />
        ) : null}
      </Widget>

      <Popup open={editPopupState} size="md" onClose={popup.off}>
        <Header variant="compact">
          <Header.CloseButton />
          <Header.Title>
            {archetype?.id !== undefined
              ? 'Edit ideal candidate profile'
              : 'Define ideal candidate profile'}
          </Header.Title>
        </Header>
        <VStack space="s-16">
          <CandidateArchetypeLapeFormContext
            archetype={archetype}
            specialisation={specialisation}
          >
            <CandidateArchetypeFormBody />
            <Popup.Actions horizontal>
              <NewSaveButtonWithPopup
                useValidator
                noPopup
                hideWhenNoChanges
                onAfterSubmit={() => {
                  refetchArchetype()
                  popup.off()
                }}
              >
                Save
              </NewSaveButtonWithPopup>
            </Popup.Actions>
          </CandidateArchetypeLapeFormContext>
        </VStack>
      </Popup>
    </>
  )
}

export const CandidateArchetypeInfo = ({
  archetype,
}: {
  archetype: SpecialisationCandidateArchetypeInterface
}) => (
  <VStack space="s-16" px="s-16" pb="s-16">
    <SkillsWidget skills={archetype.skills} />
    <BulletTextWidget label="Must have" values={archetype.must_have_traits} />
    <BulletTextWidget label="Nice to have" values={archetype.nice_to_have_traits} />
    <BulletTextWidget label="Red flags" values={archetype.red_flag_traits} />
  </VStack>
)
