import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Box, Token, Text } from '@revolut/ui-kit'
import {
  PerformanceSettingsManagersEntityType,
  PerformanceSettingsManagersWithType,
} from '@src/interfaces/settings'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import {
  getGradeColor,
  PerformanceReviewInterface,
  ReviewerRelationToShortTitle,
} from '@src/interfaces/performance'
import Table from '@src/components/TableV2/Table'

export const functionalManagerColumn: ColumnInterface<{
  functional_manager: EmployeeOptionInterface | null
}> = {
  type: CellTypes.insert,
  idPoint: 'functional_manager.id',
  dataPoint: 'functional_manager.full_name',
  sortKey: 'functional_manager__full_name',
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.manager,
  title: 'FM',
  insert: ({ data }) => <Table.EmployeeCell employee={data.functional_manager} />,
}

export const probationManagersTypeColumn: ColumnInterface<PerformanceSettingsManagersWithType> =
  {
    type: CellTypes.text,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
  }

export const probationManagersNameColumn: ColumnInterface<PerformanceSettingsManagersWithType> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Users',
    insert: ({ data }) => {
      if (data.type === PerformanceSettingsManagersEntityType.Group) {
        return data.name || null
      }
      return <Table.EmployeeCell employee={data} />
    },
  }

export const probationManagersActionColumn: ColumnInterface<PerformanceSettingsManagersWithType> =
  {
    type: CellTypes.insert,
    idPoint: 'action',
    dataPoint: 'action',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
  }

export const probationReviewGoalColumn: ColumnInterface<DeliverableInterface> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Goal',
}

export const probationReviewDueColumn: ColumnInterface<DeliverableInterface> = {
  type: CellTypes.insert,
  idPoint: 'deadline_date_time',
  dataPoint: 'deadline_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Due date',
}

export const probationReviewCheckpointColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'performance_checkpoint.id',
    dataPoint: 'performance_checkpoint.id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Checkpoint',
    insert: ({ data }) => {
      return data.performance_checkpoint?.number
        ? `Checkpoint ${data.performance_checkpoint?.number}`
        : null
    },
  }

export const probationReviewGradeColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'reviewed_employee.grade',
  dataPoint: 'reviewed_employee.grade',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  masked: true,
  maskTooltip: {
    isShown: (
      <Box p="s-4">
        <Text whiteSpace="nowrap">Click to hide latest grade</Text>
      </Box>
    ),
    isHidden: (
      <Box p="s-4">
        <Text whiteSpace="nowrap">Click to show latest grade</Text>
      </Box>
    ),
  },
  colors: data => {
    if (!data.grade?.id) {
      return Token.color.greyTone50
    }
    return getGradeColor(data.grade.id)
  },
  insert: ({ data }) => <Table.ItemCell>{data.grade?.label || 'n/a'}</Table.ItemCell>,
  title: 'Grade',
}

export const probationReviewTypeColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'reviewer_relation',
  dataPoint: 'reviewer_relation',
  sortKey: 'reviewer_relation',
  filterKey: 'reviewer_relation',
  selectorsKey: selectorKeys.scorecards_review_type,
  insert: ({ data }) =>
    data.reviewer_relation
      ? `${ReviewerRelationToShortTitle[data.reviewer_relation]} review`
      : '-',
  title: 'Type',
}

export const probationReviewSeniorityColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'seniority.id',
    dataPoint: 'seniority.name',
    sortKey: 'reviewed_employee__seniority__name',
    filterKey: 'reviewed_employee__seniority__id',
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
    insert: ({ data }) => <Table.SeniorityCell seniority={data.seniority?.name} />,
  }

export const probationReviewTeamColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'reviewed_employee__team__name',
  filterKey: 'reviewed_employee__team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const probationReviewRoleColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.text,
  idPoint: 'role.id',
  dataPoint: 'role.name',
  sortKey: 'reviewed_employee__role__name',
  filterKey: 'reviewed_employee__role__id',
  selectorsKey: selectorKeys.role,
  title: 'Role',
}

export const probationReviewSpecialisationAsRoleColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: 'reviewed_employee__specialisation__name',
    filterKey: 'reviewed_employee__specialisation__id',
    selectorsKey: selectorKeys.specialisations,
    title: 'Role',
  }
