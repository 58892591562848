import React from 'react'
import { MoreBar, Widget, Link } from '@revolut/ui-kit'
import { getLinkedinUrl } from '@src/utils/hiring'
import uniq from 'lodash/uniq'

interface LinksProps {
  links?: string[]
}

export const Links = ({ links }: LinksProps) => {
  const linkedIn = getLinkedinUrl(links)
  const otherLinks = uniq(links?.filter(link => link !== linkedIn))

  if (links?.length === 0) {
    return null
  }

  return (
    <Widget p="s-16">
      <MoreBar>
        {linkedIn ? (
          <MoreBar.Action
            useIcon="LogoLinkedIn"
            use={Link}
            target="_blank"
            rel="noopener noreferrer"
            href={linkedIn}
          >
            LinkedIn
          </MoreBar.Action>
        ) : null}
        {otherLinks?.map(link => (
          <MoreBar.Action
            useIcon="LinkExternal"
            use={Link}
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            key={link}
          >
            Open
          </MoreBar.Action>
        ))}
      </MoreBar>
    </Widget>
  )
}
