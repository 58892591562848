import React from 'react'
import {
  HStack,
  RadioSelect,
  TextButton,
  useDropdown,
  Text,
  Icon,
  Ellipsis,
  Token,
  useTooltip,
  Tooltip,
  useToggle,
  Flex,
} from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { CompetencyMatrixSkill, SkillLevels } from '@src/interfaces/roles'
import { RadioOptionInterface } from '@src/components/Inputs/RadioButtons/RadioButtons'
import { getListIndicator, ListType } from '@src/utils'
import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { SkillDescriptionPopup } from './SkillDescriptionPopup'

interface Option extends RadioOptionInterface {
  text?: string
}

// The `LineClamp` component from UI Kit doesn't work well when it's nested in the cell
const DescriptionContainer = styled.div`
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface Props {
  dataTestId: string
  value: IdAndName<SkillLevels> | undefined
  onChange: (value: RadioOptionInterface | null) => void
  options: Option[]
  disabled?: boolean
  skillLevelDescription: string | undefined
  skillset: CompetencyMatrixSkill | undefined
  refetchSkills: VoidFunction
}

export const SeniorityCell = ({
  dataTestId,
  value,
  onChange,
  options,
  disabled,
  skillLevelDescription,
  skillset,
  refetchSkills,
}: Props) => {
  const dropdown = useDropdown({ disabled })

  const tooltip = useTooltip()
  const [isDescriptionPopupOpen, toggleDescriptionPopup] = useToggle()

  const mappedOptions = options.map(item => ({
    key: item.id,
    value: item,
    label: item.name,
    disabled: item.disabled,
  }))

  const description = (skillLevelDescription && skillLevelDescription?.split('\\n')) || []

  const descriptionWithListIndicators = description?.map(
    (text, index) => `${getListIndicator(index, ListType.letters)}. ${text}`,
  )

  return (
    <Flex
      flexDirection="column"
      gap="s-8"
      data-testid={dataTestId}
      height={rowWrapperMinHeight['competency-matrix']}
      py="s-16"
    >
      <TextButton
        {...dropdown.getAnchorProps()}
        variant="foreground"
        aria-label="select skill level"
        use={disabled ? Text : undefined}
      >
        <HStack gap="s-8">
          <Text variant="emphasis1" color={Token.color.greyTone50}>
            <Ellipsis>{value?.name || (disabled ? '-' : 'Select')}</Ellipsis>
          </Text>
          {!disabled && <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />}
        </HStack>
      </TextButton>
      <RadioSelect<Option>
        options={mappedOptions}
        value={value}
        labelList="seniority"
        {...dropdown.getTargetProps()}
        onChange={onChange}
        autoClose
        width={388}
      >
        {option => (
          <>
            <Text use="p">{option.value.name}</Text>
            {option.value.text && (
              <Text use="p" variant="small" mt="s-4" color={Token.color.greyTone50}>
                {option.value.text.split('\\n').map((text, index) => (
                  <Ellipsis key={index}>
                    {getListIndicator(index, ListType.dots)} {text}
                  </Ellipsis>
                ))}
              </Text>
            )}
          </>
        )}
      </RadioSelect>
      {descriptionWithListIndicators && (
        <>
          <DescriptionContainer
            onClick={e => {
              e.preventDefault()
              toggleDescriptionPopup.on()
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(descriptionWithListIndicators.join('<br/>')),
            }}
            {...tooltip.getAnchorProps()}
          />
          <Tooltip {...tooltip.getTargetProps()}>Click to view more</Tooltip>
          {isDescriptionPopupOpen && description && skillset && value && (
            <SkillDescriptionPopup
              onClose={toggleDescriptionPopup.off}
              skillset={skillset}
              skillLevel={value}
              descriptionWithListIndicators={descriptionWithListIndicators}
              skillLevelDescription={description}
              refetchSkills={refetchSkills}
            />
          )}
        </>
      )}
    </Flex>
  )
}
