import { Avatar } from '@revolut/ui-kit'
import { Country } from '@src/interfaces/selectors'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import React from 'react'

interface Props {
  country: Country | null | undefined
}

export const CountryAvatar = ({ country }: Props) => {
  if (!country?.country_code) {
    return null
  }
  return (
    <Avatar
      size={20}
      variant="brand"
      image={getCountryFlagImageByCode(country.country_code)}
    />
  )
}
