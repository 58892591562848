import React from 'react'
import { HStack, Icon, List, VStack, Text, Token } from '@revolut/ui-kit'

import { formatSnakeCase } from '@src/utils/string'

const unexpectedErrorFormatMsg =
  'Unexpected error format. Please, contact your system administrator'

const ErrorsList = ({ errors }: { errors: string[] }) => {
  return (
    <List variant="compact">
      {errors.map(err => (
        <List.Item
          key={err}
          marginWidth={4}
          useIcon="16/BulletFilled"
          color={Token.color.greyTone50}
        >
          <Text ml="-s-4" variant="caption">
            {err}
          </Text>
        </List.Item>
      ))}
    </List>
  )
}

interface Props {
  errors: string | string[] | object | null
}
export const ValidationErrorMessage = ({ errors }: Props) => {
  if (typeof errors === 'object' && !Array.isArray(errors) && errors !== null) {
    return (
      <VStack space="s-16" px="s-24">
        {Object.entries(errors).map(([key, errValue]) => (
          <VStack key={key} align="start">
            <HStack space="s-8" align="center">
              <Icon
                size={15}
                name="ExclamationMarkOutline"
                color={Token.color.greyTone50}
              />
              <Text variant="primary" color={Token.color.greyTone50}>
                {formatSnakeCase(key)}:
              </Text>
            </HStack>
            {Array.isArray(errValue) ? (
              <ErrorsList
                errors={
                  Array.isArray(errValue)
                    ? errValue
                    : typeof errValue === 'string'
                    ? [errValue]
                    : [unexpectedErrorFormatMsg]
                }
              />
            ) : (
              errValue
            )}
          </VStack>
        ))}
      </VStack>
    )
  }
  if (Array.isArray(errors)) {
    return <ErrorsList errors={errors} />
  }
  if (typeof errors === 'string') {
    return <>{errors}</>
  }
  return null
}
