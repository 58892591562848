import React from 'react'
import {
  Item,
  Text,
  Token,
  Avatar,
  IconName,
  Color,
  VStack,
  Action,
} from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { CandidateMatch } from '@src/features/CommonCandidatesTable/types'
import { getMatchType } from '@src/features/CommonCandidatesTable/utils'

interface CVScoreProps {
  score?: number | null
  specialisationId?: number
}

export const candidateMatchConfig: Record<
  CandidateMatch,
  { text: string; icon: IconName; color: Color } | null
> = {
  brilliant: {
    text: 'Brilliant match',
    icon: 'DoubleThumbs',
    color: Token.color.success,
  },
  good: {
    text: 'Good match',
    icon: 'SocialLike',
    color: Token.color.success,
  },
  ok: {
    text: 'OK match',
    icon: 'LikeDislike',
    color: Token.color.foreground,
  },
  poor: {
    text: 'Poor match',
    icon: 'SocialDislike',
    color: Token.color.warning,
  },
  no_match: {
    text: 'No match',
    icon: 'SocialDislike',
    color: Token.color.warning,
  },
  not_available: {
    text: 'Not available',
    icon: 'Binoculars',
    color: Token.color.inactive,
  },
}

export const CVScore = ({ score, specialisationId }: CVScoreProps) => {
  const matchType = getMatchType(score)
  const rating = candidateMatchConfig[matchType]

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={rating?.icon} color={rating?.color} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color={rating?.color}>{rating?.text}</Item.Title>
        <Item.Description>
          <VStack space="s-8">
            <Text>CV Score</Text>
            <Action
              useIcon="LinkExternal"
              color={Token.color.foreground}
              use={InternalLink}
              to={pathToUrl(
                ROUTES.FORMS.SPECIALISATIONS.ARCHETYPE.SPECIALISATION_OVERVIEW,
                {
                  id: specialisationId,
                },
              )}
              target="_blank"
            >
              View ideal candidate profile
            </Action>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
