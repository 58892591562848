import {
  BitbucketNumericMetrics,
  InterviewNumericMetrics,
  JiraNumericMetrics,
  Metrics,
  NumericMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { PerformanceInsightsGroupSourceMetricInterface } from '@src/features/AiInsights/Performance/interfaces/insights'

const jiraNumericMetrics: JiraNumericMetrics[] = Object.values(JiraNumericMetrics)

const bitbucketNumericMetrics: BitbucketNumericMetrics[] = Object.values(
  BitbucketNumericMetrics,
)
const nonComparableBitbucketMetrics: BitbucketNumericMetrics[] = [
  BitbucketNumericMetrics.LinesAdded,
  BitbucketNumericMetrics.LinesRemoved,
]

const interviewsNumericMetrics: InterviewNumericMetrics[] = Object.values(
  InterviewNumericMetrics,
)
const nonComparableInterviewsMetrics: InterviewNumericMetrics[] = [
  InterviewNumericMetrics.ConversionRate,
  InterviewNumericMetrics.PassRate,
]

const comparableMetrics: Metrics[] = [
  ...jiraNumericMetrics,
  ...bitbucketNumericMetrics.filter(
    metricName => !nonComparableBitbucketMetrics.includes(metricName),
  ),
  ...interviewsNumericMetrics.filter(
    metricName => !nonComparableInterviewsMetrics.includes(metricName),
  ),
]

interface MetricModel {
  isNumeric: <T extends NumericMetrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
  isEnabled: <T extends Metrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
  isComparable: <T extends Metrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
  isByName: <T extends Metrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
    name: T,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
}

const isNumeric = <T extends NumericMetrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> =>
  !![...jiraNumericMetrics, ...bitbucketNumericMetrics, ...interviewsNumericMetrics].find(
    metricName => metricName === metric.name,
  )

const isComparable = <T extends Metrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> =>
  !!comparableMetrics.find(metricName => metricName === metric.name)

const isEnabled = <T extends Metrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> => metric.enabled

const isByName = <T extends Metrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  name: T,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> => metric.name === name

const createPerformanceInsightsGroupModel = (): MetricModel => ({
  isNumeric,
  isComparable,
  isEnabled,
  isByName,
})

export const metricModel = createPerformanceInsightsGroupModel()
