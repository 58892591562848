import React, { useRef } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { Box, ItemSkeleton, VStack } from '@revolut/ui-kit'
import { getEngagementOutliers } from '@src/api/engagement'
import { OutlierItem } from './OutlierItem'
import { EngagementOutlierInterface } from '@src/interfaces/engagement'
import { useTable } from '@components/Table/hooks'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { Virtuoso } from 'react-virtuoso'

interface Props {
  sortDirection: SORT_DIRECTION
  surveyRoundId: number
  title: string
  onClose: VoidFunction
}

export const OutliersSideBar = ({
  sortDirection,
  title,
  surveyRoundId,
  onClose,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const table = useTable<EngagementOutlierInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getEngagementOutliers({ params: requestData, roundId: surveyRoundId }),
    },
    undefined,
    [
      {
        sortBy: 'average_score',
        direction: sortDirection,
      },
    ],
  )

  return (
    <SideBar isOpen title={title} onClose={onClose} sideProps={{ scrollRef }}>
      {!table.data.length && table.loading ? (
        <VStack mt="s-12" gap="s-8">
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </VStack>
      ) : null}
      {table.data && table.data.length > 0 ? (
        <Virtuoso<EngagementOutlierInterface>
          data={table.data}
          overscan={1000}
          customScrollParent={scrollRef?.current || undefined}
          components={{
            Footer: () => <Footer loading={table.loading} />,
          }}
          itemContent={(i, outlier) => (
            <Box mt="s-12" key={i}>
              <OutlierItem outlier={outlier} />
            </Box>
          )}
          endReached={table.fetchNextPage}
        />
      ) : null}
    </SideBar>
  )
}

const Footer = ({ loading }: { loading: boolean }) => (
  <VStack mt="s-12" gap="s-8">
    {loading ? (
      <>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </>
    ) : (
      <Box p="s-8" />
    )}
  </VStack>
)
