import React, { useMemo } from 'react'
import { RowInterface } from '@src/interfaces/data'
import { ImportCandidatesDataInterface } from '@src/interfaces/importCandidates'
import {
  ImportCandidatesCellOptions,
  importCandidatesFullNameColumn,
  importCandidatesRequisitionColumn,
  importCandidatesSpecialisationColumn,
  importCandidatesSeniorityColumn,
  importCandidatesOriginColumn,
  importCandidatesEmailColumn,
  importCandidatesCountryColumn,
  importCandidatesCurrentCompanyColumn,
  importCandidatesRecruiterColumn,
  importCandidatesHiringManagerColumn,
  importCandidatesJobPostingColumn,
  importCandidatesPhoneColumn,
  importCandidatesLinkedInUrlColumn,
  importCandidatesPreferredWorkLocationColumn,
  importCandidatesSourcerColumn,
  importCandidatesNotesColumn,
} from '@src/constants/columns/importCandidates'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { fields, useOptions } from './config'
import { BulkDataImportSession } from '@src/features/BulkDataImport/BulkDataImportSession'
import { BulkDataImportActions } from '@src/features/BulkDataImport/BulkDataImportActions'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { API } from '@src/constants/api'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const candidateRow = (
  options: ImportCandidatesCellOptions,
  specialisationsEnabled: boolean,
): Partial<RowInterface<ImportInterface<ImportCandidatesDataInterface>>> => ({
  cells: [
    {
      ...importCandidatesFullNameColumn,
      width: 150,
    },
    {
      ...importCandidatesRequisitionColumn(options),
      width: 150,
    },
    {
      ...importCandidatesSpecialisationColumn(options, specialisationsEnabled),
      width: 150,
    },
    {
      ...importCandidatesSeniorityColumn(options),
      width: 150,
    },
    {
      ...importCandidatesOriginColumn(options),
      width: 150,
    },
    {
      ...importCandidatesEmailColumn,
      width: 150,
    },
    {
      ...importCandidatesCountryColumn,
      width: 150,
    },
    {
      ...importCandidatesCurrentCompanyColumn,
      width: 150,
    },
    {
      ...importCandidatesRecruiterColumn(options),
      width: 150,
    },
    {
      ...importCandidatesHiringManagerColumn(options),
      width: 150,
    },
    {
      ...importCandidatesJobPostingColumn(options),
      width: 150,
    },
    {
      ...importCandidatesPhoneColumn,
      width: 150,
    },
    {
      ...importCandidatesLinkedInUrlColumn,
      width: 150,
    },
    {
      ...importCandidatesPreferredWorkLocationColumn,
      width: 150,
    },
    {
      ...importCandidatesSourcerColumn(options),
      width: 150,
    },
    {
      ...importCandidatesNotesColumn,
      width: 150,
    },
  ],
})

export const CandidatesSession = () => {
  const options = useOptions()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const row = useMemo(
    () => candidateRow(options, specialisationsEnabled),
    [options, specialisationsEnabled],
  )

  return (
    <BulkDataImportSession
      row={row}
      apiEndpoint={API.CANDIDATES_UPLOAD}
      tableName={TableNames.ImportCandidates}
      fields={fields}
      actions={props => (
        <BulkDataImportActions {...props} backUrl={ROUTES.RECRUITMENT.CANDIDATES} />
      )}
    />
  )
}
