import React from 'react'
import { ActionButton, Box, List, Text, VStack } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SideBar from '@src/components/SideBar/SideBar'
import { CandidateInterface } from '@src/interfaces/interviewTool'

interface HowGradesArePredictedSidebarProps {
  candidate: CandidateInterface
  open: boolean
  onClose: VoidFunction
}

export const HowGradesArePredictedSidebar = ({
  candidate,
  open,
  onClose,
}: HowGradesArePredictedSidebarProps) => {
  return (
    <SideBar title="How are grades predicted?" isOpen={open} onClose={onClose}>
      <VStack gap="s-16">
        <VStack gap="s-8">
          <Text variant="emphasis1" fontWeight={500}>
            Methodology
          </Text>
          <Text variant="body2">
            We use a data-driven approach to assess candidates and provide feedback to
            help them improve. This ensures a fair and consistent process that aligns with
            our company's hiring standards. We also use the same methodology to assess
            employees after they join the team, ensuring a smooth transition and a
            high-quality employee base.
          </Text>
        </VStack>
        <VStack gap="s-8">
          <Text variant="emphasis1" fontWeight={500}>
            Deliverables
          </Text>
          <Text variant="body2">
            We evaluate candidates based on their performance in tasks like home
            assignments, online tests, and other practical assessments. This helps us
            gauge their ability to apply their skills and knowledge to real-world
            situations.
          </Text>
        </VStack>
        <VStack gap="s-8">
          <Text variant="emphasis1" fontWeight={500}>
            Skills
          </Text>
          <Text variant="body2">
            We evaluate candidates based on their skills as outlined in the specialisation
            competency matrix. This includes both technical and soft skills essential for
            the role. We use various methods like technical assessments, interviews, and
            reference checks to assess these skills.
          </Text>
        </VStack>
        <VStack gap="s-8">
          <Text variant="emphasis1" fontWeight={500}>
            Values
          </Text>
          <Text variant="body2">
            We assess candidates against our company values to ensure they are a good
            cultural fit. This includes evaluating their work ethic, teamwork, commitment
            to our mission, and other key values that are important to our organisation.
          </Text>
        </VStack>
        <VStack gap="s-8">
          <Text variant="emphasis1" fontWeight={500}>
            Candidate Performance Profile
          </Text>
          <Box>
            <Text variant="body2">
              For detailed candidate performance information, please visit the Performance
              tab. This tab provides a breakdown of the candidate's scores in each key
              assessment area. You can also:
            </Text>
            <List variant="compact">
              <List.Item useIcon="Dot">
                <Text variant="body2">
                  Check interviewer scores: See how each interviewer rated the candidate.
                </Text>
              </List.Item>
              <List.Item useIcon="Dot">
                <Text variant="body2">
                  View detailed feedback: Read detailed feedback from interviewers on the
                  candidate's performance.
                </Text>
              </List.Item>
              <List.Item useIcon="Dot">
                <Text variant="body2">
                  Compare skills breakdown to specialization competency: Analyze how the
                  candidate's skills align with the requirements of the specialization.
                </Text>
              </List.Item>
              <List.Item useIcon="Dot">
                <Text variant="body2">
                  Visit the Performance Profile tab: Click here to view the candidate's
                  performance profile in more detail.
                </Text>
              </List.Item>
            </List>
          </Box>
        </VStack>
        <ActionButton
          to={pathToUrl(ROUTES.FORMS.CANDIDATE.PERFORMANCE, { id: candidate.id })}
          use={InternalLink}
          useIcon="Wealth"
        >
          Performance Profile
        </ActionButton>
      </VStack>
    </SideBar>
  )
}
