import React from 'react'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { useQuery } from '@src/utils/queryParamsHooks'
import PageLoading from '@components/PageLoading/PageLoading'
import { useDepartmentReviewCycles } from '@src/pages/Forms/DepartmentForm/Performance/hooks/useReviewCycles'
import { SkillsRatingsDistributionWidget } from '@src/features/ReviewCycle/Analytics/Skills/SkillsRatingsDistribution'

interface Props {
  data: DepartmentInterface
}

const CYCLE_ID_PARAM = 'cycle__id'

export const SkillsAnalyticsTab = ({ data }: Props) => {
  const { query, changeQueryParam } = useQuery(true)

  const { options, currentlySelectedCycleId, isLoading } = useDepartmentReviewCycles(
    data.id,
  )

  const cycleId = query[CYCLE_ID_PARAM]
    ? Number(query[CYCLE_ID_PARAM])
    : currentlySelectedCycleId

  const onFilterCycle = (id: number) => {
    changeQueryParam(CYCLE_ID_PARAM, String(id))
  }

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <SkillsRatingsDistributionWidget
      cycleId={cycleId}
      departmentId={data.id}
      cycleFilterKey={CYCLE_ID_PARAM}
      cycleOptions={options}
      onFilterCycle={onFilterCycle}
    />
  )
}
