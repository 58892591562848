import { useMemo } from 'react'
import { Token } from '@revolut/ui-kit'
import { useGetLatestPerformanceCycles } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/NumericMetricHistoryChart/hooks/useGetLatestPerformanceCycles'
import {
  MetricsParamsKeys,
  useGetEmployeeJiraMetrics,
} from '@src/features/AiInsights/Performance/api/metrics'
import { JiraNumericMetrics } from '@src/features/AiInsights/Performance/interfaces/metrics'
import {
  NumericMetricHistoryDataKey,
  NumericMetricHistoryItemData,
} from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/NumericMetricHistoryChart/ChartData'
import { getMetricDefaultMessage } from '@src/features/AiInsights/Performance/components/Metrics/common/constants'

const HISTORY_RECORDS_COUNT = 4

export const METRIC_COLOR = Token.colorChannel.blue

const DATA_KEYS = Object.values(JiraNumericMetrics).reduce<
  Record<JiraNumericMetrics, NumericMetricHistoryDataKey>
>((record, metricKey) => {
  const dataKey: NumericMetricHistoryDataKey = {
    id: 0,
    label: getMetricDefaultMessage(metricKey),
    value: metricKey,
    color: METRIC_COLOR,
  }

  return {
    ...record,
    [metricKey]: dataKey,
  }
}, {} as Record<JiraNumericMetrics, NumericMetricHistoryDataKey>)

interface Props {
  dataKeys: Record<JiraNumericMetrics, NumericMetricHistoryDataKey>
  data: Record<JiraNumericMetrics, NumericMetricHistoryItemData[]>
  isLoading: boolean
}

export const useChartData = (employeeId: number): Props => {
  const { data: cycles, isLoading: isCyclesLoading } =
    useGetLatestPerformanceCycles(employeeId)

  const { data: metricsHistory, isLoading: isMetricsLoading } = useGetEmployeeJiraMetrics(
    employeeId,
    {
      [MetricsParamsKeys.CycleId]: cycles
        .slice(0, HISTORY_RECORDS_COUNT)
        .map(({ id }) => Number(id)),
    },
    {
      enabled: cycles?.length > 1,
    },
  )

  const data = useMemo(
    () =>
      Object.values(JiraNumericMetrics).reduce<
        Record<JiraNumericMetrics, NumericMetricHistoryItemData[]>
      >((record, metricKey) => {
        const metricsHistoryItems = (metricsHistory?.results ?? []).reduce<
          NumericMetricHistoryItemData[]
        >((nonEmptyMetrics, metric) => {
          const metricValue = metric[metricKey]

          if (metricValue === null) {
            return nonEmptyMetrics
          }

          return [
            ...nonEmptyMetrics,
            {
              [metricKey]: String(metricValue),
              label: metric.cycle ? metric.cycle.name : '',
            },
          ]
        }, [])

        return {
          ...record,
          [metricKey]: metricsHistoryItems,
        }
      }, {} as Record<JiraNumericMetrics, NumericMetricHistoryItemData[]>),
    [metricsHistory?.results],
  )

  return useMemo<Props>(
    () => ({
      dataKeys: DATA_KEYS,
      data,
      isLoading: isCyclesLoading || isMetricsLoading,
    }),
    [data, isCyclesLoading, isMetricsLoading],
  )
}
