import { ROUTES } from '@src/constants/routes'
import { ExternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'

export const getMetricSourceIntegrationLink = (source: ExternalMetricSources): string =>
  metricSourceToIntegrationLink[source]

const metricSourceToIntegrationLink: Record<ExternalMetricSources, string> = {
  [ExternalMetricSources.Jira]: ROUTES.FEATURES.INTEGRATION.JIRA,
  [ExternalMetricSources.Bitbucket]: ROUTES.FEATURES.INTEGRATION.BITBUCKET,
}
