import React from 'react'
import { VStack, Widget, Text, Icon, TravelWidget, HStack, Spacer } from '@revolut/ui-kit'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { IntegrationForm } from '../../../../Forms/GoalForm/Form/GoalMetricForm/constants'
import { CLICKUP_LOGIN } from '@src/constants/externalLinks'
import { CopyUrlToClipboard } from '../CopyUrlToClipboard'

interface Props {
  setLightBoxSrc: (src: { video: string; poster: string } | undefined) => void
}

export const ClickupHelperContent = ({ setLightBoxSrc }: Props) => {
  const { values } = useLapeContext<IntegrationForm>()

  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Log in to your <Text fontWeight={700}>ClickUp account</Text>, go to
                Settings and click <Text fontWeight={700}>ClickUp API</Text>
              </Text>
            }
            bulletVariant="filled"
            subtitle={
              <VStack pt="s-12">
                <OpenInNewTab link={CLICKUP_LOGIN} label="Take me there" />
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Click ”+ Create an App”"
            subtitle="You need to be an admin or owner in order to do this"
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title="Enter your app's name and the following redirect URL:"
            subtitle={
              <CopyUrlToClipboard
                url={values.redirect_uri}
                successText="redirect URL copied to clipboard"
              />
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Click <Text fontWeight={700}>Create App</Text>
              </Text>
            }
            subtitle="On the next screen, you'll see a Client ID and Client Secret have been generated."
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Copy <Text fontWeight={700}>Client ID</Text> and{' '}
                <Text fontWeight={700}>Client Secret</Text>
              </Text>
            }
            subtitle="Use Client ID and Client Secret in Revolut People to connect ClickUp"
          />
        </AutoStepper>
      </Widget>

      <TravelWidget
        use="div"
        variant="large"
        image={[
          'https://assets.revolut.com/revoluters/assets/integrations/clickup_integration_help_preview.png',
        ]}
      >
        <TravelWidget.Details
          use="button"
          onClick={() =>
            setLightBoxSrc({
              video:
                'https://assets.revolut.com/revoluters/assets/integrations/clickup_integration_help.mp4',
              poster:
                'https://assets.revolut.com/revoluters/assets/integrations/clickup_integration_help_preview.png',
            })
          }
        >
          <HStack align="center">
            <Text>Watch video</Text>
            <Spacer />
            <Icon name="ChevronRight" />
          </HStack>
        </TravelWidget.Details>
      </TravelWidget>
    </VStack>
  )
}
