import { Color, IconName, Token } from '@revolut/ui-kit'
import { PerformanceReviewAnalyticsGroupTypes } from '@src/interfaces/performance'

export const DISTRIBUTION_CHART_COLORS: Color[] = [
  Token.color.orange,
  Token.color.deepGrey,
  Token.color.blue,
  Token.color.teal,
  Token.color.lightGreen,
]

export const groupTypeToIconNameMap: Record<
  PerformanceReviewAnalyticsGroupTypes,
  IconName
> = {
  [PerformanceReviewAnalyticsGroupTypes.Country]: 'Globe',
  [PerformanceReviewAnalyticsGroupTypes.Seniority]: 'Profile',
  [PerformanceReviewAnalyticsGroupTypes.Department]: 'Bank',
  [PerformanceReviewAnalyticsGroupTypes.Function]: 'RepairTool',
  [PerformanceReviewAnalyticsGroupTypes.Role]: 'Profile',
  [PerformanceReviewAnalyticsGroupTypes.Specialisation]: 'Profile',
  [PerformanceReviewAnalyticsGroupTypes.Cycle]: 'DocumentsPair',
}
