import React, { useEffect, useState } from 'react'
import { Box, Text, InputGroup, Token } from '@revolut/ui-kit'
import {
  CustomFieldsForSectionInterface,
  DynamicGroupIDs,
  SectionOptions,
} from '@src/interfaces/customFields'
import { getCustomFieldsForSectionRequest } from '@src/api/customFields'
import { FilterByInterface } from '@src/interfaces/data'
import { FieldOptions, Statuses } from '@src/interfaces'
import LapeCustomFieldsRouter from '@components/Stepper/LapeCustomFieldsRouter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CUSTOM_FIELD_SECTIONS,
  EmployeeProfileSubSections,
} from '@src/interfaces/employees'
import { useGetEmployeeSettings } from '@src/api/settings'
import { toIdAndName } from '@src/utils/toIdAndName'

interface CustomFieldsFilters {
  dynamicGroups?: DynamicGroupIDs
  departmentId?: number
  roleId?: number
  requisitionId?: number
}

interface NewStepperSection extends CustomFieldsFilters {
  className?: string
  sectionId: SectionOptions
  subSectionId?: EmployeeProfileSubSections | EmployeeProfileSubSections[]
  children?: React.ReactNode
  customFieldsOnly?: boolean
  useExternalRequests?: boolean
}

interface UseCustomFieldsProps extends CustomFieldsFilters {
  sectionId: SectionOptions
  subSectionId?: string | string[]
  useExternalRequests?: boolean
}

export const useCustomFields = ({
  sectionId,
  subSectionId,
  dynamicGroups,
  departmentId,
  requisitionId,
  roleId,
  useExternalRequests,
}: UseCustomFieldsProps) => {
  const [customFields, setCustomFields] = useState<CustomFieldsForSectionInterface[]>([])

  const { data: settings } = useGetEmployeeSettings(
    sectionId !== SectionOptions.EmployeeProfile,
  )
  const subsectionIdsArray = Array.isArray(subSectionId)
    ? subSectionId
    : typeof subSectionId === 'string'
    ? [subSectionId]
    : []

  useEffect(() => {
    const filters: FilterByInterface[] = [
      {
        filters: [toIdAndName(String(sectionId))],
        columnName: 'section__id',
      },
      {
        filters: [toIdAndName(Statuses.active)],
        columnName: 'status',
      },
    ]
    let fetchFields = false

    switch (sectionId) {
      case SectionOptions.EmployeeProfile:
        if (subSectionId && dynamicGroups?.length && !!settings?.enable_custom_fields) {
          filters.push(
            {
              filters: dynamicGroups.map(group => toIdAndName(String(group.id))),
              columnName: 'dynamic_groups',
            },
            {
              filters: [toIdAndName(CUSTOM_FIELD_SECTIONS.EMPLOYEE_PROFILE)],
              columnName: 'section__id',
            },
            {
              filters: subsectionIdsArray.map(id => toIdAndName(id)),
              columnName: 'sub_section__id',
            },
          )
          fetchFields = true
        }
        break
      case SectionOptions.Departments:
        if (departmentId) {
          filters.push({
            filters: [toIdAndName(String(departmentId))],
            columnName: 'table_filters__id',
          })
          fetchFields = true
        }
        break
      case SectionOptions.Roles:
        if (roleId) {
          filters.push({
            filters: [toIdAndName(String(roleId))],
            columnName: 'table_filters__id',
          })
          fetchFields = true
        }
        break
      case SectionOptions.Requisitions:
        if (requisitionId) {
          filters.push({
            filters: [toIdAndName(String(requisitionId))],
            columnName: 'table_filters__id',
          })
          fetchFields = true
        }
        break
    }

    if (fetchFields) {
      getCustomFieldsForSectionRequest(filters, useExternalRequests).then(res => {
        const fields = res.data.results
        setCustomFields(fields)
      })
    }
  }, [subSectionId, dynamicGroups, settings?.enable_custom_fields])

  return customFields
}

const NewStepperSectionCustomFields = ({
  className,
  children,
  sectionId,
  subSectionId,
  departmentId,
  roleId,
  requisitionId,
  dynamicGroups,
  customFieldsOnly,
  useExternalRequests,
}: NewStepperSection) => {
  const form = useLapeContext<{ field_options: FieldOptions; custom_fields?: any }>()
  const { values } = form

  const hidden = values?.field_options?.no_access?.includes('custom_fields')
  const disabled = values?.field_options?.read_only?.includes('custom_fields')

  const customFields = useCustomFields({
    sectionId,
    subSectionId,
    dynamicGroups,
    departmentId,
    roleId,
    requisitionId,
    useExternalRequests,
  })

  useEffect(() => {
    if (customFields.length) {
      // Needed for BE to do validation on it
      if (!values.custom_fields) {
        values.custom_fields = {}
      }
    }
  }, [customFields])

  if ((!children || customFieldsOnly) && !customFields?.length) {
    return null
  }

  return (
    <Box className={className}>
      {children}
      {!!customFields?.length && !hidden && (
        <>
          {customFieldsOnly ? null : (
            <Box mt="s-12" mb="s-12">
              <Box height={1} backgroundColor={Token.color.greyTone10} />
            </Box>
          )}
          <Text mb="s-12" display="block" color={Token.color.greyTone50}>
            Custom fields
          </Text>
          <InputGroup>
            {customFields.map(field => (
              <LapeCustomFieldsRouter field={field} disabled={disabled} key={field.id} />
            ))}
          </InputGroup>
        </>
      )}
    </Box>
  )
}

export default NewStepperSectionCustomFields
