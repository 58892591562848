import { useCallback, useEffect, useState } from 'react'
import { InsightsOptionInterface } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/types'

interface Props {
  selectedInsights: InsightsOptionInterface | null
  onSelectInsights: (insight: InsightsOptionInterface) => void
}

export const useInsightsSelector = (insights: InsightsOptionInterface[]): Props => {
  const [selectedInsights, setSelectedInsights] =
    useState<InsightsOptionInterface | null>(null)

  useEffect(() => {
    if (!insights.length) {
      return
    }

    if (!selectedInsights) {
      setSelectedInsights(insights[0])
    } else {
      const nextSelectedInsights = insights.find(({ id }) => id === selectedInsights.id)

      if (
        !!nextSelectedInsights &&
        nextSelectedInsights.comment !== selectedInsights.comment
      ) {
        setSelectedInsights(nextSelectedInsights)
      }
    }
  }, [JSON.stringify(insights), selectedInsights])

  const onSelectInsights = useCallback(insight => {
    setSelectedInsights(insight)
  }, [])

  return { selectedInsights, onSelectInsights }
}
