import React from 'react'
import { Banner, HStack, Tag, Text, Token } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'

interface Props {
  team: IdAndName
}

export const NoPersonalGoalBanner = ({ team }: Props) => {
  return (
    <Banner>
      <Banner.Content>
        <Banner.Title>This employee has not been assigned any goals</Banner.Title>
        <Banner.Description>
          <HStack gap="s-8">
            <Text>Assessment is based on their contribution to the Team's Goals.</Text>
            {team.name && (
              <Tag
                useIcon="People"
                variant="faded"
                color={Token.color.deepGrey}
                bg={Token.color.deepGrey_20}
                use="a"
                href={pathToFullUrlWithActiveWorkspace(ROUTES.FORMS.TEAM.SUMMARY, {
                  id: team.id,
                })}
                target="_blank"
              >
                {team.name}
              </Tag>
            )}
          </HStack>
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
