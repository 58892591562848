import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { CalendarProviders } from '@src/interfaces/meetings'

export const useOneToOnesCalendarAvailability = (): Record<
  CalendarProviders,
  boolean
> => {
  const isCommercial = useIsCommercial()
  const { settings } = useGlobalSettings()

  return {
    [CalendarProviders.Google]: isCommercial
      ? settings.office_suite_provider === 'google'
      : true,
  }
}
