import React from 'react'
import {
  Avatar,
  Box,
  Carousel,
  HStack,
  Icon,
  Item,
  List,
  Placeholder,
  Tag,
  Text,
  Tile,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { EngagementInsightsInterface } from '@src/interfaces/engagement'
import { FeatureFlags } from '@src/store/auth/types'
import {
  formatAnswerScoreValue,
  getAnswerScoreColor,
  getCategoryIconName,
  getCategoryName,
} from './helpers'

interface SectionProps {
  title: string
  children: React.ReactNode
}
const Section = ({ title, children }: SectionProps) => {
  return (
    <VStack space="s-12">
      <Text fontSize={18} fontWeight={500}>
        {title}
      </Text>
      {children}
    </VStack>
  )
}

interface Props {
  insight: EngagementInsightsInterface
  onClose: VoidFunction
  onClickCreateAction: VoidFunction
}
export const InsightDetailsSideBar = ({
  insight,
  onClose,
  onClickCreateAction,
}: Props) => {
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)

  return (
    <SideBar
      isOpen
      title={insight.title}
      subtitle={
        <HStack align="center" gap="s-6">
          <Icon name={getCategoryIconName(insight.category.id)} size={14} />
          {getCategoryName(insight.category.id)}
        </HStack>
      }
      onClose={onClose}
    >
      <VStack gap="s-24">
        <Section title="Summary">
          <Widget p="s-12">
            <List variant="compact" color={Token.color.greyTone50}>
              {insight.topics.map((topic, i) => (
                <List.Item key={i} useIcon="Dot">
                  {topic}
                </List.Item>
              ))}
            </List>
          </Widget>
        </Section>
        {isEngagementDev && (
          <Section title="Suggested actions">
            <Box maxWidth={500}>
              <Carousel align="side">
                <Carousel.Item minWidth={144}>
                  <Tile
                    use="button"
                    onClick={onClickCreateAction}
                    variant="extra-small"
                    bg={Token.color.widgetBackground}
                  >
                    <Tile.Avatar>
                      <Avatar useIcon="Cleaning" />
                    </Tile.Avatar>
                    <Tile.Title color={Token.color.greyTone50}>
                      Create new action from scratch
                    </Tile.Title>
                  </Tile>
                </Carousel.Item>
              </Carousel>
            </Box>
          </Section>
        )}
        <Section title="Supporting comments">
          {insight.supporting_answers.length > 0 ? (
            <VStack gap="s-8">
              {insight.supporting_answers.map(answer => (
                <Item key={answer.id}>
                  <Item.Content>
                    <Item.Title>
                      {answer.answer_text || "This answer doesn't contain a comment"}
                    </Item.Title>
                    <Item.Description>
                      Q: {answer.question.question_text}
                    </Item.Description>
                  </Item.Content>
                  {typeof answer.answer_score === 'number' && (
                    <Item.Side>
                      <Item.Value>
                        <Tag variant="outlined" color={getAnswerScoreColor(answer)}>
                          {formatAnswerScoreValue(answer)}
                        </Tag>
                      </Item.Value>
                    </Item.Side>
                  )}
                </Item>
              ))}
            </VStack>
          ) : (
            <Widget>
              <Placeholder>
                <Placeholder.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
                  }}
                />
                <Placeholder.Title>
                  We couldn't find any comments related to this insight
                </Placeholder.Title>
                <Placeholder.Description>
                  Even though we found this insight, we couldn't find comments that would
                  help explain it
                </Placeholder.Description>
              </Placeholder>
            </Widget>
          )}
        </Section>
      </VStack>
    </SideBar>
  )
}
