import { useMemo } from 'react'
import { selectorKeys } from '@src/constants/api'
import { CycleOption } from '@src/interfaces/selectors'
import { useGetDepartmentReviewCycles } from '@src/api/performance'
import { useGetSelectors } from '@src/api/selectors'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import {
  Cycle,
  PerformanceHistoryContentType,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

interface Props {
  cycles: Cycle[]
  options: CycleOption[]
  currentlySelectedCycleId?: number
  contentType?: PerformanceHistoryContentType
  isLoading: boolean
}

export const useDepartmentReviewCycles = (departmentId?: number): Props => {
  const { data: contentTypes } = useGetSelectors<PerformanceHistoryContentType>(
    selectorKeys.performance_history_content_types,
  )
  const contentType = contentTypes?.find(({ model }) => model === 'department')

  const { data: cycles = [], isLoading: isCyclesLoading } = useGetDepartmentReviewCycles(
    departmentId,
    contentType?.id,
  )
  const {
    cycles: options,
    initialCycleOffset: currentlySelectedCycleId,
    loading: isCycleOptionsLoading,
  } = useSelectedPerformanceCycle(selectorKeys.all_review_cycles)

  const reviewCyclesIds = useMemo<number[]>(
    () =>
      cycles.reduce<number[]>((ids, cycle) => [...ids, ...cycle.review_cycle_ids], []),
    [cycles],
  )

  return useMemo<Props>(
    () => ({
      cycles,
      options: options.filter(option => reviewCyclesIds.includes(Number(option.id))),
      currentlySelectedCycleId,
      contentType,
      isLoading: isCyclesLoading || isCycleOptionsLoading,
    }),
    [
      cycles,
      options,
      currentlySelectedCycleId,
      contentType,
      reviewCyclesIds,
      isCyclesLoading,
      isCycleOptionsLoading,
    ],
  )
}
