import { TextButton, Widget } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  performanceGradeColumn,
  performanceTimelineNameColumn,
  performanceTimelineSeniorityColumn,
  performanceTimelineSpecialisationColumn,
} from '@src/constants/columns/performance'
import { TableNames } from '@src/constants/table'
import { summaryTableProps } from '@src/features/Summary/common'
import { RowInterface } from '@src/interfaces/data'
import { PerformanceTimelineInterface } from '@src/interfaces/performance'
import React, { useMemo, useState } from 'react'
import { usePerformanceTimelineTable } from '../../EmployeeProfile/Layout/Performance/hooks'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const performanceTimelineRow = (
  specialisationsEnabled: boolean,
): RowInterface<PerformanceTimelineInterface> => ({
  noChildrenRequest: true,
  cells: [
    {
      ...performanceTimelineNameColumn,
      width: 100,
    },
    {
      ...performanceGradeColumn,
      width: 100,
    },
    {
      ...performanceTimelineSeniorityColumn,
      width: 140,
    },
    {
      ...performanceTimelineSpecialisationColumn(specialisationsEnabled),
      width: 100,
    },
  ],
})

interface PerformanceTimelineProps {
  employee: EmployeeInterface
  cycleId?: number | string
}

export const PerformanceTimeline = (props: PerformanceTimelineProps) => {
  const { employee, cycleId } = props
  const [rowsExpanded, setRowsExpanded] = useState(false)

  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const performanceTimelineTable = usePerformanceTimelineTable(employee, cycleId)
  const row = useMemo(
    () => performanceTimelineRow(specialisationsEnabled),
    [specialisationsEnabled],
  )

  const tableData = performanceTimelineTable.table?.data || []
  const cutByLines = 5
  const showBtn = tableData.length > cutByLines
  const filteredTableData =
    !showBtn || rowsExpanded ? tableData : tableData.slice(0, cutByLines)

  return (
    <Widget p="s-16">
      <AdjustableTable
        loading={performanceTimelineTable.table?.loading}
        expandableType="chevron"
        name={TableNames.PerformanceTimelineSidebar}
        noDataMessage="Performance timeline will appear here"
        row={row}
        {...summaryTableProps}
        {...performanceTimelineTable.table}
        data={filteredTableData}
        count={filteredTableData.length}
      />
      {showBtn && (
        <TextButton onClick={() => setRowsExpanded(!rowsExpanded)} mt="s-8">
          View {rowsExpanded ? 'less' : 'more'}
        </TextButton>
      )}
    </Widget>
  )
}
