import {
  useDeleteV2,
  useFetchV2,
  usePostV2,
  UseQueryOptions,
  useUpdateV2,
} from '@src/utils/reactQuery'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { API } from '@src/constants/api'
import { apiV2 } from '@src/api'
import {
  PerformanceInsightsDomain,
  PerformanceInsightsGroupInterface,
  PerformanceInsightsInterface,
  SkillOneToOneInsightsInterface,
  SkillPerformanceInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { EmployeeInterface } from '@src/interfaces/employees'
import { isEmpty } from 'lodash'

export const useGetPerformanceInsightsGroups = (
  queryOptions?: UseQueryOptions<GetRequestInterface<PerformanceInsightsGroupInterface>>,
) =>
  useFetchV2<GetRequestInterface<PerformanceInsightsGroupInterface>>({
    url: API.AI_PERFORMANCE_INSIGHTS_GROUPS,
    queryOptions,
  })

export const useDeleteInsightsGroup = () =>
  useDeleteV2({ url: API.AI_PERFORMANCE_INSIGHTS_GROUPS })

export const performanceInsightsGroupFormRequests: RequestInterfaceNew<PerformanceInsightsGroupInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.AI_PERFORMANCE_INSIGHTS_GROUPS}/${id}`),
    submit: async data => apiV2.post(API.AI_PERFORMANCE_INSIGHTS_GROUPS, data),
    update: async (data, { id }) =>
      apiV2.patch(`${API.AI_PERFORMANCE_INSIGHTS_GROUPS}/${id}`, data),
    delete: async ({ id }) => apiV2.delete(`${API.AI_PERFORMANCE_INSIGHTS_GROUPS}/${id}`),
  }

export const useRegeneratePerformanceInsights = () =>
  usePostV2<undefined, undefined, void>({
    url: '/performance/insightsGroups/regenerateInsights',
  })

export const useGetPerformanceInsightsGroupEligibleEmployees = (id?: number) =>
  useFetchV2<GetRequestInterface<EmployeeInterface>>({
    url: `/performance/insightsGroups/${id}/employees`,
    queryOptions: { enabled: id !== undefined },
  })

interface EmployeeKeyPerformanceInsightsParams {
  cycleId: number
  managerId?: number
}

export const useGetEmployeePerformanceKeyInsights = (
  employeeId: number,
  params: EmployeeKeyPerformanceInsightsParams,
  queryOptions?: UseQueryOptions<PerformanceInsightsInterface | null>,
) =>
  useFetchV2<PerformanceInsightsInterface | null>({
    url: `/employees/${employeeId}/performance/keyInsights`,
    version: 'v2',
    params: {
      params: {
        cycle_id: params.cycleId,
        ...(params.managerId ? { manager_id: params.managerId } : {}),
      },
    },
    queryOptions: {
      ...(queryOptions || {}),
      enabled:
        queryOptions?.enabled && employeeId !== undefined && params.cycleId !== undefined,
      select: data => (isEmpty(data) ? null : data),
    },
  })

interface EmployeeSkillPerformanceInsightsParams {
  cycleId: number
  skillId: number
  managerId?: number
}

export const useGetEmployeeSkillPerformanceInsights = (
  employeeId: number,
  params: EmployeeSkillPerformanceInsightsParams,
  queryOptions?: UseQueryOptions<SkillPerformanceInsightsInterface | null>,
) =>
  useFetchV2<SkillPerformanceInsightsInterface | null>({
    url: `/employees/${employeeId}/performance/skillInsights`,
    version: 'v2',
    params: {
      params: {
        cycle_id: params.cycleId,
        skill_id: params.skillId,
        ...(params.managerId ? { manager_id: params.managerId } : {}),
      },
    },
    queryOptions: {
      ...(queryOptions || {}),
      enabled:
        queryOptions?.enabled &&
        employeeId !== undefined &&
        params.cycleId !== undefined &&
        params.skillId !== undefined,
      select: data => (isEmpty(data) ? null : data),
    },
  })

export const useCommentOnPerformanceInsights = (
  employeeId: number,
  insightId: number,
  insightsDomain: PerformanceInsightsDomain,
) => {
  const url =
    insightsDomain === 'keyInsights'
      ? `/employees/${employeeId}/performance/keyInsights/${insightId}`
      : `/employees/${employeeId}/performance/skillInsights/${insightId}`

  return useUpdateV2<PerformanceInsightsInterface, { comment: string | null }>({
    url,
    version: 'v2',
    usePut: false,
  })
}

export const useGetEmployeeSkillOneToOneInsights = (
  employeeId?: number,
  insightId?: number,
  queryOptions?: UseQueryOptions<SkillOneToOneInsightsInterface[]>,
) =>
  useFetchV2<SkillOneToOneInsightsInterface[]>({
    url: `/employees/${employeeId}/performance/skillInsights/${insightId}/oneToOnes`,
    version: 'v2',
    queryOptions: {
      ...(queryOptions || {}),
      enabled:
        queryOptions?.enabled !== false &&
        employeeId !== undefined &&
        insightId !== undefined,
    },
  })
