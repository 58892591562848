import { format } from 'date-fns'
import { Color, IconName, Token } from '@revolut/ui-kit'
import {
  EmployeeTimelineEventFieldName,
  EmployeeTimelineEventInterface,
} from '@src/interfaces/employeeTimeline'
import { notReachable } from '@src/utils/notReachable'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { hasHRPermissions } from '@src/pages/EmployeeProfile/helpers'
import { useMemo } from 'react'

export const isNull = (value: string | null) => !value || value === 'null'

export const getGroup = (item: EmployeeTimelineEventInterface) => {
  const date = new Date(item.effective_date)

  return format(date, 'MMMM yyyy')
}

export const getIconProps = (
  from: string | null,
): { name: IconName | undefined; color: Color | undefined } => {
  if (isNull(from)) {
    return { name: 'PlusCircle', color: Token.color.blue }
  }

  return { name: undefined, color: undefined }
}

export const changeFieldNameToIconName = (
  changeType: EmployeeTimelineEventFieldName,
): IconName => {
  switch (changeType) {
    case 'Annual salary':
    case 'Salary amount':
    case 'Salary currency':
      return 'Salary'
    case 'Contract type':
      return 'Document'
    case 'Entity':
      return 'Bank'
    case 'Functional manager':
    case 'Line manager':
      return 'Profile'
    case 'Job title':
    case 'Position':
    case 'Specialisation':
    case 'status':
      return 'Services'
    case 'Location':
      return 'Globe'
    case 'Seniority':
    case 'Seniority level':
      return 'StarFilled'
    case 'Team':
      return 'People'
    default:
      return notReachable(changeType)
  }
}

export const useCheckHRPermissions = () => {
  const permissions = useSelector(selectPermissions)
  return useMemo(() => hasHRPermissions(permissions), [permissions])
}
