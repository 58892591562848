import { ApiQueryParams } from '@src/api/types'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import { useEffect, useRef, useState } from 'react'
import { useGenerateInsights, useGetEngagementInsightsStatus } from '@src/api/engagement'

const POLLING_DELAY = 1000

export const useCheckOrGenerateInsights = ({
  roundId,
  scopeParams,
}: {
  roundId: number
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}) => {
  const { mutate: generateInsights, error: generateError } = useGenerateInsights({
    roundId,
  })
  const [polling, setPolling] = useState(false)
  const retryGenerate = useRef(true)

  useEffect(() => {
    retryGenerate.current = true
  }, [roundId, scopeParams])
  const { data, error, isFetching } = useGetEngagementInsightsStatus({
    roundId,
    params: scopeParams,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchInterval: polling && !generateError ? POLLING_DELAY : undefined,
      onSuccess: res => {
        if (res.status.id === 'failed' && retryGenerate.current) {
          generateInsights(scopeParams)
          setPolling(true)
          retryGenerate.current = false
          return
        }

        if (res.status.id === 'not_started') {
          generateInsights(scopeParams)
          setPolling(true)
          return
        }

        if (res.status.id === 'in_progress') {
          setPolling(true)
          return
        }

        setPolling(false)
      },
      onError: () => {
        setPolling(false)
      },
    },
  })

  return {
    insightsStatus: data?.status.id,
    isFetching,
    error: error || generateError,
  }
}
