import React, { useMemo } from 'react'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { connect } from 'lape'
import { OfferChecklistTableInterface } from '@src/interfaces/offerCreation'
import {
  offerChecklistCheckColumn,
  offerChecklistCriteriaColumn,
  offerChecklistReqColumn,
  offerChecklistValueColumn,
} from '@src/constants/columns/offerForm/offerChecklist'
import { useGetOfferChecklist } from '@src/api/offerCreation'
import { formatDate, formatMoney } from '@src/utils/format'
import isNumber from 'lodash/isNumber'
import { Box, Color, Flex, Text, VStack } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { SeniorityInterface } from '@src/interfaces/seniority'

export const ROW: RowInterface<OfferChecklistTableInterface> = {
  cells: [
    {
      ...offerChecklistCriteriaColumn,
      width: 160,
    },
    {
      ...offerChecklistCheckColumn,
      width: 75,
    },
    {
      ...offerChecklistValueColumn,
      width: 200,
    },
    {
      ...offerChecklistReqColumn,
      width: 285,
    },
  ],
}

type Props = {
  id: number
}

const formatMoneyRange = (
  lower?: number,
  upper?: number,
  lowerLocal?: number,
  upperLocal?: number,
  localCurrencyCode?: string,
) => {
  let range =
    isNumber(lower) && isNumber(upper)
      ? `${formatMoney(Math.floor(lower))} - ${formatMoney(Math.floor(upper))}`
      : undefined

  let localRange =
    isNumber(lowerLocal) && isNumber(upperLocal)
      ? `${formatMoney(Math.floor(lowerLocal), localCurrencyCode)} - ${formatMoney(
          Math.floor(upperLocal),
          localCurrencyCode,
        )}`
      : undefined

  if (!range) {
    return '-'
  }

  return (
    <Text>
      {range} {localRange && <Text color={Color.GREY_TONE_50}>({localRange})</Text>}
    </Text>
  )
}

export const getFormattedMoney = (
  amount?: number,
  currencyCode?: string,
  localAmount?: number,
  localCurrencyCode?: string,
) => {
  let money = isNumber(amount) ? formatMoney(Math.floor(amount), currencyCode) : undefined
  let localMoney = isNumber(localAmount)
    ? formatMoney(Math.floor(localAmount), localCurrencyCode)
    : undefined

  if (!money) {
    return '-'
  }

  return (
    <Text>
      {money} {localMoney && <Text color={Color.GREY_TONE_50}>({localMoney})</Text>}
    </Text>
  )
}

const getReqisitionSeniorityRange = (
  seniorityMin?: SeniorityInterface,
  seniorityMax?: SeniorityInterface,
) => {
  if (seniorityMin && seniorityMax) {
    return seniorityMin.name === seniorityMax.name
      ? seniorityMin.name
      : `${seniorityMin.name} - ${seniorityMax.name}`
  }

  return '-'
}

const OfferChecklist = ({ id }: Props) => {
  const { data, isLoading } = useGetOfferChecklist(id)

  const tableData = useMemo<OfferChecklistTableInterface[]>(() => {
    if (!data) {
      return []
    }

    const criteria = data.criteria
    const compensationCriteria = criteria.compensation_criterion
    const budgetCriteria = criteria.budget_criterion
    const signInBonusCriteria = criteria.sign_on_bonus_criterion
    const seniorityCriteria = criteria.seniority_criterion
    const locationCriteria = criteria.location_criterion
    const startDateCriteria = criteria.start_date_criterion

    return [
      {
        criteria: compensationCriteria.criterion_name,
        check: compensationCriteria.criterion_passed,
        offerValue: getFormattedMoney(
          compensationCriteria.offer_value?.converted_amount?.amount,
          compensationCriteria.offer_value?.converted_amount?.currency_iso_code,
          compensationCriteria.offer_value?.local_amount?.amount,
          compensationCriteria.offer_value?.local_amount?.currency_iso_code,
        ),
        reqValue: formatMoneyRange(
          compensationCriteria.benchmark_or_requisition?.converted_amount?.lower_band,
          compensationCriteria.benchmark_or_requisition?.converted_amount?.upper_band,
          compensationCriteria.benchmark_or_requisition?.local_amount?.lower_band,
          compensationCriteria.benchmark_or_requisition?.local_amount?.upper_band,
          compensationCriteria.benchmark_or_requisition?.local_amount?.currency_iso_code,
        ),
      },
      {
        criteria: budgetCriteria.criterion_name,
        tooltip: (
          <Box p="s-8" width={320}>
            <VStack gap="s-4">
              <Text display="block" variant="h6" color="background">
                Impact on department budget (monthly)
              </Text>
              <Flex justifyContent="space-between">
                <Text color="background">Approved budget</Text>
                <Text color="background">
                  {getFormattedMoney(data.current_approved_budget?.staff_cost)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text color="background">Current run rate</Text>
                <Text color="background">
                  {getFormattedMoney(data.current_run_rate?.staff_cost)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text color="background">Budget available</Text>
                <Text color="background">
                  {getFormattedMoney(data.available_budget?.staff_cost)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text color="background">Variance of offer with requisition budget</Text>
                <Text color="background">
                  {data.offer_form_compensation?.converted_amount?.amount &&
                  data.requisition_budget?.converted_amount?.amount
                    ? getFormattedMoney(
                        (data.requisition_budget.converted_amount.amount -
                          data.offer_form_compensation.converted_amount.amount) /
                          12,
                      )
                    : '-'}
                </Text>
              </Flex>
            </VStack>
          </Box>
        ),
        check: budgetCriteria.criterion_passed,
        offerValue: getFormattedMoney(
          budgetCriteria.offer_value?.converted_amount?.amount,
        ),
        reqValue: getFormattedMoney(
          budgetCriteria.benchmark_or_requisition?.converted_amount?.amount,
        ),
      },
      {
        criteria: signInBonusCriteria.criterion_name,
        check: signInBonusCriteria.criterion_passed,
        offerValue: getFormattedMoney(
          signInBonusCriteria.offer_value?.amount,
          signInBonusCriteria.offer_value?.currency_iso_code,
        ),
        reqValue: formatMoneyRange(
          signInBonusCriteria.benchmark_or_requisition?.converted_amount?.lower_band,
          signInBonusCriteria.benchmark_or_requisition?.converted_amount?.upper_band,
        ),
      },
      {
        criteria: seniorityCriteria.criterion_name,
        check: seniorityCriteria.criterion_passed,
        offerValue: seniorityCriteria.offer_value?.name,
        reqValue: getReqisitionSeniorityRange(
          seniorityCriteria.benchmark_or_requisition?.seniority_min,
          seniorityCriteria.benchmark_or_requisition?.seniority_max,
        ),
      },
      {
        criteria: locationCriteria.criterion_name,
        check: locationCriteria.criterion_passed,
        offerValue: locationCriteria.offer_value,
        reqValue: locationCriteria.benchmark_or_requisition?.join(', '),
      },
      {
        criteria: startDateCriteria.criterion_name,
        check: startDateCriteria.criterion_passed,
        offerValue: formatDate(startDateCriteria.offer_value),
        reqValue: formatDate(startDateCriteria.benchmark_or_requisition),
      },
    ]
  }, [data])

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.OfferChecklist}
          row={ROW}
          noReset
          hideCountAndButtonSection
          loading={isLoading}
          data={tableData}
          count={tableData.length}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default connect(OfferChecklist)
