import { PerformanceSelector } from '@src/interfaces/performance'
import React, { useEffect } from 'react'
import { ActionButton, Box, Icon, Token } from '@revolut/ui-kit'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/CollapsableTableWrapper'
import { CalculatedDeliverablesRating } from './useCalculatedDeliverablesRating'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableTypes } from '@src/interfaces/table'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { usePIPGoalContext } from '@src/features/PIP/components/PipSmartGoalSideBar'
import {
  smartGoalBaseRow,
  useSmartGoalsTable,
} from '@src/features/PIP/components/PipSmartGoalsTable'
import { useCanSetPIPGoals } from '@src/features/PIP/permissions/usePipGoalsPermissions'
import { usePerformanceTabContext } from '../PerformanceTabContext'

interface Props {
  employeeId: number
  isSummaryReview?: boolean
  selectedCycle: PerformanceSelector
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
}

export const SummaryPipGoalsCollapsable = ({
  employeeId,
  selectedCycle,
  calculatedDeliverablesRating,
  isSummaryReview,
}: Props) => {
  const performanceTabContext = usePerformanceTabContext()
  const canSetPIPGoals = useCanSetPIPGoals(employeeId)
  const table = useSmartGoalsTable(employeeId)
  const { close, open } = usePIPGoalContext()

  performanceTabContext.summaryPipGoalsTableRef.current = table

  useEffect(() => {
    return () => {
      performanceTabContext.summaryPipGoalsTableRef.current = undefined
    }
  }, [])

  const onGoalDeleted = () => {
    close()
    table.refresh()
  }

  const onAfterSubmit = () => {
    close()
    table.refresh()
  }

  const handleEditGoal = (goal: DeliverableInterface) => {
    open({
      variant: 'edit',
      goal,
      employeeId,
      onAfterSubmit,
    })
  }

  const onRowClick = (goal: DeliverableInterface) => {
    open({
      variant: 'details',
      goal,
      employeeId,
      pipCycle: selectedCycle,
      onGoalDeleted,
      onEditClick: handleEditGoal,
      onGoalUpdated: onAfterSubmit,
    })
  }

  const handleAddGoal = () => {
    open({ variant: 'add', employeeId, pipCycle: selectedCycle, onAfterSubmit })
  }

  const additionalContent =
    canSetPIPGoals && table.data.length > 0 ? (
      <ActionButton useIcon="Plus" my={9} mx={16} onClick={handleAddGoal}>
        Add PIP goals
      </ActionButton>
    ) : null

  return (
    <Box mt="s-16">
      <CollapsableTableWrapper
        mapperFunc={calculatedDeliverablesRating?.mapper}
        gradesMap={calculatedDeliverablesRating?.gradesMap}
        completedReviews={isSummaryReview}
        icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
        headerTitle="Goals"
        headerRating={calculatedDeliverablesRating?.rating}
        count={table.data.length}
        ratings={calculatedDeliverablesRating?.ratings}
        additionalContent={additionalContent}
      >
        <AdjustableTable<DeliverableInterface>
          name={TableNames.PipSummaryGoals}
          type={TableTypes.Contained}
          emptyState={
            <EmptyTableRaw
              imageId="3D304"
              title="PIP goals pending"
              description="Line Manager needs to provide PIP goals"
              action={
                canSetPIPGoals ? (
                  <ActionButton useIcon="Plus" onClick={handleAddGoal}>
                    Add PIP goals
                  </ActionButton>
                ) : null
              }
            />
          }
          row={smartGoalBaseRow}
          onRowClick={onRowClick}
          {...table}
        />
      </CollapsableTableWrapper>
    </Box>
  )
}
