import { AxiosError, AxiosResponse } from 'axios'

import { apiV2 } from '@src/api'
import { ApiQueryParams } from '@src/api/types'
import {
  GetRequestData,
  GetRequestInterface,
  TableRequestInterface,
} from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { DetailsPageType } from '@src/pages/Performance/Engagement/components/SurveyResults/ResultsWidget/common'
import { useFetchV2, UseQueryOptions } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import {
  SurveyAnalyticsApiQueryParam,
  EngagementResultInterface,
  EngagementResultsHeatmapRowInterface,
  EngagementSurveyAnalyticsInterface,
  ItemsToAnalyse,
} from './interfaces'

export const useGetSurveyAnalytics = ({
  surveyId,
  surveyRoundId,
  filters,
  enabled = true,
}: {
  surveyId: number | undefined
  surveyRoundId: number | undefined
  filters?: FilterByInterface[]
  enabled?: boolean
}) =>
  useFetchV2<EngagementSurveyAnalyticsInterface>({
    url: `/engagement/analytics/survey/${surveyId}`,
    params: {
      params: { ...groupFiltersIntoURLQuery(filters), survey_round_id: surveyRoundId },
    },
    queryOptions: {
      enabled: Boolean(enabled && surveyId != null),
    },
  })

export const getEngagementQuestionsResultsTableRequests = <S>(
  surveyId: number,
): TableRequestInterface<EngagementResultInterface, S> => {
  return {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get(`/engagement/analytics/survey/${surveyId}/questions`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }
}

const mapNestedPlaceholders = <T>(response: AxiosResponse<GetRequestInterface<T>>) => ({
  ...response,
  data: {
    ...response.data,
    results: response.data.results.map(row => ({
      ...row,
      isParent: true,
      children: [null],
    })),
  },
})

export const getEngagementCategoriesResultsTableRequests = <S>(
  surveyId: number,
): TableRequestInterface<EngagementResultInterface, S> => {
  return {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2
        .get<GetRequestInterface<EngagementResultInterface>>(
          `/engagement/analytics/survey/${surveyId}/drivers`,
          { params: filterSortPageIntoQuery(sortBy, filters, page) },
        )
        .then(mapNestedPlaceholders),
  }
}

export const getSurveyResultsHeatmapTableRequests = ({
  itemsToAnalyse,
  surveyId,
  surveyRoundId,
  onError,
}: {
  surveyId: number
  surveyRoundId?: number
  itemsToAnalyse: ItemsToAnalyse
  onError?: (e: AxiosError) => void
}): TableRequestInterface<EngagementResultsHeatmapRowInterface> => {
  return {
    // @ts-expect-error current table implementation doesn't support empty returns from getters on the type level
    getItems: async ({ sortBy, filters, page }) => {
      if (itemsToAnalyse === 'categories') {
        return apiV2
          .get<GetRequestInterface<EngagementResultsHeatmapRowInterface>>(
            `/engagement/analytics/survey/${surveyId}/drivers/heatmap`,
            {
              params: {
                ...filterSortPageIntoQuery(sortBy, filters, page),
                survey_round_id: surveyRoundId,
              },
            },
          )
          .then(mapNestedPlaceholders)
          .catch(e => {
            onError?.(e) // see @ts-expect-error above
          })
      }
      return apiV2
        .get<GetRequestInterface<EngagementResultsHeatmapRowInterface>>(
          `/engagement/analytics/survey/${surveyId}/questions/heatmap`,
          {
            params: {
              ...filterSortPageIntoQuery(sortBy, filters, page),
              survey_round_id: surveyRoundId,
            },
          },
        )
        .catch(e => {
          onError?.(e) // see @ts-expect-error above
        })
    },
  }
}

export const useGetSurveyResultsItemDetails = (
  surveyId: number | string,
  itemsToAnalyse?: DetailsPageType,
  itemId?: number | string,
  params?: ApiQueryParams<SurveyAnalyticsApiQueryParam>,
) =>
  useFetchV2<EngagementResultInterface>({
    url: `/engagement/analytics/survey/${surveyId}/${
      itemsToAnalyse === 'question' ? 'questions' : 'drivers'
    }/${itemId}`,
    params: { params },
    queryOptions: { enabled: Boolean(itemsToAnalyse && itemId) },
  })

export const useGetEngagementDrivers = ({
  surveyId,
  queryOptions,
  params,
}: {
  surveyId: number
  queryOptions?: UseQueryOptions<GetRequestData<EngagementResultInterface>>
  params?: ApiQueryParams<SurveyAnalyticsApiQueryParam>
}) =>
  useFetchV2<GetRequestData<EngagementResultInterface>>({
    url: `/engagement/analytics/survey/${surveyId}/drivers`,
    params: { params },
    queryOptions,
  })
