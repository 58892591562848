import React from 'react'

import {
  Box,
  Color,
  HStack,
  Icon,
  IconName,
  Tag,
  Text,
  TextSkeleton,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { PipelineStageStatus, ProbationInterface } from '@src/interfaces/probation'
import { CheckpointType } from '@src/interfaces/probationReview'
import { useGetPipelineStages } from '@src/api/probationEmployees'

interface Props {
  probationData: ProbationInterface
}

const statusIconMap: Record<PipelineStageStatus, IconName> = {
  pending: '16/SandWatch',
  completed: 'Check',
  ongoing: 'Flag',
}

const statusColorMap: Record<PipelineStageStatus, Color> = {
  pending: Token.color.greyTone50,
  completed: Token.color.success,
  ongoing: Token.color.accent,
}

export const CurrentStageCell = ({ probationData }: Props) => {
  const tooltip = useTooltip()
  const { data: stageData, isLoading } = useGetPipelineStages({
    employeeId: probationData.employee.id,
    cycleId: probationData.cycle.id,
    enabled: tooltip.state,
  })
  const getTagName = () => {
    switch (probationData.checkpoint_type) {
      case CheckpointType.Recommendation:
        return 'Mgr recommendation'
      case CheckpointType.Decision:
        return 'Committee review'
      case CheckpointType.Review:
        return `Checkpoint ${probationData.checkpoint_number}`
      case CheckpointType.Goals:
        return `${
          probationData.cycle.category === 'PIP' ? 'PIP' : 'Probation'
        } goals setting`
      default:
        return '-'
    }
  }

  return (
    <Box>
      <Tag
        useIcon="Flag"
        variant="faded"
        color={Token.color.deepGrey}
        bg={Token.color.deepGrey_20}
        {...tooltip.getAnchorProps()}
      >
        {getTagName()}
      </Tag>
      <Tooltip {...tooltip.getTargetProps()} minWidth={217}>
        <VStack gap="s-2">
          <Text variant="emphasis2">Stages</Text>
          {isLoading ? (
            <>
              <TextSkeleton variant="body3" />
              <TextSkeleton variant="body3" />
            </>
          ) : (
            stageData?.map(stage => (
              <HStack key={stage.name} gap="s-4">
                <Icon
                  name={statusIconMap[stage.status]}
                  color={statusColorMap[stage.status]}
                  size={16}
                />
                <Text variant="body3" color={Token.color.greyTone50}>
                  {stage.name}
                </Text>
              </HStack>
            ))
          )}
        </VStack>
      </Tooltip>
    </Box>
  )
}
