import React, { useMemo } from 'react'
import {
  Tooltip,
  Subheader,
  Group,
  Item,
  Text,
  HStack,
  Icon,
  Token,
  useTooltip,
  Link,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { SkillLevels } from '@src/interfaces/roles'
import { groupBy } from 'lodash'
import { SkillResource } from '@src/interfaces/skills'
import { ResourceAvatar } from './ResourceAvatar'

interface Props {
  skillResources: SkillResource[]
}

export const SkillResources = ({ skillResources }: Props) => {
  const { data: skillLevels, isLoading } = useGetSelectors<IdAndName<SkillLevels>>(
    selectorKeys.skill_resource_levels,
  )
  const tooltip = useTooltip()

  const groupedAndSortedResources = useMemo(() => {
    const groupedResources = groupBy(skillResources, 'level.id')
    return skillLevels?.reduce<{ name: string; resources: SkillResource[] }[]>(
      (acc, curr) => {
        if (Object.prototype.hasOwnProperty.call(groupedResources, curr.id)) {
          acc.push({ name: curr.name, resources: groupedResources[curr.id] })
        }
        return acc
      },
      [],
    )
  }, [skillLevels, skillResources])

  if (!skillResources.length) {
    return null
  }

  return (
    <>
      <Subheader variant="default">
        <Subheader.Title>
          <HStack gap="s-8" align="center">
            <Text>Skill resources</Text>
            <Icon name="InfoOutline" size={16} {...tooltip.getAnchorProps()} />
            <Tooltip {...tooltip.getTargetProps()} maxWidth={216}>
              Links to any external or internal resources with guidance on how to improve
              this skill. For example, articles, courses, master classes, etc.
            </Tooltip>
          </HStack>
        </Subheader.Title>
      </Subheader>
      <Group>
        {isLoading && <ItemSkeleton />}
        {groupedAndSortedResources?.map(({ name, resources }) => (
          <React.Fragment key={name}>
            <Subheader variant="nested">{name}</Subheader>
            {resources.map(resource => (
              <Item
                key={resource.url}
                use={Link}
                target="_blank"
                rel="noopener noreferrer"
                href={resource.url}
              >
                <Item.Avatar>
                  <ResourceAvatar resource={resource} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title color={Token.color.foreground}>{resource.title}</Item.Title>
                  <Item.Description>{resource.description}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <Icon name="LinkExternal" color={Token.color.foreground} />
                </Item.Side>
              </Item>
            ))}
          </React.Fragment>
        ))}
      </Group>
    </>
  )
}
