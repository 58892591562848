import { GetRequestInterface, IdAndName, RequestInterfaceNew } from '@src/interfaces'
import {
  OrganisationStructure,
  OrganisationStructureLevel,
  OrganisationStructureLevelTab,
} from './types'
import { apiV2 } from '@src/api'
import { useFetchV2, useUpdateV2 } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'

export const structureRequests: RequestInterfaceNew<OrganisationStructure> = {
  get: async ({ id }) => apiV2.get(`${API.ORGANISATION_STRUCTURES}/${id}`),
  update: async (data, { id }) =>
    apiV2.patch(`${API.ORGANISATION_STRUCTURES}/${id}`, data),
  submit: async data => apiV2.post(`${API.ORGANISATION_STRUCTURES}`, data),
  delete: async ({ id }) => apiV2.delete(`${API.ORGANISATION_STRUCTURES}/${id}`),
}

export const deleteStructure = (id: number) =>
  apiV2.delete(`${API.ORGANISATION_STRUCTURES}/${id}`)

export const useUpdateStructure = () =>
  useUpdateV2<OrganisationStructure, OrganisationStructure>({
    url: API.ORGANISATION_STRUCTURES,
  })

export const useOrganisationStructure = (id: number) =>
  useFetchV2<OrganisationStructure>({
    url: `${API.ORGANISATION_STRUCTURES}/${id}`,
  })

export const useOrganisationStructures = () =>
  useFetchV2<GetRequestInterface<OrganisationStructure>>({
    url: API.ORGANISATION_STRUCTURES,
  })

export const structureLevelRequests: RequestInterfaceNew<OrganisationStructureLevel> = {
  get: async ({ levelId: id }) =>
    apiV2.get(`${API.ORGANISATION_STRUCTURES_LEVELS}/${id}`),
  update: async (data, { levelId: id }) =>
    apiV2.patch(`${API.ORGANISATION_STRUCTURES_LEVELS}/${id}`, data),
  submit: async data => apiV2.post(`${API.ORGANISATION_STRUCTURES_LEVELS}`, data),
  delete: async (_, { levelId: id }) =>
    apiV2.delete(`${API.ORGANISATION_STRUCTURES_LEVELS}/${id}`),
}

export const useUpdateStructureLevel = () =>
  useUpdateV2<OrganisationStructureLevel, OrganisationStructureLevel>({
    url: API.ORGANISATION_STRUCTURES_LEVELS,
  })

export const deleteStructureLevel = (id: number) =>
  apiV2.delete(`${API.ORGANISATION_STRUCTURES_LEVELS}/${id}`)

export const structureLevelTabRequests: RequestInterfaceNew<OrganisationStructureLevelTab> =
  {
    get: async ({ levelId, id }) =>
      apiV2.get(`${API.ORGANISATION_STRUCTURES_LEVELS}/${levelId}/tabs/${id}`),
    update: async (data, { levelId, id }) =>
      apiV2.patch(`${API.ORGANISATION_STRUCTURES_LEVELS}/${levelId}/tabs/${id}`, data),
    submit: async (data, { levelId }) =>
      apiV2.post(`${API.ORGANISATION_STRUCTURES_LEVELS}/${levelId}/tabs`, data),
    delete: async (_, { levelId, id }) =>
      apiV2.delete(`${API.ORGANISATION_STRUCTURES_LEVELS}/${levelId}/tabs/${id}`),
  }

export const structureLevelSelectedUnitsSelector = (levelId: number) =>
  apiV2.get<{ results: IdAndName[] }>(
    `${API.ORGANISATION_STRUCTURES_LEVELS}/${levelId}/units`,
  )
