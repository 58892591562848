import {
  TimeSeriesBreakdownInterface,
  TimeSeriesInterface,
} from '@src/interfaces/totalCompensation'
import { Box, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { formatNumber } from '@src/utils/format'
import React from 'react'

interface BreakDownProps {
  breakdown: TimeSeriesBreakdownInterface[]
}

export const BonusBreakdown = ({ breakdown }: BreakDownProps) => {
  if (breakdown.length === 0) {
    return null
  }

  return (
    <Box pl="s-24">
      <ul>
        {breakdown.map((item: TimeSeriesBreakdownInterface) => (
          <li key={item.year}>
            <Flex gap="s-20">
              <Text color={Token.color.background} variant="body3" use="div">
                {`Bonus from ${item.year}:`}
              </Text>
              <Text color={Token.color.background} ml="auto" variant="body3" use="div">
                {formatNumber(Number(item.value))}
              </Text>
            </Flex>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export const BenefitsBreakdown = ({ breakdown }: BreakDownProps) => {
  if (breakdown.length === 0) {
    return null
  }

  return (
    <Box pl="s-24">
      <ul>
        {breakdown.map((item: TimeSeriesBreakdownInterface, i) => (
          <li key={i}>
            <Flex gap="s-20">
              <Text color={Token.color.background} variant="body3" use="div">
                {item.reason}:
              </Text>
              <Text color={Token.color.background} ml="auto" variant="body3" use="div">
                {formatNumber(Number(item.value))}
              </Text>
            </Flex>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export const EquityBreakdown = ({ breakdown }: BreakDownProps) => {
  if (breakdown.length === 0) {
    return null
  }

  const aggregatedByYearData = breakdown.reduce<Record<string, TimeSeriesInterface>>(
    (acc, item) => {
      if (!acc[item.year]) {
        acc[item.year] = { breakdown: [], value: '0.00' }
      }
      acc[item.year].breakdown.push(item)
      return acc
    },
    {},
  )

  return (
    <Box pl="s-24">
      <ul>
        {Object.keys(aggregatedByYearData).map(year => (
          <li key={year}>
            <VStack>
              <Text color={Token.color.background} variant="body3" use="div">
                {`Equity from ${year}:`}
              </Text>
              <ul>
                {aggregatedByYearData[year].breakdown.map(({ reason, value }, i) => (
                  <Flex gap="s-20" key={`${reason}_${i}`}>
                    <Text
                      color={Token.color.background}
                      variant="body3"
                      use="div"
                    >{`${reason}:`}</Text>
                    <Text
                      color={Token.color.background}
                      ml="auto"
                      variant="body3"
                      use="div"
                    >
                      {formatNumber(Number(value))}
                    </Text>
                  </Flex>
                ))}
              </ul>
            </VStack>
          </li>
        ))}
      </ul>
    </Box>
  )
}
