import React from 'react'
import { Box, DataPointSkeleton, Flex, TextSkeleton, VStack } from '@revolut/ui-kit'
import { times } from '@src/utils/times'
import { metricWidgetStylesProps } from '@src/features/AiInsights/Performance/components/Metrics/common/styles'
import { SummarySection } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/types'

interface Props {
  sections: SummarySection[]
}

export const SummarySkeleton = ({ sections }: Props) => {
  const hasHeaderSection = sections.includes('header')
  const hasHighlightsSection = sections.includes('highlights')
  const hasNotesSection =
    sections.includes('summary') ||
    sections.includes('strengths') ||
    sections.includes('weaknesses')

  return (
    <Box>
      <VStack space="s-8">
        {hasHeaderSection && (
          <Box>
            <TextSkeleton variant="heading3" width={200} />
            <TextSkeleton width={80} />
          </Box>
        )}
        {hasHighlightsSection && (
          <Flex gap="s-8">
            {times(
              index => (
                <DataPointSkeleton
                  key={index}
                  p="s-12"
                  {...metricWidgetStylesProps}
                  flex={1}
                >
                  <DataPointSkeleton.Label />
                  <DataPointSkeleton.Value />
                  <DataPointSkeleton.Label />
                </DataPointSkeleton>
              ),
              3,
            )}
          </Flex>
        )}
        {hasNotesSection && (
          <>
            <TextSkeleton width={80} />
            <VStack space="s-8" p="s-16" {...metricWidgetStylesProps}>
              <TextSkeleton width="33%" />
              <TextSkeleton width="88%" />
              <TextSkeleton width="66%" />
            </VStack>
          </>
        )}
      </VStack>
    </Box>
  )
}
