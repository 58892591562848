import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon, Tag, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import {
  getMetricSourceAvatar,
  getMetricSourceLabel,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { MetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { SourceItem } from '@src/features/AiInsights/Performance/components/Sources/common/SourceItem'
import {
  externalMetricSources,
  internalMetricSources,
} from '@src/features/AiInsights/Performance/models/metricSourceModel'

interface Props {
  source: MetricSources
  info?: React.ReactNode
}

export const MetricsSourceItem = ({ source, info }: Props) => (
  <SourceItem
    title={getMetricSourceLabel(source)}
    avatar={getMetricSourceAvatar(source)}
    side={<MetricSourceItemTag source={source} />}
    info={<MetricSourceItemInfo info={info} />}
  />
)

const MetricSourceItemTag = ({ source }: Pick<Props, 'source'>) => {
  const isExternalMetric = !!externalMetricSources.find(
    externalSource => externalSource === source,
  )
  const isInternalMetric = !!internalMetricSources.find(
    internalSource => internalSource === source,
  )
  const isUnknownMetric = !isExternalMetric && !isInternalMetric

  if (isUnknownMetric) {
    return null
  }

  if (isExternalMetric) {
    return (
      <Tag variant="outlined" color={Token.color.deepGrey}>
        <FormattedMessage
          id="performance.insights.group.external.source.item.tag"
          defaultMessage="External"
        />
      </Tag>
    )
  }

  return (
    <Tag variant="outlined" color={Token.color.deepGrey}>
      <FormattedMessage
        id="performance.insights.group.internal.source.item.tag"
        defaultMessage="Internal"
      />
    </Tag>
  )
}

const MetricSourceItemInfo = ({ info }: Pick<Props, 'info'>) => {
  const tooltip = useTooltip()

  if (!info) {
    return null
  }

  return (
    <Icon name="InfoOutline" size={18} {...tooltip.getAnchorProps()}>
      <Tooltip placement="right" width={240} {...tooltip.getTargetProps()}>
        {info}
      </Tooltip>
    </Icon>
  )
}
