import React from 'react'
import { BoxProps, Token } from '@revolut/ui-kit'

export const metricsGridGap = '8px'
export const metricsGridCellItemStyles: React.CSSProperties = {
  minWidth: `calc(50% - ${metricsGridGap} * 2`,
}

export const metricWidgetStylesProps: BoxProps = {
  radius: Token.radius.r16,
  bg: Token.color.widgetBackground,
}
