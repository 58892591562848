import { FeatureFlags } from './types'

export enum Authenticated {
  authenticated = 'true',
  not_authenticated = 'false',
}

export const featureBundles = [
  FeatureFlags.BundleHR,
  FeatureFlags.BundleRecruitment,
] as const
