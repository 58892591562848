import React from 'react'
import {
  Spacer,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
  VStackProps,
} from '@revolut/ui-kit'
import { BAR_ITEM_HEIGHT } from '@src/features/ReviewCycle/Analytics/Skills/Charts/RatingDistributionGridBarChart/Row'

interface Props extends VStackProps {
  children: React.ReactNode
  tooltipContent: React.ReactNode
}

export const Column = ({ children, tooltipContent, ...vStackProps }: Props) => {
  const tooltip = useTooltip()

  return (
    <>
      <VStack
        height={BAR_ITEM_HEIGHT}
        p="s-4"
        radius={Token.radius.r6}
        {...vStackProps}
        {...tooltip.getAnchorProps()}
        data-testid="performance_analytics_skills_ratings_distribution_grid_chart_column"
      >
        <Spacer />
        <Text textAlign="center" fontSize={12} use="p">
          {children}
        </Text>
        <Spacer />
      </VStack>
      <Tooltip {...tooltip.getTargetProps()}>{tooltipContent}</Tooltip>
    </>
  )
}
