import React, { PropsWithChildren } from 'react'
import Form from '@src/features/Form/Form'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { performanceInsightsGroupFormRequests } from '@src/features/AiInsights/Performance/api/insights'

export const EditForm = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Form<PerformanceInsightsGroupInterface> api={performanceInsightsGroupFormRequests}>
      {children}
    </Form>
  )
}
