import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CreateForm } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/CreateForm'
import { Layout } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Layout'
import { SubmitFormAction } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/FormActions'

export const CreatePage = () => {
  return (
    <CreateForm>
      <Layout
        title={
          <FormattedMessage
            id="forms.performance.insights.group.create.title"
            defaultMessage="Add new insights group"
          />
        }
        actions={({ label, onSave }) => (
          <SubmitFormAction
            label={label}
            onAfterSubmit={formData => {
              onSave(formData)

              navigateReplace(
                pathToUrl(ROUTES.FORMS.PERFORMANCE_INSIGHTS.EDIT, {
                  id: formData.id,
                }),
              )
            }}
          />
        )}
      />
    </CreateForm>
  )
}
