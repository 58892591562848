import {
  Flex,
  Text,
  Box,
  Separator,
  Circle,
  Icon,
  Token,
  Ellipsis,
} from '@revolut/ui-kit'
import {
  FinalGrade,
  PerformanceSelector,
  PerformanceTimelineStats,
} from '@src/interfaces/performance'
import React, { useMemo } from 'react'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { StepSection } from './components/StepSection'
import { HeaderSummarySkeleton } from './components/HeaderSummarySkeleton'
import { GradeSection } from './components/GradeSection'
import { usePerformanceSummaryData } from './usePerfomanceSummaryData'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import { useGetPromotionProgression } from '@src/api/promotions'

interface Props {
  isLoading: boolean
  initialPeriod: PerformanceSelector | undefined
  checkpoints: ProbationCheckpoints | undefined
  timelineStats: PerformanceTimelineStats | null | undefined
  startDate: string
  seniorityName: string
  isExternalUserHeader: boolean
  employeeId: number
  isCurrentUserProfile: boolean
  probationGrade?: FinalGrade
}

export const PerformanceSummaryHeader = ({
  isLoading,
  initialPeriod,
  checkpoints,
  timelineStats,
  startDate,
  seniorityName,
  isExternalUserHeader,
  employeeId,
  isCurrentUserProfile,
  probationGrade,
}: Props) => {
  const { data: progression, isLoading: isPromotionLoading } = useGetPromotionProgression(
    employeeId,
    initialPeriod?.id,
  )

  const showProgression =
    !!progression?.eligibility_criteria?.cycles_in_role_and_seniority &&
    !!progression?.eligibility_criteria?.strong_plus_grades_in_recent_cycles &&
    !!progression?.eligibility_criteria_result?.progression_cycle

  const {
    isEligible = false,
    grade,
    periodCategory,
    periodName,
    title,
    firstStepTitle,
    firstStepSubTitle,
    firstStepCount,
    firstStepRequiredCount,
    middleStepRequiredCount,
    middleStepCount,
    middleStepTitle,
    middleStepSubTitle,
    lastStepTitle,
    lastStepSubtitle,
  } = usePerformanceSummaryData({
    initialPeriod,
    progression,
    checkpoints,
    timelineStats,
    isCurrentUserProfile,
    probationGrade,
  })
  const pickStepProgressColor = (count: number, expectation: number) => {
    if (expectation === 0) {
      return Token.color.accent
    }

    if (count >= expectation) {
      return Token.color.success
    }

    return Token.color.accent
  }

  const pickStepProgressText = (data: number, amountRequired: number) => {
    return `${data}/${amountRequired}`
  }

  const isNoPerformanceState =
    isExternalUserHeader ||
    (!showProgression && initialPeriod?.category === ReviewCycleCategory.Performance)

  const subTitle = useMemo(() => {
    return periodCategory &&
      [
        ReviewCycleCategory.PIP,
        ReviewCycleCategory.PIP_V2,
        ReviewCycleCategory.Probation,
      ].includes(periodCategory)
      ? 'Assessed grade'
      : 'Overall grade'
  }, [periodCategory])
  const isPending = isPromotionLoading || isLoading
  return (
    <>
      {(isPending || !initialPeriod) && <HeaderSummarySkeleton />}
      {!isPending && initialPeriod && (
        <Flex
          pr="s-72"
          pb="s-4"
          gap="s-72"
          style={{ flexGrow: 1 }}
          justifyContent="space-between"
        >
          <GradeSection
            category={timelineStats?.cycle?.category}
            grade={grade}
            periodName={periodName}
            subTitle={subTitle}
          />
          <Flex gap="s-16" flexDirection="column">
            <Text color={Token.color.greyTone50} variant="emphasis2">
              {title}
            </Text>

            <StepSection
              isExternalUserHeader={isNoPerformanceState}
              title={firstStepTitle}
              subTitle={firstStepSubTitle}
              requiredCount={firstStepCount}
              color={pickStepProgressColor(firstStepCount, firstStepRequiredCount)}
              value={
                firstStepRequiredCount === 0 ? 0 : firstStepCount / firstStepRequiredCount
              }
              progressText={pickStepProgressText(firstStepCount, firstStepRequiredCount)}
            />
          </Flex>

          <StepSection
            isExternalUserHeader={isNoPerformanceState}
            alignItems="flex-end"
            title={middleStepTitle}
            subTitle={middleStepSubTitle}
            requiredCount={middleStepCount}
            color={pickStepProgressColor(middleStepCount, middleStepRequiredCount)}
            value={
              middleStepRequiredCount === 0
                ? 0
                : middleStepCount / middleStepRequiredCount
            }
            progressText={pickStepProgressText(middleStepCount, middleStepRequiredCount)}
          />

          <Flex gap="s-16" justifyContent="space-between" alignItems="flex-end">
            <Box mb="-s-8" height={60}>
              <Separator orientation="vertical" />
            </Box>
            <Circle bg={Token.color.greyTone10} size={44}>
              <Icon name={isEligible ? 'Trophy' : 'Profile'} size={24} />
            </Circle>

            <Flex gap="s-2" flexDirection="column">
              <Text whiteSpace="nowrap" variant="emphasis1">
                {isNoPerformanceState ? seniorityName : lastStepTitle}
              </Text>
              <Text
                color={isEligible ? Token.color.success : Token.color.greyTone50}
                whiteSpace="nowrap"
                variant="body2"
              >
                <Ellipsis tooltip="always" maxWidth={300}>
                  {isNoPerformanceState ? `Started ${startDate}` : lastStepSubtitle}
                </Ellipsis>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}
