import React from 'react'
import { Box, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { formatMoney, formatNumber } from '@src/utils/format'
import {
  BENEFITS_LABEL,
  ON_TARGET_BONUS_LABEL,
  VESTED_EQUITY_LABEL,
} from '../Components/CompensationOverTime'
import { BarChartDataSeriesInterface } from '../Components/EquityOverTime'
import { BonusBreakdown, BenefitsBreakdown, EquityBreakdown } from './TooltipBreakdowns'

interface TooltipValueInterface {
  color?: string
  label: string
  value: string
}

export const BarChartTooltip = (
  props: any,
  dataKeys?: DataKey<BarChartDataSeriesInterface>[],
) => {
  const values = dataKeys?.reduce((acc: TooltipValueInterface[], dataKey) => {
    if (Number(props[dataKey.value])) {
      acc.push({
        color: dataKey.color ? `rgb(${dataKey.color})` : undefined,
        label: dataKey.label,
        value: props[dataKey.value],
      })
    }
    return acc
  }, [])

  return (
    <VStack space="s-4">
      {props.total && (
        <Flex justifyContent="space-between">
          <Text color={Token.color.background} pr="s-16" variant="emphasis2">
            {props.year}
          </Text>
          <Text color={Token.color.background} variant="emphasis2">
            {formatMoney(Number(props.total), props.currency.iso_code)}
          </Text>
        </Flex>
      )}

      {dataKeys &&
        values?.map((value, id) => (
          <Box key={id}>
            <Flex alignItems="center" justifyContent="flex-start" gap="s-12">
              {value.color ? (
                <Box
                  bg={value.color}
                  border={`1px solid ${Token.color.greyTone50}`}
                  borderRadius={Token.radius.r2}
                  height="s-12"
                  width="s-12"
                />
              ) : null}
              <Text color={Token.color.background} variant="body3" use="div">
                {value.label}:
              </Text>
              <Text color={Token.color.background} ml="auto" variant="body3" use="div">
                {formatNumber(Number(value.value))}
              </Text>
            </Flex>
            {value.label === ON_TARGET_BONUS_LABEL && (
              <BonusBreakdown breakdown={props.on_target_bonus.breakdown} />
            )}
            {value.label === VESTED_EQUITY_LABEL && (
              <EquityBreakdown breakdown={props.vested_equity.breakdown} />
            )}
            {value.label === BENEFITS_LABEL && (
              <BenefitsBreakdown breakdown={props.benefits_contribution.breakdown} />
            )}
          </Box>
        ))}
    </VStack>
  )
}
