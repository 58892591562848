import { PerformanceInsightsInterface } from '@src/features/AiInsights/Performance/interfaces/insights'

interface PerformanceInsightsModel {
  getGenerationDate: (insights: PerformanceInsightsInterface) => string
}

const getGenerationDate = (insights: PerformanceInsightsInterface) =>
  insights.update_date_time ?? insights.creation_date_time

const createPerformanceInsightsModel = (): PerformanceInsightsModel => ({
  getGenerationDate,
})

export const performanceInsightsModel = createPerformanceInsightsModel()
