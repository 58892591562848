import React from 'react'
import { InputGroup, InputGroupProps } from '@revolut/ui-kit'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { DatePickerInputProps } from '@components/Inputs/DatePickerInput/DatePickerInput'

interface Props
  extends Omit<InputGroupProps<'div'>, 'onChange'>,
    Pick<DatePickerInputProps, 'onChange'> {
  name: string
  dateLabel: string
  timeLabel: string
}

export const DateTimeInputGroup = ({
  name,
  dateLabel,
  timeLabel,
  onChange,
  ...inputGroupProps
}: Props) => (
  <InputGroup {...inputGroupProps}>
    <LapeDatePickerInput name={name} label={dateLabel} required onChange={onChange} />
    <LapeNewTimeInput name={name} label={timeLabel} width="50%" hideOptional />
  </InputGroup>
)
