import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalDashboardDetails, GoalsInterface } from '@src/interfaces/goals'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import { DashboardsList } from './DashboardsList'
import React from 'react'

export const DashboardsWidget = ({
  onAdd,
  onSelected,
  viewMode = false,
}: {
  onAdd?: () => void
  onSelected: (dashboard: GoalDashboardDetails) => void
  viewMode?: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const showCta = !!onAdd && !viewMode

  return (
    <BaseWidget
      variant={viewMode ? 'preview' : 'form'}
      title="Analytics"
      description="(Optional) Are there performance analytics which help to track this goal?"
      icon="ViewGrid"
      action={showCta ? { handler: onAdd, label: 'Add link' } : undefined}
    >
      {values.dashboards?.length ? (
        <DashboardsList viewMode={viewMode} onViewRequested={onSelected} />
      ) : null}
    </BaseWidget>
  )
}
