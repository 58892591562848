import React, { useEffect, useMemo, useState } from 'react'
import { getRequisitionHires } from '@src/api/requisitions'
import { chain, FilterButton } from '@revolut/ui-kit'
import { useTable } from '@components/TableV2/hooks'
import { HireInterface, RequisitionInterface } from '@src/interfaces/requisitions'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  getHireActionColumn,
  hireCandidateProfileColumn,
  hireEmployeeProfileColumn,
  hireNameColumn,
  hireRecruiterColumn,
  hireSeniorityColumn,
  hireSpecialisationColumn,
  hireStartDateColumn,
  hireStatusColumn,
} from '@src/constants/columns/hires'
import MoveHireSideBar from '@src/pages/Forms/RequisitionForm/Hires/MoveHireSideBar'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface Props {
  data: RequisitionInterface
}

const getRow = (
  canMoveHire: boolean,
  canCancelHire: boolean,
  onRefresh: () => void,
  onMoveHire: (rowIndex: number) => void,
  specialisationsEnabled: boolean,
): RowInterface<HireInterface> => ({
  cells: [
    {
      ...hireNameColumn,
      width: 150,
    },
    {
      ...hireSpecialisationColumn(specialisationsEnabled),
      width: 150,
    },
    {
      ...hireSeniorityColumn,
      width: 100,
    },
    {
      ...hireEmployeeProfileColumn,
      width: 100,
    },
    {
      ...hireCandidateProfileColumn,
      width: 100,
    },
    {
      ...hireRecruiterColumn,
      width: 100,
    },
    {
      ...hireStartDateColumn,
      width: 100,
    },
    {
      ...hireStatusColumn,
      width: 80,
    },
    ...(canMoveHire || canCancelHire
      ? [
          {
            ...getHireActionColumn(canMoveHire, canCancelHire, onRefresh, onMoveHire),
            width: 120,
          },
        ]
      : []),
  ],
})

const Hires = ({ data }: Props) => {
  const user = useSelector(selectUser)
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const [myHires, setMyCandidates] = useState<HireInterface[]>()
  const [onlyMine, setOnlyMine] = useState<boolean>()
  const [currentOpenIndex, setCurrentOpenIndex] = useState<number | null>(null)

  const canMoveHires = !!data.field_options.permissions?.includes(
    PermissionTypes.MoveRequisitionHires,
  )

  const canCancelHire = !!data.field_options.permissions?.includes(
    PermissionTypes.CancelHiresRequisitions,
  )

  const table = useTable<HireInterface>({
    getItems: fetchParams => getRequisitionHires(data.id, fetchParams),
  })

  const row = useMemo(
    () =>
      getRow(
        canMoveHires,
        canCancelHire,
        table.refresh,
        index => setCurrentOpenIndex(index),
        specialisationsEnabled,
      ),
    [specialisationsEnabled],
  )

  useEffect(() => {
    if (table.data) {
      setMyCandidates(
        table.data.filter(hire => {
          return hire.candidate?.recruiter?.id === user.id
        }),
      )
    } else {
      setMyCandidates(undefined)
    }
  }, [table.data])

  return (
    <>
      <MoveHireSideBar
        initialHireIndex={currentOpenIndex}
        allHires={table.data}
        requisitionId={data.id}
        isOpen={currentOpenIndex !== null}
        onClose={() => setCurrentOpenIndex(null)}
        onAfterSubmit={() => {
          table.refresh()
          setCurrentOpenIndex(null)
        }}
      />
      <Table.Widget>
        <Table.Widget.Filters>
          <FilterButton active={onlyMine} onClick={() => setOnlyMine(!onlyMine)}>
            {chain('My candidates', myHires?.length)}
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable<HireInterface>
            name={TableNames.Hires}
            useWindowScroll
            dataType="Hire"
            row={row}
            {...table}
            data={myHires && onlyMine ? myHires : table.data}
            emptyState={<EmptyTableRaw title="No hires for this requisition" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}

export default Hires
