import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  BottomSheet,
  Button,
  Header,
  HStack,
  Text,
  TextButton,
  TextWidget,
  Token,
  useToggle,
} from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { Commentator } from '@src/features/AiInsights/Performance/components/Insights/InsightsActions/common/Commentator'

interface Props {
  comment: string
  employee: EmployeeInterface
}

export const ViewCommentAction = ({ comment, employee }: Props) => {
  const [isPopupOpen, popupToggler] = useToggle()

  return (
    <>
      <TextButton onClick={popupToggler.on}>
        <HStack space="s-8" align="center" color={Token.color.greyTone50}>
          <Commentator employee={employee} />
          <Text variant="body2">
            <FormattedMessage
              id="performance.insights.comment.view.btn.label"
              defaultMessage="View comment"
            />
          </Text>
        </HStack>
      </TextButton>
      <BottomSheet open={isPopupOpen} onClose={popupToggler.off}>
        <Header>
          <Header.Title>
            <FormattedMessage
              id="performance.insights.comment.view.label"
              defaultMessage="Employee comment"
            />
          </Header.Title>
          <Header.Subtitle>
            <FormattedMessage
              id="performance.insights.view.comment.popup.subtitle"
              values={{ name: employee.full_name }}
              defaultMessage="From {name}"
            />
          </Header.Subtitle>
        </Header>
        <TextWidget>
          <TextWidget.Title>
            <FormattedMessage
              id="performance.insights.view.comment.popup.body.title"
              values={{ name: employee.full_name }}
              defaultMessage="{name}'s comment"
            />
          </TextWidget.Title>
          <TextWidget.Content>{comment}</TextWidget.Content>
        </TextWidget>
        <BottomSheet.Actions>
          <Button variant="secondary" onClick={popupToggler.off}>
            <FormattedMessage id="common.labels.cta.close" defaultMessage="Close" />
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}
