import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { ImportEmployeesIntro } from './ImportEmployeesIntro'
import { EditableEmployeesTableSimple } from '@src/features/EditableEmployeesTable/TableSimple'
import { EmployeesBulkSessionSimple } from '@src/features/EditableEmployeesTable/TableSimple/BulkSession'
import { pathToUrl } from '@src/utils/router'
import { API } from '@src/constants/api'

import { importEmployeesConfig } from '../common/checkpointsConfig'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { OnboardingChecklistContent } from '../components/OnboardingChecklistContent'
import { SelectFlowPage, SelectFlow } from './SelectFlow'
import { SourceSettings } from '@src/pages/OnboardingChecklistV2/ImportEmployees/SourceSettings'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'
import PageLoading from '@components/PageLoading/PageLoading'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { EditableEmployeesTableDetailed } from '@src/features/EditableEmployeesTable/TableDetailed'
import { EmployeesBulkSessionDetailed } from '@src/features/EditableEmployeesTable/TableDetailed/BulkSession'
import { BulkSessionTableWrapperProps } from '@src/features/EditableEmployeesTable/common'

export const ImportEmployees = () => {
  const { isLoading, data } = useGetOnboardingCheckpointCategory('importEmployees')
  const hasEmployeeRecordsOnboardingV2 = useHasFeatureFlag(
    FeatureFlags.EmployeeRecordsOnboardingV2,
  )
  const routes = useMemo(
    () => [
      {
        title: 'Intro',
        path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
        url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
        canView: [PermissionTypes.ViewEmployeePreferences],
        component: ImportEmployeesIntro,
      },
      {
        title: 'Choose source',
        path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SELECT_SOURCE,
        url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SELECT_SOURCE,
        canView: [PermissionTypes.ViewEmployeePreferences],
        component: SelectFlow,
      },
      {
        title: 'Import',
        path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
        url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
        canView: [PermissionTypes.ViewEmployeePreferences],
        component: () =>
          hasEmployeeRecordsOnboardingV2 ? (
            <EditableEmployeesTableDetailed
              sessionRoute={
                ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION
              }
            />
          ) : (
            <EditableEmployeesTableSimple
              sessionRoute={
                ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION
              }
            />
          ),
        isWide: true,
      },
    ],
    [hasEmployeeRecordsOnboardingV2],
  )

  if (isLoading) {
    return <PageLoading />
  }

  const importEmployeesProps: BulkSessionTableWrapperProps = {
    importRoute: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT,
    sessionRoute: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION,
    anyRoute: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY,
    onAfterConfirmRoute: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    apiEndpoint: API.EMPLOYEE_UPLOADS,
    getHeader: title => (
      <OnboardingChecklistHeader
        title={title}
        backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT)}
      />
    ),
  }
  return (
    <Switch>
      {data?.state?.id === 'completed' ? (
        <InternalRedirect
          from={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
          to={ROUTES.APPS.EMPLOYEES.BULK_IMPORT_EMPLOYEES.SESSION}
        />
      ) : null}
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SELECT_FLOW}>
        <SelectFlowPage />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SOURCE_SETTINGS}>
        <SourceSettings />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}>
        {hasEmployeeRecordsOnboardingV2 ? (
          <EmployeesBulkSessionDetailed {...importEmployeesProps} />
        ) : (
          <EmployeesBulkSessionSimple {...importEmployeesProps} />
        )}
      </Route>
      <Route>
        <OnboardingChecklistContent config={importEmployeesConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
