import React, { useState } from 'react'
import { ActionButton, Text } from '@revolut/ui-kit'
import { useUpdateCandidateConsent } from '@src/features/CandidateConsentToInterviewAISummarisation/api'
import { ConsentLayout } from '@src/features/CandidateConsentToInterviewAISummarisation/ConsentLayout'

interface Props {
  recruiterEmail: string
}

export const WithdrawConsent = ({ recruiterEmail }: Props) => {
  const [submitted, setSubmitted] = useState(false)

  const {
    mutateAsync,
    isLoading: loadingPatchCandidateConsent,
    isError,
  } = useUpdateCandidateConsent()

  const handleSubmit = async () => {
    await mutateAsync({ consent_to_interview_ai_summarisation: false })
    setSubmitted(true)
  }

  if (isError) {
    return (
      <ConsentLayout title="Withdrawing Consent for Interview Recording">
        <Text textAlign="center">
          There was a error with you request to withdraw consent.
        </Text>
        <Text textAlign="center">Please contact {recruiterEmail}</Text>
        <ActionButton mx="auto" use="a" href={`mailto:${recruiterEmail}`}>
          Contact recruiter
        </ActionButton>
      </ConsentLayout>
    )
  }

  if (submitted) {
    return (
      <ConsentLayout title="Withdrawing Consent for Interview Recording">
        <Text textAlign="center">
          Thank you for sharing your preference! Your choice matters to us.
        </Text>
      </ConsentLayout>
    )
  }

  return (
    <ConsentLayout title="Withdrawing Consent for Interview Recording">
      <Text textAlign="center" variant="emphasis1">
        If you wish to withdraw your consent for your interview to be recorded, please
        submit this form. Once we receive your request, we will delete any existing
        recordings.
      </Text>
      <Text fontWeight={500} textAlign="center" variant="emphasis1">
        Please note that withdrawing your consent will not affect your candidacy.
      </Text>
      <ActionButton
        variant="black"
        mx="auto"
        pending={loadingPatchCandidateConsent}
        onClick={handleSubmit}
      >
        Withdraw
      </ActionButton>
    </ConsentLayout>
  )
}
