import React, { forwardRef } from 'react'
import { Color, Flex, Text } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'

interface DropdownSelectCellProps {
  open: boolean
  onClick: () => void
  label: string
}

export const DropdownSelectCell = forwardRef<HTMLButtonElement, DropdownSelectCellProps>(
  ({ open, onClick, label }, ref) => {
    return (
      <Flex
        onClick={onClick}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        use="button"
        type="button"
        color="inherit"
        ref={ref}
      >
        <Text>{label}</Text>
        {open ? (
          <ChevronUp color={Color.GREY_TONE_50} size={16} />
        ) : (
          <ChevronDown color={Color.GREY_TONE_50} size={16} />
        )}
      </Flex>
    )
  },
)
