import React, { useState } from 'react'

import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { HiringProcessFormActions } from './components/HiringProcessFormActions'
import { HiringProcessFormBody } from './components/HiringProcessFormBody'

const HiringProcess = () => {
  const [enableFiltering, setEnableFiltering] = useState(true)

  return (
    <>
      <PageBody maxWidth="100%">
        <HiringProcessFormBody
          setEnableFiltering={val => setEnableFiltering(val)}
          enableFiltering={enableFiltering}
        />
      </PageBody>
      <PageActions>
        <HiringProcessFormActions
          onAfterSubmit={() => {
            setEnableFiltering(true)
          }}
        />
      </PageActions>
    </>
  )
}

export default HiringProcess
