import { useRef } from 'react'

import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { sumPerformanceWeights } from '@src/pages/Specialisation/Form/helpers/sumPerformanceWeights'
import { FeatureFlags } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'

export const useWeightsErrors = () => {
  const competencyMatrixV2 = useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2)
  const { values } = useLapeContext<SpecialisationInterface>()

  const totalWeight = useRef<number>()
  const skillWithNoWeight = useRef<boolean>()

  totalWeight.current = sumPerformanceWeights(
    competencyMatrixV2 ? values.competency_matrix : values.performance_weights,
  )
  if (competencyMatrixV2) {
    skillWithNoWeight.current = !!values.competency_matrix?.some(skill => !skill.weight)
  } else {
    skillWithNoWeight.current = !!values.performance_weights?.some(
      ({ weight }) => !weight,
    )
  }

  return {
    totalWeight: totalWeight.current,
    skillWithNoWeight: skillWithNoWeight.current,
  }
}
