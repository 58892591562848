import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { OnboardingV1Experience } from './components/OnboardingV1Experience'
import { SurveysTable } from './components/SurveysTable'

const EngagementBase = () => {
  const permissions = useSelector(selectPermissions)
  const canView = permissions.includes(PermissionTypes.ViewEngagement)

  return (
    <Switch>
      {canView && (
        <Route exact path={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD}>
          <SurveysTable />
        </Route>
      )}
      <InternalRedirect to={ROUTES.PERFORMANCE.GOALS.GENERAL} />
    </Switch>
  )
}

export const Engagement = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isOnboardingV2 = featureFlags.includes(FeatureFlags.OnboardingFlowV2)
  const isCommercial = useIsCommercial()

  if (!isCommercial) {
    return <EngagementBase />
  }
  return isOnboardingV2 ? (
    <OnboardingAppScreen category="engagement">
      <EngagementBase />
    </OnboardingAppScreen>
  ) : (
    <OnboardingV1Experience>
      <EngagementBase />
    </OnboardingV1Experience>
  )
}
