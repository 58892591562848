import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { PageBody } from '@components/Page/PageBody'
import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getSettingsLinkConfig } from '../../common/config'
import {
  organisationSettingsLinkConfig,
  isGeneralOrganisationSettingsHidden,
} from '../config'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { navigateReplace } from '@src/actions/RouterActions'
import { useCompanyStructuresSettingsEnabled } from '@src/pages/Settings/Structures/components/useCompanyStructuresSettingsEnabled'
import { organisationViewPermissions } from '@src/pages/Settings/SettingsLandingPage/constants'

const ListPage = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const generalSettingsHidden = isGeneralOrganisationSettingsHidden(values)

  const config = getSettingsLinkConfig({
    config: organisationSettingsLinkConfig,
    permissions,
    featureFlags,
    type: 'settings',
  })

  return (
    <PageBody mb="s-64">
      <Group mb="s-24">
        {!generalSettingsHidden && (
          <NewSelectionCard
            icon="Gear"
            title="General"
            to={pathToUrl(ROUTES.SETTINGS.ORGANISATION.GENERAL)}
            subtitle={
              <FormattedMessage
                id="settings.organisation.general"
                defaultMessage="Settings that apply to teams, departments and company"
              />
            }
            use={InternalLink}
          />
        )}
        {config.organisationTeams?.component}
        {config.organisationDepartments?.component}
      </Group>
    </PageBody>
  )
}

const routes = [
  {
    title: 'Teams settings',
    path: ROUTES.SETTINGS.ORGANISATION.LIST,
    url: ROUTES.SETTINGS.ORGANISATION.LIST,
    canView: organisationViewPermissions,
    component: ListPage,
  },
]

export const List = () => {
  const companyStructuresEnabled = useCompanyStructuresSettingsEnabled()

  useEffect(() => {
    if (companyStructuresEnabled) {
      navigateReplace(ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL)
    }
  }, [companyStructuresEnabled])

  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Teams settings"
      subtitle="Settings that apply to all teams"
    />
  )
}
