import React, {
  useState,
  createContext,
  ReactNode,
  useMemo,
  useCallback,
  useContext,
} from 'react'

import SideBar from '@src/components/SideBar/SideBar'
import {
  PipSmartGoalsDetails,
  PipSmartGoalsDetailsViewOnly,
} from '@src/features/PIP/components/PipSmartGoalsDetails'
import {
  PipSmartGoalAddForm,
  PipSmartGoalEditForm,
} from '@src/features/PIP/components/PipSmartGoalForm'

import { DeliverableInterface } from '@src/interfaces/deliverables'
import { PerformanceSelector } from '@src/interfaces/performance'
import { Button, Side, useToggle } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type Variant = 'details' | 'edit' | 'add' | 'view'

type SmartGoalFormState =
  | {
      variant: 'view'
      goal: DeliverableInterface
      pipCycle: PerformanceSelector
    }
  | {
      variant: 'details'
      employeeId: string | number
      goal: DeliverableInterface
      pipCycle: PerformanceSelector
      onGoalDeleted: VoidFunction
      onGoalUpdated: (goal: DeliverableInterface) => void
      onEditClick: (goal: DeliverableInterface) => void
    }
  | {
      variant: 'edit'
      employeeId: string | number
      goal: DeliverableInterface
      onAfterSubmit?: VoidFunction
    }
  | {
      variant: 'add'
      employeeId: string | number
      pipCycle: PerformanceSelector
      onAfterSubmit?: VoidFunction
    }

interface PipSmartGoalSideBarContextValue {
  isOpen: boolean
  state: SmartGoalFormState | undefined
  open: (state: SmartGoalFormState) => void
  close: VoidFunction
}

const getTitleByVariant = (variant: Variant) => {
  switch (variant) {
    case 'details':
    case 'view':
      return 'PIP goal'
    case 'edit':
      return 'Edit PIP goal'
    case 'add':
      return 'Add PIP goal'
    default:
      return ''
  }
}

const PipGoalSideBarContext = createContext<PipSmartGoalSideBarContextValue | undefined>(
  undefined,
)

export const usePIPGoalContext = () => {
  const context = useContext(PipGoalSideBarContext)

  if (context == null) {
    throw new Error(`usePIPGoalContext must be used within a PipGoalSideBarContext`)
  }
  return context
}

export const PipGoalSideBarProvider = ({ children }: { children?: ReactNode }) => {
  const [isOpen, toggleSidebar] = useToggle()
  const [state, setState] = useState<SmartGoalFormState>()

  const open = useCallback((newState: SmartGoalFormState) => {
    setState(newState)
    toggleSidebar.on()
  }, [])

  const close = useCallback(() => {
    toggleSidebar.off()
    setState(undefined)
  }, [])

  const value = useMemo(() => {
    return {
      open,
      close,
      state,
      isOpen,
    }
  }, [isOpen, state])

  return (
    <PipGoalSideBarContext.Provider value={value}>
      {children}
    </PipGoalSideBarContext.Provider>
  )
}

const PipGoalSideBarActions = ({
  onAfterSubmit,
  onClose,
}: {
  onAfterSubmit?: VoidFunction
  onClose?: VoidFunction
}) => {
  return (
    <Side.Actions horizontal>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <NewSaveButtonWithPopup
        useValidator
        noPopup
        onAfterSubmit={onAfterSubmit}
        hideWhenNoChanges={false}
      >
        Confirm
      </NewSaveButtonWithPopup>
    </Side.Actions>
  )
}

export const PipGoalSideBar = () => {
  const context = usePIPGoalContext()

  if (!context || !context?.state) {
    return null
  }

  const { isOpen, state, close } = context

  return (
    <SideBar
      isOpen={isOpen}
      onClose={close}
      useBackButton
      title={getTitleByVariant(state.variant)}
    >
      {state.variant === 'view' && <PipSmartGoalsDetailsViewOnly {...state} />}

      {state.variant === 'edit' && (
        <PipSmartGoalEditForm {...state}>
          <PipGoalSideBarActions onAfterSubmit={state?.onAfterSubmit} onClose={close} />
        </PipSmartGoalEditForm>
      )}

      {state.variant === 'add' && (
        <PipSmartGoalAddForm {...state}>
          <PipGoalSideBarActions onAfterSubmit={state?.onAfterSubmit} onClose={close} />
        </PipSmartGoalAddForm>
      )}

      {state.variant === 'details' && <PipSmartGoalsDetails {...state} />}
    </SideBar>
  )
}
