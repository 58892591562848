import React, { useMemo } from 'react'

import { OnboardingBulkDataUpload } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { fields, useOptions } from './sessionConfig'
import { ROUTES } from '@src/constants/routes'
import { contractsConfig } from '../common/constants'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import {
  ImportContractsCellOptions,
  importContractsCompanyEntityColumn,
  importContractsContractTermColumn,
  importContractsContractTypeColumn,
  importContractsEmailColumn,
  importContractsEndDateColumn,
  importContractsFullTimeEquivalentColumn,
  importContractsLocationColumn,
  importContractsNoticePeriodAfterProbationColumn,
  importContractsNoticePeriodAfterProbationUnitColumn,
  importContractsNoticePeriodDuringProbationColumn,
  importContractsNoticePeriodDuringProbationUnitColumn,
  importContractsSalaryAmountColumn,
  importContractsSalaryCurrencyColumn,
  importContractsSalaryPaymentFrequencyColumn,
  importContractsSalaryTimeUnitColumn,
  importContractsSeniorityColumn,
  importContractsSpecialisationColumn,
  importContractsSpecialisationSenioritySublevelColumn,
  importContractsStartDateColumn,
  importContractsStatusColumn,
  importContractsTitleColumn,
  importContractsWeeklyWorkingHoursColumn,
} from '@src/constants/columns/importContracts'
import { ImportContractsDataInterface } from '@src/interfaces/importContracts'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const importContractRow = (
  options: ImportContractsCellOptions,
  specialisationsEnabled: boolean,
): Partial<RowInterface<ImportInterface<ImportContractsDataInterface>>> => ({
  cells: [
    {
      ...importContractsEmailColumn,
      width: 200,
    },
    {
      ...importContractsContractTypeColumn(options),
      width: 200,
    },
    {
      ...importContractsContractTermColumn(options),
      width: 160,
    },
    {
      ...importContractsStartDateColumn,
      width: 150,
    },
    {
      ...importContractsEndDateColumn,
      width: 150,
    },
    {
      ...importContractsLocationColumn,
      width: 180,
    },
    {
      ...importContractsCompanyEntityColumn,
      width: 200,
    },
    {
      ...importContractsSalaryAmountColumn,
      width: 140,
    },
    {
      ...importContractsSalaryCurrencyColumn,
      width: 200,
    },
    {
      ...importContractsSalaryTimeUnitColumn(options),
      width: 160,
    },
    {
      ...importContractsSalaryPaymentFrequencyColumn(options),
      width: 230,
    },
    {
      ...importContractsSpecialisationColumn(specialisationsEnabled),
      width: 220,
    },
    {
      ...importContractsSeniorityColumn,
      width: 180,
    },
    {
      ...importContractsSpecialisationSenioritySublevelColumn,
      width: 180,
    },
    {
      ...importContractsTitleColumn,
      width: 220,
    },
    {
      ...importContractsStatusColumn(options),
      width: 200,
    },
    {
      ...importContractsWeeklyWorkingHoursColumn,
      width: 200,
    },
    {
      ...importContractsFullTimeEquivalentColumn,
      width: 190,
    },
    {
      ...importContractsNoticePeriodDuringProbationColumn,
      width: 260,
    },
    {
      ...importContractsNoticePeriodDuringProbationUnitColumn(options),
      width: 290,
    },
    {
      ...importContractsNoticePeriodAfterProbationColumn,
      width: 260,
    },
    {
      ...importContractsNoticePeriodAfterProbationUnitColumn(options),
      width: 290,
    },
  ],
})

export const ContractsBulkUpload = () => {
  const options = useOptions()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const row = useMemo(
    () => importContractRow(options, specialisationsEnabled),
    [options, specialisationsEnabled],
  )
  return (
    <OnboardingBulkDataUpload
      previousRoute={ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.SETTINGS}
      importRoute={ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.UPLOAD.IMPORT}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.ANY}
      apiEndpoint={API.CONTRACTS_UPLOAD}
      config={contractsConfig}
      currentStep="Upload contracts"
      category="contract_bulk_upload"
      name="contract"
      row={row}
      tableName={TableNames.ImportContracts}
      fields={fields}
      recommendedLabel="yes"
      yesByDefault
    />
  )
}
