import isBefore from 'date-fns/isBefore'

type Accessor<T extends Object> = keyof T | ((datum: T) => string)

const getDateByAccessor = <T extends Object>(datum: T, accessor: Accessor<T>) => {
  const dateStr =
    typeof accessor === 'string'
      ? datum[accessor]
      : typeof accessor === 'function'
      ? accessor(datum)
      : undefined
  return dateStr && typeof dateStr === 'string' ? new Date(dateStr) : new Date()
}

export function sortByDate<T extends Object>(
  data: T[],
  accessor: keyof T,
  sortDirection?: 'asc' | 'desc',
): T[]
export function sortByDate<T extends Object>(
  data: T[],
  accessor: (datum: T) => string,
  sortDirection?: 'asc' | 'desc',
): T[]
export function sortByDate<T extends Object = {}>(
  data: T[],
  accessor: Accessor<T>,
  sortDirection: 'asc' | 'desc' = 'desc',
): T[] {
  const dataToSort = [...data]
  const res = sortDirection === 'asc' ? -1 : 1

  return (
    dataToSort.sort((prev, next) => {
      const datePrev = getDateByAccessor(prev, accessor)
      const dateNext = getDateByAccessor(next, accessor)
      return isBefore(datePrev, dateNext) ? res : -1 * res
    }) || []
  )
}
