import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Layout } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Layout'
import { EditForm } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/EditForm'
import { SaveFormAction } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/FormActions'

export const EditPage = () => {
  return (
    <EditForm>
      <Layout
        title={
          <FormattedMessage
            id="forms.performance.insights.group.edit.title"
            defaultMessage="Edit insights group"
          />
        }
        actions={({ label, onSave }) => (
          <SaveFormAction label={label} onAfterSubmit={onSave} />
        )}
      />
    </EditForm>
  )
}
