import { ScheduleOneToOneMeetingPayload } from '@src/api/meetings'
import { formatRRule } from '@src/utils/format'
import { format } from 'date-fns'
import { TimeSlot } from '@src/interfaces/meetings'
import { getCurrentTimezone } from '@src/utils/timezones'
import { utcToZonedTime } from 'date-fns-tz'

export const formatRecurrence = (event: ScheduleOneToOneMeetingPayload): string =>
  formatRRule(event.recurrence[0])

export const formatTimeDiff = (
  slot: TimeSlot,
  fullDayName: boolean = false,
  timezoneId?: string,
): string => {
  const timezone = timezoneId || getCurrentTimezone()
  const startDate = new Date(slot.start)
  const endDate = new Date(slot.end)

  const day = format(startDate, fullDayName ? 'eeee' : 'eee')
  const fromTime = format(utcToZonedTime(startDate, timezone), 'HH:mm')
  const toTime = format(utcToZonedTime(endDate, timezone), 'HH:mm')

  return `${day}, ${fromTime} - ${toTime}`
}
