import React, { useCallback, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import {
  ActionButton,
  Box,
  HStack,
  Item,
  ItemSkeleton,
  Search,
  Spacer,
  StatusWidget,
  VStack,
} from '@revolut/ui-kit'

import {
  createGetEmployeesWithSurveyResultsAccess,
  createSurveyResultsUserAccessMode,
  updateSurveyResultsUserAccessMode,
} from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import { DropdownButton } from '@src/components/DropdownButton/DropdownButton'
import SideBar from '@src/components/SideBar/SideBar'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { selectorKeys } from '@src/constants/api'
import { ErrorItem } from '@src/features/Errors/components/ErrorItem'
import { ParsableError } from '@src/features/Errors/parseError'
import { useInfiniteLoadingList } from '@src/hooks/useInfiniteLoadingList'
import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  EmployeeWithSurveyAccessInterface,
  EngagementResultsAccessModeType,
  EngagementResultsUserAccessModeInterface,
} from '@src/interfaces/engagement'
import { times } from '@src/utils/times'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'

interface EmployeeAccessItemProps {
  pending: boolean
  employee: EmployeeOptionInterface
  accessMode: EngagementResultsUserAccessModeInterface | null
  options: IdAndName<EngagementResultsAccessModeType>[]
  onSelect: (newValue: IdAndName<EngagementResultsAccessModeType>) => Promise<void>
}
const EmployeeAccessItem = ({
  employee,
  accessMode,
  pending,
  options,
  onSelect,
}: EmployeeAccessItemProps) => {
  return (
    <Item key={employee.id}>
      <Item.Avatar>
        <UserAvatar
          id={employee.id}
          avatar={employee.avatar}
          full_name={employee.full_name}
          size={36}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{employee.full_name}</Item.Title>
        <Item.Description>{employee.specialisation?.name || '-'}</Item.Description>
      </Item.Content>
      <Item.Side>
        <DropdownButton<IdAndName<EngagementResultsAccessModeType>>
          pending={pending}
          options={options}
          onSelect={onSelect}
        >
          {accessMode?.access_type.name || 'No access'}
        </DropdownButton>
      </Item.Side>
    </Item>
  )
}

interface Props {
  surveyId: number
  isOpen: boolean
  onClose: VoidFunction
}
export const ShareResultsSidebar = ({ surveyId, isOpen, onClose }: Props) => {
  const [searchInputValue, setSearchInputValue] = useState('')

  const [filterByEmployeeName, setFilterByEmployeeName] = useState('')
  const [filterByTeam, setFilterByTeam] = useState<IdAndName[]>([])
  const [filterByDepartment, setFilterByDepartment] = useState<IdAndName[]>([])

  const hasActiveFilters = Boolean(
    filterByEmployeeName || filterByTeam || filterByDepartment,
  )
  const clearAllFilters = () => {
    setFilterByEmployeeName('')
    setFilterByTeam([])
    setFilterByDepartment([])
  }

  const [pendingEmployeeIds, setPendingEmployeeIds] = useState<number[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [failedEmployeeIdsToErrors, setFailedEmployeeIdsToErrors] = useState<
    Record<number, ParsableError>
  >({})

  const setFilterByNameDebounced = useCallback(debounce(setFilterByEmployeeName, 500), [])

  const { data: accessTypesOptions = [] } = useGetSelectors<
    IdAndName<EngagementResultsAccessModeType>
  >(selectorKeys.engagement_survey_access_types)
  const params = useMemo(
    () => ({
      search: filterByEmployeeName,
      team__id: filterByTeam.map(({ id }) => id).join(','),
      team__department__id: filterByDepartment.map(({ id }) => id).join(','),
    }),
    [filterByEmployeeName, filterByTeam, filterByDepartment],
  )

  const getEmployees = useCallback(createGetEmployeesWithSurveyResultsAccess(surveyId), [
    surveyId,
  ])

  const {
    ref,
    items: employeesWithAccessMode,
    isLoadingMore,
    isReloading,
    refetch,
  } = useInfiniteLoadingList<EmployeeWithSurveyAccessInterface>({
    enabled: isOpen,
    getItems: getEmployees,
    params,
    handleError: e => {
      setErrorMessage(getMessageFromApiError(e))
    },
  })

  const isLoadingEmployees = isLoadingMore || isReloading

  const { data: departmentsOptions = [] } = useGetSelectors<IdAndName>(
    selectorKeys.department,
  )
  const { data: teamsOptions = [] } = useGetSelectors<IdAndName>(selectorKeys.team)

  return (
    <SideBar title="Add employees" isOpen={isOpen} onClose={onClose}>
      <VStack space="s-16">
        <Search
          value={searchInputValue}
          placeholder="Search"
          onChange={newValue => {
            setSearchInputValue(newValue)
            setFilterByNameDebounced(newValue)
          }}
        />
        <HStack space="s-16" mb="s-16">
          <FilterButtonCheckboxSelect<IdAndName>
            label="Team"
            searchable
            width={300}
            options={teamsOptions}
            value={filterByTeam}
            onChange={newValue => {
              if (newValue) {
                setFilterByTeam(newValue)
              }
            }}
          />
          <FilterButtonCheckboxSelect<IdAndName>
            label="Department"
            searchable
            width={300}
            options={departmentsOptions}
            value={filterByDepartment}
            onChange={newValue => {
              if (newValue) {
                setFilterByDepartment(newValue)
              }
            }}
          />
        </HStack>
        {errorMessage ? (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-business/3D148.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-business/3D148@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-business/3D148@3x.png',
              }}
            />
            <StatusWidget.Title>Failed to load employees</StatusWidget.Title>
            <StatusWidget.Description>{errorMessage}</StatusWidget.Description>
            <StatusWidget.Actions>
              <ActionButton
                useIcon="Retry"
                variant="primary-on-blue"
                onClick={() => {
                  setErrorMessage(null)
                  refetch()
                }}
              >
                Retry
              </ActionButton>
              {hasActiveFilters && (
                <ActionButton
                  onClick={() => {
                    setErrorMessage(null)
                    clearAllFilters()
                  }}
                >
                  Clear all filters
                </ActionButton>
              )}
            </StatusWidget.Actions>
          </StatusWidget>
        ) : (
          <>
            {employeesWithAccessMode.length ? (
              employeesWithAccessMode.map(
                ({ survey_access: accessMode, ...employee }) => {
                  if (
                    Object.keys(failedEmployeeIdsToErrors).includes(String(employee.id))
                  ) {
                    return (
                      <ErrorItem
                        error={failedEmployeeIdsToErrors[employee.id]}
                        forcedTitle="Access update has failed"
                        actions={
                          <ActionButton
                            variant="negative"
                            size="xs"
                            useIcon="Retry"
                            onClick={() => {
                              const newFailedEmployeeIdsToErrors = {
                                ...failedEmployeeIdsToErrors,
                              }
                              delete newFailedEmployeeIdsToErrors[employee.id]
                              setFailedEmployeeIdsToErrors(newFailedEmployeeIdsToErrors)
                            }}
                          >
                            Retry
                          </ActionButton>
                        }
                      />
                    )
                  }
                  return (
                    <EmployeeAccessItem
                      key={employee.id}
                      employee={employee}
                      accessMode={accessMode}
                      pending={pendingEmployeeIds.includes(employee.id)}
                      options={accessTypesOptions}
                      onSelect={async newValue => {
                        if (!surveyId || !newValue) {
                          return
                        }
                        const apiCallArgs: [
                          number,
                          number,
                          EngagementResultsAccessModeType,
                        ] = [surveyId, employee.id, newValue.id]
                        const apiCall = accessMode?.id
                          ? () =>
                              updateSurveyResultsUserAccessMode(
                                accessMode.id,
                                ...apiCallArgs,
                              )
                          : () => createSurveyResultsUserAccessMode(...apiCallArgs)

                        try {
                          setPendingEmployeeIds([...pendingEmployeeIds, employee.id])
                          await apiCall()
                        } catch (e) {
                          setFailedEmployeeIdsToErrors({
                            ...failedEmployeeIdsToErrors,
                            [employee.id]: e,
                          })
                        } finally {
                          await refetch(() => {
                            setPendingEmployeeIds([
                              ...pendingEmployeeIds.filter(id => id !== employee.id),
                            ])
                          })
                        }
                      }}
                    />
                  )
                },
              )
            ) : !isLoadingEmployees ? (
              <StatusWidget>
                <StatusWidget.Image
                  image={{
                    default:
                      'https://assets.revolut.com/assets/3d-images-business/3D028.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-business/3D028@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-business/3D028@3x.png',
                  }}
                />
                <StatusWidget.Title>Nothing found</StatusWidget.Title>
                <StatusWidget.Actions>
                  {hasActiveFilters && (
                    <ActionButton
                      useIcon="Retry"
                      onClick={() => {
                        setErrorMessage(null)
                        clearAllFilters()
                      }}
                    >
                      Clear all filters
                    </ActionButton>
                  )}
                </StatusWidget.Actions>
              </StatusWidget>
            ) : null}
            {(isLoadingMore || isReloading) &&
              times(idx => <ItemSkeleton key={idx} />, 10)}
            <Spacer height={24} />
          </>
        )}
      </VStack>
      <Box ref={ref} />
    </SideBar>
  )
}
