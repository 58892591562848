import React from 'react'
import { Placeholder, VStack } from '@revolut/ui-kit'
import {
  BAR_ITEM_HEIGHT,
  GridBarChartRowSkeleton,
} from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/Row'
import { times } from '@src/utils/times'

const CHART_HEIGHT = 500
const ROWS_COUNT = 5
const ROW_GAP = 16

interface SkeletonProps {
  rowHeight?: number
  chartHeight?: number
  rowGap?: number
  rowsCount?: number
}

export const GridBarChartSkeleton = ({
  rowHeight = BAR_ITEM_HEIGHT,
  chartHeight = CHART_HEIGHT,
  rowGap = ROW_GAP,
  rowsCount = ROWS_COUNT,
}: SkeletonProps) => {
  return (
    <VStack
      space={ROW_GAP}
      width="100%"
      height={Math.min(chartHeight, rowHeight * rowsCount + (rowsCount - 1) * rowGap)}
      overflow="hidden"
    >
      {times(
        index => (
          <GridBarChartRowSkeleton key={index} height={rowHeight} />
        ),
        rowsCount,
      )}
    </VStack>
  )
}

interface PlaceholderProps {
  title: React.ReactNode
}

export const GridBarChartPlaceholder = ({ title }: PlaceholderProps) => (
  <Placeholder>
    <Placeholder.Image
      image={{
        default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
        '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
        '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
      }}
    />
    <Placeholder.Title>{title}</Placeholder.Title>
  </Placeholder>
)
