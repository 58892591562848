import { apiV2 } from '@src/api'
import { API } from '@src/constants/api'
import { RequestInterfaceNew } from '@src/interfaces'
import { DepartmentTabSectionInterface } from '../interfaces'

export const departmentResourcesRequests: RequestInterfaceNew<DepartmentTabSectionInterface> =
  {
    get: async ({ departmentId, tabId, id }) =>
      apiV2.get(`${API.DEPARTMENT}/${departmentId}/tabs/${tabId}/resources/${id}`),
    update: async (data, { departmentId, tabId, id }) =>
      apiV2.patch(
        `${API.DEPARTMENT}/${departmentId}/tabs/${tabId}/resources/${id}`,
        data,
      ),
    submit: async (data, { departmentId, tabId }) =>
      apiV2.post(`${API.DEPARTMENT}/${departmentId}/tabs/${tabId}/resources`, data),
  }

export const deleteDepartmentResouce = ({
  departmentId,
  tabId,
  id,
}: {
  departmentId: number
  tabId: number
  id: number
}) => apiV2.delete(`${API.DEPARTMENT}/${departmentId}/tabs/${tabId}/resources/${id}`)
