import {
  ActionButton,
  DetailsCell,
  HStack,
  IconName,
  Token,
  Text,
  Avatar,
} from '@revolut/ui-kit'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import React, { PropsWithChildren } from 'react'

type BaseWidgetProps = {
  icon: IconName
  title: string
  description?: string
  action?: { handler: () => void; label: string; icon?: IconName; disabled?: boolean }
  variant?: 'preview' | 'form'
}

export const BaseWidget = (props: PropsWithChildren<BaseWidgetProps>) => {
  const { icon, title, description, children, action, variant = 'form' } = props

  return variant === 'form' ? (
    <CellWithItem
      icon={icon}
      title={title}
      description={description}
      side={
        action ? (
          <ActionButton
            onClick={action?.handler}
            disabled={action.disabled}
            useIcon={action.icon || '16/Plus'}
          >
            {action.label}
          </ActionButton>
        ) : null
      }
    >
      {children || null}
    </CellWithItem>
  ) : (
    <DetailsCell px={0} pb={0}>
      <DetailsCell.Title px="s-16">
        <HStack space="s-8" align="center">
          <Avatar useIcon={icon} />
          <Text variant="h6" color={Token.color.greyTone50}>
            {title}
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Note>{children}</DetailsCell.Note>
    </DetailsCell>
  )
}
