import React, { useEffect, useState } from 'react'
import {
  Box,
  Cell,
  Text,
  ActionButton,
  Token,
  useStatusPopup,
  StatusPopup,
  Button,
} from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { connect } from 'lape'
import { EligibleEmployeesInterface } from '@src/interfaces/supportTool/eligibleEmployees'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import AutoStepper from '@components/Stepper/AutoStepper'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { getSelectors } from '@src/api/selectors'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { regenerateEligibleEmployeesScorecards } from '@src/api/supportTool/eligibleEmployees'
import { useSubsenioritySelector } from '@src/hooks/useSubsenioritySelector'
import { useGetOrganisationSettings } from '@src/api/settings'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { ContributorType } from '@src/interfaces/talent/performance'
import { BaseOptionId, EmployeeOption } from '@src/interfaces/selectors'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const EligiblePeopleEdit = () => {
  const { values, initialValues } = useLapeContext<EligibleEmployeesInterface>()
  const [employees, setEmployees] = useState<
    RadioSelectOption<{ id: number; name: string | undefined }>[]
  >([])
  const [eligibleOptions, setEligibleOptions] = useState<
    RadioSelectOption<{ id: string; name: string }>[]
  >([])
  const [employeeTypes, setEmployeeTypes] = useState<
    RadioSelectOption<{ id: ContributorType; name: string }>[] | undefined
  >()
  const [regenerateScorecardsPending, setRegenerateScorecardsPending] =
    useState<boolean>(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const statusPopup = useStatusPopup()
  const { data: settings } = useGetOrganisationSettings()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const subsenioritySelector = useSubsenioritySelector<EligibleEmployeesInterface>(
    values,
    initialValues,
  )

  const fetchEligibleOptions = async () => {
    const resp = await getSelectors(selectorKeys.yes_no_inverted_value_options)
    setEligibleOptions(
      resp.data.options.map(item => ({
        label: item.name,
        value: {
          id: item.id as string,
          name: item.name as string,
        },
      })),
    )
  }

  const fetchEmployees = async () => {
    const resp = await getSelectors<BaseOptionId, EmployeeOption>(selectorKeys.employee)
    setEmployees(
      resp.data.options.map(item => ({
        label: item.name,
        value: {
          id: +item.id,
          name: item.full_name,
        },
      })),
    )
  }

  const fetchEmployeeTypes = async () => {
    const resp = await getSelectors<ContributorType>(
      selectorKeys.performance_reviewed_employee_type,
    )
    const filteredOptions = resp.data.options.filter(
      option => option.id !== ContributorType.Mgr,
    )
    setEmployeeTypes(
      filteredOptions.map(item => ({
        label: item.name,
        value: {
          id: item.id,
          name: item.name,
        },
      })),
    )
  }

  useEffect(() => {
    fetchEmployees()
    fetchEligibleOptions()
    fetchEmployeeTypes()
  }, [])

  const onClickRegenerateScorecards = async () => {
    setRegenerateScorecardsPending(true)
    try {
      const { data } = await regenerateEligibleEmployeesScorecards(values.id)
      setPopupOpen(false)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Success</StatusPopup.Title>
          <StatusPopup.Description>{data?.result}</StatusPopup.Description>
        </StatusPopup>,
      )
      fetchEmployees()
      fetchEligibleOptions()
    } catch (e) {
      const errorMsg = getStringMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to regenerate scorecards</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
          <StatusPopup.Actions>
            <Button elevated onClick={() => statusPopup.hide()}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
      setPopupOpen(false)
    } finally {
      setRegenerateScorecardsPending(false)
    }
  }

  const backUrl = ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES_LIST
  const lmId = values.line_manager?.id || values.employee.line_manager?.id
  const fmId = values.functional_manager?.id || values.employee.quality_control?.id
  return (
    <PageWrapper>
      <PageHeader
        title={values?.employee?.full_name}
        backUrl={backUrl}
        subtitle={values?.cycle?.name}
      />

      <PageBody>
        <AutoStepper>
          <ActionButton
            mb="s-16"
            useIcon="ArrowRepeat"
            pending={regenerateScorecardsPending}
            onClick={() => setPopupOpen(true)}
          >
            Regenerate scorecards
          </ActionButton>
          <ConfirmationDialog
            open={popupOpen}
            onClose={() => setPopupOpen(false)}
            onConfirm={onClickRegenerateScorecards}
            onReject={() => {
              setPopupOpen(false)
            }}
            label="Regenerate scorecards"
            body={
              <Text variant="primary">
                Warning: Regenerating the scorecard will permanently delete all reviews
                submitted for or by this employee in the current review cycle. This action
                cannot be undone.
              </Text>
            }
            loading={regenerateScorecardsPending}
            yesMessage="Continue"
            noMessage="Cancel"
            variant="compact"
          />
          <Box mb="s-16">
            <Cell p="s-16">
              <Text color={Token.color.greyTone50}>
                A change here can affect the scorecard content or the relation between the
                reviewee and their reviewers.
              </Text>
            </Cell>
          </Box>

          <Box mb="s-16">
            <RadioSelectInput
              label="Eligible"
              options={eligibleOptions}
              value={{
                id: values.is_disabled ? 'true' : 'false',
                name: values.is_disabled ? 'No' : 'Yes',
              }}
              onChange={val => {
                if (val?.id === 'false' || !val?.id) {
                  values.is_disabled = false
                } else {
                  values.is_disabled = true
                }
              }}
            />
          </Box>

          <Box mb="s-16">
            <RadioSelectInput
              label="Line manager"
              options={employees}
              value={
                lmId
                  ? {
                      id: lmId,
                      name: values.line_manager?.full_name,
                    }
                  : null
              }
              onChange={value => {
                const option = employees.find(item => item.value === value)
                if (option) {
                  values.line_manager = {
                    id: option.value.id,
                    name: option.label as string,
                  }
                }
              }}
            />
          </Box>

          <Box mb="s-16">
            <RadioSelectInput
              label="Functional manager"
              options={employees}
              value={
                fmId ? { id: fmId, name: values.functional_manager?.full_name } : null
              }
              onChange={value => {
                const option = employees.find(item => item.value === value)
                if (option) {
                  values.functional_manager = {
                    id: option.value.id,
                    name: option.label as string,
                  }
                }
              }}
            />
          </Box>

          <Box mb="s-16">
            <LapeRadioSelectInput
              name="specialisation"
              label={getSpecialisationTitle(specialisationsEnabled)}
              selector={selectorKeys.specialisations}
            />
          </Box>

          <Box mb="s-16">
            <LapeRadioSelectInput name="team" label="Team" selector={selectorKeys.team} />
          </Box>

          <Box mb="s-16">
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
            />
          </Box>

          {!!settings?.enable_multiple_levels_per_seniority && (
            <Box mb="s-16">
              <LapeRadioSelectInput
                name="specialisation_seniority_sublevel"
                label="Sub-seniority Level"
                selector={subsenioritySelector}
              />
            </Box>
          )}

          <Box mb="s-16">
            <LapeRadioSelectInput
              name="reviewed_employee_type"
              label="Employee type"
              options={employeeTypes || []}
              value={
                values.reviewed_employee_type
                  ? {
                      id: values.reviewed_employee_type,
                      name: values.reviewed_employee_type,
                    }
                  : null
              }
              onChange={value => {
                const option = employeeTypes?.find(item => item.value === value)
                if (option) {
                  values.reviewed_employee_type = option.value.id
                }
              }}
            />
          </Box>

          <Box mb="s-16">
            <LapeNewTextArea rows={5} name="reason_for_disabling" label="Comments" />
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup useValidator afterSubmitUrl={backUrl} />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(EligiblePeopleEdit)
