import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import {
  RoadmapInterfaceForm,
  UnassignedRoadmapInterface,
} from '@src/interfaces/roadmaps'
import { useSubmitFlowHelpers } from '../../common/utils'

import { useRoadmapTypeOptions } from '@src/pages/Forms/RoadmapForm/common/useRoadmapTypeOptions'

import { IdAndName } from '@src/interfaces'
import { ItemType } from '@src/interfaces/deliverables'
import { useQuery } from '@src/utils/queryParamsHooks'
import { addGoalRoadmap, roadmapsRequestsNew } from '@src/api/roadmaps'
import { captureException } from '@sentry/react'
import { InputGroup, Side } from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { LinkClickupTasksForm } from '../SidebarForms/LinkClickupTasksForm'
import { LinkLinearTasksForm } from '../SidebarForms/LinkLinearTasksForm'
import { LinkNotionTasksForm } from '../SidebarForms/LinkNotionTasksForm'
import Form from '@src/features/Form/Form'
import { LinkRoadmapsForm } from '../SidebarForms/LinkRoadmapsForm'
import { RoadmapForm } from '@src/pages/Forms/RoadmapForm/RoadmapForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { LinkMondayTasksForm } from '../SidebarForms/LinkMondayTasksForm'

interface Props {
  reviewCycle?: ReviewCyclesInterface
  onAfterAdded: () => void
  onClose: () => void
  roadmap?: Partial<RoadmapInterfaceForm>
}

export const AddRoadmapsSide = ({
  reviewCycle,
  onAfterAdded,
  onClose,
  roadmap,
}: Props) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const { query } = useQuery()
  const { options: roadmapTypeOptions, isLoading } = useRoadmapTypeOptions()
  const [selectedRoadmapType, setSelectedRoadmapType] =
    useState<IdAndName<ItemType> | null>(
      roadmap?.item_type ? { id: roadmap.item_type, name: roadmap.item_type } : null,
    )

  const getEntityKey = (): 'employee' | 'team' | 'department' | undefined => {
    if (values.content_type) {
      return values.content_type.model === 'employees'
        ? 'employee'
        : values.content_type.model === 'teams'
        ? 'team'
        : 'department'
    }
    if (query.type) {
      switch (query.type) {
        case 'department':
          return 'department'

        case 'teams':
          return 'team'
        default:
          return undefined
      }
    }
    return undefined
  }

  useEffect(() => {
    if (!isLoading && !roadmap?.item_type) {
      setSelectedRoadmapType(roadmapTypeOptions[0].value)
    }
  }, [isLoading])

  const entityKey = getEntityKey()

  const submitIntegratedRoadmap = async (
    cycle: ReviewCyclesInterface,
    epics: UnassignedRoadmapInterface[],
  ): Promise<void> => {
    try {
      setPendingSubmit(true)
      if (!selectedRoadmapType?.id) {
        throw new Error('select roadmap type')
      }
      if (values.is_company || values.is_top_level) {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          item_type: selectedRoadmapType.id,
          is_company: true,
        })
      } else {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          item_type: selectedRoadmapType.id,
          ...(entityKey && { [entityKey]: { id: values.object_id } }),
        })
      }
    } catch (err) {
      captureException(err)
      showError('Failed to add roadmap', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  return (
    <SideBar
      useLayout
      onClose={onClose}
      title={roadmap?.id ? 'Edit roadmap' : 'Add roadmap'}
      data-testid="roadmaps-form"
    >
      <InputGroup>
        <RadioSelectInput
          label="Roadmap type"
          searchable={false}
          value={selectedRoadmapType}
          options={roadmapTypeOptions}
          loading={isLoading}
          onChange={val => setSelectedRoadmapType(val)}
        />
        {selectedRoadmapType?.id === ItemType.Clickup && (
          <LinkClickupTasksForm
            pending={pendingSubmit}
            initialCycle={reviewCycle}
            ownerId={values.owner.id}
            excluded={values.roadmaps.filter(r => r.item_type === ItemType.Clickup)}
            selected={[]} // do not select anything. Always a new one
            submit={async ({ cycle, epics }) => {
              await submitIntegratedRoadmap(cycle, epics)
              onAfterAdded()
            }}
          />
        )}
        {selectedRoadmapType?.id === ItemType.Linear && (
          <LinkLinearTasksForm
            pending={pendingSubmit}
            initialCycle={reviewCycle}
            ownerId={values.owner.id}
            excluded={values.roadmaps.filter(r => r.item_type === ItemType.Linear)}
            selected={[]} // do not select anything. Always a new one
            submit={async ({ cycle, epics }) => {
              await submitIntegratedRoadmap(cycle, epics)
              onAfterAdded()
            }}
          />
        )}

        {selectedRoadmapType?.id === ItemType.Notion && (
          <LinkNotionTasksForm
            pending={pendingSubmit}
            initialCycle={reviewCycle}
            ownerId={values.owner.id}
            excluded={values.roadmaps.filter(r => r.item_type === ItemType.Notion)}
            selected={[]} // do not select anything. Always a new one
            submit={async ({ cycle, epics }) => {
              await submitIntegratedRoadmap(cycle, epics)
              onAfterAdded()
            }}
          />
        )}

        {selectedRoadmapType?.id === ItemType.Monday && (
          <LinkMondayTasksForm
            pending={pendingSubmit}
            initialCycle={reviewCycle}
            ownerId={values.owner.id}
            excluded={values.roadmaps.filter(r => r.item_type === ItemType.Monday)}
            selected={[]} // do not select anything. Always a new one
            submit={async ({ cycle, epics }) => {
              await submitIntegratedRoadmap(cycle, epics)
              onAfterAdded()
            }}
          />
        )}
        {selectedRoadmapType?.id === ItemType.Jira && (
          <LinkRoadmapsForm
            pending={pendingSubmit}
            initialCycle={reviewCycle}
            ownerId={values.owner.id}
            submit={async ({ cycle, epics }) => {
              await submitIntegratedRoadmap(cycle, epics)
              onAfterAdded()
            }}
            exclude={values.roadmaps}
          />
        )}
        {selectedRoadmapType?.id === ItemType.Manual && (
          <Form
            forceParams={roadmap?.id ? { id: String(roadmap.id) } : undefined}
            api={roadmapsRequestsNew}
            initialValues={
              roadmap?.id
                ? undefined
                : {
                    is_company: values.is_company || values.is_top_level,
                    ...(entityKey && { [entityKey]: { id: values.object_id } }),
                    goal: { id: values.id },
                    owner: values.owner,
                    review_cycle: reviewCycle,
                    item_type: ItemType.Manual,
                  }
            }
            disableLocalStorageCaching
            useLocalStorageCaching={false}
          >
            <RoadmapForm disabledFields={{ owner: 'owner is prefilled' }} />
            <Side.Actions>
              <NewSaveButtonWithPopup onAfterSubmit={onAfterAdded} useValidator />
            </Side.Actions>
          </Form>
        )}
      </InputGroup>
    </SideBar>
  )
}
