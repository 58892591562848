import {
  askClickupWorkspacePermissions,
  askJiraWorkspacePermissions,
  askLinearWorkspacePermissions,
  askLookerWorkspacePermissions,
  askMondayWorkspacePermissions,
  askSalesforceWorkspacePermissions,
  connectTableau,
} from '@src/api/officeSuiteProvider'
import { roadmapSettings } from '@src/api/settings'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { integrationApiFormMap } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import { LocalStorageKeys } from '@src/store/auth/types'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { capitalize } from 'lodash'
import { connectionTypeToUpdateTypeMap } from './components/constants'

export type ConnectionType =
  | 'looker'
  | 'jira'
  | 'tableau'
  | 'clickup'
  | 'salesforce'
  | 'linear'
  | 'notion'
  | 'monday'

export const useMakeConnection = () => {
  const showStatusPopup = useShowStatusPopup()
  const makeConnection = async (type: ConnectionType) => {
    try {
      if (type === 'jira') {
        await askJiraWorkspacePermissions()
      }
      if (type === 'looker') {
        await askLookerWorkspacePermissions()
      }
      if (type === 'clickup') {
        await askClickupWorkspacePermissions()
      }
      if (type === 'salesforce') {
        await askSalesforceWorkspacePermissions()
      }
      if (type === 'linear') {
        await askLinearWorkspacePermissions()
      }
      if (type === 'tableau') {
        const resp = await connectTableau()
        if (resp.status === 200) {
          await integrationApiFormMap[type]?.update({ enabled: true }, { id: '1' })
          workspaceLocalStorage.setItem(LocalStorageKeys.CONNECTED_INTEGRATION, 'Tableau')
          window.location.reload()
        }
      }

      if (type === 'notion') {
        const api = integrationApiFormMap[connectionTypeToUpdateTypeMap[type]]

        if (!api) {
          return
        }

        await api.update({ enabled: true }, { id: '1' })
        await roadmapSettings.update({ notion_enabled: true }, { id: '1' })
        workspaceLocalStorage.setItem(LocalStorageKeys.CONNECTED_INTEGRATION, 'Notion')
        window.location.reload()
      }

      if (type === 'monday') {
        await askMondayWorkspacePermissions()
      }
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: `${capitalize(type)} integration failed`,
        description: getMessageFromApiError(e),
      })
    }
  }
  return makeConnection
}
