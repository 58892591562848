import React from 'react'
import { TextButton, TextWidget, VStack, Widget } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { BaseGrowthPlanInterface } from '@src/interfaces/growthPlans'
import { ROUTES } from '@src/constants/routes'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { GrowthPlanHeader } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/GrowthPlanHeader'
import { EditorView } from '@components/Editor/EditorView'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { PerformanceLayoutSubTabs } from '@src/pages/EmployeeProfile/Layout/Performance/LayoutTab'

interface Props {
  employee: EmployeeInterface
  growthPlan: BaseGrowthPlanInterface
}

export const PreviewGrowthPlan = ({ employee, growthPlan }: Props) => (
  <Widget px="s-16" pt="s-16">
    <VStack space="s-16">
      <GrowthPlanHeader employee={employee} growthPlan={growthPlan} />
      <TextWidget style={transparentThemeBackgroundOverrides}>
        <TextWidget.Title>Description</TextWidget.Title>
        <TextWidget.Content>
          <VStack space="s-16">
            <EditorView html={growthPlan.description} />
            <TextButton
              use={InternalLink}
              to={pathToUrl(
                ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
                {
                  id: employee.id,
                  subtab: PerformanceLayoutSubTabs.Growth,
                },
              )}
            >
              See plan
            </TextButton>
          </VStack>
        </TextWidget.Content>
      </TextWidget>
    </VStack>
  </Widget>
)
