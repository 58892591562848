import capitalize from 'lodash/capitalize'

import { IdAndName } from '@src/interfaces'

export const toIdAndName = <T extends string>(
  value: T,
  capitalizeName?: boolean,
): IdAndName<T, string> => {
  return {
    id: value,
    name: capitalizeName ? capitalize(value) : value,
  }
}

export const toLabeledValue = <T extends IdAndName<string | number, string>>(
  value: T,
) => ({
  value,
  label: value.name,
})

export const toLabeledIdAndName = <T extends string>(value: T) => {
  return toLabeledValue(toIdAndName(value, true))
}
