import { useCallback, useEffect, useMemo, useState } from 'react'
import { LOCAL_STORAGE } from '@src/constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useRegeneratePerformanceInsights } from '@src/features/AiInsights/Performance/api/insights'

interface Props {
  isLoading: boolean
  isDisabled: boolean
  onRegenerate: VoidFunction
}

const APPROXIMATE_GENERATION_DELAY_MS = 1000 * 60 * 30
const { PERFORMANCE_INSIGHTS_GENERATION_TIMESTAMP } = LOCAL_STORAGE

export const useRegeneratePerformanceAiInsights = (): Props => {
  const [isDisabled, setIsDisabled] = useState(true)

  const { mutateAsync: regenerate, isLoading } = useRegeneratePerformanceInsights()

  useEffect(() => {
    const timestamp = workspaceLocalStorage.getItem(
      PERFORMANCE_INSIGHTS_GENERATION_TIMESTAMP,
    )

    if (!timestamp || !Number.isSafeInteger(Number(timestamp))) {
      return setIsDisabled(false)
    }

    const generationDelayMS = Date.now() - Number(timestamp)
    const canBeAlreadyGenerated = generationDelayMS > APPROXIMATE_GENERATION_DELAY_MS

    if (canBeAlreadyGenerated) {
      workspaceLocalStorage.removeItem(PERFORMANCE_INSIGHTS_GENERATION_TIMESTAMP)
      return setIsDisabled(false)
    }

    const timerDelay = APPROXIMATE_GENERATION_DELAY_MS - generationDelayMS
    const timerId = setTimeout(() => {
      workspaceLocalStorage.removeItem(PERFORMANCE_INSIGHTS_GENERATION_TIMESTAMP)
      setIsDisabled(false)
    }, timerDelay)

    return () => {
      if (!timerId) {
        return
      }

      clearTimeout(timerId)
    }
  }, [isDisabled])

  const onRegenerate = useCallback(async () => {
    if (isDisabled) {
      return
    }

    await regenerate()
    workspaceLocalStorage.setItem(
      PERFORMANCE_INSIGHTS_GENERATION_TIMESTAMP,
      String(Date.now()),
    )
    setIsDisabled(true)
  }, [regenerate, isDisabled])

  return useMemo<Props>(
    () => ({
      isLoading,
      isDisabled,
      onRegenerate,
    }),
    [isLoading, isDisabled, onRegenerate],
  )
}
