import { VStack } from '@revolut/ui-kit'
import { useGetMatrixValidatorAndSenioritiesForRole } from '@src/features/CompetencyMatrixTable/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AllowedSenioritiesWidget } from '@src/features/Roles/components/AllowedSenioritesWidget'
import { RoleCompetencyMatrixWidget } from '@src/features/Roles/components/RoleCompetencyMatrixWidget'
import { RoleInterface } from '@src/interfaces/roles'
import React from 'react'

export const CompetencyMatrix = () => {
  const { values } = useLapeContext<RoleInterface>()
  const { seniorities } = useGetMatrixValidatorAndSenioritiesForRole(values)
  return (
    <VStack gap="s-16">
      <AllowedSenioritiesWidget />
      <RoleCompetencyMatrixWidget seniorities={seniorities} />
    </VStack>
  )
}
