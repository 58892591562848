import React, { useEffect, useMemo, useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  eligibleEmployeesRequests,
  syncEligibleEmployeeData,
  triggerGenerateScorecards,
  useGetScorecardsGenerationsCategories,
  useGetScorecardsGenerationsStatus,
} from '@src/api/supportTool/eligibleEmployees'
import {
  actionsColumn,
  commentColumn,
  createScorecardsColumn,
  cycleColumn,
  eligibilityColumn,
  employeeNameColumn,
  employeeStatusColumn,
  functionalManagerColumn,
  lineManagerColumn,
  seniorityColumn,
  specialisationColumn,
  talentTypeColumn,
  teamNameColumn,
} from '@src/constants/columns/supportTool/eligibleEmployees'
import AddEligibleEmployeePopup from '@src/features/Popups/supportTool/AddEligibleEmployeePopup'
import { connect, useLape } from 'lape'
import { CycleOption, OptionInterface } from '@src/interfaces/selectors'
import { pushNotification, successNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import UploadPopup, {
  UploadPopupType,
} from '@src/features/Popups/supportTool/UploadPopup'
import { EligibleEmployeesInterface } from '@src/interfaces/supportTool/eligibleEmployees'
import Loader from '@components/CommonSC/Loader'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import { selectorKeys } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  Action,
  MoreBar,
  useTooltip,
  Tooltip as UIKitTooltip,
  useStatusPopup,
  StatusPopup,
  useToggle,
} from '@revolut/ui-kit'
import { ArrowRecurring, Plus, ShareIOs, People } from '@revolut/icons'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { GenerateScorecardsPopup } from '@src/pages/Performance/SupportTool/GenerateScorecardsPopup'
import Table from '@src/components/TableV2/Table'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useScorecardsValidation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScorecardsValidation'
import { ValidationEmployeePopup } from '@src/features/Popups/supportTool/ValidationEmployeePopup'
import { useLocation } from 'react-router-dom'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const employeeRow = (
  specialisationsEnabled: boolean,
): RowInterface<EligibleEmployeesInterface> => ({
  cells: [
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...employeeStatusColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 200,
    },
    {
      ...seniorityColumn,
      width: 140,
    },
    {
      ...specialisationColumn(specialisationsEnabled),
      width: 200,
    },
    {
      ...lineManagerColumn,
      width: 150,
    },
    {
      ...functionalManagerColumn,
      width: 150,
    },
    {
      ...cycleColumn,
      width: 150,
    },
    {
      ...createScorecardsColumn,
      width: 100,
    },
    {
      ...eligibilityColumn,
      width: 100,
    },
    {
      ...talentTypeColumn,
      width: 200,
    },
    {
      ...commentColumn,
      width: 160,
    },
    {
      ...actionsColumn,
      width: 100,
      insert: ({ data }) => (
        <Action
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.GENERAL, {
                id: data.id,
              }),
            )
          }}
        >
          Edit
        </Action>
      ),
    },
  ],
})

interface Props {
  cycleIdentifier: string | number
  cycles: CycleOption[]
}

const EligiblePeopleContent = ({ cycleIdentifier, cycles }: Props) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const state = useLape<{
    openAddPopup: boolean
    removeEmployee?: {
      id: number
      name: string
      cycle: string
    }
  }>({
    openAddPopup: false,
    removeEmployee: undefined,
  })
  const [showUploadPopup, setShowUploadPopup] = useState(false)
  const [showScorecardsPopup, setShowScorecardsPopup] = useState(false)
  const [scorecardsPending, setScorecardsPending] = useState(false)
  const [syncPending, setSyncPending] = useState(false)
  const [enableGeneration, setEnableGeneration] = useState(false)
  const { data: generationStatus } = useGetScorecardsGenerationsStatus()
  const statusPopup = useStatusPopup()
  const generateTooltip = useTooltip()
  const [isValidationPopupOpen, validationPopupToggler] = useToggle()
  const [selectedCycle, setSelectedCycle] = useState<CycleOption | null>(null)

  const initialFilterBy = [
    {
      filters: [{ id: cycleIdentifier, name: `${cycleIdentifier}` }],
      columnName: 'cycle__id',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'employee__full_name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<EligibleEmployeesInterface>(
    eligibleEmployeesRequests,
    initialFilterBy,
    initialSortBy,
  )

  const row = useMemo(() => employeeRow(specialisationsEnabled), [specialisationsEnabled])

  const onAddEmployee = async (employee: OptionInterface, cycle: OptionInterface) => {
    await eligibleEmployeesRequests.postItem({
      employee,
      cycle,
    } as unknown as Partial<EligibleEmployeesInterface>)
    state.openAddPopup = false
    pushNotification({
      type: NotificationTypes.success,
      value: 'Employee added to the eligible list successfully',
      duration: SUCCESS_DEFAULT_DURATION,
    })
    table.refresh()
  }

  const handleSubmit = () => {
    setShowUploadPopup(false)
    pushNotification({
      type: NotificationTypes.success,
      value: 'Employee info uploaded successfully',
      duration: SUCCESS_DEFAULT_DURATION,
    })
  }

  const { data: categories } = useGetScorecardsGenerationsCategories(cycleIdentifier)
  const { isPending: isValidationPending } = useScorecardsValidation(cycleIdentifier)

  useEffect(() => {
    setEnableGeneration(!!generationStatus)
  }, [generationStatus])

  const handleGenerateScorecards = async () => {
    setScorecardsPending(true)
    try {
      const enabledCategories =
        categories?.filter(category => category.enabled).map(category => category.id) ||
        []
      await triggerGenerateScorecards(cycleIdentifier, enabledCategories, table.filterBy)
      setEnableGeneration(false)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            Background jobs were triggered to generate scorecards
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setScorecardsPending(false)
      setShowScorecardsPopup(false)
    }
  }

  const handleSyncData = async () => {
    setSyncPending(true)
    try {
      const res = await syncEligibleEmployeeData(cycleIdentifier, table.filterBy)
      successNotification(res.data.result)
    } finally {
      setSyncPending(false)
    }
  }

  const handleSelectCycle = (cycle: CycleOption | null) => {
    if (cycle) {
      setSelectedCycle(cycle)
    }
  }

  const handleFilterChange = (filters: FilterByInterface) => {
    setSelectedCycle(
      cycles.find(cycle => cycle.id.toString() === filters.filters[0].id.toString()) ??
        null,
    )
    table.onFilterChange(filters)
  }

  return (
    <>
      {isValidationPopupOpen && (
        <ValidationEmployeePopup
          cycleId={cycleIdentifier}
          handleClose={validationPopupToggler.off}
        />
      )}
      <AddEligibleEmployeePopup
        open={state.openAddPopup}
        onClose={() => {
          state.openAddPopup = false
        }}
        onSubmit={onAddEmployee}
        onSelectCycle={handleSelectCycle}
        cycles={cycles}
        selectedCycle={selectedCycle}
      />
      <UploadPopup
        open={showUploadPopup}
        onClose={() => setShowUploadPopup(false)}
        onSubmit={handleSubmit}
        type={UploadPopupType.EligibleEmployees}
      />
      <GenerateScorecardsPopup
        isOpen={showScorecardsPopup}
        isPending={scorecardsPending}
        onSubmit={handleGenerateScorecards}
        onCancel={() => setShowScorecardsPopup(false)}
      />
      <Table.Widget>
        <Table.Widget.Filters>
          <CycleFilterButton
            onFilterChange={handleFilterChange}
            columnName="cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.all_review_cycles}
          />
        </Table.Widget.Filters>

        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              useIcon={Plus}
              onClick={() => {
                state.openAddPopup = true
              }}
            >
              Add employee
            </MoreBar.Action>
            <MoreBar.Action useIcon={ShareIOs} onClick={() => setShowUploadPopup(true)}>
              Bulk upload
            </MoreBar.Action>
            <MoreBar.Action
              {...generateTooltip.getAnchorProps()}
              onClick={() => {
                if (enableGeneration) {
                  setShowScorecardsPopup(true)
                }
              }}
              aria-disabled={!enableGeneration}
            >
              Generate scorecards
              {!enableGeneration && (
                <UIKitTooltip
                  {...generateTooltip.getTargetProps()}
                  width={250}
                  style={{ textAlign: 'center' }}
                  placement="bottom-start"
                >
                  You can only generate scorecards for a single cycle and if no background
                  jobs are pending
                </UIKitTooltip>
              )}
            </MoreBar.Action>
            <MoreBar.Action
              useIcon={ArrowRecurring}
              onClick={handleSyncData}
              pending={syncPending}
            >
              Sync employee data
            </MoreBar.Action>
            <MoreBar.Action
              useIcon={People}
              onClick={validationPopupToggler.on}
              pending={isValidationPending}
            >
              Validate employee data
            </MoreBar.Action>
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>

        <Table.Widget.Table>
          <AdjustableTable<EligibleEmployeesInterface>
            name={TableNames.EligibleEmployees}
            useWindowScroll
            row={row}
            {...table}
            tableSettings={{
              visible: [],
              hidden: [cycleColumn.title, employeeStatusColumn.title],
            }}
            emptyState={<EmptyTableRaw title="There are no employees" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}

const LapeConnectedEligiblePeople = connect(EligiblePeopleContent)

const EligibleEmployees = () => {
  const { search } = useLocation()
  const cycleId = new URLSearchParams(search).get('cycle__id')
  const { initialCycleOffset: cycleIdentifier, cycles } = useSelectedPerformanceCycle(
    selectorKeys.all_review_cycles,
  )

  if (cycleIdentifier === undefined) {
    return <Loader />
  }

  return (
    <LapeConnectedEligiblePeople
      cycles={cycles}
      cycleIdentifier={cycleId ?? cycleIdentifier}
    />
  )
}

export default EligibleEmployees
