import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  BottomSheet,
  Box,
  Button,
  Header,
  HStack,
  Icon,
  IconButton,
  StatusPopup,
  Text,
  TextArea,
  TextButton,
  Token,
  useToggle,
  VStack,
} from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceInsightsDomain } from '@src/features/AiInsights/Performance/interfaces/insights'
import { useCommentOnPerformanceInsights } from '@src/features/AiInsights/Performance/api/insights'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { Commentator } from '@src/features/AiInsights/Performance/components/Insights/InsightsActions/common/Commentator'

interface Props {
  insightId: number
  mode: 'create' | 'edit'
  domain: PerformanceInsightsDomain
  employee: EmployeeInterface
  comment?: string
  onSuccess?: VoidFunction
}

export const SubmitCommentAction = ({
  insightId,
  mode,
  domain,
  employee,
  comment: initialComment,
  onSuccess,
}: Props) => {
  const [comment, setComment] = useState<string | undefined>(initialComment)
  const [isPopupOpen, popupToggler] = useToggle()

  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  const { mutateAsync: commentInsights, isLoading } = useCommentOnPerformanceInsights(
    employee.id,
    insightId,
    domain,
  )

  useEffect(() => {
    setComment(initialComment)
  }, [initialComment])

  const handleConfirm = async () => {
    if (!comment) {
      return
    }

    try {
      await commentInsights([undefined, { comment }])
      showStatusPopup({
        status: 'success-result',
        title: (
          <FormattedMessage
            id="performance.insights.comment.add.success.message"
            defaultMessage="Your comment has been added and is now visible to your manager."
          />
        ),
        actions: statusPopup => (
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>
              <FormattedMessage id="common.labels.cta.close" defaultMessage="Close" />
            </Button>
          </StatusPopup.Actions>
        ),
      })

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      setComment(initialComment)
      errorPopup.show({ error, fallbackTitle: 'Failed to leave comment' })
    } finally {
      popupToggler.off()
    }
  }

  const handleDelete = async () => {
    if (!comment) {
      return
    }

    try {
      await commentInsights([undefined, { comment: null }])
      showStatusPopup({
        title: (
          <FormattedMessage
            id="performance.insights.comment.delete.success.message"
            defaultMessage="Your comment has been deleted."
          />
        ),
      })

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      setComment(undefined)
      errorPopup.show({ error, fallbackTitle: 'Failed to delete comment' })
    } finally {
      popupToggler.off()
    }
  }

  const handleCancel = () => {
    setComment(initialComment)
    popupToggler.off()
  }

  return (
    <>
      <TextButton onClick={popupToggler.on}>
        <HStack space="s-8" align="center" color={Token.color.greyTone50}>
          {mode === 'create' ? (
            <Icon name="Chat" size={14} />
          ) : (
            <Commentator employee={employee} />
          )}
          <Text variant="body2">
            <ActionLabel mode={mode} />
          </Text>
        </HStack>
      </TextButton>
      <BottomSheet open={isPopupOpen} onClose={popupToggler.off}>
        <Header>
          <Header.Title>
            <VStack space="s-4">
              <Box ml="-s-4">
                <IconButton
                  useIcon="Cross"
                  size={18}
                  onClick={handleCancel}
                  aria-label="Cancel"
                />
              </Box>
              <ActionLabel mode={mode} />
            </VStack>
          </Header.Title>
          <Header.Subtitle>
            <FormattedMessage
              id="performance.insights.add.comment.popup.subtitle"
              defaultMessage="You can provide additional context about these insights. Your manager will be able to see your comment."
            />
          </Header.Subtitle>
        </Header>
        <TextArea
          value={comment}
          label="Your comment"
          rows={3}
          maxRows={5}
          onChange={({ currentTarget }) => setComment(currentTarget.value)}
        />
        <BottomSheet.Actions>
          <Button onClick={handleConfirm} pending={isLoading} disabled={!comment}>
            <ActionLabel mode={mode} />
          </Button>
          {mode === 'edit' && (
            <Button variant="negative" onClick={handleDelete}>
              <FormattedMessage id="common.labels.cta.cancel" defaultMessage="Delete" />
            </Button>
          )}
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}

const ActionLabel = ({ mode }: Pick<Props, 'mode'>) => {
  if (mode === 'create') {
    return (
      <FormattedMessage
        id="performance.insights.comment.add.label"
        defaultMessage="Add comment"
      />
    )
  }

  return (
    <FormattedMessage
      id="performance.insights.comment.edit.label"
      defaultMessage="Edit comment"
    />
  )
}
