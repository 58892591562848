import React, { useState } from 'react'
import { Box, InputGroup, Subheader, useDropdown, VStack } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useGlobalSettings } from '@src/api/settings'
import { useMountSpecialisationForm } from '@src/pages/Forms/SpecialisationForm/General/useMountSpecialisationForm'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { FormattedMessage } from 'react-intl'
import {
  SpecialisationFunction,
  SpecialisationGoals,
  SpecialisationLinkedSpecialisations,
  SpecialisationMission,
  SpecialisationName,
  SpecialisationParentRole,
} from '@src/pages/Forms/SpecialisationForm/General/SpecialisationGeneralInfoFormComponents'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { TemplateSpecialisationDropdown } from './TemplateSpecialisationDropdown'

export const GeneralInfo = () => {
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()
  useMountSpecialisationForm()
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false)
  const dropdown = useDropdown<HTMLDivElement>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const { values } = useLapeContext<SpecialisationInterface>()
  const isNewSpecialisation = !values.id

  return (
    <VStack>
      <Subheader>
        <FormattedMessage
          id="addEditSpecialisation.form.generalInfo.details"
          defaultMessage="{type} details"
          values={{ type: specialisationsEnabled ? 'Specialisation' : 'Role' }}
        />
      </Subheader>
      <InputGroup>
        <Box ref={dropdown.getAnchorProps().ref}>
          <SpecialisationName pending={isTemplatesLoading} />
        </Box>
        {isNewSpecialisation && (
          <TemplateSpecialisationDropdown
            dropdown={dropdown}
            setIsTemplatesLoading={setIsTemplatesLoading}
            isTemplatesLoading={isTemplatesLoading}
          />
        )}
        <SpecialisationParentRole />
        <SpecialisationFunction />
        <LapeRadioSelectInput
          name="owner"
          label={`${specialisationsEnabled ? 'Specialisation' : 'Role'} Owner`}
          selector={selectorKeys.employee}
          clearable
        />
      </InputGroup>
      <Subheader>
        <FormattedMessage
          id="addEditSpecialisation.form.generalInfo.description"
          defaultMessage="{type} description"
          values={{ type: specialisationsEnabled ? 'Specialisation' : 'Role' }}
        />
      </Subheader>
      <SpecialisationMission />
      <InputGroup>
        <SpecialisationGoals />
        {promotions_enabled && <SpecialisationLinkedSpecialisations />}
      </InputGroup>
    </VStack>
  )
}
