import React, { useState } from 'react'
import {
  Box,
  VStack,
  Flex,
  Input,
  Icon,
  Link,
  HStack,
  ThemeProvider,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES, PUBLIC_ROUTES } from '@src/constants/routes'

import { Text } from './Text'
import { usePostEmail } from '@src/pages/Landing/api'
import { navigateTo } from '@src/actions/RouterActions'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

export const HeroBlockContentBaseLayout = styled(ContentBlockLayout)(
  css({
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: ['column', null, null, 'row'],
    justifyContent: [null, null, null, 'space-between'],
    paddingBottom: 0,
    paddingTop: ['s-40', 's-40', null, 0],
  }),
)

export const HeroBlockContentBase = styled(Box)<{
  align?: 'center' | 'start'
}>(({ align }) =>
  css({
    width: '100%',
    alignContent: 'center',
    display: 'grid',
    gap: ['s-16', null, null, 's-24', null, 's-32'],
    height: 'auto',
    marginX: ['auto', null, null, align === 'start' ? 0 : 'auto'],
    minHeight: [null, null, null, 200],
    textAlign: 'center',
    justifyItems: 'center',
    maxWidth: [null, null, null, align === 'start' ? '65%' : undefined],
  }),
)

export const HeroBlockContent = () => {
  const { mutateAsync: postEmail, isLoading } = usePostEmail()
  const [email, setEmail] = useState<string | undefined>(undefined)
  const { sendAnalyticsEvent } = useAnalytics()

  const handleClick = async () => {
    sendAnalyticsEvent(AnalyticsEvents.click_book_a_demo)

    try {
      if (email && email !== '') {
        await postEmail({ email })
      }
    } finally {
      navigateTo(pathToUrl(ROUTES.CONTACT_US), { email })
    }
  }

  return (
    <HeroBlockContentBaseLayout>
      <HeroBlockContentBase align="center">
        <VStack align="center" space={{ all: 's-8', lg: 's-24' }} width="100%">
          <Text
            use="h1"
            variant="h1"
            whiteSpace="pre-wrap"
            width="100%"
            mt={{ all: 's-72', md: 0 }}
          >
            Driving high performing teams
          </Text>
          <Text
            use="p"
            whiteSpace="pre-wrap"
            // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
            variant="subtitle1"
          >
            People are the driving force behind an organisation’s success. Extraordinary
            results require sustained high performance. From hiring and goal alignment to
            keeping talent happy & engaged – Revolut People is designed to help you
            achieve just that.
          </Text>
        </VStack>

        <VStack gap="s-8" display={{ all: 'none', md: 'flex' }}>
          <HStack gap="s-8">
            <Box style={{ textAlign: 'left' }} width={343}>
              <ThemeProvider mode="dark" background="deep-blue">
                <Input
                  renderPrefix={() => <Icon name="Envelope" />}
                  label="Your email"
                  value={email}
                  onChange={event => setEmail(event.currentTarget.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleClick()
                    }
                  }}
                />
              </ThemeProvider>
            </Box>
            <Button onClick={handleClick} variant="primary" disabled={isLoading}>
              Book a demo
            </Button>
          </HStack>
          <Text variant="small">
            By continuing, you confirm that you have read and agree to our{' '}
            <Link href={pathToUrl(PUBLIC_ROUTES.PRIVACY_NOTICE)} target="_blank">
              Privacy Policy
            </Link>
          </Text>
        </VStack>

        <Flex display={{ all: 'flex', md: 'none' }} alignSelf="center">
          <Button variant="primary" use={InternalLink} to={pathToUrl(ROUTES.CONTACT_US)}>
            Book a demo
          </Button>
        </Flex>
      </HeroBlockContentBase>
    </HeroBlockContentBaseLayout>
  )
}
