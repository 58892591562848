import React from 'react'
import { HStack, Icon, Tooltip, useTooltip, Text, TextButton } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'

export const ParentSkillsTitle = ({ to }: { to: string }) => {
  const tooltip = useTooltip()

  return (
    <section {...tooltip.getAnchorProps()}>
      <HStack space="s-8" align="center">
        <Text>Parent skills</Text>
        <Icon name="InfoOutline" size={16} />
      </HStack>
      <Tooltip {...tooltip.getTargetProps()} width={450}>
        <Text>
          These skills and their ratings are defined by the role and are used by all
          specialisations within this role.{' '}
          <TextButton use={InternalLink} to={to} target="_blank">
            Click here
          </TextButton>{' '}
          to go to the role.
        </Text>
      </Tooltip>
    </section>
  )
}
