import React from 'react'
import { ReviewScorecardViewInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Heart } from '@revolut/icons'
import {
  CardContentTypes,
  cultureOptions,
  getFinalGradeTitleFromRating,
  getRoundedRating,
  ratingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get } from 'lodash'
import { getCardsForView } from '@src/utils/performance'
import { CultureValuesView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/CultureValuesView'
import { getViewGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { getHideFinalRating } from '@src/pages/Forms/EmployeePerformanceViewLayout/utils'

export const CultureValuesCardView = connect(() => {
  const { values } = useLapeContext<ReviewScorecardViewInterface>()

  const hasCultureValues = !!values.summary?.culture_values
  const hasCultureSkills = !!values.summary?.culture_skills

  if (!hasCultureValues && !hasCultureSkills) {
    return null
  }

  const cultureValuesCards = values?.summary?.culture_values?.cards || []
  const cultureValuesFields = cultureValuesCards.map((card, ind) => ({
    field: `summary.culture_values.cards.${ind}`,
    title: card.name,
    grades: getViewGradesWithExpectations(cultureOptions),
    gradeRecommendation: null,
    cardIndex: ind,
    cardJustification: null,
  }))

  const cultureSkillsCards = getCardsForView(values?.summary?.culture_skills?.cards || [])
  const cultureSkillsFields = cultureSkillsCards.map((card, ind) => ({
    field: `summary.culture_skills.cards.${ind}`,
    title: card.name,
    grades: getViewGradesWithExpectations(ratingOptions),
    gradeRecommendation: null,
    cardIndex: ind,
    cardJustification: null,
  }))

  if (!cultureSkillsCards) {
    return null
  }

  const valuesFields = hasCultureValues ? cultureValuesFields : cultureSkillsFields
  const hideFinalRating = getHideFinalRating(values)
  const finalRating = hideFinalRating
    ? undefined
    : getFinalGradeTitleFromRating(
        values.grade_label_mapping,
        hasCultureValues
          ? values.summary?.culture_values?.rating || undefined
          : values.summary?.culture_skills?.rating || undefined,
      )

  return (
    <Card
      data={values}
      renderExpandedContent={expContentField => (
        <CultureValuesView selectedField={expContentField} />
      )}
      type={CardContentTypes.VALUES}
      title="Values"
      icon={Heart}
      fields={valuesFields}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.rating
        if (!ratingValue) {
          return false
        }
        return hasCultureValues
          ? ratingValue === grade.key
          : getRoundedRating(ratingValue) === grade.key
      }}
      justification={
        hasCultureValues
          ? values?.summary?.culture_values?.skipped_section_justifications
          : values?.summary?.culture_skills?.skipped_section_justifications
      }
      finalRating={finalRating}
      isViewMode
    />
  )
})
