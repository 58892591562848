import React from 'react'

import { getScopeFilters } from '@src/features/Engagement/helpers/getScopeFilters'
import { TeamInterface } from '@src/interfaces/teams'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResults/Published'

export const Engagement = ({ data }: { data: TeamInterface }) => {
  return (
    <SurveyResultsPublished
      entityId={data.id}
      entityPermissions={data.field_options.permissions}
      scopeFilters={getScopeFilters(data, 'team')}
    />
  )
}
