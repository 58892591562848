import React from 'react'

import { InfoSideBar } from '@src/pages/Forms/RoleForm/General/components/InfoSideBar'
import { GeneralInfoRoleFormContent } from '@src/pages/Forms/RoleForm/General/components/GeneralInfoRoleFormContent'

export const GeneralInfo = () => {
  return (
    <>
      <InfoSideBar />
      <GeneralInfoRoleFormContent />
    </>
  )
}
