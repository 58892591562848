import {
  FinalGrades,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Avatar, Group, Item, Text } from '@revolut/ui-kit'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import React, { useMemo } from 'react'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { ReviewersList } from './ReviewersList/ReviewersList'

export const ReviewLayoutDeliverablesPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_REVIEW_LAYOUT,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_REVIEW_LAYOUT,
}

export const CheckpointStepReviewers = ({
  checkpoint,
  requests,
  grades,
  reviewCategory,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  requests?: PerfReviewRequestFeedbackInterface[]
  grades?: FinalGrades[]
}) => {
  const reviews: GradedRequestInterface[] | undefined = useMemo(
    () =>
      requests
        ?.filter(
          item => item.probation_checkpoint?.number === checkpoint.checkpoint_number,
        )
        .map(item => {
          const gradeValue = grades?.find(rating => rating.review.id === item.id)

          return {
            ...item,
            grade: gradeValue?.value,
            updated_date:
              item.status === Statuses.completed ? item.updated_date : undefined,
          }
        }),
    [checkpoint, grades, requests],
  )

  if (!reviews?.length) {
    return (
      <Group width="100%" style={transparentThemeBackgroundOverrides}>
        <Item py="s-8">
          <Item.Avatar>
            <Avatar useIcon="16/SandWatch" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              <Text whiteSpace="nowrap" variant="emphasis1">
                Checkpoint has no scorecard
              </Text>
            </Item.Title>
            <Item.Description>
              This checkpoint has no scorecard generated yet
            </Item.Description>
          </Item.Content>
        </Item>
      </Group>
    )
  }

  const onClickAddReview = () => {
    const id = checkpoint.review?.id
    const employeeId = checkpoint.review?.reviewed_employee?.id
    if (id && employeeId) {
      const path = ReviewLayoutDeliverablesPath[reviewCategory]
      navigateTo(
        pathToUrl(path, {
          id,
          employeeId,
        }),
      )
    }
  }

  const userReviewStatus = checkpoint?.review?.status

  return (
    <Group width="100%" style={transparentThemeBackgroundOverrides}>
      {reviews && (
        <ReviewersList
          reviews={reviews}
          checkpointStatus={userReviewStatus}
          canSubmit={!!checkpoint?.can_be_submitted}
          onClickActionButton={onClickAddReview}
        />
      )}
    </Group>
  )
}
