import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type CandidatesSettingsItemId =
  | 'candidateProfile'
  | 'candidateDataRetentionSettings'
  | 'candidateOfferSettings'
  | 'candidateRecruitmentGroups'
  | 'candidateScreenCallScorecards'

export const candidatesSettingsLinkConfig: SettingsLinkItemConfig<CandidatesSettingsItemId>[] =
  [
    {
      id: 'candidateProfile',
      section: 'Candidates settings',
      icon: 'Profile',
      title: 'Candidate settings',
      to: ROUTES.SETTINGS.CANDIDATES.GENERAL,
      subtitle: (
        <FormattedMessage
          id="settings.candidates.general.description"
          defaultMessage="Basic settings for all candidates"
        />
      ),
      permissions: [PermissionTypes.ViewCandidatePreferences],
      bundles: [FeatureFlags.BundleRecruitment],
    },
    {
      id: 'candidateDataRetentionSettings',
      section: 'Candidates settings',
      icon: 'Database',
      title: 'Data retention settings',
      searchTitle: 'Candidate data retention settings',
      to: ROUTES.SETTINGS.CANDIDATES.DATA_RETENTION_SETTINGS,
      subtitle: (
        <FormattedMessage
          id="settings.candidates.dataRetention.description"
          defaultMessage="Configure Data Retention Policies for Candidate’s locations"
        />
      ),
      permissions: [PermissionTypes.ViewDataRetentionPeriod],
      bundles: [FeatureFlags.BundleRecruitment],
    },
    {
      id: 'candidateOfferSettings',
      section: 'Candidates settings',
      icon: 'Profile',
      title: 'Offer settings',
      to: ROUTES.SETTINGS.CANDIDATES.OFFERS,
      subtitle: (
        <FormattedMessage
          id="settings.candidates.offers.description"
          defaultMessage="Settings governing the way offers are handled"
        />
      ),
      permissions: [PermissionTypes.ViewOfferFormPreferences],
      bundles: [FeatureFlags.BundleRecruitment],
    },
    {
      id: 'candidateRecruitmentGroups',
      section: 'Candidates settings',
      icon: 'People',
      title: 'Recruitment groups',
      to: ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS,
      permissions: [PermissionTypes.ViewHiringProcessPreferences],
      globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
      bundles: [FeatureFlags.BundleRecruitment],
    },
    {
      id: 'candidateScreenCallScorecards',
      section: 'Candidates settings',
      icon: 'Questionnaire',
      title: 'Screen call scorecards',
      to: ROUTES.SETTINGS.CANDIDATES.SCREEN_CALL_SCORECARDS,
      permissions: [PermissionTypes.ViewHiringProcessPreferences],
      globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
      bundles: [FeatureFlags.BundleRecruitment],
    },
  ]
