import React, { PropsWithChildren, useContext } from 'react'
import { MoreBar, ActionBar, Token } from '@revolut/ui-kit'
import { TableDensityContextValue, TableDensityContext } from '@components/TableV2/Table'

export const ActionsCell = ({ children }: PropsWithChildren<{}>) => {
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  const MoreBarComp = density !== 'large' ? ActionBar : MoreBar

  return (
    <MoreBarComp
      // passing as p="s-4" makes TS to complain
      style={{ padding: `${Token.space.s4}` }}
      maxCount={1}
      onClick={e => e.stopPropagation()}
      labelMoreButton="More"
    >
      {children}
    </MoreBarComp>
  )
}
