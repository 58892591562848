import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OfferCreationInterface } from '@src/interfaces/offerCreation'
import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'

export const offerCandidateNameColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.text,
  idPoint: 'candidate.id',
  dataPoint: 'candidate.full_name',
  sortKey: 'candidate__full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate name',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
      id: data.candidate?.id,
    }),
}

export const offerRequisitionColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.text,
  idPoint: 'requisition.id',
  dataPoint: 'requisition.id',
  sortKey: null,
  filterKey: 'requisition',
  selectorsKey: selectorKeys.requisitions,
  title: 'Requisition',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
      id: data.requisition?.id,
    }),
}

export const offerSpecialisationColumn = (
  specialisationsEnabled: boolean,
): ColumnInterface<OfferCreationInterface> => ({
  type: CellTypes.text,
  idPoint: 'specialisation.name',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation',
  selectorsKey: selectorKeys.specialisations,
  title: getSpecialisationTitle(specialisationsEnabled),
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
      id: data.specialisation?.id,
    }),
})

export const offerSeniorityColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority.name',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const offerTeamColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.text,
  idPoint: 'team.name',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {
      id: data.team?.id,
    }),
}

export const offerRecruiterColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.insert,
  idPoint: 'candidate.recruiter.id',
  dataPoint: 'candidate.recruiter.name',
  sortKey: 'candidate__recruiter__full_name',
  filterKey: 'candidate__recruiter',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => <Table.EmployeeCell employee={data.candidate?.recruiter} />,
}

export const offerStatusColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.offer_form_statuses,
  title: 'Approval status',
  insert: ({ data }) =>
    data.status ? (
      <Table.StatusCell
        variant={getDefaultStatusVariant(data.status)}
        status={data.status}
      />
    ) : (
      '-'
    ),
}

export const offerSigningStatusColumn: ColumnInterface<OfferCreationInterface> = {
  type: CellTypes.insert,
  idPoint: 'signing_status',
  dataPoint: 'signing_status',
  sortKey: 'signing_status',
  filterKey: 'signing_status',
  selectorsKey: selectorKeys.offer_form_signing_statuses,
  title: 'Stage status',
  insert: ({ data }) =>
    data.signing_status ? (
      <Table.StatusCell variant="neutral" status={data.signing_status} />
    ) : (
      '-'
    ),
}
