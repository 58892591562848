import { JobPostingSectionInterface } from '@src/interfaces/jobPosting'
import { generateRandomId } from '@src/utils/numbers'

export const addSortIdsToJobPostingSections = (
  jobPostingSections: Partial<JobPostingSectionInterface>[] = [],
) => {
  return jobPostingSections.map(section => ({
    title: section.title || '',
    content: section.content || '',
    sortId: generateRandomId(),
  }))
}
