import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@revolut/ui-kit'

import { getKeyPersonsSuccessionPlanChangelogTableRequests } from '@src/api/keyPerson'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import {
  changelogChangedByWithAvatar,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogFieldsOldValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { TableNames } from '@src/constants/table'
import { CellTypes, SORT_DIRECTION } from '@src/interfaces/data'

const row = {
  cells: [
    {
      ...changelogFieldsChangedFields(),
      selectorsKey: selectorKeys.benchmark_changelog_fields,
      width: 100,
    },
    {
      ...changelogFieldsOldValue,
      width: 200,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      sortKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 150,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      type: CellTypes.dateTime,
      width: 120,
    },
    {
      ...changelogStatus,
      selectorsKey: selectorKeys.changelog_item_statuses,
      width: 100,
    },
  ],
}

export const Changelog = () => {
  const { id } = useParams<{ id: string }>()

  const table = useTable(
    getKeyPersonsSuccessionPlanChangelogTableRequests(id),
    undefined,
    [
      {
        sortBy: 'effective_date_time',
        direction: SORT_DIRECTION.ASC,
        nonResettable: true,
      },
    ],
  )

  return (
    <Box mt="s-16">
      <AdjustableTable
        name={TableNames.KeyPersonsSuccessionPlansChangelog}
        row={row}
        {...table}
        useWindowScroll
      />
    </Box>
  )
}
