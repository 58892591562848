import { SEPARATOR } from './constants'
import { splitTextAtPosition, getPrefix, linesToBulletText } from './utils'

interface State {
  text: string
  cursorPos: number
  history: string[]
}

type ActionType = 'ADD_PREFIX' | 'ENTER' | 'SET_TEXT' | 'UNDO'

interface Action<T> {
  type: ActionType
  payload: T
}

interface Add extends Action<{ key: string }> {
  type: 'ADD_PREFIX'
}

interface Enter extends Action<{ shiftKey: boolean; selectionStart: number }> {
  type: 'ENTER'
}

interface SetText extends Action<{ text: string }> {
  type: 'SET_TEXT'
}

interface Undo extends Action<null> {
  type: 'UNDO'
}

type Event = Add | Enter | SetText | Undo

export const initBulletListState = (initValue: string[]) => {
  const text = linesToBulletText(initValue)

  return {
    history: [text],
    text,
    cursorPos: text.length,
  }
}

export const reducer = (state: State, event: Event): State => {
  switch (event.type) {
    case 'ADD_PREFIX': {
      const { key } = event.payload
      const prefix = getPrefix()
      const text = `${prefix}${key}`

      return {
        ...state,
        text,
        cursorPos: prefix.length + key.length,
        history: [...state.history, text],
      }
    }

    case 'ENTER': {
      const { shiftKey, selectionStart } = event.payload
      const { textBefore, textAfter } = splitTextAtPosition(state.text, selectionStart)

      if (shiftKey) {
        // Shift+Enter => plain newline
        const text = textBefore + SEPARATOR + textAfter
        return {
          ...state,
          text,
          cursorPos: selectionStart + 1,
          history: [...state.history, text],
        }
      }

      // Enter => newline + bullet
      const inserted = `${SEPARATOR}${getPrefix()}`
      const text = textBefore + inserted + textAfter

      return {
        ...state,
        text,
        cursorPos: selectionStart + inserted.length,
        history: [...state.history, text],
      }
    }

    case 'UNDO': {
      if (state.history.length === 0) {
        return state
      }

      const history = state.history.slice(0, -1)
      const text = history.at(-1) ?? ''

      return { ...state, history, text }
    }

    case 'SET_TEXT': {
      return {
        ...state,
        text: event.payload.text,
        cursorPos: Math.min(event.payload.text.length, state.cursorPos),
        history: [...state.history, event.payload.text],
      }
    }

    default:
      return state
  }
}
