import { UpdateTypes } from '@src/interfaces/kpis'
import { notReachable } from '@src/utils/notReachable'

export const isMetricRoadmapType = (updateType?: UpdateTypes) => {
  if (!updateType) {
    return false
  }
  switch (updateType) {
    case UpdateTypes.clickup:
    case UpdateTypes.linear:
    case UpdateTypes.notion:
    case UpdateTypes.monday:
    case UpdateTypes.roadmap:
      return true
    case UpdateTypes.sql:
    case UpdateTypes.looker:
    case UpdateTypes.tableau:
    case UpdateTypes.salesforce:
    case UpdateTypes.manual:
      return false

    default:
      return notReachable(updateType)
  }
}
