import { useGlobalSettings } from '@src/api/settings'
import { FieldOptions } from '@src/interfaces'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { useIsCommercialProduct } from '@src/hooks/useIsCommercialProduct'

export const useIsEngagementManager = () => {
  const isEngagementManager = useHasGlobalPermission(
    PermissionTypes.EngagementManagerPermissions,
  )
  return isEngagementManager
}

export const useCanViewEngagementTab = <
  T extends { id: number; field_options: FieldOptions },
>({
  entity,
}: {
  entity: T | undefined
}) => {
  const { settings, isLoading } = useGlobalSettings()
  const isEngagementManager = useIsEngagementManager()
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)

  const hasLocalTabVisibilityPermission = entity?.field_options.permissions?.includes(
    PermissionTypes.ViewEngagementTabs,
  )
  const canView =
    isEngagementDev || isEngagementManager || hasLocalTabVisibilityPermission

  return {
    canView: Boolean(entity?.id && settings.engagement_enabled && canView),
    isLoading,
  }
}

export const useIsEngagementLayoutV2 = ({
  isOrganisationCompanySubtab,
}: {
  isOrganisationCompanySubtab: boolean | undefined
}) => {
  const isCommercial = useIsCommercialProduct()
  const isEngagementV2 = useHasFeatureFlag(FeatureFlags.EngagementV2) // TODO: RHR-6950 remove v1 completely when ready

  if (isCommercial) {
    return isEngagementV2
  }
  // v2 is a default layout for the internal product except "organisation -> company" subtab (RHR-6949)
  return !isOrganisationCompanySubtab
}
