import React from 'react'
import { HStack, Icon, Token, Text, TextStyle } from '@revolut/ui-kit'

import { formatPercentage } from '@src/utils/format'
import { getTrendScoreColor } from '@src/features/Engagement/helpers/statValuesToColor'

interface Props {
  avgScore: number | null | undefined
  trendAvgScore: number | null | undefined
  variant?: 'xs' | 'sm' | 'md'
  colorized?: boolean
  defaultColor?: string
}

const iconSizeMapping = {
  xs: 12,
  sm: 15,
  md: 20,
} as const

const fontSizeMapping: Record<'xs' | 'sm' | 'md', TextStyle> = {
  xs: 'body3',
  sm: 'emphasis1',
  md: 'h4',
} as const

export const TrendIndicator = ({
  avgScore,
  trendAvgScore,
  variant = 'xs',
  colorized,
  defaultColor = Token.color.greyTone50,
}: Props) => {
  if (avgScore == null || trendAvgScore == null) {
    return null
  }
  const trendDiff = avgScore - trendAvgScore
  const trendDiffFormatted = formatPercentage(trendDiff / 10)
  const color = colorized ? getTrendScoreColor(trendDiff) : defaultColor
  const iconProps = { color, size: iconSizeMapping[variant] }

  if (trendDiff === 0 || trendDiffFormatted === '0%') {
    return (
      <HStack space="s-2" align="center">
        <Icon {...iconProps} name="ArrowTopDown" />
        <Text variant={fontSizeMapping[variant]} lineHeight={0} color={color}>
          {trendDiffFormatted}
        </Text>
      </HStack>
    )
  }
  return (
    <HStack space="s-2" align="center">
      <Icon {...iconProps} name={trendDiff > 0 ? 'ArrowRates' : 'ArrowRatesDown'} />
      <Text variant={fontSizeMapping[variant]} lineHeight={0} color={color}>
        {trendDiff > 0 ? `+${trendDiffFormatted}` : trendDiffFormatted}
      </Text>
    </HStack>
  )
}
