import React, { useMemo } from 'react'
import uniqBy from 'lodash/uniqBy'

import { ReviewerRelation, ReviewInterface } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { relationToString } from '@components/ScorecardGeneral/constants'
import { Queries } from '@src/constants/api'
import Filter, { QuickFilter } from './Filter'

interface Props {
  data: ReviewInterface[]
  onFilterChange?: (options: OptionInterface[] | undefined) => void
  prefix?: string
  isClearable?: boolean
  showFilters?: boolean
  className?: string
  labelAll?: string
  hideDropdown?: boolean
  hideAll?: boolean
  additionalFilters?: QuickFilter[]
  additionalOptions?: ReviewInterface[]
  setActiveFilters?: (activeAvatars: QuickFilter[]) => void
  additionalFilter?: React.ReactNode
}

const ReviewersFilter = ({
  data,
  additionalFilters,
  additionalOptions,
  setActiveFilters,
  additionalFilter,
  ...rest
}: Props) => {
  const initialFilter: QuickFilter[] = [
    ...(additionalFilters || []),
    {
      key: ReviewerRelation.Self,
      name: relationToString(ReviewerRelation.Self, true),
      filters: [],
    },
    {
      key: ReviewerRelation.LineManager,
      name: relationToString(ReviewerRelation.LineManager, true),
      filters: [],
    },
    {
      key: ReviewerRelation.FunctionalManager,
      name: relationToString(ReviewerRelation.FunctionalManager, true),
      filters: [],
    },
  ]

  const options = useMemo(() => {
    const adjustedData = additionalOptions ? [...additionalOptions, ...data] : data
    return uniqBy(
      adjustedData.map(review => {
        return {
          relation: relationToString(review.reviewer_relation, true),
          id: review.reviewer.id ?? review.id,
          name: review.reviewer_relation
            ? `${review.reviewer.display_name} (${relationToString(
                review.reviewer_relation,
                true,
              )})`
            : review.reviewer.display_name,
        }
      }),
      ({ id }) => id,
    )
  }, [data])

  const quickFilters = useMemo(() => {
    let peerIndex = 0

    return data.reduce((acc, review) => {
      const relation =
        review.reviewer_relation === ReviewerRelation.TeamMate
          ? ReviewerRelation.Other
          : review.reviewer_relation

      if (relation === ReviewerRelation.Other) {
        peerIndex += 1

        acc.push({
          key: String(review.reviewer.id),
          name: `Peer ${peerIndex}`,
          filters: [review.reviewer.id],
          full_name: review.reviewer.full_name,
          avatar: review.reviewer.avatar,
        })
      } else {
        const index = acc.findIndex(el => el.key === relation)

        if (index >= 0 && !acc[index].filters.includes(review.reviewer.id)) {
          acc[index].filters.push(review.reviewer.id)
          acc[index].avatar = review.reviewer.avatar
          acc[index].full_name = review.reviewer.full_name
        }
      }

      return acc
    }, initialFilter)
  }, [data])

  return (
    <Filter
      hideDropdown
      additionalFilter={additionalFilter}
      setActiveFilters={setActiveFilters}
      options={options}
      quickFilters={quickFilters}
      filterKey={Queries.ReviewerId}
      {...rest}
    />
  )
}

export default ReviewersFilter
