import React from 'react'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { TargetsTableWidget } from '@src/features/Goals/GoalsTab'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'

export const TargetsTable = () => {
  const canViewReviewCycles = useHasGlobalPermission(PermissionTypes.ViewReviewCycles)
  const { data: currentCycle, isLoading } = useGetReviewCycleByOffset(0, {
    enabled: canViewReviewCycles,
  })
  return !isLoading ? <TargetsTableWidget cycle={currentCycle} /> : <PageLoading />
}
