import {
  CommitteeResultInterface,
  ProbationCheckpoint,
} from '@src/interfaces/probationReview'
import {
  ActionButton,
  Flex,
  Icon,
  IconName,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import React from 'react'
import { Statuses } from '@src/interfaces'
import { getProbationResultSettings } from '@src/features/Probation/utils'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import ProbationResult from '@src/components/ProbationResult/ProbationResult'

enum ButtonStates {
  Add = 'Add',
  View = 'View',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Decision',
  [ButtonStates.Edit]: 'Edit Decision',
  [ButtonStates.View]: 'View Decision',
}

const ButtonIcon: { [key: string]: IconName } = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
}

export const CommitteeStepReviewersActions = ({
  checkpoint,
  decision,
  onClick,
}: {
  checkpoint: ProbationCheckpoint
  decision: CommitteeResultInterface | undefined
  onClick: VoidFunction
}) => {
  const user = useSelector(selectUser)
  const userIsReviewer = decision?.reviewer?.id === user.id
  const isCompleted = checkpoint?.can_be_submitted && checkpoint?.decision && decision

  if (isCompleted) {
    return (
      <VStack>
        <Flex gap="s-8" justifyContent="flex-end">
          {userIsReviewer && (
            <TextButton label="Edit review" onClick={onClick}>
              <Icon
                name={ButtonIcon[ButtonStates.Edit]}
                size={16}
                color={Token.color.foreground}
              />
            </TextButton>
          )}
          {decision.result && (
            <ProbationResult result={decision.result} status={Statuses.completed} />
          )}
        </Flex>
        {decision.final_grade && (
          <Text variant="body2" color={Token.color.greyTone50}>
            {decision.final_grade.name}
          </Text>
        )}
      </VStack>
    )
  }

  if (decision && (!userIsReviewer || !checkpoint?.can_be_submitted)) {
    return (
      <Text variant="body1" color={getProbationResultSettings(decision.result).color}>
        {getProbationResultSettings(decision.result).title}
      </Text>
    )
  }

  return (
    <ActionButton useIcon={ButtonIcon[ButtonStates.Add]} onClick={onClick}>
      {ButtonTitles[ButtonStates.Add]}
    </ActionButton>
  )
}
