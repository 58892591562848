import React from 'react'

import { getScopeFilters } from '@src/features/Engagement/helpers/getScopeFilters'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FunctionInterface } from '@src/interfaces/functions'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResults/Published'

export const Engagement = () => {
  const { values } = useLapeContext<FunctionInterface>()

  return (
    <SurveyResultsPublished
      entityId={values.id}
      entityPermissions={values.field_options.permissions}
      scopeFilters={getScopeFilters(values, 'function')}
    />
  )
}
