import React from 'react'
import { chain, Item, ProgressStepState, VStack } from '@revolut/ui-kit'
import Message from '@src/pages/OnboardingChecklist/components/Message'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationTemplateInterface,
  ProbationTemplateTimelineType,
} from '@src/interfaces/probationTemplate'
import { RowInterface } from '@src/interfaces/data'

import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  TimelinePreviewSectionInterface,
  WithTimelinePreview,
} from '@src/pages/OnboardingChecklist/Performance/common'
import { formatToOrdinal } from '@src/utils/format'
import Table from '@src/components/TableV2/Table'
import {
  probationTemplateCountryWithNameColumn,
  probationTemplateDurationColumn,
  probationTemplateEmployeesColumn,
  probationTemplateNoticePeriodColumn,
  probationTemplateEntityNameColumn,
  probationTemplatePolicyColumn,
  probationTemplateStatusColumn,
} from '@src/features/Probation/Templates/columns'

const row: RowInterface<ProbationTemplateInterface> = {
  cells: [
    {
      ...probationTemplateCountryWithNameColumn,
      width: 200,
    },
    { ...probationTemplateEmployeesColumn, width: 150 },
    { ...probationTemplateEntityNameColumn, width: 120 },
    { ...probationTemplateDurationColumn, width: 120 },
    { ...probationTemplateNoticePeriodColumn, width: 120 },
    { ...probationTemplateStatusColumn, width: 80 },
    { ...probationTemplatePolicyColumn, width: 70 },
  ],
}

const generateTimeline = (
  template: ProbationTemplateInterface,
): TimelinePreviewSectionInterface[] => {
  let checkpointsCount = 0
  return template.timelines.map(step => {
    switch (step.timeline_type) {
      case ProbationTemplateTimelineType.goals:
        return {
          label: 'Goal setting',
          description: `${step.weeks_due_date} weeks after joining`,
          state: ProgressStepState.PENDING,
        }
      case ProbationTemplateTimelineType.decision:
        return {
          label: 'Final decision deadline',
          description: `${formatToOrdinal(
            template.total_weeks - step.weeks_due_date,
          )} week`,
          state: ProgressStepState.PENDING,
        }
      default:
        checkpointsCount += 1
        return {
          label: `Checkpoint ${checkpointsCount}`,
          description: `${formatToOrdinal(step.weeks_due_date)} week`,
          state: ProgressStepState.PENDING,
        }
    }
  })
}

export const Preview = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  if (!values.id) {
    return (
      <Item>
        <Item.Content>
          <Item.Title pb="s-8">Please define probation template</Item.Title>
        </Item.Content>
      </Item>
    )
  }

  return (
    <WithTimelinePreview
      title={chain(values.name, `Timeline preview (total ${values.total_weeks} weeks)`)}
      sections={generateTimeline(values)}
    >
      <VStack flex={3} space="s-16">
        <Message
          storageKey="onboarding_probation_preview_message"
          title="We have created the first probation cycle template for you!"
          description="Feel free to take a look and make any adjustments to suit your preferences"
        />

        <Table.Widget>
          <Table.Widget.Table>
            <AdjustableTable<ProbationTemplateInterface>
              name={TableNames.ProbationTemplates}
              useWindowScroll
              row={row}
              data={[values]}
              count={1}
              noDataMessage="Probation templates will appear here."
            />
          </Table.Widget.Table>
        </Table.Widget>
      </VStack>
    </WithTimelinePreview>
  )
}
