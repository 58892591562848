import React from 'react'
import { RoadMapTablesProps, useRenderRoadmapTables } from './table'
import { chain, Flex, Text } from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { AddRoadmapAction } from '@src/pages/Forms/RoadmapForm/common/AddRoadmapMoreBarAction'
import { EntityTypes } from '@src/constants/api'
import { RoadmapActionsBar, useRoadmapRefresh } from '.'
import RoadmapLockedChangesBanner from '../Banners/RoadmapLockedChangesBanner'
import MoreInfoButton from '@src/components/MoreInfoButton/MoreInfoButton'
import { HOW_TO_SETUP_ROADMAP } from '@src/constants/externalLinks'
import { useGetRoadmapSettings } from '@src/api/settings'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { FormattedMessage } from 'react-intl'
import Table from '@src/components/TableV2/Table'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

export interface RoadmapTabProps extends RoadMapTablesProps {
  allowRefresh: boolean
  addRoadmapProps: {
    entityType: EntityTypes
    id: number
    backUrl: string
    name: string
    tags?: string[]
  }
  triggerRefresh: (cycleId?: string | number) => Promise<void>
  tableInfoMessage?: React.ReactNode
  showTableInfo: boolean
  newLayoutNavigation?: React.ReactElement
  serviceDeskUrl?: string
}

export const RoadmapTab = (props: RoadmapTabProps) => {
  const {
    addRoadmapProps,
    triggerRefresh,
    tableInfoMessage,
    allowRefresh,
    showTableInfo,
    newLayoutNavigation,
    serviceDeskUrl,
    ...tableOuterProps
  } = props
  const { allowEdit, reviewCycle } = tableOuterProps
  const { table, changelogSwitcher, changelogState, renderTable } =
    useRenderRoadmapTables(tableOuterProps)

  const { renderRefreshNote, refreshButton, pendingTaskId } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const moreBarPending = !roadmapSettings || !props.reviewCycle

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Flex width="100%" justifyContent="space-between">
          <Flex gap="s-32">
            <Stat
              label={chain(
                'Progress',
                allowRefresh &&
                  renderRefreshNote({
                    lastUpdated: table?.stats?.refresh_date_time,
                    chained: true,
                  }),
              )}
              val={
                table?.stats?.avg_done !== undefined ? (
                  <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                    {formatPercentage(table?.stats?.avg_done)}
                  </Text>
                ) : (
                  'N/A'
                )
              }
            />
          </Flex>
          {!!newLayoutNavigation &&
            (props.tableProps.name === TableNames.EmployeeRoadmap ||
              props.tableProps.name === TableNames.DepartmentRoadmap) &&
            newLayoutNavigation}
        </Flex>
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <RoadmapActionsBar pending={moreBarPending}>
          {serviceDeskUrl && (
            <PrimaryAction useIcon="Plus" href={serviceDeskUrl} use="a" target="_blank">
              Raise an item
            </PrimaryAction>
          )}
          {allowEdit && (
            <AddRoadmapAction
              {...addRoadmapProps}
              onAfterSubmit={() => table.refresh()}
              reviewCycle={reviewCycle || null}
            />
          )}
          {changelogSwitcher}
          {allowRefresh && refreshButton}
          {roadmapSettings?.jira_epics_enabled ? (
            <MoreInfoButton href={HOW_TO_SETUP_ROADMAP} />
          ) : null}
          <Table.ColumnsSettingsButton />
        </RoadmapActionsBar>
      </Table.Widget.Actions>
      <Table.Widget.Status>
        <RoadmapLockedChangesBanner
          hidden={!changelogState.active}
          pb="s-8"
          reviewCycle={reviewCycle || null}
        />
      </Table.Widget.Status>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <CycleFilterButton
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
        />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        {renderTable({
          loading: !!pendingTaskId,
          emptyState: (
            <EmptyTableRaw
              title={
                <FormattedMessage
                  defaultMessage="Your roadmap goes here"
                  id="performance.roadmaps.emptyTable.title"
                />
              }
              description={
                <FormattedMessage
                  defaultMessage="All your epics will appear here"
                  id="performance.roadmaps.emptyTable.description"
                />
              }
              action={
                allowEdit ? (
                  <AddRoadmapAction
                    {...addRoadmapProps}
                    variant="text"
                    onAfterSubmit={() => table.refresh()}
                    reviewCycle={reviewCycle || null}
                  />
                ) : null
              }
            />
          ),
        })}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
