import React from 'react'
import { CellInsertParams, CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ProbationInterface } from '@src/interfaces/probation'
import { selectorKeys } from '@src/constants/api'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { Flex, Icon, Text, Token } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import Table from '@src/components/TableV2/Table'
import { getDefaultStatusVariant } from '@src/components/TableV2/Cells/StatusCell'
import { getSmartGoalStatus } from '@src/features/PIP/utils'

export const pipStartDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.start_date_time',
  dataPoint: 'cycle.start_date_time',
  sortKey: 'cycle__start_date_time',
  filterKey: 'cycle__start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'PIP start date',
}

export const pipEndDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.end_date_time',
  dataPoint: 'cycle.end_date_time',
  sortKey: 'cycle__end_date_time',
  filterKey: 'cycle__end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'PIP end date',
}

export const pipVersionColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.text,
  idPoint: 'version',
  dataPoint: 'version',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Version',
}

export const pipReviewGoalColumn: ColumnInterface<DeliverableInterface> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Goal',
  insert: ({ data }: CellInsertParams<DeliverableInterface>) => {
    return (
      <Flex alignItems="center" gap="s-8">
        <Text>{data.name}</Text>
        {data.description && (
          <Tooltip placement="top" text={data.description}>
            <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
          </Tooltip>
        )}
      </Flex>
    )
  },
}

export const pipReviewDueColumn: ColumnInterface<DeliverableInterface> = {
  type: CellTypes.date,
  idPoint: 'deadline_date_time',
  dataPoint: 'deadline_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Due date',
}

export const pipStatusColumn: ColumnInterface<DeliverableInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_completed',
  dataPoint: 'is_completed',
  sortKey: 'is_completed',
  filterKey: 'is_completed',
  selectorsKey: async () => ({
    options: [
      { id: 'true', name: 'Completed' },
      { id: 'false', name: 'Not completed' },
    ],
  }),
  title: 'Status',
  insert: ({ data }) => {
    const status = getSmartGoalStatus(data)
    return <Table.StatusCell status={status} variant={getDefaultStatusVariant(status)} />
  },
}
