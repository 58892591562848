import { RowHeight } from '@src/interfaces/table'

export const rowWrapperMinHeight: Record<RowHeight, number> = {
  small: 32,
  medium: 48,
  large: 72,
  heatmap: 84,
  'competency-matrix': 124,
}

export const rowSeparatorMinHeight: Record<RowHeight, number> = {
  small: 32,
  medium: 48,
  large: 72,
  heatmap: 84,
  'competency-matrix': 48,
}

export const firstColumnFontSize: Record<RowHeight, number> = {
  small: 14,
  medium: 14,
  large: 16,
  heatmap: 16,
  'competency-matrix': 16,
}

export const rowHeightToWidthMultiplier: Record<RowHeight, number> = {
  small: 0.7,
  medium: 0.85,
  large: 1,
  heatmap: 1,
  'competency-matrix': 1,
}
