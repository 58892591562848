import * as React from 'react'
import { Scorecard } from './Scorecard'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import PageLoading from '@components/PageLoading/PageLoading'
import { Token } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import { selectorKeys } from '@src/constants/api'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { isNumber } from 'lodash'

export const ScorecardPreviewPage = () => {
  const { initialCycleOffset, loading } = useSelectedPerformanceCycle(
    selectorKeys.all_review_cycles,
    true,
  )

  const { data: cycle, isLoading } = useGetReviewCycleByOffset(
    isNumber(initialCycleOffset) ? initialCycleOffset : null,
  )

  if (loading || isLoading) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader title="Setup scorecard" backUrl={ROUTES.PERFORMANCE.REVIEWS_CYCLES}>
        <PageBody maxWidthMd={Token.breakpoint.lg}>
          <Scorecard cycle={cycle} />
        </PageBody>
      </PageHeader>
    </PageWrapper>
  )
}
