import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AnytimeFeedbackInterface } from '@src/interfaces/anytimeFeedback'
import { EmployeeInterface } from '@src/interfaces/employees'
import SideBar from '@components/SideBar/SideBar'
import { AnytimeFeedbackNotes } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/AnytimeFeedbackNotes/AnytimeFeedbackNotes'

interface Props {
  employee: EmployeeInterface
  feedbacks: AnytimeFeedbackInterface[]
  isOpen: boolean
  isLoading: boolean
  onClose: VoidFunction
  onRefresh: VoidFunction
}

export const AnytimeFeedbackNotesSidebar = ({
  employee,
  feedbacks,
  isOpen,
  isLoading,
  onClose,
  onRefresh,
}: Props) => {
  return (
    <SideBar
      isOpen={isOpen}
      variant="wide"
      title={
        <FormattedMessage
          id="one-to-one.anytime.feedback.title"
          defaultMessage="Old 1:1 notes"
        />
      }
      usePortal
      onClose={onClose}
    >
      <AnytimeFeedbackNotes
        employee={employee}
        feedbacks={feedbacks}
        isLoading={isLoading}
        onRefresh={onRefresh}
      />
    </SideBar>
  )
}
