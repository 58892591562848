import React from 'react'
import { useExtensionData } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useExtensionData'
import {
  AvailabilityStatus,
  useExtensionAvailability,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/hooks/useExtensionAvailability'
import { ActionButton, Avatar, Item } from '@revolut/ui-kit'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

export const MeetingExtensionInterviewTranscriptBanner = () => {
  const { isChecking, status } = useExtensionAvailability()
  const { extensionLink } = useExtensionData()

  const hasAIInterviewSummarisationInternal = useHasFeatureFlag(
    FeatureFlags.AIInterviewSummarisationInternal,
  )

  if (
    !hasAIInterviewSummarisationInternal ||
    isChecking ||
    status === AvailabilityStatus.Installed
  ) {
    return null
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar image="https://assets.revolut.com/assets/apps/Google.png" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>Get the Revolut Meeting Chrome extension</Item.Title>
        <Item.Description>
          Download our AI powered extension to generate interview transcripts and
          summaries with ease
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <ActionButton
            use="a"
            target="_blank"
            rel="noreferrer noopener"
            href={extensionLink}
          >
            Install extension
          </ActionButton>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
