import { apiV2 } from './index'
import { FeedbackInterface, FeedbackSubmitInterface } from '../store/feedback/types'
import { useFetchV2 } from '@src/utils/reactQuery'
import { GetRequestInterface } from '@src/interfaces'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'

export const postFeedbackRequest = (data: FeedbackSubmitInterface) => {
  return apiV2.post<FeedbackInterface>('/users/feedback', data)
}

export const usePostFeedbackRequest = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<FeedbackInterface>,
    AxiosError,
    FeedbackSubmitInterface
  >(data => postFeedbackRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('/users/myFeedback', { refetchActive: true })
    },
  })
}

export const useGetMyFeedbacks = (filters: FilterByInterface<FilterOption>[]) => {
  return useFetchV2<GetRequestInterface<FeedbackInterface>>({
    url: '/users/myFeedback',
    params: {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  })
}
