import React from 'react'
import { Box, Flex, InputGroup, Widget, HStack } from '@revolut/ui-kit'
import produce from 'immer'
import { get, set } from 'lodash'

import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { QuestionContent } from './QuestionContent'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import {
  PerformanceScorecardOption,
  PerformanceScorecardQuestionType,
  PerformanceSettingsInterface,
} from '@src/interfaces/settings'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { SectionControls } from './SectionControls'

type EditQuestionProps = {
  sectionIdx: number
  questionIdx: number
}

export const EditQuestion = ({ sectionIdx, questionIdx }: EditQuestionProps) => {
  const { values, errors } = useLapeContext<PerformanceSettingsInterface>()

  const { data: questionTypeOptions, isLoading: isQuestionTypesLoading } =
    useGetSelectors<PerformanceScorecardQuestionType>(
      selectorKeys.custom_scorecard_question_types,
    )

  const question = values.custom_scorecard_sections![sectionIdx]?.questions[questionIdx]
  const questionTypeField = `custom_scorecard_sections.${sectionIdx}.questions.${questionIdx}.question_type`

  const onChangeQuestions = (options: PerformanceScorecardOption[]) => {
    // to be able to track changes
    values.custom_scorecard_sections = produce(
      values.custom_scorecard_sections!,
      draft => {
        draft[sectionIdx].questions[questionIdx].options = options
      },
    )
  }

  const onChangeType = (option: PerformanceScorecardQuestionType) => {
    set(errors, questionTypeField, undefined)

    if (option.id === 'checkbox' || option.id === 'option') {
      question.options = []
    } else {
      question.options = undefined
    }
    question.placeholder_text = option.id === 'dropdown' ? 'Select one' : undefined
    question.question_type = option
  }

  const handleDelete = () => {
    if (values.custom_scorecard_sections?.[sectionIdx]) {
      values.custom_scorecard_sections[sectionIdx].questions =
        values.custom_scorecard_sections![sectionIdx].questions.filter(
          (_, i) => i !== questionIdx,
        )
    }
  }

  const handleCopy = () => {
    values.custom_scorecard_sections?.[sectionIdx].questions.splice(questionIdx + 1, 0, {
      ...question,
      options: question.options?.map(option => ({ ...option, id: undefined })),
      id: undefined,
    })
  }

  return (
    <Widget p="s-16" mt="s-16" data-testid="custom-question">
      <Flex justifyContent="space-between" alignItems="center" mb="s-16">
        <Flex>
          <HStack space="s-8">
            <DropdownButton
              options={questionTypeOptions || []}
              onSelect={onChangeType}
              pending={isQuestionTypesLoading}
              variant={get(errors, questionTypeField) ? 'negative' : undefined}
              data-name={questionTypeField}
            >
              {question.question_type?.name || 'Select type'}
            </DropdownButton>
            <SwitchButton
              checked={question.optional}
              onClick={() => {
                question.optional = !question.optional
              }}
              useMoreBar={false}
            >
              Optional
            </SwitchButton>
          </HStack>
        </Flex>

        <SectionControls
          id={questionIdx}
          onDelete={handleDelete}
          onCopy={handleCopy}
          sections={values.custom_scorecard_sections?.[sectionIdx].questions}
        />
      </Flex>

      <Box mb="s-16">
        <InputGroup>
          <LapeNewInput
            label="Question"
            name={`custom_scorecard_sections.${sectionIdx}.questions.${questionIdx}.title`}
            required
          />
          <LapeNewTextArea
            label="Subtitle"
            name={`custom_scorecard_sections.${sectionIdx}.questions.${questionIdx}.subtitle`}
            rows={1}
            maxRows={5}
          />
        </InputGroup>
      </Box>

      <QuestionContent
        options={question?.options}
        placeholder={question?.placeholder_text}
        type={question.question_type?.id}
        onChangePlaceholder={val => {
          if (values.custom_scorecard_sections?.[sectionIdx]) {
            values.custom_scorecard_sections[sectionIdx].questions[
              questionIdx
            ].placeholder_text = val
          }
        }}
        onChangeQuestions={onChangeQuestions}
        optionsKey={`custom_scorecard_sections.${sectionIdx}.questions.${questionIdx}.options`}
      />
    </Widget>
  )
}
