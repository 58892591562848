import { EmployeeInterface } from '@src/interfaces/employees'
import React, { useState } from 'react'
import { tabsCategory } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import {
  AnytimeFeedbackListInterface,
  FeedbackCategory,
} from '@src/interfaces/anytimeFeedback'
import { Box, ErrorWidget, Flex, TabBar, ToggleButton, Token } from '@revolut/ui-kit'
import { AnytimeFeedbackCard } from '@components/AnytimeFeedback/AnytimeFeedbackCard'
import styled from 'styled-components'

// TODO: https://revolut.atlassian.net/browse/REVC-6878 check design before merging last task on redesign
const CustomTabBarItem = styled(TabBar.Item)`
  &[aria-selected='true'] {
    background-color: ${Token.color.greyTone8};
  }
`

interface Props {
  data: EmployeeInterface
  performanceContent?: React.ReactNode
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
  initialTabId?: tabsCategory
  isTabBar?: boolean
}

export const FeedbackSection = ({
  data,
  isTabBar = false,
  performanceContent,
  feedback,
  refetchFeedbackList,
  initialTabId = tabsCategory.Performance,
}: Props) => {
  const [currentTabId, setCurrentTabId] = useState<tabsCategory | null>(initialTabId)
  const feedbackList = feedback?.results || []

  const tabs = [
    {
      title: 'Performance review',
      id: tabsCategory.Performance,
      list: [],
      type: FeedbackCategory.Performance,
    },
    {
      title: 'Anytime',
      id: tabsCategory.Anytime,
      list: feedbackList.filter(item => item.category === FeedbackCategory.Anytime),
      type: FeedbackCategory.Anytime,
    },
  ]

  const currentTab = tabs.find(tab => tab.id === currentTabId)
  const isPerformance = currentTab?.type === FeedbackCategory.Performance
  const hasPerformanceContent = isPerformance && !!performanceContent

  return (
    <Box>
      <Box mb="s-16">
        <Flex gap="s-8">
          {isTabBar ? (
            <TabBar onChange={selected => setCurrentTabId(selected)} value={currentTabId}>
              {tabs.map(tab => (
                <CustomTabBarItem key={tab.id} to={tab.id}>
                  {tab.title}
                </CustomTabBarItem>
              ))}
            </TabBar>
          ) : (
            tabs.map(tab => (
              <ToggleButton
                key={tab.id}
                onClick={() => setCurrentTabId(tab.id)}
                checked={currentTab?.id === tab.id}
              >
                {tab.title}
              </ToggleButton>
            ))
          )}
        </Flex>
      </Box>
      {isPerformance && performanceContent}
      {currentTab?.list.map(item => (
        <AnytimeFeedbackCard
          key={item.id}
          feedback={item}
          refreshFeedbackCard={refetchFeedbackList}
          employeeData={data}
          type={currentTab.type}
          withBorder
        />
      ))}
      {!hasPerformanceContent && !currentTab?.list.length && (
        <ErrorWidget>
          <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images-v2/3D023.png" />
          <ErrorWidget.Title>Your feedback will appear here</ErrorWidget.Title>
        </ErrorWidget>
      )}
    </Box>
  )
}
