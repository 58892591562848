import React from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  deliverablesRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import {
  ProbationDeliverablesRatings,
  ProbationDeliverables,
} from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'
import { getViewGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { Box, VStack, Widget } from '@revolut/ui-kit'
import { CardHeader } from '../../EmployeePerformanceLayout/CardHeader'
import ExpandableText from '@components/ExpandableText/ExpandableText'
import { relationToString } from '@src/components/ScorecardGeneral/constants'

// FYI It's called "Probation" but in reality it's also used in PIP  https://revolut.atlassian.net/browse/REVC-8586
export const ProbationGoalsCardView = connect(({ gradesMap }: CommonCardProps) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()

  const filteredComments =
    values.summary?.calculated_deliverables_ratings?.recommended_rating_comment?.ratings?.filter(
      comment => comment.value,
    ) ?? []

  const justificationComments = (
    <ExpandableText
      list={filteredComments.map(comment => {
        return {
          title: comment.review.reviewer.full_name,
          additionalInfo:
            relationToString(comment.review.reviewer_relation, true) || undefined,
          items: comment.value ? [{ text: comment.value }] : [],
        }
      })}
    />
  )

  return (
    <Widget p="s-16">
      <CardHeader data={values} title="Goals" icon="Target" />
      <VStack space="s-16">
        <Box mt="s-16">
          <ProbationDeliverables
            team={values.team}
            reviewData={values.summary}
            category={values.reviews[0]?.category}
            cycleId={values.reviews[0]?.performance_checkpoint?.cycle_id}
            checkpointNum={values.reviews[0]?.probation_checkpoint?.number}
            gradesMap={gradesMap}
          />
        </Box>
        <Card
          variant="content"
          data={values}
          renderExpandedContent={() => (
            <>
              <ProbationDeliverablesRatings
                reviewData={values.summary}
                gradesMap={gradesMap}
                isViewMode
              />
              <Box mt="s-16">{justificationComments}</Box>
            </>
          )}
          renderExceedingContent={() => <Box mt="s-16">{justificationComments}</Box>}
          type={CardContentTypes.DELIVERABLES}
          fields={[
            {
              field: 'summary.calculated_deliverables_ratings.recommended_rating.rating',
              title: 'Goal performance',
              grades: getViewGradesWithExpectations(deliverablesRating),
              cardJustification:
                values?.summary?.calculated_deliverables_ratings?.recommended_rating
                  ?.rating ?? null,
            },
          ]}
          isViewMode
        />
      </VStack>
    </Widget>
  )
})
