import React, { useState } from 'react'
import { Popup } from '@revolut/ui-kit'
import { MeetingEvent, OneToOneMeeting } from '@src/interfaces/meetings'
import { useMeetingEvents } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/useMeetingEvents'
import { SelectEventBody } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/SelectEventBody'
import {
  useGetOneToOneMeetingManagerTemplate,
  useLinkOneToOneMeeting,
} from '@src/api/meetings'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ConfirmEventBody } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/ConfirmEventBody'
import { useScheduleStatusPopup } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useScheduleStatusPopup'

interface Props {
  isOpen: boolean
  meeting: OneToOneMeeting
  handleCompleteScheduling: VoidFunction
  handleClose: VoidFunction
}

export const SchedulePopup = ({
  isOpen,
  meeting,
  handleCompleteScheduling,
  handleClose,
}: Props) => {
  const { events, isLoading: isEventsLoading } = useMeetingEvents(meeting)
  const { data: template, isLoading: isTemplateLoading } =
    useGetOneToOneMeetingManagerTemplate(meeting.manager.id)
  const { mutateAsync: linkOneToOne, isLoading: isUpdating } = useLinkOneToOneMeeting(
    meeting.employee.id,
  )

  const [selectedEvent, setSelectedEvent] = useState<MeetingEvent>()

  const handleSelectEvent = (event: MeetingEvent) => {
    setSelectedEvent(event)
  }

  const handleCreateEvent = () => {
    navigateTo(
      pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.SCHEDULE,
        { employeeId: meeting.employee.id, meetingId: meeting.id },
      ),
    )
  }

  const { showSuccessPopup, showErrorPopup } = useScheduleStatusPopup(
    handleCompleteScheduling,
  )

  const handleScheduleEvent = async () => {
    if (!selectedEvent) {
      return
    }

    try {
      await linkOneToOne([
        meeting.id,
        {
          meeting_series: { id: selectedEvent.id },
        },
      ])
      showSuccessPopup(selectedEvent)
      handleClose()
    } catch (err) {
      showErrorPopup(err)
    } finally {
      setSelectedEvent(undefined)
    }
  }

  return (
    <Popup size="sm" open={isOpen} onClose={handleClose}>
      {selectedEvent ? (
        <ConfirmEventBody
          meeting={meeting}
          isPending={isUpdating}
          handleConfirm={handleScheduleEvent}
          handleCancel={() => setSelectedEvent(undefined)}
        />
      ) : (
        <SelectEventBody
          meeting={meeting}
          events={events}
          template={template}
          isLoading={isEventsLoading || isTemplateLoading}
          handleSelectEvent={handleSelectEvent}
          handleCreateEvent={handleCreateEvent}
          handleCancel={handleClose}
        />
      )}
    </Popup>
  )
}
