import React, { useEffect, useState } from 'react'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import { Button, Flex, Header, Popup, Text, Token } from '@revolut/ui-kit'

import OpenInNewTab from '@components/OpenInNewTab/OpenInNewTab'
import { checkEmployeeWorkAndCompensationConflicts } from '@src/api/employees'
import { DatePickerInputProps } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { ROUTES } from '@src/constants/routes'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeWorkAndOrgDetailsInterface } from '@src/interfaces/employees'
import { difference } from '@src/utils/form'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'

interface EffectiveDatePopupInterface {
  datePickerProps?: DatePickerInputProps
  onClose: VoidFunction
  onSaveChanges: () => Promise<void>
}

interface ConflictsInterface {
  effective_date_time: string
  field_name: string
  id: number
  status: string
}

interface ViewConflictedChangesLinkInterface {
  conflicts: ConflictsInterface[]
  id: number
  text: string
}

const ViewConflictedChangesLink = ({
  conflicts,
  id,
  text,
}: ViewConflictedChangesLinkInterface) => {
  const filterByConflictId = conflicts.map(conflict => conflict.id).join(',')

  return (
    <>
      <Text color={Token.color.red} mt="s-16" use="div">
        {text}
      </Text>
      <OpenInNewTab
        label="View Conflicted Changes"
        link={pathToFullUrlWithActiveWorkspace(
          ROUTES.FORMS.EMPLOYEE.CHANGELOG.COMPENSATION,
          { id },
          { id: filterByConflictId },
        )}
        noIcon
      />
    </>
  )
}

export const EffectiveDatePopup = ({
  datePickerProps,
  onClose,
  onSaveChanges,
}: EffectiveDatePopupInterface) => {
  const showErrorPopup = useErrorPopup()
  const formValidator = useFormValidator()
  const { initialValues, isSubmitting, values } =
    useLapeContext<EmployeeWorkAndOrgDetailsInterface>()
  const [checkingConflicts, setCheckingConflicts] = useState(true)
  const [conflicts, setConflicts] = useState<ConflictsInterface[]>([])

  const willTakeEffectIn = values.effective_date_time
    ? formatDistanceToNow(new Date(values.effective_date_time), { addSuffix: true })
    : 'now'

  const checkFutureConflicts = async () => {
    try {
      const data: Partial<EmployeeWorkAndOrgDetailsInterface> = {
        ...difference(values, initialValues),
      }
      if (values.effective_date_time) {
        data.effective_date_time = values.effective_date_time
      }
      const result = await checkEmployeeWorkAndCompensationConflicts(data, values.id)

      if (result.data) {
        setConflicts(result.data)
      }
    } catch (error) {
      showErrorPopup.show({
        error,
        fallbackTitle: `Failed to check conflicts`,
      })
    } finally {
      setCheckingConflicts(false)
    }
  }

  useEffect(() => {
    checkFutureConflicts()
  }, [])

  if (checkingConflicts) {
    return null
  }

  return (
    <Popup open onClose={onClose} variant="bottom-sheet">
      <Header variant="item">
        <Header.Title>Set effective date for changes</Header.Title>
        <Header.Description>
          You can set a date from which the changes you have made will be made active.
          <br />
          By default today’s date is set as the effective date.
        </Header.Description>
      </Header>
      <LapeDatePickerInput
        hideOptional
        label="Effective date"
        message={`Take effect ${willTakeEffectIn}`}
        name="effective_date_time"
        onAfterChange={checkFutureConflicts}
        {...datePickerProps}
      />

      {conflicts.length !== 0 &&
        values.effective_date_time &&
        isFuture(new Date(values.effective_date_time)) && (
          <ViewConflictedChangesLink
            conflicts={conflicts}
            id={values.employee.id}
            text="There are already scheduled changes for the fields that are being changed. Please cancel those changes before scheduling another:"
          />
        )}

      {conflicts.length !== 0 &&
        (!values.effective_date_time || isPast(new Date(values.effective_date_time))) && (
          <ViewConflictedChangesLink
            conflicts={conflicts}
            id={values.employee.id}
            text="There are changes that intersect with your change, please review them! Clicking submit will overwrite all intersected changes in the past:"
          />
        )}

      <Flex gap="s-16" mt="s-16">
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={
            !!conflicts.length &&
            !!values.effective_date_time &&
            isFuture(new Date(values.effective_date_time))
          }
          onClick={formValidator ? formValidator.validate(onSaveChanges) : undefined}
          pending={isSubmitting}
        >
          Save changes
        </Button>
      </Flex>
    </Popup>
  )
}
