import { captureException } from '@sentry/react'
import { createDraftGoal } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { useState } from 'react'

export const useCreateGoal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const errorPopup = useErrorPopup()
  const { navigateWithEntity } = useOrgEntity()
  const { data: contentTypes } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )

  const createGoal = async ({
    employeeId,
    cycleId,
  }: {
    employeeId: number
    cycleId: number | string
  }) => {
    try {
      setIsLoading(true)
      const response = await createDraftGoal({
        owner: { id: employeeId },
        content_type: contentTypes?.find(({ model }) => model === 'employees'),
        object_id: employeeId,
      })
      navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
        reviewCycleId: cycleId,
        isNew: true,
      })
    } catch (error) {
      captureException(error)

      errorPopup.show({
        error,
        fallbackTitle: 'Failed to create goal',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, createGoal }
}
