import { IconName } from '@revolut/ui-kit'
import { SkillRatingExpectation } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { SkillRatingsDistributionGroupTypes } from '@src/features/ReviewCycle/Analytics/api/skills'

export const getRatingExpectationIconName = (
  expectation: SkillRatingExpectation,
): IconName => skillRatingExpectationToIconNameMap[expectation]

export const skillRatingExpectationToIconNameMap: Record<
  SkillRatingExpectation,
  IconName
> = {
  below_expectations: 'ArrowDown',
  meeting_expectations: 'Dot',
  above_expectations: 'ArrowUp',
}

export const defaultGroupType = SkillRatingsDistributionGroupTypes.Benchmark
