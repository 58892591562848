import React, { createContext, useContext, useMemo, useState } from 'react'

import { useAvailableSubscriptionPlans } from '@src/api/plans'
import {
  AvailableSubscriptionPlanInterface,
  AvailableSubscriptionPlanInterfaceV2,
  MerchantApiConfigInterface,
  MerchantApiOrder,
} from '@src/interfaces/plans'
import { CheckoutWidgetType } from './CheckoutWidget'

export type OrderAndConfig = {
  order: MerchantApiOrder
  config: MerchantApiConfigInterface
  type: CheckoutWidgetType
}

export interface SubscriptionPlanContextInterface {
  selectedPlan: AvailableSubscriptionPlanInterface | undefined
  setSelectedPlan: React.Dispatch<
    React.SetStateAction<AvailableSubscriptionPlanInterface | undefined>
  >
  orderAndConfig: OrderAndConfig | undefined
  setOrderAndConfig: React.Dispatch<React.SetStateAction<OrderAndConfig | undefined>>
}

export interface SubscriptionPlanContextInterfaceV2 {
  selectedPlan: AvailableSubscriptionPlanInterfaceV2 | undefined
  setSelectedPlan: React.Dispatch<
    React.SetStateAction<AvailableSubscriptionPlanInterfaceV2 | undefined>
  >
  orderAndConfig: OrderAndConfig | undefined
  setOrderAndConfig: React.Dispatch<React.SetStateAction<OrderAndConfig | undefined>>
}

const SubscriptionPlanContext = createContext<SubscriptionPlanContextInterface | null>(
  null,
)

const SubscriptionPlanContextV2 =
  createContext<SubscriptionPlanContextInterfaceV2 | null>(null)

export const useSubscriptionPlanContext = () => {
  const context = useContext(SubscriptionPlanContext)

  if (context == null) {
    throw new Error(
      `useSubscriptionPlanContext must be used within a SubscriptionPlanProvider`,
    )
  }
  return context
}

export const useSubscriptionPlanContextV2 = () => {
  const context = useContext(SubscriptionPlanContextV2)

  if (context == null) {
    throw new Error(
      `useSubscriptionPlanContextV2 must be used within a SubscriptionPlanProviderV2`,
    )
  }
  return context
}

export const SubscriptionPlanProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const { plans } = useAvailableSubscriptionPlans()

  const [selectedPlan, setSelectedPlan] = useState<AvailableSubscriptionPlanInterface>()
  const [orderAndConfig, setOrderAndConfig] = useState<OrderAndConfig>()

  const contextValue = useMemo(
    () => ({ selectedPlan, setSelectedPlan, orderAndConfig, setOrderAndConfig }),
    [selectedPlan, setSelectedPlan, orderAndConfig, setOrderAndConfig],
  )

  if (plans?.length && !selectedPlan) {
    setSelectedPlan(plans[0])
  }

  return (
    <SubscriptionPlanContext.Provider value={contextValue}>
      {children}
    </SubscriptionPlanContext.Provider>
  )
}

export const SubscriptionPlanProviderV2: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [selectedPlan, setSelectedPlan] = useState<AvailableSubscriptionPlanInterfaceV2>()
  const [orderAndConfig, setOrderAndConfig] = useState<OrderAndConfig>()

  const contextValue = useMemo(
    () => ({ selectedPlan, setSelectedPlan, orderAndConfig, setOrderAndConfig }),
    [selectedPlan, setSelectedPlan, orderAndConfig, setOrderAndConfig],
  )

  return (
    <SubscriptionPlanContextV2.Provider value={contextValue}>
      {children}
    </SubscriptionPlanContextV2.Provider>
  )
}
