import { KeyboardEvent, useEffect, useReducer, useRef } from 'react'
import { reducer, initBulletListState } from './useBulletListActions'
import { bulletTextToLines, isPrintableKey } from './utils'

interface Options {
  initialValue: string[]
  onChange?: (newLines: string[] | undefined) => void
}

// TODO: should be refactored  https://revolut.atlassian.net/browse/REVC-8451
export const useBulletListTextArea = ({ initialValue = [], onChange }: Options) => {
  const [state, dispatch] = useReducer(reducer, initialValue, initBulletListState)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) =>
    dispatch({ type: 'SET_TEXT', payload: { text: e.currentTarget.value } })

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.metaKey && e.key === 'z') || (e.ctrlKey && e.key === 'z')) {
      e.preventDefault()
      dispatch({
        type: 'UNDO',
        payload: null,
      })
      return
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      dispatch({
        type: 'ENTER',
        payload: { shiftKey: e.shiftKey, selectionStart: e.currentTarget.selectionStart },
      })
      return
    }

    if (isPrintableKey(e) && state.text.length === 0) {
      e.preventDefault()
      dispatch({
        type: 'ADD_PREFIX',
        payload: { key: e.key },
      })
    }
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      if (textAreaRef.current) {
        textAreaRef.current.setSelectionRange(state.cursorPos, state.cursorPos)
      }
    })
  }, [state.cursorPos])

  useEffect(() => {
    onChange?.(bulletTextToLines(state.text))
  }, [state.text])

  return {
    displayText: state.text,
    textAreaRef,
    handleChange,
    handleKeyDown,
  }
}
