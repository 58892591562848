import { ManagerRecommendationInterface } from '@src/interfaces/performance'
import { ProbationAndPipBarRaiserSection } from '../types'

export const parseSectionDataByCheckpointId = (
  sections: ManagerRecommendationInterface[],
): ProbationAndPipBarRaiserSection[][] => {
  const groupedData: Record<number, Record<string, ProbationAndPipBarRaiserSection>> = {}

  sections.forEach(review => {
    const {
      checkpoint_number,
      relation,
      recommendation,
      reviewer,
      employee_project_performance,
    } = review

    if (!groupedData[checkpoint_number || 0]) {
      groupedData[checkpoint_number || 0] = {}
    }

    recommendation?.forEach(({ title, value }) => {
      if (!groupedData[checkpoint_number || 0][title]) {
        groupedData[checkpoint_number || 0][title] = {
          checkpointNumber: checkpoint_number || 0,
          question: title,
          line_manager: { response: null, employee: null, finalGrade: null },
          functional_manager: { response: null, employee: null, finalGrade: null },
        }
      }

      if (relation === 'line_manager' || relation === 'functional_manager') {
        groupedData[checkpoint_number || 0][title][relation] = {
          response: value,
          finalGrade: null,
          employee: reviewer || null,
        }
      }
    })
    if (
      employee_project_performance.name &&
      (relation === 'line_manager' || relation === 'functional_manager')
    ) {
      if (!groupedData[checkpoint_number || 0][employee_project_performance.name]) {
        groupedData[checkpoint_number || 0][employee_project_performance.name] = {
          checkpointNumber: checkpoint_number || 0,
          question: employee_project_performance.name,
          line_manager: { response: null, employee: null, finalGrade: null },
          functional_manager: { response: null, employee: null, finalGrade: null },
        }
      }

      if (
        groupedData[checkpoint_number || 0][employee_project_performance.name][relation]
      ) {
        groupedData[checkpoint_number || 0][employee_project_performance.name][relation] =
          {
            finalGrade: employee_project_performance.value,
            response: null,
            employee: reviewer || null,
          }
      }
    }
  })

  return Object.values(groupedData).map(checkpoint => Object.values(checkpoint))
}
