import React, { useMemo, useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import { refreshRoadmap, RoadmapFilterKeys } from '@src/api/roadmaps'
import { EntityTypes } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { Flex, TextSkeleton } from '@revolut/ui-kit'
import { TabBar } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import RoadmapDeadlineBanner from '@src/features/Banners/RoadmapDeadlineBanner'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { PermissionTypes } from '@src/store/auth/types'
import { readOnlyRows } from './common'
import { useGetRoadmapSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { RoadmapTab } from '@src/features/Roadmaps/RoadmapTab'
import { commonRoadmapSorting } from '@src/features/Roadmaps/constants'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

interface RoadmapProps {
  data: EmployeeInterface
}

export const getRoadmapFilters = (data: EmployeeInterface, type: RoadmapType) => [
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName:
      type === 'personal' ? RoadmapFilterKeys.EmployeeId : RoadmapFilterKeys.OwnerId,
    nonResettable: true,
  },
  {
    filters: [{ name: `0`, id: 0 }],
    columnName: RoadmapFilterKeys.ReviewCycleOffset,
    nonResettable: true,
  },
]

type RoadmapType = 'personal' | 'teamAndDepartment'

export const Roadmap = ({ data }: RoadmapProps) => {
  const { query } = useQuery()
  const [tab, setTab] = useState<RoadmapType>('personal')
  const { data: roadmapSettings } = useGetRoadmapSettings()

  const canViewReviewCycles = useHasGlobalPermission(PermissionTypes.ViewReviewCycles)

  const cycleOffset = query.review_cycle__offset || 0
  const { data: reviewCycle } = useGetReviewCycleByOffset(cycleOffset, {
    enabled: canViewReviewCycles,
  })
  const triggerRefresh = async () => {
    await refreshRoadmap(
      `EMPLOYEE-${data.id}`,
      !isUndefined(reviewCycle) ? { id: Number(reviewCycle.id) } : undefined,
    )
  }
  const tableInfoMessage = useMemo<React.ReactNode>(() => {
    if (!roadmapSettings) {
      return <TextSkeleton />
    }
    if (tab === 'teamAndDepartment') {
      return roadmapSettings.jira_epics_enabled
        ? 'The Jira items shown above are team or department roadmap items where the employee is the assignee.'
        : 'The roadmaps shown above are team or department items where the employee is the assignee.'
    }

    return roadmapSettings.jira_epics_enabled
      ? 'Use the "add roadmap" button above to add epics that you want to display here. Epics labelled in Jira will also be shown.'
      : 'Use the button above to add roadmap items you want to track for this cycle.'
  }, [roadmapSettings?.jira_epics_enabled, tab])

  const allowEdit =
    !!data.field_options.permissions?.includes(PermissionTypes.CanCreateRoadmaps) &&
    tab === 'personal'

  const navigation = (
    <Flex mb="s-16">
      <TabBar variant="segmented fit">
        <TabBar.Item
          onClick={() => setTab('personal')}
          aria-selected={tab === 'personal'}
        >
          Personal
        </TabBar.Item>
        <TabBar.Item
          onClick={() => setTab('teamAndDepartment')}
          aria-selected={tab === 'teamAndDepartment'}
        >
          Relevant
        </TabBar.Item>
      </TabBar>
    </Flex>
  )

  return (
    <>
      <RoadmapDeadlineBanner reviewCycle={reviewCycle || null} mt="s-8" mb="s-12" />
      <RoadmapTab
        allowRefresh={tab === 'personal'}
        row={readOnlyRows}
        tableProps={{ name: TableNames.EmployeeRoadmap }}
        key={tab}
        reviewCycle={reviewCycle}
        defaultFilters={getRoadmapFilters(data, tab)}
        defaultSorting={commonRoadmapSorting}
        triggerRefresh={triggerRefresh}
        allowEdit={allowEdit}
        addRoadmapProps={{
          entityType: EntityTypes.employee,
          id: data.id,
          backUrl: pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, { id: data.id }),
          name: data.display_name,
        }}
        tableInfoMessage={tableInfoMessage}
        showTableInfo={tab === 'teamAndDepartment' || allowEdit}
        newLayoutNavigation={navigation}
      />
    </>
  )
}
