import React, { useState } from 'react'
import {
  Avatar,
  Button,
  ColorSelector,
  Grid,
  Header,
  Icon,
  IconName,
  Item,
  Popup,
  RadioGroup,
  Token,
  Widget,
} from '@revolut/ui-kit'

interface TabIconPickerProps {
  onChange: (icon: IconName) => void
  initialValue: IconName
}

export const TabIconPicker = ({ onChange, initialValue }: TabIconPickerProps) => {
  const [selectedIcon, setSelectedIcon] = useState<IconName>(initialValue)
  const [popupOpen, setPopupOpen] = useState(false)

  return (
    <>
      <Item
        use="button"
        type="button"
        variant="disclosure"
        useIcon={<Avatar useIcon={initialValue} />}
        onClick={() => setPopupOpen(true)}
      >
        <Item.Content>
          <Item.Title>Tab icon</Item.Title>
        </Item.Content>
      </Item>

      <Popup variant="bottom-sheet" open={popupOpen} onClose={() => setPopupOpen(false)}>
        <Header variant="bottom-sheet">
          <Header.Title data-autofocus="true">Select icon</Header.Title>
        </Header>

        <Widget p="s-16">
          <RadioGroup<IconName>
            onChange={icon => {
              if (icon) {
                setSelectedIcon(icon)
              }
            }}
            value={selectedIcon}
          >
            {group => (
              <Grid columns={{ all: 4 }} css={{ justifyItems: 'center' }} gap="s-12">
                {[...BASE_ICONS, ...EXTRA_ICONS].map(icon => (
                  <ColorSelector
                    bg={Token.color.actionBackground}
                    {...group.getInputProps({ value: icon })}
                    key={icon}
                    size={56}
                    aria-label={icon}
                  >
                    <Icon name={icon} size={24} color={Token.color.accent} />
                  </ColorSelector>
                ))}
              </Grid>
            )}
          </RadioGroup>
        </Widget>

        <Popup.Actions horizontal>
          <Button onClick={() => setPopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onChange(selectedIcon)
              setPopupOpen(false)
            }}
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

/** Avoid removing any icons from `BASE_ICONS`, they are the ones used for tabs in the internal product, and as defaults for tabs hardcoded on BE */
const BASE_ICONS: IconName[] = [
  '16/ChartBarWithArrow',
  'Target',
  'Map',
  'BarChart',
  'Link',
  'Gift',
  'People',
  'AddContact',
  'Bank',
  'Bug',
  'Form',
  'ChangePasscode',
  'ArrowsSort',
  'Heart',
]

const EXTRA_ICONS: IconName[] = [
  'Profile',
  'RepairTool',
  'Laptop',
  'Lightning',
  'Coffee',
  'IndustrialGear',
  'Rocket',
  'Document',
  'Travel',
  'CreditBag',
  'Pencil',
  'Controls',
  'Apps',
  'Palette',
  'SocialLike',
  'SocialDislike',
  'Lounges',
  'Utilities',
]
