import {
  HStack,
  VStack,
  Text,
  Box,
  Link,
  Icon,
  Bar,
  Token,
  VisuallyHidden,
  ActionButton,
} from '@revolut/ui-kit'
import { testSqlQuery, useGetTestQueryStatus } from '@src/api/kpis'
import LapeNewCodeEditor, {
  LapeCodeEditorProps,
} from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { KPI_SQL_QUERY_URL } from '@src/constants/externalLinks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import KPIDataBaseSelection, {
  QueryDB,
} from '@src/features/FormTabs/Kpi/KPIDataBaseSelection'
import { QueryTestingTable } from '@src/features/FormTabs/Kpi/QueryTestingTable'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { connect } from 'lape'
import React, { useEffect, useState } from 'react'
import { tryToMakeEmbedLookerUrl } from '../GoalMetricForm/helpers'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { FetchColumn } from './FetchColumn'
import { LookerPreviewPopup } from '../GoalMetricForm/LookerPreviewPopup'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface Props {
  previousQuery?: KpiInterface['sql_query']
  hideLooker?: boolean
  codeEditorProps?: Partial<LapeCodeEditorProps>
}

const LOOKER_CONNECTION = {
  id: 'looker',
  name: 'Looker',
}

export const QueryField = connect(
  ({ previousQuery, hideLooker = false, codeEditorProps = {} }: Props) => {
    const { values, initialValues } = useLapeContext<KpiInterface>()
    const [pending, setPending] = useState(false)

    const [taskId, setTaskId] = useState<string>()
    const { data: previewData } = useGetTestQueryStatus(taskId)
    const showStatusPopup = useShowStatusPopup()
    const [shownLookerPreview, setShownLookerPreview] = useState(false)
    const isCommercial = useIsCommercial()

    const selectedDatabase = isCommercial ? values.connection : values.sql_query_db

    const isLooker =
      selectedDatabase?.id === 'looker' || values.update_type === UpdateTypes.looker

    const embedLookUrl = tryToMakeEmbedLookerUrl(values.look_url)

    const allowedToRun = isLooker
      ? !!values.look_url && !!embedLookUrl
      : !!values.sql_query

    const tooltipText = allowedToRun
      ? `Click here to check whether your ${isLooker ? 'URL' : 'query'} works`
      : `${isLooker ? 'A valid Look URL' : 'SQL Query'} is required`

    const runButtonLabel = isLooker ? 'Fetch data' : 'Run query'

    useEffect(() => {
      if (values.update_type === initialValues.update_type) {
        return
      }
      if (values.update_type === UpdateTypes.looker) {
        if (isCommercial) {
          values.connection = LOOKER_CONNECTION
        } else {
          values.sql_query_db = LOOKER_CONNECTION
        }
      } else if (selectedDatabase?.id === 'looker') {
        values.connection = undefined
        values.sql_query_db = undefined
      }
    }, [values.update_type])

    const handleRun = async () => {
      setPending(true)
      try {
        const response = await testSqlQuery({
          query: isLooker ? undefined : values.sql_query,
          id: values.id,
          query_db: values.sql_query_db ? values.sql_query_db.id : undefined,
          connection: values.connection ? values.connection : undefined,
          look_url: isLooker ? values.look_url : undefined,
        })

        setTaskId(response.data.task_id)
      } catch (err) {
        showStatusPopup({
          status: 'error',
          title: 'Cannot run query',
          description: getMessageFromApiError(err),
        })
        setTaskId(undefined)
        setPending(false)
      }
    }

    useEffect(() => {
      if (previewData?.status === 'SUCCESS') {
        setPending(false)
      }
    }, [previewData?.status])

    return (
      <>
        <VisuallyHidden>
          <LapeNewInput name="sql_query" />
        </VisuallyHidden>
        {isLooker ? (
          <>
            <LapeNewInput
              name="look_url"
              label="Look URL"
              required
              description={`Provide the URL for the Look you want to connect to, e.g. ${
                isCommercial
                  ? 'https://company.looker.com/looks/12345'
                  : 'https://revolut.cloud.looker.com/looks/24523'
              }`}
            />
            <FetchColumn type="looker" variant="target" />
          </>
        ) : (
          <VStack space="s-8" mt="s-24">
            <HStack align="center">
              <Text variant="caption" pr="s-8" color={Token.color.greyTone50}>
                Data connection:{' '}
              </Text>
              <KPIDataBaseSelection
                searchable={false}
                hideLookerOption={hideLooker}
                data={values}
                onChange={option => {
                  if (option) {
                    if (isCommercial) {
                      values.connection = option
                    } else {
                      values.sql_query_db = option
                    }
                  }
                }}
                value={selectedDatabase}
                label={selectedDatabase?.name}
              />
            </HStack>
            <LapeNewCodeEditor
              name="sql_query"
              placeholder="SQL Query"
              bottomInfo={
                <Box pt="s-8" px="s-12">
                  <Text use="p">
                    <Text use="p">
                      The query runs every night and updates the current value.
                    </Text>
                    {selectedDatabase?.id === QueryDB.Helios && (
                      <Text use="p">
                        Please verify that your query complies with{' '}
                        <Link target="_blank" href={KPI_SQL_QUERY_URL}>
                          Helios Rules
                        </Link>{' '}
                        or the query will not run
                      </Text>
                    )}
                  </Text>
                </Box>
              }
              diff={previousQuery || undefined}
              {...codeEditorProps}
            />
          </VStack>
        )}
        <Bar>
          <Tooltip text={tooltipText} placement="right">
            {isLooker ? (
              <ActionButton
                onClick={() => setShownLookerPreview(true)}
                disabled={!allowedToRun}
                useIcon="EyeOutline"
              >
                Preview data
              </ActionButton>
            ) : (
              <ActionButton
                onClick={handleRun}
                disabled={pending || !allowedToRun}
                useIcon={pending ? undefined : <Icon name="Play" />}
                pending={pending}
              >
                {pending ? '' : runButtonLabel}
              </ActionButton>
            )}
          </Tooltip>
        </Bar>
        {previewData?.status === 'SUCCESS' && (
          <QueryTestingTable response={previewData.result} />
        )}
        {!!values.look_url && !!embedLookUrl && (
          <LookerPreviewPopup
            open={shownLookerPreview}
            url={embedLookUrl}
            onClose={() => setShownLookerPreview(false)}
          />
        )}
      </>
    )
  },
)
