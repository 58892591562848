import React, { useCallback, useState, useMemo } from 'react'
import {
  RadioSelect as UiKitRadioSelect,
  type SelectOptionItemType,
  Flex,
  Icon,
  Token,
  useMatchMedia,
} from '@revolut/ui-kit'
import { matchSorter } from 'match-sorter'
import { RadioSelectMobile, type RadioSelectMobileProps } from './RadioSelectMobile'

export interface RadioSelectProps<T extends number | object | string>
  extends Pick<
      RadioSelectMobileProps<T>,
      | 'labelNoResults'
      | 'labelSearch'
      | 'onChange'
      | 'onClose'
      | 'open'
      | 'options'
      | 'searchable'
      | 'title'
    >,
    Partial<Pick<RadioSelectMobileProps<T>, 'children'>> {
  anchorRef?: React.RefObject<HTMLElement | null>
  labelList: string
  value?: T | null
  createNewOptionAllowed?: boolean
}

export const RadioSelect = <T extends number | object | string = string>({
  open,
  value,
  anchorRef,
  options,
  onClose,
  onChange,
  labelList,
  labelSearch: initialLabelSearch,
  labelNoResults: initialLabelNoResults,
  children = ({ item }) => String(item.label ?? item.value),
  title,
  searchable = true,
  createNewOptionAllowed = false,
}: RadioSelectProps<T>) => {
  const [searchText, setSearchText] = useState('')
  const isDesktop = useMatchMedia(Token.media.md)

  const getItems = useCallback(
    (searchInput: string, opts: SelectOptionItemType<T>[]) => {
      // matchSorter is used by UI kit internally to search using smart algorithms
      const searchResults = matchSorter(opts, searchInput, {
        keys: ['label', 'value'],
      })
      if (
        !createNewOptionAllowed ||
        !searchInput ||
        (typeof searchResults[0]?.label === 'string' &&
          searchResults[0].label.toLowerCase() === searchInput.toLowerCase())
      ) {
        return searchResults
      }

      return [
        {
          label: (
            <Flex alignItems="center">
              <Icon size={16} color={Token.color.blue} name="Plus" />
              &nbsp;&ldquo;{searchInput}&ldquo;
            </Flex>
          ),
          value: { name: searchInput },
          key: 'new-option',
        } as SelectOptionItemType<T>,
        ...searchResults,
      ]
    },
    [createNewOptionAllowed],
  )

  const items = useMemo(() => {
    return getItems(searchText, options)
  }, [options, searchText, getItems])

  const handleClose = useCallback(() => {
    onClose()
    setSearchText('')
  }, [setSearchText, onClose])

  const handleChange = useCallback(
    async (newValue: T | null) => {
      handleClose()
      await onChange(newValue)
    },
    [handleClose, onChange],
  )

  const labelNoResults = initialLabelNoResults ?? 'No results found'
  const labelSearch = initialLabelSearch ?? 'Search'

  const handleSearch = (input: string, opts: SelectOptionItemType<T>[]) => {
    setSearchText(input)
    return getItems(input, opts)
  }

  if (isDesktop) {
    return (
      <UiKitRadioSelect
        anchorRef={anchorRef}
        maxHeight="16rem"
        open={open}
        value={value}
        onChange={handleChange}
        onClose={handleClose}
        options={options}
        indicatorStyle="highlight"
        labelList={labelList}
        labelSearch={labelSearch}
        labelNoResults={labelNoResults}
        autoClose
        fitInAnchor
        searchable={searchable}
        onSearch={handleSearch}
      >
        {optionItem => children({ item: optionItem })}
      </UiKitRadioSelect>
    )
  }

  return (
    <RadioSelectMobile
      options={items}
      onClose={handleClose}
      onChange={handleChange}
      open={open}
      labelSearch={labelSearch}
      labelNoResults={labelNoResults}
      onChangeSearchText={setSearchText}
      searchText={searchText}
      searchable={searchable}
      title={title}
    >
      {children}
    </RadioSelectMobile>
  )
}
