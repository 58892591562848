import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, TextWidget, VStack } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { InsightsGroupItemsList } from '@src/features/AiInsights/Performance/components/InsightsGroups/ItemsList/ItemsList'
import { useGetPerformanceInsightsGroups } from '@src/features/AiInsights/Performance/api/insights'
import { useIsPerformanceAiInsightsEnabled } from '@src/features/AiInsights/Performance/hooks/useAiInsightsAvailability'

export const PerformanceInsightsGroupsPage = () => {
  const isPerformanceAiInsightsEnabled = useIsPerformanceAiInsightsEnabled()

  const { data, refetch, isLoading } = useGetPerformanceInsightsGroups({
    enabled: isPerformanceAiInsightsEnabled,
  })

  return (
    <PageWrapper>
      <PageHeader
        title="Insights"
        backUrl={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.EVALUATION_CRITERIA}
      />
      <PageBody>
        <VStack space="s-16">
          <TextWidget>
            <TextWidget.Title>
              <FormattedMessage
                id="performance.ai.insights.explanation.title"
                defaultMessage="Insights explained"
              />
            </TextWidget.Title>
            <TextWidget.Content>
              <FormattedMessage
                id="performance.ai.insights.explanation.description"
                values={{ b: chunks => <strong>{chunks}</strong> }}
                defaultMessage="Insights provide an <b>AI-generated summary</b> of employee performance by analysing key quantitative data, achievements, and goals. These insights help managers evaluate individual contributions, compare performance across teams, and make informed, data-driven decisions during performance reviews."
              />
            </TextWidget.Content>
          </TextWidget>
          <TextWidget data-testid="performance_ai_insights_groups_widget">
            <TextWidget.Title>
              <FormattedMessage
                id="performance.ai.insights.groups.title"
                defaultMessage="Insights groups"
              />
            </TextWidget.Title>
            <TextWidget.Content>
              <FormattedMessage
                id="performance.ai.insights.groups.description"
                defaultMessage="Insights will be displayed to these groups of people based on selected list of employees and sources."
              />
              <Box pt="s-16" data-testid="performance_ai_insights_groups_list">
                <InsightsGroupItemsList
                  isLoading={isLoading}
                  groups={data?.results ?? []}
                  refetch={refetch}
                />
              </Box>
            </TextWidget.Content>
          </TextWidget>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
