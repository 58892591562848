import { ReviewCategory } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Avatar, Group, Item, ItemSkeleton, Text, Token } from '@revolut/ui-kit'
import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useGetCommitteeResult } from '@src/api/probationReview'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { CommitteeStepReviewersActions } from './CommitteeStepReviewersActions'

const ReviewResultPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_COMMITTEE_RESULT.GENERAL,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_COMMITTEE_RESULT.GENERAL,
}

export const CommitteeStepReviewers = ({
  checkpoint,
  reviewCategory,
  cycleId,
  employeeId,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
}) => {
  const { data: decision, isLoading } = useGetCommitteeResult(
    reviewCategory,
    employeeId,
    checkpoint?.decision?.id,
    cycleId,
  )

  const onClickAddReview = () => {
    navigateTo(
      pathToUrl(ReviewResultPath[reviewCategory], {
        employeeId,
        cycleId,
        id: checkpoint?.decision?.id,
      }),
    )
  }

  if (isLoading) {
    return <ItemSkeleton />
  }

  return (
    <Group width="100%" style={transparentThemeBackgroundOverrides}>
      <Item py="s-8">
        <Item.Avatar>
          <Avatar bg={Token.color.accent} useIcon="People" color={Token.color.onAccent}>
            <Avatar.Badge
              bg={checkpoint.decision ? Token.color.green : Token.color.orange}
              position="bottom-right"
              useIcon={checkpoint.decision ? 'Check' : '16/SandWatch'}
            />
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>HR Committee</Item.Title>
          <Item.Description>
            <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
              HR Committee to review results of the
              {reviewCategory === ReviewCategory.Probation ? ' probation ' : ' PIP '}
              checkpoints.
            </Text>
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <CommitteeStepReviewersActions
            checkpoint={checkpoint}
            decision={decision}
            onClick={onClickAddReview}
          />
        </Item.Side>
      </Item>
    </Group>
  )
}
