import React, { useMemo, useEffect } from 'react'
import { useGetSelectors } from '@src/api/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { Cell, Color, InputGroup, Text } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { DepartmentInterface } from '@src/interfaces/deparment'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { useGetOrganisationSettings, useGetRoadmapSettings } from '@src/api/settings'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { SectionOptions } from '@src/interfaces/customFields'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

const General = () => {
  const { values } = useLapeContext<DepartmentInterface>()
  const currentUser = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)
  const slackUserGroupEnabled = featureFlags?.includes(FeatureFlags.SlackUserGroupEnabled)
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: jiraProjects = [] } = useGetSelectors<IdAndName<number>>(
    roadmapSettings?.jira_epics_enabled ? selectorKeys.jira_projects : null,
  )

  const jiraProjectsOptions = useMemo(
    () =>
      jiraProjects.map(project => ({
        label: project.name,
        value: { id: project.name },
      })),
    [jiraProjects],
  )

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: currentUser.id }
    }
  }, [])

  useEffect(() => {
    if (slackUserGroupEnabled && values.settings === undefined) {
      values.settings = { enable_slack_user_groups: true }
    }
  }, [])

  const { data: settings } = useGetOrganisationSettings()
  const viewSalariesEnabled = !!settings?.enable_team_owners_view_salaries
  const isEditing = !!values.id

  return (
    <>
      <PageBody>
        <NewStepperSectionCustomFields
          sectionId={SectionOptions.Departments}
          departmentId={values.id}
        >
          <NewStepperTitle title="General Information" />
          <InputGroup>
            <LapeNewInput label="Name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employees_for_teams}
            />
            {isEditing ? (
              <LapeNewInput
                label="Performance dashboard link"
                name="performance_dashboard_link"
              />
            ) : null}
            <LapeNewInput
              type="url"
              name="kpi_champions_playbook"
              label="KPI champions playbook"
            />
            <LapeNewMultiSelect
              name="department_moderators"
              label="Department moderators"
              placeholder="Department moderators"
              selector={selectorKeys.employee}
            />
            <LapeNewInput
              type="url"
              name="department_moderators_playbook"
              label="Department moderators playbook"
            />
            <LapeNewMultiSelect
              name="product_operations_champions"
              label="Product operations champions"
              placeholder="Product operations champions"
              selector={selectorKeys.employee}
            />
            <LapeNewInput
              type="url"
              name="product_operations_champions_playbook"
              label="Product operations champions playbook"
            />
            <LapeNewMultiSelect
              name="engagement_champions"
              label="Engagement champions"
              placeholder="Engagement champions"
              selector={selectorKeys.employee}
            />
            <LapeNewInput
              type="url"
              name="engagement_champions_playbook"
              label="Engagement champions playbook"
            />
          </InputGroup>
          <NewStepperTitle
            title="Mission"
            subtitle="A short summary explaining the goals and the scope of the department."
          />
          <InputGroup>
            <LapeNewTextArea label="Mission" name="mission" required rows={3} />
          </InputGroup>
          {viewSalariesEnabled ? (
            <>
              <NewStepperTitle title="Advanced settings" />
              <LapeNewSwitch
                name="settings.team_owners_can_view_salaries"
                itemTypeProps={{ title: 'Team owners can view salaries' }}
              />
            </>
          ) : null}
          {roadmapSettings?.jira_epics_enabled || slackUserGroupEnabled ? (
            <>
              <NewStepperTitle title="Integrations" />
              {roadmapSettings?.jira_epics_enabled && (
                <InputGroup>
                  <Cell flexDirection="column" p="s-12">
                    <Text alignSelf="flex-start" variant="h6">
                      JIRA Roadmaps
                    </Text>
                    <Text
                      alignSelf="flex-start"
                      color={Color.GREY_TONE_50}
                      variant="caption"
                    >
                      You can add any JIRA project keys which you are a member of and plan
                      to select from when adding roadmaps or creating a goal. Epics from
                      those projects will then be available to you.
                    </Text>
                  </Cell>
                  <LapeNewMultiSelect<{ id: string }>
                    name="jira_projects"
                    message="These are the Jira projects you will be able to select from"
                    onChange={options => {
                      values.jira_projects = options.map(option => option.value.id)
                    }}
                    options={jiraProjectsOptions}
                    placeholder="Jira Projects for Roadmaps"
                    value={
                      values.jira_projects?.map(project => ({
                        label: project,
                        value: { id: project },
                      })) || []
                    }
                  />
                </InputGroup>
              )}
              {slackUserGroupEnabled && (
                <LapeNewSwitch
                  name="settings.enable_slack_user_groups"
                  itemTypeProps={{
                    title: 'Automatically create user groups',
                    description:
                      'User groups will be set up automatically in your Slack workspace for this department',
                  }}
                />
              )}
            </>
          ) : null}
        </NewStepperSectionCustomFields>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          previewUrl={ROUTES.FORMS.DEPARTMENT.SUMMARY}
        />
      </PageActions>
    </>
  )
}

export default General
