import React from 'react'
import { ActionButton, Box, Flex, Tag, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetPerformanceRecruitmentCycleData } from '@src/api/performance'
import { getPerformanceRatingColor } from '@src/constants/columns/performanceSummary'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const RecruitmentPerformanceStats = ({
  employeeId,
  canViewRecruitmentPerformance,
}: {
  employeeId: number
  canViewRecruitmentPerformance: boolean
}) => {
  const permissions = useSelector(selectPermissions)
  const { data: recruitmentData } = useGetPerformanceRecruitmentCycleData({
    employeeId,
    options: { enabled: canViewRecruitmentPerformance },
  })
  const rating = recruitmentData?.summary_data?.skill_ratings?.average_rating
  const isCandidateProfileButtonVisible = permissions.includes(
    PermissionTypes.ViewCandidate,
  )

  return (
    <Flex alignItems="center" justifyContent="space-between" p="s-16" flexDirection="row">
      <Flex gap="s-48">
        <Box>
          <Flex alignItems="center" gap="s-8">
            <Text variant="heading3">Recruitment</Text>
            <Tag
              variant="faded"
              bg={Token.color.greyTone10}
              color={Token.color.greyTone50}
            >
              Prevoius
            </Tag>
          </Flex>

          <Text color={Token.color.greyTone50} use="div">
            Performance cycle
          </Text>
        </Box>
        {rating && (
          <Stat
            label="Grade"
            val={
              <Flex alignItems="center">
                <Box mr="s-8">
                  <Text color={getPerformanceRatingColor(rating, true)} variant="body1">
                    {PerformanceRatingTitle[rating]}
                  </Text>
                </Box>
              </Flex>
            }
            ml="s-32"
          />
        )}
      </Flex>
      {isCandidateProfileButtonVisible && recruitmentData?.candidate_id && (
        <ActionButton
          useIcon="LinkExternalSimple"
          onClick={() =>
            navigateTo(
              pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                id: recruitmentData.candidate_id,
              }),
            )
          }
        >
          Candidate Profile
        </ActionButton>
      )}
    </Flex>
  )
}
