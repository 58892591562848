import { Icon, MoreBar, Token, useToggle } from '@revolut/ui-kit'
import {
  PerformanceSelector,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import React, { useMemo } from 'react'
import { useGetPermissions } from '../hooks/useGetPermissions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import {
  closeProbationCycle,
  deleteEmployeeCycle,
  regenerateProbationScorecards,
} from '@src/api/probationEmployees'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetPeriodTypes } from '@src/utils/performance'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { navigateTo } from '@src/actions/RouterActions'
import SideBar from '@src/components/SideBar/SideBar'
import { ProbationPipAdditionalInfo } from '@src/pages/EmployeeProfile/Layout/Performance/ProbationPipAdditionalInfo'
import { ProbationTimelineSettingsForm } from '../../TimelineSettings/ProbationTimelineSettingsForm'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import RequestFeedback from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/RequestFeedback'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  checkRejectAvailability,
  getReviewer,
  isNewFlowRequestsResponse,
  isRequestFeedbackInterfaceArray,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'

import { rejectReview } from '@src/api/performanceReview'
import { useInvalidatePerformanceSettingsQuery } from '@src/api/performance'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface Props {
  selectedPeriod: PerformanceSelector
  employee: EmployeeInterface
}

export const ProbationCycleActions = ({ selectedPeriod, employee }: Props) => {
  const permissions = useGetPermissions({ employee, selectedPeriod })
  const confirmationPopup = useConfirmationDialog()
  const errorPopup = useErrorPopup()
  const showStatusPopup = useShowStatusPopup()
  const [isProbationNotesSidebarOpen, toggleProbationNotesSidebar] = useToggle()
  const [isReviewSidebarOpen, toggleReviewsSidebar] = useToggle()
  const { refetch: refetchProbationCheckpoints } = useGetProbationCheckpoints(
    employee.id,
    String(selectedPeriod.id),
    false,
  )
  const [isProbationTimelineSettingsOpen, toggleProbationTimelineSettingsSidebar] =
    useToggle()
  const { isNewProbation, isNewFlow, isNewPerformance } =
    useGetPeriodTypes(selectedPeriod)

  const { data: probationCheckpoints } = useGetProbationCheckpoints(
    isNewProbation ? employee.id : null,
    selectedPeriod?.id !== undefined ? String(selectedPeriod?.id) : null,
  )

  const user = useSelector(selectUser)

  const invalidatePerformanceSettingQueries = useInvalidatePerformanceSettingsQuery()
  const {
    data: performanceReviewRequestsData,
    isLoading,
    refetch,
  } = useFetcherPerformanceRequests({
    category: selectedPeriod.category,
    isNewFlow,
    id: employee.id,
    performanceCycle: selectedPeriod,
  })

  const performanceReviewRequests = isNewFlow
    ? isNewFlowRequestsResponse(performanceReviewRequestsData)
      ? performanceReviewRequestsData.results
      : undefined
    : isRequestFeedbackInterfaceArray(performanceReviewRequestsData)
    ? performanceReviewRequestsData
    : undefined

  const userReviewRequests = useMemo(() => {
    if (!Array.isArray(performanceReviewRequests)) {
      return undefined
    }

    return (
      performanceReviewRequests as (
        | PerfReviewRequestFeedbackInterface
        | RequestFeedbackInterface
      )[]
    ).find(request => getReviewer(request, isNewPerformance)?.id === user.id)
  }, [performanceReviewRequests, user.id, isNewPerformance])

  const handleRejectReview = async () => {
    if (userReviewRequests?.id) {
      try {
        await rejectReview(employee.id, userReviewRequests?.id)
        showStatusPopup({
          status: 'success',
          title: 'Review request successfully rejected.',
        })
        invalidatePerformanceSettingQueries()
      } catch (error) {
        errorPopup.show({ error, fallbackTitle: `Couldn't reject review` })
      }
    }
  }

  const onDeleteEmployeeCycle = async () => {
    try {
      await deleteEmployeeCycle(getReviewCycleIdWithoutPrefix(selectedPeriod?.id))
      refetch()
      showStatusPopup({
        status: 'success',
        title: 'Cycle was successfully deleted',
        onClose: () =>
          navigateTo(
            pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
              id: employee.id,
            }),
          ),
      })
    } catch (error) {
      errorPopup.show({ error, fallbackTitle: `Couldn't delete probation cycle` })
    }
  }

  const handleRegenerateScorecards = async () => {
    try {
      await regenerateProbationScorecards(
        getReviewCycleIdWithoutPrefix(selectedPeriod.id),
      )
      refetch()
      showStatusPopup({
        status: 'success',
        title: 'Scorecards regenerated',
      })
    } catch (error) {
      errorPopup.show({ error, fallbackTitle: 'Could not regenerate scorecards' })
    }
  }

  const handleCloseProbation = async () => {
    try {
      await closeProbationCycle(getReviewCycleIdWithoutPrefix(selectedPeriod.id))
      refetch()
      showStatusPopup({
        status: 'success',
        title: 'Probation cycle closed',
      })
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Could not close probation',
        forceFallbackTitle: true,
      })
    }
  }

  return (
    <>
      <MoreBar.Action
        useIcon="Document"
        onClick={() => {
          toggleProbationTimelineSettingsSidebar.off()
          toggleProbationNotesSidebar.on()
        }}
      >
        Probation notes
      </MoreBar.Action>
      {permissions.hasProbationCommitteeHRPermissions && (
        <>
          <MoreBar.Action
            useIcon="CalendarWeek"
            onClick={() => {
              toggleProbationTimelineSettingsSidebar.on()
              toggleProbationNotesSidebar.off()
            }}
          >
            Timeline settings
          </MoreBar.Action>
          {isNewProbation && selectedPeriod.status === ReviewCycleStatus.ongoing && (
            <>
              <MoreBar.Action
                useIcon="16/ArrowRecurring"
                onClick={() =>
                  confirmationPopup.show({
                    label: 'Regenerate scorecard for this cycle?',
                    yesMessage: 'Regenerate',
                    noMessage: 'Cancel',
                    onConfirm: handleRegenerateScorecards,
                  })
                }
              >
                Regenerate scorecards
              </MoreBar.Action>
              <MoreBar.Action
                use="button"
                useIcon="Cross"
                onClick={() =>
                  confirmationPopup.show({
                    label: 'Close this cycle?',
                    yesMessage: 'Close',
                    noMessage: 'Cancel',
                    onConfirm: handleCloseProbation,
                  })
                }
              >
                Close probation cycle
              </MoreBar.Action>
            </>
          )}
        </>
      )}
      {permissions.canDeleteEmployeePerformanceCycle && (
        <MoreBar.Action
          use="button"
          useIcon="Delete"
          variant="negative"
          onClick={() =>
            confirmationPopup.show({
              label: 'Delete this cycle?',
              yesMessage: 'Delete',
              noMessage: 'Cancel',
              onConfirm: onDeleteEmployeeCycle,
            })
          }
        >
          Delete probation cycle
        </MoreBar.Action>
      )}
      {permissions.canRequestReview && (
        <MoreBar.Action use="button" useIcon="Plus" onClick={toggleReviewsSidebar.switch}>
          Request review
        </MoreBar.Action>
      )}

      {permissions.canViewProbationChangelog && (
        <MoreBar.Action
          useIcon="Time"
          use={InternalLink}
          to={pathToUrl(
            ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PROBATION.CHANGELOG,
            {
              employeeId: employee.id,
              probationId: selectedPeriod.id,
            },
          )}
        >
          Probation changelog
        </MoreBar.Action>
      )}

      {checkRejectAvailability(userReviewRequests) ? (
        <MoreBar.Action
          use="button"
          useIcon={<Icon size={16} name="Reverted" color={Token.color.danger} />}
          onClick={() =>
            confirmationPopup.show({
              label: 'Reject review',
              body: 'Are you sure?',
              yesMessage: 'Reject',
              noMessage: 'Cancel',
              onConfirm: handleRejectReview,
            })
          }
        >
          Reject review request
        </MoreBar.Action>
      ) : undefined}
      <SideBar
        usePortal
        useLayout
        variant="wide"
        title="Probation Notes"
        useIcon="Document"
        isOpen={isProbationNotesSidebarOpen}
        onClose={toggleProbationNotesSidebar.off}
      >
        <ProbationPipAdditionalInfo data={employee} selectedPeriod={selectedPeriod} />
      </SideBar>
      <SideBar
        usePortal
        useLayout
        variant="wide"
        title="Probation timeline settings"
        useIcon="CalendarWeek"
        isOpen={isProbationTimelineSettingsOpen}
        onClose={toggleProbationTimelineSettingsSidebar.off}
      >
        <ProbationTimelineSettingsForm
          cycleId={selectedPeriod.cycle_id}
          placement="sidebar"
          onAfterSubmit={() => {
            toggleProbationTimelineSettingsSidebar.off()
            refetchProbationCheckpoints()
          }}
        />
      </SideBar>
      {isReviewSidebarOpen && (
        <RequestFeedback
          canRequest={permissions.canRequestReview}
          performanceCycle={selectedPeriod}
          isNewFlow={isNewFlow}
          category={ReviewCategory.Probation}
          onClose={toggleReviewsSidebar.off}
          id={employee.id}
          requests={performanceReviewRequests}
          onAddRequest={invalidatePerformanceSettingQueries}
          checkpoints={probationCheckpoints?.checkpoints}
          fetching={isLoading}
        />
      )}
    </>
  )
}
