import React from 'react'
import { Avatar, AvatarSize, BadgeSize, Text, Token } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { getInitials } from '@src/utils/employees'

interface Props {
  employee: EmployeeInterface
}

export const Commentator = ({ employee }: Props) => (
  <Avatar image={employee.avatar} uuid={String(employee.id)} size={AvatarSize.S_20}>
    <Avatar.Badge
      variant="dot"
      position="top-right"
      bg={Token.color.red}
      size={BadgeSize.SIZE_5}
    />
    {employee.full_name && (
      <Text>{!employee.avatar && getInitials(employee.full_name)}</Text>
    )}
  </Avatar>
)
