import { Statuses } from '@src/interfaces'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { toIdAndName } from '@src/utils/toIdAndName'

export const getPipGoalsFilter = (employeeId: string | number) => {
  return [
    {
      filters: [toIdAndName(String(employeeId))],
      columnName: 'employee__id',
    },
    {
      filters: [toIdAndName('PIP')],
      columnName: 'category',
    },
  ]
}

export const getSmartGoalStatus = (goal: DeliverableInterface) =>
  goal.is_completed ? Statuses.completed : Statuses.pending_completion
