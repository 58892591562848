import React, { useEffect, useState } from 'react'
import set from 'lodash/set'
import {
  ProbationRecommendationInterface,
  QuestionType,
} from '@src/interfaces/probationReview'
import { selectorKeys } from '@src/constants/api'
import SummarySidebar from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar/SummarySidebar'
import { ReviewCategory } from '@src/interfaces/performance'
import { employeesRequests } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useParams } from 'react-router-dom'
import {
  getProbationRecommendationStructure,
  probationRecommendationRequests,
} from '@src/api/probationReview'
import { OptionInterface } from '@src/interfaces/selectors'
import { getSelectors } from '@src/api/selectors'
import ProbationDecision from '@src/pages/Forms/FinalResultForm/ProbationDecision'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Box, Cell, Link, MoreBar, Text, TextWidget } from '@revolut/ui-kit'
import { connect, useLape } from 'lape'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { Grid } from '@components/CommonSC/Grid'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import Form from '@src/features/Form/Form'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import SwitchButton from '@components/SwitchButton/SwitchButton'

const General = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const form = useLapeContext<ProbationRecommendationInterface>()
  const { values } = form
  const { id, cycleId, employeeId } = useParams<{
    id: string
    cycleId: string
    employeeId: string
  }>()
  const state = useLape<{
    employee?: EmployeeInterface
    options: RadioSelectOption<OptionInterface>[]
  }>({
    employee: undefined,
    options: [],
  })

  const canEdit = !values?.id
    ? values?.field_options?.permissions?.includes(
        PermissionTypes.AddPerformanceResultRecommendation,
      )
    : values?.field_options?.permissions?.includes(
        PermissionTypes.ChangePerformanceResultRecommendation,
      )

  useEffect(() => {
    if (id) {
      return
    }
    const fetchRecommendation = async () => {
      const result = await getProbationRecommendationStructure(+employeeId, cycleId)
      form.values = result.data
    }

    fetchRecommendation()
  }, [])

  useEffect(() => {
    const fetchEmployee = async () => {
      const { data } = await employeesRequests.getItem(employeeId)
      state.employee = data
    }

    fetchEmployee()
  }, [employeeId])

  useEffect(() => {
    const fetchOptions = async () => {
      const result = await getSelectors(selectorKeys.yes_no_range_options)
      if (result && result.data) {
        state.options = result.data.options.map(item => ({
          label: item.name,
          value: item,
        }))
      }
    }

    fetchOptions()
  }, [])

  if (!state.employee || !values) {
    return null
  }

  return (
    <PageWrapper minWidth={1305} height="100vh">
      <PageHeader
        title={
          <>
            Final result for Probation Review of{' '}
            <Link
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: state.employee.id,
              })}
              use={InternalLink}
              target="_blank"
            >
              {state.employee.display_name}
            </Link>
          </>
        }
        backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
          id: employeeId,
        })}
      />
      <PageBody>
        <MoreBar>
          <SwitchButton
            checked={sidebarOpen}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            Show reviews
          </SwitchButton>
        </MoreBar>

        <AutoStepper mt="s-24">
          <TextWidget>
            <TextWidget.Title>
              Its time to decide whether {state.employee.first_name} has passed his/her
              probation.
            </TextWidget.Title>
            <TextWidget.Content>
              <Box use="ul" px="s-16">
                <Box use="li" style={{ listStyleType: 'decimal' }} pt="s-8">
                  The questions below will help you in making a more informed decision.
                </Box>
                <Box use="li" style={{ listStyleType: 'decimal' }} pt="s-8">
                  You can also go through different ratings submitted by{' '}
                  {state.employee.first_name}
                  ’s peers and other managers that has been submitted over the past 3
                  months
                </Box>
              </Box>
            </TextWidget.Content>
          </TextWidget>
          <Box mb="s-24">
            <NewStepperTitle title="Questions" />
            <Cell>
              <Box>
                {values.questions.map((question, num) => (
                  <Grid
                    style={{ gridTemplateColumns: '1fr 200px' }}
                    gap={20}
                    key={num}
                    justifyContent="space-between"
                    alignItems="center"
                    mb="s-20"
                  >
                    <Text>
                      {num + 1}. {question.text}
                    </Text>
                    {question.question_type === QuestionType.YesNo && (
                      <LapeRadioSelectInput
                        name={`questions.${num}.value`}
                        label="Select"
                        options={state.options}
                        onChange={data => {
                          if (data) {
                            set(values, `questions.${num}.value`, data.id)
                          }
                        }}
                        value={
                          state.options.find(o => o.value.id === question.value)?.value
                        }
                        disabled={!canEdit}
                      />
                    )}
                    {question.question_type === QuestionType.Seniority && (
                      <LapeRadioSelectInput
                        name={`questions.${num}.seniority`}
                        label="Select seniority"
                        selector={selectorKeys.seniority}
                        disabled={!canEdit}
                      />
                    )}
                  </Grid>
                ))}
              </Box>
            </Cell>
            <NewStepperTitle title="What is your recommendation on the final result?" />
            <Box>
              <Cell display="block">
                <ProbationDecision disabled={!canEdit} />
              </Cell>
              <Box mt="s-16">
                <LapeNewTextArea
                  label="Justification"
                  name="justification"
                  placeholder="Enter concrete evidence to support the above choices"
                  required
                  rows={5}
                  disabled={!canEdit}
                />
              </Box>
            </Box>
          </Box>

          <PageActions>
            <NewSaveButtonWithPopup
              width={342}
              useValidator
              afterSubmitUrl={pathToUrl(
                ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
                {
                  id: employeeId,
                },
              )}
              successText="Recommendation for Probation Review successfully updated."
            />
          </PageActions>
        </AutoStepper>
      </PageBody>
      <SummarySidebar
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        cycleId={cycleId}
        employeeId={state.employee.id}
        cycleName={'Probation'}
        category={ReviewCategory.Probation}
      />
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={probationRecommendationRequests}>
    <General />
  </Form>
))
