import React from 'react'
import { FormattedMessage } from 'react-intl'
import { chain, MoreBar, useToggle } from '@revolut/ui-kit'
import { CycleOption } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { SkillRatingsDistributionInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { CompareTalentSkillRatingTable } from '@src/features/ReviewCycle/Analytics/Skills/Tables/CompareTalentSkillRating/TalentSkillRatingTable'
import { EmptyView } from '@src/features/ReviewCycle/Analytics/Skills/SkillsComparator/EmptyView'
import { SkillsSelector } from '@src/features/ReviewCycle/Analytics/Skills/SkillsComparator/SkillsSelector'
import { SkillRatingsDistributionFilterKeys } from '@src/features/ReviewCycle/Analytics/api/skills'

interface Props {
  departmentId: number
  cycleId: number
  skills: SkillRatingsDistributionInterface[]
  cycleOptions: CycleOption[]
}

export const SkillsComparator = ({
  departmentId,
  cycleId,
  skills,
  cycleOptions,
}: Props) => {
  const { query } = useQuery()
  const skillIds = query[SkillRatingsDistributionFilterKeys.SkillId]
    ? query[SkillRatingsDistributionFilterKeys.SkillId].split(',').map(Number)
    : []

  const [comparableSkills, setComparableSkills] = React.useState<
    SkillRatingsDistributionInterface[]
  >(skills.filter(({ skill: { id } }) => skillIds.includes(id)))

  const [isSelecting, selectorToggler] = useToggle()

  const handleSelect = (options: SkillRatingsDistributionInterface[]) => {
    setComparableSkills(options)
    selectorToggler.off()
  }

  return (
    <>
      {comparableSkills.length > 0 ? (
        <CompareTalentSkillRatingTable
          departmentId={departmentId}
          cycleId={cycleId}
          skills={comparableSkills}
          cycleOptions={cycleOptions}
          actions={
            <MoreBar.Action useIcon="Controls" onClick={selectorToggler.on}>
              {chain(
                <FormattedMessage
                  id="performance.analytics.skills.ratings.comparator.sidebar.title"
                  defaultMessage="Selected skills"
                />,
                comparableSkills.length,
              )}
            </MoreBar.Action>
          }
        />
      ) : (
        <EmptyView onAddClick={selectorToggler.on} />
      )}
      <SkillsSelector
        addable={skills}
        removable={comparableSkills}
        isOpen={isSelecting}
        onClose={selectorToggler.off}
        onSelect={handleSelect}
      />
    </>
  )
}
