import React, { useEffect } from 'react'
import {
  Absolute,
  Box,
  BoxProps,
  Flex,
  HeaderSkeleton,
  IconButton,
  Text,
  Token,
  useMatchMedia,
} from '@revolut/ui-kit'
import { Link } from 'react-router-dom'
import { css } from 'styled-components'
import useResizeObserver from 'use-resize-observer'

import { getBackUrl } from '@src/actions/RouterActions'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { PageHeaderTitle } from '@components/Page/Header/PageHeaderTitle'
import { defaultTheme } from '@src/styles/theme'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { PageHeaderLabel } from '@components/Page/Header/PageHeaderLabel'
import { PageHeaderLabelsBar } from '@components/Page/Header/PageHeaderLabelsBar'

export enum PageHeaderRouterAction {
  goBack = 'goBack',
  navigate = 'navigate',
}

export interface PageHeaderProps extends Omit<BoxProps, 'title'> {
  title: React.ReactNode
  subtitle?: React.ReactNode
  backUrl: string
  routerAction?: PageHeaderRouterAction
  children?: React.ReactNode | React.ReactNode[]
  isLoading?: boolean
  backUrlLocationState?: object
  onClickBack?: () => void
  hideGlobalSearch?: boolean
  renderAbove?: () => React.ReactNode
  renderAboveTitle?: () => React.ReactNode
  hideUpperBlock?: boolean
  noWrap?: boolean
  backButton?: React.ReactNode
  withVerticalSpacing?: boolean
  avatar?: React.ReactNode
  loader?: React.ReactNode
}

const stickyCss = css`
  position: sticky;
  z-index: ${defaultTheme.zIndex.header};
  top: 0;
`

const withDemoBarCss = css`
  top: 34px;
  margin-bottom: 30px;
`

const HeaderCss = css`
  background-color: ${Token.color.layoutBackground};
  ${stickyCss}
`

export const PageHeader = ({
  title,
  subtitle,
  backUrl,
  children,
  isLoading,
  routerAction = PageHeaderRouterAction.goBack,
  backUrlLocationState,
  onClickBack,
  hideGlobalSearch,
  hideUpperBlock,
  renderAbove,
  renderAboveTitle,
  noWrap = true,
  backButton,
  withVerticalSpacing = false,
  avatar,
  loader,
  ...props
}: PageHeaderProps) => {
  const { height: headerHeight, ref } = useResizeObserver()
  const mdMedia = useMatchMedia(Token.media.md)

  const { visible } = useBanner()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight + 26 + (visible ? 40 : 0)
    }
  }, [headerHeight, visible])

  const routesBack = routerAction === PageHeaderRouterAction.goBack

  return (
    <>
      <Box
        pt={mdMedia ? 's-24' : 's-64'}
        pb="s-6"
        {...props}
        ref={ref}
        css={[HeaderCss, visible ? withDemoBarCss : null]}
      >
        {renderAbove?.()}
        {!hideUpperBlock && (
          <Flex alignItems="center" height={40}>
            {backButton || (
              <IconButton
                size={24}
                useIcon={routesBack ? 'BackButtonArrow' : 'Cross'}
                aria-label="close"
                color="foreground"
                use={onClickBack ? undefined : Link}
                onClick={onClickBack}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={routesBack ? getBackUrl(backUrl, backUrlLocationState) : backUrl}
              />
            )}
          </Flex>
        )}
      </Box>

      {isLoading ? (
        loader || <HeaderSkeleton variant="main" labelBackButton="Back" />
      ) : (
        <Box style={{ position: 'relative' }}>
          {renderAboveTitle?.()}
          <Text
            use="h1"
            variant="h1"
            whiteSpace={noWrap ? 'nowrap' : undefined}
            textOverflow="ellipsis"
            overflow="hidden"
            mb="s-4"
            data-testid="header_title"
          >
            {title}
          </Text>

          {avatar ? (
            <Absolute top={10} right={0}>
              {avatar}
            </Absolute>
          ) : null}

          <Box>
            <Text
              mb="s-16"
              color="grey-tone-50"
              fontSize="14px"
              whiteSpace={noWrap ? 'nowrap' : undefined}
              textOverflow="ellipsis"
              overflow="hidden"
              lineHeight="22px"
              use="div"
              data-testid="header-subtitle"
            >
              {subtitle}
            </Text>
          </Box>
          {children}
        </Box>
      )}
    </>
  )
}

PageHeader.Title = PageHeaderTitle
PageHeader.Label = PageHeaderLabel
PageHeader.LabelsBar = PageHeaderLabelsBar
