import React from 'react'
import { Flex, Placeholder } from '@revolut/ui-kit'

import Loader from '@src/components/CommonSC/Loader'
import { SurveyAnalyticsApiQueryParam } from '@src/features/Engagement/api/analytics/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { useQuery } from '@src/utils/queryParamsHooks'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { toIdAndName } from '@src/utils/toIdAndName'
import { TimelineFilterInterface } from '../../Rounds/Selector/types'
import { DetailsPageType } from '../ResultsWidget/common'
import { scopeFilterParamKeys } from '@src/features/Engagement/api/analytics/constants'

export type RouteParams = {
  id: string // survey id
  itemType: DetailsPageType
  itemId: string
}

type PageQueryParam = Exclude<SurveyAnalyticsApiQueryParam, 'survey_id'> // comes from route params (see RouteParams['id'] above)
export type PageQueryParams = Partial<Record<PageQueryParam, string>>

export const NoDataPlaceholder = () => (
  <Flex
    pb={96}
    flex="1"
    width="100%"
    height="100%"
    alignItems="flex-end"
    justifyContent="center"
  >
    <Placeholder>
      <Placeholder.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D221.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@3x.png',
        }}
      />
      <Placeholder.Title>No data found</Placeholder.Title>
    </Placeholder>
  </Flex>
)

export const withLoader = (isLoading: boolean, widgetContent: React.ReactNode) => {
  if (isLoading) {
    return <Loader />
  }
  return widgetContent
}

export const timelineFilterToQueryParams = (timelineFilter: TimelineFilterInterface) => {
  if (!timelineFilter) {
    return undefined
  }
  return {
    ...(timelineFilter.mode.value === 'rounds' && timelineFilter.round.value
      ? { survey_round_id: String(timelineFilter.round.value.id) }
      : null),
    from_date: timelineFilter.dateFrom,
    to_date: timelineFilter.dateTo,
  }
}

export const getDetailsPageQueryParams = (
  timelineFilter: TimelineFilterInterface,
  scopeFilters: FilterByInterface[],
): PageQueryParams => {
  return {
    ...timelineFilterToQueryParams(timelineFilter),
    ...groupFiltersIntoURLQuery(scopeFilters),
  }
}

const filterQueryParamKeys: PageQueryParam[] = [
  'survey_round_id',
  'from_date',
  'to_date',
  ...scopeFilterParamKeys,
]

const filterKeysFromQuery = (query: Record<string, string>, keys: string[]) =>
  Object.fromEntries(
    keys.filter(key => Object.keys(query).includes(key)).map(key => [key, query[key]]),
  )

export const useDetailsFilterParamsFromQuery = () => {
  const { query } = useQuery()
  return filterKeysFromQuery(query, filterQueryParamKeys)
}

export const useScopeFilterParamsFromQuery = () => {
  const { query } = useQuery()
  return filterKeysFromQuery(query, [...scopeFilterParamKeys])
}

export const useDetailsCommentsFilterParamsFromQuery = () => {
  const filters = []
  const filtersParams = useDetailsFilterParamsFromQuery()

  if (filtersParams.survey_round_id) {
    filters.push({
      columnName: 'survey_round_id',
      filters: [toIdAndName(filtersParams.survey_round_id)],
      nonResettable: true,
    })
  }
  if (filtersParams.from_date && filtersParams.to_date) {
    filters.push({
      columnName: 'creation_date_time',
      filters: [toIdAndName(filtersParams.from_date), toIdAndName(filtersParams.to_date)],
      nonResettable: true,
    })
  }

  return filters
}
