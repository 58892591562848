import React, { useState, useMemo } from 'react'
import { Box, Widget, Skeleton, VStack } from '@revolut/ui-kit'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { getLinkedinUrl } from '@src/utils/hiring'
import { InterviewFeedbackSideCV } from '@src/pages/Forms/InterviewFeedback/General/InterviewFeedbackSideCV'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { InterviewFeedbackSideInfo } from '@src/pages/Forms/InterviewFeedback/General/InterviewFeedbackSideInfo'
import SideBar from '@components/SideBar/SideBar'
import { PerformanceSummary } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSummary'
import { ScorecardSidebar } from '@src/pages/Forms/Candidate/Performance/Summary/ScorecardSidebar'
import { FeatureFlags } from '@src/store/auth/types'
import OnlineTestSummarySidebar from '@src/pages/Forms/Candidate/OnlineTestSummarySidebar/OnlineTestSummarySidebar'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { AIInterviewSummary } from '@src/pages/Forms/InterviewFeedback/General/AIInterviewSummary/AIInterviewSummary'
import { useGetInterviewFeedbackTranscript } from '@src/api/recruitment/interviewFeedback'

interface InterviewFeedbackSideProps extends InterviewFeedbackSideContentProps {
  open: boolean
  onClose: VoidFunction
  interviewFeedbackId: number | string
}

interface InterviewFeedbackSideContentProps {
  candidate: CandidateInterface
  interviewer?: InterviewToolInterviewer
  interviewFeedbackId: number | string
  round: InterviewRoundInterface
  canViewCandidate: boolean
  defaultTab?: InterviewFeedbackSideTabs
}

enum InterviewFeedbackSideTabs {
  AI_SUMMARY = 'AI Summary',
  CV = 'CV',
  CandidateProfile = 'Profile',
  PreviousFeedback = 'History',
}

const InterviewFeedbackSideContent = ({
  candidate,
  round,
  interviewer,
  interviewFeedbackId,
  canViewCandidate,
  defaultTab,
}: InterviewFeedbackSideContentProps) => {
  const [testStage, setTestStage] = useState<InterviewStageWithoutRoundInterface>()
  const [interviewFeedbackIds, setInterviewFeedbackIds] = useState<number[]>()
  const onCloseInterviewFeedbacks = () => {
    setInterviewFeedbackIds(undefined)
  }
  const hasAISummaryFeatureFlag = useHasFeatureFlag(
    FeatureFlags.AIInterviewSummarisationInternal,
  )

  const canViewCandidatePerformanceProfile = useHasFeatureFlag(
    FeatureFlags.CandidatePerformanceProfile,
  )
  const tabs = [InterviewFeedbackSideTabs.CV, InterviewFeedbackSideTabs.CandidateProfile]

  if (hasAISummaryFeatureFlag) {
    tabs.unshift(InterviewFeedbackSideTabs.AI_SUMMARY)
  }
  if (canViewCandidatePerformanceProfile && canViewCandidate) {
    tabs.push(InterviewFeedbackSideTabs.PreviousFeedback)
  }

  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs,
    capitalize: true,
    defaultTab,
    onTabChange: tab => {
      if (tab !== InterviewFeedbackSideTabs.PreviousFeedback) {
        onCloseInterviewFeedbacks()
      }
    },
  })

  const linkedIn = getLinkedinUrl(candidate.links)

  return (
    <>
      {tabBar}
      <Box mt="s-16">
        {currentTab === InterviewFeedbackSideTabs.AI_SUMMARY && (
          <AIInterviewSummary
            interviewFeedbackId={interviewFeedbackId}
            candidate={candidate}
            interviewer={interviewer}
          />
        )}
        {currentTab === InterviewFeedbackSideTabs.CV && (
          <InterviewFeedbackSideCV
            linkedIn={linkedIn}
            candidateId={candidate.id}
            canViewCandidate={canViewCandidate}
          />
        )}
        {currentTab === InterviewFeedbackSideTabs.CandidateProfile && (
          <InterviewFeedbackSideInfo
            candidate={candidate}
            interviewer={interviewer}
            round={round}
          />
        )}
        {currentTab === InterviewFeedbackSideTabs.PreviousFeedback && (
          <Widget pb="s-16">
            <PerformanceSummary
              compact
              round={round}
              onViewScorecard={feedbackIds => {
                setInterviewFeedbackIds(feedbackIds)
                setTestStage(undefined)
              }}
              onViewTest={stage => {
                setInterviewFeedbackIds(undefined)
                setTestStage(stage)
              }}
            />
          </Widget>
        )}
      </Box>
      {!!interviewFeedbackIds?.length && (
        <ScorecardSidebar
          interviewFeedbackIds={interviewFeedbackIds}
          round={round}
          onClose={onCloseInterviewFeedbacks}
        />
      )}
      {!!testStage && (
        <OnlineTestSummarySidebar
          isOpen
          onClose={() => {
            setTestStage(undefined)
          }}
          stage={testStage}
          roundId={round?.id}
          candidateId={candidate?.id}
        />
      )}
    </>
  )
}

export const InterviewFeedbackSide = ({
  open,
  onClose,
  ...rest
}: InterviewFeedbackSideProps) => {
  const hasAISummaryFeatureFlag = useHasFeatureFlag(
    FeatureFlags.AIInterviewSummarisationInternal,
  )

  const { data, isLoading } = useGetInterviewFeedbackTranscript(
    hasAISummaryFeatureFlag ? rest.interviewFeedbackId : undefined,
  )

  const defaultTab = useMemo(() => {
    return hasAISummaryFeatureFlag && (data?.transcript || data?.summary)
      ? InterviewFeedbackSideTabs.AI_SUMMARY
      : InterviewFeedbackSideTabs.CV
  }, [isLoading]) // set default tab only on initial load

  return (
    <SideBar
      title="Candidate details"
      isOpen={open}
      sideProps={{ resizable: true }}
      useLayout
      onClose={onClose}
    >
      {isLoading ? (
        <>
          <VStack gap="s-16">
            <Skeleton height={28} />
            <Skeleton height={200} radius="widget" />
          </VStack>
        </>
      ) : (
        <InterviewFeedbackSideContent {...rest} defaultTab={defaultTab} />
      )}
    </SideBar>
  )
}
