import { IconButton, Item, Link, Token } from '@revolut/ui-kit'
import { CompanyAvatar } from '@src/features/UserAvatarWithMenu/CompanyAvatar'
import React from 'react'

interface Props {
  playbookUrl: string
  skillName: string
  skillDescription: string | undefined
}

export const PlaybookPreview = ({ playbookUrl, skillName, skillDescription }: Props) => {
  return (
    <Item style={{ border: `1px solid ${Token.color.greyTone2_10}` }}>
      <Item.Avatar>
        <CompanyAvatar withUserBadge={false} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{skillName} playbook</Item.Title>
        <Item.Description>{skillDescription}</Item.Description>
      </Item.Content>
      <Item.Side>
        <IconButton
          href={playbookUrl}
          target="_blank"
          rel="noopener noreferrer"
          use={Link}
          useIcon="LinkExternal"
        />
      </Item.Side>
    </Item>
  )
}
