import React, { useMemo } from 'react'
import { useDropdown, Input, RadioSelect, Skeleton } from '@revolut/ui-kit'
import { OptionItem } from './OptionItem'
import { FilterInputSelectorType } from '../FilterSelect'
import { CycleOption } from '@src/interfaces/selectors'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'

interface Props {
  onChange: (val: CycleOption) => void
  label?: string
  selector?: FilterInputSelectorType<CycleOption>
  isLoading?: boolean
  value?: CycleOption
}

export const CycleInput = ({
  onChange,
  value,
  selector = selectorKeys.cycle_offsets,
  label,
  isLoading,
}: Props) => {
  const { options, asyncState } = useFetchOptions<CycleOption>(selector)
  const selectedPeriod = useMemo(
    () => options.find(option => String(option.value.id) === String(value?.id)),
    [options, value],
  )

  const { title: tagTitle } = getCycleOffsetTitleAndColor(selectedPeriod?.value)

  const dropdown = useDropdown()

  if (asyncState !== 'ready' || isLoading) {
    return <Skeleton width="100%" />
  }

  const selectedPeriodWithTitle = value?.name ? `${value.name} ${tagTitle}` : ''

  return (
    <>
      <Input
        type="button"
        label="Review cycle"
        useIcon="ChevronDown"
        {...dropdown.getAnchorProps()}
        aria-haspopup="listbox"
        value={selectedPeriodWithTitle}
      />
      <RadioSelect
        {...dropdown.getTargetProps()}
        label={label}
        options={options}
        value={selectedPeriod?.value}
        fitInAnchor
        onChange={val => {
          if (val) {
            onChange(val)
            dropdown.toggle()
          }
        }}
        labelList="People"
      >
        {option => (
          <OptionItem label={option.label} value={option.value} labelBy="offset" />
        )}
      </RadioSelect>
    </>
  )
}
