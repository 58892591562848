import React, { useState } from 'react'
import { CareerPageSettingsInterface, ExternalLink } from '@src/interfaces/settings'
import {
  ActionButton,
  Box,
  CircleButton,
  DragAndDrop,
  Flex,
  IconButton,
  Input,
  InputGroup,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { move } from '@src/utils/move'

type LinkInputProps = {
  disabled: boolean
  labelError?: string
  urlError?: string
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onChange: (externalLink: ExternalLink) => void
  onDelete: () => void
} & ExternalLink

const LinkInput = ({
  disabled,
  labelError,
  urlError,
  id,
  label,
  url,
  sortable,
  onChange,
  onDelete,
}: LinkInputProps) => {
  return (
    <Flex
      data-testid={`link_${id}`}
      gap="s-8"
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
              zIndex: 10,
            }
          : undefined
      }
    >
      <Box mt="s-16">
        <IconButton useIcon="Drag" {...sortable?.attributes} {...sortable?.listeners} />
      </Box>
      <Input
        required
        label="Label"
        value={label}
        onChange={event => {
          onChange({
            id,
            label: event.currentTarget.value,
            url,
          })
        }}
        data-name={`company_links.${id}.label`}
        aria-invalid={!!labelError}
        errorMessage={labelError}
        disabled={disabled}
      />
      <Input
        required
        label="Url"
        value={url}
        data-name={`company_links.${id}.url`}
        onChange={event => {
          onChange({
            id,
            label,
            url: event.currentTarget.value,
          })
        }}
        aria-invalid={!!urlError}
        errorMessage={urlError}
        disabled={disabled}
      />
      <Box mt="s-4">
        <CircleButton
          aria-label={`delete link ${id}`}
          onClick={onDelete}
          variant="widget-action"
          useIcon="Minus"
          disabled={disabled}
        />
      </Box>
    </Flex>
  )
}

type LinksInputProps = {
  disabled: boolean
}

const MAX_LINKS = 4
const NEW_LINK = { label: '', url: '' }

const LinksInput = ({ disabled }: LinksInputProps) => {
  const { values, errors } = useLapeContext<CareerPageSettingsInterface>()
  const links = values.company_links
  const currentLinks = links?.length ? links : []

  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeLinkIndex =
    activeId !== null ? currentLinks.findIndex(({ id }) => String(id) === activeId) : -1
  const activeLink = activeLinkIndex >= 0 ? currentLinks[activeLinkIndex] : undefined
  const activeLinkErrors = errors?.company_links?.[activeLink?.id ?? activeLinkIndex]

  const handleAdd = () => {
    if (currentLinks.length < MAX_LINKS) {
      const newLinks = [...currentLinks, { ...NEW_LINK }].map((l, id) => ({ ...l, id }))
      values.company_links = newLinks
    }
  }

  const handleDelete = (index: number) => {
    if (index !== null) {
      const newLinks = currentLinks
        .filter((_, i) => index !== i)
        .map((l, id) => ({ ...l, id }))
      values.company_links = newLinks

      if (errors.company_links) {
        errors.company_links = errors.company_links.filter((_, i) => index !== i)
      }
    }
  }

  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      values.company_links = move(currentLinks, startIndex, endIndex)
      if (errors.company_links) {
        errors.company_links = move(errors.company_links, startIndex, endIndex)
      }
    }
    setActiveId(null)
  }

  const handleChange = (index: number, newExternalLink: ExternalLink) => {
    values.company_links[index] = newExternalLink
  }
  return (
    <VStack p="s-16">
      <InputGroup>
        <DragAndDrop.Provider
          onDragStart={event => {
            setActiveId(event.active.id)
          }}
          onDragEnd={event => {
            if (event.over) {
              handleOrderChange(
                event?.active?.data?.current?.sortable.index ?? 0,
                event?.over?.data?.current?.sortable.index ?? 0,
              )
            }
          }}
          onDragCancel={() => {
            setActiveId(null)
          }}
        >
          <DragAndDrop.Sortable
            id="sortable"
            items={currentLinks.map(({ id }) => String(id))}
          >
            {sortable => {
              const currentLinkIndex = currentLinks.findIndex(
                ({ id }) => String(id) === sortable.id,
              )
              const currentLink = currentLinks[currentLinkIndex]
              const currentLinkErrors = errors?.company_links?.[currentLinkIndex]

              return (
                <LinkInput
                  key={currentLinkIndex}
                  disabled={disabled}
                  id={currentLink.id}
                  label={currentLink.label}
                  labelError={currentLinkErrors?.label}
                  urlError={currentLinkErrors?.url}
                  url={currentLink.url}
                  sortable={sortable}
                  onChange={newExternalLink =>
                    handleChange(currentLinkIndex, newExternalLink)
                  }
                  onDelete={() => handleDelete(currentLinkIndex)}
                />
              )
            }}
          </DragAndDrop.Sortable>
          <DragAndDrop.DragOverlay>
            {activeLink && (
              <LinkInput
                disabled={disabled}
                id={activeLink.id}
                label={activeLink.label}
                url={activeLink.url}
                labelError={activeLinkErrors?.label}
                urlError={activeLinkErrors?.url}
                onChange={() => {}}
                onDelete={() => {}}
              />
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
        <ActionButton
          useIcon="Plus"
          disabled={values.company_links.length === MAX_LINKS}
          onClick={handleAdd}
        >
          Add link
        </ActionButton>
      </InputGroup>
    </VStack>
  )
}

export default LinksInput
