import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { fieldChangelogRequisitionRequests } from '@src/api/changelog'
import {
  changelogChangedBy,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const fieldsChangelogRequisitionRow = (
  specialisationEnabled: boolean,
): RowInterface<ChangelogInterface<RequisitionInterface>> => ({
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields(specialisationEnabled),
      selectorsKey: selectorKeys.requisition_changelog_fields,
      width: 210,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
})

const Changelog = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const specialisationEnabled = useIsSpecialisationsEnabled()

  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: values.id, name: values.full_name }],
    },
  ]

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const row = useMemo(
    () => fieldsChangelogRequisitionRow(specialisationEnabled),
    [specialisationEnabled],
  )
  const changelogFieldsTable = useTable<ChangelogInterface<RequisitionInterface>>(
    fieldChangelogRequisitionRequests,
    initialFilter,
    initialSort,
  )

  const handleRowClick = (change: ChangelogInterface<RequisitionInterface>) => {
    navigateReplace(
      `${pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: values.id })}?change=${
        change.id
      }`,
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<ChangelogInterface<RequisitionInterface>>
          name={TableNames.RequisitionChangelog}
          useWindowScroll
          dataType="Data Change"
          row={row}
          {...changelogFieldsTable}
          onRowClick={handleRowClick}
          noDataMessage="There are no changes to display"
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Changelog
