import React from 'react'
import { DetailsCell, DetailsCellSkeleton } from '@revolut/ui-kit'

interface PredictedPerformanceContentProps {
  loading: boolean
  content: React.ReactNode
}

export const PredictedPerformanceContent = ({
  content,
  loading,
}: PredictedPerformanceContentProps) => {
  if (loading) {
    return <DetailsCellSkeleton.Content />
  }
  return <DetailsCell.Content>{content}</DetailsCell.Content>
}
