import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'

export const SenioritiesButton = () => {
  const canViewSeniorities = useHasGlobalPermission(PermissionTypes.ViewSeniority)

  return canViewSeniorities ? (
    <MoreBar.Action
      use={InternalLink}
      to={pathToUrl(ROUTES.PERFORMANCE.SENIORITIES)}
      useIcon="ArrowUpgrade"
    >
      Seniorities
    </MoreBar.Action>
  ) : null
}
