import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type JobsSettingsItemId =
  | 'jobsRequisitionSettings'
  | 'jobsHiringCountriesSettings'
  | 'jobsApprovalSettings'
  | 'jobsRequisitionsApprovalSettings'
  | 'jobsJobPostingSettings'
  | 'jobsApplicationForms'
  | 'jobsCareersWebsiteSettings'
  | 'jobsHiringProcessSettings'

export const jobsSettingsLinkConfig: SettingsLinkItemConfig<JobsSettingsItemId>[] = [
  {
    id: 'jobsRequisitionSettings',
    section: 'Jobs settings',
    icon: 'AddContact',
    title: 'Requisition settings',
    to: ROUTES.SETTINGS.REQUISITIONS.GENERAL,
    subtitle: (
      <FormattedMessage
        id="settings.recruitment.general.description"
        defaultMessage="Basic settings for all requisitions"
      />
    ),
    permissions: [PermissionTypes.ViewRequisitionPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsHiringCountriesSettings',
    section: 'Jobs settings',
    icon: 'Globe',
    title: 'Hiring countries settings',
    to: ROUTES.SETTINGS.REQUISITIONS.COUNTRY_HIRING,
    subtitle: (
      <FormattedMessage
        id="settings.recruitment.hiringCountries.description"
        defaultMessage="Define which locations can be added to a requisition"
      />
    ),
    permissions: [PermissionTypes.ViewHiringProcessPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsRequisitionsApprovalSettings',
    section: 'Jobs settings',
    icon: 'CheckVerification',
    title: 'Approval settings',
    searchTitle: 'Requisitions approval settings',
    to: ROUTES.SETTINGS.REQUISITIONS.APPROVAL,
    subtitle: (
      <FormattedMessage
        id="settings.recruitment.approval.description"
        defaultMessage="Enable and customise requisitions approval flow"
      />
    ),
    permissions: [PermissionTypes.ViewRequisitionPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsJobPostingSettings',
    section: 'Jobs settings',
    icon: 'Megaphone',
    title: 'Job posting settings',
    to: ROUTES.SETTINGS.JOB_POSTING.GENERAL,
    subtitle: (
      <FormattedMessage
        id="settings.jobPostings.general.description"
        defaultMessage="Basic settings for all job postings"
      />
    ),
    permissions: [PermissionTypes.ViewJobPostingPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsApprovalSettings',
    section: 'Jobs settings',
    icon: 'CheckVerification',
    title: 'Approval settings',
    searchTitle: 'Jobs approval settings',
    to: ROUTES.SETTINGS.JOB_POSTING.APPROVAL,
    subtitle: (
      <FormattedMessage
        id="settings.jobPostings.approval.description"
        defaultMessage="Enable and customise job postings approval flow"
      />
    ),
    permissions: [PermissionTypes.ViewJobPostingPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsApplicationForms',
    section: 'Jobs settings',
    icon: 'Questionnaire',
    title: 'Application forms',
    searchTitle: 'Job application forms',
    to: ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS,
    subtitle: (
      <FormattedMessage
        id="settings.jobPostings.applicationForms.description"
        defaultMessage="Configure application form questions"
      />
    ),
    permissions: [PermissionTypes.ViewApplicationFormPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsCareersWebsiteSettings',
    section: 'Jobs settings',
    icon: 'Browser',
    title: 'Careers website settings',
    to: ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE,
    subtitle: (
      <FormattedMessage
        id="settings.jobPostings.careersWebsite.description"
        defaultMessage="Publish and manage your careers website"
      />
    ),
    permissions: [PermissionTypes.ViewCareerPagePreferences],
    globalSetting: GlobalSettings.CareerPageEnabled,
    bundles: [FeatureFlags.BundleRecruitment],
  },
  {
    id: 'jobsHiringProcessSettings',
    section: 'Jobs settings',
    icon: 'Browser',
    title: 'Hiring Process settings',
    to: ROUTES.SETTINGS.JOB_POSTING.HIRING_PROCESS,
    subtitle: (
      <FormattedMessage
        id="settings.jobPostings.hiringProcess.description"
        defaultMessage="Basic settings for all hiring processes"
      />
    ),
    permissions: [PermissionTypes.ViewHiringProcessPreferences],
    bundles: [FeatureFlags.BundleRecruitment],
  },
]
