import React from 'react'
import { IconButton, Item, Token } from '@revolut/ui-kit'

interface Props {
  title: React.ReactNode
  description?: React.ReactNode
  avatar?: React.ReactNode
  side?: React.ReactNode
  disabled?: boolean
  onClick: VoidFunction
}

export const DNDAddableItem = ({
  title,
  description,
  avatar,
  side,
  disabled,
  onClick,
}: Props) => {
  return (
    <Item>
      {!disabled && (
        <Item.Prefix>
          <IconButton
            aria-label="Add"
            useIcon="PlusCircle"
            color={Token.color.blue}
            onClick={onClick}
            size={16}
          />
        </Item.Prefix>
      )}
      {avatar && <Item.Avatar>{avatar}</Item.Avatar>}
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {description && <Item.Description>{description}</Item.Description>}
      </Item.Content>
      {side && <Item.Side>{side}</Item.Side>}
    </Item>
  )
}
