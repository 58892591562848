import React, { useEffect } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Flex,
  Icon,
  Item,
  List,
  Spacer,
  Text,
  Token,
  TransitionCollapse,
  HStack,
  VStack,
  Widget,
  AvatarSize,
} from '@revolut/ui-kit'
import { docusignConnect } from '@src/api/integrations'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { ROUTES } from '@src/constants/routes'
import { DocusignPreferencesInterface } from '@src/interfaces/integrations'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { pathToUrl } from '@src/utils/router'
import { UsersTable } from '../DocuSign/UsersTable'

export const DocuSignOneClickForm = () => {
  const confirmationPopup = useConfirmationDialog()
  const errorPopup = useErrorPopup()
  const showStatusPopup = useShowStatusPopup()
  const { isSubmitting, loading, reset, submit, values } =
    useLapeContext<DocusignPreferencesInterface>()

  useEffect(() => {
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        title="DocuSign"
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                {values.enabled && (
                  <Text color={Token.color.success} variant="emphasis1">
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  Collaboration
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image="https://assets.revolut.com/assets/stocks/DOCU.svg"
                size={AvatarSize.S_56}
              >
                {values.enabled ? (
                  <Avatar.Badge bg={Token.color.success} useIcon="Check" />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
      />

      {!values.enabled && (
        <Box pt="s-16">
          <ActionButton
            disabled={loading || isSubmitting}
            onClick={async event => {
              event.preventDefault()
              try {
                const res = await docusignConnect()
                window.open(res.data.auth_url, `_blank`, 'width=600,height=800')
              } catch (error) {
                errorPopup.show({
                  error,
                  fallbackTitle: 'Cannot connect to DocuSign',
                })
              }
            }}
            pending={loading}
            variant="accent"
            useIcon="Link"
          >
            Enable
          </ActionButton>
        </Box>
      )}

      <PageBody gap="s-16" mb="s-64" mt="s-16">
        <Widget p="s-16">
          <Text mb="s-16" variant="heading3" use="p">
            About integration
          </Text>
          <List variant="compact">
            <List.Item useIcon="Dot">
              Generate, send and sign documents from Revolut People
            </List.Item>
            <List.Item useIcon="Dot">Send offers to candidates</List.Item>
            <List.Item useIcon="Dot">
              Get notifications of document status, task assignments (reviews,
              signatures), and more
            </List.Item>
            <List.Item useIcon="Dot">
              For this integration to work, you should have a Business Pro plan or higher
              with Docusign
            </List.Item>
          </List>
        </Widget>
        <Widget p="s-16">
          <Text mb="s-16" variant="heading3" use="p">
            How to use
          </Text>
          <Text mb="s-16" variant="body2">
            To use Docusign and Revolut People together, you’ll be offered the option to
            use the integration at the following places:
          </Text>
          <List>
            <List.Item useIcon="Statement">
              <Text variant="emphasis1" use="p">
                Offers for candidates
              </Text>
              <Text color={Token.color.greyTone50} variant="body2">
                To enable offers to be generated and signed by candidates via Docusign, go
                to Candidates Settings and switch on "Enable Docusign Integration" option
              </Text>
            </List.Item>
            <List.Item useIcon="DocumentsPair">
              <Text variant="emphasis1" use="p">
                Employee Documents
              </Text>
              <Text color={Token.color.greyTone50} variant="body2">
                To allow documents to be integrated and signed by employees via Docusign,
                go to Document Settings and switch on "Enable Docusign Integration" option
              </Text>
            </List.Item>
          </List>
        </Widget>
        <TransitionCollapse in={values.enabled}>
          <Item
            color={Token.color.danger}
            onClick={async event => {
              event.preventDefault()
              confirmationPopup.show({
                body: 'After disabling this integration you won’t be able to use DocuSign',
                label: 'Are you sure?',
                onConfirm: async () => {
                  values.enabled = false
                  try {
                    const response = await submit()
                    reset(response)
                    showStatusPopup({ title: 'Integration disabled' })
                  } catch (error) {
                    errorPopup.show({
                      error,
                      fallbackTitle: 'Cannot disable integration',
                    })
                    values.enabled = true
                  }
                },
              })
            }}
            use="button"
          >
            <Item.Content>
              <Flex gap="s-16">
                <Icon name="Unlink" />
                <Item.Title>Disconnect DocuSign</Item.Title>
              </Flex>
            </Item.Content>
          </Item>
          <NewStepperTitle noAutoStep title="DocuSign users" />
          <UsersTable />
        </TransitionCollapse>
      </PageBody>
    </PageWrapper>
  )
}
