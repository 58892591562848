import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Color,
  Flex,
  HStack,
  Spacer,
  Text,
  Token,
  Tooltip as UIKitTooltip,
  TooltipProps,
  VStack,
} from '@revolut/ui-kit'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import { PerformanceReviewAnalyticsInterface } from '@src/interfaces/performance'
import {
  getColorByIndex,
  numberWithCommas,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { formatPercentage } from '@src/utils/format'

interface Props extends TooltipProps {
  data: PerformanceReviewAnalyticsInterface
  dataKeys: PerformanceGradeOption[]
  colors?: Color[]
}

export const Tooltip = ({ data, dataKeys, colors, ...tooltipProps }: Props) => (
  <UIKitTooltip {...tooltipProps}>
    <VStack p="s-4" space="s-8">
      <HStack space="s-32">
        <Text fontSize={14} fontWeight={500}>
          {data.group.name}
        </Text>
        <Spacer />
        <Text fontSize={14} fontWeight={500} color={Token.color.greyTone50}>
          <FormattedMessage
            id="performace.analytics.reviews.total.grades"
            values={{ count: numberWithCommas(data.grades_total) }}
            defaultMessage="Total: {count}"
          />
        </Text>
      </HStack>
      {data.grades.map(({ id, count, percent }, index) => (
        <Flex justifyContent="flex-start" key={id} alignItems="center" gap="s-6">
          <Box
            bg={getColorByIndex(index, colors)}
            width="s-12"
            height="s-12"
            border={`1px solid ${Token.color.greyTone10}`}
            borderRadius={Token.radius.r2}
          />
          <Text fontSize={12} color={Token.color.greyTone50}>
            {dataKeys.find(({ id: dataKeyId }) => id === dataKeyId)?.name}
          </Text>
          <Text fontSize={12} color={Token.color.greyTone50} ml="auto">
            {numberWithCommas(count)}
          </Text>
          {percent > 0 && (
            <Text fontSize={12} color={Token.color.greyTone50} opacity={0.8}>
              {`(${formatPercentage(percent, percent > 0.01 ? undefined : 2)})`}
            </Text>
          )}
        </Flex>
      ))}
    </VStack>
  </UIKitTooltip>
)
