import { useSelector } from 'react-redux'

import { selectPermissions } from '@src/store/auth/selectors'
import { useGlobalSettings } from '@src/api/settings'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklistV2'
import { FeatureFlags } from '@src/store/auth/types'
import {
  checkIfFirstStepsCompleted,
  getFirstStepsCheckpoints,
  getNextStepsCheckpoints,
  isCheckpointDisabled,
} from '@src/pages/OnboardingChecklistV2/common/helpers'
import { engagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { allSortedConfigs, OnboardingCheckpointConfig } from './checkpointsConfig'
import {
  OnboardingCheckpointAppCategory,
  OnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklistV2'
import {
  candidatesConfig,
  documentsConfig,
  employeeRecordsConfig,
  goalsConfig,
  interviewsConfig,
  jobsConfig,
  payrollConfig,
  reviewsConfig,
  rolesConfig,
  teamsConfig,
  timeManagementConfig,
} from './checkpointsConfig'
import { API, CORE_INSTANCE_WORKSPACE } from '@src/constants/api'
import { isCommercial } from '@src/utils'
import { useFetch } from '@src/utils/reactQuery'
import { firstStepsCategories } from './constants'
import { useCurrentTenantInfo } from '@src/api/tenants'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'

export const useGetAllowedCheckpoints = (returnHidden?: boolean) => {
  const permissions = useSelector(selectPermissions)
  const { settings } = useGlobalSettings()
  const { checkpoints = [] } = useGetOnboardingCheckpoints()

  return checkpoints.filter(checkpoint => {
    if (checkpoint.category === 'paymentMethod') {
      return checkpoint.state.id !== 'completed'
    }

    const checkpointConfig = allSortedConfigs.find(
      config => config.category === checkpoint.category,
    )
    const hasPermissions = checkpointConfig?.canView.every(perm =>
      permissions.includes(perm),
    )
    const isVisible = returnHidden ? true : checkpoint.state.id !== 'hidden'
    const enabledInSettings = checkpointConfig?.globalSetting
      ? settings[checkpointConfig.globalSetting]
      : true

    return Boolean(isVisible && enabledInSettings && hasPermissions)
  })
}

export const useGetAllowedCheckpointsWithConfigs = (returnHidden?: boolean) => {
  const allowedCheckpoints = useGetAllowedCheckpoints(returnHidden)

  return allSortedConfigs
    .map(config => {
      const checkpointData = allowedCheckpoints.find(c => c.category === config.category)
      return checkpointData ? { data: checkpointData, config } : null
    })
    .filter(Boolean)
}

export const useNextConfig = (
  currentConfig: OnboardingCheckpointConfig,
): OnboardingCheckpointConfig | undefined => {
  const checkpoints = useGetAllowedCheckpointsWithConfigs(true)

  const getNextUncompletedStep = () => {
    return checkpoints
      .slice(currentConfigIndex + 1)
      .find(checkpoint => checkpoint.data.state.id !== 'completed')
  }

  const currentConfigIndex = checkpoints.findIndex(
    checkpoint => checkpoint.data.category === currentConfig.category,
  )
  const isFirstStep = firstStepsCategories.includes(currentConfig.category)
  const nextUncompletedStep = getNextUncompletedStep()

  if (isFirstStep) {
    return nextUncompletedStep?.config
  }

  if (nextUncompletedStep) {
    return nextUncompletedStep.config
  }

  return checkpoints.find(
    (checkpoint, index) =>
      index !== currentConfigIndex && checkpoint.data.state.id !== 'completed',
  )?.config
}

export const useIsOnboardingDashboardEnabled = () => {
  const featureEnabled =
    useHasFeatureFlag(FeatureFlags.OnboardingFlowV2) && isCommercial()

  const { isDemoMode } = useCurrentTenantInfo(featureEnabled)
  const { checkpoints, isLoading } = useGetOnboardingCheckpoints(featureEnabled)
  const allowedCheckpoints = useGetAllowedCheckpoints()
  const sandboxModeEnabled = hasTestCycleCookie()

  const allFirstStepsCompleted = checkIfFirstStepsCompleted(allowedCheckpoints)
  const checkpointsToTrack = allFirstStepsCompleted
    ? getNextStepsCheckpoints(allowedCheckpoints)
    : getFirstStepsCheckpoints(allowedCheckpoints)
  const hasUncompletedCheckpoints =
    checkpointsToTrack.filter(c => c.state.id !== 'completed').length > 0

  return {
    enabled:
      !isDemoMode && !sandboxModeEnabled && featureEnabled && hasUncompletedCheckpoints,
    isLoading,
    checkpoints,
  }
}

/** If onboarding step is completed, we do not need to refetch that onboarding step, it never transitions back to "not onboarded" state */
const onboardingStepCompletedCache: Record<OnboardingCheckpointAppCategory, boolean> = {
  teams: false,
  roles: false,
  goals: false,
  reviews: false,
  jobs: false,
  candidates: false,
  interviews: false,
  employeeRecords: false,
  timeManagement: false,
  documents: false,
  payroll: false,
  engagement: false,
}

const updateOnboardingStepCompletedCache = (
  checkpoints?: OnboardingCheckpointInterface[],
) => {
  if (!checkpoints) {
    return
  }
  const onboardingSteps = Object.keys(onboardingStepCompletedCache)
  checkpoints.forEach(step => {
    if (onboardingSteps.includes(step.category) && step.state.id === 'completed') {
      onboardingStepCompletedCache[step.category as OnboardingCheckpointAppCategory] =
        true
    }
  })
}

const getStepConfig = (category: OnboardingCheckpointAppCategory) => {
  return {
    teams: teamsConfig,
    roles: rolesConfig,
    goals: goalsConfig,
    reviews: reviewsConfig,
    jobs: jobsConfig,
    candidates: candidatesConfig,
    interviews: interviewsConfig,
    employeeRecords: employeeRecordsConfig,
    timeManagement: timeManagementConfig,
    documents: documentsConfig,
    payroll: payrollConfig,
    engagement: engagementConfig,
  }[category]
}

export const useAppOnboardingState = (category: OnboardingCheckpointAppCategory) => {
  const permissions = useSelector(selectPermissions)
  const isStepCompleted = onboardingStepCompletedCache[category]
  const { checkpoints, isLoading, isError } = useGetOnboardingCheckpoints(isStepCompleted)
  const stepConfig = getStepConfig(category)

  /** We need to fallback to regular UI in case onboarding endpoint failed, not to prevent using the app entirely */
  if (isStepCompleted || isError || !stepConfig) {
    return {
      onboarded: true,
      canOnboard: false,
      isLoading: false,
    }
  }

  const hasPermission = stepConfig.canView.every(permission =>
    permissions.includes(permission),
  )
  const firstStepsCompleted = checkIfFirstStepsCompleted(checkpoints)
  const paymentMethodCheckpoint = checkpoints?.find(
    checkpoint => checkpoint.category === 'paymentMethod',
  )
  const paymentMethodCompleted = paymentMethodCheckpoint
    ? paymentMethodCheckpoint.state.id === 'completed'
    : true

  const currentCheckpoint = checkpoints?.find(
    checkpoint => checkpoint.category === category,
  )

  const onboarded =
    (currentCheckpoint?.state.id === 'completed' ||
      currentCheckpoint?.completed_date_time != null) &&
    paymentMethodCompleted

  if (onboarded) {
    onboardingStepCompletedCache[category] = true
  }

  const canOnboard = (() => {
    if (!hasPermission || !paymentMethodCompleted) {
      return false
    }
    if (firstStepsCompleted) {
      return true
    }
    if (
      (category === 'teams' || category === 'roles') &&
      checkpoints &&
      !isCheckpointDisabled(category, checkpoints)
    ) {
      return true
    }
    return false
  })()

  return {
    onboarded,
    canOnboard,
    isLoading,
  }
}

export const usePrefetchOnboardingData = (enabled: boolean) => {
  const workspaceContext = useWorkspaceContext()

  const isCoreInstance =
    workspaceContext?.workspace?.toLocaleLowerCase() === CORE_INSTANCE_WORKSPACE
  const isEnabled = isCommercial() && enabled && !isCoreInstance

  const { data } = useFetch<{ results: OnboardingCheckpointInterface[] }>(
    API.ONBOARDING_CHECKPOINTS_V2,
    'v2',
    undefined,
    true,
    {
      enabled: isEnabled,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
  useFetch(`${API.TENANTS}/currentTenantInfo`, undefined, undefined, true, {
    enabled: isEnabled,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
  updateOnboardingStepCompletedCache(data?.results)
}
