import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import GeneralExamples from '@src/pages/Forms/RoleForm/General/GeneralExamples'
import { useToggle } from '@revolut/ui-kit'

export const InfoSideBar = () => {
  const [isSidebarOpen, toggleSidebar] = useToggle({ defaultState: true })

  return (
    <SideBar
      useLayout
      title="Information"
      isOpen={isSidebarOpen}
      onClose={toggleSidebar.off}
    >
      <GeneralExamples />
    </SideBar>
  )
}
