import React from 'react'
import { useSelector } from 'react-redux'
import { VStack, Widget } from '@revolut/ui-kit'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { Grid } from '@src/components/CommonSC/Grid'
import { getSettingsLinkConfig } from '@src/pages/Settings/common/config'
import { performanceSettingsLinkConfig } from './config'

export const SettingsLanding = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const config = getSettingsLinkConfig({
    config: performanceSettingsLinkConfig,
    permissions,
    featureFlags,
    type: 'settings',
  })

  return (
    <Widget>
      <Grid flow="row" gap={24}>
        <VStack width="100%">
          {config.performanceGeneral?.component}
          {config.performanceCycleDuration?.component}
          {config.performanceReviewTypes?.component}
          {config.performancePIP?.component}
          {config.performanceProbation?.component}
          {config.performanceInsights?.component}
        </VStack>
      </Grid>
    </Widget>
  )
}
