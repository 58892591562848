import React, { useEffect, useState } from 'react'

import { ItemSkeleton, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CompetencyMatrixInterface,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { useSeniorityRange } from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/useSeniorityRange'
import { SeniorityInterface } from '@src/interfaces/seniority'

import { SeniorityLevelsWidget } from '../../../../features/Specialisations/components/SeniorityLevelsWidget'
import { CultureFitSkillsWidget } from '@src/features/Specialisations/components/CultureFitSkillsWidget'
import { CompetencyMatrixWidget } from '@src/features/Specialisations/components/CompetencyMatrixWidget'
import { AllowedSenioritiesWidget } from '@src/features/Specialisations/components/AllowedSenioritesWidget'

export const CompetencyMatrix = () => {
  const { values } = useLapeContext<SpecialisationInterface>()

  const [role, setRole] = useState<RoleInterface>()

  const [filteredSeniorities, setFilteredSeniorities] = useState<SeniorityInterface[]>([])

  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])

  const { seniorities, loading } = useSeniorityRange({
    onFetchRoleSuccess: fetchedRole => {
      setDefaultCultureSkills(fetchedRole.culture_competency_matrix)
      setRole(fetchedRole)
    },
  })

  useEffect(() => {
    setFilteredSeniorities(seniorities)
  }, [seniorities])

  return (
    <VStack gap="s-16">
      <AllowedSenioritiesWidget />
      {loading ? (
        <ItemSkeleton />
      ) : (
        <CompetencyMatrixWidget role={role} filteredSeniorities={filteredSeniorities} />
      )}
      <CultureFitSkillsWidget role={role} defaultCultureSkills={defaultCultureSkills} />
      <SeniorityLevelsWidget role={role} />
    </VStack>
  )
}
