import { MergeIntegration } from '@src/interfaces/integrations'
import { UpdateTypes } from '@src/interfaces/kpis'
import { ConnectionType } from '../useMakeConnection'

export const integrationNameMap: Record<MergeIntegration, string> = {
  bamboohr: 'BambooHR',
  hibob: 'HiBob',
  personio: 'Personio',
}

export const connectionTypeToUpdateTypeMap: Record<ConnectionType, UpdateTypes> = {
  looker: UpdateTypes.looker,
  jira: UpdateTypes.roadmap,
  tableau: UpdateTypes.tableau,
  clickup: UpdateTypes.clickup,
  salesforce: UpdateTypes.salesforce,
  linear: UpdateTypes.linear,
  notion: UpdateTypes.notion,
  monday: UpdateTypes.monday,
}
