import React from 'react'
import { Token } from '@revolut/ui-kit'
import { RatingHistoryYAxisTick } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/ChartData'

interface Props {
  x: number
  y: number
  textAnchor: string
  payload: { value: number }
  ticks: RatingHistoryYAxisTick[]
}

export const YAxisTick = ({ x, y, textAnchor, payload: { value }, ticks }: Props) => {
  return (
    <text pointerEvents="none" textAnchor={textAnchor}>
      <tspan
        x={x}
        y={y}
        dy={value === 0 ? '0.5em' : 0}
        fill={Token.color.greyTone50}
        fontSize={12}
      >
        {ticks[value].isVisible ? ticks[value].label : ''}
      </tspan>
    </text>
  )
}
