import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ActionButton, Box, chain, Item, VStack, Widget } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { EmptyList } from '@src/features/AiInsights/Performance/components/InsightsGroups/common/EmptyList'
import {
  Metrics,
  MetricSources,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { PerformanceInsightsGroupSourceInterface } from '@src/features/AiInsights/Performance/interfaces/insights'

interface Props {
  isLoading: boolean
  sources: Array<PerformanceInsightsGroupSourceInterface<MetricSources, Metrics>>
  title: React.ReactNode
  description: React.ReactNode
  children: React.ReactNode
  onManageClick: VoidFunction
}

export const SourcesWidget = ({
  isLoading,
  sources,
  title,
  description,
  children,
  onManageClick,
}: Props) => {
  if (isLoading) {
    return (
      <Widget height={300}>
        <Loader />
      </Widget>
    )
  }

  return (
    <Widget>
      <VStack>
        <Item>
          <Item.Content>
            <Item.Title>{chain(title, sources.length)}</Item.Title>
            <Item.Description>{description}</Item.Description>
          </Item.Content>
          {sources.length > 0 && (
            <Item.Side>
              <ActionButton useIcon="Pencil" onClick={onManageClick}>
                <FormattedMessage id="common.manage.cta.label" defaultMessage="Manage" />
              </ActionButton>
            </Item.Side>
          )}
        </Item>
        <Box px="s-16" pb="s-16">
          {sources.length === 0 && (
            <EmptyList
              title={
                <FormattedMessage
                  id="performance.ai.insights.group.sources.empty.list.title"
                  defaultMessage="No data source selected"
                />
              }
              actions={
                <ActionButton useIcon="Plus" onClick={onManageClick}>
                  <FormattedMessage
                    id="performance.ai.insights.group.sources.add.cta.label"
                    defaultMessage="Add data source"
                  />
                </ActionButton>
              }
              imageId="3d-images-v2/3D221"
            />
          )}
          {sources.length > 0 && children}
        </Box>
      </VStack>
    </Widget>
  )
}
