import {
  CandidateMatch,
  CandidateMatchTagConfig,
} from '@src/features/CommonCandidatesTable/types'
import { Token } from '@revolut/ui-kit'

export const candidateMatchConfigs: Record<
  CandidateMatch,
  CandidateMatchTagConfig | null
> = {
  brilliant: {
    iconName: 'DoubleThumbs',
    text: 'Brilliant',
    color: Token.color.success,
    bg: Token.color.success_10,
  },
  good: {
    iconName: 'SocialLike',
    text: 'Good',
    color: Token.color.accentNeutral,
    bg: Token.color.actionBackground,
  },
  ok: {
    iconName: 'SocialLike',
    text: 'Ok',
    color: Token.color.accentNeutral,
    bg: Token.color.actionBackground,
  },
  poor: {
    iconName: 'SocialDislike',
    text: 'Poor',
    color: Token.color.greyTone50,
    bg: Token.color.greyTone8,
  },
  no_match: {
    iconName: 'SocialDislike',
    text: 'No match',
    color: Token.color.greyTone50,
    bg: Token.color.greyTone8,
  },
  not_available: {
    text: 'N/A',
    color: Token.color.greyTone50,
    bg: Token.color.greyTone8,
  },
}
