import React, { useEffect, useRef, useState } from 'react'
import {
  deliverableOptionToFinalGrade,
  getDeliverableRatingByFinalGrade,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  useScoreCardGrades,
  DeliverableGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CombinedHelp'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { ActionButton, VStack } from '@revolut/ui-kit'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import isNumber from 'lodash/isNumber'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { useGetSegmentedDeliveryGrades } from '../getSegmentedDeliveryGrades'
import { PerformanceInsights } from '@src/features/AiInsights/Performance/components/Insights/Deliverables/PerformanceInsights'
import { useAiInsightsAvailability } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { ScorecardGoals } from '../components/ScorecardGoals/ScorecardGoals'
import { getGradeEvaluationCriterionByDeliverablesProgress } from '@src/utils/performance'

interface DeliverablesCardInterface extends CommonCardProps {
  deliverablesMissingJustification?: number[]
  isSegmentedDeliverables?: boolean
  isPreview?: boolean
}

export const DeliverablesCard = connect(
  ({
    onHelpClick,
    gradesMap,
    deliverablesMissingJustification,
    isSegmentedDeliverables,
    isPreview,
  }: DeliverablesCardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasDeliverables = !!values.review_data.deliverables
    const ref = useRef<HTMLDivElement>(null)
    const { grades } = useRecommendedGradesContext()
    const [goalStats, setGoalsStats] = useState<number>()

    const getSegmentedDeliverablesGrades = useGetSegmentedDeliveryGrades()

    const { isAvailable: canAccessAiInsights, relation: aiInsightsRelation } =
      useAiInsightsAvailability(values)

    useEffect(() => {
      if (errors.review_data?.deliverables) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.deliverables])

    if (!hasDeliverables) {
      return null
    }

    const { gradeProps: goalDeliverablesGrades, evaluationCriteria } =
      useScoreCardGrades()
    const evaluationCriterion = goalStats
      ? getGradeEvaluationCriterionByDeliverablesProgress(goalStats, evaluationCriteria)
      : null

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.deliverablesGrade
        ? gradesMap[grades?.deliverablesGrade]
        : undefined

    const onSelectGrade = (
      reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
      grade: Pick<DeliverableGradeOption, 'key'>,
      field: CardField,
    ) => {
      if (isSegmentedDeliverables) {
        set(values, `${field.field}.sections.0.value`, grade.key)
        set(values, `${field.field}.rating`, grade.key)
      } else {
        reviewData.deliverables?.cards?.forEach(card => {
          set(card, `sections.0.value`, grade.key)
          set(card, `rating`, grade.key)
        })
        set(
          reviewData,
          'deliverables.section_grade',
          deliverableOptionToFinalGrade(grade.key),
        )
      }
    }

    const fields = isSegmentedDeliverables
      ? values.review_data.deliverables?.cards?.map((card, ind) => {
          const justification = card.justifications?.[0]
          return {
            field: `review_data.deliverables.cards.${ind}`,
            title: card.name,
            grades: getSegmentedDeliverablesGrades(
              get(values, `review_data.deliverables.cards.${ind}.criteria_key`),
            ),
            recommendedGrade: evaluationCriterion
              ? getDeliverableRatingByFinalGrade(evaluationCriterion.codename)
              : undefined,
            cardJustification:
              justification && 'comment' in justification ? justification.comment : null,
          }
        }) || []
      : [
          {
            field: `review_data.deliverables.cards.0`,
            title: 'Contribution and impact',
            grades: goalDeliverablesGrades,
            recommendedGrade: evaluationCriterion
              ? getDeliverableRatingByFinalGrade(evaluationCriterion.codename)
              : undefined,
            cardJustification:
              values.review_data.deliverables?.justifications?.[0]?.comment || null,
          },
        ]

    // preselect grade on contribution and impact
    useEffect(() => {
      if (
        !isSegmentedDeliverables &&
        evaluationCriterion &&
        values.review_data?.deliverables?.cards &&
        fields[0]
      ) {
        if (!values.review_data.deliverables.section_grade) {
          onSelectGrade(
            values.review_data,
            { key: getDeliverableRatingByFinalGrade(evaluationCriterion.codename) },
            fields[0],
          )
        }
      }
    }, [evaluationCriterion, isSegmentedDeliverables, values.review_data, fields])

    return (
      <Card
        data={values}
        renderExpandedContent={expContentField => (
          <Deliverables
            reviewData={values.review_data}
            deliverablesGrades={
              isSegmentedDeliverables
                ? getSegmentedDeliverablesGrades(
                    get(
                      values,
                      `review_data.deliverables.cards.${expContentField.cardIndex}.criteria_key`,
                    ),
                  )
                : goalDeliverablesGrades
            }
            onSelectGrade={(reviewData, grade) =>
              onSelectGrade(reviewData, grade, expContentField.field)
            }
            gradesMap={gradesMap}
            selectedField={expContentField}
            isSegmentedDeliverables={!!isSegmentedDeliverables}
          />
        )}
        renderExceedingContent={excContentField => {
          const justificationComment = get(
            values,
            `review_data.deliverables.justifications.${excContentField.cardIndex}.comment`,
          )
          return (
            <Deliverables
              reviewData={values.review_data}
              deliverablesGrades={goalDeliverablesGrades}
              showJustificationError={
                !justificationComment &&
                isNumber(excContentField.cardIndex) &&
                !!deliverablesMissingJustification?.includes(excContentField.cardIndex)
              }
              justificationOnly
              gradesMap={gradesMap}
              selectedField={excContentField}
              isSegmentedDeliverables={!!isSegmentedDeliverables}
            />
          )
        }}
        additionalInfo={
          <VStack gap="s-16">
            {isPreview && (
              <ActionButton
                use={InternalLink}
                to={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.EVALUATION_CRITERIA}
              >
                Manage evaluation criteria
              </ActionButton>
            )}
            {isSegmentedDeliverables ? undefined : (
              <ScorecardGoals
                setGoalsStats={setGoalsStats}
                gradesMap={gradesMap}
                values={values}
                team={values.team}
              />
            )}
            {canAccessAiInsights && !!aiInsightsRelation && values.cycle && (
              <PerformanceInsights
                employeeId={values.reviewed_employee.id}
                cycleId={Number(values.cycle.id)}
                relation={aiInsightsRelation}
              />
            )}
          </VStack>
        }
        type={CardContentTypes.DELIVERABLES}
        title={isSegmentedDeliverables ? 'Deliverables' : 'Goals'}
        stat={goalStats}
        finalRating={finalRating}
        icon="Target"
        fields={fields}
        onSelectDeliverableGrade={(grade, field) =>
          onSelectGrade(values.review_data, grade, field)
        }
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.sections?.[0]?.value
          return !!ratingValue && ratingValue === grade.key
        }}
        justification={values?.review_data?.deliverables?.skipped_section_justification}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
      />
    )
  },
)
