import { useEffect, useState } from 'react'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'

import { useGetEngagementSurvey } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { RoundOption, TimelineFilterInterface } from '../types'
import { useGetDatesRange } from './useGetDatesRange'
import { useGetSurveyRoundsOptions } from './useGetSurveyRounds'

export interface UseSurveyRoundSelectorReturnType extends TimelineFilterInterface {
  refetchRounds: VoidFunction
  survey: {
    options: IdAndName[]
    isLoadingOptions: boolean
    value: IdAndName | undefined
    setValue: (option: IdAndName) => void
    roundsPreviewSurveyId: number | undefined
    setRoundsPreviewSurveyId: (id: number | undefined) => void
    data: EngagementSurveyInterface | undefined
  }
}

export const useSurveyRoundSelector = ({
  forcedSurvey,
  forcedRoundOptionId,
  includeAllStatuses,
  enabled = true,
}: {
  forcedSurvey?: IdAndName | undefined
  forcedRoundOptionId?: number | undefined
  includeAllStatuses?: boolean
  enabled?: boolean
} = {}): UseSurveyRoundSelectorReturnType => {
  const [roundsPreviewSurveyId, setRoundsPreviewSurveyId] = useState<number>()
  const [selectedSurvey, setSelectedSurvey] = useState<IdAndName>()
  const [selectedRound, setSelectedRound] = useState<RoundOption | null>()

  const { data: surveyData } = useGetEngagementSurvey(selectedSurvey?.id)

  const { setSelectedDatesRange, dateFrom, dateTo } = useGetDatesRange(
    selectedRound,
    'rounds',
  )

  const { data: surveyOptions = [], isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted)

  useEffect(() => {
    if (!enabled) {
      return
    }
    if (!forcedSurvey && surveyOptions[0]) {
      setSelectedSurvey(surveyOptions[0])
    } else if (forcedSurvey && selectedSurvey?.id !== forcedSurvey.id) {
      setSelectedSurvey(forcedSurvey)
    }
  }, [enabled, forcedSurvey, surveyOptions])

  const roundsFilters = includeAllStatuses ? { status: '' } : undefined

  const {
    options: roundsOptions,
    isLoading: isLoadingRoundsOptions,
    refetch: refetchRoundsOptions,
  } = useGetSurveyRoundsOptions(selectedSurvey?.id, roundsFilters)

  useEffect(() => {
    if (!enabled) {
      return
    }
    if (
      !forcedRoundOptionId &&
      selectedRound === undefined &&
      selectedSurvey?.id &&
      !isLoadingRoundsOptions
    ) {
      const roundsByStatus = groupBy(roundsOptions, round => round.status.id)
      setSelectedRound(
        roundsByStatus.completed?.[0] ||
          roundsByStatus.ongoing?.[roundsByStatus.ongoing.length - 1] ||
          roundsByStatus.upcoming?.[roundsByStatus.upcoming.length - 1] ||
          roundsOptions?.[0] ||
          null,
      )
    } else if (forcedRoundOptionId && selectedRound?.id !== forcedRoundOptionId) {
      const forcedRound = roundsOptions.find(round => round.id === forcedRoundOptionId)
      setSelectedRound(forcedRound)
    }
  }, [
    enabled,
    forcedRoundOptionId,
    roundsOptions,
    selectedSurvey?.id,
    isLoadingRoundsOptions,
  ])

  useEffect(() => {
    const selectedRoundFromRefetchedOptions = roundsOptions.find(
      roundOption => roundOption.id === selectedRound?.id,
    )
    if (
      selectedRoundFromRefetchedOptions &&
      !isEqual(selectedRoundFromRefetchedOptions, selectedRound)
    ) {
      setSelectedRound(selectedRoundFromRefetchedOptions)
    }
  }, [roundsOptions])

  const { options: previewRoundsOptions, isLoading: isLoadingPreviewRoundsOptions } =
    useGetSurveyRoundsOptions(roundsPreviewSurveyId, roundsFilters)

  return {
    dateFrom,
    dateTo,
    survey: {
      options: surveyOptions,
      isLoadingOptions: isLoadingSurveyOptions,
      value: selectedSurvey,
      setValue: setSelectedSurvey,
      roundsPreviewSurveyId,
      setRoundsPreviewSurveyId,
      data: surveyData,
    },
    round: {
      options: roundsPreviewSurveyId ? previewRoundsOptions : roundsOptions,
      isLoadingOptions: isLoadingRoundsOptions,
      isLoadingPreviewOptions: isLoadingPreviewRoundsOptions,
      value: selectedRound,
      setValue: setSelectedRound,
    },
    refetchRounds: refetchRoundsOptions,
    clearAll: () => {
      setSelectedDatesRange([])
      setSelectedRound(undefined)
    },
    // calendar mode is not supported yet for the subheader selector
    mode: { value: 'rounds', setValue: () => {} },
    calendar: { value: [], setValue: () => {} },
  }
}
