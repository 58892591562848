import { MoreBar, useToggle } from '@revolut/ui-kit'
import React from 'react'
import { useGetPermissions } from '../hooks/useGetPermissions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { EmployeeInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import RequestFeedback from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/RequestFeedback'
import { PerformanceSelector, ReviewCategory } from '@src/interfaces/performance'
import { useGetPeriodTypes } from '@src/utils/performance'
import {
  isNewFlowRequestsResponse,
  isRequestFeedbackInterfaceArray,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { useInvalidatePerformanceSettingsQuery } from '@src/api/performance'

interface Props {
  employee: EmployeeInterface
  selectedPeriod: PerformanceSelector
}

export const PerformanceCycleActions = ({ employee, selectedPeriod }: Props) => {
  const permissions = useGetPermissions({ employee, selectedPeriod })
  const [isReviewSidebarOpen, toggleReviewsSidebar] = useToggle()
  const { isNewFlow } = useGetPeriodTypes(selectedPeriod)
  const invalidatePerformanceSettingQueries = useInvalidatePerformanceSettingsQuery()
  const { data: performanceReviewRequestsData, isLoading } =
    useFetcherPerformanceRequests({
      category: selectedPeriod.category,
      isNewFlow,
      id: employee.id,
      performanceCycle: selectedPeriod,
    })

  const performanceReviewRequests = isNewFlow
    ? isNewFlowRequestsResponse(performanceReviewRequestsData)
      ? performanceReviewRequestsData.results
      : undefined
    : isRequestFeedbackInterfaceArray(performanceReviewRequestsData)
    ? performanceReviewRequestsData
    : undefined

  return (
    <>
      {permissions.canViewUpwards && (
        <MoreBar.Action
          useIcon="Questionnaire"
          use={InternalLink}
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.UPWARDS, {
            id: employee.id,
          })}
        >
          View upwards review
        </MoreBar.Action>
      )}
      {permissions.canRequestReview && (
        <MoreBar.Action use="button" useIcon="Plus" onClick={toggleReviewsSidebar.switch}>
          Request review
        </MoreBar.Action>
      )}
      {isReviewSidebarOpen && (
        <RequestFeedback
          canRequest={permissions.canRequestReview}
          performanceCycle={selectedPeriod}
          isNewFlow={isNewFlow}
          category={ReviewCategory.Performance}
          onClose={toggleReviewsSidebar.off}
          id={employee.id}
          requests={performanceReviewRequests}
          onAddRequest={invalidatePerformanceSettingQueries}
          fetching={isLoading}
        />
      )}
    </>
  )
}
