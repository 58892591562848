import React from 'react'
import { Text, Token } from '@revolut/ui-kit'

interface TranscriptSpeakerProps {
  speaker: string
  type?: 'self' | 'other'
}

const COLOR_VARIANT = {
  self: Token.color.pink,
  other: Token.color.lime,
}

const UNKNOWN = Token.color.cyan

export const TranscriptSpeaker = ({ speaker, type }: TranscriptSpeakerProps) => {
  return (
    <Text color={type ? COLOR_VARIANT[type] : UNKNOWN} fontWeight={500}>
      {speaker}
    </Text>
  )
}
