import { format } from 'date-fns'

export function groupChangelogsByMonth<
  T extends {
    change_time: string
  },
>(changelogs: T[]) {
  return changelogs.reduce<Record<string, T[]>>((acc, changelog) => {
    const monthKey = format(new Date(changelog.change_time), 'MMM yyyy')

    if (!acc[monthKey]) {
      acc[monthKey] = []
    }

    acc[monthKey].push(changelog)

    return acc
  }, {})
}
