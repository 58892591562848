import { AxiosError } from 'axios'

export const checkNonInputFieldsValidations = (
  error: AxiosError | undefined,
  fields: string[],
  callback?: VoidFunction,
) => {
  if (error?.response?.status !== 400) {
    throw error
  }
  const hasNonInputFieldError = Object.keys(error.response.data || {}).some(field =>
    fields.includes(field),
  )
  if (hasNonInputFieldError) {
    callback?.()
    return true
  }
  throw error
}
