import { Button, Header, Input, InputGroup, Popup } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { CareersApplicationEducationInterface } from '@src/interfaces/careers'
import { IdAndName } from '@src/interfaces'
import isEmpty from 'lodash/isEmpty'
import RangeMonthPicker from '@components/Inputs/RangeMonthPicker/RangeMonthPicker'
import {
  useFetchDegrees,
  useFetchEducationMajors,
  useFetchUniversities,
} from '@src/api/recruitment/careers'
import { RadioSelectInput } from '@src/pages/Careers/CareersApplication/components/RadioSelect/RadioSelectInput'

type Props = {
  data: Partial<CareersApplicationEducationInterface>
  onClose: () => void
  onSubmit: (data: CareersApplicationEducationInterface) => void
}

const EducationPopup = ({ data, onClose, onSubmit }: Props) => {
  const { data: universities, isLoading: loadingUniversities } = useFetchUniversities()
  const { data: educationMajors, isLoading: loadingEducationMajors } =
    useFetchEducationMajors()
  const { data: degrees, isLoading: loadingDegrees } = useFetchDegrees()
  const [university, setUniversity] = useState<IdAndName | undefined>(data.university)
  const [major, setMajor] = useState<IdAndName | undefined>(data.major)
  const [degree, setDegree] = useState<IdAndName<string> | undefined>()
  const [startDate, setStartDate] = useState<Date | undefined | null>(
    data.start_date ? new Date(data.start_date) : undefined,
  )
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    data.end_date ? new Date(data.end_date) : undefined,
  )
  const [gpa, setGpa] = useState<number | undefined>(data.gpa)

  const isValid = !!(university && major && degree && startDate && endDate)

  useEffect(() => {
    if (degrees && data.degree) {
      setDegree(degrees.find(item => item.id === data.degree))
    }
  }, [degrees, data.degree])

  const onClickAdd = () => {
    if (isValid) {
      onSubmit({
        university,
        major,
        degree: degree.id,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        gpa,
      })
    }
  }

  return (
    <Popup variant="modal-view" open onClose={onClose}>
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Add education</Header.Title>
      </Header>
      <InputGroup>
        <RadioSelectInput<IdAndName>
          getValueKey={item => item.id}
          placeholder="University"
          options={
            universities?.map(value => ({
              key: value.id,
              label: value.name,
              value,
            })) || []
          }
          value={university}
          onChange={(val: IdAndName | null) => {
            if (val) {
              setUniversity(val)
            }
          }}
          loading={loadingUniversities}
        />
        <RadioSelectInput<IdAndName>
          getValueKey={item => item.id}
          placeholder="Major"
          options={
            educationMajors?.map(value => ({
              key: value.id,
              label: value.name,
              value,
            })) || []
          }
          value={major}
          onChange={(val: IdAndName | null) => {
            if (val) {
              setMajor(val)
            }
          }}
          loading={loadingEducationMajors}
        />
        <RadioSelectInput<IdAndName<string>>
          getValueKey={item => item.id}
          placeholder="Degree"
          options={
            degrees?.map(value => ({
              key: value.id,
              label: value.name,
              value,
            })) || []
          }
          value={degree}
          onChange={(val: IdAndName<string> | null) => {
            if (val) {
              setDegree(val)
            }
          }}
          loading={loadingDegrees}
        />
        <RangeMonthPicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          endDateLabel="Expected end date"
        />
        <Input
          type="number"
          label="GPA (optional)"
          value={gpa}
          onChange={e => {
            const val = e.currentTarget.value
            if (val !== '') {
              setGpa(+val)
            }
          }}
        />
      </InputGroup>
      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={onClickAdd} disabled={!isValid}>
          {isEmpty(data) ? 'Add' : 'Change'}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default EducationPopup
