import React, { useEffect, useState } from 'react'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Dropdown, MoreBar, Text, Token, useDropdown } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  hiringProcessActionColumn,
  hiringProcessDurationColumn,
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSeniorityColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
  hiringProcessTypeColumn,
} from '@src/constants/columns/hiringProcess'
import { useOrdering } from '@components/Table/hooks'
import { selectorKeys } from '@src/constants/api'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { getSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import { history, pathToUrl } from '@src/utils/router'
import { formHiringProcessStagesRoleRequest } from '@src/api/hiringProcess'
import HiringStagePreviewForm from '@src/pages/Forms/RoleForm/HiringProcess/HiringStagePreviewForm'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { Link, useLocation } from 'react-router-dom'
import { updateStagesAfterDelete } from '@src/utils/hiring'
import HiringStageDeleteButton from '@src/pages/Forms/RoleForm/HiringProcess/HiringStageDeleteButton'
import { handleError } from '@src/api'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const Row = (
  handleDelete: (data: HiringProcessInterface) => Promise<void>,
): RowInterface<HiringProcessInterface> => ({
  cells: [
    dragIconColumn,
    {
      ...hiringProcessTitleWithLockColumn,
      width: 185,
    },
    {
      ...hiringProcessTypeColumn,
      width: 125,
    },
    {
      ...hiringProcessDurationColumn,
      width: 100,
    },
    {
      ...hiringProcessSeniorityColumn,
      width: 106,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 200,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 90,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 139,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 240,
    },
    {
      ...hiringProcessActionColumn,
      width: 100,
      insert: ({ data }) => (
        <HiringStageDeleteButton onConfirm={() => handleDelete(data)} />
      ),
    },
  ],
})

export const HiringProcessPageContent = () => {
  const location = useLocation<{ stageId?: number } | undefined>()
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(
    location.state?.stageId,
  )
  const [hiringStages, setHiringStages] = useState<OptionInterface[]>([])

  const dropdown = useDropdown()
  const context = useLapeContext<RoleInterface>()
  const { values, errors } = context
  const changeOrder = (d: HiringProcessInterface[]) => {
    values.hiring_process_rounds = d
  }
  const ordering = useOrdering(values?.hiring_process_rounds, changeOrder)

  useEffect(() => {
    fetchHiringStages()
    history.replace({ state: { ...location.state, stageId: undefined } })
  }, [])

  const fetchHiringStages = async () => {
    const result = await getSelectors(selectorKeys.hiring_stages)
    if (result?.data?.options) {
      setHiringStages(result?.data?.options)
    }
  }

  const handleDelete = async (stage: HiringProcessInterface) => {
    try {
      await formHiringProcessStagesRoleRequest.update(
        {
          status: Statuses.archived,
        },
        {
          id: String(stage.id),
        },
      )
      updateStagesAfterDelete(context, stage.id)
    } catch (e) {
      handleError(e)
    }
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      setSelectedStageId(data.id)
    }
  }
  return (
    <>
      <HiringStagePreviewForm
        id={selectedStageId}
        onClose={() => setSelectedStageId(undefined)}
      />
      <Table.Widget>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <Dropdown {...dropdown.getTargetProps()} autoClose>
              <Dropdown.Item
                color={Token.color.accent}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={getLocationDescriptor(pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE), {
                  role: { id: values.id, name: values.name },
                })}
                use={Link}
              >
                <Text color={Token.color.accent}>+ Add new</Text>
              </Dropdown.Item>

              {hiringStages.map(stage => {
                return (
                  <Dropdown.Item
                    key={stage.id}
                    // @ts-expect-error object works fine here, but UI kit expects string
                    to={getLocationDescriptor(
                      pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE),
                      {
                        role: { id: values.id, name: values.name },
                        companyHiringStageId: stage.id,
                      },
                    )}
                    use={Link}
                  >
                    {stage.name}
                  </Dropdown.Item>
                )
              })}
            </Dropdown>
            <MoreBar.Action {...dropdown.getAnchorProps()} useIcon="ChevronDown">
              Add a round
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<HiringProcessInterface>
            name={TableNames.HiringProcess}
            dataType="Hiring stage"
            useWindowScroll
            row={Row(handleDelete)}
            cellErrors={errors?.hiring_process_rounds}
            data={values?.hiring_process_rounds}
            count={values?.hiring_process_rounds?.length || 0}
            orderingMode
            mainColumnIndex={1}
            disabledFiltering
            {...ordering}
            onRowClick={handleRowEdit}
            emptyState={<EmptyTableRaw title="Hiring stages will appear here" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
