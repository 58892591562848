import React, { useCallback, useMemo } from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { FilterButton } from '@revolut/ui-kit'
import { useTableReturnType } from '@components/TableV2/hooks'
import { useGetFavourites } from '@src/api/favourites'
import { GlobalSearchTypes } from '@src/interfaces/globalSearch'

interface FavouritesFilterProps {
  table: useTableReturnType<any, any>
}

const typeToKey: Record<
  Exclude<
    GlobalSearchTypes,
    'company' | 'team_goal' | 'department_goal' | 'employee_goal' | 'company_goal'
  >,
  string
> = {
  employee: LOCAL_STORAGE.SHOW_FAVOURITE_EMPLOYEES,
  team: LOCAL_STORAGE.SHOW_FAVOURITE_TEAMS,
  requisition: LOCAL_STORAGE.SHOW_FAVOURITE_REQUISITIONS,
  department: LOCAL_STORAGE.SHOW_FAVOURITE_DEPARTMENTS,
  function: LOCAL_STORAGE.SHOW_FAVOURITE_FUNCTIONS,
  role: LOCAL_STORAGE.SHOW_FAVOURITE_ROLES,
  specialisation: LOCAL_STORAGE.SHOW_FAVOURITE_SPECIALISATIONS,
  skill: LOCAL_STORAGE.SHOW_FAVOURITE_SKILLS,
}

export const useFavouritesFilter = (type: GlobalSearchTypes) => {
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const [showFavourites, setShowFavourites] = useLocalStorage(typeToKey[type], false)
  const { data } = useGetFavourites()

  const favourites = useMemo(
    () =>
      data?.results
        .filter(favourite => favourite.favourite_object.category.id === type)
        .map(favourite => ({
          id: favourite.favourite_object.object_id,
          name: favourite.favourite_object.object_name,
        })) || [],
    [data],
  )

  const getFavouritesFilter = useCallback(
    (setFilter: boolean) => ({
      filters: setFilter ? favourites : [],
      columnName: 'id',
      nonResettable: true,
    }),
    [favourites],
  )

  const onToggleFavourites = useCallback(
    table => {
      setShowFavourites(!showFavourites)
      table.onFilterChange(getFavouritesFilter(!showFavourites))
    },
    [showFavourites, getFavouritesFilter],
  )

  const FavouritesFilter = useMemo(() => {
    const FilterComponent = ({ table }: FavouritesFilterProps) =>
      favourites.length ? (
        <FilterButton onClick={() => onToggleFavourites(table)} active={showFavourites}>
          Favourites
        </FilterButton>
      ) : null
    return FilterComponent
  }, [showFavourites, favourites])

  return {
    FavouritesFilter,
    initialFilter: showFavourites ? getFavouritesFilter(true) : undefined,
  }
}
