import React, { useState } from 'react'
import { BottomSheet, Button, Header, Input, InputGroup } from '@revolut/ui-kit'

import { OrganisationStructureTab } from '@src/pages/Settings/Structures/types'

interface EditStructureTabPopupProps {
  open: boolean
  onClose: VoidFunction
  initialValues?: OrganisationStructureTab
  onSave: (values: OrganisationStructureTab) => void
}

export const EditStructureTabPopup = ({
  open,
  onClose,
  onSave,
  initialValues,
}: EditStructureTabPopupProps) => {
  const [state, setState] = useState(initialValues)

  if (!state || !initialValues) {
    return null
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Edit</Header.Title>
        <Header.Description>
          Updates the title of the tab being displayed on every page
        </Header.Description>
      </Header>
      <InputGroup>
        <Input
          label="Tab name"
          value={state.name}
          onChange={e => {
            if (state) {
              setState({ ...state, name: e.currentTarget.value })
            }
          }}
        />
      </InputGroup>
      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSave(state)}>Confirm</Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
