import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useRevokeConsentAndDeleteTranscripts } from '@src/api/recruitment/candidates'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

interface WithdrawConsentAndDeleteTranscriptsProps {
  candidateId: number | string
}

export const WithdrawConsentAndDeleteTranscripts = ({
  candidateId,
}: WithdrawConsentAndDeleteTranscriptsProps) => {
  const { mutateAsync, isLoading } = useRevokeConsentAndDeleteTranscripts(candidateId)

  const hasAIInterviewSummarisationInternal = useHasFeatureFlag(
    FeatureFlags.AIInterviewSummarisationInternal,
  )

  if (!hasAIInterviewSummarisationInternal) {
    return null
  }

  return (
    <MoreBar.Action
      pending={isLoading}
      useIcon="Reverted"
      variant="negative"
      onClick={() => mutateAsync({})}
    >
      Withdraw consent and delete transcripts
    </MoreBar.Action>
  )
}
