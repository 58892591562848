import React from 'react'
import { List, Token, Text, useTooltip, Tooltip } from '@revolut/ui-kit'
import { InterviewRoundEvaluationItem } from '@src/interfaces/interviewTool'

interface CVInsightsListItemProps {
  evaluation: InterviewRoundEvaluationItem
  isRedFlag: boolean
}

export const CVInsightsListItem = ({
  evaluation,
  isRedFlag,
}: CVInsightsListItemProps) => {
  const tooltip = useTooltip()

  return (
    <List.Item
      useIcon={
        !isRedFlag && evaluation.has_evaluation ? '16/CheckCircle' : '16/CrossCircle'
      }
      color={
        !isRedFlag && evaluation.has_evaluation
          ? Token.color.success
          : Token.color.warning
      }
      key={evaluation.id}
    >
      <Text color={Token.color.greyTone50} {...tooltip.getAnchorProps()}>
        {evaluation.trait_description}
      </Text>
      <Tooltip {...tooltip.getTargetProps()} maxWidth={400}>
        {evaluation.justification}
      </Tooltip>
    </List.Item>
  )
}
