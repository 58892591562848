import React from 'react'
import { Avatar, Item, VStack, Text, Flex, Tag, Skeleton } from '@revolut/ui-kit'
import { times } from '@src/utils/times'
import { OrganisationStructureTab } from '@src/pages/Settings/Structures/types'

interface TabsItemProps {
  title: React.ReactNode
  description: React.ReactNode
  onOpen: VoidFunction
  selectedTabs?: OrganisationStructureTab[]
}

export const TabsItem = ({ title, description, selectedTabs, onOpen }: TabsItemProps) => {
  return (
    <Item onClick={onOpen} use="button" type="button" variant="disclosure">
      <Item.Avatar>
        <Avatar useIcon="Browser" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>
          <VStack space="s-8">
            <Text>{description}</Text>
            <Flex flexWrap="wrap" gap="s-8">
              {selectedTabs?.map(tab => (
                <Tag variant="status" useIcon={tab.icon} key={tab.id}>
                  {tab.name}
                </Tag>
              )) || (
                <>
                  {times(
                    index => (
                      <Skeleton width={100} height={24} key={index} />
                    ),
                    9,
                  )}
                </>
              )}
            </Flex>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
