import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsGroupSourceInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import {
  Metrics,
  MetricSources,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { metricModel } from '@src/features/AiInsights/Performance/models/metricModel'
import { EligibleGroupFiltersInterface } from '@src/interfaces/reviewCycles'

const eligibilityFiltersKeys = [
  'id',
  'team_id',
  'team__department_id',
  'specialisation_id',
  'position_id',
  'position__function_id',
  'status',
  'is_in_pip',
  'employee_type',
  'contract_type',
  'is_in_probation',
  'joining_date_time',
  'end_of_probation_date_time',
  'end_of_pip_date_time',
  'location_id',
  'entity_id',
]

interface PerformanceInsightsGroupModel {
  getSource: <T extends MetricSources, K extends Metrics>(
    group: PerformanceInsightsGroupInterface,
    source: T,
  ) => PerformanceInsightsGroupSourceInterface<T, K> | undefined
  hasSourceEnabled: <T extends MetricSources>(
    group: PerformanceInsightsGroupInterface,
    sourceName: T,
  ) => boolean
  hasMetricEnabled: <T extends MetricSources>(
    group: PerformanceInsightsGroupInterface,
    sourceName: T,
    metricName: Metrics,
  ) => boolean
  hasAnyMetricEnabledForSource: <T extends MetricSources>(
    group: PerformanceInsightsGroupInterface,
    sourceName: T,
  ) => boolean
  hasAnyNumericMetricEnabledForSource: <T extends MetricSources>(
    group: PerformanceInsightsGroupInterface,
    sourceName: T,
  ) => boolean
  isEligibilityFilterKey: (key: string) => key is keyof EligibleGroupFiltersInterface
}

const getSource = <T extends MetricSources, K extends Metrics>(
  group: PerformanceInsightsGroupInterface,
  sourceName: T,
): PerformanceInsightsGroupSourceInterface<T, K> | undefined =>
  group.sources.find(
    (source): source is PerformanceInsightsGroupSourceInterface<T, K> =>
      source.name === sourceName,
  )

const hasSourceEnabled = <T extends MetricSources>(
  group: PerformanceInsightsGroupInterface,
  sourceName: T,
): boolean => {
  return !!getSource(group, sourceName)
}

const hasMetricEnabled = <T extends MetricSources>(
  group: PerformanceInsightsGroupInterface,
  sourceName: T,
  metricName: Metrics,
): boolean => {
  const source = getSource(group, sourceName)

  if (!source) {
    return false
  }

  return !!source.metrics.find(
    metric => metric.name === metricName && metricModel.isEnabled(metric),
  )
}

const hasAnyMetricEnabledForSource = <T extends MetricSources>(
  group: PerformanceInsightsGroupInterface,
  sourceName: T,
): boolean => {
  const source = getSource(group, sourceName)

  return !!source && source.metrics.some(metricModel.isEnabled)
}

const hasAnyNumericMetricEnabledForSource = <T extends MetricSources>(
  group: PerformanceInsightsGroupInterface,
  sourceName: T,
): boolean => {
  const source = getSource(group, sourceName)

  return (
    !!source && source.metrics.filter(metricModel.isNumeric).some(metricModel.isEnabled)
  )
}

const isEligibilityFilterKey = (
  key: string,
): key is keyof EligibleGroupFiltersInterface => eligibilityFiltersKeys.includes(key)

const createPerformanceInsightsGroupModel = (): PerformanceInsightsGroupModel => ({
  getSource,
  hasSourceEnabled,
  hasMetricEnabled,
  hasAnyMetricEnabledForSource,
  hasAnyNumericMetricEnabledForSource,
  isEligibilityFilterKey,
})

export const insightsGroupModel = createPerformanceInsightsGroupModel()
