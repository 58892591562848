import React from 'react'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useGetSkill } from '@src/api/skills'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import PageLoading from '@components/PageLoading/PageLoading'
import { PageBody } from '@components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useDepartmentReviewCycles } from '@src/pages/Forms/DepartmentForm/Performance/hooks/useReviewCycles'
import { TalentSkillRatingTable } from '@src/features/ReviewCycle/Analytics/Skills/Tables/TalentSkillRating/TalentSkillRatingTable'
import {
  SkillRatingsDistributionFilterKeys,
  SkillRatingsDistributionGroupTypes,
} from '@src/features/ReviewCycle/Analytics/api/skills'
import { defaultGroupType } from '@src/features/ReviewCycle/Analytics/Skills/constants'

export const SkillRatingsDistributionPage = () => {
  const { id: departmentId, skillId } = useParams<{ id: string; skillId: string }>()
  const { query } = useQuery()

  const { data: skill, isLoading: isSkillLoading } = useGetSkill(Number(skillId))
  const { options: cycleOptions, isLoading: isCycleOptionsLoading } =
    useDepartmentReviewCycles(Number(departmentId))

  const groupType =
    [
      SkillRatingsDistributionGroupTypes.Distribution,
      SkillRatingsDistributionGroupTypes.Benchmark,
    ].find(type => type === query[SkillRatingsDistributionFilterKeys.GroupType]) ??
    defaultGroupType

  if (isSkillLoading || isCycleOptionsLoading) {
    return <PageLoading />
  }

  if (!skill) {
    return <PageNotFoundWidget />
  }

  return (
    <PageWrapper>
      <PageHeader
        title={<PageHeader.Title title={skill.name} useIcon="BarChart" />}
        backUrl={pathToUrl(ROUTES.FORMS.DEPARTMENT.PERFORMANCE.SUMMARY, {
          id: departmentId,
        })}
      />
      <PageBody maxWidth="100%">
        <TalentSkillRatingTable
          departmentId={Number(departmentId)}
          skillId={Number(skillId)}
          groupType={groupType}
          cycleOptions={cycleOptions}
        />
      </PageBody>
    </PageWrapper>
  )
}
