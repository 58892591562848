import React from 'react'
import { useGetSkill } from '@src/api/skills'
import SideBar from '@src/components/SideBar/SideBar'
import { FormattedMessage } from 'react-intl'
import { SkillResources } from '@src/features/Skills/components/SkillResources'
import { SkillPlaybooks } from '@src/features/Skills/components/SkillPlaybooks'

interface Props {
  skillId: number
  onClose: VoidFunction
}

export const SkillResourcesSidebar = ({ skillId, onClose }: Props) => {
  const { data: skill, isLoading } = useGetSkill(skillId)

  return (
    <SideBar
      isOpen
      onClose={onClose}
      loading={isLoading}
      title={skill?.name}
      subtitle={
        <FormattedMessage
          id="skillResourcesSidebar.subtitle"
          defaultMessage="List of resources to help you to master {skillName} skills"
          values={{ skillName: skill?.name }}
        />
      }
    >
      <SkillPlaybooks skillName={skill?.name} playbookUrls={skill?.playbook_urls || []} />
      <SkillResources skillResources={skill?.resources || []} />
    </SideBar>
  )
}
