import { Button, Grid, Input, Item, Text, VStack } from '@revolut/ui-kit'
import { getSkillResourceMetadata } from '@src/api/skills'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { SkillLevels } from '@src/interfaces/roles'
import { SkillInterface, SkillResource } from '@src/interfaces/skills'
import React, { useState } from 'react'
import { z } from 'zod'
import { ResourceMetaData } from './ResourceMetadata'

export const SkillResourcesInput = () => {
  const { values } = useLapeContext<SkillInterface>()
  const [newResourceUrl, setNewResourceUrl] = useState('')
  const [newSkillLevel, setNewSkillLevel] = useState<IdAndName<SkillLevels>>()
  const [isValidatingMetaData, setIsValidatingMetadata] = useState(false)

  const handleAddNew = async () => {
    if (!newSkillLevel || !newResourceUrl) {
      return
    }
    setIsValidatingMetadata(true)
    // do not catch errors. Just will have empty data for them. User can update
    const { data } = await getSkillResourceMetadata({ resourceUrl: newResourceUrl })

    const newResource: SkillResource = {
      url: newResourceUrl,
      level: newSkillLevel,
      description: data.description,
      favicon: z.string().url().safeParse(data.favicon).success
        ? data.favicon
        : undefined,
      title: data.title,
    }

    if (values.resources) {
      values.resources.push(newResource)
    } else {
      values.resources = [newResource]
    }

    setNewResourceUrl('')
    setNewSkillLevel(undefined)
    setIsValidatingMetadata(false)
  }

  return (
    <Item>
      <Item.Content>
        <Item.Title>Skill resources</Item.Title>
        <Item.Description>
          <VStack gap="s-16">
            <Text>
              Links to any external or internal resources for your employees with guidance
              on how to improve this skill. For example, articles, courses, master
              classes, etc.
            </Text>
            {values.resources?.map((resource, index) => (
              <ResourceMetaData
                key={resource.url}
                resource={resource}
                onDelete={() => {
                  const updatedResources = [...(values.resources ?? [])]
                  updatedResources.splice(index, 1)
                  values.resources = updatedResources
                }}
                onEdit={editedResource =>
                  (values.resources ?? []).splice(index, 1, editedResource)
                }
              />
            ))}
            <Grid columns="2fr 1fr auto" gap="s-16" style={{ alignItems: 'center' }}>
              <Input
                label="URL"
                value={newResourceUrl}
                onChange={e => setNewResourceUrl(e.currentTarget.value)}
              />
              <RadioSelectInput<IdAndName<SkillLevels>>
                searchable={false}
                value={newSkillLevel}
                label="Skill level"
                onChange={val => {
                  if (val) {
                    setNewSkillLevel(val)
                  }
                }}
                selector={selectorKeys.skill_resource_levels}
              />
              <Button
                aria-label="add new resource"
                useIcon="Plus"
                onClick={handleAddNew}
                variant="secondary"
                height={36}
                width={36}
                pending={isValidatingMetaData}
                disabled={!newResourceUrl || !newSkillLevel}
              />
            </Grid>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
