import { useFetchV2 } from '@src/utils/reactQuery'
import { OptionInterface } from '@src/interfaces/selectors'
import { GetRequestInterface } from '@src/interfaces'
import {
  PredefinedAvailableCandidateSlot,
  DynamicAvailableCandidateSlot,
  SchedulingCandidateInterface,
  CreateCandidateSchedulingPredefinedSlotPayload,
  CreateCandidateSchedulingDynamicSlotPayload,
} from '@src/interfaces/interviewTool'
import { AxiosPromise } from 'axios'
import { apiV2 } from '@src/api'
import { decodeToken } from '@src/utils/decodeToken'
import {
  CancelScheduling,
  CandidateAISummarisationConsentInterface,
  ConsentRequiredInterface,
} from '@src/interfaces/hiringProccess'

export const getPredefinedAvailableCandidateSlots = (
  token: string,
  page: number,
  eventId?: string,
) =>
  apiV2.get<GetRequestInterface<PredefinedAvailableCandidateSlot>>(
    '/candidateInterviewScheduling/availableInterviewSlots',
    {
      headers: {
        apitoken: decodeToken(token),
      },
      params: {
        page,
        page_size: 1000,
        event_id: eventId,
      },
    },
    undefined,
    true,
  )

export const getDynamicAvailableCandidateSlots = (
  token: string,
  page: number,
  eventId?: string,
) =>
  apiV2.get<DynamicAvailableCandidateSlot[]>(
    '/candidateInterviewScheduling/availableDynamicInterviewSlots',
    {
      headers: {
        apitoken: decodeToken(token),
      },
      params: {
        page,
        event_id: eventId,
      },
    },
    undefined,
    true,
  )

export const useGetCandidateInterview = (token?: string) => {
  const context = useFetchV2<GetRequestInterface<SchedulingCandidateInterface>>({
    url: '/candidateInterviewScheduling',
    params: {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    isExternal: true,
  })

  return { ...context, data: context.data?.results?.[0] }
}
export const createCandidateAppointment = (
  params:
    | CreateCandidateSchedulingPredefinedSlotPayload
    | CreateCandidateSchedulingDynamicSlotPayload,
): AxiosPromise<Partial<SchedulingCandidateInterface>> =>
  apiV2.patch(
    `/candidateInterviewScheduling/${params.interviewId}`,
    {
      candidate_timezone: { id: params.timeZoneId },
      chosen_event_id: 'slotId' in params ? params.slotId : undefined,
      event_date_time: 'eventDate' in params ? params.eventDate : undefined,
      consent_to_re_engage: params.reEngageConsent,
      consent_to_interview_ai_summarisation: params.interviewAISummarisationConsent,
    },
    {
      headers: {
        apitoken: decodeToken(params.token) || '',
      },
    },
    undefined,
    true,
  )

export const useGetPublicTimezones = (token?: string) =>
  useFetchV2<{ options: OptionInterface[] }>({
    url: '/candidateInterviewScheduling/timezones',
    params: {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    isExternal: true,
  })

export const useCheckSchedulingReEngagementConsentNeeded = (token?: string) =>
  useFetchV2<ConsentRequiredInterface>({
    url: token ? `/candidateInterviewScheduling/isConsentRequired` : null,
    params: {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    isExternal: true,
  })

export const cancelScheduling = (
  token: string,
  interviewId?: number | string,
  data?: CancelScheduling,
) => {
  return apiV2.patch(
    `/candidateInterviewScheduling/${interviewId}/cancel`,
    data,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    true,
  )
}

export const useGetCandidateSchedulingFeatureFlags = (token?: string) =>
  useFetchV2<string[]>({
    url: token ? '/candidateInterviewScheduling/candidateFeatureFlags' : null,
    params: {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    isExternal: true,
  })

export const useGetCanddidateAISummarisationConsent = (
  token: string | null,
  interviewId?: number | string,
) =>
  useFetchV2<CandidateAISummarisationConsentInterface>({
    url: `/candidateInterviewScheduling/${interviewId}/getInterviewAISummarisationConsent`,
    params: {
      headers: {
        apitoken: decodeToken(token || undefined),
      },
    },
    isExternal: true,
    queryOptions: { enabled: !!token && !!interviewId },
  })
