import SideBar from '@components/SideBar/SideBar'
import { Box, Button, DragAndDrop, Flex, Group, Side } from '@revolut/ui-kit'
import React, { useCallback, useState } from 'react'
import {
  TableColumnSettingsInterface,
  TableSettingsInterface,
} from '@src/interfaces/tableSettings'
import { TableNames } from '@src/constants/table'
import { LocalStorageKeys } from '@src/store/auth/types'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { move } from '@src/utils/move'
import {
  useTableDispatch,
  ActionType,
} from '@components/TableV2/TableProvider/TableProvider'
import { DNDSortableItem } from '@components/DragAndDrop/SortableItem'
import { DNDOverlayItem } from '@components/DragAndDrop/OverlayItem'
import { DNDAddableItem } from '@components/DragAndDrop/AddableItem'
import { useDNDSourceItem } from '@components/DragAndDrop/hooks/useDNDSourceItem'

interface TableSettingsProps {
  name: TableNames
  isOpen: boolean
  onSave: (settings?: TableSettingsInterface) => void
  columnSettings: TableColumnSettingsInterface
}

const TableSettings = ({ name, isOpen, onSave, columnSettings }: TableSettingsProps) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const dispatch = useTableDispatch()
  const { visible, hidden } = columnSettings
  const initialState = {
    visible: visible.slice(1).map(cell => cell.title),
    hidden: hidden.map(cell => cell.title),
  }
  const [tableSettings, setTableSettings] = useState<TableSettingsInterface>(initialState)

  const handleReorder = useCallback((startIndex: number, endIndex: number) => {
    setTableSettings(current => ({
      ...current,
      visible:
        startIndex === endIndex
          ? current.visible
          : move(current.visible, startIndex, endIndex),
    }))
  }, [])

  const { activeItemId, onDragStart, onDragEnd, onDragCancel } = useDNDSourceItem<string>(
    { onAfterDragEnd: handleReorder },
  )

  const activeVisibleSettingId: string | undefined =
    activeItemId &&
    tableSettings.visible.find(visibleSettingId => visibleSettingId === activeItemId)

  const handleRemove = (title: string) => {
    setTableSettings(current => ({
      visible: current.visible.filter(t => {
        return t !== title
      }),
      hidden: [...current.hidden, title],
    }))
  }

  const handleAdd = (title: string) => {
    setTableSettings(current => ({
      visible: [...current.visible, title],
      hidden: current.hidden.filter(t => {
        return t !== title
      }),
    }))
  }

  const handleClose = () => {
    dispatch({ type: ActionType.CloseSettings })
  }

  const handleSave = () => {
    sendAnalyticsEvent(AnalyticsEvents.click_update_table_column_settings_btn, {
      table_name: name,
    })
    workspaceLocalStorage.setItem(
      LocalStorageKeys.TABLE_SETTINGS_KEY_TEMPLATE.replace('{}', name),
      JSON.stringify(tableSettings),
    )
    onSave(tableSettings)
    handleClose()
  }

  const handleReset = () => {
    workspaceLocalStorage.removeItem(
      LocalStorageKeys.TABLE_SETTINGS_KEY_TEMPLATE.replace('{}', name),
    )
    onSave(undefined)
    handleClose()
  }

  return (
    <SideBar
      isOpen={isOpen}
      onClose={handleClose}
      title="Column settings"
      subtitle="Choose what columns you want to have in the table and in what order"
      usePortal
    >
      <Flex flexDirection="column" gap="s-16">
        <DragAndDrop.Provider
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <Group>
            <DragAndDrop.Sortable
              id="sortable-table-preferences"
              items={tableSettings.visible}
            >
              {sortable => {
                const title = tableSettings.visible.find(item => item === sortable.id)
                return title ? (
                  <DNDSortableItem
                    title={title}
                    sortable={sortable}
                    onClick={() => handleRemove(title)}
                  />
                ) : null
              }}
            </DragAndDrop.Sortable>
          </Group>
          <DragAndDrop.DragOverlay>
            {activeVisibleSettingId && <DNDOverlayItem title={activeVisibleSettingId} />}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
        <Group>
          {tableSettings.hidden.map(title => {
            return (
              <Box key={title}>
                <DNDAddableItem title={title} onClick={() => handleAdd(title)} />
              </Box>
            )
          })}
        </Group>
      </Flex>
      <Side.Actions>
        <Button onClick={handleReset} variant="secondary">
          Reset
        </Button>
        <Button elevated onClick={handleSave}>
          Done
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

export default TableSettings
