import Form from '@src/features/Form/Form'
import { mondayIntegrationPreferences } from '@src/api/integrations'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { FormattedMessage } from 'react-intl'
import {
  Text,
  Avatar,
  Spacer,
  Token,
  HStack,
  VStack,
  Box,
  Item,
  Icon,
  Flex,
  ActionButton,
  Bullet,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { MondayIntegrationInterface } from '@src/interfaces/integrations'

import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import Monday from '@src/assets/temporary/Monday.png'
import { IntegrationEnablePopup } from '../components/IntegrationEnablePopup'
import { LocalStorageKeys } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const MondayIntegrationLayout = () => {
  const { values, reset, submit, isSubmitting, loading } =
    useLapeContext<MondayIntegrationInterface>()
  const confirmationPopup = useConfirmationDialog()
  const [enableIntegrationPopupOpen, setEnableIntegrationPopupOpen] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  useEffect(() => {
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Monday.com"
        backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                {values.enabled ? (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                ) : null}
                <Text color={Token.color.greyTone50} variant="body2">
                  Productivity
                </Text>
              </VStack>
              <Spacer />
              <Avatar image={Monday} size={56}>
                {values.enabled ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
      />

      {!values.enabled && (
        <Box pt="s-16">
          <ActionButton
            useIcon="Link"
            pending={isSubmitting}
            disabled={loading}
            variant="accent"
            onClick={evnt => {
              evnt.preventDefault()
              setEnableIntegrationPopupOpen(true)
            }}
          >
            Enable
          </ActionButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.monday.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <Text use="p">
              <FormattedMessage
                id="integrations.monday.intro.text"
                defaultMessage="Easily connect all your Monday.com Projects to automatically track the progress and achievement of your Goals within Revolut People Performance app"
              />
            </Text>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.monday.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.monday.intro.tips.integrate.title"
                  defaultMessage="Enable Monday integration"
                />
              ),
              icon: <Bullet variant="filled">1</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.monday.intro.tips.integrate.text"
                  defaultMessage="Use the “Enable” button above and provide the information needed"
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.monday.intro.tips.sync.title"
                  defaultMessage="Ensure the Goals and Roadmaps can be managed based on Monday"
                />
              ),
              icon: <Bullet variant="filled">2</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.monday.intro.tips.sync.text"
                  defaultMessage="Go to <a>Goals Settings -> Roadmaps</a> and switch on “Enable Roadmaps” option. And then “Manage Goals and Roadmaps based on Monday"
                  values={{
                    a: chunks => (
                      <InternalLink to={ROUTES.SETTINGS.GOALS.ROADMAPS}>
                        {chunks}
                      </InternalLink>
                    ),
                  }}
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.monday.intro.tips.roadmap.title"
                  defaultMessage="Attach goals, roadmaps to your team or department"
                />
              ),
              icon: <Bullet variant="filled">3</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.monday.intro.tips.roadmap.text"
                  defaultMessage="Team and department owners can attach goals and roadmaps to their units."
                />
              ),
            },
          ]}
        />
        {values.enabled && (
          <Item
            color={Token.color.danger}
            use="button"
            onClick={async event => {
              event.preventDefault()
              confirmationPopup.show({
                label: 'Are you sure?',
                body: 'After disabling this integration you won’t be able to use Monday as a tracking tool',
                onConfirm: async () => {
                  try {
                    values.enabled = false
                    const response = await submit()
                    showStatusPopup({ title: 'Integration disabled' })
                    reset(response)
                  } catch (error) {
                    showStatusPopup({
                      title: 'Cannot disable integration',
                      description: getMessageFromApiError(error),
                      status: 'error',
                    })
                    values.enabled = true
                  }
                },
              })
            }}
          >
            <Item.Content>
              <Flex gap="s-16">
                <Icon name="Unlink" />
                <Item.Title>Disconnect Monday</Item.Title>
              </Flex>
            </Item.Content>
          </Item>
        )}
      </PageBody>

      <IntegrationEnablePopup
        isOpen={enableIntegrationPopupOpen}
        type="monday"
        onClose={() => {
          setEnableIntegrationPopupOpen(false)
        }}
      />
    </PageWrapper>
  )
}

export const MondayIntegrationPage = () => {
  return (
    <Form api={mondayIntegrationPreferences} forceParams={{ id: '1' }}>
      <MondayIntegrationLayout />
    </Form>
  )
}
