import React, { useMemo, useState } from 'react'
import {
  Button,
  chain,
  Flex,
  Header,
  HStack,
  IconButton,
  Popup,
  Text,
  Token,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  getCVScreeningSession,
  navigateToCVScreening,
} from '@src/pages/Forms/CVScreening/utils'
import { pathToUrl } from '@src/utils/router'
import { useLocation } from 'react-router-dom'
import { SwitchItemsSidebar } from '@components/SwitchItemsSidebar/SwitchItemsSidebar'
import { FormattedMessage } from 'react-intl'
import { PageHeader } from '@components/Page/Header/PageHeader'

type Props = {
  currentNumber: number
  total: number
  children: React.ReactNode
  onNext: () => void
  onPrev: () => void
  disablePrev?: boolean
}

const CvScreeningHeader = ({
  currentNumber,
  total,
  children,
  onNext,
  onPrev,
  disablePrev,
}: Props) => {
  const location = useLocation()
  const [openSidebar, setOpenSidebar] = useState(false)
  const session = getCVScreeningSession()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const candidates = session.candidates

  const onClickSummary = () => {
    navigateTo(ROUTES.FORMS.CV_SCREENING_SUMMARY, undefined, true)
  }

  const sidebarItems = useMemo(
    () =>
      candidates?.map(item => ({
        id: +item.id,
        avatar: {
          id: +item.id,
          name: item.name,
        },
      })) || [],
    [candidates],
  )

  const isSingleCandidate =
    !session.candidates?.length || session.candidates?.length === 1

  return (
    <>
      <PageHeader
        backUrl={ROUTES.RECRUITMENT.CANDIDATES}
        onClickBack={isSingleCandidate ? undefined : () => setOpenConfirmation(true)}
        title={
          <PageHeader.Title
            title={
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Text>
                  {chain(
                    'CV Screening',
                    <Text color={Token.color.greyTone50}>
                      {currentNumber}/{total}
                    </Text>,
                  )}
                </Text>
              </Flex>
            }
            side={
              !!candidates?.length &&
              candidates.length > 1 && (
                <HStack gap="s-8">
                  <IconButton
                    useIcon="ChevronLeft"
                    size={24}
                    onClick={onPrev}
                    disabled={disablePrev}
                    color={disablePrev ? Token.color.greyTone10 : undefined}
                  />
                  <IconButton useIcon="ChevronRight" size={24} onClick={onNext} />
                  <IconButton
                    useIcon="Menu"
                    size={24}
                    onClick={() => setOpenSidebar(true)}
                  />
                </HStack>
              )
            }
            actions={children}
          />
        }
      />
      <SwitchItemsSidebar
        title="Candidates"
        items={sidebarItems}
        isOpen={openSidebar}
        onClose={() => setOpenSidebar(false)}
        selectedIndex={currentNumber - 1}
        onSelect={item => {
          navigateToCVScreening(item.id, location)
        }}
        avatarUrlFunc={id => pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id })}
        useLayout
      />
      <Popup
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        variant="bottom-sheet"
      >
        <Header variant="compact">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>
            <FormattedMessage
              id="recruitment.cvScreening.finishConfirm"
              defaultMessage="Are you sure you want to finish CV screening?"
            />
          </Header.Title>
        </Header>
        <Popup.Actions>
          <Button
            data-testid="no"
            variant="secondary"
            onClick={() => setOpenConfirmation(false)}
          >
            <FormattedMessage
              id="recruitment.cvScreening.noContinueCVScreening"
              defaultMessage="No, continue CV screening"
            />
          </Button>
          <Button onClick={onClickSummary} useIcon="BalanceSheet">
            <FormattedMessage
              id="recruitment.cvScreening.yesGoToSummary"
              defaultMessage=" Yes, go to Summary"
            />
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              navigateTo(session.referrerPath || ROUTES.RECRUITMENT.CANDIDATES)
            }}
            useIcon="Profile"
          >
            <FormattedMessage
              id="recruitment.cvScreening.yesGoToCandidates"
              defaultMessage="Yes, go to Candidates"
            />
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default CvScreeningHeader
