import { useSelector } from 'react-redux'

import { selectFeatureBundles, selectFeatureFlags } from '@src/store/auth/selectors'
import { BundleType, FeatureFlags } from '@src/store/auth/types'

const useHasBundle = (bundle: BundleType) => {
  const userFeatureFlags = useSelector(selectFeatureFlags)
  return userFeatureFlags?.includes(bundle)
}

export const useHasBundleHR = () => {
  return useHasBundle(FeatureFlags.BundleHR)
}

export const useHasBundleRecruitment = () => {
  return useHasBundle(FeatureFlags.BundleRecruitment)
}

export const useUserBundles = () => useSelector(selectFeatureBundles)
