import { useFetchV2 } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { GetRequestInterface } from '@src/interfaces'
import { DepartmentTabInterface, DepartmentTabSectionInterface } from './interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { apiV2 } from '@src/api'
import { AxiosPromise } from 'axios'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { DepartmentResourcesGeneralTab } from './Tabs/General'

export const useGetTabSections = ({
  tabId,
  departmentId,
}: {
  tabId?: number | string
  departmentId?: number | string
}) => {
  return useFetchV2<DepartmentTabInterface>({
    url: `/departments/${departmentId}/tabs/${tabId}`,
    queryOptions: {
      enabled: !!departmentId && !!tabId,
    },
  })
}

export const useGetResourcesTabConfig = ({
  tabId,
  departmentId,
}: {
  tabId?: number | string
  departmentId: number | string
}) => {
  const results = useGetTabSections({ tabId, departmentId })

  const sections = results.data?.sections.reduce<Record<string, number>>(
    (allSections, section) => ({
      ...allSections,
      [section.name]: section.total,
    }),
    {
      total: results.data?.total,
    },
  )

  return {
    config: [
      {
        id: undefined,
        key: 'total',
        label: 'Total',
        to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.TOTAL, { id: departmentId }),
        component: DepartmentResourcesGeneralTab,
      },
      ...(results.data?.sections || []).map(section => ({
        id: section.id,
        key: section.name,
        label: section.name,
        to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.ANY, {
          id: departmentId,
          subtab: section.id,
        }),
        component: DepartmentResourcesGeneralTab,
      })),
    ],
    api: () => ({ ...results, data: sections }),
    results,
  }
}

export const getTabResources =
  ({ tabId, departmentId }: { tabId: number; departmentId: number }) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<DepartmentTabSectionInterface>
  > =>
    apiV2.get(`/departments/${departmentId}/tabs/${tabId}/resources`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
