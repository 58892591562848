import {
  DeliverableOptions,
  PerformanceRating,
  performanceRatingToFinalGrade,
  Ratings,
} from '@src/interfaces/performance'
import { GradesMapInterface } from '@src/utils/grades'
import React from 'react'
import { RatingWithTooltip } from '../RatingWithTooltip'
import { PerformanceRatingTitle } from '@src/constants/performance'

export const getRatingWithTooltip = (
  getGrade: boolean,
  rating?: PerformanceRating,
  ratings?: Ratings[],
  gradesMap?: GradesMapInterface,
  expectedLevel?: DeliverableOptions | null,
) => {
  return (
    <RatingWithTooltip
      getTitle={perfRating => {
        return getGrade
          ? gradesMap?.[performanceRatingToFinalGrade(perfRating)] || '-'
          : PerformanceRatingTitle[perfRating]
      }}
      rating={rating}
      ratings={ratings}
      expectedLevel={expectedLevel}
    />
  )
}
