import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import {
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import {
  BulkApproveGoalsPayloadInterface,
  BulkAssignHRManagerPayloadInterface,
} from '@src/interfaces/pipAndProbation'
import { useUpdateV2, usePostV2, useFetchV2 } from '@src/utils/reactQuery'

export const useBulkAssignHRManager = () =>
  useUpdateV2<
    BulkAssignHRManagerPayloadInterface,
    Partial<BulkAssignHRManagerPayloadInterface>
  >({
    url: `${API.EMPLOYEE_PERFORMANCE_CYCLES_BULK_ASSIGN_MANAGER}`,
    version: 'v2',
    usePut: true,
    useId: false,
  })

export const useBulkApproveGoals = () =>
  usePostV2<BulkApproveGoalsPayloadInterface>({
    url: `${API.EMPLOYEE_PERFORMANCE_CYCLES_BULK_APPROVE_GOALS}`,
    version: 'v2',
  })

export const usePipAndProbationPerformanceReview = ({
  employeeId,
  pipCycleId,
  category,
}: {
  employeeId: number | undefined
  pipCycleId: string | undefined
  category:
    | ReviewCategory.PIP
    | ReviewCategory.PIP_V2
    | ReviewCategory.Probation
    | undefined
}) =>
  useFetchV2<GetRequestInterface<PerfReviewRequestFeedbackInterface>>({
    url: `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${pipCycleId}/performanceReviews/requests`,
    version: 'v2',
    params: { params: { category } },
    queryOptions: {
      enabled:
        Number.isInteger(employeeId) &&
        pipCycleId !== undefined &&
        category !== undefined,
    },
  })
