import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HStack, Icon, TextButton, TextSkeleton } from '@revolut/ui-kit'

interface Props {
  isLoading: boolean
  onClick: VoidFunction
}

export const DetailsButton = ({ isLoading, onClick }: Props) => {
  if (isLoading) {
    return <TextSkeleton width={40} />
  }

  return (
    <TextButton onClick={onClick}>
      <HStack space="s-8" align="center">
        <Icon name="LinkExternal" size={16} />
        <FormattedMessage
          id="performance.insights.details.cta.label"
          defaultMessage="Full details"
        />
      </HStack>
    </TextButton>
  )
}
