import React from 'react'
import { HStack, TabBar } from '@revolut/ui-kit'

import { TimelineFilterInterface } from '../../../Rounds/Selector/types'
import { CalendarDatesSelector, calendarSelectorIcon } from './CalendarDatesSelector'
import { RoundSelector, roundSelectorIcon } from './RoundSelector'

const TimelineFilterDropdown = ({ mode, round, calendar }: TimelineFilterInterface) => {
  if (mode.value === 'calendar') {
    return <CalendarDatesSelector value={calendar.value} onChange={calendar.setValue} />
  }
  if (mode.value === 'rounds') {
    return (
      <RoundSelector
        value={round.value}
        options={round.options}
        isLoadingOptions={round.isLoadingOptions}
        onChange={round.setValue}
      />
    )
  }
  return null
}

interface Props extends TimelineFilterInterface {
  hideModeSwitcher?: boolean
}
export const TimelineFilter = (props: Props) => {
  return (
    <HStack space="s-8">
      {!props.hideModeSwitcher && (
        <TabBar
          variant="segmented fit"
          mx="auto"
          value={props.mode.value}
          onChange={value => {
            props.mode.setValue(value || 'rounds')
          }}
        >
          <TabBar.Item
            to="rounds"
            useIcon={roundSelectorIcon}
            aria-label="show rounds filter"
          />
          <TabBar.Item
            to="calendar"
            useIcon={calendarSelectorIcon}
            aria-label="show calendar filter"
          />
        </TabBar>
      )}
      <TimelineFilterDropdown {...props} />
    </HStack>
  )
}
