import React, { useEffect, useRef, useState } from 'react'
import {
  DeliverableOptions,
  deliverableOptionToFinalGrade,
  getDeliverableRatingByFinalGrade,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  DeliverableGradeOption,
  useScoreCardGrades,
  PerfRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { useAiInsightsAvailability } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { VStack } from '@revolut/ui-kit'
import { PerformanceInsights } from '@src/features/AiInsights/Performance/components/Insights/Deliverables/PerformanceInsights'
import { ScorecardGoals } from '../components/ScorecardGoals/ScorecardGoals'
import { getGradeEvaluationCriterionByDeliverablesProgress } from '@src/utils/performance'

interface KPIsCardInterface extends CommonCardProps {
  setMissingKpiJustification?: React.Dispatch<React.SetStateAction<boolean>>
  showBeforeSubmitCheckError?: boolean
}

export const KPIsCard = connect(
  ({
    onHelpClick,
    gradesMap,
    setMissingKpiJustification,
    showBeforeSubmitCheckError,
  }: KPIsCardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)
    const { grades } = useRecommendedGradesContext()

    const [gradeRecommendation, setGradeRecommendation] =
      useState<DeliverableOptions | null>(null)
    const [goalsStat, setGoalsStat] = useState<number>()

    const { isAvailable: canAccessAiInsights, relation: aiInsightsRelation } =
      useAiInsightsAvailability(values)

    const { gradeProps: kpiGrades, evaluationCriteria } = useScoreCardGrades()
    const evaluationCriterion = goalsStat
      ? getGradeEvaluationCriterionByDeliverablesProgress(goalsStat, evaluationCriteria)
      : null

    useEffect(() => {
      if (errors.review_data?.kpis_section || showBeforeSubmitCheckError) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.kpis_section, showBeforeSubmitCheckError])

    // preselecting grade
    useEffect(() => {
      if (values.review_data.kpis_section && evaluationCriterion) {
        const recommendedGrade = evaluationCriterion.codename
        const recommendedRating = getDeliverableRatingByFinalGrade(recommendedGrade)
        setGradeRecommendation(recommendedRating)

        if (!values.review_data.kpis_section.recommended_rating) {
          values.review_data.kpis_section.recommended_rating = recommendedRating
        }
        if (!values.review_data.kpis_section.section_grade) {
          values.review_data.kpis_section.section_grade = recommendedGrade
        }
      }
    }, [values.review_data.kpis_section, evaluationCriterion])

    if (!hasKPI) {
      return null
    }

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.kpiGrade ? gradesMap[grades?.kpiGrade] : undefined

    const gradeValue = values.review_data.kpis_section?.recommended_rating
    const missingJustification = !values.review_data.kpis_section?.comment
    if (gradeRecommendation && gradeValue && missingJustification) {
      const recommendationScore = PerfRatingToGraphNumber[gradeRecommendation]
      const valueScore = PerfRatingToGraphNumber[gradeValue]
      setMissingKpiJustification?.(valueScore > recommendationScore)
    } else {
      setMissingKpiJustification?.(false)
    }

    const onSelectGrade = (
      reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
      grade: DeliverableGradeOption,
    ) => {
      set(reviewData, `kpis_section.recommended_rating`, grade.key)
      set(
        reviewData,
        `kpis_section.section_grade`,
        deliverableOptionToFinalGrade(grade.key),
      )
    }

    return (
      <Card
        data={values}
        renderExpandedContent={() => (
          <KPI
            reviewData={values.review_data}
            kpiGrades={kpiGrades}
            onSelectGrade={onSelectGrade}
            gradesMap={gradesMap}
          />
        )}
        renderExceedingContent={() => (
          <KPI
            reviewData={values.review_data}
            kpiGrades={kpiGrades}
            showJustificationError={showBeforeSubmitCheckError && missingJustification}
            justificationOnly
            gradesMap={gradesMap}
          />
        )}
        additionalInfo={
          <VStack space="s-16">
            <ScorecardGoals
              setGoalsStats={setGoalsStat}
              gradesMap={gradesMap}
              values={values}
              team={values.team}
            />
            {canAccessAiInsights && !!aiInsightsRelation && values.cycle && (
              <PerformanceInsights
                employeeId={values.reviewed_employee.id}
                cycleId={Number(values.cycle.id)}
                relation={aiInsightsRelation}
              />
            )}
          </VStack>
        }
        type={CardContentTypes.KPI}
        title="Goals"
        stat={goalsStat}
        finalRating={finalRating}
        icon="Target"
        fields={[
          {
            field: 'review_data.kpis_section',
            title: 'Goal performance',
            grades: kpiGrades,
            recommendedGrade: evaluationCriterion
              ? getDeliverableRatingByFinalGrade(evaluationCriterion.codename)
              : undefined,
            cardJustification: values.review_data.kpis_section?.comment || null,
          },
        ]}
        onSelectDeliverableGrade={grade => onSelectGrade(values.review_data, grade)}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.recommended_rating
          return !!ratingValue && ratingValue === grade.key
        }}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
      />
    )
  },
)
