import React, { useEffect, useMemo } from 'react'
import { useTable } from '@components/TableV2/hooks'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { getInterviewFeedbacks } from '@src/api/recruitment/interviews'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  interviewCandidate,
  interviewDateColumn,
  interviewNameColumn,
  interviewRatingColumn,
  interviewRole,
  interviewScorecardColumn,
  interviewSeniority,
  interviewSpecialisationAsRole,
  interviewStatus,
} from '@src/constants/columns/interviews'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { InterviewStats } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/InterviewStats'
import { StagesBankAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/StagesBankAction'
import { ManageInterviewingScorecardsAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/ManageInterviewingScorecardsAction'
import { RecruitmentGroupsAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/RecruitmentGroupsAction'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { InterviewAvailabilityAction } from '@src/features/InterviewAvailability/InterviewAvailabilityAction'
import Table from '@components/TableV2/Table'
import { InterviewerProfileSidebar } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/InterviewProfileSidebar'
import { RequiresFeatureFlagWrapper } from '@components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FeatureFlags } from '@src/store/auth/types'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { MoreBar, useToggle, VStack } from '@revolut/ui-kit'
import { MeetingExtensionInterviewTranscriptBanner } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/MeetingExtensionInterviewTranscriptBanner'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@src/utils/queryParamsHooks'

const interviewRow = (
  specialisationsEnabled: boolean,
): RowInterface<InterviewFeedbackInterface> => ({
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...interviewCandidate,
      width: 250,
    },
    {
      ...interviewStatus,
      width: 140,
    },
    {
      ...(specialisationsEnabled ? interviewRole : interviewSpecialisationAsRole),
      width: 200,
    },
    {
      ...interviewSeniority,
      width: 150,
    },
    {
      ...interviewNameColumn,
      width: 160,
    },
    {
      ...interviewDateColumn,
      width: 200,
    },
    {
      ...interviewRatingColumn,
      width: 150,
    },
    {
      ...interviewScorecardColumn,
      width: 110,
    },
  ],
})

export type InterviewTableQueryParams = {
  sidebar: 'openInterviewProfileSidebar'
}

const InterviewsBase = () => {
  const { query, deleteQueryParam } = useQuery<InterviewTableQueryParams>()
  const [openInterviewProfile, toggleOpenInterviewProfile] = useToggle()

  useEffect(() => {
    if (query.sidebar && query.sidebar === 'openInterviewProfileSidebar') {
      toggleOpenInterviewProfile.on()
      deleteQueryParam('sidebar')
    }
  }, [query.sidebar])

  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const row = useMemo(
    () => interviewRow(specialisationsEnabled),
    [specialisationsEnabled],
  )
  const table = useTable<InterviewFeedbackInterface>(
    { getItems: getInterviewFeedbacks },
    undefined,
    [
      {
        sortBy: 'status_order',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'scheduled_date_time',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  )

  return (
    <VStack gap="s-16">
      <MeetingExtensionInterviewTranscriptBanner />
      <Table.Widget>
        <Table.Widget.Info>
          <InterviewStats
            filters={table.filterBy}
            onFilterChange={table.onFilterChange}
          />
        </Table.Widget.Info>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar maxCount={3}>
            <InterviewAvailabilityAction />
            <RequiresFeatureFlagWrapper
              flags={[FeatureFlags.AIInterviewSummarisationInternal]}
            >
              <MoreBar.Action
                useIcon="Profile"
                onClick={() => toggleOpenInterviewProfile()}
              >
                <FormattedMessage
                  id="recruitment.interviewAvailability.actions.interviewerProfile"
                  defaultMessage="My interviewer profile"
                />
              </MoreBar.Action>
            </RequiresFeatureFlagWrapper>
            <StagesBankAction />
            <ManageInterviewingScorecardsAction />
            <RecruitmentGroupsAction />
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Search>
          <Table.Search
            placeholder="Search by name or email"
            onFilter={table.onFilterChange}
          />
        </Table.Widget.Search>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by name or email"
            onFilter={table.onFilterChange}
            variant="compact"
          />
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable<InterviewFeedbackInterface>
            name={TableNames.Interviews}
            useWindowScroll
            dataType="Interviews"
            row={row}
            hideCount
            {...table}
            noDataMessage="Interviews will appear here."
          />
        </Table.Widget.Table>
      </Table.Widget>
      <InterviewerProfileSidebar
        isOpen={openInterviewProfile}
        onClose={toggleOpenInterviewProfile}
      />
    </VStack>
  )
}

export const Interviews = () => {
  return (
    <OnboardingAppScreen category="interviews">
      <InterviewsBase />
    </OnboardingAppScreen>
  )
}
