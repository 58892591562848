import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { RoleStep, SpecialisationInterface } from '@src/interfaces/roles'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { ROUTES } from '@src/constants/routes'
import { SpecialisationStep } from '../constants'
import { navigateReplace } from '@src/actions/RouterActions'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { SubmitButton } from './SubmitButton'
import { useWeightsErrors } from '@src/features/Specialisations/hooks/useWeightsErrors'
import { formTabs } from '../AddEditSpecialisation'
import { useUserBundles } from '@src/hooks/useHasBundle'

interface Props {
  stepKey: SpecialisationStep
}

const stepToRoleStep: Record<SpecialisationStep, RoleStep> = {
  'competency-matrix': 'competency_matrix',
  'general-info': 'general',
  'hiring-process': 'hiring_process',
}

export const FormActions = ({ stepKey }: Props) => {
  const { values, dirty, valid } = useLapeContext<SpecialisationInterface>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const { totalWeight, skillWithNoWeight } = useWeightsErrors()
  const featuresBundles = useUserBundles()

  const allSteps = formTabs.filter(f => f.canView(featuresBundles))
  const stepIndex = allSteps.findIndex(s => s.key === stepKey)
  const isLastStep = stepIndex === allSteps.length - 1
  const nextStep: SpecialisationStep | 'finish' = isLastStep
    ? 'finish'
    : allSteps[stepIndex + 1].key

  const getSubmitButtonText = () => {
    if (nextStep === 'finish') {
      return 'Finish'
    }
    if (values.id && dirty && values.status !== Statuses.draft) {
      return 'Save changes'
    }
    return 'Next'
  }
  return (
    <>
      {(values.status === Statuses.draft || !values.id) && (
        <RoleSaveDraftButton
          title={specialisationsEnabled ? 'Specialisation' : 'Role'}
          pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.GENERAL_INFO)}
          pathAfterSave={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.ANY, {
            step: stepKey,
            id: ':id', // a hack to keep id in the path so it can be replaced after update
          })}
          isNew
          notification={{
            updateMsg: specialisationsEnabled
              ? 'Specialisation draft successfully updated.'
              : 'Role draft successfully updated',
            createMsg: specialisationsEnabled
              ? 'Specialisation draft successfully created.'
              : 'Role draft successfully created',
          }}
        />
      )}
      {/**
       * Need to use that component instead of the default form submit as
       * 1. step query param matters for BE (does different parsing / normalisation)
       * 2. It handles some normalisation already
       */}
      <SubmitButton
        step={stepToRoleStep[stepKey]}
        type="specialisation"
        onAfterSubmit={response => {
          navigateReplace(
            nextStep === 'finish'
              ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id: response.id })
              : pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.ANY, {
                  id: response.id,
                  step: nextStep,
                }),
          )
        }}
        disabled={
          !valid ||
          (stepKey === 'competency-matrix' &&
            ((typeof totalWeight === 'number' && totalWeight !== 100) ||
              skillWithNoWeight))
        }
      >
        {getSubmitButtonText()}
      </SubmitButton>
    </>
  )
}
