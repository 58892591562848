import {
  Avatar,
  Box,
  Group,
  Icon,
  Item,
  Token,
  TransitionCollapse,
  useToggle,
} from '@revolut/ui-kit'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompetencyMatrixInterface, RoleInterface } from '@src/interfaces/roles'
import CompetencyMatrixExample from '@src/pages/Forms/RoleForm/CompetencyMatrix/CompetencyMatrixExample'
import React, { useEffect, useState } from 'react'
import { checkCanEditRole } from '../helpers'
import { SkillsSideBar } from '@src/pages/Specialisation/Form/components/SkillsSideBar'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { getDefaultCompetencyMatrix } from '@src/api/roles'
import { useGetSkillsSettings } from '@src/api/settings'

interface Props {
  seniorities: SeniorityInterface[]
}

export const RoleCompetencyMatrixWidget = ({ seniorities }: Props) => {
  const [isAddSkillOpen, toggleAddSkill] = useToggle()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { values } = useLapeContext<RoleInterface>()
  const canEdit = checkCanEditRole(values)
  const { data: skillsSettings } = useGetSkillsSettings()
  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])

  const onChangeMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data) {
      return
    }

    values.functional_competency_matrix = data
  }

  if (values.function && (!values.seniority_min || !values.seniority_max)) {
    values.seniority_min = seniorities[0]
    values.seniority_max = seniorities[seniorities.length - 1]
  }

  const fetchDefaultMatrix = async () => {
    const response = await getDefaultCompetencyMatrix()

    if (Array.isArray(response.data?.culture_matrix)) {
      setDefaultCultureSkills(response.data.culture_matrix)
    }
  }

  useEffect(() => {
    if (!values.id) {
      fetchDefaultMatrix()
    }
  }, [])

  useEffect(() => {
    if (seniorities.length) {
      // it's needed for old roles those don't have seniority_level
      values.functional_competency_matrix = values.functional_competency_matrix?.map(
        item => ({
          ...item,
          competencies: item.competencies?.map(competency => {
            const seniorityLevel = seniorities.find(
              seniority => seniority.id === competency.seniority_id,
            )?.level

            return { ...competency, seniority_level: seniorityLevel }
          }),
        }),
      )
    }
  }, [seniorities])

  return (
    <>
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="RepairTool" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Functional skills</Item.Title>
            <Item.Description>
              You can add multiple skills but should use no more than 5 skills for this
              role.
            </Item.Description>
          </Item.Content>
          <Item.Side>
            {canEdit && (
              <PrimaryAction onClick={toggleAddSkill.on} disabled={!seniorities.length}>
                Add skill
              </PrimaryAction>
            )}
          </Item.Side>
        </Item>
        <Box>
          <CompetencyMatrixTable
            competencyMatrices={[
              {
                children: values.functional_competency_matrix,
                staticSkill: false,
                onChange: onChangeMatrix,
              },
            ]}
            minSeniority={values.seniority_min}
            maxSeniority={values.seniority_max}
            readOnly={!canEdit}
            type="role"
          />
        </Box>
      </Group>
      <Group>
        <Item onClick={() => setIsCollapsed(!isCollapsed)} style={{ cursor: 'pointer' }}>
          <Item.Prefix>
            <Icon
              color={Token.color.greyTone50}
              name={isCollapsed ? 'ChevronDown' : 'ChevronRight'}
              size={24}
            />
          </Item.Prefix>
          <Item.Avatar>
            <Avatar useIcon="RepairTool" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Competency matrix examples</Item.Title>
          </Item.Content>
        </Item>
        <TransitionCollapse in={isCollapsed}>
          <Box>
            <CompetencyMatrixExample
              minSeniority={values.seniority_min}
              maxSeniority={values.seniority_max}
              seniorities={seniorities}
            />
          </Box>
        </TransitionCollapse>
      </Group>
      {!skillsSettings?.behaviours_assessment_enabled && (
        <Group>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="LockClosed" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Culture fit skills</Item.Title>
              <Item.Description>
                Culture fit competency matrix is defined at company level. You cannot make
                any change here
              </Item.Description>
            </Item.Content>
          </Item>
          <Box>
            <CompetencyMatrixTable
              competencyMatrices={[
                {
                  children: defaultCultureSkills,
                  sectionTitle: 'Culture fit',
                  disabled: true,
                },
              ]}
              minSeniority={values.seniority_min}
              maxSeniority={values.seniority_max}
              type="role"
            />
          </Box>
        </Group>
      )}
      {isAddSkillOpen && (
        <SkillsSideBar
          withWeights={false}
          role={values}
          onClose={toggleAddSkill.off}
          seniorities={seniorities}
          type="role"
        />
      )}
    </>
  )
}
