import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, VStack } from '@revolut/ui-kit'
import { SkillInterface } from '@src/interfaces/skills'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { SkillOneToOneInsightsInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'
import { SourceItem } from '@src/features/AiInsights/Performance/components/Sources/common/SourceItem'
import { OneToOneQuotes } from '@src/features/AiInsights/Performance/components/Metrics/Internal/OneToOnes/Skills/MetricHighlights/OneToOneQuotes'

interface Props {
  cycleId: number
  oneToOneInsights: SkillOneToOneInsightsInterface[]
  oneToOneMeeting: OneToOneMeeting | null
  skill: SkillInterface
  isLoading: boolean
}

export const OneToOneMetrics = ({
  cycleId,
  oneToOneInsights,
  oneToOneMeeting,
  skill,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <SourceSkeleton />
  }

  const quotedInsights = oneToOneInsights.filter(
    ({ one_to_one_quote }) => !!one_to_one_quote,
  )

  return (
    <VStack space="s-16" data-testid="ai_insights_skill_performance_121_metrics">
      <SourceItem
        title={
          <FormattedMessage
            id="performance.skills.insights.121.notes.title"
            defaultMessage="1:1 notes"
          />
        }
        avatar={<Avatar useIcon="Chat" />}
      />
      {quotedInsights.length > 0 && (
        <OneToOneQuotes
          cycleId={cycleId}
          insights={quotedInsights}
          oneToOneMeeting={oneToOneMeeting}
          skill={skill}
        />
      )}
    </VStack>
  )
}
