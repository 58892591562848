import React from 'react'
import { ItemSkeleton, Placeholder, VStack, Widget } from '@revolut/ui-kit'
import {
  AnytimeFeedbackInterface,
  FeedbackCategory,
} from '@src/interfaces/anytimeFeedback'
import { AnytimeFeedbackCard } from '@components/AnytimeFeedback/AnytimeFeedbackCard'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FormattedMessage } from 'react-intl'

interface Props {
  employee: EmployeeInterface
  feedbacks: AnytimeFeedbackInterface[]
  isLoading: boolean
  onRefresh: VoidFunction
}

export const AnytimeFeedbackNotes = ({
  employee,
  feedbacks,
  isLoading,
  onRefresh,
}: Props) => {
  if (isLoading) {
    return (
      <VStack space="s-16">
        <ItemSkeleton height={100} />
        <ItemSkeleton height={100} />
        <ItemSkeleton height={100} />
      </VStack>
    )
  }

  if (!feedbacks.length) {
    return (
      <Widget>
        <Placeholder>
          <Placeholder.Image
            image={{
              default: `https://assets.revolut.com/assets/3d-images-v2/3D023.png`,
              '2x': `https://assets.revolut.com/assets/3d-images-v2/3D023@2x.png`,
              '3x': `https://assets.revolut.com/assets/3d-images-v2/3D023@3x.png`,
            }}
          />
          <Placeholder.Title>
            <FormattedMessage
              id="one-to-one.anytime.feedback.placeholder.title"
              defaultMessage="There is no feedback data available"
            />
          </Placeholder.Title>
        </Placeholder>
      </Widget>
    )
  }

  return (
    <>
      {feedbacks.map(feedback => (
        <AnytimeFeedbackCard
          key={feedback.id}
          feedback={feedback}
          refreshFeedbackCard={onRefresh}
          employeeData={employee}
          type={FeedbackCategory.OneToOne}
        />
      ))}
    </>
  )
}
