import React from 'react'
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  Bar,
  ReferenceLine,
  Rectangle,
} from 'recharts'
import { Token, Relative } from '@revolut/ui-kit'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ChartEmptyResults } from '@src/pages/Forms/QueryForm/components/Charts/ChartEmptyResults'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import { ChartTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/ChartTooltip'
import { formatPercentage } from '@src/utils/format'
import { DefaultLegend } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultLegend'
import {
  getActiveShapeColor,
  getMainShapeColor,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import {
  NipsHistoryDataKey,
  NipsHistoryItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/NipsBarChart/ChartData'
import { Tooltip } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/NipsBarChart/Tooltip'
import { NIPS_COLOR } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/hooks/useChartData'

const CHART_HEIGHT = 500
const CHART_MARGIN = { top: 16, right: 50, bottom: 5, left: 50 }
const DEFAULT_TICK_COUNT = 5
const X_AXIS_TICK_MARGIN = 8
const Y_AXIS_TICK_MARGIN = 65
const BAR_RADIUS = 6
const Y_AXIS_REFERENCE_ID = 'y_axis'

interface Props {
  dataKey: NipsHistoryDataKey
  data: NipsHistoryItemData[]
  isLoading: boolean
  height?: number
  width?: number
}

export const NipsHistoryBarChart = ({
  data,
  dataKey,
  isLoading,
  width,
  height = CHART_HEIGHT,
}: Props) => {
  const isSmallWidth = width ? width < 650 : false

  const valueFormatter = (value: number) => formatPercentage(value / 100)

  if (isLoading) {
    return <ChartSkeleton height={height} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={height} />
  }

  return (
    <Relative width="100%" height="100%">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout="horizontal" margin={CHART_MARGIN}>
          <XAxis
            dataKey="label"
            tickMargin={X_AXIS_TICK_MARGIN}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="number"
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickCount={DEFAULT_TICK_COUNT}
            tick={{ color: Token.color.greyTone50, fontSize: 12 }}
            orientation="right"
            tickFormatter={valueFormatter}
            tickMargin={Y_AXIS_TICK_MARGIN}
            yAxisId={Y_AXIS_REFERENCE_ID}
          />
          <Bar
            key={dataKey.id}
            dataKey={String(dataKey.value)}
            label={{
              fontSize: 16,
              fontWeight: 500,
              fill: Token.color.foreground,
              angle: isSmallWidth ? -90 : 0,
              formatter: valueFormatter,
            }}
            fill={getMainShapeColor(dataKey.color ?? NIPS_COLOR)}
            yAxisId={Y_AXIS_REFERENCE_ID}
            radius={BAR_RADIUS}
            activeBar={
              <Rectangle fill={getActiveShapeColor(dataKey.color ?? NIPS_COLOR)} />
            }
          />
          <ReferenceLine
            yAxisId={Y_AXIS_REFERENCE_ID}
            y={0}
            stroke={Token.color.greyTone50}
            strokeDasharray="3 3"
            strokeOpacity={0.6}
          />
          <RechartsTooltip
            cursor={false}
            content={<ChartTooltip tooltip={Tooltip} dataKeys={[dataKey]} />}
          />
          <Legend
            content={props => (
              <DefaultLegend props={props} dataKeys={[dataKey]} justifyContent="center" />
            )}
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: '32px',
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Relative>
  )
}
