import React from 'react'
import { FormattedMessage } from 'react-intl'
import { VStack, Widget, Text } from '@revolut/ui-kit'

import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { NOTION_PROFILE_INTEGRATIONS } from '@src/constants/externalLinks'
import { HelperVideoWidget } from '../../components/HelperVideo'

const NOTION_PREVIEW_URL =
  'https://assets.revolut.com/revoluters/assets/integrations/notion_preview.png'
const NOTION_VIDEO_URL =
  'https://assets.revolut.com/revoluters/assets/integrations/notion.mov'

export const NotionIntegrationHelperContent = () => (
  <VStack space="s-16">
    <Widget>
      <AutoStepper>
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title={
            <FormattedMessage
              id="integrations.notion.helper.stepper.1"
              defaultMessage="Log in to your <T>Notion account</T>, go to <T>Settings &rarr; Connections</T> and select <T>Develop or manage integrations</T>"
              values={{
                T: text => <Text fontWeight={700}>{text}</Text>,
              }}
            />
          }
          bulletVariant="filled"
          subtitle={
            <VStack pt="s-12">
              <OpenInNewTab link={NOTION_PROFILE_INTEGRATIONS} label="Take me there" />
            </VStack>
          }
        />
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title={
            <FormattedMessage
              id="integrations.notion.helper.stepper.2"
              defaultMessage="Click <T>”+ Create new integration”</T>"
              values={{
                T: text => <Text fontWeight={700}>{text}</Text>,
              }}
            />
          }
          bulletVariant="filled"
        />
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title={
            <FormattedMessage
              id="integrations.notion.helper.stepper.3"
              defaultMessage="Configure your internal integration"
            />
          }
          subtitle="Fill in Integration name; Select associated workspace; Select “Internal” in the “Type” field"
          bulletVariant="filled"
        />

        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title={
            <FormattedMessage
              id="integrations.notion.helper.stepper.4"
              defaultMessage="Go to integration settings"
            />
          }
          subtitle="Once integration is created, press “Configure integration settings” in the pop up"
          bulletVariant="filled"
        />

        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title={
            <FormattedMessage
              id="integrations.notion.helper.stepper.5"
              defaultMessage="Copy Internal Integration Secret"
            />
          }
          subtitle="Use Internal Integration Secret in Revolut People to connect Notion"
          bulletVariant="filled"
        />

        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title={
            <FormattedMessage
              id="integrations.notion.helper.stepper.6"
              defaultMessage="Connect your database"
            />
          }
          subtitle="Navigate to your Project database you want to connect, press “...” icon on the top right of the screen, select “Connections” in the drop-down menu and select your internal integration from the list"
          bulletVariant="filled"
        />
      </AutoStepper>
      <HelperVideoWidget
        preview={NOTION_PREVIEW_URL}
        video={NOTION_VIDEO_URL}
        poster={NOTION_PREVIEW_URL}
      />
    </Widget>
  </VStack>
)
