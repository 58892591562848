import { useGetRoadmapSettings } from '@src/api/settings'
import { ItemType } from '@src/interfaces/deliverables'

interface Params {
  includeManual?: boolean
}

const defaults: Params = {
  includeManual: true,
}

export const useRoadmapTypeOptions = (params?: Params) => {
  const { includeManual } = params || defaults
  const { data: roadmapSettings, isLoading: roadmapsLoading } = useGetRoadmapSettings()

  const options = [
    ...(roadmapSettings?.jira_epics_enabled
      ? [
          {
            id: ItemType.Jira,
            label: 'Jira',
            value: { id: ItemType.Jira, name: 'Jira' },
          },
        ]
      : []),
    ...(roadmapSettings?.clickup_enabled
      ? [
          {
            id: ItemType.Clickup,
            label: 'ClickUp',
            value: { id: ItemType.Clickup, name: 'ClickUp' },
          },
        ]
      : []),
    ...(roadmapSettings?.linear_enabled
      ? [
          {
            id: ItemType.Linear,
            label: 'Linear',
            value: { id: ItemType.Linear, name: 'Linear' },
          },
        ]
      : []),
    ...(roadmapSettings?.notion_enabled
      ? [
          {
            id: ItemType.Notion,
            label: 'Notion',
            value: { id: ItemType.Notion, name: 'Notion' },
          },
        ]
      : []),
    ...(roadmapSettings?.monday_enabled
      ? [
          {
            id: ItemType.Monday,
            label: 'Monday.com',
            value: { id: ItemType.Monday, name: 'Monday.com' },
          },
        ]
      : []),
    ...(includeManual
      ? [
          {
            id: ItemType.Manual,
            label: 'Manual',
            value: { id: ItemType.Manual, name: 'Manual' },
          },
        ]
      : []),
  ]

  return {
    isLoading: roadmapsLoading,
    options,
  }
}
