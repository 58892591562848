import React from 'react'
import { Caption, Group, Token } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { PageWrapper } from '@src/components/Page/Page'
import { OnboardingChecklistHeader } from '../../OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageBody } from '@src/components/Page/PageBody'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { GoalsSettingsProps } from './Goals'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { NewSectionCardSkeleton } from '@src/components/NewSelectionCard/NewSectionCardSkeleton'
import { getSettingsLinkConfig } from '../common/config'
import { goalsSettingsLinkConfig } from './config'
import { ROUTES } from '@src/constants/routes'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'

const Title = () => (
  <FormattedMessage id="goalsSettings.page.title" defaultMessage="Goals settings" />
)

const Description = () => (
  <FormattedMessage
    id="goalsSettings.page.description"
    defaultMessage="Settings for all goals in the system"
  />
)

export const GoalsSettingsAll = ({
  backUrl,
  targetTypesRoute,
  approvalProcessRoute,
  roadmapsRoute,
  templatesRoute,
  rulesRoute,
  isOnboarding,
  jiraIntegrationRoute,
  tableauIntegrationRoute,
  lookerIntegrationRoute,
  clickUpIntegrationRoute,
  salesforceIntegrationRoute,
  linearIntegrationRoute,
  notionIntegrationRoute,
  mondayIntegrationRoute,
}: GoalsSettingsProps) => {
  const isCommercial = useIsCommercial()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const canViewJiraIntegration = useHasGlobalPermission(
    PermissionTypes.ViewJiraIntegration,
  )
  const canViewTableauIntegration = useHasGlobalPermission(
    PermissionTypes.ViewTableauIntegration,
  )
  const canViewLookerIntegration = useHasGlobalPermission(
    PermissionTypes.ViewLookerIntegration,
  )
  const canViewClickUpIntegration = useHasGlobalPermission(
    PermissionTypes.ViewClickupIntegration,
  )
  const canViewSalesforceIntegration = useHasGlobalPermission(
    PermissionTypes.ViewSalesforceIntegration,
  )

  const canViewLinearIntegration = useHasGlobalPermission(
    PermissionTypes.ViewLinearIntegration,
  )

  const canViewNotionIntegration = useHasGlobalPermission(
    PermissionTypes.ViewNotionIntegration,
  )

  const canChangeMondayIntegration = useHasGlobalPermission(
    PermissionTypes.ChangeMondayIntegration,
  )

  const canViewKPITemplate = useHasGlobalPermission(PermissionTypes.ViewKPITemplate)
  const canViewPerformancePreferences = useHasGlobalPermission(
    PermissionTypes.ViewPerformancePreferences,
  )

  const { data: performanceSettings, isLoading } = useGetPerformanceSettings()
  const canSeeRulesAndTemplates =
    !isCommercial &&
    (canViewKPITemplate || canViewPerformancePreferences) &&
    performanceSettings?.enable_kpi_templates

  const linkToRouteMap = {
    goalsMetricTypes: targetTypesRoute,
    goalsRoadmaps: roadmapsRoute,
    goalsApprovalProcess: approvalProcessRoute,
    goalsJiraIntegration: jiraIntegrationRoute,
    goalsTableauIntegration: tableauIntegrationRoute,
    goalsLookerIntergration: lookerIntegrationRoute,
    goalsClickUpIntergration: clickUpIntegrationRoute,
    goalsSalesforceIntegration: salesforceIntegrationRoute,
    goalsLinearIntegration: linearIntegrationRoute,
    goalsNotionIntegration: notionIntegrationRoute,
    goalsMondayIntegration: mondayIntegrationRoute,
    goalsSQLIntergration: ROUTES.SETTINGS.ANALYTICS,
  } as const

  const config = getSettingsLinkConfig({
    config: goalsSettingsLinkConfig.map(link => ({
      ...link,
      to: linkToRouteMap[link.id] || link.to,
    })),
    permissions,
    featureFlags,
    type: 'settings',
  })

  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title />}
          description={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      ) : (
        <PageHeader
          title={<Title />}
          subtitle={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      )}

      <PageBody>
        <Group>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {config.goalsMetricTypes?.component}
              {canSeeRulesAndTemplates && (
                <>
                  <NewSelectionCard
                    icon="ViewGrid"
                    title="Templates"
                    to={templatesRoute}
                    use={InternalLink}
                  />
                  <NewSelectionCard
                    icon="IndustrialGear"
                    title="Rules"
                    to={rulesRoute}
                    use={InternalLink}
                  />
                </>
              )}

              {config.goalsRoadmaps?.component}
              {config.goalsApprovalProcess?.component}
            </>
          )}
        </Group>
        {[
          canViewClickUpIntegration,
          canViewJiraIntegration,
          canViewLookerIntegration,
          canViewTableauIntegration,
        ].some(Boolean) && (
          <>
            <Caption color={Token.color.greyTone50} mt="s-24" mb="s-16">
              <FormattedMessage
                defaultMessage="Integrations"
                id="settings.goals.integrations.title"
              />
            </Caption>
            <Group>
              {canViewJiraIntegration ? config.goalsJiraIntegration?.component : null}
              {canViewTableauIntegration
                ? config.goalsTableauIntegration?.component
                : null}
              {canViewLookerIntegration
                ? config.goalsLookerIntergration?.component
                : null}
              {canViewClickUpIntegration
                ? config.goalsClickUpIntergration?.component
                : null}
              {canViewSalesforceIntegration
                ? config.goalsSalesforceIntegration?.component
                : null}
              {canViewLinearIntegration ? config.goalsLinearIntegration?.component : null}
              {canViewNotionIntegration ? config.goalsNotionIntegration?.component : null}
              {canChangeMondayIntegration
                ? config.goalsMondayIntegration?.component
                : null}
              {isCommercial ? config.goalsSQLIntergration?.component : null}
            </Group>
          </>
        )}
      </PageBody>
    </PageWrapper>
  )
}

const Skeleton = () => {
  return (
    <>
      <NewSectionCardSkeleton />
      <NewSectionCardSkeleton />
      <NewSectionCardSkeleton />
    </>
  )
}
