import { Placeholder } from '@revolut/ui-kit'
import React from 'react'

export const OutliersPlaceholder = ({ type }: { type?: string }) => {
  const getTitle = () => (type ? `No ${type} outliers found` : 'No outliers found')
  const getDescription = () =>
    type
      ? `We couldn't find any ${type} outliers for this survey round`
      : "We couldn't find outliers for this survey round"

  return (
    <Placeholder>
      <Placeholder.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
        }}
      />
      <Placeholder.Title>{getTitle()}</Placeholder.Title>
      <Placeholder.Description>{getDescription()}</Placeholder.Description>
    </Placeholder>
  )
}
