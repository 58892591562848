import { IconName, Color, Token } from '@revolut/ui-kit'
import {
  AvailableSubscriptionPlanInterfaceV2,
  SubscriptionPlanInfterface,
} from '@src/interfaces/plans'
import { BundleType } from '@src/store/auth/types'

export const features = [
  {
    title: 'HR management',
    description: 'Features to manage the complete employee lifecycle',
    icon: 'People' as const,
    items: ['Employees', 'Lifecycle', 'Timeoff', 'Contracts', 'Documents'],
  },
  {
    title: 'Performance',
    description: 'Advanced performance features to continuously raise the bar',
    icon: 'AddContact' as const,
    items: ['Goals', 'Reviews'],
  },
  {
    title: 'Recruitment',
    description: 'End to end applicant tracking and candidate management',
    icon: 'AddContact' as const,
    items: ['Headcount planning', 'Job postings', 'Candidate management', 'Offer forms'],
  },
  {
    title: 'Organisation',
    icon: 'Bank' as const,
    items: ['Teams', 'Roles and Functions', 'Locations', 'Legal entities'],
  },
  {
    title: 'Administration and support',
    icon: 'HelpChatOutline' as const,
    items: [
      'Customer support',
      'Access management',
      'Dedicated customer success manager',
    ],
  },
]

export interface PlanFeatures {
  title: string
  icon: IconName
  items?: string[]
}

export type PlanIdentifier = 'performance' | 'hr' | 'recruitment' | 'allinone'

export interface PlanConfiguration {
  id: PlanIdentifier
  label: string
  features: PlanFeatures[]
  bgColor: Color
  planDescription: string
  discountedPrices: number[]
}

export const plansConfig: PlanConfiguration[] = [
  {
    id: 'performance',
    label: 'Performance',
    features: [
      {
        title: 'Goals and Roadmaps',
        icon: 'Target',
        items: [
          'Company and team goals',
          'Goals hierarchy and cascading',
          'Roadmaps synced with Jira, Notion, Linear and more',
        ],
      },
      {
        title: 'Performance reviews and reporting',
        icon: 'StarSemi',
        items: [
          'Competency Frameworks',
          '1-1 meeting notes',
          'Flexible review cycles',
          'PIP and probation',
          'Dashboards and analytics',
        ],
      },
      {
        title: 'Engagement surveys',
        icon: 'Questionnaire',
        items: ['Flexible audience', 'Anonymous results', 'Notifications', 'AI insights'],
      },
      {
        title: 'Organisation structure',
        icon: 'Bank',
        items: [
          'Departments and teams',
          'Roles and Functions',
          'Locations',
          'Legal entities',
        ],
      },
      {
        title: 'Administration and support',
        icon: 'HelpChatOutline',
        items: ['Customer support', 'Roles and permissions'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Features:',
    discountedPrices: [7.6, 7.5, 7.4, 7.2, 7.1, 7],
  },
  {
    id: 'hr',
    label: 'HR',
    features: [
      {
        title: 'Time management',
        icon: 'Resort',
        items: [
          'Time off policies & approvals',
          'Public holidays',
          'Time and attendance',
        ],
      },
      {
        title: 'Document management',
        icon: 'DocumentsPair',
        items: ['Documents', 'eSignatures with DocuSign'],
      },
      {
        title: 'Employee management',
        icon: '20/AutoExchange',
        items: ['Onboarding', 'Compensation and benefits'],
      },
      {
        title: 'Payroll reporting',
        icon: 'CreditBag',
        items: ['Employee change tracking', 'Payroll integrations'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Everything in Performance, plus:',
    discountedPrices: [10.45, 10.31, 10.18, 9.9, 9.76, 9.63],
  },
  {
    id: 'recruitment',
    label: 'Recruitment',
    features: [
      {
        title: 'Workforce planning',
        icon: 'RepairTool',
        items: ['Headcount requests', 'Approval process'],
      },
      {
        title: 'Find candidates',
        icon: 'UsersSearch',
        items: [
          'Hosted careers site',
          'Job posting management',
          'Connected job boards',
          'CRM',
        ],
      },
      {
        title: 'Evaluate candidates',
        icon: 'LikeDislike',
        items: ['Workflow automations', 'Scheduling'],
      },
      {
        title: 'Hire candidates',
        icon: 'Heart',
        items: ['Offer management', 'Confidential candidates'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Everything in Performance, plus:',
    discountedPrices: [10.45, 10.31, 10.18, 9.9, 9.76, 9.63],
  },
  {
    id: 'allinone',
    label: 'All-in-one',
    features: [
      {
        title: 'All HR features',
        icon: 'People',
        items: [
          'Time management',
          'Document management',
          'Employee management',
          'Payroll reporting',
        ],
      },
      {
        title: 'All Recruitment features',
        icon: 'AddContact',
        items: [
          'Workforce planning',
          'Find candidates',
          'Evaluate candidates',
          'Hire candidates',
        ],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Everything in Performance, plus:',
    discountedPrices: [11.4, 11.25, 11.1, 10.8, 10.65, 10.5],
  },
]

export type CustomPlanConfiguration = Pick<PlanConfiguration, 'bgColor'> & {
  features: PlanConfiguration[]
}
const getPlanIDForBundle = (bundle: BundleType): PlanIdentifier | null => {
  switch (bundle) {
    case 'hr_bundle_features':
      return 'hr'
    case 'recruitment_bundle_features':
      return 'recruitment'
    default:
      return null
  }
}
const getPlanForBundle = (bundle: BundleType): PlanConfiguration | null => {
  const planToFind = getPlanIDForBundle(bundle)
  if (planToFind) {
    return plansConfig.find(plan => plan.id === planToFind) ?? null
  }
  return null
}
export const customPlanConfiguration = (
  subscription: SubscriptionPlanInfterface,
): CustomPlanConfiguration => {
  return {
    bgColor: Token.color.widgetBackground,
    features: [
      plansConfig.find(p => p.id === 'performance'), // performance is always included
      ...subscription.bundle_features.map(getPlanForBundle),
    ].filter(Boolean),
  }
}

export const isStandardPlan = (
  plan: AvailableSubscriptionPlanInterfaceV2 | SubscriptionPlanInfterface,
): plan is AvailableSubscriptionPlanInterfaceV2 => {
  return 'marketing_full_price' in plan
}
