import React from 'react'
import { useGetInterviewerProfile } from '@src/api/recruitment/interviews'
import { DetailsCell, Group, VStack } from '@revolut/ui-kit'
import { InterviewerProfileDetails } from '@src/features/InterviewerProfile/InterviewerProfileDetails'
import { formatPercentage, formatSecondsDuration } from '@src/utils/format'
import { InterviewerProfileHirePerformanceDistributionDetails } from '@src/features/InterviewerProfile/InterviewerProfileHirePerformanceDistributionDetails'

interface InterviewerProfileProps {
  interviewerId: number
}

export const InterviewerProfile = ({ interviewerId }: InterviewerProfileProps) => {
  const { data, isLoading } = useGetInterviewerProfile(interviewerId)
  return (
    <VStack gap="s-16">
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Number of interviews</DetailsCell.Title>
          <InterviewerProfileDetails loading={isLoading}>
            {data?.total_interviews}
          </InterviewerProfileDetails>
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Pass rate</DetailsCell.Title>
          <InterviewerProfileDetails loading={isLoading}>
            {formatPercentage(data?.pass_rate)}
          </InterviewerProfileDetails>
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Final pass rate</DetailsCell.Title>
          <InterviewerProfileDetails loading={isLoading}>
            {formatPercentage(data?.final_pass_rate)}
          </InterviewerProfileDetails>
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Feedback delay</DetailsCell.Title>
          <InterviewerProfileDetails loading={isLoading}>
            {formatSecondsDuration(data?.average_feedback_delay)}
          </InterviewerProfileDetails>
        </DetailsCell>
      </Group>
      {data?.hire_performance_distribution && (
        <InterviewerProfileHirePerformanceDistributionDetails
          hirePerformanceDistribution={data?.hire_performance_distribution}
        />
      )}
    </VStack>
  )
}
