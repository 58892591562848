import { RoleStep } from '@src/interfaces/roles'
import { useGlobalSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'

export const useNextSpecialisationStepButtonLink = (currentStep: RoleStep) => {
  const {
    settings: { candidates_enabled, job_postings_enabled },
  } = useGlobalSettings()

  switch (currentStep) {
    case 'general':
      return ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX

    case 'competency_matrix': {
      if (candidates_enabled) {
        return ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS
      }

      if (job_postings_enabled) {
        return ROUTES.FORMS.SPECIALISATIONS.POSTINGS
      }

      return ROUTES.FORMS.SPECIALISATIONS.PREVIEW
    }

    case 'hiring_process':
      return job_postings_enabled
        ? ROUTES.FORMS.SPECIALISATIONS.POSTINGS
        : ROUTES.FORMS.SPECIALISATIONS.PREVIEW

    case 'postings':
      return ROUTES.FORMS.SPECIALISATIONS.PREVIEW

    default:
      return ROUTES.FORMS.SPECIALISATIONS.EDIT.GENERAL_INFO
  }
}
