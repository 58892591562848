import React, { useState } from 'react'
import { Button, Token } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { EditableEmployeesTableDetailed } from '@src/features/EditableEmployeesTable/TableDetailed'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { PageActions } from '@components/Page/PageActions'
import { EmployeeTable } from './EmployeeTable'
import { useHasBundleHR } from '@src/hooks/useHasBundle'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

const EmployeesBase = () => {
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <>
        <EditableEmployeesTableDetailed
          statsView="default"
          sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.SESSION}
        />
        <PageActions maxWidthMd={Token.breakpoint.xl}>
          <Button mt="s-32" onClick={() => setIsEditing(false)}>
            Submit
          </Button>
        </PageActions>
      </>
    )
  }
  return <EmployeeTable onClickBulkEdit={() => setIsEditing(true)} />
}

export const Employees = () => {
  const isNewPlansEnabled = useHasFeatureFlag(FeatureFlags.SubscriptionPlansV2)
  const hasBundleHr = useHasBundleHR()
  const hasEmployeeRecordsOnboardingV2 = useHasFeatureFlag(
    FeatureFlags.EmployeeRecordsOnboardingV2,
  )

  return isNewPlansEnabled && (!hasBundleHr || hasEmployeeRecordsOnboardingV2) ? (
    <EmployeesBase />
  ) : (
    <OnboardingAppScreen category="employeeRecords">
      <EmployeesBase />
    </OnboardingAppScreen>
  )
}
