import React from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Placeholder, Widget } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useQuery } from '@src/utils/queryParamsHooks'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import PageLoading from '@components/PageLoading/PageLoading'
import {
  SkillRatingsDistributionFilterKeys,
  useGetSkillsRatingsDistribution,
} from '@src/features/ReviewCycle/Analytics/api/skills'
import { SkillsComparator } from '@src/features/ReviewCycle/Analytics/Skills/SkillsComparator/Comparator'
import { useDepartmentReviewCycles } from '@src/pages/Forms/DepartmentForm/Performance/hooks/useReviewCycles'

export const SkillsRatingsDistributionComparatorPage = () => {
  const { id: departmentId } = useParams<{ id: string }>()
  const { query } = useQuery()
  const cycleId = Number(query[SkillRatingsDistributionFilterKeys.CycleId])

  const { data: skills = [], isLoading: isSkillsLoading } =
    useGetSkillsRatingsDistribution({
      [SkillRatingsDistributionFilterKeys.CycleId]: cycleId,
      [SkillRatingsDistributionFilterKeys.DepartmentId]: Number(departmentId),
    })
  const { options: cycleOptions, isLoading: isCycleOptionsLoading } =
    useDepartmentReviewCycles(Number(departmentId))

  if (isSkillsLoading || isCycleOptionsLoading) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          <PageHeader.Title
            title={
              <FormattedMessage
                id="performance.analytics.skills.ratings.compare.page.title"
                defaultMessage="Compare skills"
              />
            }
            useIcon="BarChart"
          />
        }
        backUrl={pathToUrl(ROUTES.FORMS.DEPARTMENT.PERFORMANCE.SUMMARY, {
          id: departmentId,
        })}
      />
      <PageBody maxWidth="100%">
        {!skills.length ? (
          <Widget>
            <Placeholder>
              <Placeholder.Image
                image={{
                  default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
                  '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
                  '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
                }}
              />
              <Placeholder.Title>
                <FormattedMessage
                  id="performance.analytics.skills.ratings.compare.empty.data.title"
                  defaultMessage="There is no skills ratings distribution data available"
                />
              </Placeholder.Title>
            </Placeholder>
          </Widget>
        ) : (
          <SkillsComparator
            departmentId={Number(departmentId)}
            cycleId={cycleId}
            skills={skills}
            cycleOptions={cycleOptions}
          />
        )}
      </PageBody>
    </PageWrapper>
  )
}
