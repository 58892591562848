import { RequestInterfaceNew, Statuses } from '@src/interfaces'
import { api, apiV2, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import {
  InterviewFeedbackStatus,
  InterviewResult,
  InterviewFeedbackInterface,
  InterviewFeedbackSummaryInterface,
  InterviewToolInterviewer,
  InterviewFeedbackTranscript,
} from '@src/interfaces/interviewTool'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import {
  transformInterviewToPerformanceScorecard,
  transformPerformanceToInterviewScorecard,
} from '@src/utils/interview'
import { InterviewScorecardTemplateSectionInterface } from '@src/interfaces/interviewScorecardTemplates'
import { useFetchV2 } from '@src/utils/reactQuery'
import { pathToUrl } from '@src/utils/router'
import { useMutation, useQueryClient } from 'react-query'
import { useContext } from 'react'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

export const patchResultScorecard = (id: number, result: InterviewResult) =>
  apiWithoutHandling.patch(`${API.INTERVIEWS}/interviewFeedbacks/${id}`, {
    result,
    status: InterviewFeedbackStatus.completed,
  } as Partial<InterviewFeedbackInterface>)

export const generateAndPatchResultScorecard = async (
  id: number,
  userId: number,
  result: InterviewResult,
) => {
  const resp = await createFeedback(id, {
    id: userId,
  } as InterviewToolInterviewer)

  const scorecard = resp.data

  if (scorecard) {
    return patchResultScorecard(scorecard.id, result)
  }

  return Promise.reject(new Error('No scorecard'))
}

export const interviewFeedbackFormRequests: RequestInterfaceNew<InterviewFeedbackInterface> =
  {
    get: async ({ id, subtab }) => {
      const resp = await api.get<InterviewFeedbackInterface>(
        `${API.INTERVIEWS}/interviewFeedbacks/${subtab || id}`,
      )

      return { ...resp, data: transformInterviewToPerformanceScorecard(resp.data) }
    },
    update: async (_, { id }, data) => {
      const resp = await apiWithoutHandling.put(
        `${API.INTERVIEWS}/interviewFeedbacks/${id}`,
        data ? transformPerformanceToInterviewScorecard(data) : {},
      )

      return { ...resp, data: transformInterviewToPerformanceScorecard(resp.data) }
    },
    submit: async data =>
      apiWithoutHandling.post(API.INTERVIEWS, {
        ...data,
        status: Statuses.completed,
      }),
  }

export const createFeedback = (id: number, interviewer: InterviewToolInterviewer) =>
  api.post(`${API.INTERVIEWS}/interviewFeedbacks`, {
    interview_stage: { id },
    interviewer,
  })

export const getDeliverySectionDefault = () =>
  api.get<Partial<InterviewScorecardTemplateSectionInterface>>(
    `${API.HIRING_PROCESS}/interviewScorecardTemplates/deliverySectionDefault`,
  )

export const generateSummary = (
  id: number,
  data: InterviewFeedbackInterface,
): AxiosPromise<InterviewFeedbackSummaryInterface> =>
  apiWithoutHandling.post(
    `${API.INTERVIEWS}/interviewFeedbacks/${id}/scorecardSummary`,
    transformPerformanceToInterviewScorecard(data),
  )

export const useGetInterviewFeedbackTranscript = (
  interviewFeedbackId?: number | string,
) =>
  useFetchV2<InterviewFeedbackTranscript>({
    url: pathToUrl(API.INTERVIEW_TRANSCRIPT, {
      interviewFeedbackId: String(interviewFeedbackId),
    }),
    queryOptions: {
      enabled: !!interviewFeedbackId,
    },
  })

export const useInvalidateInterviewFeedBackTranscript = () => {
  const queryClient = useQueryClient()
  return (interviewFeedbackId: number | string) =>
    queryClient.invalidateQueries(
      pathToUrl(API.INTERVIEW_TRANSCRIPT, {
        interviewFeedbackId: String(interviewFeedbackId),
      }),
      { refetchActive: true },
    )
}

export const useGenerateInterviewSummary = () => {
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const apiFunc = apiHandler || apiV2
  return useMutation<AxiosResponse, AxiosError, number | string>(interviewFeedbackId =>
    apiFunc.post(
      pathToUrl(API.GENERATE_INTERVIEW_SUMMARY, {
        interviewFeedbackId,
      }),
    ),
  )
}

export const useDeleteInterviewTranscript = () => {
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const apiFunc = apiHandler || apiV2
  return useMutation<AxiosResponse, AxiosError, number | string>(interviewFeedbackId =>
    apiFunc.post(
      pathToUrl(API.DELETE_INTERVIEW_TRANSCRIPT, {
        interviewFeedbackId,
      }),
    ),
  )
}
