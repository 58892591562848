import React, { useMemo } from 'react'
import { FilterOption, RowInterface } from '@src/interfaces/data'
import { CycleOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { SpecialisationFilterTitle } from '@src/features/Roles/helpers'
import { SkillRatingsDistributionFilterKeys } from '@src/features/ReviewCycle/Analytics/api/skills'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import Table from '@components/TableV2/Table'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import {
  SkillRatingsDistributionInterface,
  TalentSkillRatingComparableInterface,
} from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import {
  employeeColumn,
  getSpecialisationColumn,
  seniorityColumn,
  teamColumn,
} from '@src/features/ReviewCycle/Analytics/Skills/Tables/common/columns'
import { useTableFilters } from '@src/features/ReviewCycle/Analytics/Skills/Tables/CompareTalentSkillRating/hooks/useTableFilters'
import { useTableData } from '@src/features/ReviewCycle/Analytics/Skills/Tables/CompareTalentSkillRating/hooks/useTableData'
import { getRatingColumn } from '@src/features/ReviewCycle/Analytics/Skills/Tables/CompareTalentSkillRating/columns'

interface Props {
  departmentId: number
  cycleId: number
  skills: SkillRatingsDistributionInterface[]
  cycleOptions: CycleOption[]
  actions: React.ReactNode
}

const getRow = (
  skills: SkillRatingsDistributionInterface[],
  isSpecialisationsEnabled: boolean,
): RowInterface<TalentSkillRatingComparableInterface> => ({
  cells: [
    { ...employeeColumn, width: 200 },
    { ...teamColumn, width: 100 },
    { ...getSpecialisationColumn(isSpecialisationsEnabled), width: 100 },
    { ...seniorityColumn, width: 100 },
    ...skills.map(({ skill: { id, name } }) => ({
      ...getRatingColumn(id, name),
      width: 100,
    })),
  ],
})

export const CompareTalentSkillRatingTable = ({
  departmentId,
  cycleId,
  skills,
  cycleOptions,
  actions,
}: Props) => {
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()

  const table = useTableData({
    departmentId,
    cycleId,
  })
  const row = useMemo(
    () => getRow(skills, isSpecialisationsEnabled),
    [skills, isSpecialisationsEnabled],
  )
  const {
    teams: { options: teamsOptions },
    specialisations: { options: specialisationsOptions },
  } = useTableFilters(table, skills)

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search
          variant="compact"
          placeholder="Search by employee"
          columnName={SkillRatingsDistributionFilterKeys.SearchEmployee}
          onFilter={searchQuery => table.onFilterChange(searchQuery, false)}
        />
        <CycleFilterButton
          columnName={SkillRatingsDistributionFilterKeys.CycleId}
          filter={table.filterBy}
          selector={() => Promise.resolve({ options: cycleOptions })}
          onFilterChange={table.onFilterChange}
        />
        <ButtonFilter<FilterOption>
          value={teamsOptions}
          type={FilterSelectType.MultiSelect}
          selector={selectorKeys.team}
          title={teamsOptions.length ? 'Selected teams' : 'All teams'}
          useIcon="Profile"
          variant="inactive"
          onChange={filters =>
            table.onFilterChange({
              columnName: SkillRatingsDistributionFilterKeys.TeamId,
              filters,
            })
          }
        />
        <ButtonFilter<FilterOption>
          value={specialisationsOptions}
          type={FilterSelectType.MultiSelect}
          selector={selectorKeys.specialisations}
          title={<SpecialisationFilterTitle count={specialisationsOptions.length} />}
          useIcon="RepairTool"
          variant="inactive"
          onChange={filters =>
            table.onFilterChange({
              columnName: SkillRatingsDistributionFilterKeys.SpecialisationId,
              filters,
            })
          }
        />
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>{actions}</Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<TalentSkillRatingComparableInterface>
          name={TableNames.TalentSkillRatingComparator}
          row={row}
          {...table}
          emptyState={<EmptyTableRaw title="No rating distribution data found" />}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
