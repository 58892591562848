import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  ActionButton,
  Avatar,
  Box,
  chain,
  Item,
  TextWidgetSkeleton,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import {
  FiltersDetailsList,
  toFilterDetailsListItemsProps,
} from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Filters/FiltersDetails'
import { FilterLabel } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Filters/constants'
import { itemStylesOverrides } from '@src/features/AiInsights/Performance/components/InsightsGroups/common/styles'

interface Props {
  employeesCount: number
  insightsGroup: PerformanceInsightsGroupInterface
  isLoading: boolean
  onManageClick: VoidFunction
}

export const EligibilityDetails = ({
  employeesCount,
  insightsGroup,
  isLoading,
  onManageClick,
}: Props) => {
  const filtersCount = Object.values(insightsGroup.filters).filter(Boolean).length

  if (isLoading) {
    return <TextWidgetSkeleton />
  }

  return (
    <Widget data-testid="performance_ai_insights_group_eligibility_details_widget">
      <VStack>
        <Item>
          <Item.Content>
            <Item.Title>
              {chain(
                <FormattedMessage
                  id="forms.performance.insights.group.eligibility.details.widget.title"
                  defaultMessage="Eligible employees"
                />,
                employeesCount,
              )}
            </Item.Title>
            <Item.Description>
              <FormattedMessage
                id="forms.performance.insights.group.eligibility.details.widget.description"
                defaultMessage="List of employees to whom the template will be applied."
              />
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton useIcon="Pencil" onClick={onManageClick}>
              <FormattedMessage id="common.manage.cta.label" defaultMessage="Manage" />
            </ActionButton>
          </Item.Side>
        </Item>
        <Box px="s-16" pb="s-16">
          <Item style={itemStylesOverrides}>
            <Item.Avatar>
              <Avatar useIcon="People" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <FilterLabel count={filtersCount} />
              </Item.Title>
              {filtersCount > 0 && (
                <Item.Description>
                  <Box pt="s-8">
                    <FiltersDetailsList
                      items={toFilterDetailsListItemsProps(insightsGroup)}
                    />
                  </Box>
                </Item.Description>
              )}
            </Item.Content>
          </Item>
        </Box>
      </VStack>
    </Widget>
  )
}
