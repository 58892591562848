import React, { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Avatar, chain, Copyable, Group, Item, Text, Token } from '@revolut/ui-kit'

import { FormPreview } from '@components/FormPreview/FormPreview'
import { useGetPersonalInfo } from '@src/api/employees'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import {
  EmployeeContactsInterface,
  EmployeeInterface,
  PersonalInfoInterface,
} from '@src/interfaces/employees'
import { onCopyFieldNotification } from '@src/pages/EmployeeProfile/Layout/common/helpers'
import { useActiveProfileSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { useEmployeePersonalDetails } from './hooks'
import { PersonalDetailsSidebar } from './Sidebar'

type Props = {
  data: EmployeeInterface
  dynamicGroups: DynamicGroupIDs
}
export const PersonalDetails = ({ data, dynamicGroups }: Props) => {
  const { setSidebarQueryParam } = useActiveProfileSidebar()

  const personalDetails = useEmployeePersonalDetails(data.id, dynamicGroups)
  const { widget } = personalDetails.sections
  const { data: personalInfo, refetch: refetchPersonalInfo } = useGetPersonalInfo(data.id)

  // TODO: use count values && preview warning messages from BE when ready
  const missingFieldsCount = useMemo(() => {
    const fieldsToCheck: Array<
      keyof PersonalInfoInterface | Array<keyof PersonalInfoInterface>
    > = [
      'languages',
      'marital_status',
      'birth_date',
      'nationalities',
      'personal_email',
      'emergency_contact_full_name',
      'emergency_contact_relationship',
      'emergency_contact_email',
      'emergency_contact_mobile_phone',
      ['address_line_1', 'post_code', 'city', 'country'],
    ]

    const isFieldDataMissing = (
      field: keyof PersonalInfoInterface | Array<keyof PersonalInfoInterface>,
    ): boolean => {
      if (!personalInfo) {
        return false
      }
      if (Array.isArray(field)) {
        return field.every(fieldKey => isEmpty(personalInfo[fieldKey] || []))
      }
      return isEmpty(personalInfo[field] || [])
    }

    return fieldsToCheck.reduce((count, field) => {
      if (isFieldDataMissing(field)) {
        return count + 1
      }
      return count
    }, 0)
  }, [personalInfo])

  return (
    <>
      <Group>
        <Item
          use="button"
          onClick={() => setSidebarQueryParam('personalDetails', true)}
          aria-label="Open personal details sidebar"
          variant="disclosure"
        >
          <Item.Avatar>
            <Avatar useIcon="NationalId">
              {!!missingFieldsCount && (
                <Avatar.Badge useIcon="16/ExclamationMarkSign" bg={Token.color.warning} />
              )}
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              {chain(
                <Text>Personal details</Text>,
                missingFieldsCount ? (
                  <Text color={Token.color.danger}>{missingFieldsCount}</Text>
                ) : undefined,
              )}
            </Item.Title>
            <Item.Description>
              Bio, Contact, Bank, Emergency, Laptop, About, Diversity
            </Item.Description>
          </Item.Content>
        </Item>
        <FormPreview data={widget.data} dataHandler={widget.handler}>
          <FormPreview.Item<Pick<EmployeeContactsInterface, 'personal_email'>>
            title="Personal email"
            field="personal_email"
            insert={d =>
              d.personal_email ? (
                <Copyable
                  value={d.personal_email}
                  labelButtonCopy="Copy employee personal email"
                  onClick={() => onCopyFieldNotification('Email')}
                >
                  {d.personal_email}
                </Copyable>
              ) : (
                '-'
              )
            }
            loading={widget.handler.status === 'loading' || widget.handler.isRefetching}
            emptyMessage="Missing required personal email"
          />
        </FormPreview>
      </Group>
      <PersonalDetailsSidebar
        data={data}
        refreshWidgetData={() => {
          refetchPersonalInfo()
          widget.handler.refetch()
        }}
        personalDetails={personalDetails}
        onClose={() => setSidebarQueryParam('personalDetails', false)}
        dynamicGroups={dynamicGroups}
      />
    </>
  )
}
