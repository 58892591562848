import React, { useEffect, useState } from 'react'
import Summary from '@src/pages/Forms/Candidate/InterviewProgress/Summary'
import { Link, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import {
  getCandidate,
  getInterviewRound,
  getRecruiterDetails,
} from '@src/api/recruitment/interviews'
import { connect } from 'lape'
import { PageWrapper } from '@components/Page/Page'
import {
  Avatar,
  AvatarSkeleton,
  Box,
  Flex,
  Icon,
  Link as UIKitLink,
  Token,
} from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToFullUrlWithActiveWorkspace, pathToUrl } from '@src/utils/router'
import Title from '@src/pages/Forms/Candidate/InterviewProgress/components/Title'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Emails from '@src/pages/Forms/Candidate/Emails/Emails'
import { CommentsPage } from '@src/pages/Forms/Candidate/Comments/CommentsPage'
import {
  invalidateCandidateEmailThreads,
  useGetCandidateStats,
} from '@src/api/hiringProcess'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import Timeline from '@src/pages/Forms/Candidate/Timeline/Timeline'
import Forms from '@src/pages/Forms/Candidate/Forms/Forms'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import Documents from '@src/pages/Forms/Candidate/Documents/Documents'
import AnonymizedCandidate from './AnonymizedCandidate/AnonymizedCandidate'
import { useGetCandidateSettings } from '@src/api/settings'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import CandidateSubtitle from '@src/pages/Forms/Candidate/CandidateSubtitle'
import { useShowCandidateSwitcher } from '@src/pages/Forms/Candidate/utils'
import { CandidateActions } from '@src/pages/Forms/Candidate/CandidateActions/CandidateActions'
import {
  CandidateProfileContextProvider,
  useCandidateProfileContext,
} from '@src/pages/Forms/Candidate/CandidateProfileContext'
import PersonalDataSidebar from '@src/pages/Forms/Candidate/PersonalDataSidebar/PersonalDataSidebar'
import CandidateInterviewDetailsSidebar from '@src/pages/Forms/Candidate/CandidateInterviewDetailsSidebar/CandidateInterviewDetailsSidebar'
import { CandidateCvSidebar } from '@src/pages/Forms/Candidate/CandidateCVSidebar'
import { CandidateEmailSendProfileSidebar } from '@src/pages/Forms/Candidate/CandidateProfileSendEmailSidebar/CandidateEmailSendProfileSidebar'
import { CandidateSchedulingInterview } from '@src/pages/Forms/Candidate/CandidateSchedulingInterview/CandidateSchedulingInterview'
import { CandidateArchive } from '@src/pages/Forms/Candidate/CandidateArchive/CandidateArchive'
import { useQueryClient } from 'react-query'
import { LOCAL_STORAGE } from '@src/constants/api'
import { SwitcherButtonsWithSidebar } from '@components/SwitcherButtonsWIthSidebar/SwitcherButtonsWithSidebar'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { ScheduleInterviewProvider } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { MainLayout } from '@src/features/MainLayout/MainLayout'
import { Performance } from '@src/pages/Forms/Candidate/Performance/Performance'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { getInitials } from '@src/utils/employees'
import { CRMReview } from '@src/pages/Forms/Candidate/CRMReview/CRMReview'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import CopyClipboardButton from '@components/CopyClipboardButton/CopyClipboardButton'
import { AxiosError } from 'axios'
import { PageNoAccessWidget } from '@src/features/Errors/components/Page/PageNoAccessWidget'

const CandidateProfileRoot = () => {
  const location = useLocation()
  const [candidate, setCandidate] = useState<CandidateInterface>()
  const [error, setError] = useState<AxiosError | null>(null)
  const [round, setRound] = useState<InterviewRoundInterface>()
  const [recruiterDetails, setRecruiterDetails] = useState<EmployeeOptionInterface>()

  const [loading, setLoading] = useState(true)
  const params = useParams<{ id: string }>()
  const { data: stats, refetch: refreshStats } = useGetCandidateStats(candidate?.id)
  const { data: candidateSettings } = useGetCandidateSettings()
  useSetDocumentTitle(candidate?.full_name, 'Recruitment')
  const queryClent = useQueryClient()
  const candidateContext = useCandidateProfileContext()
  const { setActiveAction } = candidateContext

  const showCandidateSwitcher = useShowCandidateSwitcher()

  const featureFlags = useSelector(selectFeatureFlags)
  const canViewCandidatePerformanceProfile = featureFlags.includes(
    FeatureFlags.CandidatePerformanceProfile,
  )

  const fetchData = async (force?: boolean) => {
    setLoading(true)

    if (force) {
      setCandidate(undefined)
      setRound(undefined)
    }

    try {
      let candidateResult: CandidateInterface | undefined
      try {
        candidateResult = (await getCandidate(params.id)).data
        setCandidate(candidateResult)
      } catch (e) {
        setError(e)

        if (e.response?.status === 403) {
          const recruiterResponse = await getRecruiterDetails(+params.id)
          setRecruiterDetails(recruiterResponse.data.recruiter)
        }
      }

      if (candidateResult && candidateResult.active_interview_round) {
        await fetchRound(candidateResult.active_interview_round.id)
      }
      setActiveAction(currActiveAction => {
        // if the scheduling sidebar is already open - reset the mode
        if (currActiveAction?.type === 'schedule') {
          return {
            type: 'schedule',
            mode: 'scheduling',
          }
        }
        return currActiveAction
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchRound = async (roundId: number) => {
    setLoading(true)

    try {
      const roundResult = await getInterviewRound(roundId)
      setRound(roundResult.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(true)
  }, [params.id])

  const previewStageMode = Boolean(
    candidate?.active_interview_round &&
      round &&
      candidate?.active_interview_round.id !== round.id,
  )
  const canAddRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.AddInterviewRound)
  const canEditRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.ChangeInterviewRound)
  const canViewRound = !!round?.field_options?.permissions?.includes(
    PermissionTypes.ViewInterviewRound,
  )

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.CANDIDATE.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, params),
      icon: <Icon name="Profile" size={15} />,
      component: (
        <Summary
          candidate={candidate}
          previewStageMode={previewStageMode}
          round={round}
          refresh={fetchData}
          isLoading={loading}
          refreshStats={refreshStats}
          canEditRound={canEditRound}
          fetchRound={fetchRound}
        />
      ),
      canView: true,
    },
    {
      title: 'Performance',
      path: ROUTES.FORMS.CANDIDATE.PERFORMANCE,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.PERFORMANCE, params),
      icon: <Icon name="Wealth" size={15} />,
      component: <Performance round={round} candidate={candidate} />,
      canView: canViewRound && canViewCandidatePerformanceProfile,
    },
    {
      title: 'Documents',
      path: ROUTES.FORMS.CANDIDATE.DOCUMENTS,
      icon: <Icon name="Document" size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.DOCUMENTS, params),
      component: <Documents refreshStats={refreshStats} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.file_count} />,
    },
    {
      title: 'Forms',
      path: ROUTES.FORMS.CANDIDATE.FORMS,
      icon: <Icon name="Form" size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.FORMS, params),
      component: <Forms />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.form_count} />,
    },
    {
      title: 'Emails',
      path: ROUTES.FORMS.CANDIDATE.EMAILS,
      icon: <Icon name="Envelope" size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, params),
      component: <Emails candidateId={candidate?.id} />,
      canView: !!candidateSettings?.enable_emails,
      quickSummary: <QuickSummaryCount count={stats?.thread_count} />,
    },
    {
      title: 'Comments',
      path: ROUTES.FORMS.CANDIDATE.COMMENTS,
      icon: <Icon name="Chat" size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params),
      component: <CommentsPage roundId={round?.id} refreshStats={refreshStats} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.comment_count} />,
    },
    {
      title: 'Timeline',
      path: ROUTES.FORMS.CANDIDATE.TIMELINE,
      icon: <Icon name="16/Timeline" size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.TIMELINE, params),
      component: <Timeline roundId={round?.id} candidate={candidate!} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.timeline_event_count} />,
    },
  ].filter(tab => tab.canView)

  if (candidate?.is_anonymised) {
    return <AnonymizedCandidate />
  }

  if (error?.response?.status === 403) {
    const userIsAccessingTheirOwnCandidateProfile =
      error?.response?.data?.detail === 'You cannot access your own candidate profile.'
    return (
      <PageNoAccessWidget
        actions={
          !userIsAccessingTheirOwnCandidateProfile ? (
            <CopyClipboardButton
              text={pathToFullUrlWithActiveWorkspace(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                id: params.id,
              })}
              label="Copy the candidate link"
            />
          ) : null
        }
        description={
          recruiterDetails && !userIsAccessingTheirOwnCandidateProfile ? (
            <>
              To upgrade your permission level please{' '}
              <UIKitLink
                use={Link}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: recruiterDetails.id })}
              >
                contact the recruiter
              </UIKitLink>
            </>
          ) : undefined
        }
        title={error?.response?.data?.detail || "You don't have access to this candidate"}
      />
    )
  }

  return (
    <FormErrorGuard error={error} notFoundMessage="The candidate does not exist">
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}
              title={
                <Title
                  name={candidate?.full_name}
                  state={round?.state}
                  isConfidential={candidate?.is_confidential}
                  loading={loading}
                />
              }
              labels={
                <CandidateSubtitle
                  candidate={candidate}
                  round={round}
                  loading={loading}
                />
              }
              side={
                showCandidateSwitcher && !loading ? (
                  <SwitcherButtonsWithSidebar
                    currentId={candidate?.id}
                    localStorageKey={LOCAL_STORAGE.CANDIDATES_TABLE_CONTEXT}
                    route={ROUTES.FORMS.CANDIDATE.SUMMARY}
                    useLayout
                    title="Candidates"
                  />
                ) : undefined
              }
              actions={
                !previewStageMode && (
                  <CandidateActions
                    candidate={candidate}
                    canEditRound={canEditRound}
                    round={round}
                    refresh={fetchData}
                    loading={loading}
                  />
                )
              }
              avatar={
                loading ? (
                  <AvatarSkeleton size={56} />
                ) : candidate ? (
                  <Avatar size={56} label={getInitials(candidate?.full_name)} />
                ) : undefined
              }
            />
          }
          backUrl={ROUTES.RECRUITMENT.CANDIDATES}
          backUrlLocationState={location}
          renderAboveTitle={CRMReview}
        >
          <Box pb="s-32">
            <TabBarNavigation tabs={tabs} />
          </Box>
        </PageHeader>
        <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
          <Box maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}>
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  {tab.component}
                </Route>
              ))}
            </Switch>

            <PersonalDataSidebar onRefresh={fetchData} />
            <CandidateInterviewDetailsSidebar
              round={round}
              refresh={fetchData}
              canAddRound={canAddRound}
              canEditRound={canEditRound}
            />
            <CandidateCvSidebar candidateId={candidate?.id} />
            <CandidateEmailSendProfileSidebar
              candidateId={candidate?.id}
              onRefetch={() => {
                invalidateCandidateEmailThreads(queryClent, candidate?.id)
                refreshStats()
              }}
            />
            <CandidateSchedulingInterview
              candidate={candidate!}
              refreshStats={refreshStats}
              roundId={round?.id}
            />
            <CandidateArchive
              refresh={() => {
                fetchData()
                refreshStats()
              }}
              candidate={candidate}
            />
          </Box>
        </Flex>
      </PageWrapper>
    </FormErrorGuard>
  )
}

export const CandidateProfile = connect(() => (
  <ScheduleInterviewProvider>
    <MainLayout>
      <CandidateProfileContextProvider>
        <CandidateProfileRoot />
      </CandidateProfileContextProvider>
    </MainLayout>
  </ScheduleInterviewProvider>
))
