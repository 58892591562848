import React, { useMemo, useRef, useState } from 'react'

import {
  CheckboxSelect,
  HStack,
  Icon,
  Input,
  SelectInput,
  SelectOptionType,
  VStack,
  Spacer,
  Description,
  Paragraph,
} from '@revolut/ui-kit'
import { searchMonday } from '@src/api/roadmaps'
import { debounce } from 'lodash'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { EpicTaskOption } from '@src/interfaces/kpis'
import { MondaySearchResult } from '@src/interfaces/roadmaps'
import { MondaySelectOptions } from './MondaySelectOptions'
import { MondaySelectedTaskWidget } from './MondaySelectedTaskWidget'
import { useMondayBoards } from '@src/api/integrations'
import { MondayBoard } from '@src/interfaces/integrations'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { getMondayItemID } from '@src/pages/Hub/Integrations/Monday/helper'

interface Props {
  onSelectionChange: (epics: MondaySearchResult[]) => void
  onDelete: (key: string) => void
  addedTasks: EpicTaskOption[] | undefined
  excludedTasks?: { key: string }[]
  error?: string
}

const mapMondayOptions = (items: MondaySearchResult[], board_id: string) =>
  items.map(item => ({
    ...item,
    id: getMondayItemID(item, board_id),
  }))

export const MondayTasks = ({
  onSelectionChange,
  onDelete,
  addedTasks,
  excludedTasks,
  error,
}: Props) => {
  const inputRef = useRef(null)
  const [searchPending, setSearchPending] = useState(false)
  const [searchValue, setSearchValue] = useState<string>()
  const [options, setOptions] = useState<MondaySearchResult[]>([])
  const [errorMessage, setErrorMessage] = useState<string>()
  const [board, setBoard] = useState<MondayBoard | null>(null)

  const { isLoading, data: boardsData } = useMondayBoards({
    queryOptions: {
      enabled: !board,
      onSuccess: data => setBoard(data?.at?.(0) ?? null),
    },
  })

  const boardsOptions = (boardsData ?? []).map(b => ({
    key: b.id,
    label: b.name,
    value: b,
  }))

  const debouncedSearch = useMemo(() => {
    setErrorMessage(undefined)
    return debounce(async (searchString: string, board_id: string) => {
      try {
        const { data } = await searchMonday({
          searchQuery: searchString,
          board_id,
        })
        if (data) {
          setOptions(mapMondayOptions(data, board_id))
        }
      } catch (e) {
        setErrorMessage(getMessageFromApiError(e) || undefined)
      } finally {
        setSearchValue(searchString) // this just resets the search strings as on close this will be cleared (if you re-search)
        setSearchPending(false)
      }
    }, 1000)
  }, [])

  const onSearch = (searchString?: string) => {
    setErrorMessage(undefined)
    if (searchString === searchValue) {
      return
    }
    setOptions([])
    setSearchValue(searchString)
    if (searchString && board) {
      setSearchPending(true)
      debouncedSearch(searchString, board.id)
    }
  }

  return (
    <VStack space="s-16">
      <SelectInput
        pending={isLoading}
        invalid={!!errorMessage}
        errorMessage={errorMessage}
        label="Select your Monday.com board"
        onChange={setBoard}
        value={board}
        options={boardsOptions}
        renderOption={option => (
          <HStack space="s-12" align="center">
            <VStack>
              <Paragraph>{option.label}</Paragraph>
              <Description>{option.value.workspace}</Description>
            </VStack>
            <Spacer />
            <OpenInNewTab link={option.value.url} label="" />
          </HStack>
        )}
        renderAction={() => (board ? <OpenInNewTab link={board.url} label="" /> : null)}
      />

      {board ? (
        <>
          <Input
            invalid={!!errorMessage}
            errorMessage={errorMessage}
            containerRef={inputRef}
            placeholder="Search Monday.com items"
            pending={searchPending}
            onChange={event => onSearch(event.currentTarget.value)}
            renderAction={() => (searchPending ? undefined : <Icon name="Search" />)}
            value={searchValue}
          />
          <CheckboxSelect<MondaySearchResult, SelectOptionType<MondaySearchResult>>
            labelList="Tasks"
            value={[]}
            open={!!searchValue && !searchPending}
            anchorRef={inputRef}
            onClose={onSearch}
            options={options.map(option => ({
              value: option,
              label: option.name,
              key: option.id,
              disabled: !![...(excludedTasks || []), ...(addedTasks || [])].find(
                ({ key }) => key === option.id,
              ),
            }))}
            fitInAnchor
            labelNoResults="No tasks found"
            type="confirm"
            onChange={onSelectionChange}
          >
            {option => (
              <MondaySelectOptions
                key={option.key}
                label={option.label}
                url={option.value?.url}
                type={option.value?.type}
              />
            )}
          </CheckboxSelect>
        </>
      ) : null}

      <MondaySelectedTaskWidget
        tasks={addedTasks || []}
        error={error}
        onDelete={onDelete}
      />
    </VStack>
  )
}
