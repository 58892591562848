import React, { useState } from 'react'
import { ErrorWidget } from '@revolut/ui-kit'
import { captureError } from '@src/features/Errors/captureError'
import { BugReportSide } from '@src/components/HelpCenter/components/BugReportSide'

interface ErrorBoundaryProps {
  fallback?: React.ReactNode
  retryAction?: () => void
  buttonLabel?: string
  onReportIssue?: VoidFunction
}

export const DefaultFallBack = ({
  retryAction,
  buttonLabel,
  onReportIssue,
}: Omit<ErrorBoundaryProps, 'fallback'>) => {
  const [reportSidebarOpen, setReportSidebarOpen] = useState(false)

  return (
    <>
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D018.png" />
        <ErrorWidget.Title />
        <ErrorWidget.Description>
          Please try again, or report the issue
        </ErrorWidget.Description>
        <ErrorWidget.Action
          onClick={() => {
            if (retryAction) {
              retryAction()
            } else {
              window.location?.reload?.()
            }
          }}
        >
          {buttonLabel}
        </ErrorWidget.Action>
        <ErrorWidget.Action
          onClick={() => {
            if (onReportIssue) {
              onReportIssue()
            } else {
              setReportSidebarOpen(true)
            }
          }}
        >
          Report the issue
        </ErrorWidget.Action>
      </ErrorWidget>

      <BugReportSide
        open={reportSidebarOpen}
        onClose={() => setReportSidebarOpen(false)}
        source="errorBoundary"
      />
    </>
  )
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    captureError(error, { tags: { component: 'ErrorBoundry' } })
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <DefaultFallBack
            retryAction={this.props.retryAction}
            buttonLabel={this.props.buttonLabel}
            onReportIssue={this.props.onReportIssue}
          />
        )
      )
    }

    return this.props.children
  }
}
