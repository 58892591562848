import React from 'react'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import { noop } from 'lodash'
import {
  Box,
  chain,
  DataPoint,
  Flex,
  Highlights,
  Subheader,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'
import { SummarySection } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/SummarySection'
import Loader from '@components/CommonSC/Loader'
import { useMeetingsSummary } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/MeetingsSummary/hooks/useMeetingsSummary'

interface Props {
  summary: MeetingsSummaryInterface
  managerId: number
}

export const MeetingsSummary = ({ summary, managerId }: Props) => {
  const { isActionItemsEnabled, isPerformanceRatingEnabled, gradeProps, isLoading } =
    useMeetingsSummary(summary, managerId)

  if (isLoading) {
    return (
      <Widget height={400}>
        <Loader />
      </Widget>
    )
  }

  return (
    <Widget p="s-16" data-testid="meetings_summary_details">
      <VStack space="s-8">
        <Text variant="heading3" fontWeight={500}>
          {chain(
            <Text>{summary.cycle.name}</Text>,
            <FormattedMessage
              id="one-to-one.meetings.summary.header.title"
              defaultMessage="1:1 summary"
            />,
          )}
        </Text>
        <Text variant="body2" color={Token.color.greyTone50}>
          <FormattedMessage
            id="one-to-one.meetings.summary.header.description"
            defaultMessage="Updated daily until cycle ends"
          />
        </Text>
        <Highlights>
          {isPerformanceRatingEnabled && gradeProps.label && (
            <Widget flex={1} borderRadius={Token.radius.r16} p="s-24">
              <DataPoint>
                <VStack align="center">
                  <DataPoint.Label>
                    <FormattedMessage
                      id="one-to-one.meetings.summary.stats.avg.score.label"
                      defaultMessage="Average score"
                    />
                  </DataPoint.Label>
                  <DataPoint.Value color={gradeProps.color}>
                    {gradeProps.label}
                  </DataPoint.Value>
                  <Text variant="body2" color={Token.color.greyTone50} textAlign="center">
                    <FormattedPlural
                      value={summary.performance_grade_count}
                      one={
                        <FormattedMessage
                          id="one-to-one.meetings.summary.stats.avg.score.one.description"
                          values={{ count: summary.performance_grade_count }}
                          defaultMessage="based on {count} review"
                        />
                      }
                      other={
                        <FormattedMessage
                          id="one-to-one.meetings.summary.stats.avg.score.other.description"
                          values={{ count: summary.performance_grade_count }}
                          defaultMessage="based on {count} reviews"
                        />
                      }
                    />
                  </Text>
                </VStack>
              </DataPoint>
            </Widget>
          )}
          <Widget flex={1} borderRadius={Token.radius.r16} p="s-24">
            <DataPoint>
              <VStack align="center">
                <DataPoint.Label>
                  <FormattedMessage
                    id="one-to-one.meetings.summary.stats.meetings.count.label"
                    defaultMessage="Meetings"
                  />
                </DataPoint.Label>
                <DataPoint.Value>{summary.meetings_count}</DataPoint.Value>
                <Text variant="body2" color={Token.color.greyTone50} textAlign="center">
                  <FormattedMessage
                    id="one-to-one.meetings.summary.stats.meetings.count.description"
                    defaultMessage="this cycle"
                  />
                </Text>
              </VStack>
            </DataPoint>
          </Widget>
          {isActionItemsEnabled && (
            <Widget flex={1} borderRadius={Token.radius.r16} p="s-24">
              <DataPoint>
                <VStack align="center">
                  <DataPoint.Label>
                    <FormattedMessage
                      id="one-to-one.meetings.summary.stats.actions.count.label"
                      defaultMessage="Actions"
                    />
                  </DataPoint.Label>
                  <DataPoint.Value>{summary.actions_count}</DataPoint.Value>
                  <Text variant="body2" color={Token.color.greyTone50} textAlign="center">
                    <FormattedMessage
                      id="one-to-one.meetings.summary.stats.actions.count.description"
                      defaultMessage="recorded"
                    />
                  </Text>
                </VStack>
              </DataPoint>
            </Widget>
          )}
        </Highlights>
      </VStack>
      <Subheader>
        <Subheader.Title>
          <Text variant="emphasis1" color={Token.color.foreground}>
            <FormattedMessage
              id="one-to-one.meetings.summary.notes.title"
              defaultMessage="Notes"
            />
          </Text>
        </Subheader.Title>
      </Subheader>
      <VStack space="s-16">
        {summary.summary && (
          <SummarySection
            id={0}
            summary={summary.summary}
            title={
              <FormattedMessage id="common.summary.title" defaultMessage="Summary" />
            }
            isLoading={false}
            isCopyDisabled={false}
            isEnabledAI
            disabled
            onSummaryChange={noop}
          />
        )}
        <Flex gap="s-16">
          {summary.strengths && (
            <Box flex={1}>
              <SummarySection
                id={1}
                summary={summary.strengths}
                title={
                  <FormattedMessage
                    id="one-to-one.meetings.summary.stengths.title"
                    defaultMessage="Strengths"
                  />
                }
                isLoading={false}
                isCopyDisabled={false}
                isEnabledAI
                disabled
                onSummaryChange={noop}
              />
            </Box>
          )}
          {summary.area_for_development && (
            <Box flex={1}>
              <SummarySection
                id={2}
                summary={summary.area_for_development}
                title={
                  <FormattedMessage
                    id="one-to-one.meetings.summary.development.areas.title"
                    defaultMessage="Areas for development"
                  />
                }
                isLoading={false}
                isCopyDisabled={false}
                isEnabledAI
                disabled
                onSummaryChange={noop}
              />
            </Box>
          )}
        </Flex>
      </VStack>
    </Widget>
  )
}
