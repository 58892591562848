import React from 'react'
import { FormattedMessage } from 'react-intl'
import { List, Text, Token } from '@revolut/ui-kit'
import { toMarkdown } from '@src/features/AiInsights/Performance/components/Summary/formatters'

export type SummaryVariant = 'default' | 'list'

export interface SummaryProps {
  summary: string
  variant?: SummaryVariant
}

export const SummaryFormatted = ({ summary, variant = 'default' }: SummaryProps) => {
  if (variant === 'list') {
    return <ListFormatted summary={summary} />
  }

  return <LineFormatted summary={summary} />
}

const ListFormatted = ({ summary }: Pick<SummaryProps, 'summary'>) => (
  <List variant="compact" pl="s-8" color={Token.color.greyTone50}>
    {summary.split('\n').map((line, idx) => (
      <List.Item key={idx} useIcon="Dot">
        <FormattedMessage
          id="performance.ai.insights.summary"
          values={{
            b: chunks => (
              <Text fontWeight={700} color={Token.color.foreground}>
                {chunks}
              </Text>
            ),
          }}
          defaultMessage={toMarkdown(line)}
        />
      </List.Item>
    ))}
  </List>
)

const LineFormatted = ({ summary }: Pick<SummaryProps, 'summary'>) => (
  <Text fontSize={14} color={Token.color.greyTone50}>
    <FormattedMessage
      id="performance.ai.insights.summary"
      values={{
        b: chunks => (
          <Text fontWeight={700} color={Token.color.foreground}>
            {chunks}
          </Text>
        ),
      }}
      defaultMessage={toMarkdown(summary)}
    />
  </Text>
)
