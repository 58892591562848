import React, { PropsWithChildren } from 'react'
import { InputGroup } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { getPipSmartGoalsRequests } from '@src/api/pip'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewDatePicker from '@src/components/Inputs/LapeFields/LapeNewDatePicker'
import { PerformanceSelector } from '@src/interfaces/performance'

const FormBody = () => (
  <InputGroup>
    <LapeNewInput name="name" label="SMART goal title" required />
    <LapeNewInput name="description" label="How will this goal be measured" required />
    <LapeNewDatePicker name="deadline_date_time" label="Due date" required />
  </InputGroup>
)

interface PipSmartGoalEditFormProps {
  goal: DeliverableInterface
  employeeId: string | number
}

export const PipSmartGoalEditForm = ({
  goal,
  employeeId,
  children: actions,
}: PropsWithChildren<PipSmartGoalEditFormProps>) => {
  const pipSmartGoalsRequests = getPipSmartGoalsRequests(employeeId)

  return (
    <Form api={pipSmartGoalsRequests} forceParams={{ id: String(goal.id) }}>
      <FormBody />
      {actions}
    </Form>
  )
}

interface PipSmartGoalAddFormProps {
  employeeId: string | number
  pipCycle: PerformanceSelector
}

export const PipSmartGoalAddForm = ({
  employeeId,
  pipCycle,
  children: actions,
}: PropsWithChildren<PipSmartGoalAddFormProps>) => {
  const pipSmartGoalsRequests = getPipSmartGoalsRequests(employeeId)

  return (
    <Form
      api={pipSmartGoalsRequests}
      isExistingForm={false}
      initialValues={
        {
          employee: { id: employeeId },
          review_cycle: { id: pipCycle.id },
          category: 'PIP',
        } as Partial<DeliverableInterface>
      }
    >
      <FormBody />
      {actions}
    </Form>
  )
}
