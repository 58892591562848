import { Caption, Link, TextSkeleton } from '@revolut/ui-kit'
import { useGetBookingLinkCompany } from '@src/api/bookingLinks'
import React from 'react'

export const DataPrivacyNoticeForCandidates = () => {
  const { data: company, isLoading } = useGetBookingLinkCompany()

  if (isLoading) {
    return <TextSkeleton />
  }

  if (!company || !company.data_privacy_notice_url) {
    return null
  }

  return (
    <Caption>
      By submitting this application, I agree that my personal data will be processed in
      accordance with {company.company_name ? `${company.company_name}'s ` : ''}
      <Link
        use="a"
        rel="noreferrer noopener"
        target="_blank"
        href={company.data_privacy_notice_url}
      >
        Data Privacy Notice for Candidates
      </Link>
    </Caption>
  )
}
