import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'
import Notion from '@src/assets/temporary/Notion.png'
import Monday from '@src/assets/temporary/Monday.png'
import { getCDNAssetUrl, getRevolutersCDNAssetUrl } from '@src/utils/getCDNLink'

type GoalsSettingsItemId =
  | 'goalsMetricTypes'
  | 'goalsRoadmaps'
  | 'goalsApprovalProcess'
  | 'goalsJiraIntegration'
  | 'goalsTableauIntegration'
  | 'goalsLookerIntergration'
  | 'goalsClickUpIntergration'
  | 'goalsSQLIntergration'
  | 'goalsSalesforceIntegration'
  | 'goalsLinearIntegration'
  | 'goalsNotionIntegration'
  | 'goalsMondayIntegration'

export const goalsSettingsLinkConfig: SettingsLinkItemConfig<GoalsSettingsItemId>[] = [
  {
    id: 'goalsMetricTypes',
    section: 'Goals settings',
    icon: 'Switches',
    title: 'Metric types',
    searchTitle: 'Goals metric types',
    to: ROUTES.SETTINGS.GOALS.TARGET_TYPES,
    permissions: [PermissionTypes.ViewPerformancePreferences],
    bundles: [],
  },
  {
    id: 'goalsRoadmaps',
    section: 'Goals settings',
    icon: 'DataLimit',
    title: 'Roadmaps',
    searchTitle: 'Goals roadmaps',
    to: ROUTES.SETTINGS.GOALS.ROADMAPS,
    permissions: [PermissionTypes.ViewPerformancePreferences],
    bundles: [],
  },
  {
    id: 'goalsApprovalProcess',
    section: 'Goals settings',
    icon: 'CheckVerification',
    title: 'Approval processes',
    searchTitle: 'Goals approval processes',
    subtitle: 'Create and manage custom approval processes for your business',
    to: ROUTES.SETTINGS.GOALS.APPROVAL_PROCESS,
    permissions: [PermissionTypes.ViewPerformancePreferences],
    bundles: [],
  },
  {
    id: 'goalsJiraIntegration',
    section: 'Goals settings',
    icon: 'Jira|image',
    title: 'Jira',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.JIRA,
    subtitle: 'Enable to import data from your Jira workspace',
    permissions: [PermissionTypes.ViewJiraIntegration],
    bundles: [],
  },
  {
    id: 'goalsTableauIntegration',
    section: 'Goals settings',
    image: getCDNAssetUrl('apps/Tableau.webp'),
    title: 'Tableau',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.TABLEAU,
    subtitle: 'Enable to import data from your Tableau workspace',
    permissions: [PermissionTypes.ViewTableauIntegration],
    bundles: [],
  },
  {
    id: 'goalsLookerIntergration',
    section: 'Goals settings',
    image: getCDNAssetUrl('apps/Looker.webp'),
    title: 'Looker',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.LOOKER,
    subtitle: 'Enable to import data from your Looker workspace',
    permissions: [PermissionTypes.ViewLookerIntegration],
    bundles: [],
  },
  {
    id: 'goalsClickUpIntergration',
    section: 'Goals settings',
    image: getCDNAssetUrl('apps/ClickUp.webp'),
    title: 'ClickUp',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.CLICKUP,
    subtitle: 'Enable to import data from your ClickUp workspace',
    permissions: [PermissionTypes.ViewClickupIntegration],
    bundles: [],
  },
  {
    id: 'goalsSQLIntergration',
    section: 'Goals settings',
    icon: 'Database',
    title: 'SQL',
    to: ROUTES.SETTINGS.ANALYTICS,
    subtitle: 'Enable to import data from SQL Databases',
    permissions: [PermissionTypes.ChangeConnection],
    bundles: [],
  },
  {
    id: 'goalsSalesforceIntegration',
    section: 'Goals settings',
    image: getCDNAssetUrl('apps/Salesforce.webp'),
    title: 'Salesforce',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.SALESFORCE,
    subtitle: 'Enable to import data from Salesforce workspace',
    permissions: [PermissionTypes.ViewSalesforceIntegration],
    bundles: [],
  },
  {
    id: 'goalsLinearIntegration',
    section: 'Goals settings',
    image: getRevolutersCDNAssetUrl('icons/Linear.png'),
    title: 'Linear',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.LINEAR,
    subtitle: 'Enable to import data from Linear workspace',
    permissions: [PermissionTypes.ViewLinearIntegration],
    bundles: [],
  },
  {
    id: 'goalsNotionIntegration',
    section: 'Goals settings',
    image: Notion,
    title: 'Notion',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.NOTION,
    subtitle: 'Enable to import data from Notion workspace',
    permissions: [PermissionTypes.ViewNotionIntegration],
    bundles: [],
  },
  {
    id: 'goalsMondayIntegration',
    section: 'Goals settings',
    image: Monday,
    title: 'Monday.com',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.MONDAY,
    subtitle: 'Enable to import data from Monday workspace',
    permissions: [PermissionTypes.ChangeMondayIntegration],
    bundles: [],
  },
]
