import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { KPIRuleInterface } from '@src/interfaces/KpiTemplates'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useRefreshRuleTemplate } from '@src/api/kpiRules'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

type HandleError = Parameters<typeof useRefreshRuleTemplate>[0]['handleError']

export const RefreshButton = () => {
  const { values } = useLapeContext<KPIRuleInterface>()
  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  const handleSuccess = () => {
    showStatusPopup({ title: 'Refresh in progress' })
  }

  const handleError: HandleError = error => {
    errorPopup.show({
      fallbackTitle: 'Something went wrong during refresh',
      error,
    })
  }

  const { mutateAsync: refresh } = useRefreshRuleTemplate({
    ruleId: values.id,
    handleSuccess,
    handleError,
  })

  const canRefresh = values.field_options?.permissions?.includes(
    PermissionTypes.CanChangeKpiTemplateRule,
  )

  if (!canRefresh) {
    return null
  }

  return (
    <MoreBar.Action useIcon="Retry" onClick={() => refresh(null)}>
      Refresh
    </MoreBar.Action>
  )
}
