import React, { PropsWithChildren } from 'react'
import { Box, Subheader, Token } from '@revolut/ui-kit'
import { InterviewerProfile } from '@src/features/InterviewerProfile/InterviewerProfile'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'
import Tooltip from '@src/components/Tooltip/Tooltip'

interface Props {
  interviewerId: number
}

export const InterviewerProfileTooltip = ({
  interviewerId,
  children,
}: PropsWithChildren<Props>) => {
  const hasAIInterviewSummarisationInternal = useHasFeatureFlag(
    FeatureFlags.AIInterviewSummarisationInternal,
  )

  // TODO: either remove or bring back: https://revolut.atlassian.net/browse/PERF-6786
  const temporaryRemove = true

  if (temporaryRemove || !hasAIInterviewSummarisationInternal) {
    return <Box width="100%">{children}</Box>
  }

  return (
    <Tooltip
      placement="bottom"
      backgroundColor={Token.color.groupedBackground}
      noArrow
      body={
        <Box
          minWidth={Token.breakpoint.xs}
          p="s-16"
          radius={Token.radius.r12}
          elevation="level4"
        >
          <Subheader>
            <Subheader.Title>Interviewer profile</Subheader.Title>
          </Subheader>
          <Subheader variant="nested">
            <Subheader.Title>Based on interviewer track record</Subheader.Title>
          </Subheader>
          <InterviewerProfile interviewerId={interviewerId} />
        </Box>
      }
    >
      <Box width="100%">{children}</Box>
    </Tooltip>
  )
}
