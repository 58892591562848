import React from 'react'
import { Flex, Avatar, Text, useTooltip, chain, Tooltip, VStack } from '@revolut/ui-kit'
import { getBarRaiserGradeColor, KeeperGradeToString } from '@src/interfaces/performance'
import { RecommendationQuestionReviewInterface } from './BarRaiserSummary'
import { getAvatarUrl, getInitials } from '@src/utils/employees'

interface PeerReviewItemProps {
  reviews?: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
  isNewDesign?: boolean
}

export const PeerReviewsWithTooltip: React.FC<PeerReviewItemProps> = ({
  reviews,
  questionIdx,
  isNewDesign,
}) => {
  const tooltip = useTooltip()

  if (!reviews?.length) {
    return <Text variant="secondary">-</Text>
  }

  return (
    <Text variant="caption" textDecoration="underline" {...tooltip.getAnchorProps()}>
      {chain('View', reviews.length)}
      <Tooltip {...tooltip.getTargetProps()} placement="top">
        <VStack>
          {reviews.map(review => {
            const questionValue =
              review.recommendation.keeper_test_section?.questions[questionIdx].value
            const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
            if (isNewDesign) {
              return (
                <Flex
                  key={review.recommendation?.reviewer?.id}
                  alignItems="center"
                  gap="s-8"
                >
                  <Avatar
                    image={getAvatarUrl(review.recommendation?.reviewer?.avatar)}
                    size={32}
                    uuid={review.recommendation?.reviewer?.full_name}
                    label={
                      review.recommendation?.reviewer?.full_name &&
                      getInitials(review.recommendation.reviewer.full_name)
                    }
                  />
                  <Text variant="emphasis2" color={getBarRaiserGradeColor(questionValue)}>
                    {gradeValue}
                  </Text>
                </Flex>
              )
            }
            return (
              <Flex key={review.recommendation.reviewer?.id}>
                {chain(
                  <Text variant="small" fontWeight="bold">
                    {gradeValue}
                  </Text>,
                  <Text variant="small">
                    {review.recommendation.reviewer?.full_name}
                  </Text>,
                )}
              </Flex>
            )
          })}
        </VStack>
      </Tooltip>
    </Text>
  )
}
