import React from 'react'
import { Avatar, Banner, Token, VStack, Text } from '@revolut/ui-kit'
import { formatNumber } from '@src/utils/format'
import { isNumber } from 'lodash'

interface Props {
  totalWeight?: number | false
  skillWithNoWeight?: boolean
}

export const WeightsErrorBanner = ({ totalWeight, skillWithNoWeight }: Props) => {
  return (isNumber(totalWeight) && totalWeight !== 100) || skillWithNoWeight ? (
    <Banner>
      <Banner.Avatar>
        <Avatar useIcon="16/Warning" color={Token.color.warning} />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Description>
          <VStack gap="s-8">
            {isNumber(totalWeight) && totalWeight !== 100 && (
              <Text>
                The sum of all weights should be 100%, currently{' '}
                <strong>{formatNumber(totalWeight)}%</strong>
              </Text>
            )}
            {skillWithNoWeight && <Text>All skills need to have weight assigned</Text>}
          </VStack>
        </Banner.Description>
      </Banner.Content>
    </Banner>
  ) : null
}
