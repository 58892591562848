import React, { useState } from 'react'
import { Text, Action, Token } from '@revolut/ui-kit'

export interface ExpandableTextProps {
  length?: number
  children?: React.ReactNode
}

export const ExpandableText = ({ children, length = 180 }: ExpandableTextProps) => {
  const [isShownMore, setIsShownMore] = useState(false)

  const text = String(children)
  const isTextLong = text.length > length

  return (
    <>
      <Text>{isTextLong && !isShownMore ? `${text.slice(0, length)}...` : text}</Text>

      {isTextLong && (
        <Action
          color={Token.color.foreground}
          onClick={() => setIsShownMore(prev => !prev)}
        >
          {isShownMore ? 'Show less' : 'Show more'}
        </Action>
      )}
    </>
  )
}
