import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EmployeeButtonProps } from '../types'
import { navigateTo } from '@src/actions/RouterActions'

export const AddFeedback = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const onClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME, {
        userId: data.id,
      }),
    )
  }

  const icon = <Icon name="Chat" size={16} />

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" useIcon={icon} onClick={onClick}>
        Add feedback
      </Dropdown.Item>
    )
  }

  return (
    <MoreBar.Action onClick={onClick} useIcon={icon}>
      Add feedback
    </MoreBar.Action>
  )
}
