import { api, apiWithoutHandling, apiV2 } from '@src/api/index'
import { API, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import { getTenantSubdomain } from '@src/utils'

export type OfficeSuiteProviderSourceType =
  | 'linked_accounts'
  | 'candidate_settings'
  | 'employee_onboarding_settings'
export type OfficeSuiteProviderType =
  | 'googleCalendar'
  | 'googleEmail'
  | 'googleWorkspace'
  | 'slackWorkspace'
  | 'slackIntegration'
  | 'microsoftCalendar'
  | 'microsoftEmail'
  | 'jiraWorkspace'
  | 'lookerWorkspace'
  | 'clickupWorkspace'
  | 'salesforceWorkspace'
  | 'linearWorkspace'
  | 'docusign'
  | 'monday'

export interface ConsentStateInterface {
  subdomain?: string
  source?: OfficeSuiteProviderSourceType
  type?: OfficeSuiteProviderType
}

export const connectGoogleEmail = (
  code: string,
  redirectUrl: string,
  source?: OfficeSuiteProviderSourceType,
) =>
  api.post(API.CONNECT_GOOGLE_EMAIL, {
    code,
    source,
    redirect_url: redirectUrl,
  })

export const connectGoogleCalendar = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_GOOGLE_CALENDAR, {
    code,
    redirect_url: redirectUrl,
  })

export const connectGoogleWorkspace = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_GOOGLE_WORKSPACE, {
    code,
    redirect_url: redirectUrl,
  })

export const connectJiraWorkspace = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_JIRA, {
    code,
    redirect_url: redirectUrl,
  })

export const connectClickupWorkspace = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_CLICKUP, {
    code,
    redirect_url: redirectUrl,
  })

export const connectDocusign = (code: string, redirectUrl: string) =>
  api.post(`${API.DOCUSIGN_INTEGRATIONS}/connect`, {
    code,
    redirect_url: redirectUrl,
  })

export const connectLinearWorkspace = (code: string, redirectUrl: string) =>
  apiV2.post(API.CONNECT_LINEAR, {
    code,
    redirect_url: redirectUrl,
  })

export const connectSalesforceWorkspace = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_SALESFORCE, {
    code,
    redirect_url: redirectUrl,
  })

export const connectTableau = () => apiWithoutHandling.post(API.CONNECT_TABLEAU)

export const connectLookerWorkspace = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_LOOKER, { code, redirect_url: redirectUrl })

export const connectSlackWorkspace = (code: string, redirectUrl: string) =>
  apiV2.post(API.CONNECT_SLACK, {
    code,
    redirect_url: redirectUrl,
  })

export const connectSlackWorkspaceV2 = (code: string, redirectUrl: string) =>
  apiV2.post(
    API.CONNECT_SLACK,
    {
      code,
      redirect_url: redirectUrl,
    },
    undefined,
    'v2',
  )

export const connectMondayWorkspace = (code: string, redirectUrl: string) =>
  apiV2.post(
    API.CONNECT_MONDAY,
    {
      code,
      redirect_url: redirectUrl,
    },
    undefined,
    'v1',
  )

export const askGoogleEmailPermissions = async (
  source: OfficeSuiteProviderSourceType,
) => {
  let state: ConsentStateInterface = {
    source,
    type: 'googleEmail',
  }

  state.subdomain = getTenantSubdomain('askGoogleEmailPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(API.CONNECT_GOOGLE_EMAIL, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askGoogleCalendarPermissions = async () => {
  let state: ConsentStateInterface = {
    type: 'googleCalendar',
  }

  state.subdomain = getTenantSubdomain('askGoogleCalendarPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(API.CONNECT_GOOGLE_CALENDAR, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const connectMicrosoftEmail = (
  code: string,
  redirectUrl: string,
  source?: OfficeSuiteProviderSourceType,
) =>
  api.post(API.CONNECT_MICROSOFT_EMAIL, {
    code,
    source,
    redirect_url: redirectUrl,
  })

export const connectMicrosoftCalendar = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_MICROSOFT_CALENDAR, {
    code,
    redirect_url: redirectUrl,
  })

export const askMicrosoftEmailPermissions = async (
  source: OfficeSuiteProviderSourceType,
) => {
  let state: ConsentStateInterface = {
    source,
    type: 'microsoftEmail',
  }

  state.subdomain = getTenantSubdomain('askMicrosoftEmailPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(API.CONNECT_MICROSOFT_EMAIL, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askMicrosoftCalendarPermissions = async () => {
  let state: ConsentStateInterface = {
    type: 'microsoftCalendar',
  }

  state.subdomain = getTenantSubdomain('askMicrosoftCalendarPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(
    API.CONNECT_MICROSOFT_CALENDAR,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askGoogleWorkspacePermissions = async () => {
  let state: ConsentStateInterface = { type: 'googleWorkspace' }

  state.subdomain = getTenantSubdomain('askGoogleWorkspacePermissions')

  const loginResponse = await api.get<{ auth_url: string }>(
    API.CONNECT_GOOGLE_WORKSPACE,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askSlackWorkspacePermissions = async (
  type: OfficeSuiteProviderType = 'slackWorkspace',
) => {
  let state: ConsentStateInterface = { type }

  state.subdomain = getTenantSubdomain('askSlackWorkspacePermissions')

  const loginResponse = await apiV2.get<{ auth_url: string }>(
    API.CONNECT_SLACK,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
    type === 'slackWorkspace' ? 'v1' : 'v2',
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askClickupWorkspacePermissions = async () => {
  const state: ConsentStateInterface = {
    type: 'clickupWorkspace',
    subdomain: getTenantSubdomain('askClickupWorkspacePermissions'),
  }

  const loginResponse = await apiWithoutHandling.get<{ auth_url: string }>(
    API.CONNECT_CLICKUP,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askSalesforceWorkspacePermissions = async () => {
  const state: ConsentStateInterface = {
    type: 'salesforceWorkspace',
    subdomain: getTenantSubdomain('askSalesforceWorkspacePermissions'),
  }

  const loginResponse = await apiWithoutHandling.get<{ auth_url: string }>(
    API.CONNECT_SALESFORCE,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askLinearWorkspacePermissions = async () => {
  const state: ConsentStateInterface = {
    type: 'linearWorkspace',
    subdomain: getTenantSubdomain('askLinearWorkspacePermissions'),
  }

  const loginResponse = await apiV2.get<{ auth_url: string }>(API.CONNECT_LINEAR, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askJiraWorkspacePermissions = async () => {
  const state: ConsentStateInterface = {
    type: 'jiraWorkspace',
    subdomain: getTenantSubdomain('askJiraWorkspacePermissions'),
  }

  const loginResponse = await apiWithoutHandling.get<{ auth_url: string }>(
    API.CONNECT_JIRA,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}
export const askLookerWorkspacePermissions = async () => {
  const state: ConsentStateInterface = {
    type: 'lookerWorkspace',
    subdomain: getTenantSubdomain('askLookerWorkspacePermissions'),
  }

  const loginResponse = await apiWithoutHandling.get<{ auth_url: string }>(
    API.CONNECT_LOOKER,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askMondayWorkspacePermissions = async () => {
  const state: ConsentStateInterface = {
    type: 'monday',
    subdomain: getTenantSubdomain('askMondayWorkspacePermissions'),
  }

  const loginResponse = await apiV2.get<{ auth_url: string }>(API.CONNECT_MONDAY, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}
