import React from 'react'
import {
  Absolute,
  Box,
  Group,
  HStack,
  Icon,
  IconName,
  Item,
  Relative,
  Token,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { UploadAvatarPreview } from '@src/components/AvatarUploadPopup/components/UploadAvatarPreview'

export interface StructureLevelItem {
  id: number | string
  url: string
  icon?: IconName | null
  avatar?: string | null
  name: React.ReactNode
  description: React.ReactNode
  levels: StructureLevelItem[]
}

interface StructureLevelsProps {
  levels: StructureLevelItem[]
}

export const StructureLevels = ({ levels }: StructureLevelsProps) => {
  return (
    <Group>
      {levels.map((level, index) => (
        <SturctureLevelItem {...level} key={level.id} depth={0} index={index} />
      ))}
    </Group>
  )
}

export interface StructureLevelItemProps extends StructureLevelItem {
  depth: number
  index: number
}

const SturctureLevelItem = ({
  url,
  icon,
  avatar,
  name,
  description,
  levels,
  depth,
  index,
}: StructureLevelItemProps) => {
  return (
    <>
      <HStack>
        {depth > 0 ? (
          <Relative width={40 * depth}>
            {index === 0 ? (
              <Absolute right={-6} top={22}>
                <Icon name="ArrowHookUp" color={Token.color.greyTone20} size={16} />
              </Absolute>
            ) : null}
          </Relative>
        ) : null}
        <Box width="100%">
          <Item use={InternalLink} to={url} variant="disclosure">
            <Item.Avatar>
              <UploadAvatarPreview useIcon={icon} avatar={avatar} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{name}</Item.Title>
              <Item.Description>{description}</Item.Description>
            </Item.Content>
          </Item>
        </Box>
      </HStack>
      {levels?.map((level, idx) => (
        <SturctureLevelItem {...level} key={level.id} depth={depth + 1} index={idx} />
      ))}
    </>
  )
}
