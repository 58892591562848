import React, { useMemo } from 'react'
import {
  Ratings,
  ReviewCategory,
  SummarySkillCardJustificationInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { VStack, Group, Token, Widget } from '@revolut/ui-kit'
import {
  CommonGradeOption,
  CommonSectionProps,
  deliverablesRating,
  getRoundedRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { useParams } from 'react-router-dom'
import { GradesMapInterface } from '@src/utils/grades'
import { useGetPerformanceSelector } from '@src/api/performance'
import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'
import { GoalsWidget } from './GoalsWidget'
import { NameIdInterface } from '@src/interfaces/requisitions'
import { PipSmartGoalsTableViewWidget } from '@src/features/PIP/components/PipSmartGoalsTableWidget'
import set from 'lodash/set'
import get from 'lodash/get'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { getViewGradesWithExpectations } from '../../EmployeePerformanceLayout/Cards/utils'

interface Props extends CommonSectionProps {
  category?: ReviewCategory
  cycleId?: number
  checkpointNum?: number
  comments?: SummarySkillCardJustificationInterface[]
  gradesMap: GradesMapInterface
  team: NameIdInterface | undefined
}

// FYI It's called "Probation" but in reality it's also used in PIP  https://revolut.atlassian.net/browse/REVC-8586 https://revolut.atlassian.net/browse/REVC-8586
export const ProbationDeliverables = connect(
  ({
    reviewData,
    isViewMode = false,
    category,
    cycleId,
    checkpointNum,
    gradesMap,
    team,
  }: Props) => {
    const { employeeId } = useParams<{ employeeId: string }>()

    const { data: cycles, isLoading } = useGetPerformanceSelector(employeeId, {
      exclude_adhoc: true,
    })

    const selectedCycle = useMemo(() => {
      // for some weird reason, although the employee cycles are with "E" one retunred in the probation_checkpoint.cycle_id is just a number, but should be with E
      return cycles?.find(cycle => cycle.id === `E-${cycleId}`)
    }, [cycles, cycleId, isLoading])

    if (!reviewData || !selectedCycle) {
      return null
    }

    if (isLoading) {
      return (
        <VStack data-testid="deliverables-section">
          <TableBodySkeleton rowHeight="medium" />
        </VStack>
      )
    }

    if (category === ReviewCategory.PIP_V2) {
      return (
        <PipSmartGoalsTableViewWidget employeeId={employeeId} pipCycle={selectedCycle} />
      )
    }

    return (
      <VStack data-testid="deliverables-section">
        <GoalsWidget
          team={team}
          selectedCycle={selectedCycle}
          employeeId={employeeId}
          isViewMode={isViewMode}
          category={category}
          checkpointNum={checkpointNum}
          gradesMap={gradesMap}
        />
      </VStack>
    )
  },
)

interface ProbationDeliverableRatingsProps extends CommonSectionProps {
  gradesMap: GradesMapInterface
}

export const ProbationDeliverablesRatings = ({
  reviewData,
  gradesMap,
  isViewMode = false,
}: ProbationDeliverableRatingsProps) => {
  if (!reviewData) {
    return null
  }

  const getFieldDetails = (option: CommonGradeOption) => {
    const ratingValue = get(
      reviewData,
      'calculated_deliverables_ratings.recommended_rating',
    )
    const isChecked =
      deliverablesRating.find(item => item.key === ratingValue)?.key === option.key
    return { isChecked, ratings: [] }
  }
  const getViewFieldDetails = (option: CommonGradeOption) => {
    const ratings =
      get(
        reviewData,
        'calculated_deliverables_ratings.recommended_rating.ratings',
      )?.filter((rating: Ratings) => {
        return getRoundedRating(rating.value) === option.key
      }) || []
    const isChecked = !!ratings.length
    return { isChecked, ratings }
  }
  const deliverablesRatingWithExp = getViewGradesWithExpectations(deliverablesRating)

  return (
    <Widget>
      <Group style={{ backgroundColor: Token.color.widgetBackground }}>
        {deliverablesRatingWithExp.map((option, optionIdx) => {
          const { isChecked, ratings } = isViewMode
            ? getViewFieldDetails(option)
            : getFieldDetails(option)

          return (
            <DeliverablesGrade
              key={optionIdx}
              isViewMode={isViewMode}
              isChecked={isChecked}
              ratings={ratings}
              option={option}
              onChange={() => {
                set(
                  reviewData,
                  'calculated_deliverables_ratings.recommended_rating',
                  option.key,
                )
              }}
              gradesMap={gradesMap}
            />
          )
        })}
      </Group>
    </Widget>
  )
}
