import { PerformanceSelector } from '@src/interfaces/performance'
import { PerformanceSelectorCycleOption } from '@src/interfaces/selectors'

import { useMemo } from 'react'
import { KPITypes } from '@src/constants/table'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { adaptPerformanceSelectorToCycleOption } from '@src/utils/performance'

export const useGetCycleSelector = (selector?: PerformanceSelector[]) => {
  const buildCycleSelector = async (): Promise<{
    options: PerformanceSelectorCycleOption[]
  }> => {
    return {
      options: selector?.map(adaptPerformanceSelectorToCycleOption) ?? [],
    }
  }

  return useMemo(() => buildCycleSelector, [selector])
}

export const onKPITableRowClick = (row: { id: number; type: KPITypes }) => {
  if (row.id === -1 || isOnboardingPath()) {
    return
  }

  navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: row.id }))
}
