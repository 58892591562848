import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'
import {
  Box,
  chain,
  ExpandableCell,
  Flex,
  Group,
  Icon,
  MoreBar,
  StatusWidget,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { getStatusColor } from '@components/CommonSC/General'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { InternalLink } from '@components/InternalLink/InternalLink'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { downloadDocusignEmbedded } from '@src/api/documents'
import { ROUTES } from '@src/constants/routes'
import {
  EmbeddedDocumentInterface,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { PermissionTypes } from '@src/store/auth/types'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { useErrorPopup } from '../Errors/useErrorPopup'

type Props = {
  data: EmbeddedDocumentInterface
  documentListItem: EmployeeDocumentListItemInterface
}
export const EmbeddedPreview = ({ data, documentListItem }: Props) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false)

  const canDownload =
    data.status === 'completed' &&
    data.field_options?.permissions?.includes?.(
      PermissionTypes.DownloadSentenveloperecipientsview,
    )
  const totalCount = data.signers.length
  const pendingCount = data.signers.reduce(
    (count, signer) => (signer.status === 'pending' ? count + 1 : count),
    0,
  )
  const signatureCellContent =
    pendingCount > 0 ? (
      <Text color={Token.color.orange}>
        {chain(`${pendingCount}/${totalCount}`, 'Pending signature')}
      </Text>
    ) : (
      <Text color={Token.color.green}>Completed</Text>
    )

  const errorPopup = useErrorPopup()

  return (
    <VStack space="s-16">
      <MoreBar>
        {canDownload && (
          <MoreBar.Action
            onClick={async () => {
              try {
                setIsDownloadPending(true)
                await downloadDocusignEmbedded(data?.envelope_uuid)
              } catch (error) {
                errorPopup.show({
                  error,
                  fallbackTitle: 'Failed to download file',
                })
              } finally {
                setIsDownloadPending(false)
              }
            }}
            useIcon="Download"
            pending={isDownloadPending}
          >
            Download
          </MoreBar.Action>
        )}
        <MoreBar.Action
          use={InternalLink}
          to={pathToUrl(ROUTES.FORMS.DOCUMENT_EMBEDDED.GENERAL, {
            id: documentListItem.recipient_id,
            employeeId: documentListItem.employee.id,
          })}
          useIcon="ArrowThinRight"
        >
          Go to details page
        </MoreBar.Action>
      </MoreBar>
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D310.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@3x.png',
          }}
        />
        <StatusWidget.Title>This document can't be previewed</StatusWidget.Title>
      </StatusWidget>
      <FormPreview data={data}>
        <Group>
          <FormPreview.Item<EmbeddedDocumentInterface>
            title="Status"
            field="status"
            insert={d =>
              d.status ? (
                <Text color={getStatusColor(d.status)}>{capitalize(d.status)}</Text>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item<EmbeddedDocumentInterface>
            title="Issued by"
            field="sent_by.name"
            to={d =>
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: d.sent_by.id,
              })
            }
          />
          <FormPreview.Item title="Issued on" field="creation_date_time" type="date" />
          <FormPreview.Item title="Document category" field="category.name" />
          <FormPreview.Item<EmbeddedDocumentInterface>
            title="Signed on"
            field="signed_on"
            type="date"
            insert={d => {
              if (d.signed_on) {
                return formatDate(d.signed_on)
              }
              return 'Not signed yet'
            }}
          />
          <ExpandableCell>
            <ExpandableCell.Title>Signature status</ExpandableCell.Title>
            <ExpandableCell.Content>{signatureCellContent}</ExpandableCell.Content>
            <ExpandableCell.Note>
              <Flex flexDirection="column" gap="s-16">
                {data.signers.map(signer => (
                  <Box key={signer.routing_order}>
                    <Flex alignItems="center" mb="s-8">
                      <Box width="s-24">
                        {signer.status === 'completed' ? (
                          <Icon name="Check" color={Token.color.green} size={16} />
                        ) : (
                          <Icon name="Time" size={16} />
                        )}
                      </Box>
                      <Text variant="h6">
                        {chain(
                          `Signatory ${signer.routing_order}`,
                          capitalize(signer.status),
                        )}
                      </Text>
                    </Flex>
                    <Flex style={{ whiteSpace: 'pre' }}>
                      <UserWithAvatar {...signer.employee} ml="s-24" />
                      {signer.is_next_in_signing ? ` (current signer)` : ''}
                    </Flex>
                  </Box>
                ))}
              </Flex>
            </ExpandableCell.Note>
          </ExpandableCell>
        </Group>
      </FormPreview>
    </VStack>
  )
}
