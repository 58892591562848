import React from 'react'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Flex, Group, Subheader, Token } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar/index'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import RoleSubmitButton from '@src/pages/Forms/RoleForm/Buttons/RoleSubmitButton'
import StickyContainerLape from '@src/features/Form/Containers/StickyContainer'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import JobDescription from '@src/pages/Forms/RoleForm/JobDescription'
import { Statuses } from '@src/interfaces'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import { PermissionTypes } from '@src/store/auth/types'
import { SectionOptions } from '@src/interfaces/customFields'
import { useHasBundleRecruitment } from '@src/hooks/useHasBundle'

const Preview = () => {
  const context = useLapeContext<RoleInterface>()
  const params = useParams()

  const hasBundleRecruitment = useHasBundleRecruitment()
  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { job_postings_enabled },
  } = useGlobalSettings()
  const approvalsEnabled = !!settings?.enable_roles_approvals

  const modelPermissions = context.values.field_options.permissions || []
  const allowEdit = modelPermissions?.includes(PermissionTypes.ChangeRoles)

  if (context.loading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  return (
    <>
      <PreviewStageSidebar />
      <PageBody maxWidthMd={Token.breakpoint.md}>
        {approvalsEnabled && context.values.status !== Statuses.draft && (
          <ApprovalFlow
            isLoading={!context.values.approval_flow_status}
            steps={parseLegacyApprovalSteps(
              context.values.approval_flow_status?.approval_steps,
            )}
          />
        )}
        <FormPreview<RoleInterface>
          data={context.values}
          title="About the role"
          onEdit={
            allowEdit
              ? () => navigateTo(pathToUrl(ROUTES.FORMS.ROLE.EDIT.GENERAL_INFO, params))
              : undefined
          }
        >
          <Group>
            <FormPreview.Item title="Role name" field="name" />
            <FormPreview.Item title="Role owner" type="employee" field="role_manager" />
            <FormPreview.Item
              title="Function"
              field="function.name"
              to={() =>
                pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, {
                  id: context.values.function?.id,
                })
              }
            />
            {settings?.enable_location_limitations ? (
              <>
                <FormPreview.Item<RoleInterface>
                  title="Locations"
                  field="locations"
                  insert={data =>
                    data?.locations?.length
                      ? data.locations?.map(loc => loc.name).join(', ')
                      : 'All'
                  }
                />
              </>
            ) : null}
            <FormPreview.Details title="Role mission" field="role_description" />
            <FormPreview.Details<RoleInterface>
              title="Role goals"
              field="role_goals"
              insert={data => (
                <ul style={{ paddingInlineStart: 16 }}>
                  {data.role_goals?.map((goal, id) => {
                    return <li key={id}>{goal.text}</li>
                  })}
                </ul>
              )}
            />
            <FormPreview.CustomFields
              sectionId={SectionOptions.Roles}
              roleId={context.values.id}
            />
          </Group>
        </FormPreview>

        {job_postings_enabled && hasBundleRecruitment && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Job posting</Subheader.Title>
            </Subheader>
            <JobDescription type="role" />
          </>
        )}

        <StickyContainerLape sticky isCenter maxWidth={624}>
          {(!context.values?.id || context.values.status === Statuses.draft) && (
            <>
              <RoleSaveDraftButton
                title="role"
                pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
                pathAfterSave={ROUTES.FORMS.ROLE.PREVIEW}
                isNew
                notification={{
                  updateMsg: 'Role draft successfully updated.',
                  createMsg: 'Role draft successfully created.',
                }}
              />
              <RoleSubmitButton
                isNew
                notification={{
                  updateMsg: 'Role successfully updated.',
                  createMsg: 'Role successfully created.',
                }}
              />
            </>
          )}
        </StickyContainerLape>
      </PageBody>
    </>
  )
}

export default connect(Preview)
