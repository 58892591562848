import { useGetEngagementSurveyRounds } from '@src/api/engagement'
import { ApiQueryParams } from '@src/api/types'
import { EngagementSurveyRoundInterface } from '@src/interfaces/engagement'
import { formatPeriod } from '@src/utils/format'
import { RoundOption } from '../types'
import { sortByDate } from '@src/utils/sortByDate'

const formatRounds = (
  rounds: EngagementSurveyRoundInterface[] | undefined,
): RoundOption[] =>
  rounds?.map(round => {
    const datesRange =
      round.sent_on && round.closing_date
        ? `${formatPeriod(round.sent_on, round.closing_date)}`
        : undefined

    return {
      ...round,
      name: datesRange || `Round ${round.id}`,
      dateFrom: round.sent_on || '',
      dateTo: round.closing_date || '',
    }
  }) || []

export const useGetSurveyRoundsOptions = (
  surveyId: number | undefined,
  params: ApiQueryParams = {},
): { isLoading: boolean; options: RoundOption[]; refetch: VoidFunction } => {
  const defaultParams = {
    is_test: 'false',
    status: 'completed',
    ordering: 'status_order,-sent_on',
  }
  const {
    data: roundsOptionsData,
    refetch,
    isLoading,
  } = useGetEngagementSurveyRounds(surveyId, {
    ...defaultParams,
    ...params,
  })

  return {
    isLoading,
    refetch,
    options: sortByDate(formatRounds(roundsOptionsData?.results), 'sent_on'),
  }
}
