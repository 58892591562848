import React from 'react'
import { WeightsErrorBanner } from './WeightsErrorBanner'
import { ActionButton, Avatar, Group, Item, useToggle } from '@revolut/ui-kit'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { SkillsSideBar } from '@src/pages/Specialisation/Form/components/SkillsSideBar'
import { checkCanEdit } from '../helpers/checkCanEdit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { useMatrixData } from '../hooks/useMatrixData'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useSpecialisationCompetencyMatrices } from '../hooks/useSpecialisationCompetencyMatrices'
import { useWeightsErrors } from '../hooks/useWeightsErrors'

interface Props {
  role: RoleInterface | undefined
  filteredSeniorities: SeniorityInterface[]
}

export const CompetencyMatrixWidget = ({ role, filteredSeniorities }: Props) => {
  const [isAddSkillOpen, toggleAddSkill] = useToggle()
  const { values } = useLapeContext<SpecialisationInterface>()
  const canEditWeights = Boolean(values.id && values.performance_weights)
  const specialisationCompetencyMatrices = useSpecialisationCompetencyMatrices({
    role,
  })

  const { totalWeight, skillWithNoWeight } = useWeightsErrors()
  const { onChangeCompetencyMatrix } = useMatrixData({
    values,
    role,
    type: 'specialisation',
  })

  const canEdit = checkCanEdit(values)

  return (
    <>
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Palette" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Competency matrix</Item.Title>
          </Item.Content>
          <Item.Side>
            {canEdit && canEditWeights && (
              <ActionButton
                useIcon="Plus"
                onClick={toggleAddSkill.switch}
                variant={isAddSkillOpen ? 'accent' : 'primary'}
              >
                Add skill
              </ActionButton>
            )}
          </Item.Side>
        </Item>

        <CompetencyMatrixTable
          competencyMatrices={specialisationCompetencyMatrices}
          minSeniority={values.seniority_min}
          maxSeniority={values.seniority_max}
          withWeightColumn={canEditWeights}
          withActionColumn={canEditWeights}
          weightsError={
            skillWithNoWeight || (typeof totalWeight === 'number' && totalWeight !== 100)
          }
          firstRowTitle="Skills"
          filterNonExistent
          readOnly={!canEdit}
          onChangeCompetencyMatrix={onChangeCompetencyMatrix}
          competencyMatrix={values.competency_matrix}
          type="specialisation"
        />
      </Group>
      {canEdit && (
        <WeightsErrorBanner
          totalWeight={totalWeight}
          skillWithNoWeight={skillWithNoWeight}
        />
      )}
      {isAddSkillOpen && (
        <SkillsSideBar
          withWeights={canEditWeights}
          role={role}
          onClose={toggleAddSkill.off}
          seniorities={filteredSeniorities}
          type="specialisation"
        />
      )}
    </>
  )
}
