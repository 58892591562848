export interface FeedbackInterface {
  id?: number
  user?: {
    id: number
    email: string
    employee_id: string
  }
  user_uuid?: string
  user_email?: string
  status: { id: 'completed' | 'rejected' }
  score?: number
  text?: string
  creation_date_time: string
}

export type FeedbackTopic =
  | 'platform'
  | 'onboarding'
  | 'help_center'
  | 'support'
  | 'recruitment'

export interface FeedbackSubmitInterface {
  status: { id: 'completed' | 'rejected' }
  score?: number
  text?: string
  topic: { id: FeedbackTopic }
  labels?: (number | string)[]
  object_id?: number | string
  object_type?: string
}

export enum FeedbackActionsTypes {
  SET_FEEDBACK_OPEN = '@@feedback/SET_FEEDBACK_OPEN',
  SET_LOADING = '@@feedback/OPEN_FEEDBACK',
  CLOSE_FEEDBACK = '@@feedback/CLOSE_FEEDBACK',
  SUBMIT_FEEDBACK = '@@feedback/SUBMIT_FEEDBACK',
}

export interface FeedbackState {
  readonly open?: boolean
  readonly loading?: boolean
}

export interface FeedbackLabelInterface {
  id: number
  name: string
  score: number
  topic: FeedbackTopic
}
