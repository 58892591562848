import React, { useState } from 'react'
import {
  CandidateSidebarTypes,
  InterviewCancellingStatuses,
  InterviewFeedbackStatus,
  InterviewReschedulingStatuses,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import {
  Color,
  DropdownItemProps,
  MoreBar,
  StatusPopup,
  Text,
  useStatusPopup,
} from '@revolut/ui-kit'
import GenerateLinkPopup from '@src/pages/Forms/Candidate/InterviewProgress/components/Popups/GenerateLinkPopup'
import { skipInterview, unskipInterview } from '@src/pages/Forms/Candidate/utils'
import RescheduleInterviewButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/RescheduleInterviewButton'
import StagesTableActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StagesTableActions'
import CancelInterviewButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/CancelInterviewButton'
import AddFeedbackButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/AddFeedbackButton'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetLatestOffer } from '@src/api/offerCreation'
import {
  useGetCandidateSettings,
  useGetDocumentsSettings,
  useGetOfferSettings,
} from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetCandidate } from '@src/api/recruitment/interviews'
import {
  clearCVScreeningSession,
  navigateToCVScreening,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'
import RejectFeedbackButton from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/RejectFeedbackButton'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { MoveStageAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/MoveStageAction'
import { ScheduleInterviewAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/ScheduleInterviewAction'
import { DuplicateStageAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/DuplicateStageAction'
import { isStageStarted } from '@src/pages/Forms/Candidate/StagesWidget/utils'
import { RemoveStageAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/RemoveStageAction'

type Props = {
  stage: InterviewStageWithoutRoundInterface
  canAddFeedback: boolean
  canRejectFeedback: boolean
  canDuplicateStage: boolean
  canRemoveStage: boolean
  stages?: InterviewStageWithoutRoundInterface[]
  roundId: number
  canCancel: boolean
  onRefresh?: () => void
  currentStageId?: number
  // TODO: it's not clear, refactoring needed
  onOpenSidebar?: (
    data: InterviewStageWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
    actionType?: CandidateSidebarTypes,
  ) => void
  menuType: MenuActionType
  children?: React.ReactNode
  canViewEditOffer: boolean
  candidateId: number
  compact?: boolean
  maxCount?: number
}

const StageActions = ({
  canAddFeedback,
  canRejectFeedback,
  canViewEditOffer,
  canDuplicateStage,
  canRemoveStage,
  stage,
  stages,
  roundId,
  onRefresh,
  currentStageId,
  onOpenSidebar,
  menuType,
  canCancel,
  children,
  candidateId,
  compact,
  maxCount,
}: Props) => {
  const user = useSelector(selectUser)
  const statusPopup = useStatusPopup()
  const [isOpen, setOpen] = useState(false)
  const [isLinkPopupOpen, setLinkPopupOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { data: documentsSettings } = useGetDocumentsSettings()
  const { data: offerSettings } = useGetOfferSettings()
  const { data: candidateSettings } = useGetCandidateSettings()
  const isCommercial = useIsCommercial()

  const offersEnabled =
    (isCommercial ? true : documentsSettings?.enable_docusign_integration) &&
    canViewEditOffer
  const { data: latestOffer } = useGetLatestOffer(
    stage.interview_type === 'offer' && offersEnabled ? roundId : null,
  )
  const { data: candidate } = useGetCandidate(canViewEditOffer ? candidateId : null)
  const offerDisabled = !isCommercial && !candidate?.phone

  const itemProps: DropdownItemProps = {
    disabled: loading,
    use: 'button',
  }

  const onClose = () => {
    setOpen(false)
  }

  const linkHandler = () => {
    setOpen(false)
    setLinkPopupOpen(true)
  }

  const skipInterviewHandler = async () => {
    setLoading(true)
    setOpen(false)

    try {
      await skipInterview(roundId, stage.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Interview scheduling skipped</StatusPopup.Title>
        </StatusPopup>,
      )
      onRefresh?.()
    } finally {
      setLoading(false)
    }
  }

  const unskipInterviewHandler = async () => {
    setLoading(true)
    setOpen(false)

    try {
      await unskipInterview(roundId, stage.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Interview scheduling unskipped</StatusPopup.Title>
        </StatusPopup>,
      )

      onRefresh?.()
    } finally {
      setLoading(false)
    }
  }

  const getActions = () => {
    const actions: React.ReactNode[] = []

    const generateScorecardAction = () => {
      if (canAddFeedback) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: linkHandler,
            }}
            key="Generate scorecard"
          >
            Generate scorecard
          </MenuAction>,
        )
      }
    }

    const addFeedbackAction = () => {
      if (canAddFeedback) {
        actions.push(
          <AddFeedbackButton
            menuType={menuType}
            stageId={stage.id}
            loading={loading}
            setLoading={setLoading}
            onProceed={() => {
              setOpen(false)
              onRefresh?.()
            }}
            edit={stage.scheduling_status === 'feedback_submitted'}
            key="add"
          />,
        )
      }
    }

    const rejectFeedbackAction = () => {
      if (canRejectFeedback) {
        const feedback = stage.interview_feedbacks.find(
          item => item.interviewer?.id === user.id,
        )
        if (
          feedback &&
          (feedback.status === InterviewFeedbackStatus.pending ||
            feedback.status === InterviewFeedbackStatus.draft)
        ) {
          actions.push(
            <RejectFeedbackButton
              key="reject"
              itemProps={itemProps}
              id={feedback.id}
              onRefresh={onRefresh}
              menuType={menuType}
            />,
          )
        }
      }
    }

    const cvScreeningAction = () => {
      if (stage.id === currentStageId && candidate) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                clearCVScreeningSession()
                setCVScreeningSession({
                  candidates: [{ id: candidate!.id, name: candidate!.full_name }],
                })
                navigateToCVScreening(candidateId)
              },
            }}
            key="CV screening"
          >
            Screen CV
          </MenuAction>,
        )
      }
    }

    const sendTestInviteAction = () => {
      if (
        stage.scheduling_status === 'test_not_sent' ||
        stage.scheduling_status === 'test_sent'
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                onOpenSidebar?.(stage, undefined, 'sendOnlineTest')
              },
            }}
            key="Send invite"
          >
            {stage.scheduling_status === 'test_not_sent'
              ? 'Send invite'
              : 'Re-send invite'}
          </MenuAction>,
        )
      }
    }

    const moveStageAction = () => {
      if (stage.id !== currentStageId) {
        actions.push(
          <MoveStageAction
            stage={stage}
            roundId={roundId}
            menuType={menuType}
            onClose={onClose}
            onLoading={setLoading}
            itemProps={itemProps}
            onRefresh={onRefresh}
            key="Move to stage"
          />,
        )
      }
    }

    const duplicateStageAction = () => {
      if (canDuplicateStage && isStageStarted(stage)) {
        actions.push(
          <DuplicateStageAction
            stageId={stage.id}
            roundId={roundId}
            menuType={menuType}
            onClose={onClose}
            onLoading={setLoading}
            itemProps={itemProps}
            onRefresh={onRefresh}
            key="Duplicate stage"
          />,
        )
      }
    }

    const removeStageAction = () => {
      if (canRemoveStage && !isStageStarted(stage) && stage.id !== currentStageId) {
        actions.push(
          <RemoveStageAction
            stageId={stage.id}
            roundId={roundId}
            menuType={menuType}
            onClose={onClose}
            onLoading={setLoading}
            itemProps={itemProps}
            onRefresh={onRefresh}
            key="Duplicate stage"
          />,
        )
      }
    }

    const createOfferAction = () => {
      if (latestOffer) {
        return
      }

      const label = offerSettings?.enable_offer_templates ? 'Create offer' : 'Hire'

      actions.push(
        <MenuAction
          menuType={menuType}
          props={{
            ...itemProps,
            'aria-disabled': offerDisabled,
            onClick: () => {
              if (!offerDisabled) {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, {
                    candidateId,
                  }),
                )
              }
            },
          }}
          key={label}
        >
          {label}
          {offerDisabled && (
            <Text use="div" color={Color.RED}>
              Candidate has no phone number
            </Text>
          )}
        </MenuAction>,
      )
    }

    const editOfferAction = () => {
      if (!latestOffer) {
        return
      }

      actions.push(
        <MenuAction
          menuType={menuType}
          props={{
            ...itemProps,
            onClick: () =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, {
                  candidateId,
                  id: latestOffer.id,
                }),
              ),
          }}
          key="Edit offer"
        >
          Edit offer
        </MenuAction>,
      )
    }

    const viewOfferAction = () => {
      if (!latestOffer) {
        return
      }

      actions.push(
        <MenuAction
          menuType={menuType}
          props={{
            ...itemProps,
            onClick: () =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, {
                  candidateId,
                  id: latestOffer.id,
                }),
              ),
          }}
          key="View offer"
        >
          View offer
        </MenuAction>,
      )
    }

    const openTestReportAction = () => {
      if (
        stage.scheduling_status === 'test_completed' &&
        stage.online_test_result?.test_report?.url
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                window.open(stage.online_test_result!.test_report!.url!, '_blank')
              },
            }}
            key="Open report"
          >
            Open report
          </MenuAction>,
        )
      }
    }

    const scheduleInterviewAction = () => {
      actions.push(
        <ScheduleInterviewAction
          schedulingStatus={stage.scheduling_status}
          itemProps={itemProps}
          menuType={menuType}
          onClick={() => {
            onOpenSidebar?.(stage, 'scheduling')
            onClose()
          }}
          key="Schedule interview"
        />,
      )
    }

    const undoSkipInterviewAction = () => {
      if (
        stage.scheduling_status === InterviewStatuses.awaiting_feedback &&
        stage.skip_scheduling
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: unskipInterviewHandler,
            }}
            key="Undo skipping interview"
          >
            Undo skipping interview
          </MenuAction>,
        )
      }
    }

    const skipInterviewAction = () => {
      if (stage.scheduling_status === InterviewStatuses.pending_scheduling) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: skipInterviewHandler,
            }}
            key="Skip interview"
          >
            Skip interview
          </MenuAction>,
        )
      }
    }

    const rescheduleInterviewAction = () => {
      if (
        candidateSettings?.enable_scheduling &&
        InterviewReschedulingStatuses.includes(stage.scheduling_status)
      ) {
        actions.push(
          <RescheduleInterviewButton
            onProceed={() => {
              onOpenSidebar?.(stage, 'rescheduling')
              setOpen(false)
            }}
            disabled={itemProps.disabled}
            menuType={menuType}
            key="rescheduling"
          />,
        )
      }
    }

    const cancelInterviewAction = () => {
      if (
        candidateSettings?.enable_scheduling &&
        InterviewCancellingStatuses.includes(stage.scheduling_status) &&
        canCancel
      ) {
        actions.push(
          <CancelInterviewButton
            menuType={menuType}
            roundId={roundId}
            stageId={stage.id}
            onProceed={() => {
              onRefresh?.()
            }}
            key="cancel"
          />,
        )
      }
    }

    switch (stage.interview_type) {
      case 'cv_screening': {
        cvScreeningAction()
        moveStageAction()
        break
      }

      case 'online_test': {
        sendTestInviteAction()
        openTestReportAction()
        moveStageAction()
        break
      }

      case 'hiring_panel': {
        generateScorecardAction()
        addFeedbackAction()
        rejectFeedbackAction()
        moveStageAction()
        break
      }

      case 'offer': {
        if (offersEnabled) {
          createOfferAction()

          if (offerSettings?.enable_offer_templates) {
            editOfferAction()
            viewOfferAction()
          }
        }

        moveStageAction()
        break
      }

      default: {
        scheduleInterviewAction()
        generateScorecardAction()
        addFeedbackAction()
        rejectFeedbackAction()
        skipInterviewAction()
        undoSkipInterviewAction()
        rescheduleInterviewAction()
        cancelInterviewAction()
        moveStageAction()
        duplicateStageAction()
        removeStageAction()
      }
    }
    return actions
  }

  const actions = getActions()

  return (
    <>
      {(!!actions.length || children) && (
        <>
          {menuType !== 'dropdown' ? (
            <MoreBar maxCount={maxCount} data-testid="stages table actions morebar">
              {actions}
              {children}
            </MoreBar>
          ) : (
            <StagesTableActions
              isOpen={isOpen}
              setOpen={setOpen}
              loading={loading}
              compact={compact}
            >
              {actions}
              {children}
            </StagesTableActions>
          )}
        </>
      )}
      {stages && (
        <GenerateLinkPopup
          data={stages}
          roundId={roundId}
          isOpen={isLinkPopupOpen}
          onRefresh={onRefresh}
          onClose={() => setLinkPopupOpen(false)}
          initialStageId={stage.id}
          currentStageId={currentStageId}
        />
      )}
    </>
  )
}

export default StageActions
