import React from 'react'
import { Box } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import Table from '@components/TableV2/Table'
import { getOffersOwnership, offersOwnershipTransfer } from '@src/api/ownership'
import {
  offerCandidateNameColumn,
  offerRecruiterColumn,
  offerSigningStatusColumn,
  offerSpecialisationColumn,
  offerStatusColumn,
} from '@src/constants/columns/offerForm/offers'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const OffersOwnership = ({ data, stats, refreshStats }: Props) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const transferApi = (selections: TransferSelections) =>
    offersOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        owner: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getOffersOwnership(data.id)}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer offers to a new owner"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Offers" val={stats?.owner_offer_form_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                { ...offerCandidateNameColumn, width: 220 },
                { ...offerSpecialisationColumn(specialisationsEnabled), width: 250 },
                { ...offerRecruiterColumn, width: 250 },
                { ...offerStatusColumn, width: 150 },
                { ...offerSigningStatusColumn, width: 180 },
              ]}
              noDataMessage="Offers ownership will appear here"
              ownerColTitle="New owner"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default OffersOwnership
