import React from 'react'
import { useBulkApproveGoals } from '@src/api/pipAndProbation'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { BottomSheet, Button, Icon, Text, VStack } from '@revolut/ui-kit'

interface Props {
  open: boolean
  cycleIds: string[]
  onClose: VoidFunction
  onSuccess: VoidFunction
}

export const ProbationGoalsBulkApprove = ({
  open,
  onClose,
  onSuccess,
  cycleIds,
}: Props) => {
  const { mutateAsync: bulkApprove, isLoading } = useBulkApproveGoals()
  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  const onConfirm = async () => {
    try {
      await bulkApprove({ cycles: cycleIds })
      showStatusPopup({
        title: 'Goals approved',
        status: 'success',
      })
      onSuccess()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to approve goals',
      })
    }
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <VStack align="center">
        <Icon name="Flag" size={52} />
        <Text variant="h5" use="h5">
          Approve goals
        </Text>
      </VStack>
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" elevated onClick={onConfirm} pending={isLoading}>
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
