import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeType } from '@src/interfaces/reviewCycles'
import { Button, InputGroup, Side, chain } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import FiltersSidebarItem from '@components/FiltersSidebar/FiltersSidebarItem'
import SideBar from '@components/SideBar/SideBar'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { IdContractTypes } from '@src/interfaces/contracts'
import {
  ProbationTemplateInterface,
  ProbationTemplateRules,
} from '@src/interfaces/probationTemplate'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { connect } from 'lape'

export const EligibleEmployeesSidebar = connect(
  ({ onClose }: { onClose: () => void }) => {
    const { values } = useLapeContext<ProbationTemplateInterface>()
    const specialisationsEnabled = useIsSpecialisationsEnabled()
    const [tempRules, setTempRules] = useState<ProbationTemplateInterface['rules']>(
      values.rules || {},
    )

    const getEntityValue = (field: ProbationTemplateRules) => {
      return (
        tempRules?.[field]?.map(rule => ({
          name: String(rule.id),
          id: rule.id,
        })) || []
      )
    }

    const onEntityValueChange = (
      option: FilterOption[],
      field: ProbationTemplateRules,
    ) => {
      if (option) {
        setTempRules(prev => ({
          ...prev,
          [field]: option.length
            ? option.map(item => ({ id: Number(item.id), name: item.name }))
            : undefined,
        }))
      }
    }

    return (
      <SideBar
        isOpen
        useBackButton
        title={chain(values.country?.name, 'Eligible employee group')}
        onClose={onClose}
      >
        <InputGroup>
          {/* With specialisationsEnabled === false we still show specialisations but call them Roles on UI and hide the actual Roles filter */}
          <FiltersSidebarItem
            label={specialisationsEnabled ? 'Specialisations' : 'Roles'}
            icon="Palette"
            selector={selectorKeys.specialisations}
            value={getEntityValue(ProbationTemplateRules.specialisation_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.specialisation_id)
            }
            filterType={FilterType.selector}
          />
          {specialisationsEnabled && (
            <FiltersSidebarItem
              label="Roles"
              icon="Puzzle"
              selector={selectorKeys.role}
              value={getEntityValue(ProbationTemplateRules.position_id)}
              onChange={value =>
                onEntityValueChange(value, ProbationTemplateRules.position_id)
              }
              filterType={FilterType.selector}
            />
          )}
          <FiltersSidebarItem
            label="Departments"
            icon="BriefcaseCoins"
            selector={selectorKeys.department}
            value={getEntityValue(ProbationTemplateRules.team__department_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.team__department_id)
            }
            filterType={FilterType.selector}
          />

          <FiltersSidebarItem
            label="Teams"
            icon="People"
            selector={selectorKeys.team}
            value={getEntityValue(ProbationTemplateRules.team_id)}
            onChange={value => onEntityValueChange(value, ProbationTemplateRules.team_id)}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Seniority"
            icon="StarEmpty"
            selector={selectorKeys.seniority}
            value={getEntityValue(ProbationTemplateRules.seniority_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.seniority_id)
            }
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Country"
            icon="Globe"
            selector={selectorKeys.location}
            value={getEntityValue(ProbationTemplateRules.location_id)}
            onChange={value => {
              onEntityValueChange(value, ProbationTemplateRules.location_id)
            }}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Functions"
            icon="RepairTool"
            selector={selectorKeys.functions}
            value={getEntityValue(ProbationTemplateRules.position__function_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.position__function_id)
            }
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Employee type"
            icon="Document"
            selector={selectorKeys.employee_types}
            value={getEntityValue(ProbationTemplateRules.employee_type)}
            onChange={value => {
              if (value) {
                values.rules.employee_type = value.length
                  ? value.map(item => ({
                      id: EmployeeType[item.id as keyof typeof EmployeeType],
                      name: item.name,
                    }))
                  : undefined
              }
            }}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Contract type"
            icon="DocumentsPair"
            selector={selectorKeys.contract_types}
            value={getEntityValue(ProbationTemplateRules.contract_type)}
            onChange={value => {
              if (value) {
                values.rules.contract_type = value.length
                  ? value.map(item => ({
                      id: IdContractTypes[item.id as keyof typeof IdContractTypes],
                      name: item.name,
                    }))
                  : undefined
              }
            }}
            filterType={FilterType.selector}
          />
        </InputGroup>
        <Side.Actions horizontal>
          <Button
            variant="secondary"
            onClick={() => {
              setTempRules({})
            }}
            elevated
          >
            Clear
          </Button>
          <Button
            onClick={() => {
              values.rules = tempRules
              onClose()
            }}
          >
            Confirm
          </Button>
        </Side.Actions>
      </SideBar>
    )
  },
)
