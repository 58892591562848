import React from 'react'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import { Box, Circle, HStack, Icon, Tag, Text, Token } from '@revolut/ui-kit'

import { DistributionChart } from '@src/apps/People/Engagement/Results/components/DistributionChart'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { EngagementResultInterface } from '@src/features/Engagement/api/analytics/interfaces'
import {
  HeatMapComparisonMode,
  HeatmapTableCell,
} from '@src/features/Engagement/components/HeatmapTableCell'
import { IdAndName } from '@src/interfaces'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { EngagementAnswerInterface } from '@src/interfaces/engagement'
import { selectorKeys } from '../api'

export const getEngagementResultsQuestionNameColumn = (
  selectorOptions: IdAndName[],
): ColumnInterface<EngagementResultInterface> => ({
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: () => Promise.resolve({ options: selectorOptions }),
  title: 'Question',
})

export const engagementResultsQuestionCategoryNameColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.text,
    idPoint: 'driver',
    dataPoint: 'driver',
    sortKey: 'driver',
    filterKey: 'driver',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
  }

export const engagementResultsQuestionTypeColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: 'question__type',
    filterKey: 'question__type',
    selectorsKey: selectorKeys.engagement_question_types_v2,
    title: 'Question type',
    insert: ({ data }) => {
      if (typeof data.type === 'object') {
        // TODO: RHR-6307 Remove when BE fixes data format
        return get(data.type, 'name')
      }
      return data.type
    },
  }

export const getEngagementResultsScoreColumn = ({
  isScopedView,
  heatMapComparisonMode,
}: {
  isScopedView?: boolean
  heatMapComparisonMode?: HeatMapComparisonMode
}): ColumnInterface<EngagementResultInterface & { isParent?: boolean }> => ({
  type: CellTypes.insert,
  idPoint: 'average_score',
  dataPoint: 'average_score',
  sortKey: 'average_score',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Score',
  padding: '4px',
  notHoverable: true,
  insert: ({ data }) => (
    <HeatmapTableCell
      data={{
        ...data,
        comparisonScore: isScopedView ? data.company_average_score : null,
      }}
      isParent={data.isParent}
      mode={heatMapComparisonMode}
    />
  ),
  headerTooltip: (
    <Box color={Token.color.background} p="s-8" minWidth={250}>
      The average score is normalised average of all answers for each particular question
      or driver, it ranges from 1 to 10
    </Box>
  ),
})

export const getEngagementResultsCategoryNameColumn = (
  selectorOptions: IdAndName[],
): ColumnInterface<EngagementResultInterface> => ({
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: () => Promise.resolve({ options: selectorOptions }),
  title: 'Category',
})

export const engagementResultsQuestionTextColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'question.id',
    dataPoint: 'question.question_text',
    sortKey: 'question__title',
    filterKey: 'question__id',
    selectorsKey: selectorKeys.engagement_question_texts,
    title: 'Question',
    insert: ({ data }) => (
      <Box py="s-12">
        <Text whiteSpace="pre-wrap">{data.question?.question_text || '-'}</Text>
      </Box>
    ),
    notHoverable: true,
  }

export const isEmptyAnswerTextColName = 'answer_text__isnull'
export const engagementResultsAnswerTextColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'answer_text',
    sortKey: null,
    filterKey: isEmptyAnswerTextColName,
    filterType: FilterType.boolean,
    selectorsKey: selectorKeys.yes_no_inverted_value_options,
    title: 'Answer text',
    notHoverable: true,
    insert: ({ data }) => (
      <HStack align="center" space="s-12" py="s-8">
        {!!data.answer_text && (
          <Tooltip
            placement="top"
            text={data.acknowledged ? 'Acknowledged' : 'Not acknowledged'}
          >
            <Icon
              size={14}
              name={data.acknowledged ? 'CheckSuccess' : '16/Time'}
              color={data.acknowledged ? Token.color.green_80 : Token.color.orange_80}
            />
          </Tooltip>
        )}
        <Text
          use="div"
          whiteSpace="pre-wrap"
          color={data.answer_text ? Token.color.foreground : Token.color.greyTone50}
        >
          {data.answer_text || 'n/a'}
        </Text>
      </HStack>
    ),
  }

export const engagementResultsCategoryColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.text,
    idPoint: 'question.driver.id',
    dataPoint: 'question.driver.name',
    sortKey: 'driver__name',
    filterKey: 'driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
  }

export const engagementResultsAnswerScoreColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'answer_score',
    dataPoint: 'answer_score',
    sortKey: 'answer_score',
    filterKey: 'answer_score',
    selectorsKey: selectorKeys.engagement_answer_scores,
    title: 'Answer score',
    insert: ({ data }) => {
      if (isNumber(data.answer_score)) {
        return (
          <Circle size={18} variant="filled">
            <Text fontSize="x-small" fontWeight={500} color={Token.color.background}>
              {data.answer_score}
            </Text>
          </Circle>
        )
      }
      if (data.categories?.names?.length) {
        return (
          <HStack space="s-4">
            {data.categories.names.map(name => (
              <Tag key={name} variant="outlined">
                {name}
              </Tag>
            ))}
          </HStack>
        )
      }
      return '-'
    },
  }

export const engagementResultsAnsweredOnColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    filterType: FilterType.date,
    title: 'Answered on',
  }

export const engagementResultsDistributionColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => {
      return <DistributionChart data={data} />
    },
    title: 'Distribution',
  }

export const engagementResultsCommentsNumColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'number_of_comments',
    dataPoint: 'number_of_comments',
    sortKey: 'number_of_comments',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Comments',
    insert: ({ data }) => {
      if (data.number_of_comments == null) {
        return <Text color={Token.color.greyTone50}>n/a</Text>
      }
      return (
        <HStack align="center" space="s-8">
          <Icon name="Chat" size={14} color={Token.color.greyTone50} />
          <Text>{data.number_of_comments}</Text>
        </HStack>
      )
    },
  }
