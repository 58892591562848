import {
  Flex,
  Token,
  Text,
  Image,
  Item,
  Avatar,
  VStack,
  Group,
  TextButton,
  HStack,
  Icon,
  ActionButton,
} from '@revolut/ui-kit'
import {
  FinalGradeInterface,
  PerformanceType,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import React, { ReactNode } from 'react'
import { formatDate } from '@src/utils/format'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  useGetEmployeeGradeVisibility,
  useUpdateEmployeeGradeVisibility,
} from '@src/api/performance'
import { isAfter } from 'date-fns'

interface Props {
  type: PerformanceType | undefined
  actionsElements: ReactNode
  reviewsElements: ReactNode
  finalGrade: FinalGradeInterface | undefined
  publishedResults: SingleTimelineEventInterface[]
  isEmptyReviewers: boolean
  eventStartDate?: string
  eventEndDate?: string
  isClosedCycle?: boolean
  isManualCycle?: boolean
  canChangeGradePublishing: boolean
  employee?: EmployeeInterface
  selectedCycleId?: string | number
}

interface EmptyTimelineElementProps {
  type?: PerformanceType
  isClosedCycle?: boolean
  eventStartDate?: string
  eventEndDate?: string
  isManualCycle?: boolean
}

export const EmptyTimelineElement = ({
  type,
  eventStartDate,
  eventEndDate,
  isClosedCycle,
  isManualCycle,
}: EmptyTimelineElementProps) => {
  if (type && ['review', 'calibration'].includes(type)) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar useIcon="16/SandWatch" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {isClosedCycle ? (
              <Text whiteSpace="nowrap" variant="emphasis1">
                {type === 'review' ? 'Performance review' : 'Calibration'} has no data
              </Text>
            ) : (
              <Text whiteSpace="nowrap" variant="emphasis1">
                {type === 'review' ? 'Performance review' : 'Calibration'} has no reviews
                yet
              </Text>
            )}
          </Item.Title>
          <Item.Description>
            {eventStartDate && !isClosedCycle && !isManualCycle && (
              <Flex alignItems="center" mt="s-8" gap="s-4">
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  Start date: {formatDate(eventStartDate, 'dd MMM yyyy')}
                </Text>
              </Flex>
            )}
            {eventEndDate && !isClosedCycle && !isManualCycle && (
              <Flex alignItems="center" mt="s-8" gap="s-4">
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  End date: {formatDate(eventEndDate, 'dd MMM yyyy')}
                </Text>
              </Flex>
            )}
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }

  return (
    <Flex flex="1" justifyContent="space-between">
      <Flex gap="s-8">
        <Image
          size={50}
          image={{
            default: `https://assets.revolut.com/assets/3d-images-v2/3D257.png`,
            '2x': `https://assets.revolut.com/assets/3d-images-v2/3D257@2x.png`,
            '3x': `https://assets.revolut.com/assets/3d-images-v2/3D257@3x.png`,
          }}
        />
        <Flex gap="s-2" flexDirection="column">
          <Text whiteSpace="nowrap" variant="emphasis1">
            Something went wrong
          </Text>
          <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
            We don’t have data for this event
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const PerfomanceStepDetails = ({
  type,
  actionsElements,
  reviewsElements,
  finalGrade,
  publishedResults,
  isEmptyReviewers,
  eventStartDate,
  eventEndDate,
  isClosedCycle,
  isManualCycle,
  canChangeGradePublishing,
  employee,
  selectedCycleId,
}: Props) => {
  const { data: gradeVisibility, refetch } = useGetEmployeeGradeVisibility({
    employeeId: employee?.id,
    cycleId: selectedCycleId,
    enabled: canChangeGradePublishing,
  })
  const { mutateAsync: updateGradeVisibility, isLoading } =
    useUpdateEmployeeGradeVisibility()
  const confirmationPopup = useConfirmationDialog()
  const renderContent = () => {
    if (type === 'review_results') {
      return (
        <Item p="s-4">
          <Item.Avatar>
            <Avatar
              bg={finalGrade ? Token.color.success : undefined}
              color={finalGrade ? Token.color.white : undefined}
              useIcon={finalGrade ? 'Trophy' : '16/SandWatch'}
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              {finalGrade
                ? finalGrade.label
                : isClosedCycle
                ? 'There is no grade for this cycle'
                : 'Grade not yet published'}
            </Item.Title>
            <Item.Description>
              <VStack>
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  {finalGrade && `Achieved grade - ${finalGrade.label}`}
                </Text>
                {!isClosedCycle &&
                  !isManualCycle &&
                  publishedResults.map(result => {
                    return (
                      <Flex alignItems="center" mt="s-8" gap="s-4" key={result.title}>
                        <Icon
                          name={
                            isAfter(new Date(), new Date(result.start_period))
                              ? '16/CheckCircle'
                              : '16/SandWatch'
                          }
                          size={16}
                        />
                        <Text
                          color={Token.color.greyTone50}
                          whiteSpace="nowrap"
                          variant="body2"
                        >
                          {result.title}: {formatDate(result.start_period, 'dd MMM yyyy')}
                        </Text>
                      </Flex>
                    )
                  })}
              </VStack>
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Flex gap="s-4" flexDirection="column" justifyContent="start" flex={1}>
              {((finalGrade && !canChangeGradePublishing) ||
                (finalGrade &&
                  canChangeGradePublishing &&
                  gradeVisibility?.is_visible)) && (
                <Text variant="body1" color={Token.color.success}>
                  Completed
                </Text>
              )}
              {canChangeGradePublishing && !gradeVisibility?.is_visible && (
                <ActionButton
                  size="sm"
                  onClick={() =>
                    confirmationPopup.show({
                      label: `Publish results to ${employee?.first_name} ${employee?.last_name}`,
                      yesMessage: 'Yes, publish',
                      noMessage: 'Cancel',
                      body: `Are you sure you want to publish the result of the performance review? ${employee?.first_name} will be informed about their grade immediately.`,
                      onConfirm: async () => {
                        if (employee?.id && selectedCycleId) {
                          await updateGradeVisibility([
                            { employeeId: employee.id, cycleId: selectedCycleId },
                            { is_visible: true },
                          ])
                          refetch()
                        }
                      },
                      loading: isLoading,
                    })
                  }
                >
                  Publish now
                </ActionButton>
              )}
              {canChangeGradePublishing && gradeVisibility?.is_visible && (
                <TextButton
                  onClick={() => {
                    confirmationPopup.show({
                      label: 'Unpublish results',
                      yesMessage: 'Yes, unpublish',
                      noMessage: 'Cancel',
                      body: 'Are you sure you want to unpublish the result of the performance review?',
                      onConfirm: async () => {
                        if (employee?.id && selectedCycleId) {
                          await updateGradeVisibility([
                            { employeeId: employee.id, cycleId: selectedCycleId },
                            { is_visible: false },
                          ])
                          refetch()
                        }
                      },
                      loading: isLoading,
                    })
                  }}
                >
                  <HStack gap="s-4" align="center">
                    <Icon name="Pencil" size={20} />
                    <Text variant="body2">Unpublish</Text>
                  </HStack>
                </TextButton>
              )}
            </Flex>
          </Item.Side>
        </Item>
      )
    }
    if (type && ['kpi', 'goal', 'review', 'calibration'].includes(type)) {
      if (isEmptyReviewers) {
        return (
          reviewsElements || (
            <EmptyTimelineElement
              isManualCycle={isManualCycle}
              isClosedCycle={isClosedCycle}
              type={type}
              eventStartDate={eventStartDate}
              eventEndDate={eventEndDate}
            />
          )
        )
      }
      return reviewsElements
    }
    return (
      <Item p="s-4">
        <Item.Avatar>
          <Avatar useIcon="Megaphone" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Nominate for promotion</Item.Title>
          <Item.Description>
            Line managers and Functional managers can nominate
          </Item.Description>
        </Item.Content>
        <Item.Side>{actionsElements}</Item.Side>
      </Item>
    )
  }

  return <Group style={transparentThemeBackgroundOverrides}>{renderContent()}</Group>
}
