import React from 'react'
import { Avatar, IconButton, IconName, Item, TextButton, Token } from '@revolut/ui-kit'

import {
  AttachedTemplateInterface,
  DocumentsTemplateTypeId,
} from '@src/interfaces/documentsTemplates'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

type Props = {
  template: AttachedTemplateInterface
  handleAdd?: (template: AttachedTemplateInterface) => void
  handleDelete?: () => void
  canPreview?: boolean
}
export const TemplateItem = ({
  template,
  handleAdd,
  handleDelete,
  canPreview,
}: Props) => {
  const typeToIcon: Record<DocumentsTemplateTypeId, IconName> = {
    esignature: 'Pencil',
    generate: 'Lightning',
    request: 'Document',
  }
  const itemProps = canPreview
    ? {
        use: InternalLink,
        target: '_blank',
        to: pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, { id: template.id }),
      }
    : {}

  return (
    <Item {...itemProps} style={transparentThemeBackgroundOverrides}>
      <Item.Avatar>
        <Avatar useIcon={typeToIcon[template.template_type.id]} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{template.name}</Item.Title>
        <Item.Description>{template.template_type.name} template</Item.Description>
      </Item.Content>
      <Item.Side>
        {handleAdd && (
          <TextButton
            aria-label={`Add template ${template.name}`}
            onClick={() => handleAdd(template)}
          >
            Add
          </TextButton>
        )}
        {handleDelete && (
          <IconButton
            aria-label={`Delete template ${template.name}`}
            useIcon="Delete"
            color={Token.color.greyTone20}
            onClick={e => {
              e.preventDefault()
              handleDelete()
            }}
          />
        )}
      </Item.Side>
    </Item>
  )
}
