import React from 'react'
import { Box, Color, Flex, Text, Token } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { getColorByIndex } from '@src/pages/Forms/QueryForm/components/Charts/helpers'

interface Props<T extends string> {
  dataKeys: BaseOption<T>[]
  colors?: Color[]
}

export const Legend = <T extends string>({ dataKeys, colors }: Props<T>) => (
  <Flex columnGap="s-12" rowGap="s-2" justifyContent="center" flexWrap="wrap">
    {dataKeys.map(({ id, name }, index) => (
      <Flex key={id} alignItems="center" gap="s-4">
        <Box
          bg={getColorByIndex(index, colors)}
          width="s-12"
          height="s-12"
          border={`1px solid ${Token.color.greyTone10}`}
          borderRadius={Token.radius.r2}
        />
        <Text fontSize="small" color={Token.color.greyTone50} use="div">
          {name}
        </Text>
      </Flex>
    ))}
  </Flex>
)
