import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { EmployeeButtonProps } from '@src/pages/EmployeeProfile/Preview/components/Buttons/types'
import { pathToUrl } from '@src/utils/router'
import { useCanViewLinkedAccounts } from '@src/pages/EmployeeProfile/helpers'

export const LinkedAccounts = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const { canViewLinkedAccountsPage } = useCanViewLinkedAccounts({ employee: data })

  if (!canViewLinkedAccountsPage) {
    return null
  }

  const actionProps = {
    use: InternalLink,
    useIcon: <Icon name="16/Link" size={16} />,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS, { id: String(data.id) }),
    children: 'Linked accounts',
  }

  return isDropdownItem ? (
    <Dropdown.Item {...actionProps} />
  ) : (
    <MoreBar.Action {...actionProps} />
  )
}
