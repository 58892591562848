import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import React from 'react'
import { Box } from '@revolut/ui-kit'
import { SummaryGoalsCollapsable } from './SummaryGoalsCollapsable'
import { NoReviewsGoalsCollapsable } from '../Common/NoReviewsGoalsCollapsable'
import { NoReviewsDeliverablesCollapsable } from '../Common/NoReviewsDeliverablesCollapsable'
import { CalculatedDeliverablesRating } from './useCalculatedDeliverablesRating'
import { useGetPeriodTypes } from '@src/utils/performance'
import { SummaryPipGoalsCollapsable } from './SummaryPipGoalsCollapsable'

interface Props {
  selectedPeriod: PerformanceSelector
  isSummaryReview?: boolean
  employee: Pick<EmployeeInterface, 'id'>
  lineManagerId?: number
  employeeStats?: Pick<EmployeeStats, 'kpi_performance_percent'>
  reviewSummary?: Pick<
    ReviewSummaryInterface,
    'team' | 'grade_label_mapping' | 'reviewed_employee_type'
  >
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
}

export const GoalsSummaryCollapsableTable = ({
  selectedPeriod,
  isSummaryReview,
  employee,
  employeeStats,
  reviewSummary,
  lineManagerId,
  calculatedDeliverablesRating,
}: Props) => {
  const { isPIPv2 } = useGetPeriodTypes(selectedPeriod)

  if (isPIPv2) {
    return (
      <SummaryPipGoalsCollapsable
        isSummaryReview={isSummaryReview}
        calculatedDeliverablesRating={calculatedDeliverablesRating}
        employeeId={employee.id}
        selectedCycle={selectedPeriod}
      />
    )
  }

  if (
    (selectedPeriod.category === ReviewCycleCategory.Probation &&
      selectedPeriod.probation_reviews_deliverables_type === 'jira') ||
    selectedPeriod.category === ReviewCycleCategory.PIP
  ) {
    return (
      <SummaryGoalsCollapsable
        isSummaryReview={isSummaryReview}
        calculatedDeliverablesRating={calculatedDeliverablesRating}
        lineManagerId={lineManagerId}
        data={employee}
        selectedPeriod={selectedPeriod}
      />
    )
  }

  if (
    selectedPeriod.category === ReviewCycleCategory.Performance ||
    selectedPeriod.category === ReviewCycleCategory.Probation
  ) {
    return (
      <Box mt="s-16">
        <NoReviewsGoalsCollapsable
          calculatedDeliverablesRating={calculatedDeliverablesRating}
          summary={reviewSummary}
          employee={employee}
          lineManagerId={lineManagerId}
          selectedPeriod={selectedPeriod}
        />
      </Box>
    )
  }

  return (
    <NoReviewsDeliverablesCollapsable
      calculatedDeliverablesRating={calculatedDeliverablesRating}
      data={employee}
      category={selectedPeriod.category}
      stats={employeeStats}
      selectedPeriod={selectedPeriod}
    />
  )
}
