import { IconButton, Item, Link, Token } from '@revolut/ui-kit'
import { ResourceAvatar } from '@src/features/Skills/components/ResourceAvatar'
import { SkillResource } from '@src/interfaces/skills'
import React from 'react'

interface Props {
  resource: SkillResource
}

export const ResourcePreview = ({ resource }: Props) => {
  return (
    <Item style={{ border: `1px solid ${Token.color.greyTone2_10}` }}>
      <Item.Avatar>
        <ResourceAvatar resource={resource} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{resource.title}</Item.Title>
        <Item.Description>{resource.description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <IconButton
          href={resource.url}
          target="_blank"
          rel="noopener noreferrer"
          use={Link}
          useIcon="LinkExternal"
        />
      </Item.Side>
    </Item>
  )
}
