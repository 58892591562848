import React, { useState } from 'react'
import { ActionButton, DragAndDrop, VStack } from '@revolut/ui-kit'
import {
  JobPostingInterface,
  JobPostingSectionInterface,
} from '@src/interfaces/jobPosting'
import { DescriptionSection } from '@src/features/JobPostingFlow/Details/DescriptionSection'
import { move } from '@src/utils/move'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { generateRandomId } from '@src/utils/numbers'

export const DescriptionSections = () => {
  const { values, errors } = useLapeContext<JobPostingInterface>()
  const sections = values.sections
  const currentSections = sections || []
  const [activeSection, setActiveSection] = useState<JobPostingSectionInterface | null>(
    null,
  )

  const handleAdd = () => {
    const newSections = [
      ...currentSections,
      {
        content: '',
        title: '',
        sortId: generateRandomId(),
      },
    ]
    values.sections = newSections
  }

  const handleDelete = (sectionSortId: number) => {
    if (sectionSortId !== null) {
      const sectionIndex = currentSections.findIndex(
        ({ sortId }) => sectionSortId === sortId,
      )

      const newSections = currentSections.filter(({ sortId }) => sectionSortId !== sortId)
      values.sections = newSections

      if (errors.sections) {
        errors.sections = errors.sections.filter(
          (_, errorIndex) => errorIndex !== sectionIndex,
        )
      }
    }
  }

  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      values.sections = move(currentSections, startIndex, endIndex)
      if (errors.sections) {
        errors.sections = move(errors.sections, startIndex, endIndex)
      }
    }
    setActiveSection(null)
  }

  return (
    <VStack gap="s-16">
      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveSection(
            currentSections.find(({ sortId }) => sortId === Number(event.active.id)) ||
              null,
          )
        }}
        onDragEnd={event => {
          if (event.over) {
            handleOrderChange(
              event?.active?.data?.current?.sortable.index ?? 0,
              event?.over?.data?.current?.sortable.index ?? 0,
            )
          }
        }}
        onDragCancel={() => {
          setActiveSection(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={currentSections.map(section => section.sortId)}
        >
          {sortable => {
            const sectionIndex = currentSections.findIndex(
              section => section.sortId === sortable.id,
            )
            const section = currentSections[sectionIndex]
            return (
              <DescriptionSection
                disable={currentSections.length === 1}
                section={section}
                sectionIndex={sectionIndex}
                sortable={sortable}
                onDelete={() => handleDelete(section.sortId)}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeSection && (
            <DescriptionSection
              disable={false}
              section={activeSection}
              sectionIndex={0}
              onDelete={() => {}}
              useLape={false}
            />
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
      <ActionButton useIcon="Plus" onClick={() => handleAdd()}>
        Add section
      </ActionButton>
    </VStack>
  )
}
