import React from 'react'
import { useMeetingNoteContext } from '../../context/meetingNoteContext'
import { AiGenerationStatus } from '@src/interfaces/meetings'
import { Grid } from '@revolut/ui-kit'
import { PendingAIGeneration } from '../PendingAIGeneration'
import { SummarySection } from '../SummarySection'
import { ActionPoints } from '../ActionPoints'

export const CompletedEventContent = () => {
  const {
    onSummaryChange,
    updatingSection,
    meetingNotesDetails,
    settings: { hasEditDisabled, showCompletedMeetingSection, isEnabledAI },
  } = useMeetingNoteContext()

  if (!showCompletedMeetingSection) {
    return null
  }
  return (
    <Grid columns={isEnabledAI ? 2 : 1} gap="s-16">
      {(meetingNotesDetails?.summary_status &&
        [AiGenerationStatus.IN_PROGRESS, AiGenerationStatus.NOT_STARTED].includes(
          meetingNotesDetails.summary_status,
        )) ||
      (meetingNotesDetails?.summary_status === null &&
        meetingNotesDetails.has_transcript) ? (
        <PendingAIGeneration
          variant="summary"
          genrationStatus={
            meetingNotesDetails.summary_status || AiGenerationStatus.NOT_STARTED
          }
        />
      ) : isEnabledAI ? (
        <SummarySection
          disabled={hasEditDisabled}
          id={meetingNotesDetails?.id}
          isLoading={updatingSection === 'summary'}
          onSummaryChange={onSummaryChange}
          isEnabledAI={isEnabledAI}
          summary={meetingNotesDetails?.summary}
        />
      ) : null}
      {(meetingNotesDetails?.action_items_status &&
        [AiGenerationStatus.IN_PROGRESS, AiGenerationStatus.NOT_STARTED].includes(
          meetingNotesDetails.action_items_status,
        )) ||
      (meetingNotesDetails?.action_items_status === null &&
        meetingNotesDetails.has_transcript) ? (
        <PendingAIGeneration
          variant="action_point"
          genrationStatus={
            meetingNotesDetails.action_items_status || AiGenerationStatus.NOT_STARTED
          }
        />
      ) : (
        <ActionPoints />
      )}
    </Grid>
  )
}
