import React from 'react'
import {
  Group,
  Item,
  Avatar,
  VStack,
  chain,
  Token,
  Text,
  ItemSkeleton,
} from '@revolut/ui-kit'

import { FinalGrade, ReviewCategory, ReviewerRelation } from '@src/interfaces/performance'
import { ProbationResults } from '@src/interfaces/probationReview'
import { usePipAndProbationPerformanceReview } from '@src/api/pipAndProbation'
import { Statuses } from '@src/interfaces'
import ProbationResult from '@src/components/ProbationResult/ProbationResult'

interface SummaryCheckpointResultsProps {
  employeeId: number | undefined
  pipCycleId: string | undefined
  category: ReviewCategory.PIP | ReviewCategory.PIP_V2 | ReviewCategory.Probation
}

type AllowedRelation = typeof allowedRelationList[number]

interface ReviewData {
  reviewerFullName: string | undefined
  relation: AllowedRelation
  finalGrade: { id: FinalGrade; label: string } | null
  result: ProbationResults | null
  status: Statuses | undefined
}

interface ReviewByCheckpointMap {
  [checkpointNumber: number]: { [relation in AllowedRelation]?: ReviewData }
}

const allowedRelationList = [
  ReviewerRelation.LineManager,
  ReviewerRelation.FunctionalManager,
  ReviewerRelation.Self,
] as const

const reviewerRelationLabels = {
  [ReviewerRelation.LineManager]: 'LM',
  [ReviewerRelation.FunctionalManager]: 'FM',
  [ReviewerRelation.Self]: 'Self',
} as const

const isAllowedRelation = (relation: unknown): relation is AllowedRelation =>
  typeof relation === 'string' &&
  allowedRelationList.includes(relation as AllowedRelation)

export const SummaryCheckpointResults = ({
  employeeId,
  pipCycleId,
  category,
}: SummaryCheckpointResultsProps) => {
  const { data, isLoading } = usePipAndProbationPerformanceReview({
    employeeId,
    pipCycleId,
    category,
  })

  if (isLoading) {
    return (
      <>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </>
    )
  }

  const reviewByCheckpointMap = data?.results?.reduce<ReviewByCheckpointMap>(
    (acc, review) => {
      if (!isAllowedRelation(review.reviewer_relation)) {
        return acc
      }

      const probationCheckpoint = review?.probation_checkpoint

      if (!probationCheckpoint) {
        return acc
      }

      acc[probationCheckpoint.number] ??= {}

      acc[probationCheckpoint.number][review.reviewer_relation] = {
        reviewerFullName: review.reviewer.full_name,
        relation: review.reviewer_relation,
        result: review?.result,
        finalGrade: review?.final_grade,
        status: review?.status,
      }

      return acc
    },
    {},
  )

  const checkpointNumbersSortedAsc =
    reviewByCheckpointMap &&
    (Object.keys(reviewByCheckpointMap) as unknown as number[]).sort(
      (a, b) => Number(a) - Number(b),
    )

  return (
    <VStack gap="s-16" mt="s-16">
      {checkpointNumbersSortedAsc?.map(checkpointNumber => {
        return (
          <Group key={checkpointNumber}>
            {allowedRelationList.map(relation => {
              const review = reviewByCheckpointMap?.[checkpointNumber]?.[relation]

              if (!review) {
                return null
              }

              return (
                <Item key={checkpointNumber + relation}>
                  <Item.Avatar>
                    <Avatar useIcon="Flag" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{`Checkpoint ${checkpointNumber}`}</Item.Title>
                    <Item.Description>
                      {chain(reviewerRelationLabels[relation], review?.reviewerFullName)}
                    </Item.Description>
                  </Item.Content>

                  <Item.Side>
                    <VStack>
                      {review.result && review.status && (
                        <ProbationResult result={review.result} status={review.status} />
                      )}
                      {relation !== ReviewerRelation.Self && review.finalGrade && (
                        <Text variant="body2" color={Token.color.greyTone50}>
                          {review.finalGrade.label}
                        </Text>
                      )}
                    </VStack>
                  </Item.Side>
                </Item>
              )
            })}
          </Group>
        )
      })}
    </VStack>
  )
}
