import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type DocumentsSettingsItemId =
  | 'documentCategories'
  | 'documentCollections'
  | 'documentTemplates'

export const documentsSettingsLinkConfig: SettingsLinkItemConfig<DocumentsSettingsItemId>[] =
  [
    {
      id: 'documentCollections',
      section: 'Documents settings',
      icon: 'DocumentsPair',
      title: 'Collections',
      searchTitle: 'Document Collections',
      to: ROUTES.APPS.DOCUMENTS.COLLECTIONS,
      subtitle: (
        <FormattedMessage
          id="settings.documents.collectionsSubtitle"
          defaultMessage="Define groups of documents to be requested at once"
        />
      ),
      permissions: [PermissionTypes.ViewDocumenttemplates],
      bundles: [FeatureFlags.BundleHR],
    },
    {
      id: 'documentCategories',
      section: 'Documents settings',
      icon: 'Document',
      title: 'Documents categories',
      to: ROUTES.APPS.DOCUMENTS.CATEGORIES,
      subtitle: (
        <FormattedMessage
          id="settings.documents.categoriesSubtitle"
          defaultMessage="Define the possible categories for documents"
        />
      ),
      permissions: [PermissionTypes.ViewDocumentCategories],
      bundles: [FeatureFlags.BundleHR],
    },
    {
      id: 'documentTemplates',
      section: 'Documents settings',
      icon: 'Form',
      title: 'Templates',
      searchTitle: 'Document Templates',
      to: ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST,
      subtitle: (
        <FormattedMessage
          id="settings.documents.templatesSubtitle"
          defaultMessage="Create templates for eSignature and document requests"
        />
      ),
      permissions: [PermissionTypes.ViewDocumenttemplates],
      bundles: [FeatureFlags.BundleHR],
    },
  ]
