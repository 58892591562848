import React from 'react'
import { Button, Side, Text, VStack } from '@revolut/ui-kit'
import { skillRequestsNew } from '@src/api/skills'
import SideBar from '@src/components/SideBar/SideBar'
import Form from '../Form/Form'
import { SkillInterface } from '@src/interfaces/skills'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import { useConfirmationDialog } from '../Popups/ConfirmationDialogProvider'
import { FormattedMessage } from 'react-intl'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { SkillForm } from '@src/pages/Forms/SkillForm/Edit'

interface Props {
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
}

const FormBody = ({ onClose, onAfterSubmit }: Props) => {
  const confirmationDialog = useConfirmationDialog()

  return (
    <>
      <SkillForm />
      <Side.Actions horizontal>
        <Button
          variant="secondary"
          onClick={e => {
            e.preventDefault()
            onClose()
          }}
        >
          Cancel
        </Button>
        <NewSaveButtonWithPopup<SkillInterface>
          noPopup
          useValidator
          onAfterSubmit={result => {
            confirmationDialog.show({
              label: 'Skill created',
              yesMessage: 'Close',
              noReject: true,
              body: (
                <VStack gap="s-8">
                  <Text>
                    <FormattedMessage
                      values={{ name: result.name, status: result.status }}
                      defaultMessage="Skill {name} was created with status {status}"
                      id="createNewSkillSideBar.submitConfirmation.status"
                    />
                  </Text>
                  <strong>
                    {result.status === Statuses.pending && (
                      <FormattedMessage
                        defaultMessage="Skill will not be visible until it's approved"
                        id="createNewSkillSideBar.submitConfirmation.pendingExplanation"
                      />
                    )}
                  </strong>
                  <OpenInNewTab
                    label="Open skill in new tab"
                    link={pathToFullUrlWithActiveWorkspace(ROUTES.FORMS.SKILL.PREVIEW, {
                      id: result.id,
                    })}
                  />
                </VStack>
              ),

              onConfirm: () => {
                onAfterSubmit()
              },
            })
          }}
        >
          Add skill
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}

export const CreateNewSkillSideBar = ({ onClose, onAfterSubmit }: Props) => {
  return (
    <SideBar title="Add skills" isOpen onClose={onClose}>
      <Form api={skillRequestsNew} isExistingForm={false} disableLocalStorageCaching>
        <FormBody onClose={onClose} onAfterSubmit={onAfterSubmit} />
      </Form>
    </SideBar>
  )
}
