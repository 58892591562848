import { InterviewFeedbackTranscriptContentInterface } from '@src/interfaces/interviewTool'

const isInterviewFeedbackTranscriptContent = (
  arg: unknown,
): arg is InterviewFeedbackTranscriptContentInterface[] => {
  return (
    Array.isArray(arg) &&
    arg.every(
      ({ type, content }) =>
        (type === 'speaker' || type === 'caption') && typeof content === 'string',
    )
  )
}

export const parseTranscript = (transcript: string) => {
  try {
    const parsed = JSON.parse(transcript)
    if (isInterviewFeedbackTranscriptContent(parsed)) {
      return parsed
    }
    return null
  } catch {
    return null
  }
}
