import React from 'react'
import { Flex, Box, Text, Token, FlexProps, Color } from '@revolut/ui-kit'
import { Props } from 'recharts/types/component/DefaultLegendContent'

type DataKeyType = { value: unknown; label: string }

interface ChartLegendProps extends FlexProps {
  props: Props
  dataKeys: DataKeyType[]
  withProjection?: boolean
  projectionProps?: ProjectionLegendItemProps
}

interface ProjectionLegendItemProps {
  title?: React.ReactNode
  color?: Color
  lineWidth?: number
  lineHeight?: number
  strokeDasharray?: string
}

export const ProjectionLegendItem = ({
  title = 'Projection',
  color = Token.color.greyTone50,
  lineWidth = 25,
  lineHeight = 10,
  strokeDasharray = '5,5',
}: ProjectionLegendItemProps) => {
  return (
    <Flex alignItems="center" gap="s-4">
      <svg width={lineWidth} height={lineHeight}>
        <path
          d="M 0 5 L 25 5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray={strokeDasharray}
        />
      </svg>
      <Text color={color} fontSize="small" use="div">
        {title}
      </Text>
    </Flex>
  )
}

export const DefaultLegend = ({
  props,
  dataKeys,
  withProjection,
  projectionProps,
  ...flexProps
}: ChartLegendProps) => {
  const { payload } = props

  return (
    <Flex
      columnGap="s-12"
      rowGap="s-2"
      justifyContent="end"
      flexWrap="wrap"
      {...flexProps}
    >
      {payload?.map(entry => {
        const key = dataKeys.find(
          dataKey => String(dataKey.value) === String(entry.dataKey),
        )
        if (!key) {
          return null
        }
        return (
          <Flex key={String(key.value)} alignItems="center" gap="s-4">
            <Box
              bg={entry.color}
              width="s-12"
              height="s-12"
              border={`1px solid ${Token.color.greyTone10}`}
              borderRadius={Token.radius.r2}
            />
            <Text fontSize="small" color={Token.color.greyTone50} use="div">
              {key.label}
            </Text>
          </Flex>
        )
      })}
      {withProjection && <ProjectionLegendItem {...(projectionProps ?? {})} />}
    </Flex>
  )
}
