import React, { ForwardedRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { ActionButton, Avatar, Box, Item, VStack, Widget } from '@revolut/ui-kit'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { SummaryNotes } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Notes'
import { toString } from '@src/features/AiInsights/Performance/components/Summary/formatters'

interface Props {
  pros?: string
  cons?: string
  onConfirm: (feedback: { pros?: string; cons?: string }) => void
  onReject: VoidFunction
}

export const AiFeedback = React.forwardRef(
  ({ pros, cons, onConfirm, onReject }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const handleConfirm = () => {
      onConfirm({
        pros: pros ? toString(pros) : undefined,
        cons: cons ? toString(cons) : undefined,
      })
    }

    return (
      <Box ref={ref} data-testid="performance_scorecard_ai_feedback">
        <VStack space="s-8">
          <Widget p="s-12">
            <VStack space="s-8">
              {pros && (
                <SummaryNotes
                  notes={pros}
                  label="Strengths"
                  summaryVariant="list"
                  title={
                    <FormattedMessage
                      id="one-to-one.meetings.summary.notes.strengths.title"
                      defaultMessage="Strengths"
                    />
                  }
                />
              )}
              {cons && (
                <SummaryNotes
                  notes={cons}
                  label="Weaknesses"
                  summaryVariant="list"
                  title={
                    <FormattedMessage
                      id="one-to-one.meetings.summary.notes.weaknesses.title"
                      defaultMessage="Areas for development"
                    />
                  }
                />
              )}
            </VStack>
          </Widget>
          <Item variant="nested">
            <Item.Avatar>
              <Avatar useIcon={AiIcon} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="performance.scorecard.ai.feedback.title"
                  defaultMessage="Use AI generated content as a starting point?"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="performance.scorecard.ai.feedback.description"
                  defaultMessage="This feedback is generated with AI based on your 1:1 meetings over the last performance cycle. You can change the text afterwards."
                />
              </Item.Description>
              <Item.Actions>
                <ActionButton variant="accent" useIcon="Check" onClick={handleConfirm}>
                  <FormattedMessage
                    id="performance.scorecard.ai.feedback.cta.confirm.label"
                    defaultMessage="Yes, use this"
                  />
                </ActionButton>
                <ActionButton useIcon="Cross" onClick={onReject}>
                  <FormattedMessage
                    id="performance.scorecard.ai.feedback.cta.reject.label"
                    defaultMessage="No, clear field"
                  />
                </ActionButton>
              </Item.Actions>
            </Item.Content>
          </Item>
        </VStack>
      </Box>
    )
  },
)
