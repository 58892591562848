import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { IdStatuses } from '@src/interfaces/employees'
import { IdContractTypes } from '@src/interfaces/contracts'
import { EligibleGroupFiltersInterface, EmployeeType } from '@src/interfaces/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import FiltersSidebarItem from '@components/FiltersSidebar/FiltersSidebarItem'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface Filterable<
  T extends EligibleGroupFiltersInterface = EligibleGroupFiltersInterface,
> {
  filters: T
}

export const EligibilityGroupFilters = <
  T extends EligibleGroupFiltersInterface = EligibleGroupFiltersInterface,
>() => {
  const { values } = useLapeContext<Filterable<T>>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const getStartedBeforeValue = () => {
    if (values?.filters?.joining_date_time) {
      const [, end] = values.filters.joining_date_time.split(',')
      return [{ id: end, name: end }]
    }
    return []
  }

  const getEndOfRangeValue = (
    rangeField: 'end_of_probation_date_time' | 'end_of_pip_date_time',
  ) => {
    const [afterDate, beforeDate] = values.filters[rangeField]?.split(',') || []

    return {
      before: beforeDate ? [{ id: beforeDate, name: beforeDate }] : [],
      after: afterDate ? [{ id: afterDate, name: afterDate }] : [],
    }
  }

  const setEndOfRangeValue = (
    rangeField: 'end_of_probation_date_time' | 'end_of_pip_date_time',
    before: string | undefined,
    after: string | undefined,
  ) => {
    values.filters[rangeField] =
      before || after ? `${after || ''},${before || ''}` : undefined
  }

  const setEndOfRangeBeforeValue = (
    rangeField: 'end_of_probation_date_time' | 'end_of_pip_date_time',
    before: string | undefined,
  ) => {
    const after = values.filters[rangeField]?.split(',')
      ? values.filters[rangeField]?.split(',')[0]
      : ''

    return setEndOfRangeValue(rangeField, before, after)
  }

  const setEndOfRangeAfterValue = (
    rangeField: 'end_of_probation_date_time' | 'end_of_pip_date_time',
    after: string | undefined,
  ) => {
    const before = values.filters[rangeField]?.split(',')
      ? values.filters[rangeField]?.split(',')[0]
      : ''

    return setEndOfRangeValue(rangeField, before, after)
  }

  const getProbationStatusValue = () => {
    if (values?.filters?.is_in_probation !== undefined) {
      const id = values.filters.is_in_probation ? 'true' : 'false'
      const name = values.filters.is_in_probation ? 'Yes' : 'No'
      return [{ id, name }]
    }
    return []
  }

  const getPipStatusValue = () => {
    if (values?.filters?.is_in_pip !== undefined) {
      const id = values.filters.is_in_pip ? 'true' : 'false'
      const name = values.filters.is_in_pip ? 'Yes' : 'No'
      return [{ id, name }]
    }
    return []
  }

  type entityField =
    | 'id'
    | 'team_id'
    | 'team__department_id'
    | 'specialisation_id'
    | 'position_id'
    | 'position__function_id'
  const getEntityValue = (field: entityField) => {
    return (
      values.filters?.[field]?.map(id => ({
        name: String(id),
        id,
      })) || []
    )
  }

  const onEntityValueChange = (value: FilterOption[], field: entityField) => {
    if (value) {
      values.filters[field] = value.length
        ? value.map(item => Number(item.id))
        : undefined
    }
  }

  return (
    <>
      <FiltersSidebarItem
        label="Employee name"
        icon="Profile"
        selector={selectorKeys.all_employees}
        value={getEntityValue('id')}
        onChange={value => onEntityValueChange(value, 'id')}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Teams"
        icon="People"
        selector={selectorKeys.team}
        value={getEntityValue('team_id')}
        onChange={value => onEntityValueChange(value, 'team_id')}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Departments"
        icon="Utilities"
        selector={selectorKeys.department}
        value={getEntityValue('team__department_id')}
        onChange={value => onEntityValueChange(value, 'team__department_id')}
        filterType={FilterType.selector}
      />
      {/* With specialisationsEnabled === false we still show specialisations but call them Roles on UI and hide the actual Roles filter */}
      <FiltersSidebarItem
        label={specialisationsEnabled ? 'Specialisations' : 'Roles'}
        icon="Palette"
        selector={selectorKeys.specialisations}
        value={getEntityValue('specialisation_id')}
        onChange={value => onEntityValueChange(value, 'specialisation_id')}
        filterType={FilterType.selector}
      />
      {specialisationsEnabled && (
        <FiltersSidebarItem
          label="Roles"
          icon="Puzzle"
          selector={selectorKeys.role}
          value={getEntityValue('position_id')}
          onChange={value => onEntityValueChange(value, 'position_id')}
          filterType={FilterType.selector}
        />
      )}
      <FiltersSidebarItem
        label="Functions"
        icon="Services"
        selector={selectorKeys.functions}
        value={getEntityValue('position__function_id')}
        onChange={value => onEntityValueChange(value, 'position__function_id')}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Status"
        icon="StarFilled"
        selector={selectorKeys.employee_status_pending}
        value={
          values.filters?.status?.map(id => ({
            name: String(id),
            id,
          })) || []
        }
        onChange={value => {
          if (value) {
            values.filters.status = value.length
              ? value.map(item => IdStatuses[item.id as keyof typeof IdStatuses])
              : undefined
          }
        }}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Started before"
        icon="CalendarDate"
        value={getStartedBeforeValue()}
        onChange={value => {
          if (value) {
            values.filters.joining_date_time = value.length
              ? `,${value[0].id}`
              : undefined
          }
        }}
        filterType={FilterType.singleDate}
        fitDateRangeInAnchor={false}
      />
      <FiltersSidebarItem
        label="Probation, status"
        icon="Search"
        selector={selectorKeys.yes_no_value_options}
        value={getProbationStatusValue()}
        onChange={value => {
          if (value) {
            values.filters.is_in_probation =
              value.length !== 1 ? undefined : value[0].id === 'true'
          }
        }}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Probation, end date before"
        icon="CalendarDate"
        value={getEndOfRangeValue('end_of_probation_date_time').before}
        onChange={value => {
          if (value) {
            setEndOfRangeBeforeValue(
              'end_of_probation_date_time',
              value.length ? String(value[0].id) : undefined,
            )
          }
        }}
        filterType={FilterType.singleDate}
        fitDateRangeInAnchor={false}
      />
      <FiltersSidebarItem
        label="Probation, end date after"
        icon="CalendarDate"
        value={getEndOfRangeValue('end_of_probation_date_time').after}
        onChange={value => {
          if (value) {
            setEndOfRangeAfterValue(
              'end_of_probation_date_time',
              value.length ? String(value[0].id) : undefined,
            )
          }
        }}
        filterType={FilterType.singleDate}
        fitDateRangeInAnchor={false}
      />
      <FiltersSidebarItem
        label="PIP, status"
        icon="Search"
        selector={selectorKeys.yes_no_value_options}
        value={getPipStatusValue()}
        onChange={value => {
          if (value) {
            values.filters.is_in_pip =
              value.length !== 1 ? undefined : value[0].id === 'true'
          }
        }}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="PIP, end date before"
        icon="CalendarDate"
        value={getEndOfRangeValue('end_of_pip_date_time').before}
        onChange={value => {
          if (value) {
            setEndOfRangeBeforeValue(
              'end_of_pip_date_time',
              value.length ? String(value[0].id) : undefined,
            )
          }
        }}
        filterType={FilterType.singleDate}
        fitDateRangeInAnchor={false}
      />
      <FiltersSidebarItem
        label="PIP, end date after"
        icon="CalendarDate"
        value={getEndOfRangeValue('end_of_pip_date_time').after}
        onChange={value => {
          if (value) {
            setEndOfRangeAfterValue(
              'end_of_pip_date_time',
              value.length ? String(value[0].id) : undefined,
            )
          }
        }}
        filterType={FilterType.singleDate}
        fitDateRangeInAnchor={false}
      />
      <FiltersSidebarItem
        label="Contract type"
        icon="DocumentsPair"
        selector={selectorKeys.contract_types}
        value={
          values.filters?.contract_type?.map(id => ({
            name: String(id),
            id,
          })) || []
        }
        onChange={value => {
          if (value) {
            values.filters.contract_type = value.length
              ? value.map(
                  item => IdContractTypes[item.id as keyof typeof IdContractTypes],
                )
              : undefined
          }
        }}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Employee type"
        icon="Document"
        selector={selectorKeys.employee_types}
        value={
          values.filters?.employee_type?.map(id => ({
            name: String(id),
            id,
          })) || []
        }
        onChange={value => {
          if (value) {
            values.filters.employee_type = value.length
              ? value.map(item => EmployeeType[item.id as keyof typeof EmployeeType])
              : undefined
          }
        }}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Location"
        icon="LocationPin"
        selector={selectorKeys.location}
        value={
          values.filters?.location_id?.map(id => ({
            name: String(id),
            id,
          })) || []
        }
        onChange={value => {
          if (value) {
            values.filters.location_id = value.length
              ? value.map(item => Number(item.id))
              : undefined
          }
        }}
        filterType={FilterType.selector}
      />
      <FiltersSidebarItem
        label="Entity"
        icon="Bank"
        selector={selectorKeys.entity}
        value={
          values.filters?.entity_id?.map(id => ({
            name: String(id),
            id,
          })) || []
        }
        onChange={value => {
          if (value) {
            values.filters.entity_id = value.length
              ? value.map(item => Number(item.id))
              : undefined
          }
        }}
        filterType={FilterType.selector}
      />
    </>
  )
}
