import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { RowHeight } from '@src/interfaces/table'
import { Flex, Token } from '@revolut/ui-kit'
import { rowSeparatorMinHeight } from '@components/TableV2/common'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

interface Props {
  style?: CSSProperties
  rowHeight: RowHeight
  rowWidth?: number
  separatorContent: React.ReactNode
}

const StickyContainer = styled.div<{
  rowHeight: RowHeight
}>`
  position: sticky;
  display: flex;
  left: 0px;
  min-height: ${({ rowHeight }) => `${rowSeparatorMinHeight[rowHeight]}px`};
  // this is required for sticky to work correctly in separator
  width: 0px;
  flex-wrap: nowrap;
  white-space: nowrap;
`

const Background = styled.div<{
  rowHeight: RowHeight
  rowWidth?: number
  appTheme: 'light' | 'dark'
}>`
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: ${({ appTheme }) =>
    appTheme === 'light' ? Token.color.black_10 : Token.color.white_10};
  min-height: ${({ rowHeight }) => `${rowSeparatorMinHeight[rowHeight]}px`};
  // the 4 here is for paddings and margins so that the row spacer doesn't expand beyond the table
  // causing ability to horizontally scroll on them
  width: ${({ rowWidth }) => (rowWidth ? `${rowWidth - 4}px` : 'max-content')};
`

export const RowSeparator = ({
  style = {},
  rowHeight,
  rowWidth,
  separatorContent,
}: Props) => {
  const { theme } = useAppTheme()

  return (
    <StickyContainer rowHeight={rowHeight} style={{ ...style }}>
      <Flex height={rowHeight}>{separatorContent}</Flex>
      <Background rowWidth={rowWidth} rowHeight={rowHeight} appTheme={theme} />
    </StickyContainer>
  )
}
