import React, { ReactNode } from 'react'
import { Color, IconButton, Text } from '@revolut/ui-kit'
import { Message } from '@revolut/icons'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  PerformanceTimelineInterface,
  SkillCardInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { MatrixColumnInterface } from '@components/Table/MatrixTable'
import { ratingToColor } from '@src/utils/performance'
import { selectorKeys } from '../api'
import { PerformanceRatingTitle } from '../performance'
import { getInverseColorsAndGrade } from '@src/utils/grades'
import { getPerformanceRatingColor } from './performanceSummary'
import {
  ProbationRecommendationInterface,
  QuestionType,
} from '@src/interfaces/probationReview'
import { getRoleSpecialisationTitle } from '@src/features/Roles/helpers'

export interface ProbationRecommendationData {
  text?: string
  questionType?: QuestionType
  lm?: ProbationRecommendationInterface
  fm?: ProbationRecommendationInterface
  hof?: ProbationRecommendationInterface
  lmQuestion?: ReactNode
  fmQuestion?: ReactNode
  hofQuestion?: ReactNode
}

export const performanceCriteriaColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  title: 'Criteria',
}

export const performanceRatingColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  title: 'Rating',
  background: data => {
    return ratingToColor(data.rating)
  },
}

export const performanceNotesColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  title: 'Notes',
}

export const performanceTimelineNameColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'stage',
    dataPoint: 'stage',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Cycle',
    insert: ({ data }) => {
      if (data.type === 'cycle') {
        return data.cycle.name
      }
      return data.stage
    },
  }

export const performanceGradeColumn: ColumnInterface<PerformanceTimelineInterface> = {
  type: CellTypes.insert,
  idPoint: 'grade',
  dataPoint: 'grade',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Grade',
  insert: ({ data }) => {
    const grade = getInverseColorsAndGrade(data.grade)

    if (!grade) {
      return '-'
    }

    return <Text color={grade.color}>{data.grade.label}</Text>
  },
}

export const performanceDeliverablesRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'deliverables_rating',
    dataPoint: 'deliverables_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Goals',
    insert: ({ data }) => {
      if (!data.deliverables_rating) {
        return '-'
      }
      const color = getPerformanceRatingColor(data.deliverables_rating, true)
      return <Text color={color}>{PerformanceRatingTitle[data.deliverables_rating]}</Text>
    },
  }

export const performanceSkillsRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'skills_rating',
    dataPoint: 'skills_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Skills',
    insert: ({ data }) => {
      if (!data.skills_rating) {
        return '-'
      }
      const color = getPerformanceRatingColor(data.deliverables_rating, true)
      return <Text color={color}>{PerformanceRatingTitle[data.skills_rating]}</Text>
    },
  }

export const performanceValuesRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'values_rating',
    dataPoint: 'values_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Values',
    insert: ({ data }) => {
      if (!data.values_rating) {
        return '-'
      }
      const color = getPerformanceRatingColor(data.deliverables_rating, true)
      return <Text color={color}>{PerformanceRatingTitle[data.values_rating]}</Text>
    },
  }

export const performanceTimelineSeniorityColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee_specialisation_seniority_sublevel.name',
    dataPoint: 'employee_specialisation_seniority_sublevel.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Seniority',
    insert: ({ data }) => {
      return data.employee_specialisation_seniority_sublevel?.name
        ? data.employee_specialisation_seniority_sublevel?.name
        : data.employee_seniority?.name || null
    },
  }

export const performanceTimelineSpecialisationColumn = (
  specialisationsEnabled: boolean,
): ColumnInterface<PerformanceTimelineInterface> => ({
  type: CellTypes.text,
  idPoint: 'employee_specialisation.id',
  dataPoint: 'employee_specialisation.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: getRoleSpecialisationTitle(specialisationsEnabled),
})

export const probationRecommendationQuestionColumn: ColumnInterface<ProbationRecommendationData> =
  {
    type: CellTypes.insert,
    idPoint: 'text',
    dataPoint: 'text',
    title: '',
    selectorsKey: selectorKeys.none,
    filterKey: null,
    sortKey: null,
  }

export const probationRecommendationLMColumn: ColumnInterface<ProbationRecommendationData> =
  {
    type: CellTypes.insert,
    idPoint: 'lm',
    dataPoint: 'lm',
    title: 'LM',
    selectorsKey: selectorKeys.none,
    filterKey: null,
    sortKey: null,
  }

export const probationRecommendationFMColumn: ColumnInterface<ProbationRecommendationData> =
  {
    type: CellTypes.insert,
    idPoint: 'fm',
    dataPoint: 'fm',
    title: 'FM',
    selectorsKey: selectorKeys.none,
    filterKey: null,
    sortKey: null,
  }

export const probationRecommendationHOFColumn: ColumnInterface<ProbationRecommendationData> =
  {
    type: CellTypes.insert,
    idPoint: 'hof',
    dataPoint: 'hof',
    title: 'HoF',
    selectorsKey: selectorKeys.none,
    filterKey: null,
    sortKey: null,
  }

export const performanceFeedbackColumn = (
  onMessageClick: (data: PerformanceTimelineInterface) => void,
): ColumnInterface<PerformanceTimelineInterface> => ({
  type: CellTypes.insert,
  idPoint: 'overall_feedback',
  dataPoint: 'overall_feedback',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Feedback',
  insert: ({ data }) => {
    if (data?.overall_feedback) {
      return (
        <IconButton
          aria-label="Message"
          color={Color.GREY_TONE_50}
          onClick={e => {
            e.stopPropagation()
            onMessageClick(data)
          }}
          size={16}
          useIcon={Message}
        />
      )
    }

    return null
  },
})
