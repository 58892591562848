import React from 'react'
import Form from '@src/features/Form/Form'
import { bitbucketIntegationPreferences } from '@src/api/integrations'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { ExternalLayout } from '@src/pages/Hub/Integrations/Bitbucket/External/Layout'
import { InternalLayout } from '@src/pages/Hub/Integrations/Bitbucket/Internal/Layout'

export const BitbucketIntegrationPage = () => {
  const isCommercial = useIsCommercial()

  if (isCommercial) {
    return (
      <Form api={bitbucketIntegationPreferences} forceParams={{ id: '1' }}>
        <ExternalLayout />
      </Form>
    )
  }

  return <InternalLayout />
}
