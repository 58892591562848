import React, { useState } from 'react'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { SkillResource } from '@src/interfaces/skills'
import {
  BottomSheet,
  Button,
  Header,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Item,
  Tag,
  TextArea,
  useToggle,
} from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { SkillLevels } from '@src/interfaces/roles'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { ResourceAvatar } from '@src/features/Skills/components/ResourceAvatar'

interface Props {
  resource: SkillResource
  onDelete: VoidFunction
  onEdit: (resource: SkillResource) => void
}

export const ResourceMetaData = ({ resource, onDelete, onEdit }: Props) => {
  const confimationPopup = useConfirmationDialog()
  const [isEditOpen, toggleEditPopup] = useToggle()
  const [newResourceUrl, setNewResourceUrl] = useState(resource.url)
  const [newResourceTitle, setNewResourceTitle] = useState(resource.title || '')
  const [newResourceDescription, setNewResourceDescription] = useState(
    resource.description || '',
  )
  const [newSkillLevel, setNewSkillLevel] = useState<IdAndName<SkillLevels>>(
    resource.level,
  )

  const reset = () => {
    setNewResourceUrl(resource.url)
    setNewResourceTitle(resource.title || '')
    setNewResourceDescription(resource.description || '')
    setNewSkillLevel(resource.level)
  }

  return (
    <>
      <Item>
        <Item.Avatar>
          <ResourceAvatar resource={resource} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{resource.title || `n/a`}</Item.Title>
          <Item.Description>{resource.description || `-`}</Item.Description>
          <Item.Actions>
            <Tag>Skill level: {resource.level.name}</Tag>
          </Item.Actions>
        </Item.Content>
        <Item.Side>
          <HStack gap="s-16">
            <IconButton
              useIcon="Pencil"
              onClick={toggleEditPopup.on}
              aria-label={`Edit resource ${resource.title}`}
            />
            <IconButton
              useIcon="Delete"
              aria-label={`Delete resource ${resource.title}`}
              onClick={() => {
                confimationPopup.show({
                  noMessage: 'Cancel',
                  yesMessage: 'Delete',
                  label: `Delete ${resource.title}`,
                  body: 'Are you sure, you want to delete this resource?',
                  onConfirm: onDelete,
                })
              }}
            />
          </HStack>
        </Item.Side>
      </Item>
      {isEditOpen && (
        <BottomSheet open onClose={toggleEditPopup.off}>
          <Header>
            <Header.Title>Edit resource</Header.Title>
          </Header>
          <InputGroup>
            <Input
              label="URL"
              disabled
              value={newResourceUrl}
              onChange={e => setNewResourceUrl(e.currentTarget.value)}
            />
            <Input
              label="Title"
              value={newResourceTitle}
              onChange={e => setNewResourceTitle(e.currentTarget.value)}
            />
            <TextArea
              label="Description"
              value={newResourceDescription}
              onChange={e => setNewResourceDescription(e.currentTarget.value)}
            />
            <RadioSelectInput<IdAndName<SkillLevels>>
              searchable={false}
              value={newSkillLevel}
              label="Skill level"
              onChange={val => {
                if (val) {
                  setNewSkillLevel(val)
                }
              }}
              selector={selectorKeys.skill_resource_levels}
            />
          </InputGroup>
          <BottomSheet.Actions horizontal>
            <Button
              variant="secondary"
              onClick={() => {
                reset()
                toggleEditPopup.off()
              }}
            >
              Cancel
            </Button>
            <Button
              elevated
              onClick={() => {
                onEdit({
                  url: newResourceUrl,
                  level: newSkillLevel,
                  description: newResourceDescription,
                  title: newResourceTitle,
                  favicon: resource.favicon,
                })
                toggleEditPopup.off()
              }}
            >
              Confirm
            </Button>
          </BottomSheet.Actions>
        </BottomSheet>
      )}
    </>
  )
}
