import Form from '@src/features/Form/Form'
import { docuSignPreferencesRequests } from '@src/api/integrations'
import React from 'react'
import { useSelector } from 'react-redux'
import { DocuSignForm } from '@src/pages/Hub/Integrations/DocuSign/DocuSign'
import { DocuSignOneClickForm } from '@src/pages/Hub/Integrations/DocuSign/DocuSignOneClickForm'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

const DocuSignIntegration = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const docusignOneClickEnabled = featureFlags.includes(
    FeatureFlags.DocusignOneClickEnabled,
  )

  return (
    <Form api={docuSignPreferencesRequests} forceParams={{ id: '1' }}>
      {docusignOneClickEnabled ? <DocuSignOneClickForm /> : <DocuSignForm />}
    </Form>
  )
}

export default DocuSignIntegration
