import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import { FinalGrade, FinalGradeInterface } from '@src/interfaces/performance'
import { Token } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'

export interface GradesMapInterface {
  [key: string]: string
}

export const useGetReviewGradesMap = () => {
  const { options: gradesOptions, asyncState } = useFetchOptions<IdAndName<string>>(
    selectorKeys.grades,
  )
  const gradesMap: GradesMapInterface = {}
  gradesOptions.forEach(option => {
    gradesMap[option.value.id] = option.value.name
  })

  return { gradesMap, isLoading: asyncState === 'pending' }
}

export const toGradesMap = (options: BaseOption<FinalGrade>[]) =>
  options.reduce<GradesMapInterface>((acc, grade) => {
    acc[grade.id] = grade.name
    return acc
  }, {})

export const getColorsAndGrade = (grade?: FinalGradeInterface | null) => {
  switch (grade?.id) {
    case FinalGrade.Poor:
      return {
        color: Token.color.background,
        background: Token.color.red,
        grade: grade.label,
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: Token.color.grey10,
        grade: grade.label,
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: grade.label,
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: grade.label,
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: grade.label,
      }
    default:
      return null
  }
}

export const getInverseColorsAndGrade = (grade?: FinalGradeInterface | null) => {
  switch (grade?.id) {
    case FinalGrade.Poor:
      return {
        color: Token.color.red,
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.success,
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.success,
        background: 'transparent',
        grade: grade.label,
      }
    default:
      return null
  }
}
