import React from 'react'
import {
  ActionButton,
  DragAndDrop,
  Flex,
  HStack,
  IconButton,
  VStack,
  Widget,
  Input,
} from '@revolut/ui-kit'
import { JobPostingSectionInterface } from '@src/interfaces/jobPosting'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import HTMLEditor, { DEFAULT_MODULES } from '@components/HTMLEditor/HTMLEditor'

interface DescriptionSectionProps {
  disable: boolean
  section: JobPostingSectionInterface
  sectionIndex: number
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDelete: VoidFunction
  useLape?: boolean
}

export const DescriptionSection = ({
  disable,
  section,
  sectionIndex,
  sortable,
  onDelete,
  useLape = true,
}: DescriptionSectionProps) => {
  return (
    <Widget
      aria-label={`Description widget ${sectionIndex}`}
      p="s-16"
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Flex alignItems="center" gap="s-8" mb="s-16" justifyContent="space-between">
        {disable ? (
          <IconButton useIcon="Drag" disabled />
        ) : (
          <IconButton
            useIcon="Drag"
            ref={sortable?.setNodeRef}
            {...sortable?.attributes}
            {...sortable?.listeners}
          />
        )}
        <HStack gap="s-8" align="center">
          <ActionButton
            aria-label={`Delete ${section.title}`}
            disabled={disable}
            iconOnly
            size="xs"
            useIcon="Delete"
            onClick={() => {
              onDelete()
            }}
          />
        </HStack>
      </Flex>
      <VStack gap="s-16" width="100%">
        {useLape ? (
          <>
            <LapeNewInput
              label="Section title"
              name={`sections.${sectionIndex}.title`}
              required
            />
            <LapeHTMLEditor
              addMarginToParagraphs
              height={80}
              name={`sections.${sectionIndex}.content`}
            />
          </>
        ) : (
          <>
            <Input label="Section title" value={section.title} required />
            <HTMLEditor
              addMarginToParagraphs
              height={80}
              modules={DEFAULT_MODULES}
              value={section.content}
              onChange={() => {}}
            />
          </>
        )}
      </VStack>
    </Widget>
  )
}
