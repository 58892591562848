import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, createChain, Link, MoreBar, useToggle } from '@revolut/ui-kit'
import { employeesRequests } from '@src/api/employees'
import { getPipManagersGrade } from '@src/api/pip'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCategory } from '@src/interfaces/performance'
import {
  CommitteeResultInterface,
  ProbationManagersGrade,
} from '@src/interfaces/probationReview'
import { FinalGradeToString } from '@src/interfaces/scorecard'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import PageLoading from '@components/PageLoading/PageLoading'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import Section from '@components/CommonSC/Section'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import SummarySidebar from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar/SummarySidebar'
import ProbationDecision from '@src/pages/Forms/FinalResultForm/ProbationDecision'
import SaveButton from '@src/pages/Forms/PipCommittee/SaveButton'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'

const General = () => {
  const { cycleId, employeeId } = useParams<{ cycleId: any; employeeId: any }>()
  const { values } = useLapeContext<CommitteeResultInterface>()
  const [loading, setLoading] = useState(false)
  const [employee, setEmployee] = useState<EmployeeInterface>()
  const [managersGrade, setManagersGrade] = useState<ProbationManagersGrade>()
  const [sidebarOpen, toggleSideBarOpen] = useToggle({ defaultState: true })

  const permissions = useSelector(selectPermissions)
  const { data: grades } = useGetSelectors(selectorKeys.performance_grades)

  const backUrl = pathToUrl(
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
    {
      id: employeeId,
    },
  )

  const { data: performanceSummary, isLoading: isPerformanceSummaryLoading } =
    useFetchPerformanceSummary(cycleId, employeeId, ReviewCategory.PIP_V2)

  const performanceGrades = performanceSummary?.grade_label_mapping
    ? Object.entries(performanceSummary.grade_label_mapping).map(entry => {
        const [id, name] = entry
        return { id, name }
      })
    : grades

  const canEdit = permissions?.includes(PermissionTypes.ChangePerformanceDecision)

  useEffect(() => {
    const fetchManagersGrade = async () => {
      const { data } = await getPipManagersGrade(employeeId, cycleId)
      setManagersGrade(data)
    }

    fetchManagersGrade()
  }, [employeeId])

  useEffect(() => {
    const fetchEmployee = async () => {
      setLoading(true)

      try {
        const { data } = await employeesRequests.getItem(employeeId)
        setEmployee(data)
      } finally {
        setLoading(false)
      }
    }

    fetchEmployee()
  }, [employeeId])

  useEffect(() => {
    if (employee?.seniority && !values.seniority) {
      values.seniority = employee.seniority
    }
  }, [employee])

  if (loading || isPerformanceSummaryLoading) {
    return <PageLoading />
  }

  if (!employee) {
    return null
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          <>
            Committee PIP result for{' '}
            <Link
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: employee.id,
              })}
              use={InternalLink}
              target="_blank"
            >
              {employee.display_name}
            </Link>
          </>
        }
        subtitle={
          <Link
            color="grey-tone-50"
            to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
              id: employee.specialisation?.id,
            })}
            use={InternalLink}
            target="_blank"
          >
            {createChain(' - ')(employee.specialisation?.name, employee.seniority?.name)}
          </Link>
        }
        backUrl={backUrl}
      />
      <PageBody>
        <MoreBar>
          <SwitchButton checked={sidebarOpen} onClick={toggleSideBarOpen.switch}>
            Show reviews
          </SwitchButton>
        </MoreBar>

        <Box my="s-24">
          <Section>
            <Section.Item>
              <Section.IndexedTitle>
                {`What is your overall recommendation for ${employee.first_name}'s PIP result?`}
              </Section.IndexedTitle>
              <Section.Content>
                <ProbationDecision disabled={!canEdit} isPIP />
              </Section.Content>
            </Section.Item>

            <Section.Item>
              <Section.IndexedTitle
                subtitle={`(System aggregated grade:
                    ${
                      managersGrade?.final_managers_calculated_grade
                        ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                          FinalGradeToString[
                            managersGrade.final_managers_calculated_grade
                          ]
                        : '-'
                    })`}
              >
                What is the final calibrated grade?
              </Section.IndexedTitle>
              <Section.Content>
                {performanceGrades && (
                  <LapeNewRadioButtons
                    disabled={!canEdit}
                    name="final_grade"
                    options={performanceGrades.map(item => ({
                      label: item.name,
                      value: item,
                    }))}
                    renderRadio={radio => <Box py="s-8">{radio}</Box>}
                  />
                )}
              </Section.Content>
            </Section.Item>

            <Section.Item>
              <Section.IndexedTitle>Justifcation for the result</Section.IndexedTitle>
              <Section.Content>
                <LapeNewTextArea
                  data-testid="justification"
                  disabled={!canEdit}
                  name="justification"
                  label="Enter concrete evidence to support the above choices"
                  required
                  rows={5}
                />
              </Section.Content>
            </Section.Item>
          </Section>
        </Box>
        <PageActions>
          <SaveButton
            backUrl={backUrl}
            lineManager={employee.line_manager.display_name!}
          />
        </PageActions>
      </PageBody>

      <SummarySidebar
        category={ReviewCategory.PIP_V2}
        cycleId={cycleId}
        employeeId={employee.id}
        isOpen={sidebarOpen}
        onClose={toggleSideBarOpen.off}
      />
    </PageWrapper>
  )
}

export default General
