import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { HRManagerBulkAssign } from '../ManagerBulkAssing'
import { useBulkAssignHRManager } from '@src/api/pipAndProbation'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface Props {
  open: boolean
  cycleIds: string[]
  onClose: VoidFunction
  onSuccess: VoidFunction
}

export const ProbationHRManagerBulkAssign = ({
  open,
  onClose,
  onSuccess,
  cycleIds,
}: Props) => {
  const { mutateAsync: bulkAssign, isLoading } = useBulkAssignHRManager()
  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  const onConfirm = async (hr: EmployeeOptionInterface) => {
    try {
      await bulkAssign([
        undefined,
        { category: 'probation', cycles: cycleIds, hr_manager: { id: hr.id } },
      ])
      showStatusPopup({
        title: 'HR manager assigned',
        status: 'success',
      })
      onSuccess()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to assign HR manager',
      })
    }
  }

  return (
    <HRManagerBulkAssign
      open={open}
      isLoading={isLoading}
      hrSelector={selectorKeys.probation_hr_managers}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
