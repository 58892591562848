import React, { useMemo } from 'react'
import { Icon, StatusWidget, Token, Tooltip, useTooltip, Widget } from '@revolut/ui-kit'

import { useGetSurveyOverviewTimeSeries } from '@src/api/engagement'
import Loader from '@src/components/CommonSC/Loader'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { FilterByInterface } from '@src/interfaces/data'
import { LineChart } from '@src/pages/Forms/QueryForm/components/Charts/LineChart'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { formatDate } from '@src/utils/format'

interface Props {
  surveyId: number | undefined
  scopeFilters: FilterByInterface[] | undefined
}
export const TimeSeriesPreview = ({ surveyId, scopeFilters = [] }: Props) => {
  const { theme } = useAppTheme()
  const tooltip = useTooltip()

  const {
    data: timeSeriesData = [],
    isLoading: isLoadingTimeSeriesData,
    isError: isTimeSeriesDataError,
  } = useGetSurveyOverviewTimeSeries({
    surveyId,
    filters: scopeFilters,
  })

  const lastResultsToShowNum = 4
  const lineChartProps = useMemo(
    () => ({
      dataKeys: [
        {
          id: 0,
          label: 'Score value',
          value: 'value0' as const,
          color: Token.colorChannel.blue,
        },
      ],
      data: isTimeSeriesDataError
        ? []
        : timeSeriesData
            .filter(datum =>
              Boolean(datum.average_score != null && datum.from_date && datum.to_date),
            )
            .map(datum => ({
              id: formatDate(datum.to_date),
              label: formatDate(datum.to_date),
              value0: datum.average_score,
            }))
            .slice(-lastResultsToShowNum),
    }),
    [timeSeriesData, isTimeSeriesDataError],
  )

  return (
    <>
      <Icon
        style={{ cursor: 'pointer' }}
        {...tooltip.getAnchorProps()}
        color={Token.color.greyTone20}
        size={18}
        name="BarChart"
      />
      <Tooltip
        p="s-16"
        style={theme === 'light' ? transparentThemeBackgroundOverrides : undefined}
        {...tooltip.getTargetProps()}
      >
        {isLoadingTimeSeriesData && !isTimeSeriesDataError ? (
          <Loader />
        ) : isTimeSeriesDataError ? (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-business/3D018.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-business/3D018@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-business/3D018@3x.png',
              }}
            />
            <StatusWidget.Title>Could not load scores history data</StatusWidget.Title>
            <StatusWidget.Description>
              Please refresh the page or contact your system administrator if the issue
              persists
            </StatusWidget.Description>
          </StatusWidget>
        ) : (
          <Widget height={300} width={500} pr="s-12" pl="s-4" py="s-4">
            <LineChart {...lineChartProps} />
          </Widget>
        )}
      </Tooltip>
    </>
  )
}
