import { useCallback, useMemo, useState } from 'react'
import { DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core'

interface Options {
  onAfterDragEnd: (startIndex: number, endIndex: number) => void
}

interface Props<T> {
  activeItemId: T | undefined
  onDragStart: (event: DragStartEvent) => void
  onDragEnd: (event: DragEndEvent) => void
  onDragCancel: VoidFunction
}

export const useDNDSourceItem = <T extends UniqueIdentifier>({
  onAfterDragEnd,
}: Options): Props<T> => {
  const [activeItemId, setActiveItemId] = useState<T>()

  const onDragStart = useCallback(event => setActiveItemId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveItemId(undefined), [])

  const onDragEnd = useCallback(
    event => {
      if (event.over) {
        const startIndex = event.active.data.current.sortable.index
        const endIndex = event.over.data.current.sortable.index

        if (startIndex !== endIndex) {
          onAfterDragEnd(startIndex, endIndex)
        }
      }

      setActiveItemId(undefined)
    },
    [onAfterDragEnd],
  )

  return useMemo<Props<T>>(
    () => ({
      activeItemId,
      onDragStart,
      onDragEnd,
      onDragCancel,
    }),
    [activeItemId, onDragStart, onDragEnd, onDragCancel],
  )
}
