import { IconName } from '@revolut/ui-kit'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'

export const entityTypeToNameMap: Record<ScopeFilterKey, string> = {
  department: 'Department',
  function: 'Function',
  role: 'Role',
  specialisation: 'Specialisation',
  team: 'Team',
  line_manager: 'Line Manager',
  location: 'Location',
  country: 'Country',
  company: 'Company',
}

export const entityTypeToIconNameMap: Record<ScopeFilterKey, IconName> = {
  department: 'Bank',
  company: 'Bank',
  function: 'RepairTool',
  role: 'RepairTool',
  specialisation: 'Controls',
  team: 'People',
  line_manager: 'Profile',
  location: 'LocationPin',
  country: 'Globe',
}
