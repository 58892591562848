import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HStack, Icon, TextButton } from '@revolut/ui-kit'
import { useGetSkill } from '@src/api/skills'
import { SkillResource } from '@src/interfaces/skills'

export interface GuidelinesProps {
  title: string
  playbooksUrls: string[]
  resources: SkillResource[]
}

interface Props {
  id: number
  onClick: (props: GuidelinesProps) => void
}

export const GuidelinesButton = ({ id, onClick }: Props) => {
  const { data: skill } = useGetSkill(id)

  const hasDetails =
    skill &&
    ((skill.resources ?? []).length > 0 || (skill.playbook_urls ?? []).length > 0)

  if (!hasDetails) {
    return null
  }

  return (
    <TextButton
      onClick={() =>
        onClick({
          title: skill.name,
          playbooksUrls: skill.playbook_urls ?? [],
          resources: skill.resources ?? [],
        })
      }
    >
      <HStack space="s-8" align="center">
        <FormattedMessage
          id="scorecard.skills.guidelines.cta.label"
          defaultMessage="Skill guidelines"
        />
        <Icon name="InfoOutline" size={16} />
      </HStack>
    </TextButton>
  )
}
