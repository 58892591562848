import { EngagementOutlierInterface } from '@src/interfaces/engagement'
import { Avatar, HStack, Item, Widget } from '@revolut/ui-kit'
import React from 'react'
import { entityTypeToIconNameMap, entityTypeToNameMap } from './constants'
import { InsightScore } from '../InsightScore'

interface Props {
  outlier: EngagementOutlierInterface
}

export const OutlierItem = ({ outlier }: Props) => {
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon={entityTypeToIconNameMap[outlier.entity_type]} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <HStack gap="s-6" align="center">
              {outlier.entity_name}
            </HStack>
          </Item.Title>
          <Item.Description>{entityTypeToNameMap[outlier.entity_type]}</Item.Description>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <InsightScore scoreVariant="average" avgScore={outlier.average_score} />
          </Item.Value>
        </Item.Side>
      </Item>
    </Widget>
  )
}
