import React from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Box, DetailsCellSkeleton, ItemSkeleton, useToggle } from '@revolut/ui-kit'

import {
  useGetEngagementSurvey,
  useRefetchEngagementSurveyRounds,
  useUpdateEngagementSurvey,
} from '@src/api/engagement'
import { mapSurveyRoundStatusToColorV2 } from '@src/apps/People/Engagement/helpers'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { API } from '@src/constants/api'
import { SURVEYS_DEFAULT_ICON } from '@src/constants/common'
import { ROUTES } from '@src/constants/routes'
import { useIsEngagementLayoutV2 } from '@src/features/Engagement/hooks/permissions'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PermissionTypes } from '@src/store/auth/types'
import { useSubtabs } from '../hooks/useSubtabs'
import {
  useSurveyRoundSelector,
  UseSurveyRoundSelectorReturnType,
} from './Rounds/Selector/hooks/useSurveyRoundSelector'
import { TimelineFilterInterface } from './Rounds/Selector/types'
import { SurveySubheader } from './Subheader'
import { SurveyActions } from './SurveyActions'
import { EngagementInsightsWidget } from './SurveyResults/InsightsWidget'
import { useTimelineFilter } from './SurveyResults/ResultsWidget/TimelineFilter/useTimelineFilter'
import { useHeatmapFilters } from './SurveyResults/ResultsWidget/hooks/useHeatmapFilters'
import { ShareResultsSidebar } from './SurveyResults/ShareResultsSidebar'

interface Props {
  surveyId: number
  surveyData: ReturnType<typeof useGetEngagementSurvey>
  subheaderRoundData?: UseSurveyRoundSelectorReturnType
  timelineFilter?: TimelineFilterInterface
}
const SurveyDetailInner = ({
  surveyId,
  surveyData,
  subheaderRoundData,
  timelineFilter: timelineFilterFromProps,
}: Props) => {
  const [shareResultsSidebarOpen, toggleShareResultsSidebarOpen] = useToggle()

  const { data: survey, isLoading: isLoadingSurvey, refetch: refetchSurvey } = surveyData

  const { refetchEngagementSurveyRounds } = useRefetchEngagementSurveyRounds(surveyId)
  const heatmapFilters = useHeatmapFilters(surveyId)
  const timelineFilter = (subheaderRoundData ||
    timelineFilterFromProps) as TimelineFilterInterface

  const canEdit = !!survey?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEngagementSurvey,
  )

  const refetchSurveyData = () => {
    refetchSurvey()
    refetchEngagementSurveyRounds()
  }

  const subtabs = useSubtabs({
    id: surveyId,
    entityPermissions: survey?.field_options.permissions,
  })
  const isOverviewTab = !!useRouteMatch(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW)
  const selectedRoundId = subheaderRoundData?.round.value?.id

  return (
    <PageWrapper>
      <PageHeader
        title={
          survey && (
            <PageHeader.Title
              title={survey.title || 'Engagement survey'}
              avatar={
                <EntityAvatar
                  data={survey}
                  defaultIcon={SURVEYS_DEFAULT_ICON}
                  canEdit={canEdit}
                  api={useUpdateEngagementSurvey}
                  apiUrl={API.ENGAGEMENT_SURVEYS}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  {survey.round_status && (
                    <PageHeader.Label
                      useTag
                      color={mapSurveyRoundStatusToColorV2(survey)}
                    >
                      {survey.round_status.name}
                    </PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
              actions={
                <SurveyActions
                  survey={survey}
                  refetch={refetchSurveyData}
                  onShareResults={toggleShareResultsSidebarOpen.switch}
                />
              }
            />
          )
        }
        backUrl={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD}
        isLoading={isLoadingSurvey}
        loader={
          <Box my="s-16">
            <ItemSkeleton />
          </Box>
        }
      />
      <PageBody maxWidth={undefined} rowGap="s-16">
        {subheaderRoundData && <SurveySubheader roundSelectorData={subheaderRoundData} />}
        <TabBarNavigation tabs={subtabs} />
        {isOverviewTab && !!selectedRoundId && (
          <EngagementInsightsWidget
            surveyId={surveyId}
            insightType="survey"
            surveyRoundId={selectedRoundId}
            datesRange={{
              dateFrom: timelineFilter.dateFrom,
              dateTo: timelineFilter.dateTo,
            }}
          />
        )}

        {isLoadingSurvey ? (
          <DetailsCellSkeleton />
        ) : survey ? (
          <Switch>
            {subtabs.map(tab => (
              <Route path={tab.path} key={tab.title}>
                <tab.component
                  survey={survey}
                  refetchSurvey={refetchSurveyData}
                  timelineFilter={timelineFilter}
                  heatmapFilters={heatmapFilters}
                />
              </Route>
            ))}
          </Switch>
        ) : null}
      </PageBody>
      {survey?.id && (
        <ShareResultsSidebar
          surveyId={survey.id}
          isOpen={shareResultsSidebarOpen}
          onClose={toggleShareResultsSidebarOpen.off}
        />
      )}
    </PageWrapper>
  )
}

const SurveyDetailWithoutSubheader = ({ surveyId, surveyData, ...restProps }: Props) => {
  const timelineFilter = useTimelineFilter(surveyId)

  return (
    <SurveyDetailInner
      surveyId={surveyId}
      surveyData={surveyData}
      timelineFilter={timelineFilter}
      {...restProps}
    />
  )
}

const SurveyDetailWithSubheader = ({ surveyId, surveyData, ...restProps }: Props) => {
  const { data: survey } = surveyData
  const subheaderRoundData = useSurveyRoundSelector({
    forcedSurvey: survey?.id ? { id: survey.id, name: survey.name } : undefined,
    includeAllStatuses: true,
    enabled: !!survey,
  })
  return (
    <SurveyDetailInner
      surveyId={surveyId}
      surveyData={surveyData}
      subheaderRoundData={subheaderRoundData}
      {...restProps}
    />
  )
}

export const SurveyDetail = () => {
  const { id: surveyId } = useParams<{ id: string | undefined }>()

  const surveyData = useGetEngagementSurvey(surveyId)
  const isLayoutV2 = useIsEngagementLayoutV2({ isOrganisationCompanySubtab: false })

  if (surveyId === undefined || (!surveyData.isLoading && !surveyData.data)) {
    return <Redirect to={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD} />
  }
  const innerProps = { surveyId: Number(surveyId), surveyData }

  if (isLayoutV2) {
    return <SurveyDetailWithSubheader {...innerProps} />
  }
  return <SurveyDetailWithoutSubheader {...innerProps} />
}
