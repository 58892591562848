import React from 'react'
import pluralize from 'pluralize'
import {
  Avatar,
  Color,
  Flex,
  Group,
  IconButton,
  Item,
  Link,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { Document, LinkExternal } from '@revolut/icons'

import { FormPreview } from '@components/FormPreview/FormPreview'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  useGetContractsSettings,
  useGetEmployeeSettings,
  useGetOrganisationSettings,
  useGetPayrollSettings,
} from '@src/api/settings'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'

export const ContractPreviewFields = ({
  contract,
  employeeId,
}: {
  contract: EmployeeContractsInterface
  employeeId: string
}) => {
  const { data: settings } = useGetEmployeeSettings()
  const { data: payrollSettings } = useGetPayrollSettings()
  const { data: contractsSettings } = useGetContractsSettings()
  const { data: orgSettings } = useGetOrganisationSettings()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  return (
    <VStack space="s-16">
      <FormPreview data={contract} title="General information">
        <Group>
          <FormPreview.Item required title="Contract type" field="contract_type.name" />
          <FormPreview.Item
            required
            type="capitalized"
            title="Employee type"
            field="employee.employee_type"
          />
          <FormPreview.Item required title="Contract term" field="contract_term.name" />
          <FormPreview.Item
            required
            title="Contract start date"
            field="start_date"
            type="date"
          />
          <FormPreview.Item title="Contract end date" field="end_date" type="date" />
          {payrollSettings?.enabled && (
            <FormPreview.Item required title="Paygroup" field="pay_group.name" />
          )}
          <FormPreview.Item required title="Location" field="location.name" />
          <FormPreview.Item required title="Entity" field="company_entity.name" />
          <FormPreview.Item
            required
            title="Contract rank"
            field="is_primary_contract"
            insert={() => (contract.is_primary_contract ? 'Primary' : 'Secondary')}
          />
          {contractsSettings?.link_to_justification_enabled ? (
            <FormPreview.Item
              required
              title="Link to justification"
              field="link_to_justification"
              insert={() =>
                contract.link_to_justification ? (
                  <IconButton
                    size={16}
                    useIcon={() => <LinkExternal color={Color.BLUE} />}
                    use={Link}
                    href={contract.link_to_justification}
                  />
                ) : (
                  '-'
                )
              }
            />
          ) : null}
        </Group>
      </FormPreview>
      <FormPreview data={contract} title="Salary">
        <Group>
          <FormPreview.Item
            required
            title="Base salary amount"
            field="salary_amount"
            insert={() =>
              contract.annual_salary
                ? `${contract.annual_salary} ${
                    contract.salary_currency?.iso_code || ''
                  } annual`
                : '-'
            }
          />
          <FormPreview.Item
            required
            title="Payment frequency"
            field="salary_payment_frequency.name"
          />
        </Group>
      </FormPreview>
      <FormPreview data={contract} title="Bonus">
        <Group>
          <FormPreview.Item required title="Bonus type" field="sign_on_bonus_type.name" />
          <FormPreview.Item
            title="Bonus amount"
            field="sign_on_bonus_amount"
            insert={() =>
              contract.sign_on_bonus_type?.id !== 'no_bonus'
                ? `${contract.sign_on_bonus_amount || ''} ${
                    contract.sign_on_bonus_currency?.iso_code || ''
                  }`
                : '-'
            }
          />
        </Group>
      </FormPreview>
      <FormPreview data={contract} title="Role">
        <Group>
          <FormPreview.Item
            required
            title={getSpecialisationTitle(specialisationsEnabled)}
            field="specialisation.name"
          />
          <FormPreview.Item<EmployeeContractsInterface>
            required
            title="Seniority"
            field="seniority.name"
            insert={d => (
              <Flex>
                <Text>{d.seniority?.name || '-'}</Text>
                {!!orgSettings?.enable_multiple_levels_per_seniority &&
                  d.specialisation_seniority_sublevel && (
                    <Text pl="s-6">{d.specialisation_seniority_sublevel.name}</Text>
                  )}
              </Flex>
            )}
          />
          <FormPreview.Item
            required
            title="Job title"
            field="title"
            hidden={!settings?.enable_job_title}
          />
        </Group>
      </FormPreview>
      <FormPreview data={contract} title="Employment">
        <Group>
          <FormPreview.Item
            required
            title="Weekly working hours"
            field="weekly_working_hours"
          />
          <FormPreview.Item
            required
            title="Notice period"
            field="notice_period_after_probation"
            insert={() =>
              contract.notice_period_after_probation
                ? `${contract.notice_period_after_probation} ${pluralize(
                    contract.notice_period_after_probation_unit?.id || '',
                    contract.notice_period_after_probation,
                  )}`
                : '-'
            }
          />
        </Group>
      </FormPreview>
      {!!contract.uploaded_documents?.length && (
        <FormPreview data={contract} title="Documents">
          <VStack space="s-16">
            {contract.uploaded_documents.map(({ id: documentId, name }) => (
              <Item
                key={documentId}
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DOCUMENT_UPLOAD.GENERAL, {
                  employeeId,
                  id: documentId,
                })}
              >
                <Item.Avatar>
                  <Avatar useIcon={Document} />
                </Item.Avatar>
                <Item.Content>
                  <Text fontWeight={400}>{name}</Text>
                </Item.Content>
              </Item>
            ))}
          </VStack>
        </FormPreview>
      )}
    </VStack>
  )
}
