import React from 'react'
import { Button, StatusPopup, StatusPopupProps, useStatusPopup } from '@revolut/ui-kit'
import { ParsableError, parseError } from './parseError'
import { ActionErrorTitle } from './components/ActionErrorTitle'
import {
  getMessageFromApiError,
  getApiValidationErrorMessages,
} from '@src/store/notifications/actions'
import { getTechnicalErrorMessage } from '@src/features/Errors/getters'
import { TechnicalErrorMessage } from '@src/features/Errors/components/TechnicalErrorMessage'
import { ValidationErrorMessage } from './components/ValidationErrorMessage'

interface UseErrorPopupShowParams {
  error: ParsableError
  /** Fallback title in case of an unknown unhandled error. Do not use `Something went wrong`, use user friendly message instead, e.g. `Could not archive team` */
  fallbackTitle: string
  /** Show `fallbackTitle` instead of getting the title from the `error` */
  forceFallbackTitle?: boolean
  fallbackDescription?: string
  forceFallbackDescription?: boolean
  actions?: React.ReactNode
  popupProps?: Omit<StatusPopupProps, 'variant'>
  prettyPrintValidationErrors?: boolean
}

export const useErrorPopup = () => {
  const statusPopup = useStatusPopup()

  return {
    show: ({
      error,
      fallbackTitle,
      fallbackDescription,
      forceFallbackTitle,
      forceFallbackDescription,
      actions,
      popupProps,
      prettyPrintValidationErrors,
    }: UseErrorPopupShowParams) => {
      const parsedError = parseError(error)
      const apiDescription = getMessageFromApiError(error)
      const validationErrors = prettyPrintValidationErrors
        ? getApiValidationErrorMessages(error) || apiDescription
        : null

      let description = forceFallbackDescription ? fallbackDescription : apiDescription
      let technicalMessage: null | string = null

      if (parsedError.subType === 'be_error' || (!description && error?.response?.data)) {
        technicalMessage = getTechnicalErrorMessage(error)
        description = null
      }
      if (!technicalMessage && fallbackDescription) {
        description = fallbackDescription
      }

      const showNonTechnicalDescription = Boolean(
        description || (prettyPrintValidationErrors && validationErrors),
      )

      statusPopup.show(
        <StatusPopup variant="error" {...popupProps}>
          <StatusPopup.Title>
            {forceFallbackTitle ? (
              fallbackTitle
            ) : (
              <ActionErrorTitle error={parsedError} fallbackTitle={fallbackTitle} />
            )}
          </StatusPopup.Title>
          {showNonTechnicalDescription ? (
            <StatusPopup.Description>
              {prettyPrintValidationErrors ? (
                <ValidationErrorMessage errors={validationErrors} />
              ) : (
                description
              )}
            </StatusPopup.Description>
          ) : technicalMessage ? (
            <StatusPopup.Description>
              <TechnicalErrorMessage error={error} message={technicalMessage} />
            </StatusPopup.Description>
          ) : null}
          <StatusPopup.Actions>
            {actions || (
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            )}
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    },
    hide: statusPopup.hide,
  }
}
