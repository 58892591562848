import { RoleInterface } from '@src/interfaces/roles'

import { notReachable } from '@src/utils/notReachable'
import { RoleStep } from '../constants'

export const checkStepCompleted = (step: RoleStep, values: RoleInterface) => {
  if (!values.id) {
    return false
  }
  switch (step) {
    case 'competency-matrix':
      return Boolean(values.functional_competency_matrix.length)
    case 'general-info':
      return Boolean(values.id && values.name && values.role_manager && values.function)
    case 'hiring-process':
      return Boolean(
        values.hiring_process_rounds && values.hiring_process_rounds.length > 0,
      )
    default:
      throw notReachable(step)
  }
}
