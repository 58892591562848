import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { A } from '@components/CommonSC/General'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import styled from 'styled-components'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OptionInterface } from '@src/interfaces/selectors'
import { DocumentInterface } from '@src/interfaces/documents'
import { useGetEmployeeDocumentNames } from '@src/api/documents'
import { Grid } from '@src/components/CommonSC/Grid'
import { NewMultiSelectProps } from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const UploadLink = styled(A)`
  display: block;
  align-self: center;
  cursor: pointer;
`

interface Props<T> extends NewMultiSelectProps<T> {
  initialValues?: object
  employee?: Partial<EmployeeInterface>
}

const RETURN_AS_KEY = 'returned_uploaded_document'

const EmployeeDocSectionLape = <T extends { id: string | number }>({
  employee,
  initialValues,
  ...props
}: Props<T>) => {
  const { values } = useLapeContext<{
    uploaded_documents: OptionInterface[]
    [RETURN_AS_KEY]?: DocumentInterface
  }>()
  const user = useSelector(selectUser)
  const { data: documentNames } = useGetEmployeeDocumentNames({
    employeeId: employee?.id,
  })
  const openNewTab = useOpenNewTab()

  // This hack puts returned value into form, because returnAs does not support arrays
  useEffect(() => {
    if (values[RETURN_AS_KEY]) {
      if (!values.uploaded_documents) {
        values.uploaded_documents = []
      }
      values.uploaded_documents.push(values[RETURN_AS_KEY]!)
      delete values[RETURN_AS_KEY]
    }
  }, [values[RETURN_AS_KEY]])

  const employeeDocSelector = () =>
    Promise.resolve(documentNames ? documentNames.options : [])

  const handleNewRow = (e: React.MouseEvent) => {
    e.preventDefault()
    const url = pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { employeeId: employee?.id })
    workspaceLocalStorage.setItem(
      url,
      JSON.stringify({
        employee,
        issuer: { id: user.id, name: user.full_name },
        ...(initialValues || {}),
      }),
    )

    openNewTab(url)
  }

  if (!employee) {
    return null
  }

  return (
    <Grid gap={24} flow="column">
      <LapeNewMultiSelect
        name="uploaded_documents"
        placeholder="Link document"
        selector={employeeDocSelector}
        data-testid="uploaded_documents"
        {...props}
      />
      <UploadLink onClick={handleNewRow}>Upload new document</UploadLink>
    </Grid>
  )
}

export default EmployeeDocSectionLape
