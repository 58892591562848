import { AxiosPromise } from 'axios'
import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiV2, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import {
  ClickupTaskValidation,
  LinearSearchResult,
  JiraEpicInterface,
  RoadmapInterface,
  RoadmapInterfaceForm,
  UnassignedRoadmapInterface,
  NotionSearchResult,
  MondaySearchResult,
} from '../interfaces/roadmaps'
import {
  GetRequestInterface,
  Id,
  RequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
  tableRequests,
} from '../interfaces'
import { EntityTypes } from '@src/constants/api'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { useFetch, useFetchV2 } from '@src/utils/reactQuery'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ItemType } from '@src/interfaces/deliverables'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { SupportedEntityGraphPath } from '@src/api/goals'

export const RoadmapFilterKeys = {
  OwnerId: 'owner__id',
  ReviewCycleId: 'review_cycle__id',
  EmployeeId: 'employee__id',
  ReviewCycleOffset: 'review_cycle__offset',
} as const

const normalizePost = (
  data: Partial<RoadmapInterfaceForm>,
): Partial<RoadmapInterface> => {
  const mapped = { ...data }
  // these fields could be set to null by UI in order to clean up previous values
  // but API doesn't expect nulls
  const types: (keyof RoadmapInterface)[] = ['team', 'department', 'employee']
  types.forEach(type => {
    if (mapped[type] === null) {
      delete mapped[type]
    }
  })
  ;['priority', 'status'].forEach(field => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    if (mapped[field] && mapped[field].id) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      mapped[field] = mapped[field].id
    }
  })

  return mapped as Partial<RoadmapInterface>
}

export const normalizeRoadmapInterface = (
  data: RoadmapInterface,
): RoadmapInterfaceForm => {
  const mapped = { ...data }

  ;['priority', 'status'].forEach(field => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const value = mapped[field]
    if (value) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      mapped[field] = { id: value, name: value }
    }
  })

  return mapped as unknown as RoadmapInterfaceForm
}

export const roadmapsRequestsNew: RequestInterfaceNew<RoadmapInterfaceForm> = {
  get: ({ id }) =>
    api.get(`${API.ROADMAPS}/${id}`).then(resp => ({
      ...resp,
      data: normalizeRoadmapInterface(resp.data as RoadmapInterface),
    })),
  submit: data => apiWithoutHandling.post(API.ROADMAPS, normalizePost(data)),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.ROADMAPS}/${id}`, normalizePost(data)),
}

export const useGetRoadmaps = ({
  sortBy,
  filters,
  page,
  enabled,
}: FetchDataQueryInterface & { enabled?: boolean }) =>
  useFetchV2<GetRequestInterface<RoadmapInterface>>({
    url: API.ROADMAPS,
    params: { params: filterSortPageIntoQuery(sortBy, filters, page) },
    queryOptions: { enabled },
  })

export const roadmapsRequests: RequestInterface<RoadmapInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.ROADMAPS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(API.ROADMAPS_STATS, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
  getItem: async id => api.get(`${API.ROADMAPS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.ROADMAPS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.ROADMAPS}/${id}`),
  postItem: async data => api.post(API.ROADMAPS, data),
}

export const companyRoadmapsRequests: TableRequestInterface<RoadmapInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.COMPANY_ROADMAPS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(API.ROADMAPS_STATS, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
}

export const roadmapsExtraRequests: RequestInterface<RoadmapInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.ROADMAPS}/extra`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(API.ROADMAPS_STATS, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
  getItem: async id => api.get(`${API.ROADMAPS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.ROADMAPS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.ROADMAPS}/${id}`),
  postItem: async data => api.post(API.ROADMAPS, data),
}

export const getRoadmapsMain = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RoadmapInterface>> => {
  return api.get(`${API.ROADMAPS}/main`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })
}

export const useGetRoadmapByName = (name: string) => {
  return useFetch<GetRequestInterface<RoadmapInterface>>(`${API.ROADMAPS}`, 'v1', {
    params: filterSortPageIntoQuery(undefined, [
      {
        columnName: 'name',
        filters: [{ id: name, name }],
      },
    ]),
  })
}

export const useGetRoadmap = (id: number) => {
  return useFetch<RoadmapInterface>(`${API.ROADMAPS}/${id}`, 'v1')
}

export const getRoadmaps = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<RoadmapInterface[]> =>
  api.get(`${API.ROADMAPS}/all`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getEmployeeRoadmaps = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<RoadmapInterface[]> =>
  api.get(`${API.ROADMAPS}/employeeDeliverables`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const refreshRoadmap = (label: string, reviewCycle?: { id: number }) =>
  api.post(`/roadmaps/refresh`, {
    label,
    review_cycle: reviewCycle,
  })

export const getUnassignedRoadmaps = (
  entityType: EntityTypes,
  id: number | string,
  searchValue: string,
): AxiosPromise<GetRequestInterface<UnassignedRoadmapInterface>> => {
  return api.get('/unassignedRoadmaps', {
    params: {
      [`${entityType}__id`]: id,
      search: searchValue,
    },
  })
}

export const getUnassignedCompanyRoadmaps = (
  searchValue: string,
): AxiosPromise<GetRequestInterface<UnassignedRoadmapInterface>> => {
  return api.get('/unassignedRoadmaps', {
    params: {
      is_company: true,
      search: searchValue,
    },
  })
}

export const getAllUnassignedRoadmaps = (
  searchValue: string,
): AxiosPromise<GetRequestInterface<UnassignedRoadmapInterface>> => {
  return api.get('/unassignedRoadmaps', {
    params: {
      search: searchValue,
    },
  })
}

export const validateClickupTask = ({
  itemId,
}: {
  itemId: string
}): AxiosPromise<ClickupTaskValidation> => {
  return apiWithoutHandling.post('/integrations/clickup/validateItem', {
    item_type: 'task',
    item_id: itemId,
  })
}

export const searchLinear = ({
  searchQuery,
}: {
  searchQuery: string
}): AxiosPromise<LinearSearchResult[]> =>
  apiV2.get('/linearSearch', { params: { term: searchQuery } }, 'v1')

export const searchNotion = ({
  searchQuery,
}: {
  searchQuery: string
}): AxiosPromise<NotionSearchResult[]> =>
  apiV2.get('/notionSearch', { params: { term: searchQuery } }, 'v1')

export const searchMonday = ({
  searchQuery,
  board_id,
}: {
  searchQuery: string
  board_id: string
}): AxiosPromise<MondaySearchResult[]> =>
  apiV2.get<MondaySearchResult[]>(
    '/searchMonday',
    {
      params: { term: searchQuery, board_id },
    },
    'v1',
  )

export const addRoadmaps = (
  data: {
    keys: string[]
    review_cycle: number | string | undefined
    item_type: ItemType
  } & ({ entityType: EntityTypes; id: number | string } | { is_company: true }),
) => {
  return api.post('/unassignedRoadmaps', {
    keys: data.keys,
    review_cycle: { id: data.review_cycle },
    item_type: data.item_type,
    ...('is_company' in data
      ? { is_company: data.is_company }
      : { [data.entityType]: { id: data.id } }),
  })
}

export const addGoalRoadmap = (
  payload: { [key in 'team' | 'employee' | 'department']?: Id } & {
    keys: string[]
    review_cycle: ReviewCyclesInterface
    goal: Id
    is_company?: boolean
    item_type: ItemType
  },
) => {
  return api.post<RoadmapInterface>('/unassignedRoadmaps', payload)
}

export const getRoadmapChildIssues = ({ filters }: FetchDataQueryInterface) => {
  const parentFilter = filters?.find(item => item.columnName === 'parent__id')?.filters[0]
  const statusFilter = filters?.find(item => item.columnName === 'status')

  if (!parentFilter?.id) {
    return Promise.reject()
  }

  return api.get(
    `${API.ROADMAPS}/${parentFilter.id}/childIssues`,
    statusFilter
      ? {
          params: filterSortPageIntoQuery([], [statusFilter]),
        }
      : undefined,
  )
}

interface RefreshEpicsProps {
  data: {
    epics: string[]
  }
}
export const refreshEpics = ({ data }: RefreshEpicsProps) => {
  return api.post<{ task_id: string }>(`${API.ROADMAPS}/refreshEpics`, data)
}

export type RefreshEpicsStatus = 'PENDING' | 'SUCCESS' | 'FAILURE'
export const checkRefreshEpicsStatus = (taskId: string) => {
  return api.get<{ status: RefreshEpicsStatus }>(`${API.ROADMAPS}/refreshEpicsStatus`, {
    params: { task_id: taskId },
  })
}

export const linkToCycles = () => {
  return api.post(`${API.ROADMAPS}/linkToCycles`)
}

export const fetchEntityRoadmapGraph =
  (entityType: SupportedEntityGraphPath) =>
  async (
    entityId: number,
    range: PerformanceTimeRange = PerformanceTimeRange.week,
    months: string,
  ) => {
    const basePath = `/${entityType}/${entityId}`

    return apiWithoutHandling.get<PerformanceChartCycles>(
      `${basePath}/roadmapPerformance`,
      {
        params: {
          date_partitioning: range,
          months,
        },
      },
    )
  }

export const jiraEpicsContributionsTableRequests: tableRequests<
  JiraEpicInterface,
  undefined
> = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    apiV2.get<GetRequestInterface<JiraEpicInterface>>(
      `${API.ROADMAPS}/employeeContributions`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
}
