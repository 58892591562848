import React, { useMemo } from 'react'
import { AxiosError } from 'axios'

import { navigateTo } from '@src/actions/RouterActions'
import { useGetSelectorsWithFilters } from '@src/api/selectors'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { getSurveyResultsHeatmapTableRequests } from '@src/features/Engagement/api/analytics'
import {
  EngagementResultsHeatmapRowInterface,
  EngagementResultsHeatmapScoreInterface,
  ItemsToAnalyse,
} from '@src/features/Engagement/api/analytics/interfaces'
import {
  HEATMAP_ITEM_MIN_WIDTH,
  HeatMapComparisonMode,
  HeatmapTableCell,
} from '@src/features/Engagement/components/HeatmapTableCell'
import { CellTypes, ColumnCellInterface, FilterByInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'
import { MAX_ITEMS_ALLOWED_FOR_ANALYSIS, ViewMode } from '../common'

const mapScoreToTableCell = ({
  targetScore,
  isScopedView,
  comparisonMode,
}: {
  targetScore: EngagementResultsHeatmapScoreInterface
  isScopedView: boolean
  comparisonMode: HeatMapComparisonMode
}): ColumnCellInterface<
  EngagementResultsHeatmapRowInterface & { isParent?: boolean }
> => {
  return {
    type: CellTypes.insert,
    idPoint: targetScore.name,
    dataPoint: targetScore.name,
    sortKey: targetScore.name,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: targetScore.name,
    insert: ({ data }) => {
      const targetScoreData = data.scores.find(score => score.name === targetScore.name)
      return (
        <HeatmapTableCell
          data={
            targetScoreData && {
              ...targetScoreData,
              comparisonScore: isScopedView
                ? targetScoreData?.company_wide_average_score
                : targetScoreData?.benchmark_average,
            }
          }
          mode={comparisonMode}
          isParent={data.isParent}
        />
      )
    },
    width: HEATMAP_ITEM_MIN_WIDTH,
    padding: '4px',
    notHoverable: true,
  }
}

export type UseHeatmapTableReturnType = {
  itemsToAnalyse: ItemsToAnalyse
  surveyId: number
  surveyRoundId?: number
  onError: (e: AxiosError) => void
  initFilters: FilterByInterface[]
  isScopedView: boolean
  viewMode: ViewMode
  detailsPageQueryParams?: Record<string, string>
  comparisonMode?: HeatMapComparisonMode
}
export const useHeatmapTable = ({
  itemsToAnalyse,
  surveyId,
  surveyRoundId,
  onError,
  initFilters,
  isScopedView,
  viewMode,
  detailsPageQueryParams,
  comparisonMode = 'score',
}: UseHeatmapTableReturnType) => {
  const table = useTable<EngagementResultsHeatmapRowInterface>(
    getSurveyResultsHeatmapTableRequests({
      itemsToAnalyse,
      surveyId,
      surveyRoundId,
      onError,
    }),
    initFilters,
    undefined,
    { disableQuery: true, disable: viewMode !== 'heatmap' },
  )

  const { data: questionsOptions = [] } = useGetSelectorsWithFilters({
    type: selectorKeys.survey_questions,
    filters: [{ columnName: 'survey_id', filters: [toIdAndName(String(surveyId))] }],
  })
  const { data: categoriesOptions = [] } = useGetSelectorsWithFilters({
    type: selectorKeys.survey_drivers,
    filters: [{ columnName: 'survey_id', filters: [toIdAndName(String(surveyId))] }],
  })

  const row = useMemo(() => {
    const defaultTitleCell = {
      type: CellTypes.text,
      idPoint: 'id',
      dataPoint: 'name',
      sortKey: null,
      filterKey: itemsToAnalyse === 'questions' ? 'question' : 'driver',
      selectorsKey: () =>
        Promise.resolve({
          options: itemsToAnalyse === 'questions' ? questionsOptions : categoriesOptions,
        }),
      title: 'Name',
      width: 200,
    }

    if (!table.data[0]?.scores?.length) {
      return { cells: [defaultTitleCell] }
    }
    const rowData = table.data[0]
    const scoresNum = rowData.scores.length
    const maxScoreColsNum = MAX_ITEMS_ALLOWED_FOR_ANALYSIS
    const scoreColumnsNum = scoresNum <= maxScoreColsNum ? scoresNum : maxScoreColsNum
    const scoresNumToWidth = [500, 400, 300]

    const cells: Array<ColumnCellInterface<EngagementResultsHeatmapRowInterface>> = [
      {
        ...defaultTitleCell,
        width: scoresNumToWidth[scoresNum] || 200,
      },
    ]

    for (let i = 0; i < scoreColumnsNum; i++) {
      cells.push(
        mapScoreToTableCell({
          targetScore: rowData.scores[i],
          isScopedView,
          comparisonMode,
        }),
      )
    }
    return {
      cells,
      linkToForm: ({ id, isParent }: { id: number; isParent?: boolean }) => {
        if (!id || !detailsPageQueryParams) {
          return
        }
        if (
          itemsToAnalyse === 'questions' ||
          (itemsToAnalyse === 'categories' && !isParent)
        ) {
          navigateTo(
            pathToUrl(
              ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.QUESTION,
              {
                id: surveyId,
                itemId: id,
              },
              detailsPageQueryParams,
            ),
          )
        } else if (itemsToAnalyse === 'categories') {
          navigateTo(
            pathToUrl(
              ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.CATEGORY,
              {
                id: surveyId,
                itemId: id,
              },
              detailsPageQueryParams,
            ),
          )
        }
      },
    }
  }, [table.data[0], isScopedView, itemsToAnalyse, comparisonMode])

  return { row, table }
}
