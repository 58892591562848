import React, { useMemo } from 'react'
import { useTable } from '@components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { LeadershipPositionInterface, LeadershipStats } from '@src/interfaces/leadership'
import {
  getDepartmentLeadershipPositions,
  getDepartmentLeadershipStats,
} from '@src/api/leadership'
import {
  leadershipEmployeeNameColumn,
  leadershipFMColumn,
  leadershipLMColumn,
  leadershipLocationColumn,
  leadershipPositionNameColumn,
  leadershipPositionStatusColumn,
  leadershipSeniorityColumn,
  leadershipSpecialisationColumn,
} from '@src/constants/columns/leadership'
import { PermissionTypes } from '@src/store/auth/types'
import Stat from '@components/Stat/Stat'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EntityTypes } from '@src/constants/api'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { LEADERSHIP_TABLE } from '@src/constants/externalLinks'
import { formatPercentage } from '@src/utils/format'
import { IdAndName } from '@src/interfaces'
import { TableNames } from '@src/constants/table'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import useIsCommercial from '@src/hooks/useIsCommercial'
import Table from '@src/components/TableV2/Table'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface StructureProps {
  data: DepartmentInterface
}

const positionRow = (
  specialisationsEnabled: boolean,
): RowInterface<LeadershipPositionInterface> => ({
  cells: [
    {
      ...leadershipPositionNameColumn,
      width: 260,
    },
    {
      ...leadershipEmployeeNameColumn,
      width: 220,
    },
    // TODO: uncomment once BE is fixed
    // {
    //   ...leadershipGradeColumn,
    //   masked: true,
    //   width: 100,
    // },
    // {
    //   ...leadershipSalaryColumn,
    //   masked: true,
    //   width: 100,
    // },
    {
      ...leadershipSpecialisationColumn(specialisationsEnabled),
      width: 180,
    },
    {
      ...leadershipSeniorityColumn,
      width: 120,
    },
    {
      ...leadershipLMColumn,
      width: 120,
    },
    {
      ...leadershipFMColumn,
      width: 120,
    },
    {
      ...leadershipLocationColumn,
      width: 120,
    },
    {
      ...leadershipPositionStatusColumn,
      width: 100,
    },
  ],
})
export const navigateToLeadershipForm = (
  data: IdAndName,
  rowData?: LeadershipPositionInterface,
) => {
  const rowParams = rowData ? { id: rowData.id } : {}
  navigateTo(
    pathToUrl(ROUTES.FORMS.LEADERSHIP, {
      entityId: data.id,
      entityType: EntityTypes.department,
      ...rowParams,
    }),
    { initialValues: { department: { id: data.id, name: data.name } } },
  )
}

const Leadership = ({ data }: StructureProps) => {
  const isCommercial = useIsCommercial()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const memoizedPositionRow = useMemo(
    () => positionRow(specialisationsEnabled),
    [specialisationsEnabled],
  )
  const table = useTable<LeadershipPositionInterface, LeadershipStats>({
    getItems: getDepartmentLeadershipPositions(data.id),
    getStats: getDepartmentLeadershipStats(data.id),
  })
  const hasExpensesPermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeeExpenses,
  )
  const hasPerformancePermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeePerformance,
  )
  const canAssign = !!data.field_options.permissions?.includes(
    PermissionTypes.CanAssignLeadershipPositionsDepartment,
  )

  const hiddenCells: Partial<Record<keyof LeadershipPositionInterface, boolean>> = {
    latest_calibrated_grade: !hasPerformancePermission,
    annual_expense: !hasExpensesPermission,
  }

  const onRowClick = (row: LeadershipPositionInterface) => {
    if (!canAssign) {
      return
    }
    navigateToLeadershipForm(data, row)
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {hasPerformancePermission && (
          <>
            <Stat
              label="NIPS"
              val={table.stats && formatPercentage(table.stats.nips)}
              color={getPercentColor(table.stats && table.stats.nips * 100)}
            />
            <Stat
              label={isCommercial ? 'Strong, Exceptional' : 'Exceeding, Exceptional'}
              val={formatPercentage(
                table.stats?.above_expectations_and_exceptional_percentage || null,
              )}
            />
            <Stat
              label={isCommercial ? 'Avg-, Unsatisfactory' : 'Developing, Unsatisfactory'}
              val={formatPercentage(
                table.stats?.unsatisfactory_and_below_expectations_percentage || null,
              )}
              color={
                table.stats?.unsatisfactory_and_below_expectations_percentage
                  ? 'red'
                  : undefined
              }
            />
          </>
        )}
        <Stat label="Occupied positions" val={table.stats?.occupied_positions} />
        <Stat
          label="Unassigned positions"
          color={table.stats?.unassigned_positions ? 'red' : undefined}
          val={table.stats?.unassigned_positions}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAssign ? (
            <MoreBar.Action useIcon="Plus" onClick={() => navigateToLeadershipForm(data)}>
              Add new position
            </MoreBar.Action>
          ) : null}
          <HideIfCommercial>
            <MoreInfoButton href={LEADERSHIP_TABLE} />
          </HideIfCommercial>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>

      <Table.Widget.Table>
        <AdjustableTable<LeadershipPositionInterface, LeadershipStats>
          name={TableNames.DepartmentLeadership}
          useWindowScroll
          row={memoizedPositionRow}
          {...table}
          dataType="position"
          noDataMessage="No leadership positions"
          hiddenCells={hiddenCells}
          onRowClick={onRowClick}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Leadership
