import React from 'react'
import { FormattedMessage } from 'react-intl'
import { VStack, Widget, Text } from '@revolut/ui-kit'
import { GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'

import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import { HelperVideoWidget } from '../../components/HelperVideo'
import { CopyUrlToClipboard } from '../CopyUrlToClipboard'
import { IntegrationForm } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import { useLapeContext } from '@src/features/Form/LapeForm'

const MONDAY_PREVIEW_URL =
  'https://assets.revolut.com/revoluters/assets/integrations/monday_preview.png'
const MONDAY_VIDEO_URL =
  'https://assets.revolut.com/revoluters/assets/integrations/monday_integration.mov'

export const MondayIntegrationHelperContent = () => {
  const { values } = useLapeContext<IntegrationForm>()
  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Access Developer Settings"
            subtitle={
              <FormattedMessage
                id="integrations.monday.helper.stepper.1.subtitle"
                defaultMessage="Log into Monday.com, click your avatar (top right), select <T>Developers</T>, then choose or build an app."
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Verify Permissions"
            subtitle="Make sure you're an admin/owner and select the required scopes."
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Configure OAuth"
            subtitle={
              <FormattedMessage
                id="integrations.monday.helper.stepper.3.sub"
                defaultMessage="In OAuth, check <T>boards:read</T>, <T>users:read</T>, <T>workspaces:read</T>"
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Copy the URL and paste it into the Redirect URLs field."
            subtitle={
              <CopyUrlToClipboard
                url={values?.redirect_uri ?? GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM}
                successText="Callback URL copied to clipboard"
              />
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Copy App Credentials"
            subtitle={
              <FormattedMessage
                id="integrations.monday.helper.stepper.4.subtitle"
                defaultMessage="Under <T>App Credentials</T>, copy your <T>Client ID</T> and <T>Client Secret</T> to complete the integration."
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
            bulletVariant="filled"
          />
        </AutoStepper>
        <HelperVideoWidget
          preview={MONDAY_PREVIEW_URL}
          video={MONDAY_VIDEO_URL}
          poster={MONDAY_PREVIEW_URL}
        />
      </Widget>
    </VStack>
  )
}
