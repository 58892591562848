import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { StructurePreview } from './Preview'
import { StructureGeneral } from './General'
import { PageWrapper } from '@src/components/Page/Page'
import { structureRequests } from '../api'
import { ORGANISATION_STRUCTURE_DEFAULT_ICON } from '@src/constants/common'
import { StructureLevel } from '../StructureLevel'

export const Structure = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.SETTINGS.STRUCTURE.LEVELS}>
          <StructureLevel />
        </Route>
        <Route>
          <StructureForm />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

const StructureForm = connect(() => (
  <Form
    api={structureRequests}
    initialValues={{
      icon: ORGANISATION_STRUCTURE_DEFAULT_ICON,
      display_in_navigation: true,
    }}
  >
    <Switch>
      <Route exact path={ROUTES.SETTINGS.STRUCTURE.PREVIEW}>
        <StructurePreview />
      </Route>
      <Route exact path={ROUTES.SETTINGS.STRUCTURE.GENERAL}>
        <StructureGeneral />
      </Route>
    </Switch>
  </Form>
))
