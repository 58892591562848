import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'

import {
  VStack,
  Icon,
  Text,
  HStack,
  Token,
  InputGroup,
  Button,
  useToggle,
  Tooltip,
  Input,
  useTooltip,
  useDropdown,
  Dropdown,
  Skeleton,
  Placeholder,
  ActionButton,
  Spinner,
} from '@revolut/ui-kit'
import { ChildGoalsTable } from '../ChildGoalsTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalContentType, GoalsInterface } from '@src/interfaces/goals'
import { useQuery } from '@src/utils/queryParamsHooks'
import { capitalize, debounce } from 'lodash'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { toIdAndName } from '@src/utils/toIdAndName'
import { Statuses } from '@src/interfaces'
import {
  GoalFilterNames,
  GoalsInterfaceWithChildren,
  goalsListTableRequests,
  goalsRequests,
} from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { isGenericNewGoalPath } from '../../../../helpers'
import { OptionItem } from './OptionItem'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { CreateChildGoalPopup } from '../CreateChildGoalPopup'

const baseSearchGoalFilters = [
  {
    columnName: 'approval_status',
    nonResettable: true,
    filters: [
      toIdAndName(Statuses.pending),
      toIdAndName(Statuses.approved),
      toIdAndName(Statuses.requires_changes),
    ],
  },
]

const getAvailableChildrenGoalLevels = ({
  isCompany,
  contentTypeModel,
}: {
  isCompany: boolean
  contentTypeModel?: GoalContentType['model']
}): GoalContentType['model'][] => {
  if (isCompany) {
    return ['department', 'teams', 'employees']
  }
  if (contentTypeModel === 'department') {
    return ['teams', 'employees']
  }
  if (contentTypeModel === 'teams') {
    return ['employees']
  }
  return []
}

const typeContentTypeMap: Record<string, GoalContentType['model']> = {
  team: 'teams',
  department: 'department',
}

export const MetricBasedOnChildGoalWidget = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: goalContentTypes, isLoading: isGoalContentTypeLoading } =
    useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)
  const isGenericNewGoal = isGenericNewGoalPath()
  const errorPopup = useErrorPopup()

  const refreshTableRef = useRef<(() => void) | null>(null)

  const [options, setOptions] = useState<GoalsInterfaceWithChildren[]>([])
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<number[]>([])
  const [searchPending, setSearchPending] = useState(false)
  const [filterPending, setFilterPending] = useState(false)
  const [isUpdatePending, setIsUpdatePending] = useState(false)
  const [searchValue, setSearchValue] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [includeOnlyContentTypeFilter, setIncludeOnlyContentTypeFilter] = useState<
    GoalContentType['model'][]
  >([])

  const { query } = useQuery<{ type?: string }>()
  const disabledButtonTooltip = useTooltip()
  const infoTooltip = useTooltip()
  const dropdown = useDropdown()
  const [isNewGoalPopupOpen, toggleNewGoalPopupOpen] = useToggle()

  const contentType =
    isGenericNewGoal &&
    query.type &&
    ['department', 'teams', 'team'].includes(query.type) &&
    !values.content_type?.model
      ? typeContentTypeMap[query.type] || query.type
      : values.content_type?.model

  const isCompany =
    (isGenericNewGoal && query.type === 'companyV2') ||
    (values.is_company && !values.is_top_level)

  const availableContentTypes = useMemo(
    () =>
      getAvailableChildrenGoalLevels({
        isCompany,
        contentTypeModel: contentType,
      }),

    [contentType, isCompany],
  )

  useEffect(() => {
    setIncludeOnlyContentTypeFilter(availableContentTypes)
  }, [availableContentTypes.length])

  const debouncedSearch = useMemo(() => {
    setErrorMessage(undefined)
    return debounce(
      async (searchString: string, contentTypeFilters: GoalContentType['model'][]) => {
        try {
          const {
            data: { results },
          } = await goalsListTableRequests.getItems({
            filters: [
              ...baseSearchGoalFilters,
              {
                columnName: GoalFilterNames.ContentTypeId,
                filters:
                  goalContentTypes
                    ?.filter(({ model }) => contentTypeFilters.includes(model))
                    .map(({ id }) => toIdAndName(String(id))) || [],
              },
              { columnName: 'search', filters: [toIdAndName(searchString)] },
            ],
          })
          const resultsNotWithinThisGoal = results.filter(
            result => result.parent?.id !== values.id,
          )
          setOptions(resultsNotWithinThisGoal)
        } catch (e) {
          setErrorMessage(getMessageFromApiError(e) || undefined)
        } finally {
          setSearchPending(false)
          setFilterPending(false)
        }
      },
      1000,
    )
  }, [])

  const handleAddChildGoals = async () => {
    setIsUpdatePending(true)
    try {
      await goalsRequests.update(
        {
          name: values.name,
        },
        { id: String(values.id) },
      )
      const promises = selectedOptionsIds.map(optionID =>
        goalsRequests.update({ parent: values }, { id: String(optionID) }),
      )
      await Promise.all(promises)
      refreshTableRef.current?.()
      setSelectedOptionsIds([])
      setSearchValue('')
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed adding child goals',
        forceFallbackTitle: true,
      })
    } finally {
      setIsUpdatePending(false)
    }
  }

  const handleModelTypeFilter = (model: GoalContentType['model']) => {
    setIncludeOnlyContentTypeFilter(prev =>
      prev.includes(model) ? prev.filter(type => type !== model) : [...prev, model],
    )
  }

  const onSearch = (searchString?: string) => {
    setErrorMessage(undefined)
    if (searchString === searchValue) {
      return
    }
    setOptions([])
    setSearchValue(searchString)
    if (searchString) {
      setSearchPending(true)
      debouncedSearch(searchString, includeOnlyContentTypeFilter)
    }
  }

  const mainGoalHasNameAndOrgValue = Boolean(
    values.name && (values.is_company ? true : values.content_object?.id),
  )

  useEffect(() => {
    if (searchValue) {
      setFilterPending(true)
      debouncedSearch(searchValue, includeOnlyContentTypeFilter)
    }
  }, [includeOnlyContentTypeFilter])

  if (isGoalContentTypeLoading) {
    return <Skeleton width="100%" height={344} />
  }

  return (
    <VStack gap="s-16" px="s-16" pb="s-16">
      <HStack
        gap="s-12"
        align="center"
        borderRadius="r12"
        py="s-12"
        px="s-16"
        border={`${Token.color.greyTone10} solid 1px`}
      >
        <Text variant="emphasis1">Progress is calculated based on child goals</Text>
        <Icon name="InfoOutline" size={24} {...infoTooltip.getAnchorProps()} />
        <Tooltip {...infoTooltip.getTargetProps()}>
          <Text>
            The overall progress of the goal will be calculated as a sum of progresses of
            linked child goals with equal weights
          </Text>
        </Tooltip>
      </HStack>

      <InputGroup
        variant="horizontal"
        {...(!mainGoalHasNameAndOrgValue
          ? { ...disabledButtonTooltip.getAnchorProps() }
          : { ...dropdown.getAnchorProps().ref })}
      >
        <Input
          disabled={!mainGoalHasNameAndOrgValue}
          label="Search goals to add"
          useIcon="Search"
          invalid={!!errorMessage}
          errorMessage={errorMessage}
          pending={searchPending}
          value={searchValue}
          onChange={(ev: FormEvent<HTMLInputElement>) =>
            onSearch(ev?.currentTarget?.value)
          }
        />
        <Dropdown
          {...dropdown.getTargetProps().anchorRef}
          open={!!searchValue && !searchPending}
          fitInAnchor
          px="s-16"
        >
          <Dropdown.Group sticky="top">
            <HStack gap="s-16" py="s-8" align="center">
              {availableContentTypes.includes('department') && (
                <ActionButton
                  onClick={() => handleModelTypeFilter('department')}
                  variant={
                    includeOnlyContentTypeFilter.includes('department')
                      ? 'accent'
                      : 'primary'
                  }
                >
                  Department goal
                </ActionButton>
              )}
              {availableContentTypes.includes('teams') && (
                <ActionButton
                  onClick={() => handleModelTypeFilter('teams')}
                  variant={
                    includeOnlyContentTypeFilter.includes('teams') ? 'accent' : 'primary'
                  }
                >
                  Team goal
                </ActionButton>
              )}
              {availableContentTypes.includes('employees') && (
                <ActionButton
                  onClick={() => handleModelTypeFilter('employees')}
                  variant={
                    includeOnlyContentTypeFilter.includes('employees')
                      ? 'accent'
                      : 'primary'
                  }
                >
                  Employee goal
                </ActionButton>
              )}
              {filterPending && <Spinner />}
            </HStack>
          </Dropdown.Group>
          <Dropdown.Group py="s-8">
            <InputGroup>
              {options.length ? (
                options.map(option => (
                  <OptionItem
                    key={option.id}
                    option={option}
                    isSelected={selectedOptionsIds.includes(option.id)}
                    onChange={() =>
                      setSelectedOptionsIds(prev => {
                        if (prev.includes(option.id)) {
                          return prev.filter(id => id !== option.id)
                        }
                        return [...prev, option.id]
                      })
                    }
                  />
                ))
              ) : (
                <Placeholder>
                  <Placeholder.Image image="https://assets.revolut.com/assets/3d-images-v2/3D241.png" />
                  <Placeholder.Title>No goals found</Placeholder.Title>
                  <Placeholder.Description>
                    Try different search parameters
                  </Placeholder.Description>
                </Placeholder>
              )}
            </InputGroup>
          </Dropdown.Group>
          <Dropdown.Group sticky="bottom">
            <HStack gap="s-12" py="s-8">
              <ActionButton
                width="100%"
                onClick={() => {
                  setOptions([])
                  setSelectedOptionsIds([])
                  setSearchValue('')
                }}
              >
                Cancel
              </ActionButton>
              <ActionButton
                variant="accent"
                width="100%"
                pending={isUpdatePending}
                disabled={!selectedOptionsIds.length}
                onClick={handleAddChildGoals}
              >
                Confirm
              </ActionButton>
            </HStack>
          </Dropdown.Group>
        </Dropdown>
        <Button
          disabled={!mainGoalHasNameAndOrgValue}
          useIcon="Plus"
          variant="secondary"
          onClick={async () => {
            if (mainGoalHasNameAndOrgValue) {
              // @TODO https://revolut.atlassian.net/browse/REVC-8816 change to add goals in the sidebar
              toggleNewGoalPopupOpen.on()
            }
          }}
          maxWidth={140}
        >
          New goal
        </Button>
        <Tooltip {...disabledButtonTooltip.getTargetProps()}>
          <VStack gap="s-4">
            {!values.name && (
              <Text variant="small" color={Token.color.danger}>
                Goal name is required
              </Text>
            )}
            {!values.is_company && !values.content_object.id && (
              <Text variant="small" color={Token.color.danger}>
                {capitalize(query.type) || 'Organisational unit'} is required
              </Text>
            )}
          </VStack>
        </Tooltip>
      </InputGroup>

      <ChildGoalsTable
        onRefreshReady={refresh => {
          refreshTableRef.current = refresh
        }}
      />
      {isNewGoalPopupOpen && (
        <CreateChildGoalPopup open onClose={toggleNewGoalPopupOpen.off} />
      )}
    </VStack>
  )
}
