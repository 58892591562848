import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Group, Tag, Token, VStack } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsSourceOption,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { ExternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import {
  getMetricLabel,
  getMetricSourceAvatar,
  getMetricSourceLabel,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { ExternalSourcesSidebar } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Extrernal/Sidebar'
import { SourceItem } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/common/SourceItem'
import { buildPathToMetricEnabledField } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/utils'
import { useSourcesOptionsAvailability } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Extrernal/hooks/useSourcesOptionsAvailability'
import { SourcesWidget } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/common/SourcesWidget'
import { metricSourceModel } from '@src/features/AiInsights/Performance/models/metricSourceModel'

interface Props {
  isSidebarOpen: boolean
  onSidebarOpen: VoidFunction
  onSidebarClose: VoidFunction
  actions: React.ReactNode
}

export const ExternalSources = ({
  isSidebarOpen,
  onSidebarOpen,
  onSidebarClose,
  actions,
}: Props) => {
  const { values } = useLapeContext<PerformanceInsightsGroupInterface>()
  const externalSources = values.sources.filter(metricSourceModel.isExternal)

  const { data: sourceOptions = [], isLoading: isOptionsLoading } = useGetSelectors<
    PerformanceInsightsSourceOption<ExternalMetricSources>
  >(selectorKeys.performance_insights_external_data_sources)
  const { options, isLoading: isOptionsAvailabilityLoading } =
    useSourcesOptionsAvailability(sourceOptions)

  return (
    <Box data-testid="performance_ai_insights_group_external_sources_widget">
      <SourcesWidget
        isLoading={isOptionsLoading || isOptionsAvailabilityLoading}
        sources={externalSources}
        title={
          <FormattedMessage
            id="forms.performance.insights.group.sources.widget.external.title"
            defaultMessage="External sources"
          />
        }
        description={
          <FormattedMessage
            id="forms.performance.insights.group.sources.widget.external.description"
            defaultMessage="What are the external metrics you want to include in the insights summary?"
          />
        }
        onManageClick={onSidebarOpen}
      >
        <VStack space="s-16">
          {externalSources.map(source => (
            <SourceItem
              id={source.name}
              key={source.name}
              title={getMetricSourceLabel(source.name)}
              avatar={getMetricSourceAvatar(source.name)}
              side={
                <Tag variant="outlined" color={Token.color.deepGrey}>
                  <FormattedMessage
                    id="performance.insights.group.external.source.item.tag"
                    defaultMessage="External"
                  />
                </Tag>
              }
              expandedContent={
                <Group>
                  {source.metrics.map(({ name }) => {
                    const fieldPath = buildPathToMetricEnabledField(
                      values,
                      source.name,
                      name,
                    )

                    return fieldPath ? (
                      <LapeSingleCheckbox
                        key={name}
                        name={fieldPath}
                        label={getMetricLabel(name)}
                        cellProps={{ variant: 'compact' }}
                      />
                    ) : null
                  })}
                </Group>
              }
            />
          ))}
        </VStack>
      </SourcesWidget>
      <ExternalSourcesSidebar
        sourceOptions={options}
        isOpen={isSidebarOpen}
        actions={actions}
        onClose={onSidebarClose}
      />
    </Box>
  )
}
