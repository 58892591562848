import { CandidateMatch } from '@src/features/CommonCandidatesTable/types'
import { isNumber } from 'lodash'

export const getMatchType = (score: number | null | undefined): CandidateMatch => {
  if (!isNumber(score)) {
    return 'not_available'
  }

  if (score >= 80) {
    return 'brilliant'
  }

  if (score >= 60) {
    return 'good'
  }

  if (score >= 40) {
    return 'ok'
  }

  if (score >= 20) {
    return 'poor'
  }

  return 'no_match'
}
