import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeType } from '@src/interfaces/reviewCycles'
import { Button, InputGroup, Side, Flex } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import FiltersSidebarItem from '@components/FiltersSidebar/FiltersSidebarItem'
import SideBar from '@components/SideBar/SideBar'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { IdContractTypes } from '@src/interfaces/contracts'
import {
  ProbationTemplateInterface,
  ProbationTemplateRules,
} from '@src/interfaces/probationTemplate'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const EmployeeRulesSidebarForm = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean
  handleClose: () => void
}) => {
  const { values } = useLapeContext<ProbationTemplateInterface>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const getEntityValue = (field: ProbationTemplateRules) => {
    return (
      values.rules?.[field]?.map(rule => ({
        name: String(rule.id),
        id: rule.id,
      })) || []
    )
  }

  const onEntityValueChange = (value: FilterOption[], field: ProbationTemplateRules) => {
    if (value) {
      values.rules[field] = value.length
        ? value.map(item => ({ id: Number(item.id), name: item.name }))
        : undefined
    }
  }

  return (
    <SideBar
      variant="wide"
      title="Apply employee rules"
      subtitle="Define filters as rules to determine which new joiners should receive this template"
      onClose={handleClose}
      isOpen={isOpen}
    >
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        <InputGroup>
          <FiltersSidebarItem
            label="Seniority"
            icon="People"
            selector={selectorKeys.seniority}
            value={getEntityValue(ProbationTemplateRules.seniority_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.seniority_id)
            }
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Teams"
            icon="People"
            selector={selectorKeys.team}
            value={getEntityValue(ProbationTemplateRules.team_id)}
            onChange={value => onEntityValueChange(value, ProbationTemplateRules.team_id)}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Departments"
            icon="Utilities"
            selector={selectorKeys.department}
            value={getEntityValue(ProbationTemplateRules.team__department_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.team__department_id)
            }
            filterType={FilterType.selector}
          />
          {/* With specialisationsEnabled === false we still show specialisations but call them Roles on UI and hide the actual Roles filter */}
          <FiltersSidebarItem
            label={specialisationsEnabled ? 'Specialisations' : 'Roles'}
            icon="Palette"
            selector={selectorKeys.specialisations}
            value={getEntityValue(ProbationTemplateRules.specialisation_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.specialisation_id)
            }
            filterType={FilterType.selector}
          />
          {specialisationsEnabled && (
            <FiltersSidebarItem
              label="Roles"
              icon="Puzzle"
              selector={selectorKeys.role}
              value={getEntityValue(ProbationTemplateRules.position_id)}
              onChange={value =>
                onEntityValueChange(value, ProbationTemplateRules.position_id)
              }
              filterType={FilterType.selector}
            />
          )}
          <FiltersSidebarItem
            label="Functions"
            icon="Services"
            selector={selectorKeys.functions}
            value={getEntityValue(ProbationTemplateRules.position__function_id)}
            onChange={value =>
              onEntityValueChange(value, ProbationTemplateRules.position__function_id)
            }
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Employee type"
            icon="Document"
            selector={selectorKeys.employee_types}
            value={getEntityValue(ProbationTemplateRules.employee_type)}
            onChange={value => {
              if (value) {
                values.rules.employee_type = value.length
                  ? value.map(item => ({
                      id: EmployeeType[item.id as keyof typeof EmployeeType],
                      name: item.name,
                    }))
                  : undefined
              }
            }}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Contract type"
            icon="DocumentsPair"
            selector={selectorKeys.contract_types}
            value={getEntityValue(ProbationTemplateRules.contract_type)}
            onChange={value => {
              if (value) {
                values.rules.contract_type = value.length
                  ? value.map(item => ({
                      id: IdContractTypes[item.id as keyof typeof IdContractTypes],
                      name: item.name,
                    }))
                  : undefined
              }
            }}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Location"
            icon="LocationPin"
            selector={selectorKeys.location}
            value={getEntityValue(ProbationTemplateRules.location_id)}
            onChange={value => {
              onEntityValueChange(value, ProbationTemplateRules.location_id)
            }}
            filterType={FilterType.selector}
          />
          <FiltersSidebarItem
            label="Entity"
            icon="Bank"
            selector={selectorKeys.entity}
            value={getEntityValue(ProbationTemplateRules.entity_id)}
            onChange={value => {
              onEntityValueChange(value, ProbationTemplateRules.entity_id)
            }}
            filterType={FilterType.selector}
          />
        </InputGroup>
        <Side.Actions>
          <Button
            variant="secondary"
            onClick={() => {
              values.rules = {}
            }}
            elevated
          >
            Clear
          </Button>
        </Side.Actions>
      </Flex>
    </SideBar>
  )
}
