import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { H5, TextSkeleton } from '@revolut/ui-kit'

import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  changelogChangedByWithAvatar,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogFieldsOldValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, SORT_DIRECTION } from '@src/interfaces/data'
import { TableNames } from '@src/constants/table'
import {
  getEmployeeKeyPersonResponsibilitiesAssignmentChangelog,
  responsibilityAssignmentFormRequests,
} from '@src/api/keyPerson'
import { TableWrapper } from '@components/Table/TableWrapper'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'

const row = {
  cells: [
    {
      ...changelogFieldsChangedFields(),
      selectorsKey: selectorKeys.benchmark_changelog_fields,
      width: 100,
    },
    {
      ...changelogFieldsOldValue,
      width: 200,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      sortKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 150,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      type: CellTypes.dateTime,
      width: 120,
    },
    {
      ...changelogStatus,
      selectorsKey: selectorKeys.changelog_item_statuses,
      width: 100,
    },
  ],
}

export const Changelog = () => {
  const { id, responsibilityId } = useParams<{ id: string; responsibilityId: string }>()

  const [selectedResponsibility, setSelectedResponsibility] =
    useState<KeyPersonResponsibilityAssignmentInterface>()

  useEffect(() => {
    responsibilityAssignmentFormRequests.get({ id: responsibilityId }).then(res => {
      setSelectedResponsibility(res.data)
    })
  }, [])

  const table = useTable(
    getEmployeeKeyPersonResponsibilitiesAssignmentChangelog(id, responsibilityId),
    undefined,
    [
      {
        sortBy: 'effective_date_time',
        direction: SORT_DIRECTION.ASC,
        nonResettable: true,
      },
    ],
  )

  return (
    <TableWrapper gap="s-24">
      {selectedResponsibility ? (
        <H5>{selectedResponsibility.key_person_type.name} changelog</H5>
      ) : (
        <TextSkeleton />
      )}
      <AdjustableTable
        name={TableNames.KeyPersonsEmployeeResponsibilityChangelog}
        row={row}
        {...table}
        useWindowScroll
      />
    </TableWrapper>
  )
}
