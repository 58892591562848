import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { toIdAndName } from '@src/utils/toIdAndName'
import { FilterByInterface } from '@src/interfaces/data'

export type StatFilterId =
  | 'active_employees'
  | 'pending_employees'
  | 'onboarding_employees'
  | 'hired_employees'

export const getEmployeeTypeFilters = (includeExternal: boolean) => {
  const filters: FilterByInterface = {
    filters: [toIdAndName('internal')],
    columnName: 'employee_type',
    nonResettable: false,
  }
  if (includeExternal) {
    filters.filters.push(toIdAndName('external'))
  }

  return filters
}

export const getDefaultStatsFilter = () => {
  return hasStatusFilter() ? undefined : 'active_employees'
}

export const useStoredEmployeeTypeFilter = () => {
  const [excludeExternal, setExcludeExternal] = useLocalStorage<boolean>(
    LOCAL_STORAGE.EXCLUDE_EXTERNAL_EMPLOYEES,
    false,
  )
  return { excludeExternal, setExcludeExternal }
}

export const hasStatusFilter = () => {
  const currentURL = new URL(window.location.href)
  return typeof currentURL.searchParams.get('status') === 'string'
}

export const isStatusFilter = ({ columnName }: FilterByInterface) =>
  columnName === 'status'

export const isExternalEmployeesTypeFilter = ({
  columnName,
  filters,
}: FilterByInterface) =>
  columnName === 'employee_type' &&
  !!filters.find(filterItem => filterItem.id === 'external')
