import React, { useState } from 'react'
import { Color, Dropdown, MoreBar } from '@revolut/ui-kit'

import { successNotification } from '@src/actions/NotificationActions'
import { StatusChangeButtonProps } from '../../types'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

export const StatusChange = ({
  data,
  title,
  notification,
  newStatus,
  statusTransitions,
  negative,
  onStatusTransitionSuccess,
  isDropdownItem,
}: StatusChangeButtonProps) => {
  const [pending, setPending] = useState(false)
  const errorPopup = useErrorPopup()
  const onClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    try {
      setPending(true)
      await statusTransitions.update({ status: newStatus }, onStatusTransitionSuccess)
      successNotification(notification)
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: `Failed to ${title}`,
        forceFallbackTitle: true,
      })
    } finally {
      setPending(false)
    }
  }

  if (!data.id) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use="button"
        disabled={pending}
        color={negative ? Color.RED : undefined}
        onClick={onClick}
      >
        {title}
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      disabled={statusTransitions.pending}
      variant={negative ? 'negative' : 'primary'}
      pending={pending}
      onClick={onClick}
    >
      {title}
    </MoreBar.Action>
  )
}
