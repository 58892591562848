import { useEffect, useState } from 'react'

import { toIdAndName } from '@src/utils/toIdAndName'
import {
  RoundOption,
  TimelineFilterInterface,
  TimelineFilterMode,
} from '../../../Rounds/Selector/types'
import { useGetSurveyRoundsOptions } from '../../../Rounds/Selector/hooks/useGetSurveyRounds'
import { useGetDatesRange } from '../../../Rounds/Selector/hooks/useGetDatesRange'

export const useTimelineFilter = (
  surveyId: number | undefined,
): TimelineFilterInterface => {
  const [filterMode, setFilterMode] = useState<TimelineFilterMode>('rounds')
  const [selectedRound, setSelectedRound] = useState<RoundOption>()

  const { selectedDatesRange, setSelectedDatesRange, dateFrom, dateTo } =
    useGetDatesRange(selectedRound, filterMode)

  const { options: roundsOptions, isLoading: isLoadingRoundsOptions } =
    useGetSurveyRoundsOptions(surveyId)

  useEffect(() => {
    if (filterMode === 'rounds' && roundsOptions?.[0] && !selectedRound) {
      setSelectedRound(roundsOptions?.[0])
    }
  }, [roundsOptions])

  return {
    dateFrom,
    dateTo,
    calendar: {
      value: selectedDatesRange,
      setValue: newValue => {
        setSelectedDatesRange(newValue)
        setSelectedRound(undefined)
      },
    },
    round: {
      value: selectedRound,
      options: roundsOptions,
      isLoadingOptions: isLoadingRoundsOptions,
      setValue: newValue => {
        setSelectedRound(newValue)
        if (newValue.dateFrom && newValue.dateTo) {
          setSelectedDatesRange([
            toIdAndName(newValue.dateFrom),
            toIdAndName(newValue.dateTo),
          ])
        }
      },
    },
    mode: {
      value: filterMode,
      setValue: setFilterMode,
    },
    clearAll: () => {
      setSelectedDatesRange([])
      setSelectedRound(undefined)
    },
  }
}
