import { AudienceInterface } from '@src/components/AddAudienceSidebar/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { FieldOptions, IdAndName } from '@src/interfaces/index'
import { FileInterface } from './files'
import { FinalGrade } from './performance'

export enum MeetingTemplateStatus {
  Draft = 'draft',
  Active = 'active',
}

export enum MeetingTemplateFrequency {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  BI_WEEKLY = 'bi_weekly',
}

export enum TemplateItemRole {
  TalkingPoints = 'talking_points',
  ActionItems = 'action_items',
  PerformanceRating = 'performance_rating',
  PersonalNotes = 'personal_notes',
}

export enum TemplateItemContentType {
  Text = 'text',
  TextList = 'text__list',
  TextCheckbox = 'text__checkbox',
  DropdownSingleChoice = 'dropdown__single_choice',
}

export enum AiGenerationStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum OneToOneRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
}

export enum OneToOneEmployeeRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
  Report = 'report',
}

export interface Attendee extends EmployeeOptionInterface {
  department?: IdAndName
}

interface MeetingSeries {
  id: number
  event_id: string
  start: string
  end: string
  recurrence: string
}

export interface ValidatedMeeting {
  meeting?: MeetingEvent
  manager_meeting?: OneToOneMeeting
  previous_meeting_id?: number
  enable_ai: boolean
}

export interface OneToOneMeeting {
  id: number
  manager: Attendee
  employee: Attendee
  relation_type: OneToOneRelation
  employee_relation_type: OneToOneEmployeeRelation
  meeting_series: MeetingSeries | null
  next_meeting_start: string | null
  next_meeting_end: string | null
  next_meeting_id: number | null
  next_meeting_link: string | null
  next_meeting_calendar_link: string | null
  language: string | null
}

export interface OneToOneMeetingsStats {
  total_setup_rate: number
  lm_setup_rate: number
  fm_setup_rate: number
}

export enum MeetingEventStatus {
  Completed = 'Completed',
  Scheduled = 'Scheduled',
}

export enum MeetingNotesOrigin {
  Platform = 'platform',
  Extension = 'browser_extension',
}

export enum MeetingEventCategory {
  OneToOne = 'one_to_one',
}

export enum GoogleCalendarEventStatus {
  ActionRequired = 'needsAction',
  Declined = 'declined',
  Tentative = 'tentative',
  Accepted = 'accepted',
}

export enum CalendarProviders {
  Google = 'google',
}

interface EventAttendee extends Attendee {
  comment: string
}

export interface GoogleCalendarEventAttendee extends EventAttendee {
  response_status: GoogleCalendarEventStatus
}

export interface MeetingEvent<T extends EventAttendee = EventAttendee> {
  id: number
  title: string
  description: string
  start: string
  end: string
  status: MeetingEventStatus
  category: MeetingEventCategory
  organizer: Attendee
  parent_event_id: string | null
  is_series: boolean
  employee_relation_type: OneToOneEmployeeRelation
  recurrence: string | null
  link: string
  attendees: T[]
  object_id: number
  feedback_title: string
  feedback_description: string
  feedback_link: string | null
  feedback_event_link: string | null
}

export interface ValueName {
  value: string
  name: string
}

export interface TimeSlot {
  start: string
  end: string
}

interface BusySlot {
  busy: TimeSlot[]
}

export interface FreeBusyCalendar {
  kind: string
  timeMin: string
  timeMax: string
  calendars: Record<string, BusySlot>
}

export interface TemplateItemInterface {
  id?: number
  title: string
  is_private: boolean
  is_available_before_start: boolean
  content_type: TemplateItemContentType
  role: TemplateItemRole
  content: string | string[] | ValueName[]
}

export interface MeetingTemplateInterface {
  id: number
  name: string
  description: string
  frequency: MeetingTemplateFrequency | null
  duration: number | null
  audiences: AudienceInterface[]
  items: TemplateItemInterface[]
  created_by: EmployeeOptionInterface | null
  enable_ai: boolean
  status: MeetingTemplateStatus
}

export interface MeetingNotesFeedbackBaseInterface {
  id: number
  manager: EmployeeOptionInterface
  report: EmployeeOptionInterface
  meeting: MeetingEvent
  content: PublicContentItem[]
  private_content: PrivateContentItem[]
  summary: string | null
  summary_status: AiGenerationStatus | null
  action_items_status: AiGenerationStatus | null
  enable_ai: boolean
  language: string
}

export interface MeetingNotesFeedback extends MeetingNotesFeedbackBaseInterface {
  has_transcript?: boolean
  enable_personal_notes: boolean
  report_personal_notes: string
  manager_personal_notes: string
  attachments: FileInterface[]
  recorded_by: EmployeeOptionInterface | null
  rejected_by: EmployeeOptionInterface | null
  origin: string
  field_options: FieldOptions
}

export interface MeetingsSummaryInterface {
  id: number
  manager_meeting: Pick<
    OneToOneMeeting,
    'id' | 'employee' | 'manager' | 'relation_type' | 'meeting_series'
  >
  cycle: IdAndName<string | number>
  summary: string | null
  strengths: string | null
  area_for_development: string | null
  performance_grade: FinalGrade | null
  actions_count: number
  performance_grade_count: number
  meetings_count: number
}

export interface CreateOneToOneMeetingNotePayloadInterface {
  manager: { id: number }
  report: { id: number }
  origin: MeetingNotesOrigin
}

export const publicRoles = ['action_items', 'talking_points'] as const
export const privateRoles = ['performance_rating'] as const

export type PrivateContentItem = PerformanceRatingContent

export type PublicContentItem = ActionItemContent | TalkingPointsContent
interface ContentItemBase {
  title: string
  is_private: boolean
  is_available_before_start: boolean
  role: typeof publicRoles[number] | typeof privateRoles[number]
}
export interface ActionItem {
  completed: boolean
  value: string
}
interface ActionItemContent extends ContentItemBase {
  role: 'action_items'
  content_type: 'text__checkbox'
  content: { content: ActionItem[] | null }
}

interface PerformanceRatingContent extends ContentItemBase {
  role: 'performance_rating'
  content_type: 'dropdown__single_choice'
  content: {
    value: null | { name: string; value: FinalGrade }
    content: { name: string; value: FinalGrade }[]
  }
}

interface TalkingPointsContent extends ContentItemBase {
  content_type: 'text__list'
  role: 'talking_points'
  content: { content: string }
}

export interface UpdateOneToOneMeetingNotePayloadInterface {
  summary: string
  content: PublicContentItem[]
  private_content: PrivateContentItem[]
  manager_personal_notes: string
  report_personal_notes: string
  recorded_by: { id: number } | null
  rejected_by: { id: number } | null
  transcript: string | null
  language: string
  origin: MeetingNotesOrigin
  attachments: Pick<FileInterface, 'id'>[]
  save_hash: string // used for merging autosave changes to one changelog element
}

export type OneToOneMeetingChangelogItem =
  | ActionItemChangeLog
  | TalkingPointsChangeLog
  | OtherChangeLog

interface OneToOneMeetingBase {
  id: number
  author: Attendee | null
  change_time: string
  change_location: string
  change_type: 'updated' | 'created'
  is_ai_generated: boolean
}

interface ActionItemChangeLog extends OneToOneMeetingBase {
  change_location: 'action_items'
  change: {
    after: ActionItemContent['content'] | null
    before: ActionItemContent['content'] | null
  } | null
}

interface TalkingPointsChangeLog extends OneToOneMeetingBase {
  change_location: 'talking_points'
  change: {
    after: TalkingPointsContent['content'] | null
    before: TalkingPointsContent['content'] | null
  } | null
}

interface OtherChangeLog extends OneToOneMeetingBase {
  change_location:
    | 'summary'
    | 'manager_personal_notes'
    | 'report_personal_notes'
    | 'performance_rating'
    | 'attachments'
  change: {
    after: string | null
    before: string | null
  } | null
}
