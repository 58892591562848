import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  employeesEmailColumn,
  employeesEntityColumn,
  employeesFirstNameColumn,
  employeesLastNameColumn,
  employeesLineManagerColumn,
  employeesLocationColumn,
  employeesNameColumn,
  employeesRoleColumn,
  employeesSeniorityColumn,
  employeesTeamColumn,
} from '@src/constants/columns/employeesV2'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'

export const createGetRow =
  (params?: { showEmployeeNameColumn: boolean; isSession?: boolean }) =>
  (onCreate: CreateCallback) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => {
    const { isSession, showEmployeeNameColumn = true } = params || {}
    const isDirectEdit = !isSession

    return {
      cells: [
        showEmployeeNameColumn && {
          ...employeesNameColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmailColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesFirstNameColumn(options.onChange),
          width: 120,
        },
        {
          ...employeesLastNameColumn(options.onChange),
          width: 120,
        },
        {
          ...employeesTeamColumn(
            options.onChange,
            onChangeAction => onCreate('team', onChangeAction),
            isDirectEdit,
          ),
          width: 150,
        },
        {
          ...employeesRoleColumn(
            options.onChange,
            onChangeAction => onCreate('role', onChangeAction),
            isDirectEdit,
          ),
          width: 150,
        },
        {
          ...employeesSeniorityColumn(options.onChange, isDirectEdit),
          width: 150,
        },
        {
          ...employeesLocationColumn(
            options.onChange,
            onChangeAction => onCreate('location', onChangeAction),
            isDirectEdit,
          ),
          width: 200,
        },
        {
          ...employeesEntityColumn(
            options.onChange,
            onChangeAction => onCreate('companyEntity', onChangeAction),
            isDirectEdit,
          ),
          width: 200,
        },
        {
          ...employeesLineManagerColumn(options.onChange, isDirectEdit),
          width: 200,
        },
      ].filter(Boolean),
    }
  }

export const mandatoryBulkSessionFields = ['email', 'first_name', 'last_name']
export const fieldsForBulkEdit = [...baseFieldsForBulkEdit, 'line_manager', 'location']
export type FieldsForBulkEdit = typeof fieldsForBulkEdit[number]

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  location: ['location', 'entity'],
  entity: ['location', 'entity'],
  line_manager: ['line_manager'],
}
