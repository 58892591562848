import React from 'react'
import { Avatar, HStack, IconButton, Item, Token } from '@revolut/ui-kit'
import { TaskDefinitionInterface } from '@src/interfaces/employeeOnboardingTemplate'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

type Props = {
  handleClick?: () => void
  handleDelete?: () => void
  task: TaskDefinitionInterface
}

const getTaskAssignee = (task: TaskDefinitionInterface) => {
  switch (task.default_assignee_type.id) {
    case 'group':
      return task.default_assignee_group?.name || ''
    case 'employee':
      return task.default_assignee?.name || ''
    default:
      return task.default_assignee_relationship?.name || ''
  }
}

export const TaskItem = ({ handleClick, handleDelete, task }: Props) => {
  return (
    <Item style={transparentThemeBackgroundOverrides}>
      <Item.Avatar>
        <Avatar useIcon="CheckboxOff" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{task.name}</Item.Title>
        <Item.Description>{`Assigned to ${getTaskAssignee(task)}`}</Item.Description>
      </Item.Content>
      <Item.Side>
        <HStack gap="s-8">
          {handleClick && (
            <IconButton
              aria-label={`Edit task ${task.name}`}
              color={Token.color.greyTone20}
              onClick={handleClick}
              useIcon="Pencil"
            />
          )}
          {handleDelete && (
            <IconButton
              aria-label={`Delete task ${task.name}`}
              color={Token.color.greyTone20}
              onClick={handleDelete}
              useIcon="Delete"
            />
          )}
        </HStack>
      </Item.Side>
    </Item>
  )
}
