import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ActionButton, Avatar, Group, Item, Token, VStack } from '@revolut/ui-kit'

import {
  addDocumentSignature,
  addDocumentUpload,
  useTasks,
} from '@src/api/onboardingEmployeesV2'
import { useGetEmployeeOnboardingSettings } from '@src/api/settings'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/components/SelectTemplatesSidebar'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { ParsableError } from '@src/features/Errors/parseError'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'
import {
  OnboardingStatus,
  OnboardingTaskInterface,
  OnboardingTemplateInterface,
} from '@src/interfaces/onboardingV2'
import { RightToWork } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/RightToWork'
import { ScreeningCheck } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/ScreeningCheck'
import { DocumentsGeneratedPreview } from './DocumentsGeneratedPreview'
import { PreviewTemplateSidebar } from './PreviewTemplateSidebar'
import { TaskItem } from './TaskItem'
import { TaskSidebar } from './TaskSidebar'
import { TemplateItem } from './TemplateItem'

type Props = {
  documentsSignature?: OnboardingTemplateInterface[]
  documentsUpload?: OnboardingTemplateInterface[]
  onRefetchDocumentsSignature: VoidFunction
  onRefetchDocumentsUpload: VoidFunction
  status: OnboardingStatus
}

export const OnboardingInformation = ({
  documentsSignature,
  documentsUpload,
  onRefetchDocumentsSignature,
  onRefetchDocumentsUpload,
  status,
}: Props) => {
  const { id, onboardingId } = useParams<{ id: string; onboardingId: string }>()

  const [task, setTask] = useState<OnboardingTaskInterface>()
  const [taskSideBarOpen, setTaskSiderOpen] = useState(false)
  const [templatesSidebar, setTemplatesSidebar] = useState<'request' | 'esignature'>()
  const [templatePreview, setTemplatePreview] = useState<OnboardingTemplateInterface>()
  const { data: tasksData, refetch: refetchTasksData } = useTasks(onboardingId)

  const { data: onboardingSettings } = useGetEmployeeOnboardingSettings()
  const errorPopup = useErrorPopup()

  const attachedTemplates =
    (templatesSidebar && templatesSidebar === 'request'
      ? documentsUpload
      : documentsSignature
    )?.map(template => ({
      id: template.document_template.id,
      name: template.document_template.name,
    })) || []

  const handleDocumentAddError = (error: ParsableError) =>
    errorPopup.show({
      error,
      fallbackTitle: 'Failed to add new document',
      forceFallbackTitle: true,
    })

  const handleDocumentUpload = async (templateId: number) => {
    try {
      await addDocumentUpload(onboardingId, templateId)
      onRefetchDocumentsUpload()
    } catch (error) {
      handleDocumentAddError(error)
    }
  }

  const handleDocumentSignature = async (templateId: number) => {
    try {
      await addDocumentSignature(onboardingId, templateId)
      onRefetchDocumentsSignature()
    } catch (error) {
      handleDocumentAddError(error)
    }
  }

  const handleAddTemplate = (newTemplate: AttachedTemplateInterface) => {
    templatesSidebar === 'request'
      ? handleDocumentUpload(newTemplate.id)
      : handleDocumentSignature(newTemplate.id)
  }

  return (
    <>
      <VStack gap="s-16">
        <Group>
          <Item>
            <Item.Content>
              <Item.Title color={Token.color.greyTone50}>
                Documents to be uploaded
              </Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton onClick={() => setTemplatesSidebar('request')} useIcon="Plus">
                Add new
              </ActionButton>
            </Item.Side>
          </Item>
          {!!documentsUpload?.length && (
            <VStack>
              {documentsUpload.map(documentUpload => (
                <TemplateItem
                  key={documentUpload.id}
                  status={status}
                  template={documentUpload}
                  handleClick={() => setTemplatePreview(documentUpload)}
                />
              ))}
            </VStack>
          )}
          {!documentsUpload?.length && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon="Document" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>
                  Nothing requested yet
                </Item.Title>
              </Item.Content>
            </Item>
          )}
        </Group>

        <Group>
          <Item>
            <Item.Content>
              <Item.Title color={Token.color.greyTone50}>
                Documents to be signed
              </Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton
                onClick={() => setTemplatesSidebar('esignature')}
                useIcon="Plus"
              >
                Add new
              </ActionButton>
            </Item.Side>
          </Item>
          {!!documentsSignature?.length && (
            <VStack>
              {documentsSignature.map(documentSignature => (
                <TemplateItem
                  key={documentSignature.id}
                  status={status}
                  template={documentSignature}
                  handleClick={() => setTemplatePreview(documentSignature)}
                />
              ))}
            </VStack>
          )}
          {!documentsSignature?.length && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon="Pencil" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>
                  Nothing requested yet
                </Item.Title>
              </Item.Content>
            </Item>
          )}
        </Group>

        <Group>
          <Item>
            <Item.Content>
              <Item.Title color={Token.color.greyTone50}>Tasks</Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton onClick={() => setTaskSiderOpen(true)} useIcon="Plus">
                Add new
              </ActionButton>
            </Item.Side>
          </Item>
          {!!tasksData?.results.length && (
            <VStack>
              {tasksData.results.map(taskItem => (
                <TaskItem
                  key={taskItem.id}
                  task={taskItem}
                  handleClick={() => {
                    setTaskSiderOpen(true)
                    setTask(taskItem)
                  }}
                />
              ))}
            </VStack>
          )}
          {!tasksData?.results.length && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon="Form" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>Nothing added yet</Item.Title>
              </Item.Content>
            </Item>
          )}
        </Group>

        {onboardingSettings?.collect_screening_information && (
          <ScreeningCheck employeeId={Number(id)} hideTableRoute />
        )}
        {onboardingSettings?.collect_rights_to_work_information && (
          <RightToWork employeeId={Number(id)} hideTableRoute />
        )}
        <HideIfCommercial>
          <DocumentsGeneratedPreview employeeId={Number(id)} />
        </HideIfCommercial>
      </VStack>

      {!!templatesSidebar && (
        <SelectTemplatesSidebar
          allowCreate={templatesSidebar === 'request'}
          attachedTemplates={attachedTemplates}
          filterByTypes={[templatesSidebar]}
          isOpen={!!templatesSidebar}
          onAddTemplate={newTemplate => handleAddTemplate(newTemplate)}
          onClose={() => setTemplatesSidebar(undefined)}
        />
      )}

      <PreviewTemplateSidebar
        document={templatePreview}
        employeeId={id}
        onClose={() => setTemplatePreview(undefined)}
        onboardingId={onboardingId}
        onboardingStatus={status}
        onRefetchDocumentsSignature={onRefetchDocumentsSignature}
        onRefetchDocumentsUpload={onRefetchDocumentsUpload}
      />

      <TaskSidebar
        isOpen={taskSideBarOpen}
        onClose={() => {
          setTaskSiderOpen(false)
          setTask(undefined)
          refetchTasksData()
        }}
        onboardingId={onboardingId}
        refetchTasksData={refetchTasksData}
        task={task}
      />
    </>
  )
}
