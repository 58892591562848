import React, { useState } from 'react'

import { HiringProcessFormBody } from '@src/pages/Forms/SpecialisationForm/HiringProcess/components/HiringProcessFormBody'

export const HiringProcess = () => {
  const [enableFiltering, setEnableFiltering] = useState(true)

  return (
    <HiringProcessFormBody
      setEnableFiltering={val => setEnableFiltering(val)}
      enableFiltering={enableFiltering}
    />
  )
}
