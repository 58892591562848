import React, { PropsWithChildren } from 'react'
import { GoalsInterface } from '@src/interfaces/goals'
import { Text, Token, Tooltip, useTooltip, VStack } from '@revolut/ui-kit'

interface Props {
  goal: GoalsInterface
}

const getTooltipMessage = (goal: GoalsInterface) => {
  if (goal.is_already_cascaded) {
    return {
      title: 'Goals can only be cascaded once',
      description:
        'This goal cannot be cascaded because it has already been cascaded from another goal.',
    }
  }
  if (goal.update_type?.id === 'aggregated') {
    return {
      title: 'Aggregated goals cannot be cascaded',
      description: 'This goal cannot be cascaded because it uses children for progress.',
    }
  }

  return null
}

export const GoalSelectCell = ({ goal, children }: PropsWithChildren<Props>) => {
  const tooltip = useTooltip()

  if (!children) {
    return null
  }
  const tooltipMessage = getTooltipMessage(goal)

  if (tooltipMessage) {
    return (
      <>
        <div {...tooltip.getAnchorProps()}>{children}</div>

        <Tooltip {...tooltip.getTargetProps()} placement="right-start">
          <VStack padding="s-12" gap="s-4" maxWidth={318}>
            <Text variant="emphasis2" color={Token.color.foreground}>
              {tooltipMessage.title}
            </Text>

            <Text variant="body3" color={Token.color.greyTone50}>
              {tooltipMessage.description}
            </Text>
          </VStack>
        </Tooltip>
      </>
    )
  }

  return <>{children}</>
}
