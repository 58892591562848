import React from 'react'
import { List, TextWidget, TextWidgetSkeleton, Token } from '@revolut/ui-kit'

export const BulletTextWidget = ({
  label,
  values,
}: {
  label: string
  values: string[] | undefined
}) => (
  <TextWidget>
    <TextWidget.Title color={Token.color.greyTone50} fontSize={14}>
      {label}
    </TextWidget.Title>
    <TextWidget.Content color={Token.color.greyTone50}>
      <List variant="compact" color={Token.color.greyTone50}>
        {values?.map((value, index) => (
          <List.Item key={index} useIcon="Dot">
            {value}
          </List.Item>
        ))}
      </List>
    </TextWidget.Content>
  </TextWidget>
)

export const BulletTextWidgetSkeleton = ({ label }: { label: string }) => (
  <TextWidgetSkeleton>
    <TextWidget.Title color={Token.color.greyTone50} fontSize={14}>
      {label}
    </TextWidget.Title>
    <TextWidgetSkeleton.Summary />
  </TextWidgetSkeleton>
)
