import React from 'react'
import {
  AvatarSkeleton,
  AvatarSize,
  Flex,
  Grid,
  HStack,
  Skeleton,
  Spacer,
  TextSkeleton,
  Token,
} from '@revolut/ui-kit'

export const ROW_LABEL_MIN_WIDTH = 50
export const ROW_LABEL_WIDTH = 200
export const BAR_ITEM_HEIGHT = 52
export const DEFAULT_ROW_OPACITY = 0.8
export const ACTIVE_ROW_OPACITY = 1

interface SkeletonProps {
  height?: number
}

export const GridBarChartRowSkeleton = ({ height = BAR_ITEM_HEIGHT }: SkeletonProps) => (
  <Flex justifyContent="center" alignItems="center" gap="s-16">
    <HStack
      width={ROW_LABEL_WIDTH}
      minWidth={ROW_LABEL_MIN_WIDTH}
      space="s-12"
      align="center"
    >
      <AvatarSkeleton size={getClosestAvatarSizeToRowHeight(height)} />
      <TextSkeleton width={100} />
    </HStack>
    <Spacer />
    <Grid columns="1fr 2fr 1fr 5fr 3fr" columnGap="s-16" width="100%" overflow="auto">
      <Skeleton height={height} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={height} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={height} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={height} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={height} p="s-4" radius={Token.radius.r6} />
    </Grid>
  </Flex>
)

const sortedAvatarSizes: AvatarSize[] = [
  AvatarSize.S_96,
  AvatarSize.S_76,
  AvatarSize.S_60,
  AvatarSize.S_56,
  AvatarSize.S_48,
  AvatarSize.S_40,
  AvatarSize.S_36,
  AvatarSize.S_32,
  AvatarSize.S_24,
  AvatarSize.S_20,
]

const getClosestAvatarSizeToRowHeight = (rowHeight: number): AvatarSize =>
  sortedAvatarSizes.find(size => size <= rowHeight) ??
  sortedAvatarSizes[sortedAvatarSizes.length - 1]
