import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import { Button, InputGroup, Switch, Item, Side, Flex } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { Props } from './EligibleGroupsFiltersSidebar'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { EligibilityGroupFilters } from '@src/pages/Forms/ReviewCycle/components/EligibilityGroupFilters'

export const EligibleGroupsFiltersSidebarForm = ({
  cycleId,
  handleClose,
  refetchGroups,
  submit,
}: Props) => {
  const { values } = useLapeContext<EligibleGroupInterface>()
  const [scorecardsToggleDisabled, setScorecardsToggleDisabled] = useState(
    values.sign === 'exclude',
  )

  useEffect(() => {
    if (!values?.filters) {
      values.filters = {}
    }
    if (!values.cycle) {
      values.cycle = Number(cycleId)
    }
    if (values.should_create_scorecards === undefined) {
      values.should_create_scorecards = true
    }
    if (!values.sign) {
      values.sign = 'include'
    }
  }, [])

  if (!values?.filters) {
    return null
  }

  const toggleSetIneligible = () => {
    values.sign = values.sign === 'include' ? 'exclude' : 'include'
    if (values.sign === 'exclude') {
      values.should_create_scorecards = false
      setScorecardsToggleDisabled(true)
    } else {
      setScorecardsToggleDisabled(false)
    }
  }

  return (
    <Flex flexDirection="column" justifyContent="space-between" height="100%">
      <InputGroup>
        <LapeNewInput label="Group name" name="name" required />
        <Item use="label">
          <Item.Content>
            <Item.Title>Set this group as ineligible</Item.Title>
          </Item.Content>
          <Item.Side>
            <Switch onChange={toggleSetIneligible} checked={values.sign === 'exclude'} />
          </Item.Side>
        </Item>
        <LapeNewSwitch
          name="should_create_scorecards"
          itemTypeProps={{
            title: 'Create scorecards for this group',
          }}
          disabled={scorecardsToggleDisabled}
        />
        <EligibilityGroupFilters />
      </InputGroup>
      <Side.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            values.filters = {}
          }}
          elevated
        >
          Clear
        </Button>
        <NewSaveButtonWithPopup
          onClick={submit ? () => submit(values) : undefined}
          disabled={!values.name || !Object.keys(values.filters).length}
          tooltipText="Please add group name and populate filters"
          successText={`Eligibility group was ${values.id ? 'updated' : 'created'}`}
          onAfterSubmit={() => {
            refetchGroups()
            handleClose()
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </Flex>
  )
}
