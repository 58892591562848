import { Token } from '@revolut/ui-kit'
import { ProbationResults } from '@src/interfaces/probationReview'
import { notReachable } from '@src/utils/notReachable'

export const getProbationResultSettings = (result: ProbationResults) => {
  switch (result) {
    case ProbationResults.Passed:
      return { color: Token.color.green, title: 'Passed' }
    case ProbationResults.Extend:
      return { color: Token.color.warning, title: 'Extended' }
    case ProbationResults.ChangeSeniority:
      return { color: Token.color.greyTone50, title: 'Change Seniority' }
    case ProbationResults.Failed:
      return { color: Token.color.danger, title: 'Failed' }
    default:
      return notReachable(result)
  }
}
