import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'

export const usePipPermissions = () => {
  const canManagePIPCycle = useHasGlobalPermission(PermissionTypes.ManagePipCycles)
  const canManagePIPCommitteeDecision = useHasGlobalPermission(
    PermissionTypes.ManagePipCommitteeDecision,
  )
  const canApproveAndInitiatePIP = canManagePIPCycle || canManagePIPCommitteeDecision

  return {
    canManagePIPCycle,
    canManagePIPCommitteeDecision,
    canApproveAndInitiatePIP,
  }
}
