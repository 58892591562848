import React from 'react'
import { useSelector } from 'react-redux'
import { VStack } from '@revolut/ui-kit'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'
import { selectUser } from '@src/store/auth/selectors'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'
import { OneToOnesSummary } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/Summary'
import { InternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { MetricsSourceItem } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsSourceItem/MetricsSourceItem'

interface Props {
  oneToOnesSummary: MeetingsSummaryInterface
  isLoading: boolean
}

export const OneToOneMetrics = ({ oneToOnesSummary, isLoading }: Props) => {
  const currentUser = useSelector(selectUser)

  if (isLoading) {
    return <SourceSkeleton />
  }

  if (!oneToOnesSummary.summary) {
    return null
  }

  return (
    <VStack space="s-16" data-testid="ai_insights_performance_121_metrics">
      <MetricsSourceItem source={InternalMetricSources.OneToOnes} />
      <OneToOnesSummary
        summary={oneToOnesSummary}
        managerId={currentUser.id}
        sections={['highlights', 'summary']}
      />
    </VStack>
  )
}
