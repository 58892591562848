import React from 'react'
import styled from 'styled-components'
import { CDN_BUCKET } from '@src/constants/externalLinks'

type Props = {
  url?: string | null
  poster?: string
  autoPlay?: boolean
}

const VideoPlayer = styled.video`
  border-radius: 20px;
  outline: none;
  width: 100%;
  height: auto;
  pointer-events: auto;
`

const IframePlayer = styled.iframe`
  border-radius: 20px;
`

const getYouTubeId = (url: string) => {
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = url.match(regExp)
  return match ? match[1] : null
}

const getVimeoId = (url: string) => {
  const regExp =
    /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^/]+\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?))/
  const match = url.match(regExp)
  return match ? match[1] : null
}

const getGDriveId = (url: string) => {
  const regExp =
    /(?:drive\.google\.com\/file\/d\/|drive\.google\.com\/open\?id=|drive\.google\.com\/uc\?id=|drive\.google\.com\/a\/[^/]+\/file\/d\/)([^\/&?]+)(?:\/[^\/]*)?$/
  const match = url.match(regExp)
  return match ? match[1] : null
}

export const Video = ({ url, poster, ...rest }: Props) => {
  if (!url) {
    return null
  }

  const youTubeId = getYouTubeId(url)
  if (youTubeId) {
    return (
      <IframePlayer
        title="YouTube video player"
        src={`https://www.youtube.com/embed/${youTubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        style={{ width: '100%', aspectRatio: '16 / 9' }}
      />
    )
  }

  const vimeoId = getVimeoId(url)
  if (vimeoId) {
    return (
      <IframePlayer
        title="Vimeo video player"
        src={`https://player.vimeo.com/video/${vimeoId}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{ width: '100%', aspectRatio: '16 / 9' }}
      />
    )
  }

  const gdriveId = getGDriveId(url)
  if (gdriveId) {
    return (
      <IframePlayer
        title="GDrive video player"
        src={`https://drive.google.com/file/d/${gdriveId}/preview`}
        allow="autoplay"
        style={{ width: '100%', aspectRatio: '16 / 9' }}
      />
    )
  }

  const fullUrl = url.startsWith('http') ? url : `${CDN_BUCKET}/videos${url}`

  let fullUrlPoster: string | undefined

  if (poster) {
    fullUrlPoster = poster.startsWith('http') ? poster : `${CDN_BUCKET}/videos${poster}`
  }

  return (
    <VideoPlayer controls poster={fullUrlPoster} title="Video" {...rest}>
      <source src={fullUrl} type="video/mp4" />
    </VideoPlayer>
  )
}
