import React from 'react'
import { Avatar, Banner } from '@revolut/ui-kit'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { ParentGoal } from '@src/interfaces/goals'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'

export const CascadedGoalBanner = ({
  linkPath,
  parent,
}: {
  linkPath: string
  parent: ParentGoal | null
}) => (
  <Banner>
    <Banner.Avatar>
      <Avatar useIcon="Info" />
    </Banner.Avatar>
    <Banner.Content>
      <Banner.Title>Goal already cascaded</Banner.Title>
      <Banner.Description>
        <FormattedMessage
          id="goal.form.cascaded.banner.description"
          defaultMessage={`This goal has already been cascaded and cannot be fully edited. ${
            parent && linkPath
              ? 'You can only update the owner of the goal. <A>To view or edit the parent goal, click the link.</A>'
              : ''
          }`}
          values={{
            A: ([text]) => {
              if (typeof text !== 'string' || !parent || !linkPath) {
                return null
              }

              return (
                <OpenInNewTab
                  label={text}
                  link={pathToFullUrlWithActiveWorkspace(linkPath, { id: parent.id })}
                />
              )
            },
          }}
        />
      </Banner.Description>
    </Banner.Content>
  </Banner>
)
