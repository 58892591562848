import React, { useState } from 'react'
import {
  Action,
  BottomSheet,
  Box,
  Button,
  Flex,
  Header,
  HStack,
  StatusPopup,
  Text,
  TextArea,
  TextSkeleton,
  Token,
  useStatusPopup,
  useToggle,
} from '@revolut/ui-kit'
import { usePostFeedbackRequest, useGetMyFeedbacks } from '@src/api/user'

interface RateSummaryProps {
  title: string
  objectId: number | string
  objectType: 'interviewtranscript' | 'interviewround'
  topic: 'recruitment'
  labelYes?: string
  labelNo?: string
}

export const FeedbackItem = ({
  objectId,
  objectType,
  title,
  topic,
  labelYes = 'Yes',
  labelNo = 'No',
}: RateSummaryProps) => {
  const statusPopup = useStatusPopup()
  const [commentValue, setCommentValue] = useState<string | undefined>()
  const [openRating, toggleOpenRating] = useToggle()
  const { data, isLoading } = useGetMyFeedbacks([
    {
      filters: [
        {
          id: objectId,
          name: String(objectId),
        },
      ],
      columnName: 'object_id',
    },
    {
      filters: [
        {
          id: objectType,
          name: objectType,
        },
      ],
      columnName: 'object_type',
    },
  ])
  const { mutateAsync: postFeedbackRequest, isLoading: pending } =
    usePostFeedbackRequest()

  // We only need to know if summary was accurate and a comment if it wasn't
  // so if the summary was accurate we send 1, if it wasn't then we have
  // the comment as optional and send 0
  const handleSubmitFeedback = async (score: 0 | 1) => {
    try {
      await postFeedbackRequest({
        status: { id: 'completed' },
        score,
        text: commentValue,
        topic: { id: topic },
        labels: [],
        object_type: objectType,
        object_id: objectId,
      })
      statusPopup.show(
        <StatusPopup variant="success" onClose={() => toggleOpenRating.off()}>
          <StatusPopup.Title>Thank you</StatusPopup.Title>
          <StatusPopup.Description>
            Your feedback is helping us to improve
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>Feedback was not submitted</StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }

  if (isLoading) {
    return (
      <Box px="s-16">
        <TextSkeleton aria-label="Loading user feedback" />
      </Box>
    )
  }

  const hasFeedback = Boolean(data?.results?.length)
  if (hasFeedback) {
    return null
  }

  return (
    <>
      <Flex py="s-8" px="s-16" justifyContent="space-between" alignItems="center">
        <Text color={Token.color.greyTone50} variant="small">
          {title}
        </Text>
        <HStack gap="s-8">
          <Action
            aria-label={labelYes}
            iconOnly
            useIcon="SocialLike"
            color={Token.color.greyTone50}
            onClick={() => {
              handleSubmitFeedback(1)
            }}
          />
          <Action
            aria-label={labelNo}
            iconOnly
            color={Token.color.greyTone50}
            useIcon="SocialDislike"
            onClick={() => {
              toggleOpenRating.on()
            }}
          />
        </HStack>
      </Flex>
      <BottomSheet open={openRating} onClose={toggleOpenRating.off}>
        <Header>
          <Header.Title>Why you chose this rating?</Header.Title>
        </Header>
        <BottomSheet.Description>
          <TextArea
            label="Provide additional feedback (optional)"
            rows={2}
            value={commentValue}
            onChange={e => setCommentValue(e.currentTarget.value)}
          />
        </BottomSheet.Description>
        <BottomSheet.Actions horizontal>
          <Button
            aria-label="Submit rating"
            elevated
            pending={pending}
            onClick={() => handleSubmitFeedback(0)}
          >
            Submit
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}
