import React, { useEffect } from 'react'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  metricUnitValueColumn,
  metricValuesColumn,
} from '@src/constants/columns/goals'
import { useTable } from '@src/components/Table/hooks'
import { goalsListTableRequests, GoalsStats } from '@src/api/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { GoalsInterface } from '@src/interfaces/goals'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { TableTypes } from '@src/interfaces/table'
import { Statuses } from '@src/interfaces'
import { VStack } from '@revolut/ui-kit'
import { toIdAndName } from '@src/utils/toIdAndName'

const initialSort = [
  { sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC },
]

const ROWS = {
  isNotNested: true,
  cells: [
    {
      ...goalsNameColumn(goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })),
      title: 'Child goal',
      width: 200,
    },
    {
      ...goalsOwnerColumn,
      width: 75,
    },
    {
      ...goalsProgressColumn,
      width: 80,
    },
    { ...metricValuesColumn, width: 130 },
    { ...metricUnitValueColumn, width: 60 },
  ],
}

export const ChildGoalsTable = ({
  onRefreshReady,
}: {
  onRefreshReady?: (refresh: () => void) => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const filters: FilterByInterface[] = [
    {
      filters: [{ name: 'parent_id', id: values.id }],
      columnName: 'parent_id',
      nonInheritable: true,
      nonResettable: true,
    },
    {
      columnName: 'approval_status',
      nonResettable: true,
      filters: [
        toIdAndName(Statuses.pending),
        toIdAndName(Statuses.approved),
        toIdAndName(Statuses.requires_changes),
      ],
    },
  ]

  const table = useTable(goalsListTableRequests, filters, initialSort)

  useEffect(() => {
    if (onRefreshReady) {
      onRefreshReady(table.refresh)
    }
  }, [onRefreshReady, table.refresh])

  const isEmptyTable = table.data.length === 0 && !table.loading

  return (
    <VStack gap="s-16">
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable<GoalsInterface, GoalsStats>
            name={TableNames.CascadeGoals}
            hideHeader={isEmptyTable}
            emptyState={
              <EmptyTableRaw
                title="No child goals yet"
                description="You can add child goals later"
              />
            }
            row={ROWS}
            useWindowScroll
            {...table}
            type={isEmptyTable ? undefined : TableTypes.Contained}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </VStack>
  )
}
