import React from 'react'
import {
  ActionButton,
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  IconName,
  Item,
  Spacer,
  Text,
  Token,
} from '@revolut/ui-kit'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { itemStylesOverrides } from '@src/features/AiInsights/Performance/components/InsightsGroups/common/styles'

interface Props {
  isDeletable: boolean
  title: React.ReactNode
  description?: React.ReactNode
  badge?: React.ReactNode
  iconName?: IconName
}

interface ActionProps {
  onEditClick: VoidFunction
  onDeleteClick: VoidFunction
}

export const InsightsGroupItem = ({
  title,
  description,
  isDeletable,
  badge,
  iconName = 'People',
  onEditClick,
  onDeleteClick,
}: Props & ActionProps) => {
  return (
    <Box borderRadius="r16" style={itemStylesOverrides}>
      <Item>
        <Item.Avatar>
          <Avatar useIcon={iconName} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          {description && <Item.Description>{description}</Item.Description>}
          {badge && (
            <>
              <Spacer height="s-8" />
              <Box>{badge}</Box>
            </>
          )}
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <Flex gap="s-8" flexWrap="wrap">
              <ActionButton useIcon="Pencil" onClick={onEditClick}>
                <FormattedMessage id="common.edit.cta.label" defaultMessage="Edit" />
              </ActionButton>
              {isDeletable && (
                <ActionButton variant="negative" useIcon="Delete" onClick={onDeleteClick}>
                  <FormattedMessage
                    id="common.delete.cta.label"
                    defaultMessage="Delete"
                  />
                </ActionButton>
              )}
            </Flex>
          </Item.Value>
        </Item.Side>
      </Item>
    </Box>
  )
}

export const DefaultBadge = () => (
  <Badge bg={Token.color.accent}>
    <HStack gap="s-4" align="center" px="s-4">
      <Icon name="Profile" size={14} />
      <Text variant="emphasis4">
        <FormattedMessage id="common.default" defaultMessage="Default" />
      </Text>
    </HStack>
  </Badge>
)

export const toInsightsGroupItemProps = ({
  name,
  is_default,
  sources,
}: PerformanceInsightsGroupInterface): Props => ({
  title: name,
  isDeletable: !is_default,
  badge: is_default ? <DefaultBadge /> : undefined,
  description:
    sources.length > 0 ? (
      <FormattedPlural
        value={sources.length}
        one={
          <FormattedMessage
            id="performance.ai.insights.count.one.label"
            values={{ count: sources.length }}
            defaultMessage="{count} insight"
          />
        }
        other={
          <FormattedMessage
            id="performance.ai.insights.count.other.label"
            values={{ count: sources.length }}
            defaultMessage="{count} insights"
          />
        }
      />
    ) : undefined,
})
