import { Cell, Flex, Icon, MoreBar, Token, VStack, Text, Box } from '@revolut/ui-kit'
import React, { useState } from 'react'
import Message from '../components/Message'
import { useTable } from '@src/components/Table/hooks'
import { roadmapsRequests, useGetRoadmapByName } from '@src/api/roadmaps'
import { useGetJiraPreferences } from '@src/api/integrations'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { AddJiraRoadmapsWidget } from './JiraSearch'
import { SidePreview } from './SidePreview'
import { NewRoadmapPopup } from './NewRoadmapPopup'
import { useFindTeamByName } from '@src/api/teams'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { TeamInterface } from '@src/interfaces/teams'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import PageError from '@src/components/PageError'
import { linkToCycles } from '@src/api/roadmaps'
import { pushError } from '@src/store/notifications/actions'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import Table from '@src/components/TableV2/Table'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'

const exampleNames = [
  '[Example] Team roadmap 1',
  '[Example] Team roadmap 2',
  '[Example] Team roadmap 3',
]

const Preview = ({
  defaultTeam,
  reviewCycle,
  exampleRoadmaps,
}: {
  defaultTeam?: TeamInterface
  reviewCycle: ReviewCyclesInterface
  exampleRoadmaps: RoadmapInterface[]
}) => {
  const jiraSettings = useGetJiraPreferences()
  const table = useTable(
    roadmapsRequests,
    [
      defaultTeam
        ? {
            columnName: 'team_id',
            filters: [{ id: defaultTeam.id, name: defaultTeam.name }],
          }
        : undefined,
      { columnName: 'review_cycle__offset', filters: [{ id: 0, name: '0' }] },
    ].filter(Boolean),
  )
  const [selected, setSelected] = useState<RoadmapInterface>()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const examplesAlreadyLinked = !!exampleRoadmaps.find(roadmap => !!roadmap.review_cycle)
  const [selectedTeam, setSelectedTeam] = useState<TeamInterface>()

  const team = defaultTeam || selectedTeam

  const [generateStatus, setGenerateStatus] = useState<
    'idle' | 'pending' | 'succeed' | 'failed'
  >(examplesAlreadyLinked ? 'succeed' : 'idle')

  const onGenerateClicked = async () => {
    setGenerateStatus('pending')
    try {
      await linkToCycles()
      table.refresh()
      setGenerateStatus('succeed')
    } catch (err) {
      pushError(err)
      setGenerateStatus('failed')
    }
  }

  if (jiraSettings.isLoading || jiraSettings.isFetching || jiraSettings.isRefetching) {
    return <div />
  }

  const getMessage = () => {
    if (jiraSettings.data?.enabled) {
      return (
        <Message
          title="Roadmaps can now be linked from Jira and added to Revolut People!"
          description="Feel free to take a look and try adding one yourself"
          storageKey="roadmaps_onboarding_preview_with_jira"
        />
      )
    }
    return examplesAlreadyLinked ? (
      <Message
        title="We have created some example roadmaps for you"
        description="Feel free to take a look and try creating one yourself"
        storageKey="roadmaps_onboarding_preview"
      />
    ) : null
  }

  const teamSelector = (
    <RadioSelectInput<TeamInterface>
      minWidth={250}
      value={selectedTeam}
      label="Select a team"
      selector={selectorKeys.team}
      onChange={t => setSelectedTeam(t || undefined)}
    />
  )

  return (
    <>
      <Flex gap="s-24">
        <VStack width="60%" space="s-16">
          {getMessage()}
          <Table.Widget>
            {jiraSettings.data?.enabled ? (
              <Table.Widget.Info>
                <VStack space="s-8">
                  <Flex width="100%" justifyContent="space-between">
                    <Text variant="primary">Linking epics</Text>
                    <Icon name="InfoOutline" color={Token.color.orange} />
                  </Flex>
                  <Text color={Token.color.greyTone50}>
                    Only <b>Epics</b> can be linked as roadmap items from Jira. They can
                    be added or removed at any time. In this preview, they will be
                    attached to the <b>{team?.name || 'selected team'}</b> team for the{' '}
                    <b>{reviewCycle.name}</b> cycle.
                  </Text>
                </VStack>
              </Table.Widget.Info>
            ) : (
              <>
                {!defaultTeam && <Table.Widget.Info>{teamSelector}</Table.Widget.Info>}
                <Table.Widget.Actions>
                  <Table.Widget.MoreBar>
                    {!!team && (
                      <MoreBar.Action useIcon="Plus" onClick={() => setIsOpenPopup(true)}>
                        Add new roadmap
                      </MoreBar.Action>
                    )}

                    {generateStatus !== 'succeed' &&
                    exampleRoadmaps.length &&
                    defaultTeam ? (
                      <MoreBar.Action
                        useIcon="AutoTopup"
                        onClick={onGenerateClicked}
                        pending={generateStatus === 'pending'}
                      >
                        Generate example roadmaps
                      </MoreBar.Action>
                    ) : null}
                  </Table.Widget.MoreBar>
                </Table.Widget.Actions>
              </>
            )}
            <Table.Widget.Table>
              <AdjustableTable
                {...table}
                dataType="roadmap item"
                onRowClick={roadmap => setSelected(roadmap)}
                name={TableNames.RoadmapsMain}
                row={{
                  highlight: roadmap =>
                    !jiraSettings.data?.enabled && roadmap === selected
                      ? Token.color.actionBackground
                      : '',
                  cells: [
                    {
                      ...roadmapGenericNameColumn,
                      width: 200,
                    },
                    {
                      ...roadmapPriorityColumn,
                      width: 100,
                    },
                    {
                      ...roadmapStatusColumn,
                      width: 100,
                    },
                    {
                      ...roadmapProgressColumn,
                      width: 150,
                    },
                    {
                      ...roadmapDueDate,
                      width: 100,
                    },
                  ],
                }}
              />
            </Table.Widget.Table>
          </Table.Widget>
        </VStack>
        <Box width="40%">
          {jiraSettings.data?.enabled && reviewCycle ? (
            <Cell>
              <AddJiraRoadmapsWidget
                onAfterSubmit={() => table.refresh()}
                entityId={team?.id}
                entityType={EntityTypes.team}
                reviewCycle={reviewCycle}
              />
            </Cell>
          ) : (
            selected && <SidePreview roadmap={selected} />
          )}
        </Box>
      </Flex>
      {team ? (
        <NewRoadmapPopup
          isOpen={isOpenPopup}
          onClose={() => setIsOpenPopup(false)}
          onAfterSubmit={() => table.refresh()}
          team={team}
          reviewCycle={reviewCycle}
        />
      ) : null}
    </>
  )
}

export default () => {
  const team = useFindTeamByName('Product · Core · IT')
  const canViewReviewCycles = useHasGlobalPermission(PermissionTypes.ViewReviewCycles)

  const cycle = useGetReviewCycleByOffset(0, { enabled: canViewReviewCycles })
  const exampleRoadmaps = useGetRoadmapByName(exampleNames.join(','))

  if (
    cycle.status === 'error' ||
    team.status === 'error' ||
    exampleRoadmaps.status === 'error'
  ) {
    return (
      <PageError
        title="Sorry!"
        text="There was an error loading this page. Please try to refresh."
      />
    )
  }

  if (!cycle.isLoading && !cycle.data) {
    return (
      <PageError
        title="Environment configuration error"
        text="Performance cycles setup is incorrect or not finished"
      />
    )
  }

  if (
    team.status === 'loading' ||
    cycle.status === 'loading' ||
    exampleRoadmaps.isLoading
  ) {
    return <div />
  }

  return (
    <Preview
      defaultTeam={team.data || undefined}
      reviewCycle={cycle.data!}
      exampleRoadmaps={exampleRoadmaps.data?.results || []}
    />
  )
}
