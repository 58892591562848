import React, { useMemo } from 'react'

import {
  CompensationReviewInterface,
  TeamCompensationReviewInterface,
} from '@src/interfaces/compensation'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  BudgetDistributionHeader,
  ShowInLocalCurrenciesButton,
  useBudgetDistributionContext,
  YearSelectButton,
} from '@src/features/BudgetDistribution/BudgetDistribution'
import Stat from '@src/components/Stat/Stat'
import { formatMoney } from '@src/utils/format'
import { budgetDistributionViewRow } from './BudgetDistributionTable'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { VStack } from '@revolut/ui-kit'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface TeamAndFunctionBudgetTableProps {
  navigation?: React.ReactElement
}

const isTeamReview = (
  review?: CompensationReviewInterface,
): review is TeamCompensationReviewInterface =>
  review != null && 'allocated_bonus_budget' in review

export const TeamAndFunctionBudgetTable = ({
  navigation,
}: TeamAndFunctionBudgetTableProps) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const row = useMemo(
    () => budgetDistributionViewRow(specialisationsEnabled),
    [specialisationsEnabled],
  )

  const { table, selectedCompensationReview, isEmpty } = useBudgetDistributionContext()

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <VStack width="100%">
          {navigation}
          <BudgetDistributionHeader
            stats={
              <>
                <Stat
                  label="Allocated salaries spend"
                  val={
                    isTeamReview(selectedCompensationReview)
                      ? formatMoney(
                          selectedCompensationReview.allocated_salary_budget,
                          selectedCompensationReview.allocated_salary_budget_currency
                            .iso_code,
                        )
                      : undefined
                  }
                />
                <Stat
                  label="Allocated bonus spend"
                  val={
                    isTeamReview(selectedCompensationReview)
                      ? formatMoney(
                          selectedCompensationReview.allocated_bonus_budget,
                          selectedCompensationReview.allocated_bonus_budget_currency
                            .iso_code,
                        )
                      : undefined
                  }
                />
              </>
            }
          />
        </VStack>
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <YearSelectButton />
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <ShowInLocalCurrenciesButton />
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.TeamAndFunctionBudget}
          {...table}
          loading={isEmpty ? false : table.loading}
          useWindowScroll
          row={row}
          emptyState={
            <EmptyTableRaw
              title="No budget data was uploaded yet"
              imageId="3D098"
              description="As soon as company budget for the cycle will be uploaded, the budget will appear here."
            />
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
