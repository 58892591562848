import React from 'react'
import { Avatar, Flex, Text } from '@revolut/ui-kit'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'

import { ProbationAndPipBarRaiserSection } from './types'
import {
  getBarRaiserGradeColor,
  GradeLabelMappingInterface,
  KeeperGradeToString,
} from '@src/interfaces/performance'

export const titleColumn: ColumnInterface<ProbationAndPipBarRaiserSection> = {
  type: CellTypes.text,
  idPoint: 'question',
  dataPoint: 'question',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: '',
}

export const lmColumn = (
  gradeLabelMapping: GradeLabelMappingInterface,
): ColumnInterface<ProbationAndPipBarRaiserSection> => ({
  type: CellTypes.insert,
  idPoint: 'lm',
  dataPoint: 'lm',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'LM',
  insert: ({ data }) => {
    return (
      <Flex alignItems="center" gap="s-8">
        {data.line_manager.employee ? (
          <>
            <UserAvatar {...data.line_manager.employee} />
            {'response' in data.line_manager && data.line_manager.response && (
              <Text
                variant="emphasis2"
                color={getBarRaiserGradeColor(data.line_manager.response)}
              >
                {KeeperGradeToString[data.line_manager.response]}
              </Text>
            )}
            {'finalGrade' in data.line_manager && data.line_manager.finalGrade && (
              <Text variant="emphasis2">
                {gradeLabelMapping[data.line_manager.finalGrade]}
              </Text>
            )}
          </>
        ) : (
          <>
            <Avatar label="" size={24} />
            <Text variant="emphasis2">-</Text>
          </>
        )}
      </Flex>
    )
  },
})

export const fmColumn = (
  gradeLabelMapping: GradeLabelMappingInterface,
): ColumnInterface<ProbationAndPipBarRaiserSection> => ({
  type: CellTypes.insert,
  idPoint: 'fm',
  dataPoint: 'fm',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'FM',
  insert: ({ data }) => {
    return (
      <Flex alignItems="center" gap="s-8">
        {data.functional_manager.employee ? (
          <>
            <UserAvatar {...data.functional_manager.employee} />
            {'response' in data.functional_manager && data.functional_manager.response && (
              <Text
                variant="emphasis2"
                color={getBarRaiserGradeColor(data.functional_manager.response)}
              >
                {KeeperGradeToString[data.functional_manager.response]}
              </Text>
            )}
            {'finalGrade' in data.functional_manager &&
              data.functional_manager.finalGrade && (
                <Text variant="emphasis2">
                  {gradeLabelMapping[data.functional_manager.finalGrade]}
                </Text>
              )}
          </>
        ) : (
          <>
            <Avatar label="" size={24} />
            <Text variant="emphasis2">-</Text>
          </>
        )}
      </Flex>
    )
  },
})
