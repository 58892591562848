import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getRequisitionInitialValues } from '@src/pages/Forms/RequisitionForm/General/General'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

type Props = {
  jobPostingId?: number
  newItemInitialValues?: Partial<RequisitionInterface>
}

const AddRequisitionButton = ({ jobPostingId, newItemInitialValues = {} }: Props) => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddRequisitions)

  if (!canAdd) {
    return null
  }

  const addNewRequisition = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {}), {
      initialValues: { ...getRequisitionInitialValues(user), ...newItemInitialValues },
      jobPostingId,
    })
  }

  return (
    <PrimaryAction
      useIcon="Plus"
      onClick={() => {
        addNewRequisition()
      }}
    >
      Add requisition
    </PrimaryAction>
  )
}

export default AddRequisitionButton
