import { useMemo } from 'react'
import { FilterByInterface } from '@src/interfaces/data'
import { BaseOption } from '@src/interfaces/selectors'
import { toIdAndName } from '@src/utils/toIdAndName'
import { useTable, useTableReturnType } from '@components/TableV2/hooks'
import {
  distributionGroupToKeyMap,
  DistributionKey,
  getTalentSkillRatingTableRequests,
  SkillRatingsDistributionFilterKeys,
  SkillRatingsDistributionGroupTypes,
} from '@src/features/ReviewCycle/Analytics/api/skills'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { useDistributionOptions } from '@src/features/ReviewCycle/Analytics/Skills/hooks/useDistributionOptions'
import { SeparatorProps } from '@src/features/ReviewCycle/Analytics/Skills/Tables/TalentSkillRating/RowSeparator'

interface Options {
  departmentId: number
  skillId: number
  groupType: SkillRatingsDistributionGroupTypes
}

export interface RowDataInterface extends TalentSkillRatingInterface {
  isSeparator?: boolean
  separatorProps?: SeparatorProps
}

export const useTableData = ({
  departmentId,
  skillId,
  groupType,
}: Options): useTableReturnType<TalentSkillRatingInterface, undefined> => {
  const { data: distributionOptions, isLoading: isDistributionOptionsLoading } =
    useDistributionOptions(groupType)

  const initialFilters = useMemo<FilterByInterface[]>(
    () => [
      {
        columnName: SkillRatingsDistributionFilterKeys.DepartmentId,
        filters: [toIdAndName(String(departmentId))],
        nonResettable: true,
      },
      {
        columnName: SkillRatingsDistributionFilterKeys.SkillId,
        filters: [toIdAndName(String(skillId))],
        nonResettable: true,
      },
    ],
    [departmentId, skillId],
  )

  const table = useTable(getTalentSkillRatingTableRequests(), initialFilters)

  return useMemo<useTableReturnType<TalentSkillRatingInterface, undefined>>(
    () => ({
      ...table,
      ...adaptTableData(
        table.data,
        distributionOptions,
        distributionGroupToKeyMap[groupType],
      ),
      loading: table.loading || isDistributionOptionsLoading,
    }),
    [table, isDistributionOptionsLoading, distributionOptions, groupType],
  )
}

const adaptTableData = (
  data: TalentSkillRatingInterface[],
  distributionOptions: BaseOption<string>[],
  distributionKey: DistributionKey,
): { data: RowDataInterface[]; count: number } => {
  const UNRATED_GROUP_NAME = 'Unrated'
  const anyRowWithRating = data.find(({ rating }) => rating !== null)

  if (!anyRowWithRating) {
    return {
      data,
      count: data.length,
    }
  }

  const distributionKeysToRowsMap: Record<string, TalentSkillRatingInterface[]> = {}

  data.forEach(row => {
    const distributionOption = distributionOptions.find(
      ({ id }) => id === row[distributionKey],
    )
    const key = distributionOption ? distributionOption.name : UNRATED_GROUP_NAME

    const rows = distributionKeysToRowsMap[key]

    if (!rows || rows.length === 0) {
      distributionKeysToRowsMap[key] = [row]
    } else {
      distributionKeysToRowsMap[key] = [...rows, row]
    }
  })

  const withSeparators = Object.entries(distributionKeysToRowsMap).reduce<
    RowDataInterface[]
  >((separatedRows, [key, rows]) => {
    const separator: RowDataInterface = {
      ...rows[0],
      isSeparator: true,
      separatorProps: { id: key.toLowerCase(), title: key, count: rows.length },
    }

    return [...separatedRows, separator, ...rows]
  }, [])

  return {
    data: withSeparators,
    count: data.length,
  }
}
