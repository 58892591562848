import React from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { PerformanceSelector } from '@src/interfaces/performance'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { smartGoalBaseRow, useSmartGoalsTable } from './PipSmartGoalsTable'
import {
  PipGoalSideBar,
  PipGoalSideBarProvider,
  usePIPGoalContext,
} from './PipSmartGoalSideBar'
import { TableNames } from '@src/constants/table'
import { TableTypes } from '@src/interfaces/table'

const Content = ({ employeeId, pipCycle }: Props) => {
  const table = useSmartGoalsTable(employeeId)
  const { open } = usePIPGoalContext()

  const onRowClick = (goal: DeliverableInterface) =>
    open({ variant: 'view', goal, pipCycle })

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<DeliverableInterface>
          name={TableNames.PipReviewGoals}
          type={TableTypes.Contained}
          dataType="Goal"
          row={smartGoalBaseRow}
          onRowClick={onRowClick}
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

interface Props {
  employeeId: string
  pipCycle: PerformanceSelector
}

export const PipSmartGoalsTableViewWidget = (props: Props) => (
  <PipGoalSideBarProvider>
    <Content {...props} />
    <PipGoalSideBar />
  </PipGoalSideBarProvider>
)
