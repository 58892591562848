import { CompetencyPerformanceWeight } from '@src/interfaces/roles'

export const sumPerformanceWeights = (
  performanceWeights?: CompetencyPerformanceWeight[],
) => {
  return (
    performanceWeights?.reduce(
      // need to parse float here and go with toFixed due to JS adding more numbers on float eg 33.3 + 33.4 = 66.69999999...9 instead of 66.7
      (sum, item) => parseFloat((sum + item.weight).toFixed(10)),
      0.0,
    ) || 0
  )
}
