import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ratingToColor } from '@src/utils/performance'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { RatingColumn } from '@src/features/ReviewCycle/Analytics/Skills/Tables/common/columns'

export const ratingColumn: ColumnInterface<TalentSkillRatingInterface> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Skill level',
  background: data => ratingToColor(data.rating) || 'inherit',
  insert: ({
    data: {
      rating,
      rating_category,
      rating_score,
      expected_rating,
      expected_rating_score,
      rating_expectation,
      lm_rating,
      fm_rating,
      self_rating,
      ...talent
    },
  }) => (
    <RatingColumn
      rating={{
        rating,
        rating_category,
        rating_score,
        expected_rating,
        expected_rating_score,
        rating_expectation,
        lm_rating,
        fm_rating,
        self_rating,
      }}
      talent={talent}
    />
  ),
}
