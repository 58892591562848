import React from 'react'
import { Group, Subheader } from '@revolut/ui-kit'
import { useAiInterviewSummarisationCandidateEnabled } from '@src/pages/Careers/hooks/useAiInterviewSummarisationCandidateEnabled'
import { InterviewTranscriptConsent } from '@src/features/InterviewTranscriptConsent/InterviewTranscriptConsent'
import { useGetCanddidateAISummarisationConsent } from '@src/api/recruitment/candidateScheduling'

interface Props {
  checked: boolean
  onCheck: (checked: boolean) => void
  token: string | null
  interviewId?: string | number
}

export const CandidateSchedulingInterviewTranscriptConsent = ({
  checked,
  onCheck,
  token,
  interviewId,
}: Props) => {
  const enabled = useAiInterviewSummarisationCandidateEnabled()
  const { data: consentData } = useGetCanddidateAISummarisationConsent(token, interviewId)

  if (!enabled) {
    return null
  }

  if (interviewId && token) {
    if (!consentData || consentData.consent_to_interview_ai_summarisation) {
      return null
    }
  }

  return (
    <Group>
      <Subheader>Interview transcripts</Subheader>
      <InterviewTranscriptConsent checked={checked} onCheck={onCheck} p="s-16" />
    </Group>
  )
}
