import React from 'react'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useInitialFilters } from '@src/features/Goals/GoalsTab'
import { GoalsTableFormWidget } from '@src/features/Goals/GoalsTableWidget'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'

export const GoalsTable = () => {
  const canViewReviewCycles = useHasGlobalPermission(PermissionTypes.ViewReviewCycles)
  const { data: currentCycle, isLoading: cycleLoading } = useGetReviewCycleByOffset(0, {
    enabled: canViewReviewCycles,
  })
  const initialFilters = useInitialFilters(currentCycle)
  return !cycleLoading && initialFilters ? (
    <GoalsTableFormWidget initialFilters={initialFilters} />
  ) : (
    <PageLoading />
  )
}
