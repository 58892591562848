import React from 'react'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { ROUTES } from '@src/constants/routes'
import { getSubscriptionPlans } from '@src/api/plans'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { SubscriptionPlanInterface } from '@src/interfaces/plans'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import {
  subscriptionPlanFeaturesColumn,
  subscriptionPlanNameColumn,
  subscriptionPlanPriceColumn,
  subscriptionPlanStatusColumn,
  subscriptionPlanTrialPeriodColumn,
} from '@src/constants/columns/plans'
import Table from '@src/components/TableV2/Table'

const row: RowInterface<SubscriptionPlanInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.SETTINGS.PLAN_MANAGEMENT.DETAILS, { id: data.id })),
  cells: [
    {
      ...subscriptionPlanNameColumn,
      width: 200,
    },
    {
      ...subscriptionPlanStatusColumn,
      width: 100,
    },

    {
      ...subscriptionPlanPriceColumn,
      width: 100,
    },
    {
      ...subscriptionPlanTrialPeriodColumn,
      width: 100,
    },
    { ...subscriptionPlanFeaturesColumn, width: 200 },
  ],
}

export const PlanList = () => {
  const table = useTable({ getItems: getSubscriptionPlans })

  return (
    <>
      <PageHeader title="Manage Plans" backUrl={ROUTES.SETTINGS.ALL} />

      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable row={row} name={TableNames.SubscriptionPlans} {...table} />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
