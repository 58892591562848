import React, { useEffect, useRef, useState } from 'react'
import {
  Ellipsis,
  FilterButtonSkeleton,
  Flex,
  Icon,
  MoreBar,
  Text,
  useToggle,
} from '@revolut/ui-kit'

import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { IdAndName } from '@src/interfaces'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'

export type UseSurveySelectorReturnType = {
  value: IdAndName | undefined
  setValue: (newValue: IdAndName) => void
  options: IdAndName[]
  isLoadingOptions: boolean
}
export const useSurveySelector = (): UseSurveySelectorReturnType => {
  const [selectedSurvey, setSelectedSurvey] = useState<IdAndName>()

  const { data: surveyOptions, isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted)

  useEffect(() => {
    if (surveyOptions?.[0]) {
      setSelectedSurvey(surveyOptions[0])
    }
  }, [surveyOptions])

  return {
    value: selectedSurvey,
    setValue: setSelectedSurvey,
    options: surveyOptions || [],
    isLoadingOptions: isLoadingSurveyOptions,
  }
}

export const SurveySelector = ({
  value,
  setValue,
  options,
  isLoadingOptions,
  onChange,
}: UseSurveySelectorReturnType & { onChange: VoidFunction }) => {
  const [open, toggleOpen] = useToggle()
  const selectRef = useRef<HTMLButtonElement>(null)

  if (isLoadingOptions) {
    return <FilterButtonSkeleton width={200} />
  }
  return (
    <>
      <MoreBar.Action
        useIcon="Questionnaire"
        data-testid="engagement-survey-selector"
        aria-haspopup="listbox"
        onClick={toggleOpen.switch}
        aria-expanded={open}
        ref={selectRef}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{value?.name || 'Survey'}</Text>
          </Ellipsis>

          <Icon name={open ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </MoreBar.Action>
      <FilterSelect<IdAndName>
        open={open}
        onClose={() => {
          toggleOpen.off()
        }}
        selector={() => Promise.resolve({ options })}
        anchorRef={selectRef}
        value={value ? [value] : undefined}
        onChange={newValue => {
          const [option] = newValue

          if (option) {
            toggleOpen.off()
            setValue(option)
            onChange()
          }
        }}
        type={FilterSelectType.SingleSelect}
        width={100}
      />
    </>
  )
}
