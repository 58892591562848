import { ColumnCellInterface, RowInterface } from '@src/interfaces/data'
import { CompetencyMatrixInterface } from '@src/interfaces/roles'
import {
  DeleteColumnProps,
  getDeleteColumn,
  getSeniorityColumn,
  getSkillTitleColumn,
  getWeightColumn,
  SeniorityColumnProps,
  WeightColumnProps,
} from './columns'

interface Props
  extends DeleteColumnProps,
    WeightColumnProps,
    Omit<SeniorityColumnProps, 'seniority'> {
  withWeightColumn: boolean
  withActionColumn: boolean
}

export const row = ({
  onChangeCompetency,
  onDelete,
  skillsets,
  competencyMatrices,
  seniorities,
  firstRowTitle,
  withWeightColumn,
  onChangeWeight,
  weightsError,
  filterNonExistent,
  readOnly,
  refetchSkills,
  withActionColumn,
}: Props): RowInterface<CompetencyMatrixInterface> => {
  const cells: ColumnCellInterface<CompetencyMatrixInterface>[] = [
    { ...getSkillTitleColumn({ firstRowTitle }), width: 280 },
    ...seniorities.map(seniority => ({
      ...getSeniorityColumn({
        onChangeCompetency,
        seniorities,
        seniority,
        filterNonExistent,
        firstRowTitle,
        competencyMatrices,
        skillsets,
        readOnly,
        refetchSkills,
      }),
      width: 112,
    })),
  ]

  if (withWeightColumn) {
    cells.push({
      ...getWeightColumn({
        firstRowTitle,
        onChangeWeight,
        readOnly,
        weightsError,
        competencyMatrices,
      }),
      width: 60,
    })
  }

  if (
    withActionColumn &&
    !competencyMatrices?.every(d => !!d.hideActionsColumn) &&
    !readOnly
  ) {
    cells.push({
      ...getDeleteColumn({ firstRowTitle, onDelete, competencyMatrices }),
      width: 40,
    })
  }
  return {
    noChildrenRequest: true,
    cells,
  }
}
