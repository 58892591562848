import React from 'react'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import { IconName } from '@revolut/ui-kit'
import { EligibleGroupFiltersInterface } from '@src/interfaces/reviewCycles'

export const getFilterKeyIcon = (key: keyof EligibleGroupFiltersInterface): IconName =>
  filterTypeToIconMap[key] ?? unknownFilterIcon

export const unknownFilterIcon = 'Filter'

const filterTypeToIconMap: Record<keyof EligibleGroupFiltersInterface, IconName> = {
  id: 'Profile',
  team_id: 'People',
  team__department_id: 'Utilities',
  specialisation_id: 'Palette',
  position_id: 'Puzzle',
  position__function_id: 'Services',
  status: 'StarFilled',
  joining_date_time: 'Calendar',
  is_in_probation: 'Search',
  end_of_probation_date_time: 'Calendar',
  is_in_pip: 'Search',
  end_of_pip_date_time: 'Calendar',
  contract_type: 'DocumentsPair',
  employee_type: 'Document',
  location_id: 'LocationPin',
  entity_id: 'Bank',
}

export const getFilterLabel = (
  key: keyof EligibleGroupFiltersInterface,
  count: number,
): React.ReactNode => {
  const formatter = filterTypeToLabelFormatterMap[key]

  if (!formatter) {
    return <FilterLabel count={count} />
  }

  return formatter(count)
}

export const FilterLabel = ({ count }: { count: number }) => (
  <FormattedPlural
    value={count}
    one={
      <FormattedMessage
        id="performance.insights.group.eligibility.filters.one.label"
        values={{ count }}
        defaultMessage="{count} filter"
      />
    }
    other={
      <FormattedMessage
        id="performance.insights.group.eligibility.filters.other.label"
        values={{ count }}
        defaultMessage="{count} filters"
      />
    }
  />
)

const filterTypeToLabelFormatterMap: Record<
  keyof EligibleGroupFiltersInterface,
  (count: number) => React.ReactNode
> = {
  id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.employee.name.one.label"
          values={{ count }}
          defaultMessage="{count} name"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.employee.name.other.label"
          values={{ count }}
          defaultMessage="{count} names"
        />
      }
    />
  ),
  team_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.team.one.label"
          values={{ count }}
          defaultMessage="{count} team"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.team.other.label"
          values={{ count }}
          defaultMessage="{count} teams"
        />
      }
    />
  ),
  team__department_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.department.one.label"
          values={{ count }}
          defaultMessage="{count} department"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.department.other.label"
          values={{ count }}
          defaultMessage="{count} departments"
        />
      }
    />
  ),
  specialisation_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.specialisation.one.label"
          values={{ count }}
          defaultMessage="{count} specialisation"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.specialisation.other.label"
          values={{ count }}
          defaultMessage="{count} specialisations"
        />
      }
    />
  ),
  position_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.position.one.label"
          values={{ count }}
          defaultMessage="{count} position"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.position.other.label"
          values={{ count }}
          defaultMessage="{count} positions"
        />
      }
    />
  ),
  position__function_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.function.one.label"
          values={{ count }}
          defaultMessage="{count} function"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.function.other.label"
          values={{ count }}
          defaultMessage="{count} functions"
        />
      }
    />
  ),
  status: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.status.one.label"
          values={{ count }}
          defaultMessage="{count} status"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.status.other.label"
          values={{ count }}
          defaultMessage="{count} statuses"
        />
      }
    />
  ),
  joining_date_time: _ => (
    <FormattedMessage
      id="performance.insights.group.eligibility.filter.start.date.label"
      defaultMessage="start date"
    />
  ),
  is_in_probation: _ => (
    <FormattedMessage
      id="performance.insights.group.eligibility.filter.probation.status.label"
      defaultMessage="probation status"
    />
  ),
  end_of_probation_date_time: _ => (
    <FormattedMessage
      id="performance.insights.group.eligibility.filter.probation.date.label"
      defaultMessage="probation date"
    />
  ),
  is_in_pip: _ => (
    <FormattedMessage
      id="performance.insights.group.eligibility.filter.pip.status.label"
      defaultMessage="pip status"
    />
  ),
  end_of_pip_date_time: _ => (
    <FormattedMessage
      id="performance.insights.group.eligibility.filter.pip.date.label"
      defaultMessage="pip date"
    />
  ),
  contract_type: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.contract.type.one.label"
          values={{ count }}
          defaultMessage="{count} contract type"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.contract.type.other.label"
          values={{ count }}
          defaultMessage="{count} contract types"
        />
      }
    />
  ),
  employee_type: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.employee.type.one.label"
          values={{ count }}
          defaultMessage="{count} employee type"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.employee.type.other.label"
          values={{ count }}
          defaultMessage="{count} employee types"
        />
      }
    />
  ),
  location_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.location.one.label"
          values={{ count }}
          defaultMessage="{count} location"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.location.other.label"
          values={{ count }}
          defaultMessage="{count} locations"
        />
      }
    />
  ),
  entity_id: count => (
    <FormattedPlural
      value={count}
      one={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.entity.one.label"
          values={{ count }}
          defaultMessage="{count} entity"
        />
      }
      other={
        <FormattedMessage
          id="performance.insights.group.eligibility.filter.entity.other.label"
          values={{ count }}
          defaultMessage="{count} entities"
        />
      }
    />
  ),
}
