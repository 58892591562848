import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { departmentRequests } from '@src/api/department'
import { entitiesRequests } from '@src/api/entities'
import { teamsRequests } from '@src/api/teams'
import { rolesRequests } from '@src/api/roles'
import { UpdateOrganisationUnitType } from '@src/interfaces/updateOrganisationStructure'
import { TableNames } from '@src/constants/table'
import { teamGenericNameColumn } from '@src/constants/columns/team'
import { roleGenericNameColumn, roleHeadcountColumn } from '@src/constants/columns/role'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import {
  departmentGenericNameColumn,
  departmentHeadcountColumn,
} from '@src/constants/columns/department'
import {
  ownerNameColumn,
  roleManagerColumn,
  teamOwnerColumn,
} from '@src/constants/columns/employee'
import {
  entityContractingCountriesColumn,
  entityGenericNameColumn,
  entityPaygroupCountriesColumn,
  entityRegistrationCountryColumn,
  entityRegistrationNumberColumn,
  entityStatusColumn,
} from '@src/constants/columns/entity'
import { HStack, Token } from '@revolut/ui-kit'
import {
  deleteDepartment,
  deleteEntity,
  deleteRole,
  deleteTeam,
} from '@src/api/updateOrganisationStructure'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { specialisationsRequests } from '@src/api/specialisations'
import {
  specialisationGenericNameColumn,
  specialisationHeadcountColumn,
  specialisationManagerColumn,
} from '@src/constants/columns/specialisation'
import { notReachable } from '@src/utils/notReachable'
import { toIdAndName } from '@src/utils/toIdAndName'
import { FormattedMessage } from 'react-intl'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const typeToRoute = {
  department: ROUTES.FORMS.DEPARTMENT.SETTINGS,
  team: ROUTES.FORMS.TEAM.SETTINGS,
  role: ROUTES.FORMS.ROLE.EDIT.GENERAL_INFO,
  entity: ROUTES.FORMS.ENTITY.GENERAL,
  specialisation: ROUTES.FORMS.SPECIALISATIONS.EDIT.GENERAL_INFO,
}

export const typeToTitle: Record<UpdateOrganisationUnitType, React.ReactNode> = {
  department: (
    <FormattedMessage
      id="updateOrganisationStructure.title.department"
      defaultMessage="Departments"
    />
  ),
  team: (
    <FormattedMessage
      id="updateOrganisationStructure.title.team"
      defaultMessage="Teams"
    />
  ),
  role: (
    <FormattedMessage
      id="updateOrganisationStructure.title.role"
      defaultMessage="Roles"
    />
  ),
  entity: (
    <FormattedMessage
      id="updateOrganisationStructure.title.entity"
      defaultMessage="Legal Entities"
    />
  ),
  specialisation: (
    <FormattedMessage
      id="updateOrganisationStructure.title.specialisation"
      defaultMessage="Specialisations"
    />
  ),
}

export const typeToSearchLabel = {
  department: 'Search by department name',
  team: 'Search by team name',
  role: 'Search by role name',
  entity: 'Search by entity name',
  specialisation: 'Search by specialisation name',
}

export const typeToBackUrl = {
  department: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
  team: ROUTES.ORGANISATION.TEAMS.TEAMS,
  role: ROUTES.ORGANISATION.ROLES.ROLES,
  specialisation: ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
  entity: ROUTES.APPS.ENTITIES,
}

export const typeToTableRequests = {
  department: departmentRequests,
  team: teamsRequests,
  role: rolesRequests,
  entity: entitiesRequests,
  specialisation: specialisationsRequests,
}

export const typeToNamePlularised: Record<UpdateOrganisationUnitType, React.ReactNode> = {
  department: (
    <FormattedMessage
      id="updateOrganisationStructure.department.label.other"
      defaultMessage="departments"
    />
  ),
  team: (
    <FormattedMessage
      id="updateOrganisationStructure.team.label.other"
      defaultMessage="teams"
    />
  ),
  role: (
    <FormattedMessage
      id="updateOrganisationStructure.role.label.other"
      defaultMessage="roles"
    />
  ),
  entity: (
    <FormattedMessage
      id="updateOrganisationStructure.entity.label.other"
      defaultMessage="entities"
    />
  ),
  specialisation: (
    <FormattedMessage
      id="updateOrganisationStructure.specialisation.label.other"
      defaultMessage="specialisations"
    />
  ),
}

export const typeToName: Record<UpdateOrganisationUnitType, React.ReactNode> = {
  department: (
    <FormattedMessage
      id="updateOrganisationStructure.department.label.one"
      defaultMessage="department"
    />
  ),
  team: (
    <FormattedMessage
      id="updateOrganisationStructure.team.label.one"
      defaultMessage="team"
    />
  ),
  role: (
    <FormattedMessage
      id="updateOrganisationStructure.role.label.one"
      defaultMessage="role"
    />
  ),
  entity: (
    <FormattedMessage
      id="updateOrganisationStructure.entity.label.one"
      defaultMessage="entity"
    />
  ),
  specialisation: (
    <FormattedMessage
      id="updateOrganisationStructure.specialisation.label.one"
      defaultMessage="specialisation"
    />
  ),
}

export const typeToTableName = {
  department: TableNames.DepartmentsStructure,
  team: TableNames.TeamsStructure,
  role: TableNames.RolessStructure,
  entity: TableNames.EntitiesStructure,
  specialisation: TableNames.SpecialisationsStructure,
}

const typeToDeleteApi = {
  department: deleteDepartment,
  team: deleteTeam,
  role: deleteRole,
  entity: deleteEntity,
  specialisation: specialisationsRequests.deleteItem,
}

interface UpdateOrganisationStructureActionProps {
  id: number
  type: UpdateOrganisationUnitType
  onRefresh: () => void
}

const UpdateOrganisationStructureAction = ({
  id,
  type,
  onRefresh,
}: UpdateOrganisationStructureActionProps) => {
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <HStack space="s-8">
      <TableActionButton
        use={InternalLink}
        to={pathToUrl(typeToRoute[type], { id })}
        aria-label="Edit"
        useIcon="Pencil"
      />

      <LapeDeleteOrgUnitButton
        onAfterDelete={onRefresh}
        prefix={type}
        displayName={type}
        renderButton={({ onClick }) => {
          return (
            <TableActionButton
              onClick={onClick}
              color={Token.color.danger}
              useIcon="Delete"
              aria-label="Delete"
            />
          )
        }}
        deleteApi={() => {
          const submitFunction = typeToDeleteApi[type]
          if (submitFunction) {
            return submitFunction(id)
          }
          throw new Error(`change owner for ${type} not supported`)
        }}
        checkPermissions={false}
        forcedId={id}
        confirmMessage={`Are you sure you want to delete this ${
          type === 'specialisation' && !isSpecialisationsEnabled ? 'role' : type
        }`}
        showPendingPopup
      />
    </HStack>
  )
}

export const updateOrganisationStructureActionColumn = (
  type: UpdateOrganisationUnitType,
  onRefresh: () => void,
): ColumnInterface<{ id: number }> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <UpdateOrganisationStructureAction type={type} onRefresh={onRefresh} id={data.id} />
  ),
})

const getTypeToRow = (isSpecialisationEnabled: boolean) => ({
  department: [
    {
      ...departmentGenericNameColumn,
      width: 300,
    },
    {
      ...departmentHeadcountColumn,
      width: 250,
    },
    {
      ...ownerNameColumn,
      width: 250,
    },
  ],
  team: [
    {
      ...teamGenericNameColumn,
      width: 232,
    },
    {
      ...departmentHeadcountColumn,
      width: 130,
    },
    {
      ...teamOwnerColumn,
      width: 160,
    },
  ],
  role: [
    {
      ...roleGenericNameColumn,
      width: 220,
    },
    {
      ...roleHeadcountColumn,
      width: 100,
    },
    {
      ...roleManagerColumn,
      width: 200,
    },
  ],
  specialisation: [
    { ...specialisationGenericNameColumn(isSpecialisationEnabled), width: 220 },
    {
      ...specialisationHeadcountColumn,
      width: 100,
    },
    {
      ...specialisationManagerColumn,
      width: 200,
    },
  ],
  entity: [
    {
      ...entityGenericNameColumn,
      width: 150,
    },
    {
      ...entityRegistrationCountryColumn,
      width: 120,
    },
    {
      ...entityRegistrationNumberColumn,
      width: 120,
    },
    {
      ...entityPaygroupCountriesColumn,
      width: 300,
    },
    {
      ...entityContractingCountriesColumn,
      width: 300,
    },
    {
      ...entityStatusColumn,
      width: 100,
    },
  ],
})

const mapTypeToInitialFilter = (type: UpdateOrganisationUnitType) => {
  switch (type) {
    case 'department':
    case 'team':
    case 'entity':
      return [toIdAndName('active')]
    case 'role':
    case 'specialisation':
      return [toIdAndName('pending'), toIdAndName('approved'), toIdAndName('draft')]
    default:
      return notReachable(type)
  }
}

export const typeToDefaultFilter = (type: UpdateOrganisationUnitType) => [
  {
    columnName: 'status',
    filters: mapTypeToInitialFilter(type),
    nonResettable: true,
  },
]

export const getRow = (
  type: UpdateOrganisationUnitType,
  onRefresh: () => void,
  isSpecialisationEnabled: boolean,
) => ({
  cells: [
    {
      ...getSelectCellConfig(),
    },
    ...getTypeToRow(isSpecialisationEnabled)[type],
    {
      ...updateOrganisationStructureActionColumn(type, onRefresh),
      width: 80,
    },
  ],
})
