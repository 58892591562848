import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { RoleInterface } from '@src/interfaces/roles'
import { PermissionTypes } from '@src/store/auth/types'

export const getRoleSpecialisationTitle = (specialisationsEnabled: boolean) =>
  specialisationsEnabled ? 'Role (Specialisation)' : 'Role'

export const getRolesSpecialisationsTitle = (specialisationsEnabled: boolean) =>
  specialisationsEnabled ? 'Roles (Specialisations)' : 'Role'

export const getSpecialisationTitle = (specialisationsEnabled: boolean) =>
  specialisationsEnabled ? 'Specialisation' : 'Role'

export const getSpecialisationsTitle = (specialisationsEnabled: boolean) =>
  specialisationsEnabled ? 'Specialisations' : 'Roles'

export const SpecialisationFilterTitle = ({ count }: { count: number }) => {
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()

  if (count > 0) {
    return isSpecialisationsEnabled ? (
      <FormattedMessage
        id="filters.specialisation.active.title"
        defaultMessage="Selected specialisations"
      />
    ) : (
      <FormattedMessage id="filters.role.active.title" defaultMessage="Selected roles" />
    )
  }

  return isSpecialisationsEnabled ? (
    <FormattedMessage
      id="filters.specialisation.inactive.title"
      defaultMessage="All specialisations"
    />
  ) : (
    <FormattedMessage id="filters.role.inactive.title" defaultMessage="All roles" />
  )
}

export const checkCanEditRole = (values: RoleInterface) =>
  !values.id ||
  [PermissionTypes.AddRoles, PermissionTypes.ChangeRoles].some(p =>
    values.field_options?.permissions?.includes(p),
  )
