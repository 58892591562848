import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceInsightsDomain,
  PerformanceInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { InsightsRelation } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { ViewCommentAction } from '@src/features/AiInsights/Performance/components/Insights/InsightsActions/ViewAction'
import { SubmitCommentAction } from '@src/features/AiInsights/Performance/components/Insights/InsightsActions/SubmitAction'

interface Props {
  insights: PerformanceInsightsInterface
  employee: EmployeeInterface
  relation: InsightsRelation
  domain: PerformanceInsightsDomain
  refreshInsights: VoidFunction
}

export const InsightsActions = ({
  insights: { id, comment },
  relation,
  domain,
  employee,
  refreshInsights,
}: Props) => {
  if (relation === 'manager') {
    if (!comment) {
      return null
    }

    return <ViewCommentAction comment={comment} employee={employee} />
  }

  if (!comment) {
    return (
      <SubmitCommentAction
        insightId={id}
        mode="create"
        domain={domain}
        employee={employee}
        onSuccess={refreshInsights}
      />
    )
  }

  return (
    <SubmitCommentAction
      insightId={id}
      comment={comment}
      mode="edit"
      domain={domain}
      employee={employee}
      onSuccess={refreshInsights}
    />
  )
}
