import { useGetEmployeePerformanceKeyInsights } from '@src/features/AiInsights/Performance/api/insights'
import { InsightsRelation } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { InsightsOptionInterface } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/types'
import { toInsightsOption } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/adaptors'
import {
  employeeFMInsightsOptionName,
  employeeLMInsightsOptionName,
  managerInsightsOptionName,
} from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/constants'

interface Args {
  employeeId: number
  cycleId: number
  relation: InsightsRelation
  lmId?: number
  fmId?: number
}

interface Props {
  insights: InsightsOptionInterface[]
  isLoading: boolean
  refresh: VoidFunction
}

export const usePerformanceInsights = ({
  employeeId,
  lmId,
  fmId,
  cycleId,
  relation,
}: Args): Props => {
  const {
    data: managerInsights,
    isLoading: isManagerInsightsLoading,
    refetch: refetchManagerInsights,
  } = useGetEmployeePerformanceKeyInsights(
    employeeId,
    {
      cycleId,
    },
    { enabled: relation === 'manager' },
  )

  const {
    data: employeeLMInsights,
    isLoading: isEmployeeLMInsightsLoading,
    refetch: refetchEmployeeLMInsights,
  } = useGetEmployeePerformanceKeyInsights(
    employeeId,
    {
      cycleId,
      managerId: lmId,
    },
    { enabled: relation === 'self' && lmId !== undefined },
  )

  const {
    data: employeeFMInsights,
    isLoading: isEmployeeFMInsightsLoading,
    refetch: refetchEmployeeFMInsights,
  } = useGetEmployeePerformanceKeyInsights(
    employeeId,
    {
      cycleId,
      managerId: fmId,
    },
    { enabled: relation === 'self' && fmId !== undefined },
  )

  const getInsightsByRelation = (): InsightsOptionInterface[] => {
    if (relation === 'manager' && managerInsights) {
      return [toInsightsOption(managerInsights, managerInsightsOptionName)]
    }

    if (isEmployeeLMInsightsLoading || isEmployeeFMInsightsLoading) {
      return []
    }

    return [
      employeeLMInsights &&
        toInsightsOption(employeeLMInsights, employeeLMInsightsOptionName),
      employeeFMInsights &&
        toInsightsOption(employeeFMInsights, employeeFMInsightsOptionName),
    ].filter(Boolean)
  }

  const refresh = () => {
    if (relation === 'manager') {
      refetchManagerInsights()
    }

    if (relation === 'self') {
      refetchEmployeeLMInsights()
      refetchEmployeeFMInsights()
    }
  }

  return {
    insights: getInsightsByRelation(),
    isLoading:
      isManagerInsightsLoading ||
      isEmployeeLMInsightsLoading ||
      isEmployeeFMInsightsLoading,
    refresh,
  }
}
