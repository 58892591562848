import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getTimeOffPolicyChangelog } from '@src/api/timeOff'
import { selectorKeys } from '@src/constants/api'
import {
  changelogChangedByWithAvatar,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogFieldsOldValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { TableNames } from '@src/constants/table'
import { PageBody } from '@src/components/Page/PageBody'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { CellTypes, SORT_DIRECTION } from '@src/interfaces/data'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const changelogRow = (specialisationsEnabled: boolean) => ({
  cells: [
    {
      ...changelogFieldsChangedFields(specialisationsEnabled),
      selectorsKey: selectorKeys.time_off_policy_changelog_fields,
      width: 200,
    },
    {
      ...changelogFieldsOldValue,
      width: 200,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      sortKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 150,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      type: CellTypes.dateTime,
      width: 120,
    },
    {
      ...changelogStatus,
      selectorsKey: selectorKeys.changelog_item_statuses,
      width: 100,
    },
  ],
})

export const Changelog = () => {
  const { id } = useParams<{ id: string }>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const row = useMemo(
    () => changelogRow(specialisationsEnabled),
    [specialisationsEnabled],
  )
  const table = useTable({ getItems: getTimeOffPolicyChangelog(id) }, undefined, [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
      nonResettable: true,
    },
  ])

  return (
    <PageBody maxWidth="100%">
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable
            {...table}
            dataType="Logs"
            emptyState={<EmptyTableRaw title="List of logs will appear here" />}
            name={TableNames.PolicyChangelog}
            row={row}
            useWindowScroll
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
}
