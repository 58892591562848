import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import {
  changelogChangedBy,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { Statuses } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OfferCreationInterface } from '@src/interfaces/offerCreation'
import { changelogFieldsOfferRequests } from '@src/api/offerCreation'
import { Token } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

const FieldsChangelogRequisitionRow: RowInterface<
  ChangelogInterface<OfferCreationInterface>
> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields(),
      selectorsKey: selectorKeys.requisition_changelog_fields,
      width: 210,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
}

const Changelog = () => {
  const { values } = useLapeContext<OfferCreationInterface>()

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const changelogFieldsTable = useTable<ChangelogInterface<OfferCreationInterface>>(
    changelogFieldsOfferRequests(values.id),
    undefined,
    initialSort,
  )

  const handleRowClick = (change: ChangelogInterface<OfferCreationInterface>) => {
    navigateReplace(
      `${pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, {
        id: values.id,
        candidateId: values.candidate?.id,
      })}?change=${change.id}`,
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<ChangelogInterface<OfferCreationInterface>>
          name={TableNames.OfferChangelog}
          useWindowScroll
          dataType="Data Change"
          row={FieldsChangelogRequisitionRow}
          {...changelogFieldsTable}
          onRowClick={handleRowClick}
          noDataMessage="There are no changes to display"
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Changelog
