import React from 'react'
import {
  Box,
  Group,
  Icon,
  IconButton,
  Item,
  Token,
  TransitionCollapse,
  useToggle,
} from '@revolut/ui-kit'
import { MetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { itemStylesOverrides } from '@src/features/AiInsights/Performance/components/InsightsGroups/common/styles'

interface Props {
  id: MetricSources
  title: React.ReactNode
  avatar: React.ReactNode
  side?: React.ReactNode
  expandedContent?: React.ReactNode
}

export const SourceItem = ({ id, title, avatar, side, expandedContent }: Props) => {
  const [isExpanded, expandedToggler] = useToggle()

  return (
    <Box
      borderRadius="r16"
      style={itemStylesOverrides}
      bg={Token.color.widgetBackground}
      data-testid={`performance_ai_insights_group_source_item_${id}`}
    >
      <Group>
        <Item>
          <Item.Prefix>
            {expandedContent ? (
              <IconButton
                useIcon={isExpanded ? 'ChevronUp' : 'ChevronRight'}
                color={Token.color.greyTone50}
                onClick={expandedToggler.switch}
                aria-label="Expand"
              />
            ) : (
              <Icon name="Dot" color={Token.color.greyTone50} />
            )}
          </Item.Prefix>
          <Item.Avatar>{avatar}</Item.Avatar>
          <Item.Content>
            <Item.Title>{title}</Item.Title>
          </Item.Content>
          {side && <Item.Side>{side}</Item.Side>}
        </Item>
        <TransitionCollapse in={isExpanded}>{expandedContent}</TransitionCollapse>
      </Group>
    </Box>
  )
}
