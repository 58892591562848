import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, HStack, Spacer, Subheader } from '@revolut/ui-kit'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { KeyInsights } from '@src/features/AiInsights/Performance/components/Summary/KeyInsights'
import { DetailsButton } from '@src/features/AiInsights/Performance/components/Insights/InsightsWidget/DetailsButton'
import { SummaryVariant } from '@src/features/AiInsights/Performance/components/Summary/Summary'

interface Props {
  summary: string
  summaryVariant: SummaryVariant
  isLoading: boolean
  hasDetails: boolean
  updatedAt: string
  title?: React.ReactNode
  actions?: React.ReactNode
  onDetailsClick: VoidFunction
}

export const InsightsWidget = ({
  summary,
  summaryVariant,
  isLoading,
  hasDetails,
  updatedAt,
  title,
  actions,
  onDetailsClick,
}: Props) => {
  return (
    <Box>
      <HStack mt="-s-12" align="center">
        <Subheader>
          <Subheader.Title>
            <HStack space="s-6" align="center">
              <AiIcon />
              {title || (
                <FormattedMessage
                  id="performance.insights.subheader.title"
                  defaultMessage="Insights"
                />
              )}
            </HStack>
          </Subheader.Title>
        </Subheader>
        <Spacer />
        {actions && actions}
      </HStack>
      <KeyInsights
        variant={summaryVariant}
        summary={summary}
        updatedAt={updatedAt}
        details={
          <>
            {(hasDetails || isLoading) && (
              <DetailsButton isLoading={isLoading} onClick={onDetailsClick} />
            )}
          </>
        }
      />
    </Box>
  )
}
