import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Text } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { JiraEpicInterface } from '@src/interfaces/roadmaps'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import Table from '@components/TableV2/Table'
import { getRoadmapPriorityColor } from '@src/constants/columns/roadmap'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { formatPercentage } from '@src/utils/format'

export const epicNameColumn: ColumnInterface<JiraEpicInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'epic_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data, children }) => {
    const epicName = children ?? (
      <Text textDecoration="italic">
        <FormattedMessage
          id="roadmaps.jira.epic.unnamed.label"
          defaultMessage="[Unnamed epic]"
        />
      </Text>
    )

    return (
      <Table.ItemCell useIcon="JiraEpic|image">
        <Table.ItemCell.Title>
          {data.epic_url ? (
            <TableCellLink to={{ pathname: data.epic_url }} target="_blank">
              {epicName}
            </TableCellLink>
          ) : (
            { epicName }
          )}
        </Table.ItemCell.Title>
      </Table.ItemCell>
    )
  },
  title: 'Epic',
}

export const epicPriorityColumn: ColumnInterface<JiraEpicInterface> = {
  type: CellTypes.text,
  idPoint: 'priority',
  dataPoint: 'priority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: getRoadmapPriorityColor,
  title: 'Priority',
}

export const epicContributionColumn: ColumnInterface<JiraEpicInterface> = {
  type: CellTypes.insert,
  idPoint: 'contribution',
  dataPoint: 'contribution',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => formatPercentage(data.contribution),
  headerTooltip: (
    <Box p="s-8" width={240}>
      <FormattedMessage
        id="performance.insights.metrics.jira.epic.contribution.explanation.message"
        defaultMessage="Contribution is calculated as the number of tickets completed by an employee divided by the total number of tickets in the epic."
      />
    </Box>
  ),
  title: 'Contribution',
}

export const epicTicketsClosedColumn: ColumnInterface<JiraEpicInterface> = {
  type: CellTypes.text,
  idPoint: 'issues_closed',
  dataPoint: 'issues_closed',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Tickets closed',
}
