import React from 'react'
import { chain, Flex } from '@revolut/ui-kit'

export interface SeparatorProps {
  id: string
  title: string
  count: number
}

export const RowSeparator = ({ id, title, count }: SeparatorProps) => (
  <Flex
    alignItems="center"
    px="s-16"
    width="100%"
    data-testid={`performance_analytics_skills_distribution_table_separator_${id}`}
  >
    {chain(title, count)}
  </Flex>
)
