import React, { useState } from 'react'
import {
  ActionButton,
  Button,
  Flex,
  Header,
  Popup,
  StatusPopup,
  Text,
} from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { bulkDeletePayments, paymentsTableRequests } from '@src/api/payroll'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { getSelectCellConfig } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { useTable } from '@src/components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import {
  paymentsAmountColumn,
  paymentsCycleColumn,
  paymentsDirectionColumn,
  paymentsElementColumn,
  paymentsEmployeeColumn,
  paymentsPaygroupColumn,
  paymentsStatusColumn,
} from '@src/constants/columns/payments'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PayCyclePaymentInterface } from '@src/interfaces/payroll'
import { pathToUrl } from '@src/utils/router'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import pluralize from 'pluralize'
import { FilterButtons } from './FilterButtons'

const Row: RowInterface<PayCyclePaymentInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    { ...paymentsEmployeeColumn, width: 150 },
    { ...paymentsPaygroupColumn, width: 150 },
    { ...paymentsCycleColumn, width: 130 },
    { ...paymentsElementColumn, width: 130 },
    { ...paymentsDirectionColumn, width: 150 },
    { ...paymentsAmountColumn, width: 150 },
    { ...paymentsStatusColumn, width: 100 },
  ],
}

export const DeletePaymentsTable = () => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<PayCyclePaymentInterface>>()
  const [submitPopupOpen, setSubmitPopupOpen] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const table = useTable(paymentsTableRequests)

  const chargesCount = selectedData?.isAllSelected
    ? table.count - selectedData.excludeListIds.size
    : selectedData?.selectedRowsData.length || 0

  const onDeleteSelected = async () => {
    if (!selectedData?.selectedRowsData && !selectedData?.isAllSelected) {
      return
    }

    const params = selectedData?.isAllSelected
      ? {
          ...groupFiltersIntoURLQuery(table.filterBy),
          excluded_ids: Array.from(selectedData.excludeListIds).join(','),
        }
      : {
          id: selectedData?.selectedRowsData?.map(payment => payment.id).join(','),
        }

    setIsDeleting(true)
    try {
      await bulkDeletePayments(params)
      setSuccessPopupOpen(true)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Delete payments in bulk"
        backUrl={ROUTES.APPS.PAYROLL.PAYMENTS_TABLE}
      />
      <Table.Widget>
        <Flex mb="s-16" justifyContent="space-between">
          <Flex gap="s-32">
            <Stat
              label={`${pluralize('Charge', chargesCount)} selected`}
              val={chargesCount}
            />
          </Flex>
          <FilterButtons table={table} />
        </Flex>
        <Table.Widget.Actions>
          <ActionButton
            variant="negative"
            useIcon="Delete"
            onClick={() => setSubmitPopupOpen(true)}
            pending={isDeleting}
            disabled={!chargesCount}
          >
            Delete selected payments
          </ActionButton>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            primaryKey="id"
            tableDataLength={table.data.length}
            tableData={table.data}
          >
            <AdjustableTable
              name={TableNames.PaymentsBulkDelete}
              row={Row}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>
      <Popup
        open={submitPopupOpen}
        onClose={() => setSubmitPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="main">
          <Header.Title>Delete payments</Header.Title>
        </Header>
        <Text use="p" variant="caption" mb="s-16">
          Clicking the button below will delete {pluralize('charge', chargesCount, true)}.
          Are you sure you want to proceed?
        </Text>
        <Popup.Actions horizontal>
          <Button
            variant="negative"
            elevated
            onClick={onDeleteSelected}
            disabled={chargesCount === 0}
          >
            Delete payments
          </Button>
        </Popup.Actions>
      </Popup>
      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          navigateTo(pathToUrl(ROUTES.APPS.PAYROLL.PAYMENTS_TABLE))
        }}
        // @ts-ignore
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Payments successfully deleted</StatusPopup.Title>
      </StatusPopup>
    </PageWrapper>
  )
}
