import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { VStack } from '@revolut/ui-kit'
import { Country, PerformanceGradeOption } from '@src/interfaces/selectors'
import {
  PerformanceReviewAnalyticsGroupTypes,
  PerformanceReviewAnalyticsInterface,
} from '@src/interfaces/performance'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import { Row } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/Row'
import {
  GridBarChartPlaceholder,
  GridBarChartSkeleton,
} from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/BarChart'
import { Legend } from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/Legend'
import { DISTRIBUTION_CHART_COLORS } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/constants'

interface Props {
  data: PerformanceReviewAnalyticsInterface[]
  dataKeys: PerformanceGradeOption[]
  countries: Country[]
  isLoading: boolean
  containerHeight?: number
}

export const GradeDistributionBarChart = ({
  data,
  dataKeys,
  countries,
  isLoading,
  containerHeight,
}: Props) => {
  const [prevRowsCount, setPrevRowsCount] = useState<number>(data.length)

  useEffect(() => {
    if (isLoading) {
      return
    }

    setPrevRowsCount(data.length)
  }, [data.length, isLoading])

  if (isLoading) {
    return (
      <GridBarChartSkeleton
        chartHeight={containerHeight}
        rowsCount={prevRowsCount > 0 ? prevRowsCount : undefined}
      />
    )
  }

  if (!data.length) {
    return (
      <GridBarChartPlaceholder
        title={
          <FormattedMessage
            id="performance.analytics.grades.distribution.chart.empty.data.title"
            defaultMessage="There is no grades distribution data available"
          />
        }
      />
    )
  }

  return (
    <VStack space="s-16" width="100%">
      {data.map(datum => {
        const labelImage =
          datum.group.type === PerformanceReviewAnalyticsGroupTypes.Country
            ? getCountryFlagImageByCode(
                countries.find(({ id }) => id === datum.group.id)?.country_code,
              )
            : undefined

        return (
          <Row
            key={datum.group.id}
            data={datum}
            dataKeys={dataKeys}
            colors={DISTRIBUTION_CHART_COLORS}
            labelImage={labelImage}
          />
        )
      })}
      <Legend dataKeys={dataKeys} colors={DISTRIBUTION_CHART_COLORS} />
    </VStack>
  )
}
