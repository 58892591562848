import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  Avatar,
  InputGroup,
  Item,
  VStack,
  Text,
  Flex,
  Tag,
  Skeleton,
} from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import {
  DefaultLayoutItem,
  OrganisationStructure,
  OrganisationStructureTab,
} from '../types'
import { useGetSelectors } from '@src/api/selectors'
import { API, selectorKeys } from '@src/constants/api'
import { times } from '@src/utils/times'
import { UploadAvatarPreview } from '@src/components/AvatarUploadPopup/components/UploadAvatarPreview'
import { EntityAvatarUpload } from '@src/features/EntityAvatarUpload/EntityAvatarUpload'
import { useUpdateStructure } from '../api'
import { StructureTabsSidebar } from './components/TabsSidebar'
import { toIdAndName } from '@src/utils/toIdAndName'
import { EditStructureTabPopup } from './components/EditStructureTabPopup'

export const StructureGeneral = () => {
  const { id } = useParams<{ id?: string }>()
  const { values, reset } = useLapeContext<OrganisationStructure>()

  const [openedSidebar, setOpenedSidebar] = useState<'tabs' | 'fields' | null>(null)
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false)
  const [editTab, setEditTab] = useState<number | null>(null)

  const structureTabs = useGetSelectors<DefaultLayoutItem>(
    selectorKeys.org_structure_tabs,
  )

  useEffect(() => {
    if (values.default_layout == null && structureTabs.data) {
      values.default_layout = structureTabs.data.map(tab => ({
        ...tab,
        state: toIdAndName('enabled'),
      }))
    }
  }, [structureTabs.data, values])

  const onChangeSidebarTabs = (tabs: OrganisationStructureTab[]) => {
    values.default_layout = tabs
  }

  return (
    <>
      <PageHeader
        title={id ? 'Edit structure' : 'New structure'}
        subtitle="Manage details and choose default fields and pages for levels of this company structure."
        backUrl={
          id
            ? pathToUrl(ROUTES.SETTINGS.STRUCTURE.PREVIEW, { id })
            : ROUTES.SETTINGS.STRUCTURES.ALL
        }
      />

      <PageBody>
        <NewStepperTitle title="General details" noAutoStep />
        <InputGroup>
          <LapeNewInput
            name="name"
            label="Name"
            required
            description="Choose the name of the structure to use in navigation, settings and employee details"
          />
          <LapeNewTextArea
            name="description"
            label="Description"
            required
            description="Provide a description to help your employees understand the structure purpose"
          />
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Make structure mandatory',
              description:
                'Enable to enforce any employee to be added to at least one level of this structure',
            }}
            name="mandatory_employee_assignment"
          />
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Display in navigation',
              description:
                'Enable to view and navigate through this structure in the Organisation section',
            }}
            name="display_in_navigation"
          />
        </InputGroup>

        <NewStepperTitle
          title="Default level settings"
          subtitle="Choose default fields and pages for levels of this company structure. These settings could be overridden for each level."
          noAutoStep
        />
        <InputGroup>
          {values.id ? (
            <Item
              onClick={() => setAvatarUploadOpen(true)}
              use="button"
              type="button"
              variant="disclosure"
            >
              <Item.Avatar>
                <UploadAvatarPreview useIcon={values.icon} avatar={values.avatar} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Default icon</Item.Title>
                <Item.Description>
                  Set a default icon to visually represent this structure across the
                  platform
                </Item.Description>
              </Item.Content>
            </Item>
          ) : null}

          <Item
            onClick={() => setOpenedSidebar('tabs')}
            use="button"
            type="button"
            variant="disclosure"
          >
            <Item.Avatar>
              <Avatar useIcon="Browser" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Default tabs</Item.Title>
              <Item.Description>
                <VStack space="s-8">
                  <Text>
                    Configure which tabs will be available for levels of this structure
                  </Text>
                  <Flex flexWrap="wrap" gap="s-8">
                    {values.default_layout
                      ?.filter(tab => tab.state?.id === 'enabled')
                      .map(tab => (
                        <Tag variant="status" useIcon={tab.icon} key={tab.id}>
                          {tab.name}
                        </Tag>
                      )) || (
                      <>
                        {times(
                          index => (
                            <Skeleton width={100} height={24} key={index} />
                          ),
                          9,
                        )}
                      </>
                    )}
                  </Flex>
                </VStack>
              </Item.Description>
            </Item.Content>
          </Item>
        </InputGroup>
      </PageBody>

      {openedSidebar ? null : (
        <PageActions>
          <NewSaveButtonWithPopup
            successText="Structure saved successfully"
            previewUrl={ROUTES.SETTINGS.STRUCTURE.PREVIEW}
            useValidator
          >
            {id ? 'Update' : 'Create'}
          </NewSaveButtonWithPopup>
        </PageActions>
      )}

      <EntityAvatarUpload
        open={avatarUploadOpen}
        onClose={() => setAvatarUploadOpen(false)}
        onSuccess={response =>
          reset({ ...values, icon: response?.icon, avatar: response?.avatar })
        }
        api={useUpdateStructure}
        apiUrl={API.ORGANISATION_STRUCTURES}
        id={values.id}
        icon={values.icon}
      />

      <StructureTabsSidebar
        isOpen={openedSidebar === 'tabs'}
        tabs={values.default_layout}
        onChange={onChangeSidebarTabs}
        onClose={() => setOpenedSidebar(null)}
        onTabClick={tabId => setEditTab(tabId)}
        title="Manage page tabs"
        subtitle="Define the default layout of pages of this structure"
        allowReorder
      />

      <EditStructureTabPopup
        open={!!editTab}
        onClose={() => setEditTab(null)}
        initialValues={values.default_layout?.find(f => f.id === editTab)}
        onSave={tabState => {
          values.default_layout = values.default_layout.map(tab =>
            tab.id === tabState.id ? tabState : tab,
          )
          setEditTab(null)
        }}
        key={editTab}
      />
    </>
  )
}
