import React, { useState } from 'react'

import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Statuses } from '@src/interfaces'
import {
  ActionButton,
  Text,
  Token,
  chain,
  Item,
  Avatar,
  IconName,
  Color,
  Group,
  Icon,
  VStack,
} from '@revolut/ui-kit'
import { ReviewCategory } from '@src/interfaces/performance'
import { StyledLink } from '@src/components/Atoms/StyledLink'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getInitials } from '@src/utils/employees'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { approveProbationGoals } from '@src/api/probationReview'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { approvePipGoals } from '@src/api/pip'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface Props {
  lineManager: EmployeeOptionInterface
  goalsStatus: Statuses.approved | Statuses.pending_approval | null | undefined
  canAddGoals: boolean
  canApproveGoals: boolean
  canApprovePip: boolean
  onClickAddGoals: () => void
  isCreateGoalPending: boolean
  reviewCategory: ReviewCategory
  approveGoalsArguments: {
    employeeId: number
    cycleId: string
    checkpointId: number | undefined
  }
}

const getStatusSettings = (status: Props['goalsStatus']) => {
  switch (status) {
    case Statuses.approved:
      return { color: Token.color.foreground, title: 'Approved' }
    case Statuses.pending_approval:
      return { color: Token.color.greyTone50, title: 'Pending' }
    default:
      return { color: Token.color.greyTone50, title: '' }
  }
}

const getBadge = (status?: Statuses | null): { icon: IconName; bg: Color } | null => {
  if (!status) {
    return null
  }

  switch (status) {
    case Statuses.completed:
    case Statuses.completed_late:
    case Statuses.approved:
      return { icon: 'Check', bg: Token.color.foreground }
    case Statuses.rejected:
      return { icon: 'Cross', bg: Token.color.red }
    case Statuses.expired:
      return { icon: 'Cross', bg: Token.color.greyTone50 }

    default:
      return { icon: '16/SandWatch', bg: Token.color.orange }
  }
}

export const GoalsStepReviewers = ({
  lineManager,
  goalsStatus,
  reviewCategory,
  canAddGoals,
  canApproveGoals,
  canApprovePip,
  onClickAddGoals,
  isCreateGoalPending,
  approveGoalsArguments,
}: Props) => {
  const { color, title } = getStatusSettings(goalsStatus)
  const badge = getBadge(goalsStatus)

  return (
    <Group width="100%" style={transparentThemeBackgroundOverrides}>
      {!lineManager ? (
        <Item py="s-8">
          <Item.Avatar>
            <Avatar label="LM" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Line Manager</Item.Title>
            <Item.Description>Assign line manager to set goals</Item.Description>
          </Item.Content>
        </Item>
      ) : (
        <Item py="s-8">
          <Item.Avatar>
            <Avatar
              image={lineManager.avatar}
              label={lineManager.full_name ? getInitials(lineManager.full_name) : 'LM'}
            >
              {badge && (
                <Avatar.Badge
                  bg={badge.bg}
                  position="bottom-right"
                  useIcon={badge.icon}
                />
              )}
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Line Manager</Item.Title>
            <Item.Description>
              {chain(
                <StyledLink
                  onClick={e => e.stopPropagation()}
                  to={getLocationDescriptor(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                      id: lineManager.id,
                    }),
                  )}
                >
                  {lineManager.full_name}
                </StyledLink>,
                `Set ${
                  reviewCategory === ReviewCategory.Probation ? 'probation' : 'PIP'
                } goals`,
              )}
            </Item.Description>
          </Item.Content>
          <Item.Side>
            {canAddGoals && goalsStatus !== Statuses.approved ? (
              <ActionButton onClick={onClickAddGoals} pending={isCreateGoalPending}>
                Add goals
              </ActionButton>
            ) : (
              <Text variant="body1" color={color}>
                {title}
              </Text>
            )}
          </Item.Side>
        </Item>
      )}

      <Item py="s-8">
        <Item.Avatar>
          <Avatar label="HR">
            {badge && (
              <Avatar.Badge bg={badge.bg} position="bottom-right" useIcon={badge.icon} />
            )}
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>HR Manager</Item.Title>
          <Item.Description>
            {`Confirm ${
              reviewCategory === ReviewCategory.Probation ? 'probation' : 'PIP'
            } goals`}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          {reviewCategory === ReviewCategory.Probation && (
            <ProbationHRSide
              approveGoalsArguments={approveGoalsArguments}
              canApproveGoals={canApproveGoals}
              goalsStatus={goalsStatus}
            />
          )}
          {[ReviewCategory.PIP, ReviewCategory.PIP_V2].includes(reviewCategory) && (
            <PipHRSide
              approveGoalsArguments={approveGoalsArguments}
              canApprovePip={canApprovePip}
              goalsStatus={goalsStatus}
            />
          )}
        </Item.Side>
      </Item>
    </Group>
  )
}

const ProbationHRSide = ({
  approveGoalsArguments,
  goalsStatus,
  canApproveGoals,
}: Pick<Props, 'approveGoalsArguments' | 'canApproveGoals' | 'goalsStatus'>) => {
  const confirmationPopup = useConfirmationDialog()
  const [isApprovingProbationGoals, setIsApprovingProbationGoals] = useState(false)
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()
  const handleApproveProbationGoals = async () => {
    if (
      approveGoalsArguments.checkpointId &&
      approveGoalsArguments.employeeId &&
      approveGoalsArguments.cycleId
    ) {
      setIsApprovingProbationGoals(true)
      try {
        await approveProbationGoals(
          approveGoalsArguments.employeeId,
          approveGoalsArguments.cycleId,
          approveGoalsArguments.checkpointId,
        )
        queryClient.refetchQueries({
          queryKey: [
            `${API.EMPLOYEES}/${approveGoalsArguments.employeeId}${API.PERFORMANCE}/cycles/${approveGoalsArguments.cycleId}/probationCheckpoints`,
          ],
        })
      } catch (error) {
        errorPopup.show({ error, fallbackTitle: 'Goals not approved' })
      } finally {
        setIsApprovingProbationGoals(false)
      }
    }
  }

  return (
    <>
      {goalsStatus === Statuses.pending_approval && canApproveGoals && (
        <ActionButton
          disabled={
            !approveGoalsArguments.checkpointId ||
            !approveGoalsArguments.employeeId ||
            !approveGoalsArguments.cycleId
          }
          onClick={() =>
            confirmationPopup.show({
              label: 'Approve probation goals',
              onConfirm: handleApproveProbationGoals,
              yesMessage: 'Approve',
              noMessage: 'Cancel',
            })
          }
          pending={isApprovingProbationGoals}
        >
          Approve goals
        </ActionButton>
      )}
      {goalsStatus === Statuses.approved && <Text>Completed</Text>}
    </>
  )
}

const PipHRSide = ({
  approveGoalsArguments,
  goalsStatus,
  canApprovePip,
}: Pick<Props, 'approveGoalsArguments' | 'canApprovePip' | 'goalsStatus'>) => {
  const confirmationPopup = useConfirmationDialog()
  const [isApprovingPip, setIsApprovingPip] = useState(false)
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()
  const handleApprovePIP = async () => {
    if (
      approveGoalsArguments.checkpointId &&
      approveGoalsArguments.employeeId &&
      approveGoalsArguments.cycleId
    ) {
      setIsApprovingPip(true)
      try {
        await approvePipGoals(
          approveGoalsArguments.employeeId,
          approveGoalsArguments.checkpointId,
          approveGoalsArguments.cycleId,
        )
        queryClient.refetchQueries({
          queryKey: [
            `${API.EMPLOYEES}/${approveGoalsArguments.employeeId}${API.PERFORMANCE}/cycles/${approveGoalsArguments.cycleId}/pipCheckpoints`,
          ],
        })
      } catch (error) {
        errorPopup.show({ error, fallbackTitle: 'Goals not approved' })
      } finally {
        setIsApprovingPip(false)
      }
    }
  }

  return (
    <>
      {goalsStatus === Statuses.pending_approval && canApprovePip && (
        <ActionButton
          disabled={
            !approveGoalsArguments.checkpointId ||
            !approveGoalsArguments.employeeId ||
            !approveGoalsArguments.cycleId
          }
          onClick={() =>
            confirmationPopup.show({
              body: (
                <VStack align="center" color={Token.color.foreground}>
                  <Icon size={52} name="Flag" />
                  <Text variant="h5" use="h5">
                    Approve goals & start PIP
                  </Text>
                </VStack>
              ),
              onConfirm: handleApprovePIP,
              yesMessage: 'Confirm',
              noMessage: 'Cancel',
              showHeader: false,
            })
          }
          pending={isApprovingPip}
        >
          Approve goals & start PIP
        </ActionButton>
      )}
      {goalsStatus === Statuses.approved && <Text>Completed</Text>}
    </>
  )
}
