import React, { useState } from 'react'
import { Group, MoreBar, TextButton, VStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  deleteUploadRequest,
  deleteSignatureRequest,
  sendSignatureRequest,
  transferIssuerSignatureRequest,
} from '@src/api/onboardingEmployeesV2'
import {
  approveDocumentUpload,
  deleteDocumentUploadFile,
  rejectDocumentUpload,
  useGetUploadedDocument,
} from '@src/api/documents'
import { DocumentUploadRequestInterface } from '@src/interfaces/documents'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  OnboardingStatus,
  OnboardingTemplateInterface,
} from '@src/interfaces/onboardingV2'
import { selectorKeys } from '@src/constants/api'
import { getDocumentStatusColor } from '@src/constants/columns/documents'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import { FormPreview } from '@components/FormPreview/FormPreview'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import SideBar from '@components/SideBar/SideBar'
import { DocumentPreview } from '@src/features/DocumentSidebar/DocumentPreview'
import SettingsButtons from '@src/features/SettingsButtons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { getTemplateStatusColor } from '../utils'

type Props = {
  document?: OnboardingTemplateInterface
  employeeId: string
  onboardingId: string
  onboardingStatus: OnboardingStatus
  onClose: VoidFunction
  onRefetchDocumentsSignature: VoidFunction
  onRefetchDocumentsUpload: VoidFunction
}

export const PreviewTemplateSidebar = ({
  document,
  employeeId,
  onboardingId,
  onboardingStatus,
  onClose,
  onRefetchDocumentsSignature,
  onRefetchDocumentsUpload,
}: Props) => {
  const [isApprovalPending, setIsApprovalPending] = useState(false)
  const [isDeletePending, setIsDeletePending] = useState(false)
  const [isDeleteRequestPending, setIsDeleteRequestPending] = useState(false)
  const [isRejectPending, setIsRejectPending] = useState(false)
  const [isSendSignaturePending, setIsSendSignaturePending] = useState(false)
  const [issuerPending, setIssuerPending] = useState(false)
  const [issuerPopupOpen, setIssuerPopupOpen] = useState(false)
  const [newIssuer, setNewIssuer] = useState<EmployeeOptionInterface>()

  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  const isEsignatureTemplate =
    document?.document_template.template_type.id === 'esignature'
  const isRequestTemplate = document?.document_template.template_type.id === 'request'
  const isPendingRequest = document?.status?.id === 'pending_request'

  const {
    data: uploadedDocument,
    isLoading: isLoadingUploadedDocument,
    refetch: refetchUploadedDocument,
  } = useGetUploadedDocument(
    Number(employeeId),
    isRequestTemplate ? document.uploaded_document?.id : undefined,
  )

  const isPendingApproval = uploadedDocument?.status?.id === 'pending_approval'
  const isPendingUpload = uploadedDocument?.status?.id === 'pending_upload'

  const onConfirmTransferIssuer = async () => {
    if (!newIssuer || !document) {
      return
    }
    try {
      setIssuerPending(true)
      await transferIssuerSignatureRequest(onboardingId, document.id, newIssuer)
      showStatusPopup({
        title: 'Changed issuer successfully',
      })
      onRefetchDocumentsSignature()
      onClose()
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Failed to change issuer',
        forceFallbackTitle: true,
      })
    } finally {
      setIssuerPending(false)
      setIssuerPopupOpen(false)
      setNewIssuer(undefined)
    }
  }

  const renderDeleteUploadRequesButton = (id: number) => {
    return (
      <MoreBar.Action
        onClick={async () => {
          try {
            setIsDeleteRequestPending(true)
            await deleteUploadRequest(onboardingId, id)
            onRefetchDocumentsUpload()
            onClose()
          } catch (error) {
            errorPopup.show({
              error,
              fallbackTitle: 'Failed to delete request',
              forceFallbackTitle: true,
            })
          } finally {
            setIsDeleteRequestPending(false)
          }
        }}
        pending={isDeleteRequestPending}
        useIcon="Delete"
        variant="negative"
      >
        Delete request
      </MoreBar.Action>
    )
  }

  const renderSidebarContent = () => {
    if (isEsignatureTemplate) {
      return (
        <VStack gap="s-16">
          {isPendingRequest && (
            <SettingsButtons>
              {onboardingStatus === 'in_progress' && (
                <MoreBar.Action
                  onClick={async () => {
                    try {
                      setIsSendSignaturePending(true)
                      await sendSignatureRequest(onboardingId, document.id)
                      onRefetchDocumentsSignature()
                      onClose()
                    } catch (error) {
                      errorPopup.show({
                        error,
                        fallbackTitle: 'Failed to send request',
                        forceFallbackTitle: true,
                      })
                    } finally {
                      setIsSendSignaturePending(false)
                    }
                  }}
                  pending={isSendSignaturePending}
                  useIcon="Pencil"
                  variant="accent"
                >
                  Send for signature
                </MoreBar.Action>
              )}
              <MoreBar.Action
                onClick={() => setIssuerPopupOpen(true)}
                pending={issuerPending}
                useIcon="ArrowExchange"
              >
                Change issuer
              </MoreBar.Action>
              <MoreBar.Action
                onClick={async () => {
                  try {
                    setIsDeleteRequestPending(true)
                    await deleteSignatureRequest(onboardingId, document.id)
                    onRefetchDocumentsSignature()
                    onClose()
                  } catch (error) {
                    errorPopup.show({
                      error,
                      fallbackTitle: 'Failed to delete request',
                      forceFallbackTitle: true,
                    })
                  } finally {
                    setIsDeleteRequestPending(false)
                  }
                }}
                pending={isDeleteRequestPending}
                useIcon="Delete"
                variant="negative"
              >
                Delete request
              </MoreBar.Action>
            </SettingsButtons>
          )}
          <FormPreview data={document}>
            <Group>
              <FormPreview.Item<OnboardingTemplateInterface>
                hidden={!document.document_template.category}
                field="document_template.category.name"
                title="Category"
                insert={esignatureTemplate => {
                  return (
                    <TextButton
                      onClick={() => {
                        onClose()
                        navigateTo(
                          pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                            employeeId,
                            categoryId: esignatureTemplate.document_template.category?.id,
                          }),
                        )
                      }}
                    >
                      {esignatureTemplate.document_template.category?.name}
                    </TextButton>
                  )
                }}
              />
              <FormPreview.Item<OnboardingTemplateInterface>
                color={() => getTemplateStatusColor(document.status.id)}
                field="status.name"
                title="Status"
              />
              <FormPreview.Item<OnboardingTemplateInterface>
                field="created_by.name"
                title="Issued by"
                to={esignatureTemplate =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: esignatureTemplate.created_by.id,
                  })
                }
              />
              <FormPreview.Item
                field="creation_date_time"
                title="Created on"
                type="date"
              />
              <FormPreview.Item field="update_date_time" title="Updated on" type="date" />
            </Group>
          </FormPreview>
        </VStack>
      )
    }
    if (isRequestTemplate) {
      if (isLoadingUploadedDocument) {
        return <Loader />
      }

      if (!uploadedDocument) {
        return (
          <VStack gap="s-16">
            <SettingsButtons>
              {renderDeleteUploadRequesButton(document.id)}
            </SettingsButtons>
            <FormPreview data={document}>
              <Group>
                <FormPreview.Item<OnboardingTemplateInterface>
                  hidden={!document.document_template.category}
                  field="document_template.category.name"
                  title="Category"
                  insert={uploadTemplate => {
                    return (
                      <TextButton
                        onClick={() => {
                          onClose()
                          navigateTo(
                            pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                              employeeId,
                              categoryId: uploadTemplate.document_template.category?.id,
                            }),
                          )
                        }}
                      >
                        {uploadTemplate.document_template.category?.name}
                      </TextButton>
                    )
                  }}
                />
                <FormPreview.Item<OnboardingTemplateInterface>
                  color={() => getTemplateStatusColor(document.status.id)}
                  field="status.name"
                  title="Status"
                />
                <FormPreview.Item<OnboardingTemplateInterface>
                  field="created_by.name"
                  title="Issued by"
                  to={uploadTemplate =>
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                      id: uploadTemplate.created_by.id,
                    })
                  }
                />
                <FormPreview.Item
                  field="creation_date_time"
                  title="Created on"
                  type="date"
                />
                <FormPreview.Item
                  field="update_date_time"
                  title="Updated on"
                  type="date"
                />
              </Group>
            </FormPreview>
          </VStack>
        )
      }

      return (
        <DocumentPreview
          id={Number(uploadedDocument.id)}
          document={uploadedDocument}
          renderActions={() => (
            <SettingsButtons>
              {isPendingApproval && (
                <>
                  <MoreBar.Action
                    onClick={async () => {
                      try {
                        setIsApprovalPending(true)
                        await approveDocumentUpload(employeeId, uploadedDocument.id)
                        refetchUploadedDocument()
                        onRefetchDocumentsUpload()
                      } finally {
                        setIsApprovalPending(false)
                      }
                    }}
                    pending={isApprovalPending}
                    useIcon="Check"
                  >
                    Approve
                  </MoreBar.Action>
                  <MoreBar.Action
                    onClick={async () => {
                      try {
                        setIsRejectPending(true)
                        await rejectDocumentUpload(employeeId, uploadedDocument.id)
                        refetchUploadedDocument()
                        onRefetchDocumentsUpload()
                      } finally {
                        setIsRejectPending(false)
                      }
                    }}
                    pending={isRejectPending}
                    useIcon="Cross"
                    variant="negative"
                  >
                    Reject
                  </MoreBar.Action>
                  <MoreBar.Action
                    onClick={async () => {
                      try {
                        setIsDeletePending(true)
                        await deleteDocumentUploadFile(uploadedDocument.id)
                        refetchUploadedDocument()
                        onRefetchDocumentsUpload()
                      } finally {
                        setIsDeletePending(false)
                      }
                    }}
                    pending={isDeletePending}
                    useIcon="Delete"
                    variant="negative"
                  >
                    Delete document
                  </MoreBar.Action>
                </>
              )}
              {isPendingUpload && renderDeleteUploadRequesButton(document.id)}
            </SettingsButtons>
          )}
          renderAbovePreview={() => (
            <FormPreview data={uploadedDocument}>
              <Group>
                <FormPreview.Item<DocumentUploadRequestInterface>
                  hidden={!uploadedDocument.category}
                  field="category.name"
                  title="Category"
                  insert={uploadDocument => {
                    return (
                      <TextButton
                        onClick={() => {
                          onClose()
                          navigateTo(
                            pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                              employeeId,
                              categoryId: uploadDocument.category?.id,
                            }),
                          )
                        }}
                      >
                        {uploadDocument.category?.name}
                      </TextButton>
                    )
                  }}
                />
                <FormPreview.Item
                  color={() => getDocumentStatusColor(uploadedDocument.status.id)}
                  field="status.name"
                  title="Status"
                />
                <FormPreview.Item<DocumentUploadRequestInterface>
                  field="issuer.name"
                  to={uploadDocument =>
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                      id: uploadDocument.issuer.id,
                    })
                  }
                  title="Issued by"
                />
                <FormPreview.Item
                  field="creation_date_time"
                  title="Created on"
                  type="date"
                />
                <FormPreview.Item
                  field="updated_date_time"
                  title="Updated on"
                  type="date"
                />
              </Group>
            </FormPreview>
          )}
        />
      )
    }
    return null
  }

  return (
    <>
      <SideBar
        isOpen={!!document}
        onClose={onClose}
        sideProps={{ resizable: true }}
        title={document?.document_template.name}
      >
        {renderSidebarContent()}
      </SideBar>

      <ConfirmationDialog
        onClose={() => setIssuerPopupOpen(false)}
        onConfirm={onConfirmTransferIssuer}
        onReject={() => setIssuerPopupOpen(false)}
        open={issuerPopupOpen}
        label="Change issuer"
        loading={issuerPending}
        body={
          <RadioSelectInput
            label="Employee"
            selector={selectorKeys.employee}
            onChange={option => {
              if (option) {
                setNewIssuer(option)
              }
            }}
            value={newIssuer}
          />
        }
        yesMessage="Confirm"
        noMessage="Cancel"
        submitDisabled={!newIssuer || newIssuer?.id === document?.created_by.id}
      />
    </>
  )
}
