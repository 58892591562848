import React, { useMemo, useState } from 'react'
import {
  BottomSheet,
  Header,
  MoreBar,
  StatusPopup,
  useStatusPopup,
  Text,
  Token,
  useToggle,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import {
  typeToBackUrl,
  typeToSearchLabel,
  typeToTableRequests,
  typeToTitle,
  typeToTableName,
  getRow,
  typeToRoute,
  typeToDefaultFilter,
  typeToNamePlularised,
  typeToName,
} from './common'
import {
  UpdateOrganisationUnit,
  UpdateOrganisationUnitType,
} from '@src/interfaces/updateOrganisationStructure'
import { MergeDepartments } from './MergeDepartments'
import { MergeEntities } from './MergeEntities'
import { MergeTeams } from './MergeTeams'
import { MergeRoles } from './MergeRoles'
import Table from '@src/components/TableV2/Table'
import { MergeSpecialsations } from './MergeSpecialisations'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { ChangeOwnerPopup } from './components/ChangeOwnerPopup'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface UpdateOrganisationStructureTableProps {
  type: UpdateOrganisationUnitType
}

export const UpdateOrganisationStructureTable = ({
  type,
}: UpdateOrganisationStructureTableProps) => {
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()
  const table = useTable<UpdateOrganisationUnit>(
    typeToTableRequests[type],
    typeToDefaultFilter(type),
    [
      {
        sortBy: 'name',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
    ],
  )
  const statusPopup = useStatusPopup()

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<UpdateOrganisationUnit>>()
  const [mergeUnitsPopupOpen, setMergeUnitsPopupOpen] = useState(false)
  const [isOwnerPopupOpen, toggleOwnerPopup] = useToggle()
  const showStatusPopup = useShowStatusPopup()

  const selectedUnits = useMemo(() => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(unit => ({ id: unit.id }))
    }
    if (selectedData?.isAllSelected) {
      return table.data
        .filter(unit => !selectedData.excludeListIds.has(`${unit.id}`))
        .map(unit => ({ id: unit.id }))
    }
    return []
  }, [selectedData])

  const onMergeClick = () => {
    if (selectedUnits.length > 1) {
      setMergeUnitsPopupOpen(true)
    }
  }

  const onSuccess = () => {
    setMergeUnitsPopupOpen(false)
    table.resetFiltersAndSorting()
  }

  const getStatusPopup = (count: number, name: string) => {
    return (
      <StatusPopup
        variant="success"
        onClose={() => {
          statusPopup.hide()
          onSuccess()
        }}
      >
        <StatusPopup.Title>
          {pluralize(
            type === 'specialisation' && !isSpecialisationsEnabled ? 'role' : type,
            count,
            true,
          )}{' '}
          successfully merged to <Text color={Token.color.blue}>{name}</Text>
        </StatusPopup.Title>
      </StatusPopup>
    )
  }

  const row = useMemo(() => {
    return getRow(
      type,
      table.refresh,
      isSpecialisationsEnabled,
    ) as RowInterface<UpdateOrganisationUnit>
  }, [type, table, isSpecialisationsEnabled])

  const parsedType =
    type === 'specialisation' && !isSpecialisationsEnabled ? 'role' : type

  return (
    <>
      <Table.Widget>
        <Table.Widget.Filters>
          <Table.Search
            placeholder={typeToSearchLabel[parsedType]}
            onFilter={table.onFilterChange}
            variant="compact"
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(typeToRoute[type])}
              useIcon="Plus"
            >
              <FormattedMessage
                id="updateOrganisationStructure.form.addButton"
                defaultMessage="Add {type}"
                values={{
                  type: parsedType,
                }}
              />
            </MoreBar.Action>
            {type === 'specialisation' && (
              <MoreBar.Action
                useIcon="Profile"
                onClick={toggleOwnerPopup.on}
                disabled={!selectedUnits.length}
              >
                Change owner
              </MoreBar.Action>
            )}
            <MoreBar.Action
              onClick={onMergeClick}
              useIcon="Materials"
              disabled={selectedUnits.length < 2}
              variant="accent"
            >
              <FormattedMessage
                id="updateOrganisationStructure.form.mergeButton"
                defaultMessage="Merge {type}"
                values={{
                  type: typeToNamePlularised[parsedType],
                }}
              />
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            tableData={table.data}
          >
            <AdjustableTable
              name={typeToTableName[type]}
              useWindowScroll
              dataType={parsedType}
              row={row}
              {...table}
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>
      {isOwnerPopupOpen && (
        <ChangeOwnerPopup
          type={type}
          onClose={toggleOwnerPopup.off}
          onSuccess={resp => {
            showStatusPopup({
              onClose: () => {
                onSuccess()
                toggleOwnerPopup.off()
              },
              title: (
                <FormattedMessage
                  id="updateOrganisationStructure.changeOwnerPopup.title"
                  defaultMessage="{count} {type} successfully updated"
                  values={{
                    count: resp.updated_rows,
                    type: (
                      <FormattedPlural
                        value={resp.updated_rows}
                        one={typeToName[parsedType]}
                        other={typeToNamePlularised[parsedType]}
                      />
                    ),
                  }}
                />
              ),
            })
          }}
          unitsToChange={selectedUnits}
        />
      )}
      <BottomSheet
        open={mergeUnitsPopupOpen}
        onClose={() => setMergeUnitsPopupOpen(false)}
      >
        <Header>
          <Header.Title>
            <FormattedMessage
              id="updateOrganisationStructure.form.mergePopup.title"
              defaultMessage="Merge {type}"
              values={{
                type: typeToNamePlularised[parsedType],
              }}
            />
          </Header.Title>
        </Header>

        {type === 'department' && (
          <MergeDepartments
            unitsToMerge={selectedUnits}
            onSuccess={response => {
              statusPopup.show(getStatusPopup(response.count, response.department.name))
            }}
          />
        )}
        {type === 'team' && (
          <MergeTeams
            unitsToMerge={selectedUnits}
            onSuccess={response => {
              onSuccess()
              statusPopup.show(getStatusPopup(response.count, response.team.name))
            }}
            showMissionField
            setCurrentUserAsOwner={false}
          />
        )}
        {type === 'role' && (
          <MergeRoles
            unitsToMerge={selectedUnits}
            onSuccess={response => {
              statusPopup.show(getStatusPopup(response.count, response.role.name))
            }}
          />
        )}
        {type === 'entity' && (
          <MergeEntities
            unitsToMerge={selectedUnits}
            onSuccess={response => {
              statusPopup.show(getStatusPopup(response.count, response.entity.name))
            }}
          />
        )}
        {type === 'specialisation' && (
          <MergeSpecialsations
            unitsToMerge={selectedUnits}
            onSuccess={response => {
              statusPopup.show(
                getStatusPopup(response.count, response.specialisation.name),
              )
            }}
          />
        )}
      </BottomSheet>
    </>
  )
}

export const UpdateOrganisationStructure = () => {
  const { type } = useParams<{ type: UpdateOrganisationUnitType }>()
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <PageWrapper>
      <PageHeader
        title={
          <FormattedMessage
            id="updateOrganisationStructure.pageTitle"
            defaultMessage="Add {type}"
            values={{
              type:
                type === 'specialisation' && !isSpecialisationsEnabled
                  ? typeToTitle.role
                  : typeToTitle[type],
            }}
          />
        }
        backUrl={typeToBackUrl[type]}
      />
      <UpdateOrganisationStructureTable type={type} />
    </PageWrapper>
  )
}
