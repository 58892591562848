import { MoreBar, MoreBarSkeleton, Flex, Skeleton } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalFilterNames,
  GoalsStats,
  ManageGoalsPayload,
  goalsListTableRequests,
} from '@src/api/goals'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsWeightColumn,
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsOrgUnitColumn,
  singleTargetAdditionalColumns,
  goalsProgressColumn,
} from '@src/constants/columns/goals'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { GoalContentType, GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/TableV2/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/TableV2/EditableTable/EditableTable'
import { Statuses } from '@src/interfaces'
import { GoalTargetSidebarForm } from './GoalTargetSidebarForm'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { PermissionTypes } from '@src/store/auth/types'
import { useManageGoalsWeights } from './useManageGoalsWeights'
import { navigateTo } from '@src/actions/RouterActions'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { selectPermissions } from '@src/store/auth/selectors'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { GoalsSettingsButton } from './components/GoalsSettingsButton'
import { GoalLevelSelector } from './components/GoalLevelSelector'
import { useGetSelectors } from '@src/api/selectors'
import { isEqual } from 'lodash'
import { useLocation } from 'react-router-dom'
import { CascadeGoalAction } from '@src/features/Goals/components/CascadeGoalAction'
import { EmptyGoalTable } from './components/EmptyGoalTable'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import Table from '@src/components/TableV2/Table'
import { GoalTableInfo } from './components/GoalTableInfo'
import { useIsSingleEntitySelected } from './common/useIsSingleEntitySelected'
import { useGetGoalUrl } from './common/useGetGoalUrl'
import { toIdAndName } from '@src/utils/toIdAndName'
import { isEmployeeEntity } from './common/isEmployeeEntity'
import { NoPersonalGoalBanner } from './components/NoPersonalGoalsBanner'
import { useEmployeeGoals } from './common/useEmployeeGoals'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
  entity: OrgEntityInterface
  onManageModeChange?: (enabled: boolean) => void
}

const approvalStatusDefaultFilter = {
  columnName: GoalFilterNames.ApprovalStatus,
  filters: [
    toIdAndName(Statuses.pending),
    toIdAndName(Statuses.approved),
    toIdAndName(Statuses.requires_changes),
  ],
}

export const GoalsTableWidget = ({
  initialFilters,
  entity,
  onManageModeChange,
}: GoalsTableWidgetProps) => {
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(false)
  const [isDraftFilterInculdedAutomatically, setIsDraftFilterIncludedAutomatically] =
    useState(false)
  const permissions = useSelector(selectPermissions)
  const { data: contentTypeData } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
  const initialSort: SortByInterface[] | undefined = [
    EntityTypes.company,
    EntityTypes.companyV2,
  ].includes(entity.type)
    ? undefined
    : [{ sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC }]

  const table = useTable(goalsListTableRequests, initialFilters, initialSort, {
    parentIdFilterKey: GoalFilterNames.ParentId,
    childrenFetchOmitFilterKeys: [
      GoalFilterNames.ObjectId,
      GoalFilterNames.IsTopLevel,
      GoalFilterNames.IsCompany,
      GoalFilterNames.ContentTypeId,
    ],
  })

  const [preselectedType, setPreselectedType] = useState<
    EntityTypes.companyV2 | EntityTypes.department | EntityTypes.team
  >()
  const [isAddButtonHidden, setIsAddButtonHidden] = useState(false)
  const [cycleCategory, setCycleCategory] = useState<ReviewCycleCategory>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle
  const form = useLapeContext<ManageGoalsPayload>()
  const contentType = table.data[0]?.content_type?.id
  const {
    manageMode,
    handleSubmit,
    weightMode,
    toggleManageMode,
    confirmationDialog,
    autoDistributeWeights,
    validationMessage,
  } = useManageGoalsWeights({
    entity,
    contentType,
    tableData: table.data,
    cycleId: table.filterBy.find(f => f.columnName === GoalFilterNames.CycleId)
      ?.filters[0]?.id,
    onUpdated: () => table.refresh(),
    cycleCategory,
  })
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()
  const location = useLocation<{ selectedCycle?: boolean }>()
  const initialFilterCycle = useMemo(() => {
    return initialFilters.find(({ columnName }) => columnName === GoalFilterNames.CycleId)
  }, [initialFilters])

  useEffect(() => {
    // We need this so that we can set the correct cycle if user decides to change cycle length
    // but we also don't want to reset cycles on "go back" to the performance page
    const directedFromCycleSelect = location?.state && location.state.selectedCycle

    if (initialFilterCycle && directedFromCycleSelect) {
      table.onFilterChange(initialFilterCycle)
    }
  }, [initialFilterCycle])

  const canManageGoals =
    entity.data.field_options.permissions?.includes(PermissionTypes.CanManageGoals) ||
    false

  const canSeeSettings =
    (permissions.includes(PermissionTypes.ViewKPITemplate) &&
      performanceSettings?.enable_kpi_templates) ||
    (permissions.includes(PermissionTypes.ViewPerformancePreferences) &&
      (entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2))

  const canImportGoals = permissions.includes(PermissionTypes.KPIAdministratorPermissions)

  const isOnboarding = isOnboardingPath()

  const getGoalUrl = useGetGoalUrl()

  useEffect(() => {
    form.reset({
      ...form.values,
      goals: table.data,
    })
  }, [table.data])

  useEffect(() => {
    // fix for when window refocuses we set the correct values to the form
    if (form && !isEqual(form.initialValues.goals, table.data) && manageMode) {
      form.reset({
        ...form.values,
        goals: table.data,
      })
    }
  }, [form.initialValues.goals])

  useEffect(() => {
    onManageModeChange?.(manageMode)
    // exclude drafts and archived from edit weigths in case current filters include them
    if (manageMode) {
      const currentFilters = table.filterBy.filter(
        ({ columnName }) => columnName !== GoalFilterNames.ApprovalStatus,
      )
      table.onFilterChange([...currentFilters, approvalStatusDefaultFilter])
    }
  }, [manageMode])

  const goalsIdsToApprove = table.data
    ?.filter(
      ({ approval_status, status }) =>
        ![Statuses.draft, Statuses.archived].includes(status.id) &&
        approval_status.id !== ApprovalStatuses.Approved,
    )
    .map(({ id }) => ({ id }))

  const isSingleEntitySelected = useIsSingleEntitySelected(table.filterBy)
  const isCompany = table.filterBy.some(
    filter =>
      (filter.columnName === GoalFilterNames.IsCompany &&
        ['True', 'true'].includes(String(filter.filters[0]?.id))) ||
      (filter.columnName === GoalFilterNames.IsTopLevel &&
        ['True', 'true'].includes(String(filter.filters[0]?.id))),
  )

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(() => {
    const contentId = table.filterBy.find(
      f => f.columnName === GoalFilterNames.ContentTypeId,
    )?.filters[0]?.id
    let orgUnitFilterType: 'department' | 'team' | undefined
    if (contentId) {
      const model = contentTypeData?.find(({ id }) => id === Number(contentId))?.model
      switch (model) {
        case 'department':
          orgUnitFilterType = 'department'
          break
        case 'teams':
          orgUnitFilterType = 'team'
          break
        default:
          break
      }
    }

    let canChangeStatus = false

    if (isCompany) {
      canChangeStatus = canManageGoals
      // check if not on goals tab with selector
    } else if (![EntityTypes.company, EntityTypes.companyV2].includes(entity.type)) {
      canChangeStatus = canManageGoals
    }

    return {
      cells: [
        {
          ...goalsNameColumn(goal => getGoalUrl(goal, table.filterBy)),
          width: 300,
        },
        entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2
          ? {
              ...goalsOrgUnitColumn({
                companyName: entity.data.name,
                type: orgUnitFilterType,
              }),
              width: 160,
            }
          : null,
        {
          ...(manageMode ? goalsInputWeightColumn : goalsWeightColumn),
          width: 92,
        },
        { ...goalsProgressColumn, width: 160 },
        ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsChangeStatusColumn(
            (status, goalId) =>
              table.updateRows(
                r => r.id === goalId,
                r => ({ ...r, status }),
              ),
            canChangeStatus,
          ),
          width: 100,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 50,
        },
      ].filter(Boolean),
    }
  }, [manageMode, table.filterBy, weightMode, selectedTarget, contentTypeData])

  const teamContentTypeId = contentTypeData?.find(type => type.model === 'teams')?.id
  const employeeContentTypeId = contentTypeData?.find(
    type => type.model === 'employees',
  )?.id
  const showTeamGoals =
    isEmployeeEntity(entity) &&
    table.filterBy.some(
      ({ columnName, filters }) =>
        columnName === GoalFilterNames.ContentTypeId &&
        filters.some(filter => filter.id === String(teamContentTypeId)),
    )

  const { getEmployeeGoalType, fallbackFilters } = useEmployeeGoals({
    entity,
    cycleId: table.filterBy.find(f => f.columnName === GoalFilterNames.CycleId)
      ?.filters[0]?.id,
    cycleCategory,
  })

  useEffect(() => {
    if (table.count === 0 && !table.loading && !manageMode) {
      const approvalStatusFilter = table.filterBy.find(
        ({ columnName }) => columnName === GoalFilterNames.ApprovalStatus,
      )
      const hasDraftsFilter = approvalStatusFilter?.filters.some(
        filter => filter.id === Statuses.draft,
      )
      if (isEmployeeEntity(entity) && !hasDraftsFilter) {
        setIsLoadingEmployee(true)
        getEmployeeGoalType()
          .then(result => {
            if (result) {
              table.onFilterChange(result.relevantFilters)
            } else {
              table.onFilterChange(fallbackFilters)
            }
            setIsLoadingEmployee(false)
          })
          .catch(() => {
            table.onFilterChange(fallbackFilters)
            setIsLoadingEmployee(false)
          })
      } else if (!isEmployeeEntity(entity) && !hasDraftsFilter) {
        setIsDraftFilterIncludedAutomatically(true)
        table.onFilterChange({
          columnName: GoalFilterNames.ApprovalStatus,
          filters: [
            toIdAndName(Statuses.pending),
            toIdAndName(Statuses.approved),
            toIdAndName(Statuses.requires_changes),
            toIdAndName(Statuses.draft),
          ],
        })
      }
    }
  }, [table.loading, table.count, entity, getEmployeeGoalType])

  return (
    <>
      <Table.Widget>
        {isEmployeeEntity(entity) && (table.loading || isLoadingEmployee) ? (
          <Table.Widget.Info>
            <Skeleton height={60} width={400} radius="r16" />
          </Table.Widget.Info>
        ) : (
          <Table.Widget.Info>
            {showTeamGoals && isEmployeeEntity(entity) && entity.data.team ? (
              <NoPersonalGoalBanner team={entity.data.team} />
            ) : (
              <GoalTableInfo
                cycleCategory={cycleCategory}
                tableFilters={table.filterBy}
                tableStats={table.stats}
                entity={entity}
              />
            )}
          </Table.Widget.Info>
        )}
        <Table.Widget.Actions>
          {table.loading || isLoadingEmployee ? (
            <MoreBarSkeleton />
          ) : (
            <Table.Widget.MoreBar>
              {!showTeamGoals && (
                <BulkGoalApproveAction
                  goals={goalsIdsToApprove}
                  onApproved={table.refresh}
                  title={
                    goalsIdsToApprove.length === table.data.length
                      ? 'Approve all goals'
                      : undefined
                  }
                />
              )}
              {!isAddButtonHidden && <AddGoalAction preselectedType={preselectedType} />}
              <CascadeGoalAction />
              {!showTeamGoals &&
                table.data.length > 0 &&
                canManageGoals &&
                (isSingleEntitySelected || isCompany) && (
                  <MoreBar.Action
                    useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
                    onClick={toggleManageMode}
                  >
                    Edit weights
                  </MoreBar.Action>
                )}
              {manageMode && (
                <MoreBar.Action useIcon="AutoExchange" onClick={autoDistributeWeights}>
                  Auto-distribute weights
                </MoreBar.Action>
              )}
              {canImportGoals && (
                <MoreBar.Action
                  useIcon="Upload"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(
                        isOnboarding
                          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.IMPORT_TYPE
                          : ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT_TYPE,
                        undefined,
                      ),
                    )
                  }
                >
                  Import data
                </MoreBar.Action>
              )}
              {canSeeSettings && <GoalsSettingsButton />}
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>

        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by name"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          <GoalCycleFilter
            isFilterButtonUI
            allowEmployeeCycles
            onFilterChange={val => {
              const newFilters: FilterByInterface[] = [val]
              if (isDraftFilterInculdedAutomatically) {
                newFilters.push(approvalStatusDefaultFilter)
                setIsDraftFilterIncludedAutomatically(false)
              }
              if (showTeamGoals && isEmployeeEntity(entity)) {
                newFilters.push({
                  columnName: GoalFilterNames.ContentTypeId,
                  filters: [toIdAndName(String(employeeContentTypeId))],
                })
                newFilters.push({
                  columnName: GoalFilterNames.ObjectId,
                  filters: [toIdAndName(String(entity.data.id))],
                })
              }

              table.onFilterChange(newFilters)
            }}
            columnName={GoalFilterNames.CycleId}
            setShouldHideAddButton={setIsAddButtonHidden}
            setCycleCategory={setCycleCategory}
            selector={selectorKeys.review_cycles}
            filter={table.filterBy}
          />
          {[EntityTypes.company, EntityTypes.companyV2].includes(entity.type) && (
            <GoalLevelSelector
              cycleFilter={table.filterBy.find(
                f => f.columnName === GoalFilterNames.CycleId,
              )}
              onFilterChange={table.onFilterChange}
              setPreselectedType={setPreselectedType}
            />
          )}
        </Table.Widget.Filters>

        <Table.Widget.Table>
          {manageMode ? (
            <>
              <LapeEditableTable<GoalsInterface>
                dataFieldName="goals"
                hideCount
                name={TableNames.Goals}
                dataType="Goal"
                disableFilters={manageMode}
                {...table}
                initialData={table.data}
                row={row}
                useWindowScroll
                replaceOnInitialDataChange
              />
            </>
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              hideCount
              emptyState={
                <EmptyGoalTable
                  preselectedType={preselectedType}
                  withAction={!isAddButtonHidden}
                />
              }
              dataType="Goal"
              {...table}
              loading={table.loading || isLoadingEmployee}
              row={row as RowInterface<GoalsInterface>}
              expandableType="chevron"
              fetchChildren={(parentIndexes, id) =>
                table.fetchChildren(parentIndexes, id, [])
              }
              useFetchedChildren
              useWindowScroll
            />
          )}
        </Table.Widget.Table>
      </Table.Widget>
      {manageMode && (
        <Flex height="s-20">
          {!table.loading && table.data.length ? validationMessage : null}
        </Flex>
      )}
      {!!manageMode && (
        <PageActions height={52} my="s-4" maxWidth="100%">
          {form.dirty && (
            <NewSaveButtonWithPopup
              disabled={!!validationMessage}
              onClick={() => handleSubmit()}
              successText="Goal weights have been updated"
            >
              Submit
            </NewSaveButtonWithPopup>
          )}
        </PageActions>
      )}
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedTarget(undefined)
        }}
      />
      {confirmationDialog}
    </>
  )
}

export const GoalsTableFormWidget = React.memo(
  ({
    initialFilters,
    onManageModeChange,
  }: {
    initialFilters: FilterByInterface[]
    onManageModeChange?: (enabled: boolean) => void
  }) => {
    const { entity } = useOrgEntity()

    const initialValues = useMemo(() => {
      return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
        ? {
            goals: [],
            goal_weight_mode: entity.data.goal_weight_mode,
          }
        : {
            goals: [],
            goal_weight_mode: { id: 'automatic' },
          }
    }, [entity?.type])

    return entity ? (
      <LapeForm
        disableValidation
        onSubmit={() => Promise.resolve({})}
        initialValues={initialValues}
      >
        <GoalsTableWidget
          initialFilters={initialFilters}
          entity={entity}
          onManageModeChange={onManageModeChange}
        />
      </LapeForm>
    ) : null
  },
)
