import React, { ReactNode, Ref } from 'react'
import { Box, MoreBar, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import {
  DeliverableOptions,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkippedJustificationsInterface,
} from '@src/interfaces/performance'
import set from 'lodash/set'
import {
  BehaviourGradeOption,
  CardContentTypes,
  CommonGradeOption,
  DeliverableGradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { relationToString } from '@components/ScorecardGeneral/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import { CardItem } from '@src/pages/Forms/EmployeePerformanceLayout/CardItem'
import { notReachable } from '@src/utils/notReachable'
import { CardHeader, CardHeaderProps } from './CardHeader'

export interface CardField {
  field: string
  fieldToSelect?: string
  title: string
  titleButton?: React.ReactNode
  grades: CommonGradeOption[]
  cardIndex?: number
  cardJustification: string | null
  recommendedGrade?: DeliverableOptions
}

interface CardContentProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  type: CardContentTypes
  fields: CardField[]
  isViewMode?: boolean
  onSelectDeliverableGrade?: (grade: DeliverableGradeOption, field: CardField) => void
  onSelectBehaviourGrade?: (grade: BehaviourGradeOption, field: CardField) => void
  justification?: string | SkippedJustificationsInterface[]
  isGradeSelectedRule?: (field: string, grade: CommonGradeOption) => boolean
  headerRef?: Ref<HTMLDivElement>
  additionalInfo?: ReactNode
  actions?: ReactNode
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  renderExceedingContent?: (selectedField: SelectedFieldInterface) => React.ReactNode
  hideMessageBtn?: boolean
  header?: ReactNode
}

interface WidgetCardProps extends Omit<CardContentProps, 'header'>, CardHeaderProps {
  variant?: 'widget'
}

interface ContentCardProps extends Omit<CardContentProps, 'header'> {
  variant: 'content'
}

type CardProps = WidgetCardProps | ContentCardProps

const CardContent = ({
  data,
  type,
  fields,
  isViewMode = false,
  onSelectDeliverableGrade,
  onSelectBehaviourGrade,
  justification,
  isGradeSelectedRule,
  headerRef,
  additionalInfo,
  actions,
  renderExpandedContent,
  renderExceedingContent,
  hideMessageBtn,
  header,
}: CardContentProps) => {
  const { query } = useQuery()

  const onGradeClick = (field: CardField, grade: CommonGradeOption) => {
    if (!isViewMode) {
      if (onSelectDeliverableGrade || onSelectBehaviourGrade) {
        switch (grade.key) {
          case DeliverableOptions.DONT_KNOW:
          case DeliverableOptions.POOR:
          case DeliverableOptions.BASIC:
          case DeliverableOptions.INTERMEDIATE:
          case DeliverableOptions.ADVANCED:
          case DeliverableOptions.EXPERT:
            onSelectDeliverableGrade?.(grade, field)
            break
          case AssessBehaviourButtonTypes.positive:
          case AssessBehaviourButtonTypes.neutral:
          case AssessBehaviourButtonTypes.negative:
          case AssessBehaviourButtonTypes.unknown:
            onSelectBehaviourGrade?.(grade, field)
            break
          default:
            notReachable(grade)
        }
      } else {
        set(data, field.field, grade.key)
      }
    }
  }

  const justifications = Array.isArray(justification) ? justification : []
  const singleViewFilter = !!justifications.length && !!query[Queries.ReviewerId]

  return (
    <VStack ref={headerRef} space="s-16">
      {header}
      {isViewMode && !!justifications.length && !singleViewFilter && (
        <VStack space="s-8">
          {justifications.map((value, i) => (
            <Box
              key={i}
              p="s-16"
              mx="s-16"
              mb={justifications.length === 1 ? 's-16' : 0}
              data-testid="skip-section"
              border={`1px solid ${Token.color.greyTone10}`}
              borderRadius={Token.radius.r16}
            >
              <Text variant="primary" use="div" mb="s-4">
                {value.review.reviewer.display_name} (
                {relationToString(value.review.reviewer_relation, true)}) skipped this
                section
              </Text>
              <Text color={Token.color.greyTone50}>“{value.value}”</Text>
            </Box>
          ))}
        </VStack>
      )}
      {actions && <MoreBar>{actions}</MoreBar>}
      {additionalInfo}
      {(!isViewMode || !singleViewFilter) && (
        <>
          {fields.map((field, ind) => (
            <Widget key={ind} overflow="hidden">
              <CardItem
                data={data}
                field={field}
                isViewMode={isViewMode}
                type={type}
                cardItemIndex={ind}
                onGradeClick={onGradeClick}
                isGradeSelectedRule={isGradeSelectedRule}
                renderExpandedContent={renderExpandedContent}
                renderExceedingContent={renderExceedingContent}
                hideMessageBtn={hideMessageBtn}
              />
            </Widget>
          ))}
        </>
      )}
    </VStack>
  )
}

export const Card = (props: CardProps) => {
  if (props.variant === 'content') {
    return <CardContent {...props} />
  }

  const { data, title, stat, icon, finalRating, onHelpClick } = props

  return (
    <Widget p="s-16">
      <CardContent
        {...props}
        data={data}
        header={
          <CardHeader
            data={data}
            title={title}
            stat={stat}
            icon={icon}
            finalRating={finalRating}
            onHelpClick={onHelpClick}
          />
        }
      />
    </Widget>
  )
}
