import React, { useEffect, useMemo, useState } from 'react'
import {
  Dropdown,
  HStack,
  Icon,
  Subheader,
  Token,
  Text,
  ItemSkeleton,
  DropdownState,
  ActionButton,
  Item,
  Flex,
} from '@revolut/ui-kit'
import { debounce } from 'lodash'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { toIdAndName } from '@src/utils/toIdAndName'
import { rolesRequests } from '@src/api/roles'

interface Props {
  dropdown: DropdownState<HTMLDivElement>
  isTemplatesLoading: boolean
  setIsTemplatesLoading: (nextState: boolean) => void
}

export const TemplateRoleDropdown = ({
  dropdown,
  isTemplatesLoading,
  setIsTemplatesLoading,
}: Props) => {
  const [templateOptions, setTemplateOptions] = useState<RoleInterface[]>([])

  const { values, reset } = useLapeContext<RoleInterface>()

  const handleTemplateSelected = (template: RoleInterface) => {
    const { id: _, name: __, ...newValues } = template
    reset({
      ...newValues,
      name: values.name,
      // @ts-expect-error only used in new forms that do not have the id
      id: undefined,
    })
    dropdown.toggle(false)
  }

  const debouncedSearch = useMemo(() => {
    return debounce(async (searchValue: string) => {
      try {
        const { data } = await rolesRequests.getItems({
          filters: [{ columnName: 'search', filters: [toIdAndName(searchValue)] }],
        })
        setTemplateOptions(data.results)
      } catch {
        setTemplateOptions([])
      } finally {
        setIsTemplatesLoading(false)
      }
    }, 1000)
  }, [])

  useEffect(() => {
    dropdown.toggle(!!templateOptions.length)
    setIsTemplatesLoading(false)
  }, [templateOptions])

  useEffect(() => {
    if (values.name) {
      setIsTemplatesLoading(true)
      debouncedSearch(values.name)
    }
  }, [values.name])

  return (
    <Dropdown
      {...dropdown.getTargetProps()}
      autoFocus={false}
      focusTrap={false}
      px="s-16"
      fitInAnchor
      backgroundColor={Token.color.popoverBackground}
    >
      <Subheader variant="nested">
        <Subheader.Title>
          <HStack gap="s-16" align="center">
            <Icon name="Cleaning" />
            <Text>Recommended role templates</Text>
          </HStack>
        </Subheader.Title>
        <Subheader.Side>
          <Icon
            use="button"
            name="Cross"
            size={16}
            onClick={() => dropdown.toggle(false)}
          />
        </Subheader.Side>
      </Subheader>
      {isTemplatesLoading ? (
        <ItemSkeleton px="s-4">
          <ItemSkeleton.Content />
          <ItemSkeleton.Side />
        </ItemSkeleton>
      ) : (
        templateOptions.map(option => (
          <Item key={option.id} px="s-4">
            <Item.Content use="button">
              <Item.Title textAlign="start">{option.name}</Item.Title>
              {option.role_description && (
                <Item.Description textAlign="start">
                  {option.role_description}
                </Item.Description>
              )}
            </Item.Content>
            <Item.Side>
              <Flex flex={1}>
                <ActionButton
                  onClick={() => {
                    handleTemplateSelected(option)
                  }}
                >
                  Use template
                </ActionButton>
              </Flex>
            </Item.Side>
          </Item>
        ))
      )}
    </Dropdown>
  )
}
