import { Color, IconName, Token } from '@revolut/ui-kit'

export enum MetricDetailsVariants {
  Default = 'default',
  Percentage = 'percentage',
  Seconds = 'seconds',
}

interface ComparableMetricIconProps {
  name: IconName
  color: Color
}

export const thresholdPercentage = 0.05

export const getComparableMetricProps = (
  lhs: number,
  rhs: number,
): ComparableMetricIconProps | null => {
  if (rhs === 0) {
    return null
  }

  const diffPercentage = Number((lhs / rhs).toFixed(2))

  if (diffPercentage < 1 - thresholdPercentage) {
    return {
      name: 'ArrowDowngrade',
      color: Token.color.warning,
    }
  }

  if (diffPercentage > 1 + thresholdPercentage) {
    return {
      name: 'ArrowUpgrade',
      color: Token.color.success,
    }
  }

  return {
    name: 'Dot',
    color: Token.color.accent,
  }
}
