import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TextSkeleton, VStack } from '@revolut/ui-kit'

interface Props {
  count: number
  isLoading: boolean
}

export const AffectedEntitiesConfirmation = ({ count, isLoading }: Props) => {
  if (isLoading) {
    return (
      <VStack space="s-4">
        <TextSkeleton width={210} />
        <TextSkeleton width={80} />
      </VStack>
    )
  }

  return (
    <FormattedMessage
      id="forms.kpi.rule.submit.confirmation.message"
      values={{
        count,
        b: chunks => <strong>{chunks}</strong>,
      }}
      defaultMessage="This rule affects a number of <b>{count}</b> entities. Are you sure you want to proceed?"
    />
  )
}
