import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import React from 'react'
import { ParentRoleField } from './ParentRoleField'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { FormattedMessage } from 'react-intl'

export const SpecialisationName = ({ pending }: { pending?: boolean }) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <LapeNewInput
      label={specialisationsEnabled ? 'Specialisation name' : 'Role name'}
      name="name"
      required
      pending={pending}
    />
  )
}

export const SpecialisationParentRole = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return specialisationsEnabled ? <ParentRoleField /> : null
}

export const SpecialisationFunction = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return specialisationsEnabled ? null : (
    <LapeRadioSelectInput
      name="function"
      label="Function"
      selector={selectorKeys.functions}
    />
  )
}

export const SpecialisationGoals = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <LapeMultiInput
      title={specialisationsEnabled ? 'Specialisation goals' : 'Role goals'}
      name="goals"
    />
  )
}

export const SpecialisationLinkedSpecialisations = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <LapeNewMultiSelect
      message={
        specialisationsEnabled
          ? 'An employee’s progression progress is retained when their specialisation is changed to a linked specialisation'
          : 'An employee’s progression progress is retained when their role is changed to a linked role'
      }
      name="related_specialisations"
      placeholder={specialisationsEnabled ? 'Linked specialisations' : 'Linked roles'}
      selector={selectorKeys.specialisations}
    />
  )
}

export const SpecialisationMission = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  return (
    <LapeNewTextArea
      name="mission"
      label={specialisationsEnabled ? 'Specialisation mission' : 'Role mission'}
      rows={3}
      description={
        <FormattedMessage
          id="addEditSpecialisation.form.generalInfo.mission"
          defaultMessage="A short summary explaining the goals and the scope of the {type}"
          values={{ type: specialisationsEnabled ? 'specialisation' : 'role' }}
        />
      }
    />
  )
}
