import React from 'react'

import {
  useGetEmployeeBankDetails,
  useGetEmployeeWorkAndOrgDetails,
  useGetPersonalInfo,
} from '@src/api/employees'
import Loader from '@src/components/CommonSC/Loader'
import { useEmployeeRecordToPrimaryContractEnabled } from '@src/features/EmployeeProfile/hooks/featureFlags'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ChangelogPage } from './ChangelogPage'

interface Props {
  data: EmployeeInterface
}

export const Changelog = ({ data }: Props) => {
  const employeeRecordToPrimaryContractEnabled =
    useEmployeeRecordToPrimaryContractEnabled()

  const { data: payrollDetails } = useGetEmployeeBankDetails(data.id)
  const { data: personalInfo } = useGetPersonalInfo(data.id)
  const { data: workAndOrgDetails } = useGetEmployeeWorkAndOrgDetails(
    employeeRecordToPrimaryContractEnabled,
    data.id,
  )

  if (
    !personalInfo ||
    !payrollDetails ||
    (employeeRecordToPrimaryContractEnabled && !workAndOrgDetails)
  ) {
    return <Loader />
  }

  return (
    <ChangelogPage
      employeeId={data.id}
      employeeRecordToPrimaryContractEnabled={employeeRecordToPrimaryContractEnabled}
      payrollDetailsId={payrollDetails.id}
      personalInfoId={personalInfo.id}
      workAndOrgDetailsId={workAndOrgDetails?.id}
    />
  )
}
