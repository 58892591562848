import { rolesRequests } from '@src/api/roles'
import { RadioSelectInputProps } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FunctionOptionInterface, FunctionType } from '@src/interfaces/functions'
import { SkillInterface, SkillType } from '@src/interfaces/skills'
import { selectUser } from '@src/store/auth/selectors'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useInitializeSkillFormData = () => {
  const { values } = useLapeContext<SkillInterface>()

  const user = useSelector(selectUser)
  useEffect(() => {
    if (!values.id) {
      if (!values.owner) {
        values.owner = { id: user.id, name: user.full_name }
      }
      if (!values.function) {
        setDefaultFunction()
      }
      if (!values.skill_type) {
        values.skill_type = SkillType.Functional
      }
    }
  }, [])

  const setDefaultFunction = async () => {
    if (user.position) {
      const result = await rolesRequests.getItem(user.position.id)
      if (result.data) {
        values.function = {
          name: result?.data?.function?.name,
          id: result?.data?.function?.id,
        }
      }
    }
  }

  const executiveFunctionFilter: RadioSelectInputProps<FunctionOptionInterface>['filter'] =
    useCallback(
      optionValue => {
        return optionValue.function_type === FunctionType.executive
      },
      [values.skill_type],
    )

  return { executiveFunctionFilter }
}
