import React from 'react'
import { Badge, Flex, TextWidget, Token } from '@revolut/ui-kit'
import { CompetencyMatrixSkill } from '@src/interfaces/roles'

interface SkillBadge extends Pick<CompetencyMatrixSkill, 'id' | 'name' | 'status'> {}

interface Props {
  skills: SkillBadge[] | undefined
}

export const SkillsWidget = ({ skills }: Props) => {
  return (
    <TextWidget color={Token.color.greyTone50}>
      <TextWidget.Title fontSize={14}>Skills</TextWidget.Title>
      <TextWidget.Content>
        <Flex gap="s-8" flexWrap="wrap">
          {skills?.map(skill => (
            <Badge
              key={skill.id}
              bg={Token.color.greyTone10}
              color={Token.color.foreground}
            >
              {skill.name}
            </Badge>
          ))}
        </Flex>
      </TextWidget.Content>
    </TextWidget>
  )
}
