import React, { useRef } from 'react'
import { Header, Image, Popup, Search, Text, VStack } from '@revolut/ui-kit'
import {
  RadioSelectMobileGroup,
  type RadioSelectMobileGroupProps,
} from './RadioSelectMobileGroup'

export interface RadioSelectMobileProps<T extends number | object | string>
  extends Pick<RadioSelectMobileGroupProps<T>, 'children' | 'onChange' | 'options'> {
  open: boolean
  onClose: () => void
  labelSearch?: string
  labelNoResults?: string
  disabled?: boolean
  title?: string
  searchable?: boolean
  searchText?: string
  onChangeSearchText: (searchText: string) => void
}

export const RadioSelectMobile = <T extends number | object | string = string>({
  open,
  onClose,
  labelSearch,
  searchable,
  title,
  searchText,
  onChangeSearchText,
  options,
  children,
  onChange,
  labelNoResults,
}: RadioSelectMobileProps<T>) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)

  return (
    <Popup
      open={open}
      onClose={onClose}
      aria-label={labelSearch}
      labelButtonClose="Close"
      variant="bottom-sheet"
      shouldKeepMaxHeight={searchable}
      scrollRef={scrollRef}
    >
      <VStack gap="s-6">
        {(searchable || title) && (
          <Header variant="bottom-sheet">
            {title && <Header.Title>{title}</Header.Title>}
            {searchable && (
              <Header.Sticky>
                <Search
                  placeholder={labelSearch}
                  value={searchText}
                  onChange={onChangeSearchText}
                />
              </Header.Sticky>
            )}
          </Header>
        )}

        {options.length > 0 ? (
          <RadioSelectMobileGroup
            scrollRef={scrollRef}
            options={options}
            onChange={onChange}
          >
            {children}
          </RadioSelectMobileGroup>
        ) : (
          <VStack space="s-12" align="center">
            <Image
              size={104}
              alt={labelNoResults}
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
              }}
            />
            <Text variant="primary">{labelNoResults}</Text>
          </VStack>
        )}
      </VStack>
    </Popup>
  )
}
