import React, { PropsWithChildren } from 'react'
import { Box, InputGroup, Item, Token, Widget } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { LapeBulletListTextArea } from '../BulletListTextArea/LapeBulletListTextArea'
import { selectorKeys } from '@src/constants/api'
import { candidateArchetypeRequests } from '@src/api/specialisations'
import { OptionInterface } from '@src/interfaces/selectors'
import { isStatus, Statuses } from '@src/interfaces'
import {
  SpecialisationCandidateArchetypeInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { uniqBy } from 'lodash'

export const CandidateArchetypeFormBody = () => (
  <Widget>
    <Item
      useIcon="Palette"
      iconColor={Token.color.greyTone50}
      color={Token.color.greyTone50}
    >
      <Item.Content>
        <Item.Title>Required skills</Item.Title>
      </Item.Content>
    </Item>
    <Box p="s-16" pt={0}>
      <LapeNewMultiSelect<OptionInterface>
        name="skills"
        placeholder="Skills"
        label="Skills"
        selector={selectorKeys.approved_functional_skills}
        useQuery
        required
      />
    </Box>

    <Item
      useIcon="Cleaning"
      iconColor={Token.color.greyTone50}
      color={Token.color.greyTone50}
    >
      <Item.Content>
        <Item.Title>Prompt</Item.Title>
      </Item.Content>
    </Item>
    <Box p="s-16" pt={0}>
      <InputGroup>
        <LapeBulletListTextArea
          required
          name="must_have_traits"
          label="Must have"
          description="Tell us what your ideal candidate must have. Think education, experience and qualifications that are absolutely essential for success in this role"
        />
        <LapeBulletListTextArea
          required
          name="nice_to_have_traits"
          label="Nice to have"
          description='List any desirable but non-essential criteria. For example: "Experience with FinTech products, familiarity with Agile methodologies, fluent in Spanish.'
        />
        <LapeBulletListTextArea
          required
          name="red_flag_traits"
          label="Red flags"
          description="Identify any red flags that would make a candidate unsuitable for this role"
        />
      </InputGroup>
    </Box>
  </Widget>
)

export const CandidateArchetypeLapeFormContext = ({
  archetype,
  specialisation,
  children,
}: PropsWithChildren<{
  archetype?: SpecialisationCandidateArchetypeInterface
  specialisation: SpecialisationInterface
}>) => {
  if (archetype) {
    return (
      <Form
        api={candidateArchetypeRequests}
        disableDataCleanup
        disableLocalStorageCaching
        forceParams={{ id: String(archetype.id) }}
      >
        {children}
      </Form>
    )
  }

  return (
    <Form
      api={candidateArchetypeRequests}
      disableDataCleanup
      disableLocalStorageCaching
      isExistingForm={false}
      initialValues={{
        skills: uniqBy(
          [...specialisation.role.skillsets, ...specialisation.skillsets],
          'id',
        ).map(skill => ({
          id: skill.id,
          name: skill.name,
          status: isStatus(skill.status) ? skill.status : undefined,
        })),
        owner: { ...specialisation.owner },
        specialisation: { id: specialisation.id, name: specialisation.name },
        status: Statuses.active,
      }}
    >
      {children}
    </Form>
  )
}
