import React from 'react'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { Avatar, Box, HStack, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

interface MultipleEmployeesProps {
  employees?: (EmployeeInterface | EmployeeOptionInterface)[]
  maxEmployees?: number
  hideName?: boolean
}

export const MultipleEmployees = ({
  employees,
  maxEmployees = 3,
  hideName = false,
}: MultipleEmployeesProps) => {
  const tooltip = useTooltip()
  const slicedEmployees = employees?.slice(0, maxEmployees)

  if (!employees?.length) {
    return <span>-</span>
  }

  if (employees.length === 1) {
    const employee = employees[0]
    return (
      <UserWithAvatar
        compact={hideName}
        {...employee}
        status={
          employee.status && typeof employee.status === 'object'
            ? employee.status.id
            : employee.status
        }
      />
    )
  }

  const renderRestEmployee = () => {
    const restEmployee = employees?.slice(maxEmployees)

    return (
      <Box p="s-8">
        {restEmployee?.map(employee => (
          <Text key={employee.id} use="div" whiteSpace="nowrap">
            {employee.full_name}
          </Text>
        ))}
      </Box>
    )
  }

  return (
    <HStack gap="s-4">
      {slicedEmployees!.map(({ avatar, full_name, id }, index) => (
        <UserWithAvatar
          id={id}
          avatar={avatar}
          name={full_name}
          key={`multi_employee_${full_name}_${index}`}
          data-testid={`multi_employee_${full_name}`}
          compact={hideName}
        />
      ))}
      {slicedEmployees!.length < employees?.length && (
        <Box {...tooltip.getAnchorProps()}>
          <Avatar
            bg={Token.color.greyTone20}
            data-testid="multi_employee_rest"
            style={{ flexShrink: 0 }}
            size={24}
          >
            <Text color={Token.color.background}>
              +{employees.length - slicedEmployees!.length}
            </Text>
          </Avatar>
          <Tooltip {...tooltip.getTargetProps()}>{renderRestEmployee()}</Tooltip>
        </Box>
      )}
    </HStack>
  )
}
