import {
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'

export const useCanViewOneToOneSummary = (review: ReviewScorecardInterface) => {
  return (
    !!review.reviewer_relation &&
    [ReviewerRelation.LineManager, ReviewerRelation.FunctionalManager].includes(
      review.reviewer_relation,
    ) &&
    review.category === ReviewCategory.Performance
  )
}
