import React, { useMemo } from 'react'
import { Token } from '@revolut/ui-kit'

import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { useTable } from '@components/TableV2/hooks'
import { employeesBulkArchive, getEmployeesSimpleStats } from '@src/api/employees'
import { API, selectorKeys } from '@src/constants/api'
import { getEditableTable } from '@src/features/GenericEditableTable/api'
import {
  BulkDeleteExistingEntitiesButton,
  BulkEditExistingEntitiesAction,
} from '@src/features/GenericEditableTable/components'
import { getBulkSessionFieldsMap } from '@src/features/GenericEditableTable/helpers'
import { GetRequestData } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import {
  EmployeeSimpleStatsFormattedInterface,
  EmployeesSimpleInterface,
} from '@src/interfaces/employees'
import { EditableEmployeesTableProps } from '../common'
import { employeesSimpleTableFilterByInitial } from '../common/constants'
import { TableView } from '../common/TableView'
import {
  bulkFieldsDependencies,
  createGetRow,
  mandatoryBulkSessionFields,
} from './common'
import { useGetOnboardingStats } from './hooks'

const statsConfig: StatsConfig<EmployeeSimpleStatsFormattedInterface> = [
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.foreground,
  },
  {
    key: 'hired',
    title: 'Hired',
    filterKey: 'hired',
    color: Token.color.foreground,
  },
  {
    key: 'onboarding',
    title: 'Onboarding',
    filterKey: 'onboarding',
    color: Token.color.foreground,
  },
  {
    key: 'pending',
    title: 'Pending',
    filterKey: 'pending',
    color: Token.color.foreground,
  },
  {
    key: 'active_externals',
    title: 'External',
    filterKey: 'active_externals',
    color: Token.color.foreground,
  },
]

type Props = {
  sessionRoute: string
  statsView?: 'onboarding' | 'default'
} & Omit<
  EditableEmployeesTableProps,
  'getRow' | 'renderEditActionsRight' | 'bulkSessionFieldsMap'
>
export const EditableEmployeesTableDetailed = ({
  statsView,
  sessionRoute,
  renderEditActionsLeft,
  useMainAddForm,
}: Props) => {
  const bulkSessionFieldsMap = getBulkSessionFieldsMap(
    mandatoryBulkSessionFields,
    bulkFieldsDependencies,
  )

  const table = useTable<
    ImportInterface<EmployeesSimpleInterface>,
    EmployeeSimpleStatsFormattedInterface
  >(
    {
      getItems: getEditableTable<EmployeesSimpleInterface>(
        API.EMPLOYEES_SIMPLE,
        undefined,
        (data: GetRequestData<EmployeesSimpleInterface>) => {
          return {
            ...data,
            results: data.results.map(r => ({
              id: r.id,
              data: r,
              errors: {
                ...(r.location == null ? { location: ['Location is required'] } : null),
                ...(r.line_manager == null
                  ? { line_manager: ['Line manager is required'] }
                  : null),
              },
              error_message: null,
              state: { id: 'pending', name: 'Pending' },
              loading: {},
            })),
          }
        },
      ),
      getStats: getEmployeesSimpleStats,
    },
    employeesSimpleTableFilterByInitial,
  )

  const statFiltersProps = useSelectableTableStats<
    ImportInterface<EmployeesSimpleInterface>,
    EmployeeSimpleStatsFormattedInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  const onboardingStats = useGetOnboardingStats(employeesSimpleTableFilterByInitial)
  const onboardingStatsFilters = useMemo(() => {
    return [
      {
        id: 'total' as const,
        title: 'Total',
        value: onboardingStats.total,
        loading: onboardingStats.isLoading,
      },
      onboardingStats.errors && onboardingStats.errors > 0
        ? {
            id: 'errors' as const,
            title: 'Errors',
            value: onboardingStats.errors,
            loading: onboardingStats.isLoading,
            color: Token.color.danger,
          }
        : null,
    ].filter(Boolean)
  }, [onboardingStats.errors, onboardingStats.total, onboardingStats.isLoading])

  const onErrorFilterChange = (filter: 'total' | 'errors') => {
    if (filter === 'total') {
      table.resetFiltersAndSorting()
      return
    }

    table.onFilterChange([
      {
        columnName: 'id',
        filters: onboardingStats.errorRows?.map(r => ({ id: r.id, name: '' })) || [],
      },
    ])
  }

  return (
    <TableView
      isDetailed
      getRow={createGetRow()}
      sessionRoute={sessionRoute}
      useMainAddForm={useMainAddForm}
      renderEditActionsLeft={renderEditActionsLeft}
      bulkSessionFieldsMap={getBulkSessionFieldsMap(
        mandatoryBulkSessionFields,
        bulkFieldsDependencies,
      )}
      table={table}
      tableInfo={
        statsView === 'default' ? (
          <StatFilters {...statFiltersProps} />
        ) : statsView === 'onboarding' ? (
          <StatFilters
            filters={onboardingStatsFilters}
            onClick={onErrorFilterChange}
            selectedFilter="none"
          />
        ) : undefined
      }
      onAfterRefresh={onboardingStats.refetch}
      hiddenColumns={{ entity: true }}
      renderEditActionsRight={props => (
        <>
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="LocationPin"
            field="location"
            selector={selectorKeys.location}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={bulkSessionFieldsMap.location}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="Profile"
            field="line_manager"
            label="manager"
            selector={selectorKeys.all_employees_avatar_email}
            selectorField="email"
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={bulkSessionFieldsMap.line_manager}
          />
          <BulkDeleteExistingEntitiesButton
            {...props}
            apiHandler={employeesBulkArchive}
          />
        </>
      )}
    />
  )
}
