import type { Color, Token } from '@revolut/ui-kit'
import { opacity } from '@revolut/ui-kit'
import { CHART_COLORS } from '@src/pages/Forms/QueryForm/components/Charts/constants'
import { BaseColor, ValueOf } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { VisualisationType } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'

const LABEL_WIDTH_MULTIPLIER = 6.5
const LABEL_PADDING = 20
const NORMALIZED_STEPS = [1, 2, 2.5, 5, 10, 25, 50]

function calculateStepSize(
  minValue: number,
  maxValue: number,
  numberOfSteps: number,
): number {
  const range = maxValue - minValue
  const rawStepSize = range / numberOfSteps

  // Determine the magnitude of the raw step size and scale normalized steps by the magnitude
  const magnitude = 10 ** Math.floor(Math.log10(rawStepSize))
  const scaledSteps = NORMALIZED_STEPS.map(step => step * magnitude)

  let stepSize =
    scaledSteps.find(step => Math.ceil(range / step) <= numberOfSteps) ?? range

  // Ensure the step size is at least 1
  stepSize = Math.max(stepSize, 1)
  return stepSize
}

/*
  Manually calculate numeric ticks for chart labels based on values, number of ticks and predefined set of steps.
  Automatic calculation provided by recharts is not working well for very small values.
*/
export const getTicks = (
  values: number[],
  tickCount: number,
  chartType: VisualisationType,
): number[] => {
  if (tickCount <= 0 || values.length < 1) {
    return []
  }

  const minValue = Math.min(...values)
  const maxValue = Math.max(...values)

  if (tickCount === 1) {
    return [maxValue]
  }

  const range = maxValue - minValue
  // adding 10% padding to ensure the ticks are outside the chart values range
  const padding = chartType === 'line' ? range * 0.1 : 0
  let adjustedMin = minValue - padding
  let adjustedMax = maxValue + padding

  // for bar charts setting y-axis min value to 0 if all values are positive
  if (chartType !== 'line' && adjustedMin > 0) {
    adjustedMin = 0
  }

  const stepSize = calculateStepSize(adjustedMin, adjustedMax, tickCount - 1)

  adjustedMin =
    chartType !== 'line' && adjustedMin >= 0
      ? 0
      : Math.floor(minValue / stepSize) * stepSize

  let adjustedMaxTick = adjustedMin + stepSize * (tickCount - 1)
  while (adjustedMaxTick <= maxValue) {
    adjustedMaxTick += stepSize
  }

  const ticks = []
  for (let i = 0; i <= tickCount - 1; i++) {
    ticks.push(adjustedMin + i * stepSize)
  }
  return ticks
}

// Dynamically calculate width based on the size of the longest label
export const getAxisWidth = (ticks: number[], formatter?: (value: number) => string) => {
  const maxSize = ticks.reduce((acc, curr) => {
    const length = formatter ? formatter(curr).length : curr.toString().length
    return length > acc ? length : acc
  }, 0)

  return maxSize * LABEL_WIDTH_MULTIPLIER + LABEL_PADDING
}

export const getPercentageLabel = (percentage: number) =>
  `${percentage > 0 && percentage < 1 ? '<1' : percentage.toFixed(0)}%`

export const getMainShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 0.8)
export const getSecondaryShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 0.7)
export const getProjectedShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 0.5)
export const getActiveShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 1)

export function getColorByIndex(id: number, colors?: Array<BaseColor>): BaseColor
export function getColorByIndex(id: number, colors?: Array<Color>): Color
export function getColorByIndex(
  id: number,
  colors: Array<BaseColor | Color> = CHART_COLORS,
) {
  return colors[id % colors.length]
}

export const getIndexByColor = (color: BaseColor, colors: BaseColor[] = CHART_COLORS) => {
  return colors.findIndex(c => c === color) || 0
}

export const formatIfNumber = (value: number | string) => {
  if (!value || typeof value === 'string' || isNaN(value)) {
    return value
  }

  if (value > 0.001) {
    return numberWithCommas(parseFloat(value.toFixed(2)))
  }
  return numberWithCommas(parseFloat(value.toFixed(10)))
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
