import React from 'react'

import NewSelectionCard, {
  NewSelectionCardProps,
} from '@src/components/NewSelectionCard/NewSelectionCard'
import {
  BundleType,
  FeatureFlags,
  GlobalSettings,
  PermissionTypes,
} from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { GlobalSettingsRecord } from '@src/api/settings'

export type SettingsItemSection =
  | 'Employee settings'
  | 'Time off settings'
  | 'Documents settings'
  | 'Jobs settings'
  | 'Candidates settings'
  | 'Goals settings'
  | 'Organisation settings'
  | 'Performance settings'

export interface SettingsLinkItemConfig<T> {
  id: T
  icon?: NewSelectionCardProps['icon']
  image?: NewSelectionCardProps['image']
  section: SettingsItemSection
  title: string
  searchTitle?: string
  subtitle?: string | JSX.Element
  to: string
  /** Visibility checks */
  permissions: PermissionTypes[]
  bundles: BundleType[]
  featureFlag?: FeatureFlags
  globalSetting?: GlobalSettings
  hideIfCommercial?: boolean
}

export interface SettingsLinkItem {
  searchKeys: string[]
  component: React.ReactNode
  id: string
}

export const getSettingsLinkConfig = <T extends string>({
  config,
  permissions,
  featureFlags,
  globalSettings,
  type,
}: {
  config: SettingsLinkItemConfig<T>[]
  permissions: PermissionTypes[]
  featureFlags?: FeatureFlags[]
  globalSettings?: Partial<GlobalSettingsRecord>
  type: 'settings' | 'search'
}) => {
  return config.reduce<Record<T, SettingsLinkItem | undefined>>(
    (settingsLinkConfig, value) => {
      const hasPermission = value.permissions.some(p => permissions.includes(p))
      const hasBundlePermission = value.bundles.every(b => featureFlags?.includes(b))
      const hasFeatureFlag = value.featureFlag
        ? featureFlags?.includes(value.featureFlag)
        : true
      const hasGlobalSetting = value.globalSetting
        ? !!globalSettings?.[value.globalSetting]
        : true
      const commercialVisible = value.hideIfCommercial
        ? !featureFlags?.includes(FeatureFlags.CommercialProduct)
        : true
      const canAccess =
        hasPermission &&
        hasBundlePermission &&
        hasFeatureFlag &&
        hasGlobalSetting &&
        commercialVisible

      return {
        ...settingsLinkConfig,
        [value.id]: {
          searchKeys: canAccess
            ? [
                value.title,
                value.searchTitle,
                typeof value.subtitle === 'string'
                  ? value.subtitle
                  : value.subtitle?.props?.defaultMessage,
                value.section,
              ].filter(Boolean)
            : [],
          component: canAccess ? (
            <NewSelectionCard
              icon={value.icon}
              image={value.image}
              title={type === 'settings' ? value.title : value.searchTitle || value.title}
              to={value.to}
              subtitle={value.subtitle}
              use={InternalLink}
            />
          ) : null,
          id: value.id,
        },
      }
    },
    {} as Record<T, SettingsLinkItem>,
  )
}
