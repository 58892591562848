import React, { useContext, useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Flex, Input } from '@revolut/ui-kit'
import {
  QueryInterface,
  QueryParameterInterface,
  QueryParameterTypes,
} from '@src/interfaces/dataAnalytics'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { QueryContext } from '../QueryContextProvider'

interface TextParameterProps {
  defaultValue: string | null
  name: string
}

interface DateParameterProps {
  defaultValue: Date | null
  name: string
}

const DateParameter = ({ defaultValue, name }: DateParameterProps) => {
  const { resetParameters, setQueryRunParameters } = useContext(QueryContext)
  const [inputValue, setInputValue] = useState(defaultValue)

  useEffect(() => {
    setInputValue(defaultValue)
    setQueryRunParameters(prevValue => ({ ...prevValue, [name]: defaultValue }))
  }, [defaultValue, name])

  useEffect(() => {
    if (resetParameters && !inputValue) {
      setInputValue(defaultValue)
    }
  }, [resetParameters])

  return (
    <DatePickerInput
      label={name}
      onChange={date => {
        setInputValue(date)
        setQueryRunParameters(prevValue => ({
          ...prevValue,
          [name]: date || defaultValue,
        }))
      }}
      value={inputValue}
      width={216}
      /** To prevent form submit on enter press */
      form="none"
    />
  )
}

const TextParameter = ({ defaultValue, name }: TextParameterProps) => {
  const { resetParameters, setQueryRunParameters } = useContext(QueryContext)
  const [inputValue, setInputValue] = useState(defaultValue || '')

  useEffect(() => {
    setInputValue(defaultValue || '')
    setQueryRunParameters(prevValue => ({ ...prevValue, [name]: defaultValue }))
  }, [defaultValue, name])

  useEffect(() => {
    if (resetParameters && !inputValue) {
      setInputValue(defaultValue || '')
    }
  }, [resetParameters])

  return (
    <Input
      label={name}
      name={name}
      onChange={e => {
        const { value } = e.currentTarget

        setInputValue(value)
        setQueryRunParameters(prevValue => ({
          ...prevValue,
          [name]: value || defaultValue,
        }))
      }}
      onClear={() => {
        setInputValue('')
        setQueryRunParameters(prevValue => ({
          ...prevValue,
          [name]: defaultValue,
        }))
      }}
      value={inputValue}
      width={216}
      /** To prevent form submit on enter press */
      form="none"
    />
  )
}

export const ParametersList = () => {
  const { values } = useLapeContext<QueryInterface>()

  return (
    <Flex flexWrap="wrap" gap="s-4" maxHeight={56} mb="s-12" overflow="auto">
      {values.parameters.map((parameter: QueryParameterInterface) => {
        if (parameter.type === QueryParameterTypes.Date) {
          return (
            <DateParameter
              defaultValue={parameter.default as Date | null}
              key={parameter.name}
              name={parameter.name}
            />
          )
        }
        return (
          <TextParameter
            defaultValue={parameter.default as string | null}
            key={parameter.name}
            name={parameter.name}
          />
        )
      })}
    </Flex>
  )
}
