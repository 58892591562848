import React from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  DetailsCell,
  Group,
  Item,
  Link,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillInterface, SkillTypeTitle } from '@src/interfaces/skills'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { StyledLI, StyledOL, StyledUL } from '@components/CommonSC/General'
import { Statuses } from '@src/interfaces'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { DEFINING_SKILLS } from '@src/constants/externalLinks'
import { formatDate, formatTimeAgo } from '@src/utils/format'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import useApproval from '@src/features/ApprovalFlow/useApproval'
import { useGetSkillApprovals } from '@src/api/skills'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PlaybookPreview } from './components/PlaybookPreview'
import { ResourcePreview } from './components/ResourcePreview'

const Preview = () => {
  const { values, initialValues } = useLapeContext<SkillInterface>()

  const isPendingApproval = [
    Statuses.pending,
    Statuses.pending_reapproval,
    Statuses.expired,
  ].includes(initialValues.status || values.status)

  const isInReapproval = [Statuses.pending_reapproval, Statuses.expired].includes(
    initialValues.status || values.status,
  )

  const { approvalFlow } = useApproval({
    data: values,
    useGetApprovals: useGetSkillApprovals,
    isPendingApproval,
    noReject: isInReapproval,
    approveButtonActionText: isInReapproval ? 'Mark as reviewed' : undefined,
  })

  return (
    <PageBody gap="s-16">
      {initialValues.status === Statuses.pending_reapproval &&
        values.valid_until &&
        values.approval_date_time && (
          <ActionWidget
            title={
              <>
                Review and re-approve of this skill is due by{' '}
                {formatDate(values.valid_until)}
              </>
            }
            text={
              <>
                This skill was last reviewed on {formatDate(values.approval_date_time)}.
                Please review whether the skill description and mastery are still valid
                and of good quality.{' '}
                <HideIfCommercial>
                  To know how to review a skill{' '}
                  <Link href={DEFINING_SKILLS} target="_blank">
                    click here
                  </Link>
                  .
                </HideIfCommercial>
              </>
            }
          />
        )}
      {initialValues.status === Statuses.expired && values.approval_date_time && (
        <ActionWidget
          title={<>Last reviewed {formatTimeAgo(values.approval_date_time)}</>}
          text={
            <>
              This skill was last reviewed on {formatDate(values.approval_date_time)}.
              Please review whether the skill matrices are still valid and of good
              quality.{' '}
              <HideIfCommercial>
                To know how to review a skill{' '}
                <Link href={DEFINING_SKILLS} target="_blank">
                  click here
                </Link>
                .
              </HideIfCommercial>
            </>
          }
        />
      )}
      {approvalFlow}
      {initialValues.status === Statuses.pending && (
        <Widget p="s-16" mb="s-16">
          <Text use="p" color="grey-tone-50" variant="caption" fontWeight={500} mb="s-8">
            Approval checklist
          </Text>
          <Text use="p">
            Please review the skill contents against the following quality checks:
            <StyledUL indicatorColor="grey-tone-50">
              <StyledLI>
                Clear, concise, objective language is used in mastery level definitions.
              </StyledLI>
              <StyledLI>
                Skill description is logical and explains how to review the skill.
              </StyledLI>
              <StyledLI>
                The skill is unique and complimentary to the existing list.
              </StyledLI>
              <StyledLI>
                There are at least 2 criteria given per mastery level. Ideally
                'Intermediate' level has at least 3.
              </StyledLI>
              <StyledLI>
                High quality playbook is attached, explaining what the skill is, how it is
                used, why it is needed, associated methods and techniques, and resources
                on how to improve.
              </StyledLI>
            </StyledUL>
          </Text>
        </Widget>
      )}
      <FormPreview<SkillInterface> data={values}>
        <Group pb="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Document" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Details</Item.Title>
            </Item.Content>
          </Item>
          <FormPreview.Details field="description" title="Skill description" />
          <FormPreview.Item<SkillInterface>
            title="Category"
            insert={data => SkillTypeTitle[data.skill_type]}
          />
          <FormPreview.Item<SkillInterface> title="Function" field="function.name" />
          <FormPreview.Item<SkillInterface> title="Owner" type="employee" field="owner" />
          <DetailsCell>
            <DetailsCell.Title>Skill playbooks</DetailsCell.Title>
            {!values.playbook_urls?.length && (
              <DetailsCell.Content>-</DetailsCell.Content>
            )}
          </DetailsCell>
          <VStack gap="s-8" px="s-16" pb={(values.resources ?? []).length ? 's-16' : 0}>
            {values.playbook_urls?.map(playbookUrl => (
              <PlaybookPreview
                key={playbookUrl}
                playbookUrl={playbookUrl}
                skillDescription={values.description}
                skillName={values.name}
              />
            ))}
          </VStack>
          <DetailsCell>
            <DetailsCell.Title>Skill resources</DetailsCell.Title>
            {!(values.resources ?? []).length && (
              <DetailsCell.Content>-</DetailsCell.Content>
            )}
          </DetailsCell>
          <VStack gap="s-8" px="s-16">
            {(values.resources ?? []).map(resource => (
              <ResourcePreview key={resource.url} resource={resource} />
            ))}
          </VStack>
        </Group>
      </FormPreview>

      <Widget>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="RepairTool" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Skill mastery</Item.Title>
          </Item.Content>
          <Item.Side>
            <ActionButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.SKILL.SCORECARD_PREVIEW, { id: values.id })}
            >
              Scorecard preview
            </ActionButton>
          </Item.Side>
        </Item>
        <VStack space="s-16" p="s-16">
          {values.mastery_level_definition_poor && (
            <Box>
              <Text variant="caption">Poor</Text>
              <StyledOL type="a" indicatorColor="grey-tone-50">
                {values.mastery_level_definition_poor.map(str => (
                  <li key={str}>
                    <Text variant="caption">{str}</Text>
                  </li>
                ))}
              </StyledOL>
            </Box>
          )}
          {values.mastery_level_definition_basic && (
            <Box>
              <Text variant="caption">Basic</Text>
              <StyledOL type="a" indicatorColor="grey-tone-50">
                {values.mastery_level_definition_basic.map(str => (
                  <li key={str}>
                    <Text variant="caption">{str}</Text>
                  </li>
                ))}
              </StyledOL>
            </Box>
          )}
          {values.mastery_level_definition_intermediate && (
            <Box>
              <Text variant="caption">Intermediate</Text>
              <StyledOL type="a" indicatorColor="grey-tone-50">
                {values.mastery_level_definition_intermediate.map(str => (
                  <li key={str}>
                    <Text variant="caption">{str}</Text>
                  </li>
                ))}
              </StyledOL>
            </Box>
          )}
          {values.mastery_level_definition_advanced && (
            <Box>
              <Text variant="caption">Advanced</Text>
              <StyledOL type="a" indicatorColor="grey-tone-50">
                {values.mastery_level_definition_advanced.map(str => (
                  <li key={str}>
                    <Text variant="caption">{str}</Text>
                  </li>
                ))}
              </StyledOL>
            </Box>
          )}
          {values.mastery_level_definition_expert && (
            <Box>
              <Text variant="caption">Expert</Text>
              <StyledOL type="a" indicatorColor="grey-tone-50">
                {values.mastery_level_definition_expert.map(str => (
                  <li key={str}>
                    <Text variant="caption">{str}</Text>
                  </li>
                ))}
              </StyledOL>
            </Box>
          )}
        </VStack>
      </Widget>
    </PageBody>
  )
}

export default Preview
