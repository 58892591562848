import {
  IndividualJiraMetricsInterface,
  JiraNumericMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'

export const getJiraNumericMetricLink = (
  metricKey: JiraNumericMetrics,
  metrics: IndividualJiraMetricsInterface,
): string | undefined => {
  if (metricKey === JiraNumericMetrics.TicketsClosed) {
    return metrics.tickets_closed_link ?? undefined
  }

  if (metricKey === JiraNumericMetrics.BugsClosed) {
    return metrics.bugs_closed_link ?? undefined
  }

  return undefined
}
