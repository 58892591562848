import React from 'react'
import pluralize from 'pluralize'
import { Flex, HStack, Icon, Progress, Text, Token, VStack } from '@revolut/ui-kit'

import Tooltip from '@components/Tooltip/Tooltip'
import {
  getScheduleString,
  mapStatusToColor,
  mapSurveyRoundStatusToColorTagVariantV2,
} from '@src/apps/People/Engagement/helpers'
import Table from '@src/components/TableV2/Table'
import { SURVEYS_DEFAULT_ICON } from '@src/constants/common'
import { getRoundEndTime } from '@src/features/Engagement/helpers/getRoundEndTime'
import { Statuses } from '@src/interfaces'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { calculatePercentageBetweenDates, formatDate, timeLeft } from '@src/utils/format'
import { selectorKeys } from '../api'

export const surveyNameColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.engagement_survey_titles,
  title: 'Survey',
  insert: ({ data, children, url }) => (
    <Table.EntityCell
      useIcon={data.icon || SURVEYS_DEFAULT_ICON}
      avatar={data.avatar}
      url={url}
    >
      {children}
    </Table.EntityCell>
  ),
}

export const surveyOwnerColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: null,
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

export const surveyFrequencyColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'frequency',
  dataPoint: 'frequency',
  sortKey: null,
  filterKey: null,
  title: 'Frequency',
  insert: ({ data }) => {
    return (
      <Flex gap="s-8" alignItems="center">
        <Icon name="Calendar" size={16} color={Token.color.grey50} />
        <Text variant="caption" color={Token.color.grey50}>
          {getScheduleString(data)}
        </Text>
      </Flex>
    )
  },
  selectorsKey: selectorKeys.none,
}

export const surveyTimelineColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'timeline',
  dataPoint: 'timeline',
  sortKey: null,
  filterKey: null,
  title: 'Timeline',
  insert: ({ data }) => {
    if (data.round_status?.id === Statuses.running) {
      const progress = calculatePercentageBetweenDates(
        data.sending_date,
        getRoundEndTime({
          startTime: data.sending_date,
          deadlineDays: data.response_deadline_days,
          endTime: data.closing_date,
        }),
      )
      return (
        <VStack gap="s-8">
          <Text variant="h6" textAlign="end">
            {timeLeft(data.closing_date)}
          </Text>
          <Progress value={progress} color={Token.color.success} />
        </VStack>
      )
    }
    if (data.round_status?.id === Statuses.inactive) {
      return (
        <Flex gap="s-8" justifyContent="flex-end">
          <Text variant="caption" color={Token.color.grey50}>
            {data.sending_date ? `Last run: ${formatDate(data.sending_date)}` : '-'}
          </Text>
        </Flex>
      )
    }

    if (data.round_status?.id === Statuses.planned) {
      return (
        <Flex gap="s-8" justifyContent="flex-end">
          <Text variant="caption" color={Token.color.grey50}>
            {data.sending_date ? `Start date: ${formatDate(data.sending_date)}` : '-'}
          </Text>
        </Flex>
      )
    }

    return (
      <Text variant="caption" color={Token.color.grey50}>
        -
      </Text>
    )
  },
  selectorsKey: selectorKeys.none,
}

export const surveyRecurrenceColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'sent_every.id',
  dataPoint: 'sent_every.name',
  sortKey: 'sent_every',
  filterKey: 'sent_every',
  selectorsKey: selectorKeys.engagement_survey_recurrence,
  title: 'Recurrence',
  insert: ({ data }) => {
    const isPeriodical = data.sent_every.id !== 'manual'

    return (
      <HStack space="s-8" align="center">
        <Text>{isPeriodical ? 'Periodical' : 'Ad-hoc'}</Text>
        {isPeriodical && (
          <Tooltip placement="right" text={getScheduleString(data)}>
            <Icon name="InfoOutline" size={14} />
          </Tooltip>
        )}
      </HStack>
    )
  },
}

export const surveyDeadlineColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.range,
  idPoint: 'response_deadline_days',
  dataPoint: 'response_deadline_days',
  sortKey: 'response_deadline_days',
  filterKey: 'response_deadline_days',
  selectorsKey: selectorKeys.none,
  title: 'Deadline',
  insert: ({ data }) => {
    if (!data.response_deadline_days) {
      return '-'
    }
    return (
      <Text>{`${data.response_deadline_days} ${pluralize(
        'day',
        data.response_deadline_days,
      )}`}</Text>
    )
  },
}

export const surveyAudienceColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.range,
  idPoint: 'audience_size',
  dataPoint: 'audience_size',
  sortKey: 'audience_size',
  filterKey: 'audience_size',
  selectorsKey: selectorKeys.none,
  title: 'Audience',
  insert: ({ data }) => {
    if (!data.audience_size) {
      return '-'
    }
    return (
      <Text>{`${data.audience_size} ${pluralize('employee', data.audience_size)}`}</Text>
    )
  },
}

export const surveyUpdatedByColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'updated_by.id',
  dataPoint: 'updated_by.full_name',
  sortKey: 'updated_by__full_name',
  filterKey: 'updated_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Updated by',
  insert: ({ data }) => <Table.EmployeeCell employee={data.updated_by} />,
}

export const surveySentOnColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.date,
  idPoint: 'sent_on',
  dataPoint: 'sent_on',
  sortKey: 'sent_on',
  filterKey: 'sent_on',
  selectorsKey: selectorKeys.none,
  title: 'Sent on',
}

export const surveyUpdatedOnColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.date,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Updated on',
}

export const surveyRoundStatus: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'round_status.id',
  dataPoint: 'round_status.id',
  sortKey: 'round_status',
  filterKey: 'round_status',
  selectorsKey: selectorKeys.engagement_round_statuses,
  title: 'Status',
  insert: ({ data }) =>
    data.round_status ? (
      <Table.StatusCell
        status={data.round_status?.name}
        variant={mapSurveyRoundStatusToColorTagVariantV2(data)}
      />
    ) : (
      '-'
    ),
}

export const surveyStatusColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  colors: mapStatusToColor,
  selectorsKey: selectorKeys.engagement_survey_statuses,
  title: 'Status',
}
