import React, { useRef } from 'react'
import { Toolbox, TransitionFade, useToggle } from '@revolut/ui-kit'
import { PipHRManagerBulkAssign } from './PipHRManagerBulkAssign'
import { PipGoalsBulkApprove } from './PipGoalsBulkApprove'
import { ProbationInterface } from '@src/interfaces/probation'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import { usePipPermissions } from '@src/features/PIP/permissions/usePipPermissions'

interface Props {
  selection: ProbationInterface[] | undefined
  refresh: VoidFunction
}

export const PipBulkActionsToolbar = ({ selection, refresh }: Props) => {
  const containerRef = useRef(null)
  const [bulkAssignHRPopup, toggleBulkAssignHRPopup] = useToggle()
  const [bulkGoalsApprovePopup, toggleBulkGoalsApprovePopup] = useToggle()
  const { canApproveAndInitiatePIP } = usePipPermissions()

  if (!selection || selection.length === 0) {
    return null
  }

  const selectedCyclesIds = selection.map(employee =>
    getReviewCycleIdWithoutPrefix(employee.cycle.id),
  )

  return (
    <>
      <TransitionFade nodeRef={containerRef} in={selection.length > 0}>
        <Toolbox ref={containerRef} selection={selection.length} maxWidth={465}>
          <Toolbox.Action
            useIcon="Message"
            aria-label="Icon button"
            onClick={toggleBulkAssignHRPopup.on}
            disabled={!canApproveAndInitiatePIP}
          >
            Assign HR manager
          </Toolbox.Action>

          <Toolbox.Action
            useIcon="Flag"
            aria-label="Icon button"
            disabled={!canApproveAndInitiatePIP}
            onClick={toggleBulkGoalsApprovePopup.on}
          >
            Approve goals
          </Toolbox.Action>
        </Toolbox>
      </TransitionFade>
      <PipHRManagerBulkAssign
        open={bulkAssignHRPopup}
        cycleIds={selectedCyclesIds}
        onClose={toggleBulkAssignHRPopup.off}
        onSuccess={() => {
          toggleBulkAssignHRPopup.off()
          refresh()
        }}
      />
      <PipGoalsBulkApprove
        open={bulkGoalsApprovePopup}
        cycleIds={selectedCyclesIds}
        onClose={toggleBulkGoalsApprovePopup.off}
        onSuccess={() => {
          toggleBulkGoalsApprovePopup.off()
          refresh()
        }}
      />
    </>
  )
}
