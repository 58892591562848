import { useMemo } from 'react'
import {
  PerformanceInsightsGroupInterface,
  SkillOneToOneInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { useGetEmployee } from '@src/api/employees'
import { useGetEmployeeSkillOneToOneInsights } from '@src/features/AiInsights/Performance/api/insights'
import { useGetSkill } from '@src/api/skills'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SkillInterface } from '@src/interfaces/skills'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { useGetTalentSkillRatingHistory } from '@src/features/ReviewCycle/Analytics/api/skills'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { useGetGrowthPlan } from '@src/api/growthPlans'
import { useGetEmployeeMeetings } from '@src/api/meetings'
import { insightsGroupModel } from '@src/features/AiInsights/Performance/models/insightsGroupModel'
import { InternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { usePerformanceInsights } from '@src/features/AiInsights/Performance/components/Insights/Skills/hooks/usePerformanceInsights'
import { InsightsRelation } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { InsightsOptionInterface } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/types'
import { useInsightsSelector } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/hooks/useInsightsSelector'

interface Props {
  employee: EmployeeInterface | null
  selectedInsights: InsightsOptionInterface | null
  insights: InsightsOptionInterface[]
  insightsGroup: PerformanceInsightsGroupInterface | null
  skill: SkillInterface | null
  skillRatingHistoryData: TalentSkillRatingInterface[]
  growthPlan: GrowthPlanInterface | null
  oneToOneInsights: SkillOneToOneInsightsInterface[]
  oneToOneMeeting: OneToOneMeeting | null
  isLoading: boolean
  onSelectInsights: (insight: InsightsOptionInterface) => void
  onRefreshInsights: VoidFunction
}

export const usePerformanceOutput = (
  employeeId: number,
  cycleId: number,
  skillId: number,
  relation: InsightsRelation,
): Props => {
  const { data: employee, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)
  const { data: skill, isLoading: isSkillLoading } = useGetSkill(skillId)

  const {
    insights,
    growthPlanId,
    isLoading: isInsightsLoading,
    refresh: onRefreshInsights,
  } = usePerformanceInsights({
    employeeId,
    cycleId,
    skillId,
    lmId: employee?.line_manager?.id,
    fmId: employee?.quality_control?.id,
    relation,
  })
  const { selectedInsights, onSelectInsights } = useInsightsSelector(insights)

  const { data: skillRatingHistoryData, isLoading: isSkillRatingHistoryDataLoading } =
    useGetTalentSkillRatingHistory(employeeId, skillId)
  const { data: growthPlan, isLoading: isGrowthPlanLoading } =
    useGetGrowthPlan(growthPlanId)
  const { data: oneToOneInsights, isLoading: isOneToOneInsightsLoading } =
    useGetEmployeeSkillOneToOneInsights(employeeId, selectedInsights?.id, {
      enabled:
        !!employee?.performance_insights_group &&
        insightsGroupModel.hasSourceEnabled(
          employee.performance_insights_group,
          InternalMetricSources.OneToOnes,
        ),
    })
  const [anyOneToOneInsight] = oneToOneInsights ?? []
  const { data: oneToOneMeetings, isLoading: isOneToOneMeetingsLoading } =
    useGetEmployeeMeetings(
      employeeId,
      {
        employee_id: employeeId,
        manager_id: anyOneToOneInsight?.feedback_item.manager?.id,
      },
      {
        enabled: !!anyOneToOneInsight?.feedback_item.manager?.id,
      },
    )

  return useMemo<Props>(
    () => ({
      insights,
      selectedInsights,
      insightsGroup: employee ? employee.performance_insights_group : null,
      employee: employee ?? null,
      skill: skill ?? null,
      growthPlan: growthPlan ?? null,
      skillRatingHistoryData: skillRatingHistoryData ?? [],
      oneToOneInsights: oneToOneInsights ?? [],
      oneToOneMeeting:
        oneToOneMeetings?.results?.length === 1 ? oneToOneMeetings.results[0] : null,
      isLoading:
        isInsightsLoading ||
        isEmployeeLoading ||
        isSkillLoading ||
        isSkillRatingHistoryDataLoading ||
        isGrowthPlanLoading ||
        isOneToOneInsightsLoading,
      onSelectInsights,
      onRefreshInsights,
    }),
    [
      insights.length,
      employee?.id,
      selectedInsights,
      skill?.id,
      growthPlanId,
      isInsightsLoading,
      isEmployeeLoading,
      isSkillLoading,
      isSkillRatingHistoryDataLoading,
      isGrowthPlanLoading,
      isOneToOneInsightsLoading,
      isOneToOneMeetingsLoading,
      onRefreshInsights,
    ],
  )
}
