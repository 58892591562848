import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, Box, VStack } from '@revolut/ui-kit'
import { SourceItem } from '@src/features/AiInsights/Performance/components/Sources/common/SourceItem'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { useChartData } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/hooks/useChartData'
import {
  CHART_HEIGHT,
  RatingHistoryChart,
} from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/Chart'

interface Props {
  skillRatingHistoryData: TalentSkillRatingInterface[]
  isLoading: boolean
}

export const AnalyticsMetrics = ({ skillRatingHistoryData, isLoading }: Props) => {
  const {
    data,
    dataKeys,
    yAxisTicks,
    isLoading: isChartDataLoading,
  } = useChartData(skillRatingHistoryData)

  if (isLoading) {
    return <SourceSkeleton />
  }

  return (
    <VStack space="s-16" data-testid="ai_insights_skill_performance_analytics_metrics">
      <SourceItem
        title={
          <FormattedMessage id="common.labels.analytics" defaultMessage="Analytics" />
        }
        avatar={<Avatar useIcon="Wealth" />}
      />
      <Box width="100%" height={CHART_HEIGHT}>
        <RatingHistoryChart
          data={data}
          dataKeys={dataKeys}
          yAxisTicks={yAxisTicks}
          isLoading={isChartDataLoading}
        />
      </Box>
    </VStack>
  )
}
