import React from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { PermissionTypes, FeatureFlags } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'
import { useCompanyStructuresSettingsEnabled } from '@src/pages/Settings/Structures/components/useCompanyStructuresSettingsEnabled'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const useIsDisabled = () => {
  const permissions = useSelector(selectPermissions)

  return !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
}

export const EnableSpecialisationSettingsField = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const disableEdit = useIsDisabled()

  const hideSpecialisationsEnabled = featureFlags.includes(
    FeatureFlags.HideSpecialisationsEnabled,
  )
  const companyStructuresEnabled = useCompanyStructuresSettingsEnabled()

  return hideSpecialisationsEnabled ? (
    <ItemSwitch
      title={
        companyStructuresEnabled
          ? 'Enable specialisations'
          : 'Allow specialisations for roles'
      }
      name={'enable_hide_specialisations'}
      description={
        companyStructuresEnabled
          ? 'Allows to view specialisations for your company'
          : 'Enable to have an additional level under roles'
      }
      checked={!values.enable_hide_specialisations}
      onChange={e => {
        values.enable_hide_specialisations = !e.target.checked
      }}
      disabled={disableEdit}
    />
  ) : null
}

export const AllowRoleAndSpecialisationOwnersToViewSalariesField = () => {
  const disableEdit = useIsDisabled()

  return (
    <LapeNewSwitch
      itemTypeProps={{
        title: 'Allow Role and Specialisation owners to view salaries',
        description:
          'Allows roles and specialisation owners to view salaries in the talent section',
      }}
      name="enable_role_specialisation_owners_view_salaries"
      disabled={disableEdit}
    />
  )
}

export const EnableMultipleLevelsPerSeniorityField = () => {
  const disableEdit = useIsDisabled()

  return (
    <LapeNewSwitch
      itemTypeProps={{
        title: 'Enable multiple levels per seniority',
        description: 'Allows to define custom levels per seniority',
      }}
      name="enable_multiple_levels_per_seniority"
      disabled={disableEdit}
    />
  )
}

export const EnableDataExportForTalentAndRequisitionSectionsField = () => {
  const disableEdit = useIsDisabled()

  return (
    <LapeNewSwitch
      itemTypeProps={{
        title: 'Enable data export for Talent and Requisition sections',
        description:
          'Allows to download information for functions, roles and specialisations as CSV or XLSX files',
      }}
      name="enable_download_actions_for_function_data"
      disabled={disableEdit}
    />
  )
}

export const FunctionSettingsFields = () => {
  const disableEdit = useIsDisabled()

  return (
    <>
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Enable cost control assignment',
          description:
            'Allows to define a user as the assigned cost control when creating a function',
        }}
        name="enable_cost_control_assignment"
        disabled={disableEdit}
      />
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Enable executive functions',
          description:
            'Allows to set functions as ‘executive’ type. Only executive functions can use director+ seniorities.',
        }}
        name="enable_executive_functions"
        disabled={disableEdit}
      />
    </>
  )
}

export const RoleApprovalStepSettingsField = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const disableEdit = useIsDisabled()

  return (
    <ApprovalSteps
      title="Enable Role approval"
      description="Whenever a new role is created or an existing one is changed it asks for approval"
      switchField="enable_roles_approvals"
      approvalProcess="roles_approval_steps"
      entity="role"
      disabled={disableEdit}
      formRef={values}
    />
  )
}

export const SpecialisationApprovalStepSettingsField = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const disableEdit = useIsDisabled()

  const specialisationsEnabled = useIsSpecialisationsEnabled()

  return (
    <ApprovalSteps
      title={
        specialisationsEnabled ? 'Enable specialisation approval' : 'Enable role approval'
      }
      description={
        specialisationsEnabled
          ? 'Whenever a new specialisation is created or an existing one is changed it asks for approval'
          : 'Whenever a new role is created or an existing one is changed it asks for approval'
      }
      switchField="enable_specialisations_approvals"
      approvalProcess="specialisations_approval_steps"
      entity={specialisationsEnabled ? 'specialisation' : 'role'}
      disabled={disableEdit}
      formRef={values}
    />
  )
}

export const FunctionSettingsSaveButton = () => {
  const queryClient = useQueryClient()

  return (
    <NewSaveButtonWithPopup
      successText="Settings saved successfully"
      onAfterSubmit={() => {
        queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
        queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
        queryClient.invalidateQueries(API.APP_SETTINGS)
      }}
      useValidator
    />
  )
}
