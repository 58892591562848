import { useEffect, useMemo } from 'react'
import { FilterInputSelectorType } from '../../FilterSelect'
import { CycleOption } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import {
  getCycleStatusTitleAndColor,
  getCycleOffsetTitleAndColor,
} from '@src/utils/performance'
import { selectorKeys } from '@src/constants/api'

export type LabelByVariant = 'offset' | 'status'

interface UseCycleFilterProps {
  filter: FilterByInterface[]
  columnName: string
  selector?: FilterInputSelectorType<CycleOption>
  onChangeCallback?: (val: CycleOption) => void
  labelBy: LabelByVariant
}

export const useCycleFilter = ({
  filter,
  columnName,
  selector = selectorKeys.cycle_offsets,
  onChangeCallback,
  labelBy,
}: UseCycleFilterProps) => {
  const { options, asyncState } = useFetchOptions<CycleOption>(selector)

  const filteredFilter = useMemo(
    () =>
      filter
        ?.find((filterBy: FilterByInterface) => filterBy.columnName === columnName)
        ?.filters.filter(option => option.id !== '' && option.name !== '')?.[0],
    [filter, columnName],
  )

  const selectedPeriod = useMemo(
    () => options.find(option => String(option.value.id) === String(filteredFilter?.id)),
    [options, filteredFilter],
  )

  useEffect(() => {
    if (selectedPeriod?.value) {
      onChangeCallback && onChangeCallback(selectedPeriod.value)
    }
  }, [selectedPeriod?.value?.id, onChangeCallback])

  const { title: tagTitle } =
    labelBy === 'status'
      ? getCycleStatusTitleAndColor(selectedPeriod?.value)
      : getCycleOffsetTitleAndColor(selectedPeriod?.value)

  return {
    options,
    asyncState,
    selectedPeriod,
    tagTitle,
  }
}
