import React from 'react'
import { Tooltip, useTooltip } from '@revolut/ui-kit'
import { KeyboardShortcuts } from '@src/pages/Forms/CVScreening/utils'

type Props = {
  linkedin: {
    disabled: boolean
  }
  scheduling: {
    disabled: boolean
  }
  applicationQuestions: {
    disabled: boolean
  }
  sendOnlineTest: {
    disabled: boolean
  }
}

export const useCVScreeningActionsTooltips = ({
  linkedin,
  scheduling,
  applicationQuestions,
  sendOnlineTest,
}: Props) => {
  const quickArchiveTooltip = useTooltip<HTMLButtonElement>()
  const skipTooltip = useTooltip<HTMLButtonElement>()
  const nextStageTooltip = useTooltip<HTMLButtonElement>()
  const linkedinTooltip = useTooltip<HTMLAnchorElement>()
  const schedulingTooltip = useTooltip<HTMLButtonElement>()
  const applicationQuestionsTooltip = useTooltip<HTMLButtonElement>()
  const timelineTooltip = useTooltip<HTMLButtonElement>()
  const sendOnlineTestTooltip = useTooltip<HTMLButtonElement>()

  return {
    quickArchive: {
      tooltip: (
        <Tooltip {...quickArchiveTooltip.getTargetProps()}>
          {KeyboardShortcuts.quickArchive.label}
        </Tooltip>
      ),
      anchorProps: quickArchiveTooltip.getAnchorProps(),
    },
    skip: {
      tooltip: (
        <Tooltip {...skipTooltip.getTargetProps()}>
          {KeyboardShortcuts.skip.label}
        </Tooltip>
      ),
      anchorProps: skipTooltip.getAnchorProps(),
    },
    nextStage: {
      tooltip: (
        <Tooltip {...nextStageTooltip.getTargetProps()}>
          {KeyboardShortcuts.moveNextStage.label}
        </Tooltip>
      ),
      anchorProps: nextStageTooltip.getAnchorProps(),
    },
    linkedin: {
      tooltip: (
        <Tooltip {...linkedinTooltip.getTargetProps()}>
          {linkedin.disabled
            ? 'There is no Linkedin link'
            : `Go to Linkedin profile (${KeyboardShortcuts.linkedin.label})`}
        </Tooltip>
      ),
      anchorProps: linkedinTooltip.getAnchorProps(),
    },
    scheduling: {
      tooltip: (
        <Tooltip {...schedulingTooltip.getTargetProps()}>
          {scheduling.disabled
            ? 'Scheduling is disabled in the settings'
            : `Schedule the interview (${KeyboardShortcuts.schedule.label})`}
        </Tooltip>
      ),
      anchorProps: schedulingTooltip.getAnchorProps(),
    },
    applicationQuestions: {
      tooltip: (
        <Tooltip {...applicationQuestionsTooltip.getTargetProps()}>
          {applicationQuestions.disabled
            ? 'No application questions found'
            : `Application questions (${KeyboardShortcuts.applicationQuestions.label})`}
        </Tooltip>
      ),
      anchorProps: applicationQuestionsTooltip.getAnchorProps(),
    },
    timeline: {
      tooltip: (
        <Tooltip {...timelineTooltip.getTargetProps()}>
          Timeline ({KeyboardShortcuts.timeline.label})
        </Tooltip>
      ),
      anchorProps: timelineTooltip.getAnchorProps(),
    },
    sendOnlineTest: {
      tooltip: sendOnlineTest.disabled ? (
        <Tooltip {...sendOnlineTestTooltip.getTargetProps()}>
          You cannot send an online test, because the next stage is not an Online Test
        </Tooltip>
      ) : null,
      anchorProps: sendOnlineTestTooltip.getAnchorProps(),
    },
  }
}
