import * as React from 'react'

import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { useChangelogProps } from '@src/utils/form'
import NewDatePicker, {
  NewDatePickerProps,
} from '@components/Inputs/NewDatePicker/NewDatePicker'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'
import {
  getFullDateTimeFormat,
  getShortDateTimeFormat,
  localDateToUtc,
} from '@src/utils/timezones'

interface Props extends Omit<NewDatePickerProps, 'onChange' | 'value'> {
  name: string
  value?: string
  /** To display the date value as if user is in UTC timezone */
  displayInUtc?: boolean
  useShortDateTimeFormat?: boolean
  preserveTime?: boolean
}

const LapeNewDatePicker = ({
  name,
  displayInUtc = true,
  useShortDateTimeFormat = false,
  preserveTime = false,
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const { value, error, apiError, touched, onChange, hidden } = lapeProps
  const formValidator = useFormValidator()
  const changelogProps = useChangelogProps(name)

  if (hidden) {
    return null
  }

  const getDateWithCorrectTime = (newDate: Date): Date => {
    if (preserveTime && value) {
      const oldDate = new Date(getFullDateTimeFormat(value))
      newDate.setHours(oldDate.getHours())
      newDate.setMinutes(oldDate.getMinutes())
    }
    return newDate
  }

  const onDateChange = (date?: Date) => {
    if (!date) {
      onChange(null)
      return
    }
    const newDateTime = preserveTime
      ? getDateWithCorrectTime(date).toISOString()
      : localDateToUtc(date)
    if (useShortDateTimeFormat) {
      onChange(getShortDateTimeFormat(newDateTime))
    } else {
      onChange(newDateTime)
    }
  }

  const inputValue = (() => {
    if (!value) {
      return undefined
    }
    if (displayInUtc) {
      const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000
      return new Date(
        new Date(getFullDateTimeFormat(value)).valueOf() + timeZoneOffset,
      ).toISOString()
    }

    return value
  })()

  return (
    <NewDatePicker
      name={name}
      onChange={onDateChange}
      value={inputValue}
      {...props}
      {...changelogProps}
      error={(touched && error) || apiError || props.error}
      {...getCommonInputProps({ ...props, name }, lapeProps, !!formValidator?.validated)}
    />
  )
}

export default connect(LapeNewDatePicker)
