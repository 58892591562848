import React, { useRef, useState } from 'react'
import { Input, type InputProps } from '@revolut/ui-kit'
import {
  RadioSelect,
  type RadioSelectProps,
} from '@src/pages/Careers/CareersApplication/components/RadioSelect/RadioSelect'

interface RadioSelectInputProps<T extends number | object | string>
  extends Omit<InputProps, 'defaultValue' | 'onChange' | 'value'>,
    Pick<RadioSelectProps<T>, 'options' | 'searchable' | 'value'> {
  onChange: RadioSelectProps<T>['onChange']
  getValueKey: (value: T) => number | string
  loading?: boolean
  createNewOptionAllowed?: boolean
}

const checkValueIsValidObject = (value: unknown): value is { name: string } => {
  return Boolean(
    typeof value === 'object' &&
      value &&
      'name' in value &&
      typeof value.name === 'string',
  )
}

export const RadioSelectInput = <T extends number | object | string = number>({
  options,
  value,
  onChange,
  searchable,
  errorMessage,
  placeholder,
  getValueKey,
  loading,
  createNewOptionAllowed,
}: RadioSelectInputProps<T>) => {
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setOpen] = useState(false)
  const option = options.find(el => el.key === (value ? getValueKey(value) : value))
  const label = option ? String(option.label) : undefined

  const customOptionName =
    createNewOptionAllowed && checkValueIsValidObject(value) ? value.name : null

  const displayValue = label || customOptionName || ''

  return (
    <>
      <Input
        type="button"
        containerRef={anchorRef}
        useIcon="ChevronDown"
        onClick={() => setOpen(!isOpen)}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        value={displayValue}
        label={placeholder}
        errorMessage={errorMessage}
        invalid={Boolean(errorMessage)}
        pending={loading}
      />
      <RadioSelect<T>
        open={isOpen}
        anchorRef={anchorRef}
        onClose={() => setOpen(false)}
        options={options}
        value={value}
        onChange={onChange}
        labelList="Options"
        title="Select option"
        searchable={searchable}
        createNewOptionAllowed={createNewOptionAllowed}
      >
        {({ item }) => item.label}
      </RadioSelect>
    </>
  )
}
