import {
  chain,
  Flex,
  HStack,
  Icon,
  IconName,
  Tag,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import React from 'react'
import { EngagementInsightsTarget } from '@src/interfaces/engagement'
import { InsightWithScore } from './hooks/useGetInsightsWithScore'
import { InsightScoreVariant } from './helpers'
import { InsightScore } from './components/InsightScore'

interface InsightsItemProps {
  insight: InsightWithScore
  onClick: (insight: InsightWithScore) => void
  titleIconName?: IconName
  insightType?: EngagementInsightsTarget
  scoreVariant: InsightScoreVariant
}

export const InsightsItem = ({
  insight,
  onClick,
  titleIconName,
  insightType,
  scoreVariant,
}: InsightsItemProps) => {
  return (
    <VStack width="100%" onClick={() => onClick(insight)} style={{ cursor: 'pointer' }}>
      <Flex
        gap={{ all: 's-6', xl: 's-16' }}
        flexDirection={{ all: 'column', xl: 'row' }}
        justifyContent="space-between"
      >
        <HStack gap="s-6" align="center">
          <Text variant="body2">{insight.title}</Text>
          {titleIconName && (
            <Icon name={titleIconName} color={Token.color.greyTone50} size={14} />
          )}
        </HStack>
        <HStack color={Token.color.greyTone50} style={{ flexShrink: 0 }}>
          {insightType !== 'driver' &&
            chain(
              <Tag useIcon="People" color={Token.color.grey50_40}>
                {insight.driver.name}
              </Tag>,
              typeof insight.avgScore === 'number' && (
                <InsightScore
                  scoreVariant={scoreVariant}
                  avgScore={insight.avgScore}
                  trendAvgScore={insight.trendAvgScore}
                />
              ),
            )}
        </HStack>
      </Flex>
      <Text variant="body2" color={Token.color.greyTone50} mt="s-6">
        {insight.subtitle}
      </Text>
    </VStack>
  )
}
