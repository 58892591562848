import React, { PropsWithChildren } from 'react'
import { DetailsCell, DetailsCellSkeleton } from '@revolut/ui-kit'

interface InterviewerProfileDetailsProps {
  loading: boolean
}

export const InterviewerProfileDetails = ({
  loading,
  children,
}: PropsWithChildren<InterviewerProfileDetailsProps>) => {
  if (loading) {
    return <DetailsCellSkeleton.Content />
  }
  return <DetailsCell.Content>{children}</DetailsCell.Content>
}
