import React from 'react'
import {
  CandidateInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { TranscriptSpeaker } from '@src/components/TranscriptSpeaker/TranscriptSpeaker'

interface TranscriptSpeakerNameProps {
  candidate: CandidateInterface
  interviewer?: InterviewToolInterviewer
  speaker: string
  speakerLabel?: string
}

export const TranscriptSpeakerName = ({
  candidate,
  interviewer,
  speaker,
  speakerLabel,
}: TranscriptSpeakerNameProps) => {
  const type =
    speaker === interviewer?.full_name || speaker === interviewer?.display_name
      ? 'self'
      : speaker === candidate.full_name
      ? 'other'
      : undefined
  return <TranscriptSpeaker speaker={speakerLabel || speaker} type={type} />
}
