import React, { useRef, useState } from 'react'
import { ActionMenu, MoreBar } from '@revolut/ui-kit'
import { StageType } from '@src/interfaces/hiringProccess'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'

type AddStageButtonProps = {
  disabled: boolean
  onClick: (stageType: OptionInterface) => void
}

const AddStageButton = ({ disabled, onClick }: AddStageButtonProps) => {
  const { data } = useGetSelectors(selectorKeys.hiring_stage_types)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const handleClick = (stageType: StageType) => {
    const stage = data?.find(({ id }) => id === stageType)
    if (stage) {
      onClick(stage)
    }
  }
  return (
    <>
      <MoreBar.Action
        disabled={disabled}
        ref={anchorRef}
        useIcon="ChevronDown"
        onClick={() => {
          setOpen(!open)
        }}
      >
        Add stage
      </MoreBar.Action>
      <ActionMenu open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <ActionMenu.Group>
          <ActionMenu.Item
            onClick={() => {
              handleClick('skills_round')
            }}
          >
            <ActionMenu.Title>Skills stage</ActionMenu.Title>
            <ActionMenu.Description>
              Technical interview to assess candidate skills
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              handleClick('screen_call')
            }}
          >
            <ActionMenu.Title>Screen call stage</ActionMenu.Title>
            <ActionMenu.Description>
              Interview to assess candidate motivations and experience
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              handleClick('home_assessment')
            }}
          >
            <ActionMenu.Title>Home task stage</ActionMenu.Title>
            <ActionMenu.Description>
              A task for the candidate to complete in their own time
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              handleClick('final_round')
            }}
          >
            <ActionMenu.Title>Final stage</ActionMenu.Title>
            <ActionMenu.Description>
              Interview with hiring manager and/or team member(s)
            </ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

export default AddStageButton
