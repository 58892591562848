import React from 'react'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useGetCandidateConsent } from '@src/features/CandidateConsentToInterviewAISummarisation/api'
import { AskForCandidateConsent } from '@src/features/CandidateConsentToInterviewAISummarisation/AskForCandidateConsent'
import { WithdrawConsent } from '@src/features/CandidateConsentToInterviewAISummarisation/WithdrawConsent'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

export const CandidateConsentToInterviewAISummarisation = () => {
  const { data, isLoading: loadingCandidateConsent } = useGetCandidateConsent()

  if (loadingCandidateConsent) {
    return <PageLoading />
  }

  if (data?.candidate_consent_to_interview_ai_summarisation) {
    return <WithdrawConsent recruiterEmail={data.recruiter_email} />
  }

  if (data) {
    return <AskForCandidateConsent recruiterEmail={data.recruiter_email} />
  }

  return <PageNotFoundWidget noLink />
}
