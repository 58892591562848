import {
  ActionButton,
  Avatar,
  Button,
  Grid,
  Group,
  HStack,
  Input,
  Item,
  Radio,
  Skeleton,
  useToggle,
  VStack,
  Widget,
  Text,
  Token,
  IconButton,
  Box,
} from '@revolut/ui-kit'
import { goBack, getBackUrl } from '@src/actions/RouterActions'
import {
  useGetGradeEvaluationCriteria,
  useUpdateGradeEvaluationCriterion,
} from '@src/api/performanceSettings'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  GradeEvaluationCriterion,
  PerformanceSettingsInterface,
} from '@src/interfaces/settings'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { connect } from 'lape'
import React, { useEffect, useState } from 'react'
import { parsePercentage } from './utils/parsePercentage'
import { GradesPreviewSidebar } from './components/GradesPreviewSidebar'
import { DeliverablesSettingsWidget } from './components/DeliverablesSettingWidget'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@src/components/Page/PageBody'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { FormattedMessage } from 'react-intl'
import { useIsPerformanceAiInsightsEnabled } from '@src/features/AiInsights/Performance/hooks/useAiInsightsAvailability'

const validateGrades = (grades: Array<any>) => {
  const errors: Record<number, string> = {}

  grades.forEach((grade, i) => {
    const prevGradePercent = grades[i - 1]?.goal_performance_percent ?? -Infinity
    const nextGradePercent = grades[i + 1]?.goal_performance_percent ?? Infinity

    if (i === 0) {
      // First grade: must be 0 or higher
      if (grade.goal_performance_percent === null || grade.goal_performance_percent < 0) {
        errors[i] = 'The first grade must be 0% or higher.'
      }
    } else if (i === grades.length - 1) {
      if (grade.goal_performance_percent !== null) {
        // technically unreachable. The last grade is always null as it's "above the .at(-1)"
        errors[i] = ''
      }
    } else if (
      // Middle grades: must be between previous and next
      grade.goal_performance_percent === null ||
      grade.goal_performance_percent <= prevGradePercent ||
      grade.goal_performance_percent >= nextGradePercent
    ) {
      errors[i] =
        `Grade percentage must be greater than the previous value (${prevGradePercent}%) ` +
        `and less than the next value (${nextGradePercent}%).`
    }
  })

  return errors
}

export const ManageEvaluationCriteria = connect(() => {
  const { values, initialValues, submit, isSubmitting } =
    useLapeContext<PerformanceSettingsInterface>()
  const {
    data: { results: evaluationCriteria = [] } = {},
    isLoading: isCriteriaLoading,
  } = useGetGradeEvaluationCriteria()
  const { mutateAsync: updateEvaluationCriterion, isLoading: isCriterionUpdating } =
    useUpdateGradeEvaluationCriterion()

  const isPerformanceAiInsightsEnabled = useIsPerformanceAiInsightsEnabled()

  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()
  const [previewSidebarOpen, setPreviewSidebarOpen] = useToggle()
  const [updatedGrades, setUpdatedGrades] = useState<
    (Pick<GradeEvaluationCriterion, 'id' | 'goal_performance_percent' | 'label'> & {
      inputPercent: string | null
      error?: string
    })[]
  >([])

  useEffect(() => {
    if (!isCriteriaLoading && evaluationCriteria) {
      setUpdatedGrades(
        evaluationCriteria.map(v => ({
          ...v,
          inputPercent:
            v.goal_performance_percent === null ? null : `${v.goal_performance_percent}%`,
        })),
      )
    }
  }, [evaluationCriteria])

  const setNewLabel = (id: number, label: string) => {
    setUpdatedGrades(prev =>
      prev.map(gradeItem => (gradeItem.id === id ? { ...gradeItem, label } : gradeItem)),
    )
  }

  const setNewPercentage = (id: number, value: string) => {
    const validInput = /^\d*(\.|,)?\d*%?$/
    if (!validInput.test(value)) {
      return
    }

    setUpdatedGrades(prev => {
      return prev.map(gradeItem => {
        const newValue = parsePercentage(value)

        return gradeItem.id === id
          ? {
              ...gradeItem,
              error: undefined,
              goal_performance_percent: newValue,
              inputPercent: value,
            }
          : { ...gradeItem, error: undefined }
      })
    })
  }

  const updateGrades = () => {
    return Promise.all(
      updatedGrades.map(grade => {
        if (grade.error) {
          throw new Error('Provide correct grades percentage')
        }
        const currentGrade = evaluationCriteria.find(({ id }) => id === grade.id)
        if (
          currentGrade &&
          (currentGrade.label !== grade.label ||
            currentGrade.goal_performance_percent !== grade.goal_performance_percent)
        ) {
          return updateEvaluationCriterion([
            grade.id,
            {
              goal_performance_percent: grade.goal_performance_percent,
              label: grade.label,
            },
          ])
        }
        return () => {}
      }),
    )
  }

  const submitAction = async () => {
    // Validate grades
    const errors = validateGrades(updatedGrades)

    if (Object.keys(errors).length > 0) {
      setUpdatedGrades(prev =>
        prev.map((grade, i) => ({
          ...grade,
          error: errors[i], // Attach specific error if it exists
        })),
      )

      throw new Error('Please fix the highlighted errors before submitting.')
    }

    // If validation passes
    await updateGrades()
    submit()
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Manage evaluation criteria"
        backButton={
          <IconButton
            size={24}
            useIcon={'BackButtonArrow'}
            aria-label="close"
            color="foreground"
            use={InternalLink}
            onClick={e => {
              e.preventDefault()
              const isSame = updatedGrades.every(uGrade => {
                const initialGrade = evaluationCriteria.find(
                  cGrade => cGrade.id === uGrade.id,
                )
                return (
                  uGrade.goal_performance_percent ===
                    initialGrade?.goal_performance_percent &&
                  uGrade.label === initialGrade.label
                )
              })
              if (isSame) {
                goBack(ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING)
              } else {
                confirmationPopup.show({
                  showHeader: true,
                  label: 'Unsaved changes',
                  body: `You've got some unsaved changes. Are you sure you want to go back?`,
                  noMessage: 'Cancel',
                  yesMessage: 'Go Back',
                  onConfirm: async () => {
                    goBack(ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING)
                  },
                })
              }
            }}
            // @ts-expect-error object works fine here, but UI kit expects string
            to={getBackUrl(ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING)}
          />
        }
        backUrl={pathToUrl(ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING)}
      />
      <PageBody>
        <VStack gap="s-16">
          <Group>
            <Item>
              <Item.Content>
                <Item.Title>Performance Evaluation Criteria</Item.Title>
                <Item.Description>
                  Choose how managers will assess employee performance: based on quarterly
                  goals or generic deliverables.
                </Item.Description>
              </Item.Content>
            </Item>
            <HStack gap="s-8" px="s-16" pb="s-16">
              <Item
                use="label"
                height="100%"
                aria-pressed={!values.enable_segmented_deliverables_assessment}
                onClick={() => {
                  values.enable_segmented_deliverables_assessment = false
                }}
              >
                <Item.Prefix>
                  <Radio
                    checked={!values.enable_segmented_deliverables_assessment}
                    aria-labelledby="goals-title"
                    aria-describedby="goals-description"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="goals-title">Goals for the cycle</Item.Title>
                  <Item.Description id="goals-description">
                    Evaluate employees based on their individual or team goals set for
                    this cycle.
                  </Item.Description>
                </Item.Content>
              </Item>
              <Item
                use="label"
                height="100%"
                aria-pressed={values.enable_segmented_deliverables_assessment}
                onClick={() => {
                  values.enable_segmented_deliverables_assessment = true
                }}
              >
                <Item.Prefix>
                  <Radio
                    checked={values.enable_segmented_deliverables_assessment}
                    aria-labelledby="deliverables-title"
                    aria-describedby="deliverables-description"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="deliverables-title">Generic deliverables</Item.Title>
                  <Item.Description id="deliverables-description">
                    Assess performance using standardized criteria: Speed, Complexity, and
                    Quality.
                  </Item.Description>
                </Item.Content>
              </Item>
            </HStack>
          </Group>
          {values.enable_segmented_deliverables_assessment && (
            <DeliverablesSettingsWidget />
          )}
          <Widget>
            <Item>
              <Item.Avatar>
                <Avatar useIcon="Trophy" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Grades</Item.Title>
                <Item.Description>
                  The calibration logic sets a benchmark for expected results by defining
                  the percentage of employees you expect to achieve each performance
                  grade.
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <ActionButton useIcon="EyeShow" onClick={setPreviewSidebarOpen.switch}>
                  Preview
                </ActionButton>
              </Item.Side>
            </Item>
            <VStack gap="s-8" pl="s-64" pr="s-16" pb="s-16">
              {isCriteriaLoading ? (
                <>
                  <Skeleton height={50} radius="widget" />
                  <Skeleton height={50} radius="widget" />
                  <Skeleton height={50} radius="widget" />
                  <Skeleton height={50} radius="widget" />
                  <Skeleton height={50} radius="widget" />
                </>
              ) : (
                updatedGrades.map((grade, i) => (
                  <Grid key={grade.id} columns="1fr 100px" columnGap="s-8">
                    <Input
                      value={grade.label}
                      label={`Grade ${i + 1}`}
                      onChange={event => {
                        setNewLabel(grade.id, event.currentTarget.value)
                      }}
                    />
                    <Input
                      invalid={!!grade.error}
                      onChange={event =>
                        setNewPercentage(grade.id, event.currentTarget.value)
                      }
                      label={
                        i === 0
                          ? 'Below'
                          : i === updatedGrades.length - 1
                          ? 'Above'
                          : 'Up to'
                      }
                      value={
                        grade.inputPercent === null
                          ? `>${updatedGrades[i ? i - 1 : i].inputPercent}`
                          : grade.inputPercent
                      }
                      disabled={i === updatedGrades.length - 1}
                    />
                    {grade.error && (
                      <Text
                        variant="small"
                        px="s-4"
                        style={{ gridColumn: '1/3' }}
                        textAlign="end"
                        color={Token.color.danger}
                      >
                        {grade.error}
                      </Text>
                    )}
                  </Grid>
                ))
              )}
            </VStack>
          </Widget>
          {isPerformanceAiInsightsEnabled && (
            <Box data-testid="performance_insights_settings_section">
              <LapeNewSwitch
                name="enable_performance_insights"
                itemTypeProps={{
                  title: (
                    <FormattedMessage
                      id="performace.settings.ai.insights.switcher.title"
                      defaultMessage="Enable insights"
                    />
                  ),
                  description: (
                    <FormattedMessage
                      id="performace.settings.ai.insights.switcher.description"
                      defaultMessage="When enabled, performance ai insights will be generated."
                    />
                  ),
                  avatar: <Avatar useIcon="Gift" />,
                  actions: (
                    <ActionButton
                      useIcon="Pencil"
                      disabled={!values.enable_performance_insights || isSubmitting}
                      pending={isSubmitting}
                      use={InternalLink}
                      to={pathToUrl(ROUTES.PERFORMANCE.INSIGHTS.INSIGHTS_GROUPS)}
                    >
                      <FormattedMessage
                        id="performace.settings.ai.insights.switcher.manage.cta.label"
                        defaultMessage="Manage"
                      />
                    </ActionButton>
                  ),
                  onLabelClick: e => e.preventDefault(),
                }}
                onAfterChange={() => submit()}
              />
            </Box>
          )}
          <PageActions>
            <Button
              variant="secondary"
              onClick={() => {
                values.enable_segmented_deliverables_assessment =
                  initialValues.enable_segmented_deliverables_assessment || false // satisfy TS
                goBack()
              }}
            >
              Cancel
            </Button>
            <NewSaveButtonWithPopup
              useValidator
              pending={isCriterionUpdating || isSubmitting}
              onClick={submitAction}
              errorHandler={e => {
                showStatusPopup({
                  status: 'error',
                  title: "Couldn't update evaluation criteria",
                  description:
                    typeof e.message === 'string' ? e.message : getMessageFromApiError(e),
                })
              }}
              onSubmitError={e => {
                showStatusPopup({
                  status: 'error',
                  title: "Couldn't update evaluation criteria",
                  description: getMessageFromApiError(e),
                })
              }}
              hideWhenNoChanges={false}
              onAfterSubmit={() => {
                goBack()
              }}
            >
              Save
            </NewSaveButtonWithPopup>
          </PageActions>
        </VStack>
        <GradesPreviewSidebar
          isOpen={previewSidebarOpen}
          onClose={setPreviewSidebarOpen.off}
          grades={updatedGrades}
        />
      </PageBody>
    </PageWrapper>
  )
})
