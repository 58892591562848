import React from 'react'
import { Statuses } from '@src/interfaces'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import { Item } from '@revolut/ui-kit'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { relationToString } from '@src/components/ScorecardGeneral/constants'
import { StyledInternalLink } from '@src/components/Atoms/StyledLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ReviewerAction } from './ReviewerAction'

interface Props {
  reviews: GradedRequestInterface[]
  checkpointStatus: Statuses | undefined
  onClickActionButton: VoidFunction
  canSubmit: boolean
}

export const ReviewersList = ({
  reviews,
  checkpointStatus,
  onClickActionButton,
  canSubmit,
}: Props) => {
  return (
    <>
      {reviews.map(review => (
        <Item key={`${review.reviewer?.id}${review.reviewer_relation}`} py="s-8">
          <Item.Avatar>
            <UserAvatar {...review.reviewer} status={undefined} size={40} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{relationToString(review.reviewer_relation, false)}</Item.Title>
            <Item.Description>
              {review.reviewer.id ? (
                <StyledInternalLink
                  to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: review.reviewer?.id,
                  })}
                >
                  {review.reviewer.full_name}
                </StyledInternalLink>
              ) : (
                <>{review.reviewer.full_name}</>
              )}
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ReviewerAction
              review={review}
              canSubmit={canSubmit}
              checkpointStatus={checkpointStatus}
              onClickActionButton={onClickActionButton}
            />
          </Item.Side>
        </Item>
      ))}
    </>
  )
}
