import React from 'react'
import { Box, Flex, VStack } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import {
  BitbucketNumericMetrics,
  ExternalMetricSources,
  IndividualBitbucketMetricsInterface,
  Metrics,
  MetricSources,
  OrgUnitBitbucketMetricsInterface,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { PerformanceInsightsGroupSourceInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { MetricHighlight } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/NumericMetric/MetricHighlight'
import { metricModel } from '@src/features/AiInsights/Performance/models/metricModel'
import {
  metricsGridCellItemStyles,
  metricsGridGap,
} from '@src/features/AiInsights/Performance/components/Metrics/common/styles'
import {
  getMetricLabel,
  getMetricUnit,
  getNumericMetricDetailsVariant,
  NumericMetricAvgCalculationInfo,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { MetricsSourceItem } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsSourceItem/MetricsSourceItem'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'

interface Props<T extends MetricSources, K extends Metrics> {
  orgUnit: BaseOption<string>
  insightsSource: PerformanceInsightsGroupSourceInterface<T, K>
  employeeMetrics: IndividualBitbucketMetricsInterface | null
  orgUnitMetrics: OrgUnitBitbucketMetricsInterface | null
  isLoading: boolean
}

export const BitbucketMetrics = ({
  orgUnit,
  insightsSource,
  employeeMetrics,
  orgUnitMetrics,
  isLoading,
}: Props<ExternalMetricSources.Bitbucket, BitbucketNumericMetrics>) => {
  if (isLoading) {
    return <SourceSkeleton />
  }

  const enabledMetrics = insightsSource.metrics.filter(metricModel.isEnabled)

  if (!employeeMetrics || !orgUnitMetrics || enabledMetrics.length === 0) {
    return null
  }

  return (
    <VStack space="s-16" data-testid="ai_insights_performance_bitbucket_metrics">
      <MetricsSourceItem
        source={ExternalMetricSources.Bitbucket}
        info={<NumericMetricAvgCalculationInfo />}
      />
      <Flex gap={metricsGridGap} flexWrap="wrap" width="100%">
        {enabledMetrics.map(metric => {
          const { name: metricKey } = metric

          const highlightProps = {
            value: employeeMetrics[metricKey] ?? 0,
            average: orgUnitMetrics[metricKey] ?? 0,
            employeeName: employeeMetrics.employee.name,
            orgUnitName: orgUnit.id,
            variant: getNumericMetricDetailsVariant(metricKey),
            comparable: metricModel.isComparable(metric),
            inverted: metricKey === BitbucketNumericMetrics.AverageMergeTime,
            metricName: getMetricUnit(metricKey),
            dataLabel: getMetricLabel(metricKey),
          }

          return (
            <Box
              key={metricKey}
              flex={1}
              style={metricsGridCellItemStyles}
              data-testid={`ai_insights_performance_bitbucket_numeric_metric_${metricKey}`}
            >
              <MetricHighlight {...highlightProps} />
            </Box>
          )
        })}
      </Flex>
    </VStack>
  )
}
