import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  SkillCardInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { RecommendationQuestionInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/BarRaiserSummary'
import { RecommendationExtraSectionQuestionInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/BarRaiserSummaryCollapsable'
import { Flex, HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'
import { getComparedRatingToDeliverableProps } from '@src/constants/columns/performanceSummary'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@src/components/Tooltip/Tooltip'

export const performanceSummarySkillsNameColumn: ColumnInterface<
  | SummarySkillCardInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | RecommendationQuestionInterface
  | RecommendationExtraSectionQuestionInterface
> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => {
    const ratingData =
      'ratings' in data
        ? getComparedRatingToDeliverableProps(data.rating, data.rating_expectation)
        : undefined
    return <NameCell name={data.name} ratingData={ratingData} />
  },
}

interface Props {
  name: string
  ratingData: ReturnType<typeof getComparedRatingToDeliverableProps> | undefined
}

const NameCell = ({ name, ratingData }: Props) => {
  return (
    <Tooltip
      placement="bottom"
      backgroundColor={Token.color.popoverBackground}
      body={
        <VStack p="s-16" width={249} gap="s-8">
          {ratingData && ratingData.attitude !== 'neutral' && (
            <HStack align="center" gap="s-8" color={ratingData.iconColor}>
              <Icon name="16/Warning" size={14} />
              <Text>
                <FormattedMessage
                  id="performanceSummarySkill.table.nameCell.tooltip"
                  defaultMessage="Skill level is {attitude} expectations"
                  values={{
                    attitude: ratingData.attitude === 'positive' ? 'above' : 'below',
                  }}
                />
              </Text>
            </HStack>
          )}
          <Text color={Token.color.foreground}>Click to find out ways to improve</Text>
        </VStack>
      }
    >
      <Flex justifyContent="flex-start" alignItems="center" gap="s-8" width="100%">
        <Text>{name}</Text>
        {ratingData?.attitude !== 'neutral' && (
          <Icon name="Dot" size={16} color={ratingData?.iconColor} />
        )}
      </Flex>
    </Tooltip>
  )
}
