import React from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { getSettingsLinkConfig } from '../common/config'
import { jobsSettingsLinkConfig } from '../Jobs/config'

export const RequistionList = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const config = getSettingsLinkConfig({
    config: jobsSettingsLinkConfig,
    permissions,
    featureFlags,
    type: 'settings',
  })

  return (
    <Group mb="s-24">
      {config.jobsRequisitionSettings?.component}
      {config.jobsHiringCountriesSettings?.component}
      {config.jobsRequisitionsApprovalSettings?.component}
    </Group>
  )
}
