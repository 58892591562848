import React, { useCallback, useEffect, useMemo, useState } from 'react'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import Table from '@src/components/TableV2/Table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  Avatar,
  Box,
  Dropdown,
  ItemSkeleton,
  useDropdown,
  Text,
  MoreBar,
  Token,
  Banner,
} from '@revolut/ui-kit'
import { RequiresFeatureFlagWrapper } from '@src/components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FilterByInterface } from '@src/interfaces/data'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { useGetHiringProcessSettings } from '@src/api/settings'
import HiringStagePreviewForm from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringStagePreviewForm'
import { history, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link, useLocation } from 'react-router-dom'
import { useOrdering, useTable } from '@src/components/TableV2/hooks'
import { specialisationHiringStagesRequests } from '@src/api/specialisations'
import { OptionInterface } from '@src/interfaces/selectors'
import { rolesRequests } from '@src/api/roles'
import { handleError } from '@src/api'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { hiringProcessTableRow } from '../hiringProcessTableRow'
import { formHiringProcessStagesSpecialisationRequest } from '@src/api/hiringProcess'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface Props {
  enableFiltering: boolean
  setEnableFiltering: (enable: boolean) => void
}

const initialFilter: FilterByInterface[] = [
  {
    columnName: 'status',
    filters: [
      {
        id: 'active',
        name: 'active',
      },
    ],
    nonResettable: true,
  },
]

export const HiringProcessFormBody = ({ enableFiltering, setEnableFiltering }: Props) => {
  const location = useLocation<{ stageId?: number } | undefined>()
  const { data: hiringProcessSettings, isLoading: isLoadingHiringProcessSettings } =
    useGetHiringProcessSettings()

  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(
    location.state?.stageId,
  )

  const roundDropdown = useDropdown()
  const [hiringStages, setHiringStages] = useState<OptionInterface[]>([])
  const [role, setRole] = useState<RoleInterface>()
  const context = useLapeContext<SpecialisationInterface>()
  const { values, errors } = context

  const specialisationPermissions = values?.field_options.permissions || []
  const canEdit = specialisationPermissions.includes(PermissionTypes.ChangeSpecialisation)

  const changeOrder = useCallback((d: HiringProcessInterface[]) => {
    values.hiring_process_rounds = d

    // if the process has been changed, but not saved yet - disable the filtering
    setEnableFiltering(false)
  }, [])

  const specialisationsEnabled = useIsSpecialisationsEnabled()

  useEffect(() => {
    fetchHiringStages()
    history.replace({ state: { ...location.state, stageId: undefined } })
  }, [])

  const table = useTable(specialisationHiringStagesRequests(values.id), initialFilter)
  const filtersApplied = useMemo(
    () => table.filterBy.some(filter => !filter.nonResettable),
    [table.filterBy],
  )

  const ordering = useOrdering(
    table.data,
    orderedData => {
      table.setData(orderedData)
      changeOrder(orderedData)
    },
    table.count,
    table.refresh,
  )

  const fetchHiringStages = async () => {
    const roleResult = values?.role?.id
      ? await rolesRequests.getItem(values?.role?.id)
      : undefined
    const result = await getSelectors(selectorKeys.hiring_stages)
    if (result?.data?.options) {
      setHiringStages(result?.data?.options)
    }

    if (roleResult?.data) {
      setRole(roleResult.data)
    }
  }

  const handleDelete = async (stage: HiringProcessInterface) => {
    try {
      await formHiringProcessStagesSpecialisationRequest.delete!(stage, {
        id: String(stage.id),
      })
      table.refresh()
    } catch (e) {
      handleError(e)
    }
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      setSelectedStageId(data.id)
    }
  }

  const row = useMemo(
    () => hiringProcessTableRow(handleDelete, changeOrder, filtersApplied),
    [hiringProcessTableRow, changeOrder, filtersApplied],
  )

  return (
    <>
      {canEdit && (
        <RequiresFeatureFlagWrapper flags={[FeatureFlags.JobPostingFlowSettings]}>
          {isLoadingHiringProcessSettings && (
            <Box mb="s-16">
              <ItemSkeleton />
            </Box>
          )}
          {hiringProcessSettings?.enable_global_hiring_process_lock ? (
            <Box mb="s-16">
              <Banner>
                <Banner.Avatar>
                  <Avatar useIcon="InfoOutline" />
                </Banner.Avatar>
                <Banner.Content>
                  <Banner.Title>
                    <Text color={Token.color.greyTone50} variant="body2">
                      This hiring process will be enforced in all job postings for this{' '}
                      {specialisationsEnabled ? 'specialisation' : 'role'}
                    </Text>
                  </Banner.Title>
                </Banner.Content>
              </Banner>
            </Box>
          ) : hiringProcessSettings?.enable_specialisation_hiring_process_lock ? (
            <Box mb="s-16">
              <LapeNewSwitch
                itemTypeProps={{
                  avatar: <Avatar useIcon="LockClosed" />,
                  title: 'Lock hiring process?',
                  description: specialisationsEnabled
                    ? 'Require every job posting for this specialisation to use this hiring process. Additional stages or questions can still be added'
                    : 'Require every job posting for this role to use this hiring process. Additional stages or questions can still be added',
                }}
                name="is_hiring_process_locked"
                disabled={!canEdit}
              />
            </Box>
          ) : null}
        </RequiresFeatureFlagWrapper>
      )}
      <Table.Widget>
        <HiringStagePreviewForm
          id={selectedStageId}
          type="specialisation"
          onClose={() => setSelectedStageId(undefined)}
          onSuccessDelete={() => {
            table.refresh()
          }}
        />
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <Dropdown {...roundDropdown.getTargetProps()} autoClose>
              <Dropdown.Group>
                <Dropdown.Item
                  color={Token.color.accent}
                  // @ts-expect-error object works fine here, but UI kit expects string
                  to={getLocationDescriptor(
                    pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                    {
                      specialisation: { id: values.id, name: values.name },
                    },
                  )}
                  use={Link}
                >
                  <Text>+ Add new</Text>
                </Dropdown.Item>
              </Dropdown.Group>
              {!!role?.hiring_process_rounds?.length && (
                <Dropdown.Group>
                  <Text ml="s-16" color="grey-tone-50" fontSize="small">
                    {values.role?.name ? `${values.role?.name} stages` : 'Role stages'}
                  </Text>
                  {role.hiring_process_rounds.map(stage => {
                    return (
                      <Dropdown.Item
                        key={stage.id}
                        // @ts-expect-error object works fine here, but UI kit expects string
                        to={getLocationDescriptor(
                          pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                          {
                            specialisation: { id: values.id, name: values.name },
                            roleHiringStageId: stage.id,
                          },
                        )}
                        use={Link}
                      >
                        {stage.title}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Group>
              )}

              <Dropdown.Group>
                {!!role?.hiring_process_rounds?.length && (
                  <Text ml="s-16" color="grey-tone-50" fontSize="small">
                    Other stages
                  </Text>
                )}
                {hiringStages.map(stage => {
                  return (
                    <Dropdown.Item
                      key={stage.id}
                      // @ts-expect-error object works fine here, but UI kit expects string
                      to={getLocationDescriptor(
                        pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                        {
                          specialisation: { id: values.id, name: values.name },
                          companyHiringStageId: stage.id,
                        },
                      )}
                      use={Link}
                    >
                      {stage.name}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Group>
            </Dropdown>
            <MoreBar.Action {...roundDropdown.getAnchorProps()} useIcon="ChevronDown">
              Add a round
            </MoreBar.Action>
            <HideIfCommercial>
              {values.hiring_playbook_url && (
                <MoreBar.Action
                  use="a"
                  target="_blank"
                  href={values.hiring_playbook_url}
                  useIcon="16/LinkExternal"
                >
                  Hiring Playbook
                </MoreBar.Action>
              )}
            </HideIfCommercial>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<HiringProcessInterface>
            name={TableNames.SpecialisationHiringStages}
            dataType="Hiring stage"
            useWindowScroll
            mainColumnIndex={1}
            row={row}
            cellErrors={errors?.hiring_process_rounds}
            orderingMode={!filtersApplied}
            disabledFiltering={!enableFiltering}
            hideCount
            {...ordering}
            onRowClick={handleRowEdit}
            noDataMessage={<EmptyTableRaw title="Hiring stages will appear here." />}
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
