import React from 'react'
import { FormattedMessage } from 'react-intl'
import { chain, HStack, Spacer, Tag, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { MeetingNotesFeedbackBaseInterface } from '@src/interfaces/meetings'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import { formatDateTime } from '@src/utils/format'
import { toGradesMap } from '@src/utils/grades'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import { SummaryFormatted } from '@src/features/AiInsights/Performance/components/Summary/Summary'
import {
  getContentItemsByRole,
  getPrivateContentItemsByRole,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/utils/getContentItemsByRole'

interface Props {
  feedback: MeetingNotesFeedbackBaseInterface
  grades: PerformanceGradeOption[]
}

const MAX_ACTION_ITEMS_COUNT = 4

export const FeedbackItem = ({ feedback, grades }: Props) => {
  const { manager, meeting } = feedback
  const performanceRating = getPrivateContentItemsByRole(feedback, 'performance_rating')
  const talkingPoints = getContentItemsByRole(feedback, 'talking_points')
  const actionItems = getContentItemsByRole(feedback, 'action_items')

  const gradeProps = getInverseColorsAndGrade(
    performanceRating?.content.value?.value,
    toGradesMap(grades),
  )

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <HStack align="center">
          {chain(
            <UserWithAvatar {...manager} />,
            <Text color={Token.color.greyTone50}>{formatDateTime(meeting.end)}</Text>,
          )}
          <Spacer />
          {gradeProps && (
            <Tag variant="outlined" color={gradeProps.color}>
              {gradeProps.grade}
            </Tag>
          )}
        </HStack>
        {talkingPoints?.content.content && (
          <VStack space="s-8">
            <FormattedMessage
              id="oneToOne.feedback.talkingPoints.title"
              defaultMessage="Talking points:"
            />
            <SummaryFormatted variant="list" summary={talkingPoints.content.content} />
          </VStack>
        )}
        {!!actionItems?.content.content?.length && (
          <VStack space="s-8">
            <FormattedMessage
              id="oneToOne.feedback.actionItems.title"
              defaultMessage="Action items:"
            />
            <SummaryFormatted
              variant="list"
              summary={actionItems.content.content
                .slice(0, MAX_ACTION_ITEMS_COUNT)
                .map(({ value }) => value)
                .join('\n')}
            />
          </VStack>
        )}
      </VStack>
    </Widget>
  )
}
