import React from 'react'
import { Side, VStack } from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { getActionPlanFormRequests } from '@src/api/engagement'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { formatDate } from '@src/utils/format'
import { EngagementInsightActionInterface } from '@src/interfaces/engagement'

const FormContent = ({ onAfterSubmit }: { onAfterSubmit: VoidFunction }) => {
  const { values } = useLapeContext<EngagementInsightActionInterface>()

  values.related_goals = [] // to bypass BE validation

  return (
    <VStack gap="s-16">
      <LapeNewInput required name="title" label="Title" />
      <LapeNewTextArea required name="description" label="Description" />
      <LapeRadioSelectInput
        required
        name="owner"
        label="Owner"
        selector={selectorKeys.employee}
      />
      <LapeDatePickerInput
        required
        name="due_date"
        label="Due date"
        onAfterChange={dueDate => {
          if (dueDate) {
            values.due_date = formatDate(dueDate, 'yyyy-MM-dd')
          }
        }}
      />

      <Side.Actions>
        <NewSaveButtonWithPopup
          onAfterSubmit={onAfterSubmit}
          successText="Action was successfully created"
        >
          Create action
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </VStack>
  )
}

interface Props {
  roundId: number
  onClose: VoidFunction
}
export const CreateActionSideBar = ({ roundId, onClose }: Props) => {
  return (
    <SideBar isOpen title="Create action" onClose={onClose}>
      <Form
        api={getActionPlanFormRequests(roundId)}
        forceParams={{ id: undefined }}
        disableLocalStorageCaching
      >
        <FormContent onAfterSubmit={onClose} />
      </Form>
    </SideBar>
  )
}
