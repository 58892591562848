import React from 'react'
import { IconName, List, Token } from '@revolut/ui-kit'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import {
  FilterLabel,
  getFilterKeyIcon,
  getFilterLabel,
  unknownFilterIcon,
} from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Filters/constants'
import { insightsGroupModel } from '@src/features/AiInsights/Performance/models/insightsGroupModel'

interface ListItemProps {
  id: string
  label: React.ReactNode
  iconName: IconName
}

interface Props {
  items: ListItemProps[]
}

export const FiltersDetailsList = ({ items }: Props) => (
  <List variant="compact">
    {items.map(({ id, label, iconName }) => (
      <List.Item key={id} useIcon={iconName} color={Token.color.greyTone50}>
        {label}
      </List.Item>
    ))}
  </List>
)

export const toFilterDetailsListItemsProps = (
  insightsGroup: PerformanceInsightsGroupInterface,
): ListItemProps[] =>
  Object.entries(insightsGroup.filters).reduce<ListItemProps[]>(
    (nonEmptyFilters, [key, value]) => {
      if (value === undefined || value === null) {
        return nonEmptyFilters
      }

      let filterCount = 0

      if (typeof value === 'string') {
        filterCount = value.split(',').filter(Boolean).length
      } else if (typeof value === 'boolean') {
        filterCount = 1
      } else if (Array.isArray(value)) {
        filterCount = value.length
      }

      if (!insightsGroupModel.isEligibilityFilterKey(key)) {
        return [
          ...nonEmptyFilters,
          {
            id: key,
            label: <FilterLabel count={filterCount} />,
            iconName: unknownFilterIcon,
          },
        ]
      }

      return [
        ...nonEmptyFilters,
        {
          id: key,
          label: getFilterLabel(key, filterCount),
          iconName: getFilterKeyIcon(key),
        },
      ]
    },
    [],
  )
