import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { candidateSettings, useGlobalSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { getSettingsLinkConfig } from '../common/config'
import { candidatesSettingsLinkConfig } from './config'

const ListPage = () => {
  const { settings } = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const config = getSettingsLinkConfig({
    config: candidatesSettingsLinkConfig,
    globalSettings: settings,
    permissions,
    featureFlags,
    type: 'settings',
  })

  return (
    <PageBody mb="s-64">
      <Group mb="s-24">
        {config.candidateProfile?.component}
        {config.candidateDataRetentionSettings?.component}
        {config.candidateOfferSettings?.component}
        {config.candidateRecruitmentGroups?.component}
        {config.candidateScreenCallScorecards?.component}
      </Group>
    </PageBody>
  )
}

const routes = [
  {
    title: 'Candidates settings',
    path: ROUTES.SETTINGS.CANDIDATES.LIST,
    url: ROUTES.SETTINGS.CANDIDATES.LIST,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: ListPage,
  },
]

export const List = () => {
  return <SettingsForm routes={routes} api={candidateSettings} />
}
