import React from 'react'
import { ActionButton, Subheader } from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { SubheaderWithDescription } from '@src/components/SubheaderWithDescription'
import { StructureLevels } from './components/StructureLevels'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const specialisationConfig = {
  id: 'specialisations',
  url: ROUTES.SETTINGS.STRUCTURES.ROLES.SPECIALISATIONS,
  icon: 'RepairTool' as const,
  name: 'Specialisations',
  description: 'Focused subsets within a role used to define skills and competencies.',
  levels: [],
}

export const StructuresRolesGeneral = () => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  return (
    <PageWrapper>
      <PageHeader
        title="Roles structure"
        subtitle="Define areas of technical competencies to provide a clear view of expectations to use in hiring and performance management."
        backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
      />

      <PageBody>
        <ActionButton
          to={ROUTES.SETTINGS.STRUCTURES.ROLES.GENERAL}
          use={InternalLink}
          useIcon="Gear"
          mb="s-16"
        >
          Settings
        </ActionButton>

        <SubheaderWithDescription description="Change function and role settings">
          <Subheader.Title>Manage structure</Subheader.Title>
        </SubheaderWithDescription>

        <StructureLevels
          levels={[
            {
              id: 'funtions',
              url: ROUTES.SETTINGS.STRUCTURES.ROLES.FUNCTIONS,
              icon: 'RepairTool',
              name: 'Functions',
              description:
                'Larger organizational units responsible for a broad area of expertise. Encompass multiple roles.',
              levels: specialisationsEnabled
                ? [
                    {
                      id: 'roles',
                      url: ROUTES.SETTINGS.STRUCTURES.ROLES.ROLES,
                      icon: 'RepairTool',
                      name: 'Roles',
                      description:
                        'Groups of employees with similar skill sets and an embedded competency framework to assess performance.',
                      levels: [specialisationConfig],
                    },
                  ]
                : [{ ...specialisationConfig, name: 'Roles' }],
            },
          ]}
        />
      </PageBody>
    </PageWrapper>
  )
}
