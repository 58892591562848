import React from 'react'
import { AxiosError } from 'axios'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import styled from 'styled-components'
import { Token } from '@revolut/ui-kit'

import {
  ERROR_DEFAULT_DURATION,
  ERRORS,
  INVALID_IAP_CREDENTIALS,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { captureError } from '@src/features/Errors/captureError'
import { ActionErrorTitle } from '@src/features/Errors/components/ActionErrorTitle'
import { parseError } from '@src/features/Errors/parseError'
import notificationState from './state'
import { NotificationInterface, NotificationTypes } from './types'

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`

const Error = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`

const A = styled.a`
  font-size: 14px;
  align-self: center;
  color: ${Token.color.foreground};
  text-decoration: ${Token.color.foreground} underline;
`

export const pushNotification = (data: Omit<NotificationInterface, 'id'>) => {
  const notification = {
    ...data,
    id: uniqueId('notification_'),
    open: true,
  }

  // do not push error notifications with the same message/value if one already exists
  if (
    notification.type === 'error' &&
    notificationState.notifications.some(
      ntf =>
        (ntf?.message && ntf.message === notification.message) ||
        ntf?.value === notification.value,
    )
  ) {
    return
  }

  // Little hack to not show all other errors when session expired
  if (
    !notificationState.notifications.some(ntf => ntf?.value === ERRORS.SESSION_EXPIRED)
  ) {
    notificationState.notifications.push(notification)
  }
}

export const closeNotification = (id: string) => {
  const notificationIndex = notificationState.notifications.findIndex(
    ntf => ntf?.id === id,
  )
  if (notificationIndex !== -1) {
    notificationState.notifications.splice(notificationIndex, 1)
  }
}

/** @deprecated */
export const getErrorMessageFromError = (error?: AxiosError) => {
  if (!error) {
    return ''
  }

  const validationErrors: unknown =
    get(error, 'response.data.message') ||
    get(error, 'response.data.error') ||
    get(error, 'response.data.detail') ||
    get(error, 'response.data.name') ||
    get(error, 'response.data.non_field_errors') ||
    get(error, 'response.data')

  if (typeof validationErrors === 'string') {
    return validationErrors
  }

  if (Array.isArray(validationErrors)) {
    return validationErrors.filter(e => typeof e === 'string').join(' ')
  }

  return ''
}

export const getMessageFromApiError = (error?: AxiosError | null) => {
  if (!error) {
    return null
  }

  const parsedError: unknown =
    get(error, 'response.data.message') ||
    get(error, 'response.data.error') ||
    get(error, 'response.data.detail') ||
    get(error, 'response.data.non_field_errors') ||
    get(error, 'response.data.non_field_error') ||
    get(error, 'response.data')

  if (typeof parsedError === 'string') {
    return parsedError
  }

  if (Array.isArray(parsedError) && typeof parsedError[0] === 'string') {
    return parsedError[0]
  }

  return null
}

const checkIfString = (err: unknown) => typeof err === 'string'

const checkIfArrayOfStrings = (err: unknown) =>
  Array.isArray(err) && err.every(checkIfString)

const checkIfParsableObject = (err: unknown) => {
  if (typeof err === 'object' && !Array.isArray(err) && err !== null) {
    return Object.values(err).every(
      errValue => checkIfString(errValue) || checkIfArrayOfStrings(errValue),
    )
  }
  return false
}

export const getApiValidationErrorMessages = (error?: AxiosError | null) => {
  if (!error || error.response?.status !== 400) {
    return null
  }
  const errorData: unknown = get(error, 'response.data')
  const isParsableObject = checkIfParsableObject(errorData)

  if (isParsableObject) {
    return errorData
  }
  return null
}

/** @deprecated use `getMessageFromApiError` */
export const getStringMessageFromError = (
  error?: AxiosError,
  defaultMessage = ERRORS.UNKNOWN_REFRESH,
) => {
  if (!error) {
    return defaultMessage
  }

  const fromResponse = getErrorMessageFromError(error)

  if (fromResponse) {
    return fromResponse
  }

  return error.message || defaultMessage
}

/** @deprecated */
export const pushError = (data: { message?: string; error?: AxiosError<any> }) => {
  const { message, error } = data

  /** To handle errors for requests with responseType === 'arraybuffer' */
  if (error?.response?.data instanceof ArrayBuffer) {
    try {
      error.response.data = JSON.parse(
        String.fromCharCode.apply(null, [...new Uint8Array(error.response.data)]),
      )
    } catch {
      /** Do nothing */
    }
  }

  const notification: Omit<NotificationInterface, 'id'> & { disabled: boolean } = {
    type: NotificationTypes.error,
    value: message || getStringMessageFromError(error),
    duration: ERROR_DEFAULT_DURATION,
    /** This notification will show a popup, and we don't want that to happen for GET requests */
    disabled:
      !error?.response?.config?.method ||
      error.response.config.method.toLowerCase() === 'get',
  }

  switch (get(error, 'response.status')) {
    case 403:
      if (
        get(error, 'response.data.detail') === 'User not authenticated' ||
        get(error, 'response.data.detail') === 'Session expired' ||
        get(error, 'response.data.detail') === 'Invalid token'
      ) {
        notification.value = ERRORS.SESSION_EXPIRED
        /** We are showing logout in SessionExpiredPopup */
        notification.disabled = true
      } else {
        // these kind of errors fail on the server level and mean that it failed the security check, it's not json
        const securityIssue = get(error, 'response.headers.content-type') === 'text/plain'
        notification.value = securityIssue
          ? ERRORS.INVALID_INPUT
          : error?.response?.data?.detail || ERRORS.NOT_ENOUGH_PERMISSIONS
      }
      break
    case 400:
      notification.duration = 999999999
      notification.value = (
        <ActionErrorTitle
          error={parseError(error)}
          fallbackTitle="Could not handle request data"
        />
      )
      notification.message = getMessageFromApiError(error)

      if (notification.message == null && error?.response?.data) {
        notification.technicalMessage = {
          status: error?.response?.status,
          message: error?.message,
          data: error?.response?.data,
          url: error?.config?.url,
          method: error?.config?.method?.toUpperCase(),
          requestId: error?.response?.headers['request-id'],
        }
      }

      if (error?.response?.data?.url && error.response.data?.url_title) {
        notification.value = (
          <div>
            <Title>{ERRORS.BAD_REQUEST}:</Title>
            <Error>
              <A href={error.response.data.url} target="_blank">
                {error.response.data.url_title}
              </A>
            </Error>
          </div>
        )
      }
      break
    case 502:
      notification.value = ERRORS.UNKNOWN_REFRESH
      break
    case 503:
      notification.value = ERRORS.TOO_MANY_REQUESTS
      break
    case 401:
      if (get(error, 'response.data')?.startsWith(INVALID_IAP_CREDENTIALS)) {
        notification.disabled = true
      } else {
        notification.value = ERRORS.SESSION_EXPIRED
        /** We are showing logout in SessionExpiredPopup */
        notification.disabled = true
      }
      break
    default:
      break
  }
  if (!notification.disabled) {
    pushNotification(notification)

    captureError(error, { tags: { component: 'pushError' }, severity: 'fatal' })
  }
}

export const successNotification = (message: string, backUrl?: string) => {
  pushNotification({
    value: message,
    duration: SUCCESS_DEFAULT_DURATION,
    type: NotificationTypes.success,
    backUrl,
  })
}
