import { Token } from '@revolut/ui-kit'
import { BaseGrowthPlanInterface, GrowthPlanDecision } from '@src/interfaces/growthPlans'

export const getGrowthPlanDecisionColor = <T extends BaseGrowthPlanInterface>(
  growthPlan: T,
) => {
  switch (growthPlan.decision) {
    case GrowthPlanDecision.Completed:
      return Token.color.success
    case GrowthPlanDecision.Missed:
      return Token.color.danger
    default:
      return Token.color.foreground
  }
}

export const growthPlanDecisionToLabelMap: Record<GrowthPlanDecision, string> = {
  [GrowthPlanDecision.Ongoing]: 'Ongoing',
  [GrowthPlanDecision.Missed]: 'Missed',
  [GrowthPlanDecision.Completed]: 'Completed',
}
