import React from 'react'
import {
  Avatar,
  Box,
  Color,
  Ellipsis,
  Flex,
  Grid,
  HStack,
  Spacer,
  Text,
  Token,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { PerformanceReviewAnalyticsInterface } from '@src/interfaces/performance'
import { formatPercentage } from '@src/utils/format'
import { groupTypeToIconNameMap } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/constants'
import { Tooltip } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/Tooltip'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import {
  ACTIVE_ROW_OPACITY,
  BAR_ITEM_HEIGHT,
  DEFAULT_ROW_OPACITY,
  ROW_LABEL_MIN_WIDTH,
  ROW_LABEL_WIDTH,
} from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/Row'
import { getColorByIndex } from '@src/pages/Forms/QueryForm/components/Charts/helpers'

interface Props {
  data: PerformanceReviewAnalyticsInterface
  dataKeys: PerformanceGradeOption[]
  colors?: Color[]
  labelImage?: string
}

export const Row = ({ data, dataKeys, colors, labelImage }: Props) => {
  const tooltip = useTooltip()

  const { group, grades: groupGrades } = data
  const gridColumns = groupGrades.reduce<string>(
    (columns, { percent }) =>
      percent > 0 ? `${columns} ${Number(percent.toFixed(2)) * 100}fr` : columns,
    '',
  )
  const visibleGrades = groupGrades.filter(({ percent }) => percent > 0)

  return (
    <Box {...tooltip.getAnchorProps<HTMLDivElement>()}>
      <Flex
        key={group.id}
        justifyContent="center"
        alignItems="center"
        gap="s-16"
        opacity={tooltip.state ? ACTIVE_ROW_OPACITY : DEFAULT_ROW_OPACITY}
      >
        <HStack
          width={ROW_LABEL_WIDTH}
          minWidth={ROW_LABEL_MIN_WIDTH}
          align="center"
          space="s-12"
        >
          <Avatar
            size={32}
            image={labelImage}
            useIcon={labelImage ? undefined : groupTypeToIconNameMap[group.type]}
          />
          <Ellipsis>{group.name}</Ellipsis>
        </HStack>
        <Spacer />
        <Grid columns={gridColumns} columnGap="s-16" width="100%">
          {visibleGrades.map(({ id, percent }) => {
            const dataKey = dataKeys.find(({ id: dataKeyId }) => dataKeyId === id)
            const color = dataKey
              ? getColorByIndex(dataKeys.indexOf(dataKey), colors)
              : undefined

            return (
              <VStack
                key={id}
                height={BAR_ITEM_HEIGHT}
                p="s-4"
                backgroundColor={color}
                radius={Token.radius.r6}
              >
                <Spacer />
                <Text textAlign="center" fontSize={12} use="p">
                  {percent > 0.01 ? formatPercentage(percent) : '<1'}
                </Text>
                <Spacer />
              </VStack>
            )
          })}
        </Grid>
      </Flex>
      <Tooltip
        data={data}
        dataKeys={dataKeys}
        colors={colors}
        {...tooltip.getTargetProps<HTMLDivElement>()}
      />
    </Box>
  )
}
