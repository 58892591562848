import React from 'react'
import { Flex } from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { QuickFilter } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Filter'

interface Props {
  activeFilters: QuickFilter[] | undefined
}

export const RatingReviewerColumn = ({ activeFilters }: Props) => {
  return (
    <Flex gap="s-4">
      {activeFilters
        ?.filter(filter => filter.avatar || filter.full_name)
        ?.map((filter, index) => (
          <UserWithAvatar
            size={24}
            key={index}
            uuid={filter.full_name}
            id={filter.key}
            full_name={filter.full_name}
            avatar={filter.avatar}
            compact
            asText
          />
        ))}
    </Flex>
  )
}
