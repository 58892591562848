import React, { useState } from 'react'
import { Flex, ActionButtonSkeleton, ActionButton } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { NominateForPromotion } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/NominateForPromotion'
import { ViewNominationButton } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/ViewNominationButton'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import { GoalContentType } from '@src/interfaces/goals'
import { useGetSelectors } from '@src/api/selectors'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { captureException } from '@sentry/react'
import { createDraftGoal } from '@src/api/goals'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { canAddTeamKpi } from '@src/store/auth/selectors'

interface Props {
  category: string | undefined
  employee: EmployeeInterface
  selectedPeriod: PerformanceSelector
}

export enum SecondaryActionButtonVariants {
  None = 'None',
  Goals = 'Goals',
  MeetingNotes = 'MeetingNotes',
  Promotion = 'Promotion',
}

export const useSecondaryActionButtonVariant = ({
  category,
  employee,
  selectedPeriod,
}: Props): SecondaryActionButtonVariants => {
  const canAddKpi = useSelector(canAddTeamKpi)
  const canAddGoals = !!employee.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )
  const isKPICategoryAddGoal =
    category === 'kpi' ? canAddGoals : category === 'kpi' && canAddKpi
  const isGoalCategoryAddGoal = category === 'goal' && canAddGoals

  if (
    selectedPeriod?.status === ReviewCycleStatus.ongoing &&
    (isKPICategoryAddGoal || isGoalCategoryAddGoal)
  ) {
    return SecondaryActionButtonVariants.Goals
  }

  if (category === 'meeting') {
    return SecondaryActionButtonVariants.MeetingNotes
  }

  if (category === 'promotion') {
    return SecondaryActionButtonVariants.Promotion
  }

  return SecondaryActionButtonVariants.None
}

interface ButtonProps {
  variant: SecondaryActionButtonVariants
  employee: EmployeeInterface
  selectedPeriod: PerformanceSelector
}

export const PerformanceStepSecondaryActionButton = ({
  variant,
  employee,
  selectedPeriod,
}: ButtonProps) => {
  if (variant === SecondaryActionButtonVariants.None) {
    return null
  }

  if (variant === SecondaryActionButtonVariants.Goals) {
    return <AddGoalsButton employee={employee} selectedPeriod={selectedPeriod} />
  }

  if (variant === SecondaryActionButtonVariants.Promotion) {
    return (
      <>
        <NominateForPromotion
          text="Nominate"
          icon="Plus"
          data={employee}
          cycleId={selectedPeriod.id}
        />
        <ViewNominationButton data={employee} cycleId={selectedPeriod.id} />
      </>
    )
  }

  return null
}

const AddGoalsButton = ({ employee, selectedPeriod }: Omit<ButtonProps, 'variant'>) => {
  const [isCreateGoalPending, setIsCreateGoalPending] = useState(false)
  const { data: contentTypes, isLoading: contentTypesLoading } =
    useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)
  const { navigateWithEntity } = useOrgEntity()
  const showErrorPopup = useErrorPopup()

  const onAddGoalsClick = async () => {
    try {
      setIsCreateGoalPending(true)
      const response = await createDraftGoal({
        owner: { id: employee.id },
        content_type: contentTypes?.find(({ model }) => model === 'employees'),
        object_id: employee.id,
      })
      navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
        reviewCycleId: selectedPeriod.id,
        isNew: true,
      })
    } catch (err) {
      captureException(err)
      showErrorPopup.show({ error: err, fallbackTitle: 'Failed to create goal' })
    } finally {
      setIsCreateGoalPending(false)
    }
  }

  return (
    <Flex mr="s-6">
      {contentTypesLoading ? (
        <ActionButtonSkeleton />
      ) : (
        <ActionButton
          useIcon="Plus"
          onClick={onAddGoalsClick}
          pending={isCreateGoalPending}
        >
          Add goals
        </ActionButton>
      )}
    </Flex>
  )
}
