import React from 'react'
import { Flex, Image, StatusWidget, Text, Token } from '@revolut/ui-kit'
import { AxiosError } from 'axios'
import { ERRORS } from '@src/constants/notifications'

type Props = {
  error: AxiosError
  onReset?: () => void
}

export const TableError = ({ error, onReset }: Props) => {
  const errorCode = error.response?.status
  let errorMessage = ERRORS.UNKNOWN_REFRESH

  switch (errorCode) {
    case 403:
      errorMessage = 'You do not have access to this table.'
      break

    case 404:
      errorMessage = 'This table does not exist.'
      break

    // if the request is too long it's being cut by nginx with Network Error with no status code at all
    case undefined:
    case 413: // 413 Content Too Large
    case 414: // 414 Request-URI Too Large
      errorMessage =
        'The filters you have used are incorrect or too long. Please try changing them.'
  }

  return (
    <Flex gap="s-16" alignItems="center" flexDirection="column" py="s-40">
      <Image
        size={104}
        image={{
          default: `https://assets.revolut.com/assets/3d-images-v2/3D018.png`,
          '2x': `https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png`,
          '3x': `https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png`,
        }}
      />
      <Text variant="emphasis1">Sorry, the table failed to load</Text>
      <Text variant="body2" color={Token.color.greyTone50}>
        {errorMessage}
      </Text>
      {onReset && (
        <StatusWidget.Action onClick={onReset}>Reset filters</StatusWidget.Action>
      )}
    </Flex>
  )
}
