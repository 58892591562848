import React from 'react'
import { Dropdown, FilterButton, Icon, Text, useDropdown } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { SkillRatingsDistributionGroupTypes } from '@src/features/ReviewCycle/Analytics/api/skills'

export interface ChartTypeOption extends BaseOption<SkillRatingsDistributionGroupTypes> {}

interface Props {
  value: ChartTypeOption
  onSelect: (value: ChartTypeOption) => void
}

export const ChartTypeSelector = ({ value, onSelect }: Props) => {
  const dropdown = useDropdown()

  return (
    <>
      <FilterButton
        useIcon="BarChart"
        useEndIcon={
          <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} size={24} />
        }
        {...dropdown.getAnchorProps()}
      >
        <Text>{value.name}</Text>
      </FilterButton>
      <Dropdown
        autoClose
        fitInAnchor
        {...dropdown.getTargetProps()}
        data-testid="performance_analytics_skills.chart.type.dropdown"
      >
        {chartTypeOptions.map(option => (
          <Dropdown.Item key={option.id} use="button" onClick={() => onSelect(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

const groupTypeToLabelMap: Record<SkillRatingsDistributionGroupTypes, string> = {
  [SkillRatingsDistributionGroupTypes.Distribution]: 'Distribution',
  [SkillRatingsDistributionGroupTypes.Benchmark]: 'Benchmark',
}

const distributionTypeOption: ChartTypeOption = {
  id: SkillRatingsDistributionGroupTypes.Distribution,
  name: groupTypeToLabelMap[SkillRatingsDistributionGroupTypes.Distribution],
}

const benchmarkTypeOption: ChartTypeOption = {
  id: SkillRatingsDistributionGroupTypes.Benchmark,
  name: groupTypeToLabelMap[SkillRatingsDistributionGroupTypes.Benchmark],
}

export const chartTypeOptions: ChartTypeOption[] = [
  distributionTypeOption,
  benchmarkTypeOption,
]

const groupTypeToOptionMap: Record<SkillRatingsDistributionGroupTypes, ChartTypeOption> =
  {
    [SkillRatingsDistributionGroupTypes.Distribution]: distributionTypeOption,
    [SkillRatingsDistributionGroupTypes.Benchmark]: benchmarkTypeOption,
  }

export const getChartTypeOption = (
  groupType: SkillRatingsDistributionGroupTypes,
): ChartTypeOption => groupTypeToOptionMap[groupType]
