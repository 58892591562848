import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import isFinite from 'lodash/isFinite'
import { selectorKeys } from '@src/constants/api'
import {
  KPIRuleInterface,
  TemplateApplicationStrategies,
} from '@src/interfaces/KpiTemplates'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { TeamInterface } from '@src/interfaces/teams'
import { teamsRequests } from '@src/api/teams'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { teamGenericNameColumn, teamNameColumn } from '@src/constants/columns/team'
import {
  departmentGenericNameColumn,
  departmentHeadcountColumn,
  departmentNameColumn,
  departmentPerformanceColumn,
  departmentRequisitionsColumn,
  departmentRoadmapColumn,
} from '@src/constants/columns/department'
import {
  employeeNameColumn,
  employeeNameIdStatusColumn,
  employeeStartedAtColumn,
  lineManagerColumn,
  ownerNameColumn,
  teamOwnerColumn,
} from '@src/constants/columns/employee'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { departmentRequests } from '@src/api/department'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequests } from '@src/api/employees'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { PageWrapper } from '@components/Page/Page'
import Form from '@src/features/Form/Form'
import {
  kpiRuleRequestsNew,
  useCheckKpiRuleAffectedEntitiesByFilters,
} from '@src/api/kpiRules'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { ActionButton, Bar, Box, InputGroup, MoreBar, TextArea } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { Instructions } from '@src/pages/Forms/KpiTemplates/KpiRule/Instructions'
import NewWarningMessage, {
  NewWarningTypes,
} from '@components/NewWarningMessage/NewWarningMessage'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import SettingsButtons, { CopyButton, DeleteButton } from '@src/features/SettingsButtons'
import { KPITypes, TableNames } from '@src/constants/table'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { RefreshButton } from './RefreshButton'
import { kpiRuleTemplateSelector } from '@src/utils/kpi'
import { AffectedEntitiesConfirmation } from '@src/pages/Forms/KpiTemplates/KpiRule/components/AffectedEntitiesConfirmation'

const TeamRows: RowInterface<TeamInterface> = {
  cells: [
    {
      ...teamGenericNameColumn,
      width: 300,
    },
    {
      ...departmentNameColumn,
      width: 200,
    },
    {
      ...teamOwnerColumn,
      width: 200,
    },
    {
      ...departmentHeadcountColumn,
      width: 130,
    },
    {
      ...departmentRequisitionsColumn,
      width: 100,
    },
    {
      ...departmentPerformanceColumn,
      width: 150,
    },
    {
      ...departmentRoadmapColumn,
      width: 200,
    },
  ],
}

const DepartmentRows: RowInterface<DepartmentInterface> = {
  cells: [
    {
      ...departmentGenericNameColumn,
      width: 300,
    },
    {
      ...ownerNameColumn,
      width: 200,
    },
    {
      ...departmentHeadcountColumn,
      width: 130,
    },
    {
      ...departmentRequisitionsColumn,
      width: 100,
    },
    {
      ...departmentPerformanceColumn,
      width: 150,
    },
    {
      ...departmentRoadmapColumn,
      width: 200,
    },
  ],
}

const EmployeeRows = (
  specialisationsEnabled: boolean,
): RowInterface<EmployeeInterface> => ({
  cells: [
    {
      ...employeeNameColumn,
      width: 300,
    },
    {
      ...teamNameColumn,
      width: 200,
    },
    {
      ...roleNameColumn(specialisationsEnabled),
      width: 130,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...lineManagerColumn,
      width: 150,
    },
    {
      ...locationNameColumn,
      width: 200,
    },
    {
      ...employeeNameIdStatusColumn,
      width: 160,
    },
    {
      ...employeeStartedAtColumn,
      width: 160,
    },
  ],
})

const getEntityTypeText = (template: KPIRuleInterface['template'] | null) => {
  if (!template) {
    return '...'
  }
  if (template.kpi_type?.id === KPITypes.employee_kpi) {
    return `employee${template.has_probation_target ? ' in probation' : ''}`
  }
  return template.kpi_type?.id?.toLowerCase().split('_').at(0) || '...'
}

const General = connect(({ variant }: { variant: 'KPI' | 'goal' }) => {
  const user = useSelector(selectUser)
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const { values, initialValues } = useLapeContext<KPIRuleInterface>()
  const isEditMode = initialValues.id !== undefined

  const [showInstructions, setShowInstructions] = useState<boolean>(!values.id)
  const [showInputIds, setShowInputIds] = useState(false)

  const { mutateAsync: checkAffectedEntities, isLoading: isCheckingAffectedEntities } =
    useCheckKpiRuleAffectedEntitiesByFilters(values.id)
  const [affectedEntitiesCount, setAffectedEntitiesCount] = useState<number>()

  const initialFilters: FilterByInterface[] = initialValues.filters
    ? Object.keys(initialValues.filters).map(key => ({
        columnName: key,
        filters: initialValues.filters![key].map((id: string | number) => ({
          id,
          name: `${id}`,
        })),
      }))
    : []

  const tableOptions = {
    disableQuery: true,
  }

  let table: useTableReturnType<any, any> | null = null
  let row: RowInterface<any> | null = null
  const team = useTable<TeamInterface>(teamsRequests, initialFilters, [], tableOptions)
  const department = useTable<DepartmentInterface>(
    departmentRequests,
    initialFilters,
    [],
    tableOptions,
  )
  const employees = useTable<EmployeeInterface>(
    { ...employeesRequests, getStats: undefined },
    initialFilters,
    [],
    tableOptions,
  )

  useEffect(() => {
    if (!isEditMode && !initialValues.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  if (values.template) {
    switch (values.template?.kpi_type?.id) {
      case 'team_kpi':
        table = team
        row = TeamRows
        break
      case 'department_kpi':
        table = department
        row = DepartmentRows
        break
      case 'employee_kpi':
        table = employees
        row = EmployeeRows(specialisationsEnabled)
    }
  }

  useEffect(() => {
    if (!values.template || !table || values.template.id === initialValues.template?.id) {
      return
    }

    const isEmployee = values.template.kpi_type?.id === KPITypes.employee_kpi
    const shouldAddProbationFilter = isEmployee && values.template.has_probation_target

    if (shouldAddProbationFilter) {
      table.resetFiltersAndSorting([
        {
          columnName: 'is_in_probation',
          filters: [{ id: 'True', name: 'True' }],
        },
      ])
    } else {
      table.resetFiltersAndSorting()
    }
  }, [values.template?.id])

  useEffect(() => {
    if (values.application_strategy && table && !table.loading) {
      const filters: KPIRuleInterface['filters'] = {}
      table.filterBy.forEach(filter => {
        filters[filter.columnName] = filter.filters.map(f => f.id)
      })

      if (
        values.application_strategy?.id === TemplateApplicationStrategies.apply_selected
      ) {
        values.filters = {
          ...filters,
          id: table.data.map(item => item.id),
        }
      } else {
        values.filters = filters
      }
      if (values.template?.has_probation_target) {
        values.filters = {
          ...values.filters,
          is_in_probation: ['True'],
        }
      }
    }
  }, [table?.loading, values.application_strategy, values.template?.has_probation_target])

  useEffect(() => {
    if (values.application_strategy?.id === TemplateApplicationStrategies.apply_all) {
      setShowInputIds(false)
    }
  }, [values.application_strategy])

  useEffect(() => {
    if (!values.application_strategy) {
      values.application_strategy = { id: TemplateApplicationStrategies.apply_all }
    }
  }, [])

  const handleIdFilter: React.FormEventHandler<HTMLTextAreaElement> = e => {
    const value = e.currentTarget.value
    const idsString = value.replace(/\s+/g, '')
    const idsArr = idsString.split(',').filter(id => id !== '' && isFinite(+id))

    table?.onFilterChange({
      columnName: 'id',
      filters: idsArr.map(id => ({ name: id, id })),
    })
  }

  const handleCheckAffectedEntities = async () => {
    try {
      const { data } = await checkAffectedEntities(values.filters)
      setAffectedEntitiesCount(data.count)
    } catch {
      setAffectedEntitiesCount(undefined)
    }
  }

  const applicationStrategiesOptions = [
    {
      value: TemplateApplicationStrategies.apply_all,
      label: 'Apply to all future ones',
      tooltip:
        'Use this option if you want to control the rule filters using the usual filters in table below. This is the default behavior.',
    },
    {
      value: TemplateApplicationStrategies.apply_selected,
      label: 'Apply to selected',
      tooltip:
        'This is an advanced option if you want to input the entity IDs manually. This option should be used together with “Input IDs manually”',
    },
  ]

  return (
    <>
      <Instructions
        variant={variant}
        isOpen={showInstructions}
        onClose={() => setShowInstructions(false)}
      />
      <PageWrapper>
        <PageHeader
          title={isEditMode ? initialValues.name : `New ${variant} template rule`}
          subtitle={
            isEditMode &&
            initialValues.owner?.id && (
              <InternalUIKitLink
                color="inherit"
                // @ts-expect-error object works fine here, but UI kit expects string
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: initialValues.owner.id,
                })}
              >
                {initialValues.owner.full_name}
              </InternalUIKitLink>
            )
          }
          backUrl={ROUTES.SETTINGS.GOALS.RULES.LIST}
        />
        <PageBody>
          <SettingsButtons>
            <MoreBar.Action
              useIcon={showInstructions ? 'SwitchOn' : 'SwitchOff'}
              onClick={() => setShowInstructions(!showInstructions)}
            >
              Show instructions
            </MoreBar.Action>
            <RefreshButton />
            <CopyButton afterSubmitUrl={pathToUrl(ROUTES.FORMS.KPI_RULE.GENERAL, {})} />
            <DeleteButton
              deleteApi={kpiRuleRequestsNew.delete!}
              backUrl={ROUTES.SETTINGS.GOALS.RULES.LIST}
              title={`${variant} template rule`}
            />
          </SettingsButtons>
          <AutoStepper>
            <NewStepperTitle title="General Info" />
            <InputGroup>
              <LapeNewInput required name="name" label="Name" />
              <LapeRadioSelectInput
                name="owner"
                label="Rule owner"
                selector={selectorKeys.employee}
              />
              <LapeNewTextArea required name="description" label="Description" rows={2} />
            </InputGroup>
            <NewStepperTitle title="Template" />
            <InputGroup>
              <LapeRadioSelectInput
                name="template"
                label="Template"
                selector={kpiRuleTemplateSelector}
              />
            </InputGroup>
            <>
              <NewStepperTitle title={`Apply to ${getEntityTypeText(values.template)}`} />
              <InputGroup>
                <LapeNewRadioButtons
                  variant="cell"
                  name="application_strategy"
                  options={applicationStrategiesOptions}
                  useMappingAsValue
                />
                <Bar>
                  <ActionButton
                    useIcon="Pencil"
                    onClick={() => {
                      setShowInputIds(true)
                      values.application_strategy = {
                        id: TemplateApplicationStrategies.apply_selected,
                      }
                    }}
                  >
                    Input IDs manually
                  </ActionButton>
                </Bar>
              </InputGroup>
              {showInputIds && (
                <Box mt="s-24">
                  <TextArea
                    onChange={handleIdFilter}
                    rows={2}
                    label="Start writing IDs separated by comma. E.g. 1, 3, 4, 5"
                  />
                </Box>
              )}
              {(!row || !table) && (
                <NewWarningMessage mt="s-24" type={NewWarningTypes.warning}>
                  Choose Template to see the table.
                </NewWarningMessage>
              )}
            </>
          </AutoStepper>
          {row && table && (
            <Box paddingTop="s-16">
              <Table.Widget>
                <Table.Widget.Table>
                  <AdjustableTable<
                    TeamInterface | DepartmentInterface | EmployeeInterface
                  >
                    name={TableNames.KpiTemplatesKPIRules}
                    row={row}
                    useWindowScroll
                    {...table}
                    onFilterChange={filter => table?.onFilterChange(filter, false)}
                    emptyState={
                      <EmptyTableRaw title={`${variant} rules will appear here.`} />
                    }
                    dataType={values.template?.kpi_type?.name}
                  />
                </Table.Widget.Table>
              </Table.Widget>
            </Box>
          )}
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            afterSubmitUrl={ROUTES.SETTINGS.GOALS.RULES.LIST}
            confirmationDialogue={
              isEditMode
                ? {
                    body: (
                      <AffectedEntitiesConfirmation
                        count={affectedEntitiesCount ?? 0}
                        isLoading={isCheckingAffectedEntities}
                      />
                    ),
                    loading: isCheckingAffectedEntities,
                  }
                : undefined
            }
            onBeforeSubmit={isEditMode ? () => handleCheckAffectedEntities() : undefined}
            onAfterSubmit={() => setAffectedEntitiesCount(undefined)}
            useValidator
          />
        </PageActions>
      </PageWrapper>
    </>
  )
})

export const KpiRule = ({ variant = 'KPI' }: { variant?: 'KPI' | 'goal' }) => (
  <Form api={kpiRuleRequestsNew}>
    <General variant={variant} />
  </Form>
)
