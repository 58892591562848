import React from 'react'
import { CellInsertParams, CellTypes, ColumnInterface } from '@src/interfaces/data'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { ROUTES } from '@src/constants/routes'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { selectorKeys } from '@src/constants/api'
import {
  CompetencyMatrixInterface,
  CompetencyMatrixSkill,
  SkillLevels,
} from '@src/interfaces/roles'
import { Flex, IconButton, Token } from '@revolut/ui-kit'
import { MatrixRowInterface } from '../types'
import { getCompetencyMatrix } from '../helpers/getCompetencyMatrix'
import { SeniorityInterface } from '@src/interfaces/seniority'
import {
  CompetencyLevels,
  getCompetencyBackground,
  getCompetencyOptions,
  getNormalizedSeniorityName,
  getSkillLevelDescription,
} from '@src/features/CompetencyMatrixTable/utils'
import { css } from 'styled-components'
import WeightCell from '../components/WeightCell'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { SeniorityCell } from '../components/SeniorityCell'
import { IdAndName } from '@src/interfaces'
import { rowWrapperMinHeight } from '@src/components/TableV2/common'

interface CommonMatrixColumnInterface {
  firstRowTitle?: string
  readOnly?: boolean
}

export const getSkillTitleColumn = ({
  firstRowTitle,
}: CommonMatrixColumnInterface): ColumnInterface<CompetencyMatrixInterface> => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  selectorsKey: selectorKeys.none,
  filterKey: null,
  sortKey: null,
  notHoverable: true,
  title: firstRowTitle || '',
  insert: ({ data }) => {
    return (
      <Flex
        height={rowWrapperMinHeight['competency-matrix']}
        alignItems="flex-start"
        py="s-16"
        data-testid="skill-name"
      >
        {data.skill?.id ? (
          <OpenInNewTab
            noIcon
            color={Token.color.foreground}
            link={pathToFullUrlWithActiveWorkspace(ROUTES.FORMS.SKILL.PREVIEW, {
              id: data.skill.id,
            })}
            label={data.skill?.name || ''}
          />
        ) : (
          data.skill?.name
        )}
      </Flex>
    )
  },
})

export interface SeniorityColumnProps extends CommonMatrixColumnInterface {
  onChangeCompetency?: (
    competencyLevel: SkillLevels,
    seniorityId: number,
    seniorityName: string,
    seniorityLevel: number,
    index: number,
    matrixId: number,
  ) => void
  seniority: SeniorityInterface
  seniorities: SeniorityInterface[]
  skillsets: CompetencyMatrixSkill[]
  competencyMatrices: MatrixRowInterface[]
  filterNonExistent?: boolean
  refetchSkills: VoidFunction
}

export const getSeniorityColumn = ({
  onChangeCompetency,
  seniority,
  skillsets,
  competencyMatrices,
  readOnly,
  seniorities,
  filterNonExistent,
  refetchSkills,
}: SeniorityColumnProps): ColumnInterface<CompetencyMatrixInterface> => ({
  type: CellTypes.insert,
  idPoint: String(seniority.id),
  dataPoint: seniority.name,
  selectorsKey: selectorKeys.none,
  filterKey: null,
  notHoverable: true,
  sortKey: null,
  background: (
    data: MatrixRowInterface | CompetencyMatrixInterface,
  ): string | undefined =>
    getCompetencyBackground(seniority.level, data as CompetencyMatrixInterface),
  title: getNormalizedSeniorityName(seniority.name),
  insert: ({
    data,
    parentIndexes,
  }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
    const {
      index,
      competencyMatrix: parsedCompetencyMatrix,
      matrixId,
    } = getCompetencyMatrix({
      data,
      parentIndexes,
      competencyMatrices,
    })

    const isDisabled = parsedCompetencyMatrix.disabled
    const competencyMatrix = parsedCompetencyMatrix.children || []

    const d = data as CompetencyMatrixInterface
    const skillset = skillsets.find(item => item.id === d.skill?.id)
    const competencyMatrixItem = d.skill?.id
      ? competencyMatrix.find(item => item.skill?.id === d.skill?.id)
      : competencyMatrix[index]

    const competencyItem = d?.competencies?.find(
      item => item.seniority_level === seniority.level,
    )!
    const competencyName = CompetencyLevels.find(
      item => item.id === competencyItem?.competency_level,
    )?.name

    const competency: IdAndName<SkillLevels> | undefined =
      competencyItem?.competency_level && competencyName
        ? {
            id: competencyItem.competency_level,
            name: competencyName,
          }
        : undefined

    const tooltip = {
      tooltipTitle: '',
      tooltipText: '',
    }

    if (competency?.id && skillset) {
      tooltip.tooltipTitle = `${competency.name} ${skillset.name}`
      tooltip.tooltipText = getSkillLevelDescription(competency.id, skillset) || ''
    }

    const shouldFilterNonExistent =
      filterNonExistent && competencyMatrices[matrixId].sectionTitle === 'Deliverables'

    const selectOptions = getCompetencyOptions({
      skillset,
      competencies: competencyMatrixItem?.competencies,
      seniorityId: seniority.id,
      seniorities,
    })

    return (
      <SeniorityCell
        refetchSkills={refetchSkills}
        dataTestId={`competency-${isDisabled || readOnly ? 'static-' : ''}${index}-${
          seniority.name
        }`}
        disabled={isDisabled || readOnly}
        skillset={skillset}
        value={competency}
        options={
          shouldFilterNonExistent
            ? selectOptions.filter(option => option.id !== 'none')
            : selectOptions
        }
        skillLevelDescription={
          competency?.id ? getSkillLevelDescription(competency.id, skillset) : undefined
        }
        onChange={value => {
          if (value) {
            onChangeCompetency?.(
              value.id as SkillLevels,
              seniority.id,
              seniority.name,
              seniority.level,
              index,
              matrixId,
            )
          }
        }}
      />
    )
  },
})

export interface WeightColumnProps extends CommonMatrixColumnInterface {
  onChangeWeight?: (index: number, weight: number, matrixId: number) => void
  competencyMatrices: MatrixRowInterface[]
  weightsError?: boolean
}

export const getWeightColumn = ({
  competencyMatrices,
  onChangeWeight,
  weightsError,
  readOnly,
  firstRowTitle,
}: WeightColumnProps): ColumnInterface<CompetencyMatrixInterface> => ({
  type: CellTypes.insert,
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: null,
  filterKey: null,
  notHoverable: true,
  selectorsKey: selectorKeys.none,
  title: 'Weight',
  background: data => (data.weight === 0 ? Token.color.danger_30 : undefined),
  wrapperCss: () =>
    weightsError
      ? css`
          outline: 1px solid ${Token.color.red};
          outline-offset: -1px;
        `
      : undefined,
  insert: ({
    data,
    parentIndexes,
  }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
    const {
      index,
      competencyMatrix: parsedCompetencyMatrix,
      matrixId,
    } = getCompetencyMatrix({
      data,
      parentIndexes,
      competencyMatrices,
    })
    const isDisabled =
      parsedCompetencyMatrix.disabled && parsedCompetencyMatrix.disableWeights

    const d = data as CompetencyMatrixInterface

    return (
      <Flex
        height={rowWrapperMinHeight['competency-matrix']}
        alignItems="flex-start"
        py="s-16"
      >
        <WeightCell
          value={d.weight || 0}
          type={TableCellInputType.float}
          disabled={isDisabled || readOnly}
          onBlur={v => {
            onChangeWeight?.(index, +v, matrixId)
          }}
          testid={`weight_${firstRowTitle}_${index}`}
        />
      </Flex>
    )
  },
})

export interface DeleteColumnProps extends CommonMatrixColumnInterface {
  onDelete?: (index: number, matrixId: number) => void
  competencyMatrices: MatrixRowInterface[]
}
export const getDeleteColumn = ({
  firstRowTitle,
  onDelete,
  competencyMatrices,
}: DeleteColumnProps) => ({
  type: CellTypes.insert,
  idPoint: 'delete',
  dataPoint: 'delete',
  selectorsKey: selectorKeys.none,
  filterKey: null,
  sortKey: null,
  title: '',
  insert: ({
    parentIndexes,
    data,
  }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
    const {
      index,
      competencyMatrix: parsedCompetencyMatrix,
      matrixId,
    } = getCompetencyMatrix({
      data,
      parentIndexes,
      competencyMatrices,
    })

    const isSkillOptional = parsedCompetencyMatrix.isSkillOptional
    const isDisabled = parsedCompetencyMatrix.disabled
    const competencyMatrix = parsedCompetencyMatrix.children || []

    return (
      <Flex
        justifyContent="center"
        height={rowWrapperMinHeight['competency-matrix']}
        py="s-16"
      >
        {onDelete && (isSkillOptional || competencyMatrix.length > 1) && !isDisabled && (
          <IconButton
            data-testid={`delete-${firstRowTitle}-${index}`}
            useIcon="CrossCircle"
            aria-label={`Delete ${firstRowTitle} ${index}`}
            onClick={() => onDelete?.(index, matrixId)}
            color={Token.color.greyTone20}
            size={16}
          />
        )}
      </Flex>
    )
  },
})
