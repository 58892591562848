import styled from 'styled-components'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import isNumber from 'lodash/isNumber'
import {
  EpicOption,
  KPIEpicsTargetRow,
  KpiInterface,
  KpiTargetEpics,
  KpiTargets,
} from '@src/interfaces/kpis'
import { selectorKeys } from '@src/constants/api'
import CycleWithStatus from '@components/ColumnInserts/CycleWithStatus/CycleWithStatus'
import React from 'react'
import { Flex, Text } from '@revolut/ui-kit'
import RoadmapProgress from '@components/ColumnInserts/RoadmapProgress/RoadmapProgress'
import { formatDate } from '@src/utils/format'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { strategyLabelsMap } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/common'
import CellWithChangelog from '@components/ColumnInserts/CellWithChangelog/CellWithChangelog'
import KPITargetActions, {
  KpiTargetActionsOptions,
} from '@components/ColumnInserts/KPITargetActions/KPITargetActions'
import { Link } from '@revolut/icons'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import LongNumberCell from '@components/TableV2/AdvancedCells/LongNumberCell/LongNumberCell'
import { getStatusColor } from '@components/CommonSC/General'

const A = styled.a`
  color: inherit;
  text-decoration: none;
  justify-self: start;

  &:hover {
    text-decoration: underline;
  }
`

export const kpiTargetCycleNameColumn: ColumnInterface<KpiTargets> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Cycle',
  insert: ({ data, parentIndexes }) => {
    if (data.employee_cycle) {
      return (
        <CellWithChangelog fieldName={`targets.${parentIndexes[0]}.employeeCycle.name`}>
          <CycleWithStatus name={data.employee_cycle.name} />
        </CellWithChangelog>
      )
    }

    if (data.review_cycle) {
      return (
        <CellWithChangelog fieldName={`targets.${parentIndexes[0]}.reviewCycle.name`}>
          <CycleWithStatus {...data.review_cycle} />
        </CellWithChangelog>
      )
    }

    if (data.is_probation) {
      return 'Probation'
    }

    return '-'
  },
}

export const kpiTargetParentColumn: ColumnInterface<
  KpiTargets | KPIEpicsTargetRow | EpicOption
> = {
  type: CellTypes.insert,
  idPoint: 'parent_kpi',
  dataPoint: 'parent_kpi',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Parent',
  insert: ({ data, parentIndexes }) => {
    if ('url' in data) {
      return null
    }
    if (!data.parent_kpi?.id) {
      return '-'
    }
    const targetsField = 'children' in data ? 'target_epics' : 'targets'
    const path = ROUTES.FORMS.KPI.PREVIEW

    return (
      <CellWithChangelog fieldName={`${targetsField}.${parentIndexes[0]}.parent_kpi`}>
        <a
          target="_blank"
          rel="noreferrer"
          href={pathToFullUrlWithActiveWorkspace(path, { id: data.parent_kpi?.id })}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <Link color="grey-tone-50" hoverColor="primary" size={14} />
        </a>
      </CellWithChangelog>
    )
  },
}

export const kpiTargetOwnerColumn: ColumnInterface<
  KpiTargets | KPIEpicsTargetRow | EpicOption
> = {
  type: CellTypes.insert,
  idPoint: 'owner',
  dataPoint: 'owner',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Owner',
  insert: ({ data, parentIndexes }) => {
    if ('url' in data) {
      return null
    }
    if (!data.owner || !data.is_top_down) {
      return '-'
    }
    const targetsField = 'children' in data ? 'target_epics' : 'targets'

    return (
      <CellWithChangelog fieldName={`${targetsField}.${parentIndexes[0]}.owner`}>
        <UserWithAvatar {...data.owner} />
      </CellWithChangelog>
    )
  },
}

export const kpiTargetStrategyColumn: ColumnInterface<KpiTargets> = {
  type: CellTypes.insert,
  idPoint: 'kpi_goal',
  dataPoint: 'kpi_goal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Strategy',
  insert: ({ data, parentIndexes }) => (
    <CellWithChangelog fieldName={`targets.${parentIndexes[0]}.kpi_goal`}>
      <>{strategyLabelsMap[data.kpi_goal]}</>
    </CellWithChangelog>
  ),
}

export const kpiTargetStatusColumn: ColumnInterface<
  KpiTargets | KpiTargetEpics | EpicOption
> = {
  type: CellTypes.insert,
  idPoint: 'target_status',
  dataPoint: 'target_status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data, parentIndexes }) => {
    if (parentIndexes.length === 1) {
      const status = (data as KpiTargets).status
      return status ? (
        <Text color={getStatusColor(status)}>{status.replace('_', ' ')}</Text>
      ) : null
    }
    return null
  },
}

export const kpiTargetInitialValueColumn: ColumnInterface<KpiTargets> = {
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Initial value',
  insert: ({ data, parentIndexes }) => (
    <CellWithChangelog fieldName={`targets.${parentIndexes[0]}.initial_value`}>
      <LongNumberCell value={data.initial_value} />
    </CellWithChangelog>
  ),
}

export const getKpiTargetCurrentValueColumn = (
  kpi: KpiInterface,
): ColumnInterface<KpiTargets> => ({
  type: CellTypes.insert,
  idPoint: 'current_value',
  dataPoint: 'current_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Result',
  insert: ({ data }) => {
    const result = data.current_value || kpi.current_progress
    return isNumber(result) ? <LongNumberCell value={result} /> : '-'
  },
})

export const kpiTargetValueColumn: ColumnInterface<KpiTargets> = {
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Target',
  insert: ({ data, parentIndexes }) => (
    <CellWithChangelog fieldName={`targets.${parentIndexes[0]}.target`}>
      <LongNumberCell value={data.target} />
    </CellWithChangelog>
  ),
}

export const kpiDynamicColumn = (
  key: 'target' | 'initial_value',
): ColumnInterface<KpiTargets> => ({
  type: CellTypes.insert,
  idPoint: key,
  dataPoint: key,
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: key === 'initial_value' ? 'Initial value' : 'Target',
  insert: () => 'dynamic',
})

export const kpiTargetWeightColumn: ColumnInterface<KpiTargets> = {
  type: CellTypes.insert,
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Weight',
  insert: ({ data }) => (data.weight ? `${data.weight}%` : '-'),
}

export const kpiTargetProgressColumn: ColumnInterface<KpiTargets> = {
  type: CellTypes.insert,
  idPoint: 'progress',
  dataPoint: 'progress',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Progress',
  insert: ({ data }) =>
    isNumber(data.progress) ? <ColoredPercent percent={data.progress * 100} /> : '-',
}

export const kpiTargetEpicsNameColumn: ColumnInterface<KPIEpicsTargetRow | EpicOption> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Cycle / Deliverables',
  insert: ({ data }) => {
    if ('children' in data) {
      return <CycleWithStatus {...data.review_cycle} />
    }
    return (
      <A href={data.url} target="_blank" rel="noreferrer noopener">
        {data.name}
      </A>
    )
  },
}

export const kpiTargetEpicsProgress: ColumnInterface<KPIEpicsTargetRow | EpicOption> = {
  type: CellTypes.insert,
  idPoint: 'progress',
  dataPoint: 'progress',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Progress',
  insert: ({ data }) => (
    <Flex>
      <Text textAlign="right" width="4em" pr="1em">
        {data.done ? Math.round(data.done) : 0}%
      </Text>
      <RoadmapProgress {...data} />
    </Flex>
  ),
}

export const kpiTargetEpicsDueDate: ColumnInterface<KPIEpicsTargetRow | EpicOption> = {
  type: CellTypes.insert,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Due',
  insert: ({ data }) => {
    if ('due_date' in data && !!data.due_date) {
      return <Text>{formatDate(data.due_date || '')}</Text>
    }
    return <Text color="grey-tone-50">-</Text>
  },
}

export const kpiTargetEpicsStatus: ColumnInterface<KPIEpicsTargetRow | EpicOption> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Jira Status',
  insert: ({ data }) => (
    <Text color={data.jira_status === 'Done' ? 'green' : undefined}>
      {data.jira_status}
    </Text>
  ),
}

export const getKpiTargetAction = (
  options: KpiTargetActionsOptions,
): ColumnInterface<KPIEpicsTargetRow | EpicOption | KpiTargets> => {
  return {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
    insert: ({ data, parentIndexes }) => (
      <KPITargetActions parentIndexes={parentIndexes} rowData={data} options={options} />
    ),
  }
}
