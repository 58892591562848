import React, { useState } from 'react'
import { UseQueryResult } from 'react-query'
import { useParams } from 'react-router-dom'
import { Avatar, Banner, Group, MoreBar, Text, Token, VStack } from '@revolut/ui-kit'

import { FormPreview } from '@components/FormPreview/FormPreview'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import {
  downloadAdpReport,
  useGetAdpReportExport,
  useGetAdpReportFtpUpload,
} from '@src/api/payroll'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ROUTES } from '@src/constants/routes'
import { AdpReportInterface } from '@src/interfaces/payroll'
import { pathToUrl } from '@src/utils/router'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface Props {
  action: string
  id: string
  requestId: string
  useGetRequestData: (id: string, requestId: string) => UseQueryResult<AdpReportInterface>
}

const getReportColorAndSubtitle = (
  action: string,
  isCompleted: boolean,
  error: string | null,
) => {
  if (!isCompleted) {
    return {
      color: Token.color.warning,
      subtitle: action === 'download' ? 'Generating report' : 'Sending report',
    }
  }

  if (isCompleted && !!error) {
    return {
      color: Token.color.danger,
      subtitle:
        action === 'download' ? 'Could not generate report' : 'Could not send report',
    }
  }

  return {
    color: Token.color.success,
    subtitle: action === 'download' ? 'Report generated' : 'Report sent',
  }
}

const DownloadReport = ({ action, id, requestId, useGetRequestData }: Props) => {
  const [isPending, setIsPending] = useState(false)
  const { data } = useGetRequestData(id, requestId)

  const errorPopup = useErrorPopup()

  if (!data) {
    return <PageLoading />
  }

  const handleDownload = async () => {
    try {
      setIsPending(true)
      await downloadAdpReport(id, requestId, data.file_name)
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: 'Could not download report',
        forceFallbackTitle: true,
      })
    } finally {
      setIsPending(false)
    }
  }

  const { color, subtitle } = getReportColorAndSubtitle(
    action,
    data.is_completed,
    data.error,
  )
  const backUrl = pathToUrl(ROUTES.PEOPLE.PAYROLL, { id })

  return (
    <>
      <PageHeader
        backUrl={backUrl}
        routerAction={PageHeaderRouterAction.goBack}
        title={`${data.pay_group.name} Report`}
        subtitle={<Text color={color}>{subtitle}</Text>}
      />
      <PageBody>
        <VStack space="s-24">
          {action === 'download' && (
            <MoreBar>
              <MoreBar.Action
                disabled={!data.is_completed || !!data.error}
                onClick={handleDownload}
                pending={isPending}
                useIcon="Download"
              >
                Download
              </MoreBar.Action>
            </MoreBar>
          )}
          {data.error && (
            <Banner>
              <Banner.Avatar>
                <Avatar
                  bg={Token.color.red_10}
                  color={Token.color.red}
                  size={56}
                  useIcon="16/Warning"
                />
              </Banner.Avatar>
              <Banner.Content>
                <Banner.Description>{data.error}</Banner.Description>
              </Banner.Content>
            </Banner>
          )}
          <FormPreview data={data}>
            <Group>
              <FormPreview.Item field="from_date" title="Start date" type="date" />
              <FormPreview.Item field="to_date" title="End date" type="date" />
              <FormPreview.Item
                title="Requested by"
                field="requested_by"
                type="employee"
              />
            </Group>
          </FormPreview>
        </VStack>
      </PageBody>
    </>
  )
}

export const PreviewAdpDownloadReport = () => {
  const { action, id, requestId } =
    useParams<{ action: string; id: string; requestId: string }>()

  return (
    <DownloadReport
      action={action}
      id={id}
      requestId={requestId}
      useGetRequestData={
        action === 'download' ? useGetAdpReportExport : useGetAdpReportFtpUpload
      }
    />
  )
}
