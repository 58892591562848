import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Id, IdAndName } from '@src/interfaces'
import { getCDNAssetUrl, getRevolutersCDNAssetUrl } from '@src/utils/getCDNLink'
import { pathToUrl } from '@src/utils/router'

export const navigateToDepartmentResourcePath = ({
  departmentId,
  tabId,
  id,
  initialSection,
}: {
  departmentId: number
  tabId: number
  id?: number
  initialSection?: number
}) =>
  navigateTo(
    pathToUrl(
      ROUTES.FORMS.DEPARTMENT_RESOURCE,
      {
        departmentId,
        tabId,
        id,
      },
      initialSection ? { initialSection: `${initialSection}` } : undefined,
    ),
  )

const getIcon = (asset: string) => getCDNAssetUrl(`icons/${asset}.svg`)
const getRevolutersIcon = (asset: string) =>
  getRevolutersCDNAssetUrl(`assets/icons/${asset}.svg`)

export const getValueTypeIcon = (valueType: Id<string> | null) => {
  const fallback = getIcon('Link')

  if (!valueType) {
    return fallback
  }

  switch (valueType.id) {
    case 'confluence':
      return getIcon('Confluence')
    case 'jira':
      return getIcon('Jira')
    case 'looker':
      return getRevolutersIcon('Looker')
    case 'tableau':
      return getRevolutersIcon('Tableau')
    case 'revolut':
      return getIcon('16/Revolut')
    case 'figma':
      return getIcon('Figma')
    default:
      return fallback
  }
}

export const getValueTypeLabel = (valueType: IdAndName<string> | null) => {
  if (valueType) {
    return valueType.name
  }
  return 'Link'
}
