import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { PageBody } from '@src/components/Page/PageBody'
import { BulkDataImportActions } from '@src/features/BulkDataImport/BulkDataImportActions'
import { BulkDataImportSession } from '@src/features/BulkDataImport/BulkDataImportSession'
import { importContractRow } from '@src/pages/OnboardingChecklist/Contracts/BulkUpload'
import {
  fields,
  useOptions,
} from '@src/pages/OnboardingChecklist/Contracts/sessionConfig'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const ReviewData = () => {
  const options = useOptions()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  return (
    <PageBody maxWidth="100%">
      <BulkDataImportSession
        actions={props => (
          <BulkDataImportActions {...props} backUrl={ROUTES.APPS.CONTRACTS.ALL} />
        )}
        apiEndpoint={API.CONTRACTS_UPLOAD}
        fields={fields}
        row={importContractRow(options, specialisationsEnabled)}
        tableName={TableNames.ImportContracts}
      />
    </PageBody>
  )
}
