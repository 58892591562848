import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import {
  checkCanRequestFeedback,
  checkCanViewPIPv2Settings,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'

interface Props {
  employee: EmployeeInterface
  selectedPeriod: PerformanceSelector
}

export const useGetPermissions = ({ employee, selectedPeriod }: Props) => {
  const user = useSelector(selectUser)
  const canViewPIPv2Settings = checkCanViewPIPv2Settings(selectedPeriod)
  const { data: performanceSettings } = useGetPerformanceSettings()
  const canDeleteEmployeePerformanceCycle = useHasGlobalPermission(
    PermissionTypes.DeleteEmployeePerformanceCycle,
  )
  const hasProbationCommitteeHRPermissions = employee.field_options.permissions?.includes(
    PermissionTypes.ProbationCommitteeHRPermissions,
  )

  const canViewProbationChangelog =
    hasProbationCommitteeHRPermissions ||
    user.id === employee.line_manager?.id ||
    user.id === employee.quality_control?.id

  const permissions = {
    canViewPIPv2Settings,
    canViewProbationChangelog,
    canRequestReview:
      performanceSettings?.enable_peer_reviews ?? checkCanRequestFeedback(employee),
    canViewUpwards: performanceSettings?.enable_upwards_reviews,
    canDeleteEmployeePerformanceCycle,
    hasProbationCommitteeHRPermissions,
  }

  return permissions
}
