import { useEffect, useRef, useState } from 'react'
import { AxiosError, AxiosPromise } from 'axios'
import { useIntersectViewport } from '@revolut/ui-kit'

import { GetRequestData } from '@src/interfaces'
import { ApiQueryParams } from '@src/api/types'

type UseInfiniteLoadingListParams<T> = {
  enabled?: boolean
  getItems: (params: { params: ApiQueryParams }) => AxiosPromise<GetRequestData<T>>
  params: ApiQueryParams
  handleError?: (e?: AxiosError) => void
}
export const useInfiniteLoadingList = <T>({
  enabled = true,
  getItems,
  params,
  handleError,
}: UseInfiniteLoadingListParams<T>) => {
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const [hasMoreData, setHasMoreData] = useState<boolean>(false)
  const [items, setItems] = useState<T[]>([])

  const ref = useRef(null)
  const pending = isLoadingMore || isReloading

  useIntersectViewport(pending ? undefined : ref, isIntersecting => {
    if (isReloading) {
      return
    }
    if (hasMoreData && isIntersecting) {
      setPage(page + 1)
    }
  })

  const loadFilteredItems = async () => {
    let results: T[] = []

    try {
      const { data } = await getItems({
        params: { ...params, page },
      })
      results = data.results
      const { pages, count } = data
      setHasMoreData(!!pages.next)
      setTotal(count)
    } catch (e) {
      handleError?.(e)
    }
    return results
  }

  useEffect(() => {
    if (!enabled || isReloading || page === 1) {
      return
    }
    setIsLoadingMore(true)

    loadFilteredItems()
      .then(loadedItems => {
        setItems([...items, ...loadedItems])
      })
      .finally(() => {
        setIsLoadingMore(false)
      })
  }, [enabled, page, isReloading])

  useEffect(() => {
    if (!enabled) {
      return
    }
    setIsReloading(true)
    setPage(1)

    loadFilteredItems()
      .then(res => {
        setItems(res)
      })
      .finally(() => {
        setIsReloading(false)
      })
  }, [enabled, params])

  return {
    ref,
    items,
    total,
    hasMoreData,
    isReloading,
    isLoadingMore,
    refetch: async (onSuccess?: VoidFunction) => {
      const refetched = await loadFilteredItems()
      setItems(refetched)
      onSuccess?.()
    },
  }
}
