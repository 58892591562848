import Form from '@src/features/Form/Form'
import React, { ComponentProps } from 'react'
import { TimelineFormBody } from './TimelineFormBody'
import { pipCycleSettingsRequests } from '@src/api/pip'

interface Props extends Omit<ComponentProps<typeof TimelineFormBody>, 'variant'> {
  cycleId: number
  employeeId: number
}

export const PipTimelineSettingsForm = ({
  cycleId,
  employeeId,
  ...formBodyProps
}: Props) => {
  return (
    <Form
      api={pipCycleSettingsRequests}
      forceParams={{ id: String(cycleId), employeeId: String(employeeId) }}
      disableLocalStorageCaching
      disableDataCleanup
    >
      <TimelineFormBody variant="PIP" {...formBodyProps} />
    </Form>
  )
}
