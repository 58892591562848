import React from 'react'
import { useTable, useTableReturnType } from '@components/TableV2/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
  RowInterface,
} from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import {
  kpiCompanyPerformanceColumn,
  kpiMetricColumn,
  kpiStrategyColumn,
  kpiUnitColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useQuery } from '@src/utils/queryParamsHooks'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import {
  foreignGoalColumn,
  goalTargetNameColumn,
  targetsTypeColumn,
} from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { targetsTableWithoutChildren } from '@src/api/goals'
import { GoalsSettingsButton } from '../components/GoalsSettingsButton'
import Table from '@src/components/TableV2/Table'
import { ProgressWithNavigation } from '../components/ProgressWithNavigation'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

export const getKpiFilters = (cycleId?: string): FilterByInterface[] => [
  ...getCommonFilters({ reviewCycle: cycleId ? { id: cycleId } : undefined }),
  {
    filters: [{ name: 'True', id: 'True' }],
    columnName: 'is_company',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting: SortByInterface[] = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
  {
    sortBy: 'has_children',
    direction: SORT_DIRECTION.ASC,
  },
]

export const CompanyTargets = ({
  initialCycle,
}: {
  initialCycle?: ReviewCyclesInterface
}) => {
  const { query } = useQuery()
  const table = useTable<KpiInterface, Stats>(
    targetsTableWithoutChildren,
    getKpiFilters(query.cycle__id || !initialCycle ? undefined : String(initialCycle.id)),
    kpiSorting,
  )

  return (
    <KpiPage
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}

interface StructureProps {
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const departmentRow: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...goalTargetNameColumn,
      width: 300,
    },
    {
      ...foreignGoalColumn,
      width: 150,
    },
    {
      ...kpiCompanyPerformanceColumn,
      width: 150,
    },
    {
      ...kpiMetricColumn,
      width: 190,
    },
    {
      ...kpiUnitColumn,
      width: 60,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 150,
    },
    {
      ...targetsTypeColumn,
      width: 90,
    },
  ],
}

const KpiPage = ({ table }: StructureProps) => {
  const canRefreshKpis = true // fixme: define the rule

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <ProgressWithNavigation goalFilters={table.filterBy} />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <GoalCycleFilter
          isFilterButtonUI
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          selector={selectorKeys.review_cycles}
          filter={table.filterBy}
        />
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {refreshButton}
          <GoalsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<KpiInterface>
          hideCount
          expandableType="chevron"
          name={TableNames.CompanyKPI}
          useWindowScroll
          dataType="Targets"
          row={departmentRow}
          {...table}
          onRowClick={onKPITableRowClick}
          emptyState={
            <EmptyTableRaw title="Please add your company metrics to see them here." />
          }
        />
        {refreshNote}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
