import { useMemo, useState } from 'react'
import { isPipOrProbationCategory, ReviewCategory } from '@src/interfaces/performance'

type TabKey = 'checkpoint' | 'full'

export const useSummarySideBarTabs = (
  category: ReviewCategory | undefined,
  initialTab: TabKey | undefined = 'checkpoint',
): {
  selectedTab: TabKey
  tabs: { key: TabKey; title: string }[]
  selectTab: React.Dispatch<React.SetStateAction<TabKey>>
} | null => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(initialTab)

  return useMemo(() => {
    if (isPipOrProbationCategory(category)) {
      return {
        tabs: [
          { key: 'checkpoint', title: 'Checkpoint results' },
          { key: 'full', title: 'Full performance review' },
        ],
        selectedTab,
        selectTab: setSelectedTab,
      }
    }

    return null
  }, [category, selectedTab])
}
