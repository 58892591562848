import React from 'react'
import { Box } from '@revolut/ui-kit'
import { usePerformanceOutput } from '@src/features/AiInsights/Performance/components/Insights/Skills/hooks/usePerformanceOutput'
import { SkillPerformanceOutputProps } from '@src/features/AiInsights/Performance/components/Insights/Skills/PerformanceOutput'
import { InsightsWidget } from '@src/features/AiInsights/Performance/components/Insights/InsightsWidget/InsightsWidget'
import { InsightsRelation } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { InsightsSelector } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/InsightsSelector'
import { InsightsOptionInterface } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/types'
import { InsightsActions } from '@src/features/AiInsights/Performance/components/Insights/InsightsActions/InsightsActions'
import { performanceInsightsModel } from '@src/features/AiInsights/Performance/models/insightsModel'

interface Props {
  employeeId: number
  cycleId: number
  skillId: number
  relation: InsightsRelation
  onDetailsClick: (props: SkillPerformanceOutputProps) => void
  onSelectInsightsClick: VoidFunction
}

export const PerformanceInsights = ({
  employeeId,
  cycleId,
  skillId,
  relation,
  onDetailsClick,
  onSelectInsightsClick,
}: Props) => {
  const {
    employee,
    insights,
    selectedInsights,
    insightsGroup,
    skillRatingHistoryData,
    skill,
    growthPlan,
    oneToOneInsights,
    oneToOneMeeting,
    isLoading,
    onSelectInsights,
    onRefreshInsights,
  } = usePerformanceOutput(employeeId, cycleId, skillId, relation)

  if (!employee || !insightsGroup || !skill) {
    return null
  }

  const hasDetails =
    skillRatingHistoryData.length > 0 || !!growthPlan || oneToOneInsights.length > 0

  const handleDetailsClick = () => {
    onDetailsClick({
      cycleId,
      skill,
      skillRatingHistoryData,
      growthPlan,
      oneToOneInsights,
      oneToOneMeeting,
      isLoading,
    })
  }

  const handleSelectInsightsClick = (option: InsightsOptionInterface) => {
    if (option.id === selectedInsights?.id) {
      return
    }

    onSelectInsightsClick()
    onSelectInsights(option)
  }

  if (!selectedInsights?.data) {
    return null
  }

  return (
    <Box p="s-16" data-testid="performance_skill_insights_widget">
      <InsightsWidget
        summary={selectedInsights.data}
        summaryVariant="default"
        isLoading={isLoading}
        hasDetails={hasDetails}
        updatedAt={performanceInsightsModel.getGenerationDate(selectedInsights)}
        title={
          insights.length > 1 ? (
            <InsightsSelector
              value={selectedInsights}
              options={insights}
              onSelect={handleSelectInsightsClick}
            />
          ) : undefined
        }
        actions={
          <InsightsActions
            insights={selectedInsights}
            relation={relation}
            domain="skillInsights"
            employee={employee}
            refreshInsights={onRefreshInsights}
          />
        }
        onDetailsClick={handleDetailsClick}
      />
    </Box>
  )
}
