import React from 'react'
import useResizeObserver from 'use-resize-observer'
import { FormattedMessage } from 'react-intl'
import { Box, Subheader, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { PerformanceGradeOption } from '@src/interfaces/selectors'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useChartData } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/hooks/useChartData'
import { GradesDistributionBarChart } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/BarChart'

interface Props {
  reviewCycle: ReviewCyclesInterface | null
  gradesOptions: PerformanceGradeOption[]
  isLoading: boolean
}

export const GradesDistributionBySeniority = ({
  reviewCycle,
  gradesOptions,
  isLoading,
}: Props) => {
  const { height, ref } = useResizeObserver<HTMLDivElement>()
  const {
    dataKeys,
    data: chartData,
    isLoading: isChartDataLoading,
  } = useChartData(reviewCycle, gradesOptions)

  return (
    <Box
      height="100%"
      width="100%"
      ref={ref}
      data-testid="performance_analytics_seniority_grades_distribution"
    >
      <Widget px="s-16" pb="s-16" height="100%">
        <VStack space="s-16" height="100%">
          <Subheader>
            <Subheader.Title>
              <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
                <FormattedMessage defaultMessage="Performance" id="performance.label" />
              </Text>
            </Subheader.Title>
            <Subheader.Side>
              <Text color={Token.color.foreground}>
                <FormattedMessage
                  defaultMessage="By seniority"
                  id="performance.reviews.analytics.groups.by-seniority.label"
                />
              </Text>
            </Subheader.Side>
          </Subheader>
          <GradesDistributionBarChart
            data={chartData}
            dataKeys={dataKeys}
            isLoading={isLoading || isChartDataLoading}
            height={height}
          />
        </VStack>
      </Widget>
    </Box>
  )
}
