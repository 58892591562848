import React from 'react'
import { CalendarProps, DateInput, InputProps } from '@revolut/ui-kit'
import { DatePickerLocaleProvider } from '@src/components/Inputs/DatePickerInput/DatePickerLocaleProvider'

export type DatePickerInputDisabledDays = Extract<
  CalendarProps,
  { variant?: 'date' }
>['disabledDays']

export interface DatePickerInputProps {
  value?: Date | string | null
  onChange?: (date: Date | null) => void
  defaultValue?: Date | string | null
  label?: string
  message?: React.ReactNode
  description?: React.ReactNode
  disabledDays?: DatePickerInputDisabledDays
  disabled?: boolean
  clearable?: boolean
  renderAction?: InputProps['renderAction']
  initialMonth?: Date
  'aria-invalid'?: boolean
  'data-name'?: string
  hideOptional?: boolean
  placeholder?: string
  width?: number
  form?: string
}

export const DatePickerInput = ({
  value,
  defaultValue,
  ...props
}: DatePickerInputProps) => {
  return (
    <DatePickerLocaleProvider>
      <DateInput
        value={value ? new Date(value) : null}
        defaultValue={defaultValue ? new Date(defaultValue) : null}
        {...props}
      />
    </DatePickerLocaleProvider>
  )
}
