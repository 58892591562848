import React, { useMemo, useState } from 'react'
import { FilterByInterface, FilterType, RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@components/TableV2/hooks'
import { Bar, FilterButton, MoreBar, chain } from '@revolut/ui-kit'
import { createReview, crmTableRequests, getCRMIds } from '@src/api/CRM'
import {
  crmNameColumn,
  crmSpecialisationColumn,
  crmSeniorityColumn,
  crmTagsColumn,
  crmCurrentCompanyColumn,
  crmYearsOfExperienceColumn,
  crmPreferredLocationsColumn,
  crmCurrentCountryColumn,
  crmStatusColumn,
  crmArchivalReasonColumn,
  crmDesiredSalaryColumn,
  crmLastEngagementDateColumn,
  crmArchivalDateColumn,
  crmReEngagementDateColumn,
  crmReEngagementStatusColumn,
} from '@src/constants/columns/CRM'
import { TableNames } from '@src/constants/table'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { FiltersSidebarItemInterface } from '@src/components/FiltersSidebar/FiltersSidebarItem'
import FiltersSidebar, {
  getAppliedFiltersCount,
} from '@src/components/FiltersSidebar/FiltersSidebar'
import { selectorKeys } from '@src/constants/api'
import { CRMInterface, CRMSelectedCandidate } from '@src/interfaces/CRM'
import { useGetSelectors } from '@src/api/selectors'
import { useGetCandidateSettings } from '@src/api/settings'
import SendEngagementEmail from '@src/features/CRM/SendEngagementEmail'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { renderOptionFilterSidebarItem } from '@src/components/JobPostingOption/JobPostingOption'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RequiresFeatureFlagWrapper } from '@src/components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FeatureFlags } from '@src/store/auth/types'
import { ConsiderForAJob } from '@src/features/CRM/ConsiderForAJob'
import { CRMReviewStats, CRMStats } from '@src/features/CRM/CRMStats'
import { useSidebar } from '@src/hooks/useSidebar'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const useRow = (specialisationsEnabled: boolean) => {
  const { data: archivedReasonsOptions } = useGetSelectors(
    selectorKeys.interview_round_archived_reasons,
  )
  const row: RowInterface<CRMInterface> = {
    cells: [
      getSelectCellConfig(),
      { ...crmNameColumn, width: 250 },
      { ...crmSpecialisationColumn(specialisationsEnabled), width: 250 },
      { ...crmTagsColumn, width: 180 },
      { ...crmCurrentCompanyColumn, width: 150 },
      { ...crmCurrentCountryColumn, width: 150 },
      { ...crmArchivalDateColumn, width: 120 },
      { ...crmLastEngagementDateColumn, width: 140 },
      { ...crmReEngagementStatusColumn, width: 140 },
      { ...crmSeniorityColumn, width: 100 },
      { ...crmYearsOfExperienceColumn, width: 120 },
      { ...crmPreferredLocationsColumn, width: 150 },
      { ...crmStatusColumn, width: 120 },
      {
        ...crmArchivalReasonColumn,
        width: 120,
        insert: ({ data }) => {
          const archivedReason = archivedReasonsOptions?.find(
            ({ id }) => id === data.archived_reason,
          )
          return archivedReason ? archivedReason.name : '-'
        },
      },
      { ...crmDesiredSalaryColumn, width: 140 },
      { ...crmReEngagementDateColumn, width: 140 },
    ],
  }
  return row
}

const sidebarFilters: FiltersSidebarItemInterface[] = [
  {
    label: 'Specialisation',
    field: 'specialisation',
    filterType: FilterType.selector,
    selector: selectorKeys.specialisations,
  },
  {
    label: 'Job posting',
    field: 'application__job_posting',
    filterType: FilterType.selector,
    selector: selectorKeys.job_postings_locations_type,
    renderOption: renderOptionFilterSidebarItem,
  },
  {
    label: 'Archival reason',
    field: 'archived_reason',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_round_archived_reasons,
  },
  {
    label: 'Archival date',
    field: 'archived_date_time',
    filterType: FilterType.date,
  },
  {
    label: 'Last active stage',
    field: 'latest_interview_stage__interview_type',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_stage_types,
  },
  {
    label: 'Origin',
    field: 'origin',
    filterType: FilterType.selector,
    selector: selectorKeys.candidate_origin_choices,
  },
  {
    label: 'Tags',
    field: 'candidate__tags',
    filterType: FilterType.selector,
    selector: selectorKeys.candidate_tags,
  },
  {
    label: 'Right to Work',
    field: 'candidate__right_to_work',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_feedback_right_to_work_choices,
  },
  {
    label: 'Ready to relocate',
    field: 'candidate__is_ready_to_relocate',
    filterType: FilterType.boolean,
  },
  {
    label: 'Eligibility to relocate',
    field: 'candidate__is_eligible_to_relocate',
    filterType: FilterType.boolean,
  },
  // {
  //   label: 'Last activity date',
  //   field: 'latest_activity_date_time',
  //   filterType: FilterType.date,
  // },
  // {
  //   label: 'Confidential candidates only',
  //   field: 'candidate__is_confidential',
  //   filterType: FilterType.boolean,
  //   icon: 'Anonymous',
  // },
  // {
  //   label: 'Sourced by',
  //   field: 'source',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.employee,
  //   icon: 'LogoLinkedIn',
  // },
  // {
  //   label: 'Hiring manager',
  //   field: 'hiring_manager',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.employee,
  //   icon: 'Profile',
  // },
  // {
  //   label: 'Recruiter',
  //   field: 'recruiter',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.employee,
  //   icon: 'Profile',
  // },
  // {
  //   label: 'Desired compensation',
  //   field: 'converted_expected_salary',
  //   filterType: FilterType.range,
  //   icon: Cash,
  // },
  // {
  //   label: 'Current country',
  //   field: 'current_country',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.location,
  //   icon: LocationPin,
  // },
  // {
  //   label: 'Preferred work location',
  //   field: 'preferred_location',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.location,
  //   icon: LocationPin,
  // },
  // {
  //   label: 'Created date',
  //   field: 'created_date',
  //   filterType: FilterType.date,
  //   icon: TimeAndMoney,
  // },
  // {
  //   label: 'Interview round created date',
  //   field: 'interview_round_created_date',
  //   filterType: FilterType.date,
  //   icon: Time,
  // },
  // {
  //   label: 'Notice period',
  //   field: 'notice_period',
  //   filterType: FilterType.date,
  //   icon: Message,
  // },
]

type CRMSidebars = 'filters' | 'sendEmails' | 'considerForAJob'

const getSelectedIds = (
  tableData: CRMInterface[],
  selectedData?: SelectTableWrapperOnChangeData<CRMInterface>,
): CRMSelectedCandidate[] => {
  let rows = selectedData?.selectedRowsData ?? []
  if (selectedData?.isAllSelected) {
    rows = tableData.filter(o => !selectedData?.excludeListIds?.has(String(o.id)))
  }
  return rows.map(o => ({
    candidateId: o.candidate.id,
    roundId: o.id,
    stageId: o?.latest_interview_stage?.id,
  }))
}

type CRMTableProps = {
  filterBy?: FilterByInterface[]
  reviewId?: string
}

const CRMTable = ({ filterBy, reviewId }: CRMTableProps) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const table = useTable<CRMInterface>(crmTableRequests, filterBy)
  const row = useRow(specialisationsEnabled)
  const { sidebar, toggleSidebar, closeSidebar } = useSidebar<CRMSidebars>()
  const { data: candidateSettings } = useGetCandidateSettings()
  const [loadingReview, setLoadingReview] = useState(false)
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<CRMInterface>>()
  const disabledBulkActions =
    !selectedData?.selectedRowsData.length && !selectedData?.isAllSelected
  const selectedCandidates = useMemo(
    () => getSelectedIds(table.data, selectedData),
    [selectedData, table.data],
  )
  const showStatusPopup = useShowStatusPopup()

  const handleReview = async () => {
    if (!loadingReview) {
      setLoadingReview(true)
      try {
        const { bulkIds } = await getCRMIds(table, selectedData)
        const reviewRes = await createReview(table, bulkIds)
        const firstCandidateId = selectedCandidates[0]?.candidateId
        if (bulkIds.length && firstCandidateId) {
          navigateTo(
            pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
              id: firstCandidateId,
            }),
            {
              reviewId: reviewRes.data.id,
              interviewRoundId: bulkIds[0],
            },
          )
        }
      } catch (e) {
        showStatusPopup({
          status: 'error',
          title: 'Something went wrong',
          description: getStringMessageFromError(e),
        })
      } finally {
        setLoadingReview(false)
      }
    }
  }
  const isReviewSummary = !!reviewId
  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          {isReviewSummary ? (
            <CRMReviewStats
              filters={table.filterBy}
              reviewId={reviewId}
              onFilterChange={table.onFilterChange}
            />
          ) : (
            <CRMStats filters={table.filterBy} onFilterChange={table.onFilterChange} />
          )}
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <Bar>
            <Table.Search
              placeholder="Search by name, email, specialisation, current company"
              onFilter={table.onFilterChange}
              variant="compact"
            />
            <FilterButton
              useIcon="Filter"
              onClick={() => {
                toggleSidebar('filters')
              }}
              onClear={() => {
                table.resetFiltersAndSorting()
              }}
            >
              {chain('Filters', getAppliedFiltersCount(table.filterBy, sidebarFilters))}
            </FilterButton>
          </Bar>
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {candidateSettings?.enable_emails && (
              <MoreBar.Action
                onClick={() => {
                  toggleSidebar('sendEmails')
                }}
                disabled={disabledBulkActions}
                useIcon="Envelope"
              >
                Send engagement emails
              </MoreBar.Action>
            )}
            <RequiresFeatureFlagWrapper flags={[FeatureFlags.ImprovedCRM]}>
              {!isReviewSummary && (
                <MoreBar.Action
                  disabled={disabledBulkActions}
                  useIcon="Switches"
                  pending={loadingReview}
                  onClick={() => {
                    handleReview()
                  }}
                >
                  Review
                </MoreBar.Action>
              )}
              <MoreBar.Action
                disabled={disabledBulkActions}
                useIcon="Newspaper"
                onClick={() => {
                  toggleSidebar('considerForAJob')
                }}
              >
                Consider for a job
              </MoreBar.Action>
            </RequiresFeatureFlagWrapper>
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            tableData={table.data}
          >
            <AdjustableTable<CRMInterface>
              name={TableNames.CRM}
              useWindowScroll
              dataType="records"
              row={row}
              noDataMessage="Records will appear here."
              tableSettings={{
                visible: [],
                hidden: [
                  crmSeniorityColumn.title,
                  crmYearsOfExperienceColumn.title,
                  crmPreferredLocationsColumn.title,
                  crmStatusColumn.title,
                  crmArchivalReasonColumn.title,
                  crmDesiredSalaryColumn.title,
                  crmReEngagementDateColumn.title,
                ],
              }}
              {...table}
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>

      <FiltersSidebar
        useLayout
        open={sidebar === 'filters'}
        onClose={closeSidebar}
        items={sidebarFilters}
        filters={table.filterBy}
        onFilter={table.onFilterChange}
      />
      {candidateSettings?.enable_emails && (
        <SendEngagementEmail
          candidates={selectedCandidates}
          open={sidebar === 'sendEmails'}
          onClose={closeSidebar}
        />
      )}
      {sidebar === 'considerForAJob' && (
        <ConsiderForAJob
          candidates={selectedCandidates}
          onClose={() => {
            closeSidebar()
          }}
          onRefresh={() => {
            table.refresh()
          }}
        />
      )}
    </>
  )
}

export default CRMTable
