import {
  DeliverableOptions,
  SkillSummary,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { KpiRowInterface } from '../SummaryReviewCollapsableTables'
import { getRatingWithTooltip } from './getRatingWithTooltip'

export const getRatingSummaryColumnValue = (
  data: SkillSummary | SummarySkillCardInterface | KpiRowInterface,
  expectedLevel: DeliverableOptions | null,
) => {
  return getRatingWithTooltip(
    false,
    data.rating || undefined,
    data.ratings,
    undefined,
    expectedLevel,
  )
}
