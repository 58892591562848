import React from 'react'
import pluralize from 'pluralize'
import { format } from 'date-fns'
import { Tag, Text, Token } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  PayGroupListItemInterface,
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PAYROLL_ISSUES_LEVEL_NONE,
  PAYROLL_ISSUES_LEVEL_WARNING,
  PayrollStatusId,
} from '@src/interfaces/payrollV2'
import Table from '@src/components/TableV2/Table'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

export const payGroupNameColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.active_pay_groups,
  title: 'Paygroup',
}

export const payGroupHeadcountColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
}

export const payGroupPayRunColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_pay_cycle.start_date',
  dataPoint: 'current_pay_cycle.start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Current payrun',
  insert: ({ data }) => {
    if (!data.current_pay_cycle?.start_date || !data.current_pay_cycle?.end_date) {
      return '-'
    }
    const formattedFrom = format(new Date(data.current_pay_cycle.start_date), 'd MMM')
    const formattedTo = format(new Date(data.current_pay_cycle.end_date), 'd MMM')

    return (
      <Tag>
        {formattedFrom} - {formattedTo}
      </Tag>
    )
  },
}

export const payGroupPayrollProviderColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'payroll_provider.id',
  dataPoint: 'payroll_provider.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Payroll provider',
}

export const payGroupChangesNumColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_changes',
  dataPoint: 'total_changes',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Changes',
  insert: ({ data }) => (
    <Text>
      {data.total_changes ? pluralize('change', data.total_changes || 0, true) : 'None'}
    </Text>
  ),
}

export const payGroupIssuesNumColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_changes',
  dataPoint: 'total_changes',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Issues',
  insert: ({ data }) => {
    const issueLevelToColor = {
      [PAYROLL_ISSUES_LEVEL_NONE]: Token.color.foreground,
      [PAYROLL_ISSUES_LEVEL_WARNING]: Token.color.warning,
      [PAYROLL_ISSUES_LEVEL_CRITICAL]: Token.color.danger,
    }
    return (
      <Text color={issueLevelToColor[data.issues_level]}>
        {data.total_changes ? pluralize('issue', data.total_issues || 0, true) : 'None'}
      </Text>
    )
  },
}

export const payGroupStatusColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => {
    const statusToVariant: Record<PayrollStatusId, ColorTagVariant> = {
      active: 'success',
      processed: 'default',
      approved: 'success',
      with_issues: 'warning',
    }
    return (
      <Table.StatusCell variant={statusToVariant[data.status]} status={data.status} />
    )
  },
}
