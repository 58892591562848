import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ResultAnswersTable } from './AnswersTable'
import { ResultCategoriesTable } from './CategoriesTable'
import { allSurveyResultsBaseRoutes, ResultsInterface } from './common'
import { Heatmap } from './Heatmap'
import { ResultQuestionsTable } from './QuestionsTable'
import { ROUTES } from '@src/constants/routes'

export const TableContent = (props: ResultsInterface) => {
  return (
    <Switch>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
          ...allSurveyResultsBaseRoutes.map(route => route.CATEGORIES),
        ]}
      >
        {props.viewMode === 'table' && <ResultCategoriesTable {...props} />}
        {props.viewMode === 'heatmap' && <Heatmap {...props} />}
      </Route>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.CATEGORY,
          ...allSurveyResultsBaseRoutes.map(route => route.QUESTIONS),
        ]}
      >
        {props.viewMode === 'table' && <ResultQuestionsTable {...props} />}
        {props.viewMode === 'heatmap' && <Heatmap {...props} />}
      </Route>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
          ...allSurveyResultsBaseRoutes.map(route => route.ANSWERS),
        ]}
      >
        {props.viewMode === 'table' && <ResultAnswersTable {...props} />}
        {props.viewMode === 'heatmap' && <Heatmap {...props} />}
      </Route>
    </Switch>
  )
}
