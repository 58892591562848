import React from 'react'
import { chain, Checkbox, Item, Tag, Text, Token, VStack } from '@revolut/ui-kit'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'

interface RequisitionItemProps {
  requisition: RequisitionInterface
  selected: boolean
  onClick: (id: number | string) => void
}

export const RequisitionItem = ({
  requisition,
  selected,
  onClick,
}: RequisitionItemProps) => {
  const seniorityRange =
    requisition.seniority_min?.id !== requisition.seniority_max?.id
      ? `${requisition.seniority_min?.name} - ${requisition.seniority_max?.name}`
      : requisition.seniority_min?.name ?? ''
  return (
    <Item
      style={{ backgroundColor: 'transparent' }}
      use="label"
      onClick={e => {
        e.preventDefault()
        onClick(requisition.id)
      }}
    >
      <Item.Prefix>
        <Checkbox checked={selected} />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>
          {chain(
            <Text color={Token.color.greyTone50}>{requisition.id}</Text>,
            <Text>{requisition.requisition_title}</Text>,
          )}
        </Item.Title>
        <Item.Description>
          <VStack>
            {requisition.team?.name && <Text>Team: {requisition.team?.name}</Text>}
            {seniorityRange && <Text> Seniority: {seniorityRange}</Text>}
          </VStack>
        </Item.Description>
        <Item.Actions>
          <OpenInNewTab
            color={Token.color.accent}
            iconPosition="end"
            label="View details"
            link={pathToFullUrlWithActiveWorkspace(ROUTES.FORMS.REQUISITION.ROLE, {
              id: requisition.id,
            })}
            onClick={e => {
              e.stopPropagation()
            }}
          />
        </Item.Actions>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Tag
            bg={Token.color.greyTone8}
            color={Token.color.greyTone50}
            useIcon="AddContact"
            variant="status"
          >
            {requisition.headcount}
          </Tag>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
