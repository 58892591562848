import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Action,
  Banner,
  Box,
  Button,
  Cell,
  Color,
  Flex,
  Group,
  Icon,
  InputGroup,
  Link,
  MoreBar,
  Portal,
  StatusPopup,
  Text,
  TextWidget,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import {
  answerOptions,
  PromotionCommonProps,
} from '@src/pages/Forms/PromotionNominationForm/common/common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  addPromotionComment,
  getPromotionComments,
  getPromotionNominationRequestsNew,
  useGetPromotionNomination,
  useGetPromotionProgressionTrack,
  useGetPromotionStages,
} from '@src/api/promotions'
import {
  PromotionNominationInterface,
  RecommendationDataInterface,
} from '@src/interfaces/promotions'
import SettingsButtons from '@src/features/SettingsButtons'
import Help from '@src/pages/Forms/PromotionNominationForm/common/Help'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { EmployeeInterface } from '@src/interfaces/employees'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetSpecialisationSeniorities } from '@src/api/specialisations'
import isNil from 'lodash/isNil'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  PROMOTION_MANAGERS_GUIDELINES,
  PROMOTION_PROCESS,
} from '@src/constants/externalLinks'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { KeeperGrade } from '@src/interfaces/performance'
import { useParams } from 'react-router-dom'
import { IdAndName, Statuses } from '@src/interfaces'
import JustificationSidebar from '@src/pages/Forms/PromotionNominationForm/common/JustificationSidebar'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetOrganisationSettings } from '@src/api/settings'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { AnytimeFeedbackSidebarStandalone } from '@components/AnytimeFeedback/AnytimeFeedbackSidebarStandalone'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import PerformanceDataSidebar from './PerformanceDataSidebar'
import { selectorKeys } from '@src/constants/api'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { EligibilityCriteria } from './common/EligibilityCriteria'
import { EvidenceDescription } from './common/EvidenceDescription'
import { InternalLink } from '@components/InternalLink/InternalLink'
import Form from '@src/features/Form/Form'
import PopupWithComment from '@src/pages/Forms/PromotionNominationForm/common/PopupWithComment'
import { goBack } from '@src/actions/RouterActions'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import NominationStatusBanner from '@src/pages/Forms/PromotionNominationForm/common/NominationStatusBanner'
import { pushNotification } from '@src/store/notifications/actions'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import {
  PerformanceContext,
  PerformanceContextValue,
} from './common/EligibilityAndPerformance/PerformanceContext'
import {
  GrowthPlansQueryKeys,
  useGetGrowthPlansForNomination,
} from '@src/api/growthPlans'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { PreviewGrowthPlan } from '@src/pages/Forms/PromotionNominationForm/PreviewGrowthPlan'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getRoleSpecialisationTitle } from '@src/features/Roles/helpers'

// Senior VP and Senior Director are soon to be deprecated
const notAllowedSeniorityIds = [17, 18]

enum SidebarType {
  Guidelines = 'Guidelines',
  Justification = 'justification',
  PerformanceData = 'performanceData',
  Feedback = 'feedback',
}

const PromotionNominationForm = ({
  employee,
  nominationId,
  editMode,
}: PromotionCommonProps) => {
  const { lastPublishedCycle } = useContext<PerformanceContextValue>(PerformanceContext)
  const params = useParams<{ employeeId?: string; id?: string }>()
  const { data: nomination } = useGetPromotionNomination(employee.id, nominationId)
  const [openSidebar, setOpenSidebar] = useState<SidebarType | null>(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)
  const [pending, setPending] = useState(false)
  const closeSideBar = useCallback(() => {
    setOpenSidebar(null)
  }, [])
  const { values, initialValues, submit, disabled, dirty } =
    useLapeContext<PromotionNominationInterface>()
  const { data: growthPlans, isLoading: isGrowthPlanLoading } =
    useGetGrowthPlansForNomination(
      {
        [GrowthPlansQueryKeys.TargetSpecialisationId]: values.target_specialisation
          ? Number(values.target_specialisation.id)
          : undefined,
        [GrowthPlansQueryKeys.TargetSeniorityId]: values.target_seniority?.id,
      },
      !params.id,
    )
  const growthPlanForNomination = growthPlans?.results[0] ?? null
  const attachedGrowthPlan = params.id ? values.growth_plan : null
  const growthPlanForPreview = attachedGrowthPlan ?? growthPlanForNomination

  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const { data: settings } = useGetOrganisationSettings()
  const multipleSenioritiesEnabled = !!settings?.enable_multiple_levels_per_seniority

  const seniorities = useGetSpecialisationSeniorities(
    values.target_specialisation?.id || null,
  )

  const { data: stagesList } = useGetPromotionStages(nomination?.id)
  const {
    data: progression,
    error: progressionTrackFailure,
    isLoading: isLoadingProgressionTrack,
  } = useGetPromotionProgressionTrack(employee.id)

  const showEligibilityFailurePlaceholder =
    !!progressionTrackFailure ||
    (!progression?.progression_track && !isLoadingProgressionTrack)

  useEffect(() => {
    if (!values.progression_track && progression?.progression_track) {
      values.progression_track = progression.progression_track
    }
  }, [progression?.progression_track])

  useEffect(() => {
    if (!values.employee) {
      values.employee = { id: employee.id, name: employee.full_name }
    }

    if (!values.target_specialisation) {
      values.target_specialisation = employee.specialisation
      values.is_role_change_promotion = false
    }

    if (!values.recommendation_data) {
      // needed for correct validation on the BE in case inner fields are empty
      values.recommendation_data = {} as RecommendationDataInterface
    }
  }, [])

  const roleHasChanged = employee.specialisation?.id !== values.target_specialisation?.id

  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const currentStage = nomination?.current_stages?.[nomination?.current_stages.length - 1]
  const currentStageInd =
    stagesList?.results.findIndex(el => el.stage_type.id === currentStage?.id) || -1
  const performanceCommitteeStageInd = 4

  const canEditForm =
    permissions.includes(PermissionTypes.PromotionsManagerPermissions) ||
    (nomination?.nominated_by.id === user.id &&
      nomination?.status === Statuses.in_review &&
      currentStageInd > -1 &&
      currentStageInd < performanceCommitteeStageInd)

  const filteredSeniorityOptions = useMemo(() => {
    if (!seniorities.data) {
      return []
    }
    const seniorityOptions = seniorities.data.reduce((result, sen) => {
      if (!notAllowedSeniorityIds.includes(sen.id)) {
        result.push({
          value: sen,
          label: sen.name,
        })
      }
      return result
    }, [] as RadioSelectOption<SeniorityInterface>[])

    if (!employee.seniority || roleHasChanged) {
      return seniorityOptions
    }
    return seniorityOptions.filter(opt => opt.value.level > employee.seniority!.level)
  }, [seniorities.data, roleHasChanged])

  const backUrl = pathToUrl(
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
    {
      id: employee.id,
    },
  )

  // Determine and set default value for target seniority
  useEffect(() => {
    if (values.id) {
      if (!values.target_seniority) {
        values.target_seniority = values.initial_seniority
      }
      if (!values.target_specialisation) {
        values.target_specialisation = values.initial_specialisation
      }
      return
    }
    const selectedSeniority = values.target_seniority || initialValues.target_seniority
    const selectedLevel = selectedSeniority?.level

    if (filteredSeniorityOptions.length < 1) {
      if (selectedLevel) {
        values.target_seniority = null
      }
      return
    }
    const minSeniority = filteredSeniorityOptions[0].value
    const maxSeniority =
      filteredSeniorityOptions[filteredSeniorityOptions.length - 1].value

    if (!isNil(selectedLevel) && selectedLevel >= minSeniority.level) {
      if (selectedLevel > maxSeniority.level) {
        values.target_seniority = maxSeniority
      } else if (selectedSeniority) {
        values.target_seniority = selectedSeniority
      }
      return
    }
    // they are filtered to be higher than the current seniority so the first one is +1 level
    values.target_seniority = filteredSeniorityOptions[0].value
  }, [filteredSeniorityOptions])

  const commentsApi = useMemo(() => {
    return nominationId ? getPromotionComments(nominationId) : null
  }, [nominationId])

  const onSubmit = async (comment: string) => {
    setPending(true)
    try {
      if (values.is_role_change_promotion) {
        await submit()
      } else {
        // if we don't change role we have to send null to the BE
        const fallbackSpecialisation = values.target_specialisation
        values.target_specialisation = null
        await submit().catch(error => {
          values.target_specialisation = fallbackSpecialisation
          throw error
        })
      }
      if (comment.length && nominationId) {
        await addPromotionComment(nominationId, comment)
      }
      setIsSuccessPopupOpen(true)
    } catch (e) {
      pushNotification({
        value: e.response?.data || 'Failed to save, try again later',
        duration: ERROR_DEFAULT_DURATION,
        type: NotificationTypes.error,
      })
    } finally {
      setPending(false)
      setIsPopupOpen(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Promotion Nomination"
        backUrl={backUrl}
        routerAction={
          initialValues.id
            ? PageHeaderRouterAction.goBack
            : PageHeaderRouterAction.navigate
        }
        subtitle={
          <>
            <Text color="foreground">
              <UserWithAvatar {...employee} status={employee.status.id} />
            </Text>
            <Text mt="s-4" variant="caption" use="p">
              Promotion request is subject to approval from the relevant parties.{' '}
              <HideIfCommercial>
                More information on the process{' '}
                <Link target="_blank" href={PROMOTION_PROCESS}>
                  here
                </Link>
                .
              </HideIfCommercial>
            </Text>
          </>
        }
      />
      <PageBody>
        {editMode && (
          <SettingsButtons mb="s-24">
            <MoreBar.Action
              useIcon="Chat"
              onClick={() => setOpenSidebar(SidebarType.Feedback)}
            >
              Show feedbacks
            </MoreBar.Action>
            {lastPublishedCycle && (
              <MoreBar.Action
                useIcon="BarChart"
                onClick={() => setOpenSidebar(SidebarType.PerformanceData)}
              >
                View performance data
              </MoreBar.Action>
            )}
            <MoreBar.Action
              useIcon="Document"
              onClick={() => setOpenSidebar(SidebarType.Justification)}
            >
              View justification example
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="InfoOutline"
              onClick={() => setOpenSidebar(SidebarType.Guidelines)}
            >
              View nomination guidelines
            </MoreBar.Action>
          </SettingsButtons>
        )}
        {!editMode && canEditForm && (
          <SettingsButtons mb="s-24">
            <MoreBar.Action
              use={InternalLink}
              useIcon="Pencil"
              to={pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM, params)}
            >
              Edit
            </MoreBar.Action>
          </SettingsButtons>
        )}
        {!editMode && nomination && <NominationStatusBanner nomination={nomination} />}
        {multipleSenioritiesEnabled && editMode && (
          <VStack space="s-16">
            <TextWidget>
              <TextWidget.Title>Our promotion philosophy</TextWidget.Title>
              <TextWidget.Summary color={Color.GREY_TONE_50}>
                Revolut aims to create a high-performance culture with sustainable growth,
                built on achieving...
              </TextWidget.Summary>
              <TextWidget.Content>
                Revolut aims to create a high-performance culture with sustainable growth,
                built on achieving business objectives and meeting customer needs. Those
                who excel can be promoted the following ways:
                <br /> <br />
                1. Progression between seniority levels happens automatically and is based
                on the tenure and performance results of an employee. You do not need to
                nominate for this.
                <br />
                2. Promotion of high-performing employees at seniority level III, based on
                nominations assessed against the eligibility criteria.
                <br />
                3. Fast-track promotions of our most talented employees at seniority
                levels I or II, who are producing excellent results on a consistent basis,
                and already meet the eligibility criteria.
                <br />
                <br />
                Read more on our{' '}
                <Link href={PROMOTION_MANAGERS_GUIDELINES} target="_blank">
                  guidelines page for managers.
                </Link>
              </TextWidget.Content>
            </TextWidget>
          </VStack>
        )}
        <AutoStepper mt={editMode ? 's-16' : '0'}>
          <NewStepperTitle
            title="Position details"
            subtitle="Current position and proposed changes"
          />

          <FormPreview<EmployeeInterface> data={employee}>
            <Group>
              <FormPreview.Item<EmployeeInterface>
                title={getRoleSpecialisationTitle(specialisationsEnabled)}
                field="specialisation.name"
              />
              <FormPreview.Item<EmployeeInterface>
                title="Seniority (Level)"
                field="specialisation_seniority_sublevel.name"
                hidden={!multipleSenioritiesEnabled}
              />
            </Group>
          </FormPreview>
          <InputGroup>
            <VStack space="s-16" mt="s-16">
              <LapeRadioSelectInput
                name="target_specialisation"
                label="Proposed specialisation"
                selector={selectorKeys.specialisations}
                hasError={seniorities.data && filteredSeniorityOptions.length === 0}
                message={
                  seniorities.data && filteredSeniorityOptions.length === 0
                    ? `Specialisation ${
                        values.target_specialisation?.name
                      } doesn't have seniority higher than ${
                        employee.seniority?.name || 'current'
                      }. Please select a different specialisation.`
                    : undefined
                }
                inputProps={{
                  renderAction: () => {
                    if (
                      employee.specialisation &&
                      values.target_specialisation?.id === employee.specialisation.id
                    ) {
                      return <Text pr="s-4">(No change)</Text>
                    }
                    return null
                  },
                }}
                onChange={option => {
                  if (option) {
                    values.target_specialisation = option
                    values.is_role_change_promotion =
                      employee.specialisation?.id !== values.target_specialisation?.id
                  }
                }}
                value={values?.target_specialisation || nomination?.target_specialisation}
                disabled={!editMode}
              />
              <LapeRadioSelectInput
                name="target_seniority"
                label="Proposed seniority"
                options={filteredSeniorityOptions}
                loading={!seniorities.data}
                labelEmptyState="No higher seniorities for selected specialisation."
                disabled={!editMode}
              />
            </VStack>
          </InputGroup>

          {growthPlanForPreview && (
            <>
              <NewStepperTitle
                title="Growth plan"
                subtitle="The employee had a growth plan in place"
              />
              <PreviewGrowthPlan employee={employee} growthPlan={growthPlanForPreview} />
            </>
          )}

          <NewStepperTitle
            title="Promotion criteria"
            subtitle="The eligibility and evidence for this promotion nomination"
          />
          {values.progression_track && (
            <Cell>
              <EligibilityCriteria
                employeeId={employee.id}
                targetSeniority={values.target_seniority!}
                targetSpecialisation={values.target_specialisation!}
                nominationId={nominationId!}
                employee={employee}
                showSubtitle
                editMode={editMode}
                nomination={nomination}
                progressionTrack={values.progression_track}
              />
            </Cell>
          )}
          {showEligibilityFailurePlaceholder && (
            <Banner>
              <Banner.Avatar>
                <Icon name="ExclamationMark" size={36} color={Token.color.danger} />
              </Banner.Avatar>
              <Banner.Content>
                <Banner.Title>Something went wrong.</Banner.Title>
                <Banner.Description>
                  Failed to load the eligibility criteria. This could be due to a network
                  issue, or a missing seniority level in the nominee's profile. Please
                  check that a seniority level is assigned to this user and{' '}
                  <Action
                    style={{ display: 'inline-block' }}
                    aria-label="Reload Page"
                    onClick={() => window.location.reload()}
                  >
                    relaod the page.
                  </Action>
                </Banner.Description>
              </Banner.Content>
            </Banner>
          )}
          {editMode && (
            <>
              <NewStepperTitle
                title="Recommendations"
                subtitle="Will this employee be a strong performer in the proposed position?"
              />
              <Cell display="block">
                <VStack space="s-16">
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      A. This employee exemplifies our core values at all times and acts
                      as a culture champion within their <u>current</u> peer group (the
                      same seniority and function)?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<KeeperGrade>>
                      name="recommendation_data.is_culture_champion"
                      options={answerOptions}
                      label="Answer"
                      required
                      value={
                        values.recommendation_data?.is_culture_champion ||
                        nomination?.recommendation_data?.is_culture_champion
                      }
                      disabled={!editMode}
                    />
                  </Box>
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      B. This employee has demonstrated skills and capabilities exceeding
                      their <u>current</u> peer group (the same seniority and function)?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<KeeperGrade>>
                      name="recommendation_data.are_skills_exceeding_current_position"
                      options={answerOptions}
                      label="Answer"
                      required
                      value={
                        values.recommendation_data
                          ?.are_skills_exceeding_current_position ||
                        nomination?.recommendation_data
                          ?.are_skills_exceeding_current_position
                      }
                      disabled={!editMode}
                    />
                  </Box>
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      C. This employee has demonstrated skills and capabilities exceeding
                      their <u>future</u> peer group (proposed new seniority and
                      function)?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<KeeperGrade>>
                      name="recommendation_data.are_skills_exceeding_future_position"
                      options={answerOptions}
                      label="Answer"
                      required
                      value={
                        values.recommendation_data
                          ?.are_skills_exceeding_future_position ||
                        nomination?.recommendation_data
                          ?.are_skills_exceeding_future_position
                      }
                      disabled={!editMode}
                    />
                  </Box>
                </VStack>
              </Cell>
            </>
          )}
        </AutoStepper>

        {!editMode && nomination && (
          <VStack space="s-16" mt="s-24">
            <Text use="h6" fontWeight={500} color={Color.GREY_TONE_50}>
              Justification and evidence cases
            </Text>
            <Cell my="s-16">
              <VStack space="s-32">
                <VStack space="s-8">
                  <Text variant="primary">Why should this employee be reviewed now?</Text>
                  <Text variant="caption">
                    {nomination.business_requirements_justification}
                  </Text>
                </VStack>
                {nomination.justification.map((item, i) => (
                  <VStack space="s-8" key={i}>
                    <Text variant="primary">Evidence case {i + 1}</Text>
                    <Text variant="secondary" whiteSpace="break-spaces">
                      <EvidenceDescription text={item} />
                    </Text>
                  </VStack>
                ))}
              </VStack>
            </Cell>

            <Text variant="h6" fontWeight={500} color={Color.GREY_TONE_50}>
              Manager recommendations
            </Text>

            <Cell>
              <VStack space="s-24">
                <Flex justifyContent="space-between">
                  <Text variant="caption" color="grey-tone-50">
                    A. This employee exemplifies our core values at all times and acts as
                    a culture champion within their current peer group (the same seniority
                    and function)?
                  </Text>
                  <Text variant="caption" width="20%" textAlign="end">
                    {nomination.recommendation_data.is_culture_champion?.name}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text variant="caption" color="grey-tone-50">
                    B. This employee has demonstrated skills and capabilities exceeding
                    their current peer group (the same seniority and function)?
                  </Text>
                  <Text variant="caption" width="20%" textAlign="end">
                    {
                      nomination.recommendation_data.are_skills_exceeding_current_position
                        ?.name
                    }
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text variant="caption" color="grey-tone-50">
                    C. This employee has demonstrated skills and capabilities exceeding
                    their future peer group (proposed new seniority and function)?
                  </Text>
                  <Text variant="caption" width="20%" textAlign="end">
                    {
                      nomination.recommendation_data.are_skills_exceeding_future_position
                        ?.name
                    }
                  </Text>
                </Flex>
              </VStack>
            </Cell>
            {nominationId && commentsApi && (
              <Box>
                <CommentsSection api={commentsApi} />
              </Box>
            )}
          </VStack>
        )}
      </PageBody>
      <PageActions>
        {!params.id ? (
          <NewSaveButtonWithPopup
            useValidator
            successText="Nomination request created"
            afterSubmitUrl={pathToUrl(
              ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
              {
                id: employee.id,
              },
            )}
            onClick={() => {
              if (growthPlanForNomination) {
                values.growth_plan = {
                  id: growthPlanForNomination.id,
                } as GrowthPlanInterface
              }

              if (values.is_role_change_promotion) {
                return submit()
              }
              // if we don't change role we have to send null to the BE
              const fallbackSpecialisation = values.target_specialisation
              values.target_specialisation = null
              return submit().catch(error => {
                values.target_specialisation = fallbackSpecialisation
                throw error
              })
            }}
            pending={isGrowthPlanLoading}
            disabled={!!showEligibilityFailurePlaceholder}
          />
        ) : (
          <>
            {dirty && !disabled && editMode && (
              // after nomination is created, when editing we need to request a note
              // in additional popup before sending a request, and then show status popup as usually
              <Button
                elevated
                onClick={() => {
                  setIsPopupOpen(true)
                  return Promise.reject()
                }}
                pending={pending}
              >
                Save Changes
              </Button>
            )}
          </>
        )}
      </PageActions>
      {params.id && (
        <>
          <StatusPopup
            variant="success"
            open={isSuccessPopupOpen}
            preventUserClose
            onClose={() => {
              setIsSuccessPopupOpen(false)
              goBack(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
                  id: employee.id,
                }),
              )
            }}
          >
            <StatusPopup.Title>Nomination updated</StatusPopup.Title>
          </StatusPopup>
          <PopupWithComment
            title="Edit nomination"
            subtitle="Please comment on the reason for this action. This will be added to the nomination comments."
            isOpen={isPopupOpen}
            onSubmit={onSubmit}
            onClose={() => setIsPopupOpen(false)}
            isSubmitting={pending}
          />
        </>
      )}
      <Portal>
        <Help isOpen={openSidebar === SidebarType.Guidelines} onClose={closeSideBar} />
        <JustificationSidebar
          isOpen={openSidebar === SidebarType.Justification}
          onClose={closeSideBar}
        />
        <AnytimeFeedbackSidebarStandalone
          userId={employee.id}
          isOpen={openSidebar === SidebarType.Feedback}
          onClose={closeSideBar}
          nominationId={nomination?.id}
        />
        {lastPublishedCycle && nominationId && (
          <PerformanceDataSidebar
            lastPublishedCycle={lastPublishedCycle}
            nominationId={nominationId}
            employee={employee}
            onClose={closeSideBar}
            isOpen={openSidebar === SidebarType.PerformanceData}
          />
        )}
      </Portal>
    </PageWrapper>
  )
}

export default (props: PromotionCommonProps) => {
  const params = useParams<{ id?: string }>()

  return (
    <Form api={getPromotionNominationRequestsNew(props.employee.id)} disableDataCleanup>
      <PromotionNominationForm {...props} nominationId={params.id} />
    </Form>
  )
}
