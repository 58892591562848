import React, { useEffect, useState } from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { EmployeeProgressNavigation } from './EmployeeProgressNavigation'
import {
  fetchEntityGoalsGraph,
  goalsListTableRequests,
  GoalsStats,
  useGetOrganisationalUnit,
} from '@src/api/goals'
import { isNumber } from 'lodash'
import { FilterByInterface } from '@src/interfaces/data'
import { GoalCycleFilter } from './GoalCycleFilter'
import { selectorKeys } from '@src/constants/api'
import { CollapsableGoalTree } from './CollapsableGoalTree'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useParams } from 'react-router-dom'

interface Props {
  initialCycle?: ReviewCyclesInterface
  initialFilters: FilterByInterface[]
}
export const OrganisationalGoalsWidget = ({ initialCycle, initialFilters }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [personalGoalStats, setPersonalGoalStats] = useState<GoalsStats>()

  const { query, changeQueryParam } = useQuery<{ cycle__id: string }>()
  const { data } = useGetOrganisationalUnit({
    employeeId: id,
    cycleId: query.cycle__id,
  })

  useEffect(() => {
    const filters = !query.cycle__id
      ? initialFilters
      : initialFilters.map(filter =>
          filter.columnName === 'cycle__id'
            ? { ...filter, filters: [{ id: query.cycle__id, name: query.cycle__id }] }
            : filter,
        )
    goalsListTableRequests.getStats?.({ filters }).then(resp => {
      setPersonalGoalStats(resp.data)
    })
    if (initialCycle?.id && !query.cycle__id) {
      changeQueryParam('cycle__id', String(initialCycle.id))
    }
  }, [query.cycle__id])

  return (
    <VStack gap="s-16">
      <Widget p="s-16">
        <VStack gap="s-16">
          <EmployeeProgressNavigation
            showBarGraph
            warnAboutMandatoryGoal={
              isNumber(personalGoalStats?.mandatory_progress)
                ? Number(personalGoalStats?.mandatory_progress) < 1
                : false
            }
            value={personalGoalStats?.overall_progress}
            fetchData={fetchEntityGoalsGraph('employees')}
            id={Number(id)}
          />
          <GoalCycleFilter
            isFilterButtonUI
            allowEmployeeCycles={false}
            onFilterChange={filters => {
              if (filters.filters.at(0)?.id) {
                changeQueryParam('cycle__id', String(filters.filters.at(0)!.id))
              }
            }}
            columnName="cycle__id"
            selector={selectorKeys.review_cycles}
            filter={
              initialCycle
                ? [
                    {
                      columnName: 'cycle__id',
                      filters: query.cycle__id
                        ? [{ id: query.cycle__id, name: query.cycle__id }]
                        : [],
                    },
                  ]
                : []
            }
          />
        </VStack>
      </Widget>
      <CollapsableGoalTree units={data} cycleId={query.cycle__id} />
    </VStack>
  )
}
