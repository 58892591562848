import React, { useEffect } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Button,
  Item,
  Radio,
  RadioGroup,
  Subheader,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useCareersSettings } from '@src/api/recruitment/careers'
import {
  useGetOpenRequisitionsByJobPosting,
  useGetPendingRequisitionsByJobPosting,
} from '@src/api/requisitions'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useLocation, useParams } from 'react-router-dom'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobBoards } from '@src/features/JobPostingFlow/Publish/JobBoards'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { CareersWebsite } from '@src/features/JobPostingFlow/Publish/CareersWebsite'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { Approvals } from '@src/features/JobPostingFlow/Publish/Approvals'

export const Publish = () => {
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { values } = useLapeContext<JobPostingInterface>()
  const isCommercial = useIsCommercial()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { data: careerPageSettings } = useCareersSettings()
  const { data: openRequisitions, isLoading: loadingOpenRequisitions } =
    useGetOpenRequisitionsByJobPosting(values.id)
  const { data: pendingRequisitions, isLoading: loadingPendingRequisitions } =
    useGetPendingRequisitionsByJobPosting(values.id)

  useEffect(() => {
    if (!values.requested_publishing_status) {
      values.requested_publishing_status = PublishingStatuses.unpublished
    }
  }, [values.requested_publishing_status])

  if (loadingOpenRequisitions || loadingPendingRequisitions) {
    return <PageLoading />
  }

  const careersPageDisabled = isCommercial && !careerPageSettings?.publish_career_website
  const openRequisitionCount = openRequisitions?.count ?? 0
  const pendingRequisitionCount = pendingRequisitions?.count ?? 0
  const requisitionChecks = jobPostingSettings?.enable_requisition_publishing_checks
  const failsRequisitionChecks = requisitionChecks && !openRequisitionCount
  const disabled = careersPageDisabled || failsRequisitionChecks
  return (
    <>
      <TwoColumnsLayout
        left={
          <VStack gap="s-16">
            <Widget>
              <Box pl="s-16">
                <Subheader>
                  <Subheader.Title>Publishing Options</Subheader.Title>
                </Subheader>
              </Box>
              {careersPageDisabled && (
                <Item>
                  <Item.Avatar>
                    <Avatar color={Token.color.warning} useIcon="ExclamationTriangle" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Careers website is not published</Item.Title>
                    <Item.Description>
                      The application form is currently unavailable to candidates.
                      <br />
                      Please publish Careers website in the settings to start receiving
                      applications.
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <ActionButton
                      target="_blank"
                      to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE)}
                      use={InternalLink}
                      useIcon="Gear"
                    >
                      Settings
                    </ActionButton>
                  </Item.Side>
                </Item>
              )}
              {failsRequisitionChecks && (
                <Item>
                  <Item.Avatar>
                    <Avatar color={Token.color.warning} useIcon="ExclamationTriangle" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>
                      {pendingRequisitionCount > 1
                        ? 'None of the connected requisitions are opened'
                        : pendingRequisitionCount === 1
                        ? 'Connected requisition is not opened'
                        : 'No requisitions are connected'}
                    </Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <ActionButton
                      use={InternalLink}
                      useIcon="Plus"
                      onClick={() => {
                        navigateTo(
                          pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS, params),
                          locationState,
                        )
                      }}
                    >
                      Manage requisitions
                    </ActionButton>
                  </Item.Side>
                </Item>
              )}

              <RadioGroup<PublishingStatuses>
                defaultValue={
                  values.requested_publishing_status || PublishingStatuses.unpublished
                }
                onChange={newStatus => {
                  if (newStatus) {
                    values.requested_publishing_status = newStatus
                  }
                }}
              >
                {group => (
                  <>
                    <Item use="label">
                      <Item.Prefix>
                        <Radio
                          {...group.getInputProps({
                            value: PublishingStatuses.unpublished,
                          })}
                        />
                      </Item.Prefix>
                      <Item.Avatar>
                        <Avatar useIcon="EyeHide" />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>Save as unpublished</Item.Title>
                        <Item.Description>
                          Posting will appear in the list of job postings on Revolut
                          People in an unpublished state. Candidates will only be able to
                          apply once published.
                        </Item.Description>
                      </Item.Content>
                    </Item>
                    {jobPostingSettings?.enable_all_publishing_statuses && (
                      <Item
                        use="label"
                        aria-disabled={disabled}
                        onClick={e => {
                          if (disabled) {
                            e.preventDefault()
                          }
                        }}
                      >
                        <Item.Prefix>
                          <Radio
                            disabled={!disabled}
                            {...group.getInputProps({
                              value: PublishingStatuses.published_internally,
                            })}
                          />
                        </Item.Prefix>
                        <Item.Avatar>
                          <Avatar useIcon="Bank" />
                        </Item.Avatar>
                        <Item.Content>
                          <Item.Title>Publish internally</Item.Title>
                          <Item.Description>
                            Posting will appear in the list of job postings on Revolut
                            People but will not be published to any public source.
                            Candidates can apply if you share a link to the application
                            form.
                          </Item.Description>
                        </Item.Content>
                      </Item>
                    )}
                    <Item
                      use="label"
                      aria-disabled={disabled}
                      onClick={e => {
                        if (disabled) {
                          e.preventDefault()
                        }
                      }}
                    >
                      <Item.Prefix>
                        <Radio
                          disabled={!disabled}
                          {...group.getInputProps({
                            value: PublishingStatuses.fully_published,
                          })}
                        />
                      </Item.Prefix>
                      <Item.Avatar>
                        <Avatar useIcon="Globe" />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>Publish internally and externally</Item.Title>
                        <Item.Description>
                          Publish on careers website and selected job boards
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </>
                )}
              </RadioGroup>
            </Widget>
            {values.requested_publishing_status ===
              PublishingStatuses.fully_published && (
              <>
                <CareersWebsite requisitions={openRequisitions?.results || []} />
                <JobBoards />
              </>
            )}
          </VStack>
        }
        leftMaxWidth={{ lg: Token.breakpoint.md, all: '100%' }}
        rightMaxWidth={{ lg: Token.breakpoint.sm, all: '100%' }}
        right={<Approvals />}
      />
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.HIRING_PROCESS, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          successText="Job posting saved successfully"
          onAfterSubmit={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                id: values.id,
                specId: values.specialisation?.id,
              }),
            )
          }}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
