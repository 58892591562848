import { createChain } from '@revolut/ui-kit'
import { relationToString } from '@components/ScorecardGeneral/constants'
import { ReviewerRelation, ReviewInterface } from '@src/interfaces/performance'
import { Warning } from '@components/WarningMessage/WarningMessage'
import SummaryCommentsPopup from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryCommentsPopup'
import pluralize from 'pluralize'
import React from 'react'

export interface JustificationInterface {
  value: string
  review: Pick<
    ReviewInterface,
    'reviewer_relation' | 'probation_checkpoint' | 'reviewer'
  > & { id?: number }
}
interface Props {
  justifications: JustificationInterface[]
}
export const BarRaiserSummaryJustification = ({ justifications }: Props) => {
  const messageChain = createChain(' ')
  const relationString = (relation: ReviewerRelation) =>
    `(${relationToString(relation, true)})`
  if (!justifications.length) {
    return null
  }
  return (
    <Warning multiline>
      {messageChain(
        justifications[0].review.reviewer.display_name,
        relationString(justifications[0].review.reviewer_relation),
        justifications[1] && 'and',
        justifications[1] && justifications[1].review.reviewer.display_name,
        justifications[1] && relationString(justifications[1].review.reviewer_relation),
        'added',
        pluralize('justification', justifications.length),
      )}
      <SummaryCommentsPopup
        data={justifications}
        title="Grade justification"
        subtitle=""
      />
    </Warning>
  )
}
