import React from 'react'
import pluralize from 'pluralize'
import {
  Color,
  ExpandableCell,
  HStack,
  Link,
  Text,
  VStack,
  DetailsCell,
} from '@revolut/ui-kit'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { CommunicationIcons } from './CommunicationIcons'
import Icon from '@src/components/Icon/Icon'
import { slackChannelNameToUrl } from './helpers'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

type Props = {
  jiraProjects?: string[] | null
  group?: CommunicationGroupInterface | null
  linkedGroups?: CommunicationGroupInterface[] | null
  jiraEnabled?: boolean
}

export const CommunicationDetails = ({
  jiraProjects,
  group,
  linkedGroups,
  jiraEnabled = true,
}: Props) => {
  return (
    <>
      <HideIfCommercial>
        {!!group?.slack_channel_name && (
          <ExpandableCell>
            <ExpandableCell.Title>Communication</ExpandableCell.Title>
            <ExpandableCell.Content>
              <CommunicationIcons group={group} />
            </ExpandableCell.Content>
            <ExpandableCell.Note>
              <Link
                target="_blank"
                href={slackChannelNameToUrl(group.slack_channel_name)}
              >
                <HStack space="s-8" align="center">
                  <Icon type="SlackMono" size="tiny" color={Color.BLUE} />
                  <Text color={Color.BLUE}>{group.slack_channel_name}</Text>
                </HStack>
              </Link>
            </ExpandableCell.Note>
          </ExpandableCell>
        )}
      </HideIfCommercial>
      {jiraEnabled &&
        (jiraProjects?.length ? (
          <ExpandableCell>
            <ExpandableCell.Title>JIRA Roadmap Projects</ExpandableCell.Title>
            <ExpandableCell.Content>
              {pluralize('key', jiraProjects.length, true)}
            </ExpandableCell.Content>
            <ExpandableCell.Note>
              <VStack space="s-8">
                {jiraProjects.map(project => (
                  <Text key={project}>{project}</Text>
                ))}
              </VStack>
            </ExpandableCell.Note>
          </ExpandableCell>
        ) : (
          <DetailsCell>
            <DetailsCell.Title>JIRA Roadmap Projects</DetailsCell.Title>
            <DetailsCell.Content>-</DetailsCell.Content>
          </DetailsCell>
        ))}
      {linkedGroups?.length ? (
        <ExpandableCell>
          <ExpandableCell.Title>Linked groups</ExpandableCell.Title>
          <ExpandableCell.Content>
            {pluralize('group', linkedGroups.length, true)}
          </ExpandableCell.Content>
          <ExpandableCell.Note>
            <VStack space="s-8">
              {linkedGroups.map(({ id, name }) => (
                <Text key={id}>{name}</Text>
              ))}
            </VStack>
          </ExpandableCell.Note>
        </ExpandableCell>
      ) : (
        <DetailsCell>
          <DetailsCell.Title>Linked groups</DetailsCell.Title>
          <DetailsCell.Content>-</DetailsCell.Content>
        </DetailsCell>
      )}
    </>
  )
}
