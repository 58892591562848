import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  ActionButton,
  Avatar,
  Flex,
  Subheader,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { SkillInterface } from '@src/interfaces/skills'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { formatDate } from '@src/utils/format'
import { ROUTES } from '@src/constants/routes'
import { EditorView } from '@components/Editor/EditorView'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'
import { SourceItem } from '@src/features/AiInsights/Performance/components/Sources/common/SourceItem'
import { PerformanceLayoutSubTabs } from '@src/pages/EmployeeProfile/Layout/Performance/LayoutTab'
import {
  getGrowthPlanDecisionColor,
  growthPlanDecisionToLabelMap,
} from '@src/features/GrowthPlans/GrowthPlan/components/constants'
import { growthPlanActionModel } from '@src/features/GrowthPlans/GrowthPlan/models/actionModel'
import { CompletedAction } from '@src/features/AiInsights/Performance/components/Metrics/Internal/GrowthPlans/Skills/MetricHighlights/CompletedAction'
import { MetricHighlight } from '@src/features/AiInsights/Performance/components/Metrics/Internal/GrowthPlans/Skills/MetricHighlights/MetricHighlight'

interface Props {
  growthPlan: GrowthPlanInterface
  skill: SkillInterface
  isLoading: boolean
}

export const GrowthPlanMetrics = ({ growthPlan, skill, isLoading }: Props) => {
  if (isLoading) {
    return <SourceSkeleton />
  }

  const growthPlanLink = pathToUrl(
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
    {
      id: growthPlan.employee.id,
      subtab: PerformanceLayoutSubTabs.Growth,
    },
  )

  const completedActions = growthPlan.actions.filter(
    action =>
      growthPlanActionModel.isCompleted(action) &&
      growthPlanActionModel.hasAssociatedSkill(action, skill.id),
  )
  const hasDetails = !!growthPlan.description || completedActions.length > 0

  return (
    <VStack space="s-16" data-testid="ai_insights_skill_performance_growth_plan_metrics">
      <SourceItem
        title={
          <FormattedMessage
            id="performance.labels.growth.plan"
            defaultMessage="Growth Plan"
          />
        }
        avatar={<Avatar useIcon="Flag" />}
        side={
          <ActionButton
            to={growthPlanLink}
            target="_blank"
            use={InternalLink}
            useIcon="LinkExternal"
          >
            <FormattedMessage
              id="performance.skills.insights.review.growth.plan.cta.label"
              defaultMessage="See plan"
            />
          </ActionButton>
        }
      />
      <Flex gap="s-8">
        <Widget flex={1}>
          <MetricHighlight
            label={
              <FormattedMessage
                id="performance.skills.insights.growth.plan.timeline.label"
                defaultMessage="Timeline"
              />
            }
            value={formatDate(growthPlan.deadline)}
          />
        </Widget>
        {growthPlan.decision && (
          <Widget flex={1}>
            <MetricHighlight
              label={
                <FormattedMessage id="common.labels.status" defaultMessage="Status" />
              }
              value={
                <Text color={getGrowthPlanDecisionColor(growthPlan)}>
                  {growthPlanDecisionToLabelMap[growthPlan.decision]}
                </Text>
              }
            />
          </Widget>
        )}
      </Flex>
      {hasDetails && (
        <Widget>
          <VStack space="s-4" p="s-16" pt="s-4">
            {growthPlan.description && (
              <>
                <Subheader>
                  <Subheader.Title>
                    <FormattedMessage
                      id="common.labels.description"
                      defaultMessage="Description"
                    />
                  </Subheader.Title>
                </Subheader>
                <EditorView html={growthPlan.description} />
              </>
            )}
            {completedActions.length > 0 && (
              <>
                <Subheader>
                  <Subheader.Title>
                    <FormattedMessage
                      id="performance.skills.insights.growth.plan.actions.label"
                      defaultMessage="Completed actions"
                    />
                  </Subheader.Title>
                </Subheader>
                <VStack space="s-16">
                  {completedActions.map(action => (
                    <CompletedAction key={action.id} action={action} />
                  ))}
                </VStack>
              </>
            )}
          </VStack>
        </Widget>
      )}
    </VStack>
  )
}
