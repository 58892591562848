import {
  Text,
  VStack,
  HStack,
  Widget,
  Subheader,
  Box,
  Item,
  ItemSkeleton,
  Avatar,
  Token,
} from '@revolut/ui-kit'
import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { UserAvatar } from '@components/UserWithAvatar/UserAvatar'
import { CountryFlag } from '@components/Inputs/LapeFields/LapePhoneInput'
import CandidateTags from '@src/features/InterviewTool/CandidateTags'
import { Info } from '@src/pages/Forms/CVScreening/components/Info'
import { useGetParsedResume } from '@src/api/recruitment/careers'
import { WorkExperience } from '@src/pages/Forms/CVScreening/components/WorkExperience'
import { formatDateRange } from '../utils'
import { Links } from './Links'

interface CVScreeningProfileProps {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
}

export const Profile = ({ candidate, round }: CVScreeningProfileProps) => {
  const { data, isLoading } = useGetParsedResume(
    candidate?.active_interview_round?.application?.id,
  )

  if (isLoading) {
    return (
      <VStack space="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }

  if (!candidate || !round) {
    return null
  }

  return (
    <VStack gap="s-16">
      <Widget p="s-16">
        <HStack gap="s-12">
          <UserAvatar size={56} full_name={candidate.full_name} />
          <VStack gap="s-12">
            <Text variant="heading2">{candidate.full_name}</Text>
            {candidate.country ? (
              <HStack gap="s-8" align="center">
                <CountryFlag countryCode={candidate.country.country_code} />
                <Text>{candidate.country.name}</Text>
              </HStack>
            ) : null}
            <CandidateTags candidate={candidate} />
          </VStack>
        </HStack>
      </Widget>

      <Links links={(candidate?.links || []).concat(data?.parsed_resume?.links || [])} />

      {!data?.parsed_resume?.work_experiences ||
      data?.parsed_resume?.work_experiences.length === 0 ? null : (
        <WorkExperience experiences={data.parsed_resume.work_experiences} />
      )}

      {data?.parsed_resume?.educations && data.parsed_resume.educations.length > 0 ? (
        <Widget>
          <Box px="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Education</Subheader.Title>
            </Subheader>
          </Box>
          {data.parsed_resume.educations.map((education, id) => (
            <Item key={id}>
              <Item.Avatar>
                <Avatar useIcon="Bank" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{education.university.name}</Item.Title>
                <Item.Description>
                  <VStack>
                    <Text>
                      {formatDateRange(education.start_date, education.end_date)}
                    </Text>
                    <Text>{education.degree}</Text>
                  </VStack>
                </Item.Description>
              </Item.Content>
            </Item>
          ))}
        </Widget>
      ) : null}

      {data?.parsed_resume?.qualifications &&
      data.parsed_resume.qualifications.length > 0 ? (
        <Widget>
          <Box px="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Qualifications</Subheader.Title>
            </Subheader>
          </Box>
          {data.parsed_resume.qualifications.map((qualification, id) => (
            <Item key={id}>
              <Item.Content>
                <Item.Title>{qualification.title}</Item.Title>
                <Item.Description>{qualification.description}</Item.Description>
              </Item.Content>
            </Item>
          ))}
        </Widget>
      ) : null}

      {data?.parsed_resume?.summary ? (
        <Widget px="s-16" pb="s-16">
          <Subheader variant="nested">
            <Subheader.Title>CV Summary</Subheader.Title>
          </Subheader>
          <Text color={Token.color.greyTone50}>{data?.parsed_resume.summary}</Text>
        </Widget>
      ) : null}

      <Widget>
        <Box px="s-16">
          <Subheader variant="nested">
            <Subheader.Title>More details</Subheader.Title>
          </Subheader>
        </Box>
        <Info candidate={candidate} round={round} />
      </Widget>
    </VStack>
  )
}
