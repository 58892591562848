import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { useGetGoals } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { ContributorType } from '@src/interfaces/talent/performance'

interface Props {
  isLoading: boolean
  isFetching: boolean
  isIndividualGoalsVisible: boolean
  isTeamGoalsVisible: boolean
  isIndividualContributor: boolean
  hasIndividualGoals: boolean
}

export const useGoalsVisibilityBySummary = (
  summary: Pick<ReviewSummaryInterface, 'team' | 'reviewed_employee_type'> | undefined,
  filters: FilterByInterface[],
  { enabled }: { enabled?: boolean } = { enabled: true },
): Props => {
  const { data, isFetching, isLoading } = useGetGoals(
    { filters },
    !!filters.find(({ columnName }) => columnName === 'cycle__id') && enabled,
  )

  const isIndividualGoalsEnabled = summary?.reviewed_employee_type !== ContributorType.IC
  const isIndividualGoalsVisible = !!(typeof summary?.reviewed_employee_type !==
  'undefined'
    ? isIndividualGoalsEnabled
    : data && data.count > 0)
  const isTeamGoalsVisible = !!(typeof summary?.reviewed_employee_type !== 'undefined'
    ? !isIndividualGoalsEnabled
    : data && data.count === 0)

  return {
    isLoading,
    isFetching,
    isIndividualGoalsVisible,
    isTeamGoalsVisible,
    isIndividualContributor: summary?.reviewed_employee_type === ContributorType.IC,
    hasIndividualGoals: Boolean(data && data.count > 0),
  }
}
