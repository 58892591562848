import { Icon, Tag } from '@revolut/ui-kit'
import { getComparedRatingToDeliverableProps } from '@src/constants/columns/performanceSummary'
import { PerformanceRatingTitle } from '@src/constants/performance'
import {
  DeliverableOptions,
  ReviewDataSectionInterface,
  SkillCardInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import React from 'react'

export const getRatingColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
  expectedLevel: DeliverableOptions | null,
) => {
  const props = getComparedRatingToDeliverableProps(data.rating, expectedLevel)
  if (data.rating) {
    return (
      <>
        <Tag color={props.color}>{PerformanceRatingTitle[data.rating]}</Tag>
        <Icon size={16} name={props.icon} color={props.iconColor} />
      </>
    )
  }
  return '-'
}
