import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ratingToColor } from '@src/utils/performance'
import { TalentSkillRatingComparableInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { RatingColumn } from '@src/features/ReviewCycle/Analytics/Skills/Tables/common/columns'

export const getRatingColumn = (
  id: number,
  name: string,
): ColumnInterface<TalentSkillRatingComparableInterface> => ({
  type: CellTypes.insert,
  idPoint: String(id),
  dataPoint: String(id),
  sortKey: String(id),
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: name,
  background: ({ skills }) => {
    const talentSkill = skills.find(({ skill }) => skill.id === id)

    if (!talentSkill) {
      return 'inherit'
    }

    return ratingToColor(talentSkill.rating) || 'inherit'
  },
  insert: ({ data: { skills, ...talent } }) => {
    const talentSkill = skills.find(({ skill }) => skill.id === id)

    if (!talentSkill) {
      return <>-</>
    }

    const { skill, ...rating } = talentSkill

    return <RatingColumn rating={rating} talent={talent} />
  },
})
