import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React, { ComponentType, useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import {
  specialisationsRequestsNew,
  specialisationsRequestsV2,
} from '@src/api/specialisations'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { SpecialisationStep } from './constants'
import { Badge, Box, Token, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { PageActions } from '@src/components/Page/PageActions'
import { GeneralInfo } from './components/GeneralInfo'
import { CompetencyMatrix } from './components/CompetencyMatrix'
import { HiringProcess } from './components/HiringProcess'
import { FormActions } from './components/FormActions'
import { checkStepCompleted } from './helpers/checkStepCompleted'
import { FormattedMessage } from 'react-intl'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { BundleType, FeatureFlags } from '@src/store/auth/types'
import { connect } from 'lape'
import { selectFeatureBundles } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'

export const formTabs: (TabBarTableNavigationInterface & {
  component: ComponentType
  key: SpecialisationStep
  canView: (userBundles: BundleType[]) => boolean
})[] = [
  {
    title: 'General info',
    key: 'general-info',
    component: GeneralInfo,
    canView: () => true,
  },
  {
    title: 'Competency Matrix',
    key: 'competency-matrix',
    component: CompetencyMatrix,
    canView: () => true,
  },
  {
    title: 'Hiring process',
    key: 'hiring-process',
    component: HiringProcess,
    canView: bundles => bundles.includes(FeatureFlags.BundleRecruitment),
  },
]

const FormBody = () => {
  const { id, step } = useParams<{ id?: string; step: SpecialisationStep }>()
  const { values } = useLapeContext<SpecialisationInterface>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const featuresBundles = useSelector(selectFeatureBundles)

  const title = useMemo(() => {
    if (!id) {
      return (
        <FormattedMessage
          id="addEditSpecialisation.form.title.create"
          defaultMessage="Add new {type}"
          values={{ type: specialisationsEnabled ? 'specialisation' : 'role' }}
        />
      )
    }
    return (
      <FormattedMessage
        id="addEditSpecialisation.form.title.edit"
        defaultMessage="Edit {name} {type}"
        values={{
          type: specialisationsEnabled ? 'specialisation' : 'role',
          name: values.name || '',
        }}
      />
    )
  }, [specialisationsEnabled])

  const tabs = useMemo(() => {
    return formTabs
      .filter(tab => tab.canView(featuresBundles))
      .map((tab, idx) => {
        const path = pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.ANY, {
          id,
          step: tab.key,
        })
        const isCompleted = checkStepCompleted(tab.key, values)
        const isCurrent = step === tab.key

        return {
          ...tab,
          path,
          disabled: !values.id,
          url: path,
          preTitle: (
            <Box mr="s-8">
              <Badge
                size={16}
                bg={
                  isCurrent
                    ? Token.color.foreground
                    : isCompleted
                    ? Token.color.green
                    : Token.color.greyTone50
                }
              >
                {idx + 1}
              </Badge>
            </Box>
          ),
        }
      })
  }, [id, step, values])

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.ORGANISATION.ROLES.SPECIALISATIONS} />
      <Box mb="s-16">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.key} path={tab.path}>
            <PageBody
              maxWidthMd={
                tab.key === 'general-info' ? Token.breakpoint.md : Token.breakpoint.xxl
              }
            >
              <VStack gap="s-16">
                <tab.component />
              </VStack>
            </PageBody>
            <PageActions
              maxWidthMd={
                tab.key === 'general-info' ? Token.breakpoint.md : Token.breakpoint.xxl
              }
            >
              <FormActions stepKey={tab.key} />
            </PageActions>
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export const AddEditSpecialisation = connect(() => {
  const competencyMatrixV2 = useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2)

  return (
    <Form
      api={competencyMatrixV2 ? specialisationsRequestsV2 : specialisationsRequestsNew}
    >
      <FormBody />
    </Form>
  )
})
