import React from 'react'
import { Box } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import Table from '@components/TableV2/Table'
import {
  candidatesRecruiterOwnershipTransfer,
  getCandidatesRecruiterOwnership,
} from '@src/api/ownership'
import {
  candidateOwnershipNameColumn,
  candidateOwnershipRecruiterNameColumn,
  candidateOwnershipSpecialisationColumn,
} from '@src/constants/columns/ownership'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const CandidatesRecruiterOwnership = ({ data, stats, refreshStats }: Props) => {
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    candidatesRecruiterOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        recruiter: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getCandidatesRecruiterOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer candidates to a new recruiter"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Candidates" val={stats?.recruiter_interview_round_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                { ...candidateOwnershipNameColumn, width: 200 },
                { ...candidateOwnershipRecruiterNameColumn, width: 200 },
                {
                  ...candidateOwnershipSpecialisationColumn(specialisationsEnabled),
                  width: 200,
                },
              ]}
              noDataMessage="Candidates ownership will appear here"
              ownerColTitle="New recruiter"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default CandidatesRecruiterOwnership
