import React, { useEffect, useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import {
  employeeNameColumn,
  employeeStatusColumn,
  employeeTypeColumn,
  lineManagerColumn,
  qualityControlRevolutersColumn,
} from '@src/constants/columns/employee'
import { teamNameColumn } from '@src/constants/columns/team'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { seniorityNameRevolutersColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import { startedAtColumn } from '@src/constants/columns/dates'
import { getRevolutersItems } from '@src/api/revoluters'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { FilterButton } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import pluralize from 'pluralize'
import { getFilteredEmployeesSelector } from '@src/api/employeesBulkEdit'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { useIsMount } from '@src/utils/isMount'
import { BulkEditData } from '@src/interfaces/bulkEdit'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { useGetEmployeesStats } from '@src/api/employees'

const Row: RowInterface<RevolutersInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...employeeTypeColumn,
      width: 110,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 160,
    },
    {
      ...teamNameColumn,
      width: 220,
    },
    {
      ...specialisationRoleNameColumn,
      width: 200,
    },
    {
      ...seniorityNameRevolutersColumn,
      width: 110,
    },
    {
      ...lineManagerColumn,
      width: 200,
    },
    {
      ...qualityControlRevolutersColumn,
      width: 200,
    },
    {
      ...locationNameColumn,
      width: 120,
    },
    {
      ...startedAtColumn,
      width: 120,
    },
    {
      ...employeeStatusColumn,
      width: 120,
    },
  ],
}

const SelectEmployeeTable = () => {
  const user = useSelector(selectUser)

  const [showMyReports, setShowMyReports] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_REPORTS,
    false,
  )

  const isMount = useIsMount()

  const getFilterByLineManager = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'line_manager__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters = []

    if (showMyReports) {
      filters.push(getFilterByLineManager(true))
    }

    return filters
  }

  const sortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'employee_type',
      direction: SORT_DIRECTION.ASC,
      nonResettable: true,
    },
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const table = useTable<RevolutersInterface>(
    { getItems: getRevolutersItems },
    getInitialFilters(),
    sortBy,
  )

  const [bulkEditData, setBulkEditData] = useState<BulkEditData>({
    selectedIds: [],
    isAllSelected: false,
    filters: [],
  })

  const { data: stats } = useGetEmployeesStats()

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<RevolutersInterface>>()

  useEffect(() => {
    const selectedIds = (
      selectedData?.selectedRowsIds ? Array.from(selectedData?.selectedRowsIds) : []
    ).map(id => Number(id))

    setBulkEditData({
      selectedIds,
      isAllSelected: selectedData?.isAllSelected || false,
      filters: table.filterBy,
    })
  }, [selectedData?.selectedRowsIds, selectedData?.isAllSelected, table.filterBy])

  const onToggleMyReports = () => {
    setShowMyReports(!showMyReports)
    table.onFilterChange(getFilterByLineManager(!showMyReports))
  }

  useEffect(() => {
    if (isMount) {
      return
    }

    if (
      !bulkEditData.isAllSelected &&
      Array.from(selectedData?.selectedRowsIds || []).length === 0
    ) {
      setBulkEditData({
        ...bulkEditData,
        selectedIds: [],
      })
    }

    const updateIds = async () => {
      const selectedEmployees = await getFilteredEmployeesSelector(bulkEditData.filters)
      const arrayOfIds = selectedEmployees.data.options.map(employee =>
        Number(employee.id),
      )
      setBulkEditData({
        ...bulkEditData,
        selectedIds: arrayOfIds,
      })
    }

    if (bulkEditData.isAllSelected) {
      updateIds()
    }
  }, [bulkEditData.isAllSelected])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Active" val={stats?.active} />
        <Stat label="Pending" val={stats?.pending} />
        <Stat label="Onboarding" val={stats?.onboarding} />
        <Stat label="Hired" val={stats?.hired} />
      </Table.Widget.Info>
      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by name or title"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <PrimaryAction
            use={InternalLink}
            // @ts-expect-error object works fine here, but UI kit expects string
            to={getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.BULK_EDIT_EMPLOYEES.EDIT),
              bulkEditData,
            )}
            style={bulkEditData.selectedIds.length ? {} : { pointerEvents: 'none' }}
            disabled={!bulkEditData.selectedIds.length}
            useIcon="Pencil"
          >
            Edit {pluralize('employee', bulkEditData.selectedIds.length, true)} in bulk
          </PrimaryAction>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by name or title"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton onClick={onToggleMyReports} active={showMyReports}>
          My reports
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <SelectTableWrapper
          enabled
          onChange={setSelectedData}
          filters={table.filterBy}
          tableDataLength={table.data.length}
          tableData={table.data}
        >
          <AdjustableTable<RevolutersInterface>
            name={TableNames.Employees}
            useWindowScroll
            dataType="Employee"
            row={Row}
            {...table}
            noDataMessage="Employees will appear here."
          />
        </SelectTableWrapper>
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default SelectEmployeeTable
