import React from 'react'
import { Placeholder, Widget } from '@revolut/ui-kit'

interface Props {
  title: React.ReactNode
  imageId: string
  actions?: React.ReactNode
}

export const EmptyList = ({ title, imageId, actions }: Props) => (
  <Widget>
    <Placeholder>
      <Placeholder.Image
        image={{
          default: `https://assets.revolut.com/assets/${imageId}.png`,
          '2x': `https://assets.revolut.com/assets/${imageId}@2x.png`,
          '3x': `https://assets.revolut.com/assets/${imageId}@3x.png`,
        }}
      />
      <Placeholder.Title>{title}</Placeholder.Title>
      {actions && <Placeholder.Actions>{actions}</Placeholder.Actions>}
    </Placeholder>
  </Widget>
)
