import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import { RecommendationQuestionInterface } from './BarRaiserSummary'
import { OptionInterface } from '@src/interfaces/selectors'
import { RecommendationExtraSectionQuestionInterface } from './BarRaiserSummaryCollapsable'
import {
  performanceSummaryBarRaiserReviewerColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import {
  getBarRaiserGradeColor,
  KeeperGradeToString,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { Avatar, Badge, Box, chain, Flex, Text, Token } from '@revolut/ui-kit'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { PeerReviewsWithTooltip } from './PeerReviewsWithTooltip'
import { MultipleEmployees } from '@src/components/ColumnInserts/MultipleEmployees/MultipleEmployees'
import {
  agregatedQuestionsAndCheckpointsForMultipleFilters,
  agregatedQuestionsAndCheckpointsForSingleFilter,
  filteredAnswersArrayByAnswer,
} from './utils'

const getConntentByRelation = (
  data: RecommendationExtraSectionQuestionInterface,
  targetRelation: ReviewerRelation,
  checkpointFilters: OptionInterface[] | undefined,
) => {
  let content
  const lmReviewAnswers = data.reviews?.filter(({ relation, checkpoint_number }) => {
    const isTargetRelation = relation === targetRelation

    if (!checkpointFilters) {
      return isTargetRelation
    }

    const checkpointFiltersIds = checkpointFilters.map(({ id }) => id)
    return (
      isTargetRelation &&
      checkpoint_number &&
      checkpointFiltersIds.includes(checkpoint_number)
    )
  })

  if (checkpointFilters?.length === 1) {
    const answersArray = agregatedQuestionsAndCheckpointsForSingleFilter(
      lmReviewAnswers,
      data,
    )

    content = answersArray?.length ? (
      <Box>
        {answersArray?.map((item, index) => {
          return (
            <Flex pb="s-6" key={index} alignItems="center" gap="s-8">
              <Avatar
                image={getAvatarUrl(item.reviewer?.avatar)}
                size={checkpointFilters ? 32 : 24}
                uuid={item?.reviewer?.full_name}
                label={
                  item?.reviewer?.full_name && getInitials(item?.reviewer?.full_name)
                }
              />
              <Flex flexDirection="column" gap="s-6">
                <Flex key={index} gap="s-2">
                  {item.answer && (
                    <Text variant="emphasis2" color={getBarRaiserGradeColor(item.answer)}>
                      {KeeperGradeToString[item.answer]}
                    </Text>
                  )}
                  {!checkpointFilters && (
                    <Badge bg={Token.color.greyTone10} color="foreground">
                      CP{item.checkpoint}
                    </Badge>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )
        })}
      </Box>
    ) : (
      '-'
    )
  } else {
    const { groupedAnswersArray, answersArray, reviewers } =
      agregatedQuestionsAndCheckpointsForMultipleFilters(lmReviewAnswers, data)

    content = groupedAnswersArray.length ? (
      <Flex alignItems="center" gap="s-6">
        <MultipleEmployees hideName employees={reviewers} maxEmployees={2} />

        {groupedAnswersArray?.map((item, index) => {
          return (
            <Tooltip
              placement="top"
              body={
                <Box width="max-content" maxWidth={400} p="s-8">
                  <Flex flexDirection="column" gap="s-20">
                    {item.answer &&
                      filteredAnswersArrayByAnswer(answersArray, item.answer).map(
                        answer => {
                          return (
                            <Flex key={answer.answer} flexDirection="column">
                              <Text>CP{answer.checkpoint}:</Text>
                              {answer.answer && (
                                <Text>
                                  {answer?.reviewer?.full_name} -{' '}
                                  <Text color={getBarRaiserGradeColor(answer.answer)}>
                                    {KeeperGradeToString[answer.answer]}
                                  </Text>
                                </Text>
                              )}
                            </Flex>
                          )
                        },
                      )}
                  </Flex>
                </Box>
              }
              key={index}
            >
              {item.answer &&
                chain(
                  <Text
                    ml={index !== 0 ? 's-2' : ''}
                    variant="emphasis2"
                    color={getBarRaiserGradeColor(item.answer)}
                  >
                    {KeeperGradeToString[item.answer]}
                  </Text>,
                  item.amount,
                )}
              {index !== groupedAnswersArray.length - 1 && <>,</>}
            </Tooltip>
          )
        })}
      </Flex>
    ) : (
      '-'
    )
  }
  return content
}

export const getRowRecommendationExtraSection: (
  checkpointFilters: OptionInterface[] | undefined,
) => RowInterface<RecommendationExtraSectionQuestionInterface> = checkpointFilters => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: 'Question',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        return getConntentByRelation(
          data,
          ReviewerRelation.LineManager,
          checkpointFilters,
        )
      },
      width: 90,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        return getConntentByRelation(
          data,
          ReviewerRelation.FunctionalManager,
          checkpointFilters,
        )
      },
      title: 'FM',
      width: 90,
    },
  ],
})

export const getRow: (
  completedReviews: boolean,
) => RowInterface<RecommendationQuestionInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: 'Question',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const lmReview = data.reviews?.find(review => review.type === 'lm')
        const questionValue =
          lmReview?.recommendation.keeper_test_section?.questions[data.questionIdx].value

        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        const reviewerData = lmReview?.recommendation.reviewer
        return lmReview && reviewerData ? (
          <Flex alignItems="center" gap="s-8">
            <Avatar
              image={getAvatarUrl(reviewerData.avatar)}
              size={32}
              uuid={reviewerData.full_name}
              label={reviewerData.full_name && getInitials(reviewerData.full_name)}
            />
            <Text variant="emphasis2" color={getBarRaiserGradeColor(questionValue)}>
              {gradeValue}
            </Text>
          </Flex>
        ) : (
          '-'
        )
      },
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const fmReview = data.reviews?.find(review => review.type === 'fm')

        const questionValue =
          fmReview?.recommendation.keeper_test_section?.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        const reviewerData = fmReview?.recommendation.reviewer

        return fmReview && reviewerData ? (
          <Flex alignItems="center" gap="s-8">
            <Avatar
              image={getAvatarUrl(reviewerData.avatar)}
              size={32}
              uuid={reviewerData.full_name}
              label={reviewerData.full_name && getInitials(reviewerData.full_name!)}
            />
            <Text variant="emphasis2" color={getBarRaiserGradeColor(questionValue)}>
              {gradeValue}
            </Text>
          </Flex>
        ) : (
          '-'
        )
      },
      title: 'FM',
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const peerReviews = data.reviews?.filter(review => review.type === 'peer')
        const questionValue = peerReviews?.[0]?.recommendation.keeper_test_section
          ? peerReviews?.[0]?.recommendation.keeper_test_section.questions[
              data.questionIdx
            ].value
          : undefined
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return completedReviews ? (
          <PeerReviewsWithTooltip
            isNewDesign
            reviews={peerReviews}
            questionIdx={data.questionIdx}
          />
        ) : (
          gradeValue
        )
      },
      title: 'Peer',
      width: 60,
    },
  ],
})
