import React from 'react'
import {
  Dropdown,
  HStack,
  Icon,
  Text,
  TextButton,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import { InsightsOptionInterface } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/types'

interface Props {
  value: InsightsOptionInterface
  options: InsightsOptionInterface[]
  onSelect: (option: InsightsOptionInterface) => void
}

export const InsightsSelector = ({ value, options, onSelect }: Props) => {
  const dropdown = useDropdown()

  return (
    <>
      <TextButton {...dropdown.getAnchorProps()}>
        <HStack space="s-4" align="center" color={Token.color.greyTone50}>
          <Text variant="body2">{value.name}</Text>
          <Icon size={14} name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
        </HStack>
      </TextButton>
      <Dropdown {...dropdown.getTargetProps()} autoClose aria-label="Insights Selector">
        {options.map(option => (
          <Dropdown.Item key={option.id} use="button" onClick={() => onSelect(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}
