import {
  DeliverableGradeOption,
  ratingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import {
  DeliverableOptions,
  getDeliverableOptionScore,
  SectionType,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { Flex, Icon, Text, VStack } from '@revolut/ui-kit'
import React from 'react'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'

export const getValuesRatingOptions = (
  options: { key: string; text: string }[],
  cardInd: number,
  cards?: ValueBasedCardInterface[],
  withTooltip?: boolean,
) => {
  return options.map((option, optionInd) => {
    const skipUntilInd = 1

    const sections = cards?.[cardInd]?.sections
    const sectionItems = sections?.map(section => {
      return {
        aboveExp: false,
        belowExp: false,
        title: section.text,
        description: section.description
          .split(/\n/)
          .filter(item => item.replace(' ', '').length)[optionInd - 1],
      }
    })

    return {
      ...option,
      tooltip:
        withTooltip && optionInd >= skipUntilInd ? (
          <VStack space="s-8">
            {sectionItems?.map((item, itemInd) => (
              <Flex key={itemInd} gap="s-8">
                <Icon name="CheckSuccess" color="success" size={16} mt="s-4" />
                <VStack>
                  <Text fontWeight={700}>{item.title}</Text>
                  <Text>{item.description}</Text>
                </VStack>
              </Flex>
            ))}
          </VStack>
        ) : undefined,
    }
  })
}

export const getAboveBelowExpectationGrades = (
  grade: DeliverableOptions | AssessBehaviourButtonTypes,
  recommendation: DeliverableOptions | null,
) => {
  const isBehaviourType =
    grade === AssessBehaviourButtonTypes.positive ||
    grade === AssessBehaviourButtonTypes.neutral ||
    grade === AssessBehaviourButtonTypes.negative ||
    grade === AssessBehaviourButtonTypes.unknown

  if (!recommendation || grade === DeliverableOptions.DONT_KNOW || isBehaviourType) {
    return { aboveExp: false, belowExp: false }
  }

  const gradeScore = getDeliverableOptionScore(grade)
  const recommendationScore = getDeliverableOptionScore(recommendation)

  return {
    aboveExp: gradeScore > recommendationScore,
    belowExp: gradeScore < recommendationScore,
  }
}

export const getGradesWithExpectations = (
  grades: Pick<DeliverableGradeOption, 'key' | 'text' | 'description'>[],
  gradeRecommendation: DeliverableOptions | null,
) => {
  return grades.map(grade => {
    const { aboveExp, belowExp } = getAboveBelowExpectationGrades(
      grade.key,
      gradeRecommendation,
    )
    return { ...grade, aboveExp, belowExp }
  })
}

export const getViewGradesWithExpectations = <
  T extends DeliverableOptions | AssessBehaviourButtonTypes,
>(
  grades: {
    key: T
    text: string
    description?: string[]
  }[],
) => {
  return grades.map(grade => ({
    ...grade,
    belowExp: false,
    aboveExp: false,
  }))
}

export const getSkillsRatingOptions = (cardInd: number, cards?: SkillCardInterface[]) => {
  return ratingOptions.map((option, optionInd) => {
    const skipUntilInd = 2
    const prevOption =
      optionInd === skipUntilInd ? undefined : ratingOptions[optionInd - 1]

    // in the normalized card all sections above selected are cleaned up
    const notNormalizedCardSections = cards?.[cardInd]?.sections
    const cardSection = notNormalizedCardSections?.find(section => {
      return section.type === SectionType.CriteriasAssessment
        ? section.level_key === option.key
        : false
    })
    const sectionItems =
      cardSection?.type === SectionType.CriteriasAssessment ? cardSection.items : null

    let tooltip
    if (option.key === DeliverableOptions.POOR) {
      tooltip = <Text>None of the criteria for this skill are met</Text>
    } else if (option.key !== DeliverableOptions.DONT_KNOW) {
      tooltip = (
        <VStack space="s-8">
          {prevOption ? (
            <Text>
              All criteria for <Text fontWeight={700}>{prevOption.text}</Text> rating and{' '}
              the following <Text fontWeight={700}>{option.text}</Text> criteria are met:
            </Text>
          ) : (
            <Text>
              All criteria for the following <Text fontWeight={700}>{option.text}</Text>{' '}
              rating are met:
            </Text>
          )}
          {sectionItems?.map((item, itemInd) => (
            <Flex key={itemInd} gap="s-8">
              <Icon name="CheckSuccess" color="success" size={16} mt="s-4" />
              <Text>{item.text}</Text>
            </Flex>
          ))}
        </VStack>
      )
    }

    const rating = option.key
    const recommendedRating = cards?.[cardInd].rating_expectation || null
    const { aboveExp, belowExp } = getAboveBelowExpectationGrades(
      rating,
      recommendedRating,
    )

    return {
      ...option,
      tooltip,
      aboveExp,
      belowExp,
    }
  })
}
