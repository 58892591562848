import { PerformanceInsightsInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { InsightsOptionInterface } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/types'

export const toInsightsOption = <
  T extends PerformanceInsightsInterface = PerformanceInsightsInterface,
>(
  insights: T,
  name: string,
): InsightsOptionInterface => ({
  ...insights,
  name,
})
