import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export enum ExternalMetricSources {
  Jira = 'jira',
  Bitbucket = 'bitbucket',
}

export enum InternalMetricSources {
  Goals = 'goals',
  Interviews = 'interviews',
  OneToOnes = 'one_to_ones',
}

export type MetricSources = ExternalMetricSources | InternalMetricSources

export enum JiraNumericMetrics {
  TicketsClosed = 'tickets_closed',
  BugsClosed = 'bugs_closed',
  StoryPoints = 'story_points',
  CycleParticipation = 'cycle_participation',
}

export enum JiraCommonMetrics {
  EpicContribution = 'epic_contribution',
}

export type JiraMetrics = JiraNumericMetrics | JiraCommonMetrics

export enum BitbucketNumericMetrics {
  PRsMerged = 'merged_prs',
  PRsReviewed = 'reviewed_prs',
  LinesAdded = 'added_lines',
  LinesRemoved = 'removed_lines',
  CommentsAdded = 'comments_added',
  AverageMergeTime = 'average_pr_merge_time_seconds',
}

export enum InterviewNumericMetrics {
  InterviewsCompleted = 'completed_interviews',
  PassRate = 'pass_rate',
  ConversionRate = 'conversion_rate',
  AverageFeedbackTime = 'average_feedback_delay_seconds',
}

export type NumericMetrics =
  | JiraNumericMetrics
  | BitbucketNumericMetrics
  | InterviewNumericMetrics
export type Metrics = NumericMetrics | JiraCommonMetrics

interface IndividualSourceMetricsInterface<T extends MetricSources> {
  id: number
  source: T
  cycle: IdAndName | null
  employee: EmployeeOptionInterface
}

export interface IndividualJiraMetricsInterface
  extends IndividualSourceMetricsInterface<ExternalMetricSources.Jira> {
  [JiraNumericMetrics.TicketsClosed]: number | null // int
  [JiraNumericMetrics.BugsClosed]: number | null // int
  [JiraNumericMetrics.StoryPoints]: number | null // int
  [JiraNumericMetrics.CycleParticipation]: number | null // float
  tickets_closed_link: string | null
  bugs_closed_link: string | null
}

export interface OrgUnitJiraMetricsInterface {
  [JiraNumericMetrics.TicketsClosed]: number | null // float
  [JiraNumericMetrics.BugsClosed]: number | null // float
  [JiraNumericMetrics.StoryPoints]: number | null // float
  [JiraNumericMetrics.CycleParticipation]: number | null // float
}

export interface IndividualBitbucketMetricsInterface
  extends IndividualSourceMetricsInterface<ExternalMetricSources.Bitbucket> {
  [BitbucketNumericMetrics.LinesAdded]: number | null // int
  [BitbucketNumericMetrics.LinesRemoved]: number | null // int
  [BitbucketNumericMetrics.PRsMerged]: number | null // int
  [BitbucketNumericMetrics.PRsReviewed]: number | null // int
  [BitbucketNumericMetrics.CommentsAdded]: number | null // int
  [BitbucketNumericMetrics.AverageMergeTime]: number | null // int
}

export interface OrgUnitBitbucketMetricsInterface {
  [BitbucketNumericMetrics.LinesAdded]: number | null // float
  [BitbucketNumericMetrics.LinesRemoved]: number | null // float
  [BitbucketNumericMetrics.PRsMerged]: number | null // float
  [BitbucketNumericMetrics.PRsReviewed]: number | null // float
  [BitbucketNumericMetrics.CommentsAdded]: number | null // float
  [BitbucketNumericMetrics.AverageMergeTime]: number | null // float
}

export interface IndividualInterviewsMetricsInterface
  extends IndividualSourceMetricsInterface<InternalMetricSources.Interviews> {
  [InterviewNumericMetrics.InterviewsCompleted]: number | null // int
  [InterviewNumericMetrics.PassRate]: number | null // float
  [InterviewNumericMetrics.ConversionRate]: number | null // float
  [InterviewNumericMetrics.AverageFeedbackTime]: number | null // int
}

export interface OrgUnitInterviewsMetricsInterface {
  [InterviewNumericMetrics.InterviewsCompleted]: number | null // float
  [InterviewNumericMetrics.PassRate]: number | null // float
  [InterviewNumericMetrics.ConversionRate]: number | null // float
  [InterviewNumericMetrics.AverageFeedbackTime]: number | null // float
}
