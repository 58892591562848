import React, { PropsWithChildren } from 'react'
import Form from '@src/features/Form/Form'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { performanceInsightsGroupFormRequests } from '@src/features/AiInsights/Performance/api/insights'
import { initialFilters } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/constants'

export const CreateForm = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Form<PerformanceInsightsGroupInterface>
      api={performanceInsightsGroupFormRequests}
      forceParams={{
        new: 'new',
      }}
      initialValues={{
        filters: initialFilters,
        sources: [],
      }}
      disableLocalStorageCaching
    >
      {children}
    </Form>
  )
}
