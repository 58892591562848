import { useTableReturnType } from '@src/components/TableV2/hooks'
import { Stats } from '@src/interfaces/data'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import React, { createContext, useContext, useMemo, ReactNode, useRef } from 'react'

type SummaryPipGoalsTable = useTableReturnType<DeliverableInterface, Stats>

interface PerformanceTabContextInterface {
  summaryPipGoalsTableRef: React.MutableRefObject<SummaryPipGoalsTable | undefined>
}

const PerformanceTabContext = createContext<PerformanceTabContextInterface | undefined>(
  undefined,
)

export const usePerformanceTabContext = (): PerformanceTabContextInterface => {
  const context = useContext(PerformanceTabContext)
  if (!context) {
    throw new Error('usePerformanceTabContext must be used within PerformanceTabProvider')
  }
  return context
}

export const PerformanceTabProvider = ({ children }: { children: ReactNode }) => {
  const summaryPipGoalsTableRef = useRef<SummaryPipGoalsTable>()

  const value = useMemo(
    () => ({
      summaryPipGoalsTableRef,
    }),
    [],
  )

  return (
    <PerformanceTabContext.Provider value={value}>
      {children}
    </PerformanceTabContext.Provider>
  )
}
