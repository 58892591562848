import cronstrue from 'cronstrue'
import { useSelector } from 'react-redux'

import {
  NotificationTemplateInterface,
  NotificationAction,
  NotificationType,
} from '@src/interfaces/notificationTemplate'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CDN_BUCKET } from '@src/constants/externalLinks'
import { Color } from '@revolut/ui-kit'

export const getScheduleMessage = (values: NotificationTemplateInterface) => {
  if (!values.schedule) {
    return null
  }

  let message = ''

  try {
    message = cronstrue.toString(values.schedule)
  } catch {
    message = 'Invalid cron value'
  }

  return message
}

export const actionOptions: RadioSelectOption<{
  id: NotificationAction
  name: string
}>[] = [
  {
    label: 'Navigate to URL',
    value: { id: NotificationAction.url, name: 'Navigate to URL' },
  },
  {
    label: 'Show full text',
    value: { id: NotificationAction.text, name: 'Show full text' },
  },
]

export const useCanSendToTenants = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()
  const featureFlags = useSelector(selectFeatureFlags)

  const isCoreInstance = featureFlags?.includes(FeatureFlags.TenantAccounts)

  return (
    isCoreInstance &&
    (values.type?.id === NotificationType.system ||
      values.type?.id === NotificationType.announcement ||
      values.type?.id === NotificationType.email) &&
    values.audience_selection_type?.id === 'filtered_table'
  )
}

export const INTERNAL_ICONS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

export const COMMERCIAL_ANNOUNCEMENT_BACKGROUND_ICONS = [
  'Calendar',
  'Chat',
  'Check',
  'Globe',
  'Heart',
  'Present',
  'Tile-pattern',
  'Warn',
] as const

export type AnnouncementBackgroundIcon =
  | typeof INTERNAL_ICONS[number]
  | typeof COMMERCIAL_ANNOUNCEMENT_BACKGROUND_ICONS[number]

export const getAnnouncementBackgroundIconUrl = (icon: AnnouncementBackgroundIcon) => {
  return `${CDN_BUCKET}/announcements/${icon}.png`
}

export const COMMERCIAL_DEFAULT_BACKGROUND = 'blue'

export const COMMERCIAL_BACKGROUNDS: Color[] = [
  'deep-purple',
  COMMERCIAL_DEFAULT_BACKGROUND,
  'light-blue',
  'cyan',
  'green',
  'teal',
  'lime',
  'yellow',
  'red',
  'pink',
  'deep-pink',
  'orange',
  'deep-grey',
  'grey-tone-50',
  'brown',
  'black',
]

export const INTERNAL_BACKGROUND_GREY = '#686E7F'
export const INTERNAL_BACKGROUND_WHITE = '#FFFFFF'
export const INTERNAL_BACKGROUND_DARK = '#161618'

export const INTERNAL_BACKGROUNDS_DARK = [
  INTERNAL_BACKGROUND_GREY,
  INTERNAL_BACKGROUND_DARK,
]

export const INTERNAL_BACKGROUNDS_LIGHT = [INTERNAL_BACKGROUND_WHITE]

export const INTERNAL_BACKGROUNDS = [
  ...INTERNAL_BACKGROUNDS_DARK,
  ...INTERNAL_BACKGROUNDS_LIGHT,
]

export const getDefaultBackgroundColor = ({
  isCommercial,
}: {
  isCommercial: boolean
}) => {
  return isCommercial ? COMMERCIAL_DEFAULT_BACKGROUND : INTERNAL_BACKGROUND_GREY
}

export const isLegacyInternalColor = ({
  data,
  isInternal,
}: {
  data: NotificationTemplateInterface
  isInternal: boolean
}) => {
  return !!(
    isInternal &&
    data.announcement_color &&
    !INTERNAL_BACKGROUNDS.includes(data.announcement_color)
  )
}

export const isLegacyInternalAsset = ({
  data,
  isInternal,
}: {
  data: NotificationTemplateInterface
  isInternal: boolean
}) => {
  return !!(
    isInternal &&
    data.announcement_background_icon &&
    !INTERNAL_ICONS.includes(data.announcement_background_icon)
  )
}
