import React from 'react'
import { DragAndDrop, HStack, IconButton, Item, Token } from '@revolut/ui-kit'
import { getDNDItemStylesOverrides } from '@components/DragAndDrop/styles'

interface Props<T> {
  sortable: DragAndDrop.DefaultSortableItemState<T>
  title: React.ReactNode
  description?: React.ReactNode
  avatar?: React.ReactNode
  side?: React.ReactNode
  onClick: VoidFunction
}

export const DNDSortableItem = <T,>({
  sortable,
  title,
  description,
  avatar,
  side,
  onClick,
}: Props<T>) => {
  return (
    <Item ref={sortable.setNodeRef} style={getDNDItemStylesOverrides(sortable)}>
      <Item.Prefix>
        <IconButton
          aria-label="Remove"
          useIcon="MinusCircle"
          color={Token.color.red}
          onClick={onClick}
          size={16}
        />
      </Item.Prefix>
      {avatar && <Item.Avatar>{avatar}</Item.Avatar>}
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {description && <Item.Description>{description}</Item.Description>}
      </Item.Content>
      <Item.Side>
        <HStack space="s-8" align="center">
          {side}
          <IconButton
            aria-label="Drag"
            useIcon="Drag"
            color={Token.color.greyTone20}
            {...sortable.attributes}
            {...sortable.listeners}
            size={16}
          />
        </HStack>
      </Item.Side>
    </Item>
  )
}
