import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button, chain, DragAndDrop, Group, Side, VStack } from '@revolut/ui-kit'
import { Id } from '@src/interfaces'
import { move } from '@src/utils/move'
import { SkillRatingsDistributionInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import SideBar from '@components/SideBar/SideBar'
import { DNDSortableItem } from '@components/DragAndDrop/SortableItem'
import { DNDOverlayItem } from '@components/DragAndDrop/OverlayItem'
import { DNDAddableItem } from '@components/DragAndDrop/AddableItem'
import { useDNDSourceItem } from '@components/DragAndDrop/hooks/useDNDSourceItem'

interface SortableOption extends SkillRatingsDistributionInterface, Id<string> {}

const toSortableOption = (
  ratingDistribution: SkillRatingsDistributionInterface,
): SortableOption => ({
  ...ratingDistribution,
  id: ratingDistribution.skill.name,
})

interface Props {
  addable: SkillRatingsDistributionInterface[]
  removable: SkillRatingsDistributionInterface[]
  isOpen: boolean
  onClose: VoidFunction
  onSelect: (options: SkillRatingsDistributionInterface[]) => void
}

export const SkillsSelector = ({
  isOpen,
  addable,
  removable,
  onClose,
  onSelect,
}: Props) => {
  const [removableOptions, setRemovableOptions] = useState(
    removable.map(toSortableOption),
  )
  const [addableOptions, setAddableOptions] = useState(
    addable
      .map(toSortableOption)
      .filter(
        ({ id: addableId }) =>
          !removableOptions.find(({ id: removableId }) => addableId === removableId),
      ),
  )

  const handleAdd = (option: SortableOption) => {
    setRemovableOptions(previous => [...previous, option])
    setAddableOptions(previous => previous.filter(({ id }) => id !== option.id))
  }

  const handleRemove = (option: SortableOption) => {
    setAddableOptions(previous => [...previous, option])
    setRemovableOptions(previous => previous.filter(({ id }) => id !== option.id))
  }

  const handleReorder = useCallback(
    (startIndex: number, endIndex: number) => {
      setRemovableOptions(previous => move(previous, startIndex, endIndex))
    },
    [setRemovableOptions],
  )

  const { activeItemId, onDragStart, onDragEnd, onDragCancel } = useDNDSourceItem({
    onAfterDragEnd: handleReorder,
  })

  const handleSelect = () =>
    onSelect(removableOptions.map(({ id, ...ratingDistribution }) => ratingDistribution))

  return (
    <SideBar
      title={chain(
        <FormattedMessage
          id="performance.analytics.skills.ratings.comparator.sidebar.title"
          defaultMessage="Selected skills"
        />,
        removableOptions.length,
      )}
      subtitle={
        <FormattedMessage
          id="performance.analytics.skills.ratings.comparator.sidebar.description"
          defaultMessage="Choose what skills you want to compare in the table and in what order"
        />
      }
      onClose={onClose}
      isOpen={isOpen}
    >
      <VStack space="s-16">
        <DragAndDrop.Provider
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <Group>
            <DragAndDrop.Sortable id="sortable-skills" items={removableOptions}>
              {sortable => {
                const option = removableOptions.find(({ id }) => id === sortable.id)

                return option ? (
                  <DNDSortableItem
                    title={option.id}
                    sortable={sortable}
                    onClick={() => handleRemove(option)}
                  />
                ) : null
              }}
            </DragAndDrop.Sortable>
          </Group>
          <DragAndDrop.DragOverlay>
            {activeItemId && <DNDOverlayItem title={activeItemId} />}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
        <Group>
          {addableOptions.map(option => {
            return (
              <Box key={option.id} aria-label={option.id}>
                <DNDAddableItem title={option.id} onClick={() => handleAdd(option)} />
              </Box>
            )
          })}
        </Group>
      </VStack>
      <Side.Actions>
        {!!removableOptions.length && (
          <Button onClick={handleSelect}>
            {chain(
              <FormattedMessage
                id="performance.analytics.skills.ratings.comparator.compare.skills.cta.label"
                defaultMessage="Compare skills"
              />,
              removableOptions.length,
            )}
          </Button>
        )}
      </Side.Actions>
    </SideBar>
  )
}
