import React from 'react'

import { Statuses } from '@src/interfaces'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  Flex,
  Icon,
  TextButton,
  Token,
  VStack,
  Text,
  ActionButton,
} from '@revolut/ui-kit'
import ProbationResult from '@src/components/ProbationResult/ProbationResult'
import { getReviewStatusTextAndColor } from '../helpers/getReviewStatusTextAndColor'

interface Props {
  review: GradedRequestInterface
  checkpointStatus: Statuses | undefined
  onClickActionButton: VoidFunction
  canSubmit: boolean
}

export const ReviewerAction = ({
  checkpointStatus,
  onClickActionButton,
  canSubmit,
  review,
}: Omit<Props, 'reviews'> & {
  review: GradedRequestInterface
}) => {
  const user = useSelector(selectUser)
  const userIsReviewer = review.reviewer?.id === user.id

  if (review.status === Statuses.completed) {
    if (review.result || review.final_grade) {
      return (
        <VStack>
          <Flex gap="s-8" justifyContent="flex-end">
            {userIsReviewer && canSubmit && (
              <TextButton label="Edit review" onClick={onClickActionButton}>
                <Icon name="Pencil" size={16} color={Token.color.foreground} />
              </TextButton>
            )}
            {review.result && (
              <ProbationResult result={review.result} status={review.status} />
            )}
          </Flex>
          {review.final_grade && (
            <Text variant="body2" color={Token.color.greyTone50}>
              {review.final_grade.label}
            </Text>
          )}
        </VStack>
      )
    }
    return (
      <Text variant="body1" color={getReviewStatusTextAndColor(review.status)?.color}>
        {getReviewStatusTextAndColor(review.status)?.title}
      </Text>
    )
  }

  if (!userIsReviewer || !canSubmit) {
    return (
      <Text variant="body1" color={getReviewStatusTextAndColor(review.status)?.color}>
        {getReviewStatusTextAndColor(review.status)?.title}
      </Text>
    )
  }

  if (checkpointStatus === Statuses.draft) {
    return (
      <ActionButton useIcon="Pencil" onClick={onClickActionButton}>
        Continue Review
      </ActionButton>
    )
  }
  return (
    <ActionButton useIcon="Plus" onClick={onClickActionButton}>
      Add Review
    </ActionButton>
  )
}
