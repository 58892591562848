import { PageWrapper } from '@src/components/Page/Page'
import React, { useState } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import {
  useGetInternalDashboard,
  useUpdateInternalDashboard,
} from '@src/api/analyticsDashboards'
import Page404 from '@src/pages/Page404/Page404'
import { useParams } from 'react-router-dom'
import { Subtitle } from '@components/Page/Header/PageHeaderTitle'
import { formatDateTime } from '@src/utils/format'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageBody } from '@components/Page/PageBody'
import { MetricsGrid } from '@src/pages/Forms/DataAnalyticsInternalDashboardView/components/MetricsGrid'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { TenantDeploymentPopup } from '@src/pages/Forms/DataAnalyticsInternalDashboardView/components/TenantDeploymentPopup'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { API } from '@src/constants/api'
import { DASHBOARD_DEFAULT_ICON } from '@src/constants/common'
import { PageError } from '@src/features/Errors/components/Page/PageError'

export const DataAnalyticsInternalDashboardView = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, error } = useGetInternalDashboard(id)
  const featureFlags = useSelector(selectFeatureFlags)
  const globalPermissions = useSelector(selectPermissions)
  const [deploymentPopupOpen, setDeploymentPopupOpen] = useState(false)

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (error) {
    return <PageError error={error} />
  }

  if (!data || !id) {
    return <Page404 />
  }

  const canChange =
    !!data.field_options?.actions?.includes(EntityPermissions.Change) && !!data.owner

  const canManageDeployment =
    featureFlags?.includes(FeatureFlags.QueryDeployments) &&
    featureFlags?.includes(FeatureFlags.TenantAccounts) &&
    globalPermissions.includes(PermissionTypes.ManageQueryDeployments)

  const canManagePermissions = data.field_options?.permissions?.includes(
    PermissionTypes.AccessObjectPermissionsInternalDashboard,
  )

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              title={data?.name}
              avatar={
                <EntityAvatar
                  data={data}
                  defaultIcon={DASHBOARD_DEFAULT_ICON}
                  api={useUpdateInternalDashboard}
                  apiUrl={`${API.DASHBOARDS}/internal`}
                  canEdit={canChange}
                />
              }
              labels={
                data.update_date_time ? (
                  <Subtitle iconName="History">
                    Edited {formatDateTime(data.update_date_time)}
                  </Subtitle>
                ) : null
              }
              actions={
                canChange || canManageDeployment || canManagePermissions ? (
                  <MoreBar>
                    {canChange && (
                      <MoreBar.Action
                        to={pathToUrl(
                          ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.METRICS,
                          {
                            id: data.id,
                          },
                        )}
                        use={InternalLink}
                        useIcon="Pencil"
                      >
                        Edit
                      </MoreBar.Action>
                    )}
                    {canManagePermissions && (
                      <MoreBar.Action
                        to={pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD_PERMISSIONS, {
                          id: data.id,
                        })}
                        use={InternalLink}
                        useIcon="LockClosed"
                      >
                        Manage Permissions
                      </MoreBar.Action>
                    )}
                    {canManageDeployment && (
                      <MoreBar.Action
                        onClick={() => setDeploymentPopupOpen(true)}
                        useIcon="ShareAndroid"
                      >
                        Deploy Dashboard
                      </MoreBar.Action>
                    )}
                  </MoreBar>
                ) : null
              }
            />
          }
          backUrl={ROUTES.APPS.DATA_ANALYTICS.DASHBOARD}
        />
        <PageBody maxWidth="100%">
          <MetricsGrid dashboardId={data.id} layout={data.layout} />
        </PageBody>
        {canManageDeployment ? (
          <TenantDeploymentPopup
            open={deploymentPopupOpen}
            onClose={() => setDeploymentPopupOpen(false)}
            dashboard={data}
          />
        ) : null}
      </PageWrapper>
    </>
  )
}
