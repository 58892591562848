import React, { useMemo } from 'react'
import { ActionButton, Avatar, Box, Item, MoreBar, VStack } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import {
  OrganisationStructure,
  OrganisationStructureLevelBase,
} from '@src/pages/Settings/Structures/types'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { DeleteButton } from '@src/features/SettingsButtons'
import { deleteStructure } from '../api'
import { PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { StructureLevelItem, StructureLevels } from '../components/StructureLevels'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

export const StructurePreview = () => {
  const { values } = useLapeContext<OrganisationStructure>()

  const canEdit = useHasGlobalPermission(PermissionTypes.ChangeOrganisationPreferences)

  const errorPopup = useErrorPopup()

  const newLevelUrl = pathToUrl(ROUTES.SETTINGS.STRUCTURE.LEVELS, { id: values.id })

  const hierarchicalLevels = useMemo(() => {
    const levels =
      values.levels?.map(level => ({
        ...level,
        url: pathToUrl(ROUTES.SETTINGS.STRUCTURE.LEVELS, {
          levelId: level.id,
          id: values.id,
        }),
      })) || []
    const topLevels = levels?.filter(level => !level.parent_level)

    return topLevels?.map(level => ({
      ...level,
      levels: generateLevels(level.id, levels, 0),
    }))
  }, [])

  return (
    <>
      <PageHeader
        pb="s-8"
        title={`${values.name} structure`}
        backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
      />

      <PageBody>
        <Box mb="s-32">
          <MoreBar>
            {canEdit && (
              <MoreBar.Action
                useIcon="Pencil"
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.STRUCTURE.GENERAL, { id: values.id })}
              >
                Edit details
              </MoreBar.Action>
            )}

            <DeleteButton
              title="structure"
              deleteApi={() =>
                deleteStructure(values.id).catch(error => {
                  if (
                    getMessageFromApiError(error)?.includes(
                      `Item cannot be deleted because it's referenced by other entities`,
                    )
                  ) {
                    errorPopup.show({
                      error,
                      forceFallbackTitle: true,
                      fallbackTitle: 'The structure is not empty',
                      fallbackDescription:
                        'If you want to delete the structure, you must delete all levels before proceeding',
                    })
                  } else {
                    errorPopup.show({
                      error,
                      fallbackTitle: 'Failed to delete structure',
                    })
                  }
                  throw error
                })
              }
              backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
              globalPermissions={[PermissionTypes.ChangeOrganisationPreferences]}
              dialogProps={{
                label: 'Delete structure',
                body: 'Please confirm that you want to delete this structure. This operation is final and cannot be undone.',
                noMessage: 'Cancel',
                yesBtnVariant: 'negative',
                yesMessage: 'Delete',
              }}
            />
          </MoreBar>
        </Box>

        {hierarchicalLevels?.length ? (
          <VStack space="s-16">
            <StructureLevels levels={hierarchicalLevels} />
            <ActionButton to={newLevelUrl} use={InternalLink} useIcon="Plus">
              Add level
            </ActionButton>
          </VStack>
        ) : (
          <Item>
            <Item.Avatar>
              <Avatar useIcon="QuestionOutline" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>No structural levels yet</Item.Title>
              <Item.Description>
                Add a structural level to define the hierarchy and organise this space
                effectively. Structural levels help establish clear roles,
                responsibilities, and reporting relationships within your organisational
                structure.
              </Item.Description>
              <Item.Actions>
                <ActionButton to={newLevelUrl} use={InternalLink} useIcon="Plus">
                  Add first structural level
                </ActionButton>
              </Item.Actions>
            </Item.Content>
          </Item>
        )}
      </PageBody>
    </>
  )
}

const MAX_LEVEL_DEPTH = 5

const generateLevels = (
  parentId: number | string,
  levels: (OrganisationStructureLevelBase & { url: string })[],
  depth: number,
): StructureLevelItem[] => {
  if (depth > MAX_LEVEL_DEPTH) {
    return []
  }
  return levels
    .filter(level => level.parent_level?.id === parentId)
    .map(level => ({
      ...level,
      levels: generateLevels(level.id, levels, depth + 1),
    }))
}
