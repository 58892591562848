import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  chain,
  Color,
  Ellipsis,
  Grid,
  HStack,
  Text,
  Token,
  useTooltip,
} from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { SkillRatingsDistributionInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { formatPercentage } from '@src/utils/format'
import { getColorByIndex } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { Column } from '@src/features/ReviewCycle/Analytics/Skills/Charts/RatingDistributionGridBarChart/Column'
import {
  ACTIVE_ROW_OPACITY,
  DEFAULT_ROW_OPACITY,
} from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/Row'

interface Props {
  data: SkillRatingsDistributionInterface
  dataKeys: BaseOption<string>[]
  colors?: Color[]
  maxCount: number
  onRowClick: (skillId: number) => void
}

export const BAR_ITEM_HEIGHT = 24
export const ROW_LABEL_WIDTH = 250

const GRID_FRACTION_PRECISION = 2

export const Row = ({ data, dataKeys, colors, maxCount, onRowClick }: Props) => {
  const tooltip = useTooltip()

  const { ratings, ratings_total } = data

  const gridWidth =
    maxCount > 0
      ? formatPercentage(data.ratings_total / maxCount, GRID_FRACTION_PRECISION)
      : 0

  const gridColumns = ratings.reduce<string>(
    (columns, { count }) =>
      count > 0 && ratings_total > 0
        ? `${columns} ${
            Number((count / ratings_total).toFixed(GRID_FRACTION_PRECISION)) * 100
          }fr`
        : columns,
    '',
  )
  const visibleColumns = ratings.filter(({ count }) => count > 0)

  return (
    <Box
      {...tooltip.getAnchorProps<HTMLDivElement>()}
      opacity={tooltip.state ? ACTIVE_ROW_OPACITY : DEFAULT_ROW_OPACITY}
      onClick={() => onRowClick(data.skill.id)}
      style={{ cursor: 'pointer' }}
      data-testid="performance_analytics_skills_ratings_distribution_grid_chart_row"
    >
      <Grid columns={`${ROW_LABEL_WIDTH}px 1fr`} columnGap="s-16">
        <HStack space="s-4">
          <FormattedMessage
            id="performance.analytics.skills.ratings.distribution.chart.row.title"
            values={{
              skillName: data.skill.name,
              count: ratings_total,
              ellipsis: chunks => <Ellipsis tooltip="auto">{chunks}</Ellipsis>,
              secondary: chunks => <Text color={Token.color.greyTone50}>{chunks}</Text>,
            }}
            defaultMessage="<ellipsis>{skillName}</ellipsis> <secondary>({count})</secondary>"
          />
        </HStack>
        <Grid columns={gridColumns} columnGap="s-4" width={gridWidth}>
          {visibleColumns.map(({ id, count }) => {
            const dataKey = dataKeys.find(({ id: dataKeyId }) => dataKeyId === id)
            const color = dataKey
              ? getColorByIndex(dataKeys.indexOf(dataKey), colors)
              : undefined

            return (
              <Box key={id}>
                <Column
                  backgroundColor={color}
                  tooltipContent={chain(
                    <Text fontWeight={500}>{data.skill.name}</Text>,
                    <Text color={color} fontWeight={500}>
                      {dataKey?.name}
                    </Text>,
                  )}
                >
                  <Text fontWeight={500}>{count}</Text>
                </Column>
              </Box>
            )
          })}
        </Grid>
      </Grid>
    </Box>
  )
}
