import React from 'react'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import {
  ActionBar,
  ActionButton,
  Box,
  Color,
  List,
  MoreBar,
  MoreBarSkeleton,
  Text,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SettingsButtons from '@src/features/SettingsButtons'
import ScheduleInterviewButton from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/ScheduleInterviewButton'
import ChangeStage from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/ChangeStage'
import SendEmail from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/SendEmail'
import NextStage from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/NextStage'
import AddFeedback from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/AddFeedback'
import GenerateFeedbackLink from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/GenerateFeedbackLink'
import Unarchive from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/Unarchive'
import RefreshInterviewStages from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/RefreshInterviewStages'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import SendDataEmail from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/SendDataEmail'
import { SnoozeAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'
import { PermissionTypes } from '@src/store/auth/types'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetCandidateSettings, useGetOfferSettings } from '@src/api/settings'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { MarkConfidentialAction } from '@src/pages/Forms/Candidate/CandidateActions/MarkConfidentialAction'
import { ArchiveOpportunityAction } from '@src/pages/Forms/Candidate/CandidateActions/ArchiveOpportunity'
import { WithdrawConsentAndDeleteTranscripts } from '@src/pages/Forms/Candidate/CandidateActions/WithdrawConsentAndDeleteTranscripts'

type Props = {
  candidate?: CandidateInterface
  canEditRound: boolean
  round?: InterviewRoundInterface
  refresh: () => Promise<void>
  loading?: boolean
}

export const CandidateActions = ({
  candidate,
  canEditRound,
  round,
  refresh,
  loading,
}: Props) => {
  const { setActiveAction } = useCandidateProfileContext()
  const isCommercial = useIsCommercial()
  const { data: candidateSettings } = useGetCandidateSettings()

  const { data: stages, status: stagesLoadingStatus } = useFetchInterviewStages(round?.id)

  const canUnarchive = round?.field_options?.permissions?.includes(
    PermissionTypes.UnarchiveInterviewRound,
  )
  const hireButtonDisabled = !isCommercial && !candidate?.phone
  let canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  let canAnonymize = candidate?.field_options?.permissions?.includes(
    PermissionTypes.AnonymizeCandidate,
  )
  const canViewEditOffer = candidate?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidateOffers,
  )
  const { data: offerSettings } = useGetOfferSettings()

  const onRefresh = async () => {
    refresh()
  }

  if (stagesLoadingStatus === 'error') {
    return (
      <ActionWidget
        mb="s-16"
        title="The hiring process is not defined correctly"
        text={
          <>
            Please ensure that all following conditions are met:
            <List variant="compact" color="grey-tone-50">
              <List.Item>
                - there are no missing scorecards in stage definitions (one exception is
                Online Test stage)
              </List.Item>
              <List.Item>
                - there are no missing CV Screening or Screen Call stage
              </List.Item>
              <List.Item>- there are no missing tests in Online Test stages</List.Item>
              <List.Item>
                - there are no missing interviewers in stage definitions (one exception is
                Online Test stage)
              </List.Item>
            </List>
          </>
        }
      >
        {round && (
          <ActionBar>
            <ActionButton
              variant="negative"
              onClick={() => {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                    id: round.specialisation.id,
                  }),
                )
              }}
            >
              Go to hiring process
            </ActionButton>
          </ActionBar>
        )}
      </ActionWidget>
    )
  }

  if (stagesLoadingStatus === 'loading' || loading) {
    return <MoreBarSkeleton mb="s-16" />
  }

  if (!candidate) {
    return null
  }

  const id = candidate.id

  const currentStageId = round?.latest_interview_stage?.id

  return (
    <Box>
      <SettingsButtons pb="s-16" maxCount={5}>
        {!round?.archived_reason && !!currentStageId && (
          <>
            {candidateSettings?.enable_scheduling && (
              <>
                <ScheduleInterviewButton
                  stages={stages}
                  onClickSchedule={() => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'scheduling',
                    })
                  }}
                  onClickCalendarSchedule={() => {
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.SCHEDULE_INTERVIEW.MANUAL, {
                        candidateId: id,
                        roundId: String(round.id),
                      }),
                    )
                  }}
                  onClickPrepCall={() => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'scheduling',
                      isPrepCall: true,
                    })
                  }}
                />
              </>
            )}
            {canEditRound && (
              <ChangeStage
                data={stages}
                currentStageId={currentStageId}
                roundId={round.id}
                onChange={onRefresh}
              />
            )}
            {candidateSettings?.enable_emails && (
              <SendEmail
                onClick={() =>
                  setActiveAction({
                    type: 'sendEmail',
                  })
                }
              />
            )}
            {canEditRound && (
              <NextStage
                data={stages}
                currentStageId={currentStageId}
                roundId={round.id}
                onChange={onRefresh}
              />
            )}
            {canAddFeedback && (
              <>
                <AddFeedback data={stages} currentStageId={currentStageId} />

                <GenerateFeedbackLink
                  stages={stages}
                  currentStageId={currentStageId}
                  round={round}
                  refresh={onRefresh}
                />
              </>
            )}
          </>
        )}

        {round?.latest_interview_stage && canEditRound && round.archived_reason && (
          <>
            <MoreBar.Action
              onClick={() => {
                setActiveAction({
                  type: 'candidate',
                })
              }}
            >
              Change opportunity
            </MoreBar.Action>
            {canUnarchive && <Unarchive onSuccess={onRefresh} round={round!} />}
          </>
        )}

        <MoreBar.Action
          useIcon="AddContact"
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
                candidateId: candidate?.id,
              }),
            )
          }}
        >
          Consider for new job posting
        </MoreBar.Action>

        {round && <RefreshInterviewStages roundId={round.id} onRefresh={onRefresh} />}

        <PermissionTransferButton
          values={candidate}
          path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CANDIDATE}
        />

        {round?.latest_interview_stage && <SendDataEmail id={id} />}

        {round?.latest_interview_stage && canViewEditOffer && !candidate?.employee_id && (
          <MoreBar.Action
            useIcon="Newspaper"
            onClick={() => {
              if (!hireButtonDisabled) {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, {
                    candidateId: candidate?.id,
                  }),
                )
              }
            }}
            aria-disabled={hireButtonDisabled}
          >
            {offerSettings?.enable_offer_templates ? 'Create offer' : 'Hire'}
            {hireButtonDisabled && (
              <Text use="div" color={Color.RED}>
                Candidate has no phone number
              </Text>
            )}
          </MoreBar.Action>
        )}

        {candidate && <SnoozeAction candidate={candidate} onAfterSubmit={onRefresh} />}

        {candidate && (
          <MarkConfidentialAction
            candidateId={candidate.id}
            isConfidential={!!candidate.is_confidential}
            onRefresh={refresh}
          />
        )}

        {canAnonymize && (
          <MoreBar.Action
            useIcon="Anonymous"
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.ANONYMIZE_CANDIDATE.GENERAL, { id }))
            }
          >
            Anonymize data
          </MoreBar.Action>
        )}

        {candidate && <WithdrawConsentAndDeleteTranscripts candidateId={candidate.id} />}

        <ArchiveOpportunityAction canEditRound={canEditRound} round={round} />
      </SettingsButtons>
    </Box>
  )
}
