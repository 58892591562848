import React, { useMemo } from 'react'

import { RowInterface } from '@src/interfaces/data'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import {
  importEmployeesAccessLevelColumn,
  importEmployeesDepartmentColumn,
  importEmployeesEmailColumn,
  importEmployeesFirstNameColumn,
  importEmployeesStartDateColumn,
  importEmployeesLastNameColumn,
  importEmployeesLineManagerColumn,
  importEmployeesLocationColumn,
  importEmployeesMiddleNameColumn,
  importEmployeesPreferredNameColumn,
  importEmployeesFunctionalManagerColumn,
  importEmployeesSeniorityColumn,
  importEmployeesSpecialisationColumn,
  importEmployeesTeamColumn,
  importEmployeesTypeColumn,
  importEmployeesJobTitleColumn,
  importEmployeesEntityColumn,
  importEmployeesProbationEndDateColumn,
  importEmployeesSalaryAmountColumn,
  importEmployeesContractTypeColumn,
  importEmployeesContractTermColumn,
  importEmployeesSalaryCurrencyColumn,
  importEmployeesSalaryPaymentFrequencyColumn,
  importEmployeesWeeklyWorkingHoursColumn,
  importEmployeesFullTimeEquivalentColumn,
  importEmployeesNoticePeriodAfterProbationColumn,
  importEmployeesNoticePeriodAfterProbationUnitColumn,
  importEmployeesNoticePeriodDuringProbationColumn,
  importEmployeesNoticePeriodDuringProbationUnitColumn,
  importEmployeesPersonalEmailColumn,
  importEmployeesPhoneCountryCodeColumn,
  importEmployeesPhoneNumberShortColumn,
  importEmployeesAddressLine1Column,
  importEmployeesAddressLine2Column,
  importEmployeesAddressLine3Column,
  importEmployeesLegalSexColumn,
  importEmployeesNationalInsuranceNumberColumn,
  importEmployeesEmployeePensionContributionColumn,
  importEmployeesEmployerPensionContributionColumn,
  importEmployeesBankNameColumn,
  importEmployeesAccountNameColumn,
  importEmployeesBankCurrencyColumn,
  importEmployeesBankAccountNumberColumn,
  importEmployeesSortCodeColumn,
  importEmployeesSalaryTimeUnitColumn,
  importEmployeesStatusColumn,
  importEmployeesWorkModalityColumn,
  ImportEmployeeCellOptions,
  importEmployeesCountryColumn,
} from '@src/constants/columns/importEmployees'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { fields, useOptions } from './config'
import { BulkDataImportSession } from '@src/features/BulkDataImport/BulkDataImportSession'
import { BulkDataImportActions } from '@src/features/BulkDataImport/BulkDataImportActions'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { API } from '@src/constants/api'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const importEmployeeRow = (
  options: ImportEmployeeCellOptions,
  specialisationsEnabled: boolean,
): RowInterface<ImportInterface<ImportEmployeeDataInterface>> => ({
  cells: [
    {
      ...importEmployeesFirstNameColumn,
      width: 150,
    },
    {
      ...importEmployeesMiddleNameColumn,
      width: 150,
    },
    {
      ...importEmployeesLastNameColumn,
      width: 150,
    },
    {
      ...importEmployeesPreferredNameColumn,
      width: 150,
    },
    {
      ...importEmployeesEmailColumn,
      width: 200,
    },
    {
      ...importEmployeesAccessLevelColumn,
      width: 200,
    },
    {
      ...importEmployeesTeamColumn,
      width: 150,
    },
    {
      ...importEmployeesDepartmentColumn,
      width: 150,
    },
    {
      ...importEmployeesLineManagerColumn(options),
      width: 150,
    },
    {
      ...importEmployeesFunctionalManagerColumn(options),
      width: 150,
    },
    {
      ...importEmployeesTypeColumn(options),
      width: 150,
    },
    {
      ...importEmployeesSpecialisationColumn(specialisationsEnabled),
      width: 150,
    },
    {
      ...importEmployeesJobTitleColumn,
      width: 150,
    },
    {
      ...importEmployeesSeniorityColumn,
      width: 150,
    },
    {
      ...importEmployeesLocationColumn,
      width: 150,
    },
    {
      ...importEmployeesCountryColumn(options),
      width: 150,
    },
    {
      ...importEmployeesEntityColumn,
      width: 150,
    },
    {
      ...importEmployeesStartDateColumn,
      width: 150,
    },
    {
      ...importEmployeesProbationEndDateColumn,
      width: 150,
    },
    {
      ...importEmployeesSalaryAmountColumn,
      width: 150,
    },
    {
      ...importEmployeesSalaryTimeUnitColumn(options),
      width: 150,
    },
    {
      ...importEmployeesWorkModalityColumn(options),
      width: 150,
    },
    {
      ...importEmployeesContractTypeColumn(options),
      width: 150,
    },
    {
      ...importEmployeesContractTermColumn(options),
      width: 150,
    },
    {
      ...importEmployeesSalaryCurrencyColumn,
      width: 150,
    },
    {
      ...importEmployeesSalaryPaymentFrequencyColumn(options),
      width: 150,
    },
    {
      ...importEmployeesWeeklyWorkingHoursColumn,
      width: 150,
    },
    {
      ...importEmployeesFullTimeEquivalentColumn,
      width: 150,
    },
    {
      ...importEmployeesNoticePeriodAfterProbationColumn,
      width: 150,
    },
    {
      ...importEmployeesNoticePeriodAfterProbationUnitColumn(options),
      width: 150,
    },
    {
      ...importEmployeesNoticePeriodDuringProbationColumn,
      width: 150,
    },
    {
      ...importEmployeesNoticePeriodDuringProbationUnitColumn(options),
      width: 150,
    },
    {
      ...importEmployeesPersonalEmailColumn,
      width: 150,
    },
    {
      ...importEmployeesPhoneCountryCodeColumn,
      width: 150,
    },
    {
      ...importEmployeesPhoneNumberShortColumn,
      width: 150,
    },
    {
      ...importEmployeesAddressLine1Column,
      width: 150,
    },
    {
      ...importEmployeesAddressLine2Column,
      width: 150,
    },
    {
      ...importEmployeesAddressLine3Column,
      width: 150,
    },
    {
      ...importEmployeesLegalSexColumn,
      width: 150,
    },
    {
      ...importEmployeesNationalInsuranceNumberColumn,
      width: 150,
    },
    {
      ...importEmployeesEmployeePensionContributionColumn,
      width: 150,
    },
    {
      ...importEmployeesEmployerPensionContributionColumn,
      width: 150,
    },
    {
      ...importEmployeesBankNameColumn,
      width: 150,
    },
    {
      ...importEmployeesAccountNameColumn,
      width: 150,
    },
    {
      ...importEmployeesBankCurrencyColumn,
      width: 150,
    },
    {
      ...importEmployeesBankAccountNumberColumn,
      width: 150,
    },
    {
      ...importEmployeesSortCodeColumn,
      width: 150,
    },
    {
      ...importEmployeesStatusColumn(options),
      width: 150,
    },
  ],
})

export const EmployeeSession = () => {
  const options = useOptions()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const row = useMemo(
    () => importEmployeeRow(options, specialisationsEnabled),
    [options, specialisationsEnabled],
  )

  return (
    <BulkDataImportSession
      row={row}
      apiEndpoint={API.EMPLOYEE_UPLOADS}
      tableName={TableNames.ImportEmployees}
      fields={fields}
      actions={props => (
        <BulkDataImportActions {...props} backUrl={ROUTES.PEOPLE.EMPLOYEES} />
      )}
      customFieldDropdownOptionKey="id"
    />
  )
}
