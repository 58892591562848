import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { PermissionTypes } from '@src/store/auth/types'

export const useCanSetPIPGoals = (employeeId: number | string) => {
  const { data: employee } = useGetEmployeeWithManagerFilter(
    employeeId,
    'line_manager__id',
  )

  if (!employee) {
    return false
  }

  return employee?.field_options?.permissions?.includes(
    PermissionTypes.SetGooalsEmployees,
  )
}
