import React, { useState } from 'react'
import { ActionButton, Portal } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'
import { ApplicationFormPreview } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreview'

type ReuseApplicationFormProps = {
  specialisationId?: number | string
}

export const ReuseApplicationForm = ({ specialisationId }: ReuseApplicationFormProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  return (
    <>
      {openJobPostings && (
        <Portal>
          <JobPostingPopup
            confirmLabel="Reuse form"
            renderJobPosting={ApplicationFormPreview}
            specialisationId={specialisationId}
            titleLabel="Application forms"
            onClose={() => {
              setOpenJobPostings(false)
            }}
            onConfirm={jobPosting => {
              if (jobPosting.application_form_sections) {
                values.application_form_sections =
                  jobPosting.application_form_sections.map((section, index) => ({
                    ...section,
                    sortId: index,
                  }))
              }
            }}
          />
        </Portal>
      )}
      <ActionButton
        useIcon="Search"
        onClick={() => {
          setOpenJobPostings(true)
        }}
      >
        Copy from existing job
      </ActionButton>
    </>
  )
}
