import React, { useEffect, useMemo, useState } from 'react'
import { SeniorityInterface } from '@src/interfaces/seniority'
import {
  CompetencyMatrixInterface,
  CompetencyMatrixInterfaceV2,
  CompetencyMatrixSkill,
  SkillLevels,
} from '@src/interfaces/roles'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { SkillInterface } from '@src/interfaces/skills'
import { getMatrixSkills } from '@src/api/skills'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { Spinner } from '@revolut/ui-kit'
import { connect, useLape } from 'lape'
import { useTable } from '@components/TableV2/hooks'
import { GetRequestInterface } from '@src/interfaces'
import { AxiosResponse } from 'axios'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'

import { useGetSeniorityList } from '@src/api/seniority'
import { GroupSeparator } from './components/GroupSeparator'
import { CompetencyItem, MatrixRowInterface, MatrixTableData } from './types'
import { isSeparator } from './helpers/isSeparator'
import { row } from './constants/row'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  minSeniority?: SeniorityInterface
  maxSeniority?: SeniorityInterface
  seniorities?: SeniorityInterface[]
  competencyMatrices?: MatrixRowInterface[]
  withWeightColumn?: boolean
  firstRowTitle?: string
  weightsError?: boolean
  approvedSkills?: CompetencyMatrixSkill[]
  filterNonExistent?: boolean
  readOnly?: boolean
  competencyMatrix?: CompetencyMatrixInterfaceV2[]
  onChangeCompetencyMatrix?: (data?: CompetencyMatrixInterfaceV2[]) => void
  withActionColumn?: boolean
  type?: 'specialisation' | 'role'
}

const CompetencyMatrixTable = ({
  minSeniority,
  maxSeniority,
  competencyMatrices,
  withWeightColumn,
  firstRowTitle = 'Skill',
  weightsError,
  filterNonExistent = false,
  readOnly = false,
  onChangeCompetencyMatrix,
  competencyMatrix,
  type,
  withActionColumn = true,
  ...props
}: Props) => {
  const [skillsets, setSkillsets] = useState<SkillInterface[] | CompetencyMatrixSkill[]>(
    [],
  )

  const { data: fetchedSkills = [] } = useGetSelectors<CompetencyMatrixSkill>(
    props.approvedSkills ? null : selectorKeys.approved_functional_skills,
  )
  const { data: senioritiesResponse } = useGetSeniorityList({
    sortBy: [{ sortBy: 'level', direction: SORT_DIRECTION.DESC }],
  })
  const competencyMatrixV2 =
    useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2) && type === 'specialisation'

  const seniorities = senioritiesResponse?.results || []

  const approvedSkills = props.approvedSkills || fetchedSkills

  const state = useLape<{
    data: MatrixRowInterface[] | undefined
  }>({
    data: competencyMatrices,
  })

  const filteredSeniorities = useMemo(() => {
    if (minSeniority && maxSeniority && seniorities.length) {
      const minIndex = seniorities.findIndex(item => item.level === minSeniority.level)
      const maxIndex =
        seniorities.findIndex(item => item.level === maxSeniority.level) + 1
      return seniorities.slice(minIndex, maxIndex)
    }

    return seniorities
  }, [minSeniority, maxSeniority, seniorities])

  const table = useTable<MatrixRowInterface | CompetencyMatrixInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: state.data,
            count: state.data?.filter(data => !isSeparator(data)).length,
          },
        } as AxiosResponse<GetRequestInterface<MatrixRowInterface | CompetencyMatrixInterface>>)
      }),
  })

  useEffect(() => {
    if (!competencyMatrices?.length) {
      return
    }

    state.data = competencyMatrices

    table.setData(state.data)
  }, [competencyMatrices])

  const fetchSkills = async () => {
    let result: SkillInterface[]
    try {
      result = await getMatrixSkills()
    } catch (e) {
      result = []
    }

    setSkillsets(result)
  }

  useEffect(() => {
    fetchSkills()
  }, [])

  const onChangeWeight = (index: number, weight: number, matrixId: number) => {
    const item = state?.data?.[matrixId]?.children?.[index]

    if (item) {
      item.weight = weight

      if (competencyMatrixV2) {
        onChangeCompetencyMatrix?.(
          competencyMatrix?.map(skill =>
            skill.skill_id === item.skill?.id ? { ...skill, weight } : skill,
          ),
        )
      } else {
        competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
      }
    }
  }

  const onDelete = (index: number, matrixId: number) => {
    const item = state?.data?.[matrixId]?.children?.[index]

    if (item) {
      state.data![matrixId]!.children!.splice(index, 1)

      if (competencyMatrixV2) {
        onChangeCompetencyMatrix?.(
          competencyMatrix?.filter(skill => skill.skill_id !== item.skill?.id),
        )
      } else {
        competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
      }
    }
  }

  const onChangeCompetency = (
    competencyLevel: SkillLevels,
    seniorityId: number,
    seniorityName: string,
    seniorityLevel: number,
    index: number,
    matrixId: number,
  ) => {
    const item = state?.data?.[matrixId]?.children?.[index]

    if (item) {
      const competency = item.competencies?.find(c => c.seniority_id === seniorityId)
      if (competency) {
        competency.competency_level = competencyLevel
      } else {
        item.competencies?.push({
          seniority_id: seniorityId,
          seniority_name: seniorityName,
          seniority_level: seniorityLevel,
          competency_level: competencyLevel,
        })
      }

      if (competencyMatrixV2) {
        onChangeCompetencyMatrix?.(
          competencyMatrix?.map(skill =>
            skill.skill_id === item.skill?.id
              ? { ...skill, competencies: item.competencies || [] }
              : skill,
          ),
        )
      } else {
        competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
      }
    }
  }

  if (!state.data) {
    return <Spinner />
  }

  const commonRowProps = {
    seniorities: filteredSeniorities,
    competencyMatrices: state.data,
    approvedSkills,
    skillsets,
    firstRowTitle,
    onChangeWeight: withWeightColumn ? onChangeWeight : undefined,
    weightsError,
    withWeightColumn: !!withWeightColumn,
    filterNonExistent,
    refetchSkills: fetchSkills,
  }

  const rowInstance = row({
    ...commonRowProps,
    readOnly,
    onDelete,
    onChangeCompetency,
    withActionColumn,
  })

  if (competencyMatrices?.length === 1) {
    table.data = [...(state.data[0]?.children || [])]
  }

  const commonTableProps = {
    idPath: 'skill.id',
    row: rowInstance,
    ...table,
  }

  const tableData = state.data?.reduce<MatrixTableData[]>(
    (allRows, currentRow, parentIndex) => {
      const rowData =
        'children' in currentRow && currentRow.children
          ? currentRow.children.map(
              (child, childIndex) =>
                ({
                  ...child,
                  parentIndex,
                  childIndex,
                } as CompetencyItem),
            )
          : [currentRow as MatrixRowInterface]

      return [...allRows, ...rowData]
    },
    [],
  )

  return (
    <AdjustableTable
      name={TableNames.CompetencyMatrix}
      {...commonTableProps}
      emptyState={<EmptyTableRaw title="No skills added" />}
      data={tableData}
      count={tableData.filter(data => !isSeparator(data)).length}
      hideCount
      forcedRowHeight="competency-matrix"
      renderSeparatorContent={rowData => <GroupSeparator rowData={rowData} />}
    />
  )
}

export default connect(CompetencyMatrixTable)
