import { RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { ApplicationCandidateInterface } from '@src/interfaces/applicationCandidate'
import { useFetchV2 } from '@src/utils/reactQuery'

export const applicationCandidateRequests: RequestInterfaceNew<ApplicationCandidateInterface> =
  {
    get: async ({ id }) => api.get(`${API.INTERVIEWS}/application/${id}`),
    update: async (data, { id }) =>
      api.patch(`${API.INTERVIEWS}/application/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${API.INTERVIEWS}/application`, data),
  }

export const useGetInterviewApplication = (id?: number) =>
  useFetchV2<ApplicationCandidateInterface>({
    url: `${API.INTERVIEWS}/application/${id}`,
    queryOptions: { enabled: Boolean(id) },
  })
