import { useIsPerformanceAiInsightsEnabled } from '@src/features/AiInsights/Performance/hooks/useAiInsightsAvailability'
import {
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

export type InsightsRelation = 'manager' | 'self'

interface Props {
  isAvailable: boolean
  relation?: InsightsRelation
}

export const useAiInsightsAvailability = (review: ReviewScorecardInterface): Props => {
  const isPerformanceAiInsightsEnabled = useIsPerformanceAiInsightsEnabled()
  const { data: settings } = useGetPerformanceSettings({ cacheTime: 0, staleTime: 0 })

  let relation: InsightsRelation | undefined

  switch (review.reviewer_relation) {
    case ReviewerRelation.LineManager:
    case ReviewerRelation.FunctionalManager:
      relation = 'manager'
      break
    case ReviewerRelation.Self:
      relation = 'self'
      break
    default:
      relation = undefined
  }

  const isAvailable =
    isPerformanceAiInsightsEnabled &&
    !!settings?.enable_performance_insights &&
    review.category === ReviewCategory.Performance &&
    relation !== undefined

  return {
    isAvailable,
    relation,
  }
}
