import { FinalGrade } from '@src/interfaces/performance'
import { FilterByInterface, SortByInterface, Stats } from '@src/interfaces/data'
import { RequestInterface, TableRequestInterface, tableRequests } from '@src/interfaces'
import { GoalsInterfaceWithChildren, GoalsStats } from '@src/api/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import React from 'react'

export type GradeAndPercent = {
  grade: FinalGrade
  percent: number
}

export enum CycleType {
  Year = 'year',
  ReviewCycle = 'review_cycle',
}

export interface Cycle {
  id: number
  name: string
  overall_progress: GradeAndPercent | null
  type: CycleType
  review_cycle_offsets: number[]
  review_cycle_ids: number[]
  karma_cycle_ids: number[]
  analytics_offset: string | null
}

export type CycleOption = {
  label: string
  key: number
  value: Cycle
}

export interface PerformanceHistoryContentType {
  id: number
  app_label: string
  model: 'department'
}

export type CycleStats = {
  goal_progress: GradeAndPercent | null
  roadmap_progress: GradeAndPercent | null
  karma_percentage: GradeAndPercent | null
  review_cycle_id: number
  total_karma: number
  name: string
}

export interface CycleSummaryData extends CycleTablesData {
  selectedCycle: Cycle
  cycleStats: CycleStats[] | undefined
  scrollGoalsRef: React.RefObject<HTMLDivElement>
  scrollRoadmapRef: React.RefObject<HTMLDivElement>
  scrollKarmaRef: React.RefObject<HTMLDivElement>
}

export interface CycleTablesData {
  goalTableRequests: TableRequestInterface<GoalsInterfaceWithChildren, GoalsStats>
  goalsSettings: TableSettings
  roadmapsTableRequests: RequestInterface<RoadmapInterface, Stats, RoadmapInterface>
  roadmapsSettings: TableSettings
  karmaTableRequests: tableRequests<KarmaInterface, KarmaStatsInterface, {}>
  karmaSettings: TableSettings
}

export type TableSettings = {
  initialFilters: FilterByInterface[]
  initialSort: SortByInterface[]
}

export interface PerformanceSummaryData {
  display_name: string | undefined
  goal_progress: GradeAndPercent | null
  roadmap_progress: GradeAndPercent | null
  overall_score: GradeAndPercent | null
  estimated_bonus_impact: (GradeAndPercent & { is_accrediated: boolean }) | null
  karma_percentage: GradeAndPercent | null
  bad_karma: number
  good_karma: number
  total_karma: number
  total_max_karma: number
  karma_display_name: string | null
}
