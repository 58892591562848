import { FilterButton, MoreBar } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { navigateTo } from '@src/actions/RouterActions'
import { getPayCyclesTableRequests } from '@src/api/payroll'
import { useGetSelectors } from '@src/api/selectors'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import {
  payCycleCountryColumn,
  payCycleEndDateColumn,
  payCycleGroupNameColumn,
  payCycleHeadcountColumn,
  payCycleIssuesColumn,
  payCyclePayDayColumn,
  payCycleStartDateColumn,
  payCycleStatusColumn,
  payCycleSubmissionDateColumn,
  payCycleTotalContributionColumn,
  payCycleTotalDeductionsColumn,
  payCycleTotalGrossColumn,
  payCycleTotalNetColumn,
} from '@src/constants/columns/payCycles'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { PayCycleInterface } from '@src/interfaces/payroll'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'

const row: RowInterface<PayCycleInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, { id: data.id })),
  cells: [
    {
      ...payCycleCountryColumn,
      width: 150,
    },
    {
      ...payCycleHeadcountColumn,
      width: 100,
    },
    {
      ...payCycleStartDateColumn,
      width: 130,
    },
    {
      ...payCycleEndDateColumn,
      width: 130,
    },
    {
      ...payCycleGroupNameColumn,
      width: 150,
    },
    {
      ...payCycleSubmissionDateColumn,
      width: 100,
    },
    {
      ...payCyclePayDayColumn,
      width: 100,
    },
    {
      ...payCycleIssuesColumn,
      width: 140,
    },
    {
      ...payCycleStatusColumn,
      width: 100,
    },
  ].filter(Boolean),
}

export const PayCyclesTableV1 = () => {
  const [selectedCountryFilter, setSelectedCountryFilter] = useState<OptionInterface[]>(
    [],
  )
  const [selectedEntityFilter, setSelectedEntityFilter] = useState<OptionInterface[]>([])

  const { data: countries } = useGetSelectors(selectorKeys.countries)
  const { data: entities } = useGetSelectors(selectorKeys.entity)

  const [ownedByMe, setOwnedByMe] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_OWNED_BY_ME,
    false,
  )
  const [withIssues, setWithIssues] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_WITH_ISSUES,
    false,
  )

  const isCommercial = useIsCommercial()
  const user = useSelector(selectUser)

  const canViewPayrollPreferences = useHasGlobalPermission(
    PermissionTypes.ViewPayrollPreferences,
  )

  const getOwnedByMeFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'owner__id',
    filters: enabled ? [{ name: user.display_name, id: user.id }] : [],
    nonResettable: true,
  })

  const getWithIssuesFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'issue_count',
    filters: enabled
      ? [
          toIdAndName('1'),
          toIdAndName(''), // passing empty values here to simulate range "> 1"
        ]
      : [],
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const initialFilters: FilterByInterface[] = [
      {
        columnName: 'current',
        filters: [toIdAndName('true')],
        nonResettable: true,
      },
    ]
    if (ownedByMe) {
      initialFilters.push(getOwnedByMeFilter(true))
    }
    return initialFilters
  }

  const table = useTable(getPayCyclesTableRequests(), getInitialFilters())

  const toggleOwnedByMe = () => {
    setOwnedByMe(prev => {
      const newOwnedByMe = !prev
      table.onFilterChange(getOwnedByMeFilter(newOwnedByMe))
      return newOwnedByMe
    })
  }

  const toggleWithIssues = () => {
    setWithIssues(prev => {
      const newWithIssues = !prev
      table.onFilterChange(getWithIssuesFilter(newWithIssues))
      return newWithIssues
    })
  }

  const selectCountries = (selection: OptionInterface[] | undefined) => {
    setSelectedCountryFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'pay_group__country__id',
    })
  }

  const selectEntities = (selection: OptionInterface[] | undefined) => {
    setSelectedEntityFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'pay_group__company_entity__id',
    })
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat
            label="Total"
            val={table.loading ? undefined : table.stats?.total_pay_cycles}
          />
          <Stat
            label="Open cycles"
            val={table.loading ? undefined : table.stats?.open_pay_cycles}
          />
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <FilterButton onClick={toggleOwnedByMe} active={ownedByMe}>
            Owned by me
          </FilterButton>
          <FilterButton onClick={toggleWithIssues} active={withIssues}>
            With issues
          </FilterButton>
          <FilterButtonCheckboxSelect
            label="Country"
            searchable
            width={300}
            options={countries || []}
            value={selectedCountryFilter}
            onChange={selectCountries}
          />
          <FilterButtonCheckboxSelect
            label="Entity"
            searchable
            width={300}
            options={entities || []}
            value={selectedEntityFilter}
            onChange={selectEntities}
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <HideIfCommercial>
              <MoreBar.Action
                to={pathToUrl(ROUTES.APPS.PAYROLL.PAYMENTS_TABLE)}
                use={InternalLink}
                useIcon="Pencil"
              >
                Manage payments
              </MoreBar.Action>
            </HideIfCommercial>
            {canViewPayrollPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.PAYROLL.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </MoreBar.Action>
            )}
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            hideCount
            name={TableNames.PayCycles}
            dataType="paycycle"
            row={row}
            useWindowScroll
            tableSettings={{
              visible: [],
              hidden: [payCycleGroupNameColumn.title, payCycleSubmissionDateColumn.title],
            }}
            hiddenCells={{
              [payCycleTotalGrossColumn.idPoint]: isCommercial,
              [payCycleTotalContributionColumn.idPoint]: isCommercial,
              [payCycleTotalDeductionsColumn.idPoint]: isCommercial,
              [payCycleTotalNetColumn.idPoint]: isCommercial,
            }}
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
