export const scopeFilterParamKeys = [
  'line_manager',
  'team',
  'department',
  'role',
  'specialisation',
  'function',
  'company',
  'location',
  'country',
] as const
