import { IdAndName } from '.'
import { EmployeeOptionInterface, IdStatuses } from './employees'
import { EmployeeEventDetailsInterface } from '@src/interfaces/employeeEvents'

export interface RevolutersInterface {
  id: number
  avatar: string | null
  employee_type: IdAndName<string>
  full_name: string
  joining_date_time: string
  line_manager: EmployeeOptionInterface | null
  quality_control: EmployeeOptionInterface | null
  location: (IdAndName & { location_name: string }) | null
  seniority: IdAndName & { level: number }
  specialisation: IdAndName & { status: RevolutersStatuses }
  status: IdAndName<IdStatuses>
  team: IdAndName & { department_id: number }
  department: IdAndName
  event: EmployeeEventDetailsInterface
}

export enum RevolutersStatuses {
  opened = 'opened',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  closed = 'closed',
}
