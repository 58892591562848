import React, { useRef } from 'react'
import {
  Action,
  Dropdown,
  HStack,
  Subheader,
  SubheaderSkeleton,
  Text,
  Token,
  useToggle,
  VStack,
} from '@revolut/ui-kit'
import {
  useDeleteInterviewTranscript,
  useGenerateInterviewSummary,
  useGetInterviewFeedbackTranscript,
  useInvalidateInterviewFeedBackTranscript,
} from '@src/api/recruitment/interviewFeedback'
import { AiIcon } from '@src/components/AiIcon/AiIcon'
import { AISummary } from '@src/pages/Forms/InterviewFeedback/General/AIInterviewSummary/AISummary'
import { TranscriptPopup } from '@src/pages/Forms/InterviewFeedback/General/AIInterviewSummary/TranscriptPopup'
import {
  CandidateInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'

interface AIInterviewSummaryProps {
  candidate: CandidateInterface
  interviewer?: InterviewToolInterviewer
  interviewFeedbackId: number | string
}

export const AIInterviewSummary = ({
  candidate,
  interviewer,
  interviewFeedbackId,
}: AIInterviewSummaryProps) => {
  const { data, isLoading: loadingInterviewFeedbackTranscript } =
    useGetInterviewFeedbackTranscript(interviewFeedbackId)
  const invalidateTranscript = useInvalidateInterviewFeedBackTranscript()

  const { mutateAsync: generateInterviewSummary, isLoading: loadingGenerateSummary } =
    useGenerateInterviewSummary()

  const { mutateAsync: deleteInterviewTranscript, isLoading: loadingDeleteSummary } =
    useDeleteInterviewTranscript()

  const moreActionsRef = useRef<HTMLButtonElement>(null)
  const [showMoreActions, toggleMoreActions] = useToggle()
  const [showTranscript, toggleShowTranscript] = useToggle()

  const isLoading =
    loadingInterviewFeedbackTranscript || loadingGenerateSummary || loadingDeleteSummary
  const hasSummary = !!data?.summary
  const hasTranscript = !!data?.transcript

  const handleGenerateSummary = async () => {
    await generateInterviewSummary(interviewFeedbackId)
    invalidateTranscript(interviewFeedbackId)
  }

  const handleDeleteTranscript = async () => {
    await deleteInterviewTranscript(interviewFeedbackId)
    invalidateTranscript(interviewFeedbackId)
  }

  return (
    <VStack gap="s-4">
      <Subheader>
        <Subheader.Title>
          <HStack align="center" gap="s-8">
            <AiIcon />
            <Text>AI interview summary</Text>
          </HStack>
        </Subheader.Title>
        <Subheader.Side>
          {isLoading && <SubheaderSkeleton.Side />}
          {!isLoading && hasTranscript && (
            <>
              <Action ref={moreActionsRef} onClick={() => toggleMoreActions()}>
                More actions
              </Action>
              <Dropdown
                open={showMoreActions}
                anchorRef={moreActionsRef}
                onClose={toggleMoreActions.off}
              >
                <Dropdown.Item
                  use="button"
                  useIcon="ArrowRepeat"
                  onClick={() => {
                    toggleMoreActions.off()
                    handleGenerateSummary()
                  }}
                >
                  {hasSummary ? 'Regenerate summary' : 'Generate summary'}
                </Dropdown.Item>
                <Dropdown.Item
                  use="button"
                  useIcon="Document"
                  onClick={() => {
                    toggleMoreActions.off()
                    toggleShowTranscript()
                  }}
                >
                  View transcript
                </Dropdown.Item>
                <Dropdown.Item
                  use="button"
                  useIcon="Delete"
                  color={Token.color.danger}
                  onClick={() => {
                    toggleMoreActions.off()
                    handleDeleteTranscript()
                  }}
                >
                  Delete transcript
                </Dropdown.Item>
              </Dropdown>
            </>
          )}
        </Subheader.Side>
      </Subheader>
      <AISummary key={data?.summary} interviewFeedbackId={interviewFeedbackId} />

      {data?.transcript && (
        <TranscriptPopup
          candidate={candidate}
          interviewer={interviewer}
          open={showTranscript}
          transcript={data.transcript}
          onClose={toggleShowTranscript.off}
        />
      )}
    </VStack>
  )
}
