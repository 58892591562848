import { HStack, Image, VStack, Text, Token } from '@revolut/ui-kit'
import React from 'react'

export interface EmployeeTileProps {
  firstName: string
  lastName: string
  imageSrc: string
  team?: string
}

export const EmployeeTile = ({
  firstName,
  lastName,
  imageSrc,
  team,
}: EmployeeTileProps) => {
  return (
    <HStack gap="s-16">
      <Image
        alt={`${firstName} ${lastName}`}
        src={imageSrc}
        style={{ objectFit: 'cover' }}
        width={102}
        height={117}
        radius={Token.radius.r8}
      />
      <VStack>
        <Text style={{ textTransform: 'uppercase' }} fontWeight={700} fontSize="1.25rem">
          {firstName}
        </Text>
        <Text style={{ textTransform: 'uppercase' }} fontWeight={700} fontSize="1.25rem">
          {lastName}
        </Text>
        {team && (
          <Text
            color={Token.color.greyTone20}
            fontWeight={400}
            fontSize="0.875rem"
            mt="s-20"
          >
            {team}
          </Text>
        )}
      </VStack>
    </HStack>
  )
}
