import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useProbationChangelog } from '@src/api/probation'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { PipAndProbationChangeLog } from '@src/features/PipAndProbation/PipAndProbationChangelog'
import {
  renderChangeLogItem,
  probationChangelogItemConfig,
} from '@src/features/PipAndProbation/PipAndProbationChagelogItem'
import { PipAndProbationChangelogItem } from '@src/interfaces/pipAndProbation'

const renderProbationChangeLogItem = renderChangeLogItem(probationChangelogItemConfig)

export const ProbationChangeLogPage = () => {
  const { employeeId, probationId } =
    useParams<{ employeeId: string; probationId: string }>()

  return (
    <PageWrapper>
      <PageHeader
        title="Probation Change Log"
        subtitle={<EmployeeUserWithAvatar id={employeeId} />}
        backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
          id: employeeId,
        })}
      />
      <PageBody>
        <PipAndProbationChangeLog<PipAndProbationChangelogItem>
          id={probationId}
          renderChangeLogItem={renderProbationChangeLogItem}
          useGetChangelog={useProbationChangelog}
        />
      </PageBody>
    </PageWrapper>
  )
}
