import React from 'react'
import { ActionButton, Avatar, Item, useToggle } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface } from '@src/interfaces/roles'
import { AllowedSenioritiesPopup } from './AllowedSenioritesPopup'
import { FormattedMessage } from 'react-intl'
import { checkCanEditRole } from '../helpers'

export const AllowedSenioritiesWidget = () => {
  const { values } = useLapeContext<RoleInterface>()
  const [isSenioritiesEditOpen, toggleSenioritiesEdit] = useToggle()
  const canEdit = checkCanEditRole(values)
  return (
    <>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="TurboTransfer" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="allowedSeniorities.widget.senioritiesRange"
              defaultMessage="{minSeniority} - {maxSeniority}"
              values={{
                minSeniority: values.seniority_min?.name || '',
                maxSeniority: values.seniority_max?.name || '',
              }}
            />
          </Item.Title>
          <Item.Description>Allowed seniorites</Item.Description>
        </Item.Content>
        {canEdit && (
          <Item.Side style={{ justifyContent: 'flex-start' }}>
            <ActionButton
              onClick={toggleSenioritiesEdit.on}
              variant={isSenioritiesEditOpen ? 'accent' : 'primary'}
            >
              Edit
            </ActionButton>
          </Item.Side>
        )}
      </Item>
      {isSenioritiesEditOpen && (
        <AllowedSenioritiesPopup onClose={toggleSenioritiesEdit.off} />
      )}
    </>
  )
}
