import React from 'react'
import { Text } from '@revolut/ui-kit'

import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { useGetSurveyAnalytics } from '../api/analytics'
import { EngagementSurveyAnalyticsInterface } from '../api/analytics/interfaces'
import { normalizedScoreToColors } from '../helpers/statValuesToColor'
import { useGetEngagementSurveyRounds } from '@src/api/engagement'

interface ScorePreviewProps {
  analyticsData: EngagementSurveyAnalyticsInterface | undefined
  isLoading: boolean
}
const ScorePreview = ({ analyticsData, isLoading }: ScorePreviewProps) => {
  if (isLoading) {
    return null
  }
  return (
    <Text color={normalizedScoreToColors(analyticsData?.average_score)?.color}>
      {analyticsData?.average_score ?? 'n/a'}
    </Text>
  )
}

export const useGetEngagementScorePreview = (
  enabled: boolean,
  scopeFilters: FilterByInterface[],
) => {
  const { data: surveyOptions, isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted, undefined, {
      enabled,
    })
  const latestSurvey = surveyOptions?.[0]

  const { data: rounds, isLoading: isRoundsLoading } = useGetEngagementSurveyRounds(
    latestSurvey?.id,
    {
      is_test: 'false',
      status: 'completed',
      ordering: 'status_order,-sent_on',
    },
  )
  const lastRound = rounds?.results[0]

  const { data: surveyAnalyticsData, isLoading: isLoadingSurveyAnalyticsData } =
    useGetSurveyAnalytics({
      surveyId: latestSurvey?.id,
      surveyRoundId: lastRound?.id,
      filters: scopeFilters,
      enabled: enabled && !!lastRound,
    })

  const isLoading =
    isLoadingSurveyOptions || isLoadingSurveyAnalyticsData || isRoundsLoading

  return {
    isLoading,
    scoreValue: surveyAnalyticsData?.average_score,
    scorePreview: (
      <ScorePreview analyticsData={surveyAnalyticsData} isLoading={isLoading} />
    ),
  }
}
