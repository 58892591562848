import { RoadmapFilterKeys, roadmapsRequests } from '@src/api/roadmaps'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { commonRoadmapSorting } from '@src/features/Roadmaps/constants'
import { RowInterface } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { toIdAndName } from '@src/utils/toIdAndName'
import React from 'react'

interface Props {
  cycleId: number | string
  ownerId: number
}

const row: RowInterface<RoadmapInterface> = {
  cells: [
    { ...roadmapGenericNameColumn, sortKey: null, filterKey: null, width: 220 },
    {
      ...roadmapPriorityColumn,
      sortKey: null,
      filterKey: null,
      width: 90,
    },
    {
      ...roadmapProgressColumn,
      sortKey: null,
      filterKey: null,
      width: 130,
    },
    {
      ...roadmapStatusColumn,
      sortKey: null,
      filterKey: null,
      width: 90,
    },
    {
      ...roadmapStartDate,
      sortKey: null,
      filterKey: null,
      width: 110,
    },
    {
      ...roadmapDueDate,
      sortKey: null,
      filterKey: null,
      width: 110,
    },
    {
      ...roadmapJiraLabelColumn,
      sortKey: null,
      filterKey: null,
      width: 100,
    },
    {
      ...roadmapEpicUrlColumn,
      sortKey: null,
      filterKey: null,
      width: 100,
    },
  ],
}

export const ScorecardRoadmapTable = ({ ownerId, cycleId }: Props) => {
  const table = useTable<RoadmapInterface>(
    roadmapsRequests,
    [
      {
        columnName: RoadmapFilterKeys.OwnerId,
        filters: [toIdAndName(String(ownerId))],
      },
      {
        columnName: RoadmapFilterKeys.ReviewCycleId,
        filters: [toIdAndName(String(cycleId))],
      },
    ],
    commonRoadmapSorting,
    { disableQuery: true },
  )
  return <AdjustableTable name={TableNames.ScorecardRoadmaps} row={row} {...table} />
}
