import { EngagementResultsAverageScoreInterface } from '../api/analytics/interfaces'

export const getAmbivalentsRate = (
  averageScore: EngagementResultsAverageScoreInterface,
) => {
  const ambivalentsRate =
    1 -
    averageScore.detractors_rate -
    averageScore.passives_rate -
    averageScore.promoters_rate

  if (ambivalentsRate < 0) {
    return 0
  }
  return ambivalentsRate
}
