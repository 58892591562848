import React from 'react'
import { Avatar } from '@revolut/ui-kit'
import { SkillResource } from '@src/interfaces/skills'

interface Props {
  resource: SkillResource
}

export const ResourceAvatar = ({ resource }: Props) => {
  return (
    <Avatar
      {...(resource.favicon ? { image: resource.favicon } : { useIcon: 'Link' })}
      variant="brand"
    />
  )
}
