import React, { useState } from 'react'
import { isNumber, omit } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  Box,
  FilterButtonSkeleton,
  Flex,
  Highlights,
  HStack,
  MoreBar,
  Separator,
  TabBar,
  TableWidget,
  Token,
} from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import Stat from '@src/components/Stat/Stat'
import Table from '@src/components/TableV2/Table'
import TableLoader from '@src/components/TableV2/TableLoader'
import { selectorKeys } from '@src/constants/api'
import { useGetSurveyAnalytics } from '@src/features/Engagement/api/analytics'
import { ItemsToAnalyse } from '@src/features/Engagement/api/analytics/interfaces'
import { NoSurveysPlaceholder } from '@src/features/Engagement/components/NoSurveysPlaceholder'
import {
  normalizedScoreToColors,
  participationRateToColor,
} from '@src/features/Engagement/helpers/statValuesToColor'
import { useIsEngagementLayoutV2 } from '@src/features/Engagement/hooks/permissions'
import { IdAndName } from '@src/interfaces'
import { EngagementSurveyInterface, GroupByOptionItem } from '@src/interfaces/engagement'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'
import { formatPercentage } from '@src/utils/format'
import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName, toLabeledIdAndName } from '@src/utils/toIdAndName'
import { UseSurveyRoundSelectorReturnType } from '../../Rounds/Selector/hooks/useSurveyRoundSelector'
import { TimelineFilterInterface } from '../../Rounds/Selector/types'
import {
  getAnalysedItemsBaseRoute,
  getHeatmapComparingOptions,
  ResultsInterface,
  ViewMode,
} from './common'
import { useExportHeatmapData } from './hooks/useExportHeatmapData'
import { UseHeatmapFiltersReturnType } from './hooks/useHeatmapFilters'
import { SurveySelector, UseSurveySelectorReturnType } from './SurverySelector'
import { TableContent } from './TableContent'
import { TimelineFilter } from './TimelineFilter'
import { HeatMapComparisonMode } from '@src/features/Engagement/components/HeatmapTableCell'

const validateTimelineFilterConfig = (timelineFilter: TimelineFilterInterface) => {
  if (timelineFilter.mode.value === 'calendar') {
    return true
  }
  if (timelineFilter.mode.value === 'rounds') {
    return Boolean(
      timelineFilter.round.value && timelineFilter.dateFrom && timelineFilter.dateTo,
    )
  }
  return true
}

interface StatHighlightsProps {
  surveyId: number | undefined
  scopeFilters: ResultsInterface['scopeFilters']
}
const StatHighlights = ({ surveyId, scopeFilters }: StatHighlightsProps) => {
  const { data: surveyAnalytics, isLoading: isLoadingSurveyAnalytics } =
    useGetSurveyAnalytics({
      surveyId,
      filters: scopeFilters,
      surveyRoundId: undefined,
    })

  return (
    <Highlights>
      <Stat
        color={normalizedScoreToColors(surveyAnalytics?.average_score)?.color}
        val={
          isLoadingSurveyAnalytics ? undefined : surveyAnalytics?.average_score ?? 'N/A'
        }
        label="Average score"
      />
      <Stat
        color={
          surveyAnalytics?.audience_size ? Token.color.foreground : Token.color.greyTone50
        }
        val={
          isLoadingSurveyAnalytics ? undefined : surveyAnalytics?.audience_size ?? 'N/A'
        }
        label="Audience size"
        tooltip="This is the number of active employees who were requested to complete the survey"
      />
      <Stat
        val={
          isLoadingSurveyAnalytics
            ? undefined
            : formatPercentage(surveyAnalytics?.response_rate ?? null, 2)
        }
        label="Participation"
        tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
        color={
          !surveyAnalytics?.response_rate
            ? Token.color.greyTone50
            : participationRateToColor(
                Math.floor(
                  isNumber(surveyAnalytics?.response_rate)
                    ? surveyAnalytics.response_rate * 100
                    : 0,
                ),
              )
        }
      />
    </Highlights>
  )
}

const ITEMS_TO_ANALYZE_FALLBACK: ItemsToAnalyse = 'categories'
interface FiltersDropdownsProps {
  itemsToAnalyse: ItemsToAnalyse
  heatmapFilters: UseHeatmapFiltersReturnType
  viewMode: ViewMode
  isScopedView: boolean
  survey: EngagementSurveyInterface | undefined
  isCompany?: boolean
  showItemTypeSelector?: boolean
  comparisonMode: HeatMapComparisonMode
  onComparisonModeChange: (value: HeatMapComparisonMode) => void
}
const FiltersDropdowns = ({
  itemsToAnalyse = ITEMS_TO_ANALYZE_FALLBACK,
  heatmapFilters,
  viewMode,
  isScopedView,
  survey,
  isCompany,
  showItemTypeSelector,
  comparisonMode,
  onComparisonModeChange,
}: FiltersDropdownsProps) => {
  const params = useParams()
  const { query, changeQueryParam } = useQuery<{ viewMode?: ViewMode }>()
  const isLayoutV2 = useIsEngagementLayoutV2({ isOrganisationCompanySubtab: isCompany })
  const showV1Dropdowns = !isLayoutV2 || isCompany

  return (
    <HStack align="center" space="s-8">
      {itemsToAnalyse !== 'answers' && (
        <>
          {showV1Dropdowns && (
            <RadioSelectInput<IdAndName<ViewMode>>
              inputProps={{ width: 140 }}
              label="View mode"
              searchable={false}
              value={toIdAndName(viewMode)}
              options={['table' as const, 'heatmap' as const].map(toLabeledIdAndName)}
              onChange={value => {
                if (value?.id) {
                  changeQueryParam('viewMode', value.id)
                }
              }}
            />
          )}
          {viewMode === 'heatmap' && (
            <RadioSelectInput
              inputProps={{ width: 180 }}
              label="Group by"
              searchable={false}
              value={heatmapFilters.groupBy.value}
              selector={selectorKeys.engagement_analytics_heatmap_groups}
              onChange={value => {
                if (value?.id) {
                  heatmapFilters.groupBy.setValue(value)
                }
              }}
            />
          )}
          <RadioSelectInput
            inputProps={{ width: 180 }}
            searchable={false}
            options={getHeatmapComparingOptions(isScopedView, viewMode)}
            value={toIdAndName(comparisonMode)}
            onChange={(value: IdAndName<HeatMapComparisonMode> | null) => {
              if (value?.id) {
                onComparisonModeChange(value?.id)
              }
            }}
          />
        </>
      )}
      {showV1Dropdowns && showItemTypeSelector && (
        <RadioSelectInput
          inputProps={{ width: 180 }}
          label="Analyse"
          searchable={false}
          value={toIdAndName(itemsToAnalyse)}
          options={['categories' as const, 'answers' as const]
            .filter(Boolean)
            .map(toLabeledIdAndName)}
          onChange={value => {
            if (!value) {
              return
            }
            if (survey) {
              const path = getAnalysedItemsBaseRoute()

              if (path) {
                navigateReplace(
                  pathToUrl(
                    path,
                    isScopedView
                      ? { ...params, subtab: value.id }
                      : { ...params, type: value.id },
                    value.id === 'answers' ? omit(query, 'viewMode') : query,
                  ),
                )
              }
            }
          }}
        />
      )}
    </HStack>
  )
}

interface PublishedResultsInterface {
  subheaderRoundData?: UseSurveyRoundSelectorReturnType
  surveySelector?: UseSurveySelectorReturnType
  isCompany?: boolean
}
export const EngagementResultsWidget = ({
  itemsToAnalyse: itemsToAnalyseFromProps,
  survey,
  refetchSurvey,
  initialFilters,
  timelineFilter: timelineFilterFromProps,
  heatmapFilters,
  scopeFilters,
  subheaderRoundData,
  surveySelector,
  isCompany,
  showItemTypeSelector = true,
}: ResultsInterface & PublishedResultsInterface) => {
  const isLayoutV2 = useIsEngagementLayoutV2({ isOrganisationCompanySubtab: isCompany })
  const [comparisonMode, setComparisonMode] = useState<HeatMapComparisonMode>('score')
  const params = useParams<{ type: ItemsToAnalyse; subtab: ItemsToAnalyse }>()
  const itemsToAnalyseFromPath =
    itemsToAnalyseFromProps || params.type || params.subtab || ITEMS_TO_ANALYZE_FALLBACK

  const { query, changeQueryParam } = useQuery<{ viewMode?: ViewMode }>()
  const { viewMode = 'table' } = query

  const timelineFilter = (timelineFilterFromProps ||
    subheaderRoundData) as TimelineFilterInterface

  const { onInitExport } = useExportHeatmapData(
    survey.id,
    timelineFilter.dateFrom,
    timelineFilter.dateTo,
  )

  const surveyId = survey.id || surveySelector?.value?.id
  const isScopedView = !!scopeFilters

  const surveysOptionsNum = surveySelector?.options.length || 0
  const roundsOptionsNum = timelineFilter.round.options.length
  const showFilters =
    surveySelector?.isLoadingOptions ||
    timelineFilter.round.isLoadingOptions ||
    roundsOptionsNum > 0 ||
    surveysOptionsNum > 1
  return (
    <Table.Widget>
      <Table.Widget.Info>
        {isCompany ? (
          <Flex
            width="100%"
            gap="s-32"
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack align="center" space="s-24">
              <CompanyNavigation />
              <Box height="s-48">
                <Separator orientation="vertical" />
              </Box>
              <StatHighlights surveyId={surveyId} scopeFilters={scopeFilters} />
            </HStack>
            {showFilters && (
              <FiltersDropdowns
                isCompany
                itemsToAnalyse={itemsToAnalyseFromPath}
                heatmapFilters={heatmapFilters}
                viewMode={viewMode}
                isScopedView={isScopedView}
                survey={survey}
                showItemTypeSelector={showItemTypeSelector}
                comparisonMode={comparisonMode}
                onComparisonModeChange={value => setComparisonMode(value)}
              />
            )}
          </Flex>
        ) : (
          <Flex
            flex="1"
            width="100%"
            justifyContent="space-between"
            ml={isCompany ? 's-16' : undefined}
          >
            {isLayoutV2 ? (
              itemsToAnalyseFromPath !== 'answers' && (
                <TabBar
                  variant="segmented fit"
                  mx="auto"
                  value={viewMode}
                  onChange={value => {
                    if (value) {
                      changeQueryParam('viewMode', value)
                    }
                  }}
                >
                  <TabBar.Item to="table" useIcon="ViewListSmall" aria-label="table view">
                    Table
                  </TabBar.Item>
                  <TabBar.Item
                    to="heatmap"
                    useIcon="AvatarGrid"
                    aria-label="heatmap view"
                  >
                    Heatmap
                  </TabBar.Item>
                </TabBar>
              )
            ) : (
              <StatHighlights surveyId={surveyId} scopeFilters={scopeFilters} />
            )}
            {showFilters && (
              <Box ml={isLayoutV2 ? 'auto' : undefined}>
                <FiltersDropdowns
                  itemsToAnalyse={itemsToAnalyseFromPath}
                  heatmapFilters={heatmapFilters}
                  viewMode={viewMode}
                  isScopedView={isScopedView}
                  survey={survey}
                  comparisonMode={comparisonMode}
                  onComparisonModeChange={value => setComparisonMode(value)}
                />
              </Box>
            )}
          </Flex>
        )}
      </Table.Widget.Info>
      {showFilters && (
        <Table.Widget.Filters ml={isCompany ? 's-8' : undefined}>
          {surveySelector && (
            <SurveySelector
              {...surveySelector}
              onChange={() => timelineFilter.clearAll()}
            />
          )}
          {(isCompany || !isLayoutV2) && (
            <TimelineFilter hideModeSwitcher={isScopedView} {...timelineFilter} />
          )}
          {viewMode === 'heatmap' && (
            <>
              {heatmapFilters.groupBy.isLoadingAvailableItems ? (
                <FilterButtonSkeleton width={150} />
              ) : (
                <FilterButtonCheckboxSelect<GroupByOptionItem>
                  searchable
                  label={heatmapFilters.groupBy.value.name}
                  options={heatmapFilters.groupBy.availableItems}
                  value={heatmapFilters.value}
                  onChange={newValue => {
                    if (heatmapFilters.groupBy && newValue) {
                      heatmapFilters.setValue(newValue)
                    } else {
                      heatmapFilters.setValue([])
                    }
                  }}
                />
              )}
            </>
          )}
        </Table.Widget.Filters>
      )}
      <Table.Widget.Actions>
        {viewMode === 'heatmap' && (
          <Table.Widget.MoreBar maxCount={1}>
            <MoreBar.Action useIcon="Upload" onClick={onInitExport}>
              Export heatmap data
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        )}
      </Table.Widget.Actions>
      {!timelineFilter.round.isLoadingOptions &&
      !heatmapFilters.isLoadingGroupByOptions ? (
        <Table.Widget.Table>
          {validateTimelineFilterConfig(timelineFilter) ? (
            <TableContent
              survey={survey}
              refetchSurvey={refetchSurvey}
              viewMode={viewMode}
              initialFilters={initialFilters}
              heatmapFilters={heatmapFilters}
              heatMapComparisonMode={comparisonMode}
              timelineFilter={timelineFilter}
              scopeFilters={scopeFilters}
              itemsToAnalyse={itemsToAnalyseFromPath}
            />
          ) : (
            <NoSurveysPlaceholder
              timelineFilter={timelineFilter}
              surveysOptionsNum={surveysOptionsNum}
            />
          )}
        </Table.Widget.Table>
      ) : (
        <TableWidget.Table>
          <TableLoader loading rowHeight="large" />
        </TableWidget.Table>
      )}
    </Table.Widget>
  )
}
