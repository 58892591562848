import React, { useEffect, useRef } from 'react'
import {
  ChangeScorecardInterface,
  CriteriaAssessment,
  PerformanceReviewTypes,
  PreviousReviewInterface,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ScorecardError,
  ScorecardSections,
  SectionType,
  SingleChoice,
  SkillCardInterface,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { ScorecardAssessLevel } from '@components/ScorecardGeneral/ScorecardAssessLevel'
import ScorecardRadioSection from '@components/ScorecardGeneral/ScorecardRadioSection'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import { useLocation } from 'react-router-dom'
import CardResult from '@components/ScorecardGeneral/CardResult'
import CardJustification from '@components/ScorecardGeneral/CardJustification'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BoxProps, Token, VStack } from '@revolut/ui-kit'
import { connect } from 'lape'
import { OptionInterface } from '@src/interfaces/selectors'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'

interface ScorecardSectionProps extends Omit<BoxProps, 'onChange'> {
  skillId: string
  disabled?: boolean
  cardIndex: number
  type: PerformanceReviewTypes
  category: ReviewCategory
  onChange?: (params: ChangeScorecardInterface) => void
  viewMode?: boolean
  errors?: ScorecardError
  sectionErrors?: ScorecardError[] | null
  touched?: boolean
  card: SkillCardInterface | SummarySkillCardInterface
  previousReviews?: PreviousReviewInterface[]
  hideJustification?: boolean
  hideResult?: boolean
  resultLoading?: boolean
  onSetResultLoading: (ids: (number | string)[]) => void
  cardTitleTags?: OptionInterface[]
  generateOptionDataName?: (
    cardIndex: number,
    sectionIndex: number,
    optionIndex: number,
  ) => string
  showErrorLabel?: boolean
  justificationOnly?: boolean
  justificationMessage?: string
  renderGuidelines?: (id: number) => React.ReactNode
}

export const ScorecardSectionRevamped = connect(
  ({
    skillId,
    type,
    disabled,
    cardIndex,
    onChange,
    viewMode,
    errors,
    touched,
    sectionErrors,
    previousReviews,
    card,
    hideJustification,
    hideResult,
    resultLoading,
    onSetResultLoading,
    category,
    cardTitleTags,
    generateOptionDataName,
    showErrorLabel,
    justificationOnly = false,
    justificationMessage,
    renderGuidelines,
    ...boxProps
  }: ScorecardSectionProps) => {
    const { values } = useLapeContext<
      ReviewScorecardInterface | ReviewScorecardViewInterface
    >()
    const { hash } = useLocation()
    const ref = useRef<HTMLDivElement>(null)
    const justificationRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (`#${skillId}` === hash) {
        ref.current?.scrollIntoView()
      }
    }, [skillId, hash])

    useEffect(() => {
      const alreadyScrolled =
        sectionErrors && sectionErrors.findIndex(section => section) < cardIndex

      if (hash === SCROLL_ERROR_HASH && !alreadyScrolled && errors) {
        if (errors.sections) {
          ref.current?.scrollIntoView()
        } else if (errors.justification) {
          justificationRef.current?.scrollIntoView()
        } else {
          ref.current?.scrollIntoView()
        }
      }
    }, [hash])

    const renderContent = (
      cardItem: SkillCardInterface | SummarySkillCardInterface,
      section: ScorecardSections,
      sectionIndex: number,
    ) => {
      switch (section.type) {
        case SectionType.SingleChoice:
          return (
            <ScorecardRadioSection
              content={section as SingleChoice}
              cardIndex={cardIndex}
              sectionIndex={sectionIndex}
              onChange={onChange}
              viewMode={viewMode}
              key={sectionIndex}
            />
          )

        case SectionType.CriteriasAssessment:
          return (
            <ScorecardAssessLevel
              content={section as CriteriaAssessment}
              cardIndex={cardIndex}
              sectionIndex={sectionIndex}
              onChange={onChange}
              viewMode={viewMode}
              error={errors?.sections?.[sectionIndex]}
              touched={touched}
              key={sectionIndex}
              generateOptionDataName={generateOptionDataName}
              ratingExpectation={cardItem.rating_expectation}
              guidelines={
                renderGuidelines && sectionIndex === 0
                  ? renderGuidelines(Number(skillId))
                  : undefined
              }
            />
          )
        default:
          return null
      }
    }

    const firstError = errors?.sections?.find(Boolean)

    return (
      <VStack space="s-16" ref={ref} data-testid={`card-${skillId}`} {...boxProps}>
        {!justificationOnly && (
          <>
            <VStack borderRadius={Token.radius.r12} backgroundColor="transparent">
              {card.sections.map((section, sectionIndex) => {
                return renderContent(card, section, sectionIndex)
              })}
            </VStack>
            {showErrorLabel && touched && firstError && (
              <InputErrorMessage message="This question is required" />
            )}
            {!viewMode && !hideResult && (
              <CardResult
                card={card as SkillCardInterface}
                type={type}
                disabled={disabled}
                loading={resultLoading}
              />
            )}
          </>
        )}
        {!hideJustification && (
          <CardJustification
            reviews={(values as ReviewScorecardViewInterface)?.reviews}
            justificationRef={justificationRef}
            card={card}
            cardIndex={cardIndex}
            previousReviews={previousReviews}
            type={type}
            disabled={disabled}
            viewMode={viewMode}
            fieldError={errors?.justification}
            justificationMessage={justificationMessage}
          />
        )}
      </VStack>
    )
  },
)
