import { Token } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'

export const getReviewStatusTextAndColor = (status?: Statuses) => {
  switch (status) {
    case Statuses.completed:
    case Statuses.completed_late:
    case Statuses.approved:
      return {
        color: Token.color.foreground,
        title: 'Completed',
      }
    case Statuses.rejected:
      return { color: Token.color.red, title: 'Rejected' }
    case Statuses.expired:
      return { color: Token.color.greyTone50, title: 'Expired' }
    default:
      return { color: Token.color.greyTone50, title: 'Pending' }
  }
}
