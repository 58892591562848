import {
  Placeholder,
  BottomSheet,
  Button,
  VStack,
  Widget,
  Text,
  Token,
} from '@revolut/ui-kit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BitbucketConfig } from '../constants'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import OpenInNewTab from '@components/OpenInNewTab/OpenInNewTab'
import { BITBUCKET_ALL_WORKSPACES } from '@src/constants/externalLinks'
import { CopyUrlToClipboard } from '../../components/CopyUrlToClipboard'

interface Props {
  onClose: VoidFunction
}

const BitbucketHelperContent = () => {
  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <FormattedMessage
                id="integrations.bitbucket.helper.stepper.1"
                defaultMessage="Log in to your <T>Bitbucket account</T>, select
                your icon profile on the top right, and select <T>All workspaces</T>"
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
            bulletVariant="filled"
            subtitle={
              <VStack pt="s-12">
                <OpenInNewTab link={BITBUCKET_ALL_WORKSPACES} label="Take me there" />
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <FormattedMessage
                id="integrations.bitbucket.helper.stepper.2"
                defaultMessage="Select a workspace you want to integrate with Revolut People and
                press <T>Manage</T>. In the left side panel select <T>OAuth consumers &rarr; Add consumer</T>"
                values={{
                  T: text => <Text fontWeight={700}>{text}</Text>,
                }}
              />
            }
            subtitle={
              <FormattedMessage
                id="integrations.bitbucket.helper.stepper.2.subtitle"
                defaultMessage="Make sure that checkbox
                <T>“This is a private consumer”</T>
                is selected"
                values={{
                  T: text => <Text color={Token.color.foreground}>{text}</Text>,
                }}
              />
            }
            bulletVariant="filled"
          />

          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title="Fill in callback url"
            subtitle={
              <CopyUrlToClipboard
                url={document.location.origin}
                successText="Callback URL copied to clipboard"
              />
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title="Set permissions"
            subtitle="Under “Permissions”, select read level for “Account”, “Workspace Membership”, “Projects” and “Pull Requests”"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title="Expand details of created OAuth consumer"
            subtitle="You'll see generated Key and Secret inside expanded panel."
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title="Copy Key and Secret"
            subtitle="Use Client ID and Client Secret in Revolut People to connect Bitbucket"
          />
        </AutoStepper>
      </Widget>
    </VStack>
  )
}

export const BitbucketIntegrationHelperPopup = ({ onClose }: Props) => {
  const { name, image } = BitbucketConfig

  return (
    <BottomSheet open onClose={onClose}>
      <Placeholder>
        <Placeholder.Image image={image} />
        <Placeholder.Title>
          <FormattedMessage
            values={{ name }}
            defaultMessage="Setup Revolut app in {name}"
            id="integration.bitbucket.helpPopup.title"
          />
        </Placeholder.Title>
        <Placeholder.Description>
          <FormattedMessage
            values={{ name }}
            defaultMessage="Before you can connect your {name} account, you need to set up the Revolut app in {name}."
            id="integration.bitbucket.helpPopup.description"
          />
        </Placeholder.Description>
      </Placeholder>
      <BitbucketHelperContent />
      <BottomSheet.Actions gradient="full">
        <Button variant="primary" onClick={onClose}>
          Got it
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
