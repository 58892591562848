import React from 'react'
import { Box, copyToClipboard, Grid, Icon, Text, Token, VStack } from '@revolut/ui-kit'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'

interface Props {
  url: string | undefined
  successText: string
}

export const CopyUrlToClipboard = ({ url, successText }: Props) => {
  return (
    <VStack pt="s-12">
      {/* using custom component here as `Tag` is only one line, and redirect_uri can be longer */}
      <Box
        use="button"
        onClick={e => {
          e.preventDefault()
          url &&
            copyToClipboard(url).then(() => {
              pushNotification({
                value: successText,
                duration: SUCCESS_DEFAULT_DURATION,
                type: NotificationTypes.success,
              })
            })
        }}
      >
        <Grid
          columns="auto 1fr"
          bg={Token.color.black}
          color={Token.color.white}
          borderRadius="r16"
          placeItems="center"
          gap="s-4"
          px="s-8"
          py="s-6"
        >
          <Icon name="Copy" size={12} />
          <Text
            variant="emphasis4"
            textAlign="start"
            style={{ overflowWrap: 'anywhere' }}
          >
            {url}
          </Text>
        </Grid>
      </Box>
    </VStack>
  )
}
