import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FunctionInterface } from '@src/interfaces/functions'
import {
  specialisationAllSkillsColumn,
  specialisationFunctionColumn,
  specialisationGenericNameColumn,
  specialisationHeadcountColumn,
  specialisationManagerColumn,
  specialisationRequisitionsColumn,
  specialisationRoleColumn,
  specialisationStatusColumn,
} from '@src/constants/columns/specialisation'
import { nipsColumn } from '@src/constants/columns/department'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { specialisationsRequests } from '@src/api/specialisations'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

const specialisationRow = (
  specialisationsEnabled: boolean,
  competencyMatrixV2: boolean,
): RowInterface<SpecialisationInterface> => ({
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id })),
  cells: [
    {
      ...specialisationGenericNameColumn(specialisationsEnabled),
      width: 270,
    },
    {
      ...(specialisationsEnabled
        ? specialisationRoleColumn
        : specialisationFunctionColumn),
      width: 250,
    },
    {
      ...specialisationManagerColumn,
      width: 250,
    },
    {
      ...specialisationHeadcountColumn,
      width: 100,
    },
    {
      ...specialisationRequisitionsColumn,
      width: 110,
    },
    {
      ...nipsColumn,
      width: 100,
    },
    {
      ...specialisationStatusColumn,
      width: 200,
    },
    {
      ...specialisationAllSkillsColumn(competencyMatrixV2),
      width: 350,
    },
  ],
})

const Specialisations = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const { data: orgSettings } = useGetOrganisationSettings()
  const hiddenCells = useMemo(
    () => ({
      [specialisationStatusColumn.idPoint]:
        !orgSettings?.enable_specialisations_approvals,
    }),
    [orgSettings?.enable_specialisations_approvals],
  )
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const competencyMatrixV2 = useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2)

  const row = useMemo(
    () => specialisationRow(specialisationsEnabled, competencyMatrixV2),
    [specialisationsEnabled, competencyMatrixV2],
  )

  const initialFilter = [
    {
      filters: [{ name: values.name, id: values.id }],
      columnName: 'role__function__id',
      nonResettable: true,
    },
    {
      filters: [
        { id: 'pending', name: 'pending' },
        { id: 'approved', name: 'approved' },
        { id: 'draft', name: 'draft' },
      ],
      columnName: 'status',
    },
  ]

  const table = useTable<SpecialisationInterface>(specialisationsRequests, initialFilter)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.GENERAL_INFO))
  }

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <PrimaryAction onClick={handleNewRow} useIcon="Plus">
            {specialisationsEnabled ? 'Add specialisation' : 'Add role'}
          </PrimaryAction>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<SpecialisationInterface>
          name={TableNames.FunctionSpecialisations}
          useWindowScroll
          dataType={specialisationsEnabled ? 'Specialisation' : 'Role'}
          row={row}
          hiddenCells={hiddenCells}
          {...table}
          emptyState={
            <EmptyTableRaw
              title={
                specialisationsEnabled
                  ? 'All specialisations will appear here'
                  : 'All roles will appear here'
              }
            />
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Specialisations
