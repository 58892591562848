import { Token } from '@revolut/ui-kit'

export const DISTRIBUTION_CHART_COLORS = [
  Token.color.greyTone50,
  Token.color.orange,
  Token.color.deepGrey,
  Token.color.accent,
  Token.color.success,
  Token.color.cyan,
]

export const BENCHMARK_CHART_COLORS = [
  Token.color.warning,
  Token.color.success,
  Token.color.cyan,
]
