import { GetRequestData, TableRequestInterface } from '@src/interfaces'
import { useFetchV2 } from '@src/utils/reactQuery'
import {
  SkillRatingsDistributionInterface,
  TalentSkillRatingComparableInterface,
  TalentSkillRatingInterface,
} from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { apiV2 } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'

export enum SkillRatingsDistributionGroupTypes {
  Distribution = 'distribution',
  Benchmark = 'benchmark',
}

export enum SkillRatingsDistributionFilterKeys {
  CycleId = 'cycle_id',
  DepartmentId = 'department_id',
  TeamId = 'team_id',
  SpecialisationId = 'specialisation_id',
  SkillId = 'skill_id',
  GroupType = 'group_type',
  RatingCategory = 'rating_category',
  RatingExpectation = 'rating_expectation',
  Search = 'search',
  SearchEmployee = 'search_employee',
}

export interface SkillRatingsDistributionParams
  extends Partial<{
    [SkillRatingsDistributionFilterKeys.CycleId]: number | number[]
    [SkillRatingsDistributionFilterKeys.DepartmentId]: number | number[]
    [SkillRatingsDistributionFilterKeys.TeamId]: number | number[]
    [SkillRatingsDistributionFilterKeys.SpecialisationId]: number | number[]
    [SkillRatingsDistributionFilterKeys.SkillId]: number | number[]
    [SkillRatingsDistributionFilterKeys.GroupType]: SkillRatingsDistributionGroupTypes
    [SkillRatingsDistributionFilterKeys.RatingCategory]: string
    [SkillRatingsDistributionFilterKeys.RatingExpectation]: string
    [SkillRatingsDistributionFilterKeys.Search]: string
    [SkillRatingsDistributionFilterKeys.SearchEmployee]: string
  }> {}

export type DistributionKey = 'rating_category' | 'rating_expectation'

export const distributionGroupToKeyMap: Record<
  SkillRatingsDistributionGroupTypes,
  DistributionKey
> = {
  [SkillRatingsDistributionGroupTypes.Distribution]: 'rating_category',
  [SkillRatingsDistributionGroupTypes.Benchmark]: 'rating_expectation',
}

export const getTalentSkillRatingTableRequests = (): TableRequestInterface<
  TalentSkillRatingInterface,
  undefined
> => ({
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get<GetRequestData<TalentSkillRatingInterface>>('/performance/skillsTalent', {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getTalentSkillsCompareRatingTableRequests = (): TableRequestInterface<
  TalentSkillRatingComparableInterface,
  undefined
> => ({
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get<GetRequestData<TalentSkillRatingComparableInterface>>(
      '/performance/skillsCompare',
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
})

export const useGetTalentSkillRatingHistory = (employeeId?: number, skillId?: number) => {
  return useFetchV2<TalentSkillRatingInterface[]>({
    url: '/performance/skillsTalent/history',
    params: { params: { employee_id: employeeId, skill_id: skillId } },
    queryOptions: {
      enabled: employeeId !== undefined && skillId !== undefined,
    },
  })
}

export const useGetSkillsRatingsDistribution = (
  params: SkillRatingsDistributionParams,
) => {
  return useFetchV2<SkillRatingsDistributionInterface[]>({
    url: '/performance/skillsAnalytics',
    params: { params: adaptSkillRatingsDistributionParamsToQueryParams(params) },
    queryOptions: {
      enabled: params.cycle_id !== undefined,
    },
  })
}

const adaptSkillRatingsDistributionParamsToQueryParams = (
  params: SkillRatingsDistributionParams,
): Record<keyof SkillRatingsDistributionParams, string> =>
  Object.entries(params).reduce<Record<string, string>>((adaptedParams, [key, value]) => {
    if (typeof value === 'string') {
      return {
        ...adaptedParams,
        [key]: value,
      }
    }

    if (typeof value === 'number') {
      return {
        ...adaptedParams,
        [key]: String(value),
      }
    }

    if (Array.isArray(value)) {
      return {
        ...adaptedParams,
        [key]: value.join(','),
      }
    }

    return adaptedParams
  }, {})
