import type { ActionItem } from '@src/interfaces/meetings'
import { isNumber } from 'lodash'
import { useEffect, useRef, useState } from 'react'

export const useActionPointContent = (actionPoints: ActionItem[]) => {
  const [isEdited, setIsEdited] = useState(false)
  const textAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({})
  const [actionItems, setActionItems] = useState<(ActionItem & { id: number })[]>(
    actionPoints.length
      ? actionPoints.map((item, index) => ({
          completed: item.completed,
          id: index,
          value: item.value,
        }))
      : [{ id: 0, value: '', completed: false }],
  )

  useEffect(() => {
    setActionItems(
      actionPoints.length
        ? actionPoints.map((item, index) => ({
            completed: item.completed,
            id: index,
            value: item.value,
          }))
        : [{ id: 0, value: '', completed: false }],
    )
  }, [actionPoints])

  const updateContent = (id: number, newContent: string) => {
    setIsEdited(true)
    if (!newContent) {
      const newActionItems = actionItems.filter(item => item.id !== id)
      if (newActionItems.length === 0) {
        setActionItems([{ id: 0, value: '', completed: false }])
        textAreaRefs.current[0]?.focus()
        return
      }
      setActionItems(newActionItems)

      const previousItemId = actionItems.findIndex(item => item.id === id) - 1
      const lastItemId = newActionItems.at(-1)?.id
      const focusRefId = previousItemId >= 0 ? previousItemId : lastItemId
      if (isNumber(focusRefId)) {
        const textArea = textAreaRefs.current[focusRefId]
        textArea?.focus()
        // Move cursor to the end of the text
        if (textArea) {
          const textLength = textArea.value.length
          textArea.setSelectionRange(textLength, textLength)
        }
      }
    } else {
      setActionItems(prev => {
        return prev.map(item => {
          if (item.id === id) {
            item.value = newContent
          }
          return item
        })
      })
    }
  }

  const toggleComplete = (id: number) => {
    setIsEdited(true)
    setActionItems(prev =>
      prev.map(item => {
        if (item.id === id) {
          item.completed = !item.completed
        }
        return item
      }),
    )
  }

  const addNew = () => {
    const newId = actionItems.length
    setActionItems(prev => [...prev, { id: newId, value: '', completed: false }])
    setTimeout(() => {
      textAreaRefs.current[newId]?.focus()
    }, 0)
  }

  const insertAt = (initialString: string, index: number, pasteValue: string): string => {
    return initialString.slice(0, index) + pasteValue + initialString.slice(index)
  }

  const onPasteActionItems = (id: number, elements: string[]) => {
    const cursorPosition = textAreaRefs.current[id]?.selectionStart
    const [firstElement, ...rest] = elements
    let actionItemsCopy = [...actionItems]

    const pasteFirstToCursorPosition = () => {
      if (isNumber(cursorPosition)) {
        actionItemsCopy = actionItemsCopy.map(item => {
          if (item.id === id) {
            return { ...item, value: insertAt(item.value, cursorPosition, firstElement) }
          }
          return item
        })
      }
    }

    const pastedContentAfterCursorItem = () => {
      const newActionItems = actionItemsCopy.slice(0, id + 1)

      rest.forEach((element, index) => {
        newActionItems.push({ id: index + id + 1, value: element, completed: false })
      })
      const lastAddedItemId = actionItemsCopy.at(-1)?.id

      actionItemsCopy.slice(id + 1).forEach(element => {
        newActionItems.push({ ...element, id: element.id + rest.length })
      })

      return { newActionItems, lastAddedItemId }
    }

    pasteFirstToCursorPosition()
    const { newActionItems, lastAddedItemId } = pastedContentAfterCursorItem()

    setIsEdited(true)
    setActionItems(newActionItems)

    setTimeout(() => {
      if (lastAddedItemId) {
        textAreaRefs.current[lastAddedItemId]?.focus()
      }
    }, 0)
  }

  return {
    addNew,
    updateContent,
    isEdited,
    actionItems,
    toggleComplete,
    textAreaRefs,
    onPasteActionItems,
  }
}
