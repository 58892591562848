import React, { useEffect } from 'react'

import { VStack, Token, Text, HStack, Avatar, Flex, Tag } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'

import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'

import { MetricTypeSelectorValue, useMetricTypeSelector } from './useMetricTypeSelector'

interface Props {
  onSelect: (value?: UpdateTypes) => void
}

export const MetricTypeSelector = ({ onSelect }: Props) => {
  const { apiErrors, values } = useLapeContext<KpiInterface & { tempId?: number }>()
  const { data: options } = useMetricTypeSelector()

  const updateTypeOption = values.update_type
    ? {
        id: values.update_type,
        value: {
          ...options.find(option => option.value.id === values.update_type)?.value,
        },
      }
    : undefined

  useEffect(() => {
    if (updateTypeOption?.value.connected) {
      onSelect(undefined)
    } else {
      onSelect(updateTypeOption?.id)
    }
  }, [updateTypeOption])

  return (
    <LapeRadioSelectInput<MetricTypeSelectorValue>
      label="Metric type"
      hasError={!updateTypeOption && !!apiErrors.targets?.length}
      message={
        !updateTypeOption && !!apiErrors.targets?.length
          ? 'Metric type is required'
          : undefined
      }
      value={updateTypeOption}
      onChange={option => {
        values.update_type = option?.id
      }}
      options={options}
      name="update_type"
      searchable={false}
    >
      {option => {
        const { image, icon, name, description, iconBackground, connected } = option.value
        return (
          <HStack space="s-16" width="100%">
            <Avatar
              useIcon={icon}
              image={image}
              color={Token.color.foreground}
              bg={iconBackground}
            />
            <VStack m="-s-2" width="100%">
              <Flex justifyContent="space-between" width="100%">
                <Text variant="primary">{name}</Text>
                {!connected && (
                  <Tag variant="outlined" color={Token.color.greyTone50} useIcon="Dot">
                    <Text>Not Connected</Text>
                  </Tag>
                )}
              </Flex>
              <Text variant="caption" color={Token.color.greyTone50}>
                {description}
              </Text>
            </VStack>
          </HStack>
        )
      }}
    </LapeRadioSelectInput>
  )
}
