import { RadioOption } from '@src/components/Inputs/NewRadioButtons/NewRadioButtons'
import { SkillType, SkillTypeTitle } from '@src/interfaces/skills'

export const skillTypeOptions: RadioOption<SkillType>[] = [
  {
    value: SkillType.Functional,
    label: SkillTypeTitle[SkillType.Functional],
    tooltip:
      'Function-specific skills with a single definition that are primarily used and controlled by one function.',
  },
  {
    value: SkillType.Executive,
    label: SkillTypeTitle[SkillType.Executive],
    tooltip:
      'Cross-functional skills with a common definition that are used throughout all areas of the organisation.',
  },
  {
    value: SkillType.Culture,
    label: SkillTypeTitle[SkillType.Culture],
  },
]
