import React from 'react'
import {
  performanceSummaryRatingColumnNewDesign,
  performanceSummarySkillReviewerColumn,
} from '@src/constants/columns/performanceSummary'
import { RowInterface } from '@src/interfaces/data'
import { RatingReviewerColumn } from '../common/RatingReviewerColumn'
import {
  SkillCardInterface,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { QuickFilter } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Filter'
import { getRatingSummaryColumnValue } from '../common/getRatingSummaryColumnValue'
import { getRatingColumnValue } from '../common/getRatingColumnValue'
import { performanceSummarySkillsNameColumn } from './columns'

export const getSkillsRow: (
  activeFilters: QuickFilter[] | undefined,
  isSummaryReview: boolean,
) => RowInterface<SkillCardInterface | SummarySkillCardInterface> = (
  activeFilters,
  isSummaryReview,
) => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 50,
    },
    {
      ...performanceSummarySkillReviewerColumn,
      width: 50,
      insert: () => <RatingReviewerColumn activeFilters={activeFilters} />,
    },
    {
      ...performanceSummaryRatingColumnNewDesign,
      textAlign: 'right',
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data, data.rating_expectation)
          : getRatingColumnValue(data, data.rating_expectation),
      title: isSummaryReview ? 'Rating' : 'Latest rating',
      background: undefined,
      width: activeFilters ? 18 : 40,
    },
  ],
})
