import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HStack, Icon, List, Text, TextButton, VStack, Widget } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { SkillInterface } from '@src/interfaces/skills'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { SkillOneToOneInsightsInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { SummaryFormatted } from '@src/features/AiInsights/Performance/components/Summary/Summary'
import { InternalLink } from '@components/InternalLink/InternalLink'
import {
  CYCLE_ID_QUERY_PARAM_KEY,
  MEETING_EVENT_ID_QUERY_PARAM_KEY,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/constants'

interface Props {
  cycleId: number
  insights: SkillOneToOneInsightsInterface[]
  oneToOneMeeting: OneToOneMeeting | null
  skill: SkillInterface
}

export const OneToOneQuotes = ({ cycleId, insights, oneToOneMeeting, skill }: Props) => {
  const createFeedbackLink = (insight: SkillOneToOneInsightsInterface) => {
    if (!oneToOneMeeting) {
      return undefined
    }

    const params = {
      meetingId: oneToOneMeeting.id,
      employeeId: oneToOneMeeting.employee.id,
      managerId: oneToOneMeeting.manager.id,
    }
    const queryParams = {
      [CYCLE_ID_QUERY_PARAM_KEY]: String(cycleId),
      [MEETING_EVENT_ID_QUERY_PARAM_KEY]: String(insight.feedback_item.meeting.id),
    }

    return pathToUrl(
      ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.SUMMARY,
      params,
      queryParams,
    )
  }

  return (
    <VStack space="s-16">
      <HStack space="s-4" align="center">
        <AiIcon />
        <Text fontSize={18} fontWeight={500}>
          <FormattedMessage
            id="performance.skills.insights.121.notes.quotes.title"
            values={{
              skillName: skill.name,
            }}
            defaultMessage="Notes on {skillName}"
          />
        </Text>
      </HStack>
      <Widget p="s-16">
        <List variant="compact" space="s-8">
          {insights.map((insight, idx) => (
            <List.Item key={idx} useIcon="Dot">
              <OneToOneQuote
                insight={insight}
                feedbackLink={createFeedbackLink(insight)}
              />
            </List.Item>
          ))}
        </List>
      </Widget>
    </VStack>
  )
}

interface QuoteProps {
  insight: SkillOneToOneInsightsInterface
  feedbackLink?: string
}

const OneToOneQuote = ({ insight, feedbackLink }: QuoteProps) => {
  if (!insight.one_to_one_quote) {
    return null
  }

  return (
    <VStack>
      <SummaryFormatted summary={insight.one_to_one_quote} />
      {feedbackLink && (
        <TextButton use={InternalLink} to={feedbackLink} target="_blank">
          <HStack space="s-8" align="center">
            <Icon name="LinkExternal" size={16} />
            <FormattedMessage
              id="performance.skills.insights.121.notes.quote.preview.cta.label"
              defaultMessage="See 1:1 summary"
            />
          </HStack>
        </TextButton>
      )}
    </VStack>
  )
}
