import { useCallback, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import { OptionInterface } from '@src/interfaces/selectors'
import { getFiltersToParams } from './helpers'

export const useQuestionsFilters = () => {
  const [searchValue, setSearchValue] = useState('')
  const [textFilter, setTextFilter] = useState('')
  const [driversFilter, setDriversFilter] = useState<OptionInterface[]>([])

  const filtersParams = useMemo(() => {
    return getFiltersToParams({
      textFilter,
      driversFilter,
    })
  }, [textFilter, driversFilter])

  const setTextFilterDebounced = useCallback(debounce(setTextFilter, 500), [])

  return {
    filtersParams,
    search: {
      searchValue,
      setSearchValue,
    },
    filters: {
      setTextFilter: setTextFilterDebounced,
      driversFilter,
      setDriversFilter,
    },
  }
}
