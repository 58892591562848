import { EntityTypes } from '@src/constants/api'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EmployeeInterface } from '@src/interfaces/employees'

export const isEmployeeEntity = (
  entity: OrgEntityInterface,
): entity is {
  type: EntityTypes.employees | EntityTypes.employee
  data: EmployeeInterface & { name?: string }
} => [EntityTypes.employee, EntityTypes.employees].includes(entity.type)
