import React, { FormEvent, useMemo, useRef, useState } from 'react'

import { CheckboxSelect, Icon, Input, SelectOptionType, VStack } from '@revolut/ui-kit'
import { searchLinear } from '@src/api/roadmaps'
import { debounce } from 'lodash'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { EpicTaskOption } from '@src/interfaces/kpis'
import { LinearSearchResult } from '@src/interfaces/roadmaps'
import { LinearSelectOption } from './components/LinearSelectOption'
import { LinearSelectedTaskWidget } from './components/LinearSelectedTaskWidget'

interface Props {
  onSelectionChange: (epics: LinearSearchResult[]) => void
  onDelete: (key: string) => void
  addedTasks: EpicTaskOption[] | undefined
  excludedTasks?: { key: string }[]
  error?: string
}

export const LinearTasks = ({
  onSelectionChange,
  onDelete,
  addedTasks,
  excludedTasks,
  error,
}: Props) => {
  const inputRef = useRef(null)
  const [searchPending, setSearchPending] = useState(false)
  const [searchValue, setSearchValue] = useState<string>()
  const [options, setOptions] = useState<LinearSearchResult[]>([])
  const [errorMessage, setErrorMessage] = useState<string>()

  const debouncedSearch = useMemo(() => {
    setErrorMessage(undefined)
    return debounce(async (searchString: string) => {
      try {
        const { data } = await searchLinear({ searchQuery: searchString })
        if (data) {
          setOptions(data)
        }
      } catch (e) {
        setErrorMessage(getMessageFromApiError(e) || undefined)
      } finally {
        setSearchValue(searchString) // this just resets the search strings as on close this will be cleared (if you re-search)
        setSearchPending(false)
      }
    }, 1000)
  }, [])

  const onSearch = (searchString?: string) => {
    setErrorMessage(undefined)
    if (searchString === searchValue) {
      return
    }
    setOptions([])
    setSearchValue(searchString)
    if (searchString) {
      setSearchPending(true)
      debouncedSearch(searchString)
    }
  }

  return (
    <VStack space="s-16">
      <Input
        invalid={!!errorMessage}
        errorMessage={errorMessage}
        containerRef={inputRef}
        placeholder="Search Linear projects & issues"
        pending={searchPending}
        onChange={(ev: FormEvent<HTMLInputElement>) => onSearch(ev?.currentTarget?.value)}
        renderAction={() => (searchPending ? undefined : <Icon name="Search" />)}
        value={searchValue}
      />
      <CheckboxSelect<LinearSearchResult, SelectOptionType<LinearSearchResult>>
        labelList="Tasks"
        value={[]}
        open={!!searchValue && !searchPending}
        anchorRef={inputRef}
        onClose={onSearch}
        options={options.map(option => ({
          value: option,
          label: option.name,
          key: option.id,
          disabled: !![...(excludedTasks || []), ...(addedTasks || [])].find(
            ({ key }) => key === option.id,
          ),
        }))}
        fitInAnchor
        labelNoResults="No tasks found"
        labelClear="Cancel"
        type="confirm"
        labelApply="Add item"
        onChange={onSelectionChange}
      >
        {option => (
          <LinearSelectOption
            key={option.key}
            label={option.label}
            taskId={option.value?.id}
            url={option.value?.url}
            type={option.value?.type}
          />
        )}
      </CheckboxSelect>
      <LinearSelectedTaskWidget
        tasks={addedTasks || []}
        error={error}
        onDelete={onDelete}
      />
    </VStack>
  )
}
