import React from 'react'

import { Action } from '@revolut/ui-kit'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { SEARCH_COLUMN_NAME } from '@components/TableV2/SearchTable/SearchTable'
import { FilterByInterface } from '@src/interfaces/data'
import { getAppliedFiltersCount } from '@components/FiltersSidebar/FiltersSidebar'
import { candidateTableFilters } from '@src/features/CommonCandidatesTable/candidateTableFilters'

interface CommonCandidatesTableEmptyStateProps {
  filters?: FilterByInterface[]
  onClear: VoidFunction
}

export const CommonCandidatesTableEmptyState = ({
  filters,
  onClear,
}: CommonCandidatesTableEmptyStateProps) => {
  const searchFilter = filters?.find(item => item.columnName === SEARCH_COLUMN_NAME)

  if (filters && searchFilter) {
    const hasAppliedFilters = getAppliedFiltersCount(filters, candidateTableFilters) > 0

    return (
      <EmptyTableRaw
        title="Couldn’t find any records that match your search criteria."
        description={
          hasAppliedFilters ? 'Try clearing filters for more results' : undefined
        }
        action={
          hasAppliedFilters ? <Action onClick={onClear}>Clear filters</Action> : undefined
        }
      />
    )
  }

  return (
    <EmptyTableRaw title="Candidates will appear here. Please check your referred candidates in your HR profile" />
  )
}
