import React from 'react'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import { Flex, Text } from '@revolut/ui-kit'
import { SummaryDataPoint } from '@src/features/AiInsights/Performance/components/Metrics/common/OneToOnes/Summary/DataPoint'
import { GradeProps } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/MeetingsSummary/hooks/useMeetingsSummary'

interface Props {
  meetingsCount: number
  actionsCount: number
  gradesCount: number
  isRatingVisible: boolean
  isActionItemsVisible: boolean
  gradeProps: GradeProps
}

export const SummaryHighlights = ({
  meetingsCount,
  actionsCount,
  gradesCount,
  isRatingVisible,
  isActionItemsVisible,
  gradeProps,
}: Props) => {
  return (
    <Flex gap="s-8">
      {isRatingVisible && (
        <SummaryDataPoint
          label={
            <FormattedMessage
              id="one-to-one.meetings.summary.stats.avg.score.label"
              defaultMessage="Average score"
            />
          }
          value={<Text color={gradeProps.color}>{gradeProps.label}</Text>}
          metric={
            <FormattedPlural
              value={gradesCount}
              one={
                <FormattedMessage
                  id="one-to-one.meetings.summary.stats.avg.score.one.description"
                  values={{ count: gradesCount }}
                  defaultMessage="based on {count} review"
                />
              }
              other={
                <FormattedMessage
                  id="one-to-one.meetings.summary.stats.avg.score.other.description"
                  values={{ count: gradesCount }}
                  defaultMessage="based on {count} reviews"
                />
              }
            />
          }
          flex={1}
        />
      )}
      <SummaryDataPoint
        label={
          <FormattedMessage
            id="one-to-one.meetings.summary.stats.meetings.count.label"
            defaultMessage="Meetings"
          />
        }
        value={<Text>{meetingsCount}</Text>}
        metric={
          <FormattedMessage
            id="one-to-one.meetings.summary.stats.meetings.count.description"
            defaultMessage="this cycle"
          />
        }
        flex={1}
      />
      {isActionItemsVisible && (
        <SummaryDataPoint
          label={
            <FormattedMessage
              id="one-to-one.meetings.summary.stats.actions.count.label"
              defaultMessage="Actions"
            />
          }
          value={<Text>{actionsCount}</Text>}
          metric={
            <FormattedMessage
              id="one-to-one.meetings.summary.stats.actions.count.description"
              defaultMessage="recorded"
            />
          }
          flex={1}
        />
      )}
    </Flex>
  )
}
