import React from 'react'

import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'

import { Scorecard } from '@src/pages/Performance/Scorecard/Scorecard'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import { selectorKeys } from '@src/constants/api'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { isNumber } from 'lodash'

export const PerformanceReviewScorecard = () => {
  const { initialCycleOffset, loading } = useSelectedPerformanceCycle(
    selectorKeys.all_review_cycles,
    true,
  )
  const { data: cycle, isLoading } = useGetReviewCycleByOffset(
    isNumber(initialCycleOffset) ? initialCycleOffset : null,
  )
  if (loading || isLoading) {
    return <PageLoading />
  }

  return <Scorecard cycle={cycle} />
}
