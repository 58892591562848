import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'

import { useParams } from 'react-router-dom'
import { useGetEmployeeMeeting } from '@src/api/meetings'
import { FilterByInterface } from '@src/interfaces/data'
import { SummaryHeader } from './components/SummaryHeader'
import { useReviewCycleData } from './hooks/useReviewCycleData'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'

import { useQuery } from '@src/utils/queryParamsHooks'
import { oneToOneModel } from '../models/oneToOneModel'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'
import { MeetingNotesContextProvider } from './context/meetingNoteContext'
import { MeetingNotePageBody } from './components/MeetingNotePageBody/MeetingNotePageBody'
import {
  CYCLE_ID_QUERY_PARAM_KEY,
  MEETING_EVENT_ID_QUERY_PARAM_KEY,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/constants'
import { useGetEmployee } from '@src/api/employees'

export const OneToOneSummary = () => {
  const canViewMeeting = useHasGlobalPermission(PermissionTypes.ViewMeeting)

  const currentUser = useSelector(selectUser)

  const params = useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const { query, changeQueryParam, deleteQueryParam } = useQuery<{
    [CYCLE_ID_QUERY_PARAM_KEY]?: string
    [MEETING_EVENT_ID_QUERY_PARAM_KEY]?: string
  }>()

  const employeeId = Number(params.employeeId)
  const meetingId = Number(params.meetingId)
  const managerId = Number(params.managerId)

  const { data: oneToOneMeeting, isLoading: isLoadingEmployeeManagerMeeting } =
    useGetEmployeeMeeting(employeeId, meetingId)

  const {
    isLoading: isReviewCyclesLoading,
    selectedCycle,
    reviewCycleOptions,
  } = useReviewCycleData({
    activeCycleId: query.cycle_id,
    employeeId,
    managerId,
  })

  const { data: employee, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)

  const onCycleChange = (cycle: FilterByInterface) => {
    if (cycle.filters.at(0)?.id) {
      changeQueryParam(CYCLE_ID_QUERY_PARAM_KEY, String(cycle.filters.at(0)?.id))
    }
    deleteQueryParam(MEETING_EVENT_ID_QUERY_PARAM_KEY)
  }

  const canSeeMeetingDetails =
    canViewMeeting ||
    (oneToOneMeeting && oneToOneModel.isMeetingParticipant(currentUser, oneToOneMeeting))

  if (!isLoadingEmployeeManagerMeeting && !canSeeMeetingDetails) {
    return <PageNotFoundWidget />
  }

  return (
    <PageWrapper>
      <SummaryHeader
        employee={employee ?? null}
        meeting={oneToOneMeeting ?? null}
        cycle={selectedCycle ?? null}
        isLoading={isLoadingEmployeeManagerMeeting || isEmployeeLoading}
      />

      <CycleFilterButton
        isLoading={isReviewCyclesLoading}
        columnName={CYCLE_ID_QUERY_PARAM_KEY}
        filter={
          selectedCycle
            ? [
                {
                  columnName: CYCLE_ID_QUERY_PARAM_KEY,
                  filters: [{ id: selectedCycle.id, name: selectedCycle?.name }],
                },
              ]
            : []
        }
        onFilterChange={onCycleChange}
        selector={() => Promise.resolve(reviewCycleOptions)}
      />
      <MeetingNotesContextProvider employeeId={employeeId} managerId={managerId}>
        <MeetingNotePageBody />
      </MeetingNotesContextProvider>
    </PageWrapper>
  )
}
