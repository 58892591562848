import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import { useFetch, useFetchV2 } from '@src/utils/reactQuery'
import { ApplicationCandidateInterface } from '@src/interfaces/applicationCandidate'

export const useGetHiringPipeline = (id?: number | string | null) =>
  useFetchV2<ApplicationCandidateInterface>({
    url: `/hiringPipelines/${id}`,
    queryOptions: { enabled: Boolean(id) },
  })

export const useGetHiringPipelineByJobPostingId = (id: number | string) =>
  useFetch<{ results: HiringPipelineInterface[] }>('/hiringPipelines', undefined, {
    params: {
      job_posting__id: id,
    },
  })
