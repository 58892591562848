import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import FilterSelect, { FilterInputSelectorType, FilterSelectType } from '../FilterSelect'
import { CycleOption } from '@src/interfaces/selectors'
import { StatisticsTitle, StatValue } from '@components/CommonSC/TableStatistics'
import { FilterByInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import Tooltip from '@components/Tooltip/Tooltip'
import { ChevronDown } from '@revolut/icons'
import {
  Caption,
  H4,
  Skeleton,
  Text,
  Token,
  FilterButton,
  Flex,
  Ellipsis,
  Icon,
} from '@revolut/ui-kit'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'

const Container = styled.div``
const FilterContainer = styled.div`
  &:not(:last-child):after {
    content: ', ';
  }
`
const SpanFilterContainer = styled.span`
  &:not(:last-child):after {
    content: ', ';
  }
`
const FiltersContainer = styled(StatValue)`
  cursor: pointer;
  align-items: center;
  color: ${Token.color.greyTone50};

  &:hover {
    color: ${Token.color.foreground};
  }
`

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
`

const SwitchButton = styled.button<{ isNext?: boolean }>`
  margin: ${props => (props.isNext ? '0 0 0 4px' : '0 4px 0 0')};

  &:disabled {
    cursor: not-allowed;
  }
`

const TooltipBody = styled.div`
  width: 250px;
  padding: 16px;
  font-size: 13px;
  line-height: 18px;
  color: ${Token.color.greyTone20};
`

export enum CycleFilterType {
  None = 'None',
  Switcher = 'Switcher',
  NewUI = 'NewUI',
  Filter = 'Filter',
}

export interface CycleFilterProps {
  className?: string
  placeholder?: string
  onFilterChange: (filters: FilterByInterface, reset?: boolean) => void
  columnName: string
  filter: FilterByInterface[]
  type?: CycleFilterType
  filterInputType?: FilterSelectType
  disabled?: boolean
  selector?: FilterInputSelectorType<CycleOption>
  label?: string
  onChange?: (option: CycleOption) => void
}

export const CycleFilter = ({
  className,
  onFilterChange,
  placeholder = 'Select Cycle',
  filter,
  columnName,
  type = CycleFilterType.None,
  selector = selectorKeys.cycle_offsets,
  filterInputType,
  disabled,
  label = 'Cycle',
  onChange,
}: CycleFilterProps) => {
  const cycleFilterRef = useRef<HTMLDivElement>(null)
  const [openCycleFilter, setOpenCycleFilter] = useState(false)
  const { options, asyncState } = useFetchOptions<CycleOption>(selector)
  const reviewCycles = options?.map(option => option.value)

  const filteredFilters = useMemo(
    () =>
      filter
        ?.find(
          (filterBy: FilterByInterface): filterBy is FilterByInterface<CycleOption> =>
            filterBy.columnName === columnName,
        )
        ?.filters.filter(option => option.id !== '' && option.name !== ''),
    [filter],
  )

  const getDisabledMsg = (isNextButton: boolean) => {
    const offset = isNextButton ? -1 : 1
    const selectedFilters = filter?.find(
      (filterBy: FilterByInterface) => filterBy.columnName === columnName,
    )?.filters

    if (selectedFilters?.length === 1) {
      const foundCycle = reviewCycles?.find(
        cycle => cycle.id === +selectedFilters[0].id + offset,
      )

      if (!foundCycle) {
        return isNextButton
          ? 'Next cycle is not available'
          : 'Previous cycle is not available'
      }

      return ''
    }

    if (selectedFilters && selectedFilters.length > 1) {
      return 'You can not switch between cycles when multiple cycles are selected. Select a single cycle to enable the feature again.'
    }

    return ''
  }

  const disabledMsgPrevButton = useMemo(() => {
    return getDisabledMsg(false)
  }, [filter, reviewCycles])

  const disabledMsgNextButton = useMemo(() => {
    return getDisabledMsg(true)
  }, [filter, reviewCycles])

  const handleOpenCycleFilter = () => {
    if (disabled) {
      return
    }
    setOpenCycleFilter(true)
  }

  const handleCloseCycleFilter = () => {
    setOpenCycleFilter(false)
  }

  const handleCycleFilterChange = (option: CycleOption[]) => {
    onFilterChange(
      {
        columnName,
        filters: option,
      },
      false,
    )
    setOpenCycleFilter(false)
    onChange && onChange(option[0])
  }

  const onClickSwitch = (isNext: boolean) => {
    const offset = isNext ? -1 : 1
    const selectedFilters = filter?.find(
      (filterBy: FilterByInterface) => filterBy.columnName === columnName,
    )?.filters

    if (selectedFilters?.length === 1) {
      const foundCycle = reviewCycles?.find(
        cycle => cycle.id === +selectedFilters[0].id + offset,
      )

      if (foundCycle) {
        onFilterChange(
          {
            columnName,
            filters: [foundCycle],
          },
          false,
        )
      }
    }
  }

  const hideOpenArrow =
    (type === CycleFilterType.Switcher && filteredFilters?.length) || disabled

  const renderReviewCyclesButton = () => {
    if (type === CycleFilterType.Filter) {
      return (
        <FilterButton
          useIcon="Calendar"
          aria-haspopup="listbox"
          onClick={() => setOpenCycleFilter(!openCycleFilter)}
          aria-expanded={openCycleFilter}
        >
          <Flex flexWrap="nowrap" gap="s-8" alignItems="center" maxWidth={200}>
            <Ellipsis>
              {filteredFilters?.length
                ? filteredFilters
                    .map(filteredFilter => {
                      return reviewCycles.find(
                        reviewCycle =>
                          String(reviewCycle.id) === String(filteredFilter.id),
                      )?.name
                    })
                    .join(', ')
                : placeholder}
            </Ellipsis>

            <Icon name={openCycleFilter ? 'ChevronUp' : 'ChevronDown'} />
          </Flex>
        </FilterButton>
      )
    }
    if (type === CycleFilterType.NewUI) {
      if (asyncState === 'pending') {
        return <Skeleton width={80} height={20} mt="5px" mb="s-2" />
      }

      return (
        <>
          <H4 use="span" color="foreground" fontWeight="bold">
            {filteredFilters?.length
              ? filteredFilters.slice(0, 4).map((filteredFilter, id) => {
                  const filteredCycle = reviewCycles.find(
                    reviewCycle => String(reviewCycle.id) === String(filteredFilter.id),
                  )

                  if (!filteredCycle) {
                    return placeholder
                  }

                  return (
                    <SpanFilterContainer key={id}>
                      {`${filteredCycle.name} ${id === 3 ? '...' : ''}`}
                    </SpanFilterContainer>
                  )
                })
              : placeholder}
          </H4>
          {!hideOpenArrow && <ChevronDown color="foreground" hoverColor="grey-tone-50" />}
        </>
      )
    }
    return (
      <>
        {reviewCycles ? (
          <FiltersContainer>
            {filteredFilters?.length
              ? filteredFilters.slice(0, 4).map((filteredFilter, id) => {
                  const filteredCycle = reviewCycles.find(
                    reviewCycle => String(reviewCycle.id) === String(filteredFilter.id),
                  )

                  return (
                    <FilterContainer key={id}>
                      {`${filteredCycle?.name} ${id === 3 ? '...' : ''}`}
                    </FilterContainer>
                  )
                })
              : placeholder}
          </FiltersContainer>
        ) : (
          <Icon type="Spinner" size="tiny" />
        )}{' '}
        {!hideOpenArrow && <Icon type="ArrowDown" size="tiny" />}
      </>
    )
  }

  return (
    <Container className={className}>
      {cycleFilterRef.current && (
        <FilterSelect
          open={openCycleFilter}
          anchorRef={cycleFilterRef}
          onClose={handleCloseCycleFilter}
          value={filteredFilters}
          onChange={handleCycleFilterChange}
          selector={selector}
          type={filterInputType}
          width={290}
        >
          {option => {
            if (option.value) {
              return (
                <>
                  <Text use="p">{option.value.name} </Text>
                  <Text use="p" variant="caption" color="grey-tone-50">
                    {option.value.sub_title}
                  </Text>
                </>
              )
            }
            return null
          }}
        </FilterSelect>
      )}
      {type !== CycleFilterType.NewUI && type !== CycleFilterType.Filter && (
        <StatisticsTitle>Cycle</StatisticsTitle>
      )}
      <BtnContainer>
        {type === CycleFilterType.Switcher && filteredFilters?.length ? (
          <Tooltip
            placement={'bottom'}
            text={disabledMsgPrevButton}
            hide={!disabledMsgPrevButton}
            body={
              disabledMsgPrevButton.length > 50 ? (
                <TooltipBody>{disabledMsgPrevButton}</TooltipBody>
              ) : undefined
            }
          >
            <SwitchButton
              disabled={disabled || !!disabledMsgPrevButton}
              type="button"
              onClick={() => {
                onClickSwitch(false)
              }}
            >
              <Icon type={'ArrowBack'} size={'tiny'} />
            </SwitchButton>
          </Tooltip>
        ) : null}

        <FiltersContainer ref={cycleFilterRef} onClick={handleOpenCycleFilter}>
          {renderReviewCyclesButton()}
        </FiltersContainer>
        {type === CycleFilterType.Switcher && filteredFilters?.length ? (
          <Tooltip
            placement={'bottom'}
            text={disabledMsgNextButton}
            hide={!disabledMsgNextButton}
            body={
              disabledMsgNextButton.length > 50 ? (
                <TooltipBody>{disabledMsgNextButton}</TooltipBody>
              ) : undefined
            }
          >
            <SwitchButton
              disabled={disabled || !!disabledMsgNextButton}
              isNext
              type="button"
              onClick={() => {
                onClickSwitch(true)
              }}
            >
              <Icon type={'ArrowForward'} size={'tiny'} />
            </SwitchButton>
          </Tooltip>
        ) : null}
      </BtnContainer>
      {type === CycleFilterType.NewUI && <Caption color="grey-tone-50">{label}</Caption>}
    </Container>
  )
}
