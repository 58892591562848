import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'

interface GrowthPlanActionModel {
  isCompleted: (action: GrowthPlanActionInterface) => boolean
  hasAssociatedSkill: (action: GrowthPlanActionInterface, skillId: number) => boolean
}

const isCompleted = (action: GrowthPlanActionInterface) => action.is_completed

const hasAssociatedSkill = (action: GrowthPlanActionInterface, skillId: number) =>
  action.content_type?.model === 'skillset' && action.object_id === skillId

const createGrowthPlanActionModel = (): GrowthPlanActionModel => {
  return {
    isCompleted,
    hasAssociatedSkill,
  }
}

export const growthPlanActionModel = createGrowthPlanActionModel()
