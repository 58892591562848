import React, { useEffect, useMemo, useState } from 'react'
import { Box, FilterButton, Flex, useToggle, Widget } from '@revolut/ui-kit'
import {
  LargeWeeklyCalendar,
  LargeWeeklyCalendarEventInterface,
} from '@components/LargeWeeklyCalendar'
import { getCurrentWeek, NavigationWeek } from '@components/NavigateWeek/NavigateWeek'
import { useCalendarEvents } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/hooks/useCalendarEvents'
import { meetingRecurrence } from '@src/interfaces/meetingsTracker'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { TimezoneSelector } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/TimezoneSelector'
import { getCurrentTimezone } from '@src/utils/timezones'
import {
  RecurrenceOption,
  RecurrenceSelector,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/RecurrenceSelector'
import { Timezone } from '@src/interfaces/selectors'
import { MeetingTemplateInterface, OneToOneMeeting } from '@src/interfaces/meetings'
import { useScheduleEvent } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/hooks/useScheduleEvent'
import { endOfDay } from 'date-fns'
import { startDayOfWeek } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/constants'
import {
  defaultOneToOneMeetingFrequency,
  getMeetingRecurrenceWithFallback,
  meetingFrequencyToRecurrenceMap,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/constants/meeting'
import { utcToZonedTime } from 'date-fns-tz'
import { isString } from 'lodash'

interface Props {
  meeting: OneToOneMeeting
  template?: MeetingTemplateInterface
  handleSelectEvent: (
    event: LargeWeeklyCalendarEventInterface | undefined,
    recurrence: meetingRecurrence,
    timezone: Timezone,
  ) => void
}

export const ScheduleOneToOneCalendar = ({
  meeting,
  template,
  handleSelectEvent,
}: Props) => {
  const [recurrence, setRecurrence] = useState<meetingRecurrence>(
    getMeetingRecurrenceWithFallback(template?.frequency ?? null),
  )
  const [freeBusyOverlap, busyBusyOverlapToggler] = useToggle({ defaultState: true })
  const [week, setWeek] = useState<NavigationWeek>(
    getCurrentWeek({ weekStartsOn: startDayOfWeek }),
  )
  const [timezone, setTimezone] = useState<Timezone>()
  const [defaultTimezone, setDefaultTimezone] = useState<Timezone>()
  const { data: timezones } = useGetSelectors<Timezone>(selectorKeys.timezones)

  const { events } = useCalendarEvents(meeting, freeBusyOverlap, week)
  const {
    event: schedulingEvent,
    updateEvent,
    addEvent,
  } = useScheduleEvent(meeting, week, template)

  useEffect(() => {
    const tz = timezones?.find(({ id }) => id === getCurrentTimezone())

    setTimezone(tz)
    setDefaultTimezone(tz)
  }, [timezones])

  useEffect(() => {
    if (!template) {
      return
    }

    setRecurrence(getMeetingRecurrenceWithFallback(template.frequency))
  }, [template?.frequency])

  useEffect(() => {
    if (timezone === undefined) {
      return
    }

    if (schedulingEvent !== undefined) {
      handleSelectEvent(schedulingEvent, recurrence, timezone)
    }
  }, [schedulingEvent, timezone, recurrence, handleSelectEvent])

  const timezoneOptions = useMemo(() => {
    if (!defaultTimezone || !timezone || timezone.id === defaultTimezone.id) {
      return { zones: [] }
    }

    return {
      zones: [defaultTimezone, timezone].map(({ id }) => ({ timezoneName: id })),
    }
  }, [timezone, defaultTimezone])

  const handleTimezoneChange = (tz?: Timezone) => {
    setTimezone(tz || defaultTimezone)
  }

  const handleRecurrenceChange = (option: RecurrenceOption) => {
    setRecurrence(
      option
        ? option.id
        : meetingFrequencyToRecurrenceMap[defaultOneToOneMeetingFrequency],
    )
  }

  const mappedEvents = events.map(evnt => ({
    ...evnt,
    ...(isString(evnt.start) && isString(evnt.end) && timezone?.id
      ? {
          start: utcToZonedTime(evnt.start, timezone.id),
          end: utcToZonedTime(evnt.end, timezone.id),
        }
      : {}),
  }))

  return (
    <Widget p="s-16" height="100%">
      <Box height="100%" width="100%" overflow="hidden">
        <LargeWeeklyCalendar
          startDayOfWeek={startDayOfWeek}
          eventView={['time']}
          events={schedulingEvent ? [schedulingEvent, ...mappedEvents] : mappedEvents}
          timezone={timezoneOptions}
          workweek
          weekStartDate={new Date(week.start)}
          side={
            <Flex flexWrap="wrap" gap="s-16" ml="s-16">
              <FilterButton
                useIcon="People"
                active={freeBusyOverlap}
                onClick={busyBusyOverlapToggler.switch}
              >
                Busy overlap
              </FilterButton>
              <RecurrenceSelector value={recurrence} onChange={handleRecurrenceChange} />
              {timezone && (
                <TimezoneSelector value={timezone} onChange={handleTimezoneChange} />
              )}
            </Flex>
          }
          onSwitchWeek={(start, end) => {
            setWeek({
              start: start.toISOString(),
              end: endOfDay(end).toISOString(),
            })
          }}
          onChangeEvent={updateEvent}
          onAddEvent={addEvent}
        />
      </Box>
    </Widget>
  )
}
