import { createSelector } from 'reselect'
import { State } from '../index'
import { PermissionTypes } from './types'
import { featureBundles } from './constants'

export const selectAuth = (state: State) => state.auth

export const selectAuthenticated = createSelector(selectAuth, auth => auth.authenticated)

export const selectUser = createSelector(selectAuth, auth => auth.user)

export const selectPermissions = createSelector(selectAuth, auth => auth.permissions)

export const selectFeatureFlags = createSelector(selectAuth, auth => auth.featureFlags)

export const selectFeatureBundles = createSelector(selectAuth, auth =>
  featureBundles.filter(bundle => auth.featureFlags.includes(bundle)),
)

export const selectLoginUrl = createSelector(selectAuth, auth => auth.loginUrl)

export const canAddTeamKpi = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddTeamKpi),
)
export const canAddDepartmentKpi = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddDepartmentKpi),
)
export const canAddFunctionKpi = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddFunctionKpi),
)
export const canAddRoleKpi = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddRoleKpi),
)
export const canAddPipCycle = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddPipCycle),
)

export const canAddCustomField = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddCustomField),
)
export const canDeleteCustomField = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.DeleteCustomField),
)
export const canAddCompanyGoal = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddCompanyGoal),
)
export const canChangeCompanyGoal = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.ChangeCompanyGoal),
)
export const canAssignLabel = createSelector(selectAuth, auth =>
  auth.permissions.includes(PermissionTypes.AddEmployeeManualLabelAssignments),
)
