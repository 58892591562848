import React, { ComponentProps } from 'react'
import { DataPoint, Highlights, TextSkeleton } from '@revolut/ui-kit'
import { OverallProgress } from './OverallProgress'
import { useParams, useRouteMatch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useGetOrganisationalUnitStats } from '@src/api/goals'
import { useQuery } from '@src/utils/queryParamsHooks'
import pluralize from 'pluralize'
import { isNumber } from 'lodash'
import { EmployeeProgressNaviagationLabel } from './EmployeeProgressNaviagationLabel'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

interface Props extends ComponentProps<typeof OverallProgress> {
  cycleCategory?: ReviewCycleCategory
}

export const EmployeeProgressNavigation = ({
  cycleCategory,
  ...overallProgressProps
}: Props) => {
  const { id } = useParams<{ id: string }>()
  const { query } = useQuery<{ cycle__id: string }>()
  const { data, isLoading } = useGetOrganisationalUnitStats({
    employeeId: id,
    cycleId: query.cycle__id,
  })

  const isOrgGoalsPath = Boolean(
    useRouteMatch({
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ORGANISATIONAL,
      exact: true,
    }),
  )
  return (
    <Highlights variant="tabs">
      <OverallProgress
        selected={Boolean(
          useRouteMatch({
            path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
            exact: true,
          }),
        )}
        navigateTo={pathToUrl(
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
          {
            id,
          },
        )}
        label={
          <EmployeeProgressNaviagationLabel
            label="Performance goals"
            title="Relevant for Performance Review!"
            subtitle="These goals are directly tied to performance evaluations and impact the employee’s review."
          />
        }
        {...overallProgressProps}
      />
      {cycleCategory !== ReviewCycleCategory.PIP &&
        cycleCategory !== ReviewCycleCategory.PIP_V2 &&
        cycleCategory !== ReviewCycleCategory.Probation && (
          <DataPoint
            use={InternalLink}
            to={pathToUrl(
              ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ORGANISATIONAL,
              {
                id,
              },
            )}
            aria-selected={isOrgGoalsPath}
          >
            <DataPoint.Value>
              {isLoading ? (
                <TextSkeleton />
              ) : isNumber(data?.total_goals) ? (
                pluralize('goal', data?.total_goals, true)
              ) : (
                'n/a'
              )}
            </DataPoint.Value>
            <DataPoint.Label>
              <EmployeeProgressNaviagationLabel
                label="Organisational goals"
                title="For reference only"
                subtitle="These goals represent broader objectives within the team or department. They are provided for reference and do not impact individual performance reviews."
              />
            </DataPoint.Label>
          </DataPoint>
        )}
    </Highlights>
  )
}
