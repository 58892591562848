import React, { useRef } from 'react'
import { Toolbox, TransitionFade, useToggle } from '@revolut/ui-kit'
import { ProbationHRManagerBulkAssign } from './ProbationHRManagerBulkAssign'
import { ProbationGoalsBulkApprove } from './ProbationGoalsBulkApprove'
import { ProbationInterface } from '@src/interfaces/probation'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import { useProbationPermissions } from '@src/features/Probation/permissions/useProbationPermissions'

interface Props {
  selection: ProbationInterface[] | undefined
  refresh: VoidFunction
}

export const ProbationBulkActionsToolbar = ({ selection, refresh }: Props) => {
  const nodeRef = useRef(null)
  const [bulkAssignPopup, toggleBulkAssignPopup] = useToggle()
  const [bulkGoalsApprovePopup, toggleBulkGoalsApprovePopup] = useToggle()
  const { hasProbationCommitteeHRPermissions } = useProbationPermissions()

  if (!selection || selection.length === 0) {
    return null
  }

  const selectedCyclesIds = selection.map(employee =>
    getReviewCycleIdWithoutPrefix(employee.cycle.id),
  )

  return (
    <>
      <TransitionFade nodeRef={nodeRef} in={selection.length > 0}>
        <Toolbox ref={nodeRef} selection={selection.length} maxWidth={465}>
          <Toolbox.Action
            useIcon="Message"
            aria-label="Icon button"
            disabled={!hasProbationCommitteeHRPermissions}
            onClick={() => toggleBulkAssignPopup.on()}
          >
            Assign HR manager
          </Toolbox.Action>

          <Toolbox.Action
            useIcon="Flag"
            aria-label="Icon button"
            disabled={!hasProbationCommitteeHRPermissions}
            onClick={toggleBulkGoalsApprovePopup.on}
          >
            Approve goals
          </Toolbox.Action>
        </Toolbox>
      </TransitionFade>
      <ProbationHRManagerBulkAssign
        open={bulkAssignPopup}
        onClose={toggleBulkAssignPopup.off}
        onSuccess={() => {
          toggleBulkAssignPopup.off()
          refresh()
        }}
        cycleIds={selectedCyclesIds}
      />
      <ProbationGoalsBulkApprove
        open={bulkGoalsApprovePopup}
        onClose={toggleBulkGoalsApprovePopup.off}
        onSuccess={() => {
          toggleBulkGoalsApprovePopup.off()
          refresh()
        }}
        cycleIds={selectedCyclesIds}
      />
    </>
  )
}
