import React, { PropsWithChildren } from 'react'
import { Text, Token, VStack } from '@revolut/ui-kit'
import PublicPageLayout from '@src/components/CommonSC/PublicPageLayout'

interface Props {
  title: React.ReactNode
}

export const ConsentLayout = ({ title, children }: PropsWithChildren<Props>) => (
  <PublicPageLayout>
    <VStack maxWidth={600} gap="s-16" px="s-16" pt="s-32" color={Token.color.foreground}>
      <Text variant="h4" textAlign="center">
        {title}
      </Text>
      {children}
    </VStack>
  </PublicPageLayout>
)
