import React, { useMemo } from 'react'
import { FilterButton, Token } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { RowInterface } from '@src/interfaces/data'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Stat from '@components/Stat/Stat'
import { getOffboardingQueue, getOffboardingQueueStats } from '@src/api/offboarding'
import {
  offboardingQueueAccessRetentionApprovalStatusColumn,
  offboardingQueueEmployeeColumn,
  offboardingQueueHRManagerColumn,
  offboardingQueueLastDayInOfficeColumn,
  offboardingQueueLastDayOfContractColumn,
  offboardingQueueLMColumn,
  offboardingQueueLocationColumn,
  offboardingQueueSeniorityColumn,
  offboardingQueueSpecialisationColumn,
  offboardingQueueStatusColumn,
} from '@src/constants/columns/offboarding'
import {
  OffboardingQueueInterface,
  OffboardingQueueStatsInterface,
} from '@src/interfaces/offboarding'
import { selectUser } from '@src/store/auth/selectors'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { useGetLifecycleSettings } from '@src/api/settings'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

const offboardingRow = (
  specialisationsEnabled: boolean,
): RowInterface<OffboardingQueueInterface> => ({
  linkToForm: ({ employee }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, { employeeId: employee.id }),
    ),
  cells: [
    {
      ...offboardingQueueEmployeeColumn,
      width: 200,
    },
    {
      ...offboardingQueueSpecialisationColumn(specialisationsEnabled),
      width: 200,
    },
    {
      ...offboardingQueueSeniorityColumn,
      width: 100,
    },
    {
      ...offboardingQueueLocationColumn,
      width: 100,
    },
    {
      ...offboardingQueueLMColumn,
      width: 100,
    },
    {
      ...offboardingQueueLastDayInOfficeColumn,
      width: 150,
    },
    {
      ...offboardingQueueLastDayOfContractColumn,
      width: 150,
    },
    {
      ...offboardingQueueAccessRetentionApprovalStatusColumn,
      width: 200,
    },
    {
      ...offboardingQueueStatusColumn,
      width: 200,
    },
    {
      ...offboardingQueueHRManagerColumn,
      width: 200,
    },
  ],
})

const OffboardingTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_OFFBOARDING_ASSIGNED_TO_ME,
    false,
  )
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const user = useSelector(selectUser)

  const { data: lifecycleSettings } = useGetLifecycleSettings()

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'hr_manager__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = [
      {
        columnName: 'status',
        filters: [
          {
            id: 'pending',
            name: 'Pending',
          },
        ],
        nonResettable: true,
      },
    ]

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }
  const row = useMemo(
    () => offboardingRow(specialisationsEnabled),
    [specialisationsEnabled],
  )

  const table = useTable<OffboardingQueueInterface, OffboardingQueueStatsInterface>(
    {
      getItems: getOffboardingQueue,
      getStats: getOffboardingQueueStats,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Total" val={table.stats?.total} />
        <Stat label="Pending" val={table.stats?.pending} color={Token.color.warning} />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          Assigned to me
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.Offboarding}
          useWindowScroll
          dataType="Employee"
          row={row}
          {...table}
          noDataMessage="Offboarding employees will appear here."
          hiddenCells={{
            [offboardingQueueAccessRetentionApprovalStatusColumn.idPoint]:
              !lifecycleSettings?.enable_access_retention,
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default OffboardingTable
