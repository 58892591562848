import React, { useState } from 'react'

import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'

import { probationTemplateRequests } from '@src/api/probationTemplate'
import { ProbationTemplateFormBody } from './ProbationTemplateFormBody'
import { ActionButton, HStack, useToggle } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

import {
  GroupedError,
  groupErrors,
} from '@src/components/DependenciesPopup/DependantEntities'
import { FailedDeleteDueToDependenciesPopup } from '@src/components/DependenciesPopup/FailedDeleteDueToDependenciesPopup'
import { isErrorWithDependencies } from '@src/components/DependenciesPopup/isErrorWithDependencies'

interface Props {
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
  templateId: number
}

export const EditProbationTemplateSidebar = ({
  onClose,
  onAfterSubmit,
  templateId,
}: Props) => {
  return (
    <SideBar title="Edit template" onClose={onClose} isOpen>
      <Form
        forceParams={{ id: String(templateId) }}
        disableLocalStorageCaching
        api={probationTemplateRequests}
      >
        <FormBody onAfterSubmit={onAfterSubmit} onClose={onClose} />
      </Form>
    </SideBar>
  )
}

const FormBody = connect(({ onAfterSubmit, onClose }: Omit<Props, 'templateId'>) => {
  const { values } = useLapeContext<ProbationTemplateInterface>()
  const [errorDetails, setErrorDetails] = useState<GroupedError[]>()
  const [isDependencyError, toggleIsDependencyError] = useToggle()
  const [isDeleting, setIsDeleting] = useState(false)
  const confirmationPopup = useConfirmationDialog()
  const errorPopup = useErrorPopup()

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await probationTemplateRequests?.delete?.({ id: values.id }, {})
      onAfterSubmit()
      onClose()
    } catch (error) {
      if (isErrorWithDependencies(error)) {
        setErrorDetails(groupErrors(error.response?.data?.dependencies))
        toggleIsDependencyError.on()
      } else {
        errorPopup.show({
          error,
          fallbackTitle: 'Failed to delete template',
          forceFallbackTitle: true,
        })
      }
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <>
      <HStack gap="s-8" pb="s-16">
        {!values.is_default && (
          <ActionButton
            variant="negative"
            onClick={() => {
              confirmationPopup.show({
                loading: isDeleting,
                yesMessage: 'Delete',
                noMessage: 'Cancel',
                label: `Delete template ${values.name}`,
                onConfirm: handleDelete,
              })
            }}
            useIcon="Delete"
          >
            Delete
          </ActionButton>
        )}
      </HStack>
      <ProbationTemplateFormBody onClose={onClose} onAfterSubmit={onAfterSubmit} />
      {isDependencyError && errorDetails && (
        <FailedDeleteDueToDependenciesPopup
          errorDetails={errorDetails}
          open
          entityId={values.id}
          onRetry={handleDelete}
          onCancel={toggleIsDependencyError.off}
        />
      )}
    </>
  )
})
