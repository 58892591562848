import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

export const hasHRPermissions = (permissions: string[]) => {
  return permissions.some(
    permission =>
      permission === PermissionTypes.HRManagerPermissions ||
      permission === PermissionTypes.LeadHRManagerPermissions ||
      permission === PermissionTypes.AdminHRManagerPermissions,
  )
}

export const checkLinkedRevolutAccountViewPermissions = (data: EmployeeInterface) =>
  !!data.field_options.permissions?.includes(PermissionTypes.ViewEmployeelinkedaccount)

export const useCanViewLinkedAccounts = ({
  employee,
}: {
  employee?: EmployeeInterface
}) => {
  const { settings } = useGlobalSettings()
  const isCommercial = useIsCommercial()
  const user = useSelector(selectUser)
  const isCurrentUser = user.id === employee?.id
  const isSettingEnabled = !!settings.linked_accounts_enabled

  const hasRevolutLinkedAccountViewPermission =
    !!employee && checkLinkedRevolutAccountViewPermissions(employee)

  const canView = isCommercial
    ? isCurrentUser
    : Boolean(isCurrentUser || hasRevolutLinkedAccountViewPermission)

  return {
    canViewLinkedAccountsPage: isSettingEnabled && canView,
    canViewRevolutAccounts: hasRevolutLinkedAccountViewPermission,
  }
}
