import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PipPipelineTable } from './PipPipelineTable'
import { PageBody } from '@src/components/Page/PageBody'
import { Token } from '@revolut/ui-kit'

export const PipReviewsTable = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="PIP pipeline"
        subtitle="Check out employees who are on PIP"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.PIP)}
      />
      <PageBody maxWidthMd={Token.breakpoint.xxl}>
        <PipPipelineTable />
      </PageBody>
    </PageWrapper>
  )
}
