import React from 'react'
import {
  Avatar,
  Banner,
  Color,
  Flex,
  HStack,
  Icon,
  IconName,
  Spacer,
  Tag,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { mapSurveyRoundStatusToColor } from '@src/apps/People/Engagement/helpers'
import {
  EngagementSurveyInterface,
  EngagementSurveyRoundInterface,
} from '@src/interfaces/engagement'
import { useTestSurvey } from '@src/pages/Forms/Engagement/hooks/useTestSurvey'
import {
  calculatePercentageBetweenDates,
  formatDate,
  formatPercentage,
  timeLeft,
} from '@src/utils/format'
import { getRoundEndTime } from '@src/features/Engagement/helpers/getRoundEndTime'
import { RoundActions } from './RoundActions'

const mapStatusToAvatarData = (
  status: EngagementSurveyRoundInterface['status'],
): { icon: IconName; color: Color } => {
  switch (status.id) {
    case 'completed':
      return { icon: 'Check', color: Token.color.accent }
    case 'upcoming':
      return { icon: '16/SandWatch', color: Token.color.accent }
    case 'cancelled':
    case 'terminated':
      return { icon: 'Cross', color: Token.color.greyTone20 }
    default:
      return { icon: 'TurboTransfer', color: Token.color.success }
  }
}

export const RoundItem = ({
  permissions,
  surveyId,
  round,
  refetch,
}: {
  surveyId: number
  round: EngagementSurveyRoundInterface
  permissions: EngagementSurveyInterface['field_options']['permissions']
  refetch: VoidFunction
}) => {
  const { runTestSurvey, isPending: isTestSurveyPending } = useTestSurvey({
    surveyId,
    preSubmit: false,
  })

  const roundEndTime = getRoundEndTime({
    startTime: round.sent_on,
    deadlineDays: round.response_deadline_days,
    endTime: round.closing_date,
  })
  const progress = calculatePercentageBetweenDates(round.sent_on, roundEndTime)
  const { color: avatarColor, icon } = mapStatusToAvatarData(round.status)

  return (
    <Banner
      style={{
        border: `1px solid ${Token.color.greyTone5}`,
        borderRadius: '16px',
      }}
    >
      <Banner.Avatar size={40}>
        <Avatar
          size={40}
          progressColor={avatarColor}
          useIcon={icon}
          color={avatarColor}
          progress={progress}
        />
      </Banner.Avatar>
      <Banner.Content>
        <Flex gap="s-16" alignItems="center">
          <VStack gap="s-2" flex={1}>
            <Text>{round.sent_on ? formatDate(round.sent_on) : round.status.name}</Text>
            <HStack gap="s-8">
              {round.status.id === 'completed' ? (
                <Text variant="small" color={mapSurveyRoundStatusToColor(round)}>
                  {round.status.name}
                </Text>
              ) : (
                <Tag variant="outlined" color={mapSurveyRoundStatusToColor(round)}>
                  {round.status.name}
                </Tag>
              )}
              {round.status.id === 'ongoing' && (
                <Text variant="small" color={Token.color.greyTone50}>
                  {timeLeft(roundEndTime)}
                </Text>
              )}
              {round.status.id === 'upcoming' && round.sent_on && (
                <Text variant="small" color={Token.color.greyTone50}>
                  Starting {formatDate(round.sent_on)}
                </Text>
              )}
            </HStack>
          </VStack>
          <VStack flex={1}>
            <HStack gap="s-4">
              <Icon name="Profile" color={Token.color.grey50} />
              <Text color={Token.color.grey50} variant="h6">
                {round.response_count || 0}/{round.audience_size || 0}
              </Text>
            </HStack>
            <Text color={Token.color.grey50} variant="h6">
              Responses (
              {formatPercentage(
                round.audience_size
                  ? (round.response_count || 0) / round.audience_size
                  : 0,
                2,
              )}
              )
            </Text>
          </VStack>
          <RoundActions
            surveyId={surveyId}
            round={round}
            refetch={refetch}
            permissions={permissions}
            onPreview={runTestSurvey}
            isPending={isTestSurveyPending}
            placeholder={<Spacer width={24} />}
          />
        </Flex>
      </Banner.Content>
    </Banner>
  )
}
