import React from 'react'
import upperFirst from 'lodash/upperFirst'
import { Fixed, Side, Header, TabBar } from '@revolut/ui-kit'
import { isPipOrProbationCategory, ReviewCategory } from '@src/interfaces/performance'
import { defaultTheme } from '@src/styles/theme'
import { SideBarHeaderTitle } from '@components/SideBar/SideBarHeaderTitle'
import { SummaryCheckpointResults } from './SummaryCheckpointResults'
import { useSummarySideBarTabs } from './hooks'
import { FullSummaryReview } from './SummaryFullReview'
import { SummarySidebarProps } from './types'

const SummarySidebar = ({
  cycleId,
  employeeId,
  cycleName,
  category,
  predefinedCheckpointFilter,
  predefinedReviewer = false,
  onClose,
  isOpen,
  employeeName,
  employeeSeniority,
  employeeSpecialisation,
  onClickRequestReview,
  narrativeCell,
}: SummarySidebarProps) => {
  const tabsState = useSummarySideBarTabs(category)

  let title = employeeName

  if (!employeeName) {
    if (category === ReviewCategory.PIP || category === ReviewCategory.PIP_V2) {
      title = 'PIP review'
    } else {
      title = `${
        category !== ReviewCategory.Probation ? cycleName : ''
      } ${category} review`
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <Fixed
      right={0}
      top={0}
      zIndex={defaultTheme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      overflow="auto"
    >
      <Side open onClose={onClose} variant="wide" height="100%">
        <Header variant="item" pb={0}>
          <Header.CloseButton aria-label="Close" />
          <SideBarHeaderTitle data-testid="title">
            {upperFirst(title?.trim())}
          </SideBarHeaderTitle>
          {employeeSpecialisation && employeeSeniority && (
            <Header.Description>
              {employeeSpecialisation} - {employeeSeniority}
            </Header.Description>
          )}
        </Header>
        {narrativeCell}

        {tabsState ? (
          <TabBar variant="segmented fit">
            {tabsState.tabs.map(t => (
              <TabBar.Item
                key={t.key}
                aria-selected={tabsState.selectedTab === t.key}
                onClick={() => tabsState.selectTab(t.key)}
              >
                {t.title}
              </TabBar.Item>
            ))}
          </TabBar>
        ) : null}

        {tabsState?.selectedTab === 'checkpoint' &&
          isPipOrProbationCategory(category) && (
            <SummaryCheckpointResults
              employeeId={employeeId}
              pipCycleId={cycleId}
              category={category}
            />
          )}

        {(!tabsState || tabsState?.selectedTab === 'full') && (
          <FullSummaryReview
            category={category}
            cycleId={cycleId}
            employeeId={employeeId}
            predefinedCheckpointFilter={predefinedCheckpointFilter}
            predefinedReviewer={predefinedReviewer}
            onClickRequestReview={onClickRequestReview}
          />
        )}
      </Side>
    </Fixed>
  )
}

export default SummarySidebar
