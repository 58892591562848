import React from 'react'
import { Avatar, Box, Group, Item } from '@revolut/ui-kit'
import { useGetOrganisationSettings } from '@src/api/settings'
import SpecialisationSublevels from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/SpecialisationSublevels'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'

interface Props {
  role: RoleInterface | undefined
}

export const SeniorityLevelsWidget = ({ role }: Props) => {
  const { data: organisationSettings } = useGetOrganisationSettings()
  const { values } = useLapeContext<SpecialisationInterface>()

  const canChangeSpecialisationSublevels = useHasGlobalPermission(
    PermissionTypes.ChangeSpecialisationSublevels,
  )

  const canEditSublevels =
    values.field_options?.permissions?.includes(
      PermissionTypes.ChangeSpecialisationSublevels,
    ) || canChangeSpecialisationSublevels
  return organisationSettings?.enable_multiple_levels_per_seniority ? (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="TurboTransfer" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Seniority levels</Item.Title>
        </Item.Content>
        <Item.Side />
      </Item>
      {!!role && (
        <Box>
          {values.id ? (
            <SpecialisationSublevels id={values.id} canEdit={canEditSublevels} />
          ) : null}
        </Box>
      )}
    </Group>
  ) : null
}
