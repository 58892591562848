import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, useToggle, Widget } from '@revolut/ui-kit'
import {
  ExternalMetricSources,
  InternalMetricSources,
  JiraCommonMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { insightsGroupModel } from '@src/features/AiInsights/Performance/models/insightsGroupModel'
import SideBar from '@components/SideBar/SideBar'
import { PerformanceOutput } from '@src/features/AiInsights/Performance/components/Insights/Deliverables/PerformanceOutput'
import { usePerformanceOutput } from '@src/features/AiInsights/Performance/components/Insights/Deliverables/hooks/usePerformanceOutput'
import { InsightsWidget } from '@src/features/AiInsights/Performance/components/Insights/InsightsWidget/InsightsWidget'
import { InsightsRelation } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { InsightsSelector } from '@src/features/AiInsights/Performance/components/Insights/InsightsSelector/InsightsSelector'
import { InsightsActions } from '@src/features/AiInsights/Performance/components/Insights/InsightsActions/InsightsActions'
import { performanceInsightsModel } from '@src/features/AiInsights/Performance/models/insightsModel'

interface Props {
  employeeId: number
  cycleId: number
  relation: InsightsRelation
}

export const PerformanceInsights = ({ employeeId, cycleId, relation }: Props) => {
  const {
    employee,
    insightsGroup,
    insights,
    oneToOnesSummary,
    selectedInsights,
    selectedOrgUnit,
    orgUnits,
    employeeJiraMetrics,
    employeeBitbucketMetrics,
    employeeInterviewsMetrics,
    orgUnitJiraMetrics,
    orgUnitBitbucketMetrics,
    orgUnitInterviewsMetrics,
    jiraEpicsTable,
    isLoading,
    isJiraMetricsLoading,
    isBitbucketMetricsLoading,
    isInterviewsMetricsLoading,
    isOneToOnesSummaryLoading,
    onSelectOrgUnit,
    onSelectInsights,
    onRefreshInsights,
  } = usePerformanceOutput(employeeId, cycleId, relation)

  const [isDetailsSidebarOpen, detailsSidebarToggler] = useToggle()

  if (!employee || !insightsGroup || !selectedOrgUnit) {
    return null
  }

  const hasJiraMetrics =
    (!!employeeJiraMetrics &&
      !!orgUnitJiraMetrics &&
      insightsGroupModel.hasAnyNumericMetricEnabledForSource(
        insightsGroup,
        ExternalMetricSources.Jira,
      )) ||
    (jiraEpicsTable.count > 0 &&
      insightsGroupModel.hasMetricEnabled(
        insightsGroup,
        ExternalMetricSources.Jira,
        JiraCommonMetrics.EpicContribution,
      ))
  const hasBitbucketMetrics =
    !!employeeBitbucketMetrics &&
    !!orgUnitBitbucketMetrics &&
    insightsGroupModel.hasAnyMetricEnabledForSource(
      insightsGroup,
      ExternalMetricSources.Bitbucket,
    )
  const hasInterviewsMetrics =
    !!employeeInterviewsMetrics &&
    !!orgUnitInterviewsMetrics &&
    insightsGroupModel.hasAnyMetricEnabledForSource(
      insightsGroup,
      InternalMetricSources.Interviews,
    )
  const hasOneToOnesMetrics =
    !!oneToOnesSummary &&
    insightsGroupModel.hasSourceEnabled(insightsGroup, InternalMetricSources.OneToOnes)

  const hasDetails =
    hasJiraMetrics || hasBitbucketMetrics || hasInterviewsMetrics || hasOneToOnesMetrics
  const isDetailsLoading =
    isJiraMetricsLoading ||
    isBitbucketMetricsLoading ||
    isInterviewsMetricsLoading ||
    isOneToOnesSummaryLoading

  if (insightsGroup.sources.length === 0 || !selectedInsights?.data) {
    return null
  }

  return (
    <Box data-testid="performance_insights_widget">
      <Widget p="s-16">
        <InsightsWidget
          summary={selectedInsights.data}
          summaryVariant="list"
          isLoading={isDetailsLoading}
          hasDetails={hasDetails}
          updatedAt={performanceInsightsModel.getGenerationDate(selectedInsights)}
          title={
            insights.length > 1 ? (
              <InsightsSelector
                value={selectedInsights}
                options={insights}
                onSelect={onSelectInsights}
              />
            ) : undefined
          }
          actions={
            <InsightsActions
              insights={selectedInsights}
              relation={relation}
              domain="keyInsights"
              employee={employee}
              refreshInsights={onRefreshInsights}
            />
          }
          onDetailsClick={detailsSidebarToggler.on}
        />
      </Widget>
      <SideBar
        isOpen={isDetailsSidebarOpen}
        title={
          <FormattedMessage
            id="performance.insights.details.sidebar.title"
            defaultMessage="Performance output"
          />
        }
        onClose={detailsSidebarToggler.off}
      >
        <Box mt="-s-12">
          <PerformanceOutput
            employeeId={employeeId}
            aiInsights={selectedInsights}
            insightsGroup={insightsGroup}
            oneToOnesSummary={oneToOnesSummary}
            selectedOrgUnit={selectedOrgUnit}
            orgUnits={orgUnits}
            employeeJiraMetrics={employeeJiraMetrics}
            employeeBitbucketMetrics={employeeBitbucketMetrics}
            employeeInterviewsMetrics={employeeInterviewsMetrics}
            orgUnitJiraMetrics={orgUnitJiraMetrics}
            orgUnitBitbucketMetrics={orgUnitBitbucketMetrics}
            orgUnitInterviewsMetrics={orgUnitInterviewsMetrics}
            jiraEpicsTable={jiraEpicsTable}
            isLoading={isLoading}
            isJiraMetricsLoading={isJiraMetricsLoading}
            isBitbucketMetricsLoading={isBitbucketMetricsLoading}
            isInterviewsMetricsLoading={isInterviewsMetricsLoading}
            isJiraMetricsVisible={hasJiraMetrics || isJiraMetricsLoading}
            isBitbucketMetricsVisible={hasBitbucketMetrics || isBitbucketMetricsLoading}
            isInterviewsMetricsVisible={
              hasInterviewsMetrics || isInterviewsMetricsLoading
            }
            isOneToOnesMetricsVisible={hasOneToOnesMetrics || isOneToOnesSummaryLoading}
            isOneToOnesSummaryLoading={isOneToOnesSummaryLoading}
            onSelectOrgUnit={onSelectOrgUnit}
          />
        </Box>
      </SideBar>
    </Box>
  )
}
