import React from 'react'
import {
  Avatar,
  Banner,
  BottomSheet,
  Box,
  Button,
  Icon,
  Token,
  Header,
} from '@revolut/ui-kit'
import { DependantEntities, GroupedError } from './DependantEntities'

export const FailedDeleteDueToDependenciesPopup = ({
  errorDetails,
  open,
  entityId,
  onRetry,
  onCancel,
  prefix,
}: {
  errorDetails: GroupedError[]
  open: boolean
  entityId: number
  onRetry: () => void
  onCancel: () => void
  prefix?: string
}) => {
  if (!open) {
    return null
  }
  const isPartOfReviewCycles = errorDetails.some(error => error.model === 'TimelineItem')
  return (
    <BottomSheet open onClose={onCancel}>
      <Header variant="bottom-sheet">
        <Header.Title>Item could not be deleted</Header.Title>
        <Header.Subtitle>
          <Box mt="s-8">
            This item is referenced by other objects in the platform. Please review the
            dependencies below, either by assigning them to another item or deleting them
            too.
          </Box>
        </Header.Subtitle>
        <Header.Actions>
          <Icon name="16/Warning" color={Token.color.warning} />
        </Header.Actions>
      </Header>
      {isPartOfReviewCycles && (
        <Banner mb="s-16">
          <Banner.Avatar>
            <Avatar useIcon="16/ExclamationMarkSign" />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Description>
              This item was a part of review cycle and cannot be deleted. Consider
              archiving it.
            </Banner.Description>
          </Banner.Content>
        </Banner>
      )}
      <DependantEntities entityId={entityId} details={errorDetails} prefix={prefix} />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onRetry}>
          Retry
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
