import React, { useMemo } from 'react'

import { navigateTo } from '@src/actions/RouterActions'
import { useGetSelectorsWithFilters } from '@src/api/selectors'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import {
  getEngagementResultsScoreColumn,
  engagementResultsCommentsNumColumn,
  engagementResultsDistributionColumn,
  getEngagementResultsCategoryNameColumn,
} from '@src/constants/columns/engagementResults'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import {
  getEngagementCategoriesResultsTableRequests,
  getEngagementQuestionsResultsTableRequests,
} from '@src/features/Engagement/api/analytics'
import {
  EngagementResultInterface,
  ItemsToAnalyse,
} from '@src/features/Engagement/api/analytics/interfaces'
import { useIsEngagementManager } from '@src/features/Engagement/hooks/permissions'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'

import { PageQueryParams, getDetailsPageQueryParams } from '../Details/common'
import { ResultsInterface } from './common'
import {
  getDateRangeFilterParams,
  getSurveyRoundFilterParams,
  useApplyNonTableFilters,
} from './hooks/useApplyNonTableFilters'
import { HeatMapComparisonMode } from '@src/features/Engagement/components/HeatmapTableCell'

const getRow = ({
  surveyId,
  canViewDetails = true,
  queryParams,
  categoriesOptions = [],
  heatMapComparisonMode,
  isScopedView,
}: {
  surveyId: number
  canViewDetails?: boolean
  queryParams: PageQueryParams
  categoriesOptions: IdAndName[]
  heatMapComparisonMode?: HeatMapComparisonMode
  isScopedView: boolean
}): RowInterface<EngagementResultInterface> => ({
  linkToForm: canViewDetails
    ? ({ id, driver }) => {
        return navigateTo(
          pathToUrl(
            driver
              ? ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.QUESTION
              : ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.CATEGORY,
            { id: surveyId, itemId: id },
            queryParams,
          ),
        )
      }
    : undefined,
  cells: [
    { ...getEngagementResultsCategoryNameColumn(categoriesOptions), width: 350 },
    {
      ...getEngagementResultsScoreColumn({
        isScopedView,
        heatMapComparisonMode,
      }),
      width: 100,
    },
    { ...engagementResultsDistributionColumn, width: 200 },
    { ...engagementResultsCommentsNumColumn, width: 70 },
  ],
})

export const ResultCategoriesTable = ({
  survey,
  viewMode,
  timelineFilter,
  heatMapComparisonMode,
  initialFilters = [],
  scopeFilters = [],
}: ResultsInterface) => {
  const isEngagementManager = useIsEngagementManager()

  const itemsToAnalyse: ItemsToAnalyse = 'categories'
  const disableTableRequests = viewMode !== 'table'

  const table = useTable<EngagementResultInterface>(
    getEngagementCategoriesResultsTableRequests(survey.id),
    [
      ...getSurveyRoundFilterParams(timelineFilter),
      ...getDateRangeFilterParams(
        timelineFilter.dateFrom,
        timelineFilter.dateTo,
        itemsToAnalyse,
      ),
      ...initialFilters,
      ...scopeFilters,
    ],
    undefined,
    {
      disable: disableTableRequests,
      disableQuery: true,
    },
  )

  useApplyNonTableFilters({
    disable: disableTableRequests || table.loading,
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'categories',
  })

  const { data: categoriesOptions = [] } = useGetSelectorsWithFilters<IdAndName>({
    type: selectorKeys.survey_drivers,
    filters: [{ columnName: 'survey_id', filters: [toIdAndName(String(survey.id))] }],
  })
  const pageQueryParams = getDetailsPageQueryParams(timelineFilter, scopeFilters)
  const row = useMemo(
    () =>
      getRow({
        surveyId: survey.id,
        queryParams: pageQueryParams,
        categoriesOptions,
        heatMapComparisonMode,
        isScopedView: !!scopeFilters?.length,
      }),
    [survey.id, isEngagementManager, pageQueryParams, categoriesOptions],
  )

  const fetchNestedQuestions = async (_: number[], id: number | string) => {
    const res = await getEngagementQuestionsResultsTableRequests(survey.id).getItems({
      filters: [
        ...table.filterBy,
        { columnName: 'driver', filters: [toIdAndName(String(id))] },
      ],
    })
    return res.data.results
  }

  return (
    <AdjustableTable
      {...table}
      name={TableNames.EngagementSurveysResultCategories}
      row={row}
      hideCount
      emptyState={<EmptyTableRaw title="Categories not found" />}
      expandableType="chevron"
      useWindowScroll
      useFetchedChildren
      fetchChildren={fetchNestedQuestions}
    />
  )
}
