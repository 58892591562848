import React from 'react'
import { Relative, Absolute } from '@revolut/ui-kit'
import DocumentSkeleton from '@components/Skeletons/DocumentSkeleton'
import CVPreview from '@src/pages/Forms/CVScreening/CVScreeningDocument'
import { CandidateDataType } from '@src/utils/hiring'

interface CVScreeningCVProps {
  candidates: Map<number, CandidateDataType>
  id: number
}

export const CV = ({ candidates, id }: CVScreeningCVProps) => {
  /* rendering everything here and hide it to avoid fetching data/rendering again. Speed is crucial here */
  return (
    <Relative height="calc(100vh - 185px)">
      {!candidates.size && <DocumentSkeleton maxWidth={940} />}
      {[...candidates.keys()].map(item => (
        <Absolute
          top={0}
          width="100%"
          height="100%"
          key={item}
          opacity={item === id ? '1' : '0'}
          zIndex={item === id ? 1 : -1}
        >
          <CVPreview id={item} loading={!candidates.get(item)?.loaded} />
        </Absolute>
      ))}
    </Relative>
  )
}
