import React, { useState } from 'react'
import { BottomSheet, Header, VStack, Text, Button } from '@revolut/ui-kit'
import MultiInput from '@src/components/Inputs/MultiInput/MultiInput'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { IdAndName } from '@src/interfaces'
import { CompetencyMatrixSkill, SkillLevels } from '@src/interfaces/roles'
import { PermissionTypes } from '@src/store/auth/types'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { FormattedMessage } from 'react-intl'
import { skillRequestsNew } from '@src/api/skills'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface Props {
  onClose: VoidFunction
  skillset: CompetencyMatrixSkill
  skillLevelDescription: string[]
  descriptionWithListIndicators: string[]
  skillLevel: IdAndName<SkillLevels>
  refetchSkills: VoidFunction
}

export const SkillDescriptionPopup = ({
  onClose,
  skillset,
  skillLevelDescription,
  skillLevel,
  refetchSkills,
  descriptionWithListIndicators,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const canEditSkill = useHasGlobalPermission(PermissionTypes.ChangeSkillset)

  const confirmationDialog = useConfirmationDialog()
  const errorPopup = useErrorPopup()

  const [editedDescription, setEditedDescription] =
    useState<string[]>(skillLevelDescription)

  return (
    <BottomSheet open onClose={onClose}>
      <Header>
        <Header.Title>
          {isEditing
            ? `Editing ${skillset.name}`
            : `${skillset.name}: ${skillLevel.name}`}
        </Header.Title>
      </Header>
      {isEditing ? (
        <MultiInput
          value={editedDescription}
          onChange={setEditedDescription}
          data-name="skill-description"
          title={skillLevel.name}
        />
      ) : (
        <VStack gap="s-8">
          {descriptionWithListIndicators.map(item => (
            <Text key={item}>{item}</Text>
          ))}
        </VStack>
      )}
      <BottomSheet.Actions horizontal gradient="full">
        {!isEditing ? (
          <>
            <Button
              variant="secondary"
              type="button"
              onClick={e => {
                e.preventDefault()
                onClose()
              }}
            >
              Close
            </Button>
            {canEditSkill && skillLevel.id !== SkillLevels.None && (
              <Button
                elevated
                onClick={e => {
                  e.preventDefault()
                  setIsEditing(true)
                }}
              >
                Edit
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              variant="secondary"
              type="button"
              onClick={e => {
                e.preventDefault()
                setEditedDescription(skillLevelDescription)
                setIsEditing(false)
              }}
            >
              Cancel
            </Button>
            {canEditSkill && !!skillset.id && skillLevel.id && (
              <Button
                elevated
                onClick={e => {
                  e.preventDefault()
                  confirmationDialog.show({
                    label: 'Are you sure?',
                    body: (
                      <FormattedMessage
                        id="integrations.bitbucket.helper.stepper.1"
                        defaultMessage="Updating skill <T>{skillName}</T> will affect all entities where it is used."
                        values={{
                          T: text => <Text fontWeight={700}>{text}</Text>,
                          skillName: skillset?.name,
                        }}
                      />
                    ),
                    noMessage: 'Cancel',
                    yesMessage: 'Update',
                    onConfirm: async () => {
                      try {
                        await skillRequestsNew.update(
                          {
                            [`mastery_level_definition_${skillLevel.id}`]:
                              editedDescription,
                          },
                          { id: String(skillset.id) },
                        )
                        refetchSkills()
                      } catch (error) {
                        errorPopup.show({
                          error,
                          fallbackTitle: 'Could not update skill',
                        })
                      } finally {
                        setIsEditing(false)
                      }
                    },
                    onReject: () => {
                      setEditedDescription(skillLevelDescription)
                      setIsEditing(false)
                    },
                  })
                }}
              >
                Save
              </Button>
            )}
          </>
        )}
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
