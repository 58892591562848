import React, { useMemo, useState } from 'react'
import {
  ActionButton,
  Flex,
  Group,
  HStack,
  ItemSkeleton,
  Text,
  VStack,
} from '@revolut/ui-kit'
import ButtonFilter from '@src/components/ButtonFilters/ButtonFilter'
import { selectorKeys } from '@src/constants/api'
import { truncate } from 'lodash'
import { FilterSortPageIntoQueryInterface } from '@src/interfaces/data'
import { GetRequestInterface, UseFetchResult } from '@src/interfaces'
import { groupChangelogsByMonth } from '@src/utils/changelog'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props<T> {
  useGetChangelog: (
    id: string | number,
    options?: FilterSortPageIntoQueryInterface | undefined,
  ) => UseFetchResult<GetRequestInterface<T>>
  renderChangeLogItem: (changelog: T) => React.ReactNode
  id: string | number
}

export function PipAndProbationChangeLog<T extends { change_time: string }>({
  useGetChangelog,
  renderChangeLogItem,
  id,
}: Props<T>) {
  const [page, setPage] = useState(1)
  const [changeLocationFilters, setChangeLocationFilters] = useState<OptionInterface[]>()
  const [changeTimeFilter, setChangeTimeFilter] = useState<OptionInterface[]>()

  const { data, isLoading } = useGetChangelog(id, {
    page,
    filters: [
      changeLocationFilters && {
        columnName: 'change_location',
        filters: changeLocationFilters,
      },
      changeTimeFilter && {
        columnName: 'change_time',
        filters: changeTimeFilter,
      },
    ].filter(Boolean),
  })

  const grouppedChangelog = useMemo(
    () => (data?.results ? groupChangelogsByMonth(data.results) : []),
    [data?.results],
  )

  if (isLoading) {
    return (
      <Group>
        <ItemSkeleton />
        <ItemSkeleton />
      </Group>
    )
  }

  return (
    <VStack gap="s-16">
      <HStack gap="s-16">
        <ButtonFilter
          type="MultiSelect"
          title={truncate(
            changeLocationFilters?.map(i => i.name).join(', ') || 'All Fields',
            { length: 15 },
          )}
          value={changeLocationFilters}
          onChange={val => {
            setChangeLocationFilters(val)
          }}
          selector={selectorKeys.employee_cycle_changelog_change_locations}
        />

        <ButtonFilter
          useIcon="Calendar"
          type="DateRange"
          title="All time"
          value={changeTimeFilter}
          onChange={val => {
            setChangeTimeFilter(val)
          }}
          selector={selectorKeys.none}
        />
      </HStack>

      {Object.entries(grouppedChangelog)?.map(([month, changelogs]) => (
        <VStack key={month} gap="s-16">
          <Text variant="emphasis1">{month}</Text>
          <Group>{changelogs?.map(changelog => renderChangeLogItem(changelog))}</Group>
        </VStack>
      ))}

      {data && data.results.length < data.count && (
        <Flex width="100%" justifyContent="center">
          <ActionButton
            onClick={() => {
              setPage(prev => prev + 1)
            }}
          >
            Load more
          </ActionButton>
        </Flex>
      )}
    </VStack>
  )
}
