import React, { useState } from 'react'
import { ActionMenu, MoreBar, useDropdown } from '@revolut/ui-kit'

import { ReportTypes } from '@src/interfaces/payroll'
import { useExportOptionsPopup, useReportPeriodPopup } from './hooks'
import { exportGrossToNetReport } from '@src/api/payrollV2'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '../Errors/useErrorPopup'

interface Props {
  payCycleId: number | string
  payrollProvider: string
  isDeel?: boolean
}
export const AsyncReportExportButton = ({
  payCycleId,
  payrollProvider,
  isDeel,
}: Props) => {
  const dropdown = useDropdown()

  const [reportPeriodPopupOpen, setReportPeriodPopupOpen] = useState(false)
  const [reportType, setReportType] = useState<ReportTypes>()

  const {
    exportOption,
    setExportOption,
    showPopup: showExportOptionsPopup,
    popup: exportOptionsPopup,
  } = useExportOptionsPopup({
    payrollProvider,
    onClickNext: () => setReportPeriodPopupOpen(true),
    onClickCancel: () => setReportType(undefined),
  })

  const { popup: reportPeriodPopup } = useReportPeriodPopup({
    isOpen: reportPeriodPopupOpen,
    setIsOpen: setReportPeriodPopupOpen,
    payrollProvider,
    exportOption,
    payCycleId,
    reportType,
  })

  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  return (
    <>
      <MoreBar.Action {...dropdown.getAnchorProps()} useIcon="Download">
        Download report
      </MoreBar.Action>

      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          <ActionMenu.Item
            onClick={() => {
              setReportType('base_report')

              if (payrollProvider === 'revolut') {
                setExportOption('download')
                setReportPeriodPopupOpen(true)
              } else {
                showExportOptionsPopup()
              }
            }}
            useIcon="BalanceSheet"
          >
            Base Report
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              setReportType('change_report')

              if (payrollProvider === 'revolut') {
                setExportOption('download')
                setReportPeriodPopupOpen(true)
              } else {
                showExportOptionsPopup()
              }
            }}
            useIcon="ArrowExchange"
          >
            Change Report
          </ActionMenu.Item>
          {isDeel && (
            <ActionMenu.Item
              onClick={async () => {
                try {
                  await exportGrossToNetReport(payCycleId)
                  showStatusPopup({
                    title: 'Report was successfully exported',
                    status: 'success',
                  })
                } catch (error) {
                  errorPopup.show({
                    error,
                    fallbackTitle: 'Could not export gross to net report',
                    forceFallbackTitle: true,
                  })
                }
              }}
              useIcon="BarChart"
            >
              Gross to Net Report
            </ActionMenu.Item>
          )}
        </ActionMenu.Group>
      </ActionMenu>

      {exportOptionsPopup}
      {reportPeriodPopup}
    </>
  )
}
