import React from 'react'
import {
  Ellipsis,
  FilterButton,
  FilterButtonSkeleton,
  Flex,
  Icon,
  RadioSelect,
  Text,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import { LabelByVariant, useCycleFilter } from './hooks/useCycleFilter'
import { OptionItem } from './OptionItem'
import capitalize from 'lodash/capitalize'
import { FilterInputSelectorType } from '../FilterSelect'
import { FilterByInterface } from '@src/interfaces/data'
import { CycleOption } from '@src/interfaces/selectors'

interface Props {
  onFilterChange: (filters: FilterByInterface, reset?: boolean) => void
  columnName: string
  label?: string
  filter: FilterByInterface[]
  selector?: FilterInputSelectorType<CycleOption>
  onChangeCallback?: (val: CycleOption) => void
  isLoading?: boolean
  labelCycleBy?: LabelByVariant
}

export const CycleFilterButton = ({
  onFilterChange,
  filter,
  columnName,
  selector,
  label = 'Performance cycle',
  isLoading,
  onChangeCallback,
  labelCycleBy = 'offset',
}: Props) => {
  const { options, asyncState, selectedPeriod, tagTitle } = useCycleFilter({
    filter,
    columnName,
    selector,
    onChangeCallback,
    labelBy: labelCycleBy,
  })

  const dropdown = useDropdown()

  if (asyncState !== 'ready' || isLoading) {
    return <FilterButtonSkeleton width={200} />
  }

  return (
    <>
      <FilterButton
        useIcon="Calendar"
        aria-haspopup="listbox"
        aria-expanded={dropdown.state}
        {...dropdown.getAnchorProps()}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{selectedPeriod?.label || 'Select cycle'}</Text>
          </Ellipsis>
          <Text variant="emphasis2" color={Token.color.greyTone50}>
            {capitalize(tagTitle)}
          </Text>
          <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <RadioSelect
        {...dropdown.getTargetProps()}
        label={label}
        options={options}
        value={selectedPeriod?.value}
        onChange={val => {
          if (val) {
            onFilterChange(
              {
                columnName,
                filters: [val],
              },
              false,
            )
            dropdown.toggle()
          }
        }}
        labelList="Cycles"
      >
        {option => (
          <OptionItem label={option.label} value={option.value} labelBy={labelCycleBy} />
        )}
      </RadioSelect>
    </>
  )
}
