import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { SelectPlan } from './SelectPlan'
import { Checkout } from './Checkout'
import { SubscriptionAndPayments } from './SubscriptionAndPayments'
import {
  SubscriptionPlanProvider,
  SubscriptionPlanProviderV2,
} from './SubscriptionPlanProvider'
import { InvoiceDetails } from './InvoiceDetails'
import { MainLayout } from '@src/features/MainLayout/MainLayout'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { FeatureFlags } from '@src/store/auth/types'
import { SelectPlanV2 } from '@src/pages/Forms/Plans/SelectPlanV2'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'

export const Plans = () => {
  return (
    <>
      <Switch>
        <Route path={[ROUTES.PLANS.SELECT_PLAN, ROUTES.PLANS.CHECKOUT]}>
          <PlanSelectionRouter />
        </Route>
        <Route exact path={[ROUTES.PLANS.PAYMENT_DETAILS, ROUTES.PLANS.INVOICES]}>
          <MainLayout>
            <SubscriptionAndPayments />
          </MainLayout>
        </Route>
        <Route exact path={ROUTES.PLANS.INVOICE_DETAILS}>
          <MainLayout>
            <InvoiceDetails />
          </MainLayout>
        </Route>
        <Route>
          <PageNotFoundWidget />
        </Route>
      </Switch>
    </>
  )
}

const PlanSelectionRouter = () => {
  const isNewPlansEnabled = useHasFeatureFlag(FeatureFlags.SubscriptionPlansV2)

  if (isNewPlansEnabled) {
    return (
      <SubscriptionPlanProvider>
        <SubscriptionPlanProviderV2>
          <Switch>
            <Route exact path={ROUTES.PLANS.SELECT_PLAN}>
              <MainLayout>
                <SelectPlanV2 />
              </MainLayout>
            </Route>
            <Route exact path={ROUTES.PLANS.CHECKOUT}>
              <Layout>
                <Checkout />
              </Layout>
            </Route>
          </Switch>
        </SubscriptionPlanProviderV2>
      </SubscriptionPlanProvider>
    )
  }

  return (
    <SubscriptionPlanProvider>
      <SubscriptionPlanProviderV2>
        <Layout>
          <Switch>
            <Route exact path={ROUTES.PLANS.SELECT_PLAN}>
              <SelectPlan />
            </Route>
            <Route exact path={ROUTES.PLANS.CHECKOUT}>
              <Checkout />
            </Route>
          </Switch>
        </Layout>
      </SubscriptionPlanProviderV2>
    </SubscriptionPlanProvider>
  )
}
