import { ColorTag } from '@components/ColorTag/ColorTag'
import React from 'react'
import { InterviewRoundEvaluationItem } from '@src/interfaces/interviewTool'
import { useTooltip, Tooltip } from '@revolut/ui-kit'

interface CVSkillsListItemProps {
  evaluation: InterviewRoundEvaluationItem
}

export const CVSkillsListItem = ({ evaluation }: CVSkillsListItemProps) => {
  const tooltip = useTooltip()

  return (
    <>
      <ColorTag
        variant={evaluation.has_evaluation ? 'success' : 'warning'}
        useIcon={evaluation.has_evaluation ? '16/CheckCircle' : '16/CrossCircle'}
        {...tooltip.getAnchorProps()}
      >
        {evaluation.skill.name}
      </ColorTag>
      <Tooltip {...tooltip.getTargetProps()} maxWidth={400}>
        {evaluation.justification}
      </Tooltip>
    </>
  )
}
