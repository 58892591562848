import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { DepartmentInterface } from '@src/interfaces/deparment'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { useGetResourcesTabConfig } from './api'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface DepartmentResourcesProps {
  data: DepartmentInterface
  tabId?: number
}

export const DepartmentResources = ({ data, tabId }: DepartmentResourcesProps) => {
  const {
    results: { error, isLoading },
    config,
  } = useGetResourcesTabConfig({ tabId, departmentId: data.id })

  if (!tabId) {
    return <PageNotFoundWidget />
  }

  if (error) {
    return <PageError error={error} />
  }

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <Switch>
      {config.map(tab => (
        <Route path={tab.to} exact key={tab.key}>
          <tab.component data={data} tabId={tabId} sectionId={tab.id} />
        </Route>
      ))}
      {config[0].to ? <InternalRedirect to={config[0].to} /> : null}
    </Switch>
  )
}
