import React, { useState } from 'react'
import styled from 'styled-components'
import {
  SkippedJustificationsInterface,
  SummarySkillCardJustificationInterface,
} from '@src/interfaces/performance'
import { Popup, Header, Text, chain, Token } from '@revolut/ui-kit'
import CommentsPopupCard from '@components/ColumnInserts/Justification/CommentsPopupCard'

interface Props {
  title: string
  data: (SkippedJustificationsInterface | SummarySkillCardJustificationInterface)[]
  label?: string
  subtitle?: string
}

const Wrapper = styled.div`
  color: ${Token.color.blue};
  padding-top: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const SummaryCommentsPopup = ({
  data,
  title,
  subtitle = 'Skipped',
  label = 'View Justification',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Wrapper
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <Text variant="emphasis2">{label}</Text>
      </Wrapper>
      <Popup
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>
            {chain(
              title,
              subtitle && <Text color={Token.color.greyTone50}>{subtitle}</Text>,
            )}
          </Header.Title>
        </Header>

        {data.map((justification, i) => (
          <CommentsPopupCard justification={justification} key={i}>
            {justification.value}
          </CommentsPopupCard>
        ))}
      </Popup>
    </>
  )
}

export default SummaryCommentsPopup
