import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  DatePickerInputCell,
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportContractsDataInterface } from '@src/interfaces/importContracts'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'
import { ImportInterface } from '@src/interfaces/bulkDataImport'

type EditableContractColumn = GenericEditableTableColumn<ImportContractsDataInterface>

type EditableContractWithSpecialisationsCheckColumn = (
  onChange: GenericEditableTableOnChange,
  specialisationsEnabled: boolean,
) => ColumnInterface<ImportInterface<ImportContractsDataInterface>>

export const onboardingContractsEmailColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const onboardingContractsContractTypeColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'contract_type',
    dataPoint: 'contract_type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Contract type',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="contract_type"
        fieldName="contract type"
        selector={selectorKeys.contract_types}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsContractTermColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'contract_term',
    dataPoint: 'contract_term',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Contract term',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="contract_term"
        fieldName="contract term"
        selector={selectorKeys.contract_terms}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsContractPositionColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'position',
    dataPoint: 'position',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Position',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="position"
        selector={selectorKeys.role}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsStartDateColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'contract_start_date',
  dataPoint: 'contract_start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
  insert: ({ data }) => (
    <DatePickerInputCell field="contract_start_date" data={data} onChange={onChange} />
  ),
})

export const onboardingContractsEndDateColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'contract_end_date',
  dataPoint: 'contract_end_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End date',
  insert: ({ data }) => (
    <DatePickerInputCell field="contract_end_date" data={data} onChange={onChange} />
  ),
})

export const onboardingContractsLocationColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Location',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="location"
      selector={selectorKeys.location}
      selectorField="name"
    />
  ),
})

export const onboardingContractsCompanyEntityColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'entity',
    dataPoint: 'entity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Company entity',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="entity"
        selector={selectorKeys.entity}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsSalaryAmountColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'salary_amount',
    dataPoint: 'salary_amount',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary amount',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        type={TableCellInputType.positiveInt}
        field="salary_amount"
      />
    ),
  })

export const onboardingContractsSalaryCurrencyColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'salary_currency',
    dataPoint: 'salary_currency',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary currency',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="salary_currency"
        fieldName="salary currency"
        selector={selectorKeys.currencies}
        selectorField="iso_code"
      />
    ),
  })

export const onboardingContractsSalaryTimeUnitColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'salary_time_unit',
    dataPoint: 'salary_time_unit',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary time unit',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="salary_time_unit"
        fieldName="salary time unit"
        selector={selectorKeys.contract_salary_time_units}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsSalaryPaymentFrequencyColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'salary_payment_frequency',
    dataPoint: 'salary_payment_frequency',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary payment frequency',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="salary_payment_frequency"
        fieldName="salary payment frequency"
        selector={selectorKeys.contract_salary_payment_frequencies}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsSpecialisationColumn: EditableContractWithSpecialisationsCheckColumn =
  (onChange, specialisationsEnabled: boolean) => ({
    type: CellTypes.insert,
    idPoint: 'specialisation',
    dataPoint: 'specialisation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: getSpecialisationTitle(specialisationsEnabled),
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="specialisation"
        selector={selectorKeys.specialisations}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsSeniorityColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
      selectorField="name"
    />
  ),
})

export const onboardingContractsSpecialisationSenioritySublevelColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'subseniority',
    dataPoint: 'subseniority',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Seniority sublevel',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="subseniority"
        selector={selectorKeys.seniority_sublevels}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsTitleColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="job_title" />,
})

export const onboardingContractsWeeklyWorkingHoursColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'weekly_working_hours',
    dataPoint: 'weekly_working_hours',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Weekly working hours',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        type={TableCellInputType.positiveInt}
        field="weekly_working_hours"
      />
    ),
  })

export const onboardingContractsFullTimeEquivalentColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'full_time_equivalent',
    dataPoint: 'full_time_equivalent',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Full time equivalent',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        type={TableCellInputType.positiveInt}
        field="full_time_equivalent"
      />
    ),
  })

export const onboardingContractsNoticePeriodDuringProbationColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_during_probation',
    dataPoint: 'notice_period_during_probation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period during probation',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        type={TableCellInputType.positiveInt}
        field="notice_period_during_probation"
      />
    ),
  })

export const onboardingContractsNoticePeriodDuringProbationUnitColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_during_probation_unit',
    dataPoint: 'notice_period_during_probation_unit',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period during probation unit',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="notice_period_during_probation_unit"
        fieldName="notice period during probation unit"
        selector={selectorKeys.contract_notice_period_time_units}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsNoticePeriodAfterProbationColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_after_probation',
    dataPoint: 'notice_period_after_probation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period after probation',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        type={TableCellInputType.positiveInt}
        field="notice_period_after_probation"
      />
    ),
  })

export const onboardingContractsNoticePeriodAfterProbationUnitColumn: EditableContractColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_after_probation_unit',
    dataPoint: 'notice_period_after_probation_unit',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period after probation unit',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="notice_period_after_probation_unit"
        fieldName="notice period after probation unit"
        selector={selectorKeys.contract_notice_period_time_units}
        selectorField="name"
      />
    ),
  })

export const onboardingContractsStatusColumn: EditableContractColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'contract_status',
  dataPoint: 'contract_status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="contract_status"
      fieldName="contract status"
      selector={selectorKeys.employee_contract_status}
      selectorField="name"
    />
  ),
})
