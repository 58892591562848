import { HStack, Icon, Text, Token, VStack, List } from '@revolut/ui-kit'
import { PlanFeatures } from '@src/pages/Forms/Plans/config'
import React from 'react'

interface Props {
  feature: PlanFeatures
}

export const PlanWidgetFeature = ({ feature }: Props) => {
  return (
    <VStack pb="s-16" key={feature.title}>
      <HStack space="s-8" align="center">
        <Icon name={feature.icon} color={Token.color.foreground} />
        <Text variant="h6">{feature.title}</Text>
      </HStack>
      <List variant="compact" color="grey-tone-50" pt="s-8" pl="s-32">
        {feature.items?.map(item => (
          <List.Item useIcon="16/CheckCircle" key={item}>
            {item}
          </List.Item>
        ))}
      </List>
    </VStack>
  )
}
